import React from 'react'
import { useNode, Element } from '@craftjs/core'

import { ColumnsSettings } from '../Columns'
import { SocialImage } from '../Image'

export const Social = ({ children = '', ...props }) => {
  const {
    connectors: { connect },
  } = useNode()

  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = props
  const parentPadding = props.allSidesPadding
    ? props.parentPadding
    : `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`

  return props.sections ? (
    <div
      ref={(ref) => connect(ref)}
      style={{
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        textAlign: 'center',
        padding: parentPadding,
      }}
    >
      {props.sections >= 1 && <Element is={SocialImage} id="imgSocial1" />}
      {props.sections >= 2 && <Element is={SocialImage} id="imgSocial2" />}
      {props.sections >= 3 && <Element is={SocialImage} id="imgSocial3" />}
      {props.sections >= 4 && <Element is={SocialImage} id="imgSocial4" />}
      {props.sections >= 5 && <Element is={SocialImage} id="imgSocial5" />}
    </div>
  ) : null
}

export const SocialDefaultProps = {
  sections: 1,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  isSocial: true,
}

Social.craft = {
  props: SocialDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ColumnsSettings,
  },
}
