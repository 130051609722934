import React from 'react'
import { PopupContainer } from 'components/Common'
import { FlexBox } from 'styledComponent'
import styled from 'styled-components'
import { JioLogoV2, VILogo, SmsPingLogo } from 'assets/dlt'

const LinkText = styled.span`
  color: #007bff;
  text-decoration: underline;
`

const BoldText = styled.span`
  font-weight: bold;
`
const disclaimerText = (shortUrl, source = '') => [
  {
    title: 'Brief Overview',
    description: `To ensure the safety and trust of your customers, TRAI requires that any communication through SMS containing a URL must have the URL whitelisted. This helps in preventing fraud, reducing spam, and ensuring that only legitimate content reaches your customers.`,
  },
  {
    title: 'Link',
    description: `Learn more about the process to whitelist the URL across various DLT platforms`,
    supportingIcons: [
      {
        icon: VILogo,
        link: 'https://www.vilpower.in/static/link_documents/usermanuals/CTA_Whitelisting.pdf',
      },
      {
        icon: JioLogoV2,
        link: 'https://trueconnect.jio.com/assets/Trai_module/SampleFileDownloads/CTA%20Whitelisting%20USER%20GUIDE.pdf',
      },
      {
        icon: SmsPingLogo,
        link: 'https://smartping.live/entity/assets/img/newHelpImg/pdf/CTA%20Whitelisting.pdf',
      },
    ],
  },
  {
    title: 'Disclaimer',
    description: (
      <>
        BillMe has shortened {source === 'custom' ? 'your' : source} URL using
        the gotiny domain. To comply with TRAI guidelines, please ensure that{' '}
        <LinkText>{shortUrl}</LinkText> whitelisted on your DLT platform. If
        you’ve already done this, no further action is needed.
      </>
    ),
  },
  {
    title: 'Important Notice',
    description: `If you proceed without whitelisting, the SMS will be charged but will not be delivered to the end customer.`,
  },
]

export const TraiDisclaimer = ({
  isPopOpen,
  setIsPopOpen,
  shortUrl,
  source = '',
}) => {
  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Disclaimer"
      cancelButtonName="Ok"
    >
      <FlexBox gap="12px" direction="column">
        {disclaimerText(shortUrl, source).map((text, i) => (
          <FlexBox direction="column" gap="10px" key={i}>
            <div>
              <BoldText>{text.title}</BoldText>: {text.description}
            </div>
            <FlexBox gap="30px">
              {text?.supportingIcons?.map((supportingImage, i) =>
                supportingImage?.icon ? (
                  <a
                    href={supportingImage?.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      width="120"
                      height="100%"
                      key={i}
                      src={supportingImage?.icon}
                      alt="icon"
                    />
                  </a>
                ) : null
              )}
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </PopupContainer>
  )
}
