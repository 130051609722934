import { Text } from 'components/Common'
import React from 'react'

import { $DisclaimerBlock } from './TemplateCampaign.styled'

const Disclaimer = ({ label, text, align, margin }) => {
  return (
    <$DisclaimerBlock gap="8px" align={align} margin={margin}>
      <Text weight="semi-bold">{label}</Text>
      <div>{text}</div>
    </$DisclaimerBlock>
  )
}

export default Disclaimer
