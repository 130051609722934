import { Input, LoaderPopup, TopActionBar, useToasts } from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer } from '../StoreManagement.styled'
import {
  getLocation,
  useDebouncedEffect,
  useRouter,
  useSelectStore,
} from 'hooks'
import React, { useState } from 'react'
import { Div, FlexBox, Grid } from 'styledComponent'
import { useTheme } from 'styled-components'
import {
  DIFF_OBJ,
  ERROR_DECODE,
  FILTER_EMPTY,
  GET_SEL_VALUE,
} from 'utils/reusables'
import { _selectOptionsGenerator } from 'utils/formatter'
import { useEffect } from 'react'
import {
  createCompany,
  getCompanyDetails,
  getCompanyTypes,
  updateCompany,
} from 'redux/actions/storeMgtActions'
import { useDispatch } from 'react-redux'
import { FORM_INITS, REQUIRED_FIELDS } from '../constants'
import { getPathStatus } from '../utils'
import { FormExtras } from '../components'
import { getStatesCities } from 'redux/actions/consumerAnalyticsActions'

export const CompanyForm = () => {
  const { history, params, pathname } = useRouter()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const {
    data: { companyTypes, companyDetails, screenLoading },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { isCreate, isView, isEdit, act } = getPathStatus(pathname)

  const [form, setForm] = useState(FORM_INITS?.Company)

  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  useDebouncedEffect(
    () => {
      getLocation(form?.country, form?.state).then(
        ({ countryOptions, stateOptions, cityOptions }) => {
          setCountryOptions(countryOptions)
          setStateOptions(stateOptions)
          setCityOptions(cityOptions)
          const country = countryOptions?.find(
            (count) => count?.name === form?.country
          )
          setForm({
            ...form,
            countryCode: country?.phonecode,
            currencyCode: country?.currency,
          })
        }
      )
    },
    [form?.country, form?.state],
    200
  )

  useEffect(() => {
    companyTypes?.length === 0 && dispatch(getCompanyTypes())
    params?.id && dispatch(getCompanyDetails(params?.id))
    dispatch(getStatesCities())
  }, [])

  useEffect(() => {
    companyTypes?.length === 0 && dispatch(getCompanyTypes())
    params?.id && dispatch(getCompanyDetails(params?.id))
    dispatch(getStatesCities())
  }, [])

  useDebouncedEffect(
    () => {
      if (companyDetails?._id === params?.id) {
        setForm({ ...FORM_INITS?.Company, ...companyDetails })
      }
    },
    [companyDetails],
    100
  )

  useDebouncedEffect(
    () => {
      const country = countryOptions?.find(
        (count) => count?.name === form?.country
      )
      setForm({
        ...form,
        countryCode: country?.phonecode,
        currencyCode: country?.currency,
      })
    },
    [form?.country],
    100
  )

  const handleChange = (field, value) => {
    setForm((frm) => ({ ...frm, [field]: value }))
  }

  const getCommonProps = (id, label, placeholder) => ({
    id,
    label,
    placeholder,
    containerMargin: '0',
    inputWidth: '400px',
    variant: 'input',
    key: id,
    value: form[id] || '',
    onChange: (e) => handleChange(id, e.target.value),
    disabled: isView,
  })

  const getSpecificProps = {
    companyType: {
      maxMenuHeight: '200px',
      variant: 'single-select',
      options: _selectOptionsGenerator(companyTypes),
      inputWidth: '400px',
      value: GET_SEL_VALUE(
        form?.companyType,
        _selectOptionsGenerator(companyTypes)
      ),
      onChange: (e) => handleChange('companyType', e?.value),
    },
    country: {
      variant: 'single-select',
      options: countryOptions,
      value: GET_SEL_VALUE(form?.country, countryOptions),
      onChange: (e) => handleChange('country', e?.value),
    },
    state: {
      variant: 'single-select',
      options: stateOptions,
      value: GET_SEL_VALUE(form?.state, stateOptions),
      onChange: (e) => handleChange('state', e?.value),
    },
    city: {
      variant: 'single-select',
      options: cityOptions,
      value: GET_SEL_VALUE(form?.city, cityOptions),
      onChange: (e) => handleChange('city', e?.value),
    },
    officeAddres: {
      rows: '5',
      variant: 'textarea',
    },
  }

  const onSave = async () => {
    try {
      const unFilledId = Object.keys(form)?.find(
        (item) => !form[item] && REQUIRED_FIELDS?.Company?.includes(item)
      )
      const label = document
        .getElementById('label' + unFilledId)
        ?.getAttribute('label')
      if (unFilledId) {
        throw `Please fill ${label}`
      } else {
        const res = await createCompany(FILTER_EMPTY({ ...form, vendorId }))
        if (res) {
          toast('Company created Successfully', 'success')
          history.push('/storeManagement')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onUpdate = async () => {
    try {
      const reqdForm = DIFF_OBJ(form, companyDetails)
      const unFilled = Object.keys(reqdForm)?.find((item) => !form[item])
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else if (Object.keys(reqdForm)?.length === 0) {
        throw `Please change attributes to update details`
      } else {
        const res = await updateCompany({
          ...reqdForm,
          vendorId,
          _id: params.id,
        })
        if (res) {
          toast('Company updated Successfully', 'success')
          history.push('/storeManagement')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <$WhiteContainer>
      <LoaderPopup isPopOpen={screenLoading} />
      <Navbar heading="Store Management - Company" />
      <TopActionBar
        title={`${act} Company Data`}
        onExit={() => history.push('/storeManagement')}
        extras={
          <FormExtras
            isView={isView}
            isCreate={isCreate}
            isEdit={isEdit}
            onUpdate={onUpdate}
            onSave={onSave}
            onEdit={() =>
              history.push(`/storeManagement/editCompany/${params?.id}`)
            }
          />
        }
      />
      <Grid
        columns="auto auto"
        columnGap={spacing.x3l}
        p={spacing.xl}
        pt={spacing.l}
      >
        <Input
          required
          {...getCommonProps('companyName', 'Company Name', 'ABC PVT LTD')}
        />
        <Input
          required
          {...getCommonProps('companyType', 'Select Company Type', 'pvt. ltd')}
          {...getSpecificProps['companyType']}
        />
        <Input
          {...getCommonProps(
            'inchargeName',
            'Incharge Name',
            'Mr. Arvind Modi'
          )}
        />
        <Input
          required
          {...getCommonProps('emailId', 'Email ID', 'abcpvtltd@xyz.com')}
        />
        <Input
          {...getCommonProps('country', 'Select Country', 'Select Country')}
          {...getSpecificProps?.country}
        />
        <FlexBox width="400px" justify="space-between">
          <Input
            {...getCommonProps('countryCode', 'Country Code', 'IN')}
            disabled
            inputWidth="180px"
          />
          <Input
            {...getCommonProps('currencyCode', 'Currency Code', 'INR')}
            disabled
            inputWidth="180px"
          />
        </FlexBox>
        <Input
          required
          {...getCommonProps('state', 'State', 'Maharashtra')}
          {...getSpecificProps['state']}
        />
        <Input
          required
          {...getCommonProps('city', 'City', 'Mumbai')}
          {...getSpecificProps['city']}
        />
        <Input {...getCommonProps('gst', 'GST', 'RDEJD216712750')} />
        <Input
          {...getCommonProps('billingName', 'Billing Name', 'ABC PVT LTD')}
        />
        <Div>
          <Input
            required
            {...getCommonProps('mobileNumber', 'Contact Number', '9876543210')}
            type="number"
            maxLength={10}
          />
          <FlexBox width="400px" justify="space-between">
            <Input
              {...getCommonProps('pan', 'PAN', 'ABDDE123E')}
              inputWidth="180px"
            />
            <Input
              required
              {...getCommonProps('pincode', 'PIN Code', '560056')}
              type="number"
              maxLength={6}
              inputWidth="180px"
            />
          </FlexBox>
        </Div>
        <Input
          {...getCommonProps(
            'officeAddres',
            'Address',
            'Magic Tower, 1st Floor, Magic Lane, Magic City.'
          )}
          {...getSpecificProps['officeAddres']}
        />
      </Grid>
    </$WhiteContainer>
  )
}
