import {
  $BlueBox,
  $SearchInput,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import { useSelectStore } from 'hooks'
import React, { useEffect, useState } from 'react'
import { Div, FlexBox, Grid } from 'styledComponent'
import { PopupContainer, Text, Input, Button, StateTabs } from './index'
import { _selectOptionsGenerator } from 'utils/formatter'
import { getStatesCities } from 'redux/actions/consumerAnalyticsActions'
import { GET_SEL_VALUE } from 'utils/reusables'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import SearchIcon from 'images/search.svg'
import { getStoreGroupOptions } from 'redux/actions/storeMgtActions'

export const StoreSelector = ({
  isPopOpen,
  setIsPopOpen,
  selectedStoreIds = [],
  setSelectedStoreIds,
  showStatus,
  showTabs = true,
  setTotalStores = () => {},
  setStoreGrp = () => {},
  disableCompanyInput = false,
  initForm = {},
  disableStoreGroups = false,
}) => {
  const { spacing } = useTheme()
  const dispatch = useDispatch()

  const [form, setForm] = useState({ ...init, ...initForm })
  const [localSelectedStoreIds, localSetSelectedStoreIds] =
    useState(selectedStoreIds)
  const [currentStores, setCurrentStores] = useState({})
  const [tab, setTab] = useState('Stores')
  const [strGrp, setStrGrp] = useState('')

  const {
    data: { location },
  } = useSelectStore('cAnalytics')
  const {
    data: { inactiveStores = {}, stores },
  } = useSelectStore('stores')
  const {
    data: { companies },
  } = useSelectStore('company')
  const {
    data: { brands },
  } = useSelectStore('brand')
  const {
    data: { storeGroupOptions },
  } = useSelectStore('strMgt')

  const statusStores = form?.status === 'inactive' ? inactiveStores : stores

  useEffect(() => {
    generateStores()
    dispatch(getStatesCities())
    setTotalStores(statusStores)
  }, [statusStores])

  useEffect(() => {
    if (strGrp)
      localSetSelectedStoreIds(
        storeGroupOptions?.find((sg) => sg?._id === strGrp)?.groupData
      )
    else setStoreGrp('')
  }, [strGrp])

  useEffect(() => {
    if (tab !== 'Stores') {
      dispatch(getStoreGroupOptions())
      setStrGrp('')
      setStoreGrp('')
      localSetSelectedStoreIds([])
    } else {
      setStoreGrp('')
    }
  }, [tab])

  useEffect(() => {
    isPopOpen && localSetSelectedStoreIds(selectedStoreIds)
  }, [isPopOpen])

  const handleSelect = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const loadStores = () => {
    setSelectedStoreIds(localSelectedStoreIds)
    setIsPopOpen(false)
  }

  const generateStores = () => {
    localSetSelectedStoreIds([])
    setCurrentStores({})
    const ores = Object.values(statusStores)?.reduce((acc, cur) => {
      acc[cur?.companyId] = [...(acc[cur?.companyId] || []), cur]
      return acc
    }, {})
    console.log('ores', ores)
    setCurrentStores(ores)
  }

  const isCompanyChecked = (compId) =>
    currentStores?.[compId]?.every((store) =>
      localSelectedStoreIds?.includes(store?.storeId)
    )
  const onCompanyCheck = (compId) => {
    if (
      form?.brands?.length ||
      form?.state?.length ||
      form?.city?.length ||
      form?.search?.length
    )
      return
    const compStores = currentStores?.[compId]?.map((str) => str.storeId)
    if (isCompanyChecked(compId)) {
      const selStrs = localSelectedStoreIds?.filter(
        (strId) => !compStores?.includes(strId)
      )
      localSetSelectedStoreIds(selStrs)
    } else {
      const strSet = new Set([...localSelectedStoreIds, ...compStores])
      localSetSelectedStoreIds(Array.from(strSet))
    }
  }
  const onStoreCheck = (strId) => {
    if (localSelectedStoreIds?.includes(strId)) {
      const newStrs = localSelectedStoreIds?.filter((stid) => stid !== strId)
      localSetSelectedStoreIds(newStrs)
    } else {
      localSetSelectedStoreIds([...localSelectedStoreIds, strId])
    }
  }

  const companyOptions = companies?.map((comp) => ({
    label: comp.companyName,
    value: comp.companyId,
  }))

  const brandOptions = brands?.map((brand) => ({
    label: brand.name,
    value: brand.brandId,
  }))

  const curStoresnCompanyArr = () => {
    const arr = []
    for (const key in currentStores) {
      if (Object.hasOwnProperty.call(currentStores, key)) {
        const element = currentStores[key]
        arr.push(companies?.find((cmp) => cmp.companyId === key))
        arr.push(...element)
      }
    }
    return arr
  }

  const showCompany = (compId) => {
    return (
      currentStores?.[compId]?.filter((str) => {
        if (str?.companyId === compId) {
          if (
            (form?.brand?.length === 0 ||
              form?.brand?.includes(str?.brandId)) &&
            (form?.state?.length === 0 ||
              form?.state?.find(
                (x) => x.toLowerCase() === str?.state?.toLowerCase()
              )) &&
            (form?.city?.length === 0 ||
              form?.city?.find(
                (x) => x.toLowerCase() === str?.city?.toLowerCase()
              )) &&
            `${str.storeCode ? `${str.storeCode} - ` : ''}${str.displayAddress}`
              ?.toLowerCase()
              ?.includes(form?.search?.toLowerCase())
          ) {
            return true
          }
          return false
        }
        return false
      })?.length > 0
    )
  }

  const selectAll = () => {
    const filteredStores = [...curStoresnCompanyArr()]
      ?.filter((item) => {
        if (
          (form?.company?.length === 0 ||
            form?.company?.includes(item?.companyId)) &&
          (form?.brand?.length === 0 || form?.brand?.includes(item?.brandId)) &&
          (form?.state?.length === 0 ||
            form?.state?.find(
              (x) => x.toLowerCase() === item?.state?.toLowerCase()
            )) &&
          (form?.city?.length === 0 ||
            form?.city?.find(
              (x) => x.toLowerCase() === item?.city?.toLowerCase()
            )) &&
          `${item?.storeCode ? `${item?.storeCode} - ` : ''}${
            item?.displayAddress
          }`
            ?.toLowerCase()
            ?.includes(form?.search?.toLowerCase()) &&
          item?.storeId &&
          !localSelectedStoreIds?.includes(item?.storeId)
        )
          return item
      })
      ?.map((str) => str?.storeId)
    localSetSelectedStoreIds([...localSelectedStoreIds, ...filteredStores])
  }

  const selStores = (
    <Div width="450px" padding={spacing.l}>
      <SelectedStoreTags
        statusStores={statusStores}
        localSelectedStoreIds={localSelectedStoreIds}
      />
    </Div>
  )

  return (
    <PopupContainer
      width="calc(100vw - 20px)"
      customContentStyle={{ minWidth: '1200px' }}
      heading={`Select Stores ${!disableStoreGroups ? '& Groups' : ''}`}
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      overlayStyle={{ overflow: 'auto' }}
      cancelButtonName="Close"
    >
      {showTabs && (
        <StateTabs tab={tab} setTab={setTab}>
          <span label="Stores" />
          {!disableStoreGroups ? <span label="Store Groups" /> : null}
        </StateTabs>
      )}
      {tab === 'Stores' ? (
        <Div>
          <Div mt={spacing.m}>
            <Text weight="bold" variant="medium">
              Sort Stores By:
            </Text>
            <FlexBox
              height="135px"
              style={{ color: '#000', textAlign: 'left' }}
            >
              <Input
                variant="multi-select"
                label="States"
                placeholder="Select States"
                options={_selectOptionsGenerator(location?.states)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                value={
                  (form?.state || []).map((item) =>
                    GET_SEL_VALUE(
                      item,
                      _selectOptionsGenerator(location?.states)
                    )
                  ) || ''
                }
                onChange={(arr) =>
                  handleSelect(
                    'state',
                    (arr || []).map((it) => it.value)
                  )
                }
                inputWidth="200px"
                containerMargin={`0 ${spacing.l} 0 0`}
                maxInputHeight="100px"
                inputOverflow="auto"
              />
              <Input
                variant="multi-select"
                label="Cities"
                placeholder="Select Cities"
                options={_selectOptionsGenerator(location?.cities)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                value={
                  (form?.city || []).map((item) =>
                    GET_SEL_VALUE(
                      item,
                      _selectOptionsGenerator(location?.cities)
                    )
                  ) || ''
                }
                onChange={(arr) =>
                  handleSelect(
                    'city',
                    (arr || []).map((it) => it.value)
                  )
                }
                inputWidth="200px"
                containerMargin={`0 ${spacing.l} 0 0`}
                maxInputHeight="100px"
                inputOverflow="auto"
              />
              <Input
                variant="multi-select"
                label="Companies"
                placeholder="Select Companies"
                options={companyOptions}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                disabled={disableCompanyInput}
                isClearable={false}
                value={
                  (form?.company || []).map((item) =>
                    GET_SEL_VALUE(item, companyOptions)
                  ) || ''
                }
                onChange={(arr) =>
                  handleSelect(
                    'company',
                    (arr || []).map((it) => it.value)
                  )
                }
                inputWidth="200px"
                containerMargin={`0 ${spacing.l} 0 0`}
                maxInputHeight="100px"
                inputOverflow="auto"
              />
              <Input
                variant="multi-select"
                label="Brands"
                placeholder="Select Brands"
                options={brandOptions}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                value={
                  (form?.brand || []).map((item) =>
                    GET_SEL_VALUE(item, brandOptions)
                  ) || ''
                }
                onChange={(arr) =>
                  handleSelect(
                    'brand',
                    (arr || []).map((it) => it.value)
                  )
                }
                inputWidth="200px"
                containerMargin={`0 ${spacing.l} 0 0`}
                maxInputHeight="100px"
                inputOverflow="auto"
              />
              {showStatus && (
                <Input
                  label="Store Status"
                  variant="radio"
                  direction="column"
                  options={[
                    {
                      value: 'active',
                      label: 'Active',
                    },
                    {
                      value: 'inactive',
                      label: 'Inactive',
                    },
                  ]}
                  value={form?.status}
                  onChange={(value) => handleSelect('status', value)}
                />
              )}
              <FlexBox align="center" ml={spacing.l}>
                <Button variant="textualError" onClick={() => setForm(init)}>
                  Clear Filters
                </Button>
                <Div
                  style={{ borderRight: '1px solid rgba(0,0,0,.1)' }}
                  height="40px"
                  mx={spacing.l}
                />
                <Button variant="publish" onClick={loadStores}>
                  Load Stores
                </Button>
              </FlexBox>
            </FlexBox>
            <FlexBox justify="space-between" mt={spacing.m}>
              <FlexBox gap={spacing.l}>
                <$SearchInput inputWidth="500px">
                  <span />
                  <input
                    id="searchTxt"
                    value={form?.search}
                    placeholder="Search by Store Name"
                    onChange={(e) =>
                      setForm({ ...form, search: e.target.value })
                    }
                  />
                </$SearchInput>
                <Button variant="primary" onClick={selectAll}>
                  Select All
                </Button>
                <Button
                  variant="primaryOutline"
                  onClick={() => localSetSelectedStoreIds([])}
                >
                  Unselect All
                </Button>
              </FlexBox>
            </FlexBox>
          </Div>
          <hr />
          <FlexBox
            minHeight="200px"
            height="calc(100vh - 430px)"
            justify="space-between"
          >
            <Div minWidth="calc(100% - 500px)">
              <FlexBox
                direction="column"
                wrap="wrap"
                height="100%"
                overflowX="auto"
              >
                {curStoresnCompanyArr()?.map(
                  (item, ind) =>
                    (form?.company?.length === 0 ||
                      form?.company?.includes(item?.companyId)) && (
                      <>
                        {item?.companyName && showCompany(item?.companyId) && (
                          <$Text
                            isCompany
                            onClick={() => onCompanyCheck(item?.companyId)}
                          >
                            <input
                              disabled={
                                form?.brands?.length ||
                                form?.state?.length ||
                                form?.city?.length ||
                                form?.search?.length
                              }
                              type="checkbox"
                              checked={isCompanyChecked(item?.companyId)}
                            />
                            <span>{item?.companyName}</span>
                          </$Text>
                        )}
                        {item?.brandId &&
                          (form?.brand?.length === 0 ||
                            form?.brand?.includes(item?.brandId)) &&
                          (form?.state?.length === 0 ||
                            form?.state?.find(
                              (x) =>
                                x.toLowerCase() === item?.state?.toLowerCase()
                            )) &&
                          (form?.city?.length === 0 ||
                            form?.city?.find(
                              (x) =>
                                x.toLowerCase() === item?.city?.toLowerCase()
                            )) &&
                          `${item.storeCode ? `${item.storeCode} - ` : ''}${
                            item.displayAddress
                          }`
                            ?.toLowerCase()
                            ?.includes(form?.search?.toLowerCase()) && (
                            <$Text
                              key={item?.storeId}
                              onClick={() => onStoreCheck(item.storeId)}
                            >
                              <input
                                type="checkbox"
                                checked={localSelectedStoreIds?.includes(
                                  item?.storeId
                                )}
                              />
                              <span>
                                {item.storeCode ? `${item.storeCode} - ` : ''}
                                {item.displayAddress}
                              </span>
                            </$Text>
                          )}
                      </>
                    )
                )}
              </FlexBox>
            </Div>
            {selStores}
          </FlexBox>
        </Div>
      ) : (
        <FlexBox height="calc(100vh - 200px)" justify="space-between">
          <Div maxHeight="100%" overflow="auto">
            <FlexBox align="center" justify="space-between" ml={spacing.l}>
              <Text weight="bold" variant="h3" my={spacing.l}>
                Select Store Group
              </Text>
              <Button variant="publish" onClick={loadStores}>
                Load Stores
              </Button>
            </FlexBox>
            <$StoreTable>
              <thead>
                <tr>
                  <th style={{ width: '100px' }}>Select</th>
                  <th style={{ width: '200px' }}>Group Name</th>
                  <th style={{ width: 'auto', minWidth: '500px' }}>
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {storeGroupOptions?.map((sg) => (
                  <tr
                    key={sg?._id}
                    onClick={() => {
                      setStrGrp(sg?._id)
                      setStoreGrp(sg?.name)
                    }}
                  >
                    <td>
                      <input type="radio" checked={strGrp === sg?._id} />
                    </td>
                    <td>{sg?.name}</td>
                    <td>{sg?.description || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </$StoreTable>
          </Div>
          {selStores}
        </FlexBox>
      )}
    </PopupContainer>
  )
}

export const SelectedStoreTags = ({ localSelectedStoreIds = [] }) => {
  const { spacing } = useTheme()
  const {
    data: { allStores },
  } = useSelectStore('stores')
  const [selectedStoreSearch, setSelectedStoreSearch] = useState('')

  return (
    <Div width="100%" maxHeight="100%" overflow="auto">
      <$BlueBox height="100%">
        <Text weight="bold" variant="small" type="main">
          Selected Stores: {localSelectedStoreIds?.length}
        </Text>
        <$SearchSmallInput inputWidth="100%">
          <span />
          <input
            id="searchTxt"
            placeholder="Search by Store Code or Display Address"
            value={selectedStoreSearch}
            onChange={(e) => setSelectedStoreSearch(e.target.value)}
          />
        </$SearchSmallInput>
        <Div backgroundColor="#FFF" height="calc(100% - 88px)" overflow="auto">
          <Grid
            columns="1fr 1fr"
            p={spacing.m}
            columnGap={spacing.m}
            rowGap={spacing.m}
          >
            {localSelectedStoreIds?.map((strId) => {
              const store = allStores?.[strId]
              if (
                store?.storeCode?.includes(selectedStoreSearch) ||
                store?.displayAddress
                  ?.toLowerCase()
                  ?.includes(selectedStoreSearch?.toLowerCase())
              ) {
                return (
                  <$Tag key={strId}>
                    {store?.storeCode ? `${store?.storeCode} - ` : ''}
                    {store?.displayAddress}
                  </$Tag>
                )
              }
              return null
            })}
          </Grid>
        </Div>
      </$BlueBox>
    </Div>
  )
}

const init = {
  state: [],
  city: [],
  company: [],
  brand: [],
  status: 'active',
  search: '',
}

export const $Text = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0 6px 16px;
  font-size: 16px;
  width: 300px;
  cursor: pointer;
  ${({ isCompany }) =>
    isCompany &&
    css`
      text-decoration: underline;
      margin: 6px 0 6px 0;
      font-weight: 700;
    `};
  input {
    min-width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: #000;
  }
`

export const $Tag = styled.div`
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  border: ${(props) => `1px solid ${props.theme.colors.primary.default}`};
  background-color: ${(props) => props.theme.colors.aquaHaze};
  color: #000;
  font-size: 10px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  text-align: center;
  height: 25px;
`

export const $StoreTable = styled.table`
  width: 100%;
  th {
    padding: 8px;
    color: grey;
    border: 2px solid lightgray;
  }
  td {
    padding: 8px;
    color: #495a6c;
    border: 2px solid lightgray;
  }
  input {
    width: 20px;
    height: 20px;
  }
`

export const $SearchSmallInput = styled.div`
  position: relative;
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing.l} 0;
  input {
    border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${({ theme }) => theme.spacing.m};
    padding-left: ${({ theme }) => theme.spacing.xl};
    font-size: 12px;
    width: 100%;
  }
  span {
    position: absolute;
    top: 8px;
    left: 10px;
    background: ${({ theme }) => theme.colors.white} url(${SearchIcon}) center;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`
