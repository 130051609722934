export const getPathStatus = (pathname) => {
  const isCreate = pathname?.includes('create')
  const isView = pathname?.includes('view')
  const isEdit = pathname?.includes('edit')
  const isRetail = pathname?.includes('Retail')
  const act = isCreate ? 'Create' : isView ? 'View' : 'Edit'

  return {
    isCreate,
    isView,
    isEdit,
    isRetail,
    act,
  }
}

export const checkCustomLinksValidation = (data) => {
  if (!data.length) return

  const isValid = data.every((ele) => ele.key && ele.value && !ele.isInvalid)

  return isValid
}

export const customLinksArrayToObj = (customLinks = []) =>
  customLinks.reduce((acc, curr) => {
    acc[curr?.key] = curr?.value
    return acc
  }, {})

export const customLinksObjToArray = (obj = {}) =>
  Object.keys(obj).reduce((acc, curr) => {
    const currObj = {
      key: curr,
      value: obj[curr],
    }

    acc.push(currObj)

    return acc
  }, [])
