import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { FormattedNumber } from 'react-intl'
import { CheckBox } from 'styledComponent/components/input'
import {
  TableRow,
  TableCell,
  FlexBox,
  Logo,
  ClickableImage,
  Div,
} from 'styledComponent'
import {
  cinepolisVendorId,
  mirajVendorId,
  connplexVendorId,
  nyCinemasVendorId,
  bhutaniCineplex,
  decathVendorIds,
  rajhansId,
  movieMaxVendorId,
  manyavarVendorId,
} from 'components/conditionalComponent'
import { centroStoreExitStores } from '../constants'
import { ActionCard, Text } from 'components/Common'
import { userTypes } from 'components/Layout/constants'
import { trentVendorId } from '../../../conditionalComponent'
import { showDeleteBtn } from 'utils/checkFeatureAlloted'
import { getBillType, getBookingId } from 'utils/billColumnHelper'

const getTransactionColor = (type) => {
  switch (type) {
    case 'BM':
      return '#1bd097'

    case 'BMPrint':
      return 'orange'

    case 'C.W':
      return 'darkgrey'

    case 'Print':
      return 'red'
  }
}

class BillsListCard extends Component {
  constructor() {
    super()
    this.state = {
      checkBoxStatus: false,
      storeAddress: '',
    }
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selectedBills?.includes(props.billData.billUid)) {
      return {
        ...state,
        checkBoxStatus: true,
      }
    } else {
      return {
        ...state,
        checkBoxStatus: false,
      }
    }
  }

  handleCheckboxClick() {
    this.props.onBillSelect(this.props.billData.billUid)
  }

  _checkCentroInvoiceDate = () => {
    if (
      !this.props.billData?.createdDate ||
      !this.props.billData?.storeId ||
      !centroStoreExitStores.includes(this.props.billData?.storeId)
    )
      return null
    const currentDate = moment()
    const date = moment(this.props.billData?.createdDate)
    const timeDiff = currentDate.diff(date, 'hours')

    return timeDiff >= 1
  }

  onActionClick = (action) => {
    if (this.props.billData.fileLocations != null) {
      var { png, pdf } = this.props.billData.fileLocations
    }
    const isPdf = this.props?.billData?.other?.customFrontendId === 'PDF'

    switch (action) {
      case 'view':
        return this.props.onBillView(this.props.billData)

      case 'download':
        return this.props.onDownloadBill(isPdf ? pdf : png)

      case 'delete': {
        this.props.onBillSelect(this.props.billData?.billUid)
        this.props.deleteBills()
      }
    }
  }

  render() {
    const {
      transactionType,
      createdDate,
      amount,
      user,
      billUid,
      email,
      mCustId,
      parsedData,
      vendorId, //for water bridge
      isEcomBill,
      isDelivered,
      uploadDate,
    } = this.props.billData

    const {
      invoiceNumber,
      resentNumber,
      resentEmail,
      displayAddress,
      brandLogo,
      brandName,
      customFrontendId,
      storeCode,
      visits,
      storeExit,
      storeExitType,
      countryCode: configCountryCode,
      currencyCode,
    } = this.props.billData.other

    const transactionColor = getTransactionColor(transactionType)
    let countryCode =
      (vendorId === 'j6cugjkr688xu4'
        ? '94'
        : `${
            parsedData?.customerData?.phoneDialCode || configCountryCode || '91'
          }`) + '-'

    countryCode = !countryCode.includes('+') ? `+${countryCode}` : countryCode

    const invoiceKaNumber = invoiceNumber || parsedData?.invoiceNumber

    const transactionId =
      parsedData?.customData?.TN ||
      parsedData?.customData?.barcode ||
      parsedData?.customData?.ticketId

    const components = ['view']
    if (
      showDeleteBtn(
        this.props.vendorId,
        this.props.isAdmin,
        this.props.userType !== userTypes.SECURITY
      )
    ) {
      components.push('delete')
    }
    if (
      !this.props.isCashier &&
      !this.props.isCentroCashier &&
      this.props.userType !== userTypes.SECURITY
    )
      components.push('download')

    return (
      <TableRow highlightOnHover="hsl(0, 0%, 97%)" fontSize="14px">
        {this.props.isDeleteActive && (
          <TableCell width="20px" textAlign="center">
            <CheckBox
              checked={this.state.checkBoxStatus}
              onClick={this.handleCheckboxClick}
              id={billUid}
            />
          </TableCell>
        )}
        {/* {this.props.vendorId === nykaaVendorId && (
          <TableCell>{mCustId || '-'}</TableCell>
        )} */}
        {['4gb0i29jkl7lk5hb', 'hr5pq1kfk5qccf7x'].includes(
          this.props.vendorId
        ) && this.props.userType === userTypes.SECURITY ? (
          <>
            <TableCell>{parsedData?.customerData?.firstName}</TableCell>
            <TableCell>{parsedData?.billAmountData.totalQty}</TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <FlexBox align="center">
                <Logo width="50px" height="50px" src={brandLogo} alt="" />
                <Div>
                  <Text>{brandName}</Text>
                  <Text variant="small" ellipsis maxWidth="45ch">
                    {displayAddress}
                  </Text>
                  <$EcomTag
                    isEcomBill={isEcomBill && vendorId != manyavarVendorId}
                  >
                    {isEcomBill && vendorId != manyavarVendorId
                      ? 'E-Commerce'
                      : 'Retail'}
                  </$EcomTag>
                </Div>
              </FlexBox>
            </TableCell>
            <TableCell>{storeCode || '-'}</TableCell>
          </>
        )}
        {[
          cinepolisVendorId,
          mirajVendorId,
          connplexVendorId,
          nyCinemasVendorId,
          rajhansId,
          movieMaxVendorId,
        ].includes(this.props.vendorId) && (
          <TableCell>
            {getBillType(
              this.props.billData?.parsedData?.customData?.ticketType
            )}
          </TableCell>
        )}
        <TableCell>
          <div>{email || '-'}</div>
          {resentEmail && (
            <div className="billsListContactNumberPink">{resentEmail}</div>
          )}
        </TableCell>
        <TableCell>
          <div>{user !== 'N.A' && !!user ? `${countryCode + user}` : '-'}</div>
          {resentNumber && (
            <div className="billsListContactNumberPink">
              {countryCode + resentNumber}
            </div>
          )}
        </TableCell>
        <TableCell>{invoiceKaNumber || '-'}</TableCell>
        {[
          cinepolisVendorId,
          mirajVendorId,
          connplexVendorId,
          nyCinemasVendorId,
          rajhansId,
          movieMaxVendorId,
        ].includes(this.props.vendorId) && (
          <TableCell>
            {getBookingId(this.props.billData?.parsedData?.customData?.barcode)}
          </TableCell>
        )}
        {this.props.selectedVendor === '2gtfxjl74ip6od' ? (
          <TableCell>{transactionId || '-'}</TableCell>
        ) : null}
        <TableCell>
          {amount ? (
            <FormattedNumber
              style="currency"
              currency={
                parsedData?.billAmountData?.saleCurrency ||
                currencyCode ||
                this.props.currencyCode
              }
              value={amount}
              minimumFractionDigits={0}
              maximumFractionDigits={5}
            />
          ) : (
            '-'
          )}
        </TableCell>
        {!(
          ['4gb0i29jkl7lk5hb', 'hr5pq1kfk5qccf7x'].includes(
            this.props.vendorId
          ) && this.props.userType === userTypes.SECURITY
        ) && (
          <TableCell>
            {getFormattedDate(uploadDate ? uploadDate : createdDate, true)}
          </TableCell>
        )}
        <TableCell>
          {getFormattedDate(
            uploadDate ? createdDate : parsedData?.date,
            uploadDate || isEcomBill
          )}
        </TableCell>
        {decathVendorIds.includes(this.props.vendorId) ? (
          <TableCell>
            {parsedData?.transactionalData?.cashierName || '-'}
          </TableCell>
        ) : null}
        {!(
          ['4gb0i29jkl7lk5hb', 'hr5pq1kfk5qccf7x'].includes(
            this.props.vendorId
          ) && this.props.userType === userTypes.SECURITY
        ) && (
          <TableCell textAlign="left">
            <div>
              <i
                className="fas fa-circle"
                style={{ color: transactionColor }}
              ></i>{' '}
              {transactionType === 'BM'
                ? 'BillMe'
                : transactionType === 'BMPrint'
                ? 'BillMe + Print'
                : transactionType === 'Print'
                ? 'Print'
                : 'Closed Window'}
            </div>

            {resentNumber ? (
              <div>
                <i className="fas fa-circle" style={{ color: '#ec46c0' }}></i>
                <span style={{ color: '#464a53' }}> Resent</span>
              </div>
            ) : null}
          </TableCell>
        )}
        {['1kpmjfjkkgt020t', '32la51wrkqta2oue'].includes(
          this.props.selectedVendor
        ) ? (
          <>
            <TableCell>
              <div>
                {storeExit && storeExitType ? (
                  <span>{storeExitType}</span>
                ) : this._checkCentroInvoiceDate() ? (
                  <span>auto</span>
                ) : (
                  '-'
                )}
              </div>
            </TableCell>
            <TableCell>
              <div>
                {visits?.length ? (
                  <>
                    <span>{visits?.length}</span>
                    <div>
                      <i
                        className="fas fa-check-double"
                        style={{ color: '#1bd097' }}
                      ></i>
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </TableCell>
          </>
        ) : null}

        {this.props.userType !== userTypes.SECURITY &&
        this.props.vendorId !== trentVendorId ? (
          <>
            <TableCell>
              <div>{isDelivered ? <span>Yes</span> : <span>No</span>}</div>
            </TableCell>
            <TableCell>
              <div>
                {visits?.length ? (
                  <>
                    <span>{visits?.length}</span>
                    <div>
                      <i
                        className="fas fa-check-double"
                        style={{ color: '#1bd097' }}
                      ></i>
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </TableCell>
          </>
        ) : null}

        {[
          '5c7hzqknstqhza',
          '357nfjkonyq6s0', //nykaa
        ].includes(this.props.selectedVendor) ? (
          <>
            <TableCell>
              <div>
                {visits?.length ? (
                  <>
                    <span>{visits?.length}</span>
                    <div>
                      <i
                        className="fas fa-check-double"
                        style={{ color: '#1bd097' }}
                      ></i>
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </TableCell>
          </>
        ) : null}

        <TableCell textAlign="center">
          <ActionCard
            onIconClick={this.onActionClick}
            components={components}
            item=""
          />
          {/* <FlexBox justify="center">
            <ClickableImage
              onClick={() => }
              src={OpenEyeIcon}
              title="View bill"
              width="20px"
              height="20px"
              rightMargin="10px"
            />
            {!this.props.isCashier &&
              !this.props.isCentroCashier &&
              !isEcomBill && (
                <ClickableImage
                  onClick={() => }
                  src={DownloadIcon}
                  title="Download bill"
                  width="20px"
                  height="20px"
                />
              )}
          </FlexBox> */}
        </TableCell>
      </TableRow>
    )
  }
}

const mapStateToProps = (state) => {
  const isAdmin = state.stores.storesArray?.filter((x) => x.isAdmin).length
  return {
    isCashier: state.bills.isCashier,
    vendorId: state.vendorIds.selectedVendor.value,
    currencyCode: state.auth.user.currencyCode,
    userType: state.auth.user.userType,
    isAdmin,
  }
}

export default connect(mapStateToProps)(BillsListCard)

const getFormattedDate = (dT, isSystemDate = false) => {
  if (!dT) return '-'

  if (new Date(dT) == 'Invalid Date') {
    return dT
  }

  const date = isSystemDate
    ? moment(dT).format('DD/MM/YYYY')
    : moment(moment(dT, 'DD/MM/YYYY')).format('DD/MM/YYYY')
  const time = moment(dT).format('HH:mm')
  return (
    <div style={{ width: '120px' }}>
      <div>
        <i className="fa fa-calendar" style={{ color: '#0084e9' }} /> {date}
      </div>
      <div>
        <i className="fas fa-clock" style={{ color: '#0084e9' }} /> {time}
      </div>
    </div>
  )
}

export const $EcomTag = styled.div`
  border: 1px solid ${(props) => (props?.isEcomBill ? `#00ABF0` : `#FFA726`)};
  color: ${(props) => (props?.isEcomBill ? `#00ABF0` : `#FFA726`)};
  border-radius: 4px;
  padding: 4px 8px;
  width: ${(props) => (props?.isEcomBill ? `90px` : `70px`)};
  font-size: 10px;
  text-align: center !important;
`
