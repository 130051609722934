import moment from 'moment'

const MONTHS = moment.months()
const CURRENT_MONTH = moment().month()

const generateStartAndEndDate = ({ backDayCount, backMonthCount }) => {
  let startDate = null
  let endDate = null

  if (backMonthCount || backMonthCount === 0) {
    const date = moment().set({ month: CURRENT_MONTH })
    const monthDate = date.utc().subtract(backMonthCount, 'months')

    startDate = monthDate.startOf('month').format()
    endDate = monthDate.endOf('month').format()
  } else {
    startDate = moment().utc().subtract(backDayCount, 'd').format()
    endDate = moment().utc().format()
  }

  return {
    startDate,
    endDate,
  }
}

const DATE_OPTIONS = [
  {
    date: generateStartAndEndDate({ backDayCount: 7 }),
    value: 'last 7 days',
    label: 'Last 7 days',
  },
  {
    date: generateStartAndEndDate({ backMonthCount: 0 }),
    value: MONTHS[CURRENT_MONTH],
    label: 'Current Month',
  },
  {
    date: generateStartAndEndDate({ backMonthCount: 1 }),
    value: MONTHS[CURRENT_MONTH - 1] || MONTHS[MONTHS.length - 1],
    label: MONTHS[CURRENT_MONTH - 1] || MONTHS[MONTHS.length - 1],
  },
  {
    date: generateStartAndEndDate({ backMonthCount: 2 }),
    value: MONTHS[CURRENT_MONTH - 2] || MONTHS[MONTHS.length - 2],
    label: MONTHS[CURRENT_MONTH - 2] || MONTHS[MONTHS.length - 2],
  },
]

export { DATE_OPTIONS }
