import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { FlexBox } from 'styledComponent'
import { color, spacing } from 'design-system'
import { Button } from 'components/Common'

const $DeleteButton = styled(Button)`
  margin-left: ${spacing.m};
  padding: 6px;
  svg {
    margin-right: 0 !important;
    width: 25px;
    height: 25px;
  }
`

const $SegmentDetails = styled.div`
  margin-top: ${spacing.l};
`

const $Hr = styled.div`
  margin: ${spacing.s} 0;
  width: 100%;
  height: 1px;
  background-color: ${color.light};
`

const sharedUlStyles = css`
  list-style: none;
  padding: 0;
`

const $SegmentEntities = styled.ul`
  ${sharedUlStyles}
  margin-top: ${spacing.l};
  display: flex;
  flex-direction: column;
`

const $Entity = styled.li`
  flex-basis: ${(props) => props.flexBasis || '50%'};
  margin-bottom: ${spacing.xl};
`

const $EntityContentBlock = styled.ul`
  ${sharedUlStyles}
  padding: ${spacing.m} 0;
`
const $EntityContent = styled.li`
  display: flex;
  width: ${({ width }) => width || '95%'};
  ${space};
`

const $ContentLabel = styled.span`
  min-width: ${(props) => props.minWidth || '170px'} !important;
  color: #9a9a9a;
`

const $ContentValue = styled(FlexBox)`
  color: ${color.dark};
  font-weight: 700;
`

const $DeviceTitle = styled.div`
  text-transform: capitalize;
  margin-left: ${spacing.m};
  text-align: center;
  span {
    margin-left: ${spacing.s};
    vertical-align: middle;
  }
`

export {
  $SegmentDetails,
  $Hr,
  $SegmentEntities,
  $Entity,
  $EntityContentBlock,
  $EntityContent,
  $ContentLabel,
  $ContentValue,
  $DeviceTitle,
  $DeleteButton,
}
