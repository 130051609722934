import React from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'

/**
 * @description common Line Chart component.
 * @param {Object<data, series>} data - chart data object,
 * for chart data and multiple series data
 * @param {String} title - Chart title
 * @param {String} curve - curve type
 * @param {String} toolTipTitle - tooltip title
 * @param {Boolean} zoom - show/hide zoom controls
 * @param {Boolean} showToolBar - show/hide toolBar controls
 */
const LineChart = ({
  title,
  data: { data, series },
  zoom,
  curve,
  showToolBar,
  toolTipTitle,
  onDataPointClick,
  dataTitleFormatter,
  ...props
}) => {
  const dataPointClickHandler = (
    event,
    chartContext,
    { seriesIndex, dataPointIndex, config }
  ) => {
    event.preventDefault()

    if (dataPointIndex > -1 && typeof onDataPointClick === 'function') {
      const { key, value } = data[dataPointIndex]
      const chartDataPoint = {
        key,
        value,
      }
      return onDataPointClick(chartDataPoint)
    }
    return null
  }

  const chartConfig = {
    series: series || [
      {
        name: toolTipTitle,
        data: data.map(({ value }) => value),
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: showToolBar,
        },
        zoom: {
          enabled: zoom,
        },
        events: {
          dataPointSelection: dataPointClickHandler,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: curve,
      },
      title: {
        text: title,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data.map(({ key }) => key),
      },
      tooltip: {
        y: {
          formatter: dataTitleFormatter,
        },
      },
    },
  }
  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="line"
      {...props}
    />
  )
}

export default LineChart

LineChart.defaultProps = {
  height: 350,
  title: '',
  curve: 'straight',
  zoom: false,
  showToolBar: false,
  toolTipTitle: 'Total',
  onDataPointClick: null,
  dataTitleFormatter: function (val) {
    return val
  },
}

LineChart.prototype = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.number,
        })
      ).isRequired,
      series: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          data: PropTypes.arrayOf(PropTypes.number),
        })
      ),
    })
  ),
  title: PropTypes.string,
  curve: PropTypes.string,
  toolTipTitle: PropTypes.string,
  zoom: PropTypes.bool,
  showToolBar: PropTypes.bool,
  onDataPointClick: PropTypes.func,
}
