import { defaultFilters } from 'utils/billUtils'
import _ from 'lodash'

const initialState = {
  rememberFilters: false,
  defaultFilters: _.cloneDeep(defaultFilters),
  filterQuery: {},
  isCashier: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_REMEMBER_FILTERS':
      return {
        ...state,
        rememberFilters: action.payload,
      }
    case 'SET_DEFAULT_FILTER':
      return {
        ...state,
        defaultFilters: action.payload,
      }
    case 'SET_BILL_FILTER':
      return {
        ...state,
        filterQuery: {
          ...action.payload,
        },
      }

    case 'SET_CASHIER':
      return {
        ...state,
        isCashier: action.payload,
      }

    default:
      return state
  }
}
