import React, { useEffect, useState } from 'react'

import { SurveyCampaign } from './SurveyCampaign'
import { TopBarAndStepsWrapper } from '../../components/TopBarAndStepsWrapper'
import { SURVEY_STEPS, CAMP_INIT, CHECK_IF } from '../../constants'
import { $WhiteContainer } from '../../AutoEngagement.styled'
import { useSurveyBuilderApi } from './useSurveyBuilderApi'

const SurveyEngage = () => {
  const [surveyForm, setSurveyForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createSurvey,
    updSurvey,
    surveyId,
    body,
    fetchSurveyById,
    hideSteps,
  } = useSurveyBuilderApi(surveyForm, setSurveyForm, CAMP_INIT, 'survey')

  useEffect(() => {
    const step = hideSteps
      ? { id: 3, label: 'Survey Preview', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [surveyForm])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && surveyId && updSurvey()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...surveyForm }
        const check = CHECK_IF(
          ['name', 'vendorId', 'campaignType'],
          campDetails
        )
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (surveyId && surveyId !== 'create' ? updSurvey() : createSurvey())
        )
      }

      case 2: {
        const surveyContent = { ...surveyForm?.content }
        const check = CHECK_IF(['body', 'background'], surveyContent)
        if (check) throw `Please enter ${check}`
        if (!surveyForm.contentType)
          throw `Please select Survey Page Display type`
        if (surveyContent?.body?.length < 1)
          throw `Please add at least one survey question`

        const questArray = surveyContent?.body?.filter(
          (ques) => ques?.fieldType === 'question'
        )
        if (!questArray.every((que) => que?.text?.length > 0))
          throw `Question cannot be empty`

        const optionTypes = [
          'radio',
          'checkbox',
          'range',
          'rating',
          'image',
          'image-multi',
        ]
        const optArray = questArray?.filter((ques) =>
          optionTypes.includes(ques?.inputType)
        )
        if (
          !optArray.every((que) =>
            que?.options?.every((opt) => opt?.label?.length > 0)
          )
        )
          throw `Options cannot be empty`

        const imageTypes = ['image', 'image-multi']
        const imgOptArray = questArray?.filter((ques) =>
          imageTypes.includes(ques?.inputType)
        )

        if (
          !imgOptArray.every((que) =>
            que?.options?.every((opt) => opt?.title?.length > 0)
          )
        )
          throw `All images should have titles`

        return await updateCall()
      }

      case 3: {
        return await updateCall()
      }

      default:
        return
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Create Survey"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={SURVEY_STEPS}
        onPreviousClick={fetchSurveyById}
        onSave={onSave}
        onPublish={onPublish}
        hideSteps={hideSteps}
        form={surveyForm}
      >
        <SurveyCampaign
          form={surveyForm}
          setForm={setSurveyForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}

export default SurveyEngage
