import React from 'react'

import { Div, FlexBox } from 'styledComponent'
import { Button, RenderIf, Text } from 'components/Common'
import { spacing } from 'design-system'
import { GET_SEL_VALUE } from 'utils/reusables'
import { ReactComponent as AddIcon } from 'assets/icons/add-blue.svg'
import Preview from './PreviewPanel'
import Disclaimer from './Disclaimer'
import AddTemplateContent from './AddTemplateContent'
import {
  BUTTON_TYPES,
  DISCLAIMER_TEXT_PREVIEW,
  LANGUAGES,
  MEDIA_TYPES,
  TEMPLATE_TYPES,
} from './constants'

const PreviewAndSubmit = ({ form, hideSteps, waAccounts }) => {
  const [showPopup, setShowPopup] = React.useState(false)

  return (
    <FlexBox>
      <Div flex="1" padding="0 32px 0 0">
        <RenderIf active={!hideSteps}>
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            Preview & Submit
          </Text>
        </RenderIf>
        <RenderIf active={hideSteps}>
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            Preview
          </Text>
        </RenderIf>
        <Text variant="h4" weight="bold" type="main">
          Template Details
        </Text>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">WhatsApp Account:</Text>
          <Text>
            {GET_SEL_VALUE(form?.settingAccountId, waAccounts)?.label || ''}
          </Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Template:</Text>
          <Text>{form?.name}</Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Template Description:</Text>
          <Text>{form?.description}</Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Template Category:</Text>
          <Text>{form?.category}</Text>
        </FlexBox>

        <Disclaimer
          label="Disclaimer"
          text={DISCLAIMER_TEXT_PREVIEW}
          align="flex-start"
          margin={`${spacing.xl} 0 ${spacing.m}`}
        />

        <RenderIf
          active={
            ['draft', 'pending', 'disabled'].includes(form?.status) &&
            !hideSteps
          }
        >
          <FlexBox justify="end">
            <Button
              variant="primaryOutline"
              startIcon={AddIcon}
              onClick={() => setShowPopup(true)}
            >
              Add Sample Content
            </Button>
          </FlexBox>
        </RenderIf>

        <Text variant="h4" weight="bold" type="main" mb={spacing.l}>
          Content
        </Text>
        <FlexBox gap="32px">
          <FlexBox gap="4px" margin="8px 0">
            <Text type="grey">Template Language:</Text>
            <Text>
              {LANGUAGES.find(({ value }) => value === form?.language)?.label}
            </Text>
          </FlexBox>
          <FlexBox gap="4px" margin="8px 0">
            <Text type="grey">Template Type:</Text>
            <Text>
              {GET_SEL_VALUE(form?.type, TEMPLATE_TYPES)?.label || null}
            </Text>
          </FlexBox>
        </FlexBox>

        <RenderIf active={form?.type === 'media'}>
          <FlexBox gap="4px" margin="8px 0">
            <Text type="grey">Template Media Type:</Text>
            <Text>
              {GET_SEL_VALUE(form?.mediaType, MEDIA_TYPES)?.label || null}
            </Text>
          </FlexBox>
        </RenderIf>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Template Header:</Text>
          <Text>{form?.content?.header}</Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0" align="flex-start">
          <Text type="grey">Template Body:</Text>
          <Text style={{ whiteSpace: 'pre-wrap' }}>{form?.content?.body}</Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Template Footer:</Text>
          <Text>{form?.content?.footer}</Text>
        </FlexBox>
        <FlexBox gap="4px" margin="8px 0">
          <Text type="grey">Add Button(s):</Text>
          <Text>
            {GET_SEL_VALUE(form?.buttonType, BUTTON_TYPES)?.label || null}
            {form?.buttons?.length ? ` - ${form?.buttons?.length}` : ''}
          </Text>
        </FlexBox>
      </Div>
      <Preview data={form} disableToggle />
      <AddTemplateContent
        form={form}
        isActive={showPopup}
        setIsActive={setShowPopup}
      />
    </FlexBox>
  )
}

export default PreviewAndSubmit
