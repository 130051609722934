import {
  INIT_FILTER,
  WA_FILTERS,
} from 'components/Content/autoEngagement/constants'
import createReducer from 'utils/createReducer'
import * as types from '../types/autoEngage'

const initialState = {
  aeTableLoading: false,
  aeLoading: false,
  isSIB: false,
  aeData: [],
  couponData: {
    docs: [],
    count: 0,
  },
  surveyData: {
    docs: [],
    count: 0,
  },
  surveyResponseData: {
    docs: [],
    count: 0,
    pageCount: 0,
    totalDocs: 0,
  },
  whatsAppTemplates: {
    docs: [],
    count: 0,
  },
  surveyButtonsData: [],
  segmentData: [],
  aeCampaignData: {},
  aeSurveyData: {},
  aeWhatsAppData: {},
  filterData: INIT_FILTER,
  wATemplateFilterData: WA_FILTERS,
  filterQuery: {},
  emailProvidersList: [],
  emailTemplates: [],
  emailTemplateDetails: {},
  assetsList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsCsvList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsImageList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsCarouselList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsVideoList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsPdfList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  assetsGifList: {
    docs: [],
    count: 0,
    limit: 10,
  },
  audienceStoreFilter: {
    noOfEmployee: {
      isActive: false,
      value: {
        from: 0,
        to: 0,
      },
    },
    size: {
      isActive: false,
      value: {
        from: 0,
        to: 0,
      },
    },
    location: {
      isActive: false,
      value: {
        state: '',
        city: '',
      },
    },
    /* selectedCompanies: {
      isActive: false,
      value: [],
    },
    selectedBrands: {
      isActive: false,
      value: [],
    }, */
  },
  newAudienceStoreFilter: {
    geography: [],
  },
}

export default createReducer(initialState)(types)
