import styled, { createGlobalStyle } from 'styled-components'
import { FlexBox } from 'styledComponent'

const $BodyStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || '100%'};
  margin: ${({ m }) => m || 'unset'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
`

const $FlexItem = styled.div`
  flex-basis: ${({ flexBasis }) => flexBasis || '100%'};
  width: ${({ width }) => width || 'unset'};
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  margin-right: ${({ mr }) => mr || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};
  margin-left: ${({ ml }) => ml || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #EBF3FE' : 'unset'};
`

const $Block = styled.div`
  padding: ${({ padding }) => padding || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
  position: ${({ position }) => position || 'static'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
`
const $SelectContainer = styled.div`
  width: 200px;
  margin-right: ${({ mr }) => mr || 'unset'};
`

const $Container = styled.div`
  padding: ${({ padding }) => padding || 'unset'};
`

export {
  $BodyStyles,
  $FlexBox,
  $FlexItem,
  $Block,
  $SelectContainer,
  $Container,
}
