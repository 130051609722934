import React, { Component } from 'react'
import 'css/content/recharge.scss'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'

class PaymentPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.proceedPayment = this.proceedPayment.bind(this)
  }
  proceedPayment() {
    this.props.onPaymentClicked()
  }

  render() {
    return (
      <div onClick={this.proceedPayment} className="paymentPopupGST">
        <div className="gstBreakdown">
          <div className="tag">Recharge Amount</div>
          <div className="amount">
            <FormattedNumber
              currency={this.props.currencyCode}
              style="currency"
              value={this.props.amount.toFixed(2)}
            />
          </div>
        </div>
        <div className="gstBreakdown">
          <div className="tag">GST (18%)</div>
          <div className="amount">
            <FormattedNumber
              currency={this.props.currencyCode}
              style="currency"
              value={(this.props.amount * 0.18).toFixed(2)}
            />
          </div>
        </div>
        <hr className="gstHr" />
        <div className="gstBreakdown">
          <div className="tag">
            <b>Net Payable</b>
          </div>
          <div className="amount">
            <b>
              <FormattedNumber
                currency={this.props.currencyCode}
                style="currency"
                value={(
                  parseFloat(this.props.amount) +
                  parseFloat((this.props.amount * 0.18).toFixed(2))
                ).toFixed(2)}
              />
            </b>
          </div>
        </div>
        <button className="proceedPaymentButton">Proceed To Payment</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currencyCode: state.auth.user.currencyCode,
})

export default connect(mapStateToProps)(PaymentPopup)
