import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'
import { PopupOverBillCampaign } from './PopupCampaign'

import { $WhiteContainer } from '../AutoEngagement.styled'
import { POPUP_STEPS, CAMP_INIT, CHECK_IF } from '../constants'
import { useCampaignApi } from '../components'
import { validateScheduleCampaignDateTime } from '../advert'
import { validateIframe, validateIframeAttribute } from 'utils/iframeUtils'

export const PopupOverBill = () => {
  const [popupForm, setPopupForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(popupForm, setPopupForm, CAMP_INIT, 'popupOverBill')

  useEffect(() => {
    const step = hideSteps
      ? { id: 5, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && campaignId && updCampaign()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...popupForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (campaignId && campaignId !== 'create'
            ? updCampaign()
            : createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...popupForm?.content?.body }
        const check = CHECK_IF(['componentType'], builderDetails)
        if (check) throw `Please select a component`
        if (builderDetails?.assetsDetails?.length < 1)
          throw 'Please add an asset for your campaign.'
        if (!builderDetails?.assetsDetails[0]?.fileLocation)
          throw 'Please add an asset for your campaign.'
        if (
          builderDetails.componentType === 'youtube' &&
          (!validateIframe(
            builderDetails?.assetsDetails[0]?.key ||
              builderDetails?.assetsDetails[0]?.fileLocation
          ) ||
            !validateIframeAttribute(
              builderDetails?.assetsDetails[0]?.key ||
                builderDetails?.assetsDetails[0]?.fileLocation
            ))
        ) {
          throw 'Please add a valid youtube embedd link.'
        }

        return await updateCall()
      }

      case 3: {
        if (popupForm?.content?.setting?.visibility > 10) {
          throw 'Popup Show After should be less than 10 seconds'
        }

        if (popupForm?.content?.setting?.closeBtnDelay > 10) {
          throw 'Popup Show Close Button after should be less than 10 seconds'
        }

        return await updateCall()
      }

      case 4: {
        const storeDetails = { ...popupForm?.targetGroup }
        if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
          throw 'Please select a brand and at least one store.'

        const isScheduleErrorPresent =
          validateScheduleCampaignDateTime(popupForm)
        if (isScheduleErrorPresent !== false) throw isScheduleErrorPresent

        return await updateCall()
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Popup Over Bill"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={POPUP_STEPS}
        onPreviousClick={fetchCampaignById}
        onSave={onSave}
        onPublish={onPublish}
        hideSteps={hideSteps}
        form={popupForm}
        campaignType="Popup Over Bill"
      >
        <PopupOverBillCampaign
          form={popupForm}
          active={currentStep.label}
          setForm={setPopupForm}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}
