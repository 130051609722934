import { Input, LoaderPopup, TopActionBar, useToasts } from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer, $AddBrandLogo } from '../StoreManagement.styled'
import { useDebouncedEffect, useRouter, useSelectStore } from 'hooks'
import React, { useState } from 'react'
import { Div, Grid } from 'styledComponent'
import { useTheme } from 'styled-components'
import { DIFF_OBJ, ERROR_DECODE, GET_SEL_VALUE } from 'utils/reusables'
import { _selectOptionsGenerator } from 'utils/formatter'
import { useEffect } from 'react'
import {
  createBrand,
  getBrandCategories,
  getBrandDetails,
  updateBrand,
} from 'redux/actions/storeMgtActions'
import { useDispatch } from 'react-redux'
import { FORM_INITS, REQUIRED_FIELDS } from '../constants'
import { CameraFilledIcon } from 'assets/icons'
import { getPathStatus } from '../utils'
import { FormExtras } from '../components'

export const BrandForm = () => {
  const { history, params, pathname } = useRouter()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const {
    data: { screenLoading, brandCategories, brandDetails },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { isCreate, isView, isEdit, act } = getPathStatus(pathname)

  const [form, setForm] = useState(FORM_INITS?.Company)

  useEffect(() => {
    brandCategories?.length === 0 && dispatch(getBrandCategories())
    params?.id && dispatch(getBrandDetails(params?.id))
  }, [])

  useDebouncedEffect(
    () => {
      if (brandDetails?._id === params?.id) {
        setForm(brandDetails)
      }
    },
    [brandDetails],
    100
  )

  const handleChange = (field, value) => {
    setForm((frm) => ({ ...frm, [field]: value }))
  }

  const onSave = async () => {
    try {
      const unFilled = Object.keys(form)?.find(
        (item) => !form[item] && REQUIRED_FIELDS?.Brands?.includes(item)
      )
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else {
        const formData = new FormData()
        formData.append('vendorId', vendorId)
        formData.append('brandName', form?.brandName)
        formData.append('categoryId', form?.categoryId)
        form?.description && formData.append('description', form?.description)
        form?.brandLogo && formData.append('brandLogo', form?.brandLogo)

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const res = await createBrand(formData, config)
        if (res) {
          toast('Brand created Successfully', 'success')
          history.push('/storeManagement?tab=Brands')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onUpdate = async () => {
    try {
      const reqdForm = DIFF_OBJ(form, brandDetails)
      const unFilled = Object.keys(reqdForm)?.find((item) => !form[item])
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else if (Object.keys(reqdForm)?.length === 0) {
        throw `Please change attributes to update details`
      } else {
        const formData = new FormData()
        formData.append('vendorId', vendorId)
        reqdForm?.brandLogo && formData.append('brandLogo', form?.brandLogo)
        reqdForm?.brandName && formData.append('brandName', form?.brandName)
        reqdForm?.description &&
          formData.append('description', form?.description)
        reqdForm?.categoryId && formData.append('categoryId', form?.categoryId)

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const res = await updateBrand(params.id, formData, config)
        if (res) {
          toast('Company updated Successfully', 'success')
          history.push('/storeManagement?tab=Brands')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const brandCategoryOptions = brandCategories?.map((item) => ({
    label: item.categoryName,
    value: item.categoryId,
  }))

  return (
    <$WhiteContainer>
      <LoaderPopup isPopOpen={screenLoading} />
      <Navbar heading="Store Management - Brand" />
      <TopActionBar
        title={`${act} Brand Data`}
        onExit={() => history.push('/storeManagement?tab=Brands')}
        extras={
          <FormExtras
            isView={isView}
            isCreate={isCreate}
            isEdit={isEdit}
            onUpdate={onUpdate}
            onSave={onSave}
            onEdit={() =>
              history.push(`/storeManagement/editBrand/${params?.id}`)
            }
          />
        }
      />
      <Grid
        columns="auto auto auto"
        columnGap={spacing.xl}
        p={spacing.xl}
        pt={spacing.l}
      >
        <Div
          style={{
            boxShadow:
              'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
          }}
          borderRadius="4px"
          overflow="hidden"
        >
          <$AddBrandLogo>
            {form?.brandLogo && (
              <img
                src={
                  form?.brandLogo?.length
                    ? form?.brandLogo
                    : URL.createObjectURL(form?.brandLogo)
                }
              />
            )}
            <input
              title="Brand Logo"
              disabled={isView}
              type="file"
              accept="image/*"
              onChange={(e) => handleChange('brandLogo', e.target.files[0])}
            />

            {isEdit && (
              <div>
                <CameraFilledIcon />
                {form?.brandLogo ? 'Change ' : 'Add '} Brand Logo
                <input
                  title="Brand Logo"
                  disabled={isView}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleChange('brandLogo', e.target.files[0])}
                />
              </div>
            )}
          </$AddBrandLogo>
        </Div>
        <Div>
          <Input
            required
            disabled={isView}
            containerMargin="0"
            variant="input"
            value={form?.brandName || ''}
            onChange={(e) => handleChange('brandName', e.target.value)}
            inputWidth="400px"
            id="brandName"
            label="Brand Name"
            placeholder="Fantastic Foods"
          />
          <Input
            disabled={isView}
            containerMargin="0"
            variant="textarea"
            value={form?.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            inputWidth="400px"
            rows="5"
            id="description"
            label="Brand Description"
            placeholder="Restuarant And Bar"
          />
        </Div>
        <Div>
          <Input
            required
            disabled={isView}
            variant="single-select"
            label="Brand Category"
            placeholder="Select Brand Category"
            options={brandCategoryOptions}
            value={GET_SEL_VALUE(form?.categoryId, brandCategoryOptions) || ''}
            onChange={(val) => handleChange('categoryId', val?.value)}
            inputWidth="400px"
            maxInputHeight="100px"
            inputOverflow="auto"
          />
        </Div>
      </Grid>
    </$WhiteContainer>
  )
}
