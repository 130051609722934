import React, { Component } from 'react'
import 'css/content/about.scss'

class AboutUs extends Component {
  render() {
    return (
      <div className="aboutUs shadow p-3 mb-5 bg-white rounded">
        <div className="aboutHeading">About</div>
        <div>
          BillMe is the the worlds first Digital Billing ecosystem which helps
          consumers collect all thier bills in one place
        </div>
        <div>
          We do this by providing vendors with a desktop application which works
          with all types of billing systems in the world, it helps vendors send
          digital bills with their exsisitn billing system.
        </div>
        <hr />
        <div className="aboutHeading">Products</div>
        <div>
          BillMe desktop application – Installed on the vendors PC. It
          automatically converts regualr bills into digital bills in the excat
          same format.
        </div>
        <div>
          Partner Analytics- Helps vendors collect Valuable business analytics
          accesable through billmepartner.com
        </div>
        <hr />
        <div className="aboutHeading">Pricing</div>
        <div>
          Each bill processed through the billme desktop apllication counts as 1
          transaction.
        </div>
        <div>Each transaction is priced at 0.15 paisa</div>
        <div>Partner Analytics is incuded in this per transaction cost.</div>
        <div>
          Extra Promotional Messages sent to phones number is also treated as a
          transaction and is priced at 0.15 paisa each.
        </div>
        <div>
          All transactions are prepaid, and the recharge can be done through the
          “A/c Balance” section at BillMepartner.com
        </div>
      </div>
    )
  }
}

export default AboutUs
