import React from 'react'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

import { TableHead, TableRow, TableCell, Table } from 'styledComponent'
import { spacing } from 'design-system'
import { Skeleton } from 'components/Common/Skeleton'
import { Text } from 'components/Common/Text'
import { NoDataFound } from 'components/Common/EmptyState'
import { useSelectStore } from 'hooks'
import { $FlexBox, $Block } from '../UserComplaints.styled'
import {
  $Modal,
  $ModalContent,
  $ModalOverlay,
  $ModalHeader,
} from './ComplaintsList.styled'
import TableActions from './TableActions'
import { NYB_TYPES, TABLE_COLS } from './constants'
import { SectionHeading } from 'components/Common/SectionHeading'
import { ReactComponent as CloseIcon } from 'images/close-cross.svg'

const ComplaintsTable = ({
  complaints,
  page,
  loading,
  pageLimit,
  onPageChange,
}) => {
  const [showBill, setShowBill] = React.useState(false)
  const [billUrl, setBillUrl] = React.useState(null)

  const {
    data: { stores },
  } = useSelectStore('stores')

  const _getStoreAddress = (storeId) => {
    return stores[storeId]?.displayAddress || ''
  }

  const handleBillView = (url) => {
    if (showBill) {
      setBillUrl(null)
    }

    if (url) {
      setBillUrl(url)
    }
    setShowBill((state) => !state)
  }

  return (
    <>
      <$Block margin={`${spacing.l} 0 0`} position="relative" minHeight="560px">
        {!loading && !complaints?.docs?.length ? (
          <NoDataFound />
        ) : (
          <>
            <Table margin="0px">
              <TableHead>
                <TableRow fontSize="14px">
                  {TABLE_COLS.map(({ label }, index) => (
                    <TableCell key={index}>{label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <tbody>
                  {Array(10)
                    .fill()
                    .map((_, index) => (
                      <TableRow key={index} fontSize="12px">
                        {TABLE_COLS.map((_, index) => (
                          <TableCell key={index}>
                            <Skeleton count={1} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </tbody>
              ) : null}
              {!loading && (
                <tbody>
                  {complaints?.docs?.map((complaint) => (
                    <TableRow key={complaint._id} fontSize="12px">
                      {TABLE_COLS.map(({ value }, index) => (
                        <TableCell key={index}>
                          {value === 'action' ? (
                            <TableActions
                              billUid={complaint.billUid}
                              deleted={complaint.isBillDeleted}
                              viewAction={handleBillView}
                            />
                          ) : value === 'createdDate' ? (
                            moment(complaint[value]).format('MM-DD-YYYY')
                          ) : value === 'storeId' ? (
                            _getStoreAddress(complaint[value])
                          ) : value === 'type' ? (
                            `Not Your Bill - ${
                              NYB_TYPES[complaint[value]] || ''
                            }`
                          ) : (
                            complaint[value] || ''
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </tbody>
              )}
            </Table>
            {complaints?.totalDocs > 10 ? (
              <$FlexBox
                justify="space-between"
                align="center"
                m={`${spacing.l} 0`}
              >
                <Text variant="small" type="grey">
                  Showing {page + 1} -{' '}
                  {Math.ceil(complaints?.totalDocs / pageLimit)} pages
                </Text>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  pageCount={Math.ceil(complaints?.totalDocs / pageLimit)}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={2}
                  onPageChange={onPageChange}
                  containerClassName={'feedback-pagination notSelectableText'}
                  activeClassName={'pagination_activePage'}
                  forcePage={page}
                />
              </$FlexBox>
            ) : null}
          </>
        )}
      </$Block>
      {showBill && (
        <$Modal>
          <$ModalOverlay onClick={handleBillView} />
          <$ModalContent>
            <$ModalHeader>
              <SectionHeading
                heading="Bill View"
                variant="p"
                color="main"
                weight="bold"
              />
              <CloseIcon onClick={handleBillView} />
            </$ModalHeader>
            <iframe src={billUrl} width="100%" height="100%" frameborder="0" />
          </$ModalContent>
        </$Modal>
      )}
    </>
  )
}

export default ComplaintsTable
