import React, { useEffect, useState } from 'react'

import { Text, Button, useToasts } from 'components/Common'
import { useTheme } from 'styled-components'
import { useSelectStore } from 'hooks'
import {
  postSurveyButton,
  getSurveyButtons,
  deleteSurveyButton,
} from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../constants'
import { useDispatch } from 'react-redux'
import { Div, FlexBox } from 'styledComponent'
import { AddBlueFilledIcon, TimesBoxIcon } from 'assets/icons'
import CancelPopup from '../components/CancelPopup'
import { $Delete } from '../AutoEngagement.styled'
import { TopActionBar } from '../components/TopActionBar'
import { SIBButtonUpload } from './SIBButtonUpload'

export const SIBBuilder = ({ form, setForm }) => {
  const { spacing } = useTheme()
  const { toast } = useToasts()
  const dispatch = useDispatch()

  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const {
    data: { surveyButtonsData },
  } = useSelectStore('autoEngage')
  const vendorId = selectedVendor.value

  const [media, setMedia] = useState({})
  const [showBuilder, setShowBuilder] = useState(false)
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [buttonId, setButtonId] = useState('')

  const getButtons = () => {
    dispatch(
      getSurveyButtons({
        vendorId,
        listAll: true,
      })
    )
  }

  useEffect(() => {
    if (!(surveyButtonsData && surveyButtonsData?.length > 0)) getButtons()
  }, [])

  const setSurveyButton = (surveyButtonId) => {
    setForm({
      ...form,
      content: {
        body: {
          ...form?.content?.body,
          surveyButtonId,
        },
      },
    })
  }

  const postSIBButton = async () => {
    try {
      const res = await postSurveyButton({
        vendorId,
        button: media?.fileLocation,
      })
      if (res) {
        toast('Button Saved', 'success')
        setShowBuilder(false)
        getButtons()
      } else {
        throw res
      }
    } catch (err) {
      toast(ERROR_DECODE(err))
    }
  }

  const deleteClick = (bId, e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsPopOpen(true)
    setButtonId(bId)
  }

  const onOkClick = async () => {
    try {
      const res = await deleteSurveyButton(buttonId)
      if (res) {
        toast('Button Deleted Successfully', 'success')
        getButtons()
      } else {
        throw res
      }
    } catch (err) {
      toast(ERROR_DECODE(err))
    }
  }

  return (
    <Div>
      <CancelPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        title="Are you sure you want to delete Survey Button?"
        onOkClick={onOkClick}
      />
      {showBuilder ? (
        <Div pb={spacing.l}>
          <TopActionBar
            title="Create Survey Button"
            exitTitle="Go Back"
            onExit={() => setShowBuilder(false)}
            extras={
              <Button
                disabled={!media?.fileLocation}
                mr="-16px"
                variant="primary"
                onClick={postSIBButton}
              >
                Save Button
              </Button>
            }
          />
        </Div>
      ) : (
        <Text variant="h3" type="main" weight="bold">
          Survey Button Builder
        </Text>
      )}
      {showBuilder ? (
        <SIBButtonUpload media={media} setMedia={setMedia} />
      ) : (
        <Div>
          <Button
            noBorder
            variant="textualPrimary"
            startIcon={AddBlueFilledIcon}
            onClick={() => setShowBuilder(true)}
            fontSize="20px"
            iconSize="25px"
            width="100%"
          >
            Create From Scratch
          </Button>
          <hr />
          {surveyButtonsData?.length > 0 && (
            <Text variant="medium" type="main" weight="bold">
              Choose an existing button for the campaign
            </Text>
          )}
          <FlexBox mt={spacing.mxl} wrap="wrap" gap="4%" align="center">
            {surveyButtonsData?.map((sbd) => (
              <Div
                type="button"
                mb={spacing.l}
                width="48%"
                key={sbd._id}
                onClick={() => setSurveyButton(sbd._id)}
              >
                <FlexBox align="center" gap="2%">
                  <input
                    readOnly
                    checked={sbd._id === form?.content?.body?.surveyButtonId}
                    style={{ width: '20px', height: '20px' }}
                    type="radio"
                  />
                  <Div
                    border="1px dashed lightgray"
                    position="relative"
                    borderRadius="4px"
                    p={spacing.s}
                    width="385px"
                    height="130px"
                    display="flex"
                  >
                    <img
                      src={sbd?.button}
                      style={{
                        maxWidth: '375px',
                        maxHeight: '120px',
                        objectFit: 'contain',
                        margin: 'auto',
                      }}
                    />
                    <$Delete onClick={(e) => deleteClick(sbd._id, e)}>
                      <TimesBoxIcon />
                    </$Delete>
                  </Div>
                </FlexBox>
              </Div>
            ))}
          </FlexBox>
        </Div>
      )}
    </Div>
  )
}
