import React, { useState, useEffect } from 'react'
import { FlexBox, Div } from 'styledComponent'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { useSelectStore } from 'hooks'
import { spacing } from 'design-system'
import { Text } from 'components/Common'
import { PublishItem } from '../components/PublishItem'
import { $BlueBox } from '../AutoEngagement.styled'
import {
  CHECK_IF,
  GET_SEL_VALUE,
  GET_SEND_TIME,
  WHEN_TYPES,
  WHOM_TYPES,
  CAMPAIGN_STATS,
  EMAIL_PREVIEW,
  USER_ATTRIBUTE_TYPES,
} from '../constants'
import {
  getAssetsList,
  getEmailProviders,
  getSegments,
} from 'redux/actions/autoEngageActions'
import { CampaignStats } from '../components/CampaignPreview'
import CampaignErrors from '../components/CampaignErrors'

export const EmailPreviewnPublish = ({ form }) => {
  const [segmentName, setSegmentName] = useState('')

  const dispatch = useDispatch()
  const {
    data: { segmentData, assetsCsvList, emailProvidersList },
  } = useSelectStore('autoEngage')

  useEffect(() => {
    if (emailProvidersList?.length === 0 && form.vendorId) {
      dispatch(getEmailProviders(form.vendorId))
    }
  }, [])

  useEffect(() => {
    const vendorId = form.vendorId
    if (form.targetGroup?.type) {
      if (form.targetGroup?.type === 'segments') {
        dispatch(getSegments({ vendorId }))
      } else {
        dispatch(
          getAssetsList({
            vendorId,
            listAll: true,
            fileType: 'csv',
          })
        )
      }
    }
  }, [form.targetGroup?.type])

  useEffect(() => {
    if (form.targetGroup?.type) {
      if (form.targetGroup?.type === 'segments') {
        const segName =
          segmentData?.filter((seg) => seg._id === form.targetGroup?.id)[0]
            ?.name || '-'
        setSegmentName(segName)
      } else {
        const segName =
          assetsCsvList?.docs?.filter(
            (asset) => asset._id === form.targetGroup?.id
          )[0]?.name || '-'
        setSegmentName(segName)
      }
    }
  }, [form.targetGroup?.type, segmentData, assetsCsvList])

  const getSenderName = () => {
    if (form?.senderDetails?.name) return form?.senderDetails?.name
    else if (form?.senderDetails?.id) {
      const senderName =
        emailProvidersList?.filter(
          (sender) => sender._id === form?.senderDetails?.id
        )[0]?.name || '-'
      return senderName
    }
  }

  const getValue = (value) => {
    switch (value) {
      case 'linkVisits': {
        const linkVisits = Object.values(form?.stats?.[value]).reduce(
          (acc, curr) => {
            acc += curr
            return acc
          },
          0
        )
        return linkVisits
      }

      case 'totalCost': {
        return form?.stats?.[value]?.toFixed(2)
      }

      default: {
        return form?.stats?.[value]
      }
    }
  }

  return (
    <FlexBox gap="4%">
      <Div width="48%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          View Campaign
        </Text>
        <CampaignStats form={form} campaignType="Email" />
        <PublishItem
          width="100%"
          header="Campaign Details"
          completed={
            !Boolean(CHECK_IF(['name', 'vendorId', 'companyId'], form))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Campaign Name :
            </Text>
            <Text variant="small" type="main">
              {form?.name || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="0 0 5px">
            <Text variant="small" type="grey">
              Created Date :
            </Text>
            <Text variant="small" type="main">
              {moment(form?.createdDate).format('DD MMM YYYY')}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Campaign Description :
            </Text>
            <Text variant="small" type="main">
              {form?.description || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="5px 0">
            <Text variant="small" type="grey">
              Sender Name :
            </Text>
            <Text variant="small" type="main">
              {getSenderName()}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Whom To Send"
          completed={
            !Boolean(CHECK_IF(['type', 'id'], { ...form?.targetGroup }))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form.targetGroup?.type, WHOM_TYPES(true), 'label')
                ?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Segment Name :
            </Text>
            <Text variant="small" type="main">
              {segmentName}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="When To Send"
          completed={
            Boolean(form?.validity) &&
            !(
              form?.validity !== 'immediate' &&
              Boolean(CHECK_IF(['startDate', 'scheduledTime'], form))
            )
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.validity, WHEN_TYPES, 'label')?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              When :
            </Text>
            <Text variant="small" type="main">
              <div
                dangerouslySetInnerHTML={{ __html: GET_SEND_TIME(form) || '-' }}
              />
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Embedded Components"
          completed={Boolean(form?.content?.body?.variables)}
        >
          {getEmbeddedDetails(form?.content?.body?.variables)}
        </PublishItem>
        {form?.other?.errors && form.status !== 'draft' ? (
          <>
            <hr />
            <CampaignErrors errors={form?.other?.errors} />
          </>
        ) : null}
      </Div>
      <Div width="48%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          Preview Pane
        </Text>
        <$BlueBox>
          <p>
            Subject : <b>{form?.content?.subject || '-'}</b>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: EMAIL_PREVIEW(
                form?.content?.body?.html || '-',
                form?.content?.body?.variables
              ),
            }}
          />
        </$BlueBox>
      </Div>
    </FlexBox>
  )
}

export const getEmbeddedDetails = (variables = {}) => {
  const survey = {
    label: 'Surveys',
    items: [],
  }
  const coupon = {
    label: 'Coupons',
    items: [],
  }
  const asset = {
    label: 'Assets',
    items: [],
  }
  const attribute = {
    label: 'Attributes',
    items: [],
  }
  const custom = {
    label: 'Custom',
    items: [],
  }

  const embedArray = []

  for (let i = 0; i < Object.keys(variables).length; i++) {
    const currentVariable = Object.keys(variables)[i]
    if (currentVariable.includes('survey[')) {
      survey.items.push(
        <Text variant="small" type="main" key={currentVariable}>
          {variables[currentVariable]?.name || '-'}
        </Text>
      )
    }
    if (currentVariable.includes('coupon[')) {
      coupon.items.push(
        <Text variant="small" type="main" key={currentVariable}>
          {`${variables[currentVariable]?.name}(${variables[currentVariable]?.prefix}-00000)`}
        </Text>
      )
    }
    if (currentVariable.includes('asset[')) {
      asset.items.push(
        <Text
          variant="small"
          type="main"
          key={currentVariable}
          width="300px"
          ellipsis
          title={variables[currentVariable]?.fileLocation || '-'}
        >
          <a
            target="_blank"
            href={variables[currentVariable]?.fileLocation || '-'}
          >
            {variables[currentVariable]?.fileLocation || '-'}
          </a>
        </Text>
      )
    }
    if (currentVariable.includes('attribute[')) {
      attribute.items.push(
        <Text variant="small" type="main" key={currentVariable}>
          {`${
            GET_SEL_VALUE(variables[currentVariable][0], USER_ATTRIBUTE_TYPES)
              ?.label
          } (${variables[currentVariable][1] || '-'})`}
        </Text>
      )
    }
    if (
      currentVariable.includes('custom[') ||
      currentVariable.includes('common[')
    ) {
      console.log('variables[currentVariable]', variables[currentVariable])
      custom.items.push(
        <Text variant="small" type="main" key={currentVariable}>
          {variables[currentVariable]?.custom ||
            variables[currentVariable] ||
            '-'}
        </Text>
      )
    }
  }

  ;[survey, coupon, asset, attribute, custom].forEach((item) => {
    if (item.items.length > 0) {
      embedArray.push(item)
    }
  })

  return (
    <Div>
      {embedArray?.map((item) => (
        <Div padding="5px 0">
          <Text variant="medium" type="main" weight="bold">
            {item?.label}
          </Text>
          {item?.items?.map((it) => it)}
        </Div>
      ))}
    </Div>
  )
}
