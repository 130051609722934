//Get States
export const setFilters = (params) => (dispatch) => {
  dispatch(setFiltersHelper(params))
}

export const setRememberFilters = (params) => (dispatch) => {
  dispatch(setRememberFiltersHelper(params))
}

export const resetSizeFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_SIZE_FILTER',
    payload: '',
  })
}

export const resetLocationFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_LOCATION_FILTER',
    payload: '',
  })
}

export const setSelectedBrandsFilter = (params) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_BRANDS_FILTER',
    payload: params,
  })
}

export const resetSelectedBrandsFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_SELECTED_BRANDS_FILTER',
    payload: '',
  })
}

export const setSelectedCompaniesFilter = (params) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_COMPANIES_FILTER',
    payload: params,
  })
}

export const resetSelectedCompaniesFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_SELECTED_COMPANIES_FILTER',
    payload: '',
  })
}

export const setSelectedStoresFilter = (params) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_STORES_FILTER',
    payload: params,
  })
}

export const setInactiveSelectedStoresFilter =
  (payload = []) =>
  (dispatch) => {
    dispatch({
      type: 'SET_INACTIVE_SELECTED_STORES_FILTER',
      payload,
    })
  }

export const resetSelectedStoresFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_SELECTED_STORES_FILTER',
    payload: '',
  })
}

export const resetNoOfEmployeeFilter = () => (dispatch) => {
  dispatch({
    type: 'RESET_NO_OF_EMPLOYEE_FILTER',
    payload: '',
  })
}

export const resetStoreFilters = () => (dispatch) => {
  dispatch({
    type: 'RESET_STORE_FILTERS',
    payload: '',
  })
}

export const setPreviousSidebarLink = (link) => (dispatch) => {
  dispatch({
    type: 'SET_STORE_SIDEBAR_LINK',
    payload: link,
  })
}

const setFiltersHelper = (data) => {
  return {
    type: 'SET_STORE_FILTERS',
    payload: data,
  }
}

const setRememberFiltersHelper = (data) => {
  return {
    type: 'SET_REMEMBER_FILTERS',
    payload: data,
  }
}
