import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import AdvertiseCarousel from './AdvertiseCarousel'
import { filterStores, filterBrand } from 'redux/actions/authActions'

const CustomOption = ({ innerProps, isDisabled, data }) => {
  const backgroundColor = data.isCampaign !== undefined ? '#5fc843' : '#707070'
  const addsStatus = data.isCampaign !== undefined ? 'View' : 'No Ad'
  return !isDisabled ? (
    <div className="selector_main" {...innerProps}>
      <div className="selector_icon">
        <img className="logo" src={data.brandObj.brandLogo} alt="Logo" />
      </div>
      <div className="selector_option">
        <h5 className="selector_name">{data.label}</h5>
      </div>
      <div className="active">
        <div
          className="active_ads"
          style={{ backgroundColor: backgroundColor }}
        >
          <span
            style={
              data.isCampaign !== undefined
                ? { marginLeft: '5px' }
                : { marginLeft: '2px' }
            }
          >
            {addsStatus}
          </span>
        </div>
      </div>
    </div>
  ) : null
}

const storeModify = (storeList, brands, activeCampaign) => {
  let result = []
  for (let storeId in storeList) {
    const obj = {
      label: storeList[storeId].displayAddress,
      value: storeList[storeId].storeId,
      brandId: storeList[storeId].brandId,
      brandObj: brands.find(
        (brand) => brand.brandId === storeList[storeId].brandId
      ),
      isCampaign: activeCampaign.find(
        (campaign) => campaign.storeId === storeList[storeId].storeId
      ),
    }
    if (obj.brandObj !== undefined) result.push(obj)
  }
  return result
}

const filterStoresWithBrand = (storeDetails, brandId) => {
  return storeDetails.filter((store) => store.brandId === brandId)
}

class LiveAdvertisement extends Component {
  constructor() {
    super()
    this.state = {
      activeCampaign: [],
      slides: 0,
      storeDetails: [],
      selectedBrand: {},
      selectedOption: null,
      noCampaign: true,
      brandId: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.onDeactivate = this.onDeactivate.bind(this)
  }

  componentDidMount() {
    if (this.props.selectedBrand.length)
      this.setState({ selectedBrand: this.props.selectedBrand[0].value })
  }

  async componentWillReceiveProps(props) {
    this.setState({
      activeCampaign: this.props.activeCampaign,
      selectedOption: null,
    })

    let formatedStores = storeModify(
      this.props.stores,
      this.props.brands,
      this.props.activeCampaign
    )

    if (props.filterWithBrand && this.props.selectedBrand.length) {
      const brandId = this.props.selectedBrand[0].value
      let storeDetails = filterStoresWithBrand(formatedStores, brandId)
      this.setState({ storeDetails, selectedBrand: brandId })
    } else this.setState({ storeDetails: formatedStores })
  }

  /* async handleChangeBrand(brandId) {
    this.setState({ selectedBrand: brandId, selectedOption: null });
    let storeDetails = storeModify(
      this.props.stores,
      this.props.brands,
      this.props.activeCampaign
    );

    if (brandId === "All Brands") {
      this.setState({
        storeDetails,
        activeCampaign: this.props.activeCampaign
      });
    } else {
      const filterStores = await filterStoresWithBrand(storeDetails, brandId);
      let activeCampaign = [];
      filterStores.filter(store => {
        if (store.isCampaign !== undefined)
          activeCampaign.push(store.isCampaign);
      });

      this.setState({
        storeDetails: filterStores,
        activeCampaign
      });
    }
  } */

  handleChange(selectedOption) {
    let activeCampaign = []
    const { brandId } = selectedOption.brandObj
    const { isCampaign } = selectedOption
    this.setState({ selectedOption, selectedBrand: brandId })
    if (isCampaign !== undefined) {
      activeCampaign.push(isCampaign)
      this.setState({ activeCampaign })
    } else this.setState({ activeCampaign })
  }

  onDeactivate() {
    if (this.state.activeCampaign.length) {
      let { campaignId } = this.state.activeCampaign[this.state.slides]
      this.props.handleCampaignDeactivate(campaignId)
    }
  }

  render() {
    return (
      <div className="liveBox">
        {this.state.activeCampaign.length > 0 && (
          <div className="liveboxHeading">
            <div className="liveInfo">
              <div className="greenDot animated infinite fadeIn delay-2s">
                &#x25CF;
              </div>
              <div className="liveboxHeadingText">Live Advertisement</div>
            </div>
            {this.props.showDeactivate ? (
              <div className="deactiveButtonBox">
                <button
                  className="deactivateButton"
                  onClick={this.onDeactivate}
                >
                  Deactivate
                </button>
              </div>
            ) : null}
          </div>
        )}

        <AdvertiseCarousel
          changesSlides={(slides) => {
            this.setState({ slides })
          }}
          activeCampaign={this.state.activeCampaign}
          brandId={this.state.selectedBrand}
        />

        {/* <div className="storeSelect">
          <div className="heading">Current Store:</div>
          <div className="store_select">
            <Select
              autosize={true}
              value={this.state.selectedOption}
              onChange={this.handleChange}
              options={this.state.storeDetails}
              placeholder="View all advertisements from your stores"
              required={true}
              components={{ Option: CustomOption }}
              isSearchable
            />
          </div>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    brands: filterBrand(state, 'advert'),
    selectedStores: state.stores.selectedStores,
    stores: filterStores(state, 'advert'),
    selectedBrand: state.advert.selectedBrandForAdvert,
  }
}

export default connect(mapStateToProps)(LiveAdvertisement)
