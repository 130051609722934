import React, { useState, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import Select from 'react-select'
import ReactPaginate from 'react-paginate'

import { spacing, borderRadius } from 'design-system/styles'
import {
  Avatar,
  Text,
  StarRating,
  SectionHeading,
  BarChart,
  ActivityCard,
  Button,
  Skeleton,
  useToasts,
} from 'components/Common'
import { Female, Male } from 'assets/icons'
import { FlexBox, Box, Table } from 'styledComponent'
import Drawer from 'design-system/components/drawer'
import { useRouter, useSelectStore } from 'hooks'
import AvatarIcon from 'assets/icons/avatar.jpeg'
import { post } from 'utils/axiosHandler'
import {
  TransactionInfo,
  PersonalInfo,
  DeviceInfo,
  WorkInfo,
  StyledInput,
  StyledContainer,
  StyledContainerRows,
  $TableCell,
} from './ConsumerProfileComponents'
import {
  formatConsumerProfileData as formatProfileData,
  getSentimentFace,
} from 'utils/consumerAnalytics'
import { PersonalInfoKey, ACTIVITY_TYPE } from './constants'
import {
  $TableRow,
  $RecentActivityPopupContainer,
  $RecentActivityPopupFooter,
  $RecentActivityPopupHeader,
} from './Customers.styled'
import CustomPopup from 'components/Content/CustomPopup'

const ConsumerProfile = () => {
  const [loading, setLoading] = useState(true)
  const [isEditProfile, setIsEditProfile] = useState(false)
  const [editProfile, setEditProfile] = useState({})
  const [profile, setProfile] = useState()
  const [indexOfFirstRA, setIndexOfFirstRA] = useState(0)
  const [indexOfLastRA, setIndexOfLastRA] = useState(5)
  const { query, history } = useRouter()
  const theme = useTheme()
  const activityPopupRef = useRef()

  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const { data: stores } = useSelectStore('stores.stores')

  const { toast } = useToasts()

  const { id } = query

  React.useEffect(() => {
    fetchProfile()
  }, [id])

  const fetchProfile = async () => {
    if (id) {
      setLoading(true)
      const storeIds = Object.keys(stores)

      try {
        const res = await post('/cAnalytics/customer/profile', {
          vendorId: selectedVendor.value,
          phone: id,
          storeIds,
        })
        setProfile(formatProfileData(res))
        setEditProfile(formatProfileData(res)?.personal || {})
      } catch (e) {
        console.log('Error:-', e)
      } finally {
        setLoading(false)
      }
    }
  }

  const onSubmit = async () => {
    const formBody = {}

    Object.keys(editProfile).map((key) => {
      formBody[key] = editProfile?.[key]
    })

    try {
      const res = await post(`/crm/customer`, {
        vendorId: selectedVendor.value,
        _id: profile?.personal?._id,
        source: 'merchant-upload',
        ...profile.personal,
        ...formBody,
      })

      if (res) {
        toast('Profile Updated successfully!', 'success')
        fetchProfile()
      }
    } catch (e) {
      toast('Error in updating profile, please try again', 'error')
    }
  }

  const panelClose = () => {
    history.goBack()
    setProfile()
    setLoading(true)
    setEditProfile({})
    setIsEditProfile(false)
  }

  const rowsPerPage = 5

  const handlePageChange = (data = 0) => {
    const indexOfLastRA = (data.selected + 1) * rowsPerPage
    const indexOfFirstRA = indexOfLastRA - rowsPerPage
    setIndexOfFirstRA(indexOfFirstRA)
    setIndexOfLastRA(indexOfLastRA)
  }

  const handleInputChange = (data, field) => {
    setEditProfile({
      ...editProfile,
      [field]: data,
    })
  }

  const onRowClick = (e, type) => {
    if (type === ACTIVITY_TYPE.transaction) {
      activityPopupRef.current.openModal()
    }
  }

  const pageCount = profile?.activity
    ? profile?.activity.length / rowsPerPage
    : 0

  return (
    <Drawer bgColor={theme.colors.white} open={!!id} onClose={panelClose}>
      <CustomPopup
        className={'filter-modal'}
        heading={
          <$RecentActivityPopupHeader justify="space-between" align="center">
            <p>Activity</p>
            <Button
              type="button"
              variant="textualError"
              onClick={() => activityPopupRef.current.closeModal()}
            >
              Close
            </Button>
          </$RecentActivityPopupHeader>
        }
        headerComponent
        footer={<></>}
        ref={activityPopupRef}
      >
        <$RecentActivityPopupContainer>
          <iframe src={`https://yourbill.me/3WV8j4p3gky3rLU9FYv2tr`} />
        </$RecentActivityPopupContainer>
      </CustomPopup>
      <StyledConsumerProfile>
        <Header
          profile={profile?.personal}
          transactionInfo={profile?.transactionInfo}
          isEditProfile={isEditProfile}
          setIsEditProfile={setIsEditProfile}
          editProfile={editProfile}
          handleInputChange={handleInputChange}
          handleSubmit={onSubmit}
          loading={loading}
        />
        <StyledDivider horizontal />
        <FlexBox>
          <StyledContainer>
            <TransactionInfo
              transactionInfo={profile?.transactionInfo}
              // topProduct={profile?.custItems}
              loading={loading}
            />
            <PersonalInfo
              personalInfo={profile?.personal}
              isEditProfile={isEditProfile}
              editProfile={editProfile}
              handleInputChange={handleInputChange}
              loading={loading}
            />
            <DeviceInfo
              deviceInfo={profile?.personal}
              isEditProfile={isEditProfile}
              editProfile={editProfile}
              handleInputChange={handleInputChange}
              loading={loading}
            />
            <WorkInfo
              workInfo={profile?.personal}
              isEditProfile={isEditProfile}
              editProfile={editProfile}
              handleInputChange={handleInputChange}
              loading={loading}
            />
          </StyledContainer>
          <StyledContainer>
            <Box
              flex
              direction="column"
              margin={`${spacing.m} ${spacing.xl}`}
              padding={spacing.l}
              borderRadius={borderRadius.default}
              border="1px solid #EBEBEB"
            >
              <Text>DAYS VISITED (%)</Text>
              {loading ? (
                <Skeleton width={550} height={150} count={1} />
              ) : (
                <BarChart
                  data={{ data: profile?.daysVisited }}
                  width="100%"
                  height={150}
                  showYLabels={false}
                  showGrid={false}
                  showDataLabels={false}
                  toolTipTitle="No. of Visits"
                />
              )}
            </Box>
            <Box
              flex
              direction="column"
              margin={`${spacing.m} ${spacing.xl}`}
              padding={spacing.l}
              borderRadius={borderRadius.default}
              border="1px solid #EBEBEB"
            >
              <SectionHeading variant="p" heading="RECENT ACTIVITY" />
              {loading ? (
                <Skeleton width={550} height={350} count={1} />
              ) : (
                <>
                  <Table>
                    <tbody>
                      {profile?.activity
                        ?.slice(indexOfFirstRA, indexOfLastRA)
                        .map(({ visitDate }) => (
                          <$TableRow
                            key={visitDate}
                            // highlightOnHover={theme.colors.solitude}
                            // onClick={(e) =>
                            //   onRowClick(
                            //     e,
                            //     visitDate ? ACTIVITY_TYPE.transaction : null,
                            //     'shortId123123123'
                            //   )
                            // }
                          >
                            <$TableCell>
                              <ActivityCard
                                type="visitDate"
                                value={visitDate}
                              />
                            </$TableCell>
                          </$TableRow>
                        ))}
                    </tbody>
                  </Table>
                  <FlexBox justify="space-between" align="center">
                    <Text variant="small" type="grey">
                      Showing {indexOfLastRA} - {profile?.activity?.length}{' '}
                      activities
                    </Text>
                    <ReactPaginate
                      previousLabel={'< Previous'}
                      nextLabel={'Next >'}
                      breakLabel={'...'}
                      pageCount={pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={2}
                      onPageChange={(data) => handlePageChange(data)}
                      containerClassName={
                        'feedback-pagination notSelectableText'
                      }
                      activeClassName={'pagination_activePage'}
                    />
                  </FlexBox>
                </>
              )}
            </Box>
          </StyledContainer>
        </FlexBox>
      </StyledConsumerProfile>
    </Drawer>
  )
}

const Header = ({
  profile,
  transactionInfo,
  isEditProfile,
  setIsEditProfile,
  editProfile,
  handleInputChange,
  handleSubmit,
  loading,
}) => {
  const selectstyle = {
    control: (base) => ({
      ...base,
      width: 150,
      border: 'none',
      borderRadius: 'unset',
      borderBottom: '1px solid hsl( 0, 0%, 85%)',
      marginLeft: `${spacing.m}`,
      marginBottom: `${spacing.s}`,
    }),
  }

  const IndicatorSeparator = () => null

  const dataCollectedPercentage = () => {
    let collectedKeys = 0
    PersonalInfoKey.forEach((key) => {
      if (profile && profile[key]) collectedKeys += 1
    })

    return Math.round(
      (collectedKeys * 100) / Object.keys(PersonalInfoKey).length
    )
  }

  return (
    <FlexBox
      height="75px"
      justify="space-between"
      align="center"
      padding={`0 0 ${spacing.l} 0`}
      margin={`0 ${spacing.xl}`}
    >
      {loading ? (
        <Skeleton width={575} height={50} count={1} />
      ) : (
        <>
          <FlexBox height="100%" align="center">
            <Avatar src={AvatarIcon} size="64" />
            <FlexBox
              height="100%"
              align="center"
              direction="column"
              margin={`0 ${spacing.l}`}
            >
              <FlexBox
                expand
                height="50%"
                justify="space-between"
                align="center"
              >
                {isEditProfile ? (
                  <>
                    <StyledInput
                      type="text"
                      name="firstName"
                      value={editProfile?.firstName || profile?.firstName}
                      onChange={(e) =>
                        handleInputChange(e.target.value, 'firstName')
                      }
                      placeholder="Firstname"
                    />
                    <StyledInput
                      type="text"
                      name="lastName"
                      value={editProfile?.lastName || profile?.lastName}
                      onChange={(e) =>
                        handleInputChange(e.target.value, 'lastName')
                      }
                      placeholder="Lastname"
                    />
                  </>
                ) : (
                  <Text variant="h3" weight="semi-bold">
                    {!profile?.firstName && !profile?.lastName
                      ? '-'
                      : profile?.firstName + ' ' + profile?.lastName}
                  </Text>
                )}
                <StyledDivider />
                <StyledSVG square="13px">
                  {profile?.gender === 'NA' ? (
                    ''
                  ) : profile?.gender === 'female' ||
                    editProfile.gender === 'female' ? (
                    <Female />
                  ) : (
                    <Male />
                  )}
                </StyledSVG>
                {isEditProfile ? (
                  <Select
                    options={[
                      { value: 'male', label: 'Male' },
                      { value: 'female', label: 'Female' },
                      { value: 'other', label: 'Other' },
                    ]}
                    onChange={(e) => handleInputChange(e.value, 'gender')}
                    name="gender"
                    components={{ IndicatorSeparator }}
                    styles={selectstyle}
                    placeholder="Gender"
                    defaultValue={
                      profile?.gender && {
                        value: profile.gender,
                        label:
                          profile.gender.charAt(0).toUpperCase() +
                          profile.gender.slice(1),
                      }
                    }
                  />
                ) : (
                  <Text variant="p">{profile?.gender || '-'}</Text>
                )}
              </FlexBox>
              <FlexBox
                expand
                height="50%"
                justify="space-between"
                align="center"
              >
                <FlexBox expand align="center" padding={`0 ${spacing.m} 0 0`}>
                  <Text variant="tiny">Avg. Rating</Text>
                  {transactionInfo?.avgFeedback && (
                    <StarRating
                      score={Math.ceil(transactionInfo?.avgFeedback)}
                    />
                  )}
                  <Text variant="small">({transactionInfo?.avgFeedback})</Text>
                </FlexBox>
                <StyledSVG square="17px">
                  {getSentimentFace(transactionInfo?.avgFeedback)}
                </StyledSVG>
              </FlexBox>
            </FlexBox>
            <StyledDivider />
            {!isEditProfile && (
              <DataCollected percentage={dataCollectedPercentage()} />
            )}
          </FlexBox>
        </>
      )}
      <FlexBox height="100%" align="center">
        {isEditProfile ? (
          <StyledContainerRows width="175px">
            <Button
              variant="textualPrimary"
              noBorder
              onClick={() => setIsEditProfile(!isEditProfile)}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {
                handleSubmit()
                setIsEditProfile(!isEditProfile)
              }}
            >
              Save
            </Button>
          </StyledContainerRows>
        ) : (
          <Button
            variant="textualPrimary"
            onClick={() => setIsEditProfile(!isEditProfile)}
          >
            EDIT PROFILE
          </Button>
        )}
      </FlexBox>
    </FlexBox>
  )
}

const DataCollected = (props) => {
  const size = 60
  const radius = (size - 5) / 2
  const viewBox = `0 0 ${size} ${size}`
  const dashArray = radius * Math.PI * 2
  const dashOffset = props.percentage
    ? dashArray - (dashArray * props.percentage) / 100
    : 173

  return (
    <FlexBox height="100%" align="center" margin={`0 ${spacing.l}`}>
      <Text variant="p">Data Collected</Text>
      <StyledSVG margin={`0 ${spacing.l}`}>
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            fill="none"
            stroke="#E8E8E8"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${5}px`}
          />
          <circle
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            stroke="#8BC34A"
            fill="none"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${5}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
          />
          <text fontSize="18px" x="50%" y="50%" dy="7.5px" textAnchor="middle">
            {props.percentage ? `${props.percentage}%` : '0%'}
          </text>
        </svg>
      </StyledSVG>
    </FlexBox>
  )
}

export default ConsumerProfile

const StyledConsumerProfile = styled.div`
  height: 100%;
  width: 100%;
  padding-top: ${spacing.l};
  background-color: white;
`

const StyledSVG = styled.div`
  svg {
    width: ${(props) => (props.square ? props.square : props.width)};
    height: ${(props) => (props.square ? props.square : props.height)};
    margin: ${(props) => props.margin};
  }
`

const StyledDivider = styled.div`
  height: ${(props) => (props.horizontal ? '1px' : 'inherit')};
  width: ${(props) => (props.horizontal ? '' : '1px')};
  background-color: #e8e8e8;
  margin: ${(props) =>
    props.horizontal ? `0 ${spacing.xl}` : `0 ${spacing.s}`};
`
