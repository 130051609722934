import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import { FilterFooter } from 'components/Common'
import { FilterHeader } from 'components/Common/FilterHeader'
import CustomPopup from 'components/Content/CustomPopup'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import { Input, Pill, Text, Button } from 'components/Common'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import { initialFilter, popupStyle } from '../components/constants'
import * as moment from 'moment'
import { SelectBrands } from 'components/Common'
import { getMinMax } from 'utils'
import { $Filter, $ActiveFilter, $SelectedFilter } from '../Report.styled'
import { compareDatePermission } from '../../../../utils/getDateFormat'
import { MyToast } from '../../../Common'

const getPillData = (type, value) => {
  switch (type) {
    case 'timeFrame':
      const [start, end] = value.toString().split('/')
      return (
        <>
          {moment(start).format('DD MMM YYYY')}
          <br />
          {moment(end).format('DD MMM YYYY')}
        </>
      )
    case 'brands':
      return `${value.length} brands`
    case 'stores':
      return `${value.min} to ${value.max} stores`
  }
}

const ReportFilter = React.forwardRef(({ data, handleFilter }, ref) => {
  const toastContext = useContext(MyToast)
  const selectedVendor = useSelector((state) => state.vendorIds.selectedVendor)

  const [filter, setFilter] = useState(data)
  const [selectedFilter, setSelectedFilter] = React.useState({
    id: 1,
    type: '',
    isActive: false,
    label: '',
  })

  useEffect(() => {
    setFilter(data)
  }, [data])

  const onHandleFilter = () => {
    handleFilter(filter)
    ref.current.closeModal()
  }

  const handleRangeChange = (value, field, key) => {
    const copyFilter = cloneDeep(filter)
    copyFilter[field].isActive = true
    copyFilter[field].value[key] = value
    setFilter(copyFilter)
  }

  const handleChange = (field, value) => {
    const copyFilter = cloneDeep(filter)
    if (
      field === 'timeFrame' &&
      !compareDatePermission(
        selectedVendor.featuresAlloted.exportRange,
        value.start
      )
    ) {
      toastContext.toast('You can only access last 2 months data', 'warning')
      return false
    }
    copyFilter[field].isActive = true
    copyFilter[field].value = value
    setFilter(copyFilter)
  }

  const handleClosePill = (pillToDelete) => () => {
    const copyFilter = cloneDeep(filter)
    const defaultFilter = cloneDeep(initialFilter)[pillToDelete]
    copyFilter[pillToDelete] = defaultFilter
    setFilter(copyFilter)
  }

  const getCurrentFilter = (id) => {
    switch (id) {
      case 1:
        return (
          <FlexBox direction="column">
            <FlexBox gap={spacing.xl}>
              <Text as="label" variant="small">
                {filter.timeFrame.label} :
              </Text>
              <Button variant="primaryOutline" fontSize="12px">
                <i className="fas fa-calendar-alt" /> &nbsp;
                {moment(filter.timeFrame?.value?.start).format('DD MMM YYYY') +
                  ' - ' +
                  moment(filter.timeFrame?.value?.end).format('DD MMM YYYY')}
              </Button>
            </FlexBox>
            <DateRangePicker
              singleDateRange
              maximumDate={new Date()}
              onSelect={(val) => handleChange('timeFrame', val)}
              value={filter.timeFrame.value}
            />
          </FlexBox>
        )
      case 2:
        return (
          <FlexBox direction="column">
            <Text as="label" variant="small" mb={spacing.m}>
              {filter.brands.label}
            </Text>
            <SelectBrands
              brands={filter.brands.value}
              setBrands={(brands) => handleChange('brands', brands)}
            />
          </FlexBox>
        )
      case 3:
        return (
          <Input
            variant="range"
            type="number"
            label={filter.stores.label}
            id={['min', 'max']}
            inputWidth="125px"
            value={[filter.stores.value.min, filter.stores.value.max]}
            onChange={[
              (e) =>
                handleRangeChange(
                  getMinMax(0, Infinity, e.target.value),
                  'stores',
                  'min'
                ),
              (e) =>
                handleRangeChange(
                  getMinMax(filter.stores.value.min, Infinity, e.target.value),
                  'stores',
                  'max'
                ),
            ]}
          />
        )
    }
  }

  return (
    <CustomPopup
      className="filter-modal"
      contentStyle={popupStyle}
      ref={ref}
      headerComponent
      heading={<FilterHeader heading="Filter" />}
      footer={
        <FilterFooter ref={ref} filter={filter} handleFilter={onHandleFilter} />
      }
    >
      <$Filter>
        <FlexBox>
          <FlexBox direction="column">
            {Object.values(filter).map((item) => {
              return (
                <$SelectedFilter
                  key={item.id}
                  onClick={() => {
                    setSelectedFilter(item)
                  }}
                  isActive={item.id === selectedFilter.id ? true : false}
                  isFilterActive={item.isActive}
                >
                  {item.label}
                  {item.isActive && (
                    <Pill
                      type="tab"
                      onClose={handleClosePill(item.type)}
                      marginLeft={spacing.m}
                    >
                      {getPillData(item.type, item.value)}
                    </Pill>
                  )}
                </$SelectedFilter>
              )
            })}
          </FlexBox>
          {Object.values(filter).map(
            (item) =>
              item.id === selectedFilter.id && (
                <$ActiveFilter key={item.id}>
                  <FlexBox expand padding={`${spacing.l}`}>
                    {getCurrentFilter(item.id)}
                  </FlexBox>
                </$ActiveFilter>
              )
          )}
        </FlexBox>
      </$Filter>
    </CustomPopup>
  )
})

export default ReportFilter
