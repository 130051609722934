import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import InfiniteScroll from 'react-infinite-scroll-component'
// import { toast } from 'react-toastify';
import Select from 'react-select'
import 'css/content/userAccess.scss?v=3'
import UserAccessHelper from './UserAccessHelper'
import {
  getUsersInit,
  getMoreUsers,
  deleteUser,
  downloadExcel,
} from 'redux/actions/userAccessActions'
import { filterBrand, filterStores } from 'redux/actions/authActions'
import { SingleValue, StoreSelectOption, MyToast } from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { FlexBox, GreyText } from 'styledComponent'
import { Checkbox } from 'components/Common/Input'

class UserAccess extends Component {
  static contextType = MyToast

  constructor() {
    super()
    this.state = {
      storeIds: null,
      stores: [],
      user: {
        _id: '',
      },
    }

    this.storeSelectRef = React.createRef()

    this.handleDeleteBtnClick = this.handleDeleteBtnClick.bind(this)
    this.handleEditBtnClick = this.handleEditBtnClick.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.handleUserUpdate = this.handleUserUpdate.bind(this)
    this.downloadExcel = this.downloadExcel.bind(this)
  }

  componentDidMount() {
    // Select the first listed store by default
    const storeId = [Object.keys(this.props.stores)[0]]

    if (storeId && storeId.length) {
      // Get list of all stores
      const stores = Object.values(this.props.stores).map((store) => ({
        label: store.displayAddress,
        value: store.storeId,
        brandLogo: this.props.brands.find(
          (brand) => brand.brandId === store.brandId
        ).brandLogo,
      }))
      this.storeSelectRef.current.select.commonProps.setValue([stores[0]])

      this.setState({
        storeIds: storeId,
        stores,
      })
      setTimeout(() => this.getUsers(), 0)
    }
  }

  handleDeleteBtnClick(e) {
    e.preventDefault()
    const email = e.target.getAttribute('email')
    swal({
      title: 'Are you sure?',
      text: 'Deleting the user will remove the user from your store!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirmed) => {
      if (deleteConfirmed) {
        this.props
          .deleteUser({
            email,
            storeIds: this.state.storeIds,
          })
          .then((data) => {
            if (data.code) {
              // toast.success(data.message);
              this.context.toast(data.message, 'success')
            }
          })
        const user = this.props.userList.filter(
          (user) => user.email === email
        )[0]
        if (user._id === this.state.user._id) {
          this.userAccessHelperRef.clearForm()
        }
      }
    })
  }

  handleEditBtnClick(e) {
    e.preventDefault()
    const email = e.target.getAttribute('email')
    const user = this.props.userList.filter((user) => user.email === email)[0]
    const stores = Object.values(this.props.stores).map((store) => ({
      label: store.displayAddress,
      value: store.storeId,
      brandLogo: this.props.brands.find(
        (brand) => brand.brandId === store.brandId
      ).brandLogo,
    }))

    const assignedStores = stores.filter((store) =>
      Object.keys(user.stores).includes(store.value)
    )
    this.storeSelectRef.current.select.commonProps.setValue(assignedStores)
    this.setState({ user })
  }

  getUsers() {
    this.props.getUsersInit({ storeIds: this.state.storeIds })
  }

  handleUserUpdate() {
    this.setState({
      user: {
        _id: '',
      },
    })
  }

  downloadExcel() {
    this.props.downloadExcel({ storeIds: this.state.storeIds })
  }

  handleFilterClick = (selectedStore) => {
    if (selectedStore && selectedStore.length <= 0) {
      return swal({
        title: 'Select Store',
        text: 'Select a store first to list its users.',
        icon: 'warning',
        dangerMode: true,
      })
    }

    const storeIds = selectedStore && selectedStore.map((store) => store.value)

    this.setState({ storeIds })
    setTimeout(() => this.getUsers(), 0)
    this.userAccessHelperRef.clearForm()
  }

  handleCheckboxChange = () => {
    if (this.state.storeIds?.length === this.state.stores?.length) {
      this.setState({ storeIds: [] })
      swal({
        title: 'Select Store',
        text: 'Select a store first to list its users.',
        icon: 'warning',
        dangerMode: true,
      })
    } else {
      const storeIds = this.state.stores?.map((store) => store.value)
      this.setState({ storeIds })
    }
  }

  selectedOptions = () =>
    this.state.stores.filter((opt) => this.state.storeIds?.includes(opt.value))

  render() {
    return (
      <>
        <Navbar heading="User Access" />
        <div className="userAccess animated slideInRight">
          <UserAccessHelper
            user={this.state.user}
            storeIds={this.state.storeIds}
            onUserUpdate={this.handleUserUpdate}
            onRef={(ref) => (this.userAccessHelperRef = ref)}
          />
          <div className="contentPart2">
            <div className="storeInfo shadow p-3 bg-white ">
              <Select
                placeholder="Select Stores"
                ref={this.storeSelectRef}
                isClearable={false}
                components={{
                  Option: StoreSelectOption,
                  SingleValue: SingleValue,
                }}
                options={this.state.stores}
                className="select-user-access select-stores"
                isMulti
                onChange={this.handleFilterClick}
                value={this.selectedOptions()}
              />
              <FlexBox align="center">
                <Checkbox
                  id="storeIds"
                  onCheckboxChange={this.handleCheckboxChange}
                  isChecked={
                    this.state.stores?.length === this.state.storeIds?.length
                  }
                >
                  Select All
                </Checkbox>
              </FlexBox>
            </div>
            <div className="storeUsers bg-white shadow">
              <div className="heading">Current Users</div>
              <div className="usersTable">
                <div className="tableHeading">
                  <span className="span1">No.</span>
                  <span className="span2">Email Id</span>
                  <span className="span3" onClick={this.downloadExcel}>
                    <button className="excelth">
                      <img
                        src={require('../../Layout/images/export.png')}
                        alt="Export"
                      />
                      <span>Export</span>
                    </button>
                  </span>
                </div>
                <div id="tableBody" className="tableBody">
                  {this.props.userList && this.props.userList.length > 0 ? (
                    <InfiniteScroll
                      dataLength={this.props.userList.length} //This is important field to render the next data
                      next={this.props.getMoreUsers}
                      hasMore={this.props.hasMoreUsers}
                      loader={
                        <div className="tableItem">
                          <span>Loading...</span>
                        </div>
                      }
                      scrollableTarget="tableBody"
                    >
                      {this.props.userList.map((user, i) => {
                        return (
                          <div className="tableItem" key={user._id}>
                            <span className="span1">{i + 1}.</span>
                            <span className="span2">{user.email}</span>
                            <span className="span3 actionBtn">
                              <img
                                onClick={this.handleEditBtnClick}
                                email={user.email}
                                src={require('../profile/images/Icon.png')}
                                alt=""
                              />
                              <img
                                onClick={this.handleDeleteBtnClick}
                                email={user.email}
                                src={require('../profile/images/ic_delete.png')}
                                alt=""
                              />
                            </span>
                          </div>
                        )
                      })}
                    </InfiniteScroll>
                  ) : (
                    <GreyText as="p">No user</GreyText>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userList: state.userAccess.userList,
  hasMoreUsers: state.userAccess.hasMoreUsers,
  stores: filterStores(state, 'userAccess'),
  brands: filterBrand(state, 'userAccess'),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getUsersInit, getMoreUsers, deleteUser, downloadExcel },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess)
