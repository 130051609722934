import React, { useState } from 'react'
import { Div, FlexBox } from 'styledComponent'
import { Text, Tooltip, PopupContainer } from 'components/Common'
import SenderDetails from 'assets/icons/sender-details.svg'

export const EmailRoute = () => {
  const [isPopOpen, setIsPopOpen] = useState(false)

  return (
    <FlexBox align="center" onClick={() => setIsPopOpen(true)}>
      <Text variant="small" type="main">
        Email Route
      </Text>
      <Tooltip content="Click here to view instructions" />
      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Instructions"
        cancelButtonName="Close"
        width="1225px"
      >
        <Div height="500px" overflow="auto">
          <img src={SenderDetails} />
        </Div>
      </PopupContainer>
    </FlexBox>
  )
}
