import React, { useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import {
  BoldIcon,
  CenterAlignIcon,
  ItalicIcon,
  LeftAlignIcon,
  OrderedIcon,
  RightAlignIcon,
  StrikethroughIcon,
  UnderlineIcon,
  UnorderedIcon,
} from 'assets/icons'
import { useOnClickOutside } from 'hooks'
import { HEADING_TYPES } from '../constants'
import {
  $QuestContainer,
  $SurveyInput,
  $ToolGroup,
  $ToolItem,
  $SurveySelect,
} from '../Survey.styled'
import { FlexBox } from 'styledComponent'

export const SurveyText = ({ ...props }) => {
  const ref = useRef()
  const [isToolboxOpen, setToolboxOpen] = useState(false)
  useOnClickOutside(ref, () => setToolboxOpen(false))

  return (
    <$QuestContainer
      showBorder={props.isEditing}
      ref={ref}
      onClick={() => setToolboxOpen(true)}
    >
      <ContentEditable
        disabled={!props.isEditing}
        html={props.quest?.text}
        tagName="span"
        onChange={(e) => props.setValue(e.target.value, 'text')}
      />
      {props.isEditing && isToolboxOpen && (
        <FlexBox
          style={{ border: '1px solid lightgray', borderRadius: '4px' }}
          padding="10px"
          gap="12px"
        >
          <FlexBox justify="flex-start" gap="4px">
            <$SurveySelect
              onChange={(e) =>
                document.execCommand('formatBlock', false, e.target.value)
              }
            >
              {HEADING_TYPES.map((opt) => (
                <option value={opt.value}>{opt.label}</option>
              ))}
            </$SurveySelect>
          </FlexBox>
          <FlexBox justify="flex-start" gap="4px">
            <$SurveyInput
              padding="2px"
              id="backColor"
              type="color"
              onChange={(e) =>
                document.execCommand('backColor', true, e.target.value)
              }
            />
            <small>
              Highlight <br /> Colour
            </small>
          </FlexBox>
          <FlexBox justify="flex-start" gap="4px">
            <$SurveyInput
              padding="2px"
              type="color"
              onChange={(e) =>
                document.execCommand('foreColor', true, e.target.value)
              }
            />
            <small>
              Text <br /> Colour
            </small>
          </FlexBox>
          <$ToolGroup>
            <$ToolItem
              onClick={() => document.execCommand('bold')}
              isActive={document.queryCommandState('bold')}
            >
              <BoldIcon />
            </$ToolItem>
            <$ToolItem
              onClick={() => document.execCommand('italic')}
              isActive={document.queryCommandState('italic')}
            >
              <ItalicIcon />
            </$ToolItem>
            <$ToolItem
              isActive={document.queryCommandState('underline')}
              onClick={() => document.execCommand('underline')}
            >
              <UnderlineIcon />
            </$ToolItem>
            <$ToolItem
              isActive={document.queryCommandState('strikethrough')}
              onClick={() => document.execCommand('strikethrough')}
            >
              <StrikethroughIcon />
            </$ToolItem>
          </$ToolGroup>
          <$ToolGroup>
            <$ToolItem
              isActive={document.queryCommandState('justifyLeft')}
              onClick={() => document.execCommand('justifyLeft')}
            >
              <LeftAlignIcon />
            </$ToolItem>
            <$ToolItem
              isActive={document.queryCommandState('justifyCenter')}
              onClick={() => document.execCommand('justifyCenter')}
            >
              <CenterAlignIcon />
            </$ToolItem>
            <$ToolItem
              isActive={document.queryCommandState('justifyRight')}
              onClick={() => document.execCommand('justifyRight')}
            >
              <RightAlignIcon />
            </$ToolItem>
          </$ToolGroup>
          <$ToolGroup>
            <$ToolItem
              isActive={document.queryCommandState('InsertOrderedList')}
              onClick={() => document.execCommand('insertOrderedList')}
            >
              <OrderedIcon />
            </$ToolItem>
            <$ToolItem
              isActive={document.queryCommandState('insertUnorderedList')}
              onClick={() => document.execCommand('insertUnorderedList')}
            >
              <UnorderedIcon />
            </$ToolItem>
          </$ToolGroup>
        </FlexBox>
      )}
    </$QuestContainer>
  )
}
