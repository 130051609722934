import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { connect, useSelector } from 'react-redux'

import { useRouter, useSelectStore } from 'hooks'
import {
  getCampaigns,
  filterToQuery,
  deleteCampaign,
  updateCampaign,
  setLoadTable,
  duplicateCampaign,
} from 'redux/actions/autoEngageActions'
import { Tabs, useToasts } from 'components/Common'

import { $WhiteContainer } from './AutoEngagement.styled'
import { AETopBar, AEHeader, AETable } from './campaigns'
import {
  DEFAULT_CURRENT_TAB,
  ERROR_DECODE,
  GET_SEL_VALUE,
  CAMP_TYPES,
} from './constants'
import Templates from './WhatsApp/Templates'
import { Div } from 'styledComponent'

const AutoEngage = (props) => {
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState({})
  const [activeTab, setActiveTab] = useState('')
  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )

  const { history, location, query: routeQuery } = useRouter()
  const routeId = location.pathname.split('/')[2]
  const featurePermission = (campaign) => {
    switch (campaign) {
      case 'sms':
        return featuresAlloted.smsCampaign
      case 'email':
        return featuresAlloted.emailCampaign
      case 'bannerInBill':
        return featuresAlloted.bannerCampaign
      case 'adBelowBill':
        return featuresAlloted.adCampaign
      case 'surveyInBill':
        return featuresAlloted.surveyCampaign
      case 'sellBelowBill':
        return featuresAlloted.sellCampaign
      case 'popupOverBill':
        return featuresAlloted.popupCampaign
      case 'whatsApp':
        return featuresAlloted.whatsAppCampaign
    }
  }
  const currentTab = GET_SEL_VALUE(routeId || 'all', [
    ...CAMP_TYPES.filter((x) => featurePermission(x.value)),
    DEFAULT_CURRENT_TAB,
  ])

  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const {
    data: { filterQuery, aeData, aeTableLoading },
  } = useSelectStore('autoEngage')

  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { toast } = useToasts()

  const setCurrentTab = (currentTab) => {
    if (currentTab && currentTab.value !== 'all') {
      history.push('/auto-engage/' + currentTab.value)
    } else {
      history.push('/auto-engage')
    }
  }

  useEffect(() => {
    if (
      currentTab &&
      currentTab.value !== 'all' &&
      routeId !== currentTab.value
    ) {
      setCurrentTab(GET_SEL_VALUE(routeId, CAMP_TYPES))
    }
  }, [routeId])

  useEffect(() => {
    query.vendorId &&
      props.getCampaigns({
        ...query,
        limit: 10,
      })
  }, [query])

  useEffect(() => {
    if (['Templates', 'Campaigns'].includes(routeQuery.tab)) {
      setActiveTab(routeQuery.tab || 'Campaigns')
    }
  }, [routeQuery.tab])

  useEffect(() => {
    props.setLoadTable(true)
    const tempQuery = {
      vendorId: selectedVendor.value,
      ...filterQuery,
    }
    if (search?.length > 0) {
      tempQuery.search = search
    }
    if (currentTab && currentTab.value !== 'all') {
      tempQuery.types = [currentTab.value]
    }
    debounceLoadData({ ...tempQuery })
  }, [selectedVendor, filterQuery, search, currentTab])

  const onPaginationChange = (page) => {
    props.setLoadTable(true)
    setQuery({ ...query, page: page.selected + 1 })
  }

  const debounceLoadData = useCallback(
    debounce((query) => setQuery({ ...query, page: 1 }), 750),
    []
  )

  const delCamp = async (id) => {
    try {
      const res = await deleteCampaign(id)
      if (res.message) {
        props.getCampaigns({
          ...query,
          limit: 10,
        })
        toast(res.message, 'success')
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const unpubCamp = async (id, statusType = 'unpublished') => {
    try {
      const res = await updateCampaign(id, {
        vendorId: selectedVendor.value,
        status: statusType,
      })
      if (res.message) {
        props.getCampaigns({
          ...query,
          limit: 10,
        })
        toast(res.message, 'success')
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const dupliCampaign = async (body, updatedName) => {
    try {
      const res = await duplicateCampaign(body._id, {
        name: updatedName,
        vendorId,
        createdBy: body.createdBy,
      })

      props.getCampaigns({
        ...query,
        limit: 10,
      })

      toast('Campaign duplicated successfully', 'success')
    } catch (e) {
      toast(ERROR_DECODE(e), 'error')
    }
  }
  return (
    <$WhiteContainer pb="1px">
      <AETopBar
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        heading={currentTab?.label}
        activeTab={activeTab}
      />
      <AEHeader
        currentTab={currentTab?.value}
        search={search}
        setSearch={setSearch}
        activeTab={activeTab}
      />
      {currentTab?.value === 'whatsApp' ? (
        <>
          <hr />
          <Div style={{ marginLeft: '20px' }}>
            <Tabs setActiveTab={setActiveTab}>
              <Div label="Campaigns">
                <AETable
                  data={aeData}
                  deleteCampaign={delCamp}
                  unpublishCampaign={unpubCamp}
                  dupliCampaign={dupliCampaign}
                  tableLoading={aeTableLoading}
                  onPaginationChange={onPaginationChange}
                />
              </Div>
              <Div label="Templates">
                <Templates />
              </Div>
            </Tabs>
          </Div>
        </>
      ) : (
        <>
          <AETable
            data={aeData}
            deleteCampaign={delCamp}
            unpublishCampaign={unpubCamp}
            dupliCampaign={dupliCampaign}
            tableLoading={aeTableLoading}
            onPaginationChange={onPaginationChange}
          />
        </>
      )}
    </$WhiteContainer>
  )
}

export default connect(null, { getCampaigns, filterToQuery, setLoadTable })(
  AutoEngage
)
