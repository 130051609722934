import React from 'react'
import styled from 'styled-components'

export { ReactComponent as EmailTag } from './email.svg'
export { ReactComponent as ExternalTag } from './external.svg'
export { ReactComponent as SmsTag } from './sms.svg'

export const DigitalTag = ({ label = 'Digital Bill' }) => {
  const $DigiTag = styled.span`
    text-align: center;
    border: 1px solid #5c7eee;
    background-color: #c2d0ff;
    color: #5c7eee;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 4px;
  `

  return <$DigiTag>{label}</$DigiTag>
}
