import { toast } from 'react-toastify'
import { store } from 'redux/index'
import { logoutUser } from 'redux/actions/authActions'

export const errorHandler = (status, message) => {
  if (status === 401) {
    toast.error('Session Timed Out.')
    setTimeout(() => {
      store.dispatch(logoutUser())
    }, 3000)
  } else if (status === 403) {
    toast.error('Unauthorised.')
  } else {
    toast.error(message)
  }
}
