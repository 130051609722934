import React from 'react'

import { Text } from 'components/Common/Text'
import { $FlexBox } from '../../ProductAnalytics.styled'
import { $CloseButton, $Block, $FooterButton } from './ModalComponents.styled'

/**
 * @description Modal header component
 */
const PopupHeader = ({ title, onClose }) => {
  return (
    <$Block>
      <$FlexBox justify="space-between" align="center">
        <Text variant="p" weight="bold">
          {title}
        </Text>
        <$CloseButton onClick={onClose} />
      </$FlexBox>
    </$Block>
  )
}

PopupHeader.defaultProps = {
  title: 'Are you sure?',
}

/**
 * @description Modal footer component
 */
const PopupFooter = ({ onCancel, onConfirm, loading }) => {
  return (
    <$Block>
      <$FlexBox justify="flex-end">
        <$FooterButton onClick={onConfirm} variant="primary" disabled={loading}>
          OK
        </$FooterButton>
        <$FooterButton
          variant="primaryOutline"
          noBorder
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </$FooterButton>
      </$FlexBox>
    </$Block>
  )
}

export { PopupHeader, PopupFooter }
