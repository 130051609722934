import React from 'react'
import { FlexBox } from 'styledComponent'
import { Button } from 'components/Common'
import { useTheme } from 'styled-components'

export const FormExtras = ({
  isView,
  isCreate,
  isEdit,
  onUpdate,
  onSave,
  onEdit,
}) => {
  const { spacing } = useTheme()

  return (
    <FlexBox gap={spacing.m}>
      {isView && (
        <Button variant="primaryOutline" onClick={onEdit}>
          Edit
        </Button>
      )}
      {isCreate && (
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      )}
      {isEdit && (
        <Button variant="primary" onClick={onUpdate}>
          Update
        </Button>
      )}
    </FlexBox>
  )
}
