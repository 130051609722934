export const TABS_LIST = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    feature: ['dashboard'],
  },
  {
    label: 'Bills',
    value: 'bills',
    feature: ['bills'],
  },
  {
    label: 'Reports',
    value: 'reports',
    feature: ['reports'],
  },
  {
    label: 'Feedback',
    value: 'feedback',
    feature: ['feedback'],
  },
  {
    label: 'Consumer Profiling',
    value: 'consumer-profiling',
    feature: ['crm'],
  },
  {
    label: 'Communication Channel',
    value: 'communication-channel',
    feature: [
      'smsCampaign',
      'emailCampaign',
      'adCampaign',
      'sellCampaign',
      'bannerCampaign',
      'surveyCampaign',
      'popupCampaign',
    ],
  },
  {
    label: 'All Campaigns',
    value: 'all-campaigns',
    feature: [
      'smsCampaign',
      'emailCampaign',
      'adCampaign',
      'sellCampaign',
      'bannerCampaign',
      'surveyCampaign',
      'popupCampaign',
    ],
  },
  {
    label: 'SMS',
    value: 'sms',
    feature: ['smsCampaign'],
  },
  {
    label: 'Email',
    value: 'email',
    feature: ['emailCampaign'],
  },
  {
    label: 'Banner In Bill',
    value: 'banner-in-bill',
    feature: ['bannerCampaign'],
  },
  {
    label: 'Ad Below Bill',
    value: 'ad-below-bill',
    feature: ['adCampaign'],
  },
  {
    label: 'Surveys',
    value: 'surveys',
    feature: ['surveys'],
  },
  {
    label: 'Survey Button',
    value: 'survey-button',
    feature: ['surveyCampaign'],
  },
  {
    label: 'Survey Builder',
    value: 'survey-builder',
    feature: ['surveys'],
  },
  {
    label: 'Popup over Bill',
    value: 'popup-over-bill',
    feature: ['popupCampaign'],
  },
  {
    label: 'Coupon Codes',
    value: 'coupon-codes',
    feature: ['coupons'],
  },
  {
    label: 'Manage',
    value: 'manage',
    feature: ['settings'],
  },
  {
    label: 'Business Profile',
    value: 'business-profile',
    feature: ['bProfile'],
  },
  {
    label: 'Media Bank',
    value: 'media-bank',
    feature: ['mBank'],
  },
  {
    label: 'Products',
    value: 'products',
    feature: ['products'],
  },
  {
    label: 'Customer Complaints',
    value: 'customer-complaints',
    feature: ['custComplaints'],
  },
  {
    label: 'Shopify Setup',
    value: 'shopify',
    feature: ['serviceSubscribtion'],
  },
  {
    label: 'DLT Settings',
    value: 'dlt-settings',
    feature: ['smsCampaign'],
  },
  {
    label: 'Customer Segmentation',
    value: 'customer-segmentations',
    feature: ['custSegmentation'],
  },
  {
    label: 'Digital Bill',
    value: 'digital-bill',
    feature: ['bills'],
  },
]
