import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import moment from 'moment'
import { useSelectStore } from 'hooks'
import { ReactComponent as DownloadIcon } from 'images/download.svg'
import { Table, TableHead, TableCell, TableRow } from 'styledComponent'
import { Skeleton, Avatar } from 'components/Common'
import { reportHeader } from '../components/constants'
import { $ReportTableContainer, $SquareButton } from '../Report.styled'
import { spacing } from 'design-system'
import { downloadReport } from 'redux/actions/reportsAction'

const ReportTable = ({ tableLoading, setPage }) => {
  const theme = useTheme()
  const { data } = useSelectStore('reports.data')
  const { data: allBrands } = useSelectStore('brand.brands')
  const [isDisabled, setIsDisabled] = useState(false)

  const onPaginationChange = (p) => {
    setPage((p?.selected || 0) + 1)
  }

  const fetchBrandImages = (ids) =>
    allBrands
      .filter((allBr) => ids.includes(allBr.brandId))
      .map((brand) => brand.brandLogo)

  const onDownloadClick = async (id) => {
    setIsDisabled(true)
    await downloadReport(id)
    setIsDisabled(false)
  }

  const reportRow = (rep) => [
    rep?.name || '-',
    rep?.description || '-',
    rep?.brandIds ? (
      <Avatar limit={3} src={fetchBrandImages(rep?.brandIds)} />
    ) : (
      '-'
    ),
    rep?.storeIds?.length || '-',
    rep?.dateRange
      ? `${moment(rep?.dateRange?.startDate).format('DD/MM/YYYY')} - ${moment(
          rep?.dateRange?.endDate
        ).format('DD/MM/YYYY')}`
      : '-',
    rep?.createdDate ? moment(rep.createdDate).format('DD/MM/YYYY') : '-',
    <$SquareButton
      disabled={isDisabled}
      size={32}
      onClick={() => onDownloadClick(rep?._id)}
    >
      <DownloadIcon />
    </$SquareButton>,
  ]

  return (
    <$ReportTableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={reportHeader()} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((item) => (
              <TabRow
                key={item}
                cells={[...new Array(reportHeader().length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : data.docs?.length > 0 ? (
            data.docs?.map((item, i) => (
              <TabRow
                key={i}
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={reportRow(item)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={reportHeader().length}
              cells={['No Data Found']}
            />
          )}
        </tbody>
      </Table>
      {data.totalPages > 1 && (
        <ReactPaginate
          previousLabel="< Previous"
          nextLabel="Next >"
          breakLabel="..."
          pageCount={data.totalPages || 1}
          marginPagesDisplayed={3}
          pageRangeDisplayed={2}
          onPageChange={onPaginationChange}
          containerClassName={'feedback-pagination notSelectableText'}
          activeClassName={'pagination_activePage'}
          forcePage={(data?.page || 1) - 1}
        />
      )}
    </$ReportTableContainer>
  )
}

export default ReportTable

const TabRow = ({ cells, colSpan, align, ...props }) => {
  return (
    <TableRow {...props}>
      {cells.map((cell, i) => (
        <TableCell
          padding={spacing.m}
          colSpan={colSpan}
          textAlign={align}
          key={i}
        >
          {cell}
        </TableCell>
      ))}
    </TableRow>
  )
}
