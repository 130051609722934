import styled from 'styled-components'

import { Box } from 'styledComponent/components/box'
import { FlexBox } from 'styledComponent'
import { color } from 'design-system'
import { SEGMENT_STATUSES } from '../constants'

const $Box = styled(Box)`
  padding: 0;
  border-radius: 5px;
`

const $StyledTag = styled.div`
  color: white;
  background-color: ${(props) =>
    SEGMENT_STATUSES?.[props?.type]?.colour || '#FFA500'};
  border-width: 1px;
  border-style: solid;
  padding: ${(props) => (props.padding ? props.padding : '0.25em 0.4em')};
  font-size: 75%;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  display: inline-flex;
  height: ${(props) => (props.height ? props.height : 'auto')};
  justify-content: center;
  align-items: center;
`

const $AddBlock = styled.div`
  color: ${color.primary.default};
  svg {
    fill: ${color.primary.default};
    width: 12px;
  }
  span {
    vertical-align: middle;
    margin-left: 5px;
  }
  cursor: pointer;
`

const $MenuHead = styled(FlexBox)`
  padding: 10px 20px;
  background-color: #e6e9ec;
  border-radius: 5px 5px 0 0;
  font-weight: 900;
`

const $MenuBlock = styled.ul`
  list-style: none;
  padding: 0;
`

const $MenuItem = styled.li`
  svg {
    fill: #707070;
    width: 9px;
  }
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dedede;
  background-color: ${(props) => (props.active ? '#EBF3FE' : color.white)};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #ebf3fe;
  }
  color: ${(props) => (props.locked ? '#707070' : '#33475B')};
  cursor: pointer;
`

export { $Box, $AddBlock, $MenuHead, $MenuBlock, $MenuItem, $StyledTag }
