import React from 'react'
import moment from 'moment'

import { Text } from 'components/Common'
import { Input } from 'components/Common/Input'
import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'

import { _selectOptionsGenerator } from 'utils/formatter'
import { HOURS, MINUTES, PERIOD } from '../constants'
import { GET_SEL_VALUE } from 'utils/reusables'

export const AutoTurnOnTurnOffCampaign = ({
  form,
  setForm,
  isStartDate = false,
}) => {
  const dateField = isStartDate ? 'activateAt' : 'deactivateAt'
  const theDate = isStartDate
    ? form?.activateAt || moment().add(1, 'days').startOf('day')
    : form?.deactivateAt || moment().add(2, 'days').startOf('day')
  const selectedDate = moment(theDate).local()

  React.useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      [dateField]: moment(selectedDate).toISOString(),
    }))
  }, [])

  const handleDateSelect = (date) => {
    setForm((prevState) => ({
      ...prevState,
      [dateField]: moment(date).toISOString(),
    }))
  }

  const hr12To24 = (meridiem, hr12) => {
    hr12 = Number(hr12)
    let hr24 = 0
    if (meridiem === 'AM') {
      hr24 = hr12 == 12 ? 0 : hr12 > 12 ? hr12 - 12 : hr12
    } else {
      hr24 = hr12 > 11 ? hr12 : hr12 + 12
    }
    return hr24
  }

  const handleTimeChange = (unit, value) => {
    if (unit === 'hour') {
      const meridiem = moment(selectedDate).format('A')
      value = hr12To24(meridiem, value)
    } else if (unit === 'meridiem') {
      unit = 'hour'
      const hr12 = moment(selectedDate).format('hh')
      value = hr12To24(value, hr12)
    }
    setForm((prevState) => ({
      ...prevState,
      [dateField]: moment(selectedDate).set(unit, value).toISOString(),
    }))
  }

  return (
    <Div pb={isStartDate ? '' : '120px'}>
      <FlexBox align="center" mt={spacing.l}>
        <Text variant="p" weight="bold">
          {isStartDate ? 'Start Date' : 'End Date'}
        </Text>
      </FlexBox>
      <FlexBox align="center">
        <Input
          variant="date-input"
          inputWidth="160px"
          containerMargin={`0px ${spacing.l} 0px 0px`}
          placeholder="dd-mm-yyyy"
          onChange={handleDateSelect}
          value={selectedDate.toDate()}
          minDate={moment().toDate()}
        />
        <Text variant="p">AT</Text>
        <Input
          variant="single-select"
          maxMenuHeight="120px"
          options={_selectOptionsGenerator(HOURS)}
          placeholder="HH"
          inputWidth="80px"
          containerMargin={`0px ${spacing.l}`}
          onChange={({ value }) => handleTimeChange('hour', value)}
          value={GET_SEL_VALUE(
            selectedDate.format('hh'),
            _selectOptionsGenerator(HOURS)
          )}
        />
        <Text variant="p">:</Text>
        <Input
          variant="single-select"
          maxMenuHeight="120px"
          options={_selectOptionsGenerator(MINUTES)}
          placeholder="MM"
          inputWidth="80px"
          containerMargin={`0px ${spacing.l}`}
          onChange={({ value }) => handleTimeChange('minutes', value)}
          value={GET_SEL_VALUE(
            selectedDate.format('mm'),
            _selectOptionsGenerator(MINUTES)
          )}
        />
        <Input
          variant="single-select"
          options={_selectOptionsGenerator(PERIOD)}
          inputWidth="80px"
          placeholder="AM"
          containerMargin="0px"
          onChange={({ value }) => handleTimeChange('meridiem', value)}
          value={GET_SEL_VALUE(
            selectedDate.format('A'),
            _selectOptionsGenerator(PERIOD)
          )}
        />
      </FlexBox>
    </Div>
  )
}
