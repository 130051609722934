import React from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $Block } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'
import { PropertyBar } from 'builder/editor/common/PropertyBar'

export const SpacerSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Spacer"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <PropertyBar
        label="Height"
        type="number"
        propertyName="height"
        setProp={setProp}
        craftProps={props}
        isFlex
        justify="space-between"
        margin="8px 0px"
      />
    </$Block>
  )
}
