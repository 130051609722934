import React from 'react'
import { useRouter } from 'hooks'
import { FlexBox, Grid } from 'styledComponent'
import { Text, Tabs, Button } from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { spacing } from 'design-system'
import { $MarginlessLine, $TabPadding } from '../Report.styled'
import { AddIcon } from 'assets/icons'

const ReportTopBar = () => {
  const { history } = useRouter()

  return (
    <>
      <Navbar heading="Reports" />
      <FlexBox mr={spacing.l} justify="space-between">
        <Text variant="h2" type="main" weight="bold" margin={spacing.l}>
          Reports
        </Text>
      </FlexBox>
      <$MarginlessLine />
      <Grid columns="auto auto" justify="space-between">
        <$TabPadding>
          <Tabs>
            <div label="All Reports" />
            {/* <div label="Recurring" /> */}
          </Tabs>
        </$TabPadding>
        <Button
          flex
          mr={spacing.l}
          startIcon={AddIcon}
          variant="primary"
          onClick={() => history.push('/reports/generate-report')}
        >
          <Text variant="p" type="white" weight="bold">
            Generate Report
          </Text>
        </Button>
      </Grid>
      <$MarginlessLine />
    </>
  )
}

export default ReportTopBar
