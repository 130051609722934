import styled from 'styled-components'
import { spacing } from 'design-system'
import { Carousel } from 'react-responsive-carousel'
import { FlexBox } from 'styledComponent'

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || 'auto'};
`

const $ProductTitleBlock = styled.ul`
  padding: 0;
  margin-top: ${spacing.l};
`

const $ProductTitleItem = styled.li`
  list-style: none;
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? '#EBF3FE' : theme.colors.white};
  transition: background-color 0.3s;
`

const $Carousel = styled(Carousel)`
  .slide {
    background: ${({ theme }) => theme.colors.white};
  }
`

const $Actions = styled.ul`
  padding: 0;
  display: flex;
  list-style: none;
  li {
    &:not(:last-child) {
      margin-right: ${spacing.m};
      cursor: pointer;
      svg {
        width: 42px;
        height: 42px;
      }
    }
    &:nth-child(2) {
      margin-right: ${spacing.l};
    }
  }
`

export { $FlexBox, $ProductTitleBlock, $ProductTitleItem, $Carousel, $Actions }
