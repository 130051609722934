import React from 'react'
import { useDispatch } from 'react-redux'

import { PillArray, Pill, Text } from 'components/Common'
import { spacing } from 'design-system'
import { AddIcon } from 'styledComponent'
import { useSelectStore } from 'hooks'
import { setAppliedFilter } from 'redux/actions/productAnalyticsActions'
import { $FlexBox } from '../../ProductAnalytics.styled'
import { $Button, $RemoveButton, $ContentDiv } from './FilterPills.styled'

/**
 * @description display all applied filters in pill fashion
 */
const FilterPills = React.forwardRef(({ action }, ref) => {
  const dispatch = useDispatch()

  const { data: appliedProductsFilter } = useSelectStore(
    'productAnalytics.appliedProductsFilter'
  )

  const onPillClose = (field) => {
    const filtersCopy = { ...appliedProductsFilter }

    delete filtersCopy[field]
    dispatch(setAppliedFilter(filtersCopy))

    action()
  }

  const _getActiveFilterLabels = () => {
    const activeFilters = Object.keys(appliedProductsFilter || {})?.reduce(
      (acc, curr) => {
        if (appliedProductsFilter[curr]) {
          const activeObj = {
            label: curr
              .replace(/([a-z])([A-Z])/g, '$1 $2')
              .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase()),
            id: curr,
          }
          acc.push(activeObj)
        }
        return acc
      },
      []
    )
    return activeFilters
  }

  const onRemoveAll = () => {
    dispatch(setAppliedFilter(null))
    ref.current.closeModal()
    action()
  }

  const activeFilters = _getActiveFilterLabels()

  if (!activeFilters?.length) return <div></div>

  return (
    <$FlexBox width="70%" aling="center">
      <Text>Filters : </Text>
      <PillArray width="80%" wrap="wrap">
        {activeFilters?.map(({ label, id }, index) => (
          <Pill
            key={index}
            margin={`0 ${spacing.s} ${spacing.s} 0`}
            variant="filter"
            type="tab"
            onClose={() => onPillClose(id)}
          >
            {label}
          </Pill>
        ))}
      </PillArray>
      <$ContentDiv>
        <$Button onClick={() => ref.current.openModal()}>
          <AddIcon />
        </$Button>
        <$RemoveButton variant="textualError" noBorder onClick={onRemoveAll}>
          Remove all filters
        </$RemoveButton>
      </$ContentDiv>
    </$FlexBox>
  )
})

export default FilterPills
