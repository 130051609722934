export const TEMPLATE_STEPS = [
  'Template Details',
  'Content',
  'Preview & Submit',
]

export const URL_REGEXP =
  /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const CONTENT_INPUT_PARAMS = {
  header: {
    maxChar: 60,
  },
  body: {
    maxChar: 1024,
    maxVar: 10,
  },
  footer: {
    maxChar: 60,
  },
  buttons: {
    maxVar: 1,
    maxChar: 32,
  },
  sampleContent: {
    maxChar: 32,
  },
}

export const TEMPLATE_TYPES = [
  { value: 'text', label: 'Text' },
  { value: 'media', label: 'Media' },
]

export const MEDIA_TYPES = [
  { value: 'document', label: 'Document' },
  { value: 'video', label: 'Video' },
  { value: 'image', label: 'Image' },
]

export const CATEGORY_TYPES = [
  { value: 'UTILITY', label: 'Utility' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'Authentication', label: 'Authentication' },
]

export const BUTTON_TYPES = [
  { value: 'none', label: 'None' },
  { value: 'quick-reply', label: 'Quick Reply' },
  { value: 'cta', label: 'Call To Action' },
]

export const CTA_TYPES = [
  { value: 'phone-number', label: 'Call phone number' },
  { value: 'website', label: 'Visit Website' },
]

export const WEBSITE_TYPES = [
  { value: 'static', label: 'Static' },
  { value: 'dynamic', label: 'Dynamic' },
]

export const DISCLAIMER_TEXT_PREVIEW = (
  <>
    <p>
      <strong>
        Please note that it is now mandatory to provide samples while creating
        Message Templates.
      </strong>
    </p>
    <p>
      <em>
        Samples are a way of providing an example of possible data for your
        template. This helps us during the review and approval process, so we
        can understand what kind of message you plan to send.
      </em>
    </p>
  </>
)

export const DISCLAIMER_TEXT_CONTENT = ''

export const ACCEPTED_FORMATS = {
  document: 'application/pdf',
  image: 'image/*',
  video: 'video/*',
}

export const LANGUAGES = [
  {
    label: 'Afrikaans (af)',
    value: 'af',
  },
  {
    label: 'Albanian (sq)',
    value: 'sq',
  },
  {
    label: 'Arabic (ar)',
    value: 'ar',
  },
  {
    label: 'Azerbaijani (az)',
    value: 'az',
  },
  {
    label: 'Bengali (bn)',
    value: 'bn',
  },
  {
    label: 'Bulgarian (bg)',
    value: 'bg',
  },
  {
    label: 'Catalan (ca)',
    value: 'ca',
  },
  {
    label: 'Chinese(CHN) (zh_CN)',
    value: 'zh_CN',
  },
  {
    label: 'Chinese(HKG) (zh_HK)',
    value: 'zh_HK',
  },
  {
    label: 'Chinese(TAI) (zh_TW)',
    value: 'zh_TW',
  },
  {
    label: 'Croatian (hr)',
    value: 'hr',
  },
  {
    label: 'Czech (cs)',
    value: 'cs',
  },
  {
    label: 'Danish (da)',
    value: 'da',
  },
  {
    label: 'Dutch (nl)',
    value: 'nl',
  },
  {
    label: 'English (en)',
    value: 'en',
  },
  {
    label: 'English(UK) (en_GB)',
    value: 'en_GB',
  },
  {
    label: 'English(US) (en_US)',
    value: 'en_US',
  },
  {
    label: 'Estonian (et)',
    value: 'et',
  },
  {
    label: 'Filipino (fil)',
    value: 'fil',
  },
  {
    label: 'Finnish (fi)',
    value: 'fi',
  },
  {
    label: 'French (fr)',
    value: 'fr',
  },
  {
    label: 'German (de)',
    value: 'de',
  },
  {
    label: 'Greek (el)',
    value: 'el',
  },
  {
    label: 'Gujarati (gu)',
    value: 'gu',
  },
  {
    label: 'Hausa (ha)',
    value: 'ha',
  },
  {
    label: 'Hebrew (he)',
    value: 'he',
  },
  {
    label: 'Hindi (hi)',
    value: 'hi',
  },
  {
    label: 'Hungarian (hu)',
    value: 'hu',
  },
  {
    label: 'Indonesian (id)',
    value: 'id',
  },
  {
    label: 'Irish (ga)',
    value: 'ga',
  },
  {
    label: 'Italian (it)',
    value: 'it',
  },
  {
    label: 'Japanese (ja)',
    value: 'ja',
  },
  {
    label: 'Kannada (kn)',
    value: 'kn',
  },
  {
    label: 'Kazakh (kk)',
    value: 'kk',
  },
  {
    label: 'Korean (ko)',
    value: 'ko',
  },
  {
    label: 'Lao (lo)',
    value: 'lo',
  },
  {
    label: 'Latvian (lv)',
    value: 'lv',
  },
  {
    label: 'Lithuanian (lt)',
    value: 'lt',
  },
  {
    label: 'Macedonian (mk)',
    value: 'mk',
  },
  {
    label: 'Malay (ms)',
    value: 'ms',
  },
  {
    label: 'Malayalam (ml)',
    value: 'ml',
  },
  {
    label: 'Marathi (mr)',
    value: 'mr',
  },
  {
    label: 'Norwegian (nb)',
    value: 'nb',
  },
  {
    label: 'Persian (fa)',
    value: 'fa',
  },
  {
    label: 'Polish (pl)',
    value: 'pl',
  },
  {
    label: 'Portuguese(BR) (pt_BR)',
    value: 'pt_BR',
  },
  {
    label: 'Portuguese(POR) (pt_PT)',
    value: 'pt_PT',
  },
  {
    label: 'Punjabi (pa)',
    value: 'pa',
  },
  {
    label: 'Romanian (ro)',
    value: 'ro',
  },
  {
    label: 'Russian (ru)',
    value: 'ru',
  },
  {
    label: 'Serbian (sr)',
    value: 'sr',
  },
  {
    label: 'Slovak (sk)',
    value: 'sk',
  },
  {
    label: 'Slovenian (sl)',
    value: 'sl',
  },
  {
    label: 'Spanish (es)',
    value: 'es',
  },
  {
    label: 'Spanish(ARG) (es_AR)',
    value: 'es_AR',
  },
  {
    label: 'Spanish(SPA) (es_ES)',
    value: 'es_ES',
  },
  {
    label: 'Spanish(MEX) (es_MX)',
    value: 'es_MX',
  },
  {
    label: 'Swahili (sw)',
    value: 'sw',
  },
  {
    label: 'Swedish (sv)',
    value: 'sv',
  },
  {
    label: 'Tamil (ta)',
    value: 'ta',
  },
  {
    label: 'Telugu (te)',
    value: 'te',
  },
  {
    label: 'Thai (th)',
    value: 'th',
  },
  {
    label: 'Turkish (tr)',
    value: 'tr',
  },
  {
    label: 'Ukrainian (uk)',
    value: 'uk',
  },
  {
    label: 'Urdu (ur)',
    value: 'ur',
  },
  {
    label: 'Uzbek (uz)',
    value: 'uz',
  },
  {
    label: 'Vietnamese (vi)',
    value: 'vi',
  },
  {
    label: 'Zulu (zu)',
    value: 'zu',
  },
]
