import React, { useState } from 'react'
import { connect } from 'react-redux'

import Navbar from '../../Layout/Navbar'
import { $WhiteContainer } from '../../Common/WhiteContainer'
import { $MarginlessLine } from '../autoEngagement/AutoEngagement.styled'
import Header from './Header'
import { Tabs } from 'components/Common'
import UsageContent from './UsageContent'

import { OVERALL_SUMMARY, PROMOTION } from './constants'

function UsageAndInvoices(props) {
  const { usageTab, companyId } = props
  const [tab, setActiveTab] = useState('Usage')
  const viewType = usageTab.viewType

  return (
    <>
      <Navbar heading="Usage & Invoices" />
      <$WhiteContainer>
        <Header
          showStoreHeading={
            tab === 'Usage' &&
            usageTab.viewType !== OVERALL_SUMMARY &&
            usageTab.viewType !== PROMOTION
          }
          companyId={companyId}
        />
        <$MarginlessLine mb="0" />
        <Tabs
          setActiveTab={(tab) => {
            setActiveTab(tab)
          }}
          tabMenuStyles={{
            borderBottom: '1px solid #0000001a',
          }}
        >
          <div label="Usage">
            <UsageContent
              filters={usageTab.filters}
              viewType={viewType}
              search={usageTab.search}
              companyId={companyId}
              data={usageTab.data}
              selectedStoreIds={usageTab.storeIds}
              sortFilters={usageTab.sortFilters}
            />
          </div>
        </Tabs>
      </$WhiteContainer>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    usageTab: {
      ...state.usageAndInvoice.usageTab,
    },
    companyId: state?.company?.selectedCompanies[0],
  }
}

export default connect(mapStateToProps)(UsageAndInvoices)
