import React from 'react'

import { useToasts } from 'components/Common/Toast'
import { Text } from 'components/Common/Text'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import { Input } from 'components/Common/Input'
import { PreviewPane } from './PopupPreviewAndPublish'

import { $PublishButton } from '../AutoEngagement.styled'
import { Checkbox } from 'components/Common/Input'
import { Tooltip } from 'components/Common'
import { ColorPicker } from 'components/Common/ColorPicker'

const showPopupOptions = {
  immediately: {
    label: 'Immediately',
    value: 'immediately',
  },
  after: {
    label: 'After',
    value: 'after',
  },
}

export const PopupSettings = ({ form, setForm }) => {
  const { toast } = useToasts()
  const isAddButtonActive = !!form?.addButton

  React.useEffect(() => {
    if (
      !form?.content?.setting?.visibility > 0 &&
      !form?.content?.setting?.closeBtnDelay > 0
    )
      setForm({
        ...form,
        content: {
          ...form?.content,
          setting: {
            visibility: 0,
            closeBtnDelay: 0,
          },
        },
      })
  }, [])

  React.useEffect(() => {
    const removeBtnDetails = () => delete form?.content?.setting?.btnDetails

    if (
      !form?.content?.setting?.btnDetails?.txt &&
      !form?.content?.setting?.btnDetails?.txtColor &&
      !form?.content?.setting?.btnDetails?.bgColor &&
      !form?.content?.setting?.btnDetails?.link
    )
      removeBtnDetails()

    if (!form?.addButton) removeBtnDetails()
  }, [
    form?.content?.setting?.btnDetails?.txt,
    form?.content?.setting?.btnDetails?.txtColor,
    form?.content?.setting?.btnDetails?.bgColor,
    form?.content?.setting?.btnDetails?.link,
    form?.addButton,
  ])

  const linkRedirectHandler = (e) => {
    const buttonLink = form?.content?.setting?.btnDetails?.link
    e.preventDefault()

    if (!buttonLink) {
      toast('Please enter a valid link', 'error')
      return
    }

    if (!buttonLink.includes('http')) {
      toast('Please add http / https', 'error')
      return
    }

    if (buttonLink) window.open(buttonLink, '_blank')
  }

  const handleShowDelayChange = (selected, key) => {
    if (selected === showPopupOptions.immediately.value)
      setForm({
        ...form,
        content: {
          ...form?.content,
          setting: {
            ...form?.content?.setting,
            [key]: 0,
          },
        },
      })
    else
      setForm({
        ...form,
        content: {
          ...form?.content,
          setting: {
            ...form?.content?.setting,
            [key]: 1,
          },
        },
      })
  }

  const handleShowDelayTimeChange = (value, key) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        setting: {
          ...form?.content?.setting,
          [key]: Number(value),
        },
      },
    })
  }

  const handleButtonProp = (e) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        setting: {
          ...form?.content?.setting,
          btnDetails: {
            ...form?.content?.setting?.btnDetails,
            [e.target.id]: e.target.value,
          },
        },
      },
    })
  }

  const handleButtonSelectProp = (value, key) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        setting: {
          ...form?.content?.setting,
          btnDetails: {
            ...form?.content?.setting?.btnDetails,
            [key]: value,
          },
        },
      },
    })
  }

  const handleAddButtonChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.checked,
    })
  }

  return (
    <FlexBox>
      <div style={{ flexBasis: '70%', marginRight: spacing.xl }}>
        <Text variant="medium" type="main" mb={spacing.l}>
          Show Popup
        </Text>
        <FlexBox align="center" mb={spacing.l}>
          <Input
            variant="radio"
            direction="row"
            options={[
              { ...showPopupOptions.immediately },
              { ...showPopupOptions.after },
            ]}
            onChange={(selected) =>
              handleShowDelayChange(selected, 'visibility')
            }
            containerMargin="0px"
            gap={spacing.l}
            value={
              form?.content?.setting?.visibility == 0
                ? showPopupOptions.immediately.value
                : showPopupOptions.after.value
            }
          />
          <Input
            type="number"
            variant="input"
            id="show-popup-after"
            inputWidth="100px"
            containerMargin="0px"
            disabled={form?.content?.setting?.visibility == 0}
            onChange={(e) =>
              handleShowDelayTimeChange(e.target.value, 'visibility')
            }
            value={form?.content?.setting?.visibility}
          />
          <Text variant="small" type="grey" ml={spacing.m}>
            Second(s)
          </Text>
        </FlexBox>
        <Text variant="medium" type="main" mb={spacing.l}>
          Show Close Button After
        </Text>
        <FlexBox>
          <Input
            variant="radio"
            direction="row"
            options={[
              { ...showPopupOptions.immediately },
              { ...showPopupOptions.after },
            ]}
            onChange={(selected) =>
              handleShowDelayChange(selected, 'closeBtnDelay')
            }
            containerMargin="0px"
            gap={spacing.l}
            value={
              form?.content?.setting?.closeBtnDelay == 0
                ? showPopupOptions.immediately.value
                : showPopupOptions.after.value
            }
          />
          <Input
            type="number"
            variant="input"
            id="show-popup-after"
            inputWidth="100px"
            containerMargin="0px"
            disabled={form?.content?.setting?.closeBtnDelay == 0}
            onChange={(e) =>
              handleShowDelayTimeChange(e.target.value, 'closeBtnDelay')
            }
            value={form?.content?.setting?.closeBtnDelay}
          />
          <Text variant="small" type="grey" ml={spacing.m}>
            Second(s)
          </Text>
        </FlexBox>
        <div>
          <Checkbox
            id="addButton"
            onCheckboxChange={handleAddButtonChange}
            isChecked={!!form?.addButton}
          >
            <FlexBox align="center">
              <Text variant="medium">Add Button</Text>
              <Tooltip content={'Some Content'} />
            </FlexBox>
          </Checkbox>
          <FlexBox justify="space-between">
            <Input
              label="Button Text"
              variant="input"
              type="text"
              id="txt"
              value={form?.content?.setting?.btnDetails?.txt || ''}
              onChange={handleButtonProp}
              placeholder="Visit Our Website"
              inputWidth="250px"
              subLabel="(Character Limit = 30)"
              disabled={!isAddButtonActive}
            />

            <Input label="Button Text Color">
              <ColorPicker
                color={form?.content?.setting?.btnDetails?.txtColor}
                setColor={(value) => handleButtonSelectProp(value, 'txtColor')}
                disabledButton={!isAddButtonActive}
              />
            </Input>

            <Input label="Button Color">
              <ColorPicker
                color={form?.content?.setting?.btnDetails?.bgColor}
                setColor={(value) => handleButtonSelectProp(value, 'bgColor')}
                disabledButton={!isAddButtonActive}
              />
            </Input>
          </FlexBox>
          <FlexBox align="last baseline">
            <Input
              label="Button Link"
              variant="input"
              type="text"
              id="link"
              onChange={handleButtonProp}
              placeholder="Button URL"
              inputWidth="600px"
              value={form?.content?.setting?.btnDetails?.link || ''}
              containerMargin="0px"
              disabled={!isAddButtonActive}
            />
            <$PublishButton
              noBorder
              mb="12px"
              fontSize="12px"
              variant="textualPrimary"
              onClick={linkRedirectHandler}
              margin="0px"
            >
              Test Link
            </$PublishButton>
          </FlexBox>
        </div>
      </div>
      <div style={{ flexBasis: '30%' }}>
        <PreviewPane form={form} />
      </div>
    </FlexBox>
  )
}
