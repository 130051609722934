import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { $WhiteContainer } from '../../AutoEngagement.styled'
import TemplateTable from './table'
import useTemplateApi from './useTemplateApi'

const Template = () => {
  const selectedVendor = useSelector((state) => state.vendorIds.selectedVendor)
  const autoEngage = useSelector((state) => state.autoEngage)
  const { aeTableLoading, whatsAppTemplates } = autoEngage

  const [skip, setSkip] = useState(0)
  const [triggers, setTriggers] = useState({})

  useTemplateApi(selectedVendor.value, skip, triggers)

  const pageCount =
    parseInt(whatsAppTemplates.count / 10) +
    (parseInt(whatsAppTemplates.count % 10) > 0 ? 1 : 0)

  return (
    <TemplateTable
      pageNumber={skip / 10}
      tableLoading={aeTableLoading}
      onPaginationChange={(page) => setSkip(page.selected * 10)}
      templateList={whatsAppTemplates.docs}
      pageCount={pageCount}
      setTriggers={setTriggers}
    />
  )
}

export default Template
