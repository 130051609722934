import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedNumber } from 'react-intl'

import { color, spacing } from 'design-system/styles'
import { FlexBox } from 'styledComponent'

/**
 * @description helper function to determine maximum dynamic label width needed.
 * @param {Array<Object>} data - chart data
 * @returns {String} - width in pixels
 */
const calcLabelWidth = (data) => {
  let width = 30
  if (!data) return width + 'px'
  const span = document.createElement('span')
  data.forEach(({ label }) => {
    span.innerHTML = label
    document.body.appendChild(span)
    const offsetWidth = span.offsetWidth
    if (offsetWidth > width) {
      width = offsetWidth
    }
    document.body.removeChild(span)
  })
  return width + 10 + 'px'
}

/**
 * @description common histogram chart component.
 * @param {Array<Object>} data - chart data
 * @param {String} color - bar color
 * @param {Object} startIcon - icon component
 * @param {Object} endIcon - icon component
 * @param {String} width - chart width.
 */
function Histogram({ data, color, startIcon, endIcon, width, padding }) {
  const labelWidth = calcLabelWidth(data)
  return (
    <Chart width={width} padding={padding}>
      {data.map(({ label, value }, index) => {
        const percentValue = parseInt(value * 100).toFixed(0)
        return (
          <ChartItem key={index}>
            <Label className="bar-title" width={labelWidth} align="center">
              {startIcon && startIcon}
              <span>{label}</span>
              {endIcon && endIcon}
            </Label>
            <BarBlock>
              <Bar color={color} width={percentValue} />
            </BarBlock>
            <BarValue>
              <FormattedNumber
                style="percent"
                value={value}
                minimumFractionDigits={0}
              />
            </BarValue>
          </ChartItem>
        )
      })}
    </Chart>
  )
}

Histogram.prototype = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
}

Histogram.defaultProps = {
  width: '50%',
  color: color.primary.default,
}

export { Histogram as default }

const Chart = styled.ul`
  list-style: none;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding || 0};
`

const ChartItem = styled.li`
  display: flex;
  margin: ${spacing.m} 0;
  align-items: center;
`

const BarValue = styled.span`
  text-align: right;
  font-weight: 900;
  font-size: 15px;
  width: 50px;
`

const Label = styled(FlexBox)`
  width: ${(props) => props.width};
  text-align: left;
  font-size: 15px;
  color: ${color.medium};
  font-weight: 900;
  text-transform: capitalize;
  svg {
    margin: 0 ${spacing.s};
  }
`

const BarBlock = styled.div`
  width: 100px;
  flex-grow: 1;
  background-color: #fff;
  position: relative;
  height: 20px;
  padding: 0 ${spacing.m};
`

const Bar = styled.div`
  width: ${(props) =>
    props.width ? (props.width <= 100 ? props.width + '%' : '100%') : '0'};
  background-color: ${(props) => props.color};
  position: absolute;
  top: 0;
  bottom: 0;
`
