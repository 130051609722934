import React from 'react'

import { CampaignDetails } from '../components'
import { Audience } from '../components'
import { default as BannerBuilder } from './BannerBuilder'
import { BannerPreview } from './BannerPreview'
import { BannerSettings } from './BannerSettings'

export const BannerCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          campaignType="'Banner in Bill'"
          form={form}
          setForm={setForm}
        />
      )

    case 'Banner Builder':
      return <BannerBuilder form={form} setForm={setForm} />

    case 'Audience':
      return <BannerSettings form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <BannerPreview form={form} hideSteps={hideSteps} />

    default:
      return null
  }
}
