import React from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from 'hooks'
import { color, spacing, borderRadius } from 'design-system/styles'
import { Button } from 'components/Common'
import { ReactComponent as DownCaret } from 'assets/icons/down-caret.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add-blue.svg'

const $Dropdown = styled.div`
  position: relative;
  width: ${(props) => (props.blockWidth ? props.blockWidth : 'auto')};
  div {
    background: ${color.white};
    box-shadow: 0px 2px 10px ${color.alto};
    border-radius: ${borderRadius.default};
    width: ${(props) => (props.width ? props.width : '138px')};
    padding: ${spacing.m};
    z-index: 15;
    position: absolute;
    top: 48px;
    p {
      height: 40px;
      padding: ${spacing.ml} ${spacing.m};
      cursor: pointer;
      :hover {
        background-color: ${color.solitude};
        color: ${color.dark};
      }
    }
  }
  .a,
  path {
    stroke: none;
    fill: white !important;
  }
`

export const ButtonDropdown = ({
  buttonText,
  children,
  startIcon,
  width,
  blockWidth,
}) => {
  const ref = React.useRef()
  const [showDropdown, setShowDropdown] = React.useState(false)

  useOnClickOutside(ref, () => setShowDropdown(false))

  return (
    <$Dropdown ref={ref} width={width} blockWidth={blockWidth}>
      <Button
        startIcon={startIcon && AddIcon}
        variant="primary"
        iconWidth="10px"
        onClick={() => setShowDropdown(true)}
        endIcon={DownCaret}
      >
        {buttonText}
      </Button>
      {showDropdown && <div>{children}</div>}
    </$Dropdown>
  )
}
