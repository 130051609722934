import React, { Component } from "react";

class BillingInfoCardCircle extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    this.props.onClick();
  }
  render() {
    return (
      <div className="billingInfoCardCircle" onClick={this.onClick}>
        <img
          className="infoCardCircleImage"
          src={require("" + this.props.imageName + "")}
          alt=""
        />
        <div className="circleCardName1">{this.props.circleCardName1}</div>
        <div className="circleCardName2">{this.props.circleCardName2}</div>
      </div>
    );
  }
}

export default BillingInfoCardCircle;
