import React, { useEffect, useState } from 'react'
import DateRangePicker from 'react-daterange-picker'
import * as moment from 'moment'
import { useRouter } from 'hooks'
import cloneDeep from 'lodash/cloneDeep'
import { FilterFooter } from 'components/Common'
import { FilterHeader } from 'components/Common/FilterHeader'
import CustomPopup from 'components/Content/CustomPopup'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import { Checkbox, Pill, Text, Button } from 'components/Common'
import 'react-daterange-picker/dist/css/react-calendar.css'

import {
  INIT_FILTER,
  POPUP_STYLE,
  CAMP_STATES,
  GET_SEL_VALUE,
} from '../../autoEngagement/constants'
import {
  $Filter,
  $ActiveFilter,
  $SelectedFilter,
} from '../../autoEngagement/AutoEngagement.styled'

const getPillData = (type, value) => {
  switch (type) {
    case 'timeFrame':
      const [start, end] = value.toString().split('/')
      return (
        <>
          {moment(start).format('DD MMM YYYY')}
          <br />
          {moment(end).format('DD MMM YYYY')}
        </>
      )
    case 'status':
      return value.length < 2
        ? GET_SEL_VALUE(value[0], CAMP_STATES)?.label || ''
        : value.length
  }
}

const JourneyFilter = React.forwardRef(({ data, handleFilter }, ref) => {
  const [filter, setFilter] = useState(data)
  const [selectedFilter, setSelectedFilter] = React.useState({ id: 2 })

  const { location } = useRouter()
  const routeId = location.pathname.split('/')[2]

  useEffect(() => setFilter(data), [data])
  useEffect(() => setSelectedFilter({ id: 2 }), [routeId])

  const onHandleFilter = () => {
    handleFilter(filter)
    ref.current.closeModal()
  }

  const handleChange = (field, value) => {
    const copyFilter = cloneDeep(filter)
    copyFilter[field].isActive = true
    copyFilter[field].value = value
    setFilter(copyFilter)
  }

  const onCheckboxChange = (field, value) => {
    const copyFilter = cloneDeep(filter)
    copyFilter[field].isActive = true
    if (copyFilter[field].value.includes(value)) {
      copyFilter[field].value = copyFilter[field].value.filter(
        (val) => val !== value
      )
    } else {
      copyFilter[field].value.push(value)
    }
    if (copyFilter[field].value?.length === 0) {
      copyFilter[field].isActive = false
    }
    setFilter(copyFilter)
  }

  const handleClosePill = (pillToDelete) => () => {
    const copyFilter = cloneDeep(filter)
    const defaultFilter = cloneDeep(INIT_FILTER)[pillToDelete]
    copyFilter[pillToDelete] = defaultFilter
    setFilter(copyFilter)
  }

  const getCurrentFilter = (id) => {
    switch (id) {
      case 2:
        return (
          <FlexBox direction="column">
            <Text as="label" variant="small" mb={spacing.m}>
              {filter.status.label} :
            </Text>
            <FlexBox direction="column" gap={spacing.m}>
              {CAMP_STATES.map((status) => (
                <Checkbox
                  key={status.value}
                  id={status.label}
                  isChecked={filter.status.value.includes(status.value)}
                  onCheckboxChange={() =>
                    onCheckboxChange('status', status.value)
                  }
                >
                  {status.label}
                </Checkbox>
              ))}
            </FlexBox>
          </FlexBox>
        )
      case 3:
        return (
          <FlexBox align="center" direction="column">
            <FlexBox gap={spacing.xl}>
              <Text as="label" variant="small">
                {filter.timeFrame.label} :
              </Text>
              <Button variant="primaryOutline" fontSize="12px">
                <i className="fas fa-calendar-alt" /> &nbsp;
                {moment(filter.timeFrame?.value?.start).format('DD MMM YYYY') +
                  ' - ' +
                  moment(filter.timeFrame?.value?.end).format('DD MMM YYYY')}
              </Button>
            </FlexBox>
            <DateRangePicker
              numberOfCalendars={2}
              onSelect={(val) => handleChange('timeFrame', val)}
              value={filter.timeFrame.value}
            />
          </FlexBox>
        )
    }
  }

  return (
    <CustomPopup
      className="filter-modal"
      contentStyle={{ ...POPUP_STYLE, width: '930px' }}
      ref={ref}
      headerComponent
      heading={<FilterHeader heading="Journey Filter" />}
      footer={
        <FilterFooter ref={ref} filter={filter} handleFilter={onHandleFilter} />
      }
    >
      <$Filter>
        <FlexBox>
          <FlexBox direction="column">
            {Object.values(filter).map(
              (item) =>
                !(item.type === 'types' && routeId) && (
                  <$SelectedFilter
                    key={item.id}
                    onClick={() => {
                      setSelectedFilter(item)
                    }}
                    isActive={item.id === selectedFilter.id}
                    isFilterActive={item.isActive}
                  >
                    {item.label}
                    {item.isActive && (
                      <Pill
                        type="tab"
                        onClose={handleClosePill(item.type)}
                        marginLeft={spacing.m}
                      >
                        {getPillData(item.type, item.value)}
                      </Pill>
                    )}
                  </$SelectedFilter>
                )
            )}
          </FlexBox>
          {Object.values(filter).map(
            (item) =>
              item.id === selectedFilter.id && (
                <$ActiveFilter key={item.id}>
                  <FlexBox expand padding={`${spacing.l}`}>
                    {getCurrentFilter(item.id)}
                  </FlexBox>
                </$ActiveFilter>
              )
          )}
        </FlexBox>
      </$Filter>
    </CustomPopup>
  )
})

export default JourneyFilter
