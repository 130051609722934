import styled, { css } from 'styled-components'
import { FlexBox } from 'styledComponent'

export const $SurveyContainer = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  border-radius: 8px;
  border: ${(props) => props.showBorder && '1px solid lightgray'};
  margin: 10px auto;
  padding: ${(props) => (props.showBorder || props.toolbox) && '10px'};
  text-align: center;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  background: ${(props) =>
    props.toolbox ? props.theme.colors.aquaHaze : props.theme.colors.white};
  input[type='checkbox'],
  input[type='radio'] {
    width: 18px;
    height: 18px;
  }
`

export const $QuestButton = styled.button`
  background-color: #2680eb;
  color: #fff;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
`

export const $SurveyHeader = styled.div`
  background-color: ${(props) => props.showBorder && props.theme.colors.white};
  border-radius: 8px;
  border: ${(props) => props.showBorder && '1px solid lightgray'};
  text-align: center;
  color: #34475b;
  margin-top: 12px;
  padding: 16px;
  position: relative;
  h1,
  h4 {
    margin: 12px;
  }
  svg.close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`

export const $QuestContainer = styled.div`
  background-color: ${(props) => props.showBorder && props.theme.colors.white};
  text-align: left;
  border-radius: 8px;
  border: ${(props) => props.showBorder && '1px solid lightgray'};
  margin: ${(props) => (props.showBorder ? '20px' : '0 20px')};
  padding: ${(props) => props.showBorder && '5px 20px 20px'};
  p {
    // flex: 1;
    font-weight: 600;
    margin: 0 0 8px;
  }
  span {
    margin: 5px;
  }
  input[type='checkbox'],
  input[type='radio'] {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    cursor: pointer;
  }
  input[type='color' i] {
    border-width: 0;
    cursor: pointer;
    padding: 0;
  }
`

export const $OpinionScaleBox = styled.span`
  border: 1px solid lightgray;
  margin-right: 20px;
  display: inline-flex;
  background-color: white;
  border-radius: 4px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      background-color: #2680eb;
      border-color: #2680eb;
      color: #fff;
      box-shadow: rgba(38, 128, 235, 0.35) 0px 6px 10px;
    `};
  span {
    margin: 0 !important;
  }
`

export const $Rating = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 2px 4px !important;
`

export const $OptButton = styled.button`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  cursor: pointer;
  align-items: center;
  gap: 8px;
  color: ${(props) => (props.red ? '#cf1517' : '#3b86ff')};
  border: none;
  padding: 8px;
  font-weight: bold;
  font-size: 12px;
  background-color: transparent;
  svg {
    width: 12px;
    height: 12px;
  }
  &:focus {
    outline: none;
  }
`

export const $QuestSerial = styled(FlexBox)`
  position: absolute;
  border-radius: 50%;
  color: #3b86ff;
  border: 1px solid lightgray;
  background-color: #fff;
  left: 8px;
  top: -8px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
`

export const $SurveyInput = styled.input`
  width: ${(props) => (props.width ? props.width : '32px')};
  height: 32px;
  padding: ${(props) => (props.padding ? props.padding : '5px')};
  border: 1px solid lightgray;
  border-radius: 2px;
`

export const $SurveySelect = styled.select`
  width: ${(props) => (props.width ? props.width : '150px')};
  height: 32px;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 2px;
  background-color: #fff;
  option {
    font-size: 18px;
  }
`

export const $DraggerIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 30px;
  width: 10px;
`
export const $ColorBox = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${(props) => props.bgrdColor};
  border-radius: 4px;
  margin-bottom: 12px;
`

export const $ColorInput = styled.div`
  text-align: center;
  position: relative;
  margin-top: 10px;
  input {
    height: 40px;
    width: 100px;
  }
  label {
    position: absolute;
    top: 7px;
    left: 45%;
    font-size: 18px;
    pointer-events: none;
  }
`

export const $MoreOptions = styled.div`
  position: absolute;
  bottom: 12px;
  right: 40px;
  :hover {
    z-index: 4;
  }
  > svg {
    width: 74px;
    height: 30px;
    padding: 10px 10px 10px 40px;
    border-radius: 2px;
    cursor: pointer;
  }
  div {
    position: absolute;
    width: 200px;
    left: -180px;
    top: -8px;
    padding: 12px;
    background-color: #fff;
    display: none;
    flex-direction: column;
    text-align: left;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 8px;
    span {
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      color: grey;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      :hover {
        background-color: ${(props) => props.theme.colors.aquaHaze};
      }
    }
  }
  :hover {
    > svg {
      border-color: #2680eb;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 8px;
      background-color: #fff;
    }
    div {
      display: flex;
    }
    small {
      display: flex;
      display: block;
      position: absolute;
      width: 20px;
      height: 30px;
      background-color: #fff;
      top: 0;
      left: 10px;
    }
  }
`

export const $ToolGroup = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 2px;
  height: 30px;
`

export const $ToolItem = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${(props) => (props.isActive ? 'grey' : 'white')};
  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 4px;
  }
  :focus {
    outline: none;
    border: none;
  }
`

export const $SelectContainer = styled.div`
  > div {
    padding: 0 !important;
    label {
      font-weight: bold;
      font-size: 16px;
      width: 100%;
    }
  }
`

export const $ImageSelect = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 4px;
  cursor: pointer;
  object-fit: contain;
`

export const $Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #3b85fa;
  width: 100%;
  height: 136px;
  cursor: pointer;
  border: 1px dashed lightgray;
  border-radius: 4px;
  font-size: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
`

export const $BannerContainer = styled.div`
  position: relative;
  text-align: center;
  img {
    max-width: 100%;
    max-height: 170px;
    max-width: 100%;
    object-fit: contain;
  }
  svg {
    position: absolute;
    right: 0;
    background: #fff;
    margin: 2px;
  }
`

export const $Submit = styled.button`
  background-color: ${(props) => props.bgColor || '#FFF'};
  color: ${(props) => props.textColor || '#000'};
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  border: none;
  padding: 8px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 35%) 0px 6px 10px;
  font-size: 20px;
  width: ${(props) => props.width || '120px'};
  margin: ${(props) => (props.isSinglePage ? '30px 0 20px' : '0 0 20px')};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
  }
  &:active {
    box-shadow: inset rgb(0 0 0 / 35%) 0px 2px 5px;
  }
`

export const $HeaderButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${(props) => (props.red ? '#cf1517' : '#3b86ff')};
  border: none;
  padding: 16px;
  font-weight: bold;
  font-size: 24px;
  background-color: transparent;
  margin: auto;
  svg {
    width: 20px;
    height: 20px;
  }
  &:focus {
    outline: none;
  }
`

export const $ImageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 10px 20px 0;
  position: relative;
  input {
    width: 100px;
    margin-top: 6px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #e6e6e6;
    padding: 0 8px;
    text-align: center;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    position: relative;
    > div {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 12px 0px;
      padding: 8px;
      background-color: #fff;
      svg.sic {
        margin: 12px 0 8px;
        cursor: pointer;
      }
      ${(props) =>
        props.isChecked &&
        css`
          background-color: #2680eb;
          svg.sic {
            path {
              fill: #fff;
            }
          }
        `};
      &:hover {
        background-color: #2680eb;
        svg.sic {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
`
