import React from 'react'
import { useNode } from '@craftjs/core'

import { ButtonSettings } from './ButtonSettings'

export const Button = (props) => {
  const {
    connectors: { connect },
    isSelected,
  } = useNode((state) => ({
    isSelected: state.events.selected,
  }))

  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = props
  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const {
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props
  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  return (
    <div style={{ textAlign: props.textAlign }} ref={(ref) => connect(ref)}>
      <a
        style={{ cursor: 'pointer' }}
        href={!isSelected && props.url}
        target="_blank"
        rel="noopener"
      >
        <button
          style={{ ...props, textAlign: 'center', padding, borderRadius }}
          surveyName={props.surveyName}
        >
          {props.buttonText}
        </button>
      </a>
    </div>
  )
}

export const ButtonDefaultProps = {
  pointerEvents: 'none',
  border: 'none',
  background: '#3d87ff',
  width: '200px',
  height: '50px',
  color: '#ffffff',
  borderWidth: '0px',
  borderStyle: 'solid',
  borderColor: '#000',
  buttonText: 'Button',
  fontSize: '16px',
  fontWeight: 'normal',
  textAlign: 'center',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  surveyName: '',
}

Button.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
}
