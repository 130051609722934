import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { spacing, color } from 'design-system/styles'
import BcSeparator from '../assets/breadcrumb-separator.svg'

const StyledBreadCrumb = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin: 0px ${spacing.m};
      a,
      span {
        color: ${color.primary.light};
        text-decoration: none;
        font-size: 0.9em;

        &:hover {
          color: ${color.primary.dark};
        }
      }

      &:first-child {
        margin-left: 0px;
      }

      /* Active state */
      &:last-child {
        margin-right: 0px;

        span {
          color: ${color.primary.default};
        }
      }

      /* Adding separator */
      &:not(:last-child):after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        background: url(${BcSeparator}) no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        margin-left: ${spacing.l};
      }
    }
  }
`

export const BreadCrumb = ({ children }) => {
  return (
    <StyledBreadCrumb>
      <ul>{children}</ul>
    </StyledBreadCrumb>
  )
}

export const BreadCrumbItem = ({ href, children, isCurrentPage }) => {
  return (
    <li>
      {isCurrentPage ? (
        <span>{children}</span>
      ) : (
        <Link to={href}>{children}</Link>
      )}
    </li>
  )
}
