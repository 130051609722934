import {
  AdBelowIcon,
  AllCustIcon,
  BillMonitorIcon,
  CustomerSegmentIcon,
  EmailJourneyIcon,
  EventOccurenceIcon,
  PopOverJourneyIcon,
  SellBelowIcon,
  SendBannerIcon,
  SMSJourneyIcon,
  SpecificCustomerIcon,
  SurveyJourneyIcon,
  WaitIcon,
  WhatsAppJourneyIcon,
} from 'assets/icons'
import { Text } from 'components/Common/Text'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'
import { $Dropdown } from '../../Journey.styled'

const componentsBarJson = [
  {
    title: 'Start',
    components: [
      {
        name: 'Event Occurence',
        id: 'event_occurence',
        classname: 'start',
        icon: <EventOccurenceIcon />,
      },
    ],
  },
  {
    title: 'Customers',
    components: [
      {
        name: 'Customer Segment',
        id: 'filter_customerSegment',
        classname: 'customer',
        icon: <CustomerSegmentIcon />,
      },
      {
        name: 'Specific Customer',
        id: 'filter_customerSpecific',
        classname: 'customer',
        icon: <SpecificCustomerIcon />,
      },
      {
        name: 'All Customers',
        id: 'filter_customerAll',
        classname: 'customer',
        icon: <AllCustIcon />,
      },
    ],
  },
  {
    title: 'Engagement (Digital Bill)',
    components: [
      {
        name: 'Bill Monitor',
        id: 'filter_billMonitoring',
        classname: 'digital',
        icon: <BillMonitorIcon />,
      },
      {
        name: 'Ad Below',
        id: 'filter_adBelow',
        classname: 'digital',
        icon: <AdBelowIcon />,
      },
      {
        name: 'Sell Below',
        id: 'filter_sellBelow',
        classname: 'digital',
        icon: <SellBelowIcon />,
      },
      {
        name: 'Banner',
        id: 'filter_banner',
        classname: 'digital',
        icon: <SendBannerIcon />,
      },
      {
        name: 'Survey',
        id: 'filter_survey',
        classname: 'digital',
        icon: <SurveyJourneyIcon />,
      },
      {
        name: 'Pop Over',
        id: 'filter_popOver',
        classname: 'digital',
        icon: <PopOverJourneyIcon />,
      },
    ],
  },
  {
    title: 'Engagement (3rd Party)',
    components: [
      {
        name: 'Send SMS',
        id: 'action_sendSMS',
        classname: 'party',
        icon: <SMSJourneyIcon />,
      },
      {
        name: 'Send Email',
        id: 'action_sendEmail',
        classname: 'party',
        icon: <EmailJourneyIcon />,
      },
      {
        name: 'Send WhatsApp',
        id: 'action_sendWhatsApp',
        classname: 'party',
        icon: <WhatsAppJourneyIcon />,
      },
    ],
  },
  {
    title: 'Time Regulator',
    components: [
      {
        name: 'Wait For Some Time',
        id: 'action_waitDays',
        classname: 'time',
        icon: <WaitIcon />,
      },
    ],
  },
]

export const ComponentsBar = () => {
  const { spacing, colors } = useTheme()

  const onComponentDrag = (e, box) => {
    e.dataTransfer.setData('nodeType', box.classname)
    e.dataTransfer.setData('nodeName', box.name)
    e.dataTransfer.setData('nodeId', box.id)
  }

  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )

  const filteredJson = componentsBarJson?.map((item) => {
    item.components = item.components.filter((comp) => {
      if (comp.id === 'filter_survey' && !featuresAlloted.surveys) return false
      if (
        comp.id === 'filter_customerSegment' &&
        !featuresAlloted.custSegmentation
      )
        return false
      return true
    })
    return item
  })

  return (
    <Div
      width="300px"
      overflow="auto"
      height="100%"
      background={colors.lightBlue}
    >
      {filteredJson.map((comp, i) => (
        <$Dropdown key={comp.title} open>
          <summary>{comp.title}</summary>
          <FlexBox wrap="wrap">
            {comp.components.map((box) => (
              <Div
                draggable
                width="33%"
                p={spacing.m}
                textAlign="center"
                key={box.id}
                id={box.id}
                onDragStart={(e) => onComponentDrag(e, box)}
                style={{ cursor: 'move' }}
              >
                {box.icon}
                <Text variant="small" type="main" align="center" weight="bold">
                  {box.name}
                </Text>
              </Div>
            ))}
          </FlexBox>
        </$Dropdown>
      ))}
    </Div>
  )
}
