import React from 'react'

import { SectionHeading } from 'components/Common'
import { INSTRUCTIONS } from './constants'
import {
  $InstructionBlock,
  $InstructionsListBlock,
} from './UploadProducts.styled'

const Instructions = () => {
  return (
    <$InstructionBlock>
      <SectionHeading
        heading="Instructions"
        variant="medium"
        type="grey"
        weight="bold"
      />
      <$InstructionsListBlock>
        {INSTRUCTIONS.map((instruction, index) => (
          <li key={index}>
            {index + 1}) {instruction}
          </li>
        ))}
      </$InstructionsListBlock>
    </$InstructionBlock>
  )
}

export default Instructions
