import React, { Component } from "react";

class BrandConfirm extends Component {
  render() {
    return (
      <div className="modal_body">
        <div className="response_msg">
          <div className="success_msg" style={{ margin: "0px" }}>
            <p className="msg msg--1">
              You are changing Advertisements of the{" "}
              <span className="msg_highlgt">{this.props.totalStores} </span>
              selected stores.
              <p className="msg msg--1">
                Pressing Proceed will change all the advertisements.
              </p>{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default BrandConfirm;
