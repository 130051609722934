import styled from 'styled-components'
import { spacing } from 'design-system'

export const $Alert = styled.div`
  padding: ${spacing.m};
  color: #ea868f;
`

export const $FieldContainer = styled.div`
  padding-bottom: ${spacing.l};
`
