import styled, { css } from 'styled-components'
import { ReactComponent as FilterX } from 'images/filter-x.svg'
import { ReactComponent as Export } from 'images/export.svg'
import { ReactComponent as Edit } from 'images/edit.svg'
import { ReactComponent as Up } from 'images/up.svg'
import { ReactComponent as ExportX } from 'images/export-x.svg'
import { ReactComponent as Message } from 'images/message.svg'
import { ReactComponent as Growth } from 'images/growth.svg'
import { ReactComponent as Info } from 'images/info.svg'
import { ReactComponent as Star } from 'images/star.svg'
import { ReactComponent as NewStar } from 'images/star-filled.svg'
import { ReactComponent as Caret } from 'images/down-arrow-1.svg'
import { ReactComponent as Store } from 'images/store.svg'
import { ReactComponent as Add } from 'images/add.svg'
import { ReactComponent as Loader } from 'images/loader.svg'
import { TrashIcon } from 'assets/icons'

const SvgProcessor = (Element) => styled(Element)`
  height: ${(props) => props['height'] || '1em'};
  width: ${(props) => props['width'] || '1em'};
  margin: ${(props) => props['margin'] || 'auto'};
  vertical-align: ${(props) => props['vertical-align'] || 'middle'};
  display: inline-block;

  ${(props) =>
    props['cursor'] &&
    css`
      cursor: pointer;
    `}
`

export const FilterXIcon = styled(SvgProcessor(FilterX))`
  fill: ${(props) => props.theme.colors.primary['400']};
`

export const ExportXIcon = styled(SvgProcessor(ExportX))`
  fill: ${(props) => props.theme.colors.primary['400']};
`

export const BinIcon = styled(SvgProcessor(TrashIcon))`
  fill: ${(props) => props.theme.colors.primary['400']};
`

export const CaretIcon = styled(SvgProcessor(Caret))`
  path {
    stroke: ${(props) => props.theme.colors.primary['400']};
  }
`

export const EditIcon = styled(SvgProcessor(Edit))`
  cursor: pointer;
  ${(props) =>
    props.theme.colors[props['colorVariant']] &&
    css`
      & path {
        fill: ${props.theme.colors[props['colorVariant']]['400']};
      }
    `}
`

export const LoaderIcon = styled(SvgProcessor(Loader))`
  & circle {
    stroke: ${(props) => props.theme.colors.primary['400']};
  }
  ${(props) =>
    props.theme.colors[props['colorVariant']] &&
    css`
      & circle {
        stroke: ${props.theme.colors[props['colorVariant']]['100']};
      }
    `}
`

export const MessageIcon = styled(SvgProcessor(Message))`
  ${(props) =>
    props.theme.colors[props['colorVariant']] &&
    css`
      & path {
        fill: ${props.theme.colors[props['colorVariant']]['400']};
      }
    `}
`

export const ExportIcon = SvgProcessor(Export)
export const UpIcon = SvgProcessor(Up)
export const GrowthIcon = SvgProcessor(Growth)
export const InfoIcon = SvgProcessor(Info)
export const StarIcon = SvgProcessor(Star)
export const NewStarIcon = SvgProcessor(NewStar)
export const StoreIcon = SvgProcessor(Store)
export const AddIcon = SvgProcessor(Add)
