import React, { useEffect, useState } from 'react'
import {
  useDebounce,
  useDebouncedEffect,
  useRouter,
  useSelectStore,
} from 'hooks'
import { useDispatch } from 'react-redux'
import {
  changeStoreStatus,
  deleteStoreGroup,
  getBrandLists,
  getCompanyLists,
  getStoreGroupLists,
  getStoreKeysLists,
  getStoresLists,
  updateStoreKey,
  changeStoreActive,
} from 'redux/actions/storeMgtActions'
import { $Ellipsis } from '../autoEngagement/AutoEngagement.styled'
import { ActionCard, Avatar, Text, useToasts } from 'components/Common'
import { ERROR_DECODE } from 'utils/reusables'
import { InactiveIcon, LiveIcon } from 'assets/icons'
import { SHOW_FILTER_ARRAY } from './constants'
import { Div, FlexBox, Logo } from 'styledComponent'
import moment from 'moment'

const limit = 10

export const useStoreApi = () => {
  const [search, setSearch] = useState({})
  const [page, setPage] = useState(1)
  const [confirmPopup, setConfirmPopup] = useState({})
  const [filterQuery, setFilterQuery] = useState({})

  const setIsPopOpen = (bool) =>
    setConfirmPopup({ ...confirmPopup, isPopOpen: bool })

  const dispatch = useDispatch()
  const { toast } = useToasts()

  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')
  const {
    data: {
      companyList,
      brandList,
      storeGroupList,
      ecomStoreList,
      retailStoreList,
      storeKeyslist,
      tableLoading,
    },
  } = useSelectStore('strMgt')
  const { query, history } = useRouter()

  const searchTxt = useDebounce(search)

  const getLists = () => {
    switch (query.tab) {
      case 'Company':
        return companyList
      case 'Brands':
        return brandList
      case 'Retail Stores':
        return retailStoreList
      case 'E-Commerce Stores':
        return ecomStoreList
      case 'Store Groups':
        return storeGroupList
      case 'Access Keys':
        return storeKeyslist
    }
  }

  const callListAPI = (params) => {
    switch (query.tab) {
      case 'Company':
        return dispatch(getCompanyLists(params))
      case 'Brands':
        return dispatch(getBrandLists(params))
      case 'Retail Stores':
        return dispatch(getStoresLists(params))
      case 'E-Commerce Stores':
        return dispatch(getStoresLists(params))
      case 'Store Groups':
        return dispatch(getStoreGroupLists(params))
      case 'Access Keys':
        return dispatch(getStoreKeysLists(params))
    }
  }

  const callViewAPI = (item) => {
    switch (query.tab) {
      case 'Company':
        return history.push(`/storeManagement/viewCompany/${item._id}`)
      case 'Brands':
        return history.push(`/storeManagement/viewBrand/${item._id}`)
      case 'Store Groups':
        return history.push(`/storeManagement/viewStoreGroup/${item._id}`)
      case 'Retail Stores':
        return history.push(`/storeManagement/viewRetailStore/${item._id}`)
      case 'E-Commerce Stores':
        return history.push(`/storeManagement/viewEcomStore/${item._id}`)
      default:
        return
    }
  }

  const callEditAPI = (item) => {
    switch (query.tab) {
      case 'Company':
        return history.push(`/storeManagement/editCompany/${item._id}`)
      case 'Brands':
        return history.push(`/storeManagement/editBrand/${item._id}`)
      case 'Store Groups':
        return history.push(`/storeManagement/editStoreGroup/${item._id}`)
      case 'Retail Stores':
        return history.push(`/storeManagement/editRetailStore/${item._id}`)
      case 'E-Commerce Stores':
        return history.push(`/storeManagement/editEcomStore/${item._id}`)
      default:
        return
    }
  }

  const callDeleteAPI = (item) => {
    switch (query.tab) {
      case 'Store Groups':
        return setConfirmPopup({
          isPopOpen: item?._id,
          setIsPopOpen,
          heading: 'Are you sure?',
          onSubmit: () => onStoreDelete(item?._id),
          content:
            'Store Groups will be deleted and all the analytical data will be lost.',
        })

      case 'Retail Stores':
      case 'E-Commerce Stores':
        return setConfirmPopup({
          isPopOpen: item?._id,
          setIsPopOpen,
          heading: 'Are you sure?',
          onSubmit: () =>
            onChangeStatus({
              isActive: !item?.isActive,
              _id: item?._id,
              storeId: item?.storeId,
              type: item?.type,
              vendorId,
            }),
          content: item?.isActive
            ? 'This Store will be De-activated.'
            : 'This Store will be Re-activated.',
        })
      case 'Access Keys':
        return onKeyStatusChange(item)

      default:
        return
    }
  }

  const onStoreDelete = async (id) => {
    try {
      const res = await deleteStoreGroup(id)
      if (res) {
        toast('Store Group Deleted Successfully', 'success')
        dispatch(
          getStoreGroupLists({
            limit,
            skip: 0,
            vendorId,
          })
        )
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setConfirmPopup({})
    }
  }

  const onKeyStatusChange = async (item) => {
    try {
      const res = await updateStoreKey({
        vendorId,
        id: item?.store,
        accessKeyId: item?._id,
        status: !item?.isActive,
      })
      if (res) {
        toast('Access Key status changed successfully', 'success')
        dispatch(
          getStoreKeysLists({
            limit,
            skip: 0,
            vendorId,
            ...filterQuery,
          })
        )
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setConfirmPopup({})
    }
  }

  const onChangeStatus = async (body) => {
    try {
      const res = await changeStoreStatus(body)
      if (res) {
        toast('Store Status Changed Successfully', 'success')
        dispatch(
          getStoresLists({
            limit,
            skip: 0,
            vendorId,
            type: body?.type,
          })
        )
        dispatch(
          changeStoreActive({ storeId: body.storeId, isActive: body.isActive })
        )
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setConfirmPopup({})
    }
  }

  const onActionClick = (action, item) => {
    switch (action) {
      case 'view':
        return callViewAPI(item)

      case 'edit':
        return callEditAPI(item)

      case 'delete':
        return callDeleteAPI(item)

      case 'reactivate':
      case 'deactivate':
        return callDeleteAPI(item)
    }
  }

  const onPaginationChange = (selPage) => setPage(selPage)

  const row = (item, onActionClick) => {
    switch (query.tab) {
      case 'Company':
        return [
          <$Ellipsis mx={0}>{item?.companyName || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.inchargeName || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.mobileNumber || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.emailId || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.gst || '-'}</$Ellipsis>,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={['view', 'edit']}
            item="Company"
          />,
        ]
      case 'Brands':
        return [
          <Avatar src={encodeURI(item?.brandLogo)} size={50} />,
          <$Ellipsis>{item?.brandName || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.categoryName || '-'}</$Ellipsis>,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={['view', 'edit']}
            item="Brand"
          />,
        ]
      case 'Store Groups':
        return [
          <$Ellipsis mx={0}>{item?.name || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.description || '-'}</$Ellipsis>,
          `${item?.groupData?.length || 0} stores`,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={['view', 'edit', 'delete']}
            item="Store Group"
          />,
        ]
      case 'Retail Stores':
        return [
          <$Ellipsis mx={0}>{item?.store?.company?.name || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.store?.brand?.name || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.storeId || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.storeCode || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.store?.displayAddress || '-'}</$Ellipsis>,
          item?.isActive ? <LiveIcon /> : <InactiveIcon />,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={[
              'view',
              'edit',
              item?.isActive ? 'deactivate' : 'reactivate',
            ]}
            item="Store"
          />,
        ]
      case 'E-Commerce Stores':
        return [
          <$Ellipsis mx={0}>{item?.storeId || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.storeCode || '-'}</$Ellipsis>,
          item?.isActive ? <LiveIcon /> : <InactiveIcon />,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={[
              'view',
              'edit',
              item?.isActive ? 'deactivate' : 'reactivate',
            ]}
            item="Store"
          />,
        ]
      case 'Access Keys':
        return [
          <FlexBox align="center" py="8px">
            <Logo
              width="50px"
              height="50px"
              src={item?.storeObj?.brandLogo}
              alt=""
            />
            <Div>
              <Text>{item?.storeObj?.brandName}</Text>
              <Text variant="small" ellipsis maxWidth="45ch">
                {item?.storeObj?.displayAddress}
              </Text>
            </Div>
          </FlexBox>,
          <$Ellipsis>{item?.storeObj?.storeCode || '-'}</$Ellipsis>,
          item?.accessKey || '-',
          <$Ellipsis>{item?.name || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.macId || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.version || '-'}</$Ellipsis>,
          <$Ellipsis>{item?.bit || '-'}</$Ellipsis>,
          <$Ellipsis>
            {item?.lastBillDate
              ? moment(item?.lastBillDate).format('DD MMM YYYY')
              : '-'}
          </$Ellipsis>,
          <$Ellipsis>
            {item?.versionHistory &&
            item.versionHistory.length > 0 &&
            item.versionHistory[item.versionHistory.length - 1].updateDate
              ? moment(
                  item.versionHistory[item.versionHistory.length - 1].updateDate
                ).format('DD MMM YYYY')
              : '-'}
          </$Ellipsis>,
          <$Ellipsis>{item?.isActive ? 'Active' : 'Inactive'}</$Ellipsis>,
          <ActionCard
            onIconClick={(action) => onActionClick(action, item)}
            components={[item?.isActive ? 'deactivate' : 'reactivate']}
            item="Access Key"
          />,
        ]

      default:
        return []
    }
  }

  const list = getLists()

  useEffect(() => setPage(1), [query.tab, searchTxt, filterQuery])

  useDebouncedEffect(
    () => {
      let params = {
        vendorId,
        limit,
        skip: (page - 1) * limit,
      }

      if (searchTxt[query?.tab])
        params.searchTxt = searchTxt?.[query?.tab] || ''

      if (query?.tab?.includes(' Stores')) {
        params.type = query?.tab === 'E-Commerce Stores' ? 'online' : 'offline'
      }
      if (SHOW_FILTER_ARRAY?.includes(query?.tab)) {
        params = { ...params, ...filterQuery }
      }
      callListAPI(params)
    },
    [query.tab, searchTxt, page, filterQuery],
    100
  )

  return {
    row,
    page,
    list,
    limit,
    search,
    setSearch,
    tableLoading,
    confirmPopup,
    onActionClick,
    onPaginationChange,
    filterQuery,
    setFilterQuery,
  }
}
