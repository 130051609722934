const initialState = {
    selectedBrandForAdvert:[]
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case "SELECT_BRAND_FOR_ADVERT":
        return {
          ...state,
          selectedBrandForAdvert: action.payload
        };
      default:
        return state;
    }
  }
  