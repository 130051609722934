import React from 'react'
import { useNode } from '@craftjs/core'

import { ImageSettings } from './ImageSettings'

export const SocialImage = (props) => {
  const {
    connectors: { connect },
    isSelected,
  } = useNode((state) => ({
    isSelected: state.events.selected,
  }))

  const {
    display,
    width,
    height,
    imgUrl,
    alt,
    boxSizing,
    redirectUrl,
    maxWidth,
    textAlign,
    objectFit,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  const getImage = () => (
    <img
      style={{
        borderRadius,
        maxWidth,
        boxSizing,
      }}
      src={imgUrl}
      alt={alt}
      title={alt}
      width={width}
      height={height}
    />
  )

  return (
    <div
      style={{
        display,
        borderRadius,
        padding,
        maxWidth,
        textAlign,
        objectFit,
        boxSizing,
      }}
      ref={(ref) => connect(ref)}
    >
      {redirectUrl ? (
        <a target="_blank" rel="noopener" href={!isSelected && redirectUrl}>
          {getImage()}
        </a>
      ) : (
        getImage()
      )}
    </div>
  )
}

export const SocialImageDefaultProps = {
  maxWidth: '100%',
  width: '50px',
  height: '50px',
  display: 'inline-block',
  alt: 'Social Image Name',
  textAlign: 'center',
  objectFit: 'contain',
  boxSizing: 'border-box',
  paddingTop: 10,
  paddingRight: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  imgUrl: 'https://assets.billme.co.in/public/facebook+logo+-+Email+editor.png',
  isSocialImage: true,
}

SocialImage.craft = {
  props: SocialImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
}
