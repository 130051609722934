import React from 'react'
import { Android, Apple, Windows } from 'assets/icons'
import { PersonalInfoKey } from 'components/Content/consumerAnalytics/Customers/constants'
import { ReactComponent as HappyFace } from 'images/face-happy.svg'
import { ReactComponent as NeutralFace } from 'images/face-neutral.svg'
import { ReactComponent as SadFace } from 'images/face-sad.svg'

export const getOSIcon = (os) => {
  switch (os) {
    case 'android':
      return <Android />
    case 'iphone':
      return <Apple />
    case 'other':
      return <Windows />
    default:
      return '-'
  }
}

export const processFilterData = (filters, isWithoutFilter) => {
  const processedFilters = {}

  if (isWithoutFilter) return {}

  Object.values(filters).map((filter) => {
    if (filter.isActive) {
      switch (filter.id) {
        case 1:
          const noOfVisits = {}
          noOfVisits.min = parseInt(filter.value.min)
          noOfVisits.max = parseInt(filter.value.max)
          return (processedFilters.noOfVisits = noOfVisits)

        case 2:
          const expenses = {}
          if (filter.value.total.isActive) {
            expenses.type = 'total'
            expenses.min = filter.value.total.min
            expenses.max = filter.value.total.max
          } else if (filter.value.avg.isActive) {
            expenses.type = 'average'
            expenses.min = filter.value.avg.min
            expenses.max = filter.value.avg.max
          }
          return (processedFilters.expenses = expenses)

        case 3:
          const avgFeedback = {}
          avgFeedback.min = filter.value.min
          avgFeedback.max = filter.value.max
          return (processedFilters.avgFeedback = avgFeedback)

        case 4:
          const segmentsValue = filter.value.map(({ value }) => value)
          return (processedFilters.segmentsValue = segmentsValue)

        case 5:
          const locationValues = Object.keys(filter.value).map((key) =>
            filter.value[key].map(({ value }) => value)
          )
          const mergedLocationArr = [].concat.apply([], locationValues)

          return (processedFilters.location = [...mergedLocationArr])

        case 6:
          const gender = []
          if (filter.value.male) gender.push('male')
          if (filter.value.female) gender.push('female')
          return (processedFilters.gender = gender)

        case 7:
          const os = []
          if (filter.value.google) {
            os.push('android')
          }
          if (filter.value.iphone) {
            os.push('iphone')
          }
          if (filter.value.other) {
            os.push('other')
          }
          return (processedFilters.os = os)
      }
    }
  })

  return processedFilters
}

export const formatConsumerProfileData = (data) => {
  const formatedData = new Map()
  const profileDate = {}

  if (!data) return {}

  const personal = () => {
    const personal = {}
    const setValue = (key) => {
      if (data.personal[key]) return (personal[key] = data.personal[key])

      return null
    }

    PersonalInfoKey.map((key) => {
      if (!data.personal || data.personal === null) {
        // return (personal[key] = 'NA')
      } else {
        setValue(key)
      }
    })

    return personal
  }

  const transactionInfo = () => {
    const transactionInfo = {}
    if (!data.custItems)
      return {
        day: 'NA',
        visitCount: 'NA',
        amountSpent: 'NA',
        avgSpent: 'NA',
        avgFeedback: 'NA',
      }

    const totalVisits = data.custItems[0]?.visitCount?.toFixed(0)
    const totalSpent = data.custItems[0]?.amountSpent
    const avgSpent = data.custItems[0]?.avgSpent?.toFixed(0)
    const avgFeedback = data.custItems[0]?.avgFeedback?.toFixed(0)

    transactionInfo.totalVisits = totalVisits
    transactionInfo.totalSpent = totalSpent
    transactionInfo.avgSpent = avgSpent
    transactionInfo.avgFeedback = avgFeedback

    return transactionInfo
  }

  const getDaysVisited = (days) => {
    const daysVisited = [
      { key: 'Mon', value: 0 },
      { key: 'Tue', value: 0 },
      { key: 'Wed', value: 0 },
      { key: 'Thurs', value: 0 },
      { key: 'Fri', value: 0 },
      { key: 'Sat', value: 0 },
      { key: 'Sun', value: 0 },
    ]

    days.map(({ day, visitCount }) => {
      switch (day) {
        case 1:
          return (daysVisited[0].value = visitCount)
        case 2:
          return (daysVisited[1].value = visitCount)
        case 3:
          return (daysVisited[2].value = visitCount)
        case 4:
          return (daysVisited[3].value = visitCount)
        case 5:
          return (daysVisited[4].value = visitCount)
        case 6:
          return (daysVisited[5].value = visitCount)
        case 7:
          return (daysVisited[6].value = visitCount)
      }
    })

    return daysVisited
  }

  formatedData.set('personal', personal())
  formatedData.set('transactionInfo', transactionInfo())
  formatedData.set(
    'daysVisited',
    getDaysVisited(
      data.custVisited.map(({ day, visitCount }) => {
        return { day, visitCount }
      })
    )
  )
  formatedData.set('activity', data.activity)
  formatedData.set('custItems', data.custItems)
  const personalData = formatedData.get('personal')
  const transactionData = formatedData.get('transactionInfo')
  const daysVisited = formatedData.get('daysVisited')
  const activity = formatedData.get('activity')
  const custItems = formatedData.get('custItems')
  profileDate.personal = personalData
  profileDate.transactionInfo = transactionData
  profileDate.daysVisited = daysVisited
  profileDate.activity = activity
  profileDate.custItems = custItems

  return profileDate
}

export const getSentimentFace = (feedback) => {
  if (feedback < 2) return <SadFace />
  else if (feedback > 2 && feedback < 4) return <NeutralFace />
  else if (feedback > 2 && feedback < 4) return <HappyFace />
}
