/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import { Editor, Element, Frame } from '@craftjs/core'
import lz from 'lzutf8'

import { $EditorContainer, $MainEditor } from './Editor.styled'
import { Toolbox } from './Toolbox'
import { Topbar } from './Topbar'
import { RenderNode } from './RenderNode'
import {
  Button,
  ColumnContainer,
  Columns,
  Container,
  Coupon,
  HTMLBlock,
  Image,
  Line,
  Media,
  Social,
  Spacer,
  Survey,
  Text,
  SocialImage,
} from './selectors'

const HtmlEditor = (props) => {
  const [isMobileView, setIsMobileView] = useState(false)
  const { isPreview, setIsPreview } = props

  return (
    <Editor
      enabled={!isPreview}
      onRender={RenderNode}
      resolver={{
        Button,
        ColumnContainer,
        Columns,
        Container,
        Coupon,
        HTMLBlock,
        Image,
        Line,
        Media,
        Social,
        Spacer,
        Survey,
        Text,
        SocialImage,
      }}
    >
      <div id="myEditor">
        <Topbar
          isPreview={isPreview}
          isMobileView={isMobileView}
          setIsMobileView={setIsMobileView}
          setIsPreview={setIsPreview}
          {...props}
        />
        <$EditorContainer>
          <$MainEditor width={isMobileView ? '375px' : '100%'} id="mainEditor">
            <Frame json={lz.decompress(lz.decodeBase64(props.compressedValue))}>
              <Element
                canvas
                is={Container}
                height="100%"
                width="100%"
                background="#fff"
              />
            </Frame>
          </$MainEditor>
          {!isPreview && <Toolbox />}
        </$EditorContainer>
      </div>
    </Editor>
  )
}

export default HtmlEditor
