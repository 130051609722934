import React, { Fragment } from 'react'

import { useRouter, useSelectStore } from 'hooks'
import { BoxContent } from 'styledComponent/components/box'
import { SectionHeading, Skeleton } from 'components/Common'
import {
  $AddBlock,
  $Box,
  $MenuBlock,
  $MenuHead,
  $MenuItem,
  $StyledTag,
} from './SegmentsMenu.styled'

import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { ReactComponent as RightIcon } from 'images/breadcrumb-separator.svg'
import { ReactComponent as LockIcon } from 'images/lock.svg'
import { FlexBox } from 'styledComponent'
import { SEGMENT_STATUSES } from '../constants'

const SegementsMenu = ({ isLoading }) => {
  const { data: segmentsList } = useSelectStore('cAnalytics.segmentsList')

  const { setQuery, query } = useRouter()

  const onMenuClick = (id) => {
    const menuQuery = {
      id,
    }

    setQuery(menuQuery, {})
  }

  const onAddNewClick = () => {
    const newSegmentQuery = {
      addNew: true,
    }

    setQuery(newSegmentQuery, {})
  }

  const { id } = query

  React.useEffect(() => {
    const prevQuery = Object.keys(query)
    if (!prevQuery.length || prevQuery.length > 1) {
      setQuery({ id: id || segmentsList?.[0]?._id || '' }, {})
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <$Box boxShadow>
      <BoxContent height={'calc(100vh - 220px)'}>
        <$MenuHead justify="space-between" align="center">
          <SectionHeading heading="SEGMENTS" variant="h3" />
          <$AddBlock onClick={onAddNewClick}>
            <PlusIcon />
            <span>Add New</span>
          </$AddBlock>
        </$MenuHead>
        <$MenuBlock>
          {isLoading ? (
            <Skeleton height={50} count={5} />
          ) : (
            <>
              {segmentsList?.map((segmentItem) => {
                const status = segmentItem?.type
                return (
                  <$MenuItem
                    key={segmentItem._id}
                    active={id === segmentItem._id}
                    locked={segmentItem.vendorId !== 'default'}
                    onClick={() => onMenuClick(segmentItem._id)}
                  >
                    <span>{segmentItem.name}</span>
                    <FlexBox gap="8px" align="center" justify="space-between">
                      {segmentItem.vendorId !== 'default' ? (
                        <Fragment>
                          <$StyledTag type={status}>
                            {SEGMENT_STATUSES?.[status]?.label || status}
                          </$StyledTag>
                          <RightIcon />
                        </Fragment>
                      ) : (
                        <LockIcon />
                      )}
                    </FlexBox>
                  </$MenuItem>
                )
              })}
            </>
          )}
        </$MenuBlock>
      </BoxContent>
    </$Box>
  )
}

export default SegementsMenu
