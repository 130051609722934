const initialState = {
  companies: [],
  selectedCompanies:[],
  filterCompanies:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload
      };
    case "SET_SELECTED_COMPANIES":
      return {
        ...state,
        selectedCompanies: action.payload
      }
    default:
      return state;
  }
}
