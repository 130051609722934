import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'
import { useDispatch } from 'react-redux'

import { Table, TableHead } from 'styledComponent'
import { useRouter } from 'hooks'
import {
  Skeleton,
  Input,
  useToasts,
  Text,
  PopupContainer,
} from 'components/Common'

import { SURVEY_HEADER } from '../../constants'
import { $AETableContainer, $Ellipsis } from '../../AutoEngagement.styled'
import { TabRow } from '../../campaigns/AETable'
import { getStatus } from '../../campaigns/AETable'
import { moreActions } from '../../campaigns/AETable'
import { setSurveyData } from 'redux/actions/autoEngageActions'

const SURVEY_ROW = (survey, onActionClick) => [
  <$Ellipsis>{survey?.name || '-'}</$Ellipsis>,
  survey?.status ? getStatus(survey?.status) : '-',
  <$Ellipsis>{survey?.description || '-'}</$Ellipsis>,
  survey?.createdDate ? moment(survey.createdDate).format('DD MMM YYYY') : '-',
  survey?.updatedDate ? moment(survey.updatedDate).format('DD MMM YYYY') : '-',
  moreActions(survey, onActionClick, ' Survey', { showDuplicate: true }),
]

export const SurveyTable = ({
  tableLoading,
  onPaginationChange,
  surveyList,
  pageCount,
  setTriggers,
  pageNumber,
}) => {
  const theme = useTheme()
  const { toast } = useToasts()
  const { history } = useRouter()
  const dispatch = useDispatch()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [surveyAction, setSurveyAction] = useState({})

  const onActionClick = (action, survey) => {
    const { surveyId, _id, createdBy } = survey
    switch (action) {
      case 'edit':
        return history.push(`/auto-engage/surveys/${_id}`)

      case 'view':
        return history.push(`/auto-engage/surveys/${_id}/view`)

      case 'show': {
        dispatch(setSurveyData(survey))
        return history.push(`/auto-engage/surveys/responses/${surveyId}`)
      }

      case 'unpublishForAll': {
        setSurveyAction({
          _id,
          action,
          heading: 'Unpublish For All - Are you sure?',
        })
        return setIsPopOpen(true)
      }

      case 'duplicate': {
        setSurveyAction({
          _id,
          action,
          createdBy,
          heading: 'Duplicate Campaign',
        })
        return setIsPopOpen(true)
      }

      case 'copySurvey': {
        navigator.clipboard.writeText(survey?.surveyUrl)
        return toast('Link has been copied to your clipboard.', 'success')
      }

      case 'delete': {
        setSurveyAction({ _id, action, heading: 'Delete Campaign' })
        return setIsPopOpen(true)
      }
    }
  }

  return (
    <$AETableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={SURVEY_HEADER} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                cells={[...new Array(SURVEY_HEADER.length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : surveyList?.length > 0 ? (
            surveyList?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={SURVEY_ROW(item, onActionClick)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={SURVEY_HEADER.length}
              cells={['No Surveys Found']}
            />
          )}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={pageCount || 1}
          onPageChange={onPaginationChange}
          containerClassName="aePagination"
          activeClassName="pagination_activePage"
          forcePage={pageNumber || 0}
        />
      )}
      <ActionPop
        surveyAction={surveyAction}
        setTriggers={setTriggers}
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        toast={toast}
        theme={theme}
      />
    </$AETableContainer>
  )
}

const ActionPop = ({
  surveyAction,
  setTriggers,
  isPopOpen,
  setIsPopOpen,
  toast,
  theme,
}) => {
  const [name, setName] = useState('')

  useEffect(() => () => setName(''), [isPopOpen])

  const getBody = (action) => {
    switch (action) {
      case 'delete':
        return (
          <Text weight="bold" type="main" m={theme.spacing.m}>
            Deleting a survey can result in unpublishing the associated
            campaigns. <br />
            Do you wish to continue?
          </Text>
        )

      case 'unpublishForAll':
        return (
          <Text weight="bold" type="main" m={theme.spacing.m}>
            This survey will be disabled. <br />
            All links to this survey will stop working including in Emails, SMS,
            Survey in Bill and all other media.
          </Text>
        )

      case 'duplicate':
        return (
          <Input
            variant="input"
            inputWidth="400px"
            placeholder="Enter New Survey Name"
            label="Survey Name"
            maxLength="50"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )

      default:
        return null
    }
  }

  const onSubmit = () => {
    if (surveyAction?.action === 'duplicate') {
      if (!name) {
        toast(
          'Enter a new Survey name to create a new copy of the campaign.',
          'error'
        )
        throw 'err'
      }
      surveyAction.name = name
    }
    setTriggers(surveyAction)
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading={surveyAction?.heading}
      onSubmitClick={onSubmit}
    >
      {getBody(surveyAction?.action)}
    </PopupContainer>
  )
}
