import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import leftArrow from 'images/left.svg'
import rightArrow from 'images/right.svg'
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { MyToast } from 'components/Common'
import { filterStores, filterBrand } from 'redux/actions/authActions'
import { selectBrandForAdvert } from 'redux/actions/advertActions'

class AdvertiseCarousel extends Component {
  static contextType = MyToast

  constructor() {
    super()
    this.state = {
      currentSlide: 0,
    }
    this.onClickAds = this.onClickAds.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeCampaign !== this.props.activeCampaign) {
      this.setState({ currentSlide: 0 })
      this.props.changesSlides(0)
    }
  }

  next = () => {
    if (this.props.activeCampaign.length === this.state.currentSlide + 1) {
      this.setState({ currentSlide: 0 })
    } else {
      this.setState((state) => ({
        currentSlide: state.currentSlide + 1,
      }))
    }
  }

  prev = () => {
    if (this.state.currentSlide === 0) {
      this.setState({ currentSlide: this.props.activeCampaign.length - 1 })
    } else {
      this.setState((state) => ({
        currentSlide: state.currentSlide - 1,
      }))
    }
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state
    if (currentSlide !== index) {
      this.props.changesSlides(index)
      this.setState({
        currentSlide: index,
      })
    }
  }

  onClickAds() {
    if (this.props.activeCampaign[this.state.currentSlide] !== undefined) {
      let brandId, brandName, brandLogo
      let { storeId } = this.props.activeCampaign[this.state.currentSlide]
      // adRedirectUrl = (adRedirectUrl && (adRedirectUrl.indexOf('https://') === -1 && adRedirectUrl.indexOf('http://') === -1 )) ? `http://${adRedirectUrl}` : adRedirectUrl;

      if (Object.keys(this.props.stores).includes(storeId)) {
        brandId = this.props.stores[storeId].brandId
      }

      for (let i = 0; i < this.props.brands.length; i++) {
        if (this.props.brands[i].brandId === brandId) {
          brandName = this.props.brands[i].name
          brandLogo = this.props.brands[i].brandLogo
          this.props.selectBrandForAdvert([
            {
              label: brandName,
              value: brandId,
              brandLogo,
            },
          ])
          break
        }
      }

      this.props.history.push('/advertisement')
    } else {
      // toast.error("No live campaigns running")
      this.context.toast('No live campaigns running', 'error')
    }
  }

  render() {
    return (
      <>
        <div className="liveCampaignImageBox">
          {this.props.activeCampaign.length > 1 && (
            <div className="leftBtn" onClick={this.prev}>
              <div className="left">
                <img className="leftIcon" src={leftArrow} />
              </div>
            </div>
          )}
          <div onClick={this.onClickAds} className="advertiseCarousel">
            {this.props.activeCampaign.length > 0 ? (
              <Carousel
                infiniteLoop
                autoPlay
                width={'100%'}
                height={'100%'}
                useKeyboardArrows
                dynamicHeight
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                selectedItem={this.state.currentSlide}
                onChange={this.updateCurrentSlide}
              >
                {this.props.activeCampaign.map((campaign, key) => {
                  return (
                    <div key={key}>
                      <img src={campaign.signedUrl} key={campaign.campaignId} />
                    </div>
                  )
                })}
              </Carousel>
            ) : (
              <div className="noCampaigns">
                <div className="noCampaignsLabel">
                  No Campaign Live for this Store.
                </div>
              </div>
            )}
          </div>
          {this.props.activeCampaign.length > 1 && (
            <div className="rightBtn" onClick={this.next}>
              <div className="right">
                <img className="rightIcon" src={rightArrow} />
              </div>
            </div>
          )}
        </div>
        <div className="campaignName">
          {this.props.activeCampaign.length > 0
            ? `Name: ${
                this.props.activeCampaign[this.state.currentSlide].campaignName
              }`
            : null}
        </div>
      </>
    )
  }
}

const mapStatesToProps = (state) => ({
  stores: filterStores(state, 'advert'),
  brands: filterBrand(state, 'advert'),
})

const ConnectedComponent = connect(mapStatesToProps, { selectBrandForAdvert })(
  AdvertiseCarousel
)

export default withRouter(ConnectedComponent)
