const initialState = {
  isReset: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "PASSWORD_RESET":
      return {
        ...state,
        isReset: action.payload
      };
    default:
      return state;
  }
}
