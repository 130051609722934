import { post } from 'utils/axiosHandler'

export const getBills = async (data, options) => {
  return getBillsBySearch(data, options)
  // return post('/bills/getBills', data, options)
}

export const getBillsBySearch = async (data, options) => {
  return post('/bills/getBillsBySearch', data, options)
}

export const getStoreAddress = async (data, options) => {
  return post('/bills/getStoreAddress', data, options)
}

export const getBillUrl = async (data, options) => {
  return post('/bills/getBillUrl', data, options)
}

export const deleteBill = async (data, options) => {
  return post('/bills/deleteBill', data, options)
}

export const resendBill = async (data, options) => {
  return post('/bills/resendBill', data, options)
}

export const setRememberFilters = (params) => (dispatch) => {
  dispatch(setRememberFiltersHelper(params))
}

const setRememberFiltersHelper = (data) => {
  return {
    type: 'SET_REMEMBER_FILTERS',
    payload: data,
  }
}

export const setDefaultFilters = (data) => {
  return {
    type: 'SET_DEFAULT_FILTER',
    payload: data,
  }
}

export const setBillFilterQuery = (data) => {
  return {
    type: 'SET_BILL_FILTER',
    payload: data,
  }
}

export const exportBills = async (data, options) => {
  return post('/bills/export', data, options)
}

export const setBillsCashier = (data) => {
  return {
    type: 'SET_CASHIER',
    payload: data,
  }
}
