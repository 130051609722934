import React, { createRef, useEffect, useState, useContext } from 'react'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import { FlexBox } from 'styledComponent'
import { useSelectStore } from 'hooks'
import {
  Input,
  Text,
  SelectBrands,
  SelectStores,
  Button,
} from 'components/Common'
import {
  popupStyle,
  reportTypes,
  maxEmails,
  minDate,
} from '../components/constants'
import {
  $ReportFormContainer,
  $ReportButton,
  $ReportPopup,
} from '../Report.styled'
import moment from 'moment'
import { spacing } from 'design-system'
import { compareDatePermission } from '../../../../utils/getDateFormat'
import { MyToast } from '../../../Common'

const GenerateForm = ({ form, setForm }) => {
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const {
    data: { email },
  } = useSelectStore('auth.user')
  const reportRef = createRef()
  const [isBrandPop, setIsBrandPop] = useState(true)
  const [isCalOpen, setIsCalOpen] = useState(false)
  const toastContext = useContext(MyToast)

  useEffect(() => {
    setForm({
      ...form,
      vendorId: selectedVendor?.value || '',
      brands: [],
      stores: [],
    })
  }, [selectedVendor])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    })
  }

  const handleSelect = (key, val) => {
    let reportForm = { ...form, [key]: val }
    switch (key) {
      case 'brands':
        reportForm = {
          ...form,
          brands: val,
          stores: [],
        }
        return setForm(reportForm)
      case 'emailList':
        if (val?.length > maxEmails) return
        reportForm = {
          ...form,
          emailList:
            val?.filter(
              (email) =>
                email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
            ) || [],
        }
        return setForm(reportForm)
      case 'date':
        if (
          !compareDatePermission(
            selectedVendor.featuresAlloted.exportRange,
            reportForm.date.start
          )
        ) {
          toastContext.toast(
            'You can only access last 2 months data',
            'warning'
          )
          return false
        }
        setIsCalOpen(!isCalOpen)
        return setForm(reportForm)
      default:
        return setForm(reportForm)
    }
  }

  const openPop = (pop) => {
    setIsBrandPop(pop)
    reportRef.current.openModal()
  }

  const closePop = () => reportRef.current.closeModal()

  return (
    <>
      <$ReportFormContainer>
        <div>
          <Input
            variant="input"
            label="Report Name"
            placeholder="Enter your report name"
            id="name"
            value={form.name}
            onChange={handleChange}
            inputWidth="500px"
          />
          <Input
            variant="input"
            label="Report Description"
            placeholder="Describe your report"
            id="description"
            value={form.description}
            onChange={handleChange}
            inputWidth="500px"
          />
          <Input
            variant="radio"
            direction="column"
            gap={spacing.m}
            label="Generate Report For"
            id="reportType"
            value={form.reportType}
            onChange={(val) => handleSelect('reportType', val)}
            options={reportTypes(email)}
          />
          <Input
            variant="create-tags"
            label="E-Mail List"
            id="emailList"
            value={form.emailList}
            onChange={(val) => handleSelect('emailList', val)}
            placeholder={`Enter emails and press enter... (Maximum ${maxEmails} emails)`}
          />
        </div>
        <div>
          {form.reportType !== 'consumerProfiling' && (
            <>
              <FlexBox justify="space-between" padding="20px 0">
                <Text variant="small">Brands : </Text>
                <Text type="primary" variant="small">
                  {form.brands?.length || 0} brands selected
                </Text>
                <$ReportButton
                  variant="primaryOutline"
                  onClick={() => openPop(true)}
                >
                  Select Brands
                </$ReportButton>
              </FlexBox>
              <FlexBox justify="space-between" padding="0 0 20px">
                <Text variant="small">Stores : </Text>
                <Text type="primary" variant="small">
                  {form.stores?.length || 0} stores selected
                </Text>
                <$ReportButton
                  variant="primaryOutline"
                  disabled={!form.brands?.length}
                  onClick={() => form.brands?.length > 0 && openPop(false)}
                >
                  Select Stores
                </$ReportButton>
              </FlexBox>
              <FlexBox justify="space-between" padding="0 0 20px">
                <Text variant="small">Report Time Range : </Text>
                <Button
                  variant="primaryOutline"
                  fontSize="12px"
                  onClick={() => setIsCalOpen(!isCalOpen)}
                >
                  <i className="fas fa-calendar-alt" /> &nbsp;
                  {moment(form.date?.start).format('DD MMM YYYY') +
                    ' - ' +
                    moment(form.date?.end).format('DD MMM YYYY')}
                </Button>
              </FlexBox>
              {isCalOpen && (
                <DateRangePicker
                  minimumDate={minDate}
                  maximumDate={new Date()}
                  singleDateRange
                  onSelect={(val) => handleSelect('date', val)}
                  value={form.date}
                />
              )}
            </>
          )}
        </div>
      </$ReportFormContainer>
      <$ReportPopup
        className="modal_view"
        ref={reportRef}
        heading={'Select ' + (isBrandPop ? 'Brands' : 'Stores')}
        onProceed={closePop}
        contentStyle={popupStyle}
      >
        {isBrandPop ? (
          <SelectBrands
            brands={form.brands}
            setBrands={(brands) => setForm({ ...form, stores: [], brands })}
          />
        ) : (
          <SelectStores
            brands={form.brands}
            vendorId={form.vendorId}
            stores={form.stores}
            setStores={(stores) => setForm({ ...form, stores })}
          />
        )}
      </$ReportPopup>
    </>
  )
}

export default GenerateForm
