import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'

import { useRouter, useSelectStore, useVendorSelect } from 'hooks'
import { spacing, borderRadius } from 'design-system/styles'
import {
  Text,
  Button,
  Input,
  useToasts,
  RenderIf,
  TopActionBar,
  Breadcrumb,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'

import { Male, Female, Other } from 'assets/icons'
import { stateOptions } from '../constants'
import CustomerStats from './CustomerStats'

const $FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${spacing.l} ${spacing.xl};
  min-height: calc(100vh - 60px);
`

const $CustomerForm = styled(FlexBox)`
  gap: ${spacing.xl};
  form {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: ${spacing.xl};
  }
`

const $InputButton = styled.span`
  margin-right: ${spacing.l};
  svg {
    path {
      fill: ${(props) => !props.isActive && props.theme.colors.grey};
      stroke: ${(props) => !props.isActive && props.theme.colors.grey};
    }
  }
`

const $FlexItem = styled.div`
  flex-basis: ${(props) => props.flexBasis || 'auto'};
  min-width: ${(props) => props.minWidth || 'auto'};
  max-width: ${(props) => props.maxWidth || 'auto'};
`

export const CustomerForm = ({
  initialState,
  sendForm,
  isLoading,
  editableForm,
}) => {
  const theme = useTheme()
  const { history, pathname } = useRouter()
  const [form, setForm] = React.useState(initialState)
  const [dupForm, setDupForm] = React.useState({})

  const { toast } = useToasts()
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')

  React.useEffect(() => {
    const firstName = initialState.name?.split(' ')[0] || ''
    const last = initialState.name?.split(' ')
    if (last) {
      last.shift()
    }
    const lastName = last?.join(' ') || ''
    const state =
      stateOptions.filter((st) => st.value === initialState.state) || ''
    setForm({ ...initialState, firstName, lastName, state })

    setDupForm({
      phone: initialState?.phone || '',
      email: initialState?.email || '',
    })
  }, [initialState])

  const handleChange = (e, maxLength) => {
    if (e.target.value.length > maxLength) return

    setForm({
      ...form,
      [e.target.id]: e.target.value,
    })
  }

  const handleSet = (field, value = '', e) => {
    if (e) e.preventDefault()
    const copyForm = { ...form }
    if (field === 'married' && value === 'unmarried') {
      delete copyForm?.anniversaryDate
      delete copyForm?.spouseFirst
      delete copyForm?.spouseLast
    }
    setForm({ ...copyForm, [field]: value })
  }

  const checkDate = (date, myForm) => {
    if (
      new Date(myForm[date] || new Date()).setHours(0, 0, 0, 0) ==
      new Date().setHours(0, 0, 0, 0)
    ) {
      delete myForm[date]
    }
  }

  const validateForm = () => {
    const myForm = { ...form }
    checkDate('birthDate', myForm)
    checkDate('anniversaryDate', myForm)
    if (myForm.married === 'unmarried') {
      delete myForm.spouseFirst
      delete myForm.spouseLast
      delete myForm.anniversaryDate
    }
    myForm.state = myForm.state?.value || ''
    myForm.vendorId = selectedVendor.value

    const keyset = {}
    Object.keys(myForm)
      .filter((key) => myForm[key])
      .forEach((ke) => (keyset[ke] = myForm[ke]))

    return keyset
  }

  const onSave = (e) => {
    e.preventDefault()

    if (form?.phone.trim() === '') {
      toast('Phone number cannot be empty', 'error')
      return
    } else if (form?.phone.length < 10) {
      toast('Phone number should be of 10 digits', 'error')
      return
    }

    const keySet = validateForm()
    sendForm({
      source: 'merchant-upload',
      ...keySet,
    })
  }

  const onCancel = (e) => {
    // setForm(initialState)
    // history.push({
    //   pathname: '/consumer-profiling',
    // })
    history.goBack()
  }

  const pushToEdit = () => {
    history.push({
      pathname: `${pathname}/edit`,
    })
  }

  const pathCrumbs = pathname.split('/')

  const extras =
    pathCrumbs.includes('add') || pathCrumbs.includes('edit') ? (
      <FlexBox gap={theme.spacing.l}>
        <Button onClick={onCancel} variant="primaryOutline" width="100px">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={onSave}
          type="submit"
          variant="primary"
          width="100px"
        >
          Save
        </Button>
      </FlexBox>
    ) : (
      <Button onClick={pushToEdit} variant="primary" width="100px">
        Edit
      </Button>
    )

  return (
    <>
      <Navbar heading="Customer Form" />
      <$FormContainer>
        <Breadcrumb
          label={
            pathCrumbs.includes('add')
              ? 'Add New Customer'
              : pathCrumbs.includes('edit')
              ? 'Edit Customer'
              : 'View Customer'
          }
          breadcrumb={[
            {
              label: 'Consumer Profiling',
              link: '/consumer-profiling',
            },
          ]}
        />
        <Div my={theme.spacing.l}>
          <TopActionBar extras={extras} title=" " />
        </Div>
        <FlexBox justify="space-between" gap="2%">
          <$FlexItem flexBasis="48%">
            <$CustomerForm>
              <form>
                <Input
                  disabled={isLoading || !editableForm}
                  variant="input"
                  label="First Name :"
                  placeholder="Enter First Name"
                  id="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  showClear
                  clear={() => handleSet('firstName')}
                  inputWidth="350px"
                />
                <Input
                  disabled={isLoading || !editableForm}
                  variant="input"
                  label="Last Name :"
                  placeholder="Enter Last Name"
                  id="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  showClear
                  clear={() => handleSet('lastName')}
                  inputWidth="350px"
                />
                <Input
                  variant="date-input"
                  label="Date of Birth :"
                  id="birthDate"
                  onChange={(val) => handleSet('birthDate', val)}
                  disabled={isLoading || !editableForm}
                  inputWidth="350px"
                  value={form.birthDate}
                  placeholder="Enter your date of birth"
                />
                <Input label="Gender :" id="firstName" value={form.firstName}>
                  <$InputButton isActive={form.gender === 'female'}>
                    <Button
                      disabled={isLoading || !editableForm}
                      startIcon={Female}
                      variant="custom"
                      color={
                        form.gender === 'female'
                          ? theme.colors.redViolet
                          : theme.colors.grey
                      }
                      shadow={form.gender === 'female'}
                      onClick={(e) => handleSet('gender', 'female', e)}
                    >
                      Female
                    </Button>
                  </$InputButton>
                  <$InputButton isActive={form.gender === 'male'}>
                    <Button
                      disabled={isLoading || !editableForm}
                      startIcon={Male}
                      variant="custom"
                      color={
                        form.gender === 'male'
                          ? theme.colors.mediumBlue
                          : theme.colors.grey
                      }
                      shadow={form.gender === 'male'}
                      onClick={(e) => handleSet('gender', 'male', e)}
                    >
                      Male
                    </Button>
                  </$InputButton>
                  <$InputButton isActive={form.gender === 'other'}>
                    <Button
                      disabled={isLoading || !editableForm}
                      startIcon={Other}
                      variant="custom"
                      color={
                        form.gender === 'other'
                          ? theme.colors.primary.dark
                          : theme.colors.grey
                      }
                      shadow={form.gender === 'other'}
                      onClick={(e) => handleSet('gender', 'other', e)}
                    >
                      Other
                    </Button>
                  </$InputButton>
                </Input>
                <Input
                  disabled={
                    isLoading ||
                    !editableForm ||
                    (editableForm && !!dupForm?.email)
                  }
                  variant="input"
                  label="Email Address :"
                  placeholder="Enter your email address"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                  showClear
                  type="email"
                  clear={() => handleSet('email')}
                  inputWidth="350px"
                />
                <Input
                  disabled={
                    isLoading ||
                    !editableForm ||
                    (editableForm && !!dupForm?.phone)
                  }
                  variant="input"
                  label="Contact Number :"
                  placeholder="Enter your mobile number"
                  type="number"
                  id="phone"
                  value={form.phone}
                  onChange={(e) => handleChange(e, 10)}
                  showClear
                  clear={() => handleSet('phone')}
                  inputWidth="350px"
                />
                <Input
                  disabled={isLoading || !editableForm}
                  variant="input"
                  label="Enter Profession :"
                  placeholder="Enter your profession"
                  id="profession"
                  value={form.profession}
                  onChange={handleChange}
                  showClear
                  clear={() => handleSet('profession')}
                  inputWidth="350px"
                />
                <Input
                  disabled={isLoading || !editableForm}
                  variant="input"
                  label="Company Name :"
                  placeholder="Enter your company name"
                  id="companyName"
                  value={form.companyName}
                  onChange={handleChange}
                  showClear
                  clear={() => handleSet('companyName')}
                  inputWidth="350px"
                />
                <Input
                  disabled={isLoading || !editableForm}
                  variant="input"
                  label="City :"
                  placeholder="Enter your city name"
                  id="city"
                  value={form.city}
                  onChange={handleChange}
                  showClear
                  clear={() => handleSet('city')}
                  inputWidth="350px"
                />
                <Input
                  disabled={isLoading || !editableForm}
                  variant="single-select"
                  label="State :"
                  placeholder="Enter your state"
                  options={stateOptions}
                  id="state"
                  value={form.state}
                  onChange={(value) => handleSet('state', value)}
                  inputWidth="350px"
                />
                <Input
                  label="Relationship Status :"
                  id="married"
                  value={form.married}
                >
                  <$InputButton isActive={form.married === 'married'}>
                    <Button
                      disabled={isLoading || !editableForm}
                      variant="custom"
                      color={
                        form.married === 'married'
                          ? theme.colors.mediumBlue
                          : theme.colors.grey
                      }
                      shadow={form.married === 'married'}
                      onClick={(e) => handleSet('married', 'married', e)}
                    >
                      Married
                    </Button>
                  </$InputButton>
                  <$InputButton isActive={form.married === 'unmarried'}>
                    <Button
                      disabled={isLoading || !editableForm}
                      variant="custom"
                      color={
                        form.married === 'unmarried'
                          ? theme.colors.mediumBlue
                          : theme.colors.grey
                      }
                      shadow={form.married === 'unmarried'}
                      onClick={(e) => handleSet('married', 'unmarried', e)}
                    >
                      Unmarried
                    </Button>
                  </$InputButton>
                </Input>
                <div />
                {form.married === 'married' && (
                  <>
                    <Input
                      disabled={isLoading || !editableForm}
                      variant="input"
                      label="Spouse First Name :"
                      placeholder="Enter your spouse's first name"
                      id="spouseFirst"
                      value={form.spouseFirst}
                      onChange={handleChange}
                      showClear
                      clear={() => handleSet('spouseFirst')}
                      inputWidth="350px"
                    />
                    <Input
                      disabled={isLoading || !editableForm}
                      variant="input"
                      label="Spouse Last Name :"
                      placeholder="Enter your spouse's last name"
                      id="spouseLast"
                      value={form.spouseLast}
                      onChange={handleChange}
                      showClear
                      clear={() => handleSet('spouseLast')}
                      inputWidth="350px"
                    />
                    <Input
                      variant="date-input"
                      label="Annivarsary Date :"
                      id="anniversaryDate"
                      onChange={(val) => handleSet('anniversaryDate', val)}
                      disabled={isLoading || !editableForm}
                      inputWidth="350px"
                      value={form.anniversaryDate || ''}
                      placeholder="Enter your anniversary date"
                    />
                    <div />
                  </>
                )}
              </form>
            </$CustomerForm>
          </$FlexItem>
          <$FlexItem flexBasis="50%" minWidth="200px" maxWidth="100%">
            <CustomerStats
              stats={{
                ...(form?.stats || {}),
                ...(form?.os ? { os: form.os } : {}),
              }}
            />
          </$FlexItem>
        </FlexBox>
      </$FormContainer>
    </>
  )
}
