import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import StarFilled from 'images/star-filled.svg'
import StarEmpty from 'images/star-empty.svg'
import { spacing } from 'design-system/styles'

const maxStars = 5

const $StyledStarRating = styled.div`
  display: inline-block;
  padding: ${({ padding }) => padding || spacing.m};
  img {
    width: ${(props) => props.size === 'small' && '12px'};
    height: ${(props) => props.size === 'small' && '12px'};
  }
`

/* 

    Here we are using the terminology /score/ for filled + empty star considering
    score is always 'out of something',
    Like in an examination, Q: What's your score? A: 40 (out of 100)

*/

export const StarRating = ({ score, filled, padding, size }) => {
  const renderStars = (value, url) =>
    [...new Array(Number(value))].map((_, index) => (
      <img src={url} key={index} />
    ))

  return (
    <$StyledStarRating padding={padding} size={size}>
      {Number(score) >= 0 ? (
        <>
          {renderStars(score, StarFilled)}
          {renderStars(maxStars - score, StarEmpty)}
        </>
      ) : (
        renderStars(filled, StarFilled)
      )}
    </$StyledStarRating>
  )
}

StarRating.propTypes = {
  score: PropTypes.number,
  filled: PropTypes.number,
}
