import React, { useEffect, useState } from 'react'
import { Text, Button, Input } from 'components/Common'
import { $UploadBox, $BlueBox } from '../AutoEngagement.styled'
import {
  CloudIcon,
  PlusIcon,
  AddBlueFilledIcon,
  FilePlaceholderIcon,
} from 'assets/icons'
import { spacing } from 'design-system'
import { Pdf } from 'components/Common/Pdf'

export const getCouponPosition = (couponPosition) => {
  switch (couponPosition) {
    case 'topLeft':
      return { top: '2px', left: '2px' }

    case 'topRight':
      return { top: '2px', right: '2px' }

    case 'bottomLeft':
      return { bottom: '2px', left: '2px' }

    case 'center':
      return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }

    case 'bottomRight':
    default:
      return { bottom: '2px', right: '2px' }
  }
}

const MediaUpload = ({
  src,
  setSrc,
  onChange,
  advert,
  title = 'Add Image/Generate Link for Image',
  fileType = 'image',
  acceptedFormats,
  uploadWidth = '500px',
  uploadHeight = '400px',
}) => {
  const [isGenerate, setIsGenerate] = useState(!!advert)
  return (
    <$BlueBox>
      {isGenerate ? (
        <>
          <Text variant="small" type="grey" mb={spacing.s}>
            {title}
          </Text>
          <UploadBox
            src={src}
            setSrc={setSrc}
            onChange={onChange}
            fileType={fileType}
            uploadWidth={uploadWidth}
            uploadHeight={uploadHeight}
            acceptedFormats={acceptedFormats}
            whiteBackground
          />
          {!advert && (
            <>
              <Input
                containerMargin="0"
                variant="input"
                label="Image Link"
                placeholder="Image Link"
                id="name"
                value={src}
                onChange={setSrc}
                inputWidth={uploadWidth}
              />
              <Button
                flex
                noBorder
                fontSize="12px"
                variant="textualPrimary"
                margin="auto"
              >
                Download Link
              </Button>
            </>
          )}
        </>
      ) : (
        <Button
          flex
          variant="textualPrimary"
          onClick={() => setIsGenerate(true)}
        >
          <PlusIcon />
          <Text variant="p" weight="bold" type="main">
            &nbsp; Add Image/Generate Link for Image
          </Text>
        </Button>
      )}
    </$BlueBox>
  )
}

const Video = ({ src }) => {
  const [sorce, setSorce] = useState('')
  useEffect(() => {
    setSorce('')
    setTimeout(() => setSorce(src), 10)
  }, [src])
  return sorce ? (
    <video controls>
      <source src={sorce} />
    </video>
  ) : null
}

const getFileView = (
  type,
  src,
  couponId,
  couponPosition,
  couponPreview,
  couponFontColor,
  couponFontSize,
  couponFontTypeface
) => {
  const props = getCouponPosition(couponPosition)
  switch (type) {
    case 'video':
      return <Video src={src} />

    case 'pdf':
      return <Pdf url={src} overflow="auto" />

    case 'csv':
      return <FilePlaceholderIcon />

    default:
      return (
        <big style={{ position: 'relative' }}>
          <img id={src} src={src} />
          {couponId && (
            <big
              style={{
                ...props,
                position: 'absolute',
                background: '#FFF',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: `${couponFontSize}px` || '12px',
                color: couponFontColor || '#3B86FF',
                fontFamily:
                  couponFontTypeface ||
                  '"Roboto", Arial, Helvetica, sans-serif',
              }}
            >
              {couponPreview?.[0]?.prefix
                ? `${couponPreview?.[0]?.prefix}-00000`
                : null}
            </big>
          )}
        </big>
      )
  }
}

export const UploadBox = ({
  isCarousel,
  hideBorder,
  src,
  onChange,
  fileType = 'image',
  displayFileType = fileType,
  acceptedFormats,
  uploadWidth = '500px',
  uploadHeight = '300px',
  whiteBackground,
  couponPosition,
  couponId,
  couponPreview,
  couponFontColor,
  couponFontSize,
  couponFontTypeface,
}) => {
  const [dragOverActivate, setDragOverActivate] = useState(false)
  return (
    <$UploadBox
      whiteBackground={whiteBackground}
      hideBorder={hideBorder}
      width={uploadWidth}
      height={uploadHeight}
      onDragOver={() => setDragOverActivate(true)}
      onDragLeave={() => setDragOverActivate(false)}
      onDrop={() => setDragOverActivate(false)}
      primary={dragOverActivate}
    >
      {!src &&
        (isCarousel ? (
          <div>
            <AddBlueFilledIcon />
            <Text>Add Images</Text>
          </div>
        ) : (
          <div>
            <CloudIcon />
            <Text>Drag & Drop your {displayFileType} here</Text>
            <Text variant="small">
              Or<small>Browse files</small>from your computer
            </Text>
          </div>
        ))}
      {!src && (
        <input type="file" accept={acceptedFormats} onChange={onChange} />
      )}
      {src &&
        getFileView(
          fileType,
          src,
          couponId,
          couponPosition,
          couponPreview,
          couponFontColor,
          couponFontSize,
          couponFontTypeface
        )}
    </$UploadBox>
  )
}

export default MediaUpload
