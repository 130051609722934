import React from 'react'

import {
  Text,
  Input,
  Skeleton,
  ActionCard,
  NoDataFound,
} from 'components/Common'
import { $FlexItem } from '../../ProductAnalytics.styled'
import { $CategoryList, $CategoryListItem } from './Categories.styled'

const Categories = ({
  allCategories,
  onActionClick,
  onCategoryNameChange,
  editCategory,
}) => {
  const isNewCategory = editCategory?.categoryId === 'newCategory'
  return (
    <$CategoryList>
      {allCategories?.length
        ? allCategories?.map((category, index) => (
            <$CategoryListItem
              key={index}
              active={
                editCategory?.categoryId === (category._id || 'unCategorised')
              }
              hover={!editCategory?.categoryId}
              onClick={() => onActionClick('view', category)}
            >
              <$FlexItem width="90%" flexBasis="unset">
                {editCategory?.categoryId !== category._id ||
                editCategory?.action === 'view' ||
                !category._id ? (
                  <Text
                    variant="p"
                    type={category._id ? 'main' : 'error'}
                    ellipsis
                  >
                    {category.name}
                  </Text>
                ) : (
                  <Input
                    label="Update Category"
                    value={editCategory?.category}
                    onChange={onCategoryNameChange}
                    variant="input"
                    placeholder="Category Name"
                    inputWidth="100%"
                    margin="0"
                    autoFocus
                  />
                )}
              </$FlexItem>
              {!isNewCategory &&
              editCategory?.action !== 'edit' &&
              category._id ? (
                <ActionCard
                  onIconClick={(action) => onActionClick(action, category)}
                  item="CATEGORY"
                  components={['edit', 'delete']}
                  gap="4px"
                />
              ) : null}
            </$CategoryListItem>
          ))
        : !isNewCategory && <NoDataFound />}
      {isNewCategory ? (
        <$CategoryListItem active>
          <$FlexItem width="90%" flexBasis="unset">
            <Input
              label="Add Category"
              value={editCategory?.category}
              onChange={onCategoryNameChange}
              variant="input"
              placeholder="Category Name"
              inputWidth="100%"
              margin="0"
              autoFocus
            />
          </$FlexItem>
        </$CategoryListItem>
      ) : null}
    </$CategoryList>
  )
}

export default Categories
