import { spacing } from 'design-system'
import styled, { css } from 'styled-components'

export const Image = styled.img`
  display: block;
  width: ${(props) => props['width'] || `100%`};
  height: ${(props) => props['height'] || `100%`};
`

export const Logo = styled(Image)`
  border: 1px solid
    ${(props) => (props.lightBorder ? 'hsl(0, 0%, 75%)' : '#707070')};
  border-radius: 50%;
  width: ${(props) =>
    props['width'] ||
    {
      tiny: '20px',
      small: '40px',
      medium: '60px',
      large: '80px',
    }[props.size]};
  height: ${(props) =>
    props['height'] ||
    {
      tiny: '2',
      small: '40px',
      medium: '60px',
      large: '80px',
    }[props.size]};
  margin-right: 10px;
  object-fit: contain;
`

export const ClickableImage = styled(Image)`
  margin-right: ${(props) => props.rightMargin || '0px'};
  object-fit: contain;
`
