import { post, get, patch, deleteReq } from 'utils/axiosHandler'
import * as moment from 'moment'
import * as types from '../types/autoEngage'
import { cloneDeep } from 'lodash'

export const getCampaigns = (data, options) => async (dispatch) => {
  try {
    const res = await post('/auto-engage/campaigns/list', data, options)
    dispatch({
      type: types.SET_AE_DATA,
      payload: { aeData: res.data },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_AE_DATA,
      payload: { aeData: [] },
    })
    dispatch(setLoadTable(false))
  }
}

export const getCoupons = (params) => async (dispatch) => {
  try {
    const res = await get('/auto-engage/coupons', params)
    dispatch({
      type: types.SET_COUPONS_DATA,
      payload: { couponData: res },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_COUPONS_DATA,
      payload: { couponData: {} },
    })
    dispatch(setLoadTable(false))
  }
}

export const getSurveys = (params) => async (dispatch) => {
  try {
    const res = await get('/auto-engage/surveys', params)
    dispatch({
      type: types.SET_SURVEY_DATA,
      payload: {
        surveyData: {
          docs: res?.data?.docs,
          count: res?.data?.totalDocs,
        },
      },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SURVEY_DATA,
      payload: { surveyData: {} },
    })
    dispatch(setLoadTable(false))
  }
}
export const getWhatsAppTemplates = (params) => async (dispatch, getState) => {
  try {
    const selectedVendor = getState().vendorIds.selectedVendor
    const res = await get(
      `/auto-engage/whatsapp-services/${selectedVendor.value}/templates/list`,
      params
    )
    dispatch({
      type: types.SET_WHATSAPP_TEMPLATE_LIST,
      payload: {
        whatsAppTemplates: {
          docs: res?.data?.docs,
          count: res?.data?.totalDocs,
        },
      },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_WHATSAPP_TEMPLATE_LIST,
      payload: {
        whatsAppTemplates: { docs: [], count: 0 },
      },
    })
    dispatch(setLoadTable(false))
  }
}

export const getSurveyResponses = (surveyId, params) => async (dispatch) => {
  try {
    const res = await post(`/auto-engage/survey-responses/${surveyId}`, params)
    dispatch({
      type: types.SET_SURVEY_RESPONSES_DATA,
      payload: {
        surveyResponseData: {
          docs: res?.data?.docs,
          count: res?.data?.totalDocs,
          pageCount: res?.data?.totalPages,
          totalDocs: res?.data?.totalDocs,
        },
      },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SURVEY_RESPONSES_DATA,
      payload: { surveyResponseData: {} },
    })
    dispatch(setLoadTable(false))
  }
}

export const getSegments = (params) => async (dispatch) => {
  try {
    const res = await get('/auto-engage/campaigns/segments-list', params)
    dispatch({
      type: types.SET_SEGMENT_DATA,
      payload: {
        segmentData: res?.data,
      },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SEGMENT_DATA,
      payload: { segmentData: [] },
    })
    dispatch(setLoadTable(false))
  }
}

export const getSurveyButtons = (params) => async (dispatch) => {
  try {
    const res = await get('/auto-engage/survey-buttons', params)
    dispatch({
      type: types.SET_SURVEY_BUTTON_DATA,
      payload: {
        surveyButtonsData: res?.data,
      },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SURVEY_BUTTON_DATA,
      payload: { surveyButtonsData: [] },
    })
    dispatch(setLoadTable(false))
  }
}

export const getCampaignById = (campaignId) => async (dispatch) => {
  dispatch(setCampaignLoader(true))
  try {
    const res = await get(`/auto-engage/campaigns/${campaignId}`)
    // const res = {
    //   data: {
    //     content: {
    //       body: {
    //         componentType: 'image',
    //         assetsDetails: [
    //           {
    //             assetId: '616d0b9cce4a690013e5495d',
    //             key: 'assets/nygrw1khk6j78bq2/3613dbfb6f65c2a5f482f1fc8280871d_1634536348234.jpg',
    //             couponVisibility: false,
    //             fileLocation:
    //               'https://d2e5rq195g1qgs.cloudfront.net/assets/nygrw1khk6j78bq2/3613dbfb6f65c2a5f482f1fc8280871d_1634536348234.jpg',
    //           },
    //         ],
    //       },
    //     },
    //     targetGroup: {
    //       storeIds: ['nze261kgk66fv4cr'],
    //       brandId: 'ld0341wwk9xxv20w',
    //       type: 'stores',
    //     },
    //     repeatEvery: {
    //       days: [],
    //     },
    //     stats: {
    //       linkVisits: {
    //         'www.url1.com': 1,
    //         'www.url2.com': 2,
    //         'www.url3.com': 5,
    //         'www.url4.com': 3,
    //       },
    //       buttonClicks: 4,
    //       totalEmailSent: 22,
    //       totalCost: 5,
    //       totalSmsSent: 1,
    //       totalSmsDelivered: 3,
    //       totalSurveyVisits: 6,
    //       totalSurveySubmitted: 100,
    //       totalReportEntries: 33,
    //       survey: {
    //         id1: {
    //           name: 'Nameone',
    //           totalSurveySent: 500,
    //         },
    //         surveyId2: {
    //           name: 'The great khali',
    //           totalSurveySent: 750,
    //         },
    //       },
    //       coupon: {
    //         _id1: {
    //           name: 'I am a coupon',
    //           prefix: 'TTH',
    //           totalCouponSent: 1000,
    //         },
    //         _id2: {
    //           name: 'also a coupon',
    //           prefix: 'MMT',
    //           totalCouponSent: 2000,
    //         },
    //       },
    //     },
    //     isActive: true,
    //     status: 'live',
    //     journeyCampaign: false,
    //     _id: '6163e013d553d800197240c6',
    //     vendorId: 'nygrw1khk6j78bq2',
    //     companyId: 'nygrw1khk6j7cmy1',
    //     createdBy: '5e786fc10ad21e05d95966a4',
    //     name: 'axs',
    //     type: 'bannerInBill',
    //     description: 'sxc',
    //     createdDate: '2021-10-11T06:56:19.072Z',
    //     updatedDate: '2021-10-18T05:52:39.018Z',
    //     __v: 0,
    //     validity: null,
    //     activateAt: null,
    //     deactivateAt: null,
    //   },
    // }
    if (res?.data) {
      let result = cloneDeep(res?.data)
      if (result.startDate && result.scheduledTime) {
        const [hours, minutes] = result.scheduledTime?.split(':')
        const [startDate, scheduledTime] = moment
          .utc(result.startDate)
          ?.set({ hours, minutes })
          ?.local()
          ?.format('YYYY-MM-DD/HH:mm')
          ?.split('/')
        result = { ...cloneDeep(res?.data), startDate, scheduledTime }
      }
      dispatch(setCampaignData(cloneDeep(result)))
    }
    dispatch(setCampaignLoader(false))
  } catch (error) {
    console.error(error)
    dispatch(setCampaignData({ error: 'Backend Error' }))
    dispatch(setCampaignLoader(false))
  }
}

export const getSurveyById = (surveyId) => async (dispatch) => {
  dispatch(setCampaignLoader(true))
  try {
    const res = await get(`/auto-engage/surveys/${surveyId}`)
    dispatch(setSurveyData(res?.data))
    dispatch(setCampaignLoader(false))
  } catch (error) {
    console.error(error)
    dispatch(setSurveyData({ error: 'Backend Error' }))
    dispatch(setCampaignLoader(false))
  }
}

export const getEmailProviders = (vendorId) => async (dispatch) => {
  try {
    const res = await get(`/auto-engage/email-clients/${vendorId}`)
    dispatch({
      type: types.SET_AE_EMAIL_PROVIDERS,
      payload: { emailProvidersList: [...res?.data] || [] },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_AE_EMAIL_PROVIDERS,
      payload: { emailProvidersList: [] },
    })
  }
}

export const getAssetsList = (params) => async (dispatch) => {
  let type = 'SET_AE_ASSETS_LIST'
  let list = 'assetsList'
  switch (params.fileType) {
    case 'csv':
      type = 'SET_AE_CSV_LIST'
      list = 'assetsCsvList'
      break

    case 'image':
      type = 'SET_AE_IMAGE_LIST'
      list = 'assetsImageList'
      break

    case 'carousel':
      type = 'SET_AE_CAROUSEL_LIST'
      list = 'assetsCarouselList'
      break

    case 'video':
      type = 'SET_AE_VIDEO_LIST'
      list = 'assetsVideoList'
      break

    case 'pdf':
      type = 'SET_AE_PDF_LIST'
      list = 'assetsPdfList'
      break

    case 'gif':
      type = 'SET_AE_GIF_LIST'
      list = 'assetsGifList'
      break

    default:
      type = 'SET_AE_ASSETS_LIST'
      list = 'assetsList'
      break
  }
  dispatch(setCampaignLoader(true))
  try {
    const res = await get(`/auto-engage/assets/list`, params)
    if (res) {
      res.limit = params.limit
    }
    dispatch({
      type: types[type],
      payload: { [list]: res || {} },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types[type],
      payload: { [list]: {} },
    })
  } finally {
    dispatch(setCampaignLoader(false))
  }
}

export const setCampaignData = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_AE_CAMPAIGN_DATA,
    payload: { aeCampaignData: data },
  })
}

export const setSurveyData = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_AE_SURVEY_DATA,
    payload: { aeSurveyData: data },
  })
}

export const setLoadTable = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_AE_TABLE_LOADING,
    payload: { aeTableLoading: bool },
  })
}

export const setCampaignLoader = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_AE_LOADING,
    payload: { aeLoading: bool },
  })
}

export const setFromSIB = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_FROM_SIB,
    payload: { isSIB: bool },
  })
}

export const setTemplateData = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_AE_WHATSAPP_TEMPLATE,
    payload: { SET_AE_WHATSAPP_TEMPLATE: data },
  })
}

export const filterToQuery =
  (filter, isTemplateTab = false) =>
  async (dispatch) => {
    let filterQuery = {}
    if (filter.timeFrame?.isActive) {
      filterQuery = {
        ...filterQuery,
        startDate: moment(filter.timeFrame.value.start).format('YYYY-MM-DD'),
        endDate: moment(filter.timeFrame.value.end).format('YYYY-MM-DD'),
      }
    }
    if (filter.types?.isActive) {
      let types = filter.types.value
      filterQuery = { ...filterQuery, types }
    }
    if (filter.status?.isActive) {
      let status = filter.status.value
      filterQuery = { ...filterQuery, status }
    }
    const key = isTemplateTab ? 'wATemplateFilterData' : 'filterData'
    dispatch({
      type: types.SET_AE_FILTERS,
      payload: { [key]: filter, filterQuery },
    })
    return filterQuery
  }

// No Dispatch
export const postCampaign = async (data) => {
  return await post('/auto-engage/campaigns', data)
}

export const updateCampaign = async (campaignId, data) => {
  return await patch(`/auto-engage/campaigns/${campaignId}`, data)
}

export const exportCampaignReport = async (campaignId, data) => {
  return await post(`/auto-engage/campaigns/${campaignId}/user-stats`, data)
}

export const duplicateCampaign = async (campaignId, data) => {
  return await post(`/auto-engage/campaigns/${campaignId}`, data)
}

export const deleteCampaign = async (campaignId) => {
  return await deleteReq(`/auto-engage/campaigns/${campaignId}`)
}

export const uploadCsvData = async (formData, options) => {
  return await post(`/auto-engage/campaigns/customers`, formData, options)
}

export const postEmailProvider = async (data) => {
  return await post('/auto-engage/email-clients', data)
}

export const uploadFiles = async (formData, options) => {
  return await post(`/auto-engage/assets`, formData, options)
}

export const postCoupon = async (data) => {
  return await post('/auto-engage/coupons', data)
}

export const updateCoupon = async (id, data) => {
  return await patch(`/auto-engage/coupons/${id}`, data)
}

export const deleteCoupon = async (id) => {
  return await deleteReq(`/auto-engage/coupons/${id}`)
}

export const generateCoupon = async (id) => {
  return await get(`/auto-engage/coupons/download/${id}`)
}

export const deleteAsset = async (id) => {
  return await deleteReq(`/auto-engage/assets/${id}`)
}

export const postSurvey = async (data) => {
  return await post('/auto-engage/surveys', data)
}

export const updateSurvey = async (surveyId, data) => {
  return await patch(`/auto-engage/surveys/${surveyId}`, data)
}

export const duplicateSurvey = async (surveyId, data) => {
  return await post(`/auto-engage/surveys/${surveyId}`, data)
}

export const deleteSurvey = async (id) => {
  return await deleteReq(`/auto-engage/surveys/${id}`)
}

export const postSegment = async (data) => {
  return await post('/auto-engage/campaigns/segments', data)
}

export const testCampaign = async (campaignId, data) => {
  return await post(`/auto-engage/campaigns/test/${campaignId}`, data)
}

export const postSurveyButton = async (data) => {
  return await post('/auto-engage/survey-buttons', data)
}

export const deleteSurveyButton = async (id) => {
  return await deleteReq(`/auto-engage/survey-buttons/${id}`)
}

export const exportSurveyResponses = async (surveyId, data) => {
  return await post(`/auto-engage/survey-responses/export/${surveyId}`, data)
}

export const getEmailTemplates = (vendorId, options) => async (dispatch) => {
  try {
    const res = await get(`/email-services/${vendorId}/templates`, options)
    dispatch({
      type: types.SET_EMAIL_TEMPLATES,
      payload: { emailTemplates: res?.data },
    })
  } catch (err) {
    console.error(err)
  }
}

export const getEmailTemplateDetails =
  (vendorId, templateId) => async (dispatch) => {
    try {
      const res = await get(
        `/email-services/${vendorId}/templates/${templateId}`
      )
      dispatch(setEmailTemplateDetails(res?.data))
    } catch (err) {
      console.error(err)
    }
  }

export const postEmailTemplate = async (vendorId, body) =>
  await post(`/email-services/${vendorId}/templates`, body)

export const updateEmailTemplate = async (vendorId, templateId, body) =>
  await patch(`/email-services/${vendorId}/templates/${templateId}`, body)

export const setEmailTemplateDetails = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_EMAIL_TEMPLATE_DETAILS,
    payload: { emailTemplateDetails: data },
  })
}

export const setAudienceStoreFilter = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_AUDIENCE_FILTER,
    payload: { newAudienceStoreFilter: data },
  })
}
export const getActiveCampaignCount = async (vendorId) => {
  try {
    const result = await get(
      `/auto-engage/campaigns/activeCampaignCount/${vendorId}`
    )
    return result
  } catch (e) {
    return false
  }
}

export const postTemplate = async (data) => {
  return await post('/auto-engage/whatsapp-services', data)
}

export const updateTemplate = async (vendorId, data) => {
  return await patch(`/auto-engage/whatsapp-services/${vendorId}`, data)
}

export const duplicateWATemplate = async (_id, data) => {
  return await post(
    `/auto-engage/whatsapp-services/${data.vendorId}/templates/${_id}/duplicate`,
    data
  )
}

export const deleteWATemplate = async (id, vendorId) => {
  return await deleteReq(
    `/auto-engage/whatsapp-services/${vendorId}/templates/${id}`
  )
}
