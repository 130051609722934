import React from 'react'
import { DeleteRedIcon } from 'assets/icons'
import { FlexBox } from 'styledComponent'
import { Input } from 'components/Common'

export const Radio = (props) => {
  const isChecked = props.answer?.includes(props?.optionId) || false
  const readOnly = ['gender', 'serviceExplicit'].includes(props.type)
  return (
    <FlexBox padding="0 0 10px 0" align="center" justify="flex-start" gap="8px">
      <input
        readOnly
        name={props.questId}
        type={props.inputType}
        checked={isChecked}
      />
      {props.isEditing ? (
        <Input
          containerMargin="0"
          variant="input"
          inputWidth="300px"
          disabled={!props.isEditing || readOnly}
          value={props.option}
          showClear={!readOnly}
          onChange={(e) => props.setOption(e.target.value)}
          clear={() => props.setOption('')}
          placeholder={`Option ${props.optId + 1}`}
        />
      ) : (
        <div style={{ color: props.fontColor, fontFamily: props.fontFamily }}>
          {props.option}
        </div>
      )}
      {props?.optionsLength > 1 && props.isEditing && !readOnly && (
        <DeleteRedIcon
          onClick={() => props.deleteOption(props.optId)}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        />
      )}
    </FlexBox>
  )
}
