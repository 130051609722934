import { get, patch, post, deleteReq } from 'utils/axiosHandler'
import * as types from 'redux/types/settings'

const dltBaseUrl = '/settings/'

export const getSettingsData = (type = '') => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value

  try {
    const res = await get(`/settings/vendor/${vendorId}?type=${type}`)

    dispatch({
      type: types.SET_SETTINGS_DATA,
      payload: { [type]: res.data },
    })

    if (res?.data?.isConfigured === false) {
      dispatch({
        type: types.SET_SMS_HEADERS,
        payload: { smsHeadersList: [] },
      })
      dispatch({
        type: types.SET_SMS_TEMPLATES,
        payload: { smsTemplatesList: [] },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const postSettingsData = async (data) =>
  await post(`/settings/vendor/${data?.vendorId}`, data)

export const updateSettingsData = async (data) =>
  await patch(`/settings/vendor/${data?.vendorId}`, data)

export const getSmsHeaders = (options = {}) => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setSettingsTableLoader(true))
  try {
    const res = await get(`${dltBaseUrl}${vendorId}/sms-headers`, options)

    dispatch({
      type: types.SET_SMS_HEADERS,
      payload: { smsHeadersList: res.data },
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.SET_SMS_HEADERS,
      payload: { smsHeadersList: [] },
    })
  } finally {
    dispatch(setSettingsTableLoader(false))
  }
}
export const postSmsHeaders = async (vendorId, data, config) =>
  await post(`${dltBaseUrl}${vendorId}/sms-headers`, data, config)

export const deleteSmsHeaders = async (vendorId, headerId) =>
  await deleteReq(`${dltBaseUrl}${vendorId}/sms-headers/${headerId}`)

export const getSmsTemplates = (options = {}) => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setSettingsTableLoader(true))
  try {
    const res = await get(`${dltBaseUrl}${vendorId}/sms-templates`, options)

    dispatch({
      type: types.SET_SMS_TEMPLATES,
      payload: { smsTemplatesList: res.data },
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.SET_SMS_TEMPLATES,
      payload: { smsTemplatesList: [] },
    })
  } finally {
    dispatch(setSettingsTableLoader(false))
  }
}

export const postSmsTemplates = async (vendorId, data, config) =>
  await post(`${dltBaseUrl}${vendorId}/sms-templates`, data, config)

export const deleteSmsTemplates = async (vendorId, templateId) =>
  await deleteReq(`${dltBaseUrl}${vendorId}/sms-templates/${templateId}`)

export const setSettingsLoader = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_SETTINGS_LOADING,
    payload: { settingsLoading: bool },
  })
}

export const setSettingsTableLoader = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_SETTINGS_TABLE_LOADING,
    payload: { settingsTableLoading: bool },
  })
}

export const setSettingsData = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_SETTINGS_DATA,
    payload: { [data.type]: data },
  })
}
