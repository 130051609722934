import cloneDeep from 'lodash/cloneDeep'
import { defaultFeedbackCampaignFilters } from 'utils/feedback'

const initialState = {
  data: [],
  filters: {
    search: '',
    ...cloneDeep(defaultFeedbackCampaignFilters),
  },
  sort: {
    id: 'newest',
    value: 'Newest First',
    sortOrder: -1,
    sortValue: '_id',
  },
  hasMoreCampaigns: true,
  campaignCount: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_FEEDBACK_CAMPAIGNS':
      return {
        ...state,
        data: [...action.payload.campaignData],
        campaignCount: action.payload.campaignCount,
        hasMoreCampaigns: action.payload.hasMoreCampaigns,
      }
    case 'SET_FEEDBACK_CAMPAIGN_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case 'SET_CAMPAIGN_REMEMBER_FILTERS':
      return {
        ...state,
        rememberFilters: action.payload,
      }

    case 'SET_FEEDBACK_CAMPAIGN_SORT':
      return {
        ...state,
        sort: { ...action.payload },
      }
    case 'UPDATE_FEEDBACK_CAMPAIGNS':
      return {
        ...state,
        data: [...action.payload],
      }

    case 'RESET_CAMPAIGN_FILTERS':
      return {
        ...state,
        filters: cloneDeep(initialState.filters),
      }

    default:
      return state
  }
}
