import React from 'react'
import { Div, FlexBox } from 'styledComponent'
import styled, { useTheme } from 'styled-components'
import { Text } from 'components/Common'
import { LeftIcon } from 'assets/icons'

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || '100%'};
  margin: ${({ m }) => m || 'unset'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
`

const $TopControlsBlock = styled($FlexBox)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.l}`};
  border-top: 1px solid ${({ theme }) => theme.colors.darkMercury};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkMercury};
`

const $LeftIcon = styled(LeftIcon)`
  width: 16px;
  margin-right: ${({ theme }) => theme.spacing.s};
`

const $ExitBlock = styled($FlexBox)`
  width: max-content;
  padding-right: ${({ theme }) => theme.spacing.l};
  cursor: pointer;
`

export const TopActionBar = ({ title, onExit, extras, exitTitle }) => {
  const theme = useTheme()
  return (
    <$TopControlsBlock justify="space-between" align="center">
      <FlexBox align="center">
        <ExitBlock onExit={onExit} exitTitle={exitTitle} />
        <Div padding={`0 0 0 ${theme.spacing.l}`}>
          {typeof title === 'string' ? (
            <Text variant="p" weight="semi-bold">
              {title}
            </Text>
          ) : (
            title
          )}
        </Div>
      </FlexBox>
      {extras}
    </$TopControlsBlock>
  )
}

export const ExitBlock = ({ onExit, exitTitle = 'Exit' }) => {
  const theme = useTheme()
  return (
    <$ExitBlock
      onClick={onExit}
      width="100px"
      borderRight={`1px solid ` + theme.colors.darkMercury}
      justify="center"
      align="center"
    >
      <$LeftIcon />
      <Text variant="p" py={theme.spacing.m} type="primary">
        {exitTitle}
      </Text>
    </$ExitBlock>
  )
}
