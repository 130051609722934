import React from 'react'
import { DeleteRedIcon } from 'assets/icons'
import { FlexBox } from 'styledComponent'
import { Input } from 'components/Common'

export const Dropdown = (props) => {
  return props.isEditing ? (
    <FlexBox padding="0 0 10px 0" align="center" justify="flex-start" gap="8px">
      <Input
        containerMargin="0"
        variant="input"
        inputWidth="300px"
        disabled={!props.isEditing}
        value={props.option}
        showClear
        onChange={(e) => props.setOption(e.target.value)}
        clear={() => props.setOption('')}
        placeholder={`Option ${props.optId + 1}`}
      />
      {props?.optionsLength > 1 && (
        <DeleteRedIcon
          onClick={() => props.deleteOption(props.optId)}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        />
      )}
    </FlexBox>
  ) : null
}
