import React, { useEffect, useRef, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { cloneDeep, debounce } from 'lodash'
import { useSelectStore } from 'hooks'
import { SectionHeading, ActiveFilterPills } from 'components/Common'
import { $SquareButton, $SearchInput } from '../Report.styled'
import { FilterIcon } from 'assets/icons'
import ReportFilter from './ReportFilter'
import { initialFilter } from '../components/constants'
import { filterToQuery } from 'redux/actions/reportsAction'
import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'

const ReportHeader = (props) => {
  const filterRef = useRef()
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const [searchTxt, setSearchTxt] = useState('')

  useEffect(() => {
    props.filterToQuery(initialFilter)
  }, [selectedVendor])

  useEffect(() => {
    const query = {
      vendorId: selectedVendor.value,
      ...props.reports.filterQuery,
    }
    if (searchTxt?.length > 0) {
      query.searchTxt = searchTxt
    }
    debounceLoadData(query)
  }, [selectedVendor, props.reports.filterQuery, searchTxt])

  const fetchQuery = (query) => props.setQuery({ ...query, page: 1 })

  const debounceLoadData = useCallback(debounce(fetchQuery, 750), [])

  const showModal = () => filterRef.current.openModal()

  const handleRemoveAll = () => props.filterToQuery(initialFilter)

  const handleClosePill = (_, type) => {
    const fil = cloneDeep(props.reports.filterData)
    delete fil[type]
    props.filterToQuery({ ...initialFilter, ...fil })
  }

  const handleFilter = (filter) => {
    props.filterToQuery(filter)
  }

  const pillData = Object.values(props.reports.filterData).filter(
    (fil) => fil.isActive
  )

  return (
    <>
      <FlexBox mx={spacing.l} mt={spacing.l} justify="space-between">
        <span>
          <SectionHeading
            heading="All Reports"
            subHeading="List of all reports in your system"
            variant="medium"
            subVariant="small"
          />
        </span>
        <FlexBox>
          <$SearchInput>
            <span />
            <input
              id="searchTxt"
              value={searchTxt}
              placeholder="Search by Report Name"
              onChange={(e) => setSearchTxt(e.target.value)}
            />
          </$SearchInput>
          <$SquareButton isFilter onClick={showModal} size={40}>
            <FilterIcon />
          </$SquareButton>
        </FlexBox>
        <ReportFilter
          ref={filterRef}
          data={props.reports.filterData}
          handleFilter={handleFilter}
        />
      </FlexBox>
      <FlexBox mx={spacing.l} mb={spacing.l}>
        <ActiveFilterPills
          ref={filterRef}
          pillData={pillData}
          handleAdd={showModal}
          handleRemoveAll={handleRemoveAll}
          handleClosePill={handleClosePill}
        />
      </FlexBox>
    </>
  )
}

const mapStateToProps = (state) => ({
  reports: state.reports,
})

export default connect(mapStateToProps, { filterToQuery })(ReportHeader)
