import styled from 'styled-components'

import { spacing } from 'design-system'
import MultiSelect from 'components/Common/MultiSelect'

const $MultiSelect = styled(MultiSelect)`
  width: 50%;
`

const $Div = styled.div`
  padding: ${spacing.l} 0;
`

export { $MultiSelect, $Div }
