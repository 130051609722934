import { post } from 'utils/axiosHandler'
import { getProcessedFilters } from 'utils/feedback'

export const getFeedbackCampaigns = (data) => async (dispatch, getState) => {
  const state = getState()
  const filters = getProcessedFilters(state.feedbackCampaign.filters)
  const sort = state.feedbackCampaign.sort

  const result = await post('/feedback/campaign/list', {
    ...data,
    ...filters,
    ...sort,
  })

  if (result.code) {
    const hasMoreCampaigns =
      result.campaignData.length < parseInt(result.campaignCount)
    dispatch(getFeedbackCampaignsHelper({ ...result, hasMoreCampaigns }))
    return result
  }
}

export const getFeedbackCampaignsHelper = (data) => ({
  type: 'SET_FEEDBACK_CAMPAIGNS',
  payload: data,
})

export const setFeedbackCampaignFilters = (data) => (dispatch) => {
  dispatch(setFeedbackCampaignFiltersHelper(data))
}

export const setRememberCampaignFilters = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CAMPAIGN_REMEMBER_FILTERS',
    payload: data,
  })
}

const setFeedbackCampaignFiltersHelper = (data) => ({
  type: 'SET_FEEDBACK_CAMPAIGN_FILTERS',
  payload: data,
})

export const setFeedbackCampaignSort = (data) => (dispatch) => {
  dispatch(setFeedbackCampaignSortHelper(data))
}

const setFeedbackCampaignSortHelper = (data) => ({
  type: 'SET_FEEDBACK_CAMPAIGN_SORT',
  payload: data,
})

export const updateFeedbackCampaigns = (data) => (dispatch) =>
  dispatch({
    type: 'UPDATE_FEEDBACK_CAMPAIGNS',
    payload: data,
  })

export const resetCampaignFilters = (_) => (dispatch) => {
  dispatch({
    type: 'RESET_CAMPAIGN_FILTERS',
  })
}
