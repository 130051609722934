import styled, { css } from 'styled-components'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import { Button } from 'components/Common'
import CustomPopup from 'components/Content/CustomPopup'
import SearchIcon from 'images/search.svg'

const $WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`

const $MarginlessLine = styled.hr`
  margin: ${spacing.s} 0;
`

const $TabPadding = styled(FlexBox)`
  padding: 0 ${spacing.l};
  ul {
    li {
      padding: ${spacing.m};
    }
  }
`

const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) => props.theme.colors.primary.default};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
  :disabled {
    cursor: not-allowed;
  }
`

const $SearchInput = styled.div`
  position: relative;
  cursor: pointer;
  input {
    border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
    width: 40px;
    height: 40px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${spacing.l};
    margin-right: ${spacing.l};
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    transition: width 0.5s;
    &:focus,
    :hover {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
  }
  &:focus,
  :hover {
    input {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
  }
  span {
    position: absolute;
    top: 10px;
    right: 25px;
    background: ${({ theme }) => theme.colors.white} url(${SearchIcon}) center;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`

const $ReportHeader = styled(FlexBox)`
  margin: ${spacing.l};
`

const $ReportTableContainer = styled.div`
  padding: 0 ${spacing.l};
  table {
    margin: 0 0 ${spacing.xl};
  }
`

const $ReportFormContainer = styled.div`
  padding: ${spacing.m} ${spacing.xl};
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  div {
    max-width: 500px;
  }
`

const $ReportButton = styled(Button)`
  font-size: 12px;
  min-width: 110px;
`

const $ReportPopup = styled(CustomPopup)`
  &.modal_view {
    grid-template-rows: 40px auto 50px;
    height: auto;
  }
`

const $Filter = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[300]};
`

const $SelectedFilter = styled.div`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.m} ${spacing.l};
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[300]};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${props.theme.colors.primary[100]};
      color: ${props.theme.colors.neutral[900]};
    `};

  ${(props) =>
    props.isFilterActive &&
    css`
      color: ${props.theme.colors.primary.dark};
    `}
`

const $ActiveFilter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-left: 1px solid ${(props) => props.theme.colors.neutral[300]};
`

export {
  $WhiteContainer,
  $MarginlessLine,
  $TabPadding,
  $SquareButton,
  $ReportButton,
  $ReportTableContainer,
  $ReportFormContainer,
  $ReportPopup,
  $Filter,
  $ActiveFilter,
  $SelectedFilter,
  $SearchInput,
}
