import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import { Div, FlexBox } from 'styledComponent'
import { Text, Button, useToasts } from 'components/Common'
import { CloseIcon } from 'assets/icons'
import { spacing } from 'design-system'
import { getAssetsList, uploadFiles } from 'redux/actions/autoEngageActions'
import {
  $PopContainer,
  $UploadSidebar,
  $UploadSidebarItem,
  $MarginlessLine,
} from '../AutoEngagement.styled'
import {
  GET_ASSET_LIST_COUNT,
  POPUP_STYLE,
  UPLOAD_TYPES,
  SUPPORTED_FORMATS,
  ERROR_DECODE,
} from '../constants'
import { UploadBox } from './MediaUpload'
import { RecentUploads } from './RecentUploads'

const UploadModal = (props) => {
  const { toast } = useToasts()
  const { isPopOpen, setIsPopOpen, vendorId, storeUser, uploadedFrom } = props
  const filterMedias = props.showMedias || UPLOAD_TYPES.map((upl) => upl.value)
  const initMedia = props.showMedias ? props.showMedias[0] : 'image'

  const [mediaType, setMediaType] = useState(initMedia)

  const { assetsList, assetsCount, assetsLimit } = GET_ASSET_LIST_COUNT(
    mediaType,
    props.autoEngage
  )

  const [limit, setLimit] = useState(assetsLimit)
  const [refreshList, setRefreshList] = useState(0)

  const acceptedFormats = SUPPORTED_FORMATS(mediaType)

  const fetchFiles = () =>
    props.getAssetsList({
      vendorId,
      limit,
      skip: 0,
      fileType: mediaType,
    })

  useEffect(() => {
    if (
      (assetsCount === 0 || limit !== assetsLimit || !!refreshList) &&
      vendorId &&
      mediaType
    ) {
      fetchFiles(limit)
    }
  }, [vendorId, mediaType, limit, refreshList])

  const uploadFile = async (e) => {
    try {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('name', file?.name || mediaType)
      formData.append(
        'description',
        `${mediaType.toUpperCase()} uploaded at ${new Date().toString()}`
      )
      formData.append('file', file)
      formData.append('storeUser', storeUser)
      formData.append('vendorId', vendorId)
      formData.append('uploadedFrom', uploadedFrom)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const res = await uploadFiles(formData, config)
      if (res) {
        if (props.setMedia) {
          props.setMedia(res)
        }
        fetchFiles(limit)
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <Popup
      open={isPopOpen}
      onClose={() => setIsPopOpen(false)}
      contentStyle={{ ...POPUP_STYLE, width: '60vw', maxHeight: 'auto' }}
      className="modal"
    >
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            Upload Modal
          </Text>
          <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
        </FlexBox>

        <FlexBox style={{ padding: 0 }}>
          <$UploadSidebar>
            {UPLOAD_TYPES.map(
              (type) =>
                filterMedias.includes(type.value) && (
                  <$UploadSidebarItem
                    key={type.value}
                    isActive={type.value === mediaType}
                    onClick={() => setMediaType(type.value)}
                  >
                    {type.label}
                  </$UploadSidebarItem>
                )
            )}
          </$UploadSidebar>

          <Div width="100%">
            <Div padding={spacing.l}>
              <UploadBox
                uploadWidth="100%"
                uploadHeight="150px"
                fileType={mediaType}
                acceptedFormats={acceptedFormats}
                onChange={uploadFile}
              />
            </Div>
            <$MarginlessLine />
            <RecentUploads
              limit={limit}
              setLimit={setLimit}
              assetsCount={assetsCount}
              assetsList={assetsList}
              setMedia={props.setMedia}
              mediaType={mediaType}
              setRefreshList={setRefreshList}
              height="340px"
            />
          </Div>
        </FlexBox>

        <FlexBox justify="flex-end">
          <Button variant="primaryOutline" onClick={() => setIsPopOpen(false)}>
            Close
          </Button>
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}

const mapStateToProps = (state) => ({
  autoEngage: state.autoEngage,
  storeUser: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, { getAssetsList })(UploadModal)
