import originalMoment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(originalMoment)

export const initialState = {
  retentionRate: 0,
  totalNumbersCount: 0,
  numbersCollected: [],
  totaltransactions: 0,
  data: [],
  filters: {
    groupBy: {
      isActive: false,
      value: 'user',
    },
    emailList: {
      isActive: false,
      value: [],
    },
    date: {
      isActive: true,
      value: moment.range(
        moment().clone().startOf('month'),
        moment().clone().endOf('day')
      ),
    },
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_CONSUMER_ANALYTICS':
      return {
        ...state,
        retentionRate: action.payload.counts.retentionRate,
        totalNumbersCount: action.payload.counts.totalNumbersCount,
        numbersCollected: action.payload.counts.numbersCollected,
        totaltransactions: action.payload.counts.totaltransactions,
      }

    case 'SET_CONSUMER_NUMBERS':
      return {
        ...state,
        totalNumbersToday: action.payload.totalNumbersToday,
        retentionRate: action.payload.retentionRate,
        totalNumbersCount: action.payload.totalNumbersCollected,
        totaltransactions: action.payload.totalTransactions,
      }

    case 'SET_CONSUMER_DATA':
      return {
        ...state,
        data: action.payload,
      }

    case 'GET_CONSUMER_ANALYTICS_NUMBERS_COLLECTED':
      return {
        ...state,
        totalNumbersCount: action.payload.count,
      }

    case 'SET_CONSUMER_ANALYTICS_FILTERS':
      return {
        ...state,
        filters: action.payload,
      }

    case 'SET_CONSUMER_ANALYTICS_REMEMBER_FILTERS':
      return {
        ...state,
        rememberFilters: action.payload,
      }

    default:
      return state
  }
}
