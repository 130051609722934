import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'
import { useDispatch } from 'react-redux'

import { useRouter } from 'hooks'
import { Table, TableHead, TableCell, TableRow, FlexBox } from 'styledComponent'
import {
  DraftIcon,
  LiveIcon,
  SentIcon,
  InactiveIcon,
  ScheduledIcon,
  DuplicateIcon,
  UnpublishedIcon,
  UnpublishedForAllIcon,
  EnabledIcon,
  DisabledIcon,
  RejectedIcon,
  FlaggedIcon,
  PendingIcon,
  ArrowRightIcon,
} from 'assets/icons'
import {
  DotsIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
  ViewResponsesIcon,
  UnPublishIcon,
  UnPublishForAllIcon,
} from 'assets/actions'
import {
  Skeleton,
  Tooltip,
  PopupContainer,
  Text,
  Input,
} from 'components/Common'

import { AE_HEADER, CAMP_TYPES, GET_SEL_VALUE } from '../constants'
import {
  $AETableContainer,
  $Ellipsis,
  $MoreOptions,
} from '../AutoEngagement.styled'
import CancelPopup from '../components/CancelPopup'
import { setFromSIB } from 'redux/actions/autoEngageActions'

const AE_ROW = (camp, onActionClick) => [
  <$Ellipsis margin="none">{camp?.name || '-'}</$Ellipsis>,
  camp?.status ? getStatus(camp?.status) : '-',
  <$Ellipsis>{camp?.description || '-'}</$Ellipsis>,
  GET_SEL_VALUE(camp?.type, CAMP_TYPES)?.label || '-',
  camp?.createdDate ? moment(camp.createdDate).format('DD MMM YYYY') : '-',
  camp?.updatedDate ? moment(camp.updatedDate).format('DD MMM YYYY') : '-',
  moreActions(camp, onActionClick, '', { showDuplicate: true }),
]

export const getStatus = (status) => {
  switch (status) {
    case 'draft':
      return <DraftIcon />

    case 'scheduled':
      return <ScheduledIcon />

    case 'live':
      return <LiveIcon />

    case 'sent':
      return <SentIcon />

    case 'unpublished':
      return <UnpublishedIcon />

    case 'unpublishedForAll':
      return <UnpublishedForAllIcon />

    case 'rejected':
      return <RejectedIcon />

    case 'pending':
      return <PendingIcon />

    case 'enabled':
      return <EnabledIcon />

    case 'disabled':
      return <DisabledIcon />

    case 'flagged':
      return <FlaggedIcon />
    default:
      return null
  }
} //rejected, disabled, pending, enabled

const AETable = ({
  tableLoading,
  onPaginationChange,
  data,
  deleteCampaign,
  dupliCampaign,
  unpublishCampaign,
}) => {
  const theme = useTheme()
  const { history } = useRouter()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [campaignPopDetails, setCampaignPopDetails] = useState({})
  const [isDupliPopupOpen, setIsDupliPopupOpen] = useState(false)
  const [campaignName, setCampaignName] = React.useState('')
  const [selectedCampaignBody, setSelectedCampaignBody] = React.useState('')
  const [isCampaignNameError, setIsCampaignNameError] = React.useState()

  const dispatch = useDispatch()

  const handleDuplicatePopupChange = () => {
    if (isDupliPopupOpen) {
      setCampaignName('')
      setSelectedCampaignBody('')
    }

    setIsDupliPopupOpen(!isDupliPopupOpen)
  }

  const onActionClick = async (action, body) => {
    const { _id, type, content } = body

    switch (action) {
      case 'edit':
        return history.push(`/auto-engage/${type}/${_id}`)

      case 'view':
        return history.push(`/auto-engage/${type}/${_id}/view`)

      case 'show': {
        dispatch(setFromSIB(true))
        return history.push(
          `/auto-engage/surveys/responses/${content?.body?.surveyId}`
        )
      }

      case 'unpublish': {
        setIsPopOpen(true)
        setCampaignPopDetails({
          header: 'Unpublish - Are you sure?',
          title: `Campaign will be stopped for new customers.
          Old Customers will still be able to see the campaign on their old bills.`,
          onOkAction: () => unpublishCampaign(_id),
        })
        return
      }

      case 'unpublishForAll': {
        setIsPopOpen(true)
        setCampaignPopDetails({
          header: 'Unpublish For All - Are you sure?',
          title: `Campaign will be stopped for new customers. \n
          Old Customers will also not be able to see the Campaign on their old bills.`,
          onOkAction: () => unpublishCampaign(_id, 'unpublishedForAll'),
        })
        return
      }

      case 'delete': {
        setIsPopOpen(true)
        setCampaignPopDetails({
          header: 'Delete Campaign - Are you sure?',
          title: `Campaign will be stopped for new customers.
          Old Customers will also not be able to see the campaign on their old bills.
          The Campaign will be deleted from the list.`,
          onOkAction: () => deleteCampaign(_id),
        })
        return
      }

      case 'duplicate':
        setSelectedCampaignBody(body)
        handleDuplicatePopupChange()
        return
    }
  }

  const handleDuplicateNameSubmit = () => {
    let error = isCampaignNameError

    if (error) error = ''

    if (!campaignName) {
      setIsCampaignNameError('Campaign Name cannot be empty')
      return
    }
    if (campaignName) dupliCampaign(selectedCampaignBody, campaignName)

    handleDuplicatePopupChange()
  }

  const handleCampaignNameChange = (e) => setCampaignName(e.target.value)

  return (
    <$AETableContainer>
      <PopupContainer
        isPopOpen={isDupliPopupOpen}
        setIsPopOpen={setIsDupliPopupOpen}
        heading="Duplicate Campaign"
        onSubmitClick={handleDuplicateNameSubmit}
        width="550px"
      >
        <FlexBox m={0} mb={theme.spacing.l} gap={theme.spacing.l}>
          <Tooltip content="Some Info tip" margin={0} />
          <Text weight="800">
            '{selectedCampaignBody.name}' campaign is being duplicated
          </Text>
        </FlexBox>

        <Input
          variant="input"
          label="Enter New Campaign Name"
          id="campaign-name"
          value={campaignName}
          onChange={handleCampaignNameChange}
          placeholder="Enter Campaign Name"
        />
        {isCampaignNameError && (
          <p style={{ color: 'red', fontSize: '12px', margin: '4px' }}>
            {isCampaignNameError}
          </p>
        )}
      </PopupContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow fontSize="14px" cells={AE_HEADER} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            <TableLoader headerLength={AE_HEADER.length} />
          ) : data?.docs?.length > 0 ? (
            data?.docs?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={AE_ROW(item, onActionClick)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={AE_HEADER.length}
              cells={['No Data Found']}
            />
          )}
        </tbody>
      </Table>
      {data?.totalPages > 1 && (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={data?.totalPages || 1}
          onPageChange={onPaginationChange}
          containerClassName="aePagination"
          activeClassName="pagination_activePage"
          forcePage={(data?.page || 1) - 1}
        />
      )}
      <CancelPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        header={campaignPopDetails.header}
        title={campaignPopDetails.title}
        onOkClick={campaignPopDetails.onOkAction}
      />
    </$AETableContainer>
  )
}

export default AETable

export const TabRow = ({ cells, colSpan, align, padding, ...props }) => {
  return (
    <TableRow {...props}>
      {cells.map((cell, i) => (
        <TableCell
          padding={padding}
          colSpan={colSpan}
          textAlign={`${align} !important`}
          key={i}
        >
          {cell}
        </TableCell>
      ))}
    </TableRow>
  )
}

export const moreActions = (
  camp,
  onActionClick,
  type = ' Campaign',
  options = {}
) => {
  const {
    showEdit = true,
    showView = true,
    showDelete = true,
    showDuplicate = false,
  } = options

  return (
    <$MoreOptions>
      <div>
        {camp.status === 'draft' && showEdit && (
          <span onClick={() => onActionClick('edit', camp)}>
            <EditIcon /> Edit {type}
          </span>
        )}
        {showView && (
          <span onClick={() => onActionClick('view', camp)}>
            <ViewIcon /> View {type}
          </span>
        )}
        {showDuplicate && (
          <span onClick={() => onActionClick('duplicate', camp)}>
            <DuplicateIcon /> Duplicate {type}
          </span>
        )}

        {type === 'Template' && ['live'].includes(camp.status) && (
          <span onClick={() => onActionClick('testTemplate', camp)}>
            <ArrowRightIcon /> Test this {type}
          </span>
        )}

        {['live', 'scheduled'].includes(camp.status) &&
          ![' Survey', 'Template'].includes(type) && (
            <span onClick={() => onActionClick('unpublish', camp)}>
              <UnPublishIcon /> Unpublish {type}
            </span>
          )}

        {['live', 'scheduled'].includes(camp.status) &&
          !['Template'].includes(type) && (
            <span onClick={() => onActionClick('unpublishForAll', camp)}>
              <UnPublishForAllIcon /> Unpublish For All
            </span>
          )}
        {camp.status !== 'draft' &&
          (type === ' Survey' || camp.type === 'surveyInBill') && (
            <span onClick={() => onActionClick('show', camp)}>
              <ViewResponsesIcon /> View Survey Responses
            </span>
          )}
        {(camp.status !== 'live' || ['Template', ' Survey'].includes(type)) &&
          showDelete && (
            <span onClick={() => onActionClick('delete', camp)}>
              <DeleteIcon /> Delete {type}
            </span>
          )}
        {camp.status !== 'draft' && type === ' Survey' && (
          <span onClick={() => onActionClick('copySurvey', camp)}>
            <DuplicateIcon />
            Copy Survey Link
          </span>
        )}
        {camp.status !== 'draft' && type === ' Template' && (
          <span onClick={() => onActionClick('copyTemplateBody', camp)}>
            <DuplicateIcon />
            Copy Template Body
          </span>
        )}
      </div>
      <DotsIcon />
    </$MoreOptions>
  )
}

export const TableLoader = ({ tableRows = 10, headerLength }) => {
  return [...new Array(tableRows)].map((_, i) => (
    <TabRow
      key={i}
      cells={[...new Array(headerLength)].map((it) => (
        <Skeleton key={it} />
      ))}
    />
  ))
}
