const initialState = {
  previousSidebarLink: '',
  stores: {},
  allStores: {},
  storesArray: [],
  inactiveStores: {},
  selectedStores: [],
  inactiveSelectedStores: [],
  filters: {
    noOfEmployee: {
      isActive: false,
      value: {
        from: 0,
        to: 0,
      },
    },
    size: {
      isActive: false,
      value: {
        from: 0,
        to: 0,
      },
    },
    location: {
      isActive: false,
      value: {
        state: [],
      },
    },
    selectedCompanies: { isActive: false, value: [] },
    selectedBrands: { isActive: false, value: [] },
    selectedStores: { isActive: false, value: [] },
  },
  rememberFilters: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_STORES':
      return {
        ...state,
        stores: action.payload,
      }
    case 'SET_STORES_ARRAY':
      return {
        ...state,
        storesArray: action.payload,
      }
    case 'SET_ALL_STORES':
      return {
        ...state,
        allStores: action.payload,
      }
    case 'SET_INACTIVE_STORES':
      return {
        ...state,
        inactiveStores: action.payload,
      }
    case 'SET_SELECTED_STORES':
      return {
        ...state,
        selectedStores: action.payload,
      }

    case 'SET_STORE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case 'SET_REMEMBER_FILTERS':
      return {
        ...state,
        rememberFilters: action.payload,
      }
    case 'RESET_STORE_FILTERS':
      return {
        ...state,
        filters: initialState.filters,
      }
    case 'RESET_SIZE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          size: initialState.filters.size,
        },
      }

    case 'RESET_NO_OF_EMPLOYEE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          noOfEmployee: initialState.filters.noOfEmployee,
        },
      }

    case 'RESET_LOCATION_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          location: initialState.filters.location,
        },
      }
    case 'SET_SELECTED_BRANDS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedBrands: {
            isActive: true,
            value: [action.payload],
          },
        },
      }
    case 'RESET_SELECTED_BRANDS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedBrands: initialState.filters.selectedBrands,
        },
      }
    case 'SET_SELECTED_COMPANIES_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCompanies: {
            isActive: true,
            value: [action.payload],
          },
        },
      }
    case 'RESET_SELECTED_COMPANIES_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCompanies: initialState.filters.selectedCompanies,
        },
      }
    case 'SET_SELECTED_STORES_FILTER':
      return {
        ...state,
        selectedStores: [...state.selectedStores, action.payload?.value],
        filters: {
          ...state.filters,
          selectedStores: {
            isActive: true,
            value: [...state.filters.selectedStores.value, action.payload],
          },
        },
      }
    case 'SET_INACTIVE_SELECTED_STORES_FILTER':
      return {
        ...state,
        inactiveSelectedStores: action.payload,
      }
    case 'RESET_SELECTED_STORES_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedStores: initialState.filters.selectedStores,
        },
      }
    case 'SET_STORE_SIDEBAR_LINK':
      return {
        ...state,
        previousSidebarLink: action.payload,
      }
    case 'SET_STORE_INACTIVE':
      delete state.stores[action.payload.storeId]
      return {
        ...state,
        inactiveStores: {
          ...state.inactiveStores,
          [action.payload.storeId]: {
            ...state.allStores[action.payload.storeId],
            isActive: false,
          },
        },
        allStores: {
          ...state.allStores,
          [action.payload.storeId]: {
            ...state.allStores[action.payload.storeId],
            isActive: false,
          },
        },
        storesArray: [
          ...state.storesArray.map((x) =>
            x.storeId === action.payload.storeId ? { ...x, isActive: false } : x
          ),
        ],
      }
    case 'SET_STORE_ACTIVE':
      delete state.inactiveStores[action.payload.storeId]
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: {
            ...state.allStores[action.payload.storeId],
            isActive: true,
          },
        },
        allStores: {
          ...state.allStores,
          [action.payload.storeId]: {
            ...state.allStores[action.payload.storeId],
            isActive: true,
          },
        },
        storesArray: [
          ...state.storesArray.map((x) =>
            x.storeId === action.payload.storeId ? { ...x, isActive: true } : x
          ),
        ],
      }

    case 'SET_STORE_UPSERT_DATA':
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: { ...action.payload },
        },
        allStores: {
          ...state.allStores,
          [action.payload.storeId]: { ...action.payload },
        },
        storesArray: [
          ...state.storesArray.map((x) =>
            x.storeId === action.payload.storeId
              ? { ...x, ...action.payload }
              : x
          ),
        ],
      }
    default:
      return state
  }
}
