// Functions

export const GET_OPTIONS = (type) => {
  switch (type) {
    case 'yesorno':
    case 'serviceExplicit':
      return [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ]

    case 'gender':
      return [
        {
          label: 'Male',
        },
        {
          label: 'Female',
        },
        {
          label: 'Other',
        },
      ]

    default:
      return null
  }
}

export const QUEST_GEN = (type, text = '', description = '') => {
  switch (type) {
    default:
    case 'radio':
    case 'checkbox':
      return {
        text,
        isRequired: false,
        fieldType: 'question',
        description,
        inputType: type,
        options: [OPTION_GEN(type, 1)],
      }

    case 'text':
    case 'phone':
    case 'email':
    case 'date':
    case 'image':
    case 'image-multi':
    case 'companyName':
    case 'firstName':
    case 'lastName':
    case 'spouseName':
    case 'profession':
    case 'anniversary':
    case 'location':
      return {
        text,
        isRequired: false,
        fieldType: 'question',
        description,
        showTitle: true,
        inputType: type,
        options: [],
      }

    case 'range':
    case 'rating':
      return {
        text,
        isRequired: false,
        fieldType: 'question',
        description,
        inputType: type,
        options: [
          OPTION_GEN(type, 1),
          OPTION_GEN(type, 2),
          OPTION_GEN(type, 3),
          OPTION_GEN(type, 4),
          OPTION_GEN(type, 5),
        ],
      }

    case 'yesorno':
    case 'serviceExplicit':
    case 'gender': {
      let question = text
      if (type === 'serviceExplicit') {
        question =
          'Would you like to receive messages or mails about promotions, events and offers?'
      }
      return {
        isRequired: false,
        fieldType: 'question',
        text: question,
        description,
        inputType: type,
        options: GET_OPTIONS(type),
      }
    }

    case 'html':
      return {
        isRequired: false,
        fieldType: 'html',
        text: '<h1>Type your text here...</h1>',
      }
  }
}

export const OPTION_GEN = (type, serial) => {
  switch (type) {
    default:
    case 'radio':
    case 'checkbox':
      return {
        label: '',
      }

    case 'range':
    case 'rating':
    case 'image':
    case 'image-multi':
      return {
        label: serial.toString(),
      }
  }
}
// Constants

export const HEADING_TYPES = [
  {
    label: 'Heading 1',
    value: 'h1',
  },
  {
    label: 'Heading 2',
    value: 'h2',
  },
  {
    label: 'Heading 3',
    value: 'h3',
  },
  {
    label: 'Heading 4',
    value: 'h4',
  },
  {
    label: 'Heading 5',
    value: 'h5',
  },
  {
    label: 'Heading 6',
    value: 'h6',
  },
]

export const SURVEY_OBJECT = {
  header: '',
  description: '',
  background: '#ffffff',
  body: [QUEST_GEN('radio')],
  marginals: [],
}

export const SHOW_ADD = ['radio', 'checkbox', 'yesorno', 'dropdown']

export const COLOR_ARRAY = [
  '#D9E3F0',
  '#F47373',
  '#8ED1FC',
  '#7BDCB5',
  '#BA68C8',
  '#FCB900',
  '#DCE775',
  '#F78DA7',
  '#D9E3F0',
  '#F47373',
  '#8ED1FC',
  '#7BDCB5',
  '#BA68C8',
  '#FCB900',
  '#DCE775',
  '#F78DA7',
]

export const SURVEY_STYLE = {
  border: '1px solid lightgray',
  padding: '6px',
  borderRadius: '4px',
}

export const OPINION_STYLE = {
  position: 'relative',
  cursor: 'pointer',
  padding: '0 10px',
  display: 'inline-flex',
  alignItems: 'center',
  height: '40px',
  color: '#3b85fa',
}

export const ADD_IMAGE = {
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100px',
  width: '100px',
  border: '1px dashed lightgrey',
  borderRadius: '4px',
  fontWeight: 'bold',
}

export const MODAL_STYLE = {
  width: 'auto',
  borderRadius: '10px',
}

export const HEADER_STYLES = {
  fontWeight: 'bolder',
  border: 'none',
  display: 'block',
  textAlign: 'center',
  width: '95%',
  background: 'transparent',
  margin: 'auto',
}

export const OPTION_TYPES = [
  {
    label: 'Single Select',
    value: 'radio',
  },
  {
    label: 'Yes/No',
    value: 'yesorno',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
  {
    label: 'Service Explicit',
    value: 'serviceExplicit',
  },
  {
    label: 'Multi Select',
    value: 'checkbox',
  },
  {
    label: 'Image Single Select',
    value: 'image',
  },
  {
    label: 'Image Multi Select',
    value: 'image-multi',
  },
  {
    label: 'Text Answer',
    value: 'text',
  },
  {
    label: 'Phone Number',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Anniversary Date',
    value: 'anniversary',
  },
  {
    label: 'Company Name',
    value: 'companyName',
  },
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Spouse Name',
    value: 'spouseName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Profession',
    value: 'profession',
  },
  {
    label: 'Opinion Scale',
    value: 'range',
  },
  {
    label: 'Rating',
    value: 'rating',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Location',
    value: 'location',
  },
  {
    label: 'Media Upload',
    value: 'mediaUpload',
  },
]

export const INPUT_TYPES = (type) => {
  switch (type) {
    case 'input':
      return [
        'text',
        'phone',
        'email',
        'date',
        'dob',
        'anniversary',
        'companyName',
        'firstName',
        'spouseName',
        'lastName',
        'profession',
      ]

    case 'multi':
      return ['checkbox', 'image-multi']

    default:
      return []
  }
}

export { GET_SEL_VALUE } from 'utils/reusables'
