import Terrible from './Terrible - Unfilled.png'
import Bad from './Bad - Unfilled.png'
import Average from './Average - Unfilled.png'
import Good from './Good - Unfilled.png'
import Excellent from './Excellent - Unfilled.png'

import Absolutely from './Absolutely - Unfilled.svg'
import Maybe from './Maybe - Unfilled.svg'
import NotLikely from './Not likely - Unfilled.svg'

export const SmileyUnFilled3Arr = [NotLikely, Maybe, Absolutely]
export const SmileyUnFilled2Arr = [NotLikely, Absolutely]

export default [Terrible, Bad, Average, Good, Excellent]
