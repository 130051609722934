import React from 'react'

const BillDetailsRight = ({ shortId }) => {
  const billLink = String(window.location.href).includes(
    'billme.stage.razorpay.in'
  )
    ? 'https://test.yourbill.me/'
    : 'https://yourbill.me/'

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: `0px 8px`,
        boxSizing: 'border-box',
      }}
    >
      <iframe
        src={`${billLink}${shortId}`}
        style={{ width: '100%', height: '100%', borderColor: 'transparent' }}
      />
    </div>
  )
}

export default BillDetailsRight
