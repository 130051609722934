import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { connect } from 'react-redux'

import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer } from '../autoEngagement/AutoEngagement.styled'
import JourneyHeader from './journeyTable/JourneyHeader'
import JouneyTable from './journeyTable/JourneyTable'
import { ERROR_DECODE } from '../autoEngagement/constants'
import { useToasts } from 'components/Common/Toast'
import {
  deleteJourney,
  duplicateJourney,
  getJourneys,
  setLoadTable,
  updateJourney,
} from 'redux/actions/journeyActions'

const Journey = (props) => {
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState({})

  const { filterQuery, jbData, jbTableLoading } = props.journey
  const { toast } = useToasts()

  useEffect(() => {
    query.vendorId &&
      props.getJourneys({
        ...query,
        limit: 10,
      })
  }, [query])

  useEffect(() => {
    props.setLoadTable(true)
    const tempQuery = {
      vendorId: props.vendorId,
      ...filterQuery,
    }
    if (search?.length > 0) {
      tempQuery.search = search
      tempQuery.page = 0
    }
    debounceLoadData({ ...tempQuery })
  }, [props.vendorId, filterQuery, search])

  const onPaginationChange = (page) => {
    props.setLoadTable(true)
    setQuery({ ...query, page })
  }

  const debounceLoadData = useCallback(
    debounce((query) => setQuery({ ...query }), 750),
    []
  )

  const delJourney = async (id) => {
    try {
      const res = await deleteJourney(id, {
        storeUserId: props.userId,
        storeUserEmail: props.userEmail,
      })
      if (res.message) {
        props.getJourneys({
          ...query,
          limit: 10,
        })
        toast('Journey deleted successfully.', 'success')
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const unpublishJourney = async (id, status = 'unpublished') => {
    try {
      const res = await updateJourney(id, {
        status,
        vendorId: props.vendorId,
        storeUserId: props.userId,
        storeUserEmail: props.userEmail,
      })
      if (res.message) {
        props.getJourneys({
          ...query,
          limit: 10,
        })
        toast('Journey unpublished successfully.', 'success')
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const duplicateJourneyHandler = async (id, payload = {}) => {
    try {
      const res = await duplicateJourney(id, {
        vendorId: props.vendorId,
        createdBy: props.userId,
        ...payload,
      })
      if (res.message) {
        props.getJourneys({
          ...query,
          limit: 10,
        })
        toast('Journey Duplicated successfully.', 'success')
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <$WhiteContainer pb="1px">
      <Navbar heading="Journey Builder" />
      <JourneyHeader search={search} setSearch={setSearch} />
      <JouneyTable
        data={jbData}
        page={query?.page || 0}
        deleteJourney={delJourney}
        unpublishJourney={unpublishJourney}
        unpublishForAllJourney={(id) =>
          unpublishJourney(id, 'unpublishedForAll')
        }
        duplicateJourney={duplicateJourneyHandler}
        tableLoading={jbTableLoading}
        onPaginationChange={onPaginationChange}
        createdBy={props.userId}
      />
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.userId,
  userEmail: state.auth.user.email,
  journey: state.journey,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, {
  getJourneys,
  setLoadTable,
})(Journey)
