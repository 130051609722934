import { getRootDomain } from './urlUtil'

export const getCookie = (cname) => {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const deleteCookie = (name) => {
  document.cookie =
    name +
    `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.${getRootDomain()}`

  document.cookie =
    name +
    `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname}`
}
