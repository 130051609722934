import React, { useState } from 'react'

import TopBar from './TopBar'
import { Steps, useToasts, Button } from 'components/Common'

import { $Container, $CampaignContainer } from '../AutoEngagement.styled'
import { useTheme } from 'styled-components'
import { FlexBox } from 'styledComponent'
import { CHECK_IF } from '../constants'

export const TopBarAndStepsWrapper = ({
  children,
  heading,
  labels,
  onPreviousClick,
  onNextClick,
  currentStep,
  setCurrentStep,
  hideSteps,
  onPublish,
  onSave,
  form,
  campaignType,
  whatsAppTemplate,
  customValidations,
  disableButtonOnPublish,
}) => {
  const [steps, setSteps] = useState(
    labels.map((label, i) => ({
      label,
      id: i + 1,
      status: i === 0 ? 'active' : 'inactive',
    }))
  )

  const { toast } = useToasts()
  const { spacing } = useTheme()

  const onPrevClick = () => {
    try {
      onPreviousClick()
      let flag = true
      const newSteps = steps
        .reverse()
        .map((step) => {
          if (step.status === 'active') {
            step.status = 'inactive'
          } else if (step.status === 'complete' && flag) {
            setCurrentStep(step)
            step.status = 'active'
            flag = false
          }
          return step
        })
        .reverse()
      setSteps(newSteps)
    } catch (e) {
      console.error(e)
      toast(e?.toString(), 'error')
    }
  }

  const onNeClick = async () => {
    try {
      if (customValidations) {
        const validationError = customValidations()
        if (validationError) throw validationError
      }

      await onNextClick()
      let flag = true
      const newSteps = steps.map((step) => {
        if (step.status === 'inactive' && flag) {
          setCurrentStep(step)
          step.status = 'active'
          flag = false
        } else if (step.status === 'active') {
          step.status = 'complete'
        }
        return step
      })
      setSteps(newSteps)
    } catch (e) {
      toast(e?.toString(), 'error')
    }
  }

  const PublishButton = (
    <Button
      onClick={onPublish}
      px={spacing.xl}
      variant="publish"
      isDisabled={disableButtonOnPublish}
    >
      Publish
    </Button>
  )

  const NextButton = (
    <Button onClick={onNeClick} px={spacing.xl} variant="primary">
      Next Step
    </Button>
  )

  const PreviousButton = (
    <Button onClick={onPrevClick} variant="primaryOutline">
      Previous Step
    </Button>
  )

  const extras = (
    <FlexBox gap={spacing.s}>
      {currentStep?.id !== 1 && PreviousButton}
      {currentStep?.id === steps?.length ? PublishButton : NextButton}
    </FlexBox>
  )

  const onStepClick = async (item) => {
    const error = `Please complete previous steps to skip to ${item.label}`
    try {
      switch (item.id) {
        case 2: {
          const campDetails = { ...form }
          const checkArray = ['name', 'vendorId']
          if (!form.campaignType && !whatsAppTemplate)
            checkArray.push('companyId')
          if (whatsAppTemplate) checkArray.push('category')
          const check = CHECK_IF(checkArray, campDetails)
          if (check) throw error
          if (campDetails.type === 'sms' && !campDetails.contentType)
            throw error
          break
        }

        case 3: {
          if (form.campaignType) {
            const check = CHECK_IF(['body'], { ...form?.content })
            const check2 = CHECK_IF(['contentType'], form)
            if (check || check2) throw error
            break
          }
          if (form.type === 'sms') {
            if (!form?.senderDetails?.name || !form?.content?.body?.templateId)
              throw error
            break
          }
          if (form.type === 'email') {
            const check = CHECK_IF(
              ['subject', 'body.html', 'body.compressedValue'],
              {
                ...form?.content,
              }
            )
            if (check) throw error
            break
          }
          if (form.type === 'surveyInBill') {
            const check = CHECK_IF(['surveyId'], {
              ...form?.content.body,
            })
            if (check) throw error
            break
          } else if (customValidations) {
            //TODO: add validation
            const validationError = customValidations()
            if (validationError) throw validationError
            break
          } else {
            const builderDetails = { ...form?.content?.body }
            const check = CHECK_IF(['componentType'], builderDetails)
            if (check || !builderDetails?.assetsDetails?.length) throw error
            break
          }
        }

        case 4: {
          if (form.campaignType) break
          if (form.type === 'sms') {
            const array = form?.content?.body?.templateParams || []
            if (array?.length) {
              const check =
                array.length === Object.values(array).length &&
                !array.includes(undefined) &&
                !array.includes(null)
              if (!check) throw error
            }
            break
          }
          const storeDetails = { ...form?.targetGroup }
          if (form.type === 'email') {
            const check = CHECK_IF(['type', 'id'], storeDetails)
            if (check) throw error
            break
          }
          if (form.type === 'surveyInBill') {
            const check = CHECK_IF(['surveyButtonId'], {
              ...form?.content?.body,
            })
            if (check) throw error
            break
          } else {
            if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
              throw error
            break
          }
        }

        case 5: {
          const storeDetails = { ...form?.targetGroup }
          if (form.type === 'sms') {
            const check = CHECK_IF(['type', 'id'], storeDetails)
            if (check) throw error
            break
          }
          if (form.type === 'email') {
            if (!form?.validity) throw error
            switch (form.validity) {
              case 'oneTime': {
                const check = CHECK_IF(['startDate', 'scheduledTime'], form)
                if (check) throw error
                break
              }
              case 'recurring': {
                const check = CHECK_IF(['startDate', 'scheduledTime'], form)
                if (!(form?.repeatEvery?.days?.length > 0)) throw error
                if (check) throw error
                break
              }
            }
          }
          if (form.type === 'surveyInBill') {
            break
          }
        }

        case 6: {
          if (form.type === 'sms') {
            if (!form?.validity) throw error
            switch (form.validity) {
              case 'oneTime': {
                const check = CHECK_IF(['startDate', 'scheduledTime'], form)
                if (check) throw error
                break
              }
              case 'recurring': {
                const check = CHECK_IF(['startDate', 'scheduledTime'], form)
                if (!(form?.repeatEvery?.days?.length > 0)) throw error
                if (check) throw error
                break
              }
            }
          }
          if (form.type === 'surveyInBill') {
            const storeDetails = { ...form?.targetGroup }
            if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
              throw error
            break
          }
        }
      }
      const alteredSteps = steps.map((step, i) => ({
        id: step.id,
        label: step.label,
        status:
          i === item.id - 1
            ? 'active'
            : i < item.id - 1
            ? 'complete'
            : 'inactive',
      }))
      const aleteredStep = alteredSteps.filter((step) => step.id === item.id)[0]
      if (item.id < currentStep.id) {
        await onPreviousClick()
      } else {
        await onNextClick()
      }
      setSteps(alteredSteps)
      setCurrentStep(aleteredStep)
    } catch (err) {
      toast(err, 'error')
    }
  }

  return (
    <>
      <TopBar
        heading={heading}
        title={
          hideSteps
            ? 'View Campaign'
            : campaignType
            ? `${campaignType} - ${
                whatsAppTemplate ? 'New Template' : 'New Campaign'
              }`
            : `${whatsAppTemplate ? 'New Template' : 'New Campaign'}`
        }
        extras={!hideSteps && extras}
        exitTitle={hideSteps ? 'Exit' : 'Save & Exit'}
        onExit={onSave}
      />
      <$Container>
        {!hideSteps && (
          <Steps
            stepClick={onStepClick}
            steps={steps}
            distance="150px"
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        )}
        <$CampaignContainer padding={spacing.xl}>{children}</$CampaignContainer>
      </$Container>
    </>
  )
}
