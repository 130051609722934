import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
// import { toast } from 'react-toastify'
import swal from 'sweetalert'
import { SearchInput, Dropdown, Skeleton, MyToast } from 'components/Common'
import {
  GreyText,
  HorizontalLine,
  Block,
  FilterXIcon,
  PrimaryButton,
  PrimaryOutlineButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
} from 'styledComponent'
import CampaignModal from './CampaignModal'
import FeedbackCampaignItem from './FeedbackCampaignItem'
import CampaignReport from './CampaignReport'
import CreateCampaign from './CreateCampaign'
import CampaignFilter from './CampaignFilter'
import {
  getFeedbackCampaigns,
  setFeedbackCampaignFilters,
  setFeedbackCampaignSort,
  resetCampaignFilters,
  filterBrand,
  filterStores,
} from 'redux/actions'
import { post, get, put } from 'utils/axiosHandler'
import { listRowsPerPage } from 'utils/feedback'
import Navbar from 'components/Layout/Navbar'

const initialState = {
  currentPage: 'feedback',
  screen: 0,
  selectedBrand: '',
  selectedBrandId: '',
  selectedBrandLogo: '',
  selectedStores: [],
  selectedStoresId: [],
  campaignName: '',
  scaleType: 'star',
  scaleLength: 5,
  fbBgurl: '',
  unfilledRating: '',
  filledRating: '',
  fbHeading: '',
  error: {
    brand: false,
    store: false,
    campaignName: false,
  },
  ratingModels: '',
  campaignId: '',
  paginationPage: 0,
  isListLoading: false,
  selectAll: false,
}

const SortOptions = [
  {
    id: 'newest',
    value: 'Newest First',
    sortOrder: -1,
    sortValue: '_id',
  },
  {
    id: 'oldest',
    value: 'Oldest First',
    sortOrder: 1,
    sortValue: '_id',
  },
]
class FeedbackCampaignList extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
    }

    this.newCampaign = React.createRef()
    this.campaignFilter = React.createRef()
    this.openFilterModal = this.openFilterModal.bind(this)
    this.handleFeedback = this.handleFeedback.bind(this)

    this.handleScreenChange = this.handleScreenChange.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.resetState = this.resetState.bind(this)
    this.handleBrandSelect = this.handleBrandSelect.bind(this)
    this.handleStoresSelect = this.handleStoresSelect.bind(this)
    this.handleCampaignName = this.handleCampaignName.bind(this)
    this.openStoreModal = this.openStoreModal.bind(this)
    this.handleRemoveStore = this.handleRemoveStore.bind(this)
    this.onPreviewClick = this.onPreviewClick.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.errorText = this.errorText.bind(this)
    this.onCampaignEditClick = this.onCampaignEditClick.bind(this)
    this.getBrandDetails = this.getBrandDetails.bind(this)
    this.resetPaginationNumber = this.resetPaginationNumber.bind(this)
  }

  async componentDidMount() {
    this.setState({ isListLoading: true })
    await this.props.getFeedbackCampaigns()
    this.setState({ isListLoading: false })
  }

  componentWillUnmount() {
    if (!this.props.rememberFilters) this.props.resetCampaignFilters()
  }

  handlePageClick = async ({ selected }) => {
    this.setState({ isListLoading: true })
    this.resetPaginationNumber(selected)
    await this.props.getFeedbackCampaigns({
      offset: selected * listRowsPerPage,
    })
    this.setState({ isListLoading: false })
  }

  handleSelectAll = async (filteredStores) => {
    await this.setState({ selectAll: !this.state.selectAll })
    const { selectAll, selectedStoresId } = this.state
    const selectedStoreList = []
    const selectedStores = []
    if (selectAll) {
      for (const store of filteredStores) {
        selectedStoreList.push(store.storeId)
        selectedStores.push(store.displayAddress)
      }
      this.setState({
        selectedStoresId: selectedStoreList,
        selectedStores: selectedStores,
      })
    } else {
      this.setState({ selectedStoresId: [], selectedStores: [] })
    }
  }

  handleAutoCompleteChange = (search) => {
    this.props.setFeedbackCampaignFilters({ search })
    this.resetPaginationNumber()
    setTimeout(() => this.props.getFeedbackCampaigns(), 0)
  }

  handleDropdownSelect = (sortOption) => {
    sortOption && this.props.setFeedbackCampaignSort({ ...sortOption })
    this.resetPaginationNumber()
    setTimeout(() => this.props.getFeedbackCampaigns(), 0)
  }

  handleFeedback(currentPage) {
    this.setState({ currentPage })
  }

  openFilterModal() {
    this.newCampaign.current.openModal()
  }

  handleCampaignName(e) {
    this.setState({ campaignName: e.target.value })
  }

  handleBrandSelect(e) {
    this.setState({
      selectedBrand: e.target.getAttribute('data-readablename'),
      selectedBrandId: e.target.value,
      selectedBrandLogo: e.target.getAttribute('data-logo'),
    })
  }

  openStoreModal() {
    this.openFilterModal()
    this.setState({ screen: 1 })
  }

  handleStoresSelect(e) {
    const storeId = e.target.value
    const storeName = e.target.getAttribute('data-readablename')

    if (e.target.checked) {
      if (!this.state.selectedStoresId.includes(storeId))
        this.setState({
          selectedStores: [...this.state.selectedStores, storeName],
          selectedStoresId: [...this.state.selectedStoresId, storeId],
        })
    } else {
      if (this.state.selectedStoresId.includes(storeId)) {
        const copyArrayId = [...this.state.selectedStoresId]
        const copyArrayStores = [...this.state.selectedStores]
        const indexOfId = copyArrayId.indexOf(storeId)
        const indexOfStore = copyArrayStores.indexOf(storeName)
        copyArrayId.splice(indexOfId, 1)
        copyArrayStores.splice(indexOfStore, 1)

        this.setState({
          selectedStores: copyArrayStores,
          selectedStoresId: copyArrayId,
        })
      }
    }
  }

  handleScreenChange(_e, value) {
    let copyError = { ...this.state.error }
    let hasError = false

    if (this.state.screen === 0 && !this.state.selectedBrand) {
      copyError.brand = true
      hasError = true
    }

    if (this.state.screen === 1 && !this.state.selectedStores.length) {
      copyError.store = true
      hasError = true
    }
    if (this.state.screen === 2 && !this.state.campaignName) {
      copyError.campaignName = true
      hasError = true
    }

    if (hasError) {
      this.setState({ error: copyError })
    } else {
      let screen = value || this.state.screen + 1
      if (this.state.screen === 2) {
        this.newCampaign.current.closeModal()
        this.handleFeedback('createCampaign')
      } else if (
        value === 3 &&
        this.newCampaign.current.state.isPopOpen === false
      ) {
        this.newCampaign.current.openModal()
      } else if (this.state.screen === 3) {
        this.newCampaign.current.closeModal()
        return
      }

      this.setState({
        screen,
        error: { ...initialState.error },
      })
    }
  }

  resetState() {
    this.setState(initialState)
  }

  handleBack() {
    if (this.state.screen === 1 || this.state.screen === 2)
      this.setState({ screen: this.state.screen - 1 })
  }

  handleRemoveStore(_e, storeId) {
    const storeIdIndex = this.state.selectedStoresId.indexOf(storeId)
    const copyStoresId = [...this.state.selectedStoresId]
    const copyStores = [...this.state.selectedStores]

    copyStoresId.splice(storeIdIndex, 1)
    copyStores.splice(storeIdIndex, 1)

    this.setState({
      selectedStores: copyStores,
      selectedStoresId: copyStoresId,
    })
  }

  onPreviewClick(card, filledRating, fbHeading) {
    this.setState({ card, filledRating: filledRating, fbHeading: fbHeading })
  }

  handleSave(
    cards,
    isEdit = false,
    fbHeading,
    fbBgurl,
    filledRating,
    unfilledRating,
    scaleType
  ) {
    let cardError = []
    if (scaleType === 'customIcon') {
      if (filledRating.length === 0 || unfilledRating.length === 0) {
        this.context.toast(
          'Please upload both filled and unfilled image',
          'error'
        )
        return
      }
    }
    const finalCard = cards.map((card) => {
      const nonEmptyValues = card.values.filter((item) => item)
      // if (nonEmptyValues.length === 0) cardError.push(card.index)

      return {
        index: card.index,
        values: nonEmptyValues,
        caption: card.caption,
        scaleType: card.scaleType ? card.scaleType : 'star',
      }
    })

    if (cardError.length) {
      swal({
        title: 'Error in creating the new campaign',
        text: this.errorText(cardError),
        icon: 'warning',
      })
      return
    }

    var finalObj = {
      ...(isEdit ? { campaignId: this.state.campaignId } : {}),
      campaignName: this.state.campaignName,
      storeIds: this.state.selectedStoresId,
      brandId: this.state.selectedBrandId,
      ratingModels: finalCard,
    }
    const obj = {
      headerText: fbHeading,
      backgroundUrl: fbBgurl,
      filledRating: filledRating,
      unfilledRating: unfilledRating,
    }

    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        finalObj[key] = value
      }
    }

    if (isEdit) {
      put('/feedback/campaign', finalObj)
        .then((_) => {
          swal({
            title: 'Campaign updated!',
            text: `Campaign ${this.state.campaignName} has been updated successfully`,
            icon: 'success',
          }).then((_) => {
            this.setState(initialState)
            this.props.getFeedbackCampaigns()
          })
        })
        .catch((err) => {
          // toast.error(err, {
          // position: toast.POSITION.BOTTOM_RIGHT
          // })
          this.context.toast(err, 'error')
        })

      return
    }

    post('/feedback/campaign', finalObj)
      .then((res) => {
        swal({
          title: 'New Campaign created!',
          text: `Campaign ${this.state.campaignName} has been created successfully`,
          icon: 'success',
        }).then((_) => {
          this.setState(initialState)
          this.resetPaginationNumber()
          this.props.getFeedbackCampaigns()
        })
      })
      .catch((err) => {
        // toast.error(err, {
        //   position: toast.POSITION.BOTTOM_RIGHT
        // })
        this.context.toast(err, 'error')
      })
  }

  getBrandDetails(brandId) {
    let values = {
      brandName: '',
      brandLogo: '',
    }

    for (let i = 0; i < this.props.brands.length; i++) {
      if (this.props.brands[i].brandId == brandId) {
        values = {
          brandName: this.props.brands[i].name,
          brandLogo: this.props.brands[i].brandLogo,
        }
        break
      }
    }
    return values
  }

  getStoreDetails(brandId, storeIds) {
    let stores = [],
      storesId = []

    const filteredStores = Object.values(this.props.stores).filter(
      (store) => store.brandId == brandId
    )

    filteredStores.forEach((store) => {
      if (storeIds.includes(store.storeId)) {
        stores.push(store.displayAddress)
        storesId.push(store.storeId)
      }
    })

    return {
      stores,
      storesId,
    }
  }

  async onCampaignEditClick(_e, campaignId) {
    try {
      const {
        campaign: {
          brandId,
          storeIds,
          campaignName,
          scaleType,
          scaleLength,
          headerText,
          backgroundUrl,
          filledRating,
          unfilledRating,

          quickFeedbackMeta: { ratingModels },
        },
      } = await get(`/feedback/campaign?campaignId=${campaignId}`)

      // TODO: Create loader for data loading

      const { brandName, brandLogo } = this.getBrandDetails(brandId)
      const { stores, storesId } = this.getStoreDetails(brandId, storeIds)

      this.setState({
        currentPage: 'createCampaign',
        screen: 3,
        selectedBrand: brandName,
        selectedBrandId: brandId,
        selectedBrandLogo: brandLogo,
        selectedStores: stores,
        selectedStoresId: storesId,
        campaignName,
        scaleType,
        scaleLength,
        headerText,
        backgroundUrl,
        filledRating,
        unfilledRating,
        error: {
          brand: false,
          store: false,
          campaignName: false,
        },
        ratingModels,
        campaignId,
      })
    } catch (err) {}
  }

  errorText(error) {
    let errorText = ''
    error.forEach(
      (item) => (errorText += `Card with ${item} star cannot be empty\n`)
    )
    return errorText
  }

  resetPaginationNumber(selected = 0) {
    this.setState({ paginationPage: selected })
  }

  showBackButton() {
    if (
      this.state.currentPage === 'createCampaign' &&
      this.state.screen === 1
    ) {
      return false
    } else if (this.state.screen === 3 || this.state.screen === 0) {
      return false
    }
    return true
  }

  render() {
    const pageCount = Math.ceil(
      (this.props.campaignCount - 1) / listRowsPerPage
    )

    return (
      <Block className="bg-white" height={`calc(100vh - 60px)`}>
        <Navbar heading="Feedback Campaign" />
        <div
          className="campaign-module p-3"
          style={{ backgroundColor: '#fff' }}
        >
          {this.state.currentPage === 'feedback' && (
            <>
              <div className="manage-filters">
                <div className="row mb-2">
                  <div className="col-md-6 d-inline-flex">
                    <SearchInput
                      placeHolder="Search Campaigns"
                      onInputChange={this.handleAutoCompleteChange}
                    />
                    <PrimaryOutlineButton
                      type="button"
                      className="d-inline-flex ml-2 pl-4 pr-4 filter-btn"
                      onClick={() => this.campaignFilter.current.openModal()}
                    >
                      <FilterXIcon className="mr-2" />
                      Filter
                    </PrimaryOutlineButton>
                  </div>
                  <div className="col-md-6 text-right">
                    <PrimaryButton
                      type="button"
                      className="mr-2 mb-1"
                      onClick={this.openFilterModal}
                    >
                      New Campaign
                    </PrimaryButton>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="pagination d-inline-flex justify-content-between">
                    <GreyText variant="caption">
                      Showing {this.props.campaigns.length} of{' '}
                      {this.props.campaignCount} items
                    </GreyText>
                    <div className="d-inline-flex justify-content-right">
                      <GreyText variant="caption">Sort By</GreyText>
                      <Dropdown
                        options={SortOptions}
                        onChange={this.handleDropdownSelect}
                        selected={this.props.sort}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <HorizontalLine />
              <div className="row mt-2 mb-2">
                <div className="col-md-12">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="70px">#</TableCell>
                        <TableCell width="30%">Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell textAlign="center">Active</TableCell>
                        <TableCell textAlign="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {!this.state.isListLoading &&
                        this.props.campaigns.length > 0 &&
                        this.props.campaigns.map((campaign, index) => (
                          <>
                            <FeedbackCampaignItem
                              key={index}
                              campaign={campaign}
                              index={this.state.paginationPage * 10 + index + 1}
                              onCampaignEditClick={(e) =>
                                this.onCampaignEditClick(e, campaign.campaignId)
                              }
                            />
                            {(campaign.isStatsLoading || campaign.stats) && (
                              <TableRow>
                                <TableCell colSpan={`6`}>
                                  <CampaignReport
                                    stats={campaign.stats}
                                    isStatsLoading={campaign.isStatsLoading}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </tbody>
                  </Table>
                  {this.state.isListLoading && (
                    <Skeleton height={60} count={10} />
                  )}
                  {this.props.campaigns.length > 0 && pageCount > 1 && (
                    <ReactPaginate
                      previousLabel="< Previous"
                      nextLabel="Next >"
                      breakLabel={'...'}
                      pageCount={pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        'feedback-pagination notSelectableText'
                      }
                      activeClassName={'pagination_activePage'}
                      forcePage={this.state.paginationPage}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {this.state.currentPage === 'createCampaign' && (
            <CreateCampaign
              brandName={this.state.selectedBrand}
              brandLogo={this.state.selectedBrandLogo}
              stores={this.state.selectedStores}
              storesId={this.state.selectedStoresId}
              openStoreModal={this.openStoreModal}
              handleRemoveStore={this.handleRemoveStore}
              handleScreenChange={this.handleScreenChange}
              onPreviewClick={this.onPreviewClick}
              handleSave={this.handleSave}
              ratingModels={this.state.ratingModels}
              scaleLength={this.state.scaleLength}
              scaleType={this.state.scaleType}
              resetState={this.resetState}
              fbHeading={this.state.headerText}
              fbBgurl={this.state.backgroundUrl}
              filledRating={this.state.filledRating}
              unfilledRating={this.state.unfilledRating}
            />
          )}
        </div>
        <CampaignModal
          ref={this.newCampaign}
          handleFeedback={this.handleFeedback}
          handleScreenChange={this.handleScreenChange}
          handleBack={this.handleBack}
          handleBrandSelect={this.handleBrandSelect}
          handleStoresSelect={this.handleStoresSelect}
          handleCampaignName={this.handleCampaignName}
          screen={this.state.screen}
          brandName={this.state.selectedBrand}
          brandId={this.state.selectedBrandId}
          brandLogo={this.state.selectedBrandLogo}
          stores={this.state.selectedStores}
          storesId={this.state.selectedStoresId}
          campaignName={this.state.campaignName}
          card={this.state.card}
          error={this.state.error}
          showBackButton={this.showBackButton()}
          allStoresSelectAll={this.state.selectAll}
          storeHandleSelectAll={this.handleSelectAll}
          filledRating={this.state.filledRating}
          fbHeading={this.state.fbHeading}
        />
        <CampaignFilter ref={this.campaignFilter} />
      </Block>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.feedbackCampaign.data,
    sort: state.feedbackCampaign.sort,
    hasMoreCampaigns: state.feedbackCampaign.hasMoreCampaigns,
    campaignCount: state.feedbackCampaign.campaignCount,
    brands: filterBrand(state, 'feedback'),
    stores: filterStores(state, 'feedback'),
    rememberFilters: state.feedbackCampaign.rememberFilters,
  }
}

export default connect(mapStateToProps, {
  getFeedbackCampaigns,
  setFeedbackCampaignFilters,
  setFeedbackCampaignSort,
  resetCampaignFilters,
})(FeedbackCampaignList)
