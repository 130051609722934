import React, { useEffect, useState } from 'react'
import Navbar from '../../../Layout/Navbar'
import {
  NYKAA_SEARCH_FIELD_OPTIONS,
  SEARCH_FIELD_OPTIONS,
  SECURITY_USER_FIELD_OPTIONS,
} from '../constants'
import {
  setDefaultFilters,
  getBills,
  setBillFilterQuery,
} from '../../../../redux/actions/bills'
import { GET_SEL_VALUE } from 'utils/reusables'
import { defaultFilters } from '../../../../utils/billUtils'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Skeleton } from 'components/Common'
import {
  Div,
  Text,
  Box,
  PrimaryButton,
  HorizontalLine,
  FlexBox,
} from '../../../../styledComponent'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import moment from 'moment'
import { nykaaVendorId } from 'components/conditionalComponent'
import SecurityTable from './SecurityTable'
import BillsPopup from '../billView/BillsPopup'
import { userTypes } from 'components/Layout/constants'

const SecurityView = (props) => {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date()
  endDate.setHours(23, 59, 59, 0)

  const filterQuery = useSelector((state) => state.bills.filterQuery)
  const selectedStores = useSelector((state) => state.stores?.selectedStores)
  const vendorId = useSelector(
    (state) => state.vendorIds?.selectedVendor?.value
  )
  const userType = useSelector((state) => state.auth.user.userType)

  const storeList = useSelector((state) =>
    state.stores?.storesArray.map((x) => ({
      value: x.storeId,
      label: x.displayAddress,
    }))
  )
  const [billsPopUp, setBillsPopUp] = useState({
    status: false,
    data: {},
  })
  const [selectedStore, setSelectedStore] = useState(storeList?.[0])
  const [search, setSearch] = useState({
    searchTxt: '',
    searchField: 'user',
  })
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState(null)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
  })

  const dispatch = useDispatch()
  // const data = useSelectStore()

  useEffect(() => {
    dispatch(
      setDefaultFilters({
        ...defaultFilters,
        date: {
          ...defaultFilters.date,
          isActive: true,
        },
      })
    )
    dispatch(setBillFilterQuery({ startDate, endDate }))
    fetchBills()
  }, [selectedStore])

  const fetchBills = async (skip = 0) => {
    setLoading(true)
    setSearch({ ...search, searchTxt: '' })
    setPagination({ ...pagination, page: skip / pagination.rowsPerPage })
    try {
      let res = await getBills(
        {
          selectedStoreIds: [selectedStore.value],
          startDate,
          endDate,
        },
        { loader: true }
      )
      setLoading(false)
      setRows(res.data.billsData ? res.data.billsData : [])
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const getBillsBySearch = async (searchTxt, searchField) => {
    if (searchTxt?.length === 0 && searchTxt !== search.searchTxt) {
      fetchBills()
    }
    setSearch({ searchTxt, searchField })
  }

  const onChange = (x) => {
    setSelectedStore(x)
  }

  const getDebouncedSearch = async () => {
    const { searchTxt, searchField } = search
    if (searchTxt.length === 0) return
    setLoading(true)
    let res = await getBills({
      searchParam: searchTxt,
      searchField,
      ...(filterQuery || {}),
    })
    if (res.code) {
      setRows(res.data.billsData)
    }
    setLoading(false)
  }

  const onBillViewClick = (data) => {
    setBillsPopUp({
      status: !billsPopUp.status,
      data: data,
    })
  }

  const getSearchFields = (vendorId, userType) => {
    if (vendorId === nykaaVendorId) return NYKAA_SEARCH_FIELD_OPTIONS
    if (userType === userTypes.SECURITY) return SECURITY_USER_FIELD_OPTIONS
    return SEARCH_FIELD_OPTIONS
  }
  if (loading) {
    return <Skeleton height={70} count={10} />
  }
  return (
    <>
      <Navbar heading="Bills" />
      <Box padding="15px" bgColor="#FFF">
        <Text variant="h1" style={{ marginBottom: '0px' }}>
          {' '}
          Today - {moment(startDate).format('DD/MM/YYYY')}{' '}
          {storeList.length > 1 ? (
            <Input
              label={'Select Store'}
              variant={'single-select'}
              options={storeList}
              onChange={onChange}
              value={selectedStore}
            />
          ) : null}
          <PrimaryButton onClick={fetchBills}> Refresh Page</PrimaryButton>
        </Text>
        <HorizontalLine />
        <FlexBox justify="flex-start" gap="20px" ml="16px">
          <Text mt="5px" variant="h5">
            Select Search Parameter :{' '}
          </Text>
          <Input
            containerMargin="0"
            inputWidth="200px"
            variant="single-select"
            options={getSearchFields(vendorId, userType)}
            value={GET_SEL_VALUE(
              search.searchField,
              getSearchFields(vendorId, userType)
            )}
            // label="Select Search Parameter"
            onChange={({ value }) => getBillsBySearch(search.searchTxt, value)}
          />
          <Div position="relative" className="bill-input">
            <Input
              containerMargin="0"
              inputWidth="300px"
              variant="input"
              value={search.searchTxt}
              placeholder="Search by Parameter"
              onEnter={getDebouncedSearch}
              onChange={(e) =>
                getBillsBySearch(e.target.value, search.searchField)
              }
            />
            <div className="searchBox" onClick={getDebouncedSearch}>
              <SearchIcon />
            </div>
          </Div>
        </FlexBox>
        <HorizontalLine />
        <SecurityTable rows={rows} onBillViewClick={onBillViewClick} />
      </Box>
      {!!billsPopUp.status && (
        <BillsPopup
          billData={billsPopUp.data}
          isPopOpen={billsPopUp.status}
          setIsPopOpen={() =>
            setBillsPopUp({
              ...billsPopUp,
              status: !billsPopUp.status,
            })
          }
        />
      )}
    </>
  )
}

export default SecurityView
