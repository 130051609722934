import styled, { createGlobalStyle } from 'styled-components'

import { spacing, color } from 'design-system'
import { FlexBox } from 'styledComponent'

const $Container = styled.div`
  width: ${({ width }) => width || '50%'};
  max-width: 700px;
`

const $BodyStyles = createGlobalStyle`
  body {
    background-color: ${color.white};
  }
`

const $SettingItem = styled.div`
  padding: ${({ padding }) => padding || spacing.m};
  border-bottom: 1px solid
    ${({ borderBottom }) => (borderBottom ? '#eaeaea' : 'transparent')};
`

const $SettingBlock = styled.div`
  padding: 0 20px;
  background-color: #f9f9f9;
  min-height: calc(100vh - 135px);
  position: relative;
`

const $FlexBox = styled(FlexBox)`
  margin-top: ${spacing.xxl};
  padding-bottom: ${spacing.xl};
  button {
    margin-right: ${spacing.m};
  }
  ${({ bottom }) =>
    bottom
      ? `
    position: absolute;
    bottom: 0;
  `
      : ''}
`

const $Span = styled.span`
  margin: ${spacing.s} ${spacing.s} 0;
`

export { $Container, $SettingItem, $SettingBlock, $FlexBox, $Span, $BodyStyles }
