import React from 'react'
import { useTheme } from 'styled-components'

import { Text, Input } from 'components/Common'
import { Audience } from './Audience'
import { AutoTurnOnTurnOffCampaign } from './AutoTurnOnTurnOffCampaign'

import { WHEN_TYPES_FOR_CAMPAIGN } from '../constants'
import cloneDeep from 'lodash/cloneDeep'

export const CampaignSettingsWithATOC = ({
  header = 'Campaign Settings',
  form,
  setForm,
}) => {
  const wType =
    form?.activateAt && form?.deactivateAt
      ? WHEN_TYPES_FOR_CAMPAIGN[1].value
      : WHEN_TYPES_FOR_CAMPAIGN[0].value
  const [selectedWhenType, setSelectedWhenType] = React.useState(wType)

  const { spacing } = useTheme()

  React.useEffect(() => {
    if (selectedWhenType === WHEN_TYPES_FOR_CAMPAIGN[0].value) {
      let copyForm = cloneDeep(form)
      copyForm.activateAt = null
      copyForm.deactivateAt = null
      setForm(copyForm)
    }
  }, [selectedWhenType])

  const handleWhenSelectChange = (value) => {
    setSelectedWhenType(value)
  }

  return (
    <>
      <Text variant="h3" weight="bold" mt={spacing.l}>
        {header}
      </Text>
      <Audience targetForm={form} setTargetForm={setForm} />

      <Input
        variant="radio"
        direction="row"
        gap={spacing.xl}
        label="When to Publish"
        id="selection-campaign-when"
        value={selectedWhenType}
        onChange={handleWhenSelectChange}
        options={WHEN_TYPES_FOR_CAMPAIGN}
      />

      {selectedWhenType === WHEN_TYPES_FOR_CAMPAIGN[0].value && (
        <Text variant="p" type="grey">
          Campaign will be published immediately
        </Text>
      )}

      {selectedWhenType === WHEN_TYPES_FOR_CAMPAIGN[1].value && (
        <>
          <AutoTurnOnTurnOffCampaign
            form={form}
            setForm={setForm}
            isStartDate
          />
          <AutoTurnOnTurnOffCampaign form={form} setForm={setForm} />
        </>
      )}
    </>
  )
}
