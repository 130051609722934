import React from 'react'
import styled from 'styled-components'

import { useRouter, useSelectStore } from 'hooks'
import { $CampaignContainer, $WhiteContainer } from '../AutoEngagement.styled'
import {
  Input,
  useToasts,
  Button,
  UploadExcel as UploadExcelSteps,
  Text,
} from 'components/Common'
import TopBar from '../components/TopBar'
import { ERROR_DECODE } from '../constants'
import { post } from 'utils/axiosHandler'
import excelFieldsImage from 'assets/images/coupon-upload-excel.png'
import { $Upload, $InformationBox } from '../../consumerProfiling/CRM.styled'
import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'

const CouponUpload = () => {
  const { history } = useRouter()
  const { toast } = useToasts()
  const { data: createdBy } = useSelectStore('auth.user.userId')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const [file, setFile] = React.useState(null)
  const [form, setForm] = React.useState({})
  const [isUploading, setIsUploading] = React.useState(false)

  const fileRef = React.useRef(null)

  const onCouponSave = async () => {
    setIsUploading(true)
    try {
      const isValid = form?.name && file

      if (!isValid) {
        toast('Coupon name and file are required', 'error')
        return
      }

      const formData = new FormData()

      Object.keys(form).forEach((key) => {
        formData.append(key, form[key])
      })

      formData.append('vendorId', vendorId)
      formData.append('createdBy', createdBy)
      formData.append('file', file)

      const res = await post(`/auto-engage/coupons/upload`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      if (res?.message) {
        toast(res.message, 'success')
        history.push('/auto-engage/coupons')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setIsUploading(false)
    }
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e?.target?.id]: e?.target?.value || '',
    })
  }

  const onFileChange = (e) => {
    e.preventDefault()

    setFile(e.target.files[0])
  }

  const resetFile = () => {
    setFile(null)
    if (fileRef?.current?.value) {
      fileRef.current.value = ''
    }
  }

  const SaveCoupon = () => (
    <Button
      variant="primary"
      onClick={onCouponSave}
      isDisabled={!file || isUploading}
    >
      Save & Exit
    </Button>
  )

  return (
    <$WhiteContainer>
      <TopBar
        heading="Auto Engagement - Coupons"
        title="Upload Coupon"
        extras={<SaveCoupon />}
        onExit={() => history.push('/auto-engage/coupons')}
      />
      <$Block>
        <$Upload>
          <Input
            containerMargin="0"
            variant="input"
            label="Coupon Name"
            placeholder="Enter coupon name"
            id="name"
            value={form?.name || ''}
            onChange={handleChange}
            inputWidth="500px"
            maxLength="50"
          />

          <UploadExcelSteps
            fileName="Coupon_Sample"
            sampleFile="https://assets.billme.co.in/public/samples/coupon_upload_sample.csv"
            ref={fileRef}
            onFileChange={onFileChange}
            excelFields={excelFieldsImage}
          />
        </$Upload>

        <$InformationBox>
          <div>
            <Text type="main" variant="p" weight="bold" mb={spacing.m}>
              Instructions:
            </Text>
            <Text type="grey" variant="p" weight="normal" mb={spacing.m}>
              Upload individual coupons in one column, don't add any other data
              to the csv.
            </Text>
          </div>
        </$InformationBox>
      </$Block>
    </$WhiteContainer>
  )
}

export default CouponUpload

const $Block = styled(FlexBox)`
  padding-right: 16px;
`
