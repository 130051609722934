/**
 * EmptyState.jsx
 *
 * This file will consists of multiple empty state
 * components for different purposes
 */

import React from 'react'
import styled from 'styled-components'

import { Text } from './Text'

const $EmptyCharts = styled.div`
  width: 100%;
  height: 350px;
`

const $Block = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmptyCharts = () => (
  <$EmptyCharts>
    <Text variant="p" type="grey" align="center">
      No Chart data found
    </Text>
  </$EmptyCharts>
)

/**
 * @description component to render when there is no data to be shown
 * @param {String} width block width
 * @param {String} height block height
 * @param {String} label no data found label
 */
export const NoDataFound = ({ width, height, label }) => {
  return (
    <$Block width={width} height={height}>
      <Text type="grey">{label}</Text>
    </$Block>
  )
}

NoDataFound.defaultProps = {
  width: '100%',
  height: '300px',
  label: 'No data found',
}
