import styled from 'styled-components'

import { Button } from 'components/Common/Button'
import { borderRadius, spacing } from 'design-system'

const $ContentDiv = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 0 ${spacing.l};
`
const $Button = styled.button`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.neutral[600]};
  border: 0;
  border-radius: ${borderRadius.small};
  display: flex;
  place-items: center;
  &:focus {
    outline: 0;
  }
`

const $RemoveButton = styled(Button)`
  font-size: 12px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.error};
  &:focus {
    outline: 0;
  }
`

export { $ContentDiv, $Button, $RemoveButton }
