import { isEmpty } from 'utils'
import convertToUTC from 'utils/convertToUTC'
//This function return json object which we send to axios request for a day analytics
export const sendObject = (date, date2) => {
  return {
    startDate:
      date.getFullYear() + ',' + (date.getMonth() + 1) + ',' + date.getDate(),
    endDate: isEmpty(date2)
      ? date.getFullYear() +
        ',' +
        (date.getMonth() + 1) +
        ',' +
        (date.getDate() + 1)
      : date2.getFullYear() +
        ',' +
        (date2.getMonth() + 1) +
        ',' +
        date2.getDate(),
  }
}

export const sendObject2 = (date, date2) => {
  let today = new Date(date)
  var tomDate = new Date(date)
  tomDate.setDate(date.getDate() + 1)
  tomDate.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)
  tomDate = convertToUTC(tomDate)
  today = convertToUTC(today)
  return {
    startDate: today,
    endDate: isEmpty(date2) ? tomDate : convertToUTC(date2),
  }
}
