import React from 'react'
import Popup from 'reactjs-popup'
import { COLOR_ARRAY, MODAL_STYLE } from '../constants'
import { Block, FlexBox } from 'styledComponent'
import { Text } from 'components/Common/Text'
import { CloseIcon } from 'assets/icons'
import { SketchPicker } from 'react-color'

export const SurveyBackground = ({
  showModal,
  setShowModal,
  setBackground,
  survey,
}) => {
  return (
    <Popup
      open={showModal}
      onClose={() => setShowModal(false)}
      contentStyle={MODAL_STYLE}
    >
      <>
        <FlexBox padding="18px" justify="space-between">
          <Text variant="p" weight="bold" type="main">
            Background Colour
          </Text>
          <CloseIcon type="button" onClick={() => setShowModal(false)} />
        </FlexBox>
        <Block width="100%" height="100%">
          <SketchPicker
            color={survey?.background}
            presetColors={COLOR_ARRAY}
            width="406px"
            onChangeComplete={(col) => setBackground(col.hex)}
          />
        </Block>
        {/* <Block padding="18px" width="100%" height="100%">
          <FlexBox wrap="wrap" align="center" gap="12px" justify="space-around">
            {COLOR_ARRAY.map((col) => (
              <$ColorBox
                key={col}
                onClick={() => setBackground(col)}
                bgrdColor={col}
              />
            ))}
          </FlexBox>
          <$ColorInput>
            <input
              type="color"
              value={survey?.background}
              onChange={(e) => setBackground(e.target.value)}
            />
            <label>{survey?.background?.toUpperCase()}</label>
          </$ColorInput>
          <FlexBox align="center" justify="center" padding="0 0 18px">
            Choose a custom color
          </FlexBox>
        </Block>
       */}
      </>
    </Popup>
  )
}
