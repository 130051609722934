import React from 'react'
import { $OpinionScaleBox } from '../Survey.styled'

export const OpinionScale = ({ ...props }) => {
  const num = props.optNumber >= 0 ? props.optNumber : props.hoverNumber

  const isActive = props?.reverse
    ? Number(props.option) == Number(num)
    : Number(props.option) <= Number(num)
  return (
    <$OpinionScaleBox isActive={isActive} width="20px">
      {props.option}
    </$OpinionScaleBox>
  )
}
