import React from 'react'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Div } from 'styledComponent'
import { useSelectStore } from 'hooks'
import SegmentForm from 'components/Content/consumerAnalytics/AnalyticsSegment/SegmentForm'
import { TopActionBar } from './TopActionBar'
import { getSegments } from 'redux/actions/autoEngageActions'

export const AddSegmentModal = ({ isPopOpen, setIsPopOpen }) => {
  const { colors, spacing } = useTheme()
  const dispatch = useDispatch()
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  return isPopOpen ? (
    <Div
      width="calc(100vw - 100px)"
      minHeight="calc(100vh - 60px)"
      position="absolute"
      zIndex="1000"
      left="85px"
      top="60px"
      backgroundColor={colors.white}
    >
      <TopActionBar
        exitTitle="Back"
        onExit={() => setIsPopOpen(false)}
        title="Create Segment"
      />
      <Div pl={spacing.xxl} py={spacing.l}>
        <SegmentForm
          onCancel={() => setIsPopOpen(false)}
          getSegments={() => dispatch(getSegments({ vendorId }))}
        />
      </Div>
    </Div>
  ) : null
}
