const initialState = {
    loyaltyId: "",
    settings: {
        accrual: {
            accrualPercent: 0,
        },
        redemption: {
            redemptionPercent: 0,
        }
    }
}
  
export default function(state = initialState, action) {
    switch (action.type) {
        case "SET_LOYALTY_ID":
        return {
            ...state,
            loyaltyId : action.payload
        };
        case "SET_LOYALTY_SETTING": 
        return {
            ...state,
            settings: action.payload
        }
        default:
        return state;
    }
}