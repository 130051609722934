import React from 'react'
import Navbar from 'components/Layout/Navbar'
import { $Container } from '../AutoEngagement.styled'
import { useSelectStore } from 'hooks'
import { LoaderPopup } from 'components/Common'
import { TopActionBar } from './TopActionBar'

const TopBar = ({ heading, ...topActionBarProps }) => {
  const { data } = useSelectStore('autoEngage.aeLoading')

  return (
    <$Container noPadding>
      <Navbar heading={heading} />
      <TopActionBar {...topActionBarProps} />
      <LoaderPopup
        isPopOpen={data}
        title="Your campaign is loading. Please Wait..."
      />
    </$Container>
  )
}

export default TopBar
