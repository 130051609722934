import React from 'react'
import { useNode } from '@craftjs/core'

import { MediaSettings } from './MediaSettings'

export const Media = (props) => {
  const {
    connectors: { connect },
    isSelected,
  } = useNode((state) => ({
    isSelected: state.events.selected,
  }))

  return (
    <div
      ref={(ref) => connect(ref)}
      style={{ padding: '10px', textAlign: 'center' }}
    >
      <a
        className={props.fileName}
        fileType={props.fileType}
        publicLink={props.publicLink}
        target="_blank"
        rel="noopener"
        href={!isSelected && props.link}
      >
        {props.content}
      </a>
    </div>
  )
}

export const MediaDefaultProps = {
  content: 'Click here to view file',
  link: '',
  publicLink: '',
  fileName: '',
  fileType: '',
}

Media.craft = {
  props: MediaDefaultProps,
  related: {
    settings: MediaSettings,
  },
}
