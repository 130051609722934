import React from 'react'
import { useNode } from '@craftjs/core'

import { SpacerSettings } from './SpacerSettings'

export const Spacer = (props) => {
  const {
    connectors: { connect },
    craftProps,
  } = useNode((node) => ({
    craftProps: node.data.props,
  }))

  return (
    <div
      ref={(ref) => connect(ref)}
      style={{
        ...props,
        height: `${craftProps.height}px`,
      }}
    />
  )
}

const SpacerDefaultProps = {
  width: '100%',
  height: 20,
}

Spacer.craft = {
  props: SpacerDefaultProps,
  related: {
    settings: SpacerSettings,
  },
}
