import createReducer from 'utils/createReducer'
import * as types from 'redux/types/consumerAnalytics'

const initialState = {
  totalNumbersCount: 0,
  deviceData: {},
  feedbackStars: [],
  topCustomers: [],
  stats: [],
  customersList: [],
  avgSpendSegment: {},
  avgSkuSegment: {},
  retentionSegment: null,
  allAvailableLocation: {
    list: [],
  },
  previousSelected: {
    date: {},
    stores: [],
    segments: [],
    tab: '',
    shouldFetchNewData: false,
    shouldFetchNewChartData: false,
  },
  posData: null,
  location: null,
  selectedSegments: [],
  selectedDate: {},
  filters: {
    noOfVisits: {
      id: 1,
      isActive: false,
      label: 'No. of Visits',
      value: {
        min: 0,
        max: 0,
      },
    },
    expenses: {
      id: 2,
      isActive: false,
      label: 'Expenses',
      value: {
        total: {
          isActive: false,
          min: 0,
          max: 0,
        },
        avg: {
          isActive: false,
          min: 0,
          max: 0,
        },
      },
    },
    avgFeedback: {
      id: 3,
      isActive: false,
      label: 'Avg. Feedback',
      value: {
        min: 0,
        max: 5,
      },
    },
    segmentsValue: {
      id: 4,
      isActive: false,
      label: 'Segmentation',
      value: [],
    },
    location: {
      id: 5,
      isActive: false,
      label: 'Location',
      value: [],
    },
    gender: {
      id: 6,
      isActive: false,
      label: 'Gender',
      value: {
        male: false,
        female: false,
      },
    },
    os: {
      id: 7,
      isActive: false,
      label: 'OS',
      value: {
        google: false,
        iphone: false,
        other: false,
      },
    },
  },
  ageSegment: null,
  genderSegment: null,
  segmentsList: null,
  customerSegmentsCount: null,
  segmentsRegion: null,
}

export default createReducer(initialState)(types)
