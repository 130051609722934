import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'
import { AD_CAMP_STEPS, CAMP_INIT, CHECK_IF } from '../constants'
import { $WhiteContainer } from '../AutoEngagement.styled'
import { AdvertCampaign } from './AdvertCampaign'
import { useCampaignApi } from '../components'
import { validateIframe, validateIframeAttribute } from 'utils/iframeUtils'

export const validateScheduleCampaignDateTime = (form) => {
  if (!!form?.activateAt) {
    if (!form?.deactivateAt)
      return 'Please also select End Date along with Start Date'
    else {
      const turnOnTime = moment(form?.activateAt)
      const turnOffTime = moment(form?.deactivateAt)

      if (turnOffTime.isBefore(turnOnTime)) {
        return 'Scheduled End Date time should be after Scheduled Start Date time'
      }
    }
  }

  if (!!form?.activateAt) {
    if (moment(form?.activateAt).isBefore(moment()))
      return 'Scheduled Start Date time should be after the current time'
  }
  if (!!form?.deactivateAt) {
    if (moment(form?.deactivateAt).isBefore(moment()))
      return 'Scheduled End Date time should be after the current time'
  }

  return false
}

export const AdBelowBill = () => {
  const [adForm, setAdForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(adForm, setAdForm, CAMP_INIT, 'adBelowBill')

  useEffect(() => {
    const step = hideSteps
      ? { id: 4, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [adForm])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && campaignId && updCampaign()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...adForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (campaignId && campaignId !== 'create'
            ? updCampaign()
            : createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...adForm?.content?.body }
        const check = CHECK_IF(['componentType'], builderDetails)
        if (check) throw `Please select a component`
        if (builderDetails?.assetsDetails?.length < 1)
          throw 'Please add an asset for your campaign.'
        if (
          builderDetails?.componentType !== 'youtube' &&
          !builderDetails?.assetsDetails[0]?.fileLocation
        )
          throw 'Please add an asset for your campaign.'
        if (
          builderDetails?.componentType === 'youtube' &&
          !builderDetails?.assetsDetails[0]?.key
        )
          throw 'Please add an asset for your campaign.'

        if (
          builderDetails.componentType === 'youtube' &&
          (!validateIframe(
            builderDetails?.assetsDetails[0]?.key ||
              builderDetails?.assetsDetails[0]?.fileLocation
          ) ||
            !validateIframeAttribute(
              builderDetails?.assetsDetails[0]?.key ||
                builderDetails?.assetsDetails[0]?.fileLocation
            ))
        ) {
          throw 'Please add a valid youtube embedd link.'
        }

        return await updateCall()
      }

      case 3: {
        const storeDetails = { ...adForm?.targetGroup }
        if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
          throw 'Please select a brand and at least one store.'

        const isScheduleErrorPresent = validateScheduleCampaignDateTime(adForm)
        if (isScheduleErrorPresent !== false) throw isScheduleErrorPresent

        return await updateCall()
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Ad Below Bill"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={AD_CAMP_STEPS}
        hideSteps={hideSteps}
        onSave={onSave}
        onPreviousClick={fetchCampaignById}
        onPublish={onPublish}
        form={adForm}
        campaignType="Ad Below Bill"
      >
        <AdvertCampaign
          form={adForm}
          setForm={setAdForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}
