import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import { Div, FlexBox } from 'styledComponent'
import { Text, Button, Input } from 'components/Common'
import { $PopContainer } from '../AutoEngagement.styled'
import {
  GET_SEL_VALUE,
  POPUP_STYLE,
  SECURITY_PROTOCOL,
  EMAIL_PROVIDER_INIT,
  ERROR_DECODE,
  EMAIL_PROVIDERS,
  GET_HOST_NAME,
  EMAIL_PROVIDER_IMAGES,
} from '../constants'
import { CloseIcon } from 'assets/icons'
import { spacing } from 'design-system'
import { useSelectStore } from 'hooks'
import {
  getEmailProviders,
  postEmailProvider,
} from 'redux/actions/autoEngageActions'
import { useToasts } from 'components/Common/Toast'

export const EmailProviderModal = ({ isPopOpen, setIsPopOpen }) => {
  const {
    data: { userId },
  } = useSelectStore('auth.user')
  const {
    data: { value },
  } = useSelectStore('vendorIds.selectedVendor')
  const emailProviderInit = EMAIL_PROVIDER_INIT(userId, value)

  const [emailProvider, setEmailProvider] = useState(emailProviderInit)

  const { toast } = useToasts()
  const dispatch = useDispatch()

  const handleChange = (e, sender) => {
    if (sender) {
      setEmailProvider({
        ...emailProvider,
        senderConfig: {
          ...emailProvider.senderConfig,
          [e.target.id]: e.target.value,
        },
      })
    } else {
      setEmailProvider({
        ...emailProvider,
        [e.target.id]:
          e.target.id === 'port' ? Number(e.target.value) : e.target.value,
      })
    }
  }

  const handleProvider = ({ value }) => {
    setEmailProvider({
      ...emailProvider,
      provider: value,
      host: GET_HOST_NAME(value),
    })
  }

  const handleSecure = (value) => {
    setEmailProvider({
      ...emailProvider,
      secure: value,
      port: value ? 465 : 587,
    })
  }

  const onCancel = () => {
    setEmailProvider(emailProviderInit)
    setIsPopOpen(false)
  }

  const onSubmit = async () => {
    try {
      const res = await postEmailProvider(emailProvider)
      if (res) {
        setEmailProvider(emailProviderInit)
        toast('Email config saved successfully.', 'success')
        setIsPopOpen(false)
        dispatch(getEmailProviders(value))
      } else {
        throw res
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      console.error(err)
    }
  }

  return (
    <Popup
      open={isPopOpen}
      onClose={() => setIsPopOpen(false)}
      contentStyle={{ ...POPUP_STYLE, width: '1098px' }}
      className="modal"
    >
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            Email Provider
          </Text>
          <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
        </FlexBox>

        <FlexBox gap={spacing.xxl}>
          <Div ml={spacing.l} width="300px">
            <Input
              containerMargin="0"
              variant="input"
              label="Name your Configuration"
              placeholder="Configuration Name"
              value={emailProvider.name || ''}
              onChange={handleChange}
              id="name"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="single-select"
              label="Select Provider"
              placeholder="Provider"
              value={
                GET_SEL_VALUE(emailProvider.provider, EMAIL_PROVIDERS) || ''
              }
              options={EMAIL_PROVIDERS}
              onChange={handleProvider}
              id="provider"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="input"
              label="Enter Host Name"
              placeholder="Host"
              value={emailProvider.host || ''}
              onChange={handleChange}
              id="host"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="radio"
              label="Select Security Protocol"
              placeholder="SSL"
              value={emailProvider.secure}
              options={SECURITY_PROTOCOL}
              onChange={handleSecure}
              id="secure"
              inputWidth="100%"
              gap={spacing.xxl}
            />
            <Input
              containerMargin="0"
              variant="input"
              type="number"
              label="Enter Port Details"
              placeholder="Port"
              value={emailProvider.port}
              onChange={handleChange}
              id="port"
              inputWidth="100%"
            />
          </Div>
          <Div mr={spacing.l} width="300px">
            <Input
              containerMargin="0"
              variant="input"
              label="Sender Name"
              placeholder="Sender Name"
              value={emailProvider.senderConfig?.name || ''}
              onChange={(e) => handleChange(e, 'senderConfig')}
              id="name"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="input"
              type="email"
              label="Sender Email"
              placeholder="Sender Email"
              value={emailProvider.senderConfig?.email || ''}
              onChange={(e) => handleChange(e, 'senderConfig')}
              id="email"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="input"
              label="Username"
              placeholder="Username"
              subLabel="(Please use an email ID with 2AF disabled)"
              value={emailProvider.username || ''}
              onChange={handleChange}
              id="username"
              inputWidth="100%"
            />
            <Input
              containerMargin="0"
              variant="input"
              type="password"
              label="Password"
              placeholder="Password"
              value={emailProvider.password || ''}
              onChange={handleChange}
              id="password"
              inputWidth="100%"
            />
          </Div>
          <Div ml={spacing.l} width="300px">
            <Text variant="h6" type="main" weight="bold">
              Instructions on how to setup SMTP.
            </Text>
            <Text variant="medium" type="grey" mb={spacing.l}>
              (Click on the logo to re-direct to instructions)
            </Text>
            <FlexBox
              wrap="wrap"
              gap={spacing.l}
              justify="space-around"
              align="center"
            >
              {EMAIL_PROVIDER_IMAGES.map((provider, ind) => (
                <a
                  href={provider.href}
                  key={provider.href}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    height={ind % 2 ? '50' : '100'}
                    width="140"
                    src={provider.src}
                    alt={provider.title}
                    title={provider.title}
                    style={{ objectFit: 'contain' }}
                  />
                </a>
              ))}
            </FlexBox>
          </Div>
        </FlexBox>

        <FlexBox justify="flex-end">
          <Button mr={spacing.l} variant="primaryOutline" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="primary">
            Submit
          </Button>
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}
