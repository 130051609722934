import styled from 'styled-components'

const fontSize = (size) => {
  switch (size) {
    case 'small':
      return '12px'
    case 'medium':
      return '18px'
    case 'large':
      return '24px'
    default:
      return '12px'
  }
}

const $IconBlock = styled.div`
  width: ${({ width }) => width || '38px'};
  height: ${({ height }) => height || '38px'};
  background-color: ${(props) => props.bgColor || props.theme.colors.white};
  border-radius: ${({ rounded }) => (rounded ? '5px' : 'unset')};
  position: relative;
`

const $IconText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ variant }) => fontSize(variant)};
  color: ${(props) => props.color || props.theme.colors.dark};
  font-weight: 900;
`

export { $IconBlock, $IconText }
