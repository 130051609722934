import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Chart, Geom, Axis, Tooltip } from 'bizcharts'
import _ from 'lodash'

import DateRangeSelector from './DateRangeSelector'
import ChartHeader from './ChartHeader'
import { getTotalSalesChart } from '../../../../redux/actions/billingCharts'
import { formatDate2 } from '../../../../utils/getDateFormat'
import { Text } from '../../../../styledComponent'
import '../../../../css/content/billingCharts.scss'

class TotalSalesChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'false',
    }
  }

  handleDateRangeChange = (values) => {
    this.setState({ showCalendar: false })

    this.props.handleDateChange(values)

    const startDate = values.start.startOf('day').toDate()
    const endDate = values.end.endOf('day').toDate()

    this.props.getTotalSalesChart({
      startDate,
      endDate,
      type: 'total_sales',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  showCalendar = () => {
    this.setState((state) => ({
      ...state,
      showCalendar: !state.showCalendar,
    }))
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        dispaly: 'true',
      }
    }
  }

  componentDidMount() {
    const date = this.props.selectedDate

    this.props.handleDateChange(date)

    this.props.getTotalSalesChart({
      startDate: date.start?.toDate(),
      endDate: date.end?.toDate(),
      type: 'total_sales',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  render() {
    if (_.has(this.props.billingCharts, 'totalSales')) {
      var data = this.props.billingCharts.totalSales
    } else {
      var data = []
    }
    const cols = {
      date: {
        alias: 'date',
      },
      sale: {
        alias: 'sale',
      },
    }

    return (
      <div className="totalSalesChart chartsMain">
        <ChartHeader title="Total Sales" />
        <hr />
        <DateRangeSelector
          value={this.props.selectedDate}
          onDateChange={this.handleDateRangeChange}
          showCalendar={this.state.showCalendar}
          toggleCalender={this.showCalendar}
        />
        <Chart
          height={400}
          data={data}
          padding={[100, 50, 100, 100]}
          scale={cols}
          forceFit
        >
          <Axis
            name="date"
            label={{
              formatter: (val) => formatDate2(val),
            }}
          />
          <Axis
            name="sale"
            label={{
              formatter: (val) => `${val} Rs.`,
            }}
          />
          <Tooltip
            crosshairs={{
              type: 'y',
            }}
          />
          <Geom type="line" position="date*sale" size={2} />
          <Geom
            type="point"
            position="date*sale"
            size={4}
            shape={'circle'}
            style={{
              stroke: '#fff',
              lineWidth: 1,
            }}
          />
        </Chart>
        <div className="noData">
          {_.isEmpty(data) ? (
            <Text text-align={'center'}>
              No data to display between selected dates
            </Text>
          ) : (
            <Text text-align={'center'}>
              Total Sales: {_.sumBy(data, 'sale')}
            </Text>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  billingCharts: state.billingCharts,
  errors: state.errors,
})

export default connect(mapStateToProps, { getTotalSalesChart })(TotalSalesChart)
