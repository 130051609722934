import React from 'react'
import { useNode, Element } from '@craftjs/core'

import { ColumnsSettings } from './ColumnsSettings'
import { ColumnContainer } from '../Container'

export const Columns = ({ children = '', ...props }) => {
  const {
    connectors: { connect },
  } = useNode()

  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = props
  const parentPadding = props.allSidesPadding
    ? props.parentPadding
    : `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`

  return props.sections ? (
    <div
      ref={(ref) => connect(ref)}
      style={{
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        textAlign: 'center',
        padding: parentPadding,
      }}
    >
      {props.sections >= 1 && (
        <Element canvas is={ColumnContainer} id="conta1">
          {children}
        </Element>
      )}
      {props.sections >= 2 && (
        <Element canvas is={ColumnContainer} id="conta2">
          {children}
        </Element>
      )}
      {props.sections >= 3 && (
        <Element canvas is={ColumnContainer} id="conta3">
          {children}
        </Element>
      )}
      {props.sections >= 4 && (
        <Element canvas is={ColumnContainer} id="conta4">
          {children}
        </Element>
      )}
      {props.sections >= 5 && (
        <Element canvas is={ColumnContainer} id="conta5">
          {children}
        </Element>
      )}
    </div>
  ) : null
}

export const ColumnsDefaultProps = {
  sections: 2,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
}

Columns.craft = {
  props: ColumnsDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ColumnsSettings,
  },
}
