import React from 'react'

import { CampaignSettingsWithATOC } from '../components/CampaignSettingsWithATOC'

export const BannerSettings = ({ form, setForm }) => (
  <CampaignSettingsWithATOC
    header="Campaign Settings"
    form={form}
    setForm={setForm}
  />
)
