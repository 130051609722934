import moment from 'moment'

export const ADD_NEW_OPTIONS = [
  {
    label: 'Company',
    route: '/storeManagement/createCompany',
  },
  {
    label: 'Brand',
    route: '/storeManagement/createBrand',
  },
  {
    label: 'Retail Store',
    route: '/storeManagement/createRetailStore',
  },
  {
    label: 'ECom Store',
    route: '/storeManagement/createEcomStore',
  },
  {
    label: 'Store Group',
    route: '/storeManagement/createStoreGroup',
  },
]

export const CUSTOM_LINK_TYPES = [
  {
    label: 'Food Ordering',
    value: 'foodOrdering',
  },
  {
    label: 'Google Review',
    value: 'googleReview',
  },
  {
    label: 'Ticket Booking via website/app',
    value: 'internalTicketBooking',
  },
  {
    label: 'Default Survey',
    value: 'defaultSurvey',
  },
  {
    label: 'Ticket Booking via BookMyShow',
    value: 'bookMyShowTicketBooking',
  },
  {
    label: 'Ticket Booking via Paytm',
    value: 'paytmTicketBooking',
  },
]

export const SECT_HEADINGS = {
  Company: {
    heading: 'Company Listing',
    subHeading: 'List of all company data in your system',
  },
  Brands: {
    heading: 'Brand Listing',
    subHeading: 'List of all brand data in your system',
  },
  'Retail Stores': {
    heading: 'Retail Store Listing',
    subHeading: 'List of all retail store data in your system',
  },
  'E-Commerce Stores': {
    heading: 'E-Commerce Store Listing',
    subHeading: 'List of all e-Commerce store data in your system',
  },
  'Store Groups': {
    heading: 'Store Groups Listing',
    subHeading: 'List of all store group data in your system',
  },
}

export const COLUMNS = {
  Company: [
    'Company Name',
    'Incharge Name',
    'Phone number',
    'Email Address',
    'GST',
    'Actions',
  ],
  Brands: ['Brand Logo', 'Brand Name', 'Category', 'Actions'],
  'Retail Stores': [
    'Company Name',
    'Brand',
    'Store ID',
    'Store Code',
    'Display Address',
    'Status',
    'Actions',
  ],
  'E-Commerce Stores': ['Store ID', 'Store Code', 'Status', 'Actions'],
  'Store Groups': [
    'Store Group Name',
    'Description',
    'Selected Stores',
    'Actions',
  ],
  'Manage Keys': [
    'Access Key',
    'Name',
    'Created On',
    'MAC ID',
    'IP Address',
    'Actions',
  ],
  'Access Keys': [
    'Store Details',
    'Store Code',
    'Access Key',
    'POS Name',
    'MAC ID',
    'Version',
    'BIT',
    // 'IP Address',
    'Last Transaction On',
    'Last Updated At',
    'Status',
    'Actions',
  ],
}

export const FORM_INITS = {
  Company: {
    companyName: '',
    companyType: '',
    emailId: '',
    inchargeName: '',
    billingName: '',
    mobileNumber: '',
    country: 'India',
    countryCode: 'IN',
    currencyCode: 'INR',
    state: '',
    city: '',
    gst: '',
    pan: '',
    officeAddres: '',
    pincode: '',
  },
  StoreGroup: {
    name: '',
    description: '',
    groupData: [],
  },
  Store: {
    storeCompanyId: '',
    // storeName: '',
    storeInCharge: '',
    noOfEmployee: '',
    storeSize: '',
    displayAddress: '',
    storeBrandId: '',
    country: 'India',
    state: '',
    city: '',
    storeContact: '',
    storeContact2: '',
    pincode: '',
    address: '',
    storeCode: '',
    customLinks: [],
  },
}

export const REQUIRED_FIELDS = {
  Company: [
    'companyName',
    'companyType',
    'emailId',
    'mobileNumber',
    'state',
    'city',
  ],
  Brands: ['brandName', 'categoryId'],
  StoreGroup: ['name', 'groupData'],
  'Retail Stores': [
    // 'storeName',
    'storeCompanyId',
    'displayAddress',
    'storeContact',
    'type',
    'storeBrandId',
    'posName',
    'noOfPos',
  ],
  'E-Commerce Stores': [
    // 'storeName',
    'storeCompanyId',
    'displayAddress',
    'storeContact',
    'type',
    'storeBrandId',
  ],
}

export const INIT_STORES_FILTER = {
  company: {
    id: 1,
    type: 'company',
    label: 'Company',
    isActive: false,
    inputType: 'multi-select',
    value: [],
    options: [],
  },
  brand: {
    id: 2,
    type: 'brand',
    label: 'Brand',
    isActive: false,
    inputType: 'multi-select',
    value: [],
    options: [],
  },
  status: {
    id: 3,
    type: 'status',
    label: 'Status',
    isActive: false,
    inputType: 'radio',
    options: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
    value: null,
  },
  emailList: {
    id: 4,
    type: 'emailList',
    label: 'Email List',
    isActive: false,
    inputType: 'create-tags',
    value: [],
  },
}

export const INIT_OTHER_FILTER = {
  emailList: {
    id: 1,
    type: 'emailList',
    label: 'Email List',
    isActive: false,
    inputType: 'create-tags',
    value: [],
  },
}

export const INIT_ACCESS_FILTER = {
  dateRange: {
    id: 1,
    type: 'dateRange',
    label: 'Last Transaction',
    isActive: false,
    inputType: 'date-range',
    value: {
      startDate: moment().clone().startOf('day'),
      endDate: moment().clone(),
    },
  },
  status: {
    id: 2,
    type: 'status',
    label: 'Status',
    isActive: false,
    inputType: 'radio',
    options: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
    value: false,
  },
  emailList: {
    id: 3,
    type: 'emailList',
    label: 'Email List',
    isActive: false,
    inputType: 'create-tags',
    value: [],
  },
  version: {
    id: 4,
    type: 'version',
    label: 'Version',
    isActive: false,
    inputType: 'input',
    value: '',
  },
  macId: {
    id: 5,
    type: 'macId',
    label: 'Mac Id',
    isActive: false,
    inputType: 'input',
    value: '',
  },
  bit: {
    id: 6,
    type: 'bit',
    label: 'Bit',
    isActive: false,
    inputType: 'radio',
    options: [
      {
        label: '32',
        value: 32,
      },
      {
        label: '64',
        value: 64,
      },
    ],
    value: 32,
  },
}

export const GET_FILTER_FIELD = (key, tab) => {
  switch (key) {
    case 'company':
      return 'companyIds'
    case 'brand':
      return 'brandIds'
    case 'status':
      return tab === 'Access Keys' ? 'status' : 'isActive'
    default:
      return key
  }
}

export const SHOW_FILTER_ARRAY = [
  'Retail Stores',
  'E-Commerce Stores',
  'Access Keys',
]

export const URL_REGEXP =
  /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
