export default function convertToUTC(date) {
  var d2 = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  return Math.floor(d2.getTime());
}
