import React from 'react'
import { useSelectStore } from 'hooks'
import { Avatar } from 'components/Common'
import { Checkbox } from 'components/Common/Input'
import { Block } from 'styledComponent'
import styled, { css } from 'styled-components'

const $BrandCheckbox = styled(Checkbox)`
  label {
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      min-width: 35px;
      min-height: 35px;
      border-color: ${({ theme }) => theme.colors.darkMercury};
    }
  }
`

const $SelectContainer = styled(Block)`
  width: 100%;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
      overflow-y: auto;
    `};
`

export const SelectBrands = ({ brands, setBrands }) => {
  const { data: allBrands } = useSelectStore('brand.brands')
  const { data: vendorBrandIds } = useSelectStore('vendorIds.vendorBrands')
  const vendorBrands = allBrands?.filter((ab) =>
    vendorBrandIds?.map((vb) => vb.value)?.includes(ab.brandId)
  )

  const handleCheckbox = (val) => {
    if (val === 'all') {
      if (vendorBrands.length === brands.length) {
        setBrands([])
      } else {
        setBrands(vendorBrands)
      }
    } else {
      if (brands.includes(val)) {
        const br = brands.filter((brnd) => brnd.brandId !== val.brandId)
        setBrands(br)
      } else {
        setBrands([...brands, val])
      }
    }
  }

  return (
    <$SelectContainer padding="20px" maxHeight="60vh">
      <$SelectContainer height="40px">
        <$BrandCheckbox
          id="all"
          value="all"
          onCheckboxChange={() => handleCheckbox('all')}
          isChecked={vendorBrands.length === brands.length}
        >
          Select All
        </$BrandCheckbox>
      </$SelectContainer>
      {vendorBrands.map((br) => (
        <$SelectContainer height="40px" padding="10px" key={br.brandId}>
          <$BrandCheckbox
            id={br.brandId}
            value={br.brandId}
            onCheckboxChange={() => handleCheckbox(br)}
            isChecked={brands.includes(br)}
          >
            <Avatar src={br.brandLogo} /> {br.name}
          </$BrandCheckbox>
        </$SelectContainer>
      ))}
    </$SelectContainer>
  )
}
