import React, { useState } from 'react'
import { connect } from 'react-redux'
import { $WhiteContainer } from '../AutoEngagement.styled'
import { SurveyHeader, SurveyTable, useSurveyApi } from './table'

const Survey = (props) => {
  const { vendorId } = props
  const { aeTableLoading, surveyData } = props.autoEngage

  const [search, setSearch] = useState('')
  const [skip, setSkip] = useState(0)
  const [triggers, setTriggers] = useState({})

  const onSearchChange = (s) => {
    setSearch(s)
    setSkip(0)
  }

  useSurveyApi(vendorId, search, skip, triggers)

  const pageCount =
    parseInt(surveyData.count / 10) +
    (parseInt(surveyData.count % 10) > 0 ? 1 : 0)

  return (
    <$WhiteContainer>
      <SurveyHeader search={search} setSearch={onSearchChange} />
      <SurveyTable
        pageNumber={skip / 10}
        tableLoading={aeTableLoading}
        onPaginationChange={(page) => setSkip(page.selected * 10)}
        surveyList={surveyData.docs}
        pageCount={pageCount}
        setTriggers={setTriggers}
      />
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  vendorId: state.vendorIds.selectedVendor.value,
  autoEngage: state.autoEngage,
})

export default connect(mapStateToProps)(Survey)
