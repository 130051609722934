import React from 'react'
import styled from 'styled-components'
import { FlexBox, GreyText } from 'styledComponent'

const transition = 'transform 0.5s ease-in-out, opacity 0.8s ease-in-out'

export const StyledBox = styled(FlexBox)`
  width: 90%;
  max-width: 420px;
  height: 350px;
  border: 1px solid #e1e8ed;
  box-shadow: 0px 0px 10px 10px #f6f7f9;
  margin: 2em 0 4em;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 2em 3.6em 1em;
  background-color: #fff;
`

export const Process = ({
  page,
  onPageOneSubmit,
  onPageTwoSubmit,
  onPageTwoSecondarySubmit, // Meta handler
  onPageTwoTertiarySubmit, // Meta handler

  // Can handle only two input on both pages
  isPageOneSubmitting,
  isPageTwoSubmitting,

  // ------------------------

  pageOneErrorOne,
  pageOneErrorTwo,
  pageTwoErrorOne,
  pageTwoErrorTwo,

  // ------------------------------
  handlePageOneInputOne,
  handlePageOneInputTwo,
  handlePageTwoInputOne,
  handlePageTwoInputTwo,
  // Same for value field --------
  pageOneValueOne,
  pageOneValueTwo,
  pageTwoValueOne,
  pageTwoValueTwo,
  pageTwoValueThree, //
  // ------------------------
  isBannerVisible,
  InformationBanner,
  PageOne,
  PageTwo,
  PageThree,

  handlePrivacy,
}) => {
  const [page1Opacity, setPage1Opacity] = React.useState(0)
  const [p1X, setP1X] = React.useState(50)
  const [page2Opacity, setPage2Opacity] = React.useState(0)
  const [p2X, setP2X] = React.useState(50)
  const [page3Opacity, setPage3Opacity] = React.useState(0)
  const [p3X, setP3X] = React.useState(50)

  const [bannerY, setBannerY] = React.useState(-100)
  const [bannerOpacity, setBannerOpacity] = React.useState(0)

  React.useEffect(() => {
    // Animate current page on load
    if (page === 1) {
      setP1X(0)
      setPage1Opacity(1)
    } else if (page === 2) {
      setP2X(0)
      setPage2Opacity(1)
    }
  }, [])

  React.useEffect(() => {
    if (page === 1) {
      // Reset all and show 1
      setP1X(0)
      setPage1Opacity(1)

      if (p2X === 0) {
        setP2X(-50)
        setPage2Opacity(0)
      }
    } else if (page === 2) {
      setP1X(-50)
      setPage1Opacity(0)

      setP2X(0)
      setPage2Opacity(1)
    } else if (page === 3) {
      setP2X(-50)
      setPage2Opacity(0)

      setP3X(0)
      setPage3Opacity(1)
    }
  }, [page])

  React.useEffect(() => {
    getBannerPosition()
  }, [isBannerVisible])

  const getBannerPosition = () => {
    // Show banner if visible
    if (isBannerVisible) {
      setBannerY(0)
      setBannerOpacity(1)
    } else {
      setBannerY(-100)
      setBannerOpacity(0)
    }
  }

  return (
    <StyledBox direction="column" align="center">
      {InformationBanner && (
        <InformationBanner
          style={{
            transform: `translateY(${bannerY}px)`,
            opacity: bannerOpacity,
          }}
        />
      )}
      {PageOne && (
        <FlexBox
          as="form"
          direction="column"
          justify="center"
          style={{
            width: '100%',
            transform: `translateX(${p1X}px)`,
            opacity: page1Opacity,
            transition,
            overflow: 'hidden',
            flex: page1Opacity,
          }}
        >
          <PageOne
            onSubmit={onPageOneSubmit}
            handlePageOneInputOne={handlePageOneInputOne}
            handlePageOneInputTwo={handlePageOneInputTwo}
            pageOneValueOne={pageOneValueOne}
            pageOneValueTwo={pageOneValueTwo}
            errorOne={pageOneErrorOne}
            isSubmitting={isPageOneSubmitting}
          />
        </FlexBox>
      )}

      {PageTwo && (
        <FlexBox
          as="form"
          direction="column"
          justify="center"
          style={{
            width: '100%',
            transform: `translateX(${p2X}px)`,
            opacity: page2Opacity,
            transition,
            overflow: 'hidden',
            flex: page2Opacity,
          }}
        >
          <PageTwo
            onSubmit={onPageTwoSubmit}
            onPageTwoSecondarySubmit={onPageTwoSecondarySubmit} // Meta handler
            onPageTwoTertiarySubmit={onPageTwoTertiarySubmit}
            isSubmitting={isPageTwoSubmitting}
            handlePageTwoInputOne={handlePageTwoInputOne}
            handlePageTwoInputTwo={handlePageTwoInputTwo}
            pageTwoValueOne={pageTwoValueOne}
            pageTwoValueTwo={pageTwoValueTwo}
            pageTwoValueThree={pageTwoValueThree}
            errorOne={pageTwoErrorOne}
            errorTwo={pageTwoErrorTwo}
            // Newly added
            isVisible={Boolean(page2Opacity)}
          />
        </FlexBox>
      )}

      {PageThree && (
        <FlexBox
          as="form"
          direction="column"
          justify="center"
          style={{
            width: '100%',
            transform: `translateX(${p3X}px)`,
            opacity: page3Opacity,
            transition,
            overflow: 'hidden',
            flex: page3Opacity,
          }}
        >
          <PageThree />
        </FlexBox>
      )}

      {handlePrivacy && (
        <FlexBox justify="center" expand>
          <GreyText
            as="button"
            onClick={handlePrivacy}
            fontSize="small"
            style={{
              border: 'none',
              backgroundColor: '#fff',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            Privacy Policy
          </GreyText>
        </FlexBox>
      )}
    </StyledBox>
  )
}
