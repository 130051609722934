import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { FlexBox, Div } from 'styledComponent'
import { useSelectStore } from 'hooks'
import HtmlEditor from 'builder/editor'
import {
  Text,
  Input,
  Button,
  useToasts,
  PopupContainer,
} from 'components/Common'
import {
  getEmailTemplates,
  postEmailTemplate,
  setCampaignData,
  testCampaign,
  updateCampaign,
} from 'redux/actions/autoEngageActions'
import { EMAIL_PREVIEW, ERROR_DECODE } from '../constants'
import { $PublishButton } from '../AutoEngagement.styled'
import { EditorPreview } from 'builder/editor/EditorPreview'

export const EmailMessage = ({ form, setForm, isJourney }) => {
  const dispatch = useDispatch()
  const { toast } = useToasts()
  const { spacing, colors, borderRadius } = useTheme()
  const { data: emailTemplates } = useSelectStore('autoEngage.emailTemplates')

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [isTemplatePopOpen, setIsTemplatePopOpen] = useState(false)
  const [limit, setLimit] = useState(5)
  const [email, setEmail] = useState('')
  const [templateBody, setTemplateBody] = useState({})
  const [isPreview, setIsPreview] = useState(false)

  const sendTest = async () => {
    try {
      const regEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!regEmail.test(String(email).toLowerCase()))
        throw 'Please enter a valid email'
      const updateRes = await updateCampaign(form._id, {
        vendorId: form.vendorId,
        status: form.status,
        content: {
          subject: cloneDeep(form?.content?.subject),
          body: cloneDeep(form?.content?.body),
        },
      })
      if (updateRes?.data) {
        dispatch(setCampaignData(cloneDeep(getResult(updateRes.data))))
        const res = await testCampaign(form._id, { targetUser: email })
        if (res) {
          setEmail('')
          toast('Email sent successfully', 'success')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  useEffect(() => {
    isPopOpen && fetchTemplates()
  }, [limit, isPopOpen])

  const getResult = (resData) => {
    let result = cloneDeep(resData)
    if (result.startDate && result.scheduledTime) {
      const [hours, minutes] = result.scheduledTime?.split(':')
      const [startDate, scheduledTime] = moment
        .utc(result.startDate)
        ?.set({ hours, minutes })
        ?.local()
        ?.format('YYYY-MM-DD/HH:mm')
        ?.split('/')
      result = { ...cloneDeep(resData), startDate, scheduledTime }
    }
    return result
  }

  const createEmailTemplate = async () => {
    try {
      const body = {
        createdBy: form.createdBy,
        body: form?.content?.body?.html,
        compressedValue: form?.content?.body?.compressedValue,
        status: 'live',
        name: templateBody?.name,
      }
      if (templateBody?.description) {
        body.description = templateBody?.description
      }
      if (!templateBody?.name) {
        throw 'Please add template name'
      }
      const res = await postEmailTemplate(form.vendorId, body)
      if (res?.data) {
        toast('Email saved as template successfuly', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw err
    }
  }

  const handleSubject = (subject) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        subject,
      },
    })
  }

  const handleHtml = (html, compressedValue) =>
    setForm({
      ...form,
      content: {
        ...form?.content,
        body: {
          ...form?.content?.body,
          html,
          compressedValue,
        },
      },
    })

  const handleTemplate = (e) =>
    setTemplateBody({
      ...templateBody,
      [e.target.id]: e.target.value,
    })

  const fetchTemplates = () => {
    dispatch(
      getEmailTemplates(form?.vendorId, {
        limit,
        skip: 0,
      })
    )
  }

  const onTemplateSelect = (template) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        body: {
          ...form?.content?.body,
          html: template?.body,
          compressedValue: template?.compressedValue,
        },
      },
    })
    setIsPopOpen(false)
  }

  const onPreview = async () => {
    try {
      if (isPreview || !form?._id) {
        return setIsPreview(!isPreview)
      }
      const updateRes = await updateCampaign(form._id, {
        vendorId: form.vendorId,
        status: form.status,
        content: {
          subject: cloneDeep(form?.content?.subject),
          body: cloneDeep(form?.content?.body),
        },
      })
      if (updateRes?.data) {
        setIsPreview(!isPreview)
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <>
      <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
        Message
      </Text>
      <FlexBox gap={spacing.xl} justify="space-between">
        <Input
          containerMargin="0"
          variant="input"
          label="Subject"
          placeholder="Enter Subject"
          id="subject"
          value={form?.content?.subject}
          onChange={(e) => handleSubject(e.target.value)}
          inputWidth="400px"
        />
        {form?._id && (
          <FlexBox gap="10px" align="flex-end" mr="100px">
            <Input
              variant="input"
              containerMargin="0"
              label="Enter Test Email"
              placeholder="xyz@test.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputWidth="400px"
              toolTip="Note: You can send a test mail to only one e-mail ID."
            />
            <$PublishButton
              noBorder
              mb="12px"
              fontSize="12px"
              variant="textualPrimary"
              onClick={sendTest}
            >
              Send Test Email
            </$PublishButton>
          </FlexBox>
        )}
      </FlexBox>
      <Div width="100%" height="670px">
        {!isPopOpen &&
          (isPreview ? (
            <EditorPreview
              onPreview={onPreview}
              htmlView={EMAIL_PREVIEW(
                form?.content?.body?.html || '-',
                form?.content?.body?.variables
              )}
            />
          ) : (
            <HtmlEditor
              compressedValue={form?.content?.body?.compressedValue}
              variables={form?.content?.body?.variables}
              handleHtml={handleHtml}
              onImportTemplate={() => setIsPopOpen(true)}
              onSaveTemplate={() => setIsTemplatePopOpen(true)}
              onPreview={onPreview}
            />
          ))}
      </Div>
      <PopupContainer
        isPopOpen={isTemplatePopOpen}
        setIsPopOpen={setIsTemplatePopOpen}
        heading="Templates"
        onSubmitClick={createEmailTemplate}
      >
        <Input
          containerMargin="0"
          variant="input"
          label="Template Name"
          placeholder="Enter Template Name"
          id="name"
          value={templateBody?.name || ''}
          onChange={handleTemplate}
          inputWidth="400px"
        />
        <Input
          containerMargin="0"
          variant="textarea"
          label="Template Description"
          placeholder="Enter Template Description"
          id="description"
          value={templateBody?.description || ''}
          onChange={handleTemplate}
          inputWidth="400px"
        />
      </PopupContainer>
      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Email Templates"
      >
        <Div height="480px" overflow="auto" mb={spacing.l}>
          {emailTemplates.length == 0 && (
            <Text align="center" type="main">
              No Email Templates Found.
            </Text>
          )}
          <FlexBox gap="4%" wrap="wrap">
            {emailTemplates?.map((temp) => (
              <Div
                key={temp._id}
                width="48%"
                mb={spacing.l}
                type="button"
                onClick={() => onTemplateSelect(temp)}
              >
                <Div
                  height="200px"
                  overflow="hidden"
                  style={{
                    border: `1px solid ${colors.primary.default}`,
                    borderRadius: borderRadius.default,
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: temp.body }} />
                </Div>
                <Text align="center" type="main">
                  {temp.name}
                </Text>
              </Div>
            ))}
          </FlexBox>
        </Div>
        {limit <= emailTemplates?.length && (
          <FlexBox justify="center">
            <Button
              fontSize="12"
              onClick={() => setLimit(limit + 10)}
              variant="primary"
            >
              Show more
            </Button>
          </FlexBox>
        )}
      </PopupContainer>
    </>
  )
}
