import React, { useState } from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $Block, $FlexBox } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'
import { PropertyBar } from 'builder/editor/common/PropertyBar'
import UploadModal from 'components/Content/autoEngagement/components/UploadModal'
import { StyledInput, UploadButton } from 'builder/editor/common/Common.styled'
import { UploadBox } from 'components/Content/autoEngagement/components/MediaUpload'

export const MediaSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)

  const setMedia = ({ key, fileLocation, name, fileType }) => {
    setProp((props) => {
      props.link = `$asset[${key}]`
      props.publicLink = fileLocation
      props.fileName = name
      props.fileType = fileType
    }, 300)
    setIsPopOpen(false)
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Media"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <PropertyBar
        label="Media Text"
        craftProps={props}
        setProp={setProp}
        propertyName="content"
        justify="space-between"
        margin="8px 0px"
      />

      <$FlexBox padding="0 0 4px" justify="space-between">
        <span>Select Media</span>
        <div>
          <UploadButton onClick={() => setIsPopOpen(true)}>Media</UploadButton>
        </div>
      </$FlexBox>

      <StyledInput
        readOnly
        style={{ width: '100%', background: '#D5D5D5' }}
        value={props.fileName}
      />
      <$Block padding="4px" />

      {props.publicLink && (
        <UploadBox
          uploadWidth="375px"
          fileType={props.fileType}
          src={props.publicLink}
        />
      )}

      <UploadModal
        showMedias={['image', 'gif', 'video', 'pdf']}
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        setMedia={setMedia}
        uploadedFrom="email"
      />
    </$Block>
  )
}
