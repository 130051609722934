import React from 'react'
import PropTypes from 'prop-types'

import { $IconBlock, $IconText } from './ProductIcon.styled'

/**
 * @description Common component to display product icon based on the product title
 * @param {String} width icon width
 * @param {String} height icon height
 * @param {String} bgColor background color
 * @param {String} color text color
 * @param {String} variant size style
 * @param {String} rounded rounded corners or not
 * @param {String} productTitle product title
 */

const ProductIcon = ({
  width,
  height,
  bgColor,
  color,
  variant,
  rounded,
  productTitle,
}) => {
  const getIconText = () => {
    if (!productTitle) return ''
    const strValues = productTitle.match(/\b(\w)/g)
    if (strValues.length > 2) {
      strValues.length = 2
    }
    return strValues.join('').toUpperCase()
  }
  return (
    <$IconBlock
      width={width}
      height={height}
      bgColor={bgColor}
      rounded={rounded}
    >
      <$IconText color={color} variant={variant}>
        {getIconText()}
      </$IconText>
    </$IconBlock>
  )
}

ProductIcon.prototype = {
  productTitle: PropTypes.string.isRequired,
}

export default ProductIcon
