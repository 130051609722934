import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import swal from 'sweetalert'
import { modifyCampaign } from 'redux/actions/advertActions'
import { connect } from 'react-redux'
import validateUrl from 'utils/urlValidator'

class ViewCampaign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adRedirectUrl: null,
      isEditing: false,
      newAdRedirectUrl: '',
      signedUrl: null,
    }
    this.closeModal = this.closeModal.bind(this)
    this.onDeleteClick = this.onDeleteClick.bind(this)
    this.onActivateClick = this.onActivateClick.bind(this)
    this.onDeactivateClick = this.onDeactivateClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
  }

  componentDidMount() {
    this.props.adRedirectUrl &&
      this.setState({
        adRedirectUrl: this.props.adRedirectUrl,
        newAdRedirectUrl: this.props.adRedirectUrl,
      })
  }

  closeModal() {
    this.props.viewCampaignPopup()
  }

  onDeleteClick() {
    this.props.onDelete(this.props.campaignId)
  }

  onActivateClick() {
    this.props.onActive({
      campaignId: this.props.campaignId,
      campaignName: this.props.name,
      adRedirectUrl: this.state.newAdRedirectUrl || this.props.adRedirectUrl,
      imageUrl: this.props.imageUrl,
    })
  }

  onDeactivateClick() {
    this.props.onDeactive(this.props.campaignId)
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  handleEditClick = () => this.setState({ isEditing: true })

  async handleSaveClick() {
    if (this.state.adRedirectUrl && !validateUrl(this.state.adRedirectUrl)) {
      swal('Error', 'Incorect Url!', 'error')
    } else {
      const result = await modifyCampaign({
        campaignId: this.props.campaignId,
        adRedirectUrl: this.state.adRedirectUrl,
      })

      if (result.code) {
        this.setState({
          newAdRedirectUrl: this.state.adRedirectUrl,
          isEditing: false,
        })
      }
    }
  }

  render() {
    const activateStyle = { backgroundColor: '#1dd410' }

    return (
      <div className="viewCampaign">
        <Popup
          className={'viewCampaignPopup'}
          open={this.props.open}
          onClose={this.closeModal}
        >
          <div className="popupInner">
            <div className="viewCampaignHeading">{this.props.name}</div>
            {this.props.imageUrl ? (
              <img
                className="viewCampaignImage"
                src={this.props.imageUrl}
                alt=""
              />
            ) : null}

            <div className="adRedirectUrl">
              <h6 htmlFor="adRedirectUrl">Ad Redirect Url:</h6>
              {!this.state.isEditing ? (
                <>
                  <div
                    onClick={() =>
                      this.state.newAdRedirectUrl || this.props.adRedirectUrl
                        ? window.open(
                            this.props.adRedirectUrl &&
                              this.state.newAdRedirectUrl
                          )
                        : null
                    }
                    className="middle-elem"
                  >
                    {this.state.newAdRedirectUrl ||
                      this.props.adRedirectUrl ||
                      'No Redirection Url Set'}
                  </div>
                  {
                    //  <div className="campaignBtn" onClick={this.handleEditClick}>
                    //    <i className="fas fa-edit"></i>
                    // </div>
                  }
                </>
              ) : (
                <>
                  <input
                    name="adRedirectUrl"
                    className="campaignInput middle-elem"
                    onChange={this.handleChange}
                    placeholder="Ad Redirect Url"
                  />
                  <div className="campaignBtn" onClick={this.handleSaveClick}>
                    <i className="fas fa-save"></i>
                  </div>
                </>
              )}
            </div>
            <div className="activeAndDeleteButton">
              <button
                className={'campaignActivateButton'}
                style={activateStyle}
                onClick={this.onActivateClick}
              >
                Activate
              </button>
              {!this.props.isActive ? (
                <div
                  style={
                    this.props.isActive
                      ? {
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }
                      : null
                  }
                  className="campaignBtn delete"
                  onClick={this.onDeleteClick}
                >
                  <i className="fas fa-trash"></i>
                </div>
              ) : (
                <div className="campaignBtn delete" />
              )}
            </div>
          </div>
        </Popup>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(ViewCampaign)
