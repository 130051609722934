import styled from 'styled-components'
import { spacing } from 'design-system'

const $DetailsBlock = styled.div`
  padding: ${spacing.xl};
  background-color: ${({ theme }) => theme.colors.white};
`

const $List = styled.ul`
  padding: 0;
  list-style: none;
  width: ${({ width }) => width || '50%'};
`

const $ListItem = styled.li`
  display: flex;
  margin-bottom: ${spacing.s};
`

export { $DetailsBlock, $List, $ListItem }
