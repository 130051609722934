import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { useTheme, css } from 'styled-components'
import PropTypes from 'prop-types'

import { TooltipIcon, spacing, borderRadius } from 'design-system'
import { FlexBox } from 'styledComponent'

/**
 * @description common tooltip component
 * @param {String} content - tooltip content
 * @param {*} icon - icon component
 */
const Tooltip = ({ content, icon, ...rest }) => {
  const theme = useTheme()

  if (!content) return null
  return (
    <$Icon data-tip={content} align="center" {...rest}>
      {icon ? icon : <TooltipIcon fill="hsl( 0,0%,45%)" />}
      <$Tooltip backgroundColor={theme.colors.dark} multiline />
    </$Icon>
  )
}

Tooltip.prototype = {
  content: PropTypes.string.isRequired,
}

export default Tooltip

const $Icon = styled(FlexBox)`
  margin-left: ${spacing.m};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`

const $Tooltip = styled(ReactTooltip)`
  border-radius: ${borderRadius.small};
  max-width: 300px;
  white-space: initial;
`
