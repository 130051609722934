import React from 'react'
import { useDispatch } from 'react-redux'

import CustomPopup from 'components/Content/CustomPopup'
import { useSelectStore, useStoreFetch } from 'hooks'
import { Text } from 'components/Common/Text'
import { spacing } from 'design-system'
import { Checkbox } from 'components/Common'
import { FilterFooter, FilterHeader } from 'components/Common/FIlterComponents'
import {
  getFilterOptions,
  setAppliedFilter,
} from 'redux/actions/productAnalyticsActions'

import { $FlexBox, $FlexItem } from '../../ProductAnalytics.styled'
import { $FlexList, $FlexListItem } from '../AllProducts.styled'
import {
  $FilterMenu,
  $FilterMenuItem,
  $FilterMenuValue,
} from './ProductsFilter.styled'

/**
 * @description all products filter popup
 */
const ProductsFilter = React.forwardRef(({ action }, ref) => {
  const [filter, setFilter] = React.useState(null)
  const dispatch = useDispatch()

  const { data: productsFilterOptions } = useStoreFetch(
    getFilterOptions,
    'productAnalytics.productsFilterOptions'
  )

  const { data: appliedProductsFilter } = useSelectStore(
    'productAnalytics.appliedProductsFilter'
  )

  const handleFilter = () => {
    if (_getFilterCount()) {
      const filters = { ...filter }

      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && !filters[key].length) {
          delete filters[key]
        }
      })

      dispatch(setAppliedFilter(filters))
      action()
    }
    ref.current.closeModal()
  }

  const handleClearFilter = () => {
    dispatch(setAppliedFilter(null))
    action()
    ref.current.closeModal()
  }

  const _formatUamOptions = () => {
    const options = productsFilterOptions?.uam?.map(({ value, _id }) => ({
      label: value,
      value: _id,
    }))
    return options || []
  }

  const onCheckboxChange = (e, changeFor) => {
    const valueArray = filter?.[changeFor] || []
    if (e.target.checked) {
      valueArray.push(e.target.value)
    } else {
      const valueArrayIndex = valueArray.findIndex(
        (value) => value === e.target.value
      )
      if (valueArrayIndex > -1) {
        valueArray.splice(valueArrayIndex, 1)
      }
    }

    setFilter((state) => ({
      ...state,
      [changeFor]: valueArray,
    }))
  }

  const onSelectChange = (value, changeFor) => {
    setFilter((state) => ({
      ...state,
      [changeFor]: value,
    }))
  }

  const onInputChange = (e, field) => {
    setFilter({
      ...filter,
      [field]: Number(e.target?.value),
    })
  }

  const _getFilterCount = () => {
    let count = 0
    if (filter?.taxGroup) {
      count++
    }
    for (const key in filter) {
      if (Array.isArray(filter[key]) && filter[key].length) {
        count++
      } else if (Object.values(filter[key]).length) {
        count++
      }
    }
    return count
  }

  const _getFilterValue = (filterItem) => {
    if (Array.isArray(filterItem) && filterItem.length) {
      return filterItem.length
    }

    if (filterItem && Object.values(filterItem).length) {
      return true
    }

    return false
  }

  React.useEffect(() => {
    setFilter(appliedProductsFilter || {})
  }, [appliedProductsFilter])

  const uamOptions = _formatUamOptions()

  return (
    <CustomPopup
      className={'filter-modal'}
      ref={ref}
      headerComponent={true}
      heading={
        <FilterHeader
          ref={ref}
          filter={filter || {}}
          filterCount={_getFilterCount()}
        />
      }
      footer={
        <FilterFooter
          ref={ref}
          filter={filter || {}}
          handleClearAll={handleClearFilter}
          handleFilter={handleFilter}
        />
      }
    >
      <FilterTabs>
        <div
          label="Categories"
          value={_getFilterValue(filter?.categories)}
          hasValue={_getFilterValue(filter?.categories)}
        >
          <Text variant="p">Categories</Text>
          <$FlexList direction="column" align="flex-start">
            {productsFilterOptions?.categories?.map((category) => (
              <$FlexListItem key={category._id} mb={spacing.m}>
                <Checkbox
                  id={category._id}
                  onCheckboxChange={(e) => onCheckboxChange(e, 'categories')}
                  isChecked={
                    filter?.categories?.findIndex(
                      (value) => value === category._id
                    ) > -1
                  }
                  value={category._id}
                >
                  {category.name}
                </Checkbox>
              </$FlexListItem>
            ))}
          </$FlexList>
        </div>
        {/* <div label="UAM" hasValue={_getFilterValue(filter?.uam)}>
          <Text variant="p">Unit of Measurement (UoM):</Text>
          <Input
            variant="single-select"
            options={uamOptions}
            value={filter?.uam || null}
            onChange={(selectedValue) => onSelectChange(selectedValue, 'uam')}
            placeholder=""
            inputWidth="200px"
            margin={`0 ${spacing.xxl} 0 0`}
          />
        </div>
        <div
          label="Tax Group"
          value={filter?.taxGroup}
          hasValue={filter?.taxGroup}
        >
          <Text variant="p">Tax Group</Text>
          <Input
            variant="input"
            type="number"
            id="taxGroup"
            inputWidth="200px"
            min={0}
            max={100}
            value={filter?.taxGroup}
            onChange={(e) => onInputChange(e, 'taxGroup')}
          />
        </div> */}
      </FilterTabs>
    </CustomPopup>
  )
})

export default ProductsFilter

/**
 * @description Filter menu and content in a tab fashion component
 */
const FilterTabs = (props) => {
  const [activeFilter, setActiveFilter] = React.useState(null)
  const children = React.Children.toArray(props.children)

  React.useEffect(() => {
    setActiveFilter(children[0].props.label)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <$FlexBox minHeight="350px">
      <$FilterMenu>
        {children.map(({ props: { label, value, hasValue } }) => (
          <Tab
            key={label}
            label={label}
            active={activeFilter}
            clickHandler={setActiveFilter}
            value={value}
            hasValue={hasValue}
          />
        ))}
      </$FilterMenu>
      <$FlexItem flexBasis="70%" padding={spacing.l}>
        {children.map((content, index) =>
          content.props.label === activeFilter ? (
            <React.Fragment key={index}>
              {content.props.children}
            </React.Fragment>
          ) : null
        )}
      </$FlexItem>
    </$FlexBox>
  )
}

const Tab = ({ clickHandler, label, value, active, hasValue }) => {
  return (
    <$FilterMenuItem
      onClick={() => clickHandler(label)}
      active={active === label}
    >
      <Text
        variant="p"
        type={hasValue || active === label ? 'primary' : 'grey'}
      >
        {label}
      </Text>
      {value && (
        <$FilterMenuValue>
          <span>{value}</span>
        </$FilterMenuValue>
      )}
    </$FilterMenuItem>
  )
}
