import React, { Component } from "react";
import Popup from "reactjs-popup";
import StorePopupModal from "./StorePopupModal";
import CompanyPopupModal from "./CompanyPopupModal";
import StoreAddPopupModal from "./StoreAddPopupModal";
import CompanyAddPopupModal from "./CompanyAddPopupModal";

class Popups extends Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.openPopup };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ open: true });
    this.props.onClick();
  }
  closeModal() {
    this.setState({ open: false });
    this.props.onClick();
  }
  render() {
    return (
      <div>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className="modal1">
            <img
              className="close"
              onClick={this.closeModal}
              src={require("./images/close.png")}
            />

            {this.props.popupType === "company" ? (
              <CompanyPopupModal pID={this.props.companyPopupId} />
            ) : null}
            {this.props.popupType === "store" ? (
              <StorePopupModal pID={this.props.storePopupId} />
            ) : null}
            {this.props.popupType === "storeAdd" ? (
              <StoreAddPopupModal />
            ) : null}
            {this.props.popupType === "companyAdd" ? (
              <CompanyAddPopupModal />
            ) : null}
          </div>
        </Popup>
      </div>
    );
  }
}

export default Popups;
