import React from 'react'

import { CampaignDetails } from '../components'
import { CampaignSettingsWithATOC } from '../components/CampaignSettingsWithATOC'
import AfterSurvey from './AfterSurvey'
import { SIBBuilder } from './SIBBuilder'
import { SIBPreview } from './SIBPreview'
import SurveySelect from './SurveySelect'

export const SIBCampaign = ({ active, form, setForm }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          campaignType="'Add Survey Button'"
          form={form}
          setForm={setForm}
        />
      )

    case 'Select Survey':
      return <SurveySelect form={form} setForm={setForm} />

    case 'Survey Button Builder':
      return <SIBBuilder form={form} setForm={setForm} />

    case 'After Survey':
      return <AfterSurvey form={form} setForm={setForm} />

    case 'Audience':
      return <CampaignSettingsWithATOC form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <SIBPreview form={form} />

    default:
      return null
  }
}
