import React, { useState } from 'react'
import {
  // BillResendLeftPart,
  // BillResendDialpad,
  // LeftInput,
  LeftInputClear,
  LeftDialPad,
  LeftDialPadKey,
} from '../style'
import { useSelector } from 'react-redux'
import shareIcon from '../../../../images/share.svg'
import rightClick from '../../../../images/done-tick.svg'
import { resendBill } from '../../../../redux/actions/bills'
import { useToasts } from 'components/Common'

const DialKey = (props) => {
  return props.arrayValues.map((data, i) => {
    return (
      <LeftDialPadKey
        onClick={() => {
          props.onClick(data)
        }}
        key={i}
        data={data}
      >
        {data}
      </LeftDialPadKey>
    )
  })
}

const BillResendLeft = ({
  getNumber,
  isCashier,
  user,
  userPhone,
  resendOnEmail,
  onEmailChange,
  email,
  brandName,
  shortId,
}) => {
  const [contact, setContact] = useState({ contact: '' })
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const vendorId = useSelector((state) => state.vendorIds.selectedVendor.value)
  const isMirajCashier = // ny cinemas
    ['3qp6rkl1kj2b86', '7mfv4ll1lpec9a', '4w0h5klky7ydhv'].includes(vendorId) &&
    isCashier
  const isMiraj = [
    '3qp6rkl1kj2b86',
    '7mfv4ll1lpec9a',
    '4w0h5klky7ydhv',
  ].includes(vendorId)
  const { toast } = useToasts()

  const handleResend = async (_) => {
    if (isSubmitted) return

    const userBody = {}

    if (resendOnEmail) {
      if (_validateEmail()) {
        userBody.email = email
      } else {
        return toast('Please enter a valid email', 'error')
      }
    } else {
      userBody.user = isCashier && !isMirajCashier ? userPhone : user
    }

    try {
      const res = await resendBill(
        {
          ...userBody,
          brandName,
          shortId,
        },
        {
          loader: true,
        }
      )
      if (res.code) {
        setIsSubmitted(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const _validateEmail = () => {
    if (!email) return false
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email)
  }

  const handleInputChange = (data) => {
    if (data !== null) {
      if (data === 'Clear') {
        setContact({ contact: '' })
        getNumber(contact.contact)
      } else {
        if (data.target) {
          const patt = new RegExp(`[0-9]{${data.target.value.length}}`)
          if (patt.test(data.target.value)) {
            setContact({
              [data.target.name]: data.target.value,
            })
            getNumber(data.target.value)
          }
        } else {
          if (contact.contact.length < 10) {
            setContact({
              contact: contact.contact + data,
            })
            getNumber(contact.contact + data)
          }
        }
      }
    }
  }

  const renderDialPad = () => {
    return (
      <div className="billResend_left_input_dialpad_group">
        <input
          type="text"
          className="billResend_left_input_input"
          value={contact.contact}
          onChange={handleInputChange}
          name="contact"
          maxLength="10"
          placeholder="Enter 10-digit mobile number"
        />
        <LeftDialPad>
          <DialKey
            arrayValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, null]}
            value={1}
            onClick={handleInputChange}
          />
        </LeftDialPad>
      </div>
    )
  }
  if (isCashier && !isMiraj) {
    return (
      <div className="billResend_left">
        <div className="billResend_left_header">Resend bill to {userPhone}</div>
        <div className="resendButton sendBill">
          <div onClick={handleResend}>
            <img src={shareIcon} />
            {isSubmitted ? ' BILLME SENT' : ' SEND BILLME'}
          </div>
        </div>
      </div>
    )
  } else if (isCashier && isMiraj) {
    return (
      <div className="billResend_left">
        <div className="billResend_left_header">Resend bill</div>
        {renderDialPad()}
        <div className="resendButton sendBill">
          <div onClick={handleResend}>
            <img src={shareIcon} />
            {isSubmitted ? ' BILLME SENT' : ' SEND BILLME'}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!isSubmitted ? (
        <div className="billResend_left">
          <div
            style={!!resendOnEmail ? { paddingTop: '150px' } : {}}
            className="billResend_left_header"
          >
            Resend Bill to {!resendOnEmail ? ' Phone' : ' Email'} :
          </div>
          {!resendOnEmail ? (
            renderDialPad()
          ) : (
            <div className="billResent_email_block">
              <input
                value={email}
                onChange={onEmailChange}
                placeholder="Enter E-mail address"
              />
            </div>
          )}
          <div className="resendButton sendBill">
            <div onClick={handleResend}>
              <img src={shareIcon} /> Send BillMe
            </div>
          </div>
        </div>
      ) : (
        <div className="billResend_right">
          <div className="billResend_right_inner" onClick={handleResend}>
            <img src={isSubmitted ? rightClick : shareIcon} alt="" />
            <br></br>
            <span className="billResend_right_inner_headline">
              {isSubmitted ? 'BILLME SENT' : 'SEND BILLME'}
            </span>

            {isSubmitted && (
              <>
                <span className="billResend_right_inner_contact">To</span>
                <span className="billResend_right_inner_contact">
                  {resendOnEmail
                    ? email
                    : isCashier && !isMirajCashier
                    ? userPhone
                    : user}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default BillResendLeft
