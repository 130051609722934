/**
 * hooks.js
 *
 * all the custom hooks used in the application
 * resides in this file.
 */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import UrlUtils from 'utils/urlUtil'
import {
  setSelectedVendor,
  setVendorBrands,
  setSelectedBrand,
} from 'redux/actions/vendorActions'
import { setSelectedStore } from 'redux/actions/authActions'
import { get as axiosGet } from 'utils/axiosHandler'
import {
  setFetchNewData,
  setCAPreviousSelected,
} from 'redux/actions/consumerAnalyticsActions'

/**
 * Hook to force re-render a component for
 * cases when the component state or props
 * do not change
 *
 * Usage:
 * const forceUpdate = useForceUpdate();
 *
 * forceUpdate() // for eg., when URL changes
 */
const useForceUpdate = () => React.useState()[1]

/**
 * Hook to fetch data from end point
 * and return the fetched values with loading state.
 * @param {Function} getListData Async callback to fetch remote data
 */
const useFetch = (getData, dependencies = []) => {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])

  const fetchData = React.useCallback(async () => {
    setLoading(true)
    try {
      const reponse = await getData()
      setData(reponse)
    } catch (error) {
      // throw error
    } finally {
      setLoading(false)
    }
  }, [...dependencies]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    loading,
    data,
    fetchData,
  }
}

/**
 * Hook to dispatch fetching action
 * and return the data from the store with loading state.
 * @param {Function} getData Async callback to fetch remote data
 * @param {String} reducerKeyPath accessor key path for accessing the store values
 * @param {Boolean} disableInitialFetch disabling the initial fetching action when the hook is initialized
 */
const useStoreFetch = (
  getData,
  reducerKeyPath,
  disableInitialFetch = false
) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => get(state, reducerKeyPath))
  const [loading, setLoading] = React.useState(false)

  const fetchData = React.useCallback(
    async (fetchParams, isReFetch, ...rest) => {
      try {
        setLoading(true)
        if (isReFetch) {
          await dispatch(fetchParams())
        } else {
          await dispatch(getData(fetchParams, ...rest))
        }
      } catch (error) {
        // throw error
      } finally {
        setLoading(false)
      }
    },
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    !disableInitialFetch && fetchData()
  }, [fetchData, disableInitialFetch])

  return {
    loading,
    data,
    fetchData,
  }
}

/**
 * Hook to get modularized router methods and data points
 */
const useRouter = () => {
  const location = useLocation()
  const history = useHistory()
  const params = useParams()

  const pathname = location.pathname
  const { state = {} } = location
  const { search } = location
  const query = search ? new UrlUtils(search).parsedUrl?.query : {}

  /**
   * standardized query operations
   * @param {Object} queryObj key-value [string-string] paired query object
   * @param {Object} param2 Object containing query config to set the new query
   */
  const setQuery = (
    queryObj,
    { preservePrevState = false, pathname = location.pathname }
  ) => {
    const containedQuery = {
      ...(preservePrevState ? query : {}),
      ...queryObj,
    }

    const queryString = Object.keys(containedQuery).reduce((acc, curr) => {
      if (containedQuery[curr]) {
        const searchString = `${acc ? `${acc}&` : ''}${curr}=${
          containedQuery[curr]
        }`
        acc = searchString
      }
      return acc
    }, '')

    history.push({
      pathname,
      search: queryString,
    })
  }

  return {
    location,
    history,
    query,
    pathname,
    state,
    setQuery,
    params,
  }
}

const useLazyStoreFetch = (getData, reducerKeyPath) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()

  const data = useSelector((state) => get(state, reducerKeyPath))

  const fetchData = React.useCallback(async (fetchParams, ...rest) => {
    try {
      setLoading(true)
      await dispatch(getData(fetchParams, ...rest))
    } catch (error) {
      // throw error
    } finally {
      setLoading(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    fetchData,
  }
}

/**
 * Hook to access store
 * @param {string} reducerKeyPath key path for accessing the store values
 */
const useSelectStore = (reducerKeyPath) => {
  const data = useSelector((state) => get(state, reducerKeyPath))

  return { data }
}

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

const useVendorSelect = (
  brandDependent = false,
  defaultVendorSelect = true,
  defaultForOnlyForSingleVendor = false
) => {
  const dispatch = useDispatch()
  const {
    data: { vendorIds, selectedVendor, availableBrands },
  } = useSelectStore('vendorIds')
  const { data: stores } = useSelectStore('stores.stores')

  React.useEffect(() => {
    if (defaultVendorSelect) {
      if (!Object.keys(selectedVendor).length && vendorIds.length) {
        if (
          (defaultForOnlyForSingleVendor && vendorIds.length === 1) ||
          !defaultForOnlyForSingleVendor
        ) {
          const firstVendor = vendorIds[0]
          if (!brandDependent) setStoreOnVendorId(firstVendor.value)
          setNewSelectedVendor(firstVendor)
          if (brandDependent) {
            dispatch(
              setVendorBrands(availableBrands, firstVendor.value, stores)
            )
          }
        }
      }

      if (brandDependent && selectedVendor?.value) {
        dispatch(setVendorBrands(availableBrands, selectedVendor.value, stores))
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setStoreOnVendorId = (selectedVendorId) => {
    let storeIds = []

    const storeIdArr = Object.keys(stores)

    for (let storeId of storeIdArr) {
      if (stores[storeId].vendorId === selectedVendorId) storeIds.push(storeId)
    }

    dispatch(setSelectedStore({ storeIds, vendorId: selectedVendorId }))
  }

  const setNewSelectedVendor = (values) => dispatch(setSelectedVendor(values))

  const handleVendorChange = (values) => {
    const { value: selectedVendorId } = values

    // if (!brandDependent) setStoreOnVendorId(selectedVendorId)
    setStoreOnVendorId(selectedVendorId)
    setNewSelectedVendor(values)
    //setting vendor specific brands and emptying selected brand
    if (brandDependent) {
      dispatch(setVendorBrands(availableBrands, selectedVendorId, stores))
      dispatch(setSelectedBrand(null))
    }
  }

  return {
    setStoreOnVendorId,
    setNewSelectedVendor,
    handleVendorChange,
  }
}

/**
 * previous props hook
 * @param {*} value
 */
const usePrevious = (value) => {
  const ref = React.useRef()
  const updatePrevious = (updatedValue) => {
    ref.current = updatedValue
  }
  React.useEffect(() => {
    ref.current = value
  })
  return [ref.current, updatePrevious]
}

// TODO: Refactor
/* const useFetchDataOnSelectedChange = (fetchData, { ...dependencies }) => {
  const dispatch = useDispatch()

  const { data: shouldFetchNewData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewData'
  )
  console.log("All objects:--", dependencies)
  
  React.useEffect(() => {
    const fetchDataAndCheckPreviousSelected = async (_) => {
      if (shouldFetchNewData) {
        fetchData()
        await dispatch(setFetchNewData(false))
      }

      // await dispatch(setCAPreviousSelected({ ...all }))
    }

    fetchDataAndCheckPreviousSelected()
  },[...dependencies, shouldFetchNewData])
} */

const useInterval = (callback, delay) => {
  const intervalId = React.useRef(null)
  const savedCallback = React.useRef(callback)
  React.useEffect(() => {
    savedCallback.current = callback
  })
  React.useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay)
      return () => window.clearInterval(intervalId.current)
    }
  }, [delay])
  return intervalId.current
}

const useDebounce = (value, delay = 750) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}

const useDebouncedEffect = (effect, deps = [], delay = 750) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
  }, [...deps, delay])
}

const getLocation = async (countryName = 'India', stateName) => {
  try {
    const data = await axiosGet('/location/country-state-city', {
      country: countryName,
      state: stateName,
    })
    const {
      data: { states, cities, countries },
    } = data

    return {
      countryOptions: countries.map((country) => ({
        ...country,
        label: country?.name,
        value: country?.name,
      })),
      stateOptions: states.map((state) => ({
        ...state,
        label: state?.name,
        value: state?.name,
      })),
      cityOptions: cities.map((city) => ({
        ...city,
        label: city?.name,
        value: city?.name,
      })),
    }
  } catch {
    return {
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
    }
  }
}

/**
 * Hook to make use of dark mode in the application
 * also it can detect and change the mode
 * based on user's device preferences
 *
 * @returns {Array} [Boolean, Function]
 *
 * @example: const [isDarkMode, setDarkMode] = useDarkTheme()
 */
const useDarkTheme = () => {
  const [isDarkMode, setDarkMode] = React.useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )

  React.useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const colorScheme = event.matches ? 'dark' : 'light'
        setDarkMode(colorScheme === 'dark')
      })

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {})
    }
  }, [])

  return [isDarkMode, setDarkMode]
}

export {
  useForceUpdate,
  useFetch,
  useStoreFetch,
  useRouter,
  useSelectStore,
  useOnClickOutside,
  useVendorSelect,
  useLazyStoreFetch,
  usePrevious,
  useInterval,
  useDebounce,
  useDebouncedEffect,
  getLocation,
  // useFetchDataOnSelectedChange,
  useDarkTheme,
}
