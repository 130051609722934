import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, connect } from 'react-redux'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import swal from 'sweetalert'
import { Filter, FilterHeader, DateFilter } from '../../Common'
import FilterFooter from '../manageStores/StoreFilter/Footer'
import CustomPopup from '../CustomPopup'
import {
  setConsumerAnalyticsFilters,
  setConsumerAnalyticsRememberFilters,
  getConsumerData,
  exportConsumerAnalytics,
} from '../../../redux/actions/oldCAnalyticsActions'
import { initialState } from '../../../redux/reducers/consumerAnalyticsReducer'
import { useToasts } from 'components/Common'

const moment = extendMoment(originalMoment)

const options = [
  {
    label: 'Phone Number',
    value: 'user',
  },
  {
    label: 'Email',
    value: 'email',
  },
]

const Consumer = React.forwardRef((props, ref) => {
  const [filters, setFilters] = React.useState(initialState.filters)
  const [rememberFilters, setRememberFilters] = React.useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false)

  const dispatch = useDispatch()
  const { toast } = useToasts()

  const setPropToFilter = () => {
    const filtersCopy = cloneDeep(props.filters)

    if (filtersCopy?.date?.value) {
      const dateRange = originalMoment.range(
        moment(filtersCopy.date.value.start).clone().startOf('day'),
        moment(filtersCopy.date.value.end).clone().endOf('day')
      )
      filtersCopy.date.value = dateRange
    }

    setFilters(filtersCopy)
    setRememberFilters(props.rememberFilters)
  }

  const handleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen)
  }

  const onSelectChange = (value) => {
    const copyFilter = cloneDeep(filters)
    copyFilter['groupBy'].value = value?.value
    setFilters(copyFilter)
  }

  const onEmailChange = (val) => {
    const copyFilter = cloneDeep(filters)
    copyFilter['emailList'].value = val
    setFilters(copyFilter)
  }

  const handleCheckbox = (e, key) => {
    const copyFilter = cloneDeep(filters)
    copyFilter[key].isActive = !copyFilter[key].isActive
    setFilters(copyFilter)
  }

  const handleRememberFilters = () => {
    setRememberFilters(!rememberFilters)
  }

  const handleResetFilters = () => {
    // TODO: Dispatch an action
    setFilters(initialState.filters)
  }

  const onDateSelect = (value) => {
    const diff = value.start?.diff(value.end, 'days')

    if (props.isExportFilter && Math.abs(diff) > 31) {
      toast('Only 31 days range can be selected', 'error')
      return null
    }

    const copyFilters = cloneDeep(filters)

    copyFilters.date.value = value

    setFilters(copyFilters)
    setIsDatePickerOpen(false)
  }

  const _checkExportDates = (value, byPass) => {
    if (byPass) return true

    if (!value || !value.start || !value.end) return false

    const date = {
      start: moment(value.start),
      end: moment(value.end),
    }

    const diff = date.start?.diff(date.end, 'days')

    if (Math.abs(diff) > 31) {
      toast('Only 31 days range can be selected', 'error')
      return false
    } else {
      return true
    }
  }

  const onSetFilters = async (_e, discardFilter) => {
    if (props.isExportFilter) {
      if (
        filters.date.value || discardFilter
          ? _checkExportDates(filters?.date?.value, discardFilter)
          : true
      ) {
        if (
          filters.emailList.isActive &&
          filters.emailList.value?.length === 0
        ) {
          toast('Add atleast one email', 'error')
          return
        }
        const exportData = await exportConsumerAnalytics({
          includeFilter: !discardFilter,
          filters: { ...(discardFilter ? {} : cloneDeep(filters)) },
        })

        if (exportData.code) {
          swal(exportData.message || '')
        } else {
          toast(exportData.message || '')
        }

        setPropToFilter()
      } else {
        return
      }
    } else {
      const copyFilter = cloneDeep(filters)

      dispatch(setConsumerAnalyticsFilters(copyFilter))
      dispatch(setConsumerAnalyticsRememberFilters(rememberFilters))

      dispatch(
        getConsumerData({
          domainLength: 15,
          fetchCount: 15,
        })
      )
    }
    ref.current.closeModal()
  }

  React.useEffect(() => {
    // setPropToFilter()
  }, [])

  return (
    <>
      <CustomPopup
        className={'filter-modal'}
        headerComponent={true}
        heading={
          <FilterHeader
            heading={props.isExportFilter ? 'Export filters' : 'Filters'}
          />
        }
        footer={
          <FilterFooter
            onCancel={() => ref.current.closeModal()}
            onSetFilters={onSetFilters}
            onResetFilters={handleResetFilters}
            onSetRememberFilters={handleRememberFilters}
            rememberFilters={rememberFilters}
            isExport={props.isExportFilter}
            disableExportWithoutFilter
          />
        }
        ref={ref}
      >
        <div className="filter-modal-body">
          <DateFilter
            title="Visit Date"
            isActive={filters?.date?.isActive}
            isDatePickerOpen={isDatePickerOpen}
            handleCheckboxClick={handleCheckbox}
            onDatePickerClick={handleDatePicker}
            startDate={moment(filters?.date?.value?.start).format('DD-MM-YYYY')}
            endDate={moment(filters?.date?.value?.end).format('DD-MM-YYYY')}
            dateValue={filters?.date?.value}
            onDateSelect={onDateSelect}
            checkboxMargin="0px 10px 0px 0px"
            borderColor="#0081ED"
            isTimeAbsent
          />
          <Filter
            isFilterActive={filters.groupBy?.isActive}
            borderColor="#0081ED"
            filterLabel="View By :"
            type="select"
            selectOption={options}
            selectValue={{
              label:
                filters.groupBy?.value === 'user' ? 'Phone Number' : 'Email',
              value: filters.groupBy?.value,
            }}
            onSelectChange={onSelectChange}
            onCheckboxClick={(e) => handleCheckbox(e, 'groupBy')}
            filterId="groupBy"
          />
          {props.isExportFilter && (
            <Filter
              filterId="emailList"
              isFilterActive={filters.emailList?.isActive}
              borderColor="#0081ED"
              filterLabel="Email List :"
              placeholder="Enter email Ids"
              type="tags"
              inputValue={filters.emailList?.value || []}
              onValueChange={onEmailChange}
              onCheckboxClick={(e) => handleCheckbox(e, 'emailList')}
            />
          )}
        </div>
      </CustomPopup>
    </>
  )
})

const mapStatesToProps = ({ cAnalyticsOld }) => ({
  filters: cAnalyticsOld.filters,
  rememberFilters: cAnalyticsOld.rememberFilters,
})

export const ConsumerFilter = connect(mapStatesToProps, null, null, {
  forwardRef: true,
})(Consumer)
