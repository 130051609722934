import React from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken =
  'pk.eyJ1IjoiYWthc2hiaWxsbWUiLCJhIjoiY2tobTE5NHVrMGlrazJycnR6OWVhenY0cyJ9.Z3QF3nkq0JHbuGZcHDzP3g'

const INITIAL_SATE = {
  lng: 73.205759,
  lat: 19.000914,
  zoom: 4,
}

/**
 * @description common heat map component to render map with markers
 * @param {String} width width of the map container
 * @param {String} height height of the map container
 * @param {Object} defaultMapState default state to overwrite initialized map state
 * @param {Array<Object>} markers array of markers with label and coordinates in [lng, lat] fashion
 */
const HeatMap = ({ height, width, markers, defaultMapState }) => {
  const [mapState, setMapState] = React.useState({
    ...INITIAL_SATE,
    ...defaultMapState,
  })

  const mapContainerRef = React.useRef()

  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: markers?.length
        ? markers[0].coordinates
        : [mapState.lng, mapState.lat],
      zoom: mapState.zoom,
    })

    map.on('move', () => {
      setMapState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      })
    })

    markers.forEach((mark) => {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setText(mark.label)
        .addTo(map)

      new mapboxgl.Marker()
        .setLngLat(mark.coordinates)
        .addTo(map)
        .setPopup(popup)
    })
  }, [markers]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <$Block width={width} height={height}>
      <$MapContainer ref={mapContainerRef} />
    </$Block>
  )
}

HeatMap.defaultProps = {
  width: '100%',
  height: '400px',
  markers: [],
}

HeatMap.prototype = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      coordinates: PropTypes.array,
    })
  ).isRequired,
}

export default HeatMap

const $MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .mapboxgl-control-container {
    display: none;
  }
`
const $Block = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`
