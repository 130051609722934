import React from 'react'
import { useNode } from '@craftjs/core'

import { ContainerSettings } from './ContainerSettings'

export const ColumnContainer = ({ children, ...props }) => {
  const {
    connectors: { connect },
  } = useNode()

  const {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  return (
    <div
      style={{ ...props, padding, borderRadius }}
      ref={(ref) => connect(ref)}
    >
      {children}
    </div>
  )
}

export const ColumnContainerDefaultProps = {
  maxWidth: '100%',
  overflow: 'auto',
  display: 'inline-block',
  width: '300px',
  height: '100%',
  textAlign: 'center',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: '#888888',
  boxSizing: 'border-box',
  paddingTop: 10,
  paddingRight: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  isColumn: true,
}

ColumnContainer.craft = {
  props: ColumnContainerDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
}
