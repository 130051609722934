import styled from 'styled-components'

export const StyledStoreHeader = styled.div`
  font-size: 14px;

  img {
    margin: 0px 8px 2px;
  }

  color: #000;

  a > span {
    margin-left: 8px;
    font-weight: bold;
    color: #007bff !important;
  }
`
