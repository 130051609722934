export const DLT_OPTIONS = [
  {
    label: 'Sender IDs',
    value: 'senderIds',
  },
  {
    label: 'Templates',
    value: 'templates',
  },
]

export const SMS_TEMPLATE_HEADER = [
  'Template ID',
  'Template Name',
  'Headers',
  'Status',
  'Template Body',
  'Created Date',
  'Actions',
]

export const SMS_HEADERS_HEADER = [
  'Header ID',
  'Sender ID',
  'Sender ID Description',
  'Created Date',
  'Actions',
]

export const TEMPLATE_ERROR_HEADER = [
  'Sl. No.',
  'Header',
  'Invalid',
  'Duplicate',
  'Already Exists',
  'Errors in Columns',
]

export const DLT_ERROR_ROW = (item) => [
  item.rowNumber,
  item.HEADER || item['SENDER ID'],
  item.isInvalid?.toString()?.toUpperCase(),
  item.isDuplicate?.toString()?.toUpperCase(),
  item.isExists?.toString()?.toUpperCase(),
  item.columns?.length ? item.columns?.join(', ') : 'None',
]

export const CONTENT_TYPES = [
  {
    label: 'Promotional',
    value: 'Promotional',
  },
  {
    label: 'Service Explicit',
    value: 'Service-Explicit',
  },
]

export const SMS_HEADER_INPUTS = [
  {
    variant: 'input',
    label: 'Header Name',
    value: 'name',
  },
  {
    variant: 'input',
    label: 'Header ID',
    value: 'headerId',
  },
  {
    variant: 'textarea',
    label: 'Header Description',
    value: 'description',
  },
  {
    variant: 'radio',
    label: 'Header Type',
    value: 'type',
    options: CONTENT_TYPES,
  },
]

export const TABS = {
  billExplicit: 'bill-explicit',
  dltSettings: 'dlt-settings',
  productAnalytics: 'product-analytics',
  consumerProfiling: 'consumer-profiling',
  whatsApp: 'whatsApp',
}

export const TABS_LIST = [
  {
    label: 'Bill Explicit',
    value: 'bill-explicit',
  },
  {
    label: 'DLT Settings',
    value: 'dlt-settings',
  },
  // {
  //   label: 'Product Analytics',
  //   value: 'product-analytics',
  // },
  {
    label: 'Consumer Profiling',
    value: 'consumer-profiling',
  },
  {
    label: 'WhatsApp Settings',
    value: 'whatsApp',
  },
]
