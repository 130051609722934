import React from 'react'
import { FlexBox, Div } from 'styledComponent'
import { Button } from 'components/Common'

const FilterFooter = (props) => {
  return (
    <Div p="8px" backgroundColor="#F2F5F8">
      <FlexBox gap="8px" justify="flex-end">
        <Button variant="textualUnderline" onClick={props.onResetFilters}>
          RESET FILTERS
        </Button>
        <Button variant="primaryOutline" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={props.onSetFilters}
          disabled={props.isExport && props.disabledExport}
        >
          {props.isExport ? 'Export' : 'Save'}
        </Button>
      </FlexBox>
    </Div>
  )
}

export default FilterFooter
