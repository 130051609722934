import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import 'css/content/transactions.scss'

class TransactionPending extends Component {
  render() {
    return (
      <div className="transactions transactionPending">
        <div className="transactionStatusText">Transaction Pending!</div>
        <div className="transactionStatusIcon transPending">
          <i className="fas fa-exclamation-circle" />
        </div>
        <Link to="/recharge">
          <button className="transactionsGoBackButton">Go Back</button>
        </Link>
      </div>
    )
  }
}

export default TransactionPending
