// billingAnalysis
// customerAnalysis
// consumerEngagement WILL BE CHANGED IN THE FUTURE, NOT USED NOW
// profile
// recharge NOT USED ANYMORE
// feedback
// userAccess
// ----- consumerSms CHECK later
// advert NOT USED ANYMORE
// loyalty NOT USED ANYMORE
// bills
// ----- user CHECK LAter
// setting
// crm
// productAnalytics
// reports
// autoEngage
// productManagement
// journey

/* 
  [feature controller ID] :{
    id: 'User Access ID name'
  }
*/

export const modules = {
  // cAnalyticsPro: {
  //   id: 'cAnalyticsPro',
  //   name: 'Consumer Analytics',
  // },
  feedback: {
    id: 'feedback',
    name: 'Feedback',
  },
  autoEngage: {
    id: 'autoEngage',
    name: 'Digital Campaigns',
  },
  journey: {
    id: 'journey',
    name: 'Auto Engagement',
  },
  products: {
    id: 'productManagement',
    name: 'Products',
  },
  billComplaints: {
    id: 'billComplaints',
    name: 'Customer Complaints',
  },
  bAnalytics: {
    id: 'billingAnalysis',
    name: 'Billing Analytics',
  },
  bills: {
    id: 'bills',
    name: 'Bills',
  },
  // pAnalyticsPro: {
  //   id: 'productAnalytics',
  //   name: 'Product Analytics',
  // },
  crm: {
    id: 'crm',
    name: 'Consumer Profiling',
  },
  reports: {
    id: 'reports',
    name: 'Reports',
  },
  settings: {
    id: 'setting',
    name: 'Settings',
  },
  uAccess: {
    id: 'userAccess',
    name: 'User Access',
  },
  /* mBank: {
    id: 'mBank',
    name: 'Media Bank',
  }, */
  coupons: {
    id: 'coupons',
    name: 'Coupons',
  },
  surveys: {
    id: 'surveys',
    name: 'Surveys',
  },
  custComplaints: {
    id: 'billComplaints',
    name: 'Customer Complaints',
  },
  // cAnalytics: {
  //   id: 'customerAnalysis',
  //   name: 'Customer Analytics',
  // },
  // bProfile: {
  //   id: 'profile',
  //   name: 'Profile',
  // },
  custSegmentation: {
    id: 'custSegmentation',
    name: 'Customer Segmentations',
  },
  storeManagement: {
    id: 'storeManagement',
    name: 'Store Management',
  },
}

export const permissions = [
  'reports',
  'settings',
  'crm',
  'journey',
  'custSegmentation',
  [
    'bannerCampaign',
    'adCampaign',
    'sellCampaign',
    'surveyCampaign',
    'smsCampaign',
    'emailCampaign',
    'popupCampaign',
    'surveys',
    'coupons',
    'mBank',
  ],
  'bills',
  'custComplaints',
  'uAccess',
  // 'cAnalytics',
  // 'bProfile',
  'feedback',
  // 'feedbackReplyPro',
  'products',
  'bAnalytics',
  // 'pAnalyticsPro',
  // 'cAnalyticsPro',
  'storeManagement',
]

export const autoEngage = [
  'bannerCampaign',
  'adCampaign',
  'sellCampaign',
  'surveyCampaign',
  'smsCampaign',
  'emailCampaign',
  'popupCampaign',
  'surveys',
  'coupons',
  'mBank',
]

export const bills = ['bills', 'custComplaints']
