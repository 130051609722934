import React, { useState } from 'react'

import { Input, PopupContainer, useToasts } from 'components/Common'
import { CUSTOM_LINK_TYPES } from 'components/Content/storeManagement/constants'
import { GET_SEL_VALUE } from 'utils/reusables'

export const CustomLinkTypeAttributeModal = ({
  isPopOpen,
  setIsPopOpen,
  onCustomLinkTypeChange,
}) => {
  const { toast } = useToasts()

  const [customLinkType, setCustomLinkType] = useState('')

  const onSave = () => {
    if (!customLinkType) {
      toast('Please enter your custom input.', 'error')
      throw ''
    }
    onCustomLinkTypeChange(customLinkType, 'customLinkType')
    setIsPopOpen(false)
    setCustomLinkType('')
  }

  const onCancel = () => {
    setIsPopOpen(false)
    setCustomLinkType('')
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Custom Links"
      onCancel={onCancel}
      onSubmitClick={onSave}
      contentBlockStyles={{ minHeight: '320px' }}
    >
      <Input
        containerMargin="0"
        variant="single-select"
        label="Custom a Link Type"
        placeholder="Select a link type"
        inputWidth="100%"
        value={GET_SEL_VALUE(customLinkType, CUSTOM_LINK_TYPES) || ''}
        maxLength={30}
        onChange={(e) => setCustomLinkType(e?.value)}
        options={CUSTOM_LINK_TYPES}
      />
    </PopupContainer>
  )
}
