import React from 'react'

import { CampaignDetails, Audience } from '../components'
import { CampaignSettingsWithATOC } from '../components/CampaignSettingsWithATOC'
import PdfUpload from './PdfUpload'
import { SBBPreview } from './SBBPreview'

export const SBBCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          campaignType="'Sell Below the Bill'"
          form={form}
          setForm={setForm}
        />
      )

    case 'PDF Upload':
      return <PdfUpload form={form} setForm={setForm} />

    case 'Audience':
      return <CampaignSettingsWithATOC form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <SBBPreview form={form} hideSteps={hideSteps} />

    default:
      return null
  }
}
