import React, { useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { Text } from 'components/Common'

import { PublishItem } from '../../components/PublishItem'
import { $BlueBox } from '../../AutoEngagement.styled'
import { CHECK_IF } from '../../constants'
import { SurveyBuilder } from 'builder/survey-builder'
import { $Submit } from 'builder/survey-builder/Survey.styled'
import './transition.css'

export const SurveyPreview = ({ form, setForm, hideSteps }) => {
  const [page, setPage] = useState('welcome')

  const getPage = () => {
    if (!form?.contentType) return null
    switch (page) {
      case 'welcome': {
        if (form?.content?.welcomeImage) {
          return (
            <Div position="relative">
              <img src={form?.content?.welcomeImage} width="480px" />
              <Div position="absolute" bottom="0px" left="150px">
                <$Submit
                  width="200px"
                  bgColor={form?.content?.buttonConfig?.bgColor}
                  textColor={form?.content?.buttonConfig?.textColor}
                  fontFamily={form?.content?.fontFamily}
                  fontWeight={form?.content?.buttonConfig?.fontWeight}
                  onClick={() => setPage('survey')}
                >
                  Take Survey
                </$Submit>
              </Div>
            </Div>
          )
        } else {
          return setPage('survey')
        }
      }

      case 'survey': {
        return (
          <SurveyBuilder
            hideHeader
            form={form}
            setForm={setForm}
            onSubmit={() => setPage('exit')}
          />
        )
      }

      case 'exit': {
        if (form?.content?.exitImage) {
          return (
            <Div position="relative">
              <img src={form?.content?.exitImage} width="480px" />
              <Div position="absolute" bottom="0px" left="150px">
                <$Submit
                  width="200px"
                  bgColor={form?.content?.buttonConfig?.bgColor}
                  textColor={form?.content?.buttonConfig?.textColor}
                  fontFamily={form?.content?.fontFamily}
                  fontWeight={form?.content?.buttonConfig?.fontWeight}
                  onClick={() => setPage('welcome')}
                >
                  Submit Survey
                </$Submit>
              </Div>
            </Div>
          )
        } else {
          return setPage('survey')
        }
      }

      default:
        return null
    }
  }

  return (
    <FlexBox justify="space-between">
      <Div width="50%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          {hideSteps ? 'Preview' : 'Preview & Publish'}
        </Text>
        <PublishItem
          width="100%"
          header="Survey Details"
          completed={!Boolean(CHECK_IF(['name', 'vendorId'], form))}
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Survey Name :
            </Text>
            <Text variant="small" type="main">
              {form?.name || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="0px 0 5px">
            <Text variant="small" type="grey">
              Survey Description :
            </Text>
            <Text variant="small" type="main">
              {form?.description || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Content Type :
            </Text>
            <Text variant="small" type="main">
              {form?.contentType === 'single' ? 'Single Page' : 'Multi Page'}
            </Text>
          </FlexBox>
        </PublishItem>
      </Div>
      <$BlueBox>
        <Div width="100%">
          <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
            Preview Pane
          </Text>
          {!!getPage() && (
            <Div width="480px">
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={page}
                  addEndListener={(node, done) =>
                    node.addEventListener('transitionend', done, false)
                  }
                  classNames="fade"
                >
                  <Div width="480px">{getPage()}</Div>
                </CSSTransition>
              </SwitchTransition>
            </Div>
          )}
        </Div>
      </$BlueBox>
    </FlexBox>
  )
}
