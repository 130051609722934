import React from 'react'
import { useEditor } from '@craftjs/core'
import { $PropertyPanel } from './Editor.styled'

export const SettingsPanel = () => {
  const { selected } = useEditor((state, query) => {
    const currentNodeId = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      }
    }

    return {
      selected,
    }
  })

  return selected ? (
    <$PropertyPanel>
      {selected.settings && React.createElement(selected.settings)}
    </$PropertyPanel>
  ) : null
}
