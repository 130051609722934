import React from 'react'
import { useDispatch } from 'react-redux'

import { useSelectStore } from 'hooks'
import { Input } from 'components/Common'
import { Div, FlexBox } from 'styledComponent'

import { setAudienceStoreFilter } from 'redux/actions/autoEngageActions'
import { _selectOptionsGenerator } from 'utils/formatter'
import { fetchStates } from 'redux/actions/locationAction'

export const AEStoreFilter = ({}) => {
  const [filters, setFilters] = React.useState({
    geography: [],
  })
  const dispatch = useDispatch()
  const { data: audienceStoreFilter } = useSelectStore(
    'autoEngage.newAudienceStoreFilter'
  )
  const { data: states } = useSelectStore('location.states')

  React.useEffect(() => {
    if (states.length === 0) {
      dispatch(fetchStates())
    }
  }, [])

  React.useEffect(() => {
    dispatch(setAudienceStoreFilter(filters))
  }, [filters.geography])

  const handleGeographySelect = (value) => {
    setFilters({
      ...filters,
      geography: value,
    })
  }

  return (
    <FlexBox align="center" gap="10px">
      <h6 style={{ width: '70px' }}>Sort By:</h6>
      <Div width="100%">
        <Input
          containerMargin="0"
          label="Geography"
          variant="multi-select"
          onChange={handleGeographySelect}
          options={_selectOptionsGenerator(states)}
          id="state"
          value={audienceStoreFilter?.geography}
          maxMenuHeight="200px"
        />
      </Div>
    </FlexBox>
  )
}
