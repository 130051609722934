import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import iphoneImage from 'images/iphone.png'

class AdvertPreview extends Component {
  constructor() {
    super()
    this.closeModal = this.closeModal.bind(this)
  }
  closeModal() {
    this.props.onClick()
  }

  render() {
    return (
      <div className="advertPreviewPopup">
        <Popup open={this.props.open} onClose={this.closeModal}>
          <div className="advertPreview">
            <div className="whiteBackground animated fadeIn delay-1s" />

            <img
              src={iphoneImage}
              className="advertPreviewImage animated fadeInDown"
              alt=""
            />
            <img
              src={this.props.img.frontImgUrl}
              className="previewAdImage animated fadeInUp"
              alt=""
            />
          </div>
        </Popup>
      </div>
    )
  }
}

export default AdvertPreview
