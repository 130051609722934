import React from 'react'
import styled from 'styled-components'
import DateRangePicker from 'react-daterange-picker'
import PropTypes from 'prop-types'

import formatDate from '../../../../utils/getDateFormat'

/**
 * @description chart's common date range selector
 * @param {Object} value date value
 * @param {Boolean} showCalendar show/hide date selector flag
 * @param {Function} onDateChange date change handler
 * @param {Function} toggleCalender show/hide date selector handler
 */
const DateRangeSelector = ({
  value,
  showCalendar,
  toggleCalender,
  onDateChange,
}) => {
  if (!value) return null

  const selectedDate = formatDate(value.start.toDate())
  const selectedEnd = formatDate(value.end.toDate())
  return (
    <$Block>
      <$Button onClick={toggleCalender}>
        <i className="fas fa-calendar-alt" />{' '}
        {selectedDate + ' - ' + selectedEnd}
      </$Button>
      {showCalendar && (
        <$CalenderContainer>
          <DateRangePicker
            onSelect={onDateChange}
            singleDateRange={true}
            value={value}
          />
        </$CalenderContainer>
      )}
    </$Block>
  )
}

DateRangeSelector.prototype = {
  value: PropTypes.object.isRequired,
  showCalendar: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
  toggleCalender: PropTypes.func.isRequired,
}

export default DateRangeSelector

const $Block = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  position: relative;
`
const $CalenderContainer = styled.div`
  position: absolute;
  z-index: 5;
  background-color: #fff;
  top: 100%;
`

const $Button = styled.button`
  border: 1px solid #3d87ff;
  border-radius: 4px;
  background-color: #fff;
  font-size: 70%;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3d87ff;
  width: 20%;
  margin-left: 40%;
  outline: none;
  height: 30px;
`
