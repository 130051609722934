import React, { Component } from 'react'
import Select from 'react-select'
import swal from 'sweetalert'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import AdvertCropComponent from './AdvertCropComponent'
import getCroppedImg from 'utils/cropImage'
import {
  addNew,
  activateCampaign,
  deactivateCampaign,
  getCampaigns,
  getCampaign,
  deleteCampaign,
  getActiveCampaign,
  selectBrandForAdvert,
} from '../../../redux/actions/advertActions'
import AdvertList from './AdvertList'
import AdvertPreview from './AdvertPreview'
import { formatDate2 } from '../../../utils/getDateFormat'
import ViewCampaign from './ViewCampaign'
import LiveAdvertisement from './LiveAdvertisement'
import { filterStores, filterBrand } from '../../../redux/actions/authActions'

import {
  StoreSelectOption,
  SingleValue,
} from '../../Common/CustomSelectComponents'

import CustomPopup from '../CustomPopup'
import SelectBrand from './SelectBrand'
import BrandConfirm from './BrandConfirm'
import Navbar from '../../Layout/Navbar'
import '../../../css/content/advert.scss'
import '../../../css/content/liveAdvert.scss'
import {
  GreyText,
  PrimaryButton,
  PrimaryOutlineButton,
} from '../../../styledComponent'

const previewImage = require('../../../images/advertPreview.png')

const AdvertHeading = (props) => {
  return (
    <div className="advertHeading">
      <div>
        <div className="advertHeading1">Advertising Campaign</div>
        <div className="advertHeading2">
          Have your users see their bill and your offers so they come back for
          even more.
        </div>
      </div>
      <div className="selected_store">
        <div className="stores">
          Stores Selected: {props.storeLength ? props.storeLength : 'All'}
        </div>
        <div className="advert_permission">
          Advertisement Permission: {props.permitStore}
        </div>
      </div>
    </div>
  )
}

const filters = (storeIds, stores, brand) => {
  if (storeIds.length) {
    let storeLength = storeIds.filter(
      (storeId) => stores[storeId] && brand[0].value === stores[storeId].brandId
    )
    return storeLength.length
  } else {
    const store = []
    for (let storeId in stores) {
      if (stores[storeId] && brand[0].value === stores[storeId].brandId)
        store.push(stores[storeId])
    }
    return store.length
  }
}

const initialState = {
  domainLength: 15,
  fetchCount: 0,
}
const campaignFormData = new FormData()

class Advertisment extends Component {
  constructor() {
    super()
    this.state = {
      fileUrl: null,
      fileName: null,
      cropImageModal: false,
      croppedImageUrl: null,
      fetchList: 2,
      hasMore: true,
      batch: 10,
      viewAdvertCampaign: false,
      viewCampaignAdRedirectUrl: null,
      viewCampaignIsActive: false,
      domainLength: 15,
      fetchCount: 0,
      lockFetchMore: false,
      data: [],
      campaignView: false,
      activeCampaign: [],
      confirmBox: false,
      storeLength: 0,
      selectedBrand: null,
      loadingStatus: false,
      campaignAction: 'create_campaign',
      campaignObj: {},
      campaignId: null,
    }
    this.dialogRef = React.createRef()

    this.onCampaignSave = this.onCampaignSave.bind(this)
    this.onAdvertPreview = this.onAdvertPreview.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.onPopupClose = this.onPopupClose.bind(this)
    this.onCampaignView = this.onCampaignView.bind(this)
    this.onCampaignViewPopupClose = this.onCampaignViewPopupClose.bind(this)
    this.fetchMoreData = this.fetchMoreData.bind(this)
    this.onCampaignDelete = this.onCampaignDelete.bind(this)
    this.onCampaignActivate = this.onCampaignActivate.bind(this)
    this.onCampaignDeactivate = this.onCampaignDeactivate.bind(this)
    this.confirmBrand = this.confirmBrand.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fetchCampaign = this.fetchCampaign.bind(this)
    this.handleCampaignAction = this.handleCampaignAction.bind(this)
  }

  async componentDidMount() {
    if (this.props.selectedBrand.length) {
      let storeLength = filters(
        this.props.selectedStores,
        this.props.stores,
        this.props.selectedBrand
      )
      this.setState({ storeLength })
      await this.initialFetch(null)
    } else if (this.props.brands.length > 1) this.dialogRef.current.openModal()
    else {
      if (this.props.brands.length) {
        const brand = {
          label: this.props.brands[0].name,
          value: this.props.brands[0].brandId,
          brandLogo: this.props.brands[0].brandLogo,
        }
        await this.handleChange(brand)
      }
    }
  }

  onChangeFile = async (event) => {
    const fileUploaded = event.target.files[0]
    campaignFormData.delete('newImage')
    if (fileUploaded) {
      campaignFormData.append('newImage', fileUploaded)
    }

    event.stopPropagation()
    event.preventDefault()
    if (event.target.files.length === 1) {
      this.setState({
        fileName: event.target.files[0].name,
        fileUrl: URL.createObjectURL(event.target.files[0]),
        cropImageModal: true,
      })
    } else {
      swal('Multiple File', 'Select one image for upload', 'error')
    }
  }

  async confirmBrand(action) {
    if (this.props.selectedBrand.length) {
      let storeLength = filters(
        this.props.selectedStores,
        this.props.stores,
        this.props.selectedBrand
      )
      this.dialogRef.current.openModal()
      this.setState({
        campaignAction: action.campaignAction,
        storeLength,
        confirmBox: true,
        campaignObj: action.campaignObj,
        campaignId: action.campaignId,
      })
    } else {
      this.setState({ confirmBox: false })
      this.dialogRef.current.openModal()
    }
  }

  async onCampaignSave() {
    try {
      this.setState({ loadingStatus: true })

      // campaignFormData.append("newImage", this.state.croppedImageUrl);
      campaignFormData.append('brandId', this.props.selectedBrand[0].value)
      campaignFormData.append('campaignName', this.state.campaignName)
      campaignFormData.append('adRedirectUrl', this.state.adRedirectUrl)
      const response = await addNew(campaignFormData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        successMessage: 'Campaign created successfully!',
      })
      if (response.code) {
        this.setState({ loadingStatus: false })
        this.dialogRef.current.closeModal()
        this.setState({ croppedImageUrl: null, confirmBox: false })
        await this.initialFetch(null)
      }
    } catch (e) {
      this.setState({ loadingStatus: false })
      console.error(e)
    }
  }

  async onCampaignActivate(campaignObj) {
    this.setState({ loadingStatus: true })
    const { campaignId, campaignName, adRedirectUrl, signedUrl } = campaignObj
    if (this.props.selectedBrand.length) {
      try {
        let res = await activateCampaign(
          {
            brandId: this.props.selectedBrand[0].value,
            campaignId,
            campaignName,
            adRedirectUrl,
            signedUrl,
          },
          {
            successMessage: 'Campaign activated successfully!',
          }
        )
        if (res.code) {
          this.setState({
            campaignView: false,
            loadingStatus: false,
            confirmBox: false,
          })
          this.dialogRef.current.closeModal()
        }
        await this.initialFetch(null)
      } catch (e) {
        console.error(e.message)
      }
    } else {
      this.setState({ loadingStatus: false, confirmBox: false })
      this.dialogRef.current.openModal()
    }
  }

  async onCampaignDeactivate(campaignId) {
    if (this.props.selectedBrand.length) {
      this.setState({ loadingStatus: true })
      try {
        let res = await deactivateCampaign(
          { brandId: this.props.selectedBrand[0].value, campaignId },
          {
            successMessage: 'Campaign deactivated successfully!',
          }
        )
        if (res.code) {
          this.setState({ loadingStatus: false, confirmBox: false })
          this.dialogRef.current.closeModal()
          await this.initialFetch(null)
        }
      } catch (e) {
        this.setState({ loadingStatus: false })
        this.dialogRef.current.closeModal()
        console.error(e)
      }
    } else {
      this.setState({ loadingStatus: false, confirmBox: false })
      this.dialogRef.current.openModal()
    }
  }

  async onCampaignDelete(campaignId) {
    if (this.props.selectedBrand.length) {
      this.setState({ loadingStatus: true })
      const res = await deleteCampaign(
        {
          brandId: this.props.selectedBrand[0].value,
          campaignId,
        },
        {
          successMessage: 'Campaign deleted successfully for selected stores!',
        }
      )

      if (res.code) {
        this.setState({
          campaignView: false,
          loadingStatus: false,
          confirmBox: false,
        })
        this.dialogRef.current.closeModal()
        await this.initialFetch(null)
      }
    } else {
      this.setState({ loadingStatus: false, confirmBox: false })
      this.dialogRef.current.openModal()
    }
  }

  handleCampaignAction() {
    switch (this.state.campaignAction) {
      case 'create_campaign':
        if (this.state.croppedImageUrl) this.onCampaignSave()
        else swal('Oops', 'Select Image for advertiment', 'error')
        break
      case 'deactivate_campaign':
        this.onCampaignDeactivate(this.state.campaignId)
        break
      case 'activate_campaign':
        this.onCampaignActivate(this.state.campaignObj)
        break
      case 'delete_campaign':
        this.onCampaignDelete(this.state.campaignId)
        break
      default:
        break
    }
  }

  async onCampaignView(name, id, adRedirectUrl, isActive) {
    const res = await getCampaign({
      campaignId: id,
    })
    if (res.code) {
      this.setState({
        viewCampaignUrl: res.data,
        viewCampaignName: name,
        viewCampaignId: id,
        viewCampaignAdRedirectUrl: adRedirectUrl,
        viewCampaignIsActive: isActive,
        campaignView: true,
      })
    }
  }

  onAdvertPreview() {
    if (this.state.croppedImageUrl !== null) {
      this.setState({
        viewAdvertCampaign: !this.state.viewAdvertCampaign,
      })
    }
  }

  async fetchMoreData() {
    if (!this.state.lockFetchMore) {
      this.setState({
        lockFetchMore: true,
      })
      const res = await getCampaigns({
        brandId: this.props.selectedBrand[0].value,
        domainLength: this.state.domainLength,
        fetchCount: this.state.fetchCount,
      })
      if (res.code) {
        this.setState(
          {
            data: this.state.data.concat(res.data),
          },
          () => {
            this.setState(
              {
                fetchCount: this.state.fetchCount + this.state.domainLength,
              },
              () => {
                this.setState({ lockFetchMore: false })
              }
            )
          }
        )
        if (res.data.length === 0) {
          this.setState({
            hasMore: false,
          })
        }
      }
    }
  }

  onPopupClose() {
    this.setState({
      cropImageModal: false,
    })
  }
  onCampaignViewPopupClose() {
    this.setState({ campaignView: false })
  }

  uploadBanner = async (cropValues, imageRef, campaignName, adRedirectUrl) => {
    const croppedImg = await getCroppedImg(
      imageRef,
      cropValues,
      this.state.fileName
    )
    this.setState({
      croppedImageUrl: croppedImg,
      campaignName: campaignName,
      cropImageModal: false,
      adRedirectUrl,
    })
  }

  async handleChange(selectedOption) {
    this.props.selectBrandForAdvert([selectedOption])
    let storeLength = filters(this.props.selectedStores, this.props.stores, [
      selectedOption,
    ])
    this.setState({ storeLength })
    await this.initialFetch([selectedOption])
  }

  async selectBrand() {
    if (this.state.selectedBrand) {
      this.props.selectBrandForAdvert(this.state.selectedBrand)
      let storeLength = filters(
        this.props.selectedStores,
        this.props.stores,
        this.state.selectedBrand
      )
      this.setState({ storeLength })
      this.dialogRef.current.closeModal()
      this.initialFetch(this.state.selectedBrand)
    } else swal('Oops', 'To view advertisement, select Brand first', 'error')
  }

  async initialFetch(selectedBrand) {
    let brandId
    if (selectedBrand) brandId = selectedBrand[0].value
    else if (this.props.selectedBrand.length)
      brandId = this.props.selectedBrand[0].value
    else this.setState({ data: [], activeCampaign: [] })
    if (brandId) await this.fetchCampaign(brandId)
  }

  async fetchCampaign(brandId) {
    return new Promise(async (resolve, reject) => {
      if (brandId !== null || brandId !== undefined) {
        const res = await getCampaigns({
          brandId,
          domainLength: initialState.domainLength,
          fetchCount: initialState.fetchCount,
        })
        if (res.code) this.setState({ data: res.data })

        const campaign_response = await getActiveCampaign({ brandId })
        if (campaign_response.code)
          this.setState({ activeCampaign: campaign_response.data })
        else this.setState({ activeCampaign: [] })
        res.code ? resolve(true) : reject(false)
      } else {
        swal('Oops', 'To view advertisement, select Brand first', 'error')
      }
    })
  }

  render() {
    return (
      <div className="advertMain">
        <Navbar heading="Advertising campaign" />
        <AdvertHeading
          storeLength={this.props.selectedStores.length}
          permitStore={this.state.storeLength}
        />

        <div className="brandSelector">
          <div className="view">
            <h4>Current Brand:</h4>
            <Select
              value={this.props.selectedBrand}
              options={this.props.brands.map((brand) => ({
                label: brand.name,
                value: brand.brandId,
                brandLogo: brand.brandLogo,
              }))}
              placeholder="Select the brand to view their advertisement"
              required={true}
              components={{
                Option: StoreSelectOption,
                SingleValue,
              }}
              isSearchable
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="advertBoxes">
          <div className="previewBox">
            <div className="previewBoxHeading">Create New Advertisement</div>
            <div className="previewImage">
              {this.state.croppedImageUrl ? (
                <img
                  alt="Crop"
                  className="croppedImage"
                  src={this.state.croppedImageUrl.frontImgUrl}
                  onClick={() => this.refs.fileUploader.click()}
                />
              ) : (
                <img
                  src={previewImage}
                  className="imageInput"
                  onClick={() => this.refs.fileUploader.click()}
                />
              )}
            </div>

            <input
              type="file"
              id="file"
              name="banner"
              ref="fileUploader"
              style={{
                display: 'none',
              }}
              onChange={this.onChangeFile}
              onInput={this.onChangeFile}
              onClick={(event) => {
                event.target.value = null
              }}
            />
            {this.state.cropImageModal ? (
              <AdvertCropComponent
                open={true}
                file={this.state.file}
                fileUrl={this.state.fileUrl}
                onClose={this.onPopupClose}
                onClick={() => this.closeModal()}
                uploadBanner={(
                  cropValues,
                  imageRef,
                  campaignName,
                  adRedirectUrl
                ) =>
                  this.uploadBanner(
                    cropValues,
                    imageRef,
                    campaignName,
                    adRedirectUrl
                  )
                }
              />
            ) : null}
            {this.state.campaignView ? (
              <ViewCampaign
                open={true}
                viewCampaignPopup={this.onCampaignViewPopupClose}
                name={this.state.viewCampaignName}
                imageUrl={this.state.viewCampaignUrl}
                campaignId={this.state.viewCampaignId}
                onDelete={(campaignId) =>
                  this.confirmBrand({
                    campaignAction: 'delete_campaign',
                    campaignId,
                  })
                }
                onActive={(campaignObj) =>
                  this.confirmBrand({
                    campaignAction: 'activate_campaign',
                    campaignObj,
                  })
                }
                onDeactive={(campaignId) =>
                  this.confirmBrand({
                    campaignAction: 'deactivate_campaign',
                    campaignId,
                  })
                }
                adRedirectUrl={this.state.viewCampaignAdRedirectUrl}
                isActive={this.state.viewCampaignIsActive}
              />
            ) : null}
            {this.state.viewAdvertCampaign ? (
              <AdvertPreview
                open={true}
                onClick={this.onAdvertPreview}
                img={this.state.croppedImageUrl}
              />
            ) : null}

            <div className="previewBoxButtons">
              <PrimaryOutlineButton onClick={this.onAdvertPreview}>
                Preview
              </PrimaryOutlineButton>
              <PrimaryButton
                onClick={() =>
                  this.confirmBrand({
                    campaignAction: 'create_campaign',
                  })
                }
              >
                Save
              </PrimaryButton>
            </div>
          </div>
          <LiveAdvertisement
            filterWithBrand={true}
            showDeactivate={true}
            activeCampaign={this.state.activeCampaign}
            handleCampaignDeactivate={(campaignId) => {
              this.confirmBrand({
                campaignAction: 'deactivate_campaign',
                campaignId,
              })
            }}
          />
        </div>
        <div className="advertList">
          <div className="advertListHeading">
            My Advertisements
            <hr />
          </div>
          <div className="advertListTableContainer">
            {!this.state.data.length && (
              <GreyText as="p" text-align="center">
                No campaigns for selected brand
              </GreyText>
            )}
            {this.state.data.length > 0 && (
              <InfiniteScroll
                dataLength={this.state.fetchCount}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                height={300}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Advertisement Name</th>
                      <th>Redirection URL</th>
                      <th>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((data, i) => {
                      return (
                        <AdvertList
                          srNo={i + 1}
                          campaignName={data.campaignName}
                          campaignDate={formatDate2(data.createdDate)}
                          campaignId={data._id}
                          adRedirectUrl={data.adRedirectUrl}
                          status={data.isActive}
                          key={i}
                          onView={this.onCampaignView}
                        />
                      )
                    })}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
          </div>
        </div>

        <CustomPopup
          ref={this.dialogRef}
          className={'modal_view'}
          heading={!this.state.confirmBox ? 'Advertisement View' : 'Note'}
          loadingStatus={this.state.loadingStatus}
          onProceed={() =>
            !this.state.confirmBox
              ? this.selectBrand()
              : this.handleCampaignAction()
          }
        >
          {!this.state.confirmBox ? (
            <SelectBrand
              brands={this.props.brands}
              onChangeBrand={(brand) => this.setState({ selectedBrand: brand })}
            />
          ) : (
            <BrandConfirm totalStores={this.state.storeLength} />
          )}
        </CustomPopup>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    advert: state.advert,
    stores: filterStores(state, 'advert'),
    selectedStores: state.stores.selectedStores,
    brands: filterBrand(state, 'advert'),
    selectedBrand: state.advert.selectedBrandForAdvert,
  }
}

export default connect(mapStateToProps, { selectBrandForAdvert })(Advertisment)
