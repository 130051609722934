import React, { Component } from 'react'
import { CaretIcon } from 'styledComponent/components/icons'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      dropdownActive: false,
    }
  }

  componentWillReceiveProps(props) {
    props.selected &&
      this.setState({
        selected: Array.isArray(props.selected)
          ? props.selected
          : [props.selected],
      })
  }

  handleOptionClick = (option) => {
    this.setState({ selected: [option], dropdownActive: false })
    this.props.onChange && this.props.onChange(option)
  }

  handleCaretClick = () =>
    this.setState({ dropdownActive: !this.state.dropdownActive })

  render() {
    return (
      <div className="bm-dropdown">
        <div className="bm-dropdown-selected" onClick={this.handleCaretClick}>
          <div className="bm-dropdown-value">
            {this.state.selected.length
              ? this.state.selected[0].value
              : `Default`}
          </div>
          <div className="bm-dropdown-indicator">
            <CaretIcon vertical-align="text-top" />
          </div>
        </div>
        {this.state.dropdownActive && (
          <div className="bm-dropdown-options animated fadeIn faster">
            {this.props.options &&
              this.props.options.map((option) => (
                <div
                  className={`bm-dropdown-option ${
                    this.state.selected.length &&
                    this.state.selected[0].id === option.id &&
                    'selected'
                  }`}
                  onClick={(e) => this.handleOptionClick(option)}
                  key={option.key ? option.key : option.id}
                >
                  {option.value}
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
}

export { Dropdown }
