import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const $Slider = styled.input`
  width: 40px;
  height: 14px;
  border-radius: 10px;
  outline: none;
  background: #fff;
  border: 1px solid #bbc1e1;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;

  &:after {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #bbc1e1;
    position: absolute;
    content: '';
    left: -2px;
    top: -3px;
    display: block;
    transition: all 0.3s ease-in-out;
    border: 1px solid #707070;
  }

  &:checked {
    background: #9cc2ff;
    border: 1px solid #9cc2ff;

    &:after {
      background: #3b86ff;
      transform: translateX(24px);
    }
  }

  ${space};
`

export const Slider = ({ isChecked, ...rest }) => {
  return <$Slider type="checkbox" checked={isChecked} {...rest} />
}
