import React from 'react'

import { FlexBox } from 'styledComponent'
import { SectionHeading } from 'components/Common/SectionHeading'
import { ALL_PRODUCTS_HEADINGS } from './constants'

const TitleBlock = () => {
  return (
    <FlexBox justify="space-between">
      <SectionHeading
        {...ALL_PRODUCTS_HEADINGS}
        variant="p"
        subVariant="small"
        color="main"
        weight="bold"
      />
    </FlexBox>
  )
}

export default TitleBlock
