import React from 'react'

import {
  Input,
  PopupContainer,
  RenderIf,
  Text,
  useToasts,
  SelectCompany,
} from 'components/Common'
import { Div, FlexBox } from 'styledComponent'
import Preview from './PreviewPanel'
import { ACCEPTED_FORMATS, CONTENT_INPUT_PARAMS, URL_REGEXP } from './constants'
import { $FileInputBlock } from './TemplateCampaign.styled'
import {
  ERROR_DECODE,
  SUPPORTED_FORMATS,
} from 'components/Content/autoEngagement/constants'
import { post } from 'utils/axiosHandler'
import { useSelectStore } from 'hooks'

const AddTemplateContent = ({
  form,
  isActive,
  setIsActive,
  title,
  testTrigger,
}) => {
  const [sampleContent, setSampleContent] = React.useState({})
  const [file, setFile] = React.useState(null)

  const {
    data: { companies },
  } = useSelectStore('company')

  const { toast } = useToasts()

  const bodyVariables = _getVariablesCount(form, 'body')
  const buttonVariables = _getVariablesCount(form, 'button')

  const onInputChange = (e, index, field) => {
    if (
      ['body', 'button'].includes(field) &&
      e?.target?.value?.length > CONTENT_INPUT_PARAMS.sampleContent.maxChar
    ) {
      return null
    }

    const sampleContentCopy = structuredClone(sampleContent)

    if (!sampleContentCopy.bodyVarsContent)
      sampleContentCopy.bodyVarsContent = []

    switch (field) {
      case 'button':
        sampleContentCopy.buttonUrlParam = e.target.value
        break
      case 'body':
        sampleContentCopy.bodyVarsContent[index] = e.target.value
        break

      default:
        if (field === 'phoneNumber') {
          const regexp = /^[\+\d]+$/gim
          if (
            (e.target.value && !regexp.test(e.target.value)) ||
            e.target.value?.length > 14
          ) {
            return null
          }
        }
        sampleContentCopy[field] = e.target.value
        break
    }

    setSampleContent(sampleContentCopy)
  }

  const onFileChange = (e) => {
    const fileRaw = e?.target?.files?.[0]

    if (!fileRaw) {
      setFile(null)
    }

    const supportedFormatArray = SUPPORTED_FORMATS(form?.mediaType)

    if (!supportedFormatArray.includes(fileRaw?.type?.split('/')[1])) {
      toast(
        `Unsupported file format. Upload file can be one of these formats - ${supportedFormatArray.join(
          ', '
        )}`,
        'error'
      )
      return null
    }

    //check of file limit of 50mb
    if (Number(fileRaw?.size || 0) / 1024 ** 2 >= 50) {
      toast('upload file size needs to less than 50mb', 'error')
      return null
    }

    setFile(fileRaw)
  }

  const onSubmit = async () => {
    try {
      const source = { ...sampleContent, ...(file ? { file } : {}) }

      const invalidMessage = _validateSampleData(
        source,
        bodyVariables,
        buttonVariables,
        form?.type,
        testTrigger
      )

      if (invalidMessage) {
        toast(invalidMessage, 'error')
        return null
      }

      if (!testTrigger) {
        const formData = new FormData()

        for (const key in source) {
          const data =
            key === 'bodyVarsContent'
              ? JSON.stringify(source[key])
              : source[key]
          formData.append(key, data)
        }

        await post(
          `/auto-engage/whatsapp-services/${form?.vendorId}/templates/${form?._id}/sample`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
      }

      if (testTrigger) {
        if (companies.length === 1) {
          source.companyId = companies[0]?.companyId
        }

        const res = await post(
          `/auto-engage/whatsapp-services/${form?.vendorId}/templates/${form?._id}/test`,
          source
        )

        toast(res.message, 'success')
      }

      setIsActive(false)
    } catch (error) {
      toast(ERROR_DECODE(error), 'error')
    }
  }

  React.useEffect(() => {
    setSampleContent({})
  }, [isActive])

  return (
    <PopupContainer
      isPopOpen={isActive}
      setIsPopOpen={setIsActive}
      heading={title || 'Add Sample Content'}
      onSubmitClick={onSubmit}
      disableAutoCloseOnSubmit
    >
      <FlexBox>
        <Div flex="1" padding="0 32px 0 0" height="400px" overflowY="auto">
          <RenderIf active={form?.type === 'media'}>
            <RenderIf active={!testTrigger}>
              <Text variant="h4">Header Media</Text>
              <$FileInputBlock>
                <input
                  type="file"
                  accept={ACCEPTED_FORMATS?.[form?.mediaType]}
                  onChange={onFileChange}
                />
              </$FileInputBlock>
            </RenderIf>
            <RenderIf active={testTrigger}>
              <Input
                label="Media Url"
                variant="input"
                value={sampleContent?.mediaUrl || ''}
                onChange={(e) => onInputChange(e, null, 'mediaUrl')}
                width="30%"
                containerMargin="0"
              />
            </RenderIf>
          </RenderIf>
          <RenderIf active={testTrigger}>
            <Input
              label="Phone Number"
              variant="input"
              value={sampleContent?.phoneNumber || ''}
              onChange={(e) => onInputChange(e, null, 'phoneNumber')}
              width="30%"
              containerMargin="0"
              placeholder="with + country code"
            />
            {companies.length > 1 ? (
              <SelectCompany
                companyId={sampleContent.companyId}
                setCompanyId={(companyId) =>
                  setSampleContent({ ...sampleContent, companyId })
                }
              />
            ) : null}
          </RenderIf>
          {Array(bodyVariables)
            .fill()
            ?.map((_, index) => (
              <Input
                key={index}
                label={`Body Content ${index + 1}`}
                variant="input"
                value={sampleContent?.bodyVarsContent?.[index] || ''}
                onChange={(e) => onInputChange(e, index, 'body')}
                width="30%"
                containerMargin="0"
              />
            ))}
          {Array(buttonVariables)
            .fill()
            ?.map((_, index) => (
              <Input
                key={index}
                label="Button Url Param"
                variant="input"
                value={sampleContent?.buttonUrlParam || ''}
                onChange={(e) => onInputChange(e, index, 'button')}
                width="30%"
                containerMargin="0"
              />
            ))}
          <RenderIf
            active={
              !testTrigger &&
              !(bodyVariables + buttonVariables) &&
              form?.type !== 'media'
            }
          >
            <Text variant="medium" type="grey">
              No variables found, please click on submit to proceed.
            </Text>
          </RenderIf>
        </Div>
        <Preview data={form} minHeight="300px" insidePopup disableToggle />
      </FlexBox>
    </PopupContainer>
  )
}

export default AddTemplateContent

const _getVariablesCount = (data, field) => {
  const regex = /{{[\w]+}}/gim

  let dataField = null

  switch (field) {
    case 'button':
      const buttonData = data?.buttons?.find(
        (ele) => ele.type === 'website' && ele.urlType === 'dynamic'
      )
      dataField = buttonData?.url

      break
    case 'body':
      dataField = data?.content?.body
      break

    default:
      break
  }

  if (!dataField) return 0

  const allMatches = dataField.match(regex)

  return allMatches?.length || 0
}

const _validateSampleData = (
  source,
  bodyVars,
  buttonVars,
  type,
  testTrigger
) => {
  if (bodyVars) {
    if (bodyVars !== (source?.bodyVarsContent || []).length) {
      return 'add valid values for all variables'
    }

    const invalidEntry = source?.bodyVarsContent?.some((val) => !val?.trim())
    if (invalidEntry) {
      return 'add valid values for variables'
    }
  }

  if (buttonVars && !source.buttonUrlParam?.trim()) {
    return 'add valid value for button param variable'
  }

  if (
    type === 'media' &&
    ((!testTrigger && !source.file) || (testTrigger && !source.mediaUrl))
  ) {
    return 'upload a valid file/link'
  }

  if (source.mediaUrl && !URL_REGEXP.test(source.mediaUrl)) {
    return 'please provide a valid url for media'
  }

  if (source.phoneNumber) {
    const phoneNumberRegexp = /^\+[1-9]\d{1,14}$/gm
    if (!phoneNumberRegexp.test(source.phoneNumber)) {
      return 'provide a valid phone number'
    }
  }

  return null
}
