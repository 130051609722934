import React, { Component } from 'react'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/lib/ReactCrop.scss'
import Popup from 'reactjs-popup'
import swal from 'sweetalert'
import 'css/content/advert.scss'
import validateUrl from 'utils/urlValidator'
import { PrimaryButton } from 'styledComponent'

class AdvertCropComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.openPopup,
      crop: {
        aspect: 1 / 1,
      },
      // imageRef: null,
      campaignName: '',
      adRedirectUrl: '',
    }

    this.imageRef = React.createRef()

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.setCrop = this.setCrop.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onCampaignSubmitInPopup = this.onCampaignSubmitInPopup.bind(this)
  }

  openModal() {
    this.setState({ open: true })
  }

  closeModal() {
    this.setState({ open: false })
    this.props.onClose()
  }

  setCrop = (crop) => {
    this.setState({ crop })
  }

  onImageLoaded = (image) => {
    // this.setState({ imageRef: image });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onCampaignSubmitInPopup() {
    if (this.state.campaignName === '') {
      swal('Campaign Name', 'Please Enter a Campaign Name!', 'error')
    } else if (this.state.crop.height === 0 || this.state.crop.width === 0) {
      swal('Crop Image', 'Please crop image before submitting!', 'error')
    } else if (
      this.state.adRedirectUrl &&
      !validateUrl(this.state.adRedirectUrl)
    ) {
      return swal('Error', 'Incorect Url!', 'error')
    } else {
      this.props.uploadBanner(
        this.state.crop,
        this.imageRef?.current,
        this.state.campaignName,
        this.state.adRedirectUrl
      )
    }
  }
  render() {
    return (
      <div className="crop-image">
        <Popup
          className={'popup'}
          open={this.props.open}
          onClose={this.closeModal}
        >
          <div className="popupInner">
            <div className="heading">Advertisement Image</div>
            <div className="crop-component">
              <img
                src={this.props.fileUrl}
                ref={this.imageRef}
                at="Avertisement Image"
                width="200px"
                height="auto"
              />
              {/* <ReactCrop
                src={this.props.fileUrl}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onChange={this.setCrop}
              /> */}
            </div>
            <div className="addAdvertCampaign">
              <div className="ad-input-group">
                <input
                  className="form-control inputBox"
                  onChange={this.handleChange}
                  name="campaignName"
                  value={this.state.campaignName}
                  type="text"
                  placeholder="Enter Campaign Name*"
                />
                <input
                  className="form-control inputBox"
                  onChange={this.handleChange}
                  name="adRedirectUrl"
                  value={this.state.adRedirectUrl}
                  type="text"
                  placeholder="Enter Redirect Url"
                />
              </div>
              <div className="ad-btn-group">
                <PrimaryButton
                  className="form-control"
                  onClick={this.onCampaignSubmitInPopup}
                >
                  Create
                </PrimaryButton>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    )
  }
}

export default AdvertCropComponent
