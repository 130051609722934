import React, { useEffect, useState } from 'react'
import { useRouter, useSelectStore } from 'hooks'
import { useTheme } from 'styled-components'
import { Div, FlexBox, Grid, PrimaryOutlineButton } from 'styledComponent'
import {
  Text,
  Tabs,
  ButtonDropdown,
  SectionHeading,
  Breadcrumb,
  Input,
  FilterPopup,
  useToasts,
  SelectStoreGroupModal,
  ActiveFilterPills,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import {
  $MarginlessLine,
  $TabPadding,
  $SearchInput,
  $SquareButton,
} from '../StoreManagement.styled'
import {
  ADD_NEW_OPTIONS,
  GET_FILTER_FIELD,
  INIT_ACCESS_FILTER,
  INIT_OTHER_FILTER,
  INIT_STORES_FILTER,
  SECT_HEADINGS,
  SHOW_FILTER_ARRAY,
} from '../constants'
import { BulkUploadForm } from '../forms'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { cloneDeep, isBoolean } from 'lodash'
import { ERROR_DECODE, GET_SEL_VALUES } from 'utils/reusables'
import styled from 'styled-components'
import { ExportReportIcon } from 'assets/icons'
import {
  exportBrand,
  exportCompany,
  exportStoreGroup,
  exportStoreKeys,
  exportStores,
} from 'redux/actions/storeMgtActions'

const ExportButton = styled(PrimaryOutlineButton)`
  height: 40px;
  width: 40px;
  padding: 0;
  position: relative;
  border-color: #33475b;
  svg {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background: #33475b;
    svg {
      path {
        fill: #fff;
      }
    }
  }
`
const searchPlaceholder = {
  Company: 'Company Name',
  Brands: 'Brand Name',
  'Retail Stores': 'Store Code or Display Address',
  'E-Commerce Stores': 'e-commerce Store Address',
  'Access Keys': 'Access Keys, Store Code or Display Address',
  'Store Groups': 'Store Groups Name',
}

const $Input = styled.input`
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`

export const SMTopBar = ({
  search,
  setSearch,
  filterQuery,
  setFilterQuery,
}) => {
  const { history, query } = useRouter()
  const { spacing } = useTheme()
  const { toast } = useToasts()

  const initFilter = query?.tab?.includes('Stores')
    ? INIT_STORES_FILTER
    : query?.tab === 'Access Keys'
    ? INIT_ACCESS_FILTER
    : INIT_OTHER_FILTER

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [isExport, setIsExport] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [fils, setFils] = useState(initFilter)

  const { data: companyOptions } = useSelectStore('strMgt.companyOptions')
  const { data: brandOptions } = useSelectStore('strMgt.brandOptions')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  useEffect(() => {
    setFils(initFilter)
  }, [query?.tab])

  useEffect(() => {
    const filQuery = {}
    Object.keys(fils).forEach((key) => {
      if (fils?.[key]?.isActive) {
        filQuery[GET_FILTER_FIELD(key, query?.tab)] = fils?.[key]?.value
      }
    })
    if (filterQuery?.storeIds?.length > 0) {
      filQuery.storeIds = filterQuery?.storeIds
    }
    setFilterQuery(filQuery)
  }, [fils])

  const getFilterInput = ({ fil, filter, setFilter }) => {
    const onRadioChange = (value) => {
      const newFil = cloneDeep(filter)
      newFil[fil?.type].value = value
      setFilter(newFil)
    }

    const onMultiChange = (sel) => {
      const newFil = cloneDeep(filter)
      if (fil?.type === 'company') {
        newFil.brand.value = []
      }
      newFil[fil?.type].value = sel?.map((val) => val?.value)
      setFilter(newFil)
    }

    const onEmailChange = (val) => {
      const newFil = cloneDeep(filter)
      newFil['emailList'].value = val
      setFilter(newFil)
    }

    const onInputChange = (e) => {
      console.log('e.target.value', e.target.value, fil.type)
      const newFil = cloneDeep(filter)
      newFil[fil.type].value = e.target.value
      setFilter(newFil)
    }

    switch (fil?.type) {
      case 'company': {
        return (
          <Input
            variant={fil?.inputType}
            isClearable={true}
            disabled={!fil?.isActive}
            options={companyOptions}
            value={GET_SEL_VALUES(fil?.value, companyOptions)}
            onChange={onMultiChange}
            inputWidth="100%"
            maxMenuHeight={180}
          />
        )
      }

      case 'brand': {
        return (
          <Input
            variant={fil?.inputType}
            isClearable={true}
            disabled={!fil?.isActive}
            options={brandOptions}
            value={GET_SEL_VALUES(fil?.value, brandOptions)}
            onChange={onMultiChange}
            inputWidth="100%"
            maxMenuHeight={180}
          />
        )
      }
      case 'bit':
      case 'status': {
        return (
          <Input
            variant={fil?.inputType}
            isClearable={true}
            disabled={!fil?.isActive}
            options={fil?.options}
            value={fil?.value}
            onChange={onRadioChange}
            inputWidth="100%"
            gap="24px"
          />
        )
      }

      case 'dateRange': {
        return (
          <Input
            variant={fil?.inputType}
            disabled={!fil?.isActive}
            value={fil?.value}
            onChange={onRadioChange}
            inputWidth="100%"
            maximumDate={new Date()}
          />
        )
      }

      case 'emailList': {
        return (
          <Input
            variant={fil?.inputType}
            disabled={!fil?.isActive}
            value={fil?.value}
            onChange={onEmailChange}
            placeholder="Enter Multiple E-Mail IDs"
            inputWidth="100%"
            isEmail
          />
        )
      }
      case 'macId':
      case 'version': {
        return (
          <Input
            variant={fil?.inputType}
            disabled={!fil?.isActive}
            value={fil?.value}
            onChange={onInputChange}
            placeholder={fil?.type === 'version' ? 'Version No.' : 'Mac Id'}
            inputWidth="100%"
          />
        )
      }
    }
  }

  const onSaveFilter = async (filter) => {
    const filQuery = {}
    Object.keys(filter).forEach((key) => {
      if (filter?.[key]?.isActive) {
        filQuery[GET_FILTER_FIELD(key, query?.tab)] = filter?.[key]?.value
      }
    })

    if (filQuery?.companyIds?.length === 0) {
      toast('Add atleast one company.', 'error')
      throw 'err'
    }

    if (filQuery?.brandIds?.length === 0) {
      toast('Add atleast one brand.', 'error')
      throw 'err'
    }

    if (filterQuery?.storeIds?.length > 0) {
      filQuery.storeIds = filterQuery?.storeIds
    }

    if (filter?.status?.isActive && !isBoolean(filter?.status?.value)) {
      toast('Select a status', 'error')
      throw 'err'
    }

    if (!isExport) {
      setFils(filter)
    } else {
      await onExportFilter(filQuery)
    }
  }

  const exportApi = (body) => {
    switch (query?.tab) {
      case 'Company':
        return exportCompany(body)
      case 'Brands':
        return exportBrand(body)
      case 'Store Groups':
        return exportStoreGroup(body)
      case 'Retail Stores':
        return exportStores({ ...body, type: 'offline' })
      case 'E-Commerce Stores':
        return exportStores({ ...body, type: 'online' })
      case 'Access Keys':
        return exportStoreKeys(body)
    }
  }

  const onExportFilter = async (query) => {
    try {
      query.emailList =
        query?.emailList?.filter(
          (email) =>
            email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
        ) || []
      if (query?.emailList?.length === 0) {
        throw 'Add at least one valid email.'
      }
      const body = {
        ...query,
        vendorId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      const res = await exportApi(body)
      if (res) {
        toast('You will receive your report through email shortly.', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw err
    }
  }

  const resetFilters = () => {
    setFils(initFilter)
    setSearch('')
    setFilterQuery({})
    setIsFilterOpen(false)
    setIsExport(false)
  }

  const onExport = () => {
    setIsFilterOpen(true)
    setIsExport(true)
  }

  const setSelectedStoreIds = (storeIds) => {
    if (storeIds?.length) setFilterQuery({ ...filterQuery, storeIds })
    else {
      const filQuery = { ...filterQuery }
      delete filQuery.storeIds
      setFilterQuery(filQuery)
    }
  }

  const handleClosePill = (_, type) => {
    const newFil = cloneDeep(fils)
    newFil[type] = initFilter?.[type]
    setFils(newFil)
  }

  return (
    <>
      <Navbar heading="Store Management" />
      <FlexBox m={spacing.l} justify="space-between">
        <Breadcrumb label="Store Management" />
        {query?.tab === 'Access Keys' && (
          <SelectStoreGroupModal
            selectedStoreIds={filterQuery?.storeIds || []}
            setSelectedStoreIds={setSelectedStoreIds}
          />
        )}
      </FlexBox>
      <$MarginlessLine />
      <Grid columns="auto auto" justify="space-between" align="center">
        <$TabPadding>
          <Tabs>
            <div label="Company" />
            <div label="Brands" />
            <div label="Retail Stores" />
            <div minWidth="180px" label="E-Commerce Stores" />
            <div label="Store Groups" />
            <div label="Access Keys" />
          </Tabs>
        </$TabPadding>
        <Div mr="12px">
          {query?.tab !== 'Access Keys' && (
            <ButtonDropdown startIcon buttonText="Add New">
              {ADD_NEW_OPTIONS?.map((opt) => (
                <Text
                  key={opt?.label}
                  variant="medium"
                  type="grey"
                  onClick={() => history.push(opt?.route)}
                >
                  {opt?.label}
                </Text>
              ))}
              <Text
                variant="medium"
                type="grey"
                onClick={() => setIsPopOpen(true)}
              >
                Bulk Upload Retail Stores
              </Text>
            </ButtonDropdown>
          )}
        </Div>
      </Grid>
      <BulkUploadForm isPopOpen={isPopOpen} setIsPopOpen={setIsPopOpen} />
      <$MarginlessLine />
      <FlexBox padding={spacing.l} justify="space-between">
        {SHOW_FILTER_ARRAY ? (
          <ActiveFilterPills
            pillData={Object.values(fils).filter((fil) => fil.isActive)}
            handleClosePill={handleClosePill}
            handleRemoveAll={resetFilters}
          />
        ) : (
          <SectionHeading
            {...SECT_HEADINGS[query?.tab]}
            variant="p"
            subVariant="small"
            color="main"
            weight="bold"
          />
        )}
        <FlexBox gap={spacing.m}>
          <$SearchInput>
            <span />
            <$Input
              id="searchTxt"
              value={search?.[query?.tab] || ''}
              placeholder={`Search by ${searchPlaceholder[query?.tab]}`}
              title={`Search by ${searchPlaceholder[query?.tab]}`}
              onChange={(e) =>
                setSearch({ ...search, [query?.tab]: e.target.value })
              }
            />
          </$SearchInput>
          {SHOW_FILTER_ARRAY?.includes(query?.tab) && (
            <$SquareButton
              isFilter
              size={40}
              onClick={() => setIsFilterOpen(true)}
            >
              <FilterIcon />
            </$SquareButton>
          )}
          <ExportButton onClick={onExport}>
            <ExportReportIcon />
          </ExportButton>
        </FlexBox>
      </FlexBox>
      <FilterPopup
        fils={fils}
        setFils={setFils}
        isExport={isExport}
        isPopOpen={isFilterOpen}
        setIsExport={setIsExport}
        onSaveFilter={onSaveFilter}
        resetFilters={resetFilters}
        setIsPopOpen={setIsFilterOpen}
        getFilterInput={getFilterInput}
      />
    </>
  )
}
