import _ from 'lodash'
import { capitalizeFirstLetter } from 'utils/stringManipulation'

import { OVERALL_SUMMARY } from './constants'

const USAGE_TYPE_LABEL = {
  otp: 'OTP',
  feedbackReply: 'Feedback Reply',
}

const getOtherUsageChannel = (usage) => {
  if (usage?._id?.type === 'billGen') return ''
  let channel = ''
  if (usage?._id?.channel)
    channel += `${capitalizeFirstLetter(usage?._id?.channel)}`
  if (usage?._id?.type)
    channel += ` - ${
      USAGE_TYPE_LABEL[usage?._id?.type] ||
      capitalizeFirstLetter(usage?._id?.type)
    }`
  return channel
}

export const convertApiResponseToTableData = (viewType, data) => {
  if (!data) return []
  if (viewType === OVERALL_SUMMARY) {
    const engagementUsage = data?.engagementUsage?.[0]
    const storeUsage = data?.storeUsage?.[0]
    const campaignUsage = data?.campaignUsage
    const otherUsage =
      data?.otherUsage?.filter((usage) => usage?._id?.type !== 'billGen') || []
    return [
      storeUsage && {
        viewType: 'Digital Billing',
        channels: [
          'smsCharge' in storeUsage && {
            channel: 'SMS',
            quantity: storeUsage?.smsQty,
            cost: storeUsage?.smsCharge,
          },
          'emailCharge' in storeUsage && {
            channel: 'Email',
            quantity: storeUsage?.emailQty,
            cost: storeUsage?.emailCharge,
          },
          'whatsAppCharge' in storeUsage && {
            channel: 'WhatsApp',
            quantity: storeUsage?.whatsAppQty,
            cost: storeUsage?.whatsAppCharge,
          },
        ].filter(Boolean),
        totalCharge: storeUsage?.totalCharge,
      },
      engagementUsage && {
        viewType: 'Auto Engagement',
        channels: [
          'smsCharge' in engagementUsage && {
            channel: 'SMS',
            quantity: engagementUsage?.smsQty,
            cost: engagementUsage?.smsCharge,
          },
          'emailCharge' in engagementUsage && {
            channel: 'Email',
            quantity: engagementUsage?.emailQty,
            cost: engagementUsage?.emailCharge,
          },
          'whatsAppCharge' in engagementUsage && {
            channel: 'WhatsApp',
            quantity: engagementUsage?.whatsAppQty,
            cost: engagementUsage?.whatsAppCharge,
          },
        ].filter(Boolean),
        totalCharge: engagementUsage?.totalCharge,
      },
      campaignUsage?.length && {
        viewType: 'Promotions',
        channels:
          campaignUsage?.map((campaign) => ({
            channel: capitalizeFirstLetter(campaign?._id || ''),
            quantity: campaign?.totalQty,
            cost: campaign?.totalCharge,
          })) || [],
        totalCharge: campaignUsage?.reduce(
          (prevCharge, currentCampaign) =>
            prevCharge + currentCampaign?.totalCharge,
          0
        ),
      },
      otherUsage?.length && {
        viewType: 'Other Usage',
        channels:
          otherUsage?.map((usage) => ({
            channel: getOtherUsageChannel(usage),
            quantity: usage?.totalQty,
            cost: usage?.totalCharge,
          })) || [],
        totalCharge: otherUsage?.reduce(
          (prevCharge, currentUsage) => prevCharge + currentUsage?.totalCharge,
          0
        ),
      },
    ].filter(Boolean)
  }
  return data?.docs?.map((doc) => ({
    ...doc,
    channels: [
      'smsCharge' in doc && {
        channel: 'SMS',
        quantity: doc?.smsQty,
        cost: doc?.smsCharge,
      },
      'emailCharge' in doc && {
        channel: 'Email',
        quantity: doc?.emailQty,
        cost: doc?.emailCharge,
      },
      'whatsAppCharge' in doc && {
        channel: 'WhatsApp',
        quantity: doc?.whatsAppQty,
        cost: doc?.whatsAppCharge,
      },
    ].filter(Boolean),
    channel: capitalizeFirstLetter(doc?.channel || ''),
    totalCharge: doc.totalCharge,
    totalQty: doc.totalQty,
    name: doc.name,
  }))
  // }
}

export const LIMIT = 10

export const convertFiltersToPayload = (
  filters = {},
  storeIds = [],
  search = '',
  page = 0,
  sortFilters = {},
  limit = LIMIT,
  isExport = false
) => {
  let baseObj = { search, storeIds }

  const sortParams = _(sortFilters).omitBy(_.isEmpty)
  if (!_(sortParams).isEmpty()) {
    baseObj = {
      ...baseObj,
      sort: sortParams,
    }
  }

  if (isExport) baseObj = { storeIds }
  else {
    baseObj = {
      ..._(baseObj)
        .omitBy((value) => _.isEmpty(value) || value === '' || _.isNil(value))
        .toJSON(),
      page,
      limit,
    }
  }
  return Object.values(filters)
    .filter((filter) => filter.isActive)
    .reduce((filterTillNow, currentFilter) => {
      switch (currentFilter.type) {
        case 'date-month-year':
          filterTillNow['month'] = `${currentFilter.value.month?.value}`
          filterTillNow['year'] = `${currentFilter.value.year?.value}`
          break
        case 'single-select':
          filterTillNow[currentFilter.name || currentFilter.id] =
            currentFilter.value.value
          break
        case 'multi-select':
          filterTillNow[currentFilter.name || currentFilter.id] =
            currentFilter.value.map((val) => val.value)
          break
        case 'range':
          const rangeObj = {}
          if (currentFilter.value.min !== '') {
            rangeObj['min'] = Number(currentFilter.value.min)
          }
          if (currentFilter.value.max !== '') {
            rangeObj['max'] = Number(currentFilter.value.max)
          }
          filterTillNow[currentFilter.name || currentFilter.id] = rangeObj
          break
        default:
          filterTillNow[currentFilter.name || currentFilter.id] =
            currentFilter.value
      }
      return filterTillNow
    }, baseObj)
}
