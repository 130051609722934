import React from 'react'
import { spacing } from 'design-system'
import { Div, FlexBox } from 'styledComponent'
import styled, { useTheme } from 'styled-components'
import { Text, Button } from 'components/Common'
import { LeftIcon } from 'assets/icons'

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || '100%'};
  margin: ${({ m }) => m || 'unset'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
`

const $TopControlsBlock = styled($FlexBox)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${spacing.s} ${spacing.l};
  border-top: 1px solid ${({ theme }) => theme.colors.darkMercury};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkMercury};
  border: ${(props) => props.hideTopBottomLine && 'none'};
`

const $LeftIcon = styled(LeftIcon)`
  width: 16px;
  margin-right: ${spacing.s};
`

const $Button = styled(Button)`
  padding: ${spacing.m} ${spacing.xl};
  width: ${({ width }) => width || '165px'};
  border-color: ${({ btnBorder, theme }) =>
    btnBorder ? theme.colors.primary.default : 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const $ExitBlock = styled($FlexBox)`
  width: max-content;
  padding-right: ${spacing.l};
  cursor: pointer;
`

export const TopActionBar = ({
  title,
  onExit,
  onSave,
  loading,
  buttonTitle,
  buttonVariant,
  btnBorder,
  extras,
  exitText,
  hideTopBottomLine = false,
  titleSize = 'p',
  ...rest
}) => {
  const theme = useTheme()
  return (
    <$TopControlsBlock
      hideTopBottomLine={hideTopBottomLine}
      justify="space-between"
      align="center"
    >
      <FlexBox align="center">
        {onExit && (
          <$ExitBlock
            onClick={onExit}
            width="100px"
            borderRight={`1px solid ` + theme.colors.darkMercury}
            justify="center"
            align="center"
          >
            <$LeftIcon />
            <Text variant={titleSize} type="primary">
              {exitText}
            </Text>
          </$ExitBlock>
        )}
        <Div pl={onExit ? spacing.l : ''}>
          <Text variant="p" weight="semi-bold">
            {title}
          </Text>
        </Div>
      </FlexBox>
      <FlexBox>
        {onSave && (
          <$Button
            variant={buttonVariant}
            onClick={onSave}
            disabled={loading}
            btnBorder={btnBorder}
            {...rest}
          >
            {buttonTitle}
          </$Button>
        )}
        {extras}
      </FlexBox>
    </$TopControlsBlock>
  )
}

TopActionBar.defaultProps = {
  buttonTitle: 'Save',
  buttonVariant: 'primary',
  title: 'Add New Product',
}
