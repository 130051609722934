import React from 'react'
import { useEditor, useNode } from '@craftjs/core'

import { $Block, $FlexBox } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'
import { PropertyBar } from 'builder/editor/common/PropertyBar'

export const ColumnsSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = props
  const parentPadding = `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`

  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
    align: 'center',
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        hideDelete
        header={props.isSocial ? 'Social' : 'Columns'}
        onDelete={() => actions.delete(id)}
      />

      <PropertyBar
        isFlex
        type="number"
        label="No. of sections (Max. 5)"
        propertyName="sections"
        margin="8px 0px"
        craftProps={props}
        setProp={setProp}
        max={5}
      />

      <$Block>
        <hr />
        <$FlexBox justify="space-between">
          <p>Padding</p>
          <p>{parentPadding}</p>
        </$FlexBox>

        <$FlexBox justify="space-between">
          <$Block width="48%">
            <PropertyBar
              isFlex
              min={0}
              label="Top"
              propertyName="paddingTop"
              gap="20px"
              type="number"
              {...commonProps}
            />
          </$Block>
          <$Block width="48%">
            <PropertyBar
              isFlex
              min={0}
              label="Right"
              propertyName="paddingRight"
              gap="20px"
              type="number"
              {...commonProps}
            />
          </$Block>
          <$Block width="48%">
            <PropertyBar
              isFlex
              min={0}
              label="Bottom"
              propertyName="paddingBottom"
              gap="20px"
              type="number"
              {...commonProps}
            />
          </$Block>
          <$Block width="48%">
            <PropertyBar
              isFlex
              min={0}
              label="Left"
              propertyName="paddingLeft"
              gap="20px"
              type="number"
              {...commonProps}
            />
          </$Block>
        </$FlexBox>
        <hr />
      </$Block>
    </$Block>
  )
}
