import React from 'react'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import logo from 'images/Logo.png'
import { NewStarIcon } from 'styledComponent'

const PersonalInfoKey = [
  'phone',
  'email',
  'firstName',
  'lastName',
  'birthDate',
  'gender',
  'os',
  'city',
  'state',
  'profession',
  'companyName',
  'married',
  'anniversaryDate',
  'spouseFirst',
  'spouseLast',
]

const LOCATION_DATA = [
  'TELANGANA',
  'ANDHRA PRADESH',
  'PONDICHERRY',
  'ASSAM',
  'Dibrugarh',
  'BIHAR',
  'Gopalganj',
  'CHATTISGARH',
  'DELHI',
  'GUJARAT',
  'DAMAN & DIU',
  'DADRA & NAGAR HAVELI',
  'HARYANA',
  'Haryana',
  'HIMACHAL PRADESH',
  'JAMMU & KASHMIR',
  'Kargil',
  'JHARKHAND',
  'KARNATAKA',
  'Bijapur(KAR)',
  'KERALA',
  'LAKSHADWEEP',
  'MADHYA PRADESH',
  'Maharashtra',
  'GOA',
  'MANIPUR',
  'Thoubal',
  'Imphal West',
  'Chandel',
  'MIZORAM',
  'NAGALAND',
  'TRIPURA',
  'ARUNACHAL PRADESH',
  'MEGHALAYA',
  'Jaintia Hills',
  'ODISHA',
  'CHANDIGARH',
  'PUNJAB',
  'RAJASTHAN',
  'Barmer',
  'Jhujhunu',
  'TAMIL NADU',
  'Kanchipuram',
  'Tirunelveli',
  'UTTAR PRADESH',
  'Varanasi',
  'UTTARAKHAND',
  'Ballia',
  'WEST BENGAL',
  'ANDAMAN & NICOBAR ISLANDS',
  'SIKKIM',
]

const AVG_FEEDBACK_OPTIONS = [
  {
    value: 0,
    label: (
      <div>
        0 <NewStarIcon />
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div>
        1 <NewStarIcon />
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div>
        2 <NewStarIcon />
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div>
        3 <NewStarIcon />
      </div>
    ),
  },
  {
    value: 4,
    label: (
      <div>
        4 <NewStarIcon />
      </div>
    ),
  },
  {
    value: 5,
    label: (
      <div>
        5 <NewStarIcon />
      </div>
    ),
  },
]

const SEGMENT_OPTIONS = [
  {
    value: 'newCust',
    label: 'New Customers',
  },
  {
    value: 'growth',
    label: 'Growth',
  },
  {
    value: 'vip',
    label: "Can't Loose",
  },
  {
    value: 'risk',
    label: 'Need Attention',
  },
  {
    value: 'lapse',
    label: 'Lapse',
  },
  {
    value: 'lost',
    label: 'Lost',
  },
]

const moment = extendMoment(originalMoment)

const initialFilter = {
  noOfVisits: {
    id: 1,
    isActive: false,
    label: 'No. of Visits',
    value: {
      min: 0,
      max: 0,
    },
  },
  expenses: {
    id: 2,
    isActive: false,
    label: 'Expenses',
    value: {
      total: {
        isActive: false,
        min: 0,
        max: 0,
      },
      avg: {
        isActive: false,
        min: 0,
        max: 0,
      },
    },
  },
  avgFeedback: {
    id: 3,
    isActive: false,
    label: 'Avg. Feedback',
    value: {
      min: 0,
      max: 5,
    },
  },
  segmentsValue: {
    id: 4,
    isActive: false,
    label: 'Segmentation',
    value: [],
  },
  location: {
    id: 5,
    isActive: false,
    label: 'Location',
    value: {
      states: [],
      cities: [],
    },
  },
  gender: {
    id: 6,
    isActive: false,
    label: 'Gender',
    value: {
      male: false,
      female: false,
    },
  },
  os: {
    id: 7,
    isActive: false,
    label: 'OS',
    value: {
      google: false,
      iphone: false,
      other: false,
    },
  },
}

const ACTIVITY_TYPE = {
  transaction: 'transaction',
}

export {
  LOCATION_DATA,
  AVG_FEEDBACK_OPTIONS,
  SEGMENT_OPTIONS,
  initialFilter,
  PersonalInfoKey,
  ACTIVITY_TYPE,
}
