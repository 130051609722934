import styled from 'styled-components'
import { borderRadius, spacing } from 'design-system'

const $CategoryBlock = styled.div`
  padding-top: ${spacing.l};
`

const $CategoryList = styled.ul`
  padding: 0;
  list-style: none;
`

const $CategoryListItem = styled.li`
  padding: ${spacing.m} 0;
`

const $CategoryColor = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color, theme }) => color || theme.colors.dark};
  border-radius: ${borderRadius.large};
  margin-right: 5px;
`

const $LinkBlock = styled.div`
  padding-top: ${spacing.l};
  text-align: center;
  a {
    font-size: 14px;
    text-decoration: underline;
  }
`

export {
  $CategoryBlock,
  $CategoryList,
  $CategoryListItem,
  $CategoryColor,
  $LinkBlock,
}
