import React from 'react'

import TemplateForm from './TemplateForm'
import TemplateContent from './TemplateContent'
import PreviewAndSubmit from './PreviewAndSubmit'

const TemplateCampaign = ({ active, form, setForm, hideSteps, waAccounts }) => {
  if (hideSteps)
    return (
      <PreviewAndSubmit
        form={form}
        setForm={setForm}
        hideSteps={hideSteps}
        waAccounts={waAccounts}
      />
    )

  switch (active) {
    case 'Template Details':
      return (
        <TemplateForm form={form} setForm={setForm} waAccounts={waAccounts} />
      )

    case 'Content':
      return <TemplateContent form={form} setForm={setForm} />

    case 'Preview & Submit':
      return (
        <PreviewAndSubmit form={form} setForm={setForm} hideSteps={hideSteps} />
      )

    default:
      return null
  }
}

export default TemplateCampaign
