import React from 'react'
import { FormattedNumber, FormattedDate, FormattedList } from 'react-intl'
import * as moment from 'moment'
import {
  $ContentDiv,
  $RemoveButton,
} from 'components/Content/consumerAnalytics/Customers/Customers.styled'
import { spacing } from 'design-system'
import { PillArray, Pill, Text } from 'components/Common'
import { FlexBox, NewStarIcon } from 'styledComponent'
import {
  CAMP_STATES,
  CAMP_TYPES,
  GET_SEL_VALUE,
} from 'components/Content/autoEngagement/constants'
import { useSelectStore } from 'hooks'
import { isBoolean } from 'lodash'

const getActivePillData = (
  type,
  label,
  value,
  currencyCode = 'INR',
  emptyPlaceholder = null
) => {
  switch (type) {
    case 'range':
      return `${label} : ${
        value.min === '' ? emptyPlaceholder?.min || '' : value.min
      } to ${value.max === '' ? emptyPlaceholder?.max || '' : value.max}`
    case 'date':
      return (
        <>
          {label} {' : '} <FormattedDate value={value.start} /> {' to '}{' '}
          <FormattedDate value={value.end} />
        </>
      )
    case 'expense':
      return (
        <>
          {label}{' '}
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={value.min}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
          {' to '}
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={value.max}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </>
      )
    case 'star':
      return (
        <>
          {label} {' : '} {value.min} <NewStarIcon /> {' to '} {value.max}{' '}
          <NewStarIcon />
        </>
      )
    case 'count':
      return `${label} : ${value}`
    case 'conjunction':
      return (
        <>
          {label} {' : '} <FormattedList type="conjunction" value={value} />
        </>
      )
    case 'states':
      return `${value.length} states`
    case 'status':
      return isBoolean(value)
        ? `Status: ${value ? 'Active' : 'Inactive'}`
        : value.length < 2
        ? GET_SEL_VALUE(value[0], CAMP_STATES)?.label || ''
        : value.map((val) => GET_SEL_VALUE(val, CAMP_STATES)?.label)?.join(', ')
    case 'types': {
      return value.length < 2
        ? GET_SEL_VALUE(value[0], CAMP_TYPES)?.label || ''
        : value.length + ' types'
    }
    case 'gender':
      const gender = []
      value.male && gender.push('Male')
      value.female && gender.push('Female')
      value.other && gender.push('Others')
      return (
        <>
          {gender.length !== 0 && (
            <FormattedList type="conjunction" value={gender} />
          )}
        </>
      )
    case 'relationship':
      const relationship = []
      value.married && relationship.push('Married')
      value.unmarried && relationship.push('Unmarried')
      return (
        <>
          {relationship.length !== 0 && (
            <FormattedList type="conjunction" value={relationship} />
          )}
        </>
      )
    case 'timeFrame':
      const [start, end] = value.toString().split('/')
      return `${moment(start).format('DD-MMM-YYYY')} to ${moment(end).format(
        'DD-MMM-YYYY'
      )}`
    case 'dateRange':
      return `${moment(value?.startDate).format('DD-MMM-YYYY')} to ${moment(
        value?.endDate
      ).format('DD-MMM-YYYY')}`
    case 'brands':
    case 'brand':
      return `${value.length} brands`
    case 'company':
      return `${value.length} companies`
    case 'stores':
      return `${value.min} to ${value.max} stores`
    case 'date-month-year':
      return `${value.month?.label || ''} ${value.year?.label || ''}`
    case 'single-select':
      return `${value.label}`
    case 'multi-select':
      return `${value?.map((option) => option?.label).join(', ')}`
    default:
      return label
  }
}

const ActiveFilterPill = React.forwardRef(
  ({ pillData, handleClosePill, handleRemoveAll }, ref) => {
    const {
      data: { currencyCode },
    } = useSelectStore('auth.user')
    return (
      <FlexBox expand>
        {pillData.length !== 0 && (
          <>
            <Text mr={spacing.m}>Filters : </Text>
            <PillArray width="80%" wrap="wrap">
              {pillData.map(
                ({ type, label, value, id, emptyPlaceholder }, index) => {
                  return (
                    <FlexBox key={index}>
                      <Pill
                        margin={`0 ${spacing.s} ${spacing.s} 0`}
                        variant="filter"
                        type="tab"
                        onClose={() => handleClosePill(id, type)}
                      >
                        {getActivePillData(
                          type,
                          label,
                          value,
                          currencyCode,
                          emptyPlaceholder
                        )}
                      </Pill>
                    </FlexBox>
                  )
                }
              )}
            </PillArray>
            <$ContentDiv
              margin={`0 0 0 ${spacing.l}`}
              flex
              align="center"
              width="25%"
            >
              {/* <$Button onClick={() => ref.current.openModal()}>
                <AddIcon />
              </$Button> */}
              <$RemoveButton
                variant="textualError"
                noBorder
                onClick={handleRemoveAll}
              >
                Remove all filters
              </$RemoveButton>
            </$ContentDiv>
          </>
        )}
      </FlexBox>
    )
  }
)

export default ActiveFilterPill
