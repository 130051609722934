import createReducer from 'utils/createReducer'
import * as types from 'redux/types/settings'

const INITIAL_STATE = {
  crm: null,
  settingsLoading: false,
  settingsTableLoading: false,
  smsHeadersList: [],
  smsTemplatesList: [],
}

export default createReducer(INITIAL_STATE)(types)
