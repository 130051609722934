import React from 'react'
import { $FlexItem } from '../../ProductAnalytics.styled'
import { $ProductListItem } from '../../AllCategories/Products/Products.styled'
import { ActionCard, Text } from 'components/Common'
import ColumnBox from '../../AllCategories/ColumnBox'

const UnregisteredProductsCol = ({ unregisteredProducts, addClick }) => {
  if (!unregisteredProducts?.length) {
    return null
  }

  return (
    <ColumnBox
      colWidth="100%"
      title="Display Name"
      headerControls={
        <Text variant="p" weight="semi-bold" type="main">
          Actions
        </Text>
      }
    >
      <div>
        {unregisteredProducts.map((unregisteredProduct) => (
          <$ProductListItem key={unregisteredProduct._id}>
            <$FlexItem flexBasis="100%" style={{ overflow: 'auto' }}>
              <Text
                title={unregisteredProduct.displayName}
                variant="p"
                type="main"
                ellipsis
              >
                {unregisteredProduct.displayName}
              </Text>
            </$FlexItem>
            <$FlexItem flexBasis="50px">
              <ActionCard
                onIconClick={() => addClick(unregisteredProduct)}
                components={['add']}
                item="Product"
              />
            </$FlexItem>
          </$ProductListItem>
        ))}
      </div>
    </ColumnBox>
  )
}

export default UnregisteredProductsCol
