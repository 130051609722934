const initialState = {
  paymentHtml: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYMENTS_HTML":
      return {
        paymentHtml: action.payload
      };
    default:
      return state;
  }
}
