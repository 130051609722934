import { useRouter } from 'hooks'
import React from 'react'
import { post } from 'utils/axiosHandler'

const SSO = () => {
  const { history } = useRouter()

  const ssoCall = async () => {
    let [_, query] = window.location.href.split('?')
    const loginPath = '/login'

    query = new URLSearchParams(
      Object.fromEntries(new URLSearchParams(query))
    ).toString()

    if (!query) {
      history.push(loginPath)
    }

    try {
      const response = await post(`/auth/sso?${query}`)

      if (response?.data?.redirectUri) {
        window.location.href = response.data.redirectUri
      } else {
        history.push(`${loginPath}`)
      }
    } catch (error) {
      if (error?.response?.status >= 500) {
        history.push(`${loginPath}`)
      } else {
        history.push(`${loginPath}?${query}`)
      }
    }
  }

  React.useEffect(() => {
    ssoCall()
  }, [])

  return <div>Please wait, logging you in.</div>
}

export default SSO
