import React from 'react'
import { useTheme } from 'styled-components'
import { $CampaignContainer } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { Audience } from 'components/Content/autoEngagement/components/Audience'
import { JourneyTopBar } from './JourneyTopBar'
import { useToasts } from 'components/Common'

export const JourneyAudience = ({
  title,
  updJourney,
  setJourneySteps,
  journeyDetails,
  setJourneyDetails,
}) => {
  const { spacing } = useTheme()
  const { toast } = useToasts()

  const targetGroup = {
    brandId: journeyDetails.brandId,
    storeIds: journeyDetails.storeIds,
  }

  const setTargetGroup = (target) => {
    const { brandId, storeIds = [] } = target?.targetGroup || {}
    setJourneyDetails({ brandId, storeIds })
  }

  const onNextClick = () => {
    const { brandId, storeIds } = journeyDetails
    if (!brandId || storeIds.length === 0) {
      toast('Select a brand and at least one store', 'error')
      return
    }
    updJourney(journeyDetails, () => setJourneySteps(3))
  }

  const onExitClick = () => {
    const { brandId, storeIds } = journeyDetails
    if (!brandId || storeIds.length === 0) {
      toast('Brand and store data is not saved.', 'error')
      return
    }
    updJourney(journeyDetails)
  }

  return (
    <>
      <JourneyTopBar
        progress="50%"
        title={title}
        onNextClick={onNextClick}
        onPreviousClick={() => setJourneySteps(1)}
        onExitClick={onExitClick}
      />
      <$CampaignContainer m={spacing.l}>
        <Audience
          targetForm={{ targetGroup }}
          setTargetForm={setTargetGroup}
          isJourney
        />
      </$CampaignContainer>
    </>
  )
}
