import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'

import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import 'react-datepicker/dist/react-datepicker.css'
import BillingInfoCard from './BillingInfoCard'
import BillingInfoCardCircle from './BillingInfoCardCircle'
import formatDate from '../../../utils/getDateFormat'
import '../../../css/content/billingAnalytics.scss'
import {
  getBillingAnalyticsMaxMin,
  getTotalTransactions,
  getTotalSalesAndAvg,
  exportBAnalyticsReport,
} from '../../../redux/actions/billingAnalyticsActions'
import Popups from '../../Layout/PopupMain'
import Navbar from '../../Layout/Navbar'
import { mcDonaldsVendor } from 'components/conditionalComponent'
import { compareDatePermission } from '../../../utils/getDateFormat'
import { Breadcrumb, MyToast, EmailReportPopup } from 'components/Common'
import { StoreHeading } from 'components/Layout/StoresHeader'
import { SimpleOutlineButton } from 'styledComponent/components/button'
import { FlexBox, Div, PrimaryButton, ExportIcon } from 'styledComponent'
import { spacing } from 'design-system'
import { ERROR_DECODE } from '../autoEngagement/constants'

const moment = extendMoment(originalMoment)
var dtoday = new Date()
var dyesterday = new Date(Date.now() - 864e5)

class BillingAnalytics extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    const now = moment()
    this.state = {
      value: moment.range(now.clone().startOf('day'), now.clone()),
      innerDates: null,
      todayCheck: true,
      yesterdayCheck: false,
      customCheck: false,
      mainClassName: 'mainInfoCard today shadow p-1 mb-1 bg-white rounded',
      totalSalesPopup: false,
      avgBillingPopup: false,
      highestLowestPopup: false,
      transactionsPopup: false,
      totalTransactionsPopup: false,
      showCalendar: false,
      selectedStoreIds: [],
      isPopOpen: false,
      isEmailPopOpen: false,
    }
    // this.handleChange = this.handleChange.bind(this)
    this.checkDate = this.checkDate.bind(this)
    this.openTotalSales = this.openTotalSales.bind(this)
    this.openAvgBilling = this.openAvgBilling.bind(this)
    this.openHighestLowest = this.openHighestLowest.bind(this)
    this.openTransactions = this.openTransactions.bind(this)
    this.openTotalTrans = this.openTotalTrans.bind(this)
  }

  // handleChange(date) {
  //   this.setState({
  //     startDate: date,
  //     mainClassName:
  //       'mainInfoCard bounceInLeft shadow p-1 mb-1 bg-white rounded',
  //   })
  //   this.checkDate(date)
  //   this.props.getBillingAnalyticsMaxMin(sendObject2(date, null))
  //   this.props.getTotalTransactions(sendObject2(date, null))
  //   this.props.getTotalSalesAndAvg(sendObject2(date, null))
  //   this.props.getBillingAnalyticsMaxMin(sendObject2(date, null))
  // }

  // todayClick = () => {
  //   this.handleChange(dtoday)
  // }

  // yesterdayClick = () => {
  //   this.setState({
  //     ...this.state,
  //     value: { ...this.state.value, startDate: moment(dyesterday) },
  //   })
  //   this.handleDateRangeChange({
  //     ...this.state,
  //     value: {
  //       ...this.state.value,
  //       start: moment(dyesterday),
  //       end: this.state.value.endDate,
  //     },
  //   })
  //   // this.handleChange(dyesterday);
  // }

  dashBoardStyle =
    mcDonaldsVendor === this.props.vendorEmail
      ? {
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          marginRight: '4%',
        }
      : {}

  transCardClass =
    mcDonaldsVendor === this.props.vendorEmail ? 'transCard' : 'transactionCard'

  checkDate(date) {
    if (date.getDate() === dtoday.getDate()) {
      this.setState({
        todayCheck: true,
        yesterdayCheck: false,
        customCheck: false,
        mainClassName:
          'mainInfoCard bounceIn bounceInLeft shadow p-1 mb-1 bg-white rounded',
      })
    } else if (date.getDate() === dyesterday.getDate()) {
      this.setState({
        todayCheck: false,
        yesterdayCheck: true,
        customCheck: false,
        mainClassName:
          'mainInfoCard bounceIn bounceInRight shadow p-1 mb-1 bg-white rounded',
      })
    } else {
      this.setState({
        todayCheck: false,
        yesterdayCheck: false,
        customCheck: true,
        mainClassName: 'mainInfoCard bounceIn shadow p-1 mb-1 bg-white rounded',
      })
    }
  }

  //charts functions starts here
  openTotalSales() {
    this.setState({ totalSalesPopup: !this.state.totalSalesPopup })
  }

  openAvgBilling() {
    this.setState({ avgBillingPopup: !this.state.avgBillingPopup })
  }

  openHighestLowest() {
    this.setState({ highestLowestPopup: !this.state.highestLowestPopup })
  }

  openTransactions() {
    this.setState({ transactionsPopup: !this.state.transactionsPopup })
  }

  openTransactionsBm = () => {
    this.setState({ transactionsBmPopup: !this.state.transactionsBmPopup })
  }
  openTransactionsBmP = () => {
    this.setState({ transactionsBmPPopup: !this.state.transactionsBmPPopup })
  }
  openTransactionsP = () => {
    this.setState({ transactionsPPopup: !this.state.transactionsPPopup })
  }
  openTransactionsCW = () => {
    this.setState({ transactionsCWPopup: !this.state.transactionsCWPopup })
  }

  openTotalTrans() {
    this.setState({
      totalTransactionsPopup: !this.state.totalTransactionsPopup,
    })
  }

  onChartClick = (type) => {
    switch (type) {
      case 'BM':
        return this.openTransactionsBm()
      case 'BMPrint':
        return this.openTransactionsBmP()
      case 'Print':
        return this.openTransactionsP()
      case 'C.W':
        return this.openTransactionsCW()
    }
  }

  //charts functions end here
  handleClick = (e) => {
    e.preventDefault()
    this.setState({ ...this.state, showCalendar: !this.state.showCalendar })
  }
  handleDateRangeChange = (values) => {
    const startDate = new Date(values.start.format())
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date(values.end.format())
    endDate.setHours(23, 59, 59, 0)
    if (
      !compareDatePermission(
        this.props.selectedVendor.featuresAlloted.exportRange,
        startDate
      )
    ) {
      this.context.toast('You can only access last 2 months data', 'warning')
      return false
    }
    this.setState({ value: values })
    this.setState({ ...this.state, showCalendar: !this.state.showCalendar })

    this.props.getBillingAnalyticsMaxMin({
      startDate,
      endDate,
      selectedStoreIds: this.state.selectedStoreIds,
    })
    this.props.getTotalTransactions({
      startDate,
      endDate,
      selectedStoreIds: this.state.selectedStoreIds,
    })
    this.props.getTotalSalesAndAvg({
      startDate,
      endDate,
      selectedStoreIds: this.state.selectedStoreIds,
    })
  }

  handleInnerDateChange = (values) => {
    this.setState({ innerDates: values })
  }

  onSendReport = async (emailList) => {
    const startDate = moment(this.state.value.start.toDate())
    const endDate = moment(this.state.value.end.toDate())

    if (endDate.diff(startDate, 'days') > 31) {
      return this.context.toast(
        'Report can be generated only for 31 days. Please select appropriate dates',
        'error'
      )
    }

    const body = {
      emailList,
      startDate: startDate?.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      storeIds:
        this.state.selectedStoreIds?.length > 0
          ? this.state.selectedStoreIds
          : Object.keys(this.props.stores),
    }

    try {
      const res = await exportBAnalyticsReport(body)
      if (res) this.context.toast(res?.message, 'success')
    } catch (err) {
      this.context.toast(ERROR_DECODE(err), 'error')
    }
  }

  render() {
    const selectedDate = formatDate(this.state.value.start.toDate())
    const selectedEnd = formatDate(this.state.value.end.toDate())
    const currency = this.props.currencyCode

    return (
      <div className="billingAnalytics">
        <Navbar heading="Billing Analytics" />
        <EmailReportPopup
          isPopOpen={this.state.isEmailPopOpen}
          setIsPopOpen={(bool) => this.setState({ isEmailPopOpen: bool })}
          onSendReport={this.onSendReport}
        />
        <Div backgroundColor="#FFF">
          <FlexBox p={spacing.l} justify="space-between">
            <Breadcrumb label="Billing Analytics" />
            <FlexBox ml="8px">
              <Div mr="8px" className="billingDatePicker">
                <button
                  className="datePickerButton"
                  onClick={this.handleClick}
                  style={{ height: '100%', marginLeft: '0', width: '100%' }}
                >
                  <i className="fas fa-calendar-alt" />{' '}
                  {selectedDate + ' - ' + selectedEnd}
                </button>
                {this.state.showCalendar && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '100',
                      backgroundColor: '#fff',
                    }}
                  >
                    <DateRangePicker
                      onSelect={this.handleDateRangeChange}
                      singleDateRange={true}
                      value={this.state.value}
                      maximumDate={new Date()}
                    />
                  </div>
                )}

                {/*<DatePicker*/}
                {/*  customInput={<ExampleCustomInput/>}*/}
                {/*  selected={this.state.startDate.toDate()}*/}
                {/*  onChange={this.handleChange}*/}
                {/*  calendarClassName="billingCalender"*/}
                {/*/>*/}
              </Div>
              <Div className="header_btn">
                <SimpleOutlineButton
                  type="button"
                  className="mr-2 py-2 "
                  onClick={() => this.setState({ isPopOpen: true })}
                >
                  <StoreHeading
                    selectedStoreIds={this.state.selectedStoreIds}
                    setSelectedStoreIds={(selectedStoreIds) =>
                      this.setState({ selectedStoreIds })
                    }
                    isPopOpen={this.state.isPopOpen}
                    setIsPopOpen={(isPopOpen) => this.setState({ isPopOpen })}
                    showStatus
                  />
                </SimpleOutlineButton>
              </Div>
              <PrimaryButton
                type="button"
                className="d-inline-flex px-4 mx-0 filter-btn"
                onClick={() => this.setState({ isEmailPopOpen: true })}
              >
                <ExportIcon className="mr-2" />
                Export
              </PrimaryButton>
            </FlexBox>
          </FlexBox>
        </Div>
        <div className={this.state.mainClassName}>
          {/*<div className="dateInfo">*/}
          {/*  <div className="dateYesterday date" onClick={this.yesterdayClick}>*/}
          {/*    {!this.state.yesterdayCheck ? (*/}
          {/*      <div>*/}
          {/*        <i className="fas fa-arrow-left"></i> Yesterday*/}
          {/*      </div>*/}
          {/*    ) : null}*/}
          {/*  </div>*/}

          {/*  <div className="dateManual date">*/}
          {/*    {this.state.customCheck ? <div className="dayInfo date"/> : null}*/}
          {/*    {this.state.todayCheck ? (*/}
          {/*      <div className="dayInfo date">Today</div>*/}
          {/*    ) : null}*/}
          {/*    {this.state.yesterdayCheck ? (*/}
          {/*      <div className="dayInfo date">Yesterday</div>*/}
          {/*    ) : null}*/}
          {/*    <div className="selectedDate">*/}
          {/*      <b>{selectedDate}</b>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="dateToday date" onClick={this.todayClick}>*/}
          {/*    {!this.state.todayCheck ? (*/}
          {/*      <div>*/}
          {/*        Today <i className="fas fa-arrow-right"></i>*/}
          {/*      </div>*/}
          {/*    ) : null}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div
            style={this.dashBoardStyle}
            className="BillingInfoCardLine grid-container"
          >
            {mcDonaldsVendor !== this.props.vendorEmail && (
              <>
                <BillingInfoCard
                  imageName="./images/total_sale.png"
                  cardName="Total Sales"
                  cardName2={
                    <FormattedNumber
                      style="currency"
                      value={this.props.bAnalytics.total.totalSales}
                      currency={currency}
                    />
                  }
                  onClick={this.openTotalSales}
                />
                <BillingInfoCard
                  imageName="./images/avg_billing.png"
                  cardName="Avg. Billing Amount"
                  cardName2={
                    <FormattedNumber
                      style="currency"
                      value={this.props.bAnalytics.total.avgBilling}
                      currency={currency}
                    />
                  }
                  onClick={this.openAvgBilling}
                />
              </>
            )}
            <BillingInfoCard
              imageName="./images/total_transactions.png"
              cardName="Total Transactions"
              cardName2={`${this.props.bAnalytics.noOfTransactions.total}`}
              onClick={this.openTotalTrans}
            />
          </div>
          {mcDonaldsVendor !== this.props.vendorEmail && (
            <div className="billingInfoCardCircleLine">
              <BillingInfoCardCircle
                imageName="./images/lowest_bill.png"
                circleCardName1="Highest Bill"
                circleCardName2={
                  <FormattedNumber
                    value={this.props.bAnalytics.max || '0'}
                    style="currency"
                    notation="compact"
                    currency={currency}
                  />
                }
                onClick={this.openHighestLowest}
              />

              <BillingInfoCardCircle
                imageName="./images/highest_bill.png"
                circleCardName1="Lowest Bill"
                circleCardName2={
                  <FormattedNumber
                    value={this.props.bAnalytics.min || '0'}
                    style="currency"
                    currency={currency}
                    notation="compact"
                  />
                }
                onClick={this.openHighestLowest}
              />
            </div>
          )}

          <div className="BillingInfoCardLine transactionCards">
            <BillingInfoCard
              className={this.transCardClass}
              imageName="./images/print_transaction.png"
              cardName="Print Transactions"
              cardName2={`${this.props.bAnalytics.noOfTransactions.print}`}
              onClick={this.openTransactions}
            />
            <BillingInfoCard
              className={this.transCardClass}
              imageName="./images/bm_print.png"
              cardName="BillMe Transactions"
              cardName2={`${this.props.bAnalytics.noOfTransactions.BM}`}
              onClick={this.openTransactions}
            />
            <BillingInfoCard
              className={this.transCardClass}
              imageName="./images/billme_transactions.png"
              cardName="BM+Print Transactions"
              cardName2={`${this.props.bAnalytics.noOfTransactions.BMprint}`}
              onClick={this.openTransactions}
            />
            {mcDonaldsVendor !== this.props.vendorEmail && (
              <BillingInfoCard
                className="transactionCard"
                imageName="./images/close_window.svg"
                cardName="CW Transactions"
                cardName2={`${this.props.bAnalytics.noOfTransactions.CW}`}
                onClick={this.openTransactions}
              />
            )}
          </div>
        </div>

        {this.state.totalSalesPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openTotalSales}
            popupType="TotalSalesChart"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.avgBillingPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openAvgBilling}
            popupType="AvgBillingChart"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.highestLowestPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openHighestLowest}
            popupType="HighestLowestChart"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.transactionsPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openTransactions}
            popupType="TransactionsChart"
            onChartClick={this.onChartClick}
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.totalTransactionsPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openTotalTrans}
            popupType="TotalTransactionsChart"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.transactionsBmPPopup ? (
          <Popups
            openPopup={true}
            onClick={this.openTransactionsBmP}
            popupType="TransactionsBmP"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.transactionsBmPopup ? (
          <Popups
            openPopup
            onClick={this.openTransactionsBm}
            popupType="TransactionsBm"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.transactionsCWPopup ? (
          <Popups
            openPopup
            onClick={this.openTransactionsCW}
            popupType="TransactionsCW"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}

        {this.state.transactionsPPopup ? (
          <Popups
            openPopup
            onClick={this.openTransactionsP}
            popupType="TransactionsP"
            selectedDate={this.state.innerDates || this.state.value}
            handleDateChange={this.handleInnerDateChange}
            selectedStoreIds={this.state.selectedStoreIds}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bAnalytics: state.bAnalytics,
  errors: state.errors,
  vendorEmail: state.auth.user.email,
  selectedVendor: state.vendorIds.selectedVendor,
  stores: state.stores.stores,
  currencyCode: state.auth.user.currencyCode,
})

export default connect(mapStateToProps, {
  getBillingAnalyticsMaxMin,
  getTotalTransactions,
  getTotalSalesAndAvg,
})(BillingAnalytics)
