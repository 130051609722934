import React from 'react'
import { useTheme } from 'styled-components'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import { useSelectStore } from 'hooks'
import { FlexBox, Div } from 'styledComponent'
import { Text } from 'components/Common'

import { PublishItem } from './PublishItem'
import { $BlueBox, $StatsTable, $StoreTags } from '../AutoEngagement.styled'
import {
  GET_SEL_VALUE,
  POSITION_TYPES,
  CAMPAIGN_STATS,
  BUILDER_INIT,
} from '../constants'
import { UploadBox } from './MediaUpload'
import { YoutubeComponent } from './BuilderComponents'
import { LinkHoverRedirect } from 'components/Common/LinkHoverRedirect'
import { useSelector } from 'react-redux'
import { ColorBlockWithValue } from './BuilderComponents'

const stats = {
  linkVisits: {
    'www.url1.com': 1,
    'www.url2.com': 2,
    'www.url3.com': 5,
    'www.url4.com': 3,
  },
  buttonClicks: 4,
  totalEmailSent: 22,
  totalCost: 5,
  totalSmsSent: 1,
  totalSmsDelivered: 3,
  totalSurveyVisits: 6,
  totalSurveySubmitted: 100,
  totalReportEntries: 33,
  survey: {
    id1: {
      name: 'Nameone',
      totalSurveySent: 500,
    },
    surveyId2: {
      name: 'The great khali',
      totalSurveySent: 750,
    },
  },
  coupon: {
    _id1: {
      name: 'I am a coupon',
      prefix: 'TTH',
      totalCouponSent: 1000,
    },
    _id2: {
      name: 'also a coupon',
      prefix: 'MMT',
      totalCouponSent: 2000,
    },
  },
}

const PreviewLabel = ({ children, ...rest }) => (
  <Text variant="medium" type="grey" {...rest}>
    {children}
  </Text>
)

const PreviewValue = ({ children, ...rest }) => (
  <Text variant="medium" type="main" weight="normal" {...rest}>
    {children}
  </Text>
)

export const CampaignPreview = ({
  form,
  campaignType,
  surveyButton,
  hideSteps,
}) => {
  const {
    couponPosition,
    couponId,
    couponFontColor,
    couponFontSize,
    couponFontTypeface,
  } = form?.content?.body || BUILDER_INIT

  const { spacing } = useTheme()
  const assetsDetails = form?.content?.body?.assetsDetails || []
  const surveys = useSelector((state) => state.autoEngage.surveyData.docs)
  const matchingSurvey =
    surveys?.filter((x) => x.surveyId === form?.content?.body?.surveyId) || {}
  const couponData = useSelector((state) => state.autoEngage.couponData)
  const couponSelected = form?.content?.body?.couponId
    ? couponData.docs.filter((doc) => doc._id === form?.content?.body?.couponId)
    : []
  const afterSurveySelectedCoupon = form?.afterCampaign?.couponId
    ? couponData.docs.filter((doc) => doc._id === form?.afterCampaign?.couponId)
    : []
  const getCampaignDetails = () => {
    switch (campaignType) {
      case 'Sell Below the Bill ': {
        return (
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Pdf Upload Status :
            </Text>
            <Text variant="small" type="main">
              {Boolean(assetsDetails?.length) ? 'Success' : 'Failed'}
            </Text>
          </FlexBox>
        )
      }
      case 'Survey Button ':
      case 'Add Survey Button ': {
        return (
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Survey :
            </Text>
            <Text variant="small" type="main">
              {form?.content?.body?.surveyId && matchingSurvey.length > 0
                ? matchingSurvey[0].name
                : ''}
            </Text>
          </FlexBox>
        )
      }

      default: {
        return (
          <>
            <FlexBox gap="10px" padding="8px 0px">
              <Text variant="small" type="grey">
                Coupon Name :
              </Text>
              <Text variant="small" type="main">
                {couponSelected?.[0]?.name ? couponSelected?.[0]?.name : null}
              </Text>
            </FlexBox>
            <FlexBox gap="10px" padding="8px 0px">
              <Text variant="small" type="grey">
                Coupon Position :
              </Text>
              <Text variant="small" type="main">
                {GET_SEL_VALUE(couponPosition, POSITION_TYPES)?.label || '-'}
              </Text>
            </FlexBox>
            <FlexBox gap="44px" padding="8px 0px">
              <FlexBox gap="10px" padding="0px">
                <Text variant="small" type="grey">
                  Coupon Font :
                </Text>
                <Text variant="small" type="main">
                  {couponFontTypeface || '-'}
                </Text>
              </FlexBox>
              <FlexBox gap="10px" padding="0px">
                <Text variant="small" type="grey">
                  Coupon Font Colour :
                </Text>
                <FlexBox align="center">
                  {couponFontColor ? (
                    <ColorBlockWithValue color={couponFontColor} />
                  ) : (
                    '-'
                  )}
                </FlexBox>
              </FlexBox>
              <FlexBox gap="10px" padding="8px 0px">
                <Text variant="small" type="grey">
                  Coupon Font Size :
                </Text>
                <Text variant="small" type="main">
                  {couponFontSize ? `${couponFontSize}px` : '-'}
                </Text>
              </FlexBox>
            </FlexBox>
          </>
        )
      }
    }
  }

  /* const getCampaignDetailsStatus = () => {
    switch (campaignType) {
      case 'Add Survey Button ':
      return Boolean(form?.content?.body?.surveyId) && Boolean(surveyButton)
    
      default:
      return (
        Boolean(form?.content?.body?.componentType) &&
        Boolean(form?.content?.body?.assetsDetails?.length)
      )
    }
    } */
  return (
    <>
      <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
        {hideSteps ? 'Preview' : 'Preview & Publish'}
      </Text>
      <FlexBox width="100%" justify="space-between" gap="1%">
        <Div width="70%">
          <CampaignStats form={form} campaignType={campaignType} />
          <PublishItem width="100%" header="Campaign Details">
            <FlexBox>
              <FlexBox gap="10px" padding="10px 0 5px" mr="64px">
                <PreviewLabel>Campaign Name :</PreviewLabel>
                <PreviewValue>{form.name}</PreviewValue>
              </FlexBox>
              <FlexBox gap="10px" padding="10px 0 5px">
                <PreviewLabel>Created on:</PreviewLabel>
                <PreviewValue>
                  {moment(form?.createdDate).format('DD/MM/YYYY')}
                </PreviewValue>
              </FlexBox>
            </FlexBox>
            <FlexBox gap="10px">
              <PreviewLabel>Campaign Description :</PreviewLabel>
              <PreviewValue>{form.description || '-'}</PreviewValue>
            </FlexBox>
          </PublishItem>

          <hr />
          <PublishItem width="100%" header={`${campaignType} Details`}>
            <FlexBox gap="10px" padding="10px 0 5px">
              <PreviewLabel variant="small">{campaignType} Type :</PreviewLabel>
              <PreviewValue>
                {campaignType === 'Add Survey Button '
                  ? 'Survey Button'
                  : form?.content?.body?.componentType?.toUpperCase() || '-'}
              </PreviewValue>
            </FlexBox>
            {getCampaignDetails()}
          </PublishItem>

          <hr />
          <AutoTurnOnOffPreview form={form} hideSteps={hideSteps} />
        </Div>
        <Div minWidth="420px" width="29%" minHeight="420px" overflow="auto">
          <$BlueBox height="auto" width="100%">
            <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
              Preview Pane
            </Text>
            <FlexBox wrap="wrap">
              {form?.content?.body?.componentType === 'youtube' ? (
                <YoutubeComponent
                  value={
                    assetsDetails[0]?.key ||
                    assetsDetails[0]?.fileLocation ||
                    ''
                  }
                />
              ) : (
                assetsDetails?.map((item, i) => (
                  <Div my={spacing.m} key={item?.key}>
                    <UploadBox
                      hideBorder
                      uploadWidth="100%"
                      uploadHeight="auto"
                      src={item?.fileLocation}
                      fileType={form?.content?.body?.componentType}
                      couponPosition={couponPosition}
                      couponId={item?.couponVisibility && couponId}
                      couponFontColor={couponFontColor}
                      couponFontSize={couponFontSize}
                      couponFontTypeface={couponFontTypeface}
                      couponPreview={couponSelected}
                      whiteBackground
                    />
                  </Div>
                ))
              )}
              {surveyButton && (
                <Div
                  border="1px dashed lightgray"
                  position="relative"
                  borderRadius="4px"
                  p={spacing.s}
                  width="385px"
                  height="130px"
                  display="flex"
                >
                  <img
                    src={surveyButton}
                    style={{
                      maxWidth: '375px',
                      maxHeight: '120px',
                      objectFit: 'contain',
                      margin: 'auto',
                    }}
                  />
                </Div>
              )}
              {surveyButton && form?.afterCampaign?.fileLocation && (
                <Div>
                  <Text variant="p" type="main" weight="bold" mt={spacing.l}>
                    {surveyButton ? 'After survey' : 'After campaign ends'}
                  </Text>
                  <UploadBox
                    uploadWidth="100%"
                    uploadHeight="auto"
                    src={form?.afterCampaign?.fileLocation}
                    fileType="image"
                    couponId={form?.afterCampaign?.couponId}
                    couponPosition={form?.afterCampaign?.couponPosition}
                    couponPreview={afterSurveySelectedCoupon}
                    whiteBackground
                  />
                </Div>
              )}
            </FlexBox>
          </$BlueBox>
        </Div>
      </FlexBox>
    </>
  )
}

export const CampaignStatisticsEmbeddedComponents = ({ form }) => {
  const { spacing } = useTheme()
  const embStats = []
  const isSurveyPresent =
    form?.stats?.survey && Object.keys(form?.stats?.survey).length
  const isCouponPresent =
    form?.stats?.coupon && Object.keys(form?.stats?.coupon).length

  if (!isSurveyPresent && !isCouponPresent) return null

  if (isSurveyPresent) {
    const isMultipleSurvey = Object.keys(form?.stats?.survey).length > 1

    embStats.push(
      <>
        {form?.stats?.survey &&
          Object.keys(form?.stats?.survey).map((item, index) => (
            <tr key={`totalSurveySubmitted-${index}`}>
              <td style={{ textAlign: 'center', padding: spacing.m }}>
                <Text variant="small" weight="bold" type="main">
                  Total Survey Submitted{isMultipleSurvey && ` ${index + 1}`}
                </Text>
                <Text variant="small" weight="bold" type="grey">
                  ({form?.stats?.survey?.[item]?.name})
                </Text>
              </td>
              <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
                <Text variant="small" align="center">
                  {form?.stats?.survey?.[item]?.totalSurveySubmitted}
                </Text>
              </td>
            </tr>
          ))}
      </>
    )
  }

  if (isCouponPresent) {
    const isMultipleCoupon = Object.keys(form?.stats?.coupon).length > 1

    embStats.push(
      <>
        {form?.stats?.coupon &&
          Object.keys(form?.stats?.coupon).map((item, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center', padding: spacing.m }}>
                <Text variant="small" weight="bold" type="main">
                  Coupon{isMultipleCoupon && ` ${index + 1}`} Sent
                </Text>
                <Text variant="small" weight="bold" type="grey">
                  {`${form?.stats?.coupon?.[item]?.name} (${form?.stats?.coupon?.[item]?.prefix})`}
                </Text>
              </td>
              <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
                <Text variant="small" align="center">
                  {form?.stats?.coupon?.[item]?.totalCouponSent}
                </Text>
              </td>
            </tr>
          ))}
      </>
    )
  }

  return embStats
}

export const CampaignStatisticsGeneralStats = ({ form }) => {
  const { spacing } = useTheme()

  const copyStats = cloneDeep(form?.stats)

  if (form?.stats?.linkVisits) {
    delete copyStats.linkVisits
  }

  if (copyStats && Object.keys(copyStats).length) {
    return (
      <>
        {CAMPAIGN_STATS.map(({ value, label }, index) => {
          if (Object.keys(copyStats).includes(value)) {
            return (
              <tr key={index}>
                <td style={{ textAlign: 'center', padding: spacing.m }}>
                  <Text variant="small" weight="bold" type="main">
                    <Div>{label}</Div>
                  </Text>
                </td>
                <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
                  <Text variant="small" align="center">
                    {value === 'totalCost'
                      ? copyStats?.[value]?.toFixed(2)
                      : copyStats?.[value]}
                  </Text>
                </td>
              </tr>
            )
          }

          return null
        })}
      </>
    )
  }

  return null
}

export const CampaignStatisticsLinkVisits = ({ form }) => {
  const { spacing } = useTheme()

  if (form?.stats?.linkVisits && form?.stats?.linkVisits?.length) {
    return (
      <>
        {form?.stats?.linkVisits &&
          form?.stats?.linkVisits?.map((linkData, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center', padding: spacing.m }}>
                <Text variant="small" weight="bold" type="main">
                  <Div>
                    Link {index + 1} (Clicks)
                    <br />
                    <LinkHoverRedirect link={linkData.url} />
                  </Div>
                </Text>
              </td>
              <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
                <Text variant="small" align="center">
                  {linkData.count || 0}
                </Text>
              </td>
            </tr>
          ))}
      </>
    )
  }

  return null
}

export const CampaignStatisticsAssetsSeen = ({ form }) => {
  const { spacing } = useTheme()

  const getAssetName = (astId) =>
    form?.content?.body?.assetsDetails?.find((as) => as?.assetId === astId)
      ?.name || ''
  const getAsset = (astId) =>
    form?.content?.body?.assetsDetails?.find((as) => as?.assetId === astId)
      ?.fileLocation || null
  const isImage = ['image', 'gif', 'carousel'].includes(
    form?.content?.body?.componentType
  )

  if (form?.stats?.assetSeen && Object.keys(form?.stats?.assetSeen)?.length) {
    return (
      <>
        {form?.stats?.assetSeen &&
          Object.keys(form?.stats?.assetSeen)?.map((ast, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center', padding: spacing.m }}>
                <Text variant="small" weight="bold" type="main">
                  <FlexBox align="center" gap={spacing.m}>
                    {getAsset(ast) && isImage && (
                      <img src={getAsset(ast)} height={40} />
                    )}
                    <Div>
                      Asset {index + 1} Seen
                      <br />
                      {getAssetName(ast)}
                    </Div>
                  </FlexBox>
                </Text>
              </td>
              <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
                <Text variant="small" align="center">
                  {form?.stats?.assetSeen?.[ast]}
                </Text>
              </td>
            </tr>
          ))}
      </>
    )
  }

  return null
}

export const CampaignStatisticsSMSnEmail = ({ form }) => {
  const { spacing } = useTheme()

  if (form?.stats?.totalSmsSent) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total SMS Sent
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalSmsSent}
          </Text>
        </td>
      </tr>
    )
  }

  if (form?.stats?.totalEmailSent) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total Email Sent
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalEmailSent}
          </Text>
        </td>
      </tr>
    )
  }

  if (form?.stats?.totalWhatsAppSent) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total WhatsApp Sent
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalWhatsAppSent}
          </Text>
        </td>
      </tr>
    )
  }

  if (form?.stats?.totalWhatsAppDelivered) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total WhatsApp Delivered
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalWhatsAppDelivered}
          </Text>
        </td>
      </tr>
    )
  }

  if (form?.stats?.totalWhatsAppRead) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total WhatsApp Read
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalWhatsAppRead}
          </Text>
        </td>
      </tr>
    )
  }

  if (form?.stats?.totalWhatsAppFailed) {
    return (
      <tr>
        <td style={{ textAlign: 'center', padding: spacing.m }}>
          <Text variant="small" weight="bold" type="main">
            Total WhatsApp Failed
          </Text>
        </td>
        <td style={{ borderLeft: '1px solid', padding: spacing.m }}>
          <Text variant="small" align="center">
            {form?.stats?.totalWhatsAppFailed}
          </Text>
        </td>
      </tr>
    )
  }

  return null
}

export const AutoTurnOnOffPreview = ({ form, hideSteps }) => {
  const { data: allStores } = useSelectStore('stores.stores')
  const { data: allBrands } = useSelectStore('brand.brands')
  const { spacing } = useTheme()

  return (
    <PublishItem width="100%" header="Campaign Settings">
      <FlexBox justify="space-between">
        <Div flexBasis="30%">
          <Text
            type="table"
            color="hsla(0, 0%, 0%, 0.8)"
            variant="p"
            weight="semi-bold"
          >
            Audience
          </Text>
          <FlexBox gap="10px" padding="10px 0 5px">
            <PreviewLabel>Selected Brand :</PreviewLabel>
            <PreviewValue>
              {allBrands.filter(
                (br) => form.targetGroup?.brandId === br.brandId
              )[0]?.name || '-'}
            </PreviewValue>
          </FlexBox>
          <FlexBox gap="10px">
            <Text width="92px" variant="small" type="grey">
              Selected Stores :
            </Text>
            <FlexBox wrap="wrap">
              {Object.values(allStores)
                ?.filter((sto) =>
                  form?.targetGroup?.storeIds?.includes(sto.storeId)
                )
                ?.map((str) => (
                  <$StoreTags key={str.displayAddress}>
                    {str.displayAddress}
                  </$StoreTags>
                ))}
            </FlexBox>
          </FlexBox>
        </Div>
        <Div flexBasis="60%">
          <Text type="table" variant="p" weight="semi-bold" mb={spacing.m}>
            Schedule Campaign:{' '}
            {!!form?.deactivateAt || !!form?.activateAt ? 'Active' : 'Inactive'}
          </Text>
          <FlexBox gap={spacing.xxl}>
            <div>
              <DetailRow
                label="Start Date"
                value={
                  !!form?.activateAt
                    ? moment(form?.activateAt).format('DD/MM/YYYY')
                    : '-'
                }
                mb={spacing.m}
              />
              <DetailRow
                label="Start-Time"
                value={
                  !!form?.activateAt
                    ? moment(form?.activateAt).format('hh:mm A')
                    : '-'
                }
              />
            </div>
            <div>
              <DetailRow
                label="End Date"
                value={
                  !!form?.deactivateAt
                    ? moment(form?.deactivateAt).format('DD/MM/YYYY')
                    : '-'
                }
                mb={spacing.m}
              />
              <DetailRow
                label="End-Time"
                value={
                  !!form?.deactivateAt
                    ? moment(form?.deactivateAt).format('hh:mm A')
                    : '-'
                }
              />
            </div>
          </FlexBox>
        </Div>
      </FlexBox>
    </PublishItem>
  )
}

export const DetailRow = ({ label, value = '-', isLink = false, ...rest }) => {
  const { spacing } = useTheme()

  return (
    <FlexBox {...rest}>
      <PreviewLabel mr={spacing.m}>{label}</PreviewLabel>
      <PreviewValue>
        {isLink ? (
          <a href={value} target="_blank">
            {value}
          </a>
        ) : (
          value
        )}
      </PreviewValue>
    </FlexBox>
  )
}

export const CampaignStats = ({ form, campaignType, isJourney }) => {
  const { spacing } = useTheme()
  const isNonDigital = ['SMS', 'E-Mail', 'WhatsApp'].includes(campaignType)

  const child = (
    <$StatsTable bgrd={isNonDigital ? '#ea7451' : null}>
      <thead>
        <tr>
          <th colSpan="2" style={{ padding: spacing.m }}>
            {campaignType} Campaign Statistics{' '}
            {form?.name ? `(${form.name})` : ''}
          </th>
        </tr>
        <tr>
          <th>Title</th>
          <th>Volume</th>
        </tr>
      </thead>

      <tbody>
        <CampaignStatisticsSMSnEmail form={form} />
        <CampaignStatisticsEmbeddedComponents form={form} />
        <CampaignStatisticsGeneralStats form={form} />
        <CampaignStatisticsLinkVisits form={form} />
        <CampaignStatisticsAssetsSeen form={form} />
      </tbody>
    </$StatsTable>
  )

  if (isNonDigital) {
    if (isJourney) {
      return child
    } else {
      return (
        <>
          <PublishItem width="100%" header="Campaign Statistics" showExport>
            {child}
          </PublishItem>
          <hr />
        </>
      )
    }
  } else {
    if (isJourney) {
      return null
    } else {
      return (
        <>
          <PublishItem width="100%" header="Campaign Statistics" showExport>
            {child}
          </PublishItem>
          <hr />
        </>
      )
    }
  }

  return null

  // return form?.status !== 'draft' &&
  //   Object.values(form?.stats || {})?.length ? (
  //   isNonDigital ? (
  //     child
  //   ) : (
  //     <>
  //       <PublishItem width="100%" header="Campaign Statistics" showExport>
  //         {child}
  //       </PublishItem>
  //       <hr />
  //     </>
  //   )
  // ) : null
}
