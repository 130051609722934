import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { useSelectStore } from 'hooks'
import { SectionHeading, ActiveFilterPills } from 'components/Common'
import {
  $SquareButton,
  $AEHeader,
  $SearchInput,
} from '../AutoEngagement.styled'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import AEFilter from './AEFilter'
import { INIT_FILTER, WA_FILTERS } from '../constants'
import { filterToQuery } from 'redux/actions/autoEngageActions'
import { FlexBox } from 'styledComponent'

const AEHeader = (props) => {
  const filterRef = useRef()
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const isTemplateTab = props.activeTab === 'Templates'

  useEffect(() => {
    props.filterToQuery(isTemplateTab ? WA_FILTERS : INIT_FILTER, isTemplateTab)
  }, [selectedVendor, isTemplateTab])

  const showModal = () => filterRef.current.openModal()

  const handleRemoveAll = () =>
    props.filterToQuery(isTemplateTab ? WA_FILTERS : INIT_FILTER, isTemplateTab)

  const handleClosePill = (_, type) => {
    const fil = cloneDeep(props.autoEngage.filterData)
    delete fil[type]
    props.filterToQuery({
      ...(isTemplateTab ? WA_FILTERS : INIT_FILTER),
      ...fil,
    })
  }

  const handleFilter = (filter) => {
    props.filterToQuery(filter)
  }

  const pillData = Object.values(
    isTemplateTab
      ? props.autoEngage.wATemplateFilterData
      : props.autoEngage.filterData
  ).filter((fil) => fil.isActive)

  return (
    <$AEHeader justify="space-between" align="center">
      <span>
        {/* <SectionHeading
          heading="All Campaigns"
          subHeading="List of all campaigns in your system"
          variant="medium"
          subVariant="small"
        /> */}
        <ActiveFilterPills
          ref={filterRef}
          pillData={pillData}
          handleAdd={showModal}
          handleRemoveAll={handleRemoveAll}
          handleClosePill={handleClosePill}
        />
      </span>
      <span>
        <FlexBox>
          <$SearchInput>
            <span />
            <input
              id="searchTxt"
              value={props.search}
              placeholder="Search by Campaign Name"
              onChange={(e) => props.setSearch(e.target.value)}
            />
          </$SearchInput>
          <$SquareButton title="Filter" isFilter onClick={showModal} size={40}>
            <FilterIcon />
          </$SquareButton>
        </FlexBox>
      </span>
      <AEFilter
        ref={filterRef}
        data={
          isTemplateTab
            ? props.autoEngage.wATemplateFilterData
            : props.autoEngage.filterData
        }
        handleFilter={handleFilter}
        isTemplateTab={isTemplateTab}
      />
    </$AEHeader>
  )
}

const mapStateToProps = (state) => ({
  autoEngage: state.autoEngage,
})

export default connect(mapStateToProps, { filterToQuery })(AEHeader)
