import React, { useState } from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $Block, $FlexBox } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'
import { PropertyBar } from 'builder/editor/common/PropertyBar'
import { PopupContainer } from 'components/Common'
import { StyledInput, UploadButton } from 'builder/editor/common/Common.styled'
import { SelectSurvey } from 'components/Content/autoEngagement/components/SelectSurvey'

export const SurveySettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)

  const onSelectSurvey = (survey) => {
    setProp((props) => {
      props.link = `$survey[${survey.surveyId}]`
      props.surveyName = survey.name
    }, 300)
    setIsPopOpen(false)
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Survey"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <PropertyBar
        label="Survey Text"
        craftProps={props}
        setProp={setProp}
        propertyName="content"
        justify="space-between"
        margin="8px 0px"
      />

      <$FlexBox padding="0 0 4px" justify="space-between">
        <small>Select Survey</small>
        <div>
          <UploadButton onClick={() => setIsPopOpen(true)}>Survey</UploadButton>
        </div>
      </$FlexBox>

      <StyledInput
        readOnly
        style={{ width: '100%', background: '#D5D5D5' }}
        value={props.surveyName}
      />

      <$FlexBox padding="16px 0">
        <i>
          Note : You can also embed survey in text, button & images as well, to
          do so find the survey option in the respective components.
        </i>
      </$FlexBox>

      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Select Survey"
      >
        <SelectSurvey onSurveySelect={onSelectSurvey} />
      </PopupContainer>
    </$Block>
  )
}
