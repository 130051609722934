import { post, get, patch, deleteReq } from 'utils/axiosHandler'
import * as types from '../types/storeMgtTypes'

// Companies
export const getCompanyLists = (params) => async (dispatch) => {
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/companies/lists', params)
    dispatch({
      type: types.SET_COMPANY_LIST,
      payload: {
        companyList: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_COMPANY_LIST,
      payload: { companyList: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const getCompanyOptions = () => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/companies/lists', {
      vendorId,
      skip: 0,
      limit: 10000,
    })
    dispatch({
      type: types.SET_COMPANY_OPTIONS,
      payload: {
        companyOptions: res?.data?.map((item) => ({
          label: item.companyName,
          value: item.companyId,
        })),
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_COMPANY_OPTIONS,
      payload: { companyOptions: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const getCompanyDetails = (id) => async (dispatch) => {
  dispatch(setScreenLoading(true))
  try {
    const res = await get(`/store-management/companies/${id}`)
    dispatch({
      type: types.SET_COMPANY_DETAILS,
      payload: {
        companyDetails: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_COMPANY_DETAILS,
      payload: { companyDetails: {} },
    })
  } finally {
    dispatch(setScreenLoading(false))
  }
}

export const getCompanyTypes = () => async (dispatch) => {
  try {
    const res = await get('/store-management/companies/types')
    dispatch({
      type: types.SET_COMPANY_TYPES,
      payload: {
        companyTypes: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_COMPANY_TYPES,
      payload: { companyTypes: [] },
    })
  }
}

export const createCompany = async (body) =>
  await post('/store-management/companies', body)

export const updateCompany = async (body) =>
  await patch(`/store-management/companies/${body?._id}`, body)

export const exportCompany = async (body) =>
  await post(`/store-management/companies/export`, body)

// export const deleteCompany = async (body) =>
//   await deleteReq('/store-management/companies', body)

// Brands
export const getBrandLists = (params) => async (dispatch) => {
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/brands/lists', params)
    dispatch({
      type: types.SET_BRAND_LIST,
      payload: {
        brandList: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_BRAND_LIST,
      payload: { brandList: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}
export const getBrandOptions = () => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/brands/lists', {
      vendorId,
      skip: 0,
      limit: 10000,
    })
    dispatch({
      type: types.SET_BRAND_OPTIONS,
      payload: {
        brandOptions: res?.data?.map((item) => ({
          label: item.brandName,
          value: item.brandId,
        })),
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_BRAND_OPTIONS,
      payload: { brandOptions: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const getBrandCategories = () => async (dispatch) => {
  try {
    const res = await post('/store-management/brands/categories', {
      limit: 1000,
    })
    dispatch({
      type: types.SET_BRAND_CATEGORIES,
      payload: {
        brandCategories: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_BRAND_CATEGORIES,
      payload: { brandCategories: [] },
    })
  }
}

export const getBrandDetails = (id) => async (dispatch) => {
  dispatch(setScreenLoading(true))
  try {
    const res = await get(`/store-management/brands/${id}`)
    dispatch({
      type: types.SET_BRAND_DETAILS,
      payload: {
        brandDetails: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_BRAND_DETAILS,
      payload: { brandDetails: {} },
    })
  } finally {
    dispatch(setScreenLoading(false))
  }
}

export const createBrand = async (body, config) =>
  await post('/store-management/brands', body, config)

export const updateBrand = async (id, formData, config) =>
  await patch(`/store-management/brands/${id}`, formData, config)

// export const deleteBrand = async (body) =>
//   await deleteReq('/store-management/brands', body)

export const exportBrand = async (body) =>
  await post(`/store-management/brands/export`, body)

// StoreGroup
export const getStoreGroupLists = (params) => async (dispatch) => {
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/stores-group/lists', params)
    dispatch({
      type: types.SET_STORE_GROUP_LIST,
      payload: {
        storeGroupList: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_STORE_GROUP_LIST,
      payload: { storeGroupList: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

// StoreGroup
export const getStoreGroupOptions = () => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setLoadTable(true))
  try {
    const res = await post('/store-management/stores-group/lists', {
      limit: 1000,
      skip: 0,
      vendorId,
    })
    dispatch({
      type: types.SET_STORE_GROUP_OPTIONS,
      payload: {
        storeGroupOptions: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_STORE_GROUP_OPTIONS,
      payload: { storeGroupOptions: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const getStoreGroupDetails = (id) => async (dispatch) => {
  dispatch(setScreenLoading(true))
  try {
    const res = await get(`/store-management/stores-group/${id}`)
    dispatch({
      type: types.SET_STORE_GROUP_DETAILS,
      payload: {
        storeGroupDetails: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_STORE_GROUP_DETAILS,
      payload: { storeGroupDetails: {} },
    })
  } finally {
    dispatch(setScreenLoading(false))
  }
}

export const createStoreGroup = async (body) =>
  await post('/store-management/stores-group', body)

export const updateStoreGroup = async (body) =>
  await patch(`/store-management/stores-group/${body?._id}`, body)

export const deleteStoreGroup = async (id) =>
  await deleteReq(`/store-management/stores-group/${id}`)

export const exportStoreGroup = async (body) =>
  await post(`/store-management/stores-group/export`, body)
// Stores
export const getStoresLists = (body) => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setLoadTable(true))
  const storeList =
    body?.type === 'online' ? 'ecomStoreList' : 'retailStoreList'
  try {
    const res = await post(`/store-management/${vendorId}/stores-list`, body)
    dispatch({
      type: types.SET_STORES_LIST,
      payload: {
        [storeList]: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_STORES_LIST,
      payload: { [storeList]: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const changeStoreActive =
  ({ storeId, isActive }) =>
  (dispatch, getState) => {
    const store = getState().stores.allStores[storeId]
    console.log('store', storeId, getState().stores.stores)
    dispatch({
      type: isActive ? 'SET_STORE_ACTIVE' : 'SET_STORE_INACTIVE',
      payload: store,
    })
  }

export const getStoreDetails = (id) => async (dispatch) => {
  dispatch(setScreenLoading(true))
  try {
    const res = await get(`/store-management/stores/${id}`)
    const item = res?.data || {}
    const storeDetails = {
      storeBrandId: item?.brandId,
      storeCompanyId: item?.companyId,
      ...item?.store,
      ...item,
    }
    dispatch({
      type: types.SET_STORES_DETAILS,
      payload: { storeDetails },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_STORES_DETAILS,
      payload: { storeDetails: {} },
    })
  } finally {
    dispatch(setScreenLoading(false))
  }
}

export const createStore = async (body) =>
  await post(`/store-management/${body?.vendorId}/stores`, body)

export const createBulkStores = async (vendorId, body, config) =>
  await post(`/store-management/${vendorId}/bulk/stores`, body, config)

export const updateStore = async (body) =>
  await patch(`/store-management/${body?.vendorId}/stores/${body?._id}`, body)

export const changeStoreStatus = async (body) =>
  await post(`/store-management/stores/control`, body)

export const exportStores = async (body) =>
  await post(`/store-management/${body?.vendorId}/stores/export`, body)

// Store Keys
export const getStoreKeysLists = (body) => async (dispatch, getState) => {
  const vendorId = getState().vendorIds.selectedVendor.value
  dispatch(setLoadTable(true))
  try {
    const res = await post(
      `/store-management/${vendorId}/stores-keys/list`,
      body
    )
    dispatch({
      type: types.SET_ALL_STORE_KEYS_LIST,
      payload: {
        storeKeyslist: res?.data,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_ALL_STORE_KEYS_LIST,
      payload: { storeKeyslist: [] },
    })
  } finally {
    dispatch(setLoadTable(false))
  }
}

export const getStoreKeyLists =
  (id, queryParams) => async (dispatch, getState) => {
    const vendorId = getState().vendorIds.selectedVendor.value
    dispatch(setLoadTable(true))
    try {
      const queryString = new URLSearchParams(queryParams).toString()
      const res = await get(
        `/store-management/${vendorId}/stores-keys/${id}?${queryString}`
      )
      dispatch({
        type: types.SET_STORE_KEYS_LIST,
        payload: {
          storeKeylist: res?.data?.docs,
          storeKeyDetails: res?.data?.storeDetails,
        },
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.SET_STORE_KEYS_LIST,
        payload: { storeKeyList: [], storeKeyDetails: {} },
      })
    } finally {
      dispatch(setLoadTable(false))
    }
  }

export const createStoreKey = async (body) =>
  await post(
    `/store-management/${body?.vendorId}/stores-keys/${body?._id}`,
    body
  )

export const updateStoreKey = async (body) =>
  await patch(`/store-management/${body?.vendorId}/stores-keys`, body)

export const deleteStoreKey = async (body) =>
  await deleteReq(
    `/store-management/${body?.vendorId}/stores-keys/${body?.store}/${body?._id}`
  )

export const exportStoreKeys = async (body) =>
  await post(`/store-management/${body?.vendorId}/stores-keys/exports`, body)

// Loading
export const setLoadTable = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_LOAD_TABLE,
    payload: { tableLoading: bool },
  })
}

export const setScreenLoading = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_SCREEN_LOADING,
    payload: { screenLoading: bool },
  })
}
