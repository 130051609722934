import React from 'react'
import ReactPaginate from 'react-paginate'

import { useRouter } from 'hooks'
import { TableHead, TableRow, TableCell, Table } from 'styledComponent'
import { spacing } from 'design-system'
import { Skeleton } from 'components/Common/Skeleton'
import { Text } from 'components/Common/Text'
import { NoDataFound } from 'components/Common/EmptyState'
import { $FlexBox, $Block } from '../../ProductAnalytics.styled'
import { $TableCell } from '../AllProducts.styled'
import TableActions from './TableActions'
import { TABLE_COLS } from './constants'
import ProductDetails from '../ProductDetails/ProductDetails'

/**
 * @description products table view
 * @param {Boolean} loading loading state
 * @param {Function} action action function
 */
const ProductsTable = ({ loading, action, allProducts }) => {
  const { query, setQuery } = useRouter()

  const onPageChange = ({ selected }) => {
    const selectedPage = selected + 1
    action({ page: selectedPage || 1 })
  }

  const closeProductViewDrawer = () => {
    setQuery({ id: '' }, { preservePrevState: true })
  }

  const _formatForRowData = () => {
    const rowData = allProducts?.docs?.map((row) => ({
      ...row,
      category: row?.categoryDetails?.name || (
        <Text type="error" align="center">
          Uncategorised
        </Text>
      ),
      uam: row?.uamDetails?.value || '',
      displayNames: row?.displayNames?.join(', ') || '',
    }))

    return rowData
  }

  const { id } = query

  return (
    <$Block margin={`${spacing.l} 0 0`} position="relative" minHeight="560px">
      {!loading && !allProducts?.docs?.length ? (
        <NoDataFound />
      ) : (
        <>
          <Table margin="0px">
            <TableHead>
              <TableRow fontSize="14px">
                {TABLE_COLS.map(({ label }, index) => (
                  <TableCell key={index}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <tbody>
                {Array(10)
                  .fill()
                  .map((_, index) => (
                    <TableRow key={index} fontSize="12px">
                      {TABLE_COLS.map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton count={1} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </tbody>
            ) : null}
            {!loading && (
              <tbody>
                {_formatForRowData()?.map((product) => (
                  <TableRow key={product.productCode} fontSize="12px">
                    {TABLE_COLS.map(({ value }, index) => (
                      <$TableCell key={index} colCount={TABLE_COLS.length}>
                        {value !== 'action' ? (
                          product[value]
                        ) : (
                          <TableActions
                            productId={product._id}
                            action={action}
                          />
                        )}
                      </$TableCell>
                    ))}
                  </TableRow>
                ))}
              </tbody>
            )}
          </Table>
          {allProducts?.totalDocs > 10 ? (
            <$FlexBox
              justify="space-between"
              align="center"
              m={`${spacing.l} 0`}
            >
              <Text variant="small" type="grey">
                Showing {allProducts?.page} - {allProducts?.totalPages} pages
              </Text>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={allProducts?.totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={onPageChange}
                containerClassName="aePagination"
                activeClassName="pagination_activePage"
                forcePage={allProducts?.page - 1}
              />
            </$FlexBox>
          ) : null}
        </>
      )}
      <ProductDetails
        show={!!id}
        onGoBack={closeProductViewDrawer}
        productId={id}
      />
    </$Block>
  )
}

export default ProductsTable
