import React, { useState } from 'react'
import swal from 'sweetalert'
import moment from 'moment'
import { useSelectStore, useRouter } from 'hooks'
import { initForm } from '../components/constants'
import { $WhiteContainer } from '../Report.styled'
import GenerateForm from './GenerateForm'
import GenerateHeader from './GenerateHeader'
import { generateReport } from 'redux/actions/reportsAction'

const GenerateReport = () => {
  const {
    data: { email, name },
  } = useSelectStore('auth.user')
  const [form, setForm] = useState(initForm)
  const [isDisabled, setIsDisabled] = useState(false)
  const { history } = useRouter()

  const onSave = async () => {
    const startDate = moment(form.date?.start).format('YYYY-MM-DD')
    const endDate = moment(form.date?.end).format('YYYY-MM-DD')
    const reportForm = {
      ...form,
      startDate,
      endDate,
      userName: name || email,
      userEmail: email,
      brandIds: form.brands?.map((br) => br.brandId),
      storeIds: form.stores?.map((str) => str.storeId),
    }
    delete reportForm.brands
    delete reportForm.stores
    delete reportForm.date

    if (!reportForm.description) {
      delete reportForm.description
    }

    if (form.reportType === 'consumerProfiling') {
      delete reportForm.brandIds
      delete reportForm.storeIds
      delete reportForm.startDate
      delete reportForm.endDate
    }
    if (!form.name) {
      swal('Oops', 'Enter a name for your report', 'error')
      return
    }
    if (!form.emailList?.length) {
      swal('Oops', 'Enter at least one email ID', 'error')
      return
    }
    if (form.stores?.length <= 0 && form.reportType !== 'consumerProfiling') {
      swal('Oops', 'Select at least one Store', 'error')
      return
    }
    setIsDisabled(true)
    const res = await generateReport(reportForm)
    if (res) {
      swal('Success', res, 'success').then(() => history.push('/reports'))
      setIsDisabled(false)
    } else {
      swal('Oops', 'Something went wrong!', 'error')
      setIsDisabled(false)
    }
  }

  return (
    <$WhiteContainer>
      <GenerateHeader onSave={onSave} isDisabled={isDisabled} />
      <GenerateForm form={form} setForm={setForm} />
    </$WhiteContainer>
  )
}

export default GenerateReport
