import styled from 'styled-components'
import { spacing } from 'design-system'

const $ThumbnailBlock = styled.div`
  border: 3px solid #000000d3;
  border-radius: 5px;
  position: relative;
  margin-top: ${spacing.l};
  margin-left: 2px;
`

const $ThumbnailImage = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(0deg, #00000000 0%, #000000d3 100%)
      0% 0% no-repeat padding-box;
  }
`
const $ThumbnailText = styled.div`
  padding: 10px;
`
const $MetaData = styled.ul`
  padding: 10px 0;
  list-style: none;
  width: 90%;
  margin: 0 auto;
`
const $MetaDataItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    color: #33475b;
  }
`
const $ProductRankText = styled.span`
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`
const $ProductRankIcon = styled.div`
  position: absolute;
  top: -${spacing.l};
  right: -${spacing.l};
`

const $ProductStatsBlock = styled.ul`
  padding: 0;
  margin-left: 5%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`
const $ProductStatsItem = styled.li`
  width: 45%;
  height: 200px;
  position: relative;
  border: 1px solid transparent;
  border-right-color: ${({ borderRight }) =>
    borderRight ? '#EBF3FE' : 'transparent'};
  border-top-color: ${({ borderTop }) =>
    borderTop ? '#EBF3FE' : 'transparent'};
  border-bottom-color: ${({ borderBottom }) =>
    borderBottom ? '#EBF3FE' : 'transparent'};
  div {
    margin: 0 !important;
  }
`
const $StatsText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const $ProductStatsUnit = styled.span`
  font-size: 12px;
`
const $ProductStatsIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  svg {
    width: 45px;
    height: 45px;
  }
`

export {
  $ThumbnailBlock,
  $ThumbnailImage,
  $ThumbnailText,
  $MetaData,
  $MetaDataItem,
  $ProductRankText,
  $ProductRankIcon,
  $ProductStatsBlock,
  $ProductStatsItem,
  $StatsText,
  $ProductStatsUnit,
  $ProductStatsIcon,
}
