import React, { useState } from 'react'
import { connect } from 'react-redux'
import { $WhiteContainer } from '../AutoEngagement.styled'
import CouponHeader from './CouponHeader'
import { CouponTable } from './CouponTable'
import { useCouponApi } from './useCouponApi'

const Coupons = (props) => {
  const { vendorId } = props
  const { aeTableLoading, couponData } = props.autoEngage

  const [search, setSearch] = useState('')
  const [skip, setSkip] = useState(0)
  const [coupDetails, setCoupDetails] = useState({})

  const onSearchChange = (s) => {
    setSearch(s)
    setSkip(0)
  }

  useCouponApi(vendorId, search, skip, coupDetails, false, setCoupDetails)

  const pageCount =
    parseInt(couponData.count / 10) +
    (parseInt(couponData.count % 10) > 0 ? 1 : 0)

  return (
    <$WhiteContainer>
      <CouponHeader search={search} setSearch={onSearchChange} />
      <CouponTable
        pageNumber={skip / 10}
        tableLoading={aeTableLoading}
        onPaginationChange={(page) => setSkip(page.selected * 10)}
        couponList={couponData.docs}
        pageCount={pageCount}
        setCoupDetails={setCoupDetails}
      />
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  vendorId: state.vendorIds.selectedVendor.value,
  autoEngage: state.autoEngage,
})

export default connect(mapStateToProps)(Coupons)
