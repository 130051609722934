import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { FlexBox } from '../../styledComponent'

/**
 * @description Common Toggle switch component
 * @param {String} width
 * @param {String} startText
 * @param {String} endText
 * @param {Boolean} isActive
 * @param {Function} onChange
 */
const ToggleSwitch = ({
  width,
  startIcon,
  startText,
  endIcon,
  endText,
  onChange,
  isActive,
  height,
  sliderBottom,
}) => {
  return (
    <$FlexBox width={width} justify="space-between" align="center">
      <$FlexBox gap="4px">
        {startIcon && <img height="20" src={startIcon} />}
        {startText && <$Text>{startText}</$Text>}
      </$FlexBox>
      <$Switch height={height}>
        <$Input type="checkbox" onChange={onChange} checked={isActive} />
        <$Slider active={isActive} sliderBottom={sliderBottom} />
      </$Switch>
      <$FlexBox gap="4px">
        {endIcon && <img src={endIcon} />}
        {endText && <$Text>{endText}</$Text>}
      </$FlexBox>
    </$FlexBox>
  )
}

ToggleSwitch.defaultProps = {
  width: '200px',
}

ToggleSwitch.prototype = {
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default ToggleSwitch

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || 'auto'};
`

const $Text = styled.span`
  font-size: 14px;
`

const $Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: ${({ height }) => height || '28px'};
  margin: 0;
`

const $Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid #3b86ff;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: ${({ active }) => (active ? '0' : '4px')};
    bottom: ${({ sliderBottom }) => sliderBottom || '4px'};
    background-color: #3b86ff;
    transition: 0.4s;
    border-radius: 50%;
  }
`
const $Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &: checked + ${$Slider} {
    &:before {
      transform: translateX(26px);
    }
  }
`
