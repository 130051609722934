import styled, { css } from 'styled-components'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import SearchIcon from 'images/search.svg'

export const $WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`

export const $ManageKeys = styled.div`
  background-color: ${({ theme }) => theme.colors.success.light};
  color: ${({ theme }) => theme.colors.success.dark};
  border: 1px dashed ${({ theme }) => theme.colors.success.dark};
  border-radius: 4px;
  padding: ${spacing.sm};
  width: 185px;
  text-align: center;
  cursor: pointer;
`

export const $MarginlessLine = styled.hr`
  margin: 0;
`

export const $TabPadding = styled(FlexBox)`
  padding: 0 ${spacing.l};
  ul {
    li {
      padding: ${spacing.ml};
    }
  }
`

export const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) => props.theme.colors.primary.default};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
  :disabled {
    cursor: not-allowed;
  }
`

export const $SearchInput = styled.div`
  position: relative;
  cursor: pointer;
  input {
    border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
    width: 40px;
    height: 40px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${spacing.l};
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    transition: width 0.5s;

    &:focus,
    input[value]:not([value='']),
    :hover {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
  }
  input[value]:not([value='']) {
    width: 400px;
    outline: none;
    padding-right: ${spacing.xl};
  }
  &:focus,
  :hover {
    input {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
  }
  span {
    position: absolute;
    top: 10px;
    right: 10px;
    background: ${({ theme }) => theme.colors.white} url(${SearchIcon}) center;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`
export const $AddBrandLogo = styled.div`
  position: relative;
  img {
    position: absolute;
    width: 300px;
    height: 300px;
    left: 0;
    top: 0;
    object-fit: contain;
  }
  input {
    opacity: 0;
    width: 300px;
    height: 300px;
    cursor: pointer;
  }
  div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${spacing.ml};
    padding: ${spacing.ml};
    color: ${({ theme }) => theme.colors.primary.default};
    font-weight: 600;
    border-top: 1px solid;
    text-decoration: underline;
    input {
      position: absolute;
      height: 50px;
    }
  }
`
