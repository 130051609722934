import React from 'react'
import { useTheme } from 'styled-components'
import { cloneDeep } from 'lodash'

import { useSelectStore } from 'hooks'
import {
  deleteCategory,
  getAllCategories,
} from 'redux/actions/productAnalyticsActions'
import { Box, BoxContent } from 'styledComponent/components/box'
import { AddBlueIcon } from 'assets/icons'
import { Button, LoaderPopup, useToasts } from 'components/Common'
import { patch, post } from 'utils/axiosHandler'

import { useAsyncRender } from '../hooks'
import ColumnBox from './ColumnBox'
import Categories from './Categories'
import { $AddIconBlock } from './AllCategories.styled'
import { $FlexBox } from '../ProductAnalytics.styled'
import Products from './Products'
import { ERROR_MESSAGES } from './constants'
import { ERROR_DECODE } from 'utils/reusables'
import CancelPopup from 'components/Content/autoEngagement/components/CancelPopup'

/**
 * @description All categories page
 */

const AllCategories = () => {
  const [editCategory, setEditCategory] = React.useState(null)
  const [allCategories, setAllCategories] = React.useState(null)
  const [savingState, setSavingState] = React.useState(false)
  const [isPopOpen, setIsPopOpen] = React.useState(false)

  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const { data: paLoading } = useSelectStore('productAnalytics.paLoading')
  const { data: selectedStores } = useSelectStore(
    'productAnalytics.selectedStores'
  )
  const { data } = useSelectStore('productAnalytics.allCategories')

  const { toast } = useToasts()
  const { spacing } = useTheme()
  const isNewCategory = editCategory?.categoryId === 'newCategory'
  const isUnCategorised = editCategory?.categoryId === 'unCategorised'

  const [loading, fetchAction] = useAsyncRender({
    renderAction: getAllCategories,
    dependencies: [selectedStores],
  })

  const onActionClick = (action, currCategory) => {
    if (!currCategory?._id) {
      return setEditCategory({
        action,
        categoryId: 'unCategorised',
      })
    }
    if (action === 'delete') {
      return setIsPopOpen(currCategory?._id)
    }
    if (currCategory?._id === editCategory?.categoryId) {
      return setEditCategory((state) => ({
        ...cloneDeep(state),
        action,
      }))
    }
    return setEditCategory({
      action,
      categoryId: currCategory?._id,
      prevCategory: currCategory?.name || '',
      category: currCategory?.name || '',
      newProducts: [],
      prevProducts: [],
      currentProducts: [],
      excludeProductIds: [],
    })
  }

  const onCategoryNameChange = (e) => {
    const value = e.target.value
    setEditCategory((state) => ({
      ...state,
      category: value,
    }))
  }

  const onAddNewCategory = () => {
    setEditCategory({
      action: 'edit',
      categoryId: 'newCategory',
      prevCategory: '',
      category: '',
      newProducts: [],
      prevProducts: [],
      currentProducts: [],
      excludeProductIds: [],
    })
  }

  const onCancel = () => setEditCategory(null)

  const onSave = async () => {
    const body = {}

    if (editCategory) {
      if (editCategory.category !== editCategory.prevCategory) {
        body.name = editCategory.category
      }

      if (editCategory?.newProducts?.length) {
        const newProductIds = editCategory.newProducts.map(({ _id }) => _id)
        body.productIds = [...newProductIds]
      }

      if (editCategory?.excludeProductIds?.length && !isNewCategory) {
        body.excludeProductIds = editCategory.excludeProductIds
      }
    }

    const bodyKeys = Object.keys(body)
    if (isNewCategory && bodyKeys.length < 2) {
      return toast(ERROR_MESSAGES.mandatoryFields, 'error')
    }

    if (bodyKeys.length) {
      const isValid = Object.keys(body).every((key) => {
        if ((Array.isArray(body[key]) && body[key].length) || body[key]) {
          return true
        } else {
          return false
        }
      })

      if (!isValid) {
        return toast(ERROR_MESSAGES.mandatoryFields, 'error')
      }
      setSavingState(true)
      try {
        body.vendorId = selectedVendor?.value
        body.brandId = selectedBrand?.value

        const url = `/product-management/product-categories${
          isNewCategory ? '' : `/${editCategory.categoryId}`
        }`

        const method = isNewCategory ? post : patch

        const response = await method(url, body)

        if (response?.message) {
          toast(response?.message, 'success')
          fetchAction()
          onCancel()
        }
      } catch (error) {
        console.error('onSave -> error', error)
        toast(ERROR_DECODE(error), 'error')
      } finally {
        setSavingState(false)
      }
    }
  }

  const onDeleteCategory = async () => {
    try {
      const res = await deleteCategory(isPopOpen)
      if (res?.message) {
        toast('Category deleted successfully!', 'success')
        fetchAction()
      }
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    }
  }

  React.useEffect(() => {
    if (data) {
      onCancel()
      setAllCategories(cloneDeep(data))
    }
  }, [data])

  return (
    <Box padding="0" boxShadow>
      <LoaderPopup isPopOpen={paLoading} />
      <CancelPopup
        isPopOpen={!!isPopOpen}
        setIsPopOpen={setIsPopOpen}
        onOkClick={onDeleteCategory}
        header="Delete Category - Are you sure?"
        title="All the products under this category will be Uncategorised. Do you still want to continue?"
      />
      <BoxContent>
        <$FlexBox>
          <ColumnBox
            title="Category"
            headerControls={
              !isNewCategory ? (
                <$AddIconBlock onClick={onAddNewCategory}>
                  <AddBlueIcon />
                  &nbsp; Add New Category
                </$AddIconBlock>
              ) : null
            }
          >
            <Categories
              editCategory={editCategory}
              allCategories={allCategories}
              onActionClick={onActionClick}
              onCategoryNameChange={onCategoryNameChange}
            />
          </ColumnBox>
          <ColumnBox
            title="Products"
            colWidth="70%"
            headerControls={
              editCategory?.categoryId && !isUnCategorised ? (
                editCategory?.action === 'view' ? (
                  <$AddIconBlock
                    onClick={() =>
                      setEditCategory((state) => ({
                        ...cloneDeep(state),
                        action: 'edit',
                      }))
                    }
                  >
                    <AddBlueIcon />
                    &nbsp; Add New Products
                  </$AddIconBlock>
                ) : (
                  <div>
                    <Button
                      variant="primaryOutline"
                      onClick={onCancel}
                      disabled={savingState}
                      fontSize="12px"
                      mr={spacing.m}
                      fontWeight="bold"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={onSave}
                      disabled={savingState}
                      fontSize="12px"
                      fontWeight="bold"
                    >
                      Save
                    </Button>
                  </div>
                )
              ) : null
            }
          >
            <Products
              brandId={selectedBrand?.value}
              vendorId={selectedVendor?.value}
              allCategories={allCategories}
              editCategory={editCategory}
              setEditCategory={setEditCategory}
            />
          </ColumnBox>
        </$FlexBox>
      </BoxContent>
    </Box>
  )
}

export default AllCategories
