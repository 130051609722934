import React, { useState } from 'react'
import { connect } from 'react-redux'

import { UploadHeader } from './UploadHeader'
import { UploadTable } from './UploadTable'

import { useUploadedDataApi } from './useUploadedDataApi.js'
import { $WhiteContainer } from '../AutoEngagement.styled'

const UploadedData = (props) => {
  const { vendorId } = props
  const { aeLoading, assetsList } = props.autoEngage

  const [search, setSearch] = useState('')
  const [skip, setSkip] = useState(0)
  const [update, setUpdate] = useState(0)
  const [action, setAction] = useState({
    id: '',
    action: '',
  })

  const onSearchChange = (s) => {
    setSearch(s)
    setSkip(0)
  }

  useUploadedDataApi(vendorId, search, skip, action, update)

  const pageCount =
    parseInt(assetsList.count / 10) +
    (parseInt(assetsList.count % 10) > 0 ? 1 : 0)

  return (
    <$WhiteContainer>
      <UploadHeader
        search={search}
        setSearch={onSearchChange}
        setUpdate={setUpdate}
      />
      <UploadTable
        pageNumber={skip / 10}
        tableLoading={aeLoading}
        onPaginationChange={(page) => setSkip(page.selected * 10)}
        fileList={assetsList.docs}
        pageCount={pageCount}
        setAction={setAction}
      />
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  vendorId: state.vendorIds.selectedVendor.value,
  autoEngage: state.autoEngage,
})

export default connect(mapStateToProps)(UploadedData)
