import { store } from '../index'
import * as types from '../types/usageAndInvoice'
import {
  USAGE_MAP,
  DIGITAL_BILLING,
  OVERALL_SUMMARY,
  PROMOTION,
  AUTO_ENGAGEMENT,
} from 'components/Content/usageAndInvoices/constants'

const initialState = {
  usageTab: {
    viewType: DIGITAL_BILLING,
    storeIds: [],
    company: null,
    search: '',
    page: 0,
    sortFilters: {},

    data: {},
    filters: {
      ...USAGE_MAP[DIGITAL_BILLING].filters,
    },
  },
}

export default function usageAndInvoicesReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USAGE_FILTERS:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          filters: {
            ...action.payload,
          },
        },
      }
    case types.SET_USAGE_TAB_COMPANY_ID:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          company: action.payload,
        },
      }
    case types.SET_SORT_FILTERS:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          sortFilters: {
            ...action.payload,
          },
        },
      }
    case types.SET_PAGINATION:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          page: action.payload,
        },
      }
    case types.SET_USAGE_STORE_IDS:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          ...action.payload,
        },
      }
    case types.SET_VIEW_TYPE:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          ...action.payload,
        },
      }

    case types.SET_USAGE_DATA_BY_TYPE:
      return {
        ...state,
        usageTab: {
          ...state.usageTab,
          data: {
            ...action.payload,
          },
        },
      }
    default:
      return { ...state }
  }
}
