import React, { useState } from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $FlexBox, $Block, $ToolGroup, $ToolItem } from '../../Editor.styled'
import PropertyHeader from '../../PropertyHeader'
import {
  PropertyBar,
  FontWeight,
  BorderStyle,
  Padding,
  BorderRadius,
  FontFamily,
} from 'builder/editor/common/PropertyBar'
import { CenterAlignIcon, LeftAlignIcon, RightAlignIcon } from 'assets/icons'
import { Div } from 'styledComponent'
import { EmbedSurvey } from 'builder/editor/common/EmbedSurvey'
import { StyledInput, UploadButton } from 'builder/editor/common/Common.styled'

export const ButtonSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [isSurvey, setIsSurvey] = useState(
    (props.url || '').includes('$survey[')
  )

  const setTextAlign = (align) => {
    setProp((props) => (props.textAlign = align), 300)
  }

  const onSurveySelect = (survey) => {
    setProp((props) => {
      props.url = `$survey[${survey.surveyId}]`
      props.surveyName = survey.name
    }, 300)
    setIsPopOpen(false)
  }

  const setSurveyRadio = (bool) => {
    setIsSurvey(bool)
    setProp((props) => {
      props.url = ''
      props.surveyName = ''
    }, 0)
  }

  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
    align: 'center',
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Button"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <$FlexBox justify="space-between">
        <PropertyBar
          label="Width"
          propertyName="width"
          isFlex
          isPercent
          {...commonProps}
        />

        <PropertyBar
          label="Height"
          propertyName="height"
          isFlex
          isPercent
          {...commonProps}
        />
      </$FlexBox>

      <PropertyBar
        margin="8px 0px"
        label="Button Text"
        propertyName="buttonText"
        craftProps={props}
        setProp={setProp}
        isFlex
        justify="space-between"
      />

      <>
        <hr />
        <$FlexBox
          gap="20px"
          wrap="nowrap"
          onClick={() => setSurveyRadio(false)}
        >
          <input
            style={{ width: '20px', height: '20px' }}
            type="radio"
            checked={!isSurvey}
          />
          <PropertyBar
            margin="8px 0px"
            label="Redirect URL"
            propertyName="url"
            craftProps={props}
            setProp={setProp}
            justify="space-between"
            placeholder="https://"
            isValueHidden={isSurvey}
          />
        </$FlexBox>

        <$FlexBox gap="20px" wrap="nowrap" onClick={() => setSurveyRadio(true)}>
          <input
            style={{ width: '20px', height: '20px' }}
            type="radio"
            readOnly
            checked={isSurvey}
          />
          <$Block width="100%">
            <$FlexBox padding="0 0 4px" justify="space-between">
              <small>Select Survey</small>
              <div>
                <UploadButton onClick={() => setIsPopOpen(true)}>
                  Survey
                </UploadButton>
              </div>
            </$FlexBox>
            <StyledInput
              readOnly
              style={{ width: '100%', background: '#D5D5D5' }}
              value={isSurvey ? props.surveyName : ''}
            />
          </$Block>
          <EmbedSurvey
            isPopOpen={isPopOpen}
            setIsPopOpen={setIsPopOpen}
            embeddedSurvey={props.url}
            onSurveySelect={onSurveySelect}
          />
        </$FlexBox>
        <hr />
      </>

      <PropertyBar
        label="Font Size"
        propertyName="fontSize"
        type="number"
        isFlex
        isSplit
        {...commonProps}
      />
      {FontWeight(props, setProp)}

      <Div p="4px" />

      {FontFamily(props, setProp)}

      <$FlexBox justify="space-between">
        <p>Alignment</p>
        <$ToolGroup>
          <$ToolItem
            isActive={props.textAlign === 'left'}
            onClick={() => setTextAlign('left')}
          >
            <LeftAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'center'}
            onClick={() => setTextAlign('center')}
          >
            <CenterAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'right'}
            onClick={() => setTextAlign('right')}
          >
            <RightAlignIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <PropertyBar
        type="color"
        margin="8px 0px"
        label="Button Text Colour"
        propertyName="color"
        craftProps={props}
        setProp={setProp}
        isFlex
        justify="space-between"
      />

      <PropertyBar
        type="color"
        margin="8px 0px"
        label="Button Background Colour"
        propertyName="background"
        craftProps={props}
        setProp={setProp}
        isFlex
        justify="space-between"
      />

      <PropertyBar
        type="color"
        margin="8px 0px"
        label="Button Border Colour"
        propertyName="borderColor"
        craftProps={props}
        setProp={setProp}
        isFlex
        justify="space-between"
      />

      <PropertyBar
        label="Button Border Width"
        propertyName="borderWidth"
        type="number"
        isFlex
        isSplit
        {...commonProps}
      />

      {BorderStyle(props, setProp, 'Button ')}
      {BorderRadius(props, setProp, 'Button ')}
      {Padding(props, setProp)}
    </$Block>
  )
}
