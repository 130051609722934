import React, { Component } from 'react'
import 'css/content/manageStores.scss'
import Select from 'react-select'
import { connect } from 'react-redux'
import { fetchStates } from 'redux/actions/locationAction'
import {
  setFilters,
  setRememberFilters,
  resetStoreFilters,
} from 'redux/actions/manageStoresActions'
import _ from 'lodash'
import CustomPopup from 'components/Content/CustomPopup'
import FilterFooter from './StoreFilter/Footer'
import { CheckBox } from 'styledComponent/components/input'
import { GreyText } from 'styledComponent/components/typography'
import {
  Div,
  DynamicBorderBox,
  FlexBox,
} from 'styledComponent/components/elements'
import { MultiSelect } from 'components/Common'

const defaultFilters = {
  noOfEmployee: {
    isActive: false,
    value: {
      from: 0,
      to: 0,
    },
  },
  size: {
    isActive: false,
    value: {
      from: 0,
      to: 0,
    },
  },
  location: {
    isActive: false,
    value: {
      state: [],
    },
  },
  selectedCompanies: {
    isActive: false,
    value: [],
  },
  selectedBrands: {
    isActive: false,
    value: [],
  },
}

const Header = (props) => {
  return (
    <div className="filter-modal">
      <div className="filter-modal-head">
        <h5 className="header">{props.heading}</h5>
      </div>
    </div>
  )
}

class StoreFilter extends Component {
  constructor() {
    super()
    this.state = {
      filters: _.cloneDeep(defaultFilters),
    }
    this.dialogRef = React.createRef()
  }

  componentDidMount() {
    this.props.states.length === 0 && this.props.fetchStates()
    this.setState({
      filters: _.cloneDeep(
        this.props.filters?.location ? this.props.filters : defaultFilters
      ),
    })
  }

  handleCheckboxClick = (filterName) => {
    let filters = _.cloneDeep(this.state.filters)
    filters[filterName].isActive = !filters[filterName].isActive
    filters[filterName].value = defaultFilters[filterName].value
    this.setState({
      filters,
    })
  }

  handleSelectedCompaniesChange = (value) => {
    let filters = _.cloneDeep(this.state.filters)
    filters['selectedCompanies'].value = [value]
    this.setState({
      filters,
    })
  }

  handleSelectedBrandsChange = (value) => {
    let filters = _.cloneDeep(this.state.filters)
    filters['selectedBrands'].value = [value]
    this.setState({
      filters,
    })
  }

  handleChange = (filterName, filterProperty, value) => {
    let filters = _.cloneDeep(this.state.filters)
    filters[filterName]['value'][filterProperty] = value
    this.setState({ filters })
  }

  setFilters = () => {
    if (!this.props.disableDefaultSetFilter)
      this.props.setFilters(this.state.filters)

    this.dialogRef.current.closeModal()

    if (typeof this.props.onFilterSet === 'function')
      setTimeout(() => this.props.onFilterSet(this.state.filters), 1)
  }

  handleRememberFilters = (value) => {
    this.props.setRememberFilters(value)
  }

  handleResetFilters = () => {
    this.props.resetStoreFilters()
    this.setState({ filters: _.cloneDeep(defaultFilters) })
    setTimeout(() => this.props.onFilterSet(), 1)
  }

  openFilterModal = () => {
    this.dialogRef.current.openModal()
  }

  render() {
    return (
      <CustomPopup
        className={'filter-modal'}
        headerComponent={true}
        heading={<Header heading="Create filters" />}
        footer={
          <FilterFooter
            onCancel={() => this.dialogRef.current.closeModal()}
            onSetFilters={this.setFilters}
            onResetFilters={this.handleResetFilters}
            onSetRememberFilters={this.handleRememberFilters}
            disableRememberFilter={this.props.disableRememberFilter}
          />
        }
        ref={this.dialogRef}
      >
        <div className="filter-modal-body">
          {!this.props.hideCompanySelect && (
            <DynamicBorderBox
              isActive={this.state.filters.selectedCompanies.isActive}
              borderColor="#0081ED"
            >
              <CheckBox
                id="select-company"
                checked={this.state.filters?.selectedCompanies?.isActive}
                margin="0px 0px 15px"
                onClick={() => this.handleCheckboxClick('selectedCompanies')}
              >
                <GreyText as="span">Select Company</GreyText>
              </CheckBox>
              <Select
                isDisabled={!this.state.filters?.selectedCompanies?.isActive}
                options={this.props.companies.map((company) => ({
                  label: company.companyName,
                  value: company.companyId,
                }))}
                value={this.state.filters?.selectedCompanies?.value}
                onChange={this.handleSelectedCompaniesChange}
              />
            </DynamicBorderBox>
          )}

          {!this.props.hideBrandSelect && (
            <DynamicBorderBox
              isActive={this.state.filters.selectedBrands.isActive}
              borderColor="#0081ED"
            >
              <CheckBox
                id="select-brand"
                checked={this.state.filters?.selectedBrands?.isActive}
                onClick={() => this.handleCheckboxClick('selectedBrands')}
                margin="0px 0px 15px"
              >
                <GreyText as="span">Select Brand</GreyText>
              </CheckBox>
              <Select
                isDisabled={!this.state.filters?.selectedBrands?.isActive}
                options={this.props.brands.map((brand) => ({
                  label: brand.name,
                  value: brand.brandId,
                }))}
                value={this.state.filters?.selectedBrands?.value}
                onChange={this.handleSelectedBrandsChange}
              />
            </DynamicBorderBox>
          )}

          <DynamicBorderBox
            isActive={this.state.filters?.location?.isActive}
            borderColor="#0081ED"
          >
            <CheckBox
              id="select-geography"
              checked={this.state.filters?.location?.isActive}
              onClick={() => this.handleCheckboxClick('location')}
              margin="0px 0px 15px"
            >
              <GreyText as="span">Geography</GreyText>
            </CheckBox>
            {/* <div className="sub-options"> */}
            <FlexBox inline expand align="center">
              <FlexBox align="center" expand>
                {/* <div className="sub-option mr-2"> */}
                <div className="sub-filter-name mr-2">
                  <GreyText as="span">Location</GreyText>
                </div>

                <Div width="100%">
                  <MultiSelect
                    options={this.props.states.map((st) => ({
                      label: st,
                      value: st,
                    }))}
                    onChange={(value, actionMeta) =>
                      this.handleChange('location', 'state', value)
                    }
                    value={this?.state?.filters?.location?.value?.state || []}
                    placeholder="Select"
                    isDisabled={!this?.state?.filters?.location?.isActive}
                  />
                </Div>
              </FlexBox>
            </FlexBox>
          </DynamicBorderBox>

          <DynamicBorderBox
            isActive={this.state.filters?.noOfEmployee?.isActive}
            borderColor="#0081ED"
            style={{ display: 'flex' }}
          >
            <CheckBox
              id="select-employees-range"
              checked={this.state.filters?.noOfEmployee?.isActive}
              onClick={() => this.handleCheckboxClick('noOfEmployee')}
            >
              <GreyText as="span">Number of Employees</GreyText>
            </CheckBox>
            {/* <div className="sub-options"> */}
            <FlexBox inline expand>
              <div className="sub-filter-name mt-0 mr-2"></div>
              <input
                type="number"
                className="form-control filter-select ml-2 mr-2"
                value={this.state.filters?.noOfEmployee?.value.from}
                disabled={!this.state.filters?.noOfEmployee?.isActive}
                onChange={(event) =>
                  this.handleChange('noOfEmployee', 'from', event.target.value)
                }
              />
              <div className="sub-filter-name ml-2 mr-2">-</div>
              <input
                type="number"
                className="form-control filter-select ml-2 mr-2"
                value={this.state.filters?.noOfEmployee?.value.to}
                disabled={!this.state.filters?.noOfEmployee?.isActive}
                onChange={(event) =>
                  this.handleChange('noOfEmployee', 'to', event.target.value)
                }
              />
            </FlexBox>
          </DynamicBorderBox>

          <DynamicBorderBox
            isActive={this.state.filters?.size?.isActive}
            borderColor="#0081ED"
            style={{ display: 'flex' }}
          >
            <CheckBox
              id="select-sqft"
              checked={this.state.filters?.size?.isActive}
              onClick={() => this.handleCheckboxClick('size')}
            >
              <GreyText as="span">Store Size (sq.ft)</GreyText>
            </CheckBox>
            <FlexBox inline expand>
              <div className="sub-filter-name mt-0 mr-2"></div>
              <input
                type="number"
                className="form-control filter-select ml-2 mr-2"
                value={this.state.filters?.size?.value.from}
                disabled={!this.state.filters?.size?.isActive}
                onChange={(event) =>
                  this.handleChange('size', 'from', event.target.value)
                }
              />
              <div className="sub-filter-name ml-2 mr-2">-</div>
              <input
                type="number"
                className="form-control filter-select ml-2 mr-2"
                value={this.state.filters?.size?.value.to}
                disabled={!this.state.filters?.size?.isActive}
                onChange={(event) =>
                  this.handleChange('size', 'to', event.target.value)
                }
              />
            </FlexBox>
          </DynamicBorderBox>
        </div>
      </CustomPopup>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.brand.brands,
    companies: state.company.companies,
    states: state.location.states,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchStates,
    setRememberFilters,
    setFilters,
    resetStoreFilters,
  },
  null,
  {
    forwardRef: true,
  }
)(StoreFilter)
