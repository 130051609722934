import React from 'react'
import { Text } from 'components/Common'
import { Div, FlexBox, Logo } from 'styledComponent'
import { FormattedNumber } from 'react-intl'
import styled, { css } from 'styled-components'

const StatsDivSpace = styled.div`
  margin-bottom: 39px;
`
const StatsDivLessSpace = styled.div`
  margin-top: 22px;
`

export const SEARCH_FIELD_OPTIONS = [
  {
    label: 'Invoice Number',
    value: 'invoiceNumber',
  },
  {
    label: 'Store Code',
    value: 'storeCode',
  },
  {
    label: 'Phone Number',
    value: 'user',
  },
  {
    label: 'Email',
    value: 'email',
  },
]

export const NYKAA_SEARCH_FIELD_OPTIONS = [
  {
    label: 'Invoice Number',
    value: 'invoiceNumber',
  },
  {
    label: 'Store Code',
    value: 'storeCode',
  },
]

export const SECURITY_USER_FIELD_OPTIONS = [
  {
    label: 'Phone Number',
    value: 'user',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Amount',
    value: 'parsedData.amount',
  },
]

export const centroStoreExitStores = [
  'lywv7jklc5b7ja',
  '9xxjklc57bn8',
  'lywv7jklc4znv2',
  '9xxjklc4y66r',
  '9xxjklc4vcm9',
  '2jgxmjkkhxn3vf',
  '7x0qks2qiti1',
  '32la51wrkqtaa7kc', // test store
]

export const STORE_DATA_COLUMNS = [
  'Store Details',
  'Store Code',
  'Total Sales',
  'Avg. Billing',
  'Total Trans.',
  'BillMe',
  'BillMe+Print',
  'Print',
  'Closed Window',
  'Trees Saved',
  'Tons Co2 Saved',
]

export const STORE_SORT_COLUMNS = [
  null,
  null,
  'sort',
  'sort',
  'sort',
  'sort',
  'sort',
  'sort',
  'sort',
]

export const getKeyName = (field) => {
  switch (field) {
    case 'Total Sales':
      return 'totalSales'
    case 'Avg. Billing':
      return 'avgBillingAmount'
    case 'Total Trans.':
      return 'totalTransactions'
    case 'BillMe':
      return 'totalBM'
    case 'BillMe+Print':
      return 'totalBMPrint'
    case 'Print':
      return 'totalPrint'
    case 'Closed Window':
      return 'totalCW'
    case 'Trees Saved':
      return 'treesSaved'
    case 'Tons CO <sub>2</sub> Saved':
      return 'co2'
    default:
      return 'totalTransactions'
  }
}

export const calcPercent = (numerator = 0, denominator = 0) =>
  ((numerator * 100) / (denominator || 1))?.toFixed(2)

export const STORE_DATA_ROW = (item) => [
  <FlexBox my="12px" align="center">
    <Logo width="50px" height="50px" src={item?.brandLogo} alt="" />
    <Div>
      <Text>{item?.name}</Text>
      <Text variant="small" ellipsis maxWidth="45ch">
        {item?.displayAddress}
      </Text>
      <StatsDivLessSpace />
      {item?.customFrontendID === 'CINEMA_BILL' ||
      item?.customFrontendID === 'CINEPOLISIND' ? (
        <React.Fragment>
          <Text
            variant="small"
            ellipsis
            maxWidth="45ch"
            style={{ marginBottom: '5px' }}
          >
            Movie Sales
          </Text>
          <Text variant="small" ellipsis maxWidth="45ch">
            F&B Sales
          </Text>
        </React.Fragment>
      ) : null}
    </Div>
  </FlexBox>,
  item?.storeCode || '-',
  <Div>
    <Text align="center">
      <FormattedNumber
        currency="INR"
        style="currency"
        value={item?.totalSales || 0.0}
      />
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.movieTotalSales || 0.0}
          />
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.fnbTotalSales || 0.0}
          />
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //avg bill
  <Div>
    <Text align="center">
      <FormattedNumber
        currency="INR"
        style="currency"
        value={item?.avgBillingAmount || 0.0}
      />
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.movieAvgBillingAmount || 0.0}
          />
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.fnbAvgBillingAmount || 0.0}
          />
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total Transaction
  <Div>
    <Text align="center">{item?.totalTransactions}</Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalTransactions || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalTransactions || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total BM Percentage
  <Div>
    <Text align="center">
      {item?.totalBM}({item?.totalBMPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalBM || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalBM || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total BM PrintPercent
  <Div>
    <Text align="center">
      {item?.totalBMPrint}({item?.totalBMPrintPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalBMPrint || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalBMPrint || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total PrintPercent
  <Div>
    <Text align="center">
      {' '}
      {item?.totalPrint}({item?.totalPrintPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalPrint || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalPrint || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total CWPercent
  <Div>
    <Text align="center">
      {' '}
      {item?.totalCW}({item?.totalCWPercent}%){' '}
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalCW || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalCW || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
]
export const STORE_DATA_ROW_DECATH = (item) => [
  <FlexBox my="12px" align="center">
    <Logo width="50px" height="50px" src={item?.brandLogo} alt="" />
    <Div>
      <Text>{item?.name}</Text>
      <Text variant="small" ellipsis maxWidth="45ch">
        {item?.displayAddress}
      </Text>
      <StatsDivLessSpace />
      {item?.customFrontendID === 'CINEMA_BILL' ||
      item?.customFrontendID === 'CINEPOLISIND' ? (
        <React.Fragment>
          <Text
            variant="small"
            ellipsis
            maxWidth="45ch"
            style={{ marginBottom: '5px' }}
          >
            Movie Sales
          </Text>
          <Text variant="small" ellipsis maxWidth="45ch">
            F&B Sales
          </Text>
        </React.Fragment>
      ) : null}
    </Div>
  </FlexBox>,
  item?.storeCode || '-',
  <Div>
    <Text align="center">
      <FormattedNumber
        currency="INR"
        style="currency"
        value={item?.totalSales || 0.0}
      />
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.movieTotalSales || 0.0}
          />
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.fnbTotalSales || 0.0}
          />
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //avg bill
  <Div>
    <Text align="center">
      <FormattedNumber
        currency="INR"
        style="currency"
        value={item?.avgBillingAmount || 0.0}
      />
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.movieAvgBillingAmount || 0.0}
          />
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          <FormattedNumber
            currency="INR"
            style="currency"
            value={item?.fnbAvgBillingAmount || 0.0}
          />
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total Transaction
  <Div>
    <Text align="center">{item?.totalTransactions}</Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalTransactions || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalTransactions || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total BM Percentage
  <Div>
    <Text align="center">
      {item?.totalBM}({item?.totalBMPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalBM || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalBM || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total BM PrintPercent
  <Div>
    <Text align="center">
      {item?.totalBMPrint}({item?.totalBMPrintPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalBMPrint || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalBMPrint || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total PrintPercent
  <Div>
    <Text align="center">
      {' '}
      {item?.totalPrint}({item?.totalPrintPercent}%)
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalPrint || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalPrint || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  //total CWPercent
  <Div>
    <Text align="center">
      {' '}
      {item?.totalCW}({item?.totalCWPercent}%){' '}
    </Text>
    <StatsDivSpace />
    {item?.customFrontendID === 'CINEMA_BILL' ||
    item?.customFrontendID === 'CINEPOLISIND' ? (
      <React.Fragment>
        <Text
          variant="small"
          ellipsis
          maxWidth="45ch"
          style={{ marginBottom: '5px' }}
        >
          {item?.movieTotalCW || 0.0}
        </Text>
        <Text variant="small" ellipsis maxWidth="45ch">
          {item?.fnbTotalCW || 0.0}
        </Text>
      </React.Fragment>
    ) : null}
  </Div>,
  <Div>
    <Text align="center"> {item?.treesSaved ? item?.treesSaved : 0.0}</Text>
    <StatsDivSpace />
  </Div>,
  <Div>
    <Text align="center">
      {' '}
      {item?.co2 ? (item?.co2 / 10000 / 1000).toFixed(2) : 0.0}
    </Text>
    <StatsDivSpace />
  </Div>,
]
