const SETTING_TYPE = 'crm'

const DATA_POINTS_HEADINGS = {
  heading: 'No. Of Data Points to be Collected',
  subHeading: 'Set the number of data points to be collected per transaction',
  toolTip: 'No. Of Data Points to be Collected',
}

const DATA_PRIORITY_HEADINGS = {
  heading: 'Data Collection Priority',
  toolTip: 'Data Collection Priority',
  subHeading:
    'This will prompt the cashier to ask information based on your priority.',
}

const INITIAL_DATA = {
  settings: [
    {
      label: 'Data Point',
      key: 0,
    },
    {
      label: 'Field priority',
      key: [],
    },
  ],
  type: SETTING_TYPE,
}

export {
  DATA_POINTS_HEADINGS,
  DATA_PRIORITY_HEADINGS,
  INITIAL_DATA,
  SETTING_TYPE,
}
