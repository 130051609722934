import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSelectedStore } from 'redux/actions/authActions'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import 'css/content/manageStores.scss'
import StoreFilter from './StoreFilter'
import {
  resetStoreFilters,
  resetLocationFilter,
  resetNoOfEmployeeFilter,
  resetSelectedBrandsFilter,
  resetSelectedCompaniesFilter,
  resetSizeFilter,
  resetSelectedStoresFilter,
  setSelectedBrandsFilter,
  setSelectedCompaniesFilter,
  setSelectedStoresFilter,
  setInactiveSelectedStoresFilter,
  setPreviousSidebarLink,
} from 'redux/actions/manageStoresActions'
import { selectBrandForAdvert } from 'redux/actions/advertActions'
import { Avatar, PopupContainer, SearchInput } from 'components/Common'
import { setSideBarLink } from 'redux/actions/sidebarLinkActions'
import Navbar from 'components/Layout/Navbar'

import SweetAlert from 'sweetalert'
import { Div, FlexBox, PrimaryButton } from 'styledComponent'

class ManageStores extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: [],
      inactiveColumns: [],
      allStoresSelected: false,
      inactiveStoresSelected: false,
      isFilterSet: false,
      autoCompleteData: [],
      showInactive: false,
    }
    this.generateColumns = this.generateColumns.bind(this)
    this.generateInactiveColumns = this.generateInactiveColumns.bind(this)
    this.loadStores = this.loadStores.bind(this)
    this.openFilterModal = this.openFilterModal.bind(this)
    this.getFilters = this.getFilters.bind(this)

    this.filterModalRef = React.createRef()
  }

  componentDidMount() {
    this.generateColumns()
    this.generateInactiveColumns()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVendorId !== this.props.selectedVendorId) {
      this.generateColumns()
      this.generateInactiveColumns()
    }
  }

  openFilterModal() {
    this.filterModalRef.current.openFilterModal()
  }

  getStoreCount = () => {
    let field = this.state.showInactive ? 'inactiveStores' : 'stores'
    const filterStoresByVendorId = Object.values(this.props?.[field])?.filter(
      (store) => store?.vendorId === this.props.selectedVendorId
    )

    return Object.keys(filterStoresByVendorId).length
  }

  async loadStores() {
    if (this.state.showInactive) {
      let storeIds = []
      for (let column of this.state.inactiveColumns) {
        for (let row of column) {
          if (row && row.storeId && row.isSelected) storeIds.push(row.storeId)
        }
      }
      if (storeIds.length === 0) {
        SweetAlert({
          title: 'Error',
          text: 'Please select atleast one store',
          buttons: {
            cancel: 'Close',
          },
        })
        return false
      }
      await this.props.setInactiveSelectedStoresFilter(storeIds)
      this.props.history.push({
        pathname: '/bills',
        state: 'fromStorePage',
      })
    } else {
      this.props.selectBrandForAdvert([])
      let storeIds = []
      for (let column of this.state.columns) {
        for (let row of column) {
          if (row && row.storeId && row.isSelected) storeIds.push(row.storeId)
        }
      }
      if (storeIds.length === 0) {
        SweetAlert({
          title: 'Error',
          text: 'Please select atleast one store',
          buttons: {
            cancel: 'Close',
          },
        })
        return false
      }
      await this.props.setSelectedStore({
        storeIds,
        vendorId: this.props.selectedVendorId,
      })
      this.props.setSideBarLink(this.props.previousSidebarLink)
      this.props.setPreviousSidebarLink('')
      this.props.history.goBack()
    }
  }

  handleSelectAll = () => {
    const field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    let columns = this.state?.[field]
    const selField = this.state.showInactive
      ? 'inactiveStoresSelected'
      : 'allStoresSelected'
    const isSelected = !this.isCheckedAll()
    for (let column of columns) {
      for (let row of column) {
        if (row) row.isSelected = isSelected
      }
    }
    this.setState({
      [field]: columns,
      [selField]: isSelected,
    })
  }

  handleCompanySelect = (columnIndex, rowIndex, isSelected) => {
    const field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    let columns = this.state?.[field]
    let companyId = columns[columnIndex][rowIndex].companyId
    let rowInitIndex = rowIndex

    for (let index = columnIndex; index < columns.length; index++) {
      for (
        let indexK = rowInitIndex;
        indexK < columns[index].length;
        indexK++
      ) {
        if (
          columns[index][indexK] &&
          companyId !== columns[index][indexK].companyId
        ) {
          break
        }
        if (columns[index][indexK])
          columns[index][indexK].isSelected = !isSelected
      }
      if (rowInitIndex) rowInitIndex = 0
    }
    this.setState({
      [field]: columns,
    })
  }

  handleStoreSelect = (columnIndex, rowIndex) => {
    let field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    let columns = this.state?.[field]
    columns[columnIndex][rowIndex].isSelected =
      !columns[columnIndex][rowIndex].isSelected
    this.setState({ [field]: columns })
  }

  handleResetFilters = () => {
    this.props.resetStoreFilters()
    setTimeout(() => {
      this.generateColumns()
      this.generateInactiveColumns()
    }, 0)
  }

  filterStores = (stores) => {
    let activeFilters = {}
    let filteredStores = {}
    let isFilterSet = false
    for (let filterIndex in this.props.filters) {
      if (this.props.filters[filterIndex].isActive)
        activeFilters = {
          ...activeFilters,
          [filterIndex]: { ...this.props.filters[filterIndex] },
        }
    }

    if (Object.keys(activeFilters).length) {
      isFilterSet = true
      this.setState({ isFilterSet })
      for (let storeIndex in stores) {
        const storeSize = parseInt(stores[storeIndex].storeSize)
        const noOfEmployee = parseInt(stores[storeIndex].noOfEmployee)
        const state = stores[storeIndex].state
        const city = stores[storeIndex].city
        const companyId = stores[storeIndex].companyId
        const brandId = stores[storeIndex].brandId
        const storeId = stores[storeIndex].storeId
        let isValid = true

        for (let filterIndex in activeFilters) {
          if (
            filterIndex === 'size' &&
            !(
              activeFilters[filterIndex].value.from <= storeSize &&
              storeSize <= activeFilters[filterIndex].value.to
            )
          ) {
            isValid = false
            break
          }
          if (
            filterIndex === 'noOfEmployee' &&
            !(
              activeFilters[filterIndex].value.from <= noOfEmployee &&
              noOfEmployee <= activeFilters[filterIndex].value.to
            )
          ) {
            isValid = false
            break
          }
          if (
            filterIndex === 'location' &&
            !activeFilters[filterIndex].value.state
              ?.map((a) => a.value)
              ?.includes(state)
          ) {
            isValid = false
            break
          }
          if (
            filterIndex === 'selectedCompanies' &&
            activeFilters[filterIndex].value.filter(
              (selectedCompany) => selectedCompany.value === companyId
            ).length === 0
          ) {
            isValid = false
            break
          }
          if (
            filterIndex === 'selectedBrands' &&
            activeFilters[filterIndex].value.filter(
              (selectedBrand) => selectedBrand.value === brandId
            ).length === 0
          ) {
            isValid = false
            break
          }
          if (
            filterIndex === 'selectedStores' &&
            activeFilters[filterIndex].value.filter(
              (selectedStore) => selectedStore.value === storeId
            ).length === 0
          ) {
            isValid = false
            break
          }
        }
        if (isValid) filteredStores[storeIndex] = stores[storeIndex]
      }
      return filteredStores
    }
    this.setState({ isFilterSet })
    return stores
  }

  handleResetFilter = (type) => {
    this.props[`reset${type}Filter`]()
    setTimeout(() => {
      this.generateColumns()
      this.generateInactiveColumns()
    }, 0)
  }

  generateColumns() {
    let columnCounter = 0
    let companyIndexes = {}
    let columnItems = []
    let columns = []
    let companyId = ''
    const availableHeight = window.innerHeight - 390
    const rowsPerColumn = Math.ceil(availableHeight / 55)

    let validateColumn = () => columnItems.length >= rowsPerColumn

    let addColumn = () => {
      columnCounter++
      columns.push(columnItems)
      columnItems = []
    }

    let addNullRow = () => {
      validateColumn() && addColumn()
      columnItems.push(null)
    }
    const stores = this.filterStores(this.props.stores)

    for (let storeId in stores) {
      if (this.props.selectedVendorId !== stores[storeId].vendorId) continue

      if (companyId !== this.props.stores[storeId].companyId) {
        for (let x = 0; x < 2; x++)
          companyId &&
            columnItems.length !== 0 &&
            columnItems.length !== rowsPerColumn &&
            addNullRow()

        validateColumn() && addColumn()

        let row = this.props.companies.find(
          (company) => this.props.stores[storeId].companyId == company.companyId
        )

        if (row) row.isSelected = false

        columnItems.push(row)
        companyId = this.props.stores[storeId].companyId
        companyIndexes[companyId] = {
          columnIndex: columnCounter,
          rowIndex: columnItems.length - 1,
          stores: 0,
          selectedStores: 0,
        }
      }

      validateColumn() && addColumn()
      /* Add blank row if if first row is not a company row */
      if (!columnItems.length) {
        addNullRow()
      }

      const { brandLogo } = this.props.brands.find(
        (brand) => brand.brandId === this.props.stores[storeId].brandId
      )
      let row = {
        isSelected:
          this.props.selectedStores.indexOf(storeId) > -1 ? true : false,
        brandLogo,
        ...this.props.stores[storeId],
      }
      companyIndexes[companyId].stores++
      row.isSelected && companyIndexes[companyId].selectedStores++
      columnItems.push(row)
    }
    addColumn()

    for (let cId in companyIndexes) {
      if (
        companyIndexes[cId].selectedStores === companyIndexes[cId].stores &&
        columns[companyIndexes[cId].columnIndex][companyIndexes[cId].rowIndex]
          ?.isSelected
      ) {
        columns[companyIndexes[cId].columnIndex][
          companyIndexes[cId].rowIndex
        ].isSelected = true
      }
    }
    this.setState({ columns })
  }

  generateInactiveColumns() {
    let columnCounter = 0
    let companyIndexes = {}
    let columnItems = []
    let columns = []
    let companyId = ''
    const availableHeight = window.innerHeight - 390
    const rowsPerColumn = Math.ceil(availableHeight / 55)

    let validateColumn = () => columnItems.length >= rowsPerColumn

    let addColumn = () => {
      columnCounter++
      columns.push(columnItems)
      columnItems = []
    }

    let addNullRow = () => {
      validateColumn() && addColumn()
      columnItems.push(null)
    }
    const stores = this.filterStores(this.props.inactiveStores)

    for (let storeId in stores) {
      if (this.props.selectedVendorId !== stores?.[storeId].vendorId) continue

      if (companyId !== this.props.inactiveStores?.[storeId]?.companyId) {
        for (let x = 0; x < 2; x++)
          companyId &&
            columnItems.length !== 0 &&
            columnItems.length !== rowsPerColumn &&
            addNullRow()

        validateColumn() && addColumn()

        let row = this.props.companies.find(
          (company) =>
            this.props.inactiveStores?.[storeId]?.companyId == company.companyId
        )

        if (row) row.isSelected = false

        columnItems.push(row)
        companyId = this.props.inactiveStores[storeId].companyId
        companyIndexes[companyId] = {
          columnIndex: columnCounter,
          rowIndex: columnItems.length - 1,
          stores: 0,
          selectedStores: 0,
        }
      }

      validateColumn() && addColumn()
      /* Add blank row if if first row is not a company row */
      if (!columnItems.length) {
        addNullRow()
      }

      const { brandLogo } = this.props.brands.find(
        (brand) =>
          brand.brandId === this.props?.inactiveStores[storeId]?.brandId
      )
      let row = {
        isSelected: this.props?.inactiveSelectedStores?.indexOf(storeId) > -1,
        brandLogo,
        ...this.props.inactiveStores[storeId],
      }
      companyIndexes[companyId].stores++
      row.isSelected && companyIndexes[companyId].selectedStores++
      columnItems.push(row)
    }
    addColumn()

    for (let cId in companyIndexes) {
      if (
        companyIndexes[cId].selectedStores === companyIndexes[cId].stores &&
        columns[companyIndexes[cId].columnIndex][companyIndexes[cId].rowIndex]
          ?.isSelected
      ) {
        columns[companyIndexes[cId].columnIndex][
          companyIndexes[cId].rowIndex
        ].isSelected = true
      }
    }
    this.setState({ inactiveColumns: columns })
  }

  handleAutoCompleteItemSelect = (item) => {
    if (item.type === 'store') {
      this.state.showInactive
        ? this.props.setInactiveSelectedStoresFilter(item)
        : this.props.setSelectedStoresFilter(item)
    } else if (item.type === 'company') {
      this.props.setSelectedCompaniesFilter(item)
    } else if (item.type === 'brand') {
      this.props.setSelectedBrandsFilter(item)
    }
    setTimeout(() => {
      this.generateColumns()
      this.generateInactiveColumns()
    }, 0)
    this.setState({
      autoCompleteData: [],
    })
  }

  handleAutoCompleteChange = (input) => {
    const field = this.state.showInactive ? 'inactiveStores' : 'stores'
    let autoCompleteData = []
    let autoCompleteDataLimit = 20
    let addRow = (data) => {
      let item = {}
      if (data.storeId) {
        item = {
          value: data.storeId,
          label: data.displayAddress,
          image: this.props.brands.find(
            (brand) => brand.brandId === data.brandId
          ).brandLogo,
          type: `store`,
        }
      } else if (data.companyId) {
        item = {
          value: data.companyId,
          label: data.companyName,
          type: `company`,
        }
      } else if (data.brandId) {
        item = {
          value: data.brandId,
          label: data.name,
          image: data.brandLogo,
          type: `brand`,
        }
      }
      item.value && autoCompleteData.push(item)
    }
    let regExp = new RegExp(`${input}`, 'i')
    for (let index in this.props?.[field]) {
      if (autoCompleteData.length >= autoCompleteDataLimit) break
      regExp.test(this.props?.[field]?.[index].displayAddress) &&
        addRow(this.props?.[field]?.[index])
    }
    for (let index in this.props.companies) {
      if (autoCompleteData.length >= autoCompleteDataLimit) break
      regExp.test(this.props.companies[index].companyName) &&
        addRow(this.props.companies[index])
    }
    for (let index in this.props.brands) {
      if (autoCompleteData.length >= autoCompleteDataLimit) break
      regExp.test(this.props.brands[index].name) &&
        addRow(this.props.brands[index])
    }
    this.setState({
      autoCompleteData,
    })
  }

  componentWillUnmount() {
    !this.props.rememberFilters && this.props.resetStoreFilters()
  }

  getFilters() {
    if (this.props.rememberFilters) return this.props.filters
    return {}
  }

  isChecked = (companyId) => {
    const field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    const compStores = [].concat
      .apply([], this.state?.[field])
      ?.filter((str) => str?.companyId === companyId && str?.storeId)
    return compStores?.every((str) => str.isSelected)
  }

  isCheckedAll = () => {
    const field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    return [].concat
      .apply([], this.state?.[field])
      ?.filter((str) => str?.storeId)
      ?.every((str) => str?.isSelected)
  }

  render() {
    const storeCount = this.getStoreCount()
    const field = this.state.showInactive ? 'inactiveColumns' : 'columns'
    const selPropField = this.state.showInactive
      ? 'inactiveSelectedStores'
      : 'selectedStores'

    return (
      <div className="manage-stores">
        <StoreFilter
          onFilterSet={() => {
            this.generateColumns()
            this.generateInactiveColumns()
          }}
          ref={this.filterModalRef}
          filters={this.getFilters()}
        />
        <Navbar heading={'Change your store'} />
        <div className="manage-stores-content">
          <div className="row mb-2">
            <div className="col-md-7">
              <SearchInput
                placeHolder="Search By Company Name or Store Name or Brand Name"
                values={this.state.autoCompleteData}
                onInputChange={this.handleAutoCompleteChange}
                onSelect={this.handleAutoCompleteItemSelect}
              />
            </div>
            <div className="col-md-5 text-right">
              <button
                className="btn btn-borderless"
                type="button"
                onClick={() => {
                  this.generateInactiveColumns()
                  this.setState({ showInactive: !this.state.showInactive })
                }}
              >
                View {this.state.showInactive ? ' Active ' : ' Inactive '}{' '}
                Stores
              </button>
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={this.openFilterModal}
              >
                Filter By <i className="filter mr-1 ml-2" />
              </button>
              <PrimaryButton type="button" onClick={this.loadStores}>
                Load {this.state.showInactive ? ' Bills' : ' Data'}
              </PrimaryButton>
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <div className="col-md-9 filter-col">
              <div className="filter-by-icon mr-2 mt-1">
                <i className="filter" />
              </div>
              <div className="filter-list">
                {this.props.filters.selectedBrands.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      Brand :{' '}
                      {this.props.filters.selectedBrands.value.map(
                        (selectedBrand, index) =>
                          `${selectedBrand.label}${
                            this.props.filters.selectedBrands.value.length >
                              1 &&
                            this.props.filters.selectedBrands.value.length !==
                              index - 1
                              ? ', '
                              : ''
                          }`
                      )}
                      <i
                        className="remove-filter"
                        onClick={() => this.handleResetFilter('SelectedBrands')}
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                {this.props.filters.selectedCompanies.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      Company :{' '}
                      {this.props.filters.selectedCompanies.value.map(
                        (selectedCompany, index) =>
                          `${selectedCompany.label} ${
                            this.props.filters.selectedCompanies.value.length >
                              1 &&
                            this.props.filters.selectedCompanies.value
                              .length !==
                              index - 1
                              ? ', '
                              : ''
                          }`
                      )}
                      <i
                        className="remove-filter"
                        onClick={() =>
                          this.handleResetFilter('SelectedCompanies')
                        }
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                {this.props.filters.selectedStores.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      Store :{' '}
                      {this.props.filters.selectedStores.value.map(
                        (selectedStore, index) =>
                          `${selectedStore.label} ${
                            this.props.filters.selectedStores.value.length >
                              1 &&
                            this.props.filters.selectedCompanies.value
                              .length !==
                              index - 1
                              ? ', '
                              : ''
                          }`
                      )}
                      <i
                        className="remove-filter"
                        onClick={() => this.handleResetFilter('SelectedStores')}
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                {this.props.filters.location.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      Location :{' '}
                      {this.props.filters.location.value.state
                        ?.map((a) => a.value)
                        .join(', ')}
                      <i
                        className="remove-filter"
                        onClick={() => this.handleResetFilter('Location')}
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                {this.props.filters.noOfEmployee.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      No. Of Employees From{' '}
                      {this.props.filters.noOfEmployee.value.from} To{' '}
                      {this.props.filters.noOfEmployee.value.to}
                      <i
                        className="remove-filter"
                        onClick={() => this.handleResetFilter('NoOfEmployee')}
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                {this.props.filters.size.isActive && (
                  <div className="filter-item m-1">
                    <span className="filter-item-name">
                      Store Size From {this.props.filters.size.value.from} To{' '}
                      {this.props.filters.size.value.to}
                      <i
                        className="remove-filter"
                        onClick={() => this.handleResetFilter('Size')}
                      >
                        &times;
                      </i>
                    </span>
                  </div>
                )}
                <div className="add-filter m-1" onClick={this.openFilterModal}>
                  <i className="add-icon" />
                </div>
                {this.state.isFilterSet && (
                  <div
                    className="remove-filters"
                    onClick={() => this.handleResetFilters()}
                  >
                    Remove all filters
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3 text-right">
              <div className="stores-count">
                Showing&nbsp;<b>{storeCount}</b>&nbsp;of {storeCount} stores
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-md-12 d-flex justify-content-between">
              <div className="pl-3 pr-3 table-title">
                {this.props?.[selPropField]?.length > 0 && (
                  <div className="selected-stores ml-1 mr-3">
                    {`${this.props?.[selPropField]?.length} Stores Selected`}
                  </div>
                )}
                <div className="select-all-stores ml-2">
                  <div className="custom-control custom-checkbox ">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="select-all"
                      checked={this.isCheckedAll()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="select-all"
                      onClick={() => this.handleSelectAll()}
                    >
                      Select All
                    </label>
                  </div>
                </div>
                <div className="total">({storeCount} stores)</div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-3 ml-2 mr-2">
            <div className="col-md-12 pl-2 pr-2 pt-1 pb-2 company-list">
              {this.state?.[field]?.map((column, columnIndex) => {
                return (
                  <div className="column">
                    {column.map((row, rowIndex) => {
                      return !row ? (
                        <div className="item-padding" />
                      ) : row.storeId ? (
                        <div className="store-item">
                          <div className="custom-control custom-checkbox ">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              checked={row.isSelected}
                              id={`store-${row.storeId}`}
                            />
                            <label
                              className="custom-control-label"
                              for={`store-${row.storeId}`}
                              onClick={() =>
                                this.handleStoreSelect(columnIndex, rowIndex)
                              }
                            >
                              <div className="store-image">
                                <img
                                  className="brand-image"
                                  src={row.brandLogo}
                                  alt=""
                                />
                              </div>
                              <div className="store-name ml-2">
                                {row.storeCode ? `${row.storeCode} - ` : ''}
                                {row.displayAddress}
                              </div>
                            </label>
                          </div>
                        </div>
                      ) : row.companyId && row.companyName ? (
                        <div className="company-item">
                          <div className="custom-control custom-checkbox ">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              checked={this.isChecked(row.companyId)}
                              id={`company-${row.companyId}`}
                            />
                            <label
                              className="custom-control-label company-name"
                              for={`company-${row.companyId}`}
                              onClick={() =>
                                this.handleCompanySelect(
                                  columnIndex,
                                  rowIndex,
                                  this.isChecked(row.companyId)
                                )
                              }
                            >
                              {row.companyName}
                            </label>
                          </div>
                        </div>
                      ) : null
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  brands: state.brand.brands,
  stores: state.stores.stores,
  inactiveStores: state.stores.inactiveStores,
  selectedStores: state.stores.selectedStores,
  inactiveSelectedStores: state.stores.inactiveSelectedStores,
  companies: state.company.companies,
  filters: state.stores.filters,
  rememberFilters: state.stores.rememberFilters,
  previousSidebarLink: state.stores.previousSidebarLink,
  selectedVendorId: state.vendorIds.selectedVendor.value,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedStore,
      resetStoreFilters,
      resetLocationFilter,
      resetNoOfEmployeeFilter,
      resetSelectedBrandsFilter,
      resetSelectedCompaniesFilter,
      resetSelectedStoresFilter,
      resetSizeFilter,
      setSelectedBrandsFilter,
      setSelectedCompaniesFilter,
      setSelectedStoresFilter,
      setPreviousSidebarLink,
      setSideBarLink,
      selectBrandForAdvert,
      setInactiveSelectedStoresFilter,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageStores))
