import {
  cinepolisVendorId,
  nykaaVendorId,
} from 'components/conditionalComponent'

const routeList = {
  dashboard: 'dashboard',
  billingAnalytics: 'billingAnalytics',
  consumerAnalytics: 'consumerAnalytics',
  'beta-consumer-analytics': 'betaConsumerAnalytics',
  bills: 'bills',
  feedback: 'feedback',
  consumerSms: 'consumerSms',
  advertisement: 'advert',
  userAccess: 'userAccess',
  recharge: 'recharge',
  usage: 'recharge',
  billInvoice: 'recharge',
  profile: 'profile',
  'auto-engage': 'autoEngage',
}

const userTypes = {
  NORMAL: 'normal',
  CASHIER: 'cashier',
  MANAGER: 'manager',
  OPERATIONS: 'operations',
  SECURITY: 'security',
}

export { routeList, userTypes }
