import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'
import { Text, useToasts } from 'components/Common'
import { UploadBox } from '../components/MediaUpload'
import { ComponentBox } from '../components'
import { RecentUploads } from '../components/RecentUploads'
import { GET_ASSET_LIST_COUNT, SUPPORTED_FORMATS } from '../constants'
import {
  getAssetsList,
  setCampaignLoader,
  uploadFiles,
} from 'redux/actions/autoEngageActions'
import { ChooseCoupon, GeneralComponent } from '../components/BuilderComponents'
import { uploadMedia } from '../components/builderUtils'

const AfterSurvey = ({
  form,
  setForm,
  vendorId,
  storeUser,
  autoEngage,
  getAssetsList,
  setCampaignLoader,
}) => {
  const { spacing } = useTheme()
  const { toast } = useToasts()
  const {
    couponPosition = '',
    fileKey = '',
    fileLocation = '',
    couponFontTypeface = '',
    couponFontColor = '',
    couponFontSize = '',
    couponId = '',
  } = form?.afterCampaign || {}

  const [limit, setLimit] = useState(10)
  const [componentType, setComponentType] = useState('image')

  const { assetsList, assetsCount } = GET_ASSET_LIST_COUNT(
    componentType,
    autoEngage
  )

  const setMedia = ({ key, fileLocation }) => {
    setForm({
      ...form,
      afterCampaign: {
        ...form?.afterCampaign,
        fileLocation,
        fileKey: key,
      },
    })
  }

  const fetchFiles = (lim = 10) =>
    getAssetsList({
      vendorId,
      storeUser,
      skip: 0,
      limit: lim,
      fileType: componentType,
    })

  useEffect(() => {
    vendorId && fetchFiles(limit)
  }, [vendorId, componentType, limit])

  const onSetAfterCampaign = (key, value) => {
    setForm({
      ...form,
      afterCampaign: {
        ...form?.afterCampaign,
        [key]: value,
      },
    })
  }

  const uploadFile = async (e) =>
    await uploadMedia(
      e,
      setCampaignLoader,
      SUPPORTED_FORMATS('image'),
      vendorId,
      storeUser,
      setMedia,
      fetchFiles,
      limit,
      uploadFiles,
      toast,
      'surveyInBill'
    )

  return (
    <Div>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        After Survey
      </Text>

      <FlexBox width="100%">
        <Div width="100%">
          <Text variant="p" weight="bold" type="main" mb={spacing.l}>
            Add Image/GIF for Survey Completion Message
          </Text>
          <GeneralComponent
            fileType={componentType}
            acceptedFormats={SUPPORTED_FORMATS('image')
              .map((item) => `.${item}`)
              .join(', ')}
            uploadFile={uploadFile}
            src={fileLocation}
            couponId={couponId}
            couponPosition={couponPosition}
          />
          {fileLocation && (
            <Div my={spacing.l}>
              <ChooseCoupon
                spacing={spacing}
                couponId={couponId}
                onCouponSelect={({ value }) =>
                  onSetAfterCampaign('couponId', value)
                }
                assetsDetails={[{ fileLocation, fileKey }]}
                couponPosition={couponPosition}
                setCouponProperty={(val, key) =>
                  onSetAfterCampaign(
                    key,
                    key === 'couponFontColor' ? val : val.value
                  )
                }
                couponFontSize={couponFontSize}
                couponFontColor={couponFontColor}
                couponFontTypeface={couponFontTypeface}
                componentType={componentType}
                couponDisplay
              />
            </Div>
          )}
        </Div>
        <ComponentBox
          handleClick={(type) => setComponentType(type)}
          components={['image', 'gif']}
          activeComponent={componentType}
        />
      </FlexBox>
      <hr />
      <RecentUploads
        limit={limit}
        setLimit={setLimit}
        assetsCount={assetsCount}
        assetsList={assetsList}
        setMedia={setMedia}
        mediaType="image"
        activeArray={[fileLocation]}
      />
    </Div>
  )
}

const mapStateToProps = (state) => ({
  autoEngage: state.autoEngage,
  storeUser: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, { getAssetsList, setCampaignLoader })(
  AfterSurvey
)
