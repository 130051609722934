import React from 'react'
import styled from 'styled-components'

import { useRouter } from 'hooks'
import { SectionHeading } from 'components/Common/SectionHeading'
import { spacing, color } from 'design-system'

/**
 * @description commons tabs component
 * @param {*} props 
 * @example 
 * <Tabs>
      <div label="Tab 1">Tab 1</div>
      <div label="Tab 2">Tab 2</div>
      <div label="Tab 3">Tab 3</div>
    </Tabs>
 */
const Tabs = (props) => {
  const children = React.Children.toArray(props.children)
  const { tabMenuStyles = {} } = props
  const { query, setQuery } = useRouter()
  const { tab = children[0].props.label } = query
  const setTab = (tab) => {
    const tabQuery = { tab }
    setQuery(tabQuery, {
      preservePrevState: true,
    })
    props.setActiveTab(tab)
  }

  return (
    <>
      <$TabMenus {...tabMenuStyles}>
        {children.map(({ props: { label, minWidth } }) => (
          <Tab
            key={label}
            label={label}
            padding={props.padding}
            minWidth={minWidth}
            active={tab}
            clickHandler={setTab}
          />
        ))}
        {props.lastContent ? <$TabLast>{props.lastContent}</$TabLast> : null}
      </$TabMenus>
      <$TabContent>
        {children.map((content, index) =>
          content.props.label === tab ? (
            <React.Fragment key={index}>
              {content.props.children}
            </React.Fragment>
          ) : null
        )}
      </$TabContent>
    </>
  )
}

export default Tabs

export const StateTabs = (props) => {
  const children = React.Children.toArray(props.children)
  return (
    <>
      <$TabMenus>
        {children.map(({ props: { label, minWidth } }) => (
          <Tab
            key={label}
            label={label}
            minWidth={minWidth}
            active={props.tab}
            clickHandler={props.setTab}
          />
        ))}
        {props.lastContent ? <$TabLast>{props.lastContent}</$TabLast> : null}
      </$TabMenus>
      <$TabContent>
        {children.map((content, index) =>
          content.props.label === props.tab ? (
            <React.Fragment key={index}>
              {content.props.children}
            </React.Fragment>
          ) : null
        )}
      </$TabContent>
    </>
  )
}

const Tab = ({ clickHandler, label, active, minWidth, padding }) => {
  return (
    <$TabItem
      padding={padding}
      minWidth={minWidth}
      onClick={() => clickHandler(label)}
      active={active === label}
    >
      <SectionHeading
        heading={label}
        variant={'h4'}
        color={active === label ? 'primary' : ''}
        weight="semi-bold"
      />
    </$TabItem>
  )
}

const $TabMenus = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: ${({ borderBottom }) => borderBottom || 'none'};
`

const $TabItem = styled.li`
  padding: ${({ padding }) => padding || `${spacing.ml} ${spacing.m}`};
  min-width: ${({ minWidth }) => minWidth || '120px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  margin-right: 2%;
  cursor: ${({ active }) => (active ? 'auto' : 'pointer')};
  ${({ active }) =>
    active
      ? `
    background-color: #e1edff;
    &:after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${color.primary.default};
    }
  `
      : ''}
`

const $TabContent = styled.div`
  width: 100%;
  align-self: center;
`

const $TabLast = styled.li`
  margin-left: auto;
  align-items: center;
  align-self: center;
`
