import { ReactComponent as HappyFace } from 'images/face-happy.svg'
import { ReactComponent as NeutralFace } from 'images/face-neutral.svg'
import { ReactComponent as SadFace } from 'images/face-sad.svg'

const OS_STATS_TEXT = {
  heading: 'Operating Systems',
  subHeading: 'This value is derived from consumers digital bill link',
  toolTip:
    'The shows what kind of operating system (on phone) do your customers use.',
}

const CUSTOMER_SENTIMENT_TEXT = {
  heading: 'Customer Sentiment Value',
  subHeading: 'This value is curated from analyzing user feedback & ratings',
  toolTip:
    'Customer sentiment is the emotion customers feel overall towards your brand, product or service and can vary from positive to negative to neutral.',
}

const BRAND_AUTH_TEXT = {
  heading: 'Brand Authority',
  subHeading: 'This value is based on user feedback & ratings',
}

const ALL_CUSTOMER_LIST_TEXT = {
  heading: 'All customers',
  subHeading: 'List of all customers in your system',
}

const CUSTOMER_SEGMENT_TEXT = {
  heading: 'Customer Analytics',
  toolTip:
    "Customer analytics is a process by which data from customer's behavior are presented in a marketing readable format for predictive analysis.",
  subHeading: 'Curated customer segments on various touch points',
}

const CUSTOMER_SEGMENT_COUNT_TEXT = {
  heading: 'Customer Segmentations',
  toolTip:
    'Customer segmentation is the process of dividing customers into groups based on common characteristics so you can market to each group effectively and appropriately.',
  subHeading: 'Curated customers count based on different segmentation.',
}

const SENTIMENT_VALUE = [
  {
    type: 'moderate',
    image: NeutralFace,
  },
  {
    type: 'happy',
    image: HappyFace,
  },
  {
    type: 'sad',
    image: SadFace,
  },
]

const TOP_COSTUMER_COLS = [
  {
    label: 'Consumer no:',
    value: '_id',
  },
  {
    label: 'Total visits',
    value: 'visits',
  },
  {
    label: 'Total expense',
    value: 'amount',
  },
]

const CHART_TOOLTIPS = {
  avgSku:
    'In the field of inventory management, a stock keeping unit is a distinct type of item for sale, such as a product or service. For example, if you sell 2 units of product x, it means you have sold 1 SKU in a quantity of 2 (not to be confused with the number of units). Average SKU is how many SKUs are being sold per transaction on average.',
  retention:
    'Retention rate shows the number of coustomers that have retured to shop with you in a stipulated time.',
}

const FALLBACK_ROUTE = '/consumer-analytics'

const CHART_TAB_LABELS = {
  avgSpend: 'Avg. Spend',
  avgSKU: 'Avg. SKU',
  gender: 'Gender',
  age: 'Age',
  retention: 'Retention',
  region: 'Region',
}

export {
  CUSTOMER_SENTIMENT_TEXT,
  OS_STATS_TEXT,
  BRAND_AUTH_TEXT,
  ALL_CUSTOMER_LIST_TEXT,
  CUSTOMER_SEGMENT_TEXT,
  CUSTOMER_SEGMENT_COUNT_TEXT,
  SENTIMENT_VALUE,
  TOP_COSTUMER_COLS,
  FALLBACK_ROUTE,
  CHART_TOOLTIPS,
  CHART_TAB_LABELS,
}
