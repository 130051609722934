import React from 'react'

import { PillArray, Pill, Text } from 'components/Common'
import { spacing } from 'design-system'
import { AddIcon } from 'styledComponent'
import { $FlexBox } from '../../UserComplaints.styled'
import { $Button, $RemoveButton, $ContentDiv } from './FilterPills.styled'

/**
 * @description display all applied filters in pill fashion
 */
const FilterPills = React.forwardRef(({ setFilter, filter }, ref) => {
  const onPillClose = (field) => {
    const filtersCopy = { ...filter }

    if (['startDate', 'endDate'].includes(field)) {
      delete filtersCopy.startDate
      delete filtersCopy.endDate
    } else {
      delete filtersCopy[field]
    }

    setFilter(filtersCopy)
  }

  const _getActiveFilterLabels = () => {
    const activeFilters = Object.keys(filter || {})?.reduce((acc, curr) => {
      if (filter[curr]) {
        const activeObj = {
          label: `${curr
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/(?:^|\s|["'([{])+\S/g, (match) =>
              match.toUpperCase()
            )} - ${filter[curr]}`,
          id: curr,
        }
        acc.push(activeObj)
      }
      return acc
    }, [])
    return activeFilters
  }

  const onRemoveAll = () => {
    setFilter({})
  }

  const activeFilters = _getActiveFilterLabels()

  if (!activeFilters?.length) return <div></div>

  return (
    <$FlexBox width="70%" aling="center">
      <Text mr={spacing.s}>Filters : </Text>
      <PillArray width="80%" wrap="wrap">
        {activeFilters?.map(({ label, id }, index) => (
          <Pill
            key={index}
            margin={`0 ${spacing.s} ${spacing.s} 0`}
            variant="filter"
            type="tab"
            onClose={() => onPillClose(id)}
          >
            {label}
          </Pill>
        ))}
      </PillArray>
      <$ContentDiv>
        <$Button onClick={() => ref.current.openModal()}>
          <AddIcon />
        </$Button>
        <$RemoveButton variant="textualError" noBorder onClick={onRemoveAll}>
          Remove all filters
        </$RemoveButton>
      </$ContentDiv>
    </$FlexBox>
  )
})

export default FilterPills
