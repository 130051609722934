import { useSelectStore } from 'hooks'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSurveyButtons } from 'redux/actions/autoEngageActions'

import { CampaignPreview } from '../components/CampaignPreview'

export const SIBPreview = ({ form, hideSteps }) => {
  const dispatch = useDispatch()
  const {
    data: { surveyButtonsData },
  } = useSelectStore('autoEngage')

  const surveyButton =
    surveyButtonsData?.filter(
      (sbd) => form?.content?.body?.surveyButtonId === sbd?._id
    )[0]?.button || '-'

  useEffect(() => {
    surveyButtonsData?.length === 0 &&
      dispatch(
        getSurveyButtons({
          vendorId: form.vendorId,
          listAll: true,
        })
      )
  }, [])

  return (
    <CampaignPreview
      form={form}
      campaignType="Survey Button "
      surveyButton={surveyButton}
      hideSteps={hideSteps}
    />
  )
}
