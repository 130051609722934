import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import _, { isArray } from 'lodash'
import { connect, useSelector } from 'react-redux'

import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { Text, Input, Button } from 'components/Common'
import { AddBlueIcon } from 'assets/icons'
import { _selectOptionsGenerator } from 'utils/formatter'
import { getAssetsList, getSegments } from 'redux/actions/autoEngageActions'

import { AddSegmentModal } from './AddSegmentModal'
import { GET_SEL_VALUE, WHOM_TYPES } from '../constants'
import { $AddNew, $SegmentCharacteristics } from '../AutoEngagement.styled'
import UploadData from './UploadData'

const WhomToSend = (props) => {
  const { form, setForm, vendorId, uploadedFrom } = props
  const [characteristics, setCharacteristics] = useState([])
  const [isPopOpen, setIsPopOpen] = useState(false)

  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )
  const isSegment = form.targetGroup?.type === 'segments'

  const UPLOAD_DATA_TYPES =
    props.assetsCsvList?.map((asset) => ({
      label: asset.name,
      value: asset._id,
    })) || []

  const SEGMENT_TYPES =
    props.segmentData?.map((seg) => ({
      label: seg.name,
      value: seg._id,
    })) || []

  const fetchAssets = () =>
    props.getAssetsList({
      vendorId,
      listAll: true,
      fileType: 'csv',
    })

  const fetchSegments = () =>
    props.getSegments({
      vendorId,
    })

  useEffect(() => {
    vendorId && fetchAssets()
    vendorId && fetchSegments()
  }, [vendorId])

  useEffect(() => {
    const list = isSegment ? props.segmentData : props.assetsCsvList
    const listItem = list?.find((list) => list._id === form.targetGroup?.id)

    setCharacteristics(getSegmentDetails(listItem, listItem))
  }, [form.targetGroup?.id, form.targetGroup?.type])

  const setTargetGroupId = (val) =>
    setForm({
      ...form,
      targetGroup: {
        ...form.targetGroup,
        type: form.targetGroup?.type || 'userAssets',
        id: val.value,
      },
    })

  const setTargetGroupType = (val) =>
    setForm({
      ...form,
      targetGroup: {
        ...form.targetGroup,
        type: val,
        id: '',
      },
    })

  return (
    <>
      <FlexBox justify="space-between">
        <div style={{ flexBasis: '48%' }}>
          <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
            Whom To Send
          </Text>
          <Input
            variant="radio"
            direction="row"
            gap={spacing.xxl}
            label="Selection Type"
            id="type"
            value={form.targetGroup?.type}
            onChange={setTargetGroupType}
            options={WHOM_TYPES(
              uploadedFrom !== 'email' && featuresAlloted?.custSegmentation
            )}
          />
          {form.targetGroup?.type &&
            (isSegment ? (
              <Input
                containerMargin={spacing.m + ' 0'}
                variant="single-select"
                label="Segment"
                placeholder="Choose a Segment"
                options={SEGMENT_TYPES}
                id="id"
                value={GET_SEL_VALUE(form.targetGroup?.id, SEGMENT_TYPES) || ''}
                onChange={setTargetGroupId}
                inputWidth="280px"
              >
                <$AddNew>
                  <Button
                    noBorder
                    startIcon={AddBlueIcon}
                    fontSize="12px"
                    variant="textualPrimary"
                    onClick={() => setIsPopOpen(true)}
                  >
                    Add new Segment
                  </Button>
                </$AddNew>
              </Input>
            ) : (
              <Input
                containerMargin={spacing.m + ' 0'}
                variant="single-select"
                label="Uploaded Data"
                placeholder="Uploaded Data"
                options={UPLOAD_DATA_TYPES}
                id="id"
                value={
                  GET_SEL_VALUE(form.targetGroup?.id, UPLOAD_DATA_TYPES) || ''
                }
                onChange={setTargetGroupId}
                inputWidth="280px"
              >
                <$AddNew>
                  <Button
                    noBorder
                    startIcon={AddBlueIcon}
                    fontSize="12px"
                    variant="textualPrimary"
                    onClick={() => setIsPopOpen(true)}
                  >
                    Upload New Data
                  </Button>
                </$AddNew>
              </Input>
            ))}
        </div>
        <$SegmentCharacteristics>
          <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
            {GET_SEL_VALUE(form.targetGroup?.type, WHOM_TYPES(true))?.label}{' '}
            Characteristics
          </Text>
          <table>
            <tbody>
              {characteristics?.map((char) => (
                <tr key={char?.label}>
                  <td>{char?.label}</td>
                  <td>{char?.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </$SegmentCharacteristics>
      </FlexBox>
      {isSegment ? (
        <AddSegmentModal isPopOpen={isPopOpen} setIsPopOpen={setIsPopOpen} />
      ) : (
        <UploadData
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          setTargetGroupId={setTargetGroupId}
          fetchAssets={fetchAssets}
          acceptedFormats=".csv"
          fileType="csv"
          uploadedFrom={uploadedFrom}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  assetsCsvList: state.autoEngage.assetsCsvList.docs || [],
  segmentData: state.autoEngage.segmentData || [],
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, { getAssetsList, getSegments })(
  WhomToSend
)

export const getSegmentDetails = (listItem, isSegment) => {
  const chars = [
    {
      label: 'Name',
      value: listItem?.name || '-',
    },
    {
      label: 'Description',
      value: listItem?.description || '-',
    },
    {
      label: 'Created Date',
      value: listItem?.createdDate
        ? moment(listItem?.createdDate).format('DD MMM YYYY')
        : '-',
    },
  ]

  if (!isSegment) {
    const char = {
      label: 'Total Members',
      value: listItem?.additionalInfo?.totalCustomers || '-',
    }
    chars.push(char)
  }

  if (isSegment && listItem?.conditions?.length) {
    const getLabel = (field) =>
      _.startCase(field.replace(/([a-z])([A-Z])/g, '$1 $2'))
        ?.replace('Sku', 'SKU')
        ?.replace('Avg', 'Avg.')
        ?.replace('Os', 'OS')

    const getValue = (field) => {
      if (field === 'lastVisit') {
        return field + ' days ago'
      }
      if (isArray(field)) {
        return field?.map((item) => getLabel(item)).join(', ')
      }
      return field
    }

    const segArray = new Array(10)
    const filterId = {
      age: 0,
      avgFeedback: 1,
      totalSKU: 2,
      avgSKU: 3,
      totalSpent: 4,
      visitCount: 5,
      lastVisit: 6,
      gender: 7,
      state: 8,
      city: 9,
      os: 10,
    }

    for (let item of listItem?.conditions) {
      segArray[filterId[item?.filter]] = {
        label: getLabel(item?.filter),
        value: getValue(item.value),
      }
    }
    const segs = segArray.filter((item) => !!item)
    chars.push(...segs)
  }

  return chars
}
