import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSurveyResponses,
  setSurveyData,
  setLoadTable,
  setFromSIB,
  getSurveyById,
} from 'redux/actions/autoEngageActions'
import { FILTER_EMPTY } from '../../constants'

export const useSRApi = (query, surveyId) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoadTable(true))
    dispatch(getSurveyById(surveyId))
    dispatch(getSurveyResponses(surveyId, FILTER_EMPTY(query)))
  }, [query])

  useEffect(() => {
    return () => {
      dispatch(setSurveyData({}))
      dispatch(setFromSIB(false))
    }
  }, [])

  return null
}
