import React from 'react'
import styled, { css } from 'styled-components'

const $Component = styled.div`
  width: ${(props) => props.width || '30%'};
  text-align: center;
  cursor: pointer;
  padding-top: 10px;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #d8e7f6;
      box-shadow: rgb(0, 0, 0, 0.35) 0 2px 4px;
      border-radius: ${({ theme }) => theme.borderRadius.default};
    `};

  p {
    color: ${({ theme }) => theme.colors.pickledBluewood};
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
    padding: 0 4px 4px;
  }
`

export const BuilderComponent = ({
  icon,
  text,
  handleClick,
  isActive,
  width,
}) => {
  return (
    <$Component width={width} isActive={isActive} onClick={handleClick}>
      {icon}
      <p>{text}</p>
    </$Component>
  )
}
