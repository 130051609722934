import React from 'react'

import Navbar from 'components/Layout/Navbar'
import SegmentsMenu from './SegmentsMenu'
import NewSegment from './NewSegment'
import SelectedSegment from './SelectedSegment'
import { useStoreFetch, useSelectStore, useRouter } from 'hooks'
import { getSegmentList } from 'redux/actions/consumerAnalyticsActions'
import { SegmentsContext } from './contexts'
import {
  $Container,
  $FlexContainer,
  $FlexItem,
  $BodyStyles,
} from '../ConsumerAnalytics.styled'
import { Breadcrumb, TopActionBar } from 'components/Common'
import { AddIcon } from 'assets/icons'
import { FlexBox } from 'styledComponent'
import { useTheme } from 'styled-components'
import { $Alert } from './segments.styled'

const Segment = () => {
  const { setQuery, query } = useRouter()
  const [trigger, setTrigger] = React.useState('')
  const [showEditView, setShowEditView] = React.useState(false)

  const { spacing } = useTheme()

  const {
    loading,
    data: segmentsList,
    fetchData,
  } = useStoreFetch(getSegmentList, 'cAnalytics.segmentsList', true)
  const { data: selectedStores } = useSelectStore('stores.selectedStores')

  React.useEffect(() => {
    fetchData()
  }, [selectedStores])

  const buttonProps =
    query?.addNew || (query?.id && showEditView)
      ? {
          title: '',
          buttonTitle: query?.id && 'Update',
          width: '120px',
          onSave: () => setTrigger('save'),
          onExit: () => setTrigger('exit'),
          exitText: 'Back',
        }
      : segmentsList?.length < 20
      ? {
          title: '',
          buttonTitle: 'Create New Segment',
          startIcon: AddIcon,
          width: '245px',
          onSave: () => {
            const newSegmentQuery = {
              addNew: true,
            }
            setQuery(newSegmentQuery, {})
          },
        }
      : {
          extras: (
            <$Alert> You have reached the maximum limit of 20 segments.</$Alert>
          ),
        }

  const breadProps =
    query?.addNew || (query?.id && showEditView)
      ? {
          label: query?.addNew ? 'Create New Segment' : 'Edit Segment',
          breadcrumb: [
            {
              label: 'Customer Segmentations',
              link: '/segment',
            },
          ],
        }
      : { label: 'Customer Segmentations' }

  return (
    <>
      <Navbar heading="Customer Segmentations" />
      <FlexBox p={spacing.l} justify="space-between" align="center">
        <Breadcrumb {...breadProps} />
      </FlexBox>
      <TopActionBar {...buttonProps} />
      <$Container>
        <SegmentsContext.Provider value={{ fetchSegments: fetchData }}>
          <$FlexContainer>
            {!query?.addNew && (
              <$FlexItem flexBasis={'360px'} mr={'2%'}>
                <SegmentsMenu isLoading={loading} />
              </$FlexItem>
            )}
            <$FlexItem flexBasis={'75%'}>
              <SelectedSegment
                trigger={trigger}
                setTrigger={setTrigger}
                showEditView={showEditView}
                setShowEditView={setShowEditView}
              />
              <NewSegment trigger={trigger} setTrigger={setTrigger} />
            </$FlexItem>
          </$FlexContainer>
        </SegmentsContext.Provider>
      </$Container>
      <$BodyStyles />
    </>
  )
}

export default Segment
