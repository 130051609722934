import React, { useState, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { FormattedDate, FormattedList } from 'react-intl'
import { useDispatch } from 'react-redux'

import { spacing } from 'design-system'
import { FlexBox, NewStarIcon } from 'styledComponent'
import { Input, Checkbox, Pill, PillArray, Text } from 'components/Common'
import CustomPopup from 'components/Content/CustomPopup'
import { useSelectStore } from 'hooks'
import {
  FilterHeader,
  FilterFooter,
  MultiSelectFilter,
  ExpensePill,
} from './FilterComponents'
import {
  AVG_FEEDBACK_OPTIONS,
  LOCATION_DATA,
  initialFilter,
} from '../constants'
import {
  $Filter,
  $SelectedFilter,
  $ActiveFilter,
} from './CustomerListFilter.styled'
import { getMinMax } from 'utils'
import { get } from 'utils/axiosHandler'
import { useToasts } from 'components/Common/Toast'
import {
  _selectOptionsGenerator,
  _selectOptionGeneratorForLocation,
} from 'utils/formatter'
import { setLocationList } from 'redux/actions/consumerAnalyticsActions'

const singleSelectStyles = {
  inputWidth: '120px',
  margin: `0px ${spacing.xxl} 0px 0px`,
}

const getMultipleValueSum = (data) => {
  let sum = 0

  Object.values(data).forEach((value) => {
    sum += value.length
  })

  return sum
}

const getOSAndGenderPill = (type, value) => {
  switch (type) {
    case 'gender':
      const gender = []
      if (value.male) {
        gender.push('male')
      }
      if (value.female) {
        gender.push('female')
      }
      return gender
    case 'os':
      const os = []
      if (value.google) {
        os.push('android')
      }
      if (value.iphone) {
        os.push('iphone')
      }
      if (value.other) {
        os.push('other')
      }
      return os
  }
}

export const Filters = React.forwardRef(
  (
    {
      filters,
      isExport,
      setIsExport,
      handleSaveFilter,
      clearAllFilter,
      handleExport,
    },
    ref
  ) => {
    const [filter, setFilter] = useState([])
    const [selectedFilter, setSelectedFilter] = useState({
      id: 1,
      isActive: false,
      label: '',
    })

    useEffect(() => {
      setFilter(filters)
    }, [filters])

    const handleClearAll = () => {
      const copyFilter = Object.values(cloneDeep(initialFilter))

      if (isExport) {
        setFilter(copyFilter)
      } else {
        setFilter(copyFilter)
        clearAllFilter()
      }
    }

    const handleDeletePill = (id) => () => {
      const copyFilter = Object.values(cloneDeep(filter))
      const defaultValue = Object.values(initialFilter)
      copyFilter[id - 1].isActive = false
      copyFilter[id - 1].value = defaultValue[id - 1].value
      setFilter(copyFilter)
    }

    return (
      <CustomPopup
        className={'filter-modal'}
        ref={ref}
        headerComponent={true}
        heading={
          <FilterHeader
            filter={filter}
            popupRef={ref}
            isExport={isExport}
            setIsExport={setIsExport}
          />
        }
        footer={
          <FilterFooter
            popupRef={ref}
            filter={filter}
            handleClearAll={handleClearAll}
            handleSaveFilter={handleSaveFilter}
            isExport={isExport}
            setIsExport={setIsExport}
            handleExport={handleExport}
          />
        }
      >
        <$Filter>
          <FlexBox>
            <FlexBox direction="column">
              {Object.values(filter).map((item) => {
                const pillsData = Object.values(item)[3]
                const isValueObj =
                  pillsData !== null &&
                  typeof pillsData === 'object' &&
                  !Array.isArray(pillsData)

                return (
                  <$SelectedFilter
                    key={item.id}
                    onClick={() => {
                      setSelectedFilter(item)
                    }}
                    isActive={item.id === selectedFilter.id ? true : false}
                    isFilterActive={item.isActive}
                  >
                    {item.label}
                    {item.isActive && item.id === 2 && isValueObj && (
                      <PillArray wrap="wrap">
                        {pillsData.total?.isActive && (
                          <ExpensePill
                            label="Total"
                            id={item.id}
                            type="total"
                            value={[pillsData.total.min, pillsData.total.max]}
                            onClose={handleDeletePill}
                          />
                        )}
                        {pillsData.avg?.isActive && (
                          <ExpensePill
                            label="Avg."
                            id={item.id}
                            type="avg"
                            value={[pillsData.avg.min, pillsData.avg.max]}
                            onClose={handleDeletePill}
                          />
                        )}
                      </PillArray>
                    )}
                    {item.isActive &&
                      (item.id === 1 || item.id === 3) &&
                      isValueObj && (
                        <Pill
                          variant="filter"
                          type="tab"
                          onClose={handleDeletePill(item.id)}
                          marginLeft={spacing.m}
                        >
                          {item.id === 1 && (
                            <>
                              {pillsData.min} {' to '} {pillsData.max}
                            </>
                          )}
                          {item.id === 3 && (
                            <>
                              {pillsData.min === 0 ? '0' : pillsData.min}{' '}
                              {<NewStarIcon />} {' to '}{' '}
                              {pillsData.max === 0 ? '0' : pillsData.max}{' '}
                              {<NewStarIcon />}
                            </>
                          )}
                        </Pill>
                      )}
                    {item.isActive &&
                      (item.id === 4 || item.id === 5) &&
                      pillsData !== null &&
                      Array.isArray(pillsData) && (
                        <Pill
                          variant="filter"
                          type="tab"
                          onClose={handleDeletePill(item.id)}
                          marginLeft={spacing.s}
                        >
                          {pillsData.length}
                        </Pill>
                      )}
                    {item.isActive && item.id === 5 && pillsData !== null && (
                      <Pill
                        variant="filter"
                        type="tab"
                        onClose={handleDeletePill(item.id)}
                        marginLeft={spacing.s}
                      >
                        {getMultipleValueSum(pillsData)}
                      </Pill>
                    )}
                    {item.isActive && item.id === 6 && isValueObj && (
                      <Pill
                        variant="filter"
                        type="tab"
                        onClose={handleDeletePill(item.id)}
                        marginLeft={spacing.s}
                      >
                        {
                          <FormattedList
                            type="conjunction"
                            value={getOSAndGenderPill('gender', pillsData)}
                          />
                        }
                      </Pill>
                    )}
                    {item.isActive && item.id === 7 && isValueObj && (
                      <Pill
                        variant="filter"
                        type="tab"
                        onClose={handleDeletePill(item.id)}
                        marginLeft={spacing.s}
                      >
                        {
                          <FormattedList
                            type="conjunction"
                            value={getOSAndGenderPill('os', pillsData)}
                          />
                        }
                      </Pill>
                    )}
                  </$SelectedFilter>
                )
              })}
            </FlexBox>
            <ActiveFilter
              selected={selectedFilter}
              filter={filter}
              setFilter={setFilter}
            />
          </FlexBox>
        </$Filter>
      </CustomPopup>
    )
  }
)

const ActiveFilter = ({ selected: { id }, filter, setFilter }) => {
  const [location, setLocation] = React.useState({ states: [], cities: [] })
  const [isLocationFetched, setIsLocationFetched] = React.useState(false)
  const [selectedStates, setSelectedStates] = React.useState([])
  const [selectedCities, setSelectedCities] = React.useState([])

  const dispatch = useDispatch()
  const { toast } = useToasts()
  const { data: segmentsList } = useSelectStore('cAnalytics.segmentsList')
  const { data: locationList } = useSelectStore(
    'cAnalytics.allAvailableLocation.list'
  )
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')

  const value = Object.values(filter)[id - 1]

  React.useEffect(() => {
    const fetchLocationOptions = async () => {
      try {
        const res = await get(`/location/list`)

        const data = res.result
        const states = _selectOptionGeneratorForLocation(data, 'state')

        if (res.code) {
          dispatch(setLocationList(data))
          setLocation({ states, cities: [] })
        }
        setIsLocationFetched(true)
      } catch (e) {
        console.log(e)
        toast('Error in getting location, please try again', 'error')
      }
    }

    // Location filter selected
    if (id === 5 && selectedVendor?.value && !isLocationFetched)
      fetchLocationOptions()
  }, [id])

  const handleOnChange = (value, id, field) => {
    const copyFilter = Object.values(cloneDeep(filter))
    copyFilter[id - 1].isActive = true
    copyFilter[id - 1].value[field] = value
    setFilter(copyFilter)
  }

  const getOnChangeHandler = (value, id, field, key) => {
    switch (key) {
      case 'visit-date':
      case 'total-visit':
        return handleOnChange(value, id, field)
      case 'avg-fb':
        return handleOnChange(value.value, id, field)
    }
  }

  const handleExpenseChange = (e, id, type, index) => {
    const copyFilter = Object.values(cloneDeep(filter))
    const defaultFilter = Object.values(cloneDeep(initialFilter))
    let typeToDeselect
    // to select only one either total or avg
    if (type === 'total') {
      typeToDeselect = 'avg'
    } else {
      typeToDeselect = 'total'
    }
    if (copyFilter[id - 1].value[typeToDeselect].isActive === true) {
      copyFilter[id - 1].value[typeToDeselect] =
        defaultFilter[id - 1].value[typeToDeselect]
    }
    let value = getMinMax(0, Infinity, e.target.value)
    if (index === 'max') {
      value = getMinMax(
        copyFilter[id - 1].value[type].min,
        Infinity,
        e.target.value
      )
    }
    copyFilter[id - 1].isActive = true
    copyFilter[id - 1].value[type].isActive = true
    copyFilter[id - 1].value[type][index] = value
    setFilter(copyFilter)
  }

  const handleMultiSelectChange = (
    selectedValue,
    id,
    actionMeta,
    isLocation,
    locationType
  ) => {
    let value = selectedValue
    const arrIndex = id - 1

    const copyFilter = Object.values(cloneDeep(filter))
    const defaultFilter = Object.values(cloneDeep(initialFilter))

    if (isLocation) {
      if (locationType === 'states') {
        // Get all states and corresponding cities from them
        if (selectedValue?.length) {
          let cities = []
          for (let stateSelectedFromDropdown of selectedValue) {
            for (let listItem of locationList) {
              if (stateSelectedFromDropdown.value === listItem._id) {
                // When state matches, add cities
                cities = [...cities, ...listItem.cities]
              }
            }
          }
          const formattedCities = _selectOptionsGenerator(cities)
          setLocation({ ...location, cities: formattedCities })
          setSelectedStates(selectedValue)
        } else {
          setSelectedStates([])
          setSelectedCities([])
        }
      }

      if (locationType === 'cities') setSelectedCities(selectedValue)

      if (!value) {
        delete copyFilter[arrIndex].value[locationType]
        value = {
          ...copyFilter[arrIndex].value,
        }
      } else {
        value = {
          ...copyFilter[arrIndex].value,
          [locationType]: [...value],
        }
      }
    }

    const deleteOption = (type) => {
      if (isLocation) {
        copyFilter[arrIndex].value = { ...value }

        if (
          copyFilter[arrIndex].value?.states?.length === 0 &&
          copyFilter[arrIndex].value?.cities?.length === 0
        )
          copyFilter[arrIndex].isActive = false

        return
      }

      const index = copyFilter[arrIndex].value
        .map((item) => item.label)
        .indexOf(actionMeta[type].label)

      if (index > -1) copyFilter[arrIndex].value.splice(index, 1)
      if (copyFilter[arrIndex].value.length === 0)
        copyFilter[arrIndex].isActive = false
    }

    switch (actionMeta.action) {
      case 'select-option':
        copyFilter[arrIndex].isActive = true

        if (isLocation) copyFilter[arrIndex].value = { ...value }
        else copyFilter[arrIndex].value = [...value]
        break

      case 'deselect-option':
        deleteOption('option')
        break

      case 'remove-value':
        deleteOption('removedValue')
        break

      case 'clear':
        copyFilter[arrIndex] = defaultFilter[arrIndex]
        break

      default:
        copyFilter[arrIndex].isActive = !copyFilter[arrIndex].isActive
    }

    setFilter(copyFilter)
  }

  const handleCheckboxChange = (e, id) => {
    const arrIndex = id - 1
    const key = e.target.id
    const copyFilter = Object.values(cloneDeep(filter))

    copyFilter[arrIndex].isActive = true
    copyFilter[arrIndex].value[key] = e.target.checked

    if (
      copyFilter[arrIndex].value.male === false &&
      copyFilter[arrIndex].value.female === false
    ) {
      copyFilter[arrIndex].isActive = false
    }
    if (
      copyFilter[arrIndex].value.google === false &&
      copyFilter[arrIndex].value.iphone === false &&
      copyFilter[arrIndex].value.other === false
    ) {
      copyFilter[arrIndex].isActive = false
    }

    setFilter(copyFilter)
  }

  const segmentsOptions =
    segmentsList?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    })) || []

  const avgFeedbackValue = [
    {
      label: (
        <>
          {value.value?.min}
          <NewStarIcon />
        </>
      ),
    },
    {
      label: (
        <>
          {value.value?.max}
          <NewStarIcon />
        </>
      ),
    },
  ]

  return (
    <$ActiveFilter>
      <FlexBox expand padding={`${spacing.l}`}>
        {id === 1 && (
          <Input
            variant="range"
            type="number"
            label="Total Visits"
            id={['min', 'max']}
            inputWidth="125px"
            value={[value.value.min, value.value.max]}
            onChange={[
              (e) =>
                getOnChangeHandler(
                  getMinMax(0, Infinity, e.target.value),
                  id,
                  'min',
                  'total-visit'
                ),
              (e) =>
                getOnChangeHandler(
                  getMinMax(value.value.min, Infinity, e.target.value),
                  id,
                  'max',
                  'total-visit'
                ),
            ]}
          />
        )}
        {id === 2 && (
          <FlexBox direction="column" wrap="wrap">
            <Input
              variant="range"
              type="number"
              label="Total Expenses"
              id={['fromAmt', 'toAmt']}
              inputWidth="125px"
              value={[value.value.total.min, value.value.total.max]}
              onChange={[
                (e) => handleExpenseChange(e, id, 'total', 'min'),
                (e) => handleExpenseChange(e, id, 'total', 'max'),
              ]}
              wrap="wrap"
            />
            <Text variant="small">or</Text>
            <Input
              variant="range"
              type="number"
              label="Avg. Expenses"
              id={['fromAvg', 'toAvg']}
              inputWidth="125px"
              value={[value.value.avg.min, value.value.avg.max]}
              onChange={[
                (e) => handleExpenseChange(e, id, 'avg', 'min'),
                (e) => handleExpenseChange(e, id, 'avg', 'max'),
              ]}
              wrap="wrap"
            />
          </FlexBox>
        )}
        {id === 3 && (
          <Input
            label="Avg. Feedback"
            variant="single-select-range"
            options={[AVG_FEEDBACK_OPTIONS, AVG_FEEDBACK_OPTIONS]}
            value={avgFeedbackValue}
            onChange={[
              (e) => getOnChangeHandler(e, id, 'min', 'avg-fb'),
              (e) => getOnChangeHandler(e, id, 'max', 'avg-fb'),
            ]}
            {...singleSelectStyles}
            {...singleSelectStyles}
          />
        )}
        {id === 4 && (
          <MultiSelectFilter
            label="Segmentation"
            value={value.value}
            options={segmentsOptions}
            id={id}
            handleMultiSelectChange={handleMultiSelectChange}
          />
        )}
        {id === 5 && (
          <FlexBox direction="column" expand>
            <MultiSelectFilter
              label="States"
              value={value.value?.states}
              options={location.states}
              id={id}
              handleMultiSelectChange={(selectedValue, id, actionMeta) =>
                handleMultiSelectChange(
                  selectedValue,
                  id,
                  actionMeta,
                  true,
                  'states'
                )
              }
            />
            <br />
            <MultiSelectFilter
              label="Cities"
              value={value.value?.cities}
              options={location.cities}
              id={id}
              handleMultiSelectChange={(selectedValue, id, actionMeta) =>
                handleMultiSelectChange(
                  selectedValue,
                  id,
                  actionMeta,
                  true,
                  'cities'
                )
              }
              isDisabled={!selectedStates.length}
            />
          </FlexBox>
        )}
        {id === 6 && (
          <FlexBox direction="column">
            <Text as="label" variant="small" mb={spacing.m}>
              Gender
            </Text>
            <FlexBox mt={spacing.l}>
              <Checkbox
                id="male"
                mr={spacing.l}
                onCheckboxChange={(e) => handleCheckboxChange(e, id)}
                isChecked={value.value.male}
              >
                Male
              </Checkbox>
              <Checkbox
                id="female"
                onCheckboxChange={(e) => handleCheckboxChange(e, id)}
                isChecked={value.value.female}
              >
                Female
              </Checkbox>
            </FlexBox>
          </FlexBox>
        )}
        {id === 7 && (
          <FlexBox direction="column">
            <Text as="label" variant="small" mb={spacing.m}>
              OS
            </Text>
            <FlexBox mt={spacing.l}>
              <Checkbox
                id="google"
                mr={spacing.l}
                onCheckboxChange={(e) => handleCheckboxChange(e, id)}
                isChecked={value.value.google}
              >
                Android
              </Checkbox>
              <Checkbox
                id="iphone"
                onCheckboxChange={(e) => handleCheckboxChange(e, id)}
                isChecked={value.value.iphone}
              >
                iPhone
              </Checkbox>
              <Checkbox
                id="other"
                onCheckboxChange={(e) => handleCheckboxChange(e, id)}
                isChecked={value.value.other}
              >
                Other
              </Checkbox>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </$ActiveFilter>
  )
}
