import { get } from 'utils/axiosHandler'

//Get States
export const fetchStates = (params) => (dispatch) => {
  get('/store/states', params).then((res) => {
    if (res.code) {
      dispatch(fetchStatesHelper(res.states))
    }
  })
}

export const fetchStatesHelper = (data) => {
  return {
    type: 'SET_STATES',
    payload: data,
  }
}
