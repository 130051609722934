import React, { Fragment } from 'react'
import _, { cloneDeep } from 'lodash'
import { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import { NewStarIcon } from 'styledComponent/components/icons'
import { ReactComponent as EditIcon } from 'images/edit.svg'
import { DeleteIcon, ExportReportIcon } from 'assets/icons'
import { deleteReq } from 'utils/axiosHandler'
// import EditSegment from '../EditSegment'
import { useRouter, useSelectStore } from 'hooks'
import {
  SectionHeading,
  useToasts,
  Button,
  EmailReportPopup,
  Text,
} from 'components/Common'
import { DEVICE_ICON, SEGMENT_VALUES } from '../constants'
import { useSegmentsContext } from '../contexts'
import {
  $ContentLabel,
  $ContentValue,
  $DeviceTitle,
  $Entity,
  $EntityContent,
  $EntityContentBlock,
  $Hr,
  $SegmentDetails,
  $SegmentEntities,
  $DeleteButton,
} from './SelectedSegment.styled'
import { $StyledTag } from '../SegmentsMenu/SegmentsMenu.styled'
import { SelectedStoreTags } from 'components/Common/StoreSelector'
import { exportCustomerReport } from 'redux/actions/consumerAnalyticsActions'
import { ERROR_DECODE } from 'utils/reusables'
import { ExportButton } from 'components/Content/bills/list/BillsListHeader'
import { FilterOutlineButton } from 'styledComponent/components/button'
import formatDate from 'utils/getDateFormat'
import CancelPopup from 'components/Content/autoEngagement/components/CancelPopup'

const SelectedSegment = ({
  showEditView,
  setShowEditView,
  trigger,
  setTrigger,
}) => {
  const [selectedSegmentData, setSelectedSegmentData] = React.useState({})
  const [segmentFiltersData, setSegmentFiltersData] = React.useState([])
  const [deleteState, setDeleteState] = React.useState(false)

  const { data: segmentsList } = useSelectStore('cAnalytics.segmentsList')
  const { query, history, pathname } = useRouter()
  const { fetchSegments } = useSegmentsContext()
  const { toast } = useToasts()
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')

  const { id } = query

  React.useEffect(() => {
    setSelectedSegmentData(segmentsList?.find(({ _id }) => _id === id))
  }, [segmentsList, id])

  const onSegmentDelete = async () => {
    setDeleteState(true)
    try {
      const url = `/cust-segmentation/segments/${id}`
      const body = {
        vendorId: selectedVendor.value,
      }

      await deleteReq(url, body)
      fetchSegments()
      history.push(pathname)
    } catch (error) {
      console.error(error)
      toast('Something went wrong, please try again', 'error')
    } finally {
      setDeleteState(false)
    }
  }

  const _formatData = React.useCallback(() => {
    setShowEditView(false)
    const filterData = []
    if (!selectedSegmentData) return filterData

    for (const condition of selectedSegmentData?.conditions || []) {
      const segmentData = cloneDeep(SEGMENT_VALUES).find(({ filters }) =>
        filters.find(({ filter }) => filter === condition.filter)
      )

      if (segmentData) {
        const groupIndex = filterData.findIndex(
          (entry) => entry.group === segmentData.group
        )

        if (groupIndex > -1) {
          filterData[groupIndex].filters.forEach((entry) => {
            if (condition.filter === entry.filter) {
              entry.value = ['gender', 'state', 'city', 'posType'].includes(
                condition.filter
              )
                ? condition?.value
                    ?.map((field) =>
                      _.startCase(
                        _.toLower(field.replace(/([a-z])([A-Z])/g, '$1 $2'))
                      )
                    )
                    .join(', ')
                : condition.filter === 'consent'
                ? condition.value
                  ? 'Yes'
                  : 'No'
                : condition.value
            }
          })
        } else {
          segmentData.filters.forEach((entry) => {
            if (condition.filter === entry.filter) {
              entry.value = ['gender', 'state', 'city', 'posType'].includes(
                condition.filter
              )
                ? condition?.value
                    ?.map((field) =>
                      _.startCase(
                        _.toLower(field.replace(/([a-z])([A-Z])/g, '$1 $2'))
                      )
                    )
                    .join(', ')
                : condition.filter === 'consent'
                ? condition.value
                  ? 'Yes'
                  : 'No'
                : condition.value
              filterData.push(segmentData)
            }
          })
        }
      }
    }
    const trimmedFilterData = filterData.map((filterGroup) => {
      filterGroup.filters = filterGroup?.filters?.filter(({ value }) => value)
      return filterGroup
    })

    return setSegmentFiltersData(
      trimmedFilterData?.sort((a, b) => a.numeric - b.numeric)
    )
  }, [selectedSegmentData])

  React.useEffect(() => {
    _formatData()
  }, [_formatData])

  if (!id) return null

  const storeData =
    selectedSegmentData?.conditions?.find(
      (condition) => condition.filter === 'storeIds'
    )?.value || []
  return (
    <ViewSegment
      segmentFiltersData={segmentFiltersData}
      selectedSegmentData={selectedSegmentData}
      onSegmentDelete={onSegmentDelete}
      setShowEditView={setShowEditView}
      deleteState={deleteState}
      storeData={storeData}
    />
  )
  // Commented and not removed future reference
  // showEditView ? (
  //   <EditSegment
  //     segmentData={selectedSegmentData}
  //     onCancel={() => setShowEditView(false)}
  //     trigger={trigger}
  //     setTrigger={setTrigger}
  //   />
  // ) : (
  //   <ViewSegment
  //     segmentFiltersData={segmentFiltersData}
  //     selectedSegmentData={selectedSegmentData}
  //     onSegmentDelete={onSegmentDelete}
  //     setShowEditView={setShowEditView}
  //     deleteState={deleteState}
  //   />
  // )
}

export default SelectedSegment

const ViewSegment = ({
  segmentFiltersData,
  selectedSegmentData,
  onSegmentDelete,
  setShowEditView,
  deleteState,
  storeData,
}) => {
  const { spacing } = useTheme()
  const { toast } = useToasts()
  const [isPopOpen, setIsPopOpen] = React.useState(false)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = React.useState(false)

  const {
    data: { email: storeUserEmail },
  } = useSelectStore('auth.user')

  const onSendReport = async (emailList = []) => {
    try {
      if (emailList?.length === 0) throw 'Please enter at least one email ID'
      const res = await exportCustomerReport({
        segmentId: selectedSegmentData?._id,
        emailList,
        exportedBy: storeUserEmail,
        // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      if (res) toast(res?.message, 'success')
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw err
    }
  }

  return (
    <div>
      <CancelPopup
        isPopOpen={isDeletePopupOpen}
        setIsPopOpen={setIsDeletePopupOpen}
        onOkClick={onSegmentDelete}
        title="Are you sure you want to delete this segment?"
        header="Delete Segment"
      />
      <FlexBox justify="space-between" align="center">
        <SectionHeading
          heading={
            <FlexBox gap="10px">
              {selectedSegmentData?.name}{' '}
              {selectedSegmentData?.type === 'static' ? (
                <$StyledTag type={selectedSegmentData?.status}>
                  {selectedSegmentData?.status}
                </$StyledTag>
              ) : null}
            </FlexBox>
          }
          subHeading={selectedSegmentData?.description}
          variant="h3"
          subVariant="p"
          color="primary"
          weight="bold"
        />
        {selectedSegmentData?.vendorId !== 'default' ? (
          <div>
            {/* <Button
              color="#33475B"
              variant="primaryOutline"
              borderColor="#33475B"
              mr={spacing.m}
              onClick={() => setIsPopOpen(true)}
            >
              <ExportReportIcon />
            </Button> */}
            <ExportButton
              type="button"
              className="mr-2 py-2 filter-btn"
              onClick={() => setIsPopOpen(true)}
            >
              <ExportReportIcon />
            </ExportButton>
            {/* <FilterOutlineButton */}
            {/*   height="38px" */}
            {/*   type="button" */}
            {/*   className="filter-btn" */}
            {/*   onClick={() => setShowEditView(true)} */}
            {/* > */}
            {/*   <EditIcon className="my-1" /> */}
            {/* </FilterOutlineButton> */}
            <$DeleteButton
              variant="error"
              startIcon={DeleteIcon}
              noBorder
              onClick={() => setIsDeletePopupOpen(true)}
              disable={deleteState}
            />
          </div>
        ) : null}
      </FlexBox>
      <$SegmentDetails>
        <$Hr />
        <FlexBox justify="space-between">
          <SectionHeading
            heading="Segment Details"
            variant="h3"
            weight="bold"
          />
          <FlexBox gap="10px">
            {selectedSegmentData?.dateRange ? (
              <Fragment>
                <Text type="grey">
                  {formatDate(
                    new Date(selectedSegmentData?.dateRange?.startDate)
                  )}
                  {` till `}
                  {formatDate(
                    new Date(selectedSegmentData?.dateRange?.endDate)
                  )}
                </Text>
                <Text type="grey" style={{ fontSize: '24px' }}>
                  |
                </Text>
              </Fragment>
            ) : null}
            {selectedSegmentData?.totalCustomers !== undefined ? (
              <Text type="grey">
                Total Customers: {selectedSegmentData?.totalCustomers}{' '}
              </Text>
            ) : null}
          </FlexBox>
        </FlexBox>
        <$Hr />
        <FlexBox justify="space-between">
          {segmentFiltersData?.length ? (
            <$SegmentEntities>
              {segmentFiltersData?.map(({ group, filters }, index) => (
                <$Entity key={index + group}>
                  <SectionHeading heading={group} variant="h3" />
                  <$EntityContentBlock>
                    {filters.map(({ filter, title, value }, index) =>
                      filter !== 'os' ? (
                        <$EntityContent
                          mt={['state', 'city'].includes(filter) && spacing.m}
                          key={index}
                        >
                          <$ContentLabel
                            minWidth={
                              ['state', 'city'].includes(filter) && '80px'
                            }
                          >
                            {title}
                          </$ContentLabel>
                          <$ContentValue>
                            <span>
                              {Array.isArray(value) ? value.join(', ') : value}
                              {filter === 'avgFeedback' ? (
                                <NewStarIcon />
                              ) : null}
                            </span>
                          </$ContentValue>
                        </$EntityContent>
                      ) : (
                        <$EntityContent width="65%">
                          <$ContentLabel flex="0.6">{title}</$ContentLabel>
                          <$ContentValue justify="space-between" align="center">
                            {value.map((device, index) => (
                              <$DeviceTitle key={index}>
                                {DEVICE_ICON[device]}
                                <span>{device}</span>
                              </$DeviceTitle>
                            ))}
                          </$ContentValue>
                        </$EntityContent>
                      )
                    )}
                  </$EntityContentBlock>
                </$Entity>
              ))}
            </$SegmentEntities>
          ) : null}
          {storeData?.length ? (
            <Div marginTop={spacing.l} width="50%">
              <SectionHeading heading="Stores Visited" variant="h3" />
              <Div marginTop="10px">
                <SelectedStoreTags localSelectedStoreIds={storeData} />
              </Div>
            </Div>
          ) : null}
        </FlexBox>
      </$SegmentDetails>

      <EmailReportPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        onSendReport={onSendReport}
      />
    </div>
  )
}
