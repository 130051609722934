import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { SBBCampaign } from './SBBCampaign'
import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'

import { SBB_CAMP_STEPS, CAMP_INIT, CHECK_IF } from '../constants'
import { $WhiteContainer } from '../AutoEngagement.styled'
import { useCampaignApi } from '../components'

export const SellBelowBill = () => {
  const [sbbForm, setSbbForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(sbbForm, setSbbForm, CAMP_INIT, 'sellBelowBill')

  useEffect(() => {
    const step = hideSteps
      ? { id: 4, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && campaignId && updCampaign()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...sbbForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (campaignId && campaignId !== 'create'
            ? updCampaign()
            : createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...sbbForm?.content?.body }
        if (
          !builderDetails?.assetsDetails?.length ||
          builderDetails?.assetsDetails?.length < 1
        )
          throw 'Please select a pdf for your campaign.'

        return await updateCall()
      }

      case 3: {
        const storeDetails = { ...sbbForm?.targetGroup }
        if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
          throw 'Please select a brand and at least one store.'

        if (!!sbbForm?.turnOnCampaign) {
          if (!sbbForm?.turnOffCampaign)
            throw 'Please also select Auto Turn Off Campaign along with Auto Turn On campaign'
          else {
            const turnOnTime = moment(sbbForm?.activateAt)
            const turnOffTime = moment(sbbForm?.deactivateAt)

            if (!turnOnTime.isBefore(turnOffTime)) {
              throw 'Scheduled End Date time should be after Scheduled Start Date time'
            }
          }
        }

        return await updateCall()
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Sell Below the Bill"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={SBB_CAMP_STEPS}
        hideSteps={hideSteps}
        onSave={onSave}
        onPublish={onPublish}
        onPreviousClick={fetchCampaignById}
        form={sbbForm}
        campaignType="Sell Below the Bill"
      >
        <SBBCampaign
          form={sbbForm}
          setForm={setSbbForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}
