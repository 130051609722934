import moment from 'moment'

export default function timeToUTC(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export const MONTHS = moment
  .months()
  .map((month, index) => ({ label: month, value: index }))

export const getYearsTillNow = (back = 1970) => {
  const year = new Date().getFullYear()
  return Array.from({ length: year - back + 1 }, (v, i) => year - i)
}
