// Spacing sizes refers to tshirt size
// 2,4,8,16,32,64

export const spacing = {
  xs: '2px',
  s: '4px',
  sm: '6px',
  m: '8px',
  ml: '12px',
  l: '16px',
  mxl: '24px',
  xl: '32px',
  xxl: '64px',
  x3l: '96px',
  x4l: '128px',
}

// Spacing without units
export const spacingwu = {
  xs: 2,
  s: 4,
  m: 8,
  l: 16,
  xl: 32,
  xxl: 64,
}

/* 
    Points to remember:
        - On hover, darken the element color
        - And lighten when disabled

*/

// Color name credits: http://chir.ag/projects/name-that-color/

export const color = {
  primary: {
    light: '#B0CEFF',
    default: '#3B86FF',
    dark: '#0D52C2',
  },

  // Tertiary
  eggBlue: '#00BCD1', // HSL = hsl(182 100% 82%)
  salmon: '#FD7975', // HSL = hsl(2 54% 99%)
  butterflyBush: '#4F549E', // HSL =
  brinkPink: '#F9698D', // HSL =
  doveGray: '#F9F9F9', // HSL =
  grey: '#808080', // HSL =
  alto: '#D5D5D5', // HSL =
  solitude: '#EBF3FE',
  transparent: 'transparent',
  aquaHaze: '#F2F5F8',

  // Feedback
  success: {
    light: '#84d2ac',
    default: '#28C67A',
    dark: '#208416',
    bright: '#33C481',
  },
  warning: '#FBEECA',
  error: '#D76565',
  info: '',

  // texts
  dark: '#151515',
  medium: '#696969',
  light: '#BFBFBF',
  ultralight: '#FBFBFC',
  white: '#fff',
  lightBlue: '#f2f5f8',
  mediumBlue: '#3b86fc',
  pickledBluewood: '#33475B',
  mercury: '#E3E3E3',
  redViolet: '#D91ECA',

  // pill
  brightTurquoise: '#12b9f3',
  increase: '#62B349',
  decrease: '#D76565',
  mineShaft: '#333333',
  pillDefaultBorder: '#CFCFCF',
  zircon: '#f2f7ff',
  malibu: '#98C1F9',

  // toast
  toast: {
    success: {
      color: '#155724',
      background: '#d4edda',
    },
    warning: {
      color: '#856404',
      background: '#fff3cd',
    },
    error: {
      color: '#721c24',
      background: '#f8d7da',
    },
  },
}

export const borderRadius = {
  small: '4px',
  default: '6px',
  large: '10px',
}

// Z-index
