export const getLabelFromValueInOptions = (value, options) => {
  let filteredOptions = []

  if (options?.length)
    filteredOptions = options.filter((item) => item.value === value)

  if (filteredOptions.length === 1 && filteredOptions[0].label)
    return filteredOptions[0].label

  return '-'
}
