import React from 'react'

import { spacing } from 'design-system'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { $FlexBox } from '../UserComplaints.styled'
import {
  $FlexList,
  $FlexListItem,
  $SquareButton,
  $SearchInput,
} from './ComplaintsList.styled'
import ComplaintsFilter from './ComplaintsFilter'
import FilterPills from './FilterPills'

/**
 * @description table top bar with different option controls
 */
const TableControls = ({ setFilter, filter, search, setSearch }) => {
  const filterRef = React.useRef()
  const showModal = () => filterRef.current.openModal()

  return (
    <>
      <$FlexBox justify="space-between">
        <FilterPills ref={filterRef} setFilter={setFilter} filter={filter} />
        <$FlexList style={{ padding: 0 }}>
          <$FlexListItem>
            <$SearchInput>
              <span />
              <input
                id="searchTxt"
                value={search}
                placeholder="Search by phone or email"
                onChange={(e) => setSearch(e.target.value)}
              />
            </$SearchInput>
          </$FlexListItem>
          <$FlexListItem>
            <$SquareButton isFilter size={40} onClick={showModal}>
              <FilterIcon />
            </$SquareButton>
          </$FlexListItem>
        </$FlexList>
      </$FlexBox>
      <ComplaintsFilter ref={filterRef} setFilter={setFilter} filter={filter} />
    </>
  )
}

export default TableControls
