import React from 'react'
import moment from 'moment'
import { extendMoment } from 'moment-range'

import CustomPopup from 'components/Content/CustomPopup'
import { Text } from 'components/Common/Text'
import { spacing } from 'design-system'
import { FilterFooter, FilterHeader } from 'components/Common/FIlterComponents'

import { $FlexBox, $FlexItem } from '../UserComplaints.styled'
import { $FilterMenu, $FilterMenuItem } from './ComplaintsList.styled'
import { Button } from 'components/Common'
import DateRangePicker from 'react-daterange-picker'

const extendedMoment = extendMoment(moment)
const now = extendedMoment()
const defaultRange = extendedMoment.range(
  now.clone().startOf('day'),
  now.clone()
)

const ComplaintsFilter = React.forwardRef(({ setFilter, filter }, ref) => {
  const [dateRange, setDateRange] = React.useState(defaultRange)
  const [showDatePicker, setShowDatePicker] = React.useState(false)

  const handleFilter = () => {
    setFilter({
      ...filter,
      startDate: moment(dateRange.start).format('YYYY-MM-DD'),
      endDate: moment(dateRange.end).format('YYYY-MM-DD'),
    })
    ref.current.closeModal()
  }

  const handleClearFilter = () => {
    setFilter({})
    ref.current.closeModal()
  }

  const onDateChange = (field) => {
    setDateRange(field)
  }

  const _getFilterCount = () => {
    let count = 0
    for (const key in filter) {
      if (key === 'endDate') continue
      if (Array.isArray(filter[key]) && filter[key].length) {
        count++
      } else if (Object.values(filter[key]).length) {
        count++
      }
    }
    return count
  }

  React.useEffect(() => {
    let range = defaultRange
    if (filter.startDate && filter.endDate) {
      range = {
        start: moment(filter.startDate || ''),
        end: moment(filter.endDate || ''),
      }
    }

    setDateRange(range)
  }, [])

  return (
    <CustomPopup
      className={'filter-modal'}
      ref={ref}
      headerComponent={true}
      heading={
        <FilterHeader
          ref={ref}
          filter={filter || {}}
          filterCount={_getFilterCount()}
        />
      }
      footer={
        <FilterFooter
          ref={ref}
          filter={filter || {}}
          handleFilter={handleFilter}
          handleClearAll={handleClearFilter}
        />
      }
    >
      <FilterTabs>
        <div label="Date of Complaint">
          <>
            <Text variant="p">Date of Complaint</Text>
            <$FlexBox align="flex-start" direction="column">
              <Button
                variant="primaryOutline"
                fontSize="12px"
                onClick={() => setShowDatePicker(true)}
              >
                <i className="fas fa-calendar-alt" /> &nbsp;
                {moment(dateRange.start).format('DD MMM YYYY') +
                  ' - ' +
                  moment(dateRange.end).format('DD MMM YYYY')}
              </Button>
              {showDatePicker && (
                <DateRangePicker
                  numberOfCalendars={2}
                  onSelect={onDateChange}
                  value={dateRange}
                  maximumDate={moment().toDate()}
                  minimumDate={moment().subtract(90, 'd').toDate()}
                />
              )}
            </$FlexBox>
          </>
        </div>
      </FilterTabs>
    </CustomPopup>
  )
})

export default ComplaintsFilter

/**
 * @description Filter menu and content in a tab fashion component
 */
const FilterTabs = (props) => {
  const [activeFilter, setActiveFilter] = React.useState(null)
  const children = React.Children.toArray(props.children)

  React.useEffect(() => {
    setActiveFilter(children[0].props.label)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <$FlexBox minHeight="350px">
      <$FilterMenu>
        {children.map(({ props: { label } }) => (
          <Tab
            key={label}
            label={label}
            active={activeFilter}
            clickHandler={setActiveFilter}
          />
        ))}
      </$FilterMenu>
      <$FlexItem flexBasis="70%" padding={spacing.l}>
        {children.map((content, index) =>
          content.props.label === activeFilter ? (
            <React.Fragment key={index}>
              {content.props.children}
            </React.Fragment>
          ) : null
        )}
      </$FlexItem>
    </$FlexBox>
  )
}

const Tab = ({ clickHandler, label, active }) => {
  return (
    <$FilterMenuItem
      onClick={() => clickHandler(label)}
      active={active === label}
    >
      <Text variant="p" type={'grey'}>
        {label}
      </Text>
    </$FilterMenuItem>
  )
}
