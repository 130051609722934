import styled, { css } from 'styled-components'
import { space } from 'styled-system'

export const Table = styled.table`
  margin: ${(props) => props.margin || '.5rem 0px'};
  width: ${(props) => props.width || '100%'};
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0px 2px 30px #00000016;
`
export const TableHead = styled.thead`
  background: #f2f5f8;
  tr {
    td {
      height: 60px;
      font-weight: bold !important;
      font-size: 12px;
    }
  }
`
export const TableRow = styled.tr`
  transition: all 0.3s;
  font-size: 14px;

  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};

  &:hover {
    ${(props) =>
      props.highlightOnHover
        ? `background-color: ${props.highlightOnHover}`
        : ''};
  }

  tbody & {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.08) 100%,
      red 0%
    );
    background-position: bottom;
    background-size: 2px 1px;
    background-repeat: repeat-x;
  }

  &:hover {
    ${(props) =>
      props.highlightTextOnHover &&
      `
        &&&{
            ${TableCell}{
              color: ${props.highlightTextOnHover};
            }
        }
    `}
  }

  ${(props) =>
    props.fontSize &&
    `
        &&&{
            ${TableCell}{
                font-size: ${props.fontSize};
            }
        }
    `}
`

export const TableCell = styled.td`
  ${TableHead} & {
    padding: 5px 15px;
    color: #43424b;
    font-weight: 500;
  }

  width: ${(props) => props.width || 'auto'};
  min-width: 20px;
  padding: 15px 15px;
  font-size: 1em;
  font-weight: 400;
  color: ${(props) => props['color'] || '#43424b'};
  text-align: center;

  &&& {
    &:first-child,
    &:first-child * {
      text-align: left;
    }
  }

  &&& {
    ${({ textAlign }) =>
      textAlign &&
      css`
        text-align: ${textAlign};
      `}
  }

  ${space};
`
