import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import { _selectOptionsGenerator } from 'utils/formatter'
import { capitalizeFirstLetter } from '../../../../../utils/stringManipulation'

import {
  GET_OUTCOMES,
  DAYS_OF_THE_WEEK,
  EVENT_OCCURENCES,
  GET_SUB_EVENT_OPTIONS,
  SUB_EVENT_OCCURENCES,
  GET_DELAY_OPTIONS,
  GET_DELAY_FIELDS,
  GET_DELAY_PREPONE_FIELDS,
} from '../../constants'
import {
  Input,
  Checkbox,
  Text,
  Button,
  PopupContainer,
} from 'components/Common'
import {
  GET_SEL_VALUE,
  HOURS,
  MINUTES,
  PERIOD,
} from 'components/Content/autoEngagement/constants'
import { AddSegmentModal } from 'components/Content/autoEngagement/components/AddSegmentModal'
import { getAssetsList, getSegments } from 'redux/actions/autoEngageActions'
import UploadData from 'components/Content/autoEngagement/components/UploadData'
import { getJourneyProducts } from 'redux/actions/journeyActions'
import {
  $CampaignContainer,
  $SearchInput,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import { debounce } from 'lodash'
import moment from 'moment'
import Tags from 'components/Content/Products/components/ProductTag'

const JourneyPopup = ({
  nodeData,
  nodeDatum,
  setNodeDatum,
  isPopOpen,
  setIsPopOpen,
  onPopupCancel,
  onPopupSubmit,
  currentEdge,
  outcome,
  setOutcome,
  vendorId,
  segmentData,
  assetsCsvList,
  allFilters,
  allEvents,
  jbProducts,
  cy,
  jbDetails,
}) => {
  const { spacing } = useTheme()
  const { name = 'Outcomes', nodeId } = nodeData?.data || {}

  const [customerModal, setCustomerModal] = useState(false)

  const setCustomerId = (val) =>
    setNodeDatum({
      ...nodeDatum,
      event: name,
      customerId: val.value,
    })

  const getPopupBody = (popId) => {
    switch (popId) {
      case 'start':
        return (
          <StartPopup
            spacing={spacing}
            nodeDatum={nodeDatum}
            setNodeDatum={setNodeDatum}
            allEvents={allEvents}
            vendorId={vendorId}
            jbProducts={jbProducts}
            jbDetails={jbDetails}
          />
        )

      case 'customer':
        return (
          <CustomerPopup
            nodeId={nodeId}
            spacing={spacing}
            vendorId={vendorId}
            nodeDatum={nodeDatum}
            segmentData={segmentData}
            assetsCsvList={assetsCsvList}
            setCustomerId={setCustomerId}
            customerModal={customerModal}
            setCustomerModal={setCustomerModal}
          />
        )

      case 'time':
        return (
          <TimePopup
            spacing={spacing}
            nodeDatum={nodeDatum}
            setNodeDatum={setNodeDatum}
            waitOptions={GET_DELAY_OPTIONS(allFilters)}
          />
        )

      case 'edge':
        return (
          <OutcomePopup
            cy={cy}
            spacing={spacing}
            outcome={outcome}
            setOutcome={setOutcome}
            allFilters={allFilters}
            currentEdge={currentEdge}
            onPopupCancel={onPopupCancel}
          />
        )

      default:
        return ''
    }
  }

  const getCustomerPop = (nodeId) => {
    switch (nodeId) {
      case 'filter_customerSegment':
        return (
          <AddSegmentModal
            isPopOpen={customerModal}
            setIsPopOpen={setCustomerModal}
          />
        )

      case 'filter_customerSpecific':
        return (
          <UploadData
            isPopOpen={customerModal}
            setIsPopOpen={setCustomerModal}
            setTargetGroupId={setCustomerId}
            acceptedFormats=".csv"
            fileType="csv"
            uploadedFrom="journey"
          />
        )

      default:
        return null
    }
  }

  const overlayStyle = customerModal ? { background: 'transparent' } : {}

  return (
    <>
      <PopupContainer
        isPopOpen={Boolean(isPopOpen)}
        setIsPopOpen={setIsPopOpen}
        onCancel={onPopupCancel}
        heading={name}
        submitButtonName="Save"
        onSubmitClick={onPopupSubmit}
        overlayStyle={overlayStyle}
        width={'80%'}
      >
        {getPopupBody(isPopOpen)}
      </PopupContainer>
      {getCustomerPop(nodeId)}
    </>
  )
}

const mapStateToProps = (state) => ({
  assetsCsvList: state.autoEngage.assetsCsvList.docs || [],
  segmentData: state.autoEngage.segmentData || [],
  vendorId: state.vendorIds.selectedVendor.value,
  jbProducts: state.journey.jbProducts,
})

export default connect(mapStateToProps)(JourneyPopup)

const TimePopup = ({ spacing, nodeDatum, setNodeDatum, waitOptions }) => {
  nodeDatum.option = nodeDatum?.option ? nodeDatum?.option : 'date'
  const [row, setRow] = useState(nodeDatum?.option)
  const DELAY_OPTIONS =
    waitOptions?.map((se) => ({
      label: se.label,
      value: se.name,
    })) || []

  // functions
  const setDelay = (key, val) => {
    if (key === 'option') {
      setNodeDatum({
        event: 'Delay',
        option: val,
        condition: null,
      })
      setRow(val)
    } else {
      setNodeDatum({
        ...nodeDatum,
        condition: {
          ...nodeDatum.condition,
          [key]: val,
        },
      })
    }
  }

  const getConditions = (opt) => {
    const isSelected = opt?.name === row
    const inputVal =
      isSelected && nodeDatum?.condition?.value
        ? opt?.name === 'date'
          ? moment(nodeDatum?.condition?.value).format('YYYY-MM-DD')
          : nodeDatum?.condition?.value
        : ''
    const preponeVal =
      (isSelected &&
        nodeDatum?.condition?.prepone &&
        GET_DELAY_PREPONE_FIELDS.filter(
          (x) => x.value === nodeDatum?.condition?.prepone
        )[0]) ||
      ''
    const referenceFieldVal =
      (isSelected &&
        nodeDatum?.condition?.referenceField &&
        GET_DELAY_FIELDS.filter(
          (x) => x.value === nodeDatum?.condition?.referenceField
        )) ||
      ''

    return (
      <>
        <FlexBox direction="row">
          <input
            type="radio"
            value={opt?.name}
            onChange={(e) => setDelay('option', e.target.value)}
            checked={opt?.name === row}
          />
          <p style={{ padding: '15px 15px' }}>Start Next Action</p>
          <Input
            containerMargin={`0 ${spacing.l}`}
            disabled={opt?.name !== row}
            variant="input"
            type={opt?.inputField}
            id={opt?.name}
            min={moment(new Date()).format('YYYY-MM-DD')}
            value={inputVal}
            onChange={(e) => setDelay('value', e.target.value)}
            inputWidth={opt?.inputField === 'date' ? '240px' : '100px'}
          />
          <p style={{ padding: '15px 15px' }}>
            {capitalizeFirstLetter(opt?.name)}
          </p>
          {['days', 'minutes'].includes(opt?.name) && (
            <>
              <Input
                containerMargin={`0 ${spacing.l}`}
                variant={'single-select'}
                id="prepone"
                options={GET_DELAY_PREPONE_FIELDS}
                value={preponeVal}
                inputWidth={'240px'}
                placeholder="Select Before/After"
                disabled={opt?.name !== row}
                onChange={(e) => setDelay('prepone', e.value)}
              />
              <p style={{ padding: '15px 15px' }}>from</p>
              <Input
                containerMargin={`0 ${spacing.l}`}
                variant={'single-select'}
                id="referenceField"
                options={GET_DELAY_FIELDS}
                inputWidth={'240px'}
                placeholder="Select Event"
                disabled={opt?.name !== row}
                value={referenceFieldVal}
                onChange={(e) => setDelay('referenceField', e.value)}
              />
            </>
          )}
        </FlexBox>
      </>
    )
  }
  return (
    <Div height="400px">
      {/* <Input
        containerMargin="0"
        variant="radio"
        label="Options"
        id="option"
        direction="column"
        value={nodeDatum?.option || ''}
        onChange={(val) => setDelay('option', val)}
        options={DELAY_OPTIONS}
      /> */}
      <p style={{ padding: '15px 15px' }}>Choose a waiting option</p>
      <p style={{ padding: '15px 15px' }}>Option</p>
      {waitOptions?.map((se) => getConditions(se))}
    </Div>
  )
}

const StartPopup = ({
  spacing,
  nodeDatum,
  setNodeDatum,
  allEvents,
  vendorId,
  jbProducts,
  jbDetails,
}) => {
  const [search, setSearch] = useState('')
  const [prodQuantityRadio, setProdQuantityRadio] = useState(
    nodeDatum?.condition?.max ? 'productQuantity' : 'any'
  )
  const [prodBoughtRadio, setProdBoughtRadio] = useState(
    nodeDatum?.condition?.productIds?.length ? 'productBought' : 'any'
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      limit: 10,
      skip: 0,
    }
    if (search) params.text = search
    if (nodeDatum?.event === 'Products') debounceSearch(params)
  }, [search, nodeDatum?.event])

  useEffect(() => {
    if (
      prodQuantityRadio === 'any' &&
      nodeDatum.subEvent !== 'event_ billKeyword'
    ) {
      if (prodBoughtRadio === 'any') {
        setProdBoughtRadio('productBought')
      }
      const data = { ...nodeDatum }
      data.condition = {
        ...data.condition,
        min: '',
        max: '',
      }
      setNodeDatum(data)
    }
  }, [prodQuantityRadio])

  useEffect(() => {
    if (
      prodBoughtRadio === 'any' &&
      nodeDatum.subEvent !== 'event_ billKeyword'
    ) {
      if (prodQuantityRadio === 'any') {
        setProdQuantityRadio('productQuantity')
      }
      const data = { ...nodeDatum }
      data.condition = { ...data.condition, productIds: [], productLabels: [] }
      setNodeDatum(data)
    }
  }, [prodBoughtRadio])

  const debounceSearch = useCallback(
    debounce(
      (params) =>
        dispatch(getJourneyProducts(vendorId, jbDetails?.brandId, params)),
      1000
    ),
    []
  )

  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )

  // constants
  const subEventOptions = nodeDatum?.subEvent
    ? GET_SUB_EVENT_OPTIONS(allEvents, nodeDatum?.subEvent)?.map((se) => ({
        label: se.label,
        value: se.name,
      }))
    : []

  const subEventValue = nodeDatum?.subEvent
    ? GET_SEL_VALUE(
        nodeDatum?.subEvent,
        SUB_EVENT_OCCURENCES(allEvents, nodeDatum?.event)
      )
    : ''

  const filterEvents = allEvents.filter((event) => {
    if (
      event.category.toLowerCase() === 'products' &&
      !featuresAlloted.products
    )
      return false
    else return true
  })

  // functions
  const setOccurence = (key, val, cond, id, minMax, productLabel) => {
    if (key === 'event') {
      let subEvent = null
      let option = null
      if (val === 'Products') {
        subEvent = 'event_products'
        option =
          GET_SUB_EVENT_OPTIONS(allEvents, 'event_products')?.[2]?.name || ''
      }
      setNodeDatum({
        event: val,
        subEvent,
        option,
        condition: null,
      })
    }
    if (key === 'subEvent') {
      const datum = {
        event: nodeDatum?.event,
        subEvent: val,
        option: null,
        condition: null,
      }
      if (GET_SUB_EVENT_OPTIONS(allEvents, val)?.length > 0) {
        datum.option = GET_SUB_EVENT_OPTIONS(allEvents, val)[0].name
      }
      setNodeDatum(datum)
    }
    if (key === 'option') {
      setSearch('')
      setNodeDatum({
        event: nodeDatum?.event,
        subEvent: nodeDatum?.subEvent,
        option: val,
        condition: null,
      })
    }

    if (key === 'value') {
      setNodeDatum({
        ...nodeDatum,
        value: val,
      })
    }

    if (key === 'condition') {
      if (cond === 'array') {
        let condition = nodeDatum?.condition || []
        if (condition.includes(val)) {
          condition = condition.filter((gen) => gen !== val)
        } else {
          condition.push(val)
        }
        const data = {
          ...nodeDatum,
          condition: [...condition],
        }
        setNodeDatum(data)
      } else if (cond === 'prodIds') {
        let condition = nodeDatum?.condition || {}
        let productIds = nodeDatum?.condition?.productIds || []
        let productLabels = nodeDatum?.condition?.productLabels || []
        if (productIds?.includes(val)) {
          productIds = condition?.productIds?.filter((gen) => gen !== val)
          productLabels = productLabels?.filter((gen) => gen !== productLabel)
        } else {
          productIds.push(val)
          productLabels.push(productLabel)
        }
        const data = {
          ...nodeDatum,
          condition: {
            ...condition,
            productIds,
            productLabels,
          },
        }
        setNodeDatum(data)
      } else if (cond === 'dateRange') {
        let condition = {}
        condition = {
          ...nodeDatum?.condition,
          [minMax]: {
            ...nodeDatum?.condition?.[minMax],
            [id]: val,
          },
        }
        setNodeDatum({
          ...nodeDatum,
          condition,
        })
      } else if (cond === 'toArray') {
        setNodeDatum({
          ...nodeDatum,
          condition: [...(nodeDatum?.condition || []), ...val],
          value: '',
        })
      } else if (cond === 'indexToSplice') {
        const tempArray = [...nodeDatum.condition]
        tempArray?.splice(val, 1)

        setNodeDatum({
          ...nodeDatum,
          condition: tempArray,
        })
      } else if (cond) {
        setNodeDatum({
          ...nodeDatum,
          condition: {
            ...nodeDatum?.condition,
            [cond]: val,
          },
        })
      } else {
        setNodeDatum({
          ...nodeDatum,
          condition: val,
        })
      }
    }
  }

  const getConditions = () => {
    if (!(nodeDatum?.option || nodeDatum?.subEvent)) return ''

    const opt = GET_SUB_EVENT_OPTIONS(allEvents, nodeDatum?.subEvent)?.find(
      (op) => op.name === nodeDatum.option
    )

    switch (opt?.inputField) {
      case 'range': {
        return (
          <Input
            containerMargin={`0 ${spacing.l}`}
            variant="range"
            type="number"
            label={opt.label}
            id={['min', 'max']}
            value={[nodeDatum?.condition?.min, nodeDatum?.condition?.max]}
            min={[0, nodeDatum?.condition?.min || 0]}
            max={[nodeDatum?.condition?.max || 0, 100000]}
            onChange={[
              (e) => setOccurence('condition', e.target.value, 'min'),
              (e) => setOccurence('condition', e.target.value, 'max'),
            ]}
            inputWidth="80px"
          />
        )
      }

      case 'date':
      case 'time':
      case 'text':
      case 'number': {
        return (
          <Input
            containerMargin={`0 ${spacing.l}`}
            variant="input"
            type={opt?.inputField}
            label={opt.label}
            id={opt.name}
            value={nodeDatum?.condition}
            onChange={(e) => setOccurence('condition', e.target.value)}
          />
        )
      }

      case 'textTags': {
        return (
          <div>
            <FlexBox align="end">
              <Input
                containerMargin={`0 ${spacing.l}`}
                variant="input"
                type="text"
                label={opt.label}
                id={opt.name}
                value={nodeDatum?.value}
                onChange={(e) => setOccurence('value', e.target.value)}
              />
              <Button
                variant="primaryOutline"
                {...(nodeDatum?.value
                  ? {
                      onClick: () =>
                        setOccurence(
                          'condition',
                          nodeDatum?.value?.split(', '),
                          'toArray'
                        ),
                    }
                  : {})}
                style={{
                  margin: '8px 0',
                }}
              >
                Submit
              </Button>
            </FlexBox>
            <$Block>
              {nodeDatum?.condition?.length
                ? nodeDatum?.condition?.map((tag, index) => {
                    return (
                      <Tags
                        key={index}
                        onClear={() =>
                          setOccurence('condition', index, 'indexToSplice')
                        }
                        roundedCorners
                        clearable
                      >
                        {tag}
                      </Tags>
                    )
                  })
                : null}
            </$Block>
          </div>
        )
      }

      case 'dateRange': {
        return (
          <Div>
            <Input label="Start Date" containerMargin="0">
              <FlexBox gap={spacing.m}>
                <Input
                  containerMargin="0"
                  variant="input"
                  type="date"
                  id="date"
                  min={moment(new Date()).format('YYYY-MM-DD')}
                  value={nodeDatum?.condition?.min?.date}
                  onChange={(e) =>
                    setOccurence(
                      'condition',
                      e.target.value,
                      'dateRange',
                      'date',
                      'min'
                    )
                  }
                  inputWidth="175px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="200px"
                  options={_selectOptionsGenerator(HOURS)}
                  id="hours"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.min?.hours,
                    _selectOptionsGenerator(HOURS)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'hours',
                      'min'
                    )
                  }
                  placeholder="HH"
                  inputWidth="100px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="200px"
                  options={_selectOptionsGenerator(MINUTES)}
                  id="minutes"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.min?.minutes,
                    _selectOptionsGenerator(MINUTES)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'minutes',
                      'min'
                    )
                  }
                  placeholder="MM"
                  inputWidth="100px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="200px"
                  options={_selectOptionsGenerator(PERIOD)}
                  id="period"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.min?.period,
                    _selectOptionsGenerator(PERIOD)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'period',
                      'min'
                    )
                  }
                  placeholder="AM"
                  inputWidth="100px"
                />
              </FlexBox>
            </Input>
            <Input label="End Date" containerMargin="0">
              <FlexBox gap={spacing.m}>
                <Input
                  containerMargin="0"
                  variant="input"
                  type="date"
                  id="date"
                  min={moment(nodeDatum?.condition?.min?.date || new Date())
                    .add(1, 'days')
                    .format('YYYY-MM-DD')}
                  value={nodeDatum?.condition?.max?.date}
                  onChange={(e) =>
                    setOccurence(
                      'condition',
                      e.target.value,
                      'dateRange',
                      'date',
                      'max'
                    )
                  }
                  inputWidth="175px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="100px"
                  options={_selectOptionsGenerator(HOURS)}
                  id="hours"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.max?.hours,
                    _selectOptionsGenerator(HOURS)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'hours',
                      'max'
                    )
                  }
                  placeholder="HH"
                  inputWidth="100px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="100px"
                  options={_selectOptionsGenerator(MINUTES)}
                  id="minutes"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.max?.minutes,
                    _selectOptionsGenerator(MINUTES)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'minutes',
                      'max'
                    )
                  }
                  placeholder="MM"
                  inputWidth="100px"
                />
                <Input
                  containerMargin="0"
                  variant="single-select"
                  maxMenuHeight="100px"
                  options={_selectOptionsGenerator(PERIOD)}
                  id="period"
                  value={GET_SEL_VALUE(
                    nodeDatum?.condition?.max?.period,
                    _selectOptionsGenerator(PERIOD)
                  )}
                  onChange={(val) =>
                    setOccurence(
                      'condition',
                      val.value,
                      'dateRange',
                      'period',
                      'max'
                    )
                  }
                  placeholder="PM"
                  inputWidth="100px"
                />
              </FlexBox>
            </Input>
          </Div>
        )
      }

      case 'arrayOfNumbers': {
        const isAllChecked = nodeDatum?.condition?.length === 7
        const allDays = isAllChecked ? [] : [0, 1, 2, 3, 4, 5, 6]
        return (
          <Div>
            <Checkbox
              mb={spacing.m}
              ml={spacing.m}
              id="allDays"
              key="allDays"
              isChecked={isAllChecked}
              onCheckboxChange={() => setOccurence('condition', allDays)}
            >
              All Days
            </Checkbox>
            {DAYS_OF_THE_WEEK.map((day) => (
              <Checkbox
                mb={spacing.m}
                ml={spacing.m}
                id={day.value}
                value={day.value}
                key={day.value + day.label}
                isChecked={(nodeDatum?.condition || [])?.includes(day.value)}
                onCheckboxChange={() =>
                  setOccurence('condition', day.value, 'array')
                }
              >
                {day.label}
              </Checkbox>
            ))}
          </Div>
        )
      }

      case 'combined': {
        return (
          <FlexBox>
            <Div>
              <Input
                containerMargin="0"
                variant="radio"
                label="Product Quantity"
                id="productQuantity"
                gap={spacing.xl}
                direction="row"
                value={prodQuantityRadio}
                onChange={(val) => setProdQuantityRadio(val)}
                options={[
                  {
                    label: 'Any',
                    value: 'any',
                  },
                  {
                    label: 'Specific',
                    value: 'productQuantity',
                  },
                ]}
              />
              <Input
                containerMargin={`0 ${spacing.l}`}
                variant="range"
                type="number"
                label=" "
                id={['min', 'max']}
                value={[nodeDatum?.condition?.min, nodeDatum?.condition?.max]}
                min={[0, nodeDatum?.condition?.min || 0]}
                max={[nodeDatum?.condition?.max || 0, 100000]}
                onChange={[
                  (e) => setOccurence('condition', e.target.value, 'min'),
                  (e) => setOccurence('condition', e.target.value, 'max'),
                ]}
                inputWidth="80px"
                disabled={prodQuantityRadio === 'any'}
              />
            </Div>
            <Div ml={spacing.x3l}>
              <Div width="480px">
                <Input label="Select Products">
                  <$CampaignContainer>
                    <$SearchInput inputWidth="100%">
                      <span />
                      <input
                        id="search"
                        placeholder="Search by product name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        autoFocus
                        disabled={prodBoughtRadio === 'any'}
                      />
                    </$SearchInput>
                    <hr />
                    {jbProducts.length > 0 ? (
                      <FlexBox wrap="wrap" expand>
                        {jbProducts.map((prd) => (
                          <Div key={prd._id} width="50%">
                            <Checkbox
                              id={prd._id}
                              mb={spacing.m}
                              value={prd._id}
                              disabled={prodBoughtRadio === 'any'}
                              isChecked={(
                                nodeDatum?.condition?.productIds || []
                              )?.includes(prd._id)}
                              onCheckboxChange={() =>
                                prodBoughtRadio !== 'any' &&
                                setOccurence(
                                  'condition',
                                  prd._id,
                                  'prodIds',
                                  undefined,
                                  undefined,
                                  prd?.productName
                                )
                              }
                            >
                              {prd?.productName}
                            </Checkbox>
                          </Div>
                        ))}
                      </FlexBox>
                    ) : (
                      <Text align="center" type="main">
                        No Products Found
                      </Text>
                    )}
                  </$CampaignContainer>
                </Input>
              </Div>
            </Div>
          </FlexBox>
        )
      }

      default:
        return ''
    }
  }

  return (
    <Div height="400px" overflow="auto">
      <FlexBox gap={spacing.xl}>
        <Input
          variant="single-select"
          maxMenuHeight="200px"
          options={EVENT_OCCURENCES(filterEvents)}
          value={GET_SEL_VALUE(
            nodeDatum?.event,
            EVENT_OCCURENCES(filterEvents)
          )}
          onChange={(val) => setOccurence('event', val.value)}
          label="Event"
          inputWidth="300px"
          placeholder="Select an Event"
        />
        {nodeDatum?.event === 'Products' ? (
          <Input
            containerMargin="0"
            variant="radio"
            label="Product Bought"
            id="prodBought"
            direction="row"
            value={prodBoughtRadio}
            gap={spacing.xl}
            onChange={(val) => setProdBoughtRadio(val)}
            options={[
              {
                label: 'Any',
                value: 'any',
              },
              {
                label: 'Specific',
                value: 'productBought',
              },
            ]}
          />
        ) : (
          <Input
            disabled={!nodeDatum?.event}
            variant="single-select"
            maxMenuHeight="200px"
            options={SUB_EVENT_OCCURENCES(filterEvents, nodeDatum?.event)}
            value={subEventValue}
            onChange={(val) => setOccurence('subEvent', val.value)}
            label="Sub Events"
            inputWidth="300px"
            placeholder="Select the Sub Event"
          />
        )}
      </FlexBox>
      <FlexBox expand direction="column">
        {nodeDatum?.subEvent &&
        subEventOptions?.length &&
        nodeDatum?.event !== 'Products' ? (
          <Input
            containerMargin="0"
            variant="radio"
            label="Options"
            id="option"
            direction="column"
            value={nodeDatum?.option}
            onChange={(val) => setOccurence('option', val)}
            options={subEventOptions}
          />
        ) : null}
        {getConditions()}
      </FlexBox>

      <$InstructionsBlock>
        <li>
          <span>Adding keywords:</span> Type individual keywords into the input
          box and click on submit to add it to the list. You may add a phrase as
          well if you specifically need to target those words. For example,
          "Purse" or "Red Purse"
        </li>
        <li>
          <span>All:</span> When you select the <u>ALL</u> option, the journey
          will trigger only when all the keywords or phrases added appears in
          the bill. For example, if you add "Chicken burger" & “Veg Sandwich"
          and only one is seen in the bill the journey{' '}
          <u>will not get trigger</u>.
        </li>
        <li>
          <span>Any:</span> When you select the <u>ANY</u> option, the journey
          will trigger when even one the keywords or phrases added appears in
          the bill. For example if you add "Chicken burger" & “Veg Sandwich" and
          only one is seen in the bill the journey <u>will get trigger</u>.
        </li>
      </$InstructionsBlock>
    </Div>
  )
}

export const CustomerPopup = ({
  nodeId,
  spacing,
  vendorId,
  nodeDatum,
  segmentData,
  assetsCsvList,
  customerModal,
  setCustomerId,
  setCustomerModal,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!customerModal) {
      if (nodeId === 'filter_customerSegment') {
        dispatch(getSegments({ vendorId }))
      }
      if (nodeId === 'filter_customerSpecific') {
        dispatch(
          getAssetsList({
            vendorId,
            listAll: true,
            fileType: 'csv',
          })
        )
      }
    }
  }, [customerModal])

  const SEGMENT_TYPES =
    segmentData?.map((seg) => ({
      label: seg.name,
      value: seg._id,
    })) || []

  const UPLOAD_DATA_TYPES =
    assetsCsvList?.map((asset) => ({
      label: asset.name,
      value: asset._id,
    })) || []

  switch (nodeId) {
    case 'filter_customerSegment':
    case 'filter_customerSpecific':
      const isSegment = nodeId === 'filter_customerSegment'
      return (
        <Div height="300px">
          {isSegment ? (
            <Input
              variant="single-select"
              maxMenuHeight="200px"
              label="Choose a User Segment"
              placeholder="Segment"
              options={SEGMENT_TYPES}
              value={GET_SEL_VALUE(nodeDatum?.customerId, SEGMENT_TYPES) || ''}
              onChange={setCustomerId}
              inputWidth="280px"
            />
          ) : (
            <Input
              variant="single-select"
              maxMenuHeight="200px"
              label="Choose from Uploaded Data"
              placeholder="Uploaded Data"
              options={UPLOAD_DATA_TYPES}
              value={
                GET_SEL_VALUE(nodeDatum?.customerId, UPLOAD_DATA_TYPES) || ''
              }
              onChange={setCustomerId}
              inputWidth="280px"
            />
          )}
          <Text width="280px" mb={spacing.l} align="center">
            OR
          </Text>
          <Button
            variant="primaryOutline"
            onClick={() => setCustomerModal(true)}
          >
            Add New {isSegment ? 'Segment' : 'Uploaded Data'}
          </Button>
        </Div>
      )

    default:
      return 'All Customers'
  }
}

const OutcomePopup = ({
  spacing,
  outcome,
  setOutcome,
  currentEdge,
  allFilters,
  onPopupCancel,
  cy,
}) => {
  const node = cy.getElementById(currentEdge.data().source)

  const [options, setOptions] = useState([])
  const [delay, setDelay] = useState(node?.data()?.delay || 2)

  const isDelayOutcome = ['OUTCOME_NOT', 'OUTCOME_UNOPENED'].includes(
    outcome?.value
  )

  useEffect(() => {
    let opts = GET_OUTCOMES(allFilters, currentEdge?.data()?.sourceNodeId)
    if (
      !['action_sendSMS', 'action_sendEmail'].includes(
        currentEdge.data().target
      )
    ) {
      opts = opts.filter((op) => op.value !== 'OUTCOME_UNOPENED')
    }
    setOptions(opts)
    if (opts.length && !currentEdge?.data()?.outcome?.value) {
      setOutcome(opts[0])
    }
  }, [currentEdge?.data()?.id])

  useEffect(() => {
    if (isDelayOutcome) {
      node.data({ delay })
    }
  }, [delay])

  const onRemoveEdge = () => {
    cy.remove(currentEdge)
    onPopupCancel()
  }

  return (
    <Div>
      <FlexBox gap={spacing.l}>
        {(options.length > 1 || isDelayOutcome) && (
          <Input
            variant="single-select"
            maxMenuHeight="200px"
            options={options}
            value={outcome}
            onChange={setOutcome}
            label="Outcomes"
            inputWidth="300px"
          />
        )}
        {isDelayOutcome && (
          <Input
            containerMargin={`0 ${spacing.l}`}
            placeholder="2"
            variant="input"
            type="number"
            label="Number of days to wait"
            id="unopened"
            inputWidth="150px"
            value={delay}
            onChange={(e) => setDelay(e.target.value)}
          />
        )}
      </FlexBox>
      <Div textAlign="center" width="300px">
        <Button variant="error" onClick={onRemoveEdge}>
          Remove Edge
        </Button>
      </Div>
    </Div>
  )
}

const $Block = styled(FlexBox)`
  margin: 0 16px;
  padding: 16px 0px;
  width: 40%;
  max-width: 620px;
  min-width: 300px;
  div {
    margin-right: 8px;
  }
`

const $InstructionsBlock = styled.ul`
  padding: 32px 0 0;
  margin: 0;
  list-style: none;
  width: 80%;
  li {
    margin-bottom: 16px;
    span {
      font-weight: 900;
    }
  }
`
