import React from 'react'
import { Div, FlexBox } from 'styledComponent'
import { $MarginlessLine, $SelectCoupon } from '../AutoEngagement.styled'
import AsyncSurveySelect from './AsyncSurveySelect'
import { Text } from 'components/Common'
import { useTheme } from 'styled-components'
import { useSelectStore } from 'hooks'

export const SelectSurvey = ({ selectedSurveyId, onSurveySelect }) => {
  const { spacing } = useTheme()
  const { data } = useSelectStore('autoEngage.surveyData.docs') || []

  return (
    <Div>
      <AsyncSurveySelect value={selectedSurveyId} />
      <hr />
      <Div height="250px" overflow="auto" my={spacing.l}>
        {data?.length > 0
          ? data?.map(
              (sur) =>
                sur.status === 'live' && (
                  <div key={sur._id}>
                    <$SelectCoupon
                      p={spacing.l}
                      onClick={() => onSurveySelect(sur)}
                    >
                      <FlexBox width="100%" gap={spacing.l}>
                        <input
                          readOnly
                          type="radio"
                          checked={selectedSurveyId === sur.surveyId}
                        />
                        <FlexBox style={{ flex: 1 }} justify="space-between">
                          <Div>
                            <Text type="main">{sur.name}</Text>
                            <Text type="main" variant="small">
                              {sur.description || '-'}
                            </Text>
                          </Div>
                          <Text variant="small" type="grey">
                            #{sur.surveyId}
                          </Text>
                        </FlexBox>
                      </FlexBox>
                    </$SelectCoupon>
                    <$MarginlessLine />
                  </div>
                )
            )
          : 'No Surveys Found'}
      </Div>
    </Div>
  )
}
