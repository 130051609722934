import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'

import { Div, FlexBox, Table } from 'styledComponent'
import { Skeleton, Text, Input, Dropdown } from 'components/Common'

import {
  $AETableContainer,
  $BlueBox,
  $WhiteContainer,
} from '../../AutoEngagement.styled'
import { TabRow } from '../../campaigns/AETable'
import { GET_SEL_VALUE, SR_TYPES, SORT_OPTIONS, SR_TAGS } from '../../constants'
import { SurveyBuilder } from 'builder/survey-builder'

const getCell = (item, setSurveyItem) => (
  <Div type="button" onClick={() => setSurveyItem(item)}>
    <FlexBox justify="space-between">
      <Text weight="bold">
        {item.mCustId || item.phone || item.email || 'Anonymous'}
      </Text>
      <Div>
        <Text weight="bold" variant="small">
          {[item?.billDetails?.storeCode, item?.billDetails?.displayAddress]
            .filter(Boolean)
            .join(' - ')}
        </Text>
      </Div>
    </FlexBox>
    <FlexBox justify="space-between" mt="12px">
      {SR_TAGS(item.campaignType)}
      <Text type="grey" variant="small">
        {moment(item.createdDate).format('DD MMM YYYY')}
      </Text>
    </FlexBox>
  </Div>
)

export const SRTable = ({
  tableLoading,
  srList,
  pageCount,
  aeSurveyData,
  query,
  setQuery,
  isSIB,
}) => {
  const theme = useTheme()
  const [surveyItem, setSurveyItem] = useState({})

  const onPaginationChange = (page) => {
    setQuery({
      ...query,
      page: page.selected + 1,
    })
  }

  const onSelectChannel = (camp) => {
    setQuery({
      ...query,
      campaignType: camp.value,
    })
  }

  const onSortChange = (val) => {
    setQuery({
      ...query,
      sortObj: {
        createdDate: val.sortOrder,
      },
    })
  }

  return (
    <$WhiteContainer>
      <FlexBox>
        <Div width="100%">
          <FlexBox p={theme.spacing.m} justify="space-between" align="center">
            <FlexBox p={theme.spacing.m} align="center">
              <Text variant="small" type="grey" weight="bold">
                Sort : &nbsp;
              </Text>
              <Dropdown
                options={SORT_OPTIONS}
                onChange={onSortChange}
                selected={
                  query?.sortObj?.createdDate === -1
                    ? SORT_OPTIONS[0]
                    : SORT_OPTIONS[1]
                }
              />
            </FlexBox>
            {!isSIB && (
              <FlexBox p={theme.spacing.m} justify="flex-end" align="center">
                <Text variant="small" type="grey" weight="bold">
                  Channel : &nbsp;
                </Text>
                <Input
                  containerMargin="0"
                  variant="single-select"
                  placeholder="Select Campaign"
                  value={GET_SEL_VALUE(query?.campaignType, SR_TYPES) || ''}
                  onChange={onSelectChannel}
                  options={SR_TYPES}
                  inputWidth="200px"
                />
              </FlexBox>
            )}
          </FlexBox>
          <$AETableContainer>
            <Table margin="0" borderColor={theme.colors.alto}>
              <tbody>
                {tableLoading ? (
                  [...new Array(10)].map((_, i) => (
                    <TabRow key={i} cells={[<Skeleton />]} />
                  ))
                ) : srList?.length > 0 ? (
                  srList?.map((item, i) => (
                    <TabRow
                      key={i}
                      padding="8px"
                      backgroundColor={
                        surveyItem?._id === item?._id && theme.colors.solitude
                      }
                      align="center"
                      cells={[getCell(item, setSurveyItem)]}
                    />
                  ))
                ) : (
                  <TabRow
                    align="center"
                    colSpan={1}
                    cells={['No Survey Responses Found']}
                  />
                )}
              </tbody>
            </Table>
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                pageCount={pageCount || 1}
                onPageChange={onPaginationChange}
                containerClassName="aePagination"
                activeClassName="pagination_activePage"
                forcePage={query.page - 1}
              />
            )}
          </$AETableContainer>
        </Div>
        <$BlueBox height="770px">
          {surveyItem?.campaignType && (
            <FlexBox gap="20px">
              <Text weight="bold">
                {surveyItem.mCustId ||
                  surveyItem.phone ||
                  surveyItem.email ||
                  surveyItem.billUid}
              </Text>
              {SR_TAGS(surveyItem?.campaignType)}
            </FlexBox>
          )}
          <hr />
          {aeSurveyData?.name && (
            <SurveyBuilder
              hideHeader
              form={{ ...aeSurveyData, contentType: 'single' }}
              setForm={() => {}}
              answers={surveyItem?.responses || []}
            />
          )}
        </$BlueBox>
      </FlexBox>
    </$WhiteContainer>
  )
}
