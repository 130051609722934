// Color naming - http://chir.ag/projects/name-that-color/

const Default = {
  colors: {
    primary: {
      900: `hsla( 217, 61%, 17%, 1)`,
      800: `hsla( 217, 62%, 28%, 1)`,
      700: `hsla( 217, 62%, 39%, 1)`,
      600: `hsla( 217, 63%, 51%, 1)`,
      500: `hsla( 217, 100%, 62%, 1)`,
      400: `hsla( 217, 100%, 68%, 1)`,
      300: `hsla( 217, 100%, 75%, 1)`,
      200: `hsla( 217, 100%, 85%, 1)`,
      100: `hsla( 217, 100%, 95%, 1)`,
      light: '#B0CEFF',
      default: '#3B86FF',
      dark: '#0D52C2',
    },
    neutral: {
      900: `hsl( 0, 0%, 5%)`,
      800: `hsl( 0, 0%, 25%)`,
      700: `hsl( 0, 0%, 45%)`,
      600: `hsl( 0, 0%, 55%)`,
      500: `hsl( 0, 0%, 65%)`,
      400: `hsl( 0, 0%, 75%)`,
      300: `hsl( 0, 0%, 85%)`,
      200: `hsl( 0, 0%, 95%)`,
      150: `hsl( 0, 0%, 97%)`,
      100: `hsl( 0, 0%, 100%)`,
    },
    tertiary: {
      900: `hsl(107, 54%, 5%)`,
      800: `hsl(106, 55%, 15%)`,
      700: `hsl(106, 55%, 25%)`,
      600: `hsl(106, 55%, 35%)`,
      500: `hsl(105, 55%, 45%)`,
      400: `hsl(106, 55%, 55%)`,
      300: `hsl(105, 55%, 65%)`,
      200: `hsl(105, 55%, 75%)`,
      100: `hsl(106, 55%, 85%)`,
    },

    //tertiary
    eggBlue: '#00BCD1', // HSL = hsl(182 100% 82%)
    salmon: '#FD7975', // HSL = hsl(2 54% 99%)
    butterflyBush: '#4F549E', // HSL =
    brinkPink: '#F9698D', // HSL =
    doveGray: '#F9F9F9', // HSL =
    grey: '#808080', // HSL =
    alto: '#D5D5D5', // HSL =
    solitude: '#EBF3FE',
    transparent: 'transparent',
    aquaHaze: '#F2F5F8',
    orange: '#FFA500',
    shipGray: '#43424B',

    greenYellow: '#62B349',

    // Feedback
    success: {
      light: '#84d2ac',
      default: '#28C67A',
      dark: '#208416',
      bright: '#33C481',
    },
    warning: '#FBEECA',
    error: '#D76565',
    info: '',

    // texts
    dark: '#151515',
    medium: '#696969',
    light: '#BFBFBF',
    ultralight: '#FBFBFC',
    white: '#fff',
    black: '#000',
    lightBlue: '#f2f5f8',
    mediumBlue: '#3b86fc',
    pickledBluewood: '#33475B',
    mercury: '#E3E3E3',
    redViolet: '#D91ECA',

    // pill
    brightTurquoise: '#12b9f3',
    increase: '#62B349',
    decrease: '#D76565',
    mineShaft: '#333333',
    pillDefaultBorder: '#CFCFCF',
    darkMercury: '#E6E6E6',
    zircon: '#f2f7ff',
    malibu: '#98C1F9',

    // toast
    toast: {
      success: {
        color: '#155724',
        background: '#d4edda',
      },
      warning: {
        color: '#856404',
        background: '#fff3cd',
      },
      error: {
        color: '#721c24',
        background: '#f8d7da',
      },
    },
  },
  checkBoxVariants: {
    tiny: '12px',
    small: '16px',
    medium: '17px',
    default: '18px',
    large: '20px',
  },
  breakpoints: {
    xs: '0px', // mobile devices
    sm: '767px', // tabs
    md: '992px', // laptops
    lg: '1280px', // laptops
    xl: '1920px', // large monitors
  },
  borderRadius: {
    small: '4px',
    default: '6px',
    large: '10px',
  },
  spacing: {
    xs: '2px',
    s: '4px',
    m: '8px',
    ml: '12px',
    l: '16px',
    mxl: '24px',
    xl: '32px',
    xxl: '64px',
    x3l: '96px',
    x4l: '128px',
  },
}

export { Default }
