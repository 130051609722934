import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import { Div, FlexBox } from 'styledComponent'
import { TopActionBar } from 'components/Content/autoEngagement/components/TopActionBar'
import {
  Button,
  DragBox,
  Input,
  SectionHeading,
  Text,
  useToasts,
  LoaderPopup,
} from 'components/Common'
import {
  getJourneyPriorities,
  postJourneyPriorities,
  setLoadJourney,
} from 'redux/actions/journeyActions'
import {
  ERROR_DECODE,
  GET_SEL_VALUE,
} from 'components/Content/autoEngagement/constants'
import { useTheme } from 'styled-components'
import {
  $AEHeader,
  $SearchInput,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import { spacing } from 'design-system'
import { $PriorityContainer } from '../Journey.styled'

const JourneyPriority = ({
  isPriorityOpen,
  setIsPriorityOpen,
  getJourneyPriorities,
  jbPriority,
  vendorId,
  setLoadJourney,
  jbLoading,
}) => {
  const { toast } = useToasts()
  const { colors } = useTheme()
  const [search, setSearch] = useState('')
  const [priorityList, setPriorityList] = useState([])
  const [priorOptions, setPriorOptions] = useState([])

  useEffect(() => {
    if (isPriorityOpen) {
      setLoadJourney(true)
      getJourneyPriorities({ vendorId })
    }
    return () => {
      setPriorityList(cloneDeep([]))
      setPriorOptions(cloneDeep([]))
    }
  }, [isPriorityOpen])

  useEffect(() => {
    setPriorityList(cloneDeep([...jbPriority]))
    const priors = [...new Array(jbPriority?.length)].map((_, ind) => ({
      label: ind + 1,
      value: ind,
    }))
    setPriorOptions(cloneDeep(priors))
  }, [jbPriority, isPriorityOpen])

  const onSubmit = async () => {
    if (priorityList.length === 0) {
      return toast('No campaigns to update.', 'error')
    }
    setLoadJourney(true)
    try {
      const res = await postJourneyPriorities({
        vendorId,
        priorityList: priorityList?.map((item) => item?._id),
      })
      if (res) {
        toast('Priority changes has been updated successfully.', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw err
    } finally {
      setLoadJourney(false)
    }
  }

  const arrayMove = async (arr, oldIndex, newIndex) => {
    if (oldIndex === newIndex) return
    setLoadJourney(true)
    try {
      await arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      setPriorityList(cloneDeep(arr))
      await new Promise((res) => setTimeout(res, 600))
    } catch (err) {
      console.error(err)
    } finally {
      setLoadJourney(false)
    }
  }

  const setNewOrder = async (arr = []) => {
    setLoadJourney(true)
    try {
      const newArr = await arr.map(
        (priorId) => priorityList.filter((prior) => priorId === prior._id)[0]
      )
      setPriorityList(cloneDeep(newArr))
      await new Promise((res) => setTimeout(res, 600))
    } catch (err) {
      console.error(err)
    } finally {
      setLoadJourney(false)
    }
  }

  const extras = (
    <Button variant="primary" onClick={onSubmit}>
      Update Priority
    </Button>
  )

  const isEmpty =
    priorityList?.filter((jbp) =>
      jbp?.name?.toLowerCase()?.includes(search?.toLowerCase())
    ).length === 0

  return isPriorityOpen ? (
    <Div
      width="calc(100vw - 100px)"
      minHeight="calc(100vh - 60px)"
      position="absolute"
      zIndex="10"
      left="85px"
      top="60px"
      backgroundColor={colors.white}
    >
      <TopActionBar
        exitTitle="Back"
        onExit={() => setIsPriorityOpen(false)}
        title="Auto Engagement Campaign Priority"
        extras={extras}
      />
      <LoaderPopup isPopOpen={jbLoading} />
      <$AEHeader justify="space-between">
        <span>
          <SectionHeading
            heading="All Live Auto Engagement Campaigns"
            subHeading="List of all auto engagement campaigns in priority order"
            variant="medium"
            subVariant="small"
          />
        </span>
        <span>
          <Div mr="-16px">
            <$SearchInput>
              <span />
              <input
                id="searchTxt"
                value={search}
                placeholder="Search by Journey Name"
                onChange={(e) => setSearch(e.target.value)}
              />
            </$SearchInput>
          </Div>
        </span>
      </$AEHeader>

      <PriorityWrapper
        isSearching={!!search}
        spacing={spacing}
        setNewOrder={setNewOrder}
        isEmpty={isEmpty}
      >
        {[...cloneDeep(priorityList)]?.map(
          (jbp, index) =>
            jbp?.name?.toLowerCase()?.includes(search?.toLowerCase()) && (
              <div id={jbp?._id} draggable={!search} key={index + jbp?._id}>
                <Input
                  containerMargin="0 20px 0 0"
                  variant="single-select"
                  value={GET_SEL_VALUE(index, priorOptions)}
                  onChange={(val) => arrayMove(priorityList, index, val.value)}
                  options={priorOptions}
                  inputWidth="80px"
                  maxMenuHeight="130px"
                />
                <Div>
                  <FlexBox gap={spacing.l}>
                    <Text type="main" variant="medium">
                      Journey Name :
                    </Text>
                    <Text type="main" variant="medium" weight="bold">
                      {jbp?.name}
                    </Text>
                  </FlexBox>
                  <FlexBox gap={spacing.l}>
                    <Text type="main" variant="medium">
                      Journey Event :
                    </Text>
                    <Text type="main" variant="medium" weight="bold">
                      {jbp?.eventCategory}
                    </Text>
                  </FlexBox>
                </Div>
                <span className="dots" />
              </div>
            )
        )}
      </PriorityWrapper>
    </Div>
  ) : null
}

const mapStateToProps = (state) => ({
  jbPriority: state.journey.jbPriority,
  jbLoading: state.journey.jbLoading,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, {
  getJourneyPriorities,
  setLoadJourney,
})(JourneyPriority)

const NoCampaigns = (
  <Text id="noLive" align="center" type="main" variant="medium">
    No Campaigns Found.
  </Text>
)

export const PriorityWrapper = ({
  isEmpty,
  isSearching,
  setNewOrder,
  spacing,
  children,
}) => {
  return isSearching || isEmpty ? (
    <$PriorityContainer>{isEmpty ? NoCampaigns : children}</$PriorityContainer>
  ) : (
    <Div px={spacing.l}>
      <DragBox getNewOrder={setNewOrder}>{children}</DragBox>
    </Div>
  )
}
