import React from 'react'
import { $HeaderButton, $SurveyHeader } from '../Survey.styled'
import { HEADER_STYLES } from '../constants'
import { AddBlueIcon, CloseIcon } from 'assets/icons'

export const SurveyHeader = (props) => {
  const insertSurvey = () => {
    props.setHead({
      header: 'Survey Header',
      description: 'Survey Description',
    })
  }

  const resetHeader = () => {
    props.setHead({ header: '', description: '' })
  }

  return (
    <$SurveyHeader showBorder={props.isEditing}>
      {(props.survey.description || props.survey.header) && (
        <>
          {props.isEditing && <CloseIcon type="button" onClick={resetHeader} />}
          <input
            disabled={!props.isEditing}
            value={props.survey.header || ''}
            style={{
              ...HEADER_STYLES,
              fontSize: '2em',
              color: !props.isEditing && props?.survey?.fontColor,
              fontFamily: !props.isEditing && props?.survey?.fontFamily,
            }}
            onChange={(e) => props.setHead({ header: e.target.value })}
          />
          <input
            disabled={!props.isEditing}
            value={props.survey.description || ''}
            style={{
              ...HEADER_STYLES,
              fontSize: '1.2em',
              color: !props.isEditing && props?.survey?.fontColor,
              fontFamily: !props.isEditing && props?.survey?.fontFamily,
            }}
            onChange={(e) =>
              props.setHead({
                description: e.target.value,
              })
            }
          />
        </>
      )}
      {props.isEditing && !(props.survey.description || props.survey.header) && (
        <$HeaderButton onClick={insertSurvey}>
          <AddBlueIcon />
          Add Survey Title & Description
        </$HeaderButton>
      )}
    </$SurveyHeader>
  )
}
