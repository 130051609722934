import React from 'react'
import styled from 'styled-components'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { spacing, borderRadius } from 'design-system'
import { Text, StarRating } from 'components/Common'
import { FlexBox } from 'styledComponent'

const $ColorBlock = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ type }) => getBlockColor(type)};
  border-radius: ${borderRadius.large};
`

const $Content = styled(FlexBox)`
  flex-grow: 1;
  padding: ${`0 ${spacing.l}`};
`

const getBlockColor = (type) => {
  switch (type) {
    case 'visitDate':
    case 'transaction':
      return '#1EBCD1'
    case 'rating':
      return '#FD5C93'
    case 'report':
      return '#F9698D'
    case 'feedback':
      return '#4F549E'
    default:
      return '#1EBCD1'
  }
}

const getActivityType = (type) => {
  switch (type) {
    case 'rating':
      return (
        <Text variant="p" weight="bold">
          Feedback Rating:
        </Text>
      )
    case 'report':
      return (
        <Text variant="p" weight="bold">
          Reported issue:
        </Text>
      )
    case 'feedback':
      return (
        <Text variant="p" weight="bold">
          Feedback message:
        </Text>
      )
    case 'visitDate':
      return (
        <Text variant="p" weight="bold">
          Transaction at:
        </Text>
      )
    default:
      return (
        <Text variant="p" weight="bold">
          Transaction at:
        </Text>
      )
  }
}

const getValue = (type, value) => {
  switch (type) {
    case 'rating':
      return <StarRating filled={value} padding={'0'} />
    case 'report':
    case 'feedback':
      return (
        <Text variant="p" weight="bold">
          {value}
        </Text>
      )
    case 'visitDate':
      return (
        <Text variant="p" weight="normal">
          {new Date(value).toDateString()}
        </Text>
      )
    default:
      return (
        <FormattedNumber
          style="currency"
          currency="INR"
          value={value}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        >
          {(parts) => (
            <Text variant="h3" weight="bold">
              {parts}
            </Text>
          )}
        </FormattedNumber>
      )
  }
}

export const ActivityCard = ({
  type,
  transactionType,
  storeAddress,
  timestamp,
  value,
}) => {
  switch (type) {
    case 'visitDate':
      return (
        <FlexBox height="100%" expand align="center" justify="space-between">
          <$ColorBlock type="visitDate" />
          <$Content
            height="100%"
            align="center"
            justify="space-between"
            direction="column"
          >
            <FlexBox
              height="100%"
              align="center"
              justify="space-between"
              alignSelf="flex-start"
            >
              {getActivityType('visitDate')}
              <FlexBox ml={spacing.m}>{getValue('visitDate', value)}</FlexBox>
            </FlexBox>
          </$Content>
        </FlexBox>
      )
    default:
      return (
        <FlexBox height="100%" expand align="center" justify="space-between">
          <$ColorBlock type={type} />
          <$Content
            height="100%"
            align="center"
            justify="space-between"
            direction="column"
          >
            <FlexBox
              align="center"
              justify="space-between"
              alignSelf="flex-start"
            >
              {getActivityType(type)}
              <Text variant="small" paddingLeft={spacing.l}>
                {storeAddress}
              </Text>
            </FlexBox>
            <FlexBox
              align="center"
              justify="space-between"
              alignSelf="flex-start"
            >
              <Text variant="small" type="grey">
                {timestamp}
              </Text>
              {transactionType && (
                <Text variant="small" type="grey" paddingLeft={spacing.l}>
                  {transactionType}
                </Text>
              )}
            </FlexBox>
          </$Content>
          <FlexBox
            height="100%"
            direction="column"
            justify="space-between"
            align="flex-end"
            padding={` 0 0 0 ${spacing.l}`}
          >
            {getValue(type, value)}
            <Text variant="p" type="primary">
              view
            </Text>
          </FlexBox>
        </FlexBox>
      )
  }
}
