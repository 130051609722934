import React from 'react'

import { DeleteRedIcon } from 'assets/icons'
import { $FlexBox } from './Editor.styled'

const PropertyHeader = ({ ...props }) => {
  return (
    <>
      <$FlexBox justify="space-between">
        <span>{props.header} Properties</span>
        {props.hideDelete && (
          <DeleteRedIcon type="button" onClick={props.onDelete} />
        )}
      </$FlexBox>
      <hr />
    </>
  )
}

export default PropertyHeader
