import styled from 'styled-components'
import { borderRadius, spacing } from 'design-system'
import { TableCell } from 'styledComponent'

const $FlexList = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'unset')};
  justify-content: ${({ justify }) => (justify ? justify : 'unset')};
`

const $FlexListItem = styled.li`
  margin-left: ${({ ml }) => ml || '5px'};
  margin-bottom: ${({ mb }) => mb || 'unset'};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
  padding: ${({ padding }) => padding || 'unset'};
  flex-basis: ${({ flexBasis }) => flexBasis || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
`

const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${borderRadius.small};
  margin-right: ${spacing.ml};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) =>
      props.isFilter
        ? props.theme.colors.primary.default
        : props.disabled
        ? props.theme.colors.alto
        : props.theme.colors.success.bright};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
`

const $TableCell = styled(TableCell)`
  width: ${({ colCount }) => (colCount ? `calc(100%/${colCount})` : 'auto')};
`

export { $FlexList, $FlexListItem, $SquareButton, $TableCell }
