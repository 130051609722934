import React from 'react'
import styled from 'styled-components'

import Tooltip from 'components/Common/Tooltip'
import { Text } from './Text'
import { FlexBox } from 'styledComponent'

export const SectionHeading = ({
  heading,
  subHeading,
  variant,
  subVariant,
  icon,
  color,
  toolTip,
  weight,
}) => {
  return (
    <FlexBox direction="column">
      <FlexBox icon={icon} align="center">
        <Text variant={variant} type={color} weight={weight}>
          {heading}
        </Text>
        <Tooltip content={toolTip} icon={icon} />
      </FlexBox>
      {subHeading && (
        <Text variant={subVariant} type="grey">
          {subHeading}
        </Text>
      )}
    </FlexBox>
  )
}
