import React from 'react'
import { connect } from 'react-redux'
import { toggleMenu } from 'redux/actions/toggle-action.js'
import 'css/Layout/navbar.scss'
import { logoutUser } from 'redux/actions/authActions'
import HeaderBar from 'components/Common/HeaderBar.js'
import { FlexBox } from 'styledComponent'

const Navbar = (props) => (
  <nav className="navbar border-bottom">
    <HeaderBar heading={props.heading} />

    <div
      id="navbarSupportedContent"
      style={{ paddingLeft: '15px', borderLeft: '1px solid #eee' }}
    >
      <ul className="navbar-nav ml-auto">
        <FlexBox as="li" style={{ height: '2rem', overflow: 'hidden' }}>
          <img
            src={require('../../images/avatar.png')}
            className="profileImageNav"
            alt=""
          />
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {props.auth.user.name || props.auth.user.email || 'User'}
          </a>

          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <a className="dropdown-item" href="#">
              {props.auth.user.name || props.auth.user.email}
            </a>

            <div className="dropdown-divider" />

            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.logoutUser()}
            >
              Logout
            </a>
          </div>
        </FlexBox>
      </ul>
    </div>
  </nav>
)

const mapStateToProps = (state) => ({
  toggleCheck: state.toggle,
  backButton: state.backButton,
  auth: state.auth,
})

const mapActionsToProps = { toggleMenu, logoutUser }

export default connect(mapStateToProps, mapActionsToProps)(Navbar)
