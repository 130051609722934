import React from 'react'
import styled, { css } from 'styled-components'
import { spacing, color, borderRadius } from 'design-system/styles'

const VARIANT = {
  POSITIVE: 'positive',
  QF: 'butterflyBush',
  REPLY: 'reply',
}

const SIZE = {
  SMALL: 'small',
}

const StyledBadge = styled.div`
  display: inline-block;
  padding: ${spacing.xs} ${spacing.m};
  border: 1px solid ${color.medium};
  color: ${color.medium};
  font-size: 12px;
  border-radius: ${borderRadius.default};

  ${(props) =>
    props.variant === VARIANT.POSITIVE &&
    css`
      border-color: ${color.success.dark};
      color: ${color.success.dark};
    `}

  ${(props) =>
    props.variant === VARIANT.QF &&
    css`
      border-color: ${color.butterflyBush};
      color: ${color.butterflyBush};
    `}

    ${(props) =>
    props.variant === VARIANT.REPLY &&
    css`
      margin-left: ${spacing.m};
    `}

    ${(props) =>
    props.size === SIZE.SMALL &&
    css`
      font-size: 8px;
      padding: ${spacing.xs} ${spacing.s};
      font-weight: 600;
    `}
`

export const Badge = ({ children, variant, size }) => {
  return (
    <StyledBadge variant={variant} size={size}>
      {children}
    </StyledBadge>
  )
}
