import React, { useState } from 'react'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { GET_SEL_VALUE } from '../constants'
import { useCouponApi } from '../coupon/useCouponApi'
import { ReactComponent as SearchIcon } from 'images/search.svg'

const AsyncCouponSelect = (props) => {
  const [search, setSearch] = useState('')
  useCouponApi(props.vendorId, search, 0, {}, true)

  const loadOptions = props.couponData?.map((opt) => ({
    value: opt._id,
    label: opt.name + ' | (' + opt.totalCoupons + ' coupons)',
  }))

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon width="15px" height="15px" />
      </components.DropdownIndicator>
    )
  }

  return (
    <Select
      options={loadOptions}
      onInputChange={setSearch}
      placeholder="Search & Select Coupon"
      value={GET_SEL_VALUE(props.value, loadOptions) || ''}
      onChange={props.onCouponSelect}
      menuIsOpen={props.showMenu}
      components={{ DropdownIndicator }}
      isClearable
    />
  )
}

const mapStateToProps = (state) => ({
  vendorId: state.vendorIds.selectedVendor.value,
  couponData: state.autoEngage.couponData.docs || [],
})

export default connect(mapStateToProps)(AsyncCouponSelect)
