import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'

export const CreateTags = ({
  value,
  onChange,
  placeholder,
  disabled,
  isEmail,
  customKeyDownKeys = [],
}) => {
  const [inputValue, setInputValue] = useState('')

  const tags = value?.map((val) => {
    return {
      value: val,
      label: val,
    }
  })

  const handleKeyDown = (event) => {
    if (!inputValue || value?.includes(inputValue)) return
    if (customKeyDownKeys.length) {
      if (customKeyDownKeys.includes(event.key)) {
        event.preventDefault()
        setInputValue('')
        onChange([...value, inputValue])
      }
      return
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ': {
        event.preventDefault()
        setInputValue('')
        onChange([...value, inputValue])
      }
    }
  }

  const onBlur = () => {
    if (!inputValue || value?.includes(inputValue)) return
    setInputValue('')
    onChange([...value, inputValue])
  }

  const handleChange = (values) => {
    onChange(values?.map((val) => val.value) || [])
  }

  const colourStyles = {
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      textTransform: isEmail ? 'lowercase' : '',
      backgroundColor: isEmail
        ? data?.label?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length
          ? ''
          : 'tomato'
        : '',
      color: isEmail
        ? data?.label?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length
          ? ''
          : '#FFF'
        : '',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: isEmail
        ? data?.label?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length
          ? ''
          : 'tomato'
        : '',
      color: isEmail
        ? data?.label?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length
          ? ''
          : '#FFF'
        : '',
    }),
  }

  return (
    <CreatableSelect
      isMulti
      isClearable
      isDisabled={disabled}
      menuIsOpen={false}
      placeholder={placeholder}
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      value={tags}
      onChange={handleChange}
      onInputChange={setInputValue}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      styles={colourStyles}
    />
  )
}
