import React from 'react'
import { useNode } from '@craftjs/core'

import { ImageSettings } from './ImageSettings'

export const Image = (props) => {
  const {
    connectors: { connect },
    isSelected,
  } = useNode((state) => ({
    isSelected: state.events.selected,
  }))

  const {
    width,
    height,
    imgUrl,
    alt,
    boxSizing,
    redirectUrl,
    maxWidth,
    textAlign,
    objectFit,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  const getImage = () => (
    <img
      style={{
        borderRadius,
        maxWidth,
        boxSizing,
      }}
      src={imgUrl}
      alt={alt}
      title={alt}
      width={width}
      height={height}
      surveyName={props.surveyName}
    />
  )

  return (
    <div
      style={{
        borderRadius,
        padding,
        maxWidth,
        textAlign,
        objectFit,
        boxSizing,
      }}
      ref={(ref) => connect(ref)}
    >
      {redirectUrl ? (
        <a
          target="_blank"
          rel="noopener"
          href={redirectUrl}
          onClick={isSelected ? (e) => e.preventDefault() : () => {}}
        >
          {getImage()}
        </a>
      ) : (
        getImage()
      )}
    </div>
  )
}

export const ImageDefaultProps = {
  maxWidth: '100%',
  width: '100%',
  height: '100%',
  alt: '',
  textAlign: 'center',
  objectFit: 'contain',
  boxSizing: 'border-box',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  imgUrl:
    'https://assets.billme.co.in/public/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png',
}

Image.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
}
