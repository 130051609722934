import React from 'react'

import CustomPopup from 'components/Content/CustomPopup'
import { ClearIcon, AddIcon } from 'assets/icons'
import { Input } from 'components/Common/Input'
import { SectionHeading } from 'components/Common/SectionHeading'
import { useDebouncedEffect, useSelectStore, useStoreFetch } from 'hooks'
import {
  getAllCategories,
  getFilterOptions,
} from 'redux/actions/productAnalyticsActions'
import { Text } from 'components/Common/Text'
import { PopupHeader, PopupFooter } from '../../components/ModalComponents'
import { $Block, $FlexBox, $FlexItem } from '../../ProductAnalytics.styled'
import {
  $AddAnotherBlock,
  $AddNewCategory,
  $ClearBlock,
  $PopupBody,
} from './ProductForm.styled'
import { useDispatch } from 'react-redux'

/**
 * @description add/update product form component
 */
const ProductForm = ({
  productData,
  onInputChange,
  onSelectChange,
  onAddMoreDisplayNames,
  onDisplayNameChange,
  onClearDisplayName,
  onNewCategoryChange,
  newCategory,
}) => {
  const formRef = React.useRef()

  const dispatch = useDispatch()
  useDebouncedEffect(() => dispatch(getAllCategories()), [])

  const { data: allProducts } = useSelectStore('productAnalytics.allProducts')

  const { data: productsFilterOptions, fetchData } = useStoreFetch(
    getFilterOptions,
    'productAnalytics.productsFilterOptions',
    true
  )

  const _generateOptions = (data, dataKey, suffix = '') => {
    const options = data?.map((item) => ({
      value: item?._id,
      label: item?.[dataKey] + suffix,
    }))
    return options || []
  }

  const _generateSelectValue = (data, key, suffix = '') => {
    if (data && !data.hasOwnProperty('label')) {
      const valueObj = {
        label: data[key] + suffix,
        value: data._id,
      }
      return valueObj
    } else {
      return data
    }
  }

  const closePopup = () => {
    formRef.current.closeModal()
  }
  const showPopup = () => {
    formRef.current.openModal()
  }

  const onConfirmNewCategory = () => {
    onSelectChange(newCategory, 'categoryDetails')
    formRef.current.closeModal()
    onNewCategoryChange('')
  }

  const onClearNewCategory = () => {
    let currentProductCategory = null
    if (productData?._id) {
      currentProductCategory =
        allProducts?.docs?.find(({ _id }) => _id === productData?._id)
          ?.categoryDetails || null
    }

    onSelectChange(currentProductCategory, 'categoryDetails')
  }

  React.useEffect(() => {
    if (
      !productsFilterOptions ||
      Object.values(productsFilterOptions).length !== 2
    ) {
      fetchData()
    }
  }, [productsFilterOptions, fetchData])

  const categoriesOptions = _generateOptions(
    productsFilterOptions?.categories,
    'name'
  )

  const uamOptions = _generateOptions(productsFilterOptions?.uam, 'value')

  return (
    <>
      <$FlexBox justify="space-between">
        <$FlexItem flexBasis="45%">
          <Input
            variant="input"
            label="Product Name"
            placeholder="eg: Chicken Shawarma de French style"
            id="productName"
            value={productData?.productName || ''}
            onChange={(e) => onInputChange(e, 'productName')}
            inputWidth="100%"
          />
          <Input
            variant="input"
            label="Product Code"
            placeholder="eg: 450764145"
            id="productCode"
            value={productData?.productCode || ''}
            onChange={(e) => onInputChange(e, 'productCode')}
            inputWidth="100%"
          />
          <$Block margin={`25px 0 0`}>
            {productData?.displayNames?.map((name, index) => (
              <$Block key={index} position="relative">
                <SectionHeading
                  heading={`Display Name ${index > 0 ? index + 1 : ''}`}
                  toolTip="Display Name"
                  variant="small"
                  color="main"
                />
                <Input
                  key={index}
                  variant="input"
                  placeholder="eg: Chicken Shawarma de French style"
                  value={name || ''}
                  onChange={(e) => onDisplayNameChange(e, index)}
                  inputWidth="100%"
                  margin="0"
                />
                {index > 0 && (
                  <$ClearBlock onClick={() => onClearDisplayName(index)}>
                    <ClearIcon />
                  </$ClearBlock>
                )}
              </$Block>
            ))}
            {productData?.displayNames?.length < 5 && (
              <$AddAnotherBlock onClick={onAddMoreDisplayNames}>
                <AddIcon /> Add another display name
              </$AddAnotherBlock>
            )}
          </$Block>
        </$FlexItem>
        <$FlexItem flexBasis="45%">
          <$Block margin={`12px 0 0`}>
            <Text variant="small" type="main">
              Choose Category or{' '}
              <$AddNewCategory onClick={showPopup}>
                Add new category instead
              </$AddNewCategory>
            </Text>
          </$Block>
          {productData?.categoryDetails &&
          typeof productData?.categoryDetails === 'string' ? (
            <$FlexBox justify="space-between" align="center">
              <Text variant="p" type="main">
                {productData.categoryDetails}
              </Text>
              <$ClearBlock onClick={onClearNewCategory} position="static">
                <ClearIcon />
              </$ClearBlock>
            </$FlexBox>
          ) : (
            <Input
              variant="single-select"
              placeholder="eg: Burgers"
              value={_generateSelectValue(productData?.categoryDetails, 'name')}
              onChange={(value) => onSelectChange(value, 'categoryDetails')}
              inputWidth="100%"
              margin="0"
              options={categoriesOptions}
            />
          )}

          <$FlexBox margin={`12px 0 0`} justify="space-between">
            <$FlexItem flexBasis="45%">
              <Input
                variant="single-select"
                label="Unit of Measurement (UAM)"
                placeholder="Cms, kg etc"
                value={_generateSelectValue(productData?.uamDetails, 'value')}
                onChange={(value) => onSelectChange(value, 'uamDetails')}
                inputWidth="100%"
                options={uamOptions}
              />
            </$FlexItem>
            <$FlexItem flexBasis="45%">
              <Input
                variant="input"
                label="Tax Group(GST)"
                placeholder="%"
                type="number"
                min={0}
                max={100}
                value={productData?.tax}
                onChange={(e) => onInputChange(e, 'tax')}
                inputWidth="100%"
              />
            </$FlexItem>
          </$FlexBox>
        </$FlexItem>
      </$FlexBox>
      <CustomPopup
        className={'filter-modal'}
        ref={formRef}
        headerComponent={true}
        heading={<PopupHeader title="Add New Category" onClose={closePopup} />}
        footer={
          <PopupFooter onCancel={closePopup} onConfirm={onConfirmNewCategory} />
        }
      >
        <$PopupBody>
          <Input
            variant="input"
            label="Category Name"
            placeholder="Category"
            value={newCategory}
            onChange={(e) => onNewCategoryChange(e.target.value)}
            inputWidth="50%"
          />
        </$PopupBody>
      </CustomPopup>
    </>
  )
}

export default ProductForm
