import React, { Component } from "react";

class BrandCard extends Component {
  render() {
    return (
      <div className="profileInnerBox brandBox shadow p-1 mb-1 bg-white rounded">
        <div className="brandLogo">
          <img ref="image" src={this.props.brandLogoImage} alt="Brand Logo" />
        </div>
        <div className="brandInfo">
          <div className="brandName">{this.props.brandName}</div>
          <div className="brandCategory">
            Category:
            <span className="brandCategoryName">
              {this.props.brandCategory}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default BrandCard;
