import React, { useState } from 'react'
import moment from 'moment'

import { Text, Input } from 'components/Common'

import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import { $DateInput } from '../AutoEngagement.styled'

import { _selectOptionsGenerator } from 'utils/formatter'
import {
  WHEN_TYPES,
  HOURS,
  MINUTES,
  PERIOD,
  REPEAT_EVERY,
  END_DATE_SELECTION,
  MONTH_ARR,
  WEEK_ARR,
  GET_SEL_VALUE,
  TODAY,
} from '../constants'
import { NamedCheckbox } from './CustomCheckbox'
import { timeStampToUtc, utcToTimeStamp } from 'utils/getDateFormat'

export const TimeSelector = ({ timeStamp, onTimestampChange }) => (
  <>
    <Input
      variant="single-select"
      maxMenuHeight="120px"
      options={_selectOptionsGenerator(HOURS)}
      value={timeStamp.hours}
      onChange={(val) => onTimestampChange(val, 'hours')}
      placeholder="HH"
      inputWidth="100px"
      containerMargin={`0px ${spacing.l}`}
    />
    <Input
      variant="single-select"
      maxMenuHeight="120px"
      options={_selectOptionsGenerator(MINUTES)}
      value={timeStamp.minutes}
      onChange={(val) => onTimestampChange(val, 'minutes')}
      placeholder="MM"
      inputWidth="100px"
      containerMargin={`0px ${spacing.l}`}
    />
    <Input
      variant="single-select"
      options={_selectOptionsGenerator(PERIOD)}
      value={timeStamp.period}
      onChange={(val) => onTimestampChange(val, 'period')}
      inputWidth="100px"
      containerMargin={`0px ${spacing.l}`}
    />
  </>
)

const DayOnSelector = ({ form, setForm }) => {
  const ARR = form?.repeatEvery?.type === 'month' ? MONTH_ARR : WEEK_ARR
  let days = form?.repeatEvery?.days ? [...form?.repeatEvery?.days] : []
  const type = form?.repeatEvery?.type || 'week'

  const handleDaysChange = (val) => {
    if (days?.includes(val)) {
      days = days?.filter((day) => day !== val)
    } else {
      days.push(val)
    }
    setForm({
      ...form,
      repeatEvery: {
        ...form.repeatEvery,
        days: [...days],
        type,
      },
    })
  }

  return (
    <FlexBox wrap="wrap" mt={spacing.sm} style={{ maxWidth: '300px' }}>
      {ARR.map((item) => (
        <NamedCheckbox
          key={item.value}
          name={item.label}
          value={item.value}
          label={item.label}
          lastDay={item.value === 'last day of month'}
          isChecked={days?.includes(item.value)}
          handleCheckboxChange={handleDaysChange}
          margin="4px"
        />
      ))}
    </FlexBox>
  )
}

const SendLater = ({ form, setForm, onScheduledTimeChange }) => {
  const date = form.startDate ? moment(form.startDate).format('YYYY-MM-DD') : ''
  const timeStamp = utcToTimeStamp(form.scheduledTime)
  const onDateChange = (e) => {
    setForm({
      ...form,
      startDate: e.target.value,
    })
  }

  return (
    <FlexBox align="center">
      <$DateInput
        type="date"
        min={TODAY}
        value={date}
        onChange={onDateChange}
      />
      <TimeSelector
        timeStamp={timeStamp}
        onTimestampChange={onScheduledTimeChange}
      />
    </FlexBox>
  )
}

const Recurring = ({ form, setForm, onScheduledTimeChange }) => {
  const startDate = form.startDate
    ? moment(form.startDate).format('YYYY-MM-DD')
    : ''
  const endDate = form.endDate ? moment(form.endDate).format('YYYY-MM-DD') : ''

  const [endDateType, setEndDateType] = useState(endDate ? 'till' : 'never')

  const onChangeRepeatType = (val) => {
    setForm({
      ...form,
      repeatEvery: {
        ...form.repeatEvery,
        type: val.value,
        days: [],
      },
    })
  }

  const onDateChange = (e, field) => {
    setForm({
      ...form,
      [field]: e.target.value,
    })
  }

  return (
    <>
      <FlexBox align="center" mb={spacing.m}>
        <Text variant="p" type="main" width="100px">
          Start Date
        </Text>
        <$DateInput
          type="date"
          min={TODAY}
          value={startDate}
          onChange={(e) => onDateChange(e, 'startDate')}
        />
      </FlexBox>
      <FlexBox align="flex-start">
        <Text variant="p" type="main" width="100px" my={spacing.l}>
          Repeat Every
        </Text>
        <Input
          variant="single-select"
          options={REPEAT_EVERY}
          value={GET_SEL_VALUE(form?.repeatEvery?.type, REPEAT_EVERY)}
          onChange={onChangeRepeatType}
          placeholder="Select"
          inputWidth="150px"
          containerMargin={`0px ${spacing.l}`}
        />
        <div>
          <FlexBox align="flex-start">
            <Text
              variant="p"
              type="main"
              width="30px"
              my={spacing.l}
              mr={spacing.l}
              align="center"
            >
              ON
            </Text>
            <DayOnSelector form={form} setForm={setForm} />
          </FlexBox>
          <FlexBox>
            <Text
              variant="p"
              type="main"
              width="30px"
              my={spacing.l}
              align="center"
            >
              AT
            </Text>
            <TimeSelector
              timeStamp={utcToTimeStamp(form.scheduledTime)}
              onTimestampChange={onScheduledTimeChange}
            />
          </FlexBox>
        </div>
      </FlexBox>
      <FlexBox align="center">
        <Text variant="p" type="main" width="100px">
          End Date
        </Text>
        <Input
          variant="radio"
          direction="row"
          gap={spacing.l}
          id="end-date-selection"
          options={END_DATE_SELECTION}
          value={endDateType}
          onChange={(val) => setEndDateType(val)}
          containerMargin="0px"
        />
        {endDateType === 'till' && (
          <$DateInput
            ml={spacing.xl}
            type="date"
            min={startDate}
            disabled={!startDate}
            value={endDate}
            onChange={(e) => onDateChange(e, 'endDate')}
          />
        )}
      </FlexBox>
    </>
  )
}

export const WhenToSend = ({ form, setForm }) => {
  const handleWhenSelectChange = (validity) => {
    const whenForm = { ...form, validity }
    delete whenForm.repeatEvery
    delete whenForm.scheduledTime
    delete whenForm.startDate
    delete whenForm.endDate
    setForm(whenForm)
  }

  const onScheduledTimeChange = (stamp, field) => {
    setForm({
      ...form,
      scheduledTime: timeStampToUtc({
        ...utcToTimeStamp(form.scheduledTime),
        [field]: stamp,
      }),
    })
  }

  return (
    <>
      <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
        When To Send
      </Text>
      <Input
        variant="radio"
        direction="row"
        gap={spacing.xl}
        label="Selection Type"
        id="selection"
        value={form.validity}
        onChange={handleWhenSelectChange}
        options={WHEN_TYPES}
      />
      {form.validity === 'immediate' && <p>Send as soon as you publish</p>}
      {form.validity === 'oneTime' && (
        <SendLater
          form={form}
          setForm={setForm}
          onScheduledTimeChange={onScheduledTimeChange}
        />
      )}
      {form.validity === 'recurring' && (
        <Recurring
          form={form}
          setForm={setForm}
          onScheduledTimeChange={onScheduledTimeChange}
        />
      )}
    </>
  )
}

export default WhenToSend
