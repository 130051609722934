const SAMPLE_FILE_URL =
  'https://assets.billme.co.in/product_analytics/sample.csv'

const INSTRUCTIONS = [
  'The sample excel contains all headers relating to product data upload.',
  'If you do not have certain data points for some products you can leave that cell blank, avoid putting in wrong data to maintain data quality.',
  'Compulsory data points include; Product name and Display name.',
  'Do not delete any headers or change their position or their text in the Sample CSV file.',
  'You can rename the file if you want to but do not change the file extension, only .CSV files are accepted.',
  'Acceptable input in Product code, Product name and Display names; Letters, number & special characters. Capital letters allowed',
  'Acceptable input in Tags; Letters, numbers. Separate multiple tags with commas (,). No capital letters allowed & spaces allowed.',
  'Acceptable input in Category Name; Letters. Capital letters allowed.',
  'Acceptable input in UAM; mg, gms, kgs, tons, unit, pieces, pack, strip, dozen, box, mm, cm, meter, inches, feet, sq. ft., sq. mt., ml, hours, days, weeks, months, years. (No caps)',
  'Only 1 UAM allowed per product',
  'Acceptable input in Tax group; Numbers. Do not add % symbol.',
]

export { SAMPLE_FILE_URL, INSTRUCTIONS }
