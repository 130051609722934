import React, { Component } from 'react'
import BillsList from './list/BillsList'
import '../../../css/content/bills.scss'
// import HeaderBar from "../../Common/HeaderBar";
import BillsListHeader from './list/BillsListHeader'
import { connect } from 'react-redux'
import swal from 'sweetalert'
// import LoadingOverlay from "react-loading-overlay";
import { MyToast } from 'components/Common'

import { compareDatePermission } from '../../../utils/getDateFormat'
//import actions here
import {
  getBills,
  getBillsBySearch,
  deleteBill,
  setBillFilterQuery,
  setDefaultFilters,
} from '../../../redux/actions/bills'
import Navbar from '../../Layout/Navbar'
import { userTypes } from '../../Layout/constants'
import { debounce } from 'lodash-es'
import {
  getTotalTransactions,
  getTotalSalesAndAvg,
} from '../../../redux/actions/billingAnalyticsActions'

import { centroCashier } from '../../conditionalComponent'
import Graphs from './graphs'
import StoreData from './storeData'
import { defaultFilters } from 'utils/billUtils'

const startDate = new Date()
startDate.setHours(0, 0, 0, 0)

const endDate = new Date()
endDate.setHours(23, 59, 59, 0)
class Main extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      data: null,
      isAllSelected: false,
      billsPopup: {
        status: false,
        data: {},
      },
      selectedBills: [],
      rowsPerPage: 10,
      selected: 0,
      offsetTop: 0,
      offsetLeft: 0,
      searchTxt: '',
      searchField: 'invoiceNumber',
      loading: false,
      isDeleteActive: false,
      page: 0,
    }
    this.getBillsBySearch = this.getBillsBySearch.bind(this)
    this.getBill = this.getBill.bind(this)
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this)
    this.handleBillSelection = this.handleBillSelection.bind(this)
    this.handleDeleteBills = this.handleDeleteBills.bind(this)
    this.deleteBills = this.deleteBills.bind(this)
    this.getStats = debounce(this.getStats.bind(this), 300)
    this.getDebouncedSearch = debounce(this.getDebouncedSearch.bind(this), 100)
  }

  componentDidMount() {
    this.props.setDefaultFilters({
      ...defaultFilters,
      date: {
        ...defaultFilters.date,
        isActive: true,
      },
    })

    this.props.setBillFilterQuery({ startDate, endDate })
    if (
      this.props.location?.search?.replace('?tab=', '') === 'Bills' ||
      !this.props.location?.search
    )
      this.getBill()
    this.getStats()
  }

  getStats() {
    this.props.getTotalTransactions({
      startDate: this.props?.filterQuery?.startDate || startDate,
      endDate: this.props?.filterQuery?.endDate || endDate,
      selectedStoreIds: this.props?.filterQuery?.selectedStoreIds,
    })

    this.props.getTotalSalesAndAvg({
      startDate: this.props?.filterQuery?.startDate || startDate,
      endDate: this.props?.filterQuery?.endDate || endDate,
      selectedStoreIds: this.props?.filterQuery?.selectedStoreIds,
    })
  }

  componentDidUpdate(prevProps) {
    const rule1 =
      (this.props?.filterQuery?.selectedStoreIds?.length > 0 ||
        prevProps?.filterQuery?.selectedStoreIds?.length > 0) &&
      (!this.props?.filterQuery?.selectedStoreIds?.every((store) =>
        prevProps?.filterQuery?.selectedStoreIds?.includes(store)
      ) ||
        !prevProps?.filterQuery?.selectedStoreIds?.every((store) =>
          this.props?.filterQuery?.selectedStoreIds?.includes(store)
        ))
    const rule2 =
      new Date(this.props.filterQuery?.startDate)?.toDateString() !==
      new Date(prevProps.filterQuery?.startDate)?.toDateString()
    const rule3 =
      prevProps?.location?.search !== this.props?.location?.search &&
      this.props.location?.search?.replace('?tab=', '') === 'Bills'

    const rule4 =
      new Date(this.props.filterQuery?.endDate)?.toDateString() !==
      new Date(prevProps.filterQuery?.endDate)?.toDateString()

    const rule5 = prevProps?.location?.search !== this.props?.location?.search

    const rule6 = prevProps?.selectedStores !== this.props?.selectedStores
    if (rule1 || rule3 || rule6) {
      this.getBill()
    }
    if (rule1 || rule2 || rule4 || rule5 || rule6) {
      this.getStats()
    }
  }

  async getBill(skip = 0) {
    // if (
    //   this.props.location?.search?.replace('?tab=', '')?.replace('%20', ' ') ===
    //   'Store Data'
    // )
    //   return
    if (
      this.props.filterQuery?.startDate &&
      !compareDatePermission(
        this.props.selectedVendor.featuresAlloted.exportRange,
        this.props.filterQuery.startDate
      )
    ) {
      this.context.toast('You can only access last 2 months data', 'warning')
      return false
    }
    this.setState({ loading: true, page: skip / this.state.rowsPerPage })
    try {
      let res = await getBills(
        {
          limit: this.state.rowsPerPage,
          skip,
          selectedStoreIds: this.props.selectedStores,
          startDate,
          endDate,
          ...(this.props.filterQuery || {}),
        },
        { loader: true }
      )
      if (res.code) {
        this.setState({
          loading: false,
          data: res.data.billsData,
        })
      }
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }

  handlePageClick = async (count) => {
    if (!count) return null
    this.setState({ loading: true })
    const page = this.state.page + count
    let skip = Math.ceil(page * this.state.rowsPerPage)
    this.setState({ page })

    if (this.state.searchTxt.length > 0) {
      //If Search value is found then called search api
      let res = await getBillsBySearch({
        limit: this.state.rowsPerPage,
        skip,
        searchParam: this.state.searchTxt,
        searchField: this.state.searchField,
        ...(this.props.filterQuery || {}),
      })
      if (res.code) {
        this.setState({ data: res.data.billsData, selectedBills: [] })
      }
    } else {
      let res = await getBills({
        limit: this.state.rowsPerPage,
        skip: skip,
        ...(this.props.filterQuery || {}),
      })
      if (res.code) {
        this.setState({
          data: res.data.billsData,
          selectedBills: [],
        })
      }
    }
    this.setState({ loading: false })
  }

  async getDebouncedSearch() {
    const { searchTxt, searchField } = this.state
    if (searchTxt.length === 0) return
    this.setState({ loading: true })
    let res = await getBillsBySearch({
      limit: this.state.rowsPerPage,
      skip: Math.ceil(this.state.page * this.state.rowsPerPage),
      searchParam: searchTxt,
      searchField,
      ...(this.props.filterQuery || {}),
    })
    if (res.code) {
      this.setState({
        data: res.data.billsData,
      })
    }
    this.setState({ loading: false })
  }

  async getBillsBySearch(searchTxt, searchField) {
    if (searchTxt?.length === 0 && searchTxt !== this.state.searchTxt) {
      this.getBill()
    }
    this.setState({ searchTxt, searchField })
  }

  onDeleteButtonClick(e, isDeleteBills = false) {
    e.preventDefault()

    if (isDeleteBills) {
      // Delete selected bills
      this.deleteBills()
    } else
      this.setState({
        isDeleteActive: !this.state.isDeleteActive,
        selectedBills: [],
      })
  }

  async handleDeleteBills() {
    if (this.state.selectedBills?.length) {
      const response = await deleteBill({ billUid: this.state.selectedBills })
      if (response.code) {
        // this.handleToast()
        this.context.toast('Bill deleted successfully', 'success')
        this.getBill(Math.ceil(this.state.page * this.state.rowsPerPage))
        this.setState({ selectedBills: [] })
        this.getStats()
      }
    } else swal("Can't Proceed", 'No, Bill selected.', 'error')
  }

  handleBillSelection(data) {
    if (data === 'all') {
      if (this.state.selectedBills.length === this.state.data.length) {
        this.setState({
          isAllSelected: false,
          selectedBills: [],
        })
      } else {
        let dataArray = []
        for (let x in this.state.data) {
          dataArray.push(this.state.data[x].billUid)
        }
        this.setState({
          selectedBills: dataArray,
        })
      }
    } else {
      if (this.state.selectedBills.includes(data)) {
        this.setState({
          selectedBills: this.state.selectedBills.filter((re) => re !== data),
        })
      } else {
        this.setState({
          selectedBills: [...this.state.selectedBills, data],
        })
      }
    }
  }

  deleteBills() {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      icon: 'warning',
      buttons: true,
    }).then((value) => {
      if (!!value) return this.handleDeleteBills()
    })
  }

  render() {
    const hideActionButtons =
      this.props.isCashier ||
      this.props.isManager ||
      this.props.isOperations ||
      this.props.isSecurity
    const activeTab =
      this.props.location?.search?.replace('?tab=', '')?.replace('%20', ' ') ||
      'Bills'
    return (
      <>
        <Navbar heading="Bills" />
        <BillsListHeader
          isFromStoresManage={
            this.props.history?.location?.state === 'fromStorePage'
          }
          loading={this.state.loading}
          onProceedWithFilters={this.getBill}
          searchTxt={this.state.searchTxt}
          searchField={this.state.searchField}
          onHandleSearch={this.getBillsBySearch}
          getBills={this.getBill}
          getDebouncedSearch={this.getDebouncedSearch}
          onDeleteClick={this.onDeleteButtonClick}
          isDeleteActive={this.state.isDeleteActive}
          selectedBills={this.state.selectedBills}
          hideActionButtons={hideActionButtons}
          activeTab={activeTab}
          setStoreGrp={(str) => this.setState({ storeGrpName: str })}
          storeGrpName={this.state.storeGrpName}
          billData={this.state.data}
          getStats={this.getStats}
        />
        <div className="billsPage">
          {activeTab === 'Bills' && !this.props.isOperations && (
            <BillsList
              loading={this.state.loading}
              billsData={this.state.data}
              onPageChange={(data) => this.handlePageClick(data)}
              isDeleteActive={this.state.isDeleteActive}
              handleBillSelection={this.handleBillSelection}
              selectedBills={this.state.selectedBills}
              selectedBillsCount={this.state.selectedBills?.length}
              hideActionButtons={hideActionButtons}
              page={this.state.page}
              isCentroCashier={this.props.isCentroCashier}
              deleteBills={this.deleteBills}
            />
          )}
          {activeTab === 'Store Data' && <StoreData />}
          {activeTab === 'Graphs' && <Graphs />}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const isCashier = state.auth.user.userType === userTypes.CASHIER
  const isManager = state.auth.user.userType === userTypes.MANAGER
  const isCentroCashier = centroCashier.includes(state.auth.user.email)
  const isOperations = state.auth.user.userType === userTypes.OPERATIONS
  const isSecurity = state.auth.user.userType === userTypes.SECURITY

  return {
    rememberFilters: state.bills.rememberFilters,
    defaultFilters: state.bills.defaultFilters,
    filterQuery: state.bills.filterQuery,
    selectedVendor: state.vendorIds.selectedVendor,
    selectedStores: state.stores?.selectedStores,
    isCashier,
    isManager,
    isCentroCashier,
    isOperations,
    isSecurity,
  }
}

export default connect(mapStateToProps, {
  setBillFilterQuery,
  setDefaultFilters,
  getTotalTransactions,
  getTotalSalesAndAvg,
})(Main)
