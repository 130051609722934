import React from 'react'
import styled, { useTheme } from 'styled-components'

import { borderRadius, spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import ExcelIcon from 'assets/icons/excel-icon.svg'
import ExcelFileName from 'assets/images/excel-name-sample.png'
import ExcelFields from 'assets/images/excel-upload-fields.png'
import FileUploadIcon from 'assets/icons/file-upload.svg'
import { Text } from './Text'
import { DrumStick } from './DrumStick'

/**
 * @description common component to render upload excel steps
 * @param {String} fileName file name to display
 * @param {String} sampleFile sample file url to download
 * @param {String} excelFields fields value image
 * @param {String} excelFileName file name image
 * @param {Function} onFileChange on file change handler
 * @param {Object} ref file ref object
 */
const UploadExcel = ({
  fileName,
  sampleFile,
  excelFields,
  excelFileName,
  onFileChange,
  ref,
}) => {
  const theme = useTheme()

  return (
    <$Steps>
      <FlexBox>
        <DrumStick label="1" />
        <div>
          <Text variant="p" mb={spacing.m}>
            Download <$BlueText>{fileName}.csv</$BlueText> as attached below.
          </Text>

          <$DownloadExcel align="center">
            <img src={ExcelIcon} alt="Excel Icon" />
            <Text variant="small" ml={spacing.m}>
              {fileName}.csv
            </Text>
            <a href={sampleFile} download={fileName}>
              Download file
            </a>
          </$DownloadExcel>
        </div>
      </FlexBox>
      <FlexBox>
        <DrumStick label="2" />
        <div>
          <Text variant="p" mb={spacing.m}>
            Open Sample.csv and find fill data according to headers mentioned.
          </Text>
          <img
            src={excelFields}
            alt="Excel fields"
            style={{ marginBottom: spacing.xxl }}
          />
        </div>
      </FlexBox>
      <FlexBox>
        <DrumStick label="3" />
        <div>
          <Text variant="p" mb={spacing.m}>
            Save file ( “Save as Sample.csv”)
          </Text>
          <img
            src={excelFileName}
            alt="Save file as Sample.csv"
            style={{ marginBottom: spacing.xxl }}
          />
        </div>
      </FlexBox>
      <FlexBox>
        <DrumStick label="4" />
        <div>
          <Text variant="p" mb={spacing.l}>
            Upload Sample.csv below
          </Text>

          <$DropZone justify="center" align="center">
            <img src={FileUploadIcon} alt="Upload file icon" />
            <input type="file" onChange={onFileChange} ref={ref} />
            <div>
              <Text variant="p">
                <strong>Drag & Drop Sample.csv here</strong>
              </Text>
              <Text variant="small">
                Or
                <span
                  style={{
                    color: theme.colors.primary.default,
                    padding: `0px ${spacing.s}`,
                  }}
                >
                  Browse file
                </span>
                from your computer
              </Text>
            </div>
          </$DropZone>
        </div>
      </FlexBox>
    </$Steps>
  )
}

UploadExcel.defaultProps = {
  fileName: 'User_Sample',
  sampleFile: 'https://assets.billme.co.in/crm/sample.csv',
  excelFileName: ExcelFileName,
  excelFields: ExcelFields,
  onFileChange: () => {},
}

export default UploadExcel

const $Steps = styled.div`
  padding: ${spacing.xl} 0px;
`

const $DownloadExcel = styled(FlexBox)`
  padding: ${spacing.m} ${spacing.l};
  margin: ${spacing.l} 0px ${spacing.xxl};
  box-shadow: 0px 6px 12px 0px hsla(0, 0%, 0%, 0.16);

  a {
    background-color: ${({ theme }) => theme.colors.greenYellow};
    color: ${({ theme }) => theme.colors.white};
    padding: ${spacing.m} ${spacing.l};
    border-radius: ${borderRadius.small};
    margin-left: ${spacing.l};
  }
`

const $DropZone = styled(FlexBox)`
  padding: ${spacing.xl};
  border: 1px dashed ${({ theme }) => theme.colors.primary.default};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: relative;

  img {
    margin-right: ${spacing.l};
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

const $BlueText = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  padding: 0 4px;
`
export { $Steps, $DownloadExcel, $DropZone }
