import React from 'react'
import { useNode } from '@craftjs/core'

import { LineSettings } from './LineSettings'

export const Line = (props) => {
  const {
    connectors: { connect },
    craftProps,
  } = useNode((node) => ({
    craftProps: node.data.props,
  }))

  return (
    <hr
      ref={(ref) => connect(ref)}
      style={{
        ...props,
        width: craftProps.width,
        height: `${craftProps.height}px`,
      }}
    />
  )
}

const LineDefaultProps = {
  maxWidth: '100%',
  height: '1',
  backgroundColor: '#3B86FF',
  margin: 'auto',
  width: '100%',
}

Line.craft = {
  props: LineDefaultProps,
  related: {
    settings: LineSettings,
  },
}
