import React from 'react'
import styled from 'styled-components'
import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { Button, Text, StyledCloseButton, MultiSelect } from 'components/Common'

export const MultiSelectFilter = ({
  label,
  id,
  handleMultiSelectChange,
  value,
  options,
  expand = true,
}) => {
  return (
    <FlexBox direction="column" expand={expand}>
      <Text variant="small" marginBottom={spacing.m}>
        {label}
      </Text>
      <MultiSelect
        options={options}
        onChange={(value, actionMeta) =>
          handleMultiSelectChange(value, id, actionMeta)
        }
        value={value}
        placeholder={label}
        name={label}
      />
    </FlexBox>
  )
}

export const FilterHeader = React.forwardRef(
  (
    {
      filter,
      handleClearAll,
      isExport,
      setIsExport,
      filterCount,
      filterHeader = 'Filter',
    },
    ref
  ) => {
    return (
      <$FilterHeader>
        <FlexBox justify="space-between" align="center">
          <$FilterHeaderLeft justify="space-between" align="center">
            <FlexBox my={spacing.m}>
              <Text variant="p" weight="bold">
                {filterHeader}
              </Text>
              <Text
                type="primary"
                variant="p"
                weight="bold"
                marginLeft={spacing.m}
              >
                (
                {filterCount ??
                  Object.values(filter)
                    .map(({ isActive }) => isActive)
                    .filter((f) => f === true).length}
                )
              </Text>
            </FlexBox>
            {handleClearAll && (
              <Button
                variant="textualError"
                noBorder
                onClick={() => handleClearAll()}
              >
                Clear all
              </Button>
            )}
          </$FilterHeaderLeft>
          <$CloseButton
            onClick={() => {
              if (isExport) setIsExport(false)
              ref.current.closeModal()
            }}
          />
        </FlexBox>
      </$FilterHeader>
    )
  }
)

export const FilterFooter = React.forwardRef(
  (
    {
      filter,
      handleFilter,
      isExport,
      setIsExport,
      handleExport,
      handleClearAll,
    },
    ref
  ) => {
    return (
      <$FilterFooter>
        <FlexBox justify="flex-end">
          {handleClearAll && (
            <Button
              variant="textualError"
              noBorder
              mr={spacing.m}
              onClick={() => handleClearAll()}
            >
              CLEAR FILTERS
            </Button>
          )}
          <Button
            variant="primaryOutline"
            noBorder
            mr={spacing.m}
            onClick={() => ref.current.closeModal()}
          >
            Cancel
          </Button>
          {!isExport ? (
            <Button onClick={() => handleFilter(filter)} variant="primary">
              Save
            </Button>
          ) : (
            <Button
              onClick={() => handleExport(filter, false)}
              variant="primary"
            >
              Export
            </Button>
          )}
        </FlexBox>
      </$FilterFooter>
    )
  }
)

const $FilterHeader = styled.div`
  background-color: #f2f5f8;
  padding: ${spacing.s} ${spacing.l};
`

const $FilterHeaderLeft = styled(FlexBox)`
  min-width: 250px;
`

const $FilterFooter = styled($FilterHeader)`
  padding: ${spacing.l};
`

const $CloseButton = styled(StyledCloseButton)`
  opacity: 0.6;

  &:before,
  &:after {
    background-color: #d76565;
  }

  &:hover {
    opacity: 0.9;
  }
`

const $ExportFooter = styled(FlexBox)`
  width: 100%;
`
