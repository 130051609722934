import moment from 'moment'
import { FormattedNumber } from 'react-intl'

import { MONTHS } from 'utils/dateUTC'
import { FlexBox, Logo, Text, Div } from 'styledComponent'
// import { $EcomTag } from '../bills/list/BillsListCard'
import { $ChannelHolder } from './UsageAndInvoice.styled'

export const DIGITAL_BILLING = 'store'

export const PROMOTION = 'campaign'

export const OVERALL_SUMMARY = 'overview'

export const AUTO_ENGAGEMENT = 'engagement'

export const USAGE_OPTIONS = [
  {
    label: 'Overall Summary',
    value: OVERALL_SUMMARY,
  },
  {
    label: 'Digital Billing',
    value: DIGITAL_BILLING,
  },
  {
    label: 'Promotion',
    value: PROMOTION,
  },
  {
    label: 'Auto Engagement',
    value: AUTO_ENGAGEMENT,
  },
]

export const USAGE_MAP = {
  [OVERALL_SUMMARY]: {
    canExport: true,
    canSearch: false,
    filters: {
      monthAndYear: {
        id: 'monthAndYear',
        type: 'date-month-year',
        isActive: true,
        label: 'Month & Year',
        value: {
          month: MONTHS[moment().month()],
          year: { label: moment().year(), value: moment().year() },
        },
      },
      emailList: {
        id: 'emailList',
        type: 'emailList',
        label: 'Email List',
        isActive: false,
        inputType: 'create-tags',
        value: [],
      },
    },
  },
  [DIGITAL_BILLING]: {
    canExport: true,
    canSearch: false,
    filters: {
      monthAndYear: {
        id: 'monthAndYear',
        type: 'date-month-year',
        isActive: true,
        label: 'Month & Year',
        value: {
          month: MONTHS[moment().month()],
          year: { label: moment().year(), value: moment().year() },
        },
      },
      totalCharge: {
        id: 'totalCharge',
        type: 'range',
        isActive: false,
        label: 'Total Cost',
        separator: 'to Max. Amount',
        prefix: 'Min. Amount',
        value: {
          min: 0,
          max: 0,
        },
        emptyPlaceholder: {
          min: 'Min',
          max: 'Max',
        },
      },
      emailList: {
        id: 'emailList',
        type: 'emailList',
        label: 'Email List',
        isActive: false,
        inputType: 'create-tags',
        value: [],
      },
    },
  },
  [PROMOTION]: {
    canExport: false,
    canSearch: true,
    searchPlaceholder: 'Search for campaign name',
    filters: {
      monthAndYear: {
        id: 'monthAndYear',
        type: 'date-month-year',
        isActive: true,
        label: 'Month & Year',
        value: {
          month: MONTHS[moment().month()],
          year: { label: moment().year(), value: moment().year() },
        },
      },
      campaignType: {
        id: 'campaignType',
        name: 'channel',
        type: 'multi-select',
        isActive: false,
        label: 'Campaign Type',
        value: [],
        options: [
          {
            label: 'SMS',
            value: 'sms',
          },
          {
            label: 'Email',
            value: 'email',
          },
          {
            label: 'Whatsapp',
            value: 'whatsApp',
          },
        ],
      },
      totalCharge: {
        id: 'totalCharge',
        type: 'range',
        isActive: false,
        label: 'Total Cost',
        separator: 'to Max. Amount',
        prefix: 'Min. Amount',
        value: {
          min: 0,
          max: 0,
        },
        emptyPlaceholder: {
          min: 'Min',
          max: 'Max',
        },
      },
    },
  },
  [AUTO_ENGAGEMENT]: {
    canExport: true,
    canSearch: true,
    searchPlaceholder: 'Search for campaign name',
    filters: {
      totalCharge: {
        id: 'totalCharge',
        type: 'range',
        isActive: false,
        label: 'Total Cost',
        separator: 'to Max. Amount',
        prefix: 'Min. Amount',
        value: {
          min: 0,
          max: 0,
        },
        emptyPlaceholder: {
          min: 'Min',
          max: 'Max',
        },
      },
      monthAndYear: {
        id: 'monthAndYear',
        type: 'date-month-year',
        isActive: true,
        label: 'Month & Year',
        value: {
          month: MONTHS[moment().month()],
          year: { label: moment().year(), value: moment().year() },
        },
      },
      emailList: {
        id: 'emailList',
        type: 'emailList',
        label: 'Email List',
        isActive: false,
        inputType: 'create-tags',
        value: [],
      },
    },
  },
}

export const TABLE_META = {
  [OVERALL_SUMMARY]: {
    showPagination: false,
    columns: [
      {
        name: 'Particulars',
        isSortable: false,
        cell: (data) => <div>{data?.viewType}</div>,
      },
      {
        name: 'Channels',
        isSortable: false,
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ channel }) => (
              <div>{channel}</div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Quantity',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ quantity = 0 }) => (
              <div>{quantity}</div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Cost',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ cost = 0, currencyCode = 'INR' }) => (
              <div>
                {' '}
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={cost}
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
              </div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Total',
        isSortable: false,
        sortName: 'totalCharge',
        sortDirections: ['none', 'asc', 'desc'],
        cell: ({ totalCharge = 0, currencyCode = 'INR' }) => (
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={totalCharge}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        ),
      },
    ],
  },
  [DIGITAL_BILLING]: {
    showPagination: true,
    columns: [
      {
        name: 'Store Details',
        isSortable: false,
        cell: (data) => (
          <FlexBox align="center">
            <Logo
              width="50px"
              height="50px"
              src={data?.store?.brand?.brandLogo}
              alt={data?.store?.brand?.name}
            />
            <Div>
              <Text>{data?.store?.brand?.name}</Text>
              <Text width="35ch" variant="small" ellipsis>
                {data?.store?.displayAddress}
              </Text>
              <Text>{data?.storeCode}</Text>
            </Div>
          </FlexBox>
        ),
      },
      {
        name: 'Channels',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ channel }) => (
              <Text>{channel}</Text>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Quantity',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ quantity }) => (
              <div>{quantity}</div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Cost',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ cost, currencyCode = 'INR' }) => (
              <div>
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={cost}
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
              </div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Total',
        isSortable: true,

        sortName: 'totalCharge',
        sortDirections: ['none', 'asc', 'desc'],
        cell: ({ totalCharge, currencyCode = 'INR' }) => (
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={totalCharge}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        ),
      },
    ],
  },
  [PROMOTION]: {
    showPagination: true,
    columns: [
      {
        name: 'Campaign Name',
        isSortable: false,
        cell: (data) => data?.name,
      },
      {
        name: 'Campaign Type',
        isSortable: false,
        cell: (data) => data?.channel,
      },
      {
        name: 'Messages Sent',
        isSortable: true,
        sortName: 'totalQty',
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => data?.totalQty,
      },
      {
        name: 'Total',
        isSortable: true,
        sortName: 'totalCharge',
        sortDirections: ['none', 'asc', 'desc'],
        cell: ({ totalCharge, currencyCode = 'INR' }) => (
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={totalCharge}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        ),
      },
    ],
  },
  [AUTO_ENGAGEMENT]: {
    showPagination: true,
    columns: [
      {
        name: 'Campaign Name',
        isSortable: false,
        cell: (data) => data?.name,
      },
      {
        name: 'Channels',
        isSortable: false,
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ channel }) => (
              <div>{channel}</div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Quantity',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ quantity }) => (
              <div>{quantity}</div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Cost',
        isSortable: false,
        sortDirections: ['none', 'asc', 'desc'],
        cell: (data) => (
          <$ChannelHolder>
            {data?.channels?.map(({ cost, currencyCode = 'INR' }) => (
              <div>
                {' '}
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={cost}
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
                />
              </div>
            ))}
          </$ChannelHolder>
        ),
      },
      {
        name: 'Total',
        isSortable: true,
        sortName: 'totalCharge',
        sortDirections: ['none', 'asc', 'desc'],
        cell: ({ totalCharge = 0, currencyCode = 'INR' }) => (
          <FormattedNumber
            style="currency"
            currency={currencyCode}
            value={totalCharge}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        ),
      },
    ],
  },
}
