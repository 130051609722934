import React from 'react'

import Navbar from 'components/Layout/Navbar'
import TopNavbar from './components/TopNavbar'
import AllProducts from './AllProducts'
import { $Container, $TabContent, $BodyStyles } from './ProductAnalytics.styled'
import AllCategories from './AllCategories'
import { Tabs } from 'components/Common'
import AddOrUpload from './AllProducts/AddOrUpload'
import { useRouter } from 'hooks'
import { ManagementContext } from './contexts'
import UnregisteredProducts from './UnregisteredProducts'

const ProductManagement = () => {
  const { query } = useRouter()

  return (
    <div>
      <ManagementContext.Provider value={{ forManagement: true }}>
        <Navbar heading="Products" />
        <TopNavbar />
        <$Container>
          <Tabs
            lastContent={
              !query?.tab || query?.tab === 'All Products' ? (
                <AddOrUpload />
              ) : null
            }
          >
            <div label="All Products">
              <$TabContent>
                <AllProducts />
              </$TabContent>
            </div>
            <div label="All Categories">
              <$TabContent>
                <AllCategories />
              </$TabContent>
            </div>
            <div label="Unregistered Products">
              <$TabContent>
                <UnregisteredProducts />
              </$TabContent>
            </div>
          </Tabs>
        </$Container>
        <$BodyStyles />
      </ManagementContext.Provider>
    </div>
  )
}

export default ProductManagement
