import styled, { createGlobalStyle } from 'styled-components'
import { FlexBox } from 'styledComponent'

const $BodyStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
  }
`
const $Container = styled.div`
  padding: 0 20px 20px;
`

const $TabContent = styled.div`
  padding-top: 10px;
`

const $FlexBox = styled(FlexBox)`
  width: ${({ width }) => width || '100%'};
  margin: ${({ m }) => m || 'unset'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
  justify-content: ${({ justify }) => justify || 'unset'};
  align-items: ${({ align }) => align || 'unset'};
`

const $FlexItem = styled.div`
  flex-basis: ${({ flexBasis }) => flexBasis || '100%'};
  width: ${({ width }) => width || 'unset'};
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  margin-right: ${({ mr }) => mr || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};
  margin-left: ${({ ml }) => ml || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #EBF3FE' : 'unset'};
`

const $Block = styled.div`
  width: ${({ width }) => width || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
  position: ${({ position }) => position || 'static'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
`

export { $BodyStyles, $Container, $TabContent, $FlexBox, $FlexItem, $Block }
