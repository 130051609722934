import React from 'react'
import styled, { css } from 'styled-components'
import { color } from 'design-system/styles'
import { Male, Female, Other } from 'assets/icons'

const genderIcon = (gender) => {
  switch (gender) {
    case 'male':
      return (
        <div>
          <Male />
        </div>
      )
    case 'female':
      return (
        <div>
          <Female />
        </div>
      )
    case 'other':
      return (
        <div>
          <Other />
        </div>
      )
    default:
      return null
  }
}

const genderColor = (gender) => {
  switch (gender) {
    case 'male':
      return color.mediumBlue
    case 'female':
      return color.redViolet
    case 'other':
      return 'linear-gradient(to bottom right, #FF66BB, #0C30AD)'
    default:
      return ''
  }
}

const $StyledAvatar = styled.div`
  min-width: 45px;
  min-height: 45px;
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `};
  background: #fff url(${(props) => props.src}) center / cover no-repeat;
  border-radius: 50%;
  border: 1px solid ${color.white};
  display: inline-block;
  position: relative;

  div {
    display: ${(props) => (props.gender ? 'block' : 'none !important')};
    position: absolute;
    background: ${(props) => genderColor(props.gender)};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: 0;
    top: 0;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: white;
        stroke: white;
      }
    }
  }

  ${(props) =>
    props.isList &&
    css`
      position: relative;
      border-width: 4px;
    `}
`

const $AvatarContainer = styled.ul`
  display: inline-flex;
  align-items: center;
  background-color: #eee;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Avatar = ({ src, limit, size, gender }) => {
  const renderAvatarWithLimit = (key, item, zIndex, left) => (
    <li key={key}>
      <$StyledAvatar src={item} style={{ zIndex, left }} isList />
    </li>
  )

  if (Array.isArray(src) && src.length > 1) {
    if (!limit)
      return (
        <$AvatarContainer>
          {src.map((item, i) =>
            renderAvatarWithLimit(`${item}${i}`, item, src.length - i, -i * 15)
          )}
        </$AvatarContainer>
      )
    else {
      const elements = []
      const remainingAvatar = src.length - limit

      for (let i = 0; i < limit; i++) {
        elements.push(
          renderAvatarWithLimit(
            `${src[i]}${i}`,
            src[i],
            src.length - i,
            -i * 15
          )
        )
      }

      return (
        <$AvatarContainer>
          {elements}
          <li style={{ position: 'relative', left: -(limit - 2) * 15 }}>
            + {remainingAvatar}
          </li>
        </$AvatarContainer>
      )
    }
  }

  return (
    <$StyledAvatar src={src} size={size} gender={gender}>
      {genderIcon(gender)}
    </$StyledAvatar>
  )
}
