import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { useRouter } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import { Button, Text, PopupContainer } from 'components/Common'
import { TopActionBar } from 'components/Content/autoEngagement/components/TopActionBar'
import { $ProgressBar } from '../Journey.styled'
import { $BlueBox } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { JOURNEY_INSTRUCTIONS } from '../constants'

export const JourneyTopBar = ({
  title,
  isView,
  progress,
  onNextClick,
  onExitClick,
  onPublishClick,
  onPreviousClick,
  isPreviousDisabled,
}) => {
  const { spacing } = useTheme()
  const { history } = useRouter()
  const [isPopOpen, setIsPopOpen] = useState(false)

  const extras = (
    <FlexBox gap={spacing.l}>
      {progress === '75%' && (
        <Button variant="textualPrimary" onClick={() => setIsPopOpen(true)}>
          View Instructions
        </Button>
      )}
      <Button
        variant="primaryOutline"
        disabled={isPreviousDisabled}
        onClick={onPreviousClick}
      >
        Previous Step
      </Button>
      {onPublishClick ? (
        <Button variant="publish" onClick={onPublishClick}>
          Publish
        </Button>
      ) : (
        <Button variant="primary" onClick={onNextClick}>
          Next Step
        </Button>
      )}
      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Auto Engagement - Instructions"
        cancelButtonName="Close"
      >
        <Text mb={spacing.l} weight="bold">
          {' '}
          Instructions{' '}
        </Text>
        <$BlueBox>
          {JOURNEY_INSTRUCTIONS.map((txt) => (
            <Text mb={spacing.l} key={txt}>
              {' '}
              {txt}{' '}
            </Text>
          ))}
        </$BlueBox>
      </PopupContainer>
    </FlexBox>
  )

  const onExit = () => {
    if (onExitClick) onExitClick()
    history.push('/journey')
  }

  return (
    <Div position="relative">
      <TopActionBar
        title={title}
        onExit={onExit}
        extras={isView ? '' : extras}
        exitTitle={isView ? 'Exit' : 'Save & Exit'}
      />
      <$ProgressBar width={progress} />
    </Div>
  )
}
