import styled, { css } from 'styled-components'

export const $FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: ${(props) => (props.align ? props.align : 'center')};
  gap: ${(props) => (props.gap ? props.gap : 0)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'wrap')};
`

export const $Block = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`

export const $PropertyPanel = styled.div`
  max-height: calc(100% - 50px);
  overflow: auto;
`

export const $EditorContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  border: 1px solid;
  padding: 8px;
  background-color: white;
  justify-content: center;
  gap: 16px;
  .component-selected {
    position: relative;
    outline: 1px dashed #2680eb;
    outline-offset: -1.5px;
  }
`

export const $MainEditor = styled.div`
  width: ${(props) => props.width};
  margin: auto;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid grey;
  font-family: unset;
`

export const $Toolbox = styled.div`
  min-width: 400px;
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.aquaHaze};
  width: 30%;
  border: 1px solid lightgrey;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  align-self: flex-end;
  input[type='color' i] {
    border-width: 0;
    cursor: pointer;
    padding: 0;
  }
  input[type='color'] {
    width: 30px;
    height: 30px;
  }
  textarea {
    width: 100%;
  }
  hr {
    margin: 0.5rem 0;
  }
`

export const $EditorHeader = styled.div`
  width: 100%;
  background-color: #cce0fd;
  border: 1px solid;
  border-bottom: none;
  padding: 5px;
  svg {
    cursor: pointer;
  }
`

export const $ToolTab = styled($FlexBox)`
  flex: 1;
  cursor: pointer;
  height: 50px;
  border: 1px solid ${(props) => (props.active ? '#7fb0ff' : '#aacafc')};
  color: ${(props) => (props.active ? '#000' : 'lightgray')};
  border-radius: 4px;
`

export const $TopTool = styled($FlexBox)`
  width: 40px;
  height: 40px;
  background-color: lightgray;
  border: 1px solid gray;
  cursor: pointer;

  svg {
    fill: ${(props) => (props.disabled ? 'gray' : 'black')};
  }

  ${(props) =>
    props.fullscreen
      ? css`
          padding: 5px;
          svg {
            width: 30px;
            height: 30px;
          }
          :hover {
            padding: 10px;
            svg {
              width: 25px;
              height: 25px;
            }
          }
        `
      : css`
          padding: 10px;
          svg {
            width: 25px;
            height: 25px;
          }
          :hover {
            padding: 5px;
            svg {
              width: 30px;
              height: 30px;
            }
          }
        `}
`

export const $Indicator = styled.div`
  position: absolute;
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;
  background-color: gray;
  display: flex;
  gap: 8px;
  background: #2680eb;
  padding: 0 10px;
  border-radius: 4px;
  transform: translate(-80px, -1px);
  z-index: 12;

  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
`

export const $Selector = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isMove ? 'move' : 'pointer')};
  svg {
    path {
      fill: #fff;
    }
  }
`
export const $TextToolContainer = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  /* justify-content: space-around; */
  border: 1px solid lightgrey;
  gap: 20px;
`

export const $ToolGroup = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 2px;
`

export const $ToolItem = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${(props) => (props.isActive ? 'grey' : 'white')};
  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 4px;
  }
  :focus {
    outline: none;
    border: none;
  }
`

export const $ComponentBox = styled.div`
  width: 30%;
  text-align: center;
  border-radius: 4px;
  cursor: move;
  padding-top: 12px;
  margin-bottom: 12px;
  p {
    color: ${({ theme }) => theme.colors.pickledBluewood};
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
    padding: 0 4px;
  }
  :hover {
    background-color: #d8e7f6;
    box-shadow: rgb(0, 0, 0, 0.35) 0 2px 4px;
    border-radius: ${({ theme }) => theme.borderRadius.default};
  }
`

export const $ModalOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 80%, 0.5);
`

export const $HidePointer = styled.div`
  iframe,
  video {
    pointer-events: ${(props) => props.hidePointer && 'none'};
  }
`

export const $PercentInput = styled($FlexBox)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.alto};
  overflow: hidden;
  input {
    padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
    width: 67px;
  }
  input,
  select {
    border: none;
    height: 34px;
    &:focus {
      outline: none;
    }
  }
`

export const $PropertyBar = styled.div`
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
  background-color: ${({ bgrd }) => bgrd};
`
