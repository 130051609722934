import React from 'react'
import styled, { css } from 'styled-components'
import { spacing } from 'design-system'

// Main.js
const StyledBillsPage = styled.div`
  font-family: 'myHelvitikaFontRoman';
  background-color: #ffffff !important;
  padding: ${spacing.m} ${spacing.l};
  min-height: 500px;
  border-top: 1px solid hsl(0, 0%, 87%);
`

export const BillsPage = ({ children }) => (
  <StyledBillsPage>{children}</StyledBillsPage>
)
// Main.js --- end
// BillDetails.js
const StyledBillsPopContent = styled.div`
  padding: 0%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
`

export const BillsPopContent = ({ children }) => (
  <StyledBillsPopContent>{children}</StyledBillsPopContent>
)

const StyledLeftPart = styled.div`
  background-color: white;
  margin: 0px;
`

export const LeftPart = ({ children }) => (
  <StyledLeftPart>{children}</StyledLeftPart>
)

const StyledRightPart = styled.div`
  background-color: #707070;
  height: 500px;
`

export const RightPart = ({ children }) => (
  <StyledRightPart>{children}</StyledRightPart>
)
// BillDetails.js --- end
// BillDetailsLeft.js
const StyledDetailsHeader = styled.div`
  padding: ${spacing.l} ${spacing.xxl} ${spacing.xl} ${spacing.l};
  display: grid;
  grid-template-columns: 25% 75%;
`

export const DetailsHeader = ({ children }) => (
  <StyledDetailsHeader>{children}</StyledDetailsHeader>
)

const StyledDetailsHeaderImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #0084e9;
  border-radius: 50%;
`

export const DetailsHeaderImage = ({ src, ...rest }) => (
  <StyledDetailsHeaderImage src={src} {...rest} />
)

const StyledCustomerAndBillInfo = styled.div`
  margin: 0% 5% 0% 5%;
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 0.8em;
  padding-top: 10px;
`

export const CustomerAndBillInfo = ({ children }) => (
  <StyledCustomerAndBillInfo>{children}</StyledCustomerAndBillInfo>
)

const StyledCustomerAndBillInfoChild = styled.div`
  display: grid;
  ${(props) =>
    props.align === 'left'
      ? css`
          justify-items: flex-end;
        `
      : css`
          justify-items: flex-start;
        `};
  row-gap: 5px;
  ${(props) =>
    props.align === 'left'
      ? css`
          padding-right: 20px;
        `
      : css`
          padding-left: 10px;
        `};

  div {
    color: #52a7ff;
    ${(props) =>
      props.color === '#43424b' &&
      css`
        color: #43424b;
      `};
  }
`

export const CustomerAndBillInfoChild = ({ children, color, align }) => (
  <StyledCustomerAndBillInfoChild color={color} align={align}>
    {children}
  </StyledCustomerAndBillInfoChild>
)

const StyledResendButton = styled.div`
  width: 50%;
  position: absolute;
  bottom: 0px;
  text-align: center;
  color: white;
  background-color: #28c67a;
  padding: 2%;
  cursor: pointer;

  img {
    width: 20px;
    margin-top: -2px;
  }
`

export const ResendButton = ({ children, image, onClick }) => (
  <StyledResendButton onClick={onClick}>
    <img src={image} alt="resend" />
    {children}
  </StyledResendButton>
)
// BillDetailsLeft.js --- end
// BillDetailsRight.js

// BillDetailsRight.js --- end
// BillResendLeft
const StyledBillResendLeft = styled.div`
  height: 100%;
  position: relative;
`

export const BillResendLeftPart = ({ children }) => (
  <StyledBillResendLeft>{children}</StyledBillResendLeft>
)

const StyledBillResendDialpad = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

export const BillResendDialpad = ({ children }) => (
  <StyledBillResendDialpad>{children}</StyledBillResendDialpad>
)

const StyledLeftInput = styled.div`
  margin: 2%;
  display: grid;
  grid-template-columns: 85% 15%;
  border-bottom: 1px solid black;
`

export const LeftInput = ({ children }) => (
  <StyledLeftInput>{children}</StyledLeftInput>
)

const StyledLeftInputClear = styled.div`
  color: red;
  text-align: center;
  padding-top: 8px;
  font-size: 1.5em;
  cursor: pointer;
`

export const LeftInputClear = ({ children, onClick }) => (
  <StyledLeftInputClear onClick={onClick}>{children}</StyledLeftInputClear>
)

const StyledLeftDialPad = styled.div`
  width: 100%;
  padding: ${spacing.m};
  display: grid;
  grid-template-columns: 28% 28% 28%;
  column-gap: 6%;
  row-gap: 12%;
`

export const LeftDialPad = ({ children }) => (
  <StyledLeftDialPad>{children}</StyledLeftDialPad>
)

const StyledLeftDialPadKeys = styled.div`
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  color: #707070;
  background-color: #fff;
  text-align: center;
  padding: ${spacing.s} ${spacing.m};
  font-size: 1.5em;
  font-weight: 1000;
  cursor: pointer;
  ${(props) =>
    props.data == null &&
    css`
      background-color: transparent;
      border-color: transparent;
    `};
`

export const LeftDialPadKey = ({ children, onClick, key, data }) => (
  <StyledLeftDialPadKeys onClick={onClick} key={key} data={data}>
    {children}
  </StyledLeftDialPadKeys>
)
// BillResendLeft --- end
// BillResendRight
const StyledBillResendRight = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding: 3% 5% 3% 5% !important;
`

export const BillResendRightPart = ({ children }) => (
  <StyledBillResendRight>{children}</StyledBillResendRight>
)

const StyledRightInner = styled.div`
  height: 100%;
  border-radius: 12px;
  padding: 25% 10% 0% 10%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.82);
  border: solid 1px #ffffff;
  background-color: #34c47c !important;
  cursor: pointer;
  text-align: center;
  color: white;
`

export const RightInner = ({ children, onClick }) => (
  <StyledRightInner onClick={onClick}>{children}</StyledRightInner>
)
// BillResendRight --- end
// BillsPopup
const StyledBillPopupCloseButton = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  font-size: 18px;
  background-color: hsla(0, 0%, 90%, 0.6);
  color: #ffffff;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;

  img {
    vertical-align: middle;
  }
`

export const BillPopupCloseButton = ({ onClick, src }) => (
  <StyledBillPopupCloseButton onClick={onClick}>
    <img src={src} alt="close" width={10} />
  </StyledBillPopupCloseButton>
)
// BillsPopup --- end
// BillsListHeader
const StyledBillsListHeader = styled.div`
  background-color: white;
  display: flex;
  height: 12%;
  align-items: center;
`

export const BillsListHeader = ({ children }) => (
  <StyledBillsListHeader>{children}</StyledBillsListHeader>
)

const StyledBillsListHeaderSearch = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 ${spacing.l};
`

export const BillsListHeaderSearch = ({ children }) => (
  <StyledBillsListHeaderSearch>{children}</StyledBillsListHeaderSearch>
)

const StyledBillsListSearch = styled(StyledBillsListHeaderSearch)`
  border: 0;
  background-color: #fff;
  font-weight: 900;
  padding: 0;
  width: 40px;
  background: url('../../images/search.svg') no-repeat 10px 11px;
  background-size: 17px;
`

export const BillsListSearch = ({ children }) => (
  <StyledBillsListSearch>{children}</StyledBillsListSearch>
)
// BillsListHeader --- end

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .disabled {
    cursor: not-allowed;
  }
`
