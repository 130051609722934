import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts'

import '../../../../css/content/billingCharts.scss'
import { getMaxMinChart } from '../../../../redux/actions/billingCharts'
import { formatDate2 } from '../../../../utils/getDateFormat'
import ChartHeader from './ChartHeader'
import DateRangeSelector from './DateRangeSelector'

class HighestLowestChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: 'false',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        dispaly: 'true',
      }
    }
  }

  handleDateRangeChange = (values) => {
    this.setState({ showCalendar: false })

    this.props.handleDateChange(values)

    const startDate = values.start.startOf('day').toDate()
    const endDate = values.end.endOf('day').toDate()

    this.props.getMaxMinChart({
      startDate,
      endDate,
      type: 'max_min_sales',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  showCalendar = () => {
    this.setState((state) => ({
      ...state,
      showCalendar: !state.showCalendar,
    }))
  }

  componentDidMount() {
    const date = this.props.selectedDate

    this.props.handleDateChange(date)
    console.log(this.props.selectedStoreIds)
    this.props.getMaxMinChart({
      startDate: date.start?.toDate(),
      endDate: date.end?.toDate(),
      type: 'max_min_sales',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  render() {
    let data
    if (_.has(this.props.billingCharts, 'maxMin')) {
      data = this.props.billingCharts.maxMin
    } else {
      data = []
    }
    const dataChart = []
    data.map((newData) => {
      dataChart.push({
        date: newData.date,
        tag: 'Highest Bill',
        amount: newData.highest_amt,
      })
      dataChart.push({
        date: newData.date,
        tag: 'Lowest Bill',
        amount: newData.lowest_amt,
      })
    })

    const cols = {
      date: {
        range: [0, 1],
        alias: 'date',
      },
    }

    if (this.state.dispaly === 'true') {
      return (
        <div className="highestLowestChart chartsMain">
          <ChartHeader title="Highest/Lowest Sales" />
          <hr />
          <DateRangeSelector
            value={this.props.selectedDate}
            onDateChange={this.handleDateRangeChange}
            showCalendar={this.state.showCalendar}
            toggleCalender={this.showCalendar}
          />
          <Chart
            height={450}
            data={dataChart}
            padding={[100, 50, 100, 100]}
            scale={cols}
            forceFit
          >
            <Legend />
            <Axis
              name="date"
              label={{
                formatter: (val) => formatDate2(val),
              }}
            />
            <Axis
              name="amount"
              label={{
                formatter: (val) => `${val} Rs.`,
              }}
            />
            <Tooltip
              crosshairs={{
                type: 'y',
              }}
            />
            <Geom
              type="line"
              position="date*amount"
              size={2}
              shape={'smooth'}
              color={['tag', ['#00ff00', '#ff0000']]}
            />
            <Geom
              type="point"
              position="date*amount"
              size={4}
              shape={'circle'}
              color={['tag', ['#00ff00', '#ff0000']]}
              style={{
                stroke: '#fff',
                lineWidth: 1,
              }}
            />
          </Chart>
          {_.isEmpty(data) ? (
            <div className="noData">
              No data to display between selected dates
            </div>
          ) : (
            ''
          )}
        </div>
      )
    } else {
      return <div className="waitingBillme">Waiting</div>
    }
  }
}

const mapStateToProps = (state) => ({
  billingCharts: state.billingCharts,
  errors: state.errors,
})

export default connect(mapStateToProps, { getMaxMinChart })(HighestLowestChart)
