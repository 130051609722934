import { combineReducers } from 'redux'
import toggleReducer from './toggle.js'
import authReducer from './auth.js'
import advertReducer from './advertReducer'
import errorReducer from './errorReducer.js'
import tokenReducer from './tokenReducer.js'
import pResetReducer from './pResetReducer.js'
import pGenerateReducer from './pGenerateReducer'
import backButtonReducer from './backButtonReducer'
import billingAnalyticsReducer from './billingAnalyticsReducer'
import consumerAnalyticsReducer from './cAnalyticsReducer.js'
import consumerAnalyticsPopupReducer from './consumerAnalyticsPopupReducer.js'
import companiesReducer from './companiesReducer'
import billingChartsReducer from './billingChartsReducer'
import paymentReducer from './paymentReducer'
import storesReducer from './storesReducer'
import invoiceReducer from './invoiceReducer'
import feedback from './feedback'
import feedbackCampaign from './feedbackCampaign'
import usageReducer from './usageReducer'
import sidebarLinkReducer from './sidebarLink.js'
import usersAccessReducer from './usersAccessReducer.js'
import consumerSmsReducer from './consumerSmsReducer.js'
import loyaltyReducer from './loyaltyReducer.js'
import layoutReducer from './layout'
import brandReducer from './brandReducer.js'
import locationReducer from './locationReducer.js'
import billsReducer from './billsReducer'
import vendorReducer from './vendorReducer'
import settingsReducer from './settingsReducer.js'
import crmFilters from './crmFilters.js'
import reportsReducer from './reportsReducer.js'
import autoEngageReducer from './autoEngageReducer'
import journeyReducer from './journeyReducer'
import productAnalytics from './productAnalyticsReducer'
import consumerAnalyticsOldReducer from './consumerAnalyticsReducer'
import bRed from './billReducer'
import strMgtReducer from './storeMgtReducer'
import usageAndInvoicesReducer from './usageAndInvoicesReducer.js'

export const allReducers = combineReducers({
  advert: advertReducer,
  auth: authReducer,
  autoEngage: autoEngageReducer,
  bAnalytics: billingAnalyticsReducer,
  backButton: backButtonReducer,
  billingCharts: billingChartsReducer,
  bills: billsReducer,
  brand: brandReducer,
  bRed,
  cAnalytics: consumerAnalyticsReducer,
  cAnalyticsOld: consumerAnalyticsOldReducer,
  cAnalyticsPopup: consumerAnalyticsPopupReducer,
  company: companiesReducer,
  consumerSms: consumerSmsReducer,
  crm: crmFilters,
  errors: errorReducer,
  feedback,
  feedbackCampaign,
  invoices: invoiceReducer,
  journey: journeyReducer,
  layout: layoutReducer,
  location: locationReducer,
  loyalty: loyaltyReducer,
  pGenerate: pGenerateReducer,
  pReset: pResetReducer,
  payment: paymentReducer,
  productAnalytics,
  reports: reportsReducer,
  settings: settingsReducer,
  sidebarLink: sidebarLinkReducer,
  stores: storesReducer,
  strMgt: strMgtReducer,
  toggle: toggleReducer,
  token: tokenReducer,
  usage: usageReducer,
  userAccess: usersAccessReducer,
  vendorIds: vendorReducer,
  usageAndInvoice: usageAndInvoicesReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    localStorage.clear()
    state = undefined
  }
  return allReducers(state, action)
}
