import styled, { css } from 'styled-components'
import { StyledCloseButton } from 'components/Common/Pill'
import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'

export const $Filter = styled.div`
  border: 1px solid ${(props) => props.theme.colors.neutral[300]};
`

export const $FilterHeader = styled.div`
  background-color: #f2f5f8;
  padding: ${spacing.s} ${spacing.l};
`

export const $FilterHeaderLeft = styled(FlexBox)`
  min-width: 250px;
`

export const $FilterFooter = styled($FilterHeader)`
  padding: ${spacing.l};
`

export const $CloseButton = styled(StyledCloseButton)`
  opacity: 0.6;

  &:before,
  &:after {
    background-color: #d76565;
  }

  &:hover {
    opacity: 0.9;
  }
`

export const $SelectedFilter = styled.div`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.m} ${spacing.l};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[300]};
  border-right: 1px solid ${(props) => props.theme.colors.neutral[300]};
  color: ${(props) =>
    props.isFilterActive
      ? props.theme.colors.primary[200]
      : props.theme.colors.neutral[500]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.colors.primary[100]};
      color: ${(props) => props.theme.colors.neutral[900]};
    `};

  ${(props) =>
    props.isFilterActive &&
    css`
      color: blue;
    `}
`
export const $ActiveFilter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
`

export const $ExportFooter = styled(FlexBox)`
  width: 100%;
`
