import createReducer from 'utils/createReducer'
import * as types from '../types/storeMgtTypes'

const initialState = {
  tableLoading: false,
  screenLoading: false,
  // company
  companyList: [],
  companyOptions: [],
  companyTypes: [],
  companyDetails: {},
  // brand
  brandList: [],
  brandOptions: [],
  brandCategories: [],
  brandDetails: {},
  // storeGroup
  storeGroupList: [],
  storeGroupOptions: [],
  storeGroupDetails: {},
  // Stores
  ecomStoreList: [],
  retailStoreList: [],
  storeDetails: {},
  storeKeylist: [],
  storeKeyDetails: {},
  storeKeyslist: [],
}

export default createReducer(initialState)(types)
