import styled from 'styled-components'
import React from 'react'
import { LoaderIcon } from './icons'

const BMCheckBox = ({ children, className, ...rest }) => {
  return (
    <div
      className={className}
      onClick={(event) => rest.stopPropagation && event.stopPropagation()}
    >
      <input type="checkbox" id={rest.id} {...rest} />
      <label htmlFor={rest.id}>{children}</label>
    </div>
  )
}

const BMInputGroup = ({
  children,
  handleInputChange,
  handleButtonClick,
  isLoading,
  ...rest
}) => {
  return (
    <div id="bm-input-grp" className={rest.className}>
      <input type="text" onChange={handleInputChange} />
      <button
        className="btn"
        onClick={handleButtonClick}
        disabled={rest.disabled}
      >
        {!isLoading ? (
          children
        ) : (
          <LoaderIcon colorVariant={`neutral`} height={`2em`} width={`2em`} />
        )}
      </button>
    </div>
  )
}

// FIXME: Duplicate of checkbox present in common/input - Remove this
export const CheckBox = styled(BMCheckBox)`
  margin: ${(props) => props.margin || ''};
  white-space: nowrap;
  height: ${(props) =>
    props.theme.checkBoxVariants[props['variant'] || 'default']};

  & input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }

  & input[type='checkbox']:checked ~ label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }

  & input[type='checkbox']:focus ~ label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  & input[type='checkbox']:focus:not(:checked) ~ label::before {
    border-color: #80bdff;
  }

  & input[type='checkbox']:not(:disabled):active ~ label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  & input[type='checkbox']:disabled ~ label {
    color: #6c757d;
  }

  & input[type='checkbox']:disabled ~ label::before {
    background-color: #e9ecef;
  }

  & label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    min-width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    padding-left: calc(
      ${(props) => props.theme.checkBoxVariants[props['variant'] || 'default']} +
        8px
    );
  }

  & label::before {
    position: absolute;
    top: 13%;
    left: 0rem;
    display: block;
    width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    height: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  & label::after {
    position: absolute;
    top: 13%;
    left: 0rem;
    display: block;
    width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    height: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    content: '';
    background: no-repeat 50%/50% 50%;
  }

  & input[type='checkbox']:checked ~ label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  & input[type='checkbox']:indeterminate ~ label::before {
    border-color: #007bff;
    background-color: #007bff;
  }

  & input[type='checkbox']:indeterminate ~ label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  & input[type='checkbox']:disabled:checked ~ label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }

  & input[type='checkbox']:disabled:indeterminate ~ label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
`

export const FloatingInput = styled.div`
  position: relative;
  margin: 20px 0px;
  width: 100%;

  input {
    width: 100%;
    border: 0px;
    padding-bottom: 5px;
    border-bottom: 2px solid ${(props) => props.theme.colors.neutral['300']};
    transition: all 0.2s;

    &:focus,
    &:not([value='']) {
      border-color: #ddd;
      outline: 0px;
      border-top: 0px;

      & + label {
        bottom: 20px;
        font-size: 12px;
        color: ${(props) => props.theme.colors.neutral['500']}!important;
      }
    }

    &:focus {
      border-bottom: 2px solid ${(props) => props.theme.colors.neutral['500']};
    }
  }

  label {
    position: absolute;
    left: 0px;
    bottom: 0px;
    color: ${(props) => props.theme.colors.neutral['400']};
    transition: all 0.3s;
    pointer-events: none;
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.neutral['200']};
  font-size: 12px;
  padding: 15px 10px;
  outline: none;
  border-radius: 6px;
  resize: none;

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.primary['300']};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.neutral['400']};
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  margin: ${(props) => props.margin || '0px'};
  opacity: ${(props) => (props.transparent ? '0' : '1')};
`

export const CheckboxInput = styled(Input)`
  position: ${(props) => props.position || 'static'};
`
export const InputGroup = styled(BMInputGroup)`
  display: flex;

  & input {
    width: 85%;
    padding: 10px 20px;
    border: 0;
    border-top: 1px solid #cacaca;
    height: 55px;
  }

  & button {
    width: 15%;
    background: ${(props) => props.theme.colors.primary['400']};
    height: 58px;
    border: 0;
    border-radius: 0;
    color: #fff;
    border: 1px solid ${(props) => props.theme.colors.primary['400']};
    padding: 10px 20px;
  }

  & button:hover {
    background: ${(props) => props.theme.colors.primary['600']};
    color: #fff;
  }

  & button:disabled {
    background: rgb(176, 206, 255);
  }
`

export const FilterInput = styled(Input)`
  /* display: block; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`
