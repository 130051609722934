import React from 'react'
// import { useDispatch } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { SectionHeading } from 'components/Common/SectionHeading'
import { spacing, borderRadius } from 'design-system'

const MONTHS = moment
  .months()
  .map((month, index) => {
    const date = moment().set({ month: index })
    const currentMonth = moment().month()
    const startDate = date.startOf('month').format('YYYY-MM-DD')
    const endDate = date.endOf('month').format('YYYY-MM-DD')
    return {
      startDate,
      endDate,
      label: month,
      current: currentMonth === index,
      disabled: !(index <= currentMonth),
    }
  })
  .reverse()

const CURRENT_DATE = new Date()

const MIN_DATE = new Date(moment().subtract(90, 'd').format())

const TABS = {
  month: 'Month',
  day: 'Day',
}

/**
 * @description common date or month selector component
 */
const DateSelector = ({
  selectedDate,
  handleDateChange,
  handleMonthChange,
}) => {
  const [showSelector, setShowSelector] = React.useState(false)
  const [tab, setTab] = React.useState(null)
  const selectorRef = React.useRef()

  const hideDateSelector = () => {
    setShowSelector(false)
    setTab(null)
  }

  const toggleDateSelector = () => {
    if (showSelector) {
      hideDateSelector()
    } else {
      setShowSelector((state) => !state)
    }
  }

  const onDateChange = (date) => {
    handleDateChange({
      date: {
        startDate: moment(date || CURRENT_DATE).format('YYYY-MM-DD'),
        endDate: moment(date || CURRENT_DATE).format('YYYY-MM-DD'),
        dateType: TABS.day.toLowerCase(),
      },
      tab: TABS.day,
    })

    hideDateSelector()
  }

  const onMonthSelect = (month) => {
    if (month?.disabled) return null
    let currentMonth = null
    if (!month) {
      currentMonth = MONTHS.find(({ current }) => current)
    }

    const selectedMonth = month || currentMonth

    handleMonthChange({
      date: {
        startDate: selectedMonth.startDate,
        endDate: selectedMonth.endDate,
        dateType: TABS.month.toLowerCase(),
      },
      label: selectedMonth.label,
      tab: TABS.month,
    })

    hideDateSelector()
  }

  const onOutSideComponentClick = React.useCallback((e) => {
    if (showSelector && !selectorRef.current?.contains(e.target)) {
      hideDateSelector()
    }
  })

  React.useEffect(() => {
    if (!selectedDate?.date) {
      onMonthSelect()
    }
  }, [selectedDate])

  React.useEffect(() => {
    document.addEventListener('click', onOutSideComponentClick)

    return () => {
      document.removeEventListener('click', onOutSideComponentClick)
    }
  }, [onOutSideComponentClick])

  return (
    <$Block position="relative">
      <$DateTitle onClick={toggleDateSelector}>
        {selectedDate?.date?.dateType
          ? selectedDate?.date?.dateType === 'day'
            ? 'Date - ' +
              moment(selectedDate?.date?.startDate).format('DD/MM/YYYY')
            : 'Month - ' + selectedDate?.label
          : moment(CURRENT_DATE).format('DD/MM/YYYY')}
      </$DateTitle>
      {showSelector && (
        <Tabs
          setTab={setTab}
          tab={tab || selectedDate?.tab || TABS.day}
          ref={selectorRef}
        >
          <div label={TABS.day}>
            <$DateBlock>
              <DatePicker
                selected={
                  selectedDate?.date?.dateType === 'day'
                    ? new Date(selectedDate?.date?.startDate || CURRENT_DATE)
                    : CURRENT_DATE
                }
                onChange={onDateChange}
                maxDate={CURRENT_DATE}
                minDate={MIN_DATE}
                allowSameDay
                autoFocus
                dateFormat="dd/MM/yyyy"
                inline
              />
              <$Disclaimer mt={`-${spacing.s}`}>
                <span>Select a particular date</span>
              </$Disclaimer>
            </$DateBlock>
          </div>
          <div label={TABS.month}>
            <MonthList
              selectedDate={selectedDate}
              onMonthSelect={onMonthSelect}
            />
            <$Disclaimer>
              <span>Select an entire month</span>
            </$Disclaimer>
          </div>
        </Tabs>
      )}
    </$Block>
  )
}

export default DateSelector

const Tabs = React.forwardRef((props, ref) => {
  const children = React.Children.toArray(props.children)

  return (
    <$TabsBlock ref={ref}>
      <$TabMenus>
        {children.map(({ props: { label } }) => (
          <Tab
            key={label}
            label={label}
            active={props.tab}
            clickHandler={props.setTab}
          />
        ))}
      </$TabMenus>
      {children.map((content, index) =>
        content.props.label === props.tab ? (
          <$TabContent key={index}>{content.props.children}</$TabContent>
        ) : null
      )}
    </$TabsBlock>
  )
})

const Tab = ({ clickHandler, label, active }) => {
  return (
    <$TabItem onClick={() => clickHandler(label)} active={active === label}>
      <SectionHeading
        heading={label}
        variant={'h4'}
        color={active === label ? 'primary' : 'grey'}
      />
    </$TabItem>
  )
}

const MonthList = ({ selectedDate, onMonthSelect }) => {
  const monthRef = React.useRef()

  React.useEffect(() => {
    if (monthRef.current) {
      monthRef.current.scrollIntoView({
        block: 'center',
        inline: 'start',
      })
    }
  }, [])

  return (
    <$MonthList>
      {MONTHS.map((month, index) => (
        <$MonthListItem
          key={index}
          onClick={() => onMonthSelect(month)}
          disabled={month.disabled}
          active={
            selectedDate?.date?.dateType === 'month'
              ? month.label === selectedDate?.label
              : month.current
          }
          ref={
            selectedDate?.date?.dateType === 'month'
              ? month.label === selectedDate?.label
                ? monthRef
                : null
              : month.current
              ? monthRef
              : null
          }
        >
          {month.label}
        </$MonthListItem>
      ))}
    </$MonthList>
  )
}

const $DateTitle = styled.span`
  display: block;
  width: 180px;
  padding: ${spacing.m};
  border: 1px solid #dbdbdb;
  border-radius: ${borderRadius.small};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  cursor: pointer;
`

const $TabsBlock = styled.div`
  position: absolute;
  width: 250px;
  z-index: 5;
  right: 0;
  border: 1px solid #dbdbdb;
  border-radius: ${borderRadius.small};
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  &:before {
    right: 19px;
    border: 11px solid transparent;
    border-bottom-color: #ddd;
  }
  &:after {
    right: 20px;
    border: 10px solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  }
`

const $TabMenus = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #eaeaea;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
`

const $TabItem = styled.li`
  padding: ${spacing.m};
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  &:not(:last-of-type) {
    margin-right: 2%;
  }
  cursor: ${({ active }) => (active ? 'auto' : 'pointer')};
  background-color: ${({ active, theme }) =>
    active ? '#e7eefb' : theme.colors.white};
  ${({ active }) =>
    active
      ? css`
          &:after {
            content: '';
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: ${({ theme }) => theme.colors.primary.default};
          }
        `
      : ''}
`

const $TabContent = styled.div`
  width: 100%;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  .react-datepicker-wrapper {
    display: block;
    input {
      width: 100%;
    }
  }
`

const $DateBlock = styled.div`
  height: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
`

const $MonthList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  height: 170px;
  overflow-y: auto;
`

const $MonthListItem = styled.li`
  padding: ${spacing.l} ${spacing.m};
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.6' : '1')};
  background-color: ${({ active, theme }) =>
    active ? '#e7eefb' : theme.colors.white};
`

const $Disclaimer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary.default};
  text-align: center;
  font-size: 12px;
  margin-top: ${({ mt }) => mt || 'unset'};
`

const $Block = styled.div`
  padding: ${({ padding }) => padding || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
  position: ${({ position }) => position || 'static'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
`
