import React from 'react'
import { Loader } from 'styledComponent/components/loader'

export function Skeleton(props) {
  const {
    count = 1,
    width,
    height,
    circle = false,
    style,
    background,
    loader,
  } = props
  let elements = []

  const loaderStyle = {
    ...style,
  }

  if (width !== null) loaderStyle.width = width

  if (height !== null) loaderStyle.height = height

  if (width !== null && height !== null && circle)
    loaderStyle.borderRadius = '50%'

  if (background) loaderStyle.backgroundColor = background

  for (let i = 0; i < count; i++) {
    elements.push(<Loader style={loaderStyle} loaderColor={loader} />)
  }

  return (
    <>
      {elements.length > 0 &&
        elements.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
    </>
  )
}
