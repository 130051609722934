import React from 'react'

import { CampaignPreview } from '../components/CampaignPreview'

export const SBBPreview = ({ form, hideSteps }) => (
  <CampaignPreview
    form={form}
    campaignType="Sell Below the Bill "
    hideSteps={hideSteps}
  />
)
