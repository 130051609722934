import styled from 'styled-components'

import { spacing } from 'design-system'

const $ProductList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: ${(props) => `calc(100vh - ${props.isEdit ? '350px' : '260px'})`};
  overflow-y: auto;
  scroll-behavior: smooth;
  /* scrollbars*/
  /* width */
  &::-webkit-scrollbar {
    box-shadow: inset 0 0 1px grey;
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
  }
`

const $RemoveIconBlock = styled.span`
  cursor: pointer;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`

const $ProductListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.l} ${spacing.m};
  border-bottom: 1px solid #f0f0f0;
  background-color: ${({ active, theme }) =>
    active ? '#EBF3FE' : theme.colors.white};
  &:hover {
    background-color: #ebf3fe;
  }
`

const $Icon = styled.span`
  cursor: pointer;
`

const $SelectContainer = styled.div`
  padding: ${spacing.l} ${spacing.m};
`

export {
  $ProductList,
  $RemoveIconBlock,
  $ProductListItem,
  $Icon,
  $SelectContainer,
}
