import { post, get, patch, deleteReq } from 'utils/axiosHandler'
import * as moment from 'moment'
import * as types from '../types/journey'
import { setCampaignLoader } from './autoEngageActions'

export const getJourneys = (data, options) => async (dispatch) => {
  try {
    const res = await post(
      '/journey-builder/journey/journey-list',
      data,
      options
    )
    dispatch({
      type: types.SET_JB_DATA,
      payload: { jbData: res.data },
    })
    dispatch(setLoadTable(false))
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_JB_DATA,
      payload: { jbData: {} },
    })
    dispatch(setLoadTable(false))
  }
}

export const getJourneyDetails = (id) => async (dispatch) => {
  dispatch(setLoadJourney(true))
  try {
    const res = await get(`/journey-builder/journey/${id}`)
    dispatch(setJBDetails(res.data))
    dispatch(setLoadJourney(false))
  } catch (error) {
    console.error(error)
    dispatch(setJBDetails({}))
    dispatch(setLoadJourney(false))
  }
}

export const getActions = () => async (dispatch) => {
  try {
    const res = await get(`/journey-builder/actions`)
    dispatch({
      type: types.SET_JB_ACTION_LIST,
      payload: { jbActions: res.data },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_JB_ACTION_LIST,
      payload: { jbActions: [] },
    })
  }
}

export const getFilters = () => async (dispatch) => {
  try {
    const res = await get(`/journey-builder/filters`)
    dispatch({
      type: types.SET_JB_FILTER_LIST,
      payload: { jbFilters: res.data },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_JB_FILTER_LIST,
      payload: { jbFilters: [] },
    })
  }
}

export const getEvents = () => async (dispatch) => {
  try {
    const res = await get(`/journey-builder/events`)
    dispatch({
      type: types.SET_JB_EVENT_LIST,
      payload: { jbEvents: res.data },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_JB_EVENT_LIST,
      payload: { jbEvents: [] },
    })
  }
}

export const deleteJourney = async (id, body) =>
  await deleteReq(`/journey-builder/journey/${id}`, body)

export const createJourney = async (body) =>
  await post(`/journey-builder/journey`, body)

export const updateJourney = async (id, body) =>
  await patch(`/journey-builder/journey/${id}`, body)

export const duplicateJourney = async (id, body) =>
  await post(`/journey-builder/journey/${id}/duplicate`, body)

// Journey Campaign
export const getJourneyCampaign = async (id) =>
  await get(`/auto-engage/campaigns/${id}`)

export const createJourneyCampaign = async (body) =>
  await post(`/auto-engage/campaigns/journey`, body)

export const updateJourneyCampaign = async (id, body) =>
  await patch(`/auto-engage/campaigns/journey/${id}`, body)

export const deleteJourneyCampaign = async (id) =>
  await deleteReq(`/auto-engage/campaigns/${id}?isJourneyCampaign=true`)

// Journey Products List
export const getJourneyProducts =
  (vendorId, brandId, options) => async (dispatch) => {
    try {
      const res = await get(
        `/journey-builder/journey/products-list/${vendorId}/${brandId}`,
        options
      )
      dispatch({
        type: types.SET_JB_PRODUCTS,
        payload: { jbProducts: res.data },
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.SET_JB_PRODUCTS,
        payload: { jbProducts: [] },
      })
    }
  }

export const filterToQuery = (filter) => async (dispatch) => {
  let filterQuery = {}
  if (filter.timeFrame?.isActive) {
    filterQuery = {
      ...filterQuery,
      startDate: moment(filter.timeFrame.value.start).format('YYYY-MM-DD'),
      endDate: moment(filter.timeFrame.value.end).format('YYYY-MM-DD'),
    }
  }
  if (filter.types?.isActive) {
    let types = filter.types.value
    filterQuery = { ...filterQuery, types }
  }
  if (filter.status?.isActive) {
    let status = filter.status.value
    filterQuery = { ...filterQuery, status }
  }
  await dispatch({
    type: types.SET_JB_FILTERS,
    payload: { filterData: filter, filterQuery },
  })
  return filterQuery
}

export const setLoadTable = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_JB_TABLE_LOADING,
    payload: { jbTableLoading: bool },
  })
}

export const setLoadJourney = (bool) => async (dispatch) => {
  dispatch({
    type: types.SET_JB_LOADING,
    payload: { jbLoading: bool },
  })
}

export const setJBDetails = (jbDetails) => async (dispatch) => {
  dispatch({
    type: types.SET_JB_DETAILS,
    payload: { jbDetails },
  })
}

export const getJourneyPriorities = (options) => async (dispatch) => {
  dispatch(setLoadJourney(true))
  try {
    const res = await get('/journey-builder/journey/priority', options)
    dispatch(setJourneyPriorities(res))
  } catch (error) {
    console.error(error)
    dispatch(setJourneyPriorities([]))
  } finally {
    dispatch(setLoadJourney(false))
  }
}

export const getJourneyStats = (journeyId) => async (dispatch) => {
  dispatch(setCampaignLoader(true))
  try {
    const res = await get(`/journey-builder/journey/stats/${journeyId}`)
    dispatch(setJourneyStats(res?.data))
  } catch (error) {
    console.error(error)
    dispatch(setJourneyStats({}))
  } finally {
    dispatch(setCampaignLoader(false))
  }
}

export const postJourneyPriorities = async (body) =>
  await post('/journey-builder/journey/priority', body)

export const setJourneyPriorities = (jbPriority) => async (dispatch) => {
  dispatch({
    type: types.SET_JB_PRIORITY,
    payload: { jbPriority },
  })
}

export const setJourneyStats = (jbStats) => async (dispatch) => {
  dispatch({
    type: types.SET_JB_STATS,
    payload: { jbStats },
  })
}

export const exportJourneyReport = async (journeyId, data) => {
  return await post(`/journey-builder/journey/${journeyId}/user-stats`, data)
}

export const getActiveJourneyCount = async (vendorId) => {
  try {
    const result = await get(
      `/journey-builder/journey/journey-count/${vendorId}`
    )
    return result
  } catch (e) {
    return false
  }
}
