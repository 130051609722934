import { Button, Checkbox, PopupContainer, Text } from 'components/Common'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { FlexBox } from 'styledComponent'

export const FilterPopup = ({
  isPopOpen,
  setIsPopOpen,
  isExport = false,
  setIsExport,
  getFilterInput,
  onSaveFilter,
  fils,
  resetFilters,
}) => {
  const [filter, setFilter] = useState(fils)

  useEffect(() => {
    setFilter(fils)
    if (!isPopOpen) {
      setIsExport(false)
    }
  }, [isPopOpen])

  const handleFilterActiveChange = (e, filterType) => {
    const copyFilter = cloneDeep(filter)
    copyFilter[filterType].isActive = e.target.checked
    setFilter(copyFilter)
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      submitButtonName={isExport ? 'Export' : 'Save'}
      extraButton={<ExtraButton resetFilters={resetFilters} />}
      width="800px"
      onSubmitClick={() => onSaveFilter(filter)}
      customContentStyle={{ overflow: 'visible' }}
      heading={
        <FilterHeader
          filterHeader={isExport ? 'Export Filters' : 'Filters'}
          filter={filter}
        />
      }
    >
      <$FilterWrapper>
        {Object.values(filter).map(
          (fil, index) =>
            !(!isExport && fil.type === 'emailList') && (
              <$FilterBlock key={index} active={filter?.[fil.type]?.isActive}>
                <$FilterItemLabel>
                  <Checkbox
                    id={fil?.type}
                    onCheckboxChange={(e) =>
                      handleFilterActiveChange(e, fil?.type)
                    }
                    isChecked={filter?.[fil.type]?.isActive}
                  >
                    {fil?.label}
                  </Checkbox>
                </$FilterItemLabel>
                <$FilterItem>
                  {getFilterInput({ fil, filter, setFilter })}
                </$FilterItem>
              </$FilterBlock>
            )
        )}
      </$FilterWrapper>
    </PopupContainer>
  )
}

const $FilterWrapper = styled.div`
  padding: 10px;
`

const $FilterBlock = styled(FlexBox)`
  padding: 10px 16px 10px 11px;
  margin: 10px 0;
  box-shadow: 0px 2px 30px #00000016;
  border-radius: 5px;
  border-left: 5px solid
    ${(props) => (props.active ? '#0D52C2' : 'transparent')};
  transition: border-left 0.2s ease-in-out;
  min-height: 70px;
  align-items: center;
`

const sharedStyle = `
 > div {
   padding: 0;
   margin: 0;
 }
`

const $FilterItemLabel = styled.div`
  ${sharedStyle}
  padding: 16px 0;
  width: 25%;
  border-right: 1px solid #f0f0f0;
`

const $FilterItem = styled.div`
  ${sharedStyle}
  padding: 0 25px;
  width: 75%;
  text-transform: capitalize;
`

export const ExtraButton = ({ resetFilters }) => (
  <Button variant="textualError" mr="8px" onClick={resetFilters}>
    RESET FILTERS
  </Button>
)

export const FilterHeader = ({ filterHeader, filter }) => {
  const { spacing } = useTheme()
  return (
    <FlexBox>
      <Text variant="p" weight="bold">
        {filterHeader}
      </Text>
      <Text type="primary" variant="p" weight="bold" marginLeft={spacing.m}>
        (
        {
          Object.values(filter)
            .map(({ isActive }) => isActive)
            .filter((f) => f === true).length
        }
        )
      </Text>
    </FlexBox>
  )
}
