import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isArray } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'
import { Text, useToasts } from 'components/Common'
import {
  getAssetsList,
  uploadFiles,
  setCampaignLoader,
} from 'redux/actions/autoEngageActions'

import { ComponentBox } from '../components'
import { RecentUploads } from '../components/RecentUploads'
import {
  BUILDER_INIT,
  GET_FILE_TYPE,
  SUPPORTED_FORMATS,
  REAL_IMG_DIMENSION,
  GET_ASSET_LIST_COUNT,
} from '../constants'
import {
  YoutubeComponent,
  RedirectComponent,
  CarouselComponent,
  GeneralComponent,
} from '../components/BuilderComponents'
import { ChooseCoupon } from '../components/BuilderComponents'
import { selectMedia, uploadMedia } from '../components/builderUtils'
import { AdditionalVideoOptions } from '../components/BuilderComponents'
import { useDebouncedEffect } from 'hooks'

const AdvertBuilder = (props) => {
  const { autoEngage, vendorId, storeUser, form, setForm } = props
  const {
    componentType,
    couponId,
    couponPosition,
    couponFontSize,
    couponFontColor,
    couponFontTypeface,
  } = form?.content?.body || BUILDER_INIT
  const assetsDetails = isArray(form?.content?.body?.assetsDetails)
    ? [...form?.content?.body?.assetsDetails]
    : []
  const [limit, setLimit] = useState(10)
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [couponDisplay, setCouponDisplay] = useState(
    assetsDetails?.every((asset) => asset?.couponVisibility) ? 'all' : 'chosen'
  )

  const { toast } = useToasts()
  const { spacing } = useTheme()

  const fileType = GET_FILE_TYPE(componentType)
  const src = assetsDetails?.[0]?.fileLocation || null

  useDebouncedEffect(
    () => {
      const carouselImage =
        assetsDetails?.[assetsDetails?.length - 1]?.fileLocation || null
      const myImage = document.getElementById(carouselImage)

      const myFunct = async () => {
        const realSize = await REAL_IMG_DIMENSION(myImage)
        if (assetsDetails?.length > 1 && size?.width !== 0) {
          if (
            size?.width !== realSize?.naturalWidth ||
            size?.height !== realSize?.naturalHeight
          ) {
            toast(
              'All the images should be of same width and height in a carousel',
              'error'
            )
            const assets = [...assetsDetails]
            assets.pop()
            setBody({ assetsDetails: assets })
          }
        } else if (realSize?.naturalWidth !== size?.width) {
          setSize({
            width: realSize?.naturalWidth,
            height: realSize?.naturalHeight,
          })
        }
      }
      myImage && myFunct()
    },
    [assetsDetails],
    100
  )

  const { assetsList, assetsCount } = GET_ASSET_LIST_COUNT(
    componentType,
    autoEngage
  )
  const supportedFormatArray = SUPPORTED_FORMATS(componentType)
  const acceptedFormats = supportedFormatArray
    .map((format) => `.${format}`)
    .join(', ')

  const setBody = (body) => {
    setForm({
      ...form,
      content: {
        body: {
          ...form?.content?.body,
          componentType: form?.content?.body?.componentType || 'image',
          ...cloneDeep(body),
        },
      },
    })
  }

  const fetchFiles = (lim = 10) =>
    props.getAssetsList({
      vendorId,
      storeUser,
      skip: 0,
      limit: lim,
      fileType,
    })

  useEffect(() => {
    vendorId && fileType !== 'youtube' && fetchFiles(limit)
  }, [vendorId, fileType, limit])

  const uploadFile = async (e) =>
    await uploadMedia(
      e,
      props.setCampaignLoader,
      supportedFormatArray,
      vendorId,
      storeUser,
      setMedia,
      fetchFiles,
      limit,
      uploadFiles,
      toast,
      'adBelowBill'
    )

  const setCarousel = (arr) => setBody({ assetsDetails: arr })

  const setMedia = ({ fileLocation, _id, key, name }) =>
    selectMedia(
      { fileLocation, assetId: _id, key, name },
      componentType,
      assetsDetails,
      couponId,
      setBody,
      toast
    )

  const setPosition = (pos) => setBody({ couponPosition: pos.value })

  const onCouponSelect = (couponId) => {
    const asset = [...assetsDetails]
    asset.map((ast) => (ast.couponVisibility = true))
    setBody({
      couponId: couponId?.value,
      couponPosition: 'bottomRight',
      assetsDetails: asset,
      couponFontSize: '12',
      couponFontColor: '#000',
      couponFontTypeface: 'Roboto',
    })
  }

  /**
   *
   * @param {string} value - redirect url from user or survey url
   * @param {number} i
   */

  const handleRedirect = (value, i, isSurvey, isCustomLinkType) => {
    const asset = [...assetsDetails]
    asset.map((ast, ind) => {
      if (i === ind) {
        if (isSurvey) {
          ast.surveyId = value
          if (ast?.redirectUrl) delete ast.redirectUrl
          if (ast?.customLinkType) delete ast.customLinkType
        } else if (isCustomLinkType) {
          ast.customLinkType = value
          if (ast?.redirectUrl) delete ast.redirectUrl
          if (ast?.surveyId) delete ast.surveyId
        } else {
          ast.redirectUrl = value
          if (ast?.customLinkType) delete ast.customLinkType
          if (ast?.surveyId) delete ast.surveyId
        }
      }
      return ast
    })
    setBody({ assetsDetails: asset })
  }

  const onComponentChange = (componentType) => {
    setBody({
      componentType,
      assetsDetails: [],
    })
  }

  const setCouponVisibility = (i) => {
    const asset = [...assetsDetails]
    asset[i].couponVisibility = !asset[i].couponVisibility
    setBody({ assetsDetails: asset })
  }

  const onCouponDisplayChange = (val) => {
    setCouponDisplay(val.value)
    const asset = [...assetsDetails]
    asset.map((ast) => (ast.couponVisibility = val.value === 'all'))
    setBody({ assetsDetails: asset })
  }

  const handleVideoOptionChange = (value, property) => {
    setBody({
      [property]: value,
    })
  }

  const getAdditonalVideoOptions = () => (
    <AdditionalVideoOptions
      setBody={setBody}
      form={form}
      componentType={componentType}
      onChange={handleVideoOptionChange}
    />
  )

  const setCouponProperty = (providedValue, key) => {
    setBody({ [key]: providedValue?.value || providedValue })
  }

  return (
    <>
      <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
        Advert Builder
      </Text>
      <Text variant="medium" type="grey" weight="bold" mb={spacing.s}>
        Add or link media &nbsp;
        <small>
          {' '}
          (Media Dimensions Max-Width <b>375px</b> * Max-Height <b>750px</b>){' '}
        </small>
      </Text>
      <FlexBox gap={spacing.xl} justify="space-between" align="flex-start">
        {componentType === 'youtube' ? (
          <FlexBox expand direction="column" align="center">
            <YoutubeComponent
              value={
                assetsDetails[0]?.fileLocation || assetsDetails[0]?.key || ''
              }
              setMedia={setMedia}
            />
            {getAdditonalVideoOptions()}
          </FlexBox>
        ) : (
          <Div width="100%">
            <Div width="100%">
              {componentType === 'carousel' ? (
                <CarouselComponent
                  assetsDetails={assetsDetails}
                  setCarousel={setCarousel}
                  uploadFile={uploadFile}
                  acceptedFormats={acceptedFormats}
                  couponId={couponId}
                  couponPosition={couponPosition}
                  couponFontColor={couponFontColor}
                  couponFontSize={couponFontSize}
                  couponFontTypeface={couponFontTypeface}
                />
              ) : (
                <GeneralComponent
                  fileType={fileType}
                  acceptedFormats={acceptedFormats}
                  uploadFile={uploadFile}
                  src={src}
                  couponId={couponId}
                  couponPosition={couponPosition}
                  couponFontColor={couponFontColor}
                  couponFontSize={couponFontSize}
                  couponFontTypeface={couponFontTypeface}
                />
              )}
            </Div>
            {getAdditonalVideoOptions()}
            <FlexBox m wrap="wrap" justify="space-between" mb={spacing.m}>
              <RedirectComponent
                componentType={componentType}
                assetsDetails={assetsDetails}
                handleRedirect={handleRedirect}
                toast={toast}
                body={form?.content?.body}
                setBody={setBody}
              />
            </FlexBox>
            <ChooseCoupon
              spacing={spacing}
              couponId={couponId}
              onCouponSelect={onCouponSelect}
              assetsDetails={assetsDetails}
              couponPosition={couponPosition}
              setCouponProperty={setCouponProperty}
              couponFontSize={couponFontSize}
              couponFontColor={couponFontColor}
              couponFontTypeface={couponFontTypeface}
              componentType={componentType}
              onCouponDisplayChange={onCouponDisplayChange}
              couponDisplay={couponDisplay}
              setCouponVisibility={setCouponVisibility}
            />
          </Div>
        )}
        <ComponentBox
          handleClick={onComponentChange}
          components={['image', 'carousel', 'gif', 'video', 'youtube']}
          activeComponent={componentType}
        />
      </FlexBox>
      <hr />
      {componentType !== 'youtube' && (
        <RecentUploads
          limit={limit}
          setLimit={setLimit}
          assetsCount={assetsCount}
          assetsList={assetsList}
          setMedia={setMedia}
          mediaType={fileType === 'video' ? 'video' : 'image'}
          activeArray={assetsDetails?.map((asset) => asset.fileLocation)}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  autoEngage: state.autoEngage,
  storeUser: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, { getAssetsList, setCampaignLoader })(
  AdvertBuilder
)
