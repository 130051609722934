import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'

import { Table, TableHead, FlexBox } from 'styledComponent'
import { Skeleton, Text, PopupContainer, ActionCard } from 'components/Common'

import {
  GET_SEL_VALUE,
  UPLOADED_DATA_HEADER,
  UPLOADED_FILES_FROM,
  UPLOAD_TYPES,
} from '../constants'
import { $AETableContainer, $BlueBox } from '../AutoEngagement.styled'
import { TabRow } from '../campaigns/AETable'
import CancelPopup from '../components/CancelPopup'
import { UploadBox } from '../components/MediaUpload'

const UPLOAD_ROW = (file, onActionClick) => [
  <Text type="table" m="auto" width="200px" ellipsis align="center">
    {file?.name || '-'}
  </Text>,
  GET_SEL_VALUE(file?.fileType, UPLOAD_TYPES)?.label || '-',
  `${file?.createdBy?.email || '-'} (${
    GET_SEL_VALUE(file?.uploadedFrom, UPLOADED_FILES_FROM)?.label || '-'
  })`,
  file?.createdDate ? moment(file.createdDate).format('DD MMM YYYY') : '-',
  <ActionCard
    onIconClick={(action) => onActionClick(action, file._id, file)}
    components={['view', 'download', 'delete']}
    item="Uploaded File"
  />,
]

export const UploadTable = ({
  tableLoading,
  onPaginationChange,
  fileList,
  pageCount,
  setAction,
  pageNumber,
}) => {
  const theme = useTheme()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [file, setFile] = useState({ id: '', action: '' })

  const onActionClick = (action, id, file) => {
    switch (action) {
      case 'download':
        return setAction({ id, action, ...file })

      case 'view':
      case 'delete':
        setIsPopOpen(true)
        return setFile({ id, action, ...file })
    }
  }

  return (
    <$AETableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={UPLOADED_DATA_HEADER} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                cells={[...new Array(UPLOADED_DATA_HEADER.length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : fileList?.length > 0 ? (
            fileList?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={UPLOAD_ROW(item, onActionClick)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={UPLOADED_DATA_HEADER.length}
              cells={['No Data Found']}
            />
          )}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={pageCount || 1}
          onPageChange={onPaginationChange}
          containerClassName="aePagination"
          activeClassName="pagination_activePage"
          forcePage={pageNumber || 0}
        />
      )}
      {file?.action === 'delete' ? (
        <CancelPopup
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          title="Are you sure you want to delete the file?"
          onOkClick={() => setAction(file)}
        />
      ) : (
        <PopupContainer
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          heading="View Uploaded File"
          cancelButtonName="Close"
        >
          <FlexBox direction="row-reverse">
            <$BlueBox style={{ flexBasis: '50%' }}>
              <UploadBox
                hideBorder
                uploadWidth="100%"
                src={file?.fileLocation}
                uploadHeight={file?.fileLocation ? '260px' : '150px'}
                fileType={file?.fileType}
                style={{ flexBasis: '50%' }}
              />
            </$BlueBox>
            <div style={{ flexBasis: '50%' }}>
              <Text variant="medium" width="100px" type="grey">
                Name :
              </Text>
              <Text
                variant="medium"
                ellipsis
                type="main"
                weight="bold"
                mb={theme.spacing.l}
              >
                {file?.name || '-'}
              </Text>
              <Text variant="medium" ellipsis width="100px" type="grey">
                Description :
              </Text>
              <Text
                variant="medium"
                type="main"
                weight="bold"
                mb={theme.spacing.l}
              >
                {file?.description || '-'}
              </Text>
              <Text variant="medium" width="100px" type="grey">
                Created Date :
              </Text>
              <Text
                variant="medium"
                type="main"
                weight="bold"
                mb={theme.spacing.l}
              >
                {file?.createdDate
                  ? moment(file.createdDate).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </div>
          </FlexBox>
        </PopupContainer>
      )}
    </$AETableContainer>
  )
}
