import styled from 'styled-components'
import { spacing, borderRadius } from 'design-system'
import { FlexBox, TableCell } from 'styledComponent'

export const $Upload = styled.div`
  padding: ${spacing.l} ${spacing.xl};
  width: 100%;
  min-height: 100%;
  background-color: #fff;
`

export const $InformationBox = styled.div`
  width: 400px;
  box-sizing: border-box;
  background-color: #fff;

  div {
    height: 100%;
    background-color: #f2f5f8;
    border-radius: 6px;
    font-size: 14px;
    margin: ${spacing.l} 0px;
    padding: ${spacing.xl} ${spacing.xl};
  }

  ol {
    padding: ${spacing.l} 0;
    counter-reset: list;
    li {
      color: #33475b;
      margin-bottom: ${spacing.m};
      list-style: none;
      position: relative;
      &:before {
        counter-increment: list;
        content: counter(list) ') ';
      }
    }
  }
`

export const $PointerTableCell = styled(TableCell)`
  cursor: pointer;
`
export const $MoreOptions = styled.div`
  position: relative;
  width: 80px;
  margin: auto;
  > svg {
    width: 30px;
    height: 40px;
    padding: 0 ${spacing.sm};
    border-radius: 2px;
    cursor: pointer;
    transform: rotate(90deg);
  }
  div {
    position: absolute;
    width: 220px;
    left: -196px;
    top: -8px;
    padding: ${spacing.ml};
    background-color: ${(props) => props.theme.colors.white};
    display: none;
    flex-direction: column;
    text-align: left;
    border-radius: ${(props) => props.theme.borderRadius.default};
    box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 8px;
    span {
      cursor: pointer;
      padding: ${spacing.sm};
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey};
      svg {
        width: 25px;
        height: 25px;
        margin-right: ${spacing.m};
        fill: none;
      }
      :hover {
        background-color: ${(props) => props.theme.colors.solitude};
        svg .a {
          fill: none;
        }
      }
    }
  }
  :hover {
    > svg {
      color: ${(props) => props.theme.colors.white};
      box-shadow: rgba(0, 0, 0, 0.35) 2px -2px 4px;
      background-color: ${(props) => props.theme.colors.white};
    }
    div {
      display: flex;
    }
  }
`
