import React, { Component } from "react";

class StoreCard extends Component {
  constructor(props) {
    super(props);
    this.onClickBox = this.onClickBox.bind(this);
  }
  onClickBox() {
    this.props.onClick && this.props.onClick(this.props.number);
  }

  render() {
    return (
      <div
        className="profileInnerBox shadow p-1 mb-1 bg-white rounded"
        onClick={this.onClickBox}
      >
        <div className="storeName">{this.props.storeName}</div>
        <div className="storeCompany">{this.props.storeCompany}</div>
        <div className="storeAddress">{this.props.storeAddress}</div>
      </div>
    );
  }
}

export default StoreCard;
