import React, { useState } from 'react'

import {
  DesktopIcon,
  MobileIcon,
  PreviewExitIcon,
} from 'assets/icons/emailEditorAssets'
import {
  $FlexBox,
  $EditorHeader,
  $MainEditor,
  $EditorContainer,
} from './Editor.styled'
import { spacing } from 'design-system'
import { Slider } from 'components/Common/Slider'

export const EditorPreview = ({ onPreview, htmlView }) => {
  const [isMobileView, setIsMobileView] = useState(false)
  return (
    <div id="myEditor">
      <$EditorHeader>
        <$FlexBox padding="0 0 0 128px" justify="flex-end" gap={spacing.ml}>
          <$FlexBox style={{ flex: 1 }}>
            <DesktopIcon
              title="Desktop View"
              onClick={() => setIsMobileView(false)}
            />
            <Slider
              ml={spacing.l}
              onChange={() => setIsMobileView(!isMobileView)}
              isChecked={isMobileView}
            />
            <MobileIcon
              title="Mobile View"
              onClick={() => setIsMobileView(true)}
            />
          </$FlexBox>
          <PreviewExitIcon title="Exit Preview" onClick={onPreview} />
        </$FlexBox>
      </$EditorHeader>
      <$EditorContainer>
        <$MainEditor width={isMobileView ? '375px' : '100%'} id="mainEditor">
          <div dangerouslySetInnerHTML={{ __html: htmlView }} />
        </$MainEditor>
      </$EditorContainer>
    </div>
  )
}
