import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Chart, Geom, Axis, Tooltip } from 'bizcharts'

import DateRangeSelector from './DateRangeSelector'
import { getAvgAmount } from '../../../../redux/actions/billingCharts'
import { formatDate2 } from '../../../../utils/getDateFormat'
import ChartHeader from './ChartHeader'
import { Text } from '../../../../styledComponent'
import '../../../../css/content/billingCharts.scss'

class AvgBillingChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCalendar: false,
    }
  }

  handleDateRangeChange = (values) => {
    this.setState({ showCalendar: false })

    this.props.handleDateChange(values)

    const startDate = values.start.startOf('day').toDate()
    const endDate = values.end.endOf('day').toDate()

    this.props.getAvgAmount({
      startDate,
      endDate,
      type: 'total_sales_average',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  showCalendar = () => {
    this.setState((state) => ({
      ...state,
      showCalendar: !state.showCalendar,
    }))
  }

  componentDidMount() {
    const date = this.props.selectedDate

    this.props.handleDateChange(date)

    this.props.getAvgAmount({
      startDate: date.start?.toDate(),
      endDate: date.end?.toDate(),
      type: 'total_sales_average',
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  render() {
    if (_.has(this.props.billingCharts, 'avgBilling')) {
      var data = this.props.billingCharts.avgBilling
    } else {
      var data = []
    }

    const cols = {
      date: {
        alias: 'date',
      },
      average: {
        alias: 'average',
      },
    }

    return (
      <div className="avgBillingChart chartsMain">
        <ChartHeader title="Average Billing Amount" />
        <hr />
        <DateRangeSelector
          value={this.props.selectedDate}
          onDateChange={this.handleDateRangeChange}
          showCalendar={this.state.showCalendar}
          toggleCalender={this.showCalendar}
        />
        <Chart
          height={400}
          data={data}
          padding={[100, 50, 100, 100]}
          scale={cols}
          forceFit
        >
          <Axis
            name="average"
            label={{
              formatter: (val) => `${val} Rs.`,
            }}
          />
          <Axis
            name="date"
            label={{
              formatter: (val) => formatDate2(val),
            }}
          />

          <Tooltip
            crosshairs={{
              type: 'y',
            }}
          />
          <Geom type="line" position="date*average" size={2} />
          <Geom
            type="point"
            position="date*average"
            size={4}
            shape={'circle'}
            style={{
              stroke: '#fff',
              lineWidth: 1,
            }}
          />
        </Chart>
        <div className="noData">
          {_.isEmpty(data) ? (
            <Text text-align={'center'}>
              No data to display between selected dates
            </Text>
          ) : (
            <Text text-align={'center'}>
              Average Billing Amount:{' '}
              {parseFloat(_.meanBy(data, 'average').toFixed(2))}
            </Text>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  billingCharts: state.billingCharts,
  errors: state.errors,
})

export default connect(mapStateToProps, { getAvgAmount })(AvgBillingChart)
