import React from 'react'

import { Text } from 'components/Common/Text'
import Drawer from 'design-system/components/drawer'
import { spacing } from 'design-system'
import { BarChart } from 'components/Common'
import { useFetch, useSelectStore } from 'hooks'
import { get, post } from 'utils/axiosHandler'
import { Skeleton } from 'components/Common/Skeleton'
import { NoDataFound } from 'components/Common/EmptyState'
import HeatMap from 'components/Common/HeatMap'
import { SectionHeading } from 'components/Common/SectionHeading'

import ProductIcon from '../../components/ProductIcon'
import ProductRank from '../../components/ProductRank'
import CombinationChart from '../../components/CombinationChart'
import ProductTag from '../../components/ProductTag'
import { $Block, $FlexBox, $FlexItem } from '../../ProductAnalytics.styled'
import { $FlexList, $FlexListItem } from '../AllProducts.styled'

import { $DetailsBlock, $List, $ListItem } from './ProductDetails.styled'

/**
 * @description single product details component
 * @param {Boolean} show control display of component
 * @param {String} productId product Id for which details needs to be showed
 * @param {Function} onGoBack go back handler
 */
const ProductDetails = ({ show, productId, onGoBack }) => {
  const { data: selectedDate } = useSelectStore('productAnalytics.selectedDate')
  const { data: storeIds } = useSelectStore('productAnalytics.selectedStores')
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const {
    data: { stores },
  } = useSelectStore('stores')

  const fetchAction = async () => {
    if (!productId) return null
    try {
      const body = {
        ...(selectedDate?.date || {}),
        brandId: selectedBrand?.value,
        vendorId: selectedVendor?.value,
        storeIds,
      }

      const productDetails = await post(
        `/product-management/products/${productId}`,
        body
      )

      const storesStats = await _formatForHeatMapData(productDetails?.data)

      if (productDetails?.data) {
        productDetails.data.storesStats = storesStats
      }

      return productDetails?.data || null
    } catch (error) {
      console.log(error)
    }
  }

  const { data, loading } = useFetch(fetchAction, [
    productId,
    selectedVendor,
    selectedBrand,
    selectedDate,
  ])

  const _getGenderRatio = () => {
    const genderRatio = data?.genderRatio
    if (genderRatio) {
      const total = genderRatio?.male + genderRatio?.female
      const male = Number(((genderRatio?.male / total) * 100).toFixed(2))
      const female = Number(((genderRatio?.female / total) * 100).toFixed(2))
      return {
        male,
        female,
      }
    }
    return {}
  }

  const _formatForBarChart = () => {
    const ageRange = data?.ageRange
    if (ageRange) {
      const hasValues = Object.values(ageRange).some((value) => value)

      if (!hasValues) {
        return null
      }

      const formattedData = Object.keys(ageRange).map((key) => ({
        key,
        value: ageRange[key],
      }))
      return formattedData
    }
    return null
  }

  const _formatForHeatMapData = async (productData) => {
    if (!productData || !productData?.storesStats?.length) {
      return []
    }

    const storeStats = productData.storesStats
      .map(({ storeId, qtSold }) => {
        if (stores[storeId]) {
          return {
            qtSold: Number(qtSold || 0),
            areaCode: stores[storeId].pincode,
          }
        } else {
          return null
        }
      })
      .filter((value) => value && value.areaCode)
      .reduce((acc, curr) => {
        const duplicateAreaCodeIndex = acc.findIndex(
          (stats) => stats?.areaCode === curr.areaCode
        )
        if (duplicateAreaCodeIndex > -1) {
          acc[duplicateAreaCodeIndex].qtSold += curr.qtSold
        } else {
          acc.push(curr)
        }

        return acc
      }, [])

    if (!storeStats?.length) {
      return []
    }

    try {
      const areaCodes = storeStats.map(({ areaCode }) => areaCode).join(',')

      const coordinatesRes = await get(
        `/location/geo/coordinates?areaCodes=${areaCodes}`
      )

      const coordinates = coordinatesRes?.data || []

      if (coordinates?.length) {
        const storeCoordinateStats = coordinates
          .map((coords) => {
            const currentStoreStats = storeStats.find(
              ({ areaCode }) => areaCode === coords.areaCode
            )
            if (currentStoreStats) {
              const storeCoordinates = [coords.lng, coords.lat]
              return {
                coordinates: storeCoordinates,
                label: `Qt Sold: ${currentStoreStats.qtSold?.toFixed(2)}`,
              }
            }
          })
          .filter((value) => value)

        return storeCoordinateStats
      }

      return []
    } catch (error) {
      console.error('AdvancedMode -> error', error)
    }
  }

  const genderRatio = _getGenderRatio()
  const ageRatioChartData = _formatForBarChart()

  return (
    <Drawer
      open={show}
      onClose={onGoBack}
      position="absolute"
      pl="0"
      bgColor="#fff"
    >
      <$DetailsBlock>
        {!loading && !data ? (
          <NoDataFound />
        ) : (
          <$FlexBox justify="space-between">
            <$FlexItem flexBasis="65%" borderRight>
              <$FlexBox
                justify="space-between"
                padding={`0 0 ${spacing.m}`}
                borderBottom="1px solid #E8E8E8"
              >
                <$FlexItem flexBasis="70%">
                  <$FlexBox>
                    <$FlexItem flexBasis="60px">
                      {loading ? (
                        <Skeleton count={1} height="60px" />
                      ) : (
                        <ProductIcon
                          productTitle={data.productName}
                          bgColor={'#1EBCD1'}
                          color={'#fff'}
                          variant="medium"
                          width="60px"
                          height="60px"
                          rounded
                        />
                      )}
                    </$FlexItem>
                    <$FlexItem padding={`0 0 0 ${spacing.l}`}>
                      {loading ? (
                        <Skeleton count={4} height="20px" />
                      ) : (
                        <>
                          <Text variant="h3" weight="semi-bold">
                            {data.productName}
                          </Text>
                          <$List>
                            <$ListItem>
                              <$FlexItem flexBasis="30%">
                                <Text variant="p" type="grey">
                                  Product ID:
                                </Text>
                              </$FlexItem>
                              <$FlexItem flexBasis="70%">
                                <Text variant="p">{data.productCode}</Text>
                              </$FlexItem>
                            </$ListItem>
                            <$ListItem>
                              <$FlexItem flexBasis="30%">
                                <Text variant="p" type="grey">
                                  Tax Group:
                                </Text>
                              </$FlexItem>
                              <$FlexItem flexBasis="70%">
                                <Text variant="p">{data.tax}%</Text>
                              </$FlexItem>
                            </$ListItem>
                          </$List>
                        </>
                      )}
                      {!loading && (
                        <$FlexList wrap="true">
                          <$FlexListItem
                            flexBasis="30%"
                            ml="0"
                            borderRight="1px solid #E8E8E8"
                          >
                            <$FlexBox>
                              <$FlexItem>
                                <Text variant="p" type="grey">
                                  Category
                                </Text>
                              </$FlexItem>
                              <$FlexItem>
                                <Text>{data.categoryDetails?.name}</Text>
                              </$FlexItem>
                            </$FlexBox>
                          </$FlexListItem>
                          <$FlexListItem flexBasis="60%" ml={spacing.l}>
                            <$FlexBox wrap="wrap" align="center">
                              <$FlexItem flexBasis="50px">
                                <Text variant="p" type="grey">
                                  Tags:
                                </Text>
                              </$FlexItem>
                              <$FlexItem flexBasis="80%">
                                <$FlexBox wrap="wrap">
                                  {data?.tags?.map((tag, index) => (
                                    <ProductTag
                                      key={index}
                                      margin={`0 ${spacing.m} 0 0`}
                                    >
                                      {tag}
                                    </ProductTag>
                                  ))}
                                </$FlexBox>
                              </$FlexItem>
                            </$FlexBox>
                          </$FlexListItem>
                        </$FlexList>
                      )}
                    </$FlexItem>
                  </$FlexBox>
                </$FlexItem>
                {!loading && data?.rank ? (
                  <$FlexItem flexBasis="20%">
                    <ProductRank rank={data.rank} margin="0 auto" />
                    <Text variant="tiny" align="center" type="grey">
                      Product Rank
                    </Text>
                  </$FlexItem>
                ) : null}
              </$FlexBox>
              <$FlexBox>
                <$FlexItem flexBasis="50%" borderRight padding={spacing.xl}>
                  {loading ? (
                    <Skeleton count={6} height="20px" />
                  ) : (
                    <$List width="100%">
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Display Name
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">
                            {data.displayNames?.join(', ')}
                          </Text>
                        </$FlexItem>
                      </$ListItem>
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Product Code
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">{data.productCode || ''}</Text>
                        </$FlexItem>
                      </$ListItem>
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Unit of Measurement
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">{data.uamDetails?.value}</Text>
                        </$FlexItem>
                      </$ListItem>
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Units Sold
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">
                            {data.unitsSold ? data.unitsSold?.toFixed(2) : 0}
                          </Text>
                        </$FlexItem>
                      </$ListItem>
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Appearance in bill
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">
                            {(data.appearanceInBill || 0).toFixed(2)}%
                          </Text>
                        </$FlexItem>
                      </$ListItem>
                      <$ListItem>
                        <$FlexItem flexBasis="40%">
                          <Text variant="p" type="grey">
                            Reordered
                          </Text>
                        </$FlexItem>
                        <$FlexItem flexBasis="60%">
                          <Text variant="p">
                            {(data.reordered || 0).toFixed(2)}%
                          </Text>
                        </$FlexItem>
                      </$ListItem>
                    </$List>
                  )}
                </$FlexItem>
                <$FlexItem flexBasis="50%" padding={spacing.xl}>
                  {loading ? (
                    <>
                      <Skeleton count={1} height="20px" />
                      <Skeleton count={1} height="50px" />
                      <Skeleton count={1} height="20px" />
                      <Skeleton count={1} height="200px" />
                    </>
                  ) : (
                    <>
                      <Text variant="p" type="grey">
                        Gender Consumption:
                      </Text>
                      {!genderRatio?.male && !genderRatio?.female ? (
                        <NoDataFound height="50px" />
                      ) : (
                        <CombinationChart
                          startLabel={`Male (${genderRatio?.male || 0}%)`}
                          endLabel={`Female (${genderRatio?.female || 0}%)`}
                          startValue={`${genderRatio?.male || 0}%`}
                          endValue={`${genderRatio?.female || 0}%`}
                          padding={`0 0 ${spacing.l}`}
                        />
                      )}

                      <Text variant="p" type="grey">
                        Age Ratio:
                      </Text>
                      {ageRatioChartData?.length ? (
                        <BarChart
                          data={{ data: ageRatioChartData }}
                          barWidth={
                            ageRatioChartData?.length <= 2 ? '20%' : '30%'
                          }
                          height={300}
                        />
                      ) : (
                        <NoDataFound height="100px" />
                      )}
                    </>
                  )}
                </$FlexItem>
              </$FlexBox>
            </$FlexItem>
            <$FlexItem flexBasis={'35%'}>
              <$Block padding={`0 ${spacing.l}`}>
                <$Block padding={`0 0 ${spacing.l}`}>
                  <SectionHeading
                    heading="Heat Map"
                    variant="p"
                    color="main"
                    weight="bold"
                  />
                </$Block>
                {loading ? (
                  <Skeleton count={1} height={'500px'} />
                ) : data?.storesStats?.length ? (
                  <HeatMap markers={data?.storesStats} height={'600px'} />
                ) : (
                  <NoDataFound />
                )}
              </$Block>
            </$FlexItem>
          </$FlexBox>
        )}
      </$DetailsBlock>
    </Drawer>
  )
}

export default ProductDetails
