const STATE_DATA = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman & Diu',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu & Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'New Delhi',
  'Odisha',
  'Punjab',
  'Puducherry',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
]

const stateOptions = STATE_DATA.map((state) => {
  return {
    label: state.toUpperCase(),
    value: state.toLowerCase(),
  }
})

const initialFilter = {
  age: {
    id: 1,
    type: 'age',
    label: 'Age',
    isActive: false,
    inputType: 'range',
    value: {
      min: '',
      max: '',
    },
  },
  gender: {
    id: 2,
    type: 'gender',
    label: 'Gender',
    isActive: false,
    inputType: 'checkBox',
    value: {
      male: false,
      female: false,
      other: false,
    },
  },
  relationship: {
    id: 3,
    type: 'relationship',
    label: 'Relationship',
    isActive: false,
    inputType: 'checkBox',
    value: {
      married: false,
      unmarried: false,
    },
  },
  states: {
    id: 4,
    type: 'states',
    label: 'States',
    isActive: false,
    inputType: 'multiSelect',
    value: [],
    options: stateOptions,
  },
  avgSpend: {
    id: 5,
    type: 'avgSpend',
    label: 'Avg Spend',
    isActive: false,
    inputType: 'range',
    value: {
      min: '',
      max: '',
    },
  },
  totalSpend: {
    id: 6,
    type: 'totalSpend',
    label: 'Total Spend',
    isActive: false,
    inputType: 'range',
    value: {
      min: '',
      max: '',
    },
  },
  totalVisit: {
    id: 7,
    type: 'totalVisit',
    label: 'Total Visit',
    isActive: false,
    inputType: 'range',
    value: {
      min: '',
      max: '',
    },
  },
  emailList: {
    id: 8,
    type: 'emailList',
    label: 'Email List',
    isActive: false,
    inputType: 'emailList',
    value: [],
  },
}

export { initialFilter, stateOptions }
