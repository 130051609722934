import * as types from 'redux/types/crm'

export const filterToQuery =
  (filter, disableDispatch = false) =>
  async (dispatch, getState) => {
    const search = getState()?.crm?.filterQuery?.search || ''
    let filterQuery = { search }

    if (filter.age?.isActive) {
      filterQuery = {
        ...filterQuery,
        ageRange: [filter.age.value.min, filter.age.value.max],
      }
    }

    if (filter.avgSpend?.isActive) {
      filterQuery = {
        ...filterQuery,
        aSp: [filter.avgSpend.value.min, filter.avgSpend.value.max],
      }
    }

    if (filter.totalSpend?.isActive) {
      filterQuery = {
        ...filterQuery,
        tSp: [filter.totalSpend.value.min, filter.totalSpend.value.max],
      }
    }

    if (filter.totalVisit?.isActive) {
      filterQuery = {
        ...filterQuery,
        vCnt: [filter.totalVisit.value.min, filter.totalVisit.value.max],
      }
    }

    if (filter.gender?.isActive) {
      let gen = []
      filter.gender.value.male && gen.push('male')
      filter.gender.value.female && gen.push('female')
      filter.gender.value.other && gen.push('other')
      filterQuery = { ...filterQuery, gender: gen }
    }
    if (filter.relationship?.isActive) {
      let marry = []
      filter.relationship.value.married && marry.push('married')
      filter.relationship.value.unmarried && marry.push('unmarried')
      filter.relationship.value.single && marry.push('single')
      filterQuery = { ...filterQuery, married: marry }
    }
    if (filter.states?.isActive) {
      filterQuery = {
        ...filterQuery,
        state: filter.states.value.map((st) => st.value),
      }
    }
    if (filter.emailList?.isActive && filter.emailList?.value?.length) {
      filterQuery = {
        ...filterQuery,
        emailList: filter.emailList?.value || [],
      }
    }

    if (filter.search) {
      filterQuery = {
        ...filterQuery,
        search: filter.search,
      }
    }
    if (!filter.search) {
      delete filterQuery.search
    }

    if (!disableDispatch) {
      await dispatch({
        type: types.SET_CRM_FILTERS,
        payload: { filterData: filter, filterQuery },
      })
    }

    return filterQuery
  }
