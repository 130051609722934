import DeleteSVG from 'assets/icons/delete-red.svg'
import { CHECK_IF } from '../autoEngagement/constants'

export const CYTO_INIT = {
  boxSelectionEnabled: false,
  autounselectify: true,
  wheelSensitivity: 0.3,
  userZoomingEnabled: false,
  style: [
    {
      selector: 'node',
      style: {
        height: 'data(nodeHeight)',
        width: 220,
        shape: 'round-rectangle',
        'background-fit': 'cover',
        'border-color': '#000',
        'background-color': '#fff',
        'border-width': 1,
        'border-radius': 10,
        'border-opacity': 0.5,
        'text-valign': 'center',
        'font-size': '16px',
        'text-decoration': 'underline',
      },
    },
    {
      selector: 'edge',
      style: {
        width: 2,
        'target-arrow-shape': 'vee',
        'line-color': '#ffaaaa',
        'target-arrow-color': '#ffaaaa',
        'curve-style': 'bezier',
        content: 'data(name)',
      },
    },
    {
      selector: '.start',
      style: {
        'border-color': '#915CEE',
        'background-fill': 'linear-gradient',
        'background-gradient-stop-colors': '#915CEE #915CEE white white',
        'background-gradient-stop-positions': '0% 13% 13% 100%',
      },
    },
    {
      selector: '.customer',
      style: {
        'border-color': '#53DD86',
        'background-fill': 'linear-gradient',
        'background-gradient-stop-colors': '#53DD86 #53DD86 white white',
        'background-gradient-stop-positions': '0% 20% 20% 100%',
      },
    },
    {
      selector: '.digital',
      style: {
        'border-color': '#00ABFA',
        'background-fill': 'linear-gradient',
        'background-gradient-stop-colors': '#00ABFA #00ABFA white white',
        'background-gradient-stop-positions': '0% 20% 20% 100%',
      },
    },
    {
      selector: '.party',
      style: {
        'border-color': '#EA7451',
        'background-fill': 'linear-gradient',
        'background-gradient-stop-colors': '#EA7451 #EA7451 white white',
        'background-gradient-stop-positions': '0% 20% 20% 100%',
      },
    },
    {
      selector: '.time',
      style: {
        'border-color': '#D34BB3',
        'background-fill': 'linear-gradient',
        'background-gradient-stop-colors': '#D34BB3 #D34BB3 white white',
        'background-gradient-stop-positions': '0% 15% 15% 100%',
      },
    },
    {
      selector: '.eh-handle',
      style: {
        'background-color': 'red',
        width: 10,
        height: 10,
        shape: 'round-diamond',
        'overlay-opacity': 0,
        'border-width': 12,
        'border-opacity': 0,
      },
    },
    {
      selector: '.eh-hover',
      style: {
        'background-color': 'red',
      },
    },
  ],
  layout: {
    name: 'dagre',
    directed: true,
    padding: 10,
    avoidOverlap: true,
  },
}

export const JOURNEY_DETAILS_INIT = {
  name: '',
  description: '',
}

export const COMPLETE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="Icon_ionic-ios-checkmark-circle-outline" data-name="Icon ionic-ios-checkmark-circle-outline" transform="translate(-3.375 -3.375)">
  <path id="Path_33043" data-name="Path 33043" d="M18.673,12.949l-.677-.7a.146.146,0,0,0-.108-.046h0a.14.14,0,0,0-.108.046l-4.692,4.727-1.708-1.708a.149.149,0,0,0-.215,0l-.685.685a.153.153,0,0,0,0,.219l2.154,2.154a.681.681,0,0,0,.45.219.714.714,0,0,0,.446-.212h0l5.142-5.169A.164.164,0,0,0,18.673,12.949Z" transform="translate(-3.198 -4)" fill="#86df6a"/>
  <path id="Path_33044" data-name="Path 33044" d="M11.375,4.452a6.92,6.92,0,1,1-4.9,2.027,6.877,6.877,0,0,1,4.9-2.027m0-1.077a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z" fill="#86df6a"/>
</g>
</svg>`

export const INCOMPLETE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="18.049" height="16" viewBox="0 0 18.049 16">
<g id="Icon_feather-alert-triangle" data-name="Icon feather-alert-triangle" transform="translate(-1.305 -3.346)">
  <path id="Path_33043" data-name="Path 33043" d="M9.019,5.09,2.533,16.026a1.56,1.56,0,0,0,0,1.539,1.529,1.529,0,0,0,1.314.781H16.813a1.529,1.529,0,0,0,1.314-.781,1.56,1.56,0,0,0,0-1.539L11.637,5.09a1.524,1.524,0,0,0-2.619,0Z" transform="translate(0 0)" fill="none" stroke="#e6de17" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <path id="Path_33044" data-name="Path 33044" d="M18,13.5v3.033" transform="translate(-7.672 -4.155)" fill="none" stroke="#e6de17" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <path id="Path_33045" data-name="Path 33045" d="M18,25.5h0" transform="translate(-7.672 -10.154)" fill="none" stroke="#e6de17" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</g>
</svg>`

export const getIcon = (nodeType) => {
  if (!['digital', 'party'].includes(nodeType)) return ''

  const colorCode = nodeType === 'digital' ? '#00ABFA' : '#EA7451'

  return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
  <path id="Open_Campaign" data-name="Open Campaign" d="M18.722,6H6.278A1.764,1.764,0,0,0,4.5,7.75v10.5A1.764,1.764,0,0,0,6.278,20H9.833V18.25H6.278V9.5H18.722v8.75H15.167V20h3.556A1.769,1.769,0,0,0,20.5,18.25V7.75A1.764,1.764,0,0,0,18.722,6ZM12.5,11.25l-3.556,3.5h2.667V20h1.778V14.75h2.667Z" transform="translate(20 -4.5) rotate(90)" fill="${colorCode}"/>
</svg>`
}

export const GET_ACTION_TITLE = (nodeId) => {
  switch (nodeId) {
    case 'action_sendSMS':
      return 'SMS for Journey'

    case 'action_sendWhatsApp':
      return 'WhatsApp for Journey'

    case 'action_sendEmail':
      return 'Email for Journey'

    case 'filter_survey':
      return 'Survey for Journey'

    case 'filter_adBelow':
      return 'Ad Below Bill for Journey'

    case 'filter_sellBelow':
      return 'Sell Below Bill for Journey'

    case 'filter_banner':
      return 'Banner In Bill for Journey'

    case 'filter_popOver':
      return 'Popup Over Bill for Journey'

    default:
      return ''
  }
}

export const SMS_STEPS = [
  {
    id: 1,
    status: 'active',
    label: 'Template Details',
  },
  {
    id: 2,
    status: 'inactive',
    label: 'Sender ID & Template',
  },
  {
    id: 3,
    status: 'inactive',
    label: 'Message',
  },
]

export const WHATSAPP_STEPS = [
  {
    id: 1,
    status: 'active',
    label: 'Template Details',
  },
  {
    id: 2,
    status: 'inactive',
    label: 'Sender ID & Template',
  },
  {
    id: 3,
    status: 'inactive',
    label: 'Message',
  },
]

export const EMAIL_STEPS = [
  {
    id: 1,
    status: 'active',
    label: 'Template Details',
  },
  {
    id: 2,
    status: 'inactive',
    label: 'Message',
  },
]

export const SURVEY_STEPS = [
  {
    id: 1,
    status: 'active',
    label: 'Select Survey',
  },
  {
    id: 2,
    status: 'inactive',
    label: 'Survey In Bill Builder',
  },
]

export const POPUP_OVER_STEPS = [
  {
    id: 1,
    status: 'active',
    label: 'Popup',
  },
  {
    id: 2,
    status: 'inactive',
    label: 'Popup Settings',
  },
]

export const GET_ACTION_STEPS = (action) => {
  switch (action) {
    case 'action_sendSMS':
      return SMS_STEPS
    case 'action_sendWhatsApp':
      return WHATSAPP_STEPS
    case 'action_sendEmail':
      return EMAIL_STEPS
    case 'filter_popOver':
      return POPUP_OVER_STEPS
    case 'filter_survey':
      return SURVEY_STEPS
    default:
      return []
  }
}

export const GROUP_BY = (array, groupByKey) => {
  return array.reduce((rv, x) => {
    ;(rv[x[groupByKey]] = rv[x[groupByKey]] || []).push(x)
    return rv
  }, {})
}

export const EVENT_OCCURENCES = (allEvents) =>
  Object.keys(GROUP_BY(allEvents, 'category')).map((event) => ({
    label: event,
    value: event,
  }))

export const SUB_EVENT_OCCURENCES = (allEvents, event) => {
  if (event)
    return GROUP_BY(allEvents, 'category')[event].map((subEvent) => ({
      label: subEvent.displayName,
      value: subEvent.eventId,
    }))
  else return []
}

export const GET_SUB_EVENT_OPTIONS = (allEvents, subEvent) =>
  allEvents.find((event) => event.eventId === subEvent)?.options || []

export const GET_DELAY_OPTIONS = (allFilters) =>
  allFilters.find((fil) => fil.actionId === 'action_waitDays')?.options || []

export const GET_SUB_EVENT_LABEL = (allEvents, subEvent) =>
  allEvents?.find((eve) => eve.eventId === subEvent)?.displayName || ''

export const GET_CONDITIONS_LABEL = (inputField, condition, option) => {
  switch (inputField) {
    case 'arrayOfNumbers': {
      const selectedDays = DAYS_OF_THE_WEEK?.filter((day) =>
        condition?.includes(day.value)
      )
      return selectedDays.map((day) => day.label).join(', ')
    }

    case 'dateRange': {
      const min = condition?.min || {}
      const max = condition?.max || {}
      const checkMin = CHECK_IF(['date', 'hours', 'minutes', 'period'], min)
      const checkMax = CHECK_IF(['date', 'hours', 'minutes', 'period'], max)
      if (checkMin || checkMax) {
        return 'Incomplete'
      } else {
        const date = `${min.date} ${min.hours}:${min.minutes} ${min.period} - ${max.date} ${max.hours}:${max.minutes} ${max.period}`
        return date
      }
    }

    case 'number':
      return `${condition} ${option}`

    case 'range':
    case 'productList':
    case 'combined':
      return `${
        condition?.min ? `Range (${condition?.min} - ${condition?.max}) \n` : ''
      }${
        condition?.productLabels?.length
          ? `Products (${condition?.productLabels})`
          : ''
      }`

    default:
      return condition
  }
}

export const GET_OUTCOMES = (allFilters, componentId) => {
  let outcomeArray = []
  const outcomes = allFilters.filter(
    (comp) => componentId === comp.filterId || componentId === comp.actionId
  )
  if (outcomes?.length) {
    outcomeArray = [...outcomes[0].outcomes]
  } else {
    outcomeArray = [{ name: 'OUTCOME_TRUE', label: '' }]
  }
  return outcomeArray.map((out) => ({
    label: out.label,
    value: out.name,
  }))
}

export const DAYS_OF_THE_WEEK = [
  {
    label: 'Sundays',
    value: 0,
  },
  {
    label: 'Mondays',
    value: 1,
  },
  {
    label: 'Tuesdays',
    value: 2,
  },
  {
    label: 'Wednesdays',
    value: 3,
  },
  {
    label: 'Thursdays',
    value: 4,
  },
  {
    label: 'Fridays',
    value: 5,
  },
  {
    label: 'Saturdays',
    value: 6,
  },
]

export const GET_DELAY_FIELDS = [
  {
    label: 'Previous Step',
    value: 'previousStep',
  },
  {
    label: 'Transaction/Purchase Date & Time',
    value: 'createdDate',
  },
  {
    label: 'Movie Date & Time',
    value: 'parsedData.customData.showDateTime',
  },
]

export const GET_DELAY_PREPONE_FIELDS = [
  {
    label: 'Before',
    value: 'true',
  },
  {
    label: 'After',
    value: 'false',
  },
]

export const JB_HEADER = [
  'Journey Name',
  'Status',
  'Description',
  'Created By',
  'Date Created',
  // 'Date Updated',
  'End Date',
  'Actions',
]

export const GET_NODE_HEIGHT = (nodeType) => {
  switch (nodeType) {
    case 'start':
      return 130
    case 'time':
      return 100
    default:
      return 80
  }
}

export const NODE_HTML = (cy, hideDelete = false, allEvents, allFilters) =>
  cy.nodeHtmlLabel([
    {
      query: '.node', // cytoscape query selector
      halign: 'center', // title vertical position. Can be 'left',''center, 'right'
      valign: 'center', // title vertical position. Can be 'top',''center, 'bottom'
      halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
      valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
      tpl: (data) => {
        const div = document.createElement('div')
        div.innerHTML = `<div style="display: flex; gap: 20px; align-items: center; margin-top: 8px;">
            <div style="cursor: move; display: flex; flex-direction: column;">
              <div style="min-width: 160px; margin-top: 8px; display: flex; align-items: center; gap: 6px;">
                              <span style="text-decoration: underline; font-weight: bold;">
                  ${data.name}
                </span>
                ${getIcon(data.nodeType)}
              </div>
              ${
                ['digital', 'party'].includes(data.nodeType) &&
                data.nodeId !== 'filter_billMonitoring'
                  ? `<div style="display: flex; align-items: flex-start; gap: 6px;">
                  <small> Status : ${
                    data?.campaignId ? 'Complete' : 'Incomplete'
                  }</small>
                  ${
                    data.nodeId === 'filter_billMonitoring'
                      ? ''
                      : data?.campaignId
                      ? COMPLETE_ICON
                      : INCOMPLETE_ICON
                  }
                </div>`
                  : ''
              }
            ${[
              {
                label: 'Event : ',
                check: data.event,
                value: data.event,
              },
              {
                label: 'Sub Event : ',
                check: data.subEvent,
                value: data.subEvent
                  ? GET_SUB_EVENT_LABEL(allEvents, data.subEvent)
                  : '',
              },
              {
                label: 'Option : ',
                check: data.option,
                value: data.option
                  ? (data.nodeType === 'time'
                      ? GET_DELAY_OPTIONS(allFilters)
                      : GET_SUB_EVENT_OPTIONS(allEvents, data.subEvent)
                    )?.filter((op) => op.name === data.option)[0]?.label || ''
                  : '',
              },
              {
                label: 'Condition : ',
                check: data.condition,
                value: data.condition
                  ? GET_CONDITIONS_LABEL(
                      (data.nodeType === 'time'
                        ? GET_DELAY_OPTIONS(allFilters)
                        : GET_SUB_EVENT_OPTIONS(allEvents, data.subEvent)
                      )?.filter((op) => op.name === data.option)[0]
                        ?.inputField || '',
                      data.condition?.value
                        ? data.condition?.value
                        : data.condition,
                      data?.option
                    )
                  : '',
              },
            ]
              .map((item) =>
                item.check
                  ? `<small title="${item.value}" style="max-width: 170px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              ${item.label} ${item.value} </small>`
                  : ''
              )
              .join('')}
            
            </div>
              ${
                hideDelete
                  ? ''
                  : `<img src="${DeleteSVG}" type="button" id="delete${data.id}" >`
              }
          </div>
          `
        return div.innerHTML
      },
    },
  ])

export const GET_ACTION_TYPE = (nodeId) => {
  switch (nodeId) {
    case 'action_sendSMS':
      return 'sms'

    case 'action_sendEmail':
      return 'email'

    case 'action_sendWhatsApp':
      return 'whatsApp'

    case 'filter_banner':
      return 'bannerInBill'

    case 'filter_adBelow':
      return 'adBelowBill'

    case 'filter_survey':
      return 'surveyInBill'

    case 'filter_sellBelow':
      return 'sellBelowBill'

    case 'filter_popOver':
      return 'popupOverBill'

    default:
      return ''
  }
}

export const NODE_LABEL = (nodeType) => {
  switch (nodeType) {
    case 'start':
      return 'Event Occurence'
    case 'customer':
      return 'Customers'
    case 'digital':
      return 'Digital Bill'
    case 'party':
      return 'Auto Engagement (3rd Party)'
    case 'time':
      return 'Time Regulator'
    default:
      return ''
  }
}

export const JOURNEY_INSTRUCTIONS = [
  '1) All journeys have to begin with an event block.',
  '2) Only one event block can be added per journey.',
  '3) The second block has to be the customer block, only one customer block can be added to each event block. It is compulsory to add a customer block.',
  '4) Digital bill media blocks can only be connected directly to customer blocks.',
  '5) 3rd party communication channel blocks can be added to both customer blocks and to other action blocks (including 3rd party and digital blocks).',
  '6) Time slot blocks can be used to create waiting period, it can be added after the customer block and between 2 action blocks.',
  '7) Digital Billing blocks can be added in series, not one after the other, as all digital bill blocks are activated together.',
  '8) SMS & Email blocks can be added to Digital bill media but vice versa is not possible.',
]
