import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { FlexBox, Div } from 'styledComponent'
import { useSelectStore } from 'hooks'
import { Text, useToasts, PopupContainer } from 'components/Common'
import { $Browse } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { UploadBox } from 'components/Content/autoEngagement/components/MediaUpload'
import {
  AirtelInstructions,
  JioHeaderInstructions,
  JioInstructions,
  JioLogo,
  VIHeaderInstructions,
  VIInstructions,
  VILogo,
} from 'assets/dlt'

import { ExitBlock } from 'components/Content/autoEngagement/components/TopActionBar'
import { ERROR_DECODE } from 'components/Content/autoEngagement/constants'
import { getSmsHeaders, postSmsHeaders } from 'redux/actions/settingsActions'
import { DLTErrorTable } from './DLTErrorTable'

export const DLTSmsSenderIDPopup = ({ isPopOpen, setIsPopOpen }) => {
  const { spacing } = useTheme()
  const { toast } = useToasts()
  const dispatch = useDispatch()
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const [instructs, setInstructs] = useState(false)
  const [csv, setCsv] = useState('')
  const [error, setError] = useState({
    isError: false,
    table: [],
  })

  const onSubmit = async () => {
    try {
      if (!csv) {
        throw 'Please upload a file before submitting.'
      }

      const formData = new FormData()
      formData.append('file', csv)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      const res = await postSmsHeaders(vendorId, formData, config)
      if (res) {
        toast(res?.message, 'success')
        dispatch(getSmsHeaders())
        if (res?.data?.length) {
          setError({
            isError: true,
            table: res?.data,
          })
          throw ''
        }
      }
    } catch (err) {
      err && toast(ERROR_DECODE(err), 'error')
      if (
        err?.response?.data?.message === 'Invalid data provided in given file.'
      ) {
        setError({
          isError: true,
          table: err?.response?.data?.data,
        })
      }
      throw ERROR_DECODE(err)
    }
  }

  const onCancel = () => {
    setCsv('')
    setInstructs(false)
    setIsPopOpen(false)
    setError({
      isError: false,
      table: [],
    })
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading={
        error?.isError
          ? 'Rejected Sender IDs'
          : 'Upload CSV File - Sender ID(s)'
      }
      onSubmitClick={!error.isError && onSubmit}
      onCancel={onCancel}
    >
      {error?.isError ? (
        <Div maxHeight="500px" overflow="auto">
          <DLTErrorTable errorList={error?.table || []} />
        </Div>
      ) : (
        <>
          {!instructs && (
            <>
              <UploadBox
                fileType="csv"
                acceptedFormats=".csv"
                uploadWidth="100%"
                uploadHeight="120px"
                src={csv}
                onChange={(e) => setCsv(e.target.files[0])}
              />
              <$Browse>
                <span>Add File : </span>
                <div>
                  <input
                    accept=".csv"
                    type="file"
                    onChange={(e) => setCsv(e.target.files[0])}
                    title={csv?.name || 'No File Chosen'}
                  />
                  <small>BROWSE</small>
                </div>
              </$Browse>
              <hr />
              <Text type="main">
                Instructions on how to export and upload your .csv file.
              </Text>
              <Text type="grey">(Click on the logo to load instructions)</Text>
              <FlexBox gap="50px" p={spacing.l} justify="center" align="center">
                <img
                  type="button"
                  src={JioLogo}
                  onClick={() => setInstructs('jio-header')}
                />
                {/* <img
                  type="button"
                  width="120"
                  src={AirtelLogo}
                  onClick={() => setInstructs('airtel')}
                /> */}
                <img
                  type="button"
                  width="120"
                  src={VILogo}
                  onClick={() => setInstructs('vi-header')}
                />
              </FlexBox>
            </>
          )}
          {instructs && (
            <Div height="500px" overflow="auto">
              <FlexBox gap={spacing.l} align="center" pb={spacing.m}>
                <ExitBlock
                  exitTitle="Back"
                  onExit={() => setInstructs(false)}
                />
                Instructions
              </FlexBox>
              {getInstructions(instructs)}
            </Div>
          )}
        </>
      )}
    </PopupContainer>
  )
}

export const getInstructions = (provider) => {
  const instruct = (href, src) => (
    <a href={href} target="_blank" rel="noreferrer noopener">
      <img src={src} alt={provider} width="100%" />
    </a>
  )
  switch (provider) {
    case 'jio-header':
      return instruct(
        'https://trueconnect.jio.com/#/home/common-login',
        JioHeaderInstructions
      )

    case 'vi-header':
      return instruct('https://www.vilpower.in/signin/', VIHeaderInstructions)

    case 'jio':
      return instruct(
        'https://trueconnect.jio.com/#/home/common-login',
        JioInstructions
      )

    case 'airtel':
      return instruct(
        'https://www.airtel.in/business/commercial-communication/home',
        AirtelInstructions
      )

    case 'vi':
      return instruct('https://www.vilpower.in/signin/', VIInstructions)

    default:
      return ''
  }
}
