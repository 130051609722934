import React, { Component } from 'react'
import { connect } from 'react-redux'
import FileDownload from 'js-file-download'
import cloneDeep from 'lodash/cloneDeep'
import CustomPopup from '../CustomPopup'
import FilterFooter from '../manageStores/StoreFilter/Footer'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import { formatDateInFilters } from '../../../utils/getDateFormat'
import { defaultFilters, dynamicBorderColor } from '../../../utils/feedback'
import { compareDatePermission } from '../../../utils/getDateFormat'
import { MyToast } from 'components/Common'
import {
  setFeedbackFilters,
  resetFilters,
  getFeedbacks,
  setRememberFilters,
  getFeedbackCampaigns,
  filterStores,
  filterBrand,
  exportFeedbacks,
} from '../../../redux/actions'
import {
  DateFilter,
  Filter,
  StoreSelectOption,
  FilterHeader,
} from '../../Common'
import swal from 'sweetalert'

const moment = extendMoment(originalMoment)
const now = moment()
const defaultDateRange = originalMoment.range(
  now.clone().startOf('month'),
  now.clone().endOf('day')
)

const ReactSelectStyle = {
  container: () => ({
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  }),
}

const SelectStores = ({ brandId, brands, name }) => {
  let logo = ''
  if (brands) {
    brands.forEach((brand) => {
      if (brandId === brand.brandId) {
        logo = brand.brandLogo
        return
      }
    })
    return (
      <StoreSelectOption
        isDiabled={false}
        data={{
          brandLogo: logo,
          label: name,
        }}
      />
    )
  }
  return null
}

class FeedbackFilter extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      isDatePickerOpen: false,
      filters: {},
      value: '',
      rememberFilters: false,
      campaignNames: [],
    }

    // FIXME: Can use forward ref instead of defining a new ref here, what say?
    this.dialogRef = React.createRef()

    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.onDateSelect = this.onDateSelect.bind(this)
    this.onSetFilters = this.onSetFilters.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleResetFilters = this.handleResetFilters.bind(this)
    this.handleRememberFilters = this.handleRememberFilters.bind(this)
  }

  async componentDidMount() {
    if (this.props.filters.selectedCampaign.isActive) {
      const { campaignData } = await this.props.getFeedbackCampaigns()
      this.setState({ campaignNames: campaignData })
    }

    this.setState({
      filters: cloneDeep(this.props.filters),
      rememberFilters: this.props.rememberFilters,
      value: defaultDateRange,
    })
  }

  openFilterModal = () => {
    this.dialogRef.current.openModal()
  }

  handleDatePicker() {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen })
  }

  handleRememberFilters() {
    this.setState({ rememberFilters: !this.state.rememberFilters })
  }

  // FIXME: Can make this fn common
  handleCheckboxClick = async (_e, filterName) => {
    if (
      filterName === 'selectedCampaign' &&
      !this.state.filters.selectedCampaign.isActive
    ) {
      const { campaignData } = await this.props.getFeedbackCampaigns()
      this.setState({ campaignNames: campaignData })
    }
    let filters = cloneDeep(this.state.filters)
    filters[filterName].isActive =
      filters[filterName] && !filters[filterName].isActive
    filters[filterName].value =
      defaultFilters[filterName] && defaultFilters[filterName].value
    this.setState({
      filters,
    })
  }

  handleSelect(value, filterType) {
    let filters = cloneDeep(this.state.filters)
    filters[filterType].value = value
    this.setState({ filters })
  }

  async onSetFilters(e, discardFilter = false) {
    let filters = cloneDeep(this.state.filters)

    if (
      filters.date.value.dates.startValue &&
      !compareDatePermission(
        this.props.selectedVendor.featuresAlloted.exportRange,
        filters.date.value.dates.startValue
      )
    ) {
      this.context.toast('You can only access last 2 months data', 'warning')
      return false
    }
    if (this.props.isExport) {
      if (!filters.emailList.value.length) {
        swal('Add atleast one email', 'Email is not added', 'error')
        return false
      }
      const data = await this.props.exportFeedbacks({
        filters,
        discardFilter,
        storeIds: this.props.storeIds,
      })
      if (data?.code) {
        swal(data.message || '')
      }
      // var blob = new Blob([data], {
      //   type:
      //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // })

      // FileDownload(blob, 'feedbacks.xlsx')
      // The filters set in export are not set as filters for feedback, thus resetting
      this.setState({ filters: this.props.filters })
    } else {
      // TODO: Start Validation

      this.props.setFeedbackFilters(filters)
      this.props.setRememberFilters(this.state.rememberFilters)
      this.props.getFeedbacks()
      this.props.resetPageNumber()
    }

    this.dialogRef.current.closeModal()
    // this.props.setDefaultFilters(this.state.filters)
  }

  onDateSelect = (value, states) => {
    let filters = cloneDeep(this.state.filters)
    filters.date.value.dates.startValue = value.start._i
    filters.date.value.dates.startDate = formatDateInFilters(value.start._i)
    filters.date.value.dates.endValue = value.end._i
    filters.date.value.dates.endDate = formatDateInFilters(value.end._i)
    this.setState({
      filters,
      value,
      states,
      isDatePickerOpen: false,
    })
  }

  // FIXME: Make this common
  handleTimeChange = (filterName, filterProperty, value) => {
    let filters = cloneDeep(this.state.filters)
    switch (filterProperty) {
      case 'startTime':
      case 'endTime':
        filters[filterName]['value']['time'][filterProperty]['hrs'] = value
        break
      case 'startTimeState':
        filters[filterName]['value']['time']['startTime']['state'] = value
        break
      case 'endTimeState':
        filters[filterName]['value']['time']['endTime']['state'] = value
        break
      default:
        filters[filterName]['value'][filterProperty] = value
    }

    this.setState({
      filters,
    })
  }

  handleResetFilters() {
    this.props.resetFilters()
    this.setState({
      filters: cloneDeep(defaultFilters),
      rememberFilters: false,
    })
  }

  onEmailChange = (val) => {
    const copyFilter = cloneDeep(this.state.filters)
    copyFilter['emailList'].value = val
    this.setState({ filters: copyFilter })
  }

  render() {
    return (
      <CustomPopup
        className={'filter-modal'}
        headerComponent={true}
        heading={
          <FilterHeader
            heading={this.props.isExport ? 'Export filters' : 'Create filters'}
          />
        }
        footer={
          <FilterFooter
            onCancel={() => this.dialogRef.current.closeModal()}
            onSetFilters={this.onSetFilters}
            onResetFilters={this.handleResetFilters}
            onSetRememberFilters={this.handleRememberFilters}
            rememberFilters={this.state.rememberFilters}
            isExport={this.props.isExport}
          />
        }
        ref={this.dialogRef}
      >
        <div className="filter-modal-body">
          <DateFilter
            title="Date"
            isActive={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.isActive
            }
            isDatePickerOpen={this.state.isDatePickerOpen}
            handleCheckboxClick={this.handleCheckboxClick}
            onDatePickerClick={this.handleDatePicker}
            startDate={
              this.state.filters?.date?.value?.dates?.startDate ||
              this.state.startDate
            }
            dateValue={this.state.value}
            onDateSelect={this.onDateSelect}
            onTimeChange={this.handleTimeChange}
            endDate={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.dates.endDate
            }
            startTimeHours={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.startTime.hrs
            }
            startTimeState={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.startTime.state
            }
            endTimeHours={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.endTime.hrs
            }
            endTimeState={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.endTime.state
            }
            checkboxMargin="0px 10px 0px 0px"
            borderColor={dynamicBorderColor}
          />

          <Filter
            isFilterActive={
              this.state.filters &&
              this.state.filters.ratings &&
              this.state.filters.ratings.isActive
            }
            borderColor={dynamicBorderColor}
            filterId="ratings"
            filterLabel="Ratings"
            onCheckboxClick={(e) => this.handleCheckboxClick(e, 'ratings')}
            isMulti={true}
            selectOption={[...Array(5).keys()].map((item) => ({
              value: item + 1,
              label: item + 1,
            }))}
            selectValue={
              this.state.filters &&
              this.state.filters.ratings &&
              this.state.filters.ratings.value
            }
            onSelectChange={(value) => this.handleSelect(value, 'ratings')}
            filterStyles={{ ...ReactSelectStyle }}
          />

          <Filter
            isFilterActive={
              this.state.filters &&
              this.state.filters.selectedCampaign &&
              this.state.filters.selectedCampaign.isActive
            }
            borderColor={dynamicBorderColor}
            filterId="selectedCampaign"
            filterLabel="Select Campaign"
            onCheckboxClick={(e) =>
              this.handleCheckboxClick(e, 'selectedCampaign')
            }
            selectOption={this.state.campaignNames.map((item) => ({
              value: item.campaignId,
              label: item.campaignName,
            }))}
            selectValue={
              this.state.filters &&
              this.state.filters.selectedCampaign &&
              this.state.filters.selectedCampaign.value
            }
            onSelectChange={(value) =>
              this.handleSelect(value, 'selectedCampaign')
            }
            filterStyles={{ ...ReactSelectStyle }}
          />
          {/* <Filter
            isFilterActive={
              this.state.filters &&
              this.state.filters.selectedStores &&
              this.state.filters.selectedStores.isActive
            }
            borderColor={dynamicBorderColor}
            filterId="selectedStores"
            filterLabel="Select Stores"
            onCheckboxClick={(e) =>
              this.handleCheckboxClick(e, 'selectedStores')
            }
            isMulti={true}
            selectOption={Object.keys(this.props.stores).map((store) => ({
              value: this.props.stores[store].storeId,
              label: (
                <SelectStores
                  brandId={this.props.stores[store].brandId}
                  brands={this.props.brands}
                  name={this.props.stores[store].displayAddress + " : " + this.props.stores[store].storeCode}
                />
              ),
            }))}
            selectValue={
              this.state.filters &&
              this.state.filters.selectedStores &&
              this.state.filters.selectedStores.value
            }
            onSelectChange={(value) =>
              this.handleSelect(value, 'selectedStores')
            }
            filterStyles={{ ...ReactSelectStyle }}
          /> */}

          {this.props.isExport && (
            <Filter
              isActive={
                this.state.filters &&
                this.state.filters.emailList &&
                this.state.filters.emailList.isActive
              }
              borderColor={dynamicBorderColor}
              filterId="emailList"
              isFilterActive={this.state.filters.emailList?.isActive}
              //borderColor="#0081ED"
              filterLabel="Email List"
              placeholder="Enter email Ids"
              type="tags"
              inputValue={this.state.filters.emailList?.value || []}
              onValueChange={this.onEmailChange}
              onCheckboxClick={(e) => this.handleCheckboxClick(e, 'emailList')}
              filterStyles={{ ...ReactSelectStyle }}
            />
          )}
        </div>
      </CustomPopup>
    )
  }
}

const mapStateToProps = ({
  feedback: { filters, rememberFilters },
  feedbackCampaign: { data },
  ...state
}) => ({
  filters,
  feedbackCampaignList: data,
  stores: filterStores(state, 'feedback'),
  brands: filterBrand(state, 'feedback'),
  rememberFilters,
  selectedVendor: state.vendorIds.selectedVendor,
})

export default connect(
  mapStateToProps,
  {
    getFeedbackCampaigns,
    setFeedbackFilters,
    resetFilters,
    getFeedbacks,
    setRememberFilters,
    exportFeedbacks,
  },
  null,
  { forwardRef: true }
)(FeedbackFilter)
