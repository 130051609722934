import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelectStore } from 'hooks'
import { spacing } from 'design-system'
import { Text, Input, useToasts } from 'components/Common'

const $CompanySelectContainer = styled.div`
  width: ${(props) => (props.width ? props.width : '250px')};
  margin: ${spacing.ml} 0;
`

export const SelectCompany = ({
  width,
  companyId,
  setCompanyId,
  disabled,
  smsFilter,
}) => {
  const {
    data: { companies },
  } = useSelectStore('company')
  const { data } = useSelectStore('vendorIds.selectedVendor.value')
  const { toast } = useToasts()

  const getCompanyValue =
    companies
      ?.filter((comp) => companyId === comp.companyId)
      ?.map((com) => ({
        label: com.companyName,
        value: com.companyId,
      }))[0] || ''

  const filteredCompanies = smsFilter
    ? companies.filter((com) => com?.smsConfigExists)
    : companies
  const companyIds = filteredCompanies
    ?.filter((filCom) => filCom.vendorId === data)
    ?.map((com) => ({
      label: com.companyName,
      value: com.companyId,
    }))

  useEffect(() => {
    if (companyIds?.length === 0 && smsFilter) {
      toast(
        'SMS configurations does not exist for any of the companies.',
        'error'
      )
    }
  }, [])

  return (
    <$CompanySelectContainer width={width}>
      <Text variant="small">Select Company</Text>
      <Input
        disabled={disabled}
        variant="single-select"
        value={getCompanyValue}
        options={companyIds}
        placeholder="Select Company"
        onChange={(val) => setCompanyId(val.value)}
      />
    </$CompanySelectContainer>
  )
}
