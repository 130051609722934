const INITIAL_DATA = {
  productName: '',
  productCode: '',
  displayNames: [''],
  tags: [],
  taxDetails: null,
  uamDetails: null,
  categoryDetails: null,
}

const ERROR_MESSAGES = {
  mandatoryFields: 'Product Name / Category fields are mandatory',
  genericError: 'Something went wrong, please try again.',
}

const BODY_KEYS = {
  categoryDetails: 'category',
  taxDetails: 'tax',
  uamDetails: 'uam',
}

export { INITIAL_DATA, ERROR_MESSAGES, BODY_KEYS }
