import styled, { css } from 'styled-components';
import { FlexBox } from './elements'

export const Card = styled(FlexBox)`
    width: 100%;
    
    padding: ${props => ({
        tbPadding: "10px 0px",
        default: "10px",
        custom: props.customPadding,
    })[props.padding || "default"]};
    background-color: ${props => props.selected ? "hsl(203, 75%, 97%)" : ""};
    background-image: ${props => props.noDash ? "none" : "linear-gradient(to right, #f2f2f2 70%, rgba(255, 255, 255, 0) 20%)"};
    background-position: bottom;
    background-size: 13px 1px;
    background-repeat: repeat-x;
    box-shadow: ${props => props.lightBoxShadow ? '0px 5px 10px 3px hsl(0, 0%, 95%)' : "none"};
    font-size: ${props => props.fontSize || "medium"};
    position: relative;
    margin-top: ${props => props.mt || ""};
    cursor: ${props => props.cursor || `default` };

`
export const RoundCard = styled(Card)`
    border-radius: 8px;
`;

export const CardContent = styled.div`
    height: ${ props => props["height"] || `100%`};
    width: ${ props => props["width"] || `100%`};
`

export const ChatMessageCard = styled.div`
    background: ${ props => props.theme.colors.primary["100"] };
    padding: 25px 20px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: auto;
    word-break: break-all;
`

export const ChatReplyCard = styled.div`
    padding: 25px 20px;
    background: ${ props => props.theme.colors.primary["300"] };
    width: auto;
    margin-left: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    word-break: break-all;
    border-bottom-left-radius: 25px;
`

