import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import {
  $CampaignContainer,
  $DateInput,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import { Input, Text, SelectCompany } from 'components/Common'
import { getCompanies } from 'redux/actions/payments'
import { JourneyTopBar } from './JourneyTopBar'
import { JOURNEY_DETAILS_INIT } from '../constants'
import { timeStampToUtc, utcToTimeStamp } from 'utils/getDateFormat'
import { TimeSelector } from 'components/Content/autoEngagement/components/WhenToSend'
import { TODAY } from 'components/Content/autoEngagement/constants'

export const JourneyDetails = ({
  title,
  isCreate,
  journeyDetails,
  setJourneySteps,
  setJourneyDetails,
  onJourneyDetailsSave,
}) => {
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const {
    data: { vendorIds, selectedVendor },
  } = useSelectStore('vendorIds')
  const { data } = useSelectStore('company.companies')

  const companies = data?.filter(
    (filCom) => filCom.vendorId === selectedVendor?.value
  )

  const onJourneyDetailsChange = (e) =>
    setJourneyDetails({
      ...journeyDetails,
      [e.target.id]: e.target.value,
    })

  useEffect(() => {
    if (companies?.length) {
      if (isCreate) {
        setJourneyDetails({
          ...journeyDetails,
          companyId: companies[0]?.companyId,
        })
      }
    } else {
      dispatch(getCompanies)
    }
  }, [companies?.length])

  useEffect(() => {
    setJourneyDetails({
      ...cloneDeep(JOURNEY_DETAILS_INIT),
      companyId: companies[0]?.companyId,
    })
  }, [])

  const startDate = moment(journeyDetails?.startDate || new Date()).format(
    'YYYY-MM-DD'
  )
  const startTimeStamp = moment(
    journeyDetails?.startDate || moment().set({ hour: 0, minute: 0 })
  ).format('HH:mm')

  const endDate = moment(
    journeyDetails?.endDate || moment(startDate).add(1, 'days')
  ).format('YYYY-MM-DD')
  const endTimeStamp = moment(
    journeyDetails?.endDate || moment(startDate).add(1, 'days')
  ).format('HH:mm')

  const onDateChange = (e, field, tStamp) => {
    const timeBody = {
      ...journeyDetails,
      [field]: moment(e.target.value + ' ' + tStamp)?.toISOString(),
    }
    if (field === 'startDate') {
      timeBody.endDate = undefined
    }

    setJourneyDetails(timeBody)
  }

  const onTimeChange = (stamp, field, key, date, tStamp) => {
    const timeBody = {
      ...journeyDetails,
      [key]: moment(
        date +
          ' ' +
          timeStampToUtc({
            ...utcToTimeStamp(tStamp),
            [field]: stamp,
          })
      )?.toISOString(),
    }
    if (key === 'startDate') {
      timeBody.endDate = undefined
    }

    setJourneyDetails(timeBody)
  }

  return (
    <>
      <JourneyTopBar
        title={title}
        progress="25%"
        onNextClick={() => onJourneyDetailsSave(() => setJourneySteps(2))}
        onExitClick={onJourneyDetailsSave}
        isPreviousDisabled
      />
      <$CampaignContainer m={spacing.l} pb={spacing.x4l}>
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          Journey Details
        </Text>
        <FlexBox gap={spacing.xl}>
          <Div>
            <Input
              containerMargin="0"
              variant="input"
              inputWidth="500px"
              label="Journey Name"
              placeholder="Enter Journey Name"
              id="name"
              value={journeyDetails?.name}
              onChange={onJourneyDetailsChange}
              maxLength="50"
            />
            <Input
              containerMargin="0"
              variant="textarea"
              inputWidth="500px"
              label="Journey Description"
              placeholder="Enter Journey Description"
              id="description"
              maxLength="250"
              rows={3}
              value={journeyDetails?.description}
              onChange={onJourneyDetailsChange}
            />
            <Input containerMargin="0" label="Start Date">
              <FlexBox align="center">
                <$DateInput
                  type="date"
                  min={TODAY}
                  value={journeyDetails?.startDate ? startDate : ''}
                  onChange={(e) => onDateChange(e, 'startDate', startTimeStamp)}
                />
                <TimeSelector
                  timeStamp={utcToTimeStamp(startTimeStamp)}
                  onTimestampChange={(stamp, field) =>
                    onTimeChange(
                      stamp,
                      field,
                      'startDate',
                      startDate,
                      startTimeStamp
                    )
                  }
                />
              </FlexBox>
            </Input>
            <Input containerMargin="0" label="End Date">
              <FlexBox align="center">
                <$DateInput
                  type="date"
                  min={moment(startDate).add(1, 'days').format('YYYY-MM-DD')}
                  value={journeyDetails?.endDate ? endDate : ''}
                  onChange={(e) => onDateChange(e, 'endDate', endTimeStamp)}
                />
                <TimeSelector
                  timeStamp={utcToTimeStamp(endTimeStamp)}
                  onTimestampChange={(stamp, field) =>
                    onTimeChange(stamp, field, 'endDate', endDate, endTimeStamp)
                  }
                />
              </FlexBox>
            </Input>
          </Div>
          <Div>
            {companies?.length > 1 && (
              <Div mt={companies?.length > 1 ? '22px' : ''}>
                <SelectCompany
                  companyId={journeyDetails?.companyId}
                  disabled={!isCreate}
                  setCompanyId={(companyId) =>
                    setJourneyDetails({ ...journeyDetails, companyId })
                  }
                />
              </Div>
            )}
          </Div>
        </FlexBox>
      </$CampaignContainer>
    </>
  )
}
