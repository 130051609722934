import React from 'react'
import { ThemeProvider } from 'styled-components'

import { useDarkTheme } from 'hooks'
import { RenderIf, Text } from 'components/Common'
import { FlexBox } from 'styledComponent'
import ToggleSwitch from 'components/Common/ToggleSwitch'
import { ReactComponent as PhoneIcon } from 'assets/icons/telephone-icon.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link-icon.svg'
import { ReactComponent as ImageIcon } from 'assets/icons/image-icon.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/document-icon.svg'
import {
  $PreviewBlock,
  $WhatsAppPreviewBlock,
  $ChatBubble,
  $BubbleArrow,
  $ChatHeader,
  $ChatBody,
  $ChatFooter,
  $ButtonsBlock,
  $ButtonText,
  $MediaThumbnail,
} from './TemplateCampaign.styled'

const mediaIcons = {
  image: <ImageIcon />,
  video: <PlayIcon />,
  document: <DocumentIcon />,
}

const Preview = ({
  data: { content, buttons, buttonType, type, mediaType },
  disableToggle,
  minHeight,
  insidePopup,
  hidePreviewTitle,
}) => {
  const [darkMode, setDarkMode] = useDarkTheme()

  const _getButtonIcon = (type) => {
    switch (type) {
      case 'website':
        return <ExternalLinkIcon />
      case 'phone-number':
        return <PhoneIcon />
      default:
        return null
    }
  }

  return (
    <$PreviewBlock flex="0.55" popup={insidePopup}>
      <RenderIf active={!hidePreviewTitle}>
        <Text variant="h3" weight="bold" type="main">
          Preview
        </Text>
      </RenderIf>
      <RenderIf active={!disableToggle}>
        <FlexBox>
          <Text variant="medium" type="main" margin="0 5px 0 0">
            Theme:
          </Text>
          <ToggleSwitch
            startText="Dark"
            endText="Light"
            isActive={!darkMode}
            onChange={() => setDarkMode(!darkMode)}
            width="120px"
            height="25px"
            sliderBottom="2px"
          />
        </FlexBox>
      </RenderIf>
      <ThemeProvider theme={{ mode: darkMode ? 'dark' : 'light' }}>
        <$WhatsAppPreviewBlock minHeight={minHeight}>
          <$ChatBubble minHeight="100px">
            <RenderIf active={type === 'media' && mediaType}>
              <$MediaThumbnail>{mediaIcons[mediaType]}</$MediaThumbnail>
            </RenderIf>
            <RenderIf active={content?.header && type === 'text'}>
              <$ChatHeader>{content?.header}</$ChatHeader>
            </RenderIf>
            <$ChatBody>{content?.body}</$ChatBody>
            <RenderIf active={content?.footer}>
              <$ChatFooter>{content?.footer}</$ChatFooter>
            </RenderIf>
            <RenderIf active={buttons?.length && buttonType === 'cta'}>
              <$ButtonsBlock>
                {buttons?.map((button, index) => (
                  <li key={index}>
                    <$ButtonText>
                      <span>{_getButtonIcon(button.type)} </span>
                      {button.text}
                    </$ButtonText>
                  </li>
                ))}
              </$ButtonsBlock>
            </RenderIf>
            <$BubbleArrow />
          </$ChatBubble>
          <RenderIf active={buttons?.length && buttonType === 'quick-reply'}>
            {buttons?.map((button, index) => (
              <$ChatBubble margin="4px 0 0 0" height="40px" key={index}>
                <$ButtonText>{button.text}</$ButtonText>
              </$ChatBubble>
            ))}
          </RenderIf>
        </$WhatsAppPreviewBlock>
      </ThemeProvider>
    </$PreviewBlock>
  )
}

Preview.defaultProps = {
  data: {
    content: {},
    buttons: [],
    buttonType: '',
    type: 'text',
  },
}

export default Preview
