import React, { useRef } from 'react'
import { useTheme } from 'styled-components'
import Select from 'react-select'
import { ChromePicker } from 'react-color'

import {
  $FlexBox,
  $PercentInput,
  $Block,
  $PropertyBar,
} from 'builder/editor/Editor.styled'
import { StyledInput } from './Common.styled'
import {
  BORDER_STYLE_OPTIONS,
  FONT_WEIGHT_OPTIONS,
  GET_SEL_VALUE,
  FONT_FAMILY_OPTIONS,
} from 'builder/editor/constants'
import { Div, FlexBox } from 'styledComponent'
import { useOnClickOutside } from 'hooks'

export const PropertyBar = ({
  children,
  label,
  type,
  propertyName,
  craftProps,
  setProp,
  isFlex,
  margin,
  placeholder,
  buttonName,
  onButtonClick,
  textWidth,
  isPercent,
  isSplit,
  isValueHidden = false,
  ...rest
}) => {
  const theme = useTheme()
  const ref = useRef()
  const [property, setProperty] = React.useState('')
  useOnClickOutside(ref, () => setProperty(''))

  const value = isSplit
    ? craftProps[propertyName].split(/(px|%)/)[0]
    : craftProps[propertyName]
  const onChange = (e) => {
    if (isSplit) {
      setProp(
        (props) =>
          (props[propertyName] =
            e.target.value + craftProps[propertyName].split(/(px|%)/)[1]),
        300
      )
    } else {
      setProp((props) => (props[propertyName] = e.target.value), 300)
    }
  }
  const getType = () => {
    if (type === 'color')
      return (
        <Div position="relative">
          <$PropertyBar
            bgrd={value}
            onClick={() => setProperty(propertyName)}
          />
          {property === propertyName && (
            <Div position="absolute" right={0} zIndex={10} ref={ref}>
              <ChromePicker
                style={{ position: 'absolute' }}
                color={value}
                onChange={(val) =>
                  setProp(
                    (props) =>
                      (props[
                        propertyName
                      ] = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a})`),
                    1000
                  )
                }
              />
            </Div>
          )}
        </Div>
      )

    return (
      <StyledInput
        type={type}
        style={{ width: '100%' }}
        value={isValueHidden ? '' : value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
    )
  }

  const getPercentType = () => {
    const onCheckboxClick = () => {
      if (craftProps[propertyName] === 'auto') {
        setProp((props) => ((props[propertyName] = '100%'), 300))
      } else {
        setProp((props) => ((props[propertyName] = 'auto'), 300))
      }
    }

    return (
      <FlexBox gap="20px">
        <$PercentInput wrap="no-wrap">
          <input
            type="number"
            disabled={craftProps[propertyName] === 'auto'}
            min={0}
            max={craftProps[propertyName].split(/(px|%)/)[1] === '%' && 100}
            value={craftProps[propertyName].split(/(px|%)/)[0] || ''}
            onChange={(e) =>
              setProp(
                (props) =>
                  (props[propertyName] =
                    e.target.value +
                    craftProps[propertyName].split(/(px|%)/)[1]),
                300
              )
            }
            placeholder={placeholder}
            {...rest}
          />
          <select
            value={craftProps[propertyName].split(/(px|%)/)[1] || ''}
            disabled={craftProps[propertyName] === 'auto'}
            onChange={(e) =>
              setProp(
                (props) =>
                  (props[propertyName] =
                    craftProps[propertyName].split(/(px|%)/)[0] +
                    e.target.value),
                300
              )
            }
          >
            <option value="px">px</option>
            <option value="%">%</option>
          </select>
        </$PercentInput>
        <FlexBox
          type="button"
          gap="6px"
          align="center"
          onClick={onCheckboxClick}
        >
          <input
            type="checkbox"
            name={propertyName}
            checked={craftProps[propertyName] === 'auto'}
            style={{ width: '16px', height: '16px' }}
          />
          <label style={{ marginBottom: 0 }} htmlFor={propertyName}>
            Auto
          </label>
        </FlexBox>
      </FlexBox>
    )
  }

  const getContent = () => (
    <>
      <p
        style={{
          width: textWidth || '100%',
          marginBottom: theme.spacing.s,
          fontSize: '12px',
        }}
      >
        {label}
      </p>
      {children || isPercent ? getPercentType() : getType()}
    </>
  )

  if (isFlex)
    return (
      <$FlexBox
        {...rest}
        style={{ width: '100%' }}
        margin={margin}
        wrap="nowrap"
        align="center"
      >
        {getContent()}
      </$FlexBox>
    )

  return (
    <div style={{ width: '100%', margin }} {...rest}>
      {getContent()}
    </div>
  )
}

export const BorderStyle = (props, setProp, component) => (
  <$FlexBox align="center" justify="space-between">
    <p style={{ fontSize: '12px', marginBottom: '4px' }}>
      {component} Border Style
    </p>
    <$Block width="180px">
      <Select
        options={BORDER_STYLE_OPTIONS}
        value={GET_SEL_VALUE(props.borderStyle, BORDER_STYLE_OPTIONS)}
        onChange={(val) =>
          setProp((props) => (props.borderStyle = val.value), 300)
        }
        placeholder="Select Style"
      />
    </$Block>
  </$FlexBox>
)

export const FontWeight = (props, setProp) => (
  <$FlexBox align="center" justify="space-between">
    <p style={{ fontSize: '12px', marginBottom: '4px' }}>Font Weight</p>
    <$Block width="180px">
      <Select
        options={FONT_WEIGHT_OPTIONS}
        value={GET_SEL_VALUE(props.fontWeight, FONT_WEIGHT_OPTIONS)}
        onChange={(val) =>
          setProp((props) => (props.fontWeight = val.value), 300)
        }
        placeholder="Select Style"
      />
    </$Block>
  </$FlexBox>
)

export const FontFamily = (props, setProp) => (
  <$FlexBox align="center" justify="space-between">
    <p style={{ fontSize: '12px', marginBottom: '4px' }}>Font Family</p>
    <$Block width="180px">
      <Select
        options={FONT_FAMILY_OPTIONS}
        value={GET_SEL_VALUE(props.fontFamily, FONT_FAMILY_OPTIONS)}
        onChange={(val) =>
          setProp((props) => (props.fontFamily = val.value), 300)
        }
        placeholder="Font Family"
      />
    </$Block>
  </$FlexBox>
)

export const Padding = (props, setProp) => {
  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
    align: 'center',
  }

  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = props
  const padding = `${paddingTop || 0}px ${paddingRight || 0}px ${
    paddingBottom || 0
  }px ${paddingLeft || 0}px`

  return (
    <$Block>
      <hr />
      <$FlexBox justify="space-between">
        <p>Padding</p>
        <p>{padding}</p>
      </$FlexBox>

      <$FlexBox justify="space-between">
        <$Block width="48%">
          <PropertyBar
            isFlex
            min={0}
            label="Top"
            propertyName="paddingTop"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            min={0}
            label="Right"
            propertyName="paddingRight"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            min={0}
            label="Bottom"
            propertyName="paddingBottom"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            min={0}
            label="Left"
            propertyName="paddingLeft"
            type="number"
            {...commonProps}
          />
        </$Block>
      </$FlexBox>
      <hr />
    </$Block>
  )
}

export const BorderRadius = (props, setProp) => {
  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
    align: 'center',
  }

  const {
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props
  const borderRadius = `${borderTopLeftRadius || 0}px ${
    borderTopRightRadius || 0
  }px ${borderBottomRightRadius || 0}px ${borderBottomLeftRadius || 0}px`

  return (
    <$Block>
      <hr />
      <$FlexBox justify="space-between">
        <p>Container Border Radius</p>
        <p>{borderRadius}</p>
      </$FlexBox>

      <$FlexBox justify="space-between">
        <$Block width="48%">
          <PropertyBar
            isFlex
            label="Top Left"
            propertyName="borderTopLeftRadius"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            label="Top Right"
            propertyName="borderTopRightRadius"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            label="Bottom Left"
            propertyName="borderBottomLeftRadius"
            type="number"
            {...commonProps}
          />
        </$Block>
        <$Block width="48%">
          <PropertyBar
            isFlex
            label="Bottom Right"
            propertyName="borderBottomRightRadius"
            type="number"
            {...commonProps}
          />
        </$Block>
      </$FlexBox>
    </$Block>
  )
}
