const initialState = {
    states:[]
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case "SET_STATES":
        return {
            ...state,
            states: action.payload
        };
        default:
        return state;
    }
}