import React from 'react'
import { useNode } from '@craftjs/core'

import { CouponSettings } from './CouponSettings'

export const Coupon = (props) => {
  const {
    connectors: { connect },
  } = useNode()

  const {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  return (
    <div ref={(ref) => connect(ref)}>
      <div
        style={{
          maxWidth: '100%',
          position: 'relative',
          overflow: 'hidden',
          margin: '0 auto',
          borderRadius,
          fontWeight: 'bold',
          textAlign: 'center',
          padding,
          width: props.width,
          backgroundColor: props.backgroundColor,
          color: props.color,
        }}
      >
        <big> {props.content} </big>
        <br />
        <small className={props.couponName}>{props.coupon}</small>
      </div>
    </div>
  )
}

export const CouponDefaultProps = {
  content: 'Use this Coupon to get 50%',
  couponName: 'DUMMY_COUPON',
  coupon: 'DUMMY_COUPON',
  color: '#FFF',
  backgroundColor: '#888',
  width: '320px',
  paddingTop: 25,
  paddingRight: 0,
  paddingBottom: 25,
  paddingLeft: 0,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
}

Coupon.craft = {
  props: CouponDefaultProps,
  related: {
    settings: CouponSettings,
  },
}
