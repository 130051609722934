import React from 'react'
import PropTypes from 'prop-types'

import { Bagdge } from 'assets/icons'
import { $ContainerBlock, $Rank } from './ProductRank.styled'

/**
 * product rank badge component
 * @param {Number} rank product rank
 */
const ProductRank = ({ rank, ...props }) => {
  return (
    <$ContainerBlock {...props}>
      <Bagdge />
      <$Rank>{rank}</$Rank>
    </$ContainerBlock>
  )
}

ProductRank.prototype = {
  rank: PropTypes.number.isRequired,
}

export default ProductRank
