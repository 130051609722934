import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'css/content/global.scss'
import { SelectVendor, Button } from '../Common'
import { BulbIcon } from 'assets/icons'
import { Div, FlexBox } from 'styledComponent'
import { KnowledgePopup } from 'components/Common/KnowledgePopup'
import { useSelectStore } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setShopifyOpen } from 'redux/actions/billingActions'

const HeaderBar = (props) => {
  const [isPopOpen, setIsPopOpen] = useState(false)

  const { data: bRed } = useSelectStore('bRed')
  const disaptch = useDispatch()
  const storeLogin = useSelector((state) => state.auth.user.storeLogin)
  const registeredFrom = useSelector(
    (state) => state.vendorIds.selectedVendor.registeredFrom
  )

  const isSubscribed = useSelector(
    (state) =>
      state.stores?.stores[Object.keys(state.stores?.stores)?.[0]]?.isSubscribed
  )
  const serviceSubscribtion = useSelector(
    (state) =>
      state.vendorIds.selectedVendor.featuresAlloted?.serviceSubscribtion
  )

  const homepage =
    storeLogin || registeredFrom === 'shopify' ? '/bills' : '/dashboard'
  useEffect(() => {
    if (props.heading === 'Billing & Usage' && !bRed?.onceOpened) {
      setIsPopOpen(true)
      disaptch(setShopifyOpen())
    }
  }, [props.heading, bRed?.onceOpened])

  return (
    <div className="manage-stores-header">
      {/* <StoresHeader /> */}
      <SelectVendor />
      <KnowledgePopup
        shopify={props.heading === 'Billing & Usage'}
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
      />
      <FlexBox>
        <Div p="8px">
          <Button
            variant="warning"
            fontSize="14px"
            startIcon={BulbIcon}
            iconSize="18px"
            onClick={() => setIsPopOpen(true)}
          >
            Knowledge Base
          </Button>
        </Div>
        {/* {props.heading && (
          <div className="breadcrumb">
            <div className="breadcrumb-item">
              <Link to={homepage}>Home</Link>
            </div>
            <div className="breadcrumb-item active" aria-current="page">
              {props.heading}
            </div>
          </div>
        )} */}
      </FlexBox>
    </div>
  )
}

export default HeaderBar
