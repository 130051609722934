import React from 'react'
import Popup from 'reactjs-popup'
import styled, { keyframes } from 'styled-components'

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`
const Spin1 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(0deg);
        }

        70% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
`
const Spin2 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(270deg);
        }

        70% {
          transform: rotate(270deg);
        }

        100% {
          transform: rotate(360deg);
        }
`
const Spin3 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(180deg);
        }

        70% {
          transform: rotate(180deg);
        }

        100% {
          transform: rotate(360deg);
        }
`
const Spin4 = keyframes`
    0% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(90deg);
    }

    70% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

export const $LoaderContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  transform-origin: center;
  animation: ${Spin} 3s infinite linear;
`

export const $LoaderItem = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &:before {
    display: block;
    width: 48%;
    height: 48%;
    border-radius: 0 40% 0 40%;
    background-color: #12b9f3;
    content: '';
  }

  &.item--1 {
    animation: ${Spin1} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--2 {
    animation: ${Spin2} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--3 {
    animation: ${Spin3} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--4 {
    animation: ${Spin4} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`

const Loader = () => (
  <$LoaderContainer>
    <$LoaderItem className="item--1" />
    <$LoaderItem className="item--2" />
    <$LoaderItem className="item--3" />
    <$LoaderItem className="item--4" />
  </$LoaderContainer>
)

export const LoaderPopup = ({ isPopOpen }) => {
  return (
    <Popup
      open={isPopOpen}
      overlayStyle={{ zIndex: 9999 }}
      contentStyle={{
        width: 'auto',
        background: 'transparent',
        border: 'none',
        zIndex: 9999,
      }}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <Loader />
    </Popup>
  )
}
