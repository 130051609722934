import React from 'react'

import CustomPopup from 'components/Content/CustomPopup'
import { Text } from 'components/Common/Text'
import { useToasts } from 'components/Common/Toast'
import { useSelectStore } from 'hooks'
import { deleteReq } from 'utils/axiosHandler'
import { PopupHeader, PopupFooter } from '../../components/ModalComponents'
import { $PopupBody } from './DeleteProductModal.styled'

/**
 * @description Delete product popup modal
 * @param {String} productId product id to be deleted
 * @param {Function} action refetch products action
 */
const DeleteProductModal = React.forwardRef(({ productId, action }, ref) => {
  const [deletingState, setDeletingState] = React.useState(false)

  const { toast } = useToasts()
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')

  const onClose = () => {
    ref.current.closeModal()
  }

  const onConfirm = async () => {
    setDeletingState(true)
    try {
      const url = `/product-analytics/products/${productId}`
      const body = {
        vendorId: selectedVendor.value,
      }

      await deleteReq(url, body)
      ref.current.closeModal()
      action()
    } catch (error) {
      console.log(error)
      toast('Something went wrong, please try again', 'error')
    } finally {
      setDeletingState(false)
    }
  }

  return (
    <CustomPopup
      className={'filter-modal'}
      ref={ref}
      headerComponent={true}
      heading={<PopupHeader onClose={onClose} />}
      footer={
        <PopupFooter
          onCancel={onClose}
          onConfirm={onConfirm}
          loading={deletingState}
        />
      }
    >
      <$PopupBody>
        <Text variant="p" weight="semi-bold">
          The product will be deleted permanently.
        </Text>
      </$PopupBody>
    </CustomPopup>
  )
})

export default DeleteProductModal
