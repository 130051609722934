import React, { useEffect, useRef, useState } from 'react'
import swal from 'sweetalert'
import queryString from 'query-string'
import ReactPaginate from 'react-paginate'
import { useLocation } from 'react-router-dom'
import { useDebouncedEffect, useRouter, useSelectStore } from 'hooks'

import { Div, FlexBox } from 'styledComponent'
import { Input, Text } from 'components/Common'
import ActiveFilterPills from 'components/Common/ActiveFilterPills'
import Filter from './Filters'
import {
  getUsageDataByType,
  setSortFilters,
  setUsageDataByType,
  setUsageFilters,
  setUsagePagination,
  setUsageTabCompany,
  setViewType,
} from 'redux/actions/usageAndInvoice'
import { useDispatch } from 'react-redux'
import { Table, useToasts } from 'components/Common'
import { convertApiResponseToTableData, convertFiltersToPayload } from './utils'
import { FilterOutlineButton } from 'styledComponent/components/button'

import { $SearchInput } from '../autoEngagement/AutoEngagement.styled'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import { $SquareButton } from '../consumerProfiling/CustomerProfiling'

import { ERROR_DECODE, GET_SEL_VALUE } from '../autoEngagement/constants'
import {
  USAGE_MAP,
  USAGE_OPTIONS,
  TABLE_META,
  DIGITAL_BILLING,
  AUTO_ENGAGEMENT,
} from './constants'
import { LIMIT } from './utils'

import 'css/content/usageAndInvoice.scss'
import { emailValidator } from 'utils/validators'
import { post } from 'utils/axiosHandler'

const exportUsageReport = async (
  filters,
  emailList,
  companyId,
  type,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const result = await post(`/company-usage/report/${companyId}/${type}`, {
    ...filters,
    emailList,
    timeZone,
  })
  return result
}

function UsageContent(props) {
  const {
    filters,
    viewType,
    data: apiData,
    selectedStoreIds = [],
    sortFilters,
  } = props
  const filterRef = useRef()

  const [isExport, setIsExport] = useState(false)
  const [tableLoading, setTableLoading] = useState(true)
  const [search, setSearch] = useState('')
  const { setQuery } = useRouter()
  const { search: locationQuery } = useLocation()

  const { data: page } = useSelectStore(`usageAndInvoice.usageTab.page`)
  const { data: company } = useSelectStore('usageAndInvoice.usageTab.company')
  const showModal = (_, forExport = false) => {
    filterRef.current.openModal()
    setIsExport(forExport)
  }
  const [sortColumns, setSortColumns] = useState(
    TABLE_META[viewType].columns.map((column) => column.isSortable && 'sort')
  )
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const data = convertApiResponseToTableData(viewType, apiData)
  const pillData = Object.values(filters || {}).filter(
    (filter) => filter.isActive
  )

  useEffect(() => {
    const { query } = queryString.parseUrl(locationQuery)
    try {
      dispatch(setUsageFilters(JSON.parse(query?.filters)))
    } catch {}
    try {
      dispatch(setSortFilters(JSON.parse(query?.sortFilters)))
    } catch {}

    try {
      dispatch(setUsageTabCompany(JSON.parse(query?.company)))
    } catch {}

    if (query?.page) dispatch(setUsagePagination(Number(query?.page)))
    if (query?.viewType) dispatch(setViewType(query?.viewType))
  }, [])

  useDebouncedEffect(
    () => getTableData(),
    [filters, viewType, search, selectedStoreIds, sortFilters, company, page],
    100
  )
  useEffect(() => {
    setSortColumns(
      TABLE_META[viewType].columns.map((column) => column.isSortable && 'sort')
    )
  }, [viewType])

  const getTableData = async () => {
    setTableLoading(true)

    try {
      await dispatch(
        getUsageDataByType(
          convertFiltersToPayload(
            filters,
            [DIGITAL_BILLING, AUTO_ENGAGEMENT].includes(viewType)
              ? selectedStoreIds
              : [],
            search,
            page,
            sortFilters
          )
        )
      )

      setQuery(
        {
          filters: encodeURIComponent(JSON.stringify(filters)),
          search,
          page,
          sortFilters: encodeURIComponent(JSON.stringify(sortFilters)),
          viewType,
          company: encodeURIComponent(JSON.stringify(company)),
        },
        {
          preservePrevState: true,
        }
      )
    } catch (e) {
      dispatch(setUsageDataByType([], viewType))
      toast(ERROR_DECODE(e), 'error')
    } finally {
      setTableLoading(false)
    }
  }
  const handleRemoveAll = () => {
    dispatch(setUsageFilters(USAGE_MAP[viewType].filters))
  }

  const sorting = (field, sort) => {
    switch (sort) {
      case 'increase':
        return { [field]: '1' }

      case 'decrease':
        return { [field]: '-1' }

      default:
        return { [field]: '' }
    }
  }
  const setSort = (ind) => {
    const sortDataColumns = TABLE_META[viewType].columns.map(
      (column) => column.isSortable && 'sort'
    )
    const scol = [...sortDataColumns]
    switch (sortColumns[ind]) {
      case 'sort': {
        scol[ind] = 'increase'
        break
      }
      case 'increase': {
        scol[ind] = 'decrease'
        break
      }
      default: {
        scol[ind] = 'sort'
        break
      }
    }
    dispatch(
      setSortFilters(
        sorting(TABLE_META[viewType].columns?.[ind]?.sortName, scol[ind])
      )
    )
    setSortColumns(scol)
  }

  const handleClosePill = (key) => {
    if (key === 'monthAndYear') {
      swal(
        'Cannot close this filter',
        'Month & Year filter is compulsory.',
        'error'
      )
      return
    }
    dispatch(
      setUsageFilters({ ...filters, [key]: USAGE_MAP[viewType].filters[key] })
    )
  }

  const validateFilters = (filter) => {
    let validationFlag = false
    Object.values(filter).forEach((filter) => {
      if (filter.type === 'emailList' && isExport) {
        if (filter.isActive) {
          if (filter.value?.length === 0) {
            toast(ERROR_DECODE('Atleast one email is required.'), 'error')
            validationFlag = true
            return
          } else {
            const emailValidation = filter.value.every(emailValidator)
            if (!emailValidation) {
              validationFlag = emailValidation
              toast(ERROR_DECODE('Invalid email format'), 'error')
              return
            }
          }
        } else {
          validationFlag = true
          toast(ERROR_DECODE('Email list is required.'), 'error')
          return
        }
      }

      if (filter.type === 'date-month-year') {
        if (!filter.isActive) {
          toast(ERROR_DECODE('Month & Year is required'), 'error')
          validationFlag = true
          return
        }
      }

      if (filter.type === 'range') {
        if (filter.isActive && filter.id === 'totalCharge') {
          if (filter.value?.min > filter.value?.max && filter.value?.max) {
            toast(ERROR_DECODE('Min value cannot be greater than max'), 'error')
            validationFlag = true
            return
          }
        }
        if (
          filter.isActive &&
          filter.value?.min === '' &&
          filter.value?.max === ''
        ) {
          toast(ERROR_DECODE('Min or max is required'), 'error')
          validationFlag = true
          return
        }
      }
      if (filter.type === 'multi-select') {
        if (filter.isActive && !!!filter.value.length) {
          toast(ERROR_DECODE(`${filter.label} is required`), 'error')
          validationFlag = true
          return
        }
      }
    })
    return validationFlag
  }

  return (
    <Div padding="1rem 0" height="100%">
      <FlexBox align="center" justify="space-between">
        <FlexBox align="center" flex="1">
          <Text
            variant="p"
            style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
          >
            Select Option
          </Text>
          <Input
            containerMargin="0"
            inputWidth="200px"
            variant="single-select"
            options={USAGE_OPTIONS}
            value={GET_SEL_VALUE(viewType, USAGE_OPTIONS)}
            // label="Select Search Parameter"
            onChange={({ value }) => {
              dispatch(setViewType(value))
            }}
          />
          <Div
            style={{
              borderRight: '1px solid #dadada',
              marginLeft: '30px',
              marginRight: '30px',
              height: '64px',
            }}
          />
          <ActiveFilterPills
            ref={filterRef}
            pillData={pillData}
            handleAdd={showModal}
            handleRemoveAll={handleRemoveAll}
            handleClosePill={handleClosePill}
          />
          <Filter
            ref={filterRef}
            key={viewType}
            data={filters}
            initialFilter={USAGE_MAP[viewType].filters}
            isExport={isExport}
            handleFilter={(filter) => {
              let validationFlag = validateFilters(filter)
              if (validationFlag) return
              dispatch(setUsageFilters(filter))
              filterRef.current.closeModal()
            }}
            setIsExport={setIsExport}
            handleExport={(filter) => {
              let validationFlag = validateFilters(filter)
              if (validationFlag) return
              const filters = convertFiltersToPayload(
                filter,
                [DIGITAL_BILLING, AUTO_ENGAGEMENT].includes(viewType)
                  ? selectedStoreIds
                  : [],
                search,
                page,
                sortFilters,
                LIMIT,
                true
              )
              exportUsageReport(
                filters,
                filter.emailList.value,
                company?.value,
                viewType
              )
                .then((result) => {
                  if (result)
                    toast(
                      'You will receive your report through email shortly.',
                      'success'
                    )
                })
                .catch((err) => {
                  toast(ERROR_DECODE(err, true), 'error')
                })
            }}
          />
        </FlexBox>
        <FlexBox gap="1rem" justify="space-between">
          {USAGE_MAP[viewType]?.canSearch ? (
            <$SearchInput>
              <span />
              <input
                id="searchTxt"
                value={search}
                placeholder={USAGE_MAP[viewType]?.searchPlaceholder}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </$SearchInput>
          ) : null}
          <FilterOutlineButton
            type="button"
            className="filter-btn"
            onClick={showModal}
          >
            <FilterIcon className="my-1" />
          </FilterOutlineButton>
          {USAGE_MAP[viewType].canExport ? (
            <$SquareButton mr={0} onClick={(e) => showModal(e, true)} size={40}>
              <ExcelIcon />
            </$SquareButton>
          ) : null}
        </FlexBox>
      </FlexBox>
      <Table
        theme={{
          spacing: {
            l: '0',
          },
        }}
        style={{
          marginTop: '10px',
        }}
        columns={
          TABLE_META[viewType]?.columns?.map((column) => column.name) || []
        }
        list={data || []}
        row={(item) =>
          TABLE_META[viewType]?.columns?.map((column) => (
            <div className="usage-content__cell">{column.cell(item)}</div>
          ))
        }
        setSort={(ind) => {
          if (TABLE_META[viewType]?.columns?.[ind]?.isSortable) setSort(ind)
        }}
        sortColumns={sortColumns}
        tableLoading={tableLoading}
        showPagination={false}
      />
      {TABLE_META[viewType]?.showPagination && apiData?.count ? (
        <FlexBox
          justify="space-between"
          align="center"
          className="usagePaginationContainer"
        >
          {!tableLoading ? (
            <Text variant="medium" type="grey">
              Showing {page * LIMIT + apiData?.docs?.length} - {apiData?.count}
            </Text>
          ) : (
            <div></div>
          )}
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={Math.floor(apiData?.count / LIMIT) + 1}
            onPageChange={(_page) => {
              dispatch(setUsagePagination(_page.selected))
            }}
            containerClassName="aePagination usagePaginationOverride"
            activeClassName="pagination_activePage"
            forcePage={page}
          />
        </FlexBox>
      ) : null}
    </Div>
  )
}

export default UsageContent
