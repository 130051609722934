import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { BannerCampaign } from './BannerCampaign'
import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'
import { BANNER_CAMP_STEPS, CAMP_INIT, CHECK_IF } from '../constants'
import { $WhiteContainer } from '../AutoEngagement.styled'
import { useCampaignApi } from '../components'
import { validateScheduleCampaignDateTime } from '../advert'

export const BannerInBill = () => {
  const [billForm, setBillForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(billForm, setBillForm, CAMP_INIT, 'bannerInBill')

  useEffect(() => {
    const step = hideSteps
      ? { id: 4, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && campaignId && updCampaign()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...billForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (campaignId && campaignId !== 'create'
            ? updCampaign()
            : createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...billForm?.content?.body }
        const check = CHECK_IF(['componentType'], builderDetails)
        if (check) throw `Please select a component`
        if (builderDetails?.assetsDetails?.length < 1)
          throw 'Please add an asset for your campaign.'
        if (!builderDetails?.assetsDetails[0]?.fileLocation)
          throw 'Please add an asset for your campaign.'

        return await updateCall()
      }

      case 3: {
        const storeDetails = { ...billForm?.targetGroup }
        if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
          throw 'Please select a brand and at least one store.'

        const isScheduleErrorPresent = validateScheduleCampaignDateTime(
          billForm
        )
        if (isScheduleErrorPresent !== false) throw isScheduleErrorPresent

        return await updateCall()
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Banner in Bill"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={BANNER_CAMP_STEPS}
        hideSteps={hideSteps}
        onSave={onSave}
        onPublish={onPublish}
        onPreviousClick={fetchCampaignById}
        form={billForm}
        campaignType="Banner in Bill"
      >
        <BannerCampaign
          form={billForm}
          setForm={setBillForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}
