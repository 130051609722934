import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomPopup from 'components/Content/CustomPopup'
import CampaignScreenOne from './CampaignScreenOne'
import CampaignScreenTwo from './CampaignScreenTwo'
import CampaignScreenThree from './CampaignScreenThree'
import PreviewModal from './PreviewModal'
import { ModalFooter, ModalHeader } from 'components/Common/ModalComponents'
// import { setCampaignMeta } from '../../../redux/actions/feedbackActions'

class CampaignModal extends Component {
  render() {
    return (
      <>
        <CustomPopup
          heading={
            <ModalHeader
              title={
                this.props.screen !== 3
                  ? 'New Campaign - Create your Feedback form'
                  : 'Preview Campaign'
              }
              // TODO: Make a function for closing the modal and give condition to only reset state if screen !== 3
              onClose={() => this.props.innerRef.current.closeModal()}
            />
          }
          headerComponent
          footer={
            <ModalFooter
              handleBack={this.props.handleBack}
              handleScreenChange={this.props.handleScreenChange}
              showBackButton={this.props.showBackButton}
            />
          }
          ref={this.props.innerRef}
          className="campaign-modal-view"
          contentStyle={{
            width: '60%',
            maxWidth: '1000px',
            borderRadius: '15px',
          }}
        >
          {this.props.screen === 0 && (
            <CampaignScreenOne
              brandId={this.props.brandId}
              hasError={this.props.error && this.props.error.brand}
              handleBrandSelect={this.props.handleBrandSelect}
            />
          )}
          {this.props.screen === 1 && (
            <CampaignScreenTwo
              brandLogo={this.props.brandLogo}
              hasError={this.props.error && this.props.error.store}
              handleStoresSelect={this.props.handleStoresSelect}
              storesId={this.props.storesId}
              brandId={this.props.brandId}
              allStoresSelectAll={this.props.allStoresSelectAll}
              storeHandleSelectAll={this.props.storeHandleSelectAll}
            />
          )}
          {this.props.screen === 2 && (
            <CampaignScreenThree
              hasError={this.props.error && this.props.error.campaignName}
              brandName={this.props.brandName}
              brandLogo={this.props.brandLogo}
              stores={this.props.stores}
              campaignName={this.props.campaignName}
              handleCampaignName={this.props.handleCampaignName}
            />
          )}
          {this.props.screen === 3 && (
            <PreviewModal
              brandName={this.props.brandName}
              card={this.props.card}
              filledRating={this.props.filledRating}
              fbHeading={this.props.fbHeading}
            />
          )}
        </CustomPopup>
      </>
    )
  }
}

const ConnectedComp = connect(null, null)(CampaignModal)

export default React.forwardRef((props, ref) => (
  <ConnectedComp innerRef={ref} {...props} />
))
