import React, { useEffect, useState } from 'react'

import { spacing } from 'design-system'
import { Text, useToasts } from 'components/Common'
import MediaUpload from '../components/MediaUpload'
import { FlexBox, Div } from 'styledComponent'
import { $SBBPdfPreview } from '../AutoEngagement.styled'
import { Pdf } from 'components/Common/Pdf'
import {
  getAssetsList,
  uploadFiles,
  setCampaignLoader,
} from 'redux/actions/autoEngageActions'
import { setLoadJourney } from 'redux/actions/journeyActions'
import { RecentUploads } from '../components/RecentUploads'
import { connect } from 'react-redux'
import { uploadMedia } from '../components/builderUtils'

const PdfUpload = (props) => {
  const {
    form,
    setForm,
    vendorId,
    storeUser,
    assetsCount,
    assetsList,
    isJourney = false,
  } = props
  const [limit, setLimit] = useState(10)

  const { toast } = useToasts()

  const file = form?.content?.body?.assetsDetails[0]?.fileLocation

  const fetchFiles = (lim = 10) =>
    props.getAssetsList({
      vendorId,
      storeUser,
      skip: 0,
      limit: lim,
      fileType: 'pdf',
    })

  useEffect(() => {
    vendorId && fetchFiles(limit)
  }, [limit])

  const uploadFile = async (e) =>
    await uploadMedia(
      e,
      isJourney ? props.setLoadJourney : props.setCampaignLoader,
      ['pdf'],
      vendorId,
      storeUser,
      setMedia,
      fetchFiles,
      limit,
      uploadFiles,
      toast,
      'sellBelowBill'
    )

  const setMedia = ({ fileLocation, _id, key }) => {
    const assets = [{ fileLocation, assetId: _id, key }]
    setForm({
      ...form,
      content: {
        body: {
          componentType: 'pdf',
          assetsDetails: assets,
        },
      },
    })
  }

  return (
    <>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        PDF Upload
      </Text>
      <FlexBox gap={spacing.l} justify="space-between">
        <Div width="100%">
          <MediaUpload
            title="Add/Generate link for PDF"
            fileType="PDF"
            acceptedFormats=".pdf"
            onChange={uploadFile}
            uploadWidth="100%"
            uploadHeight="450px"
            advert
          />
        </Div>
        <$SBBPdfPreview>
          <Pdf url={file} />
        </$SBBPdfPreview>
      </FlexBox>
      <hr />
      <RecentUploads
        limit={limit}
        setLimit={setLimit}
        assetsCount={assetsCount}
        assetsList={assetsList}
        setMedia={setMedia}
        mediaType="pdf"
        activeArray={form?.content?.body?.assetsDetails?.map(
          (asset) => asset.fileLocation
        )}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  assetsList: state.autoEngage.assetsPdfList?.docs || [],
  assetsCount: state.autoEngage.assetsPdfList?.count || 0,
  storeUser: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, {
  getAssetsList,
  setCampaignLoader,
  setLoadJourney,
})(PdfUpload)
