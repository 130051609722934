export const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const isArrayElementsEmpty = value => {
  let isEmpty = 0
  value.forEach(item => {
    if(!item) isEmpty+=1
  })

  return isEmpty === value.length ? true : false
}
