import React, { Component } from 'react'
// import LeftSide from "./LeftSide";
import { connect } from 'react-redux'
import { updatePhone, postLoginSuccess } from 'redux/actions/authActions'
// import { toast } from "react-toastify";
import { Button, MyToast } from 'components/Common'
import { spacing } from 'design-system'
import { LoginContainer, LoginInput } from './Login'
import { FlexBox } from 'styledComponent'

class AddPhoneNo extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: '',
    }
    this.onChange = this.onChange.bind(this)
    this.onSkip = this.onSkip.bind(this)
    this.addPhone = this.addPhone.bind(this)
  }

  onChange(e) {
    if (e.target.value.length <= 10) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  onSkip(e) {
    e && e.preventDefault()
    console.log(this.props.history)
    this.props.history.push(
      this.props?.auth?.user?.storeLogin ||
        this.props.registeredFrom === 'shopify'
        ? '/bills'
        : '/dashboard'
    )
  }

  async addPhone(e) {
    e && e.preventDefault()
    const { phoneNumber } = this.state
    if (phoneNumber && phoneNumber.length === 10) {
      const res = await updatePhone({ phoneNumber })
      if (res.code) {
        // toast.success(res.message);
        this.context.toast(res.message, 'success')
        this.props.history.push(
          this.props?.auth?.user?.storeLogin ||
            this.props.registeredFrom === 'shopify'
            ? '/bills'
            : '/dashboard'
        )
      } else {
        // toast.error(res.message);
        this.context.toast(res.message, 'error')
      }
    } else {
      // toast.error("Phone number should be 10 digit.");
      this.context.toast('Phone number should be 10 digit.', 'error')
    }
  }

  render() {
    return (
      <LoginContainer>
        <h4>Add Contact no.</h4>
        <br />
        <form>
          <div className="form-group">
            <label>Phone Number</label>
            <FlexBox align="center">
              <i className="fas fa-phone" style={{ position: 'absolute' }} />
              <LoginInput
                type="number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.onChange}
                paddingLeft={spacing.xl}
              />
            </FlexBox>
          </div>
          <center>
            <div className="updateBtn">
              <button
                type="button"
                onClick={this.onSkip}
                className="btn skipButton"
              >
                Skip
              </button>
              <Button variant="success" onClick={this.addPhone} type="submit">
                Add
              </Button>
            </div>
          </center>
        </form>
      </LoginContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.auth.userData,
    registeredFrom: state.vendorIds.selectedVendor.registeredFrom,
    isSubscribed:
      state.stores?.stores[Object.keys(state.stores?.stores)?.[0]]
        ?.isSubscribed,
    onceOpened: state.bRed.onceOpened,
  }
}

export default connect(mapStateToProps, { postLoginSuccess })(AddPhoneNo)
