import React, { useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { connect } from 'react-redux'
import { Div, FlexBox } from 'styledComponent'
import Chart from 'react-apexcharts'
import { PieChart, Skeleton, Text, useToasts } from 'components/Common'
import { getGraphData } from 'redux/actions/billingAnalyticsActions'
import { ERROR_DECODE } from 'utils/reusables'
import moment from 'moment'
import { FormattedNumber } from 'react-intl'
import { StatsInfo } from '../StatsInfo'
import { calcPercent } from '../constants'

const Graphs = ({ bAnalytics, vendorId, filterQuery, currencyCode }) => {
  const { toast } = useToasts()
  const { spacing } = useTheme()
  const [isActive, setIsActive] = useState('Total Sales')
  const [isGraphLoading, setIsGraphLoading] = useState(false)
  const [graphData, setGraphData] = useState([{ data: [] }])

  const dashboardItems = [
    {
      value: bAnalytics?.total?.totalSales,
      label: 'Total Sales',
      color: '#33475B',
      enum: 'totalSales',
    },
    {
      value: bAnalytics?.total?.avgBilling,
      label: 'Average Billing',
      color: '#33475B',
      enum: 'avgBillingAmount',
    },
    {
      value: bAnalytics?.noOfTransactions?.total,
      label: 'Total Transactions',
      color: '#33475B',
      enum: 'totalTransactions',
    },
  ]

  const transactionItems = [
    {
      value: bAnalytics?.noOfTransactions?.BM,
      percentage: calcPercent(
        bAnalytics?.noOfTransactions?.BM,
        bAnalytics?.noOfTransactions?.total
      ),
      label: 'BillMe',
      color: '#00ABF0',
    },
    {
      value: bAnalytics?.noOfTransactions?.print,
      percentage: calcPercent(
        bAnalytics?.noOfTransactions?.print,
        bAnalytics?.noOfTransactions?.total
      ),
      label: 'Print',
      color: '#D7443E',
    },
    {
      value: bAnalytics?.noOfTransactions?.BMprint,
      percentage: calcPercent(
        bAnalytics?.noOfTransactions?.BMprint,
        bAnalytics?.noOfTransactions?.total
      ),
      label: 'BillMe + Print',
      color: '#FFA726',
    },
    // {
    //   value: bAnalytics?.noOfTransactions?.CW,
    //   percentage: calcPercent(
    //     bAnalytics?.noOfTransactions?.CW,
    //     bAnalytics?.noOfTransactions?.total
    //   ),
    //   label: 'Closed Window',
    //   color: '#33475B',
    // },
  ]

  const isStatsLoading =
    bAnalytics.transactionLoading && bAnalytics.totalLoading

  useEffect(() => {
    if (isActive !== 'Transactions') getGraphs()
  }, [isActive, filterQuery])

  const getGraphs = async () => {
    const reqType = dashboardItems?.find(
      (dash) => isActive === dash?.label
    )?.enum
    try {
      setIsGraphLoading(true)
      const res = await getGraphData({
        startDate: moment(filterQuery?.startDate).format('YYYY-MM-DD'),
        endDate: moment(filterQuery?.endDate).format('YYYY-MM-DD'),
        vendorId,
        selectedStoreIds: filterQuery?.selectedStoreIds || [],
        reqType,
      })
      if (res) {
        const data = res?.data?.map((dt) => ({
          x: moment(dt?._id)?.format('DD MMM YY'),
          y: dt?.[reqType]?.toFixed(2),
        }))
        setGraphData(data)
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setIsGraphLoading(false)
    }
  }

  return (
    <Div>
      <FlexBox gap="16px">
        {dashboardItems.map((dash, i) => (
          <$Card
            key={dash?.label}
            isActive={isActive === dash?.label}
            onClick={() => setIsActive(dash?.label)}
          >
            {isStatsLoading ? (
              <Skeleton height="64px" />
            ) : (
              <>
                <div className="value">
                  {i === 2 ? (
                    Number(dash?.value || 0)?.toLocaleString('en-IN')
                  ) : (
                    <FormattedNumber
                      currency={currencyCode}
                      style="currency"
                      value={dash?.value}
                    />
                  )}
                </div>
                <div style={{ color: dash?.color }} className="label">
                  {dash?.label}
                </div>
              </>
            )}
          </$Card>
        ))}
        <$Card
          flex={5}
          isActive={isActive === 'Transactions'}
          onClick={() => setIsActive('Transactions')}
        >
          {bAnalytics.totalLoading ? (
            <Skeleton height="64px" />
          ) : (
            <FlexBox justify="space-between">
              {transactionItems?.map((dash, i) => (
                <div className="minis" key={dash?.label}>
                  <div className="value">
                    <span className="val">
                      {Number(dash?.value || 0)?.toLocaleString('en-IN')}
                    </span>
                    <span style={{ color: dash?.color }} className="percent">
                      {dash?.percentage}%
                    </span>
                  </div>
                  <div style={{ color: dash?.color }} className="label">
                    {dash?.label}
                  </div>
                </div>
              ))}
            </FlexBox>
          )}
          <Div position="absolute" top="16px" right="16px">
            <StatsInfo />
          </Div>
        </$Card>
      </FlexBox>
      <$GraphContainer>
        <Text weight="semi-bold" variant="h3" mb={spacing.l}>
          {isActive}
        </Text>
        {isActive === 'Transactions' ? (
          <>
            {isStatsLoading ? (
              <Skeleton height="400px" />
            ) : (
              <FlexBox wrap="wrap" justify="center" mt={spacing.xl}>
                {bAnalytics?.noOfTransactions?.total == 0 ? (
                  <Text weight="semi-bold" variant="h3" mb={spacing.l}>
                    No transactions were found for the selected date.
                  </Text>
                ) : (
                  <PieChart
                    data={transactionItems?.map((trs) => ({
                      key: `${trs?.label} (${(
                        (Number(trs?.value) * 100) /
                        Number(bAnalytics?.noOfTransactions?.total)
                      )?.toFixed(2)}%)`,
                      value: Number(
                        (
                          (Number(trs?.value) * 100) /
                          Number(bAnalytics?.noOfTransactions?.total)
                        )?.toFixed(2)
                      ),
                      color: trs?.color,
                    }))}
                    width={800}
                    height={400}
                  />
                )}
              </FlexBox>
            )}
          </>
        ) : (
          <>
            {isGraphLoading ? (
              <Skeleton height="400px" />
            ) : (
              <Chart
                height={400}
                width="100%"
                type="line"
                series={[
                  {
                    name: isActive,
                    data: graphData,
                  },
                ]}
                options={{
                  chart: {
                    id: 'basic-bar',
                    toolbar: {
                      show: false,
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                  },
                  xaxis: {
                    categories: graphData?.map((ser) => ser?.x),
                  },
                }}
              />
            )}
          </>
        )}
      </$GraphContainer>
    </Div>
  )
}

const mapStateToProps = (state) => {
  return {
    filterQuery: state.bills.filterQuery,
    bAnalytics: state.bAnalytics,
    vendorId: state.vendorIds.selectedVendor.value,
    currencyCode: state.auth.user.currencyCode,
  }
}

export default connect(mapStateToProps)(Graphs)

export const $Card = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px #00000026;
  padding: 16px 28px;
  border-radius: 8px;
  color: #33475b;
  cursor: pointer;
  flex: ${(props) => props.flex || 1};
  ${(props) =>
    props.isActive &&
    css`
      box-shadow: none;
      border: 2px solid #3b86ff;
    `}
  .minis {
    margin: 0 24px;
  }
  .value {
    display: flex;
    font-size: 26px;
    gap: 16px;
    font-weight: 700;
    white-space: pre;
    align-items: baseline;
    .percent {
      font-size: 20px;
      text-align: right;
    }
  }
`

export const $GraphContainer = styled.div`
  box-shadow: 0px 0px 5px #00000026;
  padding: 16px 28px;
  margin: 24px 0 0;
  border-radius: 8px;
  min-height: 500px;
`
