import React from 'react'
import { PlusIcon } from 'assets/icons'

export const MediaUpload = (props) => {
  return (
    <div>
      <p
        style={{
          fontWeight: '300',
          color: '#808080',
          fontSize: '10px',
          fontStyle: 'italic',
        }}
      >
        (Max 5 images upto 10mb. *.png, .jpg, .jpeg supported)
      </p>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '10px',
        }}
      >
        {props.answers?.answerFiles?.length > 0
          ? props.answers?.answerFiles.map((image, index) => (
              <div key={index} style={{ position: 'relative', margin: '10px' }}>
                <img
                  src={image}
                  alt={'image'}
                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                />
              </div>
            ))
          : ''}
        {(Object.keys(props.answers).length === 0 ||
          props.answers?.answerFiles?.length < 5) && (
          <div
            style={{
              cursor: 'pointer',
              padding: '5px',
              border: '3px dashed #ccc',
              borderRadius: '3px',
              width: '100px',
              height: '100px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px',
            }}
          >
            <PlusIcon style={{ fontSize: '24px' }} />
            <span style={{ fontSize: '12px' }}>Add Image</span>
          </div>
        )}
      </div>
    </div>
  )
}
