import React, { useState } from 'react'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { GET_SEL_VALUE } from '../constants'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { useSurveyApi } from '../survey/table/useSurveyApi'

const AsyncSurveySelect = (props) => {
  const [search, setSearch] = useState('')
  useSurveyApi(props.vendorId, search, 0, '', 'live')

  const loadOptions = props.surveyData?.map((opt) => ({
    value: opt._id,
    label: opt.name,
  }))

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon width="15px" height="15px" />
      </components.DropdownIndicator>
    )
  }

  const handleSurveySelectClick = (value) => setSearch(value)

  return (
    <Select
      options={loadOptions}
      onInputChange={handleSurveySelectClick}
      placeholder="Search & Select Survey"
      value={GET_SEL_VALUE(props.value, loadOptions) || ''}
      menuIsOpen={false}
      components={{ DropdownIndicator }}
    />
  )
}

const mapStateToProps = (state) => ({
  vendorId: state.vendorIds.selectedVendor.value,
  surveyData: state.autoEngage.surveyData.docs || [],
})

export default connect(mapStateToProps)(AsyncSurveySelect)
