import styled from 'styled-components'

import { spacing } from 'design-system'

const $DragHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.xl} 0 ${spacing.ml};
`

export { $DragHead }
