import React, { useState } from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $FlexBox, $Block, $ToolGroup, $ToolItem } from '../../Editor.styled'
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  StrikethroughIcon,
  OrderedIcon,
  UnorderedIcon,
  LeftAlignIcon,
  RightAlignIcon,
  CenterAlignIcon,
  LinkIcon,
  UserIcon,
  InsertCouponIcon,
  InsertSurveyIcon,
} from 'assets/icons'
import PropertyHeader from '../../PropertyHeader'
import {
  PropertyBar,
  Padding,
  FontFamily,
} from 'builder/editor/common/PropertyBar'
import { PopupContainer, Input, useToasts } from 'components/Common'
import { UserAttributeModal } from 'components/Content/autoEngagement/components/UserAttributeModal'
import { useRouter } from 'hooks'
import { SelectCoupon } from 'components/Content/autoEngagement/components/SelectCoupon'
import { SelectSurvey } from 'components/Content/autoEngagement/components/SelectSurvey'

export const TextSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [componentType, setComponentType] = useState('')
  const [linking, setLinking] = useState({
    text: '',
    link: '',
  })

  const { location } = useRouter()
  const { toast } = useToasts()
  const isSurvey = location.pathname.split('/').includes('surveyInBill')

  const handleChange = (e) => {
    setLinking({
      ...linking,
      [e.target.id]: e.target.value,
    })
  }

  const generateLink = (
    value = ` <a target="_blank" rel="noopener" href="${linking.link}">${linking.text}</a>`
  ) => {
    setProp((props) => (props.content = props.content + value), 300)
  }

  const insertCoupon = (coup) => {
    setProp(
      (props) => (props.content = props.content + ` $coupon[${coup._id}]`),
      300
    )
    setIsPopOpen(false)
  }

  const insertSurvey = (survey) => {
    setLinking({
      ...linking,
      link: `$survey[${survey.surveyId}]`,
    })
  }
  const onSurveyorLinkSubmit = () => {
    if (!linking.link || !linking.text) {
      toast('Please enter all details before proceeding.', 'error')
      throw 'Incomplete'
    }
    generateLink()
    setIsPopOpen(false)
  }

  const setTextAlign = (align) => {
    setProp((props) => (props.textAlign = align), 300)
  }

  const showPop = (type) => () => {
    setComponentType(type)
    setIsPopOpen(true)
  }

  const onCancel = () => {
    setLinking({
      text: '',
      link: '',
    })
    setIsPopOpen(false)
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Text"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <PropertyBar
        isFlex
        type="color"
        label="Text Colour"
        setProp={setProp}
        craftProps={props}
        propertyName="color"
        justify="space-between"
        margin="8px 0px"
      />

      <PropertyBar
        isFlex
        type="color"
        label="Background Colour"
        setProp={setProp}
        craftProps={props}
        propertyName="backgroundColor"
        justify="space-between"
        margin="8px 0px"
      />
      <$FlexBox justify="space-between" margin="8px 0">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>Text Style</p>
        <$ToolGroup>
          <$ToolItem
            onClick={() => document.execCommand('bold')}
            isActive={document.queryCommandState('bold')}
          >
            <BoldIcon />
          </$ToolItem>
          <$ToolItem
            onClick={() => document.execCommand('italic')}
            isActive={document.queryCommandState('italic')}
          >
            <ItalicIcon />
          </$ToolItem>
          <$ToolItem
            isActive={document.queryCommandState('underline')}
            onClick={() => document.execCommand('underline')}
          >
            <UnderlineIcon />
          </$ToolItem>
          <$ToolItem
            isActive={document.queryCommandState('strikethrough')}
            onClick={() => document.execCommand('strikethrough')}
          >
            <StrikethroughIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <$FlexBox justify="space-between" margin="8px 0">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>Alignment</p>
        <$ToolGroup>
          <$ToolItem
            isActive={props.textAlign === 'left'}
            onClick={() => setTextAlign('left')}
          >
            <LeftAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'center'}
            onClick={() => setTextAlign('center')}
          >
            <CenterAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'right'}
            onClick={() => setTextAlign('right')}
          >
            <RightAlignIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <$FlexBox justify="space-between" margin="8px 0">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>Order Format</p>
        <$ToolGroup>
          <$ToolItem
            isActive={document.queryCommandState('InsertOrderedList')}
            onClick={() => document.execCommand('insertOrderedList')}
          >
            <OrderedIcon />
          </$ToolItem>
          <$ToolItem
            isActive={document.queryCommandState('insertUnorderedList')}
            onClick={() => document.execCommand('insertUnorderedList')}
          >
            <UnorderedIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <$FlexBox justify="space-between" margin="8px 0">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>
          Selected Text Colour
        </p>
        <input
          type="color"
          onChange={(e) =>
            document.execCommand('foreColor', true, e.target.value)
          }
        />
      </$FlexBox>

      <$FlexBox justify="space-between" margin="8px 0">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>
          Selected Text Highlight Colour
        </p>
        <input
          type="color"
          onChange={(e) =>
            document.execCommand('backColor', true, e.target.value)
          }
        />
      </$FlexBox>

      <PropertyBar
        label="Font Size"
        setProp={setProp}
        craftProps={props}
        propertyName="fontSize"
        type="number"
        isFlex
        isSplit
        margin="8px 0px"
      />
      {FontFamily(props, setProp)}

      <hr />
      {!isSurvey && (
        <$FlexBox justify="space-between" margin="8px 0">
          <p style={{ marginBottom: 0, fontSize: '12px' }}>Insert</p>
          <$FlexBox gap="20px">
            <LinkIcon title="Link" type="button" onClick={showPop('link')} />
            <InsertCouponIcon
              title="Coupon"
              type="button"
              onClick={showPop('coupon')}
            />
            <InsertSurveyIcon
              title="Survey"
              type="button"
              onClick={showPop('survey')}
            />
            <UserIcon
              title="User Attribute"
              type="button"
              onClick={showPop('user')}
            />
          </$FlexBox>
        </$FlexBox>
      )}

      {Padding(props, setProp)}

      {componentType === 'user' && (
        <UserAttributeModal
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          onAttributeChange={(value, field) =>
            generateLink(` $${field}[${value}]`)
          }
        />
      )}
      {componentType === 'link' && (
        <PopupContainer
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          heading="Insert Linked Text"
          onSubmitClick={onSurveyorLinkSubmit}
        >
          <$Block>
            <Input
              variant="input"
              label="Enter Link Text"
              placeholder="Click here"
              value={linking.text || ''}
              onChange={handleChange}
              id="text"
              inputWidth="100%"
            />
            <Input
              variant="input"
              label="Enter Link"
              placeholder="https://"
              value={linking.link || ''}
              onChange={handleChange}
              id="link"
              inputWidth="100%"
            />
          </$Block>
        </PopupContainer>
      )}

      {componentType === 'coupon' && (
        <PopupContainer
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          heading="Select Coupon"
          onSubmitClick={() => setIsPopOpen(false)}
        >
          <SelectCoupon onCouponSelect={insertCoupon} />
        </PopupContainer>
      )}

      {componentType === 'survey' && (
        <PopupContainer
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          heading="Select Survey"
          onSubmitClick={onSurveyorLinkSubmit}
          onCancel={onCancel}
        >
          <Input
            variant="input"
            label="Enter Survey Link Text"
            placeholder="Click here to view"
            value={linking.text || ''}
            onChange={handleChange}
            id="text"
            inputWidth="100%"
          />
          <SelectSurvey
            onSurveySelect={insertSurvey}
            selectedSurveyId={(linking.link || '')
              .replace('$survey[', '')
              .replace(']', '')}
          />
        </PopupContainer>
      )}
    </$Block>
  )
}
