import * as types from '../types/productAnalytics'
import { serviceRequest, post, get, deleteReq } from 'utils/axiosHandler'
import { setSelectedStore } from './authActions'
import { isEqual } from 'lodash'

export const setProductAnalyticsDate = (selectedDate) => (dispatch) => {
  dispatch({
    type: types.SET_PRODUCT_ANALYTICS_DATE,
    payload: {
      selectedDate,
    },
  })
}

const generateAPICalls = (urls, body, method = 'post') => {
  const requests = []
  if (!urls || !urls.length) return requests
  urls.forEach((url) => {
    requests.push(serviceRequest({ path: url, data: body, method }))
  })
  return requests
}

export const getDashboardData = () => async (dispatch, getState) => {
  const state = getState()
  const {
    stores: { stores },
    productAnalytics: { selectedDate, selectedStores: storeIds },
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const urls = [
    '/product-analytics/overview',
    '/product-analytics/top-products',
    '/product-analytics/category-analytics',
  ]
  const body = {
    ...selectedDate?.date,
    storeIds,
    vendorId,
    brandId,
  }

  try {
    const actions = [setOverviewStats, setTopProducts, setCategoryAnalytics]
    const responses = await Promise.allSettled(generateAPICalls(urls, body))
    responses.forEach((response, index) => {
      dispatch(actions[index](response?.value?.data?.data || null))
    })
  } catch (error) {
    console.log(error)
  }
}

const setOverviewStats = (overviewStats) => ({
  type: types.SET_OVERVIEW_STATS,
  payload: {
    overviewStats,
  },
})

const setTopProducts = (topProducts) => ({
  type: types.SET_TOP_FIVE_PRODUCTS,
  payload: {
    topProducts,
  },
})

const setCategoryAnalytics = (categoryAnalytics) => ({
  type: types.SET_CATEGORY_ANALYTICS,
  payload: {
    categoryAnalytics,
  },
})

const setPALoading = (paLoading) => ({
  type: types.SET_CATEGORY_ANALYTICS,
  payload: { paLoading },
})

export const getTopProductCombinations = () => async (dispatch, getState) => {
  const state = getState()
  const {
    productAnalytics: { selectedStores: storeIds },
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const body = {
    storeIds,
    vendorId,
    brandId,
  }

  try {
    const res = await post('/product-analytics/top-combination-products', body)

    dispatch({
      type: types.SET_TOP_PRODUCT_COMBINATIONS,
      payload: {
        topProductCombinations: res?.data || null,
      },
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.SET_TOP_PRODUCT_COMBINATIONS,
      payload: {
        topProductCombinations: null,
      },
    })
  }
}

export const getProductsCount = () => async (dispatch, getState) => {
  dispatch(setPALoading(true))
  const state = getState()
  const {
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const body = { vendorId, brandId }
  try {
    const res = await post('/product-analytics/products/products-count', body)
    dispatch({
      type: types.SET_PRODUCTS_COUNT,
      payload: {
        productsCount: res?.data?.count,
      },
    })
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(setPALoading(false))
  }
}

export const getAllProducts = (data) => async (dispatch, getState) => {
  const state = getState()
  const {
    productAnalytics: { selectedDate, appliedProductsFilter },
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const filters = { ...(appliedProductsFilter || {}) }

  if (filters?.uam?.value) {
    filters.uam = filters?.uam?.value
  }

  const body = {
    ...(selectedDate?.date || {}),
    vendorId,
    brandId,
    page: 1,
    limit: 10,
    ...filters,
  }
  try {
    const res = await post('/product-management/products/list', {
      ...body,
      ...data,
    })
    dispatch({
      type: types.SET_ALL_PRODUCTS_LIST,
      payload: {
        allProducts: res || null,
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export const getFilterOptions = () => async (dispatch, getState) => {
  const state = getState()
  const {
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const getUrls = [
    `/product-management/product-categories/list?vendorId=${vendorId}&brandId=${brandId}`,
    '/product-management/products/uam',
  ]

  try {
    let options = {}
    const responses = await Promise.allSettled(
      generateAPICalls(getUrls, {}, 'get')
    )

    options.categories = responses[0]?.value?.data?.data || null
    options.uam = responses[1]?.value?.data?.data || null

    dispatch({
      type: types.SET_FILTER_OPTIONS,
      payload: {
        productsFilterOptions: options,
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export const setAppliedFilter = (appliedProductsFilter) => ({
  type: types.SET_APPLIED_PRODUCTS_FILTER,
  payload: {
    appliedProductsFilter,
  },
})

export const getProductDetails = (productId) => async (dispatch, getState) => {
  const state = getState()
  const {
    stores: { stores },
    productAnalytics: { selectedDate, selectedStores: storeIds },
    vendorIds: {
      selectedVendor: { value: vendorId },
      selectedBrand: { value: brandId },
    },
  } = state

  const body = {
    ...(selectedDate?.date || {}),
    storeIds,
    vendorId,
    brandId,
  }

  try {
    const res = await post(`/product-management/products/${productId}`, {
      ...body,
    })
    dispatch({
      type: types.SET_PRODUCT_DETAILS,
      payload: {
        productDetails: res?.data?.data || null,
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export const deleteCategory = async (categoryId) =>
  await deleteReq(`/product-management/product-categories/${categoryId}`)

export const getAllCategories = () => async (dispatch, getState) => {
  const state = getState()

  const vendorId = state?.vendorIds?.selectedVendor?.value
  const brandId = state?.vendorIds?.selectedBrand?.value

  dispatch(setPALoading(true))
  try {
    const res = await get(
      `/product-management/product-categories/list?vendorId=${vendorId}&brandId=${brandId}`
    )
    dispatch({
      type: types.SET_ALL_CATEGORIES,
      payload: {
        allCategories: res?.data || null,
      },
    })
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(setPALoading(false))
  }
}

export const setProductAnalyticsStores = (storeIds) => (dispatch, getState) => {
  const {
    productAnalytics: { selectedStores },
    vendorIds: {
      selectedVendor: { value },
    },
  } = getState()
  dispatch({
    type: types.SET_PRODUCT_ANALYTICS_STORES,
    payload: {
      selectedStores: storeIds,
    },
  })

  // if (storeIds?.length && !isEqual(selectedStores, storeIds)) {
  //   dispatch(setSelectedStore({ storeIds, vendorId: value }))
  // }
}

export const getAllUnregisteredProducts =
  (data) => async (dispatch, getState) => {
    const state = getState()
    const {
      vendorIds: {
        selectedVendor: { value: vendorId },
        selectedBrand: { value: brandId },
      },
    } = state

    const body = {
      limit: 15,
      skip: ((data?.page || 1) - 1) * 15,
      ...data,
    }
    try {
      const res = await get(
        `/product-management/unregistered-products/${vendorId}/${brandId}`,
        body
      )

      dispatch({
        type: types.SET_UNREGISTERED_PRODUCTS_LIST,
        payload: {
          unregisteredProducts: res?.data
            ? {
                ...res?.data,
                totalPages: parseInt(res?.data?.totalDocs / body?.limit) + 1,
                page: data?.page || 1,
              }
            : null,
        },
      })
    } catch (error) {
      dispatch({
        type: types.SET_UNREGISTERED_PRODUCTS_LIST,
        payload: {
          unregisteredProducts: null,
        },
      })
      console.log(error)
    }
  }

export const getProductsByCategory = async (params) =>
  await get(`/product-management/products`, params)

export const registerProduct = async (id, body) =>
  await post(`/product-management/unregistered-products/register/${id}`, body)

export const getProducts = async (filters) =>
  await post(`/product-management/products/list`, filters)

export const exportUnregisteredProducts = async (vendorId, body) =>
  await post(
    `/product-management/unregistered-products/${vendorId}/export`,
    body
  )
