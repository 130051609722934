import React from 'react'
import * as moment from 'moment'
import _ from 'lodash'
import styled from 'styled-components'
import { spacing } from 'design-system/styles'
import { Skeleton, Avatar, StarRating, Text } from 'components/Common'
import EditIcon from 'assets/icons/edit.svg'
import AvatarIcon from 'assets/icons/avatar.jpeg'

const $Customer = styled.div`
  width: 350px;
  border: 1px solid ${({ theme }) => theme.colors.alto};
  overflow: hidden;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
`

const $Profile = styled.div`
  height: 240px;
  text-align: center;
  padding: ${spacing.xl};
  position: relative;
  button {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    background: url(${EditIcon}) no-repeat center;
    border: 1px solid ${({ theme }) => theme.colors.alto};
  }
`

const $BasicDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacing.s};
  }
`

const $Tab = styled.div`
  width: 100%;
  display: flex;
  p {
    flex: 1;
    display: block;
    padding-bottom: ${spacing.ml};
    cursor: pointer;
    position: relative;
    ::after {
      position: absolute;
      display: block;
      bottom: -2px;
      height: 2px;
      content: '';
      background: ${({ theme }) => theme.colors.primary.default};
      transition: 500ms;
    }
    :nth-child(1)::after {
      right: 0;
      width: ${(props) => (props.isActive ? '100%' : 0)};
    }
    :nth-child(2)::after {
      left: 0;
      width: ${(props) => (!props.isActive ? '100%' : 0)};
    }
  }
`

const $Personal = styled.div`
  max-width: 350px;
  padding: ${spacing.l};
  max-height: 315px;
  overflow-x: hidden;
  overflow-y: auto;
  p {
    margin-bottom: ${spacing.m};
  }
  div {
    padding: ${spacing.s};
    margin-bottom: ${spacing.l};
    p:nth-child(1) {
      letter-spacing: 1.4px;
    }
    span {
      display: flex;
      p:nth-child(1) {
        min-width: 140px;
      }
    }
  }
`

const $Recent = styled.div`
  padding: ${spacing.l} ${spacing.l} 0;
  max-height: 280px;
  overflow-y: auto;
  > span {
    margin-bottom: ${spacing.xl};
  }
  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: ${spacing.l};
    a {
      text-align: right;
      font-size: 12px;
      display: block;
    }
  }
`

const $SlideAnim = styled.div`
  width: 100%;
  overflow: hidden;
  block {
    width: 200%;
    display: flex;
  }
`

const $InViewAnim = styled.div`
  width: 100%;
  position: relative;
  left: ${(props) => (props.inView ? '0' : '-50%')};
  transition: 0.5s;
`

export const CustomerDetail = ({
  personal,
  professional,
  recent,
  loading,
  onEdit,
}) => {
  const [personTab, showPersonTab] = React.useState(true)

  const unwantedFields = (field) => {
    const fieldSet = ['_id', 'vendorId']
    if (fieldSet.includes(field) || personal[field] === null) {
      return false
    }
    return true
  }

  const displayValue = (field) => {
    const dateSet = ['birthDate', 'anniversaryDate']
    if (dateSet.includes(field)) {
      return moment(personal[field])?.format('DD MMM YYYY')
    }
    if (field === 'age') {
      return parseInt(personal[field])
    }
    if (field === 'email') {
      return personal[field]
    } else {
      return _.startCase(_.toLower(personal[field]))
    }
  }

  const displayLabel = (field) => {
    switch (field) {
      case 'married':
        return 'Relationship Status'
      case 'spouseFirst':
        return 'Spouse First Name'
      case 'spouseLast':
        return 'Spouse Last Name'
      default:
        return _.startCase(_.toLower(field.replace(/([a-z])([A-Z])/g, '$1 $2')))
    }
  }

  return (
    <$Customer>
      {loading ? (
        <$Profile>
          <Skeleton
            style={{ margin: 'auto' }}
            width={100}
            height={100}
            circle
          />
          <Skeleton count={3} />
        </$Profile>
      ) : (
        <$Profile>
          <button onClick={onEdit} />
          <Avatar
            size={100}
            gender={personal.gender || 'other'}
            src={AvatarIcon}
          />

          <$BasicDetails>
            <Text variant="h3" type="main" weight="bold" align="center">
              {personal.name}
            </Text>
            <Text variant="small" type="grey" align="center">
              Last Visit : {moment(professional?.lastVisit).fromNow()}
            </Text>
            {professional?.avgFeedback && (
              <span>
                <Text variant="tiny" type="main">
                  (Avg. Rating)
                </Text>
                <StarRating
                  filled={professional?.avgFeedback || 4}
                  padding={'0 ' + spacing.ml}
                />
              </span>
            )}
          </$BasicDetails>
        </$Profile>
      )}
      <$Tab isActive={personTab}>
        <Text
          onClick={() => showPersonTab(true)}
          variant="medium"
          align="center"
          weight="bold"
          type={personTab ? 'primary' : 'grey'}
        >
          Personal
        </Text>
      </$Tab>
      <$SlideAnim>
        <block>
          <$InViewAnim inView={personTab}>
            <$Personal>
              {loading ? (
                <Skeleton count={5} />
              ) : (
                <div>
                  <Text type="grey" variant="medium" weight="bold">
                    PERSONAL INFORMATION
                  </Text>
                  {Object.keys(personal)
                    ?.sort()
                    ?.map(
                      (item) =>
                        unwantedFields(item) && (
                          <span key={item}>
                            <Text variant="small" type="grey" weight="normal">
                              {displayLabel(item)}
                            </Text>
                            <Text
                              variant="small"
                              type="main"
                              weight="semi-bold"
                            >
                              {displayValue(item)}
                            </Text>
                          </span>
                        )
                    )}
                </div>
              )}
            </$Personal>
          </$InViewAnim>
          <$InViewAnim inView={personTab}>
            {/* <$Recent>
              {loading ? (
                <Skeleton count={6} />
              ) : (
                recentData.map((data) => (
                  <span>
                    <div>
                      <Text variant="p" type="main" weight="bold">
                        {data.header}
                      </Text>
                      <Text variant="small" type="main">
                        {data.address}
                      </Text>
                      <span>
                        <Text variant="small" type="grey">
                          {data.time}
                        </Text>
                        <Text variant="small" type="grey">
                          {data.billme}
                        </Text>
                      </span>
                    </div>
                    <div>
                      <Text variant="h3" type="primary" weight="bold">
                        {data.cost}
                      </Text>
                      {data.rating && (
                        <StarRating
                          filled={data.rating}
                          size="small"
                          padding="0px"
                        />
                      )}
                      <a href={data.viewLink}>view</a>
                    </div>
                  </span>
                ))
              )}
            </$Recent> Uncomment when the api supports design */}
            <$Personal>
              {loading ? (
                <Skeleton count={6} />
              ) : (
                <div>
                  <Text type="grey" variant="medium" weight="bold">
                    VISIT INFORMATION
                  </Text>
                  {recent?.map((data) => (
                    <span key={data.visitDate}>
                      <Text variant="small" type="grey">
                        {moment(data.visitDate).fromNow()}
                      </Text>
                    </span>
                  ))}
                </div>
              )}
            </$Personal>
          </$InViewAnim>
        </block>
      </$SlideAnim>
    </$Customer>
  )
}
