const TABLE_COLS = [
  {
    label: 'Phone Number',
    value: 'phone',
  },
  {
    label: 'E-Mail Address',
    value: 'email',
  },
  {
    label: 'Store Name',
    value: 'storeId',
  },
  {
    label: 'ComplaintType',
    value: 'type',
  },
  {
    label: 'Date',
    value: 'createdDate',
  },
  {
    label: 'Action',
    value: 'action',
  },
]

const SECTION_HEADINGS = {
  heading: 'All Customer Complaints',
  subHeading: 'List fo all customer complaints in your system',
}

const NYB_TYPES = {
  wrongNumber: 'Wrong Number',
  wrongItems: 'Wrong Items',
}

const PAGINATION_LIMIT = 10

export { TABLE_COLS, PAGINATION_LIMIT, SECTION_HEADINGS, NYB_TYPES }
