import React, { Component } from 'react'
import Select from 'react-select'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'react-redux'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import swal from 'sweetalert'
import CustomPopup from '../../CustomPopup'
import FilterFooter from '../../manageStores/StoreFilter/Footer'
import {
  setDefaultFilters,
  setBillFilterQuery,
  exportBills,
} from 'redux/actions/bills'
import { exportStoreData } from 'redux/actions/billingAnalyticsActions'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import {
  hours,
  timeState,
  transactionType,
  billType,
  defaultFilters,
} from '../../../../utils/billUtils'
import { formatDateInFilters } from '../../../../utils/getDateFormat'
import '../../../../css/content/manageStores.scss'
import '../../../../css/content/billsFilter.scss'

import { userTypes } from '../../../Layout/constants'
import { MyToast } from 'components/Common'
import { compareDatePermission } from '../../../../utils/getDateFormat'
import { Filter } from 'components/Common/Filter'
import { Div } from 'styledComponent'
import { CloseIcon } from 'assets/icons'
import { ERROR_DECODE, GET_SEL_VALUE } from 'utils/reusables'
import ToggleSwitch from 'components/Common/ToggleSwitch'

const moment = extendMoment(originalMoment)

const getBillsProcessedFilters = (filtersToProcess) => {
  let filters = {}
  let validatefilters = true
  let dateQuery,
    transactionQuery,
    billTypeQuery,
    amtQuery,
    emailQuery,
    transactionsQuery,
    avgSalesQuery,
    totalSalesQuery

  Object.keys(filtersToProcess).forEach((key, i) => {
    if (filtersToProcess[key].isActive) {
      filters[key] = filtersToProcess[key]
    }
  })

  if (filtersToProcess['date'].isActive) {
    let startHrs, endHrs
    const { startValue, endValue } = filters['date']['value']['dates']
    const { startTime, endTime } = filters['date']['value']['time']

    if (startTime.state === 'PM' && startTime.hrs !== '00')
      startHrs = parseInt(startTime.hrs) + 12
    else if (startTime.state === 'AM' && startTime.hrs === '00') startHrs = 0
    else startHrs = startTime.hrs

    if (endTime.state === 'PM' && endTime.hrs !== '00')
      endHrs = parseInt(endTime.hrs) + 12
    else if (endTime.state === 'AM' && endTime.hrs === '00') endHrs = 0
    else endHrs = endTime.hrs

    const startDate = new Date(startValue)
    const endDate = new Date(endValue)

    if (!startHrs) startDate.setHours(0, 0, 0, 0)
    else startDate.setHours(startHrs)

    if (!endHrs) endDate.setHours(23, 59, 59, 0)
    else endDate.setHours(endHrs)

    dateQuery = {
      startDate,
      endDate,
    }
  }

  if (filtersToProcess['transactionType'].isActive) {
    if (filters['transactionType'].value.type.length === 0) {
      validatefilters = false
      swal(
        'Atleast select one transaction type',
        'Type is not selected',
        'error'
      )
    } else {
      transactionQuery = {
        transactionType: filters['transactionType'].value.type.map(
          (type) => type.value
        ),
      }
    }
  }
  if (filtersToProcess['billType'].isActive) {
    if (filters['billType'].value.type.length === 0) {
      validatefilters = false
      swal('Atleast select one bill type', 'Type is not selected', 'error')
    } else {
      billTypeQuery = {
        billType: filters['billType'].value.type.value,
      }
    }
  }

  if (filtersToProcess['amountRange'].isActive) {
    let { from = 0, to = 0 } = filters['amountRange'].value
    if (from >= to) {
      validatefilters = false
      swal(
        'Second amount should be greater than First amount.',
        'For Example: From = 100 <-> To = 101',
        'error'
      )
    } else {
      amtQuery = {
        amountRange: {
          min: from,
          max: to,
        },
      }
    }
  }

  if (filtersToProcess['avgSales'].isActive) {
    let { from = 0, to = 0 } = filters['avgSales'].value
    if (from >= to) {
      validatefilters = false
      swal(
        'Second amount should be greater than First amount.',
        'For Example: From = 100 <-> To = 101',
        'error'
      )
    } else {
      avgSalesQuery = {
        avgSales: {
          min: from,
          max: to,
        },
      }
    }
  }

  if (filtersToProcess['totalSales'].isActive) {
    let { from = 0, to = 0 } = filters['totalSales'].value
    if (from >= to) {
      validatefilters = false
      swal(
        'Second amount should be greater than First amount.',
        'For Example: From = 100 <-> To = 101',
        'error'
      )
    } else {
      totalSalesQuery = {
        totalSales: {
          min: from,
          max: to,
        },
      }
    }
  }

  if (filtersToProcess['transactions'].isActive) {
    let {
      from = 0,
      to = 0,
      type = 'Count',
      transType = 'BM',
    } = filters['transactions'].value
    if (from > to) {
      validatefilters = false
      swal(
        'Second amount should be greater than First amount.',
        'For Example: From = 100 <-> To = 101',
        'error'
      )
    }
    if (type === 'Percentage' && (from > 100 || to > 100)) {
      validatefilters = false
      swal(
        'Percentage cannot be grater than 100',
        'Enter a value between 1 to 100',
        'error'
      )
    } else {
      transactionsQuery = {
        transactions: {
          min: from,
          max: to,
          type,
          transType,
        },
      }
    }
  }

  if (filtersToProcess['emailList'].isActive) {
    if (filters['emailList'].value.length === 0) {
      validatefilters = false
      swal('Add atleast one email', 'Email is not added', 'error')
    } else {
      emailQuery = {
        emailList: filters['emailList'].value,
      }
    }
  }

  return {
    validatefilters,
    dateQuery,
    transactionQuery,
    amtQuery,
    emailQuery,
    transactionsQuery,
    avgSalesQuery,
    totalSalesQuery,
    billTypeQuery,
  }
}

const Header = (props) => {
  return (
    <div className="filter-modal">
      <div className="filter-modal-head">
        <h5 className="header">
          {props.heading}
          <span> ({props.count}) </span>
        </h5>
        <Div p="16px">
          <CloseIcon onClick={props.onCancel} />
        </Div>
      </div>
    </div>
  )
}
const today = moment()
class BillsFilter extends Component {
  static contextType = MyToast

  constructor(props) {
    super(props)
    this.state = {
      filters: cloneDeep(this.props.defaultFilters),
      value: moment.range(today.clone().subtract(1, 'days'), today.clone()),
      openDatePicker: false,
      transactionType: cloneDeep(transactionType),
      billType: cloneDeep(billType),
    }
    this.dialogRef = React.createRef()
    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.handleTransactionTypeChange =
      this.handleTransactionTypeChange.bind(this)
    this.handleBillTypeChange = this.handleBillTypeChange.bind(this)
    this.handleTransactionsChange = this.handleTransactionsChange.bind(this)
    this.toggleResendDestination = this.toggleResendDestination.bind(this)
    this.customOption = this.customOption.bind(this)
    this.handleResetFilters = this.handleResetFilters.bind(this)
    this.onSetFilters = this.onSetFilters.bind(this)
    this.getCount = this.getCount.bind(this)
  }

  handleTransactionTypeChange(selectedOption) {
    let filters = cloneDeep(this.state.filters)
    filters['transactionType']['value']['type'] =
      selectedOption !== null ? selectedOption : []
    this.setState({ filters })
  }

  handleBillTypeChange(selectedOption) {
    let filters = cloneDeep(this.state.filters)
    filters['billType']['value']['type'] =
      selectedOption !== null ? selectedOption : []
    this.setState({ filters })
    console.log('filters', filters)
  }

  handleTransactionsChange(selectedOption) {
    const filters = cloneDeep(this.state.filters)
    filters['transactions']['value']['transType'] = selectedOption?.value
    this.setState({ filters })
  }

  toggleResendDestination() {
    let filters = cloneDeep(this.state.filters)
    filters['transactions']['value']['type'] =
      filters['transactions']['value']['type'] === 'Count'
        ? 'Percentage'
        : 'Count'
    this.setState({ filters })
  }

  customOption = ({ innerProps, isDisabled, data, isSelected }) => {
    return !isDisabled ? (
      <div
        style={{ padding: '2.5%' }}
        className="selector_main"
        {...innerProps}
      >
        <div className="custom-control custom-checkbox ml-3 control-flex">
          <div>
            <input
              className="custom-control-input"
              type="checkbox"
              id="select-employees-range"
              checked={isSelected}
            />
            <label
              className="additional-css custom-control-label"
              for="select-employees-range"
            />
          </div>
          <div
            className="statusColor"
            style={{ backgroundColor: data.color }}
          />
          <div className="typeName">{data.label}</div>
        </div>
      </div>
    ) : null
  }

  openFilterModal = () => {
    this.dialogRef.current.openModal()
  }

  handleCheckboxClick = (filterName) => {
    let filters = cloneDeep(this.state.filters)
    filters[filterName].isActive = !filters[filterName].isActive
    filters[filterName].value = defaultFilters[filterName].value
    this.setState({
      filters,
    })
  }

  handleChange = (filterName, filterProperty, value) => {
    let filters = cloneDeep(this.state.filters)
    switch (filterProperty) {
      case 'startTime':
      case 'endTime':
        filters[filterName]['value']['time'][filterProperty]['hrs'] = value
        break
      case 'startTimeState':
        filters[filterName]['value']['time']['startTime']['state'] = value
        break
      case 'endTimeState':
        filters[filterName]['value']['time']['endTime']['state'] = value
        break
      default:
        filters[filterName]['value'][filterProperty] = value
    }

    this.setState({
      filters,
    })
  }

  onSelect = (value, states) => {
    if (!value.start._i || !value.end._i) return
    const toast = this.context.toast
    if (
      !compareDatePermission(
        this.props.selectedVendor.featuresAlloted.exportRange,
        value.start
      )
    ) {
      this.context.toast('You can only access last 2 months data', 'warning')
      return false
    }
    const diff = value.start?.diff(value.end, 'days')
    const diffLimit = Number(
      this.props.selectedVendor.featuresAlloted.billExportDayLimit
    )
    if (this.props.isExport && Math.abs(diff) > diffLimit) {
      toast(`Only ${diffLimit} days range can be selected`, 'error')
      return null
    }

    this.setState({ value, states, openDatePicker: false })
    let filters = cloneDeep(this.state.filters)
    filters['date']['value']['dates']['startDate'] = formatDateInFilters(
      value.start._i
    )
    filters['date']['value']['dates']['startValue'] = value.start._i
    filters['date']['value']['dates']['endDate'] = formatDateInFilters(
      value.end._i
    )
    filters['date']['value']['dates']['endValue'] = value.end._i
    this.setState({
      filters,
    })
  }

  handleDatePicker() {
    if (this.state.filters.date.isActive) {
      if (this.state.openDatePicker) this.setState({ openDatePicker: false })
      else this.setState({ openDatePicker: true })
    }
  }

  handleResetFilters() {
    this.setState(
      {
        filters: cloneDeep({
          ...cloneDeep(defaultFilters),
          ...this.state?.filters?.selectedStoreIds,
        }),
        openDatePicker: false,
      },
      this.onSetFilters
    )
  }

  onEmailChange = (val) => {
    const copyFilter = cloneDeep(this.state.filters)
    copyFilter['emailList'].value = val
    this.setState({ filters: copyFilter })
  }

  async onSetFilters(e, discardFilters) {
    if (e) e.preventDefault()

    const {
      validatefilters,
      dateQuery,
      transactionQuery,
      billTypeQuery,
      amtQuery,
      emailQuery = {},
      transactionsQuery,
      avgSalesQuery,
      totalSalesQuery,
    } = getBillsProcessedFilters(this.state.filters)
    emailQuery.emailList =
      emailQuery?.emailList?.filter(
        (email) =>
          email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
      ) || []

    let query = {
      ...dateQuery,
      ...transactionQuery,
      ...amtQuery,
      ...emailQuery,
      ...transactionsQuery,
      ...billTypeQuery,
      ...avgSalesQuery,
      ...totalSalesQuery,
      ...(this.props.selectedStoreIds
        ? { selectedStoreIds: this.props.selectedStoreIds }
        : []),
    }

    if (this.props.isExport && validatefilters) {
      if (
        dateQuery || discardFilters
          ? this._checkExportDates(dateQuery, discardFilters)
          : true
      ) {
        try {
          if (emailQuery?.emailList?.length === 0) {
            throw 'Please enter at least one valid email.'
          }
          let data
          if (this.props.isBills) {
            const expFilter = {
              ...(discardFilters ? {} : query),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
            if (this.props.storeGrpName) {
              expFilter.storeGrpName = this.props.storeGrpName
            }
            data = await exportBills(expFilter)
          } else {
            const expFilter = {
              emailList: query?.emailList,
              startDate: moment(query?.startDate)?.format('YYYY-MM-DD'),
              endDate: moment(query?.endDate)?.format('YYYY-MM-DD'),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              storeIds:
                query.selectedStoreIds?.length > 0
                  ? query.selectedStoreIds
                  : Object.keys(this.props.stores),
            }
            if (totalSalesQuery) {
              expFilter.tSales = totalSalesQuery?.totalSales
            }
            if (avgSalesQuery) {
              expFilter.aSales = avgSalesQuery?.avgSales
            }
            if (this.props.storeGrpName) {
              expFilter.storeGrpName = this.props.storeGrpName
            }
            if (transactionsQuery) {
              expFilter.transData = {
                ...transactionsQuery?.transactions,
                type:
                  transactionsQuery?.transactions?.type === 'Count'
                    ? 'count'
                    : 'percent',
              }
            }
            data = await exportStoreData(expFilter)
          }

          if (data?.code) {
            swal(data.message || '')
          }

          this.dialogRef.current.closeModal()
          // Reset to redux filters as export filters are not used to set the filters
          this.setState({ filters: this.props.defaultFilters })
          return
        } catch (err) {
          return swal(
            ERROR_DECODE(err || err?.response?.data?.message?.message)
          )
        }
      } else {
        return null
      }
    }

    if (validatefilters) {
      //here calling fiter api
      this.dialogRef.current.closeModal()
      this.props.setDefaultFilters(this.state.filters)
      this.props.setBillFilterQuery(query)
      setTimeout(() => {
        if (this.props.isBills) this.props.onProceed()
      }, 10)
    }
  }

  _checkExportDates = (value, byPass) => {
    if (byPass) return true
    if (!value || !value.startDate || !value.endDate) return false
    const dates = {
      start: moment(value.startDate),
      end: moment(value.endDate),
    }

    const toast = this.context.toast
    const diffLimit = Number(
      this.props.selectedVendor.featuresAlloted.billExportDayLimit
    )
    const diff = dates.start?.diff(dates.end, 'days')

    if (this.props.isExport && Math.abs(diff) > diffLimit) {
      toast(`Only ${diffLimit} days range can be selected`, 'error')
      return false
    } else {
      return true
    }
  }

  getBrandLogo = (brandId) =>
    this.props.allBrands?.find((br) => br.brandId === brandId)?.brandLogo

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.isFilterOpen !== this?.props?.isFilterOpen) {
      this.setState({
        filters: cloneDeep(this.props.defaultFilters),
        openDatePicker: false,
        value: moment.range(
          moment(this.props.defaultFilters?.date?.value?.dates?.startValue),
          moment(this.props.defaultFilters?.date?.value?.dates?.endValue)
        ),
      })
    }
  }

  getCount() {
    const curPage = this.props.isBills
      ? 'bills'
      : this.props.isStoreData
      ? 'store'
      : 'graph'

    switch (curPage) {
      case 'bills': {
        return ['transactionType', 'amountRange', 'date', 'emailList']?.filter(
          (fil) => this.state?.filters?.[fil]?.isActive
        )?.length
      }
      case 'store': {
        return [
          'transactions',
          'avgSales',
          'totalSales',
          'date',
          'emailList',
        ]?.filter((fil) => this.state?.filters?.[fil]?.isActive)?.length
      }
      default:
        return ['date']?.filter((fil) => this.state?.filters?.[fil]?.isActive)
          ?.length
    }
  }

  render() {
    return (
      <CustomPopup
        overlayStyle={{ overflow: 'auto' }}
        contentStyle={{ width: '70%', minWidth: '800px' }}
        className={'filter-modal'}
        headerComponent={true}
        heading={
          <Header
            heading={this.props.isExport ? 'Export filters' : 'Filters'}
            onCancel={() => this.dialogRef.current.closeModal()}
            count={this.getCount()}
          />
        }
        footer={
          <FilterFooter
            onCancel={() => this.dialogRef.current.closeModal()}
            onSetFilters={this.onSetFilters}
            onResetFilters={this.handleResetFilters}
            isExport={this.props.isExport}
            disabledExport={!this.state?.filters?.date?.isActive}
          />
        }
        ref={this.dialogRef}
      >
        <div className="filter-modal-body">
          {!this.props.disableDateSelection && (
            <div
              className={`filter-option filters ${
                this.state.filters.date.isActive && 'selected'
              }`}
            >
              <div className="custom-control custom-checkbox ml-3 control-flex">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="select-employees-range"
                  checked
                />
                <label
                  style={{ width: '10%' }}
                  className="additional-css custom-control-label mt-2"
                  htmlFor="select-employees-range"
                >
                  Date
                </label>
                <div className="sub-options">
                  <div className="sub-option mr-1" style={{ width: '40%' }}>
                    <div
                      className="inputDatePicker"
                      onClick={this.handleDatePicker}
                    >
                      <img
                        style={{ width: '6%', marginBottom: '10px' }}
                        src={require('../assets/calendar.svg')}
                      />
                      <div className="startDate">
                        {this.state.filters.date.value.dates.startDate}
                      </div>
                      <div className="endDate">
                        - {this.state.filters.date.value.dates.endDate}
                      </div>
                    </div>
                    {this.state.openDatePicker ? (
                      <div className="datePicker">
                        <DateRangePicker
                          value={this.state.value}
                          onSelect={this.onSelect}
                          singleDateRange={true}
                          maximumDate={moment().toDate()}
                        />
                      </div>
                    ) : null}
                  </div>
                  {this.props.isBills && (
                    <div className="sub-option" style={{ width: '60%' }}>
                      <div
                        style={{ width: '150px' }}
                        className="additional-css"
                      >
                        Time (optional)
                      </div>
                      <div className="sub-filter-name mr-2">From</div>
                      <select
                        className="form-control filter-select mr-2"
                        value={this.state.filters.date.value.time.startTime.hrs}
                        disabled={!this.state.filters.date.isActive}
                        onChange={(event) =>
                          this.handleChange(
                            'date',
                            'startTime',
                            event.target.value
                          )
                        }
                      >
                        {hours.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        className="form-control filter-select mr-2"
                        value={
                          this.state.filters.date.value.time.startTime.state
                        }
                        disabled={!this.state.filters.date.isActive}
                        onChange={(event) =>
                          this.handleChange(
                            'date',
                            'startTimeState',
                            event.target.value
                          )
                        }
                      >
                        {timeState.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="sub-filter-name mr-2">to</div>
                      <select
                        className="form-control filter-select mr-2"
                        value={this.state.filters.date.value.time.endTime.hrs}
                        disabled={!this.state.filters.date.isActive}
                        onChange={(event) =>
                          this.handleChange(
                            'date',
                            'endTime',
                            event.target.value
                          )
                        }
                      >
                        {hours.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        className="form-control filter-select"
                        value={this.state.filters.date.value.time.endTime.state}
                        disabled={!this.state.filters.date.isActive}
                        onChange={(event) =>
                          this.handleChange(
                            'date',
                            'endTimeState',
                            event.target.value
                          )
                        }
                      >
                        {timeState.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {this.props.isBills && (
            <>
              <div
                className={`filter-option filters ${
                  this.state.filters.transactionType.isActive && 'selected'
                }`}
              >
                <div className="custom-control custom-checkbox ml-3 control-flex">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="select-employees-range"
                    checked={this.state.filters.transactionType.isActive}
                  />
                  <label
                    className="additional-css custom-control-label mt-1"
                    htmlFor="select-employees-range"
                    onClick={() => this.handleCheckboxClick('transactionType')}
                  >
                    Transaction Type
                  </label>
                  <div className="sub-options">
                    <div className="sub-filter-name mt-0 mr-2"></div>
                    <Select
                      hideSelectedOptions={false}
                      className="selectOption"
                      isDisabled={!this.state.filters.transactionType.isActive}
                      isMulti={true}
                      value={this.state.filters.transactionType.value.type}
                      onChange={this.handleTransactionTypeChange}
                      options={this.state.transactionType}
                      placeholder="Select Transaction Type"
                      required={true}
                      components={{ Option: this.customOption }}
                      isSearchable
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`filter-option filters ${
                  this.state.filters.amountRange.isActive && 'selected'
                }`}
              >
                <div className="custom-control custom-checkbox ml-3 control-flex">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="select-employees-range"
                    checked={this.state.filters.amountRange.isActive}
                  />
                  <label
                    className="additional-css custom-control-label mt-1"
                    htmlFor="select-employees-range"
                    onClick={() => this.handleCheckboxClick('amountRange')}
                  >
                    Amount Range
                  </label>
                  <div className="sub-options">
                    <div className="sub-filter-name ml-2 mr-2">Min. Amount</div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.amountRange.isActive}
                      value={this.state.filters.amountRange.value.from}
                      onChange={(event) =>
                        this.handleChange(
                          'amountRange',
                          'from',
                          parseInt(event.target.value)
                        )
                      }
                    />
                    <div className="sub-filter-name ml-2 mr-2">
                      to Max. Amount
                    </div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.amountRange.isActive}
                      value={this.state.filters.amountRange.value.to}
                      onChange={(event) =>
                        this.handleChange(
                          'amountRange',
                          'to',
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {this.props.isExport && (
                <div
                  className={`filter-option filters ${
                    this.state.filters.billType.isActive && 'selected'
                  }`}
                >
                  <div className="custom-control custom-checkbox ml-3 control-flex">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="select-employees-range"
                      checked={this.state.filters.billType.isActive}
                    />
                    <label
                      className="additional-css custom-control-label mt-1"
                      htmlFor="select-employees-range"
                      onClick={() => this.handleCheckboxClick('billType')}
                    >
                      Generation Channel
                    </label>
                    <div className="sub-options">
                      <div className="sub-filter-name mt-0 mr-2"></div>
                      <Select
                        hideSelectedOptions={false}
                        className="selectOption"
                        isDisabled={!this.state.filters.billType.isActive}
                        isMulti={false}
                        value={this.state.filters.billType.value.type}
                        onChange={this.handleBillTypeChange}
                        options={this.state.billType}
                        placeholder="Select BIll Type Type"
                        required={true}
                        components={{ Option: this.customOption }}
                        isSearchable
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {this.props.isStoreData && (
            <>
              <div
                className={`filter-option filters ${
                  this.state.filters.totalSales.isActive && 'selected'
                }`}
              >
                <div className="custom-control custom-checkbox ml-3 control-flex">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="select-employees-range"
                    checked={this.state.filters.totalSales.isActive}
                  />
                  <label
                    className="additional-css custom-control-label mt-1"
                    htmlFor="select-employees-range"
                    onClick={() => this.handleCheckboxClick('totalSales')}
                  >
                    Total Sales
                  </label>
                  <div className="sub-options">
                    <div className="sub-filter-name ml-2 mr-2">Min. Amount</div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.totalSales.isActive}
                      value={this.state.filters.totalSales.value.from}
                      onChange={(event) =>
                        this.handleChange(
                          'totalSales',
                          'from',
                          parseInt(event.target.value)
                        )
                      }
                    />
                    <div className="sub-filter-name ml-2 mr-2">
                      to Max. Amount
                    </div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.totalSales.isActive}
                      value={this.state.filters.totalSales.value.to}
                      onChange={(event) =>
                        this.handleChange(
                          'totalSales',
                          'to',
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className={`filter-option filters ${
                  this.state.filters.avgSales.isActive && 'selected'
                }`}
              >
                <div className="custom-control custom-checkbox ml-3 control-flex">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="select-employees-range"
                    checked={this.state.filters.avgSales.isActive}
                  />
                  <label
                    className="additional-css custom-control-label mt-1"
                    htmlFor="select-employees-range"
                    onClick={() => this.handleCheckboxClick('avgSales')}
                  >
                    Average Sales
                  </label>
                  <div className="sub-options">
                    <div className="sub-filter-name ml-2 mr-2">Min. Amount</div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.avgSales.isActive}
                      value={this.state.filters.avgSales.value.from}
                      onChange={(event) =>
                        this.handleChange(
                          'avgSales',
                          'from',
                          parseInt(event.target.value)
                        )
                      }
                    />
                    <div className="sub-filter-name ml-2 mr-2">
                      to Max. Amount
                    </div>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.avgSales.isActive}
                      value={this.state.filters.avgSales.value.to}
                      onChange={(event) =>
                        this.handleChange(
                          'avgSales',
                          'to',
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className={`filter-option filters ${
                  this.state.filters.transactions.isActive && 'selected'
                }`}
              >
                <div
                  className="custom-control custom-checkbox ml-3 control-flex"
                  style={{ alignItems: 'center' }}
                >
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="select-employees-range"
                    checked={this.state.filters.transactions.isActive}
                  />
                  <label
                    className="additional-css custom-control-label mt-1"
                    htmlFor="select-employees-range"
                    onClick={() => this.handleCheckboxClick('transactions')}
                  >
                    Transaction
                  </label>
                  <div className="sub-options">
                    <div className="sub-filter-name mt-0 mr-2"></div>
                    <Select
                      className="selectOption"
                      isDisabled={!this.state.filters.transactions.isActive}
                      value={GET_SEL_VALUE(
                        this.state.filters.transactions.value.transType,
                        this.state.transactionType
                      )}
                      onChange={this.handleTransactionsChange}
                      options={this.state.transactionType}
                      placeholder="Select Transaction Type"
                    />
                  </div>
                  <div className="mx-4 my-3">
                    <ToggleSwitch
                      startText="Count"
                      endText="Percentage"
                      isActive={
                        this.state.filters.transactions.value.type ===
                        'Percentage'
                      }
                      onChange={this.toggleResendDestination}
                    />
                  </div>
                  <div className="sub-options">
                    <div className="sub-filter-name ml-2 mr-2">Min.</div>
                    <input
                      name="transactions"
                      id="transactions"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.transactions.isActive}
                      value={this.state.filters.transactions.value.from}
                      onChange={(event) =>
                        this.handleChange(
                          'transactions',
                          'from',
                          parseInt(event.target.value)
                        )
                      }
                    />
                    <div className="sub-filter-name ml-2 mr-2">to Max.</div>
                    <input
                      name="transactions"
                      id="transactions"
                      type="number"
                      className="form-control amtInput"
                      disabled={!this.state.filters.transactions.isActive}
                      value={this.state.filters.transactions.value.to}
                      onChange={(event) =>
                        this.handleChange(
                          'transactions',
                          'to',
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {this.props.isExport && (
            <Filter
              filterId="emailList"
              isFilterActive={this.state.filters.emailList?.isActive}
              borderColor="#0081ED"
              filterLabel="Email List"
              placeholder="Enter email Ids"
              type="tags"
              inputValue={this.state.filters.emailList?.value || []}
              onValueChange={this.onEmailChange}
              onCheckboxClick={() => this.handleCheckboxClick('emailList')}
            />
          )}
        </div>
      </CustomPopup>
    )
  }
}

const mapStateToProps = (state) => {
  const isCashier = state.auth.user.userType === userTypes.CASHIER
  const isSecurity = state.auth.user.userType === userTypes.SECURITY
  return {
    defaultFilters: state.bills.defaultFilters,
    isCashier,
    selectedVendor: state.vendorIds.selectedVendor,
    allStores: state.stores.allStores,
    stores: state.stores.stores,
    allBrands: state.brand.brands,
    inactiveSelectedStores: state.stores.inactiveSelectedStores,
    selectedStoreIds: state.bills.filterQuery.selectedStoreIds || null,
    disableDateSelection:
      [
        '3qp6rkl1kj2b86',
        '7mfv4ll1lpec9a',
        //decathlon india
        '4gb0i29jkl7lk5hb',
        'hr5pq1kfk5qccf7x',
      ].includes(state.vendorIds.selectedVendor.value) &&
      (isCashier || isSecurity),
  }
}

export default connect(
  mapStateToProps,
  { setDefaultFilters, setBillFilterQuery },
  null,
  {
    forwardRef: true,
  }
)(BillsFilter)
