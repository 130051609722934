import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MinimalIdentityBox } from 'components/Common/ModalComponents'
import { Grid, ErrorMessage } from 'styledComponent'
import { filterStores } from 'redux/actions'

class CampaignScreenTwo extends Component {
  render() {
    const filteredStores = Object.values(this.props.stores).filter(
      (store) => store.brandId == this.props.brandId
    )
    return (
      <div style={{ padding: '1em 30px' }}>
        <h5>Step 2/3: Select your stores</h5>
        <ErrorMessage as="span" error={this.props.hasError}>
          Please select atleast one store
        </ErrorMessage>
        <h5>
          <input
            type="checkbox"
            checked={this.props.allStoresSelectAll}
            onChange={() => this.props.storeHandleSelectAll(filteredStores)}
          />
          &nbsp; Select All
        </h5>
        <Grid
          justify="center"
          columns="repeat(auto-fill,minmax(130px,1fr))"
          columnGap="1em"
          style={{ maxHeight: '75vh', overflow: 'auto' }}
        >
          {filteredStores.map((store) => (
            <MinimalIdentityBox
              name={store.displayAddress}
              inputType="checkbox"
              inputName="store"
              id={store.storeId}
              handleChange={this.props.handleStoresSelect}
              checked={
                this.props.storesId &&
                this.props.storesId.includes(store.storeId)
              }
              logo={this.props.brandLogo}
            />
          ))}
        </Grid>
      </div>
    )
  }
}

const mapStatesToProps = (state) => ({
  stores: filterStores(state, 'feedback'),
})

export default connect(mapStatesToProps)(CampaignScreenTwo)
