import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FlexBox } from 'styledComponent'
import { Text } from './index'

const StyledBread = styled.a`
  text-decoration: none;
  color: #0065f2;
  &:hover {
    text-decoration: none;
  }
`

export const Breadcrumb = ({ breadcrumb = [], label = '' }) => {
  const { spacing } = useTheme()
  return (
    <FlexBox gap={spacing.m} align="baseline">
      <Text variant="h3" weight="normal">
        <StyledBread href="/dashboard">Home {'>'}</StyledBread>
      </Text>
      {breadcrumb?.map((item) => (
        <Text key={item?.link} variant="h3">
          <StyledBread href={item?.link}>
            {item?.label} {'>'}
          </StyledBread>
        </Text>
      ))}
      <Text variant="h2" type="main" weight="semi-bold">
        {label}
      </Text>
    </FlexBox>
  )
}
