import styled from 'styled-components'

import { borderRadius, spacing } from 'design-system'

const $Block = styled.div`
  padding: ${({ padding }) => padding || 'unset'};
`

const $TagsBlock = styled.div`
  padding: ${spacing.m};
  border-radius: ${borderRadius.large};
  border: 1px solid #ccc;
`
const $InputBlock = styled.div`
  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
  }
`

export { $Block, $TagsBlock, $InputBlock }
