import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { Div, FlexBox } from 'styledComponent'
import { Text, Button, Input, Tooltip } from 'components/Common'
import { $PopContainer } from '../AutoEngagement.styled'
import { GET_SEL_VALUE, POPUP_STYLE, USER_ATTRIBUTE_TYPES } from '../constants'
import { CloseIcon } from 'assets/icons'

const ATTRIBUTE_INIT = {
  selected: '',
  replacement: '',
}

export const UserAttributeModal = ({
  isPopOpen,
  setIsPopOpen,
  onAttributeChange,
}) => {
  const [attribute, setAttribute] = useState(ATTRIBUTE_INIT)

  const onSave = () => {
    onAttributeChange(
      `${attribute.selected},${attribute.replacement}`,
      'attribute'
    )
    setIsPopOpen(false)
    setAttribute(ATTRIBUTE_INIT)
  }

  const onCancel = () => {
    setIsPopOpen(false)
    setAttribute(ATTRIBUTE_INIT)
  }

  return (
    <Popup
      open={isPopOpen}
      onClose={() => setIsPopOpen(false)}
      contentStyle={{ ...POPUP_STYLE, width: '500px' }}
      className="modal"
    >
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            Select User Attribute
          </Text>
          <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
        </FlexBox>

        <Div width="100%" height="300px">
          <Input
            variant="single-select"
            label="User Attribute"
            placeholder="User Attribute"
            id="userAttribute"
            name="userAttribute"
            options={USER_ATTRIBUTE_TYPES}
            value={GET_SEL_VALUE(attribute.selected, USER_ATTRIBUTE_TYPES)}
            onChange={(val) =>
              setAttribute({
                ...attribute,
                selected: val.value,
              })
            }
            inputWidth="100%"
            maxMenuHeight="200px"
          />
          <FlexBox align="flex-start">
            <Text>If Empty.</Text>
            <Tooltip content='If the above selected attribute of the user is not available in database, select an alternate text. For eg:- If "First Name" attribute is not available, you can use "Subscriber" or "User" as your alternate text.' />
          </FlexBox>
          <Div pl="20px">
            <Input
              containerMargin="0"
              variant="input"
              label="Input Value"
              placeholder="User"
              id="inputValue"
              name="inputValue"
              inputWidth="100%"
              maxLength={30}
              value={attribute.replacement}
              onChange={(e) =>
                setAttribute({
                  ...attribute,
                  replacement: e.target.value,
                })
              }
            />
          </Div>
        </Div>

        <FlexBox justify="flex-end">
          <Button variant="primaryOutline" onClick={onCancel}>
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={onSave} variant="primary">
            Save
          </Button>
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}
