import React from 'react'
import { $QuestSerial, $MoreOptions } from '../Survey.styled'
import {
  DeleteRedIcon,
  DotsIcon,
  CopyIcon,
  EmptyIcon,
  CheckedIcon,
} from 'assets/icons'
import { SurveyText, SurveyQuestion } from './index'

export const SurveyItem = (props) => {
  const setValue = (val, key) => {
    const quest = { ...props.quest, [key]: val }
    props.setQuest(quest, props.qi)
  }

  const questNumber =
    props.body
      ?.map((ques, i) => ({ ...ques, questId: i + 'quest' }))
      ?.filter((ques) => ques.fieldType === 'question')
      ?.findIndex((qu, ind) => qu.questId === props.questId) + 1

  const getSurveyComponent = (fieldType) => {
    switch (fieldType) {
      case 'html':
        return (
          <SurveyText key={'quest' + props.qi} {...props} setValue={setValue} />
        )

      case 'question':
        return (
          <SurveyQuestion
            key={'quest' + props.qi + questNumber}
            {...props}
            questNumber={questNumber}
          />
        )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {getSurveyComponent(props.quest?.fieldType)}
      {props.quest?.fieldType === 'question' && props.isEditing && (
        <$QuestSerial>{questNumber}</$QuestSerial>
      )}
      {props.isEditing && (
        <$MoreOptions>
          <div>
            <span onClick={() => props.deleteQuestion(props.qi)}>
              <DeleteRedIcon /> Delete
            </span>
            <span onClick={() => props.replicateQuest(props.quest)}>
              <CopyIcon /> Copy
            </span>
            {props.quest?.fieldType === 'question' && (
              <span
                onClick={() => setValue(!props.quest?.isRequired, 'isRequired')}
              >
                {props.quest?.isRequired ? <CheckedIcon /> : <EmptyIcon />}
                Required
              </span>
            )}
          </div>
          <small />
          <DotsIcon />
        </$MoreOptions>
      )}
    </div>
  )
}
