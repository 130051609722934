import {
  CHARTS_AVG_BILLING,
  CHARTS_BM_STORE_WISE,
  CHARTS_BMP_STORE_WISE,
  CHARTS_GET_TRANSACTIONS_BY_TYPE,
  CHARTS_MAX_MIN_SALES,
  CHARTS_P_STORE_WISE,
  CHARTS_CW_STORE_WISE,
  CHARTS_TOTAL_SALES,
  CHARTS_TOTAL_TRANSACTIONS,
} from 'utils/dispatchConstants'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case CHARTS_TOTAL_SALES:
      return {
        ...state,
        totalSales: action.payload,
      }
    case CHARTS_AVG_BILLING:
      return {
        ...state,
        avgBilling: action.payload,
      }
    case CHARTS_TOTAL_TRANSACTIONS:
      return {
        ...state,
        totalTransactions: action.payload,
      }
    case CHARTS_MAX_MIN_SALES:
      return {
        ...state,
        maxMin: action.payload,
      }
    case CHARTS_GET_TRANSACTIONS_BY_TYPE:
      return {
        ...state,
        transactionsType: action.payload,
      }
    case CHARTS_BM_STORE_WISE:
      return {
        ...state,
        billMeStoreWise: action.payload,
      }
    case CHARTS_BMP_STORE_WISE:
      return {
        ...state,
        billMePrintStoreWise: action.payload,
      }
    case CHARTS_P_STORE_WISE:
      return {
        ...state,
        printStoreWise: action.payload,
      }
    case CHARTS_CW_STORE_WISE:
      return {
        ...state,
        cwStoreWise: action.payload,
      }
    default:
      return state
  }
}
