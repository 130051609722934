import React from 'react'
import { FormattedNumber } from 'react-intl'
import { FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { Pill, Text, MultiSelect, Button } from 'components/Common'
import {
  $FilterFooter,
  $FilterHeaderLeft,
  $FilterHeader,
  $ExportFooter,
  $CloseButton,
} from './CustomerListFilter.styled'
import { useSelectStore } from 'hooks'

export const MultiSelectFilter = ({
  label,
  id,
  handleMultiSelectChange,
  value,
  options,
  isDisabled = false,
}) => {
  return (
    <FlexBox direction="column" expand>
      <Text variant="small" marginBottom={spacing.m}>
        {label}
      </Text>
      <MultiSelect
        options={options}
        onChange={(value, actionMeta) =>
          handleMultiSelectChange(value, id, actionMeta)
        }
        value={value}
        placeholder={label}
        name={label}
        isDisabled={isDisabled}
      />
    </FlexBox>
  )
}

export const FilterHeader = ({
  filter,
  handleClearAll,
  popupRef,
  isExport,
  setIsExport,
}) => {
  return (
    <$FilterHeader>
      <FlexBox justify="space-between" align="center">
        <$FilterHeaderLeft justify="space-between" align="center">
          <FlexBox>
            <Text variant="p" weight="bold">
              Filter
            </Text>
            <Text
              type="primary"
              variant="p"
              weight="bold"
              marginLeft={spacing.m}
            >
              (
              {
                Object.values(filter)
                  .map(({ isActive }) => isActive)
                  .filter((f) => f === true).length
              }
              )
            </Text>
          </FlexBox>
          <Button
            variant="textualError"
            noBorder
            onClick={() => handleClearAll()}
          >
            Clear all
          </Button>
        </$FilterHeaderLeft>
        <$CloseButton
          onClick={() => {
            if (isExport) setIsExport(false)
            popupRef.current.closeModal()
          }}
        />
      </FlexBox>
    </$FilterHeader>
  )
}

export const FilterFooter = ({
  popupRef,
  filter,
  handleSaveFilter,
  isExport,
  setIsExport,
  handleExport,
}) => {
  return (
    <$FilterFooter>
      <FlexBox justify="flex-end">
        <Button
          variant="textualError"
          noBorder
          margin={`0 ${spacing.xl} 0 0`}
          onClick={() => {
            if (isExport) setIsExport(false)
            popupRef.current.closeModal()
          }}
        >
          Cancel
        </Button>
        {!isExport ? (
          <Button
            onClick={() => handleSaveFilter(filter)}
            variant="primary"
            padding={`0 ${spacing.xxl}`}
          >
            Save
          </Button>
        ) : (
          <$ExportFooter justify="flex-end">
            <Button
              onClick={() => {
                setIsExport(false)
                handleExport(filter, false)
              }}
              variant="primary"
              padding={`0 ${spacing.xl}`}
            >
              Export
            </Button>
            {/* <Button
              onClick={() => {
                setIsExport(false)
                handleExport(filter, true)
              }}
              variant="primaryOutline"
              padding={`0 ${spacing.xl}`}
            >
              Export without filter
            </Button> */}
          </$ExportFooter>
        )}
      </FlexBox>
    </$FilterFooter>
  )
}

export const ExpensePill = ({ label, id, type, value, onClose }) => {
  const {
    data: { currencyCode },
  } = useSelectStore('auth.user')
  return (
    <Pill
      variant="filter"
      type="tab"
      onClose={onClose(id)}
      marginLeft={spacing.m}
    >
      {label} {' : '}
      <FormattedNumber
        style="currency"
        currency={currencyCode}
        value={value && value[0]}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
      {' to '}
      <FormattedNumber
        style="currency"
        currency={currencyCode}
        value={value && value[1]}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    </Pill>
  )
}
