import React from 'react'
import { PopupContainer } from 'components/Common'
import { SelectSurvey } from 'components/Content/autoEngagement/components/SelectSurvey'

export const EmbedSurvey = ({
  isPopOpen,
  setIsPopOpen,
  embeddedSurvey,
  onSurveySelect,
}) => {
  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Select Survey"
    >
      <SelectSurvey
        onSurveySelect={onSurveySelect}
        selectedSurveyId={(embeddedSurvey || '')
          .replace('$survey[', '')
          .replace(']', '')}
      />
    </PopupContainer>
  )
}
