import React from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $Block } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'

export const HTMLBlockSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="HTML Block"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />
      <$Block>
        <p>HTML Block</p>
        <textarea
          rows="15"
          value={props.htmlContent}
          onChange={(e) =>
            setProp((props) => (props.htmlContent = e.target.value), 300)
          }
        />
      </$Block>
    </$Block>
  )
}
