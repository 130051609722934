const initialState = {
  details: [],
  fetchMore: true,
  totalNumbers: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_NUMBERS_DATA":
      return {
        details: action.payload.initialReq
          ? action.payload.details
          : state.details.concat(action.payload.details),
        fetchMore: action.payload.details.length === 0 ? false : true,
        totalNumbers: action.payload.totalNumbers
      };
    default:
      return state;
  }
}
