import React from 'react'

import { Text } from 'components/Common/Text'
import { $ColumnBox, $ColumnHead } from './ColumnBox.styled'

const ColumnBox = ({ title, headerControls, children, colWidth }) => {
  return (
    <$ColumnBox width={colWidth}>
      <$ColumnHead>
        <Text variant="p" weight="semi-bold" type="main">
          {title}
        </Text>
        {headerControls && headerControls}
      </$ColumnHead>
      {children}
    </$ColumnBox>
  )
}

export default ColumnBox
