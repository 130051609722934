import { INIT_FILTER } from 'components/Content/autoEngagement/constants'
import createReducer from 'utils/createReducer'
import * as types from '../types/journey'

const initialState = {
  jbData: {},
  jbPriority: [],
  jbDetails: {},
  jbTableLoading: false,
  jbLoading: false,
  filterData: INIT_FILTER,
  filterQuery: {},
  jbActions: [],
  jbStats: {},
  jbFilters: [],
  jbEvents: [],
  jbProducts: [],
}

export default createReducer(initialState)(types)
