import styled from 'styled-components'
import ArrowIcon from 'assets/icons/arrow.svg'

export const $Dropdown = styled.details`
  summary {
    list-style: none;
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacing.m};
    border: 1px solid ${({ theme }) => theme.colors.darkMercury};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    position: relative;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  summary::marker {
    display: none;
  }
  summary::after {
    content: url(${ArrowIcon});
    position: absolute;
    right: 14px;
    transform: rotate(-180deg);
    transition: all 0.5s;
  }
  &[open] summary::after {
    transform: rotate(0deg);
  }
`

export const $ProgressBar = styled.hr`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  top: 33px;
  width: ${(props) => props.width};
`

export const $PriorityContainer = styled.div`
  padding: ${(props) => props.theme.spacing.l};
  margin: ${({ theme }) => `0 ${theme.spacing.l} ${theme.spacing.x3l}`};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  > div {
    max-width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: ${(props) => props.theme.spacing.s};
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing.l};
    gap: ${(props) => props.theme.spacing.l};
    position: relative;
    border-radius: ${(props) => props.theme.borderRadius.small};
    border: 1px solid ${(props) => props.theme.colors.mercury};
  }
`
