import React, { useState } from 'react'

import { FlexBox } from 'styledComponent'
import { SectionHeading } from 'components/Common/SectionHeading'
import UnregisteredProductsList from './UnregisteredProductsList'
import {
  exportUnregisteredProducts,
  getAllUnregisteredProducts,
} from 'redux/actions/productAnalyticsActions'
import { UNREGISTERED_PRODUCTS_HEADINGS } from './constants'
import { useSelectStore } from 'hooks'
import { useAsyncRender } from '../hooks'
import { $SquareButton } from '../AllProducts/AllProducts.styled'
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import { EmailReportPopup, useToasts } from 'components/Common'
import { ERROR_DECODE } from 'utils/reusables'

const UnregisteredProducts = () => {
  const { toast } = useToasts()

  const { data: unregisteredProducts } = useSelectStore(
    'productAnalytics.unregisteredProducts'
  )
  const {
    data: { email },
  } = useSelectStore('auth.user')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedStores } = useSelectStore(
    'productAnalytics.selectedStores'
  )
  const [loading, fetchAction] = useAsyncRender({
    renderAction: getAllUnregisteredProducts,
    dependencies: [selectedStores, selectedBrand],
  })

  const [isPopOpen, setIsPopOpen] = useState(false)

  const onSendReport = async (emailList) => {
    try {
      const res = await exportUnregisteredProducts(selectedVendor?.value, {
        emailList,
        userEmail: email,
      })
      if (res) {
        toast('Report will be sent to your email shortly.', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <div>
      <FlexBox justify="space-between">
        <SectionHeading
          {...UNREGISTERED_PRODUCTS_HEADINGS}
          variant="p"
          subVariant="small"
          color="main"
          weight="bold"
        />
        <$SquareButton onClick={() => setIsPopOpen(true)} size={40}>
          <ExcelIcon />
        </$SquareButton>
      </FlexBox>
      <UnregisteredProductsList
        action={fetchAction}
        loading={loading}
        unregisteredProducts={unregisteredProducts}
      />
      <EmailReportPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        onSendReport={onSendReport}
      />
    </div>
  )
}

export default UnregisteredProducts
