import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getReportsList } from 'redux/actions/reportsAction'
import { ReportTopBar, ReportHeader, ReportTable } from './report'
import { $WhiteContainer } from './Report.styled'

const Reports = (props) => {
  const [query, setQuery] = useState({})
  const [tableLoading, setTableLoading] = useState(false)

  const setPage = (page) => {
    setQuery({
      ...query,
      page,
    })
  }

  useEffect(() => {
    query.vendorId && fetchData()
  }, [query])

  const fetchData = async () => {
    setTableLoading(true)
    const res = await props.getReportsList({
      ...query,
      limit: 10,
    })
    if (res) {
      setTableLoading(false)
    }
  }

  return (
    <$WhiteContainer>
      <ReportTopBar />
      <ReportHeader setQuery={setQuery} setPage={setPage} />
      <ReportTable setPage={setPage} tableLoading={tableLoading} />
    </$WhiteContainer>
  )
}

export default connect(null, { getReportsList })(Reports)
