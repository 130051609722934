import React from 'react'

import { CampaignDetails } from '../components'
import { CampaignSettingsWithATOC } from '../components/CampaignSettingsWithATOC'
import AdvertBuilder from './AdvertBuilder'
import { AdvertPreview } from './AdvertPreview'

export const AdvertCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails campaignType="Advert" form={form} setForm={setForm} />
      )

    case 'Advert Builder':
      return <AdvertBuilder form={form} setForm={setForm} />

    case 'Advert Audience':
      return <CampaignSettingsWithATOC form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <AdvertPreview form={form} hideSteps={hideSteps} />

    default:
      return null
  }
}
