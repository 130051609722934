import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { getWhatsAppTemplates } from '../../../../../redux/actions/autoEngageActions'
import Select, { components } from 'react-select'
import { GET_SEL_VALUE } from '../../constants'
import { useDebounce, useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import { Text, Input } from 'components/Common'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { $Label } from '../../AutoEngagement.styled'
import { CompleteIcon } from 'assets/icons'

const WhatsAppTemplate = ({ form, setForm }) => {
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { data: whatsAppTemplates } = useSelectStore(
    'autoEngage.whatsAppTemplates.docs'
  )
  const [searchTemplate, setSearchTemplate] = useState('')

  const debTemplate = useDebounce(searchTemplate, 500)
  const options = {
    limit: 20,
    skip: 0,
    status: 'live',
  }
  useEffect(() => {
    if (debTemplate) {
      options.search = searchTemplate
    }
    dispatch(getWhatsAppTemplates(options))
  }, [debTemplate])

  const templateOptions = whatsAppTemplates.map((t) => ({
    label: (
      <Div>
        <Text type="main" variant="medium" mb={spacing.m}>
          {t?.name}
        </Text>
        <pre>
          <Text type="grey" variant="small">
            {t?.content?.body}
          </Text>
        </pre>
      </Div>
    ),
    value: t._id,
  }))

  const onTemplateChange = (val) =>
    setForm({
      ...form,
      content: {
        body: {
          templateId: val?.value,
          templateParams: [],
          templateButtonParams: [],
        },
      },
    })
  return (
    <Div>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        Select Template
      </Text>
      <Input containerWidth="100%" label="Select Template">
        <FlexBox gap={spacing.xxl} mb={spacing.l} align="center">
          <$Label>
            {form?.content?.body?.templateId ? (
              GET_SEL_VALUE(form?.content?.body?.templateId, templateOptions)
                ?.label
            ) : (
              <Text align="center" type="main" variant="small">
                No Template Selected.
              </Text>
            )}
            {form?.content?.body?.templateId && <CompleteIcon />}
          </$Label>
        </FlexBox>
        <Select
          filterOption={false}
          options={templateOptions}
          onInputChange={setSearchTemplate}
          placeholder="Search & Select Template"
          value={GET_SEL_VALUE(form?.templates?.name, templateOptions) || ''}
          onChange={onTemplateChange}
          components={{ DropdownIndicator }}
          isClearable
        />
      </Input>
    </Div>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon width="15px" height="15px" />
    </components.DropdownIndicator>
  )
}

export default WhatsAppTemplate
