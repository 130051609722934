import React from 'react'

import { useRouter } from 'hooks'
import Templates from './Templates'
import WhatsAppCampaign from './Campaigns'
import { $WhiteContainer } from '../AutoEngagement.styled'
import TemplateCampaign from './Templates/TemplateCampaign'

const WhatsApp = () => {
  const { query, params } = useRouter()

  const getComponent = () => {
    switch (query?.tab) {
      case 'Templates':
        if (params) return <TemplateCampaign />
        else return <Templates />

      default:
        return <WhatsAppCampaign />
    }
  }

  return <$WhiteContainer pb="1px">{getComponent()}</$WhiteContainer>
}

export default WhatsApp
