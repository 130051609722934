import { initialFilter } from 'components/Content/reports/components/constants'
import createReducer from 'utils/createReducer'
import * as types from '../types/reports'

const initialState = {
  data: [],
  filterData: initialFilter,
  filterQuery: {},
}

export default createReducer(initialState)(types)
