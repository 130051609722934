import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'

import { Table, TableHead } from 'styledComponent'
import { Skeleton, ActionCard } from 'components/Common'

import {
  CAMP_TYPES,
  COUPON_CANCEL_DETAILS,
  COUPON_HEADER,
  GET_SEL_VALUE,
} from '../constants'
import {
  $AETableContainer,
  $Ellipsis,
  $MarkExternalUse,
} from '../AutoEngagement.styled'
import { TabRow } from '../campaigns/AETable'
import CancelPopup from '../components/CancelPopup'

const COUPON_ROW = (coupon, onActionClick) => [
  <$Ellipsis>{coupon?.name || '-'}</$Ellipsis>,
  <$Ellipsis>{coupon?.description || '-'}</$Ellipsis>,
  <span>
    {!coupon?.externalUse
      ? coupon?.campaigns
          ?.map(
            (camp) =>
              `${camp.name} (${GET_SEL_VALUE(camp.type, CAMP_TYPES)?.label})`
          )
          .join(', ') || <$MarkExternalUse>Unassigned</$MarkExternalUse>
      : 'Marked for External Use'}
  </span>,
  coupon?.prefix || '-',
  (coupon?.usedCouponCount || '-') + ' / ' + coupon?.totalCoupons || '-',
  coupon?.createdDate ? moment(coupon.createdDate).format('DD MMM YYYY') : '-',
  <ActionCard
    onIconClick={(action) =>
      onActionClick(
        action,
        coupon._id,
        coupon?.campaigns?.length || coupon?.externalUse
      )
    }
    components={[
      coupon?.campaigns?.length > 0
        ? 'disable-external'
        : coupon.externalUse
        ? 'unmark-external'
        : 'mark-external',
      'export',
      'delete',
    ]}
    item="COUPON"
  />,
]

export const CouponTable = ({
  tableLoading,
  onPaginationChange,
  couponList,
  pageCount,
  pageNumber,
  setCoupDetails,
}) => {
  const theme = useTheme()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [couponDetails, setCouponDetails] = useState('')

  const onActionClick = (action, id, isAssigned) => {
    switch (action) {
      case 'export':
        setCouponDetails({ id, action })
        setIsPopOpen(true)
        return

      case 'delete':
        setCouponDetails({ id, action, isAssigned })
        setIsPopOpen(true)
        return

      case 'mark-external':
        setCouponDetails({ id, action })
        setIsPopOpen(true)
        break
    }
  }

  return (
    <$AETableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={COUPON_HEADER} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                cells={[...new Array(COUPON_HEADER.length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : couponList?.length > 0 ? (
            couponList?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={COUPON_ROW(item, onActionClick)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={COUPON_HEADER.length}
              cells={['No Coupons Found']}
            />
          )}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={pageCount || 1}
          onPageChange={onPaginationChange}
          containerClassName="aePagination"
          activeClassName="pagination_activePage"
          forcePage={pageNumber || 0}
        />
      )}
      <CancelPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        header={COUPON_CANCEL_DETAILS(couponDetails?.action)?.header}
        title={
          COUPON_CANCEL_DETAILS(
            couponDetails?.action,
            couponDetails?.isAssigned
          )?.title
        }
        onOkClick={() => setCoupDetails(couponDetails)}
      />
    </$AETableContainer>
  )
}
