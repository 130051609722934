export const SET_PRODUCT_ANALYTICS_DATE = 'SET_DATE'
export const SET_OVERVIEW_STATS = 'SET_OVERVIEW_STATS'
export const SET_TOP_FIVE_PRODUCTS = 'SET_TOP_FIVE_PRODUCTS'
export const SET_TOP_PRODUCT_COMBINATIONS = 'SET_TOP_PRODUCT_COMBINATIONS'
export const SET_CATEGORY_ANALYTICS = 'SET_CATEGORY_ANALYTICS'
export const SET_ALL_PRODUCTS_LIST = 'SET_ALL_PRODUCTS_LIST'
export const SET_APPLIED_PRODUCTS_FILTER = 'SET_APPLIED_PRODUCTS_FILTER'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES'
export const SET_PRODUCT_ANALYTICS_STORES = 'SET_PRODUCT_ANALYTICS_STORES'
export const SET_PRODUCTS_COUNT = 'SET_PRODUCTS_COUNT'
export const SET_PA_LOADING = 'SET_PA_LOADING'
export const SET_UNREGISTERED_PRODUCTS_LIST = 'SET_UNREGISTERED_PRODUCTS_LIST'
