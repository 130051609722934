import cloneDeep from 'lodash/cloneDeep'
import { defaultFilters } from 'utils/feedback'

const initialState = {
  feedbacks: [],
  filters: {
    search: '',
    ...cloneDeep(defaultFilters),
  },
  sort: {
    id: 'newest',
    value: 'Newest First',
    sortOrder: -1,
    sortValue: '_id',
  },
  hasMoreFeedbacks: true,
  feedbackCount: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_FEEDBACKS':
      return {
        ...state,
        feedbacks: action.payload.feedbackData,
        hasMoreFeedbacks: action.payload.hasMoreFeedbacks,
        feedbackCount: action.payload.feedbackCount,
      }
    case 'SET_FEEDBACK_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case 'SET_FEEDBACK_REMEMBER_FILTERS':
      return {
        ...state,
        rememberFilters: action.payload,
      }

    case 'SET_FEEDBACK_SORT':
      return {
        ...state,
        sort: { ...action.payload },
      }
    case 'UPDATE_FEEDBACKS':
      return {
        ...state,
        feedbacks: action.payload,
      }

    case 'SET_CAMPAIGN_ID':
      return {
        ...state,
        filters: {
          ...cloneDeep(initialState.filters),
          selectedCampaign: { ...action.payload },
        },
      }

    case 'RESET_FEEDBACK_FILTERS':
      return {
        ...state,
        filters: cloneDeep(initialState.filters),
      }

    default:
      return state
  }
}
