import React from 'react'
import { CloseIcon, SurveyImageCheckIcon } from 'assets/icons'
import { $ImageSelect, $ImageContainer } from '../Survey.styled'

export const ImageSelect = (props) => {
  const isChecked = props.answer?.includes(props?.optionId) || false
  return (
    <$ImageContainer isChecked={isChecked}>
      <div>
        <div>
          <$ImageSelect src={props.option} />
          {props.isEditing && (
            <CloseIcon
              onClick={() => props.deleteOption(props.optId)}
              type="button"
              style={{ position: 'absolute', right: '12px', top: '12px' }}
            />
          )}
          <SurveyImageCheckIcon />
        </div>
        {props.isEditing ? (
          <input
            variant="input"
            inputWidth="100%"
            disabled={!props.isEditing}
            value={props.imageTitle || ''}
            onChange={(e) => props.setImageTitle(e.target.value)}
            placeholder={`Image Title ${props.optId + 1}`}
          />
        ) : (
          props.showTitle && (
            <small
              style={{ color: props.fontColor, fontFamily: props.fontFamily }}
            >
              {props.imageTitle}
            </small>
          )
        )}
      </div>
    </$ImageContainer>
  )
}
