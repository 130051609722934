import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import Select, { components } from 'react-select'

import { useDebounce, useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import { Text, Input } from 'components/Common'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { AddBlueIcon, CompleteIcon } from 'assets/icons'
import { useDispatch } from 'react-redux'
import { getSmsHeaders, getSmsTemplates } from 'redux/actions/settingsActions'
import { GET_SEL_VALUE } from '../constants'
import { $Label } from '../AutoEngagement.styled'
import { DLTSmsTemplatePopup } from 'components/Content/NewSettings/dltsettings/DLTSmsTemplatePopup'
import { DLTSmsSenderIDPopup } from 'components/Content/NewSettings/dltsettings/DLTSmsSenderIDPopup'

export const SMSTemplate = ({ form, setForm }) => {
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const {
    data: { smsTemplatesList = [], smsHeadersList = [] },
  } = useSelectStore('settings')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const smsHeaderOptions = smsHeadersList.map((smsh) => ({
    label: smsh.senderId,
    value: smsh.senderId,
  }))

  const smsTemplateOptions = smsTemplatesList.map((smst) => ({
    label: (
      <Div>
        <Text type="main" variant="medium" mb={spacing.m}>
          {smst.name}
        </Text>
        <pre>
          <Text type="grey" variant="small">
            {smst.template}
          </Text>
        </pre>
      </Div>
    ),
    value: smst._id,
  }))

  const [searchHeader, setSearchHeader] = useState('')
  const [searchTemplate, setSearchTemplate] = useState('')
  const [isPopOpen, setIsPopOpen] = useState(false)

  const debHeader = useDebounce(searchHeader, 500)
  const debTemplate = useDebounce(searchTemplate, 500)

  const options = {
    limit: 20,
    skip: 0,
    status: 'approved',
    type:
      form?.contentType === 'promotional' ? 'Promotional' : 'Service-Explicit',
  }

  useEffect(() => {
    if (debHeader) {
      options.name = searchHeader
    }
    dispatch(getSmsHeaders(options))
  }, [debHeader])

  useEffect(() => {
    options.senderId = form?.senderDetails?.name
    if (debTemplate) {
      options.name = searchTemplate
    }
    if (form?.content?.body?.templateId) {
      options._id = form?.content?.body?.templateId
    }
    options.senderId && dispatch(getSmsTemplates(options))
  }, [debTemplate, form?.senderDetails?.name])

  const onHeaderChange = (val) =>
    setForm({
      ...form,
      senderDetails: {
        ...form?.senderDetails,
        type: 'sms',
        name: val?.value,
      },
      content: {
        body: {
          templateId: null,
          templateParams: [],
          displayParams: [],
        },
      },
    })

  const onTemplateChange = (val) =>
    setForm({
      ...form,
      content: {
        body: {
          templateId: val?.value,
          templateParams: [],
          displayParams: [],
        },
      },
    })

  return (
    <Div>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        Sender ID & Template
      </Text>
      <Input label="Select Sender ID">
        <FlexBox gap={spacing.xxl} mb={spacing.l} align="center">
          <$Label>
            <Text align="center" type="main" variant="small">
              {form?.senderDetails?.name
                ? form?.senderDetails?.name
                : 'No Sender ID Selected.'}
            </Text>
            {form?.senderDetails?.name && <CompleteIcon />}
          </$Label>
          <FlexBox
            type="button"
            gap={spacing.l}
            align="center"
            onClick={() => setIsPopOpen('dlt-header')}
          >
            <AddBlueIcon />
            <Text align="center" type="primary" variant="small">
              Upload New Sender IDs
            </Text>
          </FlexBox>
        </FlexBox>
        <Select
          filterOption={false}
          options={smsHeaderOptions}
          onInputChange={setSearchHeader}
          placeholder="Search & Select Header"
          value={
            GET_SEL_VALUE(form?.senderDetails?.name, smsHeaderOptions) || ''
          }
          onChange={onHeaderChange}
          components={{ DropdownIndicator }}
          isClearable
        />
      </Input>
      <Input label="Select Template">
        <FlexBox gap={spacing.xxl} mb={spacing.l} align="center">
          <$Label>
            {form?.content?.body?.templateId ? (
              GET_SEL_VALUE(form?.content?.body?.templateId, smsTemplateOptions)
                ?.label
            ) : (
              <Text align="center" type="main" variant="small">
                No Template Selected.
              </Text>
            )}
            {form?.content?.body?.templateId && <CompleteIcon />}
          </$Label>
          <FlexBox
            type="button"
            gap={spacing.l}
            align="center"
            onClick={() => setIsPopOpen('dlt-template')}
          >
            <AddBlueIcon />
            <Text align="center" type="primary" variant="small">
              Upload New Templates
            </Text>
          </FlexBox>
        </FlexBox>
        <Select
          filterOption={false}
          isDisabled={!form?.senderDetails?.name}
          options={smsTemplateOptions}
          onInputChange={setSearchTemplate}
          placeholder="Search & Select Template"
          value=""
          onChange={onTemplateChange}
          components={{ DropdownIndicator }}
          isClearable
        />
      </Input>
      <DLTSmsSenderIDPopup
        isPopOpen={isPopOpen === 'dlt-header'}
        setIsPopOpen={setIsPopOpen}
        vendorId={vendorId}
      />
      <DLTSmsTemplatePopup
        isPopOpen={isPopOpen === 'dlt-template'}
        setIsPopOpen={setIsPopOpen}
      />
    </Div>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon width="15px" height="15px" />
    </components.DropdownIndicator>
  )
}
