import React from 'react'
import { connect } from 'react-redux'
import { FormattedDate } from 'react-intl'
import { withRouter } from 'react-router-dom'
// import { toast } from "react-toastify";
import { useToasts } from 'components/Common'
import FileDownload from 'js-file-download'
import { get, patch, post } from 'utils/axiosHandler'
import {
  setCampaignMessage,
  setRememberFilters,
  getFeedbacks,
  updateFeedbackCampaigns,
} from 'redux/actions'
import { setSideBarLink } from 'redux/actions/sidebarLinkActions'
import {
  CheckBox,
  EditIcon,
  MessageIcon,
  GrowthIcon,
  ExportXIcon,
  InfoIcon,
  TableRow,
  TableCell,
  TextualButton,
} from 'styledComponent'

const CampaignItem = (props) => {
  const { toast } = useToasts()

  const updateCampaigns = (params) => {
    const campaigns = [...props.campaigns]
    const updatedCampaigns = campaigns.map((campaign) => ({
      ...campaign,
      ...(campaign.campaignId === props.campaign.campaignId && params),
    }))
    props.updateFeedbackCampaigns(updatedCampaigns)
  }

  const remmoveStatsFromCampaign = () => {
    /* Remove Stats From Campaign */
    const campaigns = [...props.campaigns]
    const updatedCampaigns = campaigns.map((campaign) => {
      if (campaign.campaignId === props.campaign.campaignId) {
        delete campaign.isStatsLoading
        delete campaign.stats
      }

      return { ...campaign }
    })

    props.updateFeedbackCampaigns(updatedCampaigns)
  }

  const handleReportClick = async () => {
    if (props.campaign.stats) {
      return remmoveStatsFromCampaign()
    }

    updateCampaigns({
      isStatsLoading: true,
    })

    const result = await get('/feedback/campaign/stats', {
      campaignId: props.campaign.campaignId,
    })

    if (result.code) {
      const stats = {
        percentage: {
          '1_star': (
            (result.data['1_star'] / result.data['totalForms']) *
            100
          ).toFixed(2),
          '2_star': (
            (result.data['2_star'] / result.data['totalForms']) *
            100
          ).toFixed(2),
          '3_star': (
            (result.data['3_star'] / result.data['totalForms']) *
            100
          ).toFixed(2),
          '4_star': (
            (result.data['4_star'] / result.data['totalForms']) *
            100
          ).toFixed(2),
          '5_star': (
            (result.data['5_star'] / result.data['totalForms']) *
            100
          ).toFixed(2),
          forms_filled: (
            (result.data['totalFilledForms'] / result.data['totalForms']) *
            100
          ).toFixed(2),
        },
        ...result.data,
      }

      updateCampaigns({
        stats,
        isStatsLoading: false,
      })
    } else {
      updateCampaigns({})
    }
  }

  const handleActivateButton = async () => {
    const campaignId = props.campaign.campaignId,
      isActive = !props.campaign.isActive
    if (campaignId === 'default') return

    const result = await patch(`/feedback/campaign`, {
      isActive,
      campaignId,
    })
    if (result.code) {
      updateCampaigns({
        isActive,
      })
      // isActive ? toast.success(`Feedback Campaign '${props.campaign.campaignName}' Activated.`) : toast.warn(`Feedback Campaign '${props.campaign.campaignName}' Deactivated.`)
      isActive
        ? toast(
            `Feedback Campaign '${props.campaign.campaignName}' Activated.`,
            'success'
          )
        : toast(
            `Feedback Campaign '${props.campaign.campaignName}' Deactivated.`,
            'warning'
          )
    } else {
    }
  }

  const handleMessageClick = (campaignId, campaignName) => {
    const selectedCampaignFilter = {
      isActive: true,
      value: {
        value: campaignId,
        label: campaignName,
      },
    }

    props.setCampaignMessage(selectedCampaignFilter)
    props.setRememberFilters(false)
    props.getFeedbacks()
    props.history.push('/feedback')
    props.setSideBarLink('feedback')
  }

  const handleExportClick = async () => {
    const { campaignId } = props.campaign

    const data = await post(
      `/feedback/campaign/exportCampaignData`,
      {
        campaignId,
      },
      {
        responseType: 'arraybuffer',
      }
    )

    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileDownload(blob, 'feedbacks_campaign.xlsx')
  }

  const isDefaultCampaign = props.campaign.campaignId === 'default'

  return (
    <TableRow highlightOnHover="hsl(0, 0%, 97%)">
      <TableCell width="70px" textAlign="center">
        {props.index}
      </TableCell>
      <TableCell width="30%">{props.campaign.campaignName}</TableCell>
      <TableCell>
        {props.campaign.createdDate ? (
          <FormattedDate value={props.campaign.createdDate} />
        ) : (
          `-`
        )}
      </TableCell>
      <TableCell>
        <span style={{ textTransform: 'capitalize' }}>
          {props.campaign.type}
        </span>
        {isDefaultCampaign && (
          <InfoIcon cursor className="ml-2" vertical-align="text-bottom" />
        )}
      </TableCell>
      <TableCell textAlign="center">
        <CheckBox
          id={props.campaign.campaignId}
          defaultChecked={props.campaign.isActive}
          disabled={isDefaultCampaign}
          className="my-auto mx-2"
          onClick={handleActivateButton}
        />
      </TableCell>
      <TableCell textAlign="center">
        <TextualButton
          disabled={isDefaultCampaign}
          size="small"
          className="mr-1"
          onClick={props.onCampaignEditClick}
        >
          <EditIcon
            className="mr-1"
            colorVariant={isDefaultCampaign && 'neutral'}
          />
          Edit
        </TextualButton>
        <TextualButton
          size="small"
          className="mr-1"
          onClick={handleExportClick}
        >
          <ExportXIcon className="mr-1" />
          Export
        </TextualButton>
        <TextualButton
          size="small"
          className="mr-1"
          onClick={() =>
            handleMessageClick(
              props.campaign.campaignId,
              props.campaign.campaignName
            )
          }
        >
          <MessageIcon className="mr-1" />
          Messages
        </TextualButton>
        <TextualButton
          size="small"
          className="mr-1"
          onClick={handleReportClick}
          active={props.campaign.stats}
        >
          <GrowthIcon className="mr-1" />
          Reports
        </TextualButton>
      </TableCell>
    </TableRow>
  )
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.feedbackCampaign.data,
  }
}

export default connect(mapStateToProps, {
  updateFeedbackCampaigns,
  setCampaignMessage,
  setRememberFilters,
  getFeedbacks,
  setSideBarLink,
})(withRouter(CampaignItem))
