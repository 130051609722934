import React from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'hooks'
import { FlexBox } from 'styledComponent'
import {
  Text,
  ButtonDropdown,
  Button,
  Input,
  Breadcrumb,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { spacing } from 'design-system'
import { CAMP_TYPES } from '../constants'
import { ReactComponent as AddIcon } from 'assets/icons/add-blue.svg'
import { $MarginlessLine } from '../AutoEngagement.styled'

const AETopBar = ({ currentTab, setCurrentTab, heading, activeTab }) => {
  const { history, location, query } = useRouter()
  const routeId = location.pathname.split('/')[2]
  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )
  const isTemplateTab = activeTab === 'Templates'

  const featurePermission = (campaign) => {
    switch (campaign) {
      case 'sms':
        return featuresAlloted.smsCampaign
      case 'email':
        return featuresAlloted.emailCampaign
      case 'bannerInBill':
        return featuresAlloted.bannerCampaign
      case 'adBelowBill':
        return featuresAlloted.adCampaign
      case 'surveyInBill':
        return featuresAlloted.surveyCampaign
      case 'sellBelowBill':
        return featuresAlloted.sellCampaign
      case 'popupOverBill':
        return featuresAlloted.popupCampaign
      case 'whatsApp':
        return featuresAlloted.whatsAppCampaign
    }
  }
  return (
    <>
      <Navbar heading={heading} />
      <FlexBox p={spacing.l} justify="space-between" align="center">
        <Breadcrumb label={currentTab?.label || 'All Campaigns'} />
      </FlexBox>
      <$MarginlessLine my={0} />
      <FlexBox px={spacing.l} justify="space-between" align="center">
        <FlexBox gap={spacing.m} justify="space-between" align="center">
          <Text variant="p">Select Campaign Listing</Text>
          <Input
            name="campType"
            inputWidth="250px"
            containerMargin="0"
            options={[
              { label: 'All Campaigns', value: 'all' },
              ...CAMP_TYPES.filter((x) => featurePermission(x.value)),
            ]}
            variant="single-select"
            placeholder="Select Campaign"
            value={currentTab}
            onChange={setCurrentTab}
          />
        </FlexBox>
        {Boolean(routeId) ? (
          <Button
            variant="primary"
            startIcon={AddIcon}
            onClick={() =>
              history.push({
                pathname: `/auto-engage/${routeId}/create`,
                ...(query
                  ? { search: '?' + new URLSearchParams(query).toString() }
                  : {}),
              })
            }
          >
            New {currentTab?.label} {isTemplateTab ? 'Template' : 'Campaign'}
          </Button>
        ) : (
          <ButtonDropdown startIcon width="180px" buttonText="New Campaign">
            {CAMP_TYPES.filter((x) => featurePermission(x.value)).map(
              (camp) => (
                <Text
                  key={camp.value}
                  variant="medium"
                  type="grey"
                  onClick={() =>
                    history.push(`/auto-engage/${camp.value}/create`)
                  }
                >
                  {camp.label}
                </Text>
              )
            )}
          </ButtonDropdown>
        )}
      </FlexBox>
      <$MarginlessLine my={0} />
    </>
  )
}

export default AETopBar
