import React from 'react'

import { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'
import { Text, Button } from 'components/Common'

import { $File } from '../AutoEngagement.styled'
import { VideoPlaceholderIcon, FilePlaceholderIcon } from 'assets/icons'

export const RecentUploads = ({
  assetsList,
  limit,
  setLimit,
  assetsCount,
  setMedia,
  mediaType,
  activeArray,
  height,
  setRefreshList,
}) => {
  const theme = useTheme()
  return (
    <Div height={height} overflow="auto" px={theme.spacing.l}>
      <Text variant="h4" type="main" weight="bold">
        Recent Uploads
      </Text>
      <FlexBox py={theme.spacing.l} gap={theme.spacing.l} wrap="wrap">
        {assetsList?.length > 0 ? (
          assetsList?.map((file) => (
            <div
              style={{ cursor: 'pointer' }}
              key={file.createdDate}
              onClick={() => setMedia(file)}
            >
              <$File active={activeArray?.includes(file.fileLocation)}>
                {mediaType === 'video' ? (
                  <VideoPlaceholderIcon />
                ) : mediaType === 'pdf' ? (
                  <FilePlaceholderIcon />
                ) : (
                  <img src={file.fileLocation} title={file.name} />
                )}
              </$File>
              <Text
                m="auto"
                align="center"
                ellipsis
                width="95px"
                mt={theme.spacing.s}
                variant="small"
                type="grey"
              >
                {file.name}
              </Text>
            </div>
          ))
        ) : (
          <Text variant="h4" type="grey" weight="bold">
            No Uploads found.
          </Text>
        )}
      </FlexBox>
      <FlexBox
        width="100%"
        align="center"
        justify="center"
        gap={theme.spacing.l}
      >
        {setRefreshList && (
          <Button
            fontSize="12"
            onClick={() => setRefreshList(Math.random())}
            variant="primaryOutline"
          >
            Refresh
          </Button>
        )}
        {limit < assetsCount && (
          <Button
            fontSize="12"
            onClick={() => setLimit(limit + 10)}
            variant="primary"
          >
            Show more
          </Button>
        )}
      </FlexBox>
    </Div>
  )
}
