import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import { FlexBox as $FlexBox } from 'styledComponent'
import HeatMap from 'components/Common/HeatMap'
import { useRouter, useSelectStore, useLazyStoreFetch } from 'hooks'
import {
  SectionHeading,
  Skeleton,
  PieChart,
  LineChart,
  BarChart,
  EmptyCharts,
} from 'components/Common'
import { spacing, color } from 'design-system'
import {
  getConsumerSegmentAvgSpend,
  getConsumerSegmentAvgSku,
  getConsumerSegmentRetention,
  getConsumerSegmentAge,
  getConsumerSegmentGender,
  setFetchNewData,
  setCAPreviousSelected,
  setFetchNewChartData,
  getSegmentRegionData,
} from 'redux/actions/consumerAnalyticsActions'

import { CHART_TOOLTIPS, CHART_TAB_LABELS } from '../constants'

const AnalyticsCharts = ({ colView, exploreScreen, segmentTypes }) => {
  const dispatch = useDispatch()
  const { pathname, query, setQuery } = useRouter()
  const { data: segmentsList } = useSelectStore('cAnalytics.segmentsList')
  const { data: selectedDate } = useSelectStore('cAnalytics.selectedDate')
  const { data: selectedStores } = useSelectStore('stores.selectedStores')
  const { data: selectedSegments } = useSelectStore(
    'cAnalytics.selectedSegments'
  )
  const { data: shouldFetchNewData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewData'
  )
  const { data: shouldFetchNewChartData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewChartData'
  )

  const { tab } = query

  const {
    data: avgSkuData,
    fetchData: fetchAvgSku,
    loading: avgSkuLoading,
  } = useLazyStoreFetch(getConsumerSegmentAvgSku, 'cAnalytics.avgSkuSegment')

  const {
    data: avgSpendData,
    loading: avgSpendLoading,
    fetchData: refetchAvgSpend,
  } = useLazyStoreFetch(
    getConsumerSegmentAvgSpend,
    'cAnalytics.avgSpendSegment'
  )

  const {
    data: retentionData,
    loading: retentionLoading,
    fetchData: refetchRetention,
  } = useLazyStoreFetch(
    getConsumerSegmentRetention,
    'cAnalytics.retentionSegment'
  )

  const {
    data: ageData,
    loading: ageDataLoading,
    fetchData: fetchAgeData,
  } = useLazyStoreFetch(getConsumerSegmentAge, 'cAnalytics.ageSegment')

  const {
    data: genderData,
    loading: genderDataLoading,
    fetchData: fetchGenderData,
  } = useLazyStoreFetch(getConsumerSegmentGender, 'cAnalytics.genderSegment')

  const {
    data: segmentsRegion,
    fetchData: fetchRegionData,
    loading: segmentsRegionLoading,
  } = useLazyStoreFetch(getSegmentRegionData, 'cAnalytics.segmentsRegion')

  /**
   * User is on SKU chart page
   * Goes to Customer list page
   * Clicks back and goes back to previous page
   *    - Should fetch the tab data only if factors have changed [date, store, segment]
   */

  /* React.useEffect(() => {
    const segmentTypes = getSegmentTypes()
    switch (tab) {
      case CHART_TAB_LABELS.avgSKU:
        fetchAvgSku(segmentTypes)
        break

      case CHART_TAB_LABELS.gender:
        fetchGenderData()
        break

      case CHART_TAB_LABELS.age:
        fetchAgeData()
        break

      case CHART_TAB_LABELS.retention:
        refetchRetention(segmentTypes)
        break

      case CHART_TAB_LABELS.avgSpend:
      default:
        refetchAvgSpend(segmentTypes)
    }
  }, [tab]) */

  React.useEffect(() => {
    const fetchAndCheck = () => {
      if (shouldFetchNewData || shouldFetchNewChartData) {
        if (!exploreScreen || segmentTypes.length) fetchChartsBasedOnTabs()

        if (shouldFetchNewData) dispatch(setFetchNewData(false))
        if (shouldFetchNewChartData) dispatch(setFetchNewChartData(false))
      }

      dispatch(
        setCAPreviousSelected({
          selectedDate,
          selectedStores,
          selectedSegments,
          tab,
        })
      )
    }

    fetchAndCheck()
  }, [
    segmentTypes.length,
    selectedDate,
    selectedStores,
    shouldFetchNewData,
    shouldFetchNewChartData,
    tab,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchChartsBasedOnTabs = () => {
    const dataObj = segmentTypes.length ? { segmentTypes } : null

    switch (tab) {
      case CHART_TAB_LABELS.avgSKU:
        fetchAvgSku(dataObj)
        break

      case CHART_TAB_LABELS.gender:
        fetchGenderData()
        break

      case CHART_TAB_LABELS.age:
        fetchAgeData()
        break

      case CHART_TAB_LABELS.retention:
        refetchRetention(dataObj)
        break

      case CHART_TAB_LABELS.region:
        fetchRegionData(dataObj)
        break

      case CHART_TAB_LABELS.avgSpend:
      default:
        refetchAvgSpend(dataObj)
    }
  }

  const _findSegmentLabel = (segmentType) => {
    if (!segmentTypes) return 'N/A'
    const segmentLabel =
      segmentsList.find(({ name }) => name === segmentType)?.name || 'N/A'
    return segmentLabel
  }

  const _formatVisitInterval = (dayNumber) => {
    if (!dayNumber || typeof dayNumber !== 'number') return ''

    const startDate = moment.utc(selectedDate?.date?.startDate || '')
    let year = startDate.year()
    //get day of year number
    const startDayNumber = startDate.dayOfYear()

    if (dayNumber && dayNumber < startDayNumber) {
      year++
    }
    const date = new Date(year, 0)

    return moment(date.setDate(dayNumber)).format('DD/MM/YYYY')
  }

  const _formatDataForCharts = ({ data, key, keyTitle, value }) => {
    const defaultReturn = { data: [] }
    if (!data) return defaultReturn
    if (data.graph) {
      const formattedData = data.graph.map((item) => ({
        key:
          key === 'visitInterval'
            ? _formatVisitInterval(item[key])
            : `${keyTitle ? `${keyTitle} - ` : ''}${item[key]}`,
        value:
          item[value] || item[value] === 0 ? Number(item[value].toFixed(0)) : 0,
      }))
      return { data: formattedData }
    }

    if (Array.isArray(data) && data?.length) {
      const formattedData = data.reduce(
        (acc, curr) => {
          const keyCollection = []
          const seriesCollection = [
            {
              name: _findSegmentLabel(curr.segmentName),
              data: [],
            },
          ]

          if (curr?.graph) {
            curr.graph.forEach((item) => {
              const keyItem =
                key === 'visitInterval'
                  ? _formatVisitInterval(item[key])
                  : `${keyTitle ? `${keyTitle} - ` : ''}${item[key]}`
              keyCollection.push({ key: keyItem })
              const seriesItem =
                item[value] || item[value] === 0
                  ? Number(item[value].toFixed(0))
                  : 0
              seriesCollection[0].data.push(seriesItem)
            })
          }

          acc.keys.push(...keyCollection)
          acc.series.push(...seriesCollection)
          return acc
        },
        {
          keys: [],
          series: [],
        }
      )

      const multiSeriesData = {
        data: [...formattedData.keys],
        series: formattedData.series,
      }
      return multiSeriesData
    }

    return defaultReturn
  }

  const _findOverallValue = (data, prefix = '', suffix = '') => {
    if (!data) return null
    let overallValue = ''

    if (data.overall) {
      overallValue = data.overall.toFixed(0)
    }

    if (Array.isArray(data) && data.length === 1) {
      overallValue = data[0]?.overall?.toFixed(0) || ''
    }

    return overallValue ? `${prefix}${overallValue}${suffix}` : null
  }

  const calcOverallTotal = (data, key) => {
    if (!data || !data.length) return null
    const total = data.reduce((acc, curr) => {
      return (acc += curr[key])
    }, 0)
    return total
  }

  const onDataPointClick = () => {
    const { tab } = query
    const tabQuery = { tab }
    setQuery(tabQuery, {
      pathname: pathname + '/explore',
    })
  }

  const chartClickHandler = !exploreScreen
    ? {
        onDataPointClick,
      }
    : {}

  return (
    <Tabs colView={colView}>
      <div
        label="Avg. Spend"
        value={_findOverallValue(avgSpendData, '₹ ')}
        isLoading={avgSpendLoading}
      >
        {!avgSpendData ||
        (!avgSpendData[0]?.graph?.length && !avgSpendData?.graph) ? (
          <EmptyCharts />
        ) : (
          <LineChart
            data={_formatDataForCharts({
              data: avgSpendData,
              key: 'visitInterval',
              value: 'averageSpent',
            })}
            title="₹"
            height={350}
            {...chartClickHandler}
          />
        )}
      </div>
      <div
        label="Avg. SKU"
        value={_findOverallValue(avgSkuData)}
        tooltip={CHART_TOOLTIPS.avgSku}
        isLoading={avgSkuLoading}
      >
        {!avgSkuData ||
        (!avgSkuData[0]?.graph?.length && !avgSkuData?.graph) ? (
          <EmptyCharts />
        ) : (
          <LineChart
            data={_formatDataForCharts({
              data: avgSkuData,
              key: 'visitInterval',
              value: 'averageSKU',
            })}
            height={350}
            {...chartClickHandler}
          />
        )}
      </div>
      <div
        label="Gender"
        value={genderData ? genderData?.male + genderData?.female : '--'}
        isLoading={genderDataLoading}
      >
        {!genderData?.male && !genderData?.female ? (
          <EmptyCharts />
        ) : (
          <PieChart
            data={[
              {
                key: 'Male',
                value: genderData?.male || 0,
              },
              {
                key: 'Female',
                value: genderData?.female || 0,
              },
            ]}
            height={350}
            {...chartClickHandler}
          />
        )}
      </div>
      <div
        label="Age"
        value={calcOverallTotal(ageData, 'count')}
        isLoading={ageDataLoading}
      >
        {!ageData?.length ? (
          <EmptyCharts />
        ) : (
          <BarChart
            data={_formatDataForCharts({
              data: { graph: ageData },
              key: 'age',
              keyTitle: 'Age',
              value: 'count',
            })}
            height={350}
            barWidth="30%"
            {...chartClickHandler}
          />
        )}
      </div>
      <div
        label="Retention"
        value={_findOverallValue(retentionData, '', '%')}
        tooltip={CHART_TOOLTIPS.retention}
        isLoading={retentionLoading}
      >
        {!retentionData ||
        (!retentionData[0]?.graph?.length && !retentionData?.graph) ? (
          <EmptyCharts />
        ) : (
          <LineChart
            data={_formatDataForCharts({
              data: retentionData,
              key: 'visitInterval',
              value: 'retentionRate',
            })}
            title="%"
            height={350}
            {...chartClickHandler}
          />
        )}
      </div>
      <div
        label="Region"
        value={
          segmentsRegion?.mapData?.length
            ? _findOverallValue(segmentsRegion)
            : ''
        }
        tooltip="Region Heat Map"
        isLoading={segmentsRegionLoading}
      >
        {segmentsRegion?.mapData?.length ? (
          <HeatMap markers={segmentsRegion.mapData} height="500px" />
        ) : (
          <EmptyCharts />
        )}
      </div>
    </Tabs>
  )
}

AnalyticsCharts.defaultProps = {
  colView: true,
  segmentTypes: [],
}

export default AnalyticsCharts

const Tabs = React.memo(({ colView, ...props }) => {
  const children = React.Children.toArray(props.children)

  const { query, setQuery } = useRouter()
  const { tab = children[0].props.label } = query

  const setTab = (tab) => {
    const tabQuery = { tab }
    setQuery(tabQuery, {
      preservePrevState: true,
    })
  }

  return (
    <$FlexBox direction={colView ? 'column' : 'row'}>
      <$TabMenus colView={!colView}>
        {children.map(({ props: { label, value, tooltip } }) => (
          <Tab
            key={label}
            label={label}
            value={value}
            tooltip={tooltip}
            active={tab}
            clickHandler={setTab}
            colView={!colView}
          />
        ))}
      </$TabMenus>
      <$TabContent>
        {children.map((content, index) =>
          content.props.label === tab ? (
            <React.Fragment key={index}>
              {content.props.isLoading ? (
                <Skeleton count={1} height="300px" />
              ) : (
                <div>{content.props.children}</div>
              )}
            </React.Fragment>
          ) : null
        )}
      </$TabContent>
    </$FlexBox>
  )
})

const Tab = ({ clickHandler, label, active, value, tooltip, colView }) => {
  return (
    <$TabItem
      colView={colView}
      onClick={() => clickHandler(label)}
      active={active === label}
    >
      <SectionHeading
        heading={label}
        toolTip={tooltip}
        variant="medium"
        color={active === label ? 'primary' : 'grey'}
      />
      <$SectionValue>{value || '--'}</$SectionValue>
    </$TabItem>
  )
}

const $TabMenus = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: ${(props) => (props.colView ? 'column' : 'row')};
  justify-content: ${(props) => (props.colView ? 'space-between' : 'unset')};
`

const $TabItem = styled.li`
  padding: ${spacing.l} ${spacing.m};
  flex-basis: 5%;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: ${(props) => (props.active ? color.primary.default : '#717171')};
  border: 1px solid #dedede;
  &:not(:nth-child(1)) {
    border-left: none;
  }

  ${(props) =>
    props.active
      ? `background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgb(221, 230, 244) 0%, rgb(255,255,255) 100%);`
      : ''}

  &:after {
    ${(props) =>
      props.active
        ? `content: "";
      width: ${props.colView ? '3px' : '100%'};
      height: ${props.colView ? '100%' : '3px'};
      position: absolute;
      bottom: ${props.colView ? 'unset' : 0};
      top: ${props.colView ? '0' : 'unset'};
      left: 0;
      background-color: ${color.primary.default};
    `
        : ''}
  }
`

const $SectionValue = styled.span`
  margin-top: ${spacing.m};
  display: inline-block;
  font-size: 24px;
  font-weight: 900;
`

const $TabContent = styled.div`
  padding: 20px;
  width: 100%;
  align-self: center;
`
