import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'components/Common'
import ProductIcon from '../../../components/ProductIcon'
import ProductRank from '../../../components/ProductRank'
import {
  $ThumbnailBlock,
  $ThumbnailImage,
  $MetaData,
  $MetaDataItem,
  $ThumbnailText,
  $ProductRankText,
  $ProductRankIcon,
} from './ProductStatistics.styled'

/**
 * @description product thumbnail component
 * @param {String} productName
 * @param {String} category
 * @param {String} id
 * @param {Number} rank
 */
const ProductThumbnail = ({ productName, rank, category, id }) => {
  return (
    <$ThumbnailBlock>
      <$ThumbnailImage>
        <ProductIcon
          productTitle={productName}
          width="100%"
          height="250px"
          variant="large"
          bgColor={'#1EBCD1'}
        />
      </$ThumbnailImage>

      <$ThumbnailText>
        <Text variant="h3" align={'center'} wordBreak>
          {productName}
        </Text>
        <$MetaData>
          <$MetaDataItem>
            <span>Category:</span>
            <span>{category}</span>
          </$MetaDataItem>
          <$MetaDataItem>
            <span>ID:</span>
            <span>{id}</span>
          </$MetaDataItem>
        </$MetaData>
      </$ThumbnailText>
      <$ProductRankText>Product rank</$ProductRankText>
      <$ProductRankIcon>
        <ProductRank rank={rank} />
      </$ProductRankIcon>
    </$ThumbnailBlock>
  )
}

ProductThumbnail.prototype = {
  productName: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default ProductThumbnail
