import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useToasts } from 'components/Common'
import { useRouter, useSelectStore } from 'hooks'
import {
  postSurvey,
  setSurveyData,
  updateSurvey,
  getSurveyById,
  setCampaignLoader,
} from 'redux/actions/autoEngageActions'

import { ERROR_DECODE, DIFF_OBJ, FILTER_EMPTY } from '../../constants'

export const useSurveyBuilderApi = (form, setForm, initState, type) => {
  const { toast } = useToasts()
  const { history, location } = useRouter()
  const dispatch = useDispatch()

  const [surveyId, setSurveyId] = useState('')
  const isView = location.pathname?.split('/')[4]
  const hideSteps = ['live', 'unpublished'].includes(form?.status) || isView

  // Redux Store
  const {
    data: { aeSurveyData },
  } = useSelectStore('autoEngage')
  const {
    data: { userId },
  } = useSelectStore('auth.user')
  const { data } = useSelectStore('vendorIds.selectedVendor.value')
  const vendorId = data

  // Fitered Body
  const filteredBody = FILTER_EMPTY(DIFF_OBJ(form, aeSurveyData))

  const fetchSurveyById = () => dispatch(getSurveyById(surveyId))

  useEffect(() => {
    if (
      surveyId &&
      surveyId !== 'create' &&
      Object.keys(aeSurveyData || {})?.length === 0
    ) {
      fetchSurveyById()
    }
    let tempForm = { ...form, vendorId }
    if (aeSurveyData) {
      tempForm = { ...tempForm, ...aeSurveyData }
    }
    setForm(tempForm)
  }, [surveyId, vendorId, aeSurveyData])

  useEffect(() => {
    const routeId = location.pathname.split('/')[3]
    if (!routeId || routeId === 'create') {
      dispatch(setSurveyData({}))
      setForm({ ...initState(userId, vendorId, type), campaignType: 'survey' })
    }
    setSurveyId(routeId)
  }, [location])

  useEffect(() => () => dispatch(setSurveyData({})), [])

  const updSurvey = async (status = 'draft', exit = false) => {
    dispatch(setCampaignLoader(true))
    try {
      const res = await updateSurvey(surveyId, {
        ...filteredBody,
        status,
        vendorId,
      })
      if (res.data) {
        exit && history.push(`/auto-engage/surveys`)
        dispatch(setSurveyData(res.data))
        dispatch(setCampaignLoader(false))
      } else {
        dispatch(setCampaignLoader(false))
        throw res
      }
    } catch (err) {
      dispatch(setCampaignLoader(false))
      toast(ERROR_DECODE(err), 'error')
      throw ERROR_DECODE(err)
    }
  }

  const createSurvey = async (exit) => {
    if (!filteredBody.name) {
      history.push(`/auto-engage/surveys`)
      return
    }
    dispatch(setCampaignLoader(true))
    try {
      const res = await postSurvey(filteredBody)
      if (res.data) {
        const { _id } = res.data
        exit
          ? history.push(`/auto-engage/surveys`)
          : history.push(`/auto-engage/surveys/${_id}`)
        dispatch(setCampaignLoader(false))
        return 'success'
      } else {
        dispatch(setCampaignLoader(false))
        throw res
      }
    } catch (err) {
      dispatch(setCampaignLoader(false))
      toast(ERROR_DECODE(err), 'error')
      throw ERROR_DECODE(err)
    }
  }

  const onSave = async () => {
    Object.keys(filteredBody)?.length > 0
      ? surveyId && surveyId !== 'create'
        ? await updSurvey('draft', true)
        : await createSurvey(true)
      : history.push(`/auto-engage/surveys`)
  }

  const onPublish = async () => {
    surveyId && (await updSurvey('live', true))
    toast(
      'Campaign will be deactivated automatically if embedded surveys are totally used',
      'warning'
    )
  }

  const body = { ...filteredBody }
  return {
    createSurvey,
    updSurvey,
    onSave,
    onPublish,
    surveyId,
    body,
    fetchSurveyById,
    hideSteps,
  }
}
