import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch } from 'react-redux'

import { useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import {
  Text,
  // Checkbox,
  SelectBrand,
  SelectStores,
  Button,
  useToasts,
} from 'components/Common'
import { SEL_POPUP_STYLE } from '../constants'
import { CloseIcon, CheckmarkGreenIcon, CompleteIcon } from 'assets/icons'
import {
  $Label,
  $PopContainer,
  $SelectButton,
  // $SelectedBrandOrStore,
} from '../AutoEngagement.styled'
// import StoreFilter from 'components/Content/manageStores/StoreFilter'
// import { setAudienceStoreFilter } from 'redux/actions/autoEngageActions'
// import { getActiveFilter } from 'utils/filter'
import { AEStoreFilter } from './AEStoreFilter'

export const Audience = ({ targetForm, setTargetForm, isJourney }) => {
  // const dispatch = useDispatch()

  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const { data: allStores } = useSelectStore('stores.stores')
  const { data: allBrands } = useSelectStore('brand.brands')
  // const { data: audienceStoreFilter } = useSelectStore(
  //   'autoEngage.audienceStoreFilter'
  // )

  const { toast } = useToasts()

  const [isBrandPop, setIsBrandPop] = useState(true)
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [form, setForm] = useState({
    brands:
      allBrands?.filter(
        (ab) => targetForm?.targetGroup?.brandId === ab.brandId
      ) || [],
    stores:
      Object.values(allStores)?.filter((sto) =>
        targetForm?.targetGroup?.storeIds?.includes(sto.storeId)
      ) || [],
  })
  // const storeFilterRef = React.useRef()

  // const activeFilter = getActiveFilter(audienceStoreFilter)

  // const allVendorStores = Object.values(allStores)?.filter(
  //   (store) =>
  //     store.vendorId === selectedVendor?.value &&
  //     form.brands?.map((b) => b.brandId)?.includes(store.brandId)
  // )

  // const storeCheck = form.stores?.length === allVendorStores?.length && form.stores?.length > 0

  useEffect(() => {
    ;(form?.brands[0]?.brandId || isJourney) &&
      setTargetForm({
        ...targetForm,
        targetGroup: {
          ...targetForm?.targetGroup,
          brandId: form?.brands[0]?.brandId || '',
          storeIds: form?.stores?.map((store) => store.storeId) || [],
          type: 'stores',
        },
      })
  }, [form])

  const openPop = (pop) => {
    setIsBrandPop(pop)
    setIsPopOpen(true)
  }

  // const handleStoreCheck = () => {
  //   const stores = storeCheck ? [] : allVendorStores
  //   if (form.brands?.length < 1)
  //     return toast('Please select a brand before choosing stores', 'error')
  //   if (stores?.length < 1) toast('Please select atleast one store', 'error')
  //   else setForm({ ...form, stores })
  // }

  const setStores = (stores) => {
    if (form.brands?.length < 1)
      return toast('Please select a brand before choosing stores', 'error')
    // if (stores?.length < 1) toast('Please select atleast one store', 'error')
    else setForm({ ...form, stores })
  }

  // const handleStoreFilterPopup = () =>
  //   storeFilterRef?.current?.openFilterModal()

  // const handleOnFilterSet = (filters) => {
  //   dispatch(setAudienceStoreFilter(filters))
  // }

  return (
    <>
      <Popup
        open={isPopOpen}
        onClose={() => setIsPopOpen(false)}
        contentStyle={{
          ...SEL_POPUP_STYLE,
          width: !isBrandPop && '1200px',
          maxHeight: '95vh',
        }}
        className="modal"
      >
        <$PopContainer>
          <FlexBox justify="space-between">
            <Text variant="p" weight="bold" type="main">
              {'Select ' +
                (isBrandPop
                  ? 'Brands'
                  : `Stores (${form?.stores?.length || 0} selected)`)}
            </Text>
            <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
          </FlexBox>
          <Div maxHeight="calc(95vh - 90px)" overflow="auto">
            {!isBrandPop && (
              <div style={{ padding: '0px 20px' }}>
                <AEStoreFilter />
              </div>
            )}
            {isBrandPop ? (
              <SelectBrand
                brands={form.brands}
                setBrands={(brands) => setForm({ ...form, stores: [], brands })}
              />
            ) : (
              <SelectStores
                brands={form.brands}
                vendorId={selectedVendor.value}
                stores={form.stores}
                setStores={setStores}
                isAudience
              />
            )}
          </Div>
          <FlexBox justify="flex-end">
            <Button
              variant="primary"
              py={spacing.s}
              onClick={() => setIsPopOpen(false)}
            >
              Submit
            </Button>
          </FlexBox>
        </$PopContainer>
      </Popup>

      <div>
        <Text
          variant="p"
          weight="bold"
          type="main"
          mt={spacing.l}
          mb={spacing.s}
        >
          Audience
        </Text>
        <FlexBox gap="20px" padding="20px 0">
          <Text width="60px" variant="small">
            Brand :
          </Text>
          {form.brands[0]?.name ? (
            <$Label>
              <Text align="center" type="main" variant="small">
                {form.brands[0]?.name}
              </Text>
              <CompleteIcon />
            </$Label>
          ) : (
            <Text width="200px" align="center" type="primary" variant="small">
              No Brand Selected
            </Text>
          )}
          <$SelectButton variant="primaryOutline" onClick={() => openPop(true)}>
            Select Brand
          </$SelectButton>
        </FlexBox>
        <FlexBox gap="20px" padding="0 0 20px">
          <Text width="60px" variant="small">
            Stores :
          </Text>
          {form.stores?.length > 0 ? (
            <$Label>
              <Text align="center" type="main" variant="small">
                {form.stores?.length} store{form.stores?.length > 1 && 's'}{' '}
                selected
              </Text>
              <CompleteIcon />
            </$Label>
          ) : (
            <Text width="200px" align="center" type="primary" variant="small">
              No Stores Selected
            </Text>
          )}
          <$SelectButton
            variant="primaryOutline"
            onClick={() => form.brands?.length > 0 && openPop(false)}
            disabled={form.brands?.length < 1}
          >
            Select Stores
          </$SelectButton>
          {/* <Checkbox
            id="storeCheck"
            isChecked={storeCheck}
            onCheckboxChange={handleStoreCheck}
            disabled={form.brands?.length < 1}
          >
            <Text variant="small" type="primary">
              Set as default Advertisement for all stores
            </Text>
          </Checkbox> */}
        </FlexBox>
      </div>
    </>
  )
}
