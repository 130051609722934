import styled from 'styled-components'

// FIXME: Use Flexbox styled component
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	& > div:first-child{
		display: flex;
		flex: 4;
	}
`

export const BrandMeta = styled.h5`
	span{
		font-size: 0.8em;
	}
	margin-right: 10px;
`

