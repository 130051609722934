import { isEmpty } from 'utils'
import { post } from 'utils/axiosHandler'
import { getProcessedFilters } from 'utils/feedback'
import axios from 'axios'

let source

//Getting feedbacks for dashboard

export const getFeedbacksForDashboard = async (data, options) => {
  return post('/feedback/getFeedbacks', data, options)
}

//Getting all feedbacks data
export const getFeedbacks = (data) => async (dispatch, getState) => {
  let result

  const state = getState()
  const filters = getProcessedFilters(state.feedback.filters)
  const sort = state.feedback.sort

  if (source) source.cancel()

  const CancelToken = axios.CancelToken
  source = CancelToken.source()
  const cancelToken = source.token

  try {
    result = await post(
      '/feedback/getFeedbacks',
      {
        ...data,
        ...filters,
        ...sort,
      },
      {
        cancelToken,
      }
    )
  } catch (e) {
    if (axios.isCancel(e)) {
      // console.log("Request cancelled")
      source = ''
    }
  }

  if (result?.code) {
    dispatch(
      getFeedbacksHelper({
        code: 1,
        feedbackCount: 1,
        feedbackData: result?.data,
        hasMoreFeedbacks: true,
      })
    )
    return result
  }
}

export const replyToFeedback = (data) => async (dispatch, getState) => {
  const feedbacks = getState().feedback.feedbacks

  const result = await post('/feedback/replyToFeedback', data)
  if (result.code) {
    const updatedFeedbacks = feedbacks.map((feedback) => {
      if (feedback.billUid === data.billUid) {
        feedback.reply = data.replyText
        feedback.replyDate = new Date()
      }
      return feedback
    })
    dispatch(updateFeedbackHelper(updatedFeedbacks))
  }
  return result
}

export const setFeedbackFilters = (data) => (dispatch) => {
  dispatch(setFeedbackFiltersHelper(data))
}

export const setCampaignMessage = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CAMPAIGN_ID',
    payload: data,
  })
}

export const setRememberFilters = (data) => (dispatch) => {
  dispatch({
    type: 'SET_FEEDBACK_REMEMBER_FILTERS',
    payload: data,
  })
}

const setFeedbackFiltersHelper = (data) => ({
  type: 'SET_FEEDBACK_FILTERS',
  payload: data,
})

export const setFeedbackSort = (data) => (dispatch) => {
  dispatch(setFeedbackSortHelper(data))
}

const setFeedbackSortHelper = (data) => ({
  type: 'SET_FEEDBACK_SORT',
  payload: data,
})

export const getFeedbacksHelper = (data) => ({
  type: 'GET_FEEDBACKS',
  payload: data,
})

//axios error
export const axiosError = (err) => {
  if (!isEmpty(err.response)) {
    if (err.response.status === 401) {
      return {
        type: 'GET_ERRORS',
        payload: err.response.status,
      }
    } else {
      return {
        type: 'GET_ERRORS',
        payload: err.response.data,
      }
    }
  } else {
    return {
      type: 'GET_ERRORS',
      payload: err,
    }
  }
}

export const setCampaignMeta = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_CAMPAIGN_META',
    payload: data,
  })
}

export const updateFeedbackHelper = (data) => ({
  type: 'UPDATE_FEEDBACKS',
  payload: data,
})

export const resetFilters = (_) => async (dispatch) => {
  dispatch({
    type: 'RESET_FEEDBACK_FILTERS',
  })
}

export const exportFeedbacks =
  ({ filters, discardFilter, storeIds }) =>
  async (dispatch, getState) => {
    const state = getState()
    const processedFilters = discardFilter ? {} : getProcessedFilters(filters)
    const sort = state.feedback.sort
    try {
      const result = await post(
        '/feedback/exportAll',
        {
          ...processedFilters,
          ...sort,
          storeIds,
        },
        {
          //responseType: 'arraybuffer',
          successMessage:
            'Your request is successfully submitted. We will notify over the mail shortly. Thank you!',
        }
      )

      return result
    } catch (e) {
      console.error(e.message)
      return []
    }
  }
