import React, { Component } from 'react'
import styled, { css } from 'styled-components'
// import FileDownload from 'js-file-download'
import { sortViaBills, defaultFilters, transactionType } from 'utils/billUtils'
import BillsFilter from './BillsFilter'
import { connect } from 'react-redux'
import {
  Breadcrumb,
  Input,
  Pill,
  PillArray,
  Skeleton,
  Tabs,
  Text,
} from 'components/Common'
import { PrimaryOutlineButton, Button, FlexBox, Div } from 'styledComponent'
import CloseIcon from 'images/close-cross.svg'
import {
  calcPercent,
  NYKAA_SEARCH_FIELD_OPTIONS,
  SEARCH_FIELD_OPTIONS,
} from '../constants'
import { GET_SEL_VALUE } from 'utils/reusables'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { decathVendorIds, nykaaVendorId } from 'components/conditionalComponent'
import { StoreHeading } from 'components/Layout/StoresHeader'
import {
  FilterOutlineButton,
  SimpleOutlineButton,
} from 'styledComponent/components/button'
import { setBillFilterQuery, setDefaultFilters } from 'redux/actions/bills'
import { $MarginlessLine } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { DeleteRedIcon, ExportReportIcon, FilterIcon } from 'assets/icons'
import { spacing } from 'design-system'
import {
  $ContentDiv,
  $Button,
  $RemoveButton,
} from 'components/Content/consumerAnalytics/Customers/Customers.styled'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import checkfeaturesAlloted, { showDeleteBtn } from 'utils/checkFeatureAlloted'
import { checkReadPermission } from 'redux/actions/authActions'
import { FormattedNumber } from 'react-intl'
import swal from 'sweetalert'
import { StatsInfo } from '../StatsInfo'
import { userTypes } from 'components/Layout/constants'
import StatsUpBtn from 'assets/images/stats-chevron-up-icon.svg'
import StatsDownBtn from 'assets/images/stats-chevron-down-icon.svg'
import { Container } from 'builder/editor/selectors'

export const DeleteButton = styled(PrimaryOutlineButton)`
  margin-right: 8px;

  border-color: #cf1517;
  &:hover {
    background: #cf1517;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      margin-right: 0px;
    `}
`

export const ExportButton = styled(PrimaryOutlineButton)`
  margin-right: 8px;
  height: 38px;
  border-color: #33475b;
  &:hover {
    background: #33475b;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      margin-right: 0px;
    `}
`

export const RefreshButton = styled(ExportButton)`
  color: ${(props) => props.color || '#33475b'};
  displat: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled(Button)`
  margin-right: 8px;
`

const StatsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StatsButton = styled(Button)`
  font-size: 13px;
  color: #007bff;
  background-color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: -11px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 4px 20px;
  font-weight: 505;
  width: ${(props) => props.width};
`
const StatsText = styled.div`
  font-size: 12px;
  margin-top: 5px;
`
const StatsValue = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
`
const StatsPer1 = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: rgb(0, 171, 240);
  margin-left: 13px;
  margin-top: 5px;
`
const StatsPer2 = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #90ee90;
  margin-left: 13px;
  margin-top: 5px;
`
class BillsListHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exportBtn: false,
      sortBy: false,
      exportBy: false,
      sortByName: '',
      sortByTitles: sortViaBills,
      isExportFilter: false,
      selectedStoreIds: props.filterQuery?.selectedStoreIds || [],
      isPopOpen: false,
      isFilterOpen: Math.random(),
      isShow: false,
    }

    this.filterModalRef = React.forwardRef()
    this.handleSortBy = this.handleSortBy.bind(this)
    this.handleExportBy = this.handleExportBy.bind(this)
    this.openFilterModal = this.openFilterModal.bind(this)
    this.removeAll = this.removeAll.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getPillsData = this.getPillsData.bind(this)
  }

  handleSortBy(sortBy) {
    this.setState({ sortByName: sortBy, sortBy: false })
    let sortByData = this.state.sortByTitles.forEach((sortByObj, i) => {
      if (sortByObj.attribute === sortBy) sortByObj.active = true
      else sortByObj.active = false
    })
  }

  handleExportBy(exportBy) {
    this.setState({ exportByName: exportBy, exportBy: false })
  }

  openFilterModal(e, isExportFilter) {
    this.filterModalRef.current.openFilterModal()
    this.setState({ isExportFilter, isFilterOpen: Math.random() })
  }

  handleShowData = () => {
    const isShow = this.state.isShow
    let value = isShow ? false : true
    this.setState({ isShow: value })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState?.isPopOpen !== this.state.isPopOpen &&
      !this.state.isPopOpen
    ) {
      this.props.setBillFilterQuery({
        ...this.props?.filterQuery,
        selectedStoreIds: this.state.selectedStoreIds,
      })
    }
  }

  getPillsData(isBills, isStoreData) {
    const filQuery = []
    if (this.props.filterQuery.startDate) {
      if (isBills) {
        filQuery.push({
          key: 'date',
          value: `Date : ${moment(this.props.filterQuery.startDate).format(
            'DD MMM YY, hh:mm A'
          )} - ${moment(this.props.filterQuery.endDate).format(
            'DD MMM YY, hh:mm A'
          )}`,
        })
      } else {
        filQuery.push({
          key: 'date',
          value: `Date : ${moment(this.props.filterQuery.startDate).format(
            'DD MMM YYYY'
          )} - ${moment(this.props.filterQuery.endDate).format('DD MMM YYYY')}`,
        })
      }
    }

    if (this.props.filterQuery.transactionType && isBills)
      filQuery.push({
        key: 'transactionType',
        value:
          'Transaction Type : ' +
          this.props.filterQuery.transactionType.join(', '),
      })

    if (this.props.filterQuery.amountRange && isBills)
      filQuery.push({
        key: 'amountRange',
        value: `Amount Range : ${this.props.filterQuery.amountRange.min} - ${this.props.filterQuery.amountRange.max}`,
      })

    if (this.props.filterQuery.avgSales && isStoreData)
      filQuery.push({
        key: 'avgSales',
        value: `Avg. Sales : ${this.props.filterQuery.avgSales.min} - ${this.props.filterQuery.avgSales.max}`,
      })

    if (this.props.filterQuery.totalSales && isStoreData)
      filQuery.push({
        key: 'totalSales',
        value: `Total Sales : ${this.props.filterQuery.totalSales.min} - ${this.props.filterQuery.totalSales.max}`,
      })

    if (this.props.filterQuery.transactions && isStoreData)
      filQuery.push({
        key: 'transactions',
        value: `Transactions : ${
          GET_SEL_VALUE(
            this.props.filterQuery.transactions.transType,
            transactionType
          )?.label
        } - ${this.props.filterQuery.transactions.type} (${
          this.props.filterQuery.transactions.min
        } - ${this.props.filterQuery.transactions.max})`,
      })

    return filQuery
  }

  addTreeSaved(items, props) {
    if (items.length > 0) {
      if (
        decathVendorIds.includes(this.props.vendorId) &&
        (props.activeTab === 'Bills' || props.activeTab === 'Store Data')
      ) {
        items.push([
          {
            value: props?.bAnalytics?.noOfTransactions?.treesSaved,
            label: `Trees Saved (Lifetime)`,
            color: '#12af1d',
          },
          {
            value: (
              props.bAnalytics.noOfTransactions.co2 /
              10000 /
              1000
            ).toFixed(2),
            label: `Tons CO <sub>2</sub> Savings (Lifetime)`,
            color: '#12af1d',
          },
        ])
      }
    }
    return items
  }

  handleClose(key) {
    if (key === 'date') {
      swal('Cannot close this filter', 'Date filter is compulsory.', 'error')
      return
    }
    const fil = cloneDeep({ ...this.props.filterQuery })
    const defFil = cloneDeep({ ...this.props.defaultFilters })

    defFil[key] = defaultFilters[key]
    this.props.setDefaultFilters(cloneDeep(defFil))
    if (key === 'date') {
      delete fil.startDate
      delete fil.endDate
    } else {
      delete fil?.[key]
    }
    this.props.setBillFilterQuery(cloneDeep(fil))
    setTimeout(this.props.getBills, 1000)
  }

  removeAll() {
    if (
      Object.keys(this.props.defaultFilters)?.filter(
        (dee) => this.props.defaultFilters?.[dee]?.isActive
      )?.length <= 1
    ) {
      swal('Cannot remove all filters', 'Date filter is compulsory.', 'error')
      return
    }

    const startDate = new Date()
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date()
    endDate.setHours(23, 59, 59, 0)

    const defFilters = { startDate, endDate }
    this.props.setBillFilterQuery(
      this.props.filterQuery?.selectedStoreIds?.length
        ? {
            selectedStoreIds: this.props.filterQuery.selectedStoreIds,
            ...defFilters,
          }
        : { ...defFilters }
    )
    this.props.setDefaultFilters({
      ...defaultFilters,
      ...this.props.defaultFilters?.selectedStoreIds,
    })
    setTimeout(this.props.getBills, 1000)
  }

  render() {
    const isBills = this.props.activeTab === 'Bills'
    const isGraphs = this.props.activeTab === 'Graphs'
    const isStoreData = this.props.activeTab === 'Store Data'
    const allowedTicketTypes = ['CINEPOLIS_TICKET', 'CINEPOLIS_FOOD']
    const isBillData =
      this.props.billData &&
      this.props.billData.filter((x) =>
        allowedTicketTypes.includes(x.parsedData?.customData?.ticketType)
      ).length > 0

    const dashboardItems = [
      {
        value: this.props?.bAnalytics?.total?.totalSales,
        label: 'Total Sales',
        color: '#33475B',
        subLabelKey1: 'Movie Sales',
        subLabelValue1: this.props?.bAnalytics?.total?.movieTotalSales,
        percentage1: calcPercent(
          this.props?.bAnalytics?.total?.movieTotalSales,
          this.props?.bAnalytics?.total?.totalSales
        ),
        subLabelKey2: 'F&B Sales',
        subLabelValue2: this.props?.bAnalytics?.total?.fnbTotalSales,
        percentage2: calcPercent(
          this.props?.bAnalytics?.total?.fnbTotalSales,
          this.props?.bAnalytics?.total?.totalSales
        ),
      },
      {
        value: this.props?.bAnalytics?.total?.avgBilling,
        label: 'Average Billing',
        color: '#33475B',
        subLabelKey1: 'Avg. Movie Billing',
        subLabelValue1: this.props?.bAnalytics?.total?.movieAvgBilling,
        subLabelKey2: 'Avg. F&B Billing',
        subLabelValue2: this.props?.bAnalytics?.total?.fnbAvgBilling,
      },
      {
        value: this.props?.bAnalytics?.noOfTransactions?.total,
        label: 'Total Transactions',
        color: '#33475B',
        subLabelKey1: 'Movie Transactions',
        subLabelValue1: this.props?.bAnalytics?.noOfTransactions?.movie?.total,
        percentage1: calcPercent(
          this.props?.bAnalytics?.noOfTransactions?.movie?.total,
          this.props?.bAnalytics?.noOfTransactions?.total
        ),
        subLabelKey2: 'F&B Transactions',
        subLabelValue2: this.props?.bAnalytics?.noOfTransactions?.fnb?.total,
        percentage2: calcPercent(
          this.props?.bAnalytics?.noOfTransactions?.fnb?.total,
          this.props?.bAnalytics?.noOfTransactions?.total
        ),
      },
      {
        value: this.props?.bAnalytics?.noOfTransactions?.BM,
        percentage: calcPercent(
          this.props?.bAnalytics?.noOfTransactions?.BM,
          this.props?.bAnalytics?.noOfTransactions?.total
        ),
        label: 'BillMe',
        color: '#00ABF0',
        subLabelKey1: 'Movie BillMe Transactions',
        subLabelValue1: this.props?.bAnalytics?.noOfTransactions?.movie?.BM,
        subLabelKey2: 'F&B BillMe Transactions',
        subLabelValue2: this.props?.bAnalytics?.noOfTransactions?.fnb?.BM,
      },
      {
        value: this.props?.bAnalytics?.noOfTransactions?.print,
        percentage: calcPercent(
          this.props?.bAnalytics?.noOfTransactions?.print,
          this.props?.bAnalytics?.noOfTransactions?.total
        ),
        label: 'Print',
        color: '#D7443E',
        subLabelKey1: 'Movie Print Transactions',
        subLabelValue1: this.props?.bAnalytics?.noOfTransactions?.movie?.print,
        subLabelKey2: 'F&B Print Transactions',
        subLabelValue2: this.props?.bAnalytics?.noOfTransactions?.fnb?.print,
      },
      {
        value: this.props?.bAnalytics?.noOfTransactions?.BMprint,
        percentage: calcPercent(
          this.props?.bAnalytics?.noOfTransactions?.BMprint,
          this.props?.bAnalytics?.noOfTransactions?.total
        ),
        label: 'BillMe + Print',
        color: '#FFA726',
        subLabelKey1: 'Movie BM+Print Transactions',
        subLabelValue1:
          this.props?.bAnalytics?.noOfTransactions?.movie?.BMprint,
        subLabelKey2: 'F&B BM+Print Transactions',
        subLabelValue2: this.props?.bAnalytics?.noOfTransactions?.fnb?.BMprint,
      },
    ]

    const isbAnalyticsPermitted =
      checkfeaturesAlloted('bAnalytics', this.props.featuresAlloted) &&
      this.props.checkReadPermission('billingAnalysis')
    const isSecurity = this.props.userType === userTypes.SECURITY

    const showDelete = showDeleteBtn(
      this.props.vendorId,
      this.props.isAdmin,
      isSecurity
    )
    return (
      <Div background="#FFF" pb="1px">
        <FlexBox p="16px" justify="space-between" align="center">
          <Breadcrumb label="Bills" />
          <SimpleOutlineButton
            type="button"
            className="mr-2 py-2 mb-1"
            onClick={() => this.setState({ isPopOpen: true })}
          >
            <StoreHeading
              selectedStoreIds={this.state.selectedStoreIds}
              setSelectedStoreIds={(selectedStoreIds) => {
                this.props.setBillFilterQuery({
                  ...this.props.filterQuery,
                  selectedStoreIds,
                })
                this.setState({ selectedStoreIds })
              }}
              isPopOpen={this.state.isPopOpen}
              setIsPopOpen={(isPopOpen) => this.setState({ isPopOpen })}
              showStatus
              setStoreGrp={this.props.setStoreGrp}
            />
          </SimpleOutlineButton>
        </FlexBox>
        <Div mx={spacing.l}>
          <$MarginlessLine my={0} />
          <FlexBox justify="space-between">
            <Tabs padding={`17px`}>
              <div label="Bills" />
              {isbAnalyticsPermitted && <div label="Store Data" />}
              {isbAnalyticsPermitted && <div label="Graphs" />}
            </Tabs>
            {isBills && (
              <FlexBox minWidth="750px" justify="flex-end" gap="20px" ml="16px">
                <Text variant="p">Select Search Parameter : </Text>
                <Input
                  containerMargin="0"
                  inputWidth="200px"
                  variant="single-select"
                  options={
                    this.props.vendorId === nykaaVendorId
                      ? NYKAA_SEARCH_FIELD_OPTIONS
                      : SEARCH_FIELD_OPTIONS
                  }
                  value={GET_SEL_VALUE(
                    this.props.searchField,
                    SEARCH_FIELD_OPTIONS
                  )}
                  // label="Select Search Parameter"
                  onChange={({ value }) =>
                    this.props.onHandleSearch(this.props.searchTxt, value)
                  }
                />
                <Div position="relative" className="bill-input">
                  <Input
                    containerMargin="0"
                    inputWidth="300px"
                    variant="input"
                    value={this.props.searchTxt}
                    placeholder="Search by Parameter"
                    onEnter={this.props.getDebouncedSearch}
                    onChange={(e) =>
                      this.props.onHandleSearch(
                        e.target.value,
                        this.props.searchField
                      )
                    }
                  />
                  <div
                    className="searchBox"
                    onClick={this.props.getDebouncedSearch}
                  >
                    <SearchIcon />
                  </div>
                </Div>
              </FlexBox>
            )}
          </FlexBox>
          <$MarginlessLine mt={0} />
        </Div>
        <div className="header">
          <BillsFilter
            isFromStoresManage={this.props.isFromStoresManage}
            onProceed={this.props.onProceedWithFilters}
            isFilterOpen={this.state.isFilterOpen}
            ref={this.filterModalRef}
            isExport={this.state.isExportFilter}
            isBills={isBills}
            isStoreData={isStoreData}
            storeGrpName={this.props.storeGrpName}
          />
          <FlexBox justify="space-between" expand align="center">
            {!isSecurity && (
              <>
                <Div width="100%">
                  <FlexBox expand>
                    <Text mx={spacing.l}>Filters :</Text>
                    <PillArray width="80%" wrap="wrap">
                      {this.getPillsData(isBills, isStoreData).map(
                        (obj, index) => {
                          return (
                            <FlexBox key={obj?.key}>
                              <Pill
                                margin={`0 ${spacing.s} ${spacing.s} 0`}
                                variant="filter"
                                type="tab"
                                onClose={() => this.handleClose(obj?.key)}
                              >
                                {obj?.value}
                              </Pill>
                            </FlexBox>
                          )
                        }
                      )}
                    </PillArray>
                    <$ContentDiv
                      margin={`0 0 0 ${spacing.l}`}
                      flex
                      align="center"
                      width="25%"
                    >
                      <$RemoveButton
                        variant="textualError"
                        noBorder
                        onClick={this.removeAll}
                      >
                        Remove all filters
                      </$RemoveButton>
                    </$ContentDiv>
                  </FlexBox>
                </Div>
                <FlexBox p="8px">
                  {isBills ? (
                    <RefreshButton
                      height="38px"
                      type="button"
                      className="mr-2 filter-btn"
                      onClick={() => {
                        if (isBills) {
                          if (this.props.searchTxt.length === 0) {
                            this.props.onProceedWithFilters()
                          } else {
                            this.props.getDebouncedSearch()
                          }
                          this.props.getStats()
                        }
                      }}
                    >
                      <i class="fas fa-redo"></i>
                    </RefreshButton>
                  ) : null}
                  <FilterOutlineButton
                    height="38px"
                    type="button"
                    className="mr-2 filter-btn"
                    onClick={this.openFilterModal}
                  >
                    <FilterIcon className="my-1" />
                  </FilterOutlineButton>

                  {!isGraphs && !this.props.hideActionButtons && (
                    <ExportButton
                      type="button"
                      className="mr-2 py-2 filter-btn"
                      onClick={(e) => this.openFilterModal(e, true)}
                      isActive={this.props.isDeleteActive}
                    >
                      <ExportReportIcon />
                    </ExportButton>
                  )}

                  {isBills && !this.props.hideActionButtons && showDelete && (
                    <FlexBox align="center">
                      <DeleteButton
                        type="button"
                        className="mr-2 py-2 filter-btn"
                        onClick={(e) =>
                          this.props.onDeleteClick(
                            e,
                            this.props.selectedBills.length > 0 ? true : false
                          )
                        }
                        isActive={this.props.isDeleteActive}
                      >
                        <DeleteRedIcon />
                      </DeleteButton>
                      {this.props.isDeleteActive && (
                        <CloseButton onClick={this.props.onDeleteClick}>
                          <img src={CloseIcon} width={10} alt="Close" />
                        </CloseButton>
                      )}
                    </FlexBox>
                  )}
                </FlexBox>
              </>
            )}
          </FlexBox>
        </div>
        {!isGraphs && isbAnalyticsPermitted && (
          <div className="dashkaBoard">
            {this.props.bAnalytics.transactionLoading &&
            this.props.bAnalytics.totalLoading ? (
              <Skeleton height="64px" />
            ) : (
              this.addTreeSaved(dashboardItems, this.props)?.map((dash, i) =>
                Array.isArray(dash) ? (
                  <div className={'container'}>
                    {dash.map((value) => {
                      return (
                        <div>
                          <span
                            className="value"
                            style={{ fontSize: '20px', fontWeight: '600' }}
                          >
                            {value?.value}{' '}
                          </span>
                          <div
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '11px',
                              color: '#12af1d',
                              fontWeight: '400',
                            }}
                            dangerouslySetInnerHTML={{ __html: value.label }}
                          />
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div
                    className={
                      'container' +
                      (i < 2 ||
                      (decathVendorIds.includes(this.props.vendorId) &&
                        (this.props.activeTab === 'Bills' ||
                          this.props.activeTab === 'Store Data') &&
                        dash?.label === 'BillMe + Print')
                        ? ' val-border'
                        : '')
                    }
                    key={dash?.label}
                  >
                    <div className="value">
                      {i < 2 ? (
                        <FormattedNumber
                          currency={this.props.currencyCode}
                          style="currency"
                          value={dash?.value}
                        />
                      ) : (
                        <>
                          <span className="val">
                            {Number(dash?.value || 0)?.toLocaleString('en-IN')}
                          </span>
                          {dash?.label !== 'Total Transactions' && (
                            <span
                              style={{ color: dash?.color }}
                              className="percent"
                            >
                              {dash?.percentage}%
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div style={{ color: dash?.color }} className="label">
                      {dash?.label}
                    </div>
                    <div>
                      {this.state.isShow && isBillData && (
                        <div>
                          <br />
                          <StatsText>{dash.subLabelKey1}</StatsText>
                          <div>
                            {i < 2 ? (
                              <div
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  marginTop: '5px',
                                }}
                              >
                                <FormattedNumber
                                  currency={this.props.currencyCode}
                                  style="currency"
                                  value={
                                    dash.subLabelValue1
                                      ? dash.subLabelValue1
                                      : 0
                                  }
                                />
                                <StatsPer1>
                                  {dash.percentage1
                                    ? dash.percentage1 + '%'
                                    : null}
                                </StatsPer1>
                              </div>
                            ) : (
                              <StatsValue>
                                {' '}
                                {dash.subLabelValue1 ? dash.subLabelValue1 : 0}
                                <StatsPer1>
                                  {dash.percentage1
                                    ? dash.percentage1 + '%'
                                    : null}
                                </StatsPer1>
                              </StatsValue>
                            )}
                          </div>
                          <StatsText>{dash?.subLabelKey2}</StatsText>
                          <div>
                            {i < 2 ? (
                              <div
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  marginTop: '5px',
                                }}
                              >
                                <FormattedNumber
                                  currency={this.props.currencyCode}
                                  style="currency"
                                  value={
                                    dash.subLabelValue2
                                      ? dash.subLabelValue2
                                      : 0
                                  }
                                />
                                <StatsPer2>
                                  {dash.percentage2
                                    ? dash.percentage2 + '%'
                                    : null}
                                </StatsPer2>
                              </div>
                            ) : (
                              <StatsValue>
                                {' '}
                                {dash.subLabelValue2 ? dash.subLabelValue2 : 0}
                                <StatsPer2>
                                  {dash.percentage2
                                    ? dash.percentage2 + '%'
                                    : null}
                                </StatsPer2>
                              </StatsValue>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )
            )}
            <StatsInfo />
          </div>
        )}
        {this.props.activeTab !== 'Graphs' &&
        isBillData &&
        isbAnalyticsPermitted ? (
          <StatsDiv>
            {this.state.isShow ? (
              <StatsButton onClick={this.handleShowData} width={'12%'}>
                <span style={{ display: 'flex' }}>
                  <span>Show Brief Stats</span>
                  <span style={{ marginLeft: '3px' }}>
                    <img src={StatsUpBtn} width="90%" />{' '}
                  </span>
                </span>
              </StatsButton>
            ) : (
              <StatsButton onClick={this.handleShowData} width={'13%'}>
                <span style={{ display: 'flex' }}>
                  <span> Show Detailed Stats </span>
                  <span style={{ marginLeft: '3px' }}>
                    <img src={StatsDownBtn} width="90%" />{' '}
                  </span>
                </span>
              </StatsButton>
            )}
          </StatsDiv>
        ) : null}
      </Div>
    )
  }
}

const mapStateToProps = (state) => {
  const isAdmin = state.stores.storesArray?.filter((x) => x.isAdmin).length
  return {
    rememberFilters: state.bills.rememberFilters,
    defaultFilters: state.bills.defaultFilters,
    filterQuery: state.bills.filterQuery,
    bAnalytics: state.bAnalytics,
    isCashier: state.bills.isCashier,
    vendorId: state.vendorIds.selectedVendor.value,
    featuresAlloted: state.vendorIds.selectedVendor.featuresAlloted,
    currencyCode: state.auth.user.currencyCode,
    userType: state.auth.user.userType,
    isAdmin,
  }
}

export default connect(mapStateToProps, {
  setBillFilterQuery,
  setDefaultFilters,
  checkReadPermission,
})(BillsListHeader)
