import React from 'react'

import {
  ToggleButtonContainer,
  ToggleButton,
  UploadButton,
} from './Common.styled'
import { PropertyBar } from './PropertyBar'
import UploadModal from 'components/Content/autoEngagement/components/UploadModal'

export const UploadOrUrl = ({ craftProps, setProp }) => {
  const [active, setActive] = React.useState('upload')
  const [isPopOpen, setIsPopOpen] = React.useState(false)

  const handleModalToggle = (e) => setIsPopOpen(!isPopOpen)

  const handleToggleClick = (e) => {
    e.preventDefault()
    setActive(e.target.value)
  }

  const setMedia = ({ fileLocation }) => {
    setProp((props) => (props.imgUrl = fileLocation), 300)
    setIsPopOpen(false)
  }

  return (
    <div>
      <ToggleButtonContainer active={active}>
        <ToggleButton
          type="button"
          onClick={handleToggleClick}
          value="upload"
          isActive={active === 'upload'}
        >
          Upload image
        </ToggleButton>
        <ToggleButton
          type="button"
          onClick={handleToggleClick}
          value="url"
          isActive={active === 'url'}
        >
          Paste URL
        </ToggleButton>
      </ToggleButtonContainer>
      <br />
      {active === 'url' ? (
        <PropertyBar
          label="Image URL"
          type="text"
          propertyName="imgUrl"
          craftProps={craftProps}
          setProp={setProp}
          margin="0px 0px 16px"
        />
      ) : (
        <>
          <UploadButton onClick={handleModalToggle}>Browse Files</UploadButton>
          <UploadModal
            showMedias={['image', 'gif']}
            isPopOpen={isPopOpen}
            setIsPopOpen={setIsPopOpen}
            setMedia={setMedia}
            uploadedFrom="email"
          />
        </>
      )}
    </div>
  )
}
