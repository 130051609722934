import React, { Component } from 'react'
import { formatDate3 } from 'utils/getDateFormat'

class ExampleCustomInput extends Component {
  render() {
    return (
      <button className="datePickerButton" onClick={this.props.onClick}>
        {this.props.buttonText} <i className="fas fa-calendar-alt" />{' '}
        {formatDate3(this.props.value)}
      </button>
    )
  }
}

export default ExampleCustomInput
