import styled, { createGlobalStyle } from 'styled-components'

import { FlexBox } from 'styledComponent'
import { color, spacing } from 'design-system'

const $Container = styled.div`
  padding: 20px;
  position: relative;
`

const $BodyStyles = createGlobalStyle`
  body {
    background-color: ${color.white};
  }
`

const $FlexContainer = styled(FlexBox)`
  margin-top: ${(props) => props.mt || 'unset'};
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || 'unset'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  min-width: ${({ minWidth }) => minWidth || 'unset'};
`

const $FlexItem = styled.div`
  flex-basis: ${(props) => props.flexBasis || '100%'};
  margin-right: ${(props) => props.mr || 'unset'};
`

const $DateSelector = styled(FlexBox)`
  position: relative;
`

const $DateWrapperContainer = styled.div`
  text-align: center;
  position: relative;
`

const $Calendar = styled.div`
  position: absolute;
  top: 45px;
  right: 10px;
  background-color: white;
  z-index: 10;
  box-shadow: 0px 5px 10px hsl(0, 0%, 40%, 0.15);
`

const $VendorSelectContainer = styled.div`
  width: 200px;
  margin-bottom: ${spacing.l};
`

export {
  $Container,
  $FlexContainer,
  $FlexItem,
  $BodyStyles,
  $DateSelector,
  $DateWrapperContainer,
  $Calendar,
  $VendorSelectContainer,
}
