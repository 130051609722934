const SETTINGS_HEADING = {
  ['Untouched SKUs']: {
    heading: 'Untouched SKUs',
    toolTip: 'Untouched SKUs',
    subHeading: 'Set the number of days for untouched units.',
  },
}

const SETTING_TYPE = 'productAnalytics'

const INITIAL_DATA = {
  settings: [
    {
      label: 'Untouched SKUs',
      key: 0,
    },
  ],
  type: SETTING_TYPE,
}

export { INITIAL_DATA, SETTING_TYPE, SETTINGS_HEADING }
