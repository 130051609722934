import React, { Component } from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedNumber } from 'react-intl'
import { makeBack } from '../../../redux/actions/toggle-action'
import { formatDate2 } from '../../../utils/getDateFormat'
import { getConsumerData } from '../../../redux/actions/oldCAnalyticsActions'
import '../../../css/content/consumerAnalytics.scss'
import {
  /* PrimaryButton, ExportIcon, */ GreyText,
} from '../../../styledComponent'
import { Dropdown, Skeleton } from '../../Common'
// import { SortOptions } from "../../../utils/feedback";

class ConsumerAnalyticsNumbers extends Component {
  constructor(props) {
    super(props)
    this.SortOptions = [
      {
        id: 'averageExpenseMin',
        value: 'Average Lowest to Highest',
        sortOrder: 1,
        sortValue: 'averageExpense',
      },
      {
        id: 'averageExpenseMax',
        value: 'Average Highest to Lowest',
        sortOrder: -1,
        sortValue: 'averageExpense',
      },
      {
        id: 'LastVisitedMin',
        value: 'Last Visited Latest',
        sortOrder: -1,
        sortValue: 'LastVisited',
      },
      {
        id: 'LastVisitedMax',
        value: 'Last Visited Oldest',
        sortOrder: 1,
        sortValue: 'LastVisited',
      },
      {
        id: 'totalVisitsMin',
        value: 'Total Visits Lowest to Highest',
        sortOrder: 1,
        sortValue: 'totalVisits',
      },
      {
        id: 'totalVisitsMax',
        value: 'Total Visits Highest to Lowest',
        sortOrder: -1,
        sortValue: 'totalVisits',
      },
    ]
    this.state = {
      hasMore: true,
      domainLength: 15,
      fetchCount: 15,
      data: [],
      lockFetchMore: false,
      isLoading: false,
      sort: this.SortOptions[2],
    }
    // this.dwnldFile = this.dwnldFile.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.fetchMoreData()
    this.setState({ isLoading: false })
  }

  /* dwnldFile() {
    this.props.getFile({
      brandName: "Brand Name",
      displayAddress: 'Display Address' || ""
    });
  } */

  async fetchMoreData(sortOption) {
    if (!this.state.lockFetchMore) {
      this.setState({
        lockFetchMore: true,
      })
      let res = await this.props.getConsumerData({
        domainLength: this.state.domainLength,
        fetchCount: this.state.fetchCount,
        sort: sortOption ? sortOption : this.state.sort,
      })
      if (res.code) {
        this.setState({
          data: this.state.data.concat(res.data),
          fetchCount: this.state.fetchCount + this.state.domainLength,
          lockFetchMore: false,
        })
        if (res.data.length === 0) {
          this.setState({
            hasMore: false,
          })
        }
      }
    }
  }

  render() {
    return (
      <>
        {/* <div
          className=""
          style={{
            marginTop: '2%',
            marginRight: '3%',
            marginBottom: '2%',
            marginLeft: '3%',
            borderBottom: '1px solid',
          }}
        >
          <div className="pagination d-inline-flex justify-content-between">
            <div className="d-inline-flex justify-content-right">
              <GreyText variant="caption">Sort By</GreyText>
              <Dropdown
                options={this.SortOptions}
                onChange={this.handleDropdownSelect}
                selected={this.state.sort}
              />
            </div>
          </div>
        </div> */}
        <div className="numbersPopupConsumerAnalytics shadow bg-white rounded">
          <div className="consumerDataHeading">
            <h5>
              {' '}
              {this.props.groupBy === 'user' ? 'Phone Numbers' : 'Emails'}{' '}
            </h5>
            <h5>Average Expense</h5>
            <h5>Last Visited</h5>
            <h5>Total Visits</h5>
          </div>
          <div id="scrollableDiv" className="infiniteScrollContainer">
            {this.state.isLoading && <Skeleton height={60} count={10} />}
            <InfiniteScroll
              dataLength={this.state.fetchCount}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              scrollableTarget="scrollableDiv"
            >
              {!this.state.isLoading &&
                this.props.consumerData &&
                this.props.consumerData.length > 0 &&
                this.props.consumerData.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="consumerDataInner" key={i}>
                        <div>{data._id}</div>
                        <div>
                          {data.averageExpense ? (
                            <FormattedNumber
                              value={data.averageExpense}
                              maximumFractionDigits={2}
                              style="currency"
                              currency={this.props.currencyCode}
                            />
                          ) : (
                            '-'
                          )}
                        </div>
                        <div className="lastVisitedColor">
                          {formatDate2(data.LastVisited)}
                        </div>
                        <div>{data.totalVisits}</div>
                      </div>
                    </React.Fragment>
                  )
                })}
            </InfiniteScroll>
            {/* {

	render() {
		return (
			<>
				<div className="numbersPopupConsumerAnalytics shadow bg-white rounded">
					<div className="consumerDataHeading">
						<h5>Phone Numbers</h5>
						<h5>Average Expense</h5>
						<h5>Last Visited</h5>
						<h5>Total Visits</h5>
					</div>
					<div id="scrollableDiv" className="infiniteScrollContainer">
						{
							this.state.isLoading &&
							<Skeleton height={60} count={10} />
						}
						<InfiniteScroll
							dataLength={this.state.fetchCount}
							next={this.fetchMoreData}
							hasMore={this.state.hasMore}
							scrollableTarget="scrollableDiv"
						>
							{
								!this.state.isLoading &&
								this.props.consumerData &&
								this.props.consumerData.length > 0 &&
								this.props.consumerData.map((data, i) => {
								return (
									<>
										<div className="consumerDataInner" key={i}>
											<div>{data._id}</div>
											<div>
												{data.averageExpense 
													? 
														<FormattedNumber
															value={data.averageExpense}
															maximumFractionDigits={2}
															style="currency"
															currency="INR"
														/>
													: "-"
												}
											</div>
											<div className="lastVisitedColor">
												{formatDate2(data.LastVisited)}
											</div>
											<div>{data.totalVisits}</div>
										</div>
									</>
								);
							})}
						</InfiniteScroll>
						{/* {
							this.state.lockFetchMore &&
							<p>Loading more</p>
						} */}
            {!this.state.hasMore && (
              <p className="endOfList">You have reached the end of the list</p>
            )}
          </div>
        </div>
        {/* <PrimaryButton type="button" className="exportExcelbutton" onClick={this.dwnldFile}>
          <ExportIcon className="mr-2"/>
          Export
        </PrimaryButton> */}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    backButton: state.backButton,
    cAnalyticsPopup: state.cAnalyticsPopup,
    groupBy: state.cAnalyticsOld?.filters?.groupBy?.value || 'user',
    consumerData: state.cAnalyticsOld.data,
    currencyCode: state.auth.user.currencyCode,
  }
}

export default connect(mapStateToProps, { makeBack, getConsumerData })(
  ConsumerAnalyticsNumbers
)
