import React from 'react'

import { useSelectStore } from 'hooks'
import { get, post } from 'utils/axiosHandler'
import { Input, RenderIf, Text, useToasts } from 'components/Common'
import { PrimaryButton, Div, FlexBox, TextualButton } from 'styledComponent'
import { useTheme } from 'styled-components'
import { chunk, cloneDeep } from 'lodash'

const WhatsApp = () => {
  const [whatsAppSettings, setWhatsAppSettings] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)

  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { toast } = useToasts()
  const theme = useTheme()

  const onInputChange = (e, id, index) => {
    const settingsCopy = cloneDeep(whatsAppSettings)

    settingsCopy?.settings?.[index]?.forEach((item) => {
      if (item?.key?.id === id) {
        const numericRegexp = /^[\d]+$/gim
        if (!['primaryUsername', 'secondaryUsername'].includes(id)) {
          item.key.value = e.target.value
        } else if (numericRegexp.test(e.target.value)) {
          item.key.value = e.target.value
        }
      }
    })

    setWhatsAppSettings(settingsCopy)
  }

  const onSave = async () => {
    try {
      setIsLoading(true)

      let isInvalid = false

      whatsAppSettings?.settings?.forEach((ele) => {
        const valid = ele.some((item) => item.key.required && !item.key.value)

        if (!isInvalid) isInvalid = valid
      })

      if (isInvalid) {
        toast('Please fill all the required fields', 'error')
        return null
      }

      const settings = cloneDeep(whatsAppSettings)

      settings.isConfigured = true

      const res = await post(
        `/settings/vendor/${vendorId}?type=whatsApp`,
        settings
      )

      if (res.code) {
        toast('Settings have been saved successfully', 'success')
      }
    } catch (error) {
      toast(
        error?.response?.data?.message ||
          'Some error occurred. Please try again',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const fetchWhatsAppSettings = React.useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await get(`/settings/vendor/${vendorId}?type=whatsApp`)
      setWhatsAppSettings(data)
    } catch (error) {
      toast(
        error?.response?.data?.message ||
          'Some error occurred. Please try again',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onAddMore = () => {
    if (whatsAppSettings.settings.length >= 5) {
      return null
    }

    const settingsCopy = structuredClone(whatsAppSettings)
    const newSettingsBlock = structuredClone(whatsAppSettings?.settings?.[0])

    if (!newSettingsBlock || !newSettingsBlock.length) return null

    newSettingsBlock?.forEach((ele) => {
      if (ele.key.value) ele.key.value = ''
    })

    settingsCopy?.settings?.push(newSettingsBlock)

    setWhatsAppSettings(settingsCopy)
  }

  const removeAccount = (index) => {
    if (index === 0) return null

    const settingsCopy = structuredClone(whatsAppSettings)
    settingsCopy?.settings?.splice(index, 1)
    setWhatsAppSettings(settingsCopy)
  }

  React.useEffect(() => {
    fetchWhatsAppSettings()
  }, [])

  return (
    <Div
      p={theme.spacing.l}
      bg={theme.colors.doveGray}
      width="100%"
      minHeight="100%"
      position="relative"
    >
      {!isLoading && !whatsAppSettings?.settings?.length ? (
        'No settings found'
      ) : (
        <Div width="60%">
          {whatsAppSettings?.settings?.map((ele, indx) => (
            <React.Fragment key={indx}>
              <FlexBox justify="space-between" align="center">
                <Div width="48%">
                  <RenderIf active={indx > 0}>
                    <Text variant="h4">Account #{indx + 1}</Text>
                  </RenderIf>
                  <Input
                    label={ele?.[0]?.label}
                    variant={ele?.[0]?.key?.type}
                    value={ele?.[0]?.key?.value}
                    onChange={(e) => onInputChange(e, ele?.[0]?.key?.id, indx)}
                    inputWidth="100%"
                    required={ele?.[0]?.key?.required}
                    placeholder={ele?.[0]?.placeholder || ele?.[0]?.label}
                  />
                </Div>
                <RenderIf active={indx > 0}>
                  <Div width="48%" marginTop="32px">
                    <TextualButton
                      maxWidth="100"
                      margin="16px 0 0 0"
                      disabled={isLoading}
                      onClick={() => removeAccount(indx)}
                    >
                      Remove account
                    </TextualButton>
                  </Div>
                </RenderIf>
              </FlexBox>
              {chunk(ele?.slice(1), 2).map((data, indx2) => (
                <FlexBox key={indx2} justify="space-between">
                  {data.map((item, index2) => (
                    <RenderIf active={!!item.label}>
                      <Div width="48%" key={index2}>
                        <Input
                          label={item?.label}
                          variant={item?.key?.type}
                          value={item?.key?.value || ''}
                          onChange={(e) =>
                            onInputChange(e, item?.key?.id, indx)
                          }
                          inputWidth="100%"
                          required={item?.key?.required}
                          type={
                            item?.key?.id?.endsWith('Password')
                              ? 'password'
                              : 'input'
                          }
                          placeholder={item?.placeholder || item?.label}
                        />
                      </Div>
                    </RenderIf>
                  ))}
                </FlexBox>
              ))}
            </React.Fragment>
          ))}

          <FlexBox justify="space-between" align="end">
            <Div>
              <RenderIf active={whatsAppSettings?.settings.length < 5}>
                <TextualButton
                  maxWidth="100"
                  margin="16px 0 0"
                  disabled={isLoading}
                  onClick={onAddMore}
                >
                  Add another account
                </TextualButton>
              </RenderIf>
            </Div>
            <PrimaryButton
              maxWidth="100"
              margin="16px 0 0"
              disabled={isLoading}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
          </FlexBox>
        </Div>
      )}
    </Div>
  )
}

export default WhatsApp
