import React from 'react'
import styled from 'styled-components'
import { FlexBox } from 'styledComponent'
import { spacing, color } from 'design-system'

const $VerticalLine = styled.div`
  width: 2px;
  height: 100%;
  margin: ${spacing.s} 0px;
  background-color: #eaeaea;
`

const $DrumStickHead = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${color.primary.default};
  border: 1px solid #b0cefe;
  border-radius: 50%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral['100']};
  font-size: 12px;

  span {
    vertical-align: middle;
  }
`

export const DrumStick = ({ label }) => (
  <FlexBox direction="column" align="center" mr={spacing.l}>
    <$DrumStickHead>
      <span>{label}</span>
    </$DrumStickHead>
    <$VerticalLine />
  </FlexBox>
)
