import React from 'react'
import { useRouter } from 'hooks'
import Navbar from 'components/Layout/Navbar'
import { TopActionBar } from 'components/Common'

const GenerateHeader = ({ onSave, isDisabled }) => {
  const { history } = useRouter()

  return (
    <>
      <Navbar heading="Reports - Generate Report" />
      <TopActionBar
        btnBorder
        title="Generate Report"
        buttonTitle="Generate"
        buttonVariant="primaryOutline"
        onSave={onSave}
        loading={isDisabled}
        onExit={() => history.push('/reports')}
      />
    </>
  )
}

export default GenerateHeader
