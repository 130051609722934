import React, { useEffect, useState } from 'react'
import { Div, FlexBox } from 'styledComponent'
import { Text, Input, Button } from 'components/Common'
import { spacing } from 'design-system'
import { CONTENT_TYPES, GET_SEL_VALUE } from '../constants'
import { getEmailProviders } from 'redux/actions/autoEngageActions'
import { SelectCompany } from 'components/Common/SelectCompany'
import { EmailProviderModal } from './EmailProviderModal'
import { $AddNew } from '../AutoEngagement.styled'
import { AddBlueIcon } from 'assets/icons'
import { useDispatch } from 'react-redux'
import { useSelectStore } from 'hooks'
import { EmailRoute } from './EmailRoute'

export const CampaignDetails = ({
  showContent,
  hideCompany,
  campaignType,
  form,
  setForm,
  isTemplate = false,
}) => {
  const dispatch = useDispatch()
  const {
    data: { value },
  } = useSelectStore('vendorIds.selectedVendor')
  const { data } = useSelectStore('vendorIds.vendorIds')
  const {
    data: { emailProvidersList },
  } = useSelectStore('autoEngage')
  const {
    data: { companies },
  } = useSelectStore('company')
  const [isPopOpen, setIsPopOpen] = useState(false)

  const EMAIL_IDS =
    [...emailProvidersList]?.map((sender) => ({
      label: sender.name,
      value: sender._id,
    })) || []

  const handleChange = (e) =>
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    })

  const handleSenderDetails = (key, value) => {
    setForm({
      ...form,
      senderDetails: {
        type: form?.type,
        [key]: value,
      },
    })
  }

  const handleContentType = (contentType) => {
    setForm({
      ...form,
      contentType,
      senderDetails: {
        name: null,
        type: 'sms',
      },
      content: {
        body: {
          templateId: null,
          templateParams: [],
        },
      },
    })
  }

  const fetchEmailProviders = () => dispatch(getEmailProviders(value))

  useEffect(() => {
    form?.type === 'email' && fetchEmailProviders()
  }, [form?.type])

  const header = isTemplate ? ' Template ' : ' Campaign '

  return (
    <>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        Campaign Details
      </Text>
      <FlexBox gap={spacing.xl}>
        <div>
          <Input
            containerMargin="0"
            variant="input"
            label={campaignType + header + ' Name'}
            placeholder={`Enter ${header} name`}
            id="name"
            value={form.name || ''}
            onChange={handleChange}
            inputWidth="500px"
            maxLength="50"
          />
          {!isTemplate && (
            <Input
              containerMargin="0"
              variant="textarea"
              rows={3}
              label="Campaign Description"
              placeholder="Describe your campaign"
              id="description"
              value={form.description || ''}
              onChange={handleChange}
              inputWidth="500px"
              maxLength="250"
            />
          )}
          {campaignType === 'E-mail' && (
            <>
              <EmailRoute />
              <FlexBox align="center" gap={spacing.l}>
                <input
                  style={{ width: '20px', height: '20px' }}
                  readOnly
                  type="radio"
                  checked={!!form.senderDetails?.name}
                />
                <Input
                  containerMargin="4px 0 0"
                  variant="input"
                  placeholder="Enter Sender Name"
                  id="senderDetails"
                  value={form.senderDetails?.name || ''}
                  onChange={(e) => handleSenderDetails('name', e.target.value)}
                  inputWidth="280px"
                />
              </FlexBox>
              <FlexBox align="center" gap={spacing.l}>
                <input
                  style={{ width: '20px', height: '20px' }}
                  readOnly
                  type="radio"
                  checked={!!form.senderDetails?.id}
                />
                <Input
                  containerMargin="24px 0 0"
                  variant="single-select"
                  placeholder="Email ID"
                  options={EMAIL_IDS}
                  id="senderId"
                  value={GET_SEL_VALUE(form.senderDetails?.id, EMAIL_IDS) || ''}
                  onChange={(val) => handleSenderDetails('id', val.value)}
                  inputWidth="280px"
                  isClearable
                >
                  <$AddNew>
                    <Button
                      noBorder
                      startIcon={AddBlueIcon}
                      fontSize="12px"
                      variant="textualPrimary"
                      onClick={() => setIsPopOpen(true)}
                    >
                      Add New
                    </Button>
                  </$AddNew>
                </Input>
                <EmailProviderModal
                  isPopOpen={isPopOpen}
                  setIsPopOpen={setIsPopOpen}
                />
              </FlexBox>
            </>
          )}
          {showContent && (
            <Input
              containerMargin="0"
              variant="radio"
              direction="row"
              gap={spacing.xxl}
              label="Content Type"
              id="contentType"
              value={form.contentType || ''}
              onChange={handleContentType}
              options={CONTENT_TYPES}
            />
          )}
        </div>

        <div>
          {!hideCompany && companies?.length > 1 && (
            <Div mt={data?.length > 1 ? '22px' : ''}>
              <SelectCompany
                smsFilter={showContent}
                companyId={form.companyId}
                setCompanyId={(companyId) => setForm({ ...form, companyId })}
              />
            </Div>
          )}
        </div>
      </FlexBox>
    </>
  )
}
