import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { StyledStoreHeader } from 'styledComponent/components/layout/navbar'
import ManageStoreIcon from 'assets/icons/manage-store.svg'
import { setSideBarLink } from 'redux/actions/sidebarLinkActions'
import { setPreviousSidebarLink } from 'redux/actions/manageStoresActions'
import { StoreSelector } from 'components/Common'

const ManageStoresHeader = (props) => {
  return (
    <div>
      <StyledStoreHeader>
        <Link
          to="/"
          onClick={() => {
            props.setPreviousSidebarLink(props.selectedLink)
            props.setSideBarLink('')
          }}
        >
          <img src={ManageStoreIcon} />
          Selected Stores:
          <span>
            {props.selectedStores.length}/
            <span className="total-stores">
              {Object.keys(props.stores).length}
            </span>
          </span>
        </Link>
      </StyledStoreHeader>
    </div>
  )
}

const StoresHeading = (props) => {
  const [totalStores, setTotalStores] = useState({})
  return (
    <>
      <StyledStoreHeader>
        <a>
          <img src={ManageStoreIcon} />
          {props?.selectedStoreIds?.length > 0 ? (
            <>
              Select Stores {!props.disableStoreGroups ? `& Groups` : null} :
              <span>
                {props?.selectedStoreIds?.length}/
                <span className="total-stores">
                  {Object.keys(totalStores).length}
                </span>
              </span>
            </>
          ) : (
            `Select Stores ${!props?.disableStoreGroups ? `& Groups` : ''}`
          )}
        </a>
      </StyledStoreHeader>
      <StoreSelector {...props} setTotalStores={setTotalStores} />
    </>
  )
}

const mapStatesToProps = (state) => ({
  stores: state.stores.stores,
  selectedStores: state.stores.selectedStores,
  selectedLink: state.sidebarLink,
})

export const StoreHeading = connect(mapStatesToProps)(StoresHeading)

export const StoresHeader = connect(mapStatesToProps, {
  setSideBarLink,
  setPreviousSidebarLink,
})(ManageStoresHeader)
