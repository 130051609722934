import { isEmpty } from 'utils'
import { setSelectedCompanies } from './authActions'
import { get, post } from 'utils/axiosHandler'

//get companies data
export const getCompanies = (userData) => (dispatch, getState) => {
  return
  post(`/profile/getCompanies`)
    .then((res) => {
      // Save to localStorage
      if (res.code === 1) {
        dispatch(getCompaniesHelper(res.companies))
        const selectedStores = getState().stores.selectedStores
        const stores = getState().stores.stores
        let companiesId = new Set()
        for (let storeId of selectedStores) {
          stores[storeId] && companiesId.add(stores[storeId].companyId)
        }
        dispatch(setSelectedCompanies(Array.from(companiesId)))
      }
    })
    .catch((err) => dispatch(axiosError(err)))
}

//set logged in users
export const getCompaniesHelper = (data) => {
  return {
    type: 'SET_COMPANIES',
    payload: data,
  }
}

//get companies data
export const startPayments = (userData) => (dispatch) => {
  get('/payments/paytm', {
    ...userData,
  })
    .then((res) => {
      // Save to localStorage
      if (res) {
        dispatch(startPaymentsHelper(res))
      }
    })
    .catch((err) => dispatch(axiosError(err)))
}

//set logged in users
export const startPaymentsHelper = (data) => {
  return {
    type: 'GET_PAYMENTS_HTML',
    payload: data,
  }
}

//axios error
export const axiosError = (err) => {
  return {
    type: 'GET_ERRORS',
    payload: isEmpty(err.response) ? 'Something Went Wrong' : err.response.data,
  }
}
