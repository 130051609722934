import {
  Button,
  Input,
  LoaderPopup,
  TopActionBar,
  useToasts,
  SelectedStoreTags,
  StoreSelector,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer } from '../StoreManagement.styled'
import { useDebouncedEffect, useRouter, useSelectStore } from 'hooks'
import React, { useState } from 'react'
import { Div, FlexBox } from 'styledComponent'
import { useTheme } from 'styled-components'
import { DIFF_OBJ, ERROR_DECODE, FILTER_EMPTY } from 'utils/reusables'
import { _selectOptionsGenerator } from 'utils/formatter'
import { useEffect } from 'react'
import {
  createStoreGroup,
  getStoreGroupDetails,
  updateStoreGroup,
} from 'redux/actions/storeMgtActions'
import { useDispatch } from 'react-redux'
import { FORM_INITS, REQUIRED_FIELDS } from '../constants'
import { getPathStatus } from '../utils'
import { FormExtras } from '../components'

export const StoreGroupForm = () => {
  const { history, params, pathname } = useRouter()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const {
    data: { screenLoading, storeGroupDetails },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { isCreate, isView, isEdit, act } = getPathStatus(pathname)

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [form, setForm] = useState(FORM_INITS?.StoreGroup)

  useEffect(() => {
    params?.id && dispatch(getStoreGroupDetails(params?.id))
  }, [])

  useDebouncedEffect(
    () => {
      if (storeGroupDetails?._id === params?.id) {
        setForm(storeGroupDetails)
      }
    },
    [storeGroupDetails],
    100
  )

  const handleChange = (field, value) =>
    setForm((frm) => ({ ...frm, [field]: value }))

  const setSelectedStoreIds = (groupData) => setForm({ ...form, groupData })

  const onSave = async () => {
    try {
      const unFilled = Object.keys(form)?.find(
        (item) => !form[item] && REQUIRED_FIELDS?.StoreGroup?.includes(item)
      )
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else if (!form?.groupData?.length) {
        throw `Please select atleast one store`
      } else {
        const res = await createStoreGroup(
          FILTER_EMPTY({
            ...form,
            groupType: 'store',
            vendorId,
          })
        )
        if (res) {
          toast('Store Group created Successfully', 'success')
          history.push('/storeManagement?tab=Store%20Groups')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onUpdate = async () => {
    try {
      const reqdForm = DIFF_OBJ(form, storeGroupDetails)
      const unFilled = Object.keys(reqdForm)?.find((item) => !form[item])
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else if (Object.keys(reqdForm)?.length === 0) {
        throw `Please change attributes to update details`
      } else {
        const res = await updateStoreGroup({
          ...reqdForm,
          vendorId,
          groupType: 'store',
          _id: params.id,
        })
        if (res) {
          toast('Store Group updated Successfully', 'success')
          history.push('/storeManagement?tab=Store%20Groups')
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <$WhiteContainer>
      <Navbar heading="Store Management - Store Group" />
      <LoaderPopup isPopOpen={screenLoading} />
      <TopActionBar
        title={`${act} Store Group Data`}
        onExit={() => history.push(`/storeManagement?tab=Store%20Groups`)}
        extras={
          <FormExtras
            isView={isView}
            isCreate={isCreate}
            isEdit={isEdit}
            onUpdate={onUpdate}
            onSave={onSave}
            onEdit={() =>
              history.push(`/storeManagement/editStoreGroup/${params?.id}`)
            }
          />
        }
      />
      <FlexBox p={spacing.xl} pt={spacing.l} justify="space-between">
        <Div>
          <Input
            required
            disabled={isView}
            containerMargin="0"
            variant="input"
            value={form?.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
            inputWidth="500px"
            id="name"
            label="Group Name"
            placeholder="Group Name"
          />
          <Input
            disabled={isView}
            containerMargin="0"
            variant="textarea"
            value={form?.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            rows="5"
            inputWidth="500px"
            id="description"
            label="Group Description"
            placeholder="Group Description"
          />
          <Input
            containerMargin="0"
            inputWidth="500px"
            label="Select Retail Stores"
          >
            <Button
              disabled={isView}
              variant="primaryOutline"
              width="500px"
              onClick={() => setIsPopOpen(true)}
            >
              {form?.groupData?.length > 0
                ? `${form?.groupData?.length} Stores Selected`
                : 'Select Stores'}
            </Button>
            {form?.groupData?.length > 0 && (
              <Button
                disabled={isView}
                variant="primary"
                onClick={() => setIsPopOpen(true)}
                ml={spacing.l}
              >
                Select Stores
              </Button>
            )}
          </Input>
        </Div>
        <Div width="500px">
          <SelectedStoreTags localSelectedStoreIds={form?.groupData} />
        </Div>
      </FlexBox>
      <StoreSelector
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        selectedStoreIds={form?.groupData}
        setSelectedStoreIds={setSelectedStoreIds}
        showTabs={false}
      />
    </$WhiteContainer>
  )
}
