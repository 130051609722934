export const SET_JB_FILTERS = 'SET_JB_FILTERS'
export const SET_JB_PRIORITY = 'SET_JB_PRIORITY'
export const SET_JB_DATA = 'SET_JB_DATA'
export const SET_JB_STATS = 'SET_JB_STATS'
export const SET_JB_PRODUCTS = 'SET_JB_PRODUCTS'
export const SET_JB_DETAILS = 'SET_JB_DETAILS'
export const SET_JB_TABLE_LOADING = 'SET_JB_TABLE_LOADING'
export const SET_JB_LOADING = 'SET_JB_LOADING'
export const SET_JB_ACTION_LIST = 'SET_JB_ACTION_LIST'
export const SET_JB_FILTER_LIST = 'SET_JB_FILTER_LIST'
export const SET_JB_EVENT_LIST = 'SET_JB_EVENT_LIST'
