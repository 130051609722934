import React from 'react'
import {
  CloseButton,
  PrimaryButton,
  TextualButton,
} from 'styledComponent/components/button'
import { ThemeProvider } from 'styled-components'
import { Default } from 'styledComponent'
import { Footer, CampaignBrand } from 'styledComponent/components/modal'
import Checked from 'images/checked.svg'
import { Card } from 'styledComponent/components/card'

export const ModalHeader = (props) => (
  <ThemeProvider theme={Default}>
    <Card
      justify="space-between"
      align="center"
      padding="custom"
      customPadding="15px 30px"
      style={{
        borderRadius: '8px 8px 0px 0px',
        backgroundColor: '#fff',
      }}
    >
      <div>{props.title}</div>
      <CloseButton onClick={props.onClose} />
    </Card>
  </ThemeProvider>
)

export const ModalFooter = (props) => (
  <Footer>
    {props.showBackButton && (
      <TextualButton onClick={props.handleBack}>Back</TextualButton>
    )}
    <PrimaryButton
      margin="0px 0px 0px 10px"
      maxWidth="170"
      type="button"
      onClick={props.handleScreenChange}
    >
      Next
    </PrimaryButton>
  </Footer>
)

export const MinimalIdentityBox = ({
  name,
  logo,
  id = 'defaultid',
  inputName = 'default',
  inputType,
  handleChange,
  checked,
}) => (
  <CampaignBrand isChecked={checked}>
    {checked && <img id="checked" src={Checked} alt="Blue checked symbol" />}
    <div />
    <input
      type={inputType}
      name={inputName}
      value={id}
      onChange={handleChange}
      data-readableName={name}
      data-logo={logo}
      checked={checked}
    />
    {logo ? (
      <img src={logo} alt={`${name} logo`} width={50} height={50} />
    ) : (
      <section />
    )}
    <p title={name}>{name}</p>
  </CampaignBrand>
)
