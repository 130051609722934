import React from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep, isEqual } from 'lodash'

import Navbar from 'components/Layout/Navbar'
import { NoDataFound } from 'components/Common/EmptyState'
import { useToasts, TopActionBar } from 'components/Common'
import { spacing } from 'design-system'
import { useRouter, useSelectStore } from 'hooks'
import { patch, post } from 'utils/axiosHandler'

import { $BodyStyles, $FlexBox, $FlexItem } from '../ProductAnalytics.styled'
import { BODY_KEYS, ERROR_MESSAGES, INITIAL_DATA } from './constants'
import ThumbnailAndTags from './ThumbnailAndTags'
import ProductForm from './ProductForm'
import { ERROR_DECODE } from 'utils/reusables'

/**
 * @description add/edit product page component
 */
const AddOrEditProduct = () => {
  const [productData, setProductData] = React.useState(null)
  const [newCategory, setNewCategory] = React.useState('')
  const [savingState, setSavingState] = React.useState(false)
  const { id } = useParams()

  const { toast } = useToasts()
  const {
    history,
    state: { forManagement, displayName },
  } = useRouter()
  const { data: allProducts } = useSelectStore('productAnalytics.allProducts')
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')

  const onExitClick = () => {
    history.goBack()
  }

  const _getProductData = () => {
    let currentProduct = null
    if (id) {
      currentProduct = allProducts?.docs?.find((product) => product._id === id)
    }
    return {
      ...cloneDeep({ ...INITIAL_DATA, displayNames: [displayName || ''] }),
      ...cloneDeep(currentProduct || {}),
    }
  }

  const onAddTags = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      const tags = [...(productData?.tags || [])]
      tags.push(e.target.value)
      e.target.value = ''
      setProductData((state) => ({
        ...state,
        tags,
      }))
    }
  }

  const onClearTag = (index) => {
    const tags = [...(productData?.tags || [])]
    tags.splice(index, 1)
    setProductData((state) => ({
      ...state,
      tags,
    }))
  }

  const onInputChange = (e, field) => {
    const value = e.target.value
    setProductData((state) => ({
      ...state,
      [field]: value,
    }))
  }

  const onSelectChange = (value, field) => {
    setProductData((state) => ({
      ...state,
      [field]: value,
    }))
  }

  const onAddMoreDisplayNames = () => {
    const displayNames = productData?.displayNames || []
    if (displayNames.length < 5) {
      displayNames.push('')
      setProductData((state) => ({
        ...state,
        displayNames,
      }))
    }
  }

  const onDisplayNameChange = (e, index) => {
    const displayNames = productData?.displayNames || []
    displayNames[index] = e.target.value
    setProductData((state) => ({
      ...state,
      displayNames,
    }))
  }

  const onClearDisplayName = (index) => {
    const displayNames = productData?.displayNames || []
    displayNames.splice(index, 1)
    setProductData((state) => ({
      ...state,
      displayNames,
    }))
  }

  const onNewCategoryChange = (value) => {
    setNewCategory(value)
  }

  const _getProductFields = (localData) => {
    let currentProduct = null
    if (id) {
      currentProduct = allProducts?.docs?.find((product) => product._id === id)
    }
    if (currentProduct) {
      const diff = Object.keys(localData).reduce((result, key) => {
        if (!currentProduct.hasOwnProperty(key)) {
          result.push(key)
        } else if (isEqual(localData[key], currentProduct[key])) {
          const resultKeyIndex = result.indexOf(key)
          result.splice(resultKeyIndex, 1)
        }
        return result
      }, Object.keys(currentProduct))

      return diff
    }

    return Object.keys(localData || {})
  }

  const _generateRequestBody = (data) => {
    const body = {}
    const fields = _getProductFields(data)

    fields.forEach((key) => {
      const bodyKey = BODY_KEYS[key] || key
      if (Array.isArray(data[key]) && data[key].length) {
        if (key === 'displayNames') {
          const hasDisplayNames = data[key]?.some((value) => value)
          if (hasDisplayNames) {
            body[bodyKey] = data[key].filter((value) => value)
          } else {
            body['displayNames'] = []
          }
        } else {
          body[bodyKey] = data[key]
        }
      } else if (data[key]?.hasOwnProperty('label')) {
        body[bodyKey] = data[key].value
      } else if (typeof data[key] === 'string') {
        body[bodyKey] = data[key]
      }
    })

    return body
  }

  const onProductSave = async () => {
    const finalProductData = cloneDeep(productData)
    const mandatoryFields = ['categoryDetails', 'productName']
    const isValid = mandatoryFields.every((field) => finalProductData[field])

    if (!isValid) {
      toast(ERROR_MESSAGES.mandatoryFields, 'error')
      return null
    }

    const body = _generateRequestBody(finalProductData)

    if (Object.keys(body).length) {
      setSavingState(true)
      body.vendorId = selectedVendor?.value
      body.brandId = selectedBrand?.value

      const url = `/product-management/products${id ? `/${id}` : ''}`
      const method = id ? patch : post

      try {
        const response = await method(url, body)
        if (response.data?.message) {
          throw response.data.message
        }
        toast(`Product successfully ${id ? 'updated' : 'added'}`, 'success')
        onExitClick()
      } catch (error) {
        toast(ERROR_DECODE(error), 'error')
      } finally {
        setSavingState(false)
      }
    }
  }

  React.useEffect(() => {
    setProductData(_getProductData())
  }, [])

  return (
    <>
      <Navbar
        heading={`${
          forManagement ? 'Products' : 'Product Analytics'
        } - Product`}
      />
      {id && productData && !productData?._id ? (
        <NoDataFound />
      ) : (
        <>
          <TopActionBar
            onExit={onExitClick}
            onSave={onProductSave}
            loading={savingState}
            title={id ? 'Edit Product' : 'Add New Product'}
          />
          <$FlexBox m={`${spacing.xl} ${spacing.l} ${spacing.l}`}>
            <$FlexItem flexBasis="30%" maxWidth="300px">
              <ThumbnailAndTags
                productName={productData?.productName}
                tags={productData?.tags}
                onAddTag={onAddTags}
                onClearTag={onClearTag}
              />
            </$FlexItem>
            <$FlexItem
              flexBasis="68%"
              padding={`0 ${spacing.l} 0 ${spacing.xl}`}
            >
              <ProductForm
                productData={productData}
                newCategory={newCategory}
                onInputChange={onInputChange}
                onSelectChange={onSelectChange}
                onAddMoreDisplayNames={onAddMoreDisplayNames}
                onDisplayNameChange={onDisplayNameChange}
                onClearDisplayName={onClearDisplayName}
                onNewCategoryChange={onNewCategoryChange}
              />
            </$FlexItem>
          </$FlexBox>
        </>
      )}
      <$BodyStyles />
    </>
  )
}

export default AddOrEditProduct
