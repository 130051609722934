import React, { useEffect, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { cloneDeep } from 'lodash'
import DraggerIcon from 'assets/icons/drag.svg'
import { SurveyHeader, SurveyToolbox, SurveyItem } from './components'
import { QUEST_GEN, SURVEY_OBJECT } from './constants'
import {
  $DraggerIcon,
  $QuestButton,
  $SurveyContainer,
  $Submit,
} from './Survey.styled'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Div, FlexBox } from 'styledComponent'
import { SurveyImage } from './components/SurveyImage'
import { SurveyBackground } from './components/SurveyBackground'
import { AddBlueFilledIcon, ViewWhiteIcon, EditWhiteIcon } from 'assets/icons'
import { Text } from 'components/Common'
import 'components/Content/autoEngagement/survey/createSurvey/transition.css'

export const SurveyBuilder = ({
  form,
  setForm,
  hideHeader,
  answers,
  onSubmit,
}) => {
  const [survey, setSurvey] = useState(cloneDeep(SURVEY_OBJECT))
  const [isEditing, setIsEditing] = useState(!hideHeader)
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  const setContentType = (contentType) =>
    setForm({
      ...form,
      contentType,
    })

  useEffect(() => {
    !form.content?.background
      ? setForm({
          ...form,
          content: cloneDeep(SURVEY_OBJECT),
        })
      : setSurvey(form.content)
  }, [])

  useEffect(() => {
    setForm({
      ...form,
      content: survey,
    })
  }, [survey])

  const setBackground = (background) => {
    setSurvey({
      ...survey,
      background,
    })
  }

  const setMarginals = (marginal) => {
    let mars = []
    if (
      survey?.marginals?.filter((mar) => mar.section === marginal.section)
        ?.length > 0
    ) {
      mars = survey?.marginals?.map((mar) => {
        if (mar.section === marginal.section) mar = marginal
        return mar
      })
    } else {
      mars = [...survey.marginals, marginal]
    }
    const marginals = mars?.filter((marg) => marg.url) || []
    setSurvey({ ...survey, marginals })
  }

  const getMarginal = (sect) => {
    const marginal = survey?.marginals?.filter(
      (mar) => mar.section === sect
    )[0] || {
      section: sect,
      url: '',
    }
    return marginal
  }

  const setQuest = (quest, i) => {
    const body = survey.body?.map((qu, ind) => {
      if (i === ind) {
        qu = quest
      }
      return qu
    })
    setSurvey({ ...survey, body })
  }

  const replicateQuest = (quest) => {
    const questClone = { ...quest }
    delete questClone._id
    const body = [...survey.body, questClone]
    setSurvey({ ...survey, body })
    setTimeout(
      () =>
        document.getElementById('surveyCon')?.scrollTo({
          top: 100000,
          behavior: 'smooth',
        }),
      100
    )
  }

  const finishEditing = () => setIsEditing(!isEditing)

  const deleteQuestion = (i) => {
    const quest = survey?.body?.filter((_, ind) => i !== ind)
    const body = quest
    setSurvey({ ...survey, body })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : 'transparent',
    height: '100%',
    paddingBottom: '16px',
    borderRadius: '8px',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
  })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const body = reorder(
      survey.body,
      result.source.index,
      result.destination.index
    )

    setSurvey({ ...survey, body })
  }

  const isMultiPage = form?.contentType === 'multiple'

  const onPreviousClick = () => setIndex(index - 1)
  const onNextClick = () => {
    if (index === survey?.body?.length - 1) {
      onSubmit && onSubmit()
      return
    }
    setIndex(index + 1)
  }
  const isMultiCheck = (ind) => {
    if (!isMultiPage) return true
    if (isMultiPage && isEditing) return true
    else return ind === index
  }

  const questGenerator = () => {
    const newQuest = QUEST_GEN('radio')
    setSurvey({
      ...survey,
      body: [...survey.body, newQuest],
    })
    setTimeout(
      () =>
        document.getElementById('surveyCon')?.scrollTo({
          top: 100000,
          behavior: 'smooth',
        }),
      100
    )
  }

  return (
    <>
      <SurveyBackground
        showModal={showModal}
        setShowModal={setShowModal}
        setBackground={setBackground}
        survey={survey}
      />
      {!hideHeader && (
        <$SurveyContainer showBorder>
          <FlexBox align="center" justify="space-between">
            <Text variant="h2" type="main" weight="bold">
              Survey Builder
            </Text>
            <FlexBox align="center" gap="24px" justify="flex-start">
              <FlexBox
                align="center"
                gap="4px"
                onClick={() => setContentType('single')}
                justify="flex-start"
                type="button"
              >
                <input
                  checked={form?.contentType === 'single'}
                  type="radio"
                  id="single"
                />
                <span htmlFor="single"> Single Page Survey </span>
              </FlexBox>
              <FlexBox
                align="center"
                onClick={() => setContentType('multiple')}
                gap="4px"
                justify="flex-start"
                type="button"
              >
                <input
                  checked={form?.contentType === 'multiple'}
                  type="radio"
                  id="multiple"
                />
                <span htmlFor="multiple"> Multi Page Survey </span>
              </FlexBox>

              <$QuestButton onClick={finishEditing}>
                {isEditing ? (
                  <FlexBox align="center" gap="8px">
                    <ViewWhiteIcon /> Preview{' '}
                  </FlexBox>
                ) : (
                  <FlexBox align="center" gap="8px">
                    <EditWhiteIcon /> Edit{' '}
                  </FlexBox>
                )}
              </$QuestButton>
            </FlexBox>
          </FlexBox>
        </$SurveyContainer>
      )}
      <FlexBox align="center" justify="space-between">
        <Div width="calc(100% - 120px)">
          <$SurveyContainer
            showBorder={isEditing}
            id="surveyCon"
            style={{ background: survey?.background }}
            width={isEditing ? '800px' : '480px'}
            height="calc(100vh - 100px)"
          >
            <SurveyImage
              marginal={getMarginal('header')}
              setMarginals={setMarginals}
              isEditing={isEditing}
            />
            <SurveyHeader
              survey={survey}
              isEditing={isEditing}
              setHead={(header) => setSurvey({ ...survey, ...header })}
            />
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={index}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                classNames="fade"
              >
                <Div
                  width="100%"
                  minHeight="245px"
                  overflowX={isEditing ? '' : 'hidden'}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {survey?.body?.map((quest, i) => {
                            return (
                              isMultiCheck(i) && (
                                <Draggable
                                  key={i + 'quest'}
                                  draggableId={i + 'quest'}
                                  isDragDisabled={!isEditing}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={{
                                        cursor: 'default',
                                        ...getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        ),
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <SurveyItem
                                          body={survey?.body}
                                          isEditing={isEditing}
                                          quest={quest}
                                          qi={i}
                                          key={i + 'quest'}
                                          questId={i + 'quest'}
                                          deleteQuestion={deleteQuestion}
                                          setQuest={setQuest}
                                          replicateQuest={replicateQuest}
                                          answers={answers}
                                          fontColor={survey?.fontColor}
                                          fontFamily={survey?.fontFamily}
                                        />
                                        {isEditing && (
                                          <$DraggerIcon
                                            {...provided.dragHandleProps}
                                            style={{ cursor: 'move' }}
                                            src={DraggerIcon}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Div>
              </CSSTransition>
            </SwitchTransition>

            <FlexBox justify="center" align="center" direction="column">
              {isMultiPage && !isEditing && (
                <FlexBox justify="center" align="center" gap="20px">
                  <$Submit
                    bgColor={survey?.buttonConfig?.bgColor}
                    fontWeight={survey?.buttonConfig?.fontWeight}
                    textColor={survey?.buttonConfig?.textColor}
                    fontFamily={survey?.fontFamily}
                    disabled={index === 0}
                    onClick={onPreviousClick}
                  >
                    Previous
                  </$Submit>
                  <$Submit
                    bgColor={survey?.buttonConfig?.bgColor}
                    fontWeight={survey?.buttonConfig?.fontWeight}
                    textColor={survey?.buttonConfig?.textColor}
                    fontFamily={survey?.fontFamily}
                    onClick={onNextClick}
                  >
                    Next
                  </$Submit>
                </FlexBox>
              )}
              {!isEditing && !isMultiPage && (
                <$Submit
                  bgColor={survey?.buttonConfig?.bgColor}
                  fontWeight={survey?.buttonConfig?.fontWeight}
                  textColor={survey?.buttonConfig?.textColor}
                  fontFamily={survey?.fontFamily}
                  isSinglePage={!isMultiPage}
                  onClick={onSubmit}
                >
                  Next
                </$Submit>
              )}
            </FlexBox>

            {isEditing && (
              <FlexBox
                type="button"
                gap="8px"
                mb="16px"
                justify="center"
                align="center"
                onClick={questGenerator}
              >
                <AddBlueFilledIcon />
                <Text type="main" variant="h3" weight="bold">
                  ADD QUESTION
                </Text>
              </FlexBox>
            )}
          </$SurveyContainer>
        </Div>
        {isEditing && (
          <SurveyToolbox
            survey={survey}
            setSurvey={setSurvey}
            setShowModal={setShowModal}
          />
        )}
      </FlexBox>
    </>
  )
}
