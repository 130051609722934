import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToasts } from 'components/Common'
import {
  getAssetsList,
  setCampaignLoader,
  deleteAsset,
} from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../constants'

export const useUploadedDataApi = (vendorId, search, skip, action, update) => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: 10,
  })
  const dispatch = useDispatch()
  const { toast } = useToasts()
  const debounceLoadData = useCallback(
    debounce((q) => setQuery(q), 750),
    []
  )

  useEffect(() => {
    query.vendorId && dispatch(getAssetsList(query))
  }, [query])

  useEffect(() => {
    dispatch(setCampaignLoader(true))
    const tempQuery = { ...query, vendorId, skip, name: search }
    if (search.length === 0) {
      delete tempQuery.name
    }
    debounceLoadData(tempQuery)
  }, [vendorId, search, skip, update])

  const deleteFile = async () => {
    try {
      const res = await deleteAsset(action?.id)
      if (res) {
        toast('File deleted successfully', 'success')
        dispatch(getAssetsList(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  useEffect(() => {
    action?.id &&
      (action?.action === 'delete'
        ? deleteFile()
        : downloadFile(action?.name, action?.fileLocation))
  }, [action?.id])

  const downloadFile = (fileName, urlData) => {
    const aLink = document.createElement('a')
    aLink.download = fileName
    aLink.href = urlData
    const event = new MouseEvent('click')
    aLink.dispatchEvent(event)
  }

  return null
}
