import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelectStore } from 'hooks'
import {
  setUsageStoreIds,
  setUsageTabCompany,
} from 'redux/actions/usageAndInvoice'

import { Breadcrumb } from '../../Common'
import { Div, FlexBox } from 'styledComponent'
import { StoreHeading } from 'components/Layout/StoresHeader'
import { SimpleOutlineButton } from 'styledComponent/components/button'
import { Input } from '../../Common'

import { GET_SEL_VALUE } from '../autoEngagement/constants'

function Header({ showStoreHeading = false, companyId }) {
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [_, setStoreGrp] = useState()
  const { data: selectedStoreIds } = useSelectStore(
    'usageAndInvoice.usageTab.storeIds'
  )
  const dispatch = useDispatch()
  const { data: companies } = useSelectStore('company.companies')
  const { data: company } = useSelectStore('usageAndInvoice.usageTab.company')
  useEffect(() => {
    dispatch(
      setUsageTabCompany(
        GET_SEL_VALUE(
          companyId,
          companies?.map((company) => ({
            label: company.companyName,
            value: company.companyId,
          })) || []
        )
      )
    )
  }, [companyId])

  return (
    <FlexBox
      padding="16px 0"
      justify="space-between"
      align="center"
      width="100%"
    >
      <Breadcrumb label="Usage & Invoice" />
      <FlexBox align="center" gap="10px">
        {companies?.length > 1 ? (
          <Input
            containerMargin={'0'}
            variant={'single-select'}
            id="select-company"
            options={
              companies?.map((company) => ({
                label: company.companyName,
                value: company.companyId,
              })) || []
            }
            inputWidth={'240px'}
            placeholder="Select Company"
            value={company}
            onChange={(e) => dispatch(setUsageTabCompany(e))}
          />
        ) : null}
        {showStoreHeading ? (
          <SimpleOutlineButton
            type="button"
            className="mr-2 py-2 mb-1"
            onClick={() => setIsPopOpen(true)}
          >
            <StoreHeading
              selectedStoreIds={selectedStoreIds || []}
              setSelectedStoreIds={(selectedStoreIds) => {
                dispatch(setUsageStoreIds(selectedStoreIds))
              }}
              isPopOpen={isPopOpen}
              setIsPopOpen={(isPopOpen) => setIsPopOpen(isPopOpen)}
              showStatus
              setStoreGrp={setStoreGrp}
              disableCompanyInput={true}
              initForm={{ company: [company?.value] }}
              key={company?.value}
            />
          </SimpleOutlineButton>
        ) : null}
      </FlexBox>
    </FlexBox>
  )
}

export default Header
