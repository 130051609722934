import React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { spacing, color } from 'design-system/styles'

const getType = (type) => {
  switch (type) {
    case 'tab':
      return '4px'
    default:
      return '20px'
  }
}

const getBackgroundColor = (type) => {
  switch (type) {
    case 'primary':
      return color.brightTurquoise
    case 'filter':
      return color.zircon
    default:
      return color.white
  }
}

const getBorderColor = (type) => {
  switch (type) {
    case 'primary':
      return color.brightTurquoise
    case 'filter':
      return color.malibu
    default:
      return color.pillDefaultBorder
  }
}

const getColor = (type) => {
  switch (type) {
    case 'primary':
      return color.brightTurquoise
    case 'filled':
      return color.white
    case 'increase':
      return color.increase
    case 'decrease':
      return color.decrease
    case 'filter':
      return color.pickledBluewood
    default:
      return color.mineShaft
  }
}

const VARIANT = {
  FILLED: 'filled',
  STATS: 'stats',
  OUTLINE: 'outline',
  FILTER: 'filter',
}

const StyledPill = styled.div`
  width: max-content;
  height: ${(props) =>
    props.variant === VARIANT.STATS ? '25px' : 'max-content'};
  background-color: ${(props) => getBackgroundColor(props.bg || props.variant)};
  color: ${(props) =>
    props.variant && props.variant !== VARIANT.STATS
      ? getColor(props.variant)
      : getColor(props.color)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.padding ? props.padding : `${spacing.s} ${spacing.m}`};
  border: ${(props) =>
    props.variant === VARIANT.STATS
      ? 'none'
      : `1px solid ${getBorderColor(
          props.borderColor || props.color || props.variant
        )}`};
  border-radius: ${(props) => getType(props.type)};
  box-sizing: border-box;
  font-size: ${(props) => (props.variant === VARIANT.STATS ? '12px' : '14px')};
  font-weight: ${({ variant }) => (variant === VARIANT.FILTER ? '400' : '700')};
  ${space};
`

const StyledAvatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: ${(props) =>
    props.variant === VARIANT.STATS ? '0px' : '50%'};
  margin-right: ${spacing.m};
  background-color: #fff;
`

export const StyledCloseButton = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.3;
  position: relative;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${spacing.m};

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #333;
    ${({ variant }) =>
      variant === VARIANT.FILTER &&
      css`
        height: 10px;
        width: 1.5px;
        background-color: ${color.white};
      `}
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.6;
  }

  ${({ variant }) =>
    variant === VARIANT.FILTER &&
    css`
      background-color: #707070;
      border-radius: 50%;
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    `}
`

export const Pill = ({ avatar, children, onClose, ...rest }) => {
  return (
    <StyledPill {...rest}>
      {avatar && <StyledAvatar src={avatar} {...rest} />}
      {children}
      {onClose && <StyledCloseButton onClick={onClose} {...rest} />}
    </StyledPill>
  )
}

const StyledPillArray = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : 'nowrap')};
`

export const PillArray = ({ children, ...rest }) => {
  return <StyledPillArray {...rest}>{children}</StyledPillArray>
}
