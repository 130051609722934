import { useRouter } from 'hooks'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { $WhiteContainer } from '../../AutoEngagement.styled'
import { SRTable } from './SRTable'
import { SRTopBar } from './SRTopBar'
import { useSRApi } from './useSRApi'

const SurveyResponses = (props) => {
  const {
    aeTableLoading,
    surveyResponseData,
    aeSurveyData,
    isSIB,
  } = props.autoEngage

  const { location } = useRouter()
  const surveyId = location.pathname?.split('/')[4]

  const [query, setQuery] = useState({
    email: '',
    phone: '',
    page: 1,
    campaignType: isSIB ? 'surveyInBill' : '',
    sortObj: {
      createdDate: -1,
    },
  })

  useSRApi(query, surveyId)

  return (
    <$WhiteContainer>
      <SRTopBar
        query={query}
        setQuery={setQuery}
        isSIB={isSIB}
        totalDocs={surveyResponseData?.totalDocs}
      />
      <SRTable
        isSIB={isSIB}
        query={query}
        setQuery={setQuery}
        tableLoading={aeTableLoading}
        srList={surveyResponseData?.docs}
        pageCount={surveyResponseData?.pageCount}
        aeSurveyData={aeSurveyData}
      />
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  autoEngage: state.autoEngage,
})

export default connect(mapStateToProps)(SurveyResponses)
