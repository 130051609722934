import React, { Component } from 'react'
import 'css/content/global.scss'

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.autoCompleteRef = React.createRef()

    this.state = {
      autoCompleteActive: false,
      autoCompleteData: [],
    }
  }

  componentWillReceiveProps(props) {
    props.values && this.setState({ autoCompleteData: props.values })
  }

  handleInputFocus = () => {
    this.setState({
      autoCompleteActive: true,
    })
  }

  handleInputBlur = (event) => {
    event.preventDefault()
    if (this.autoCompleteRef.current.matches(':hover')) {
      return
    }
    this.setState({
      autoCompleteActive: false,
      autoCompleteData: [],
    })
  }

  handleInputChange = (value) => {
    this.props.onInputChange && this.props.onInputChange(value)
  }

  handleItemSelect = (item) => {
    this.props.onSelect && this.props.onSelect(item)
    this.setState({
      autoCompleteActive: false,
      autoCompleteData: [],
    })
  }

  render() {
    return (
      <div className="auto-complete" ref={this.autoCompleteRef}>
        <div className="input-group ">
          <div className="input-group-prepend">
            <i className="input-group-text search"></i>
          </div>
          <input
            type="text"
            ref={this.inputRef}
            className="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            placeholder={this.props.placeHolder}
            onChange={(event) => this.handleInputChange(event.target.value)}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
          />
        </div>
        <div className="filter-auto-complete" ref={this.autoCompleteRef}>
          {this.state.autoCompleteActive &&
            this.state.autoCompleteData.map((row, index) => {
              return (
                <div
                  Key={index}
                  className="filter-auto-complete-item"
                  onClick={(event) => this.handleItemSelect(row)}
                >
                  <div className="filter-auto-complete-image">
                    <img
                      className="logo"
                      src={row.image || require('../../images/NoPath.jpg')}
                      alt=""
                    />
                  </div>
                  <div className="filter-auto-complete-label">{row.label}</div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

export { SearchInput }
