import React, { useEffect, useState } from 'react'
import { Button, Input, PopupContainer, useToasts } from 'components/Common'
import { UploadBox } from 'components/Content/autoEngagement/components/MediaUpload'
import { $Browse } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { FlexBox } from 'styledComponent'
import { GET_SEL_VALUE, ERROR_DECODE } from 'utils/reusables'
import { useRouter, useSelectStore } from 'hooks'
import { useDispatch } from 'react-redux'
import {
  createBulkStores,
  getBrandOptions,
  getCompanyOptions,
} from 'redux/actions/storeMgtActions'

export const BulkUploadForm = ({ isPopOpen, setIsPopOpen }) => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const {
    data: { companyOptions, brandOptions },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const [form, setForm] = useState({})

  useEffect(() => {
    dispatch(getCompanyOptions())
    dispatch(getBrandOptions())
  }, [])

  useEffect(() => {
    if (!isPopOpen) setForm({})
  }, [isPopOpen])

  const handleChange = (field, value) => {
    setForm((frm) => ({ ...frm, [field]: value }))
  }

  const getCommonProps = (id, label, placeholder) => ({
    id,
    label,
    key: id,
    placeholder,
    containerMargin: '0',
    inputWidth: '350px',
    variant: 'input',
    value: form?.[id] || '',
    onChange: (e) => handleChange(id, e?.target?.value),
  })

  const getSpecificProps = {
    storeCompanyId: {
      variant: 'single-select',
      options: companyOptions,
      value: GET_SEL_VALUE(form?.storeCompanyId, companyOptions) || '',
      onChange: (e) => handleChange('storeCompanyId', e?.value),
    },
    storeBrandId: {
      variant: 'single-select',
      options: brandOptions,
      value: GET_SEL_VALUE(form?.storeBrandId, brandOptions) || '',
      onChange: (e) => handleChange('storeBrandId', e?.value),
    },
    storeType: {
      variant: 'single-select',
      options: [
        { label: 'Retail', value: 'offline' },
        { label: 'E-Commerce', value: 'online' },
      ],
      value:
        GET_SEL_VALUE(form?.storeType, [
          { label: 'Retail', value: 'offline' },
          { label: 'E-Commerce', value: 'online' },
        ]) || '',
      onChange: (e) => handleChange('storeType', e?.value),
    },
  }

  const onSubmit = async () => {
    try {
      if (
        !form?.file ||
        !form?.storeBrandId ||
        !form?.file ||
        !form?.storeType
      ) {
        throw 'Fill all necessary fields.'
      }

      const formData = new FormData()
      formData.append('file', form?.file)
      formData.append('storeBrandId', form?.storeBrandId)
      formData.append('storeCompanyId', form?.storeCompanyId)
      formData.append('type', form?.storeType)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const res = await createBulkStores(vendorId, formData, config)
      if (res) {
        toast('File uploaded successfully', 'success')
        history.push('/storeManagement')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw err
    }
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Upload CSV File - Bulk Upload Stores"
      onSubmitClick={onSubmit}
    >
      <FlexBox gap="32px">
        <Input
          required
          {...getCommonProps('storeCompanyId', 'Select Company', 'ABC PVT LTD')}
          {...getSpecificProps?.storeCompanyId}
        />
        <Input
          required
          {...getCommonProps('storeBrandId', 'Select Brand', 'Fantastic Foods')}
          {...getSpecificProps?.storeBrandId}
        />
      </FlexBox>
      <FlexBox gap="32px">
        <Input
          required
          {...getCommonProps(
            'storeType',
            'Select Store Type',
            'Retail | E-Commerce'
          )}
          {...getSpecificProps?.storeType}
        />
      </FlexBox>
      <UploadBox
        src={form?.file}
        uploadWidth="100%"
        uploadHeight="150px"
        acceptedFormats=".csv"
        fileType="csv"
        onChange={(e) => handleChange('file', e.target.files[0])}
        displayFileType="file"
      />
      <FlexBox justify="space-between">
        <Button
          mt="16px"
          fontSize="12px"
          variant="primaryOutline"
          onClick={() =>
            window.open(
              'https://assets.billme.co.in/public/samples/stores-bulk-sample.csv'
            )
          }
        >
          Download Sample .csv
        </Button>
        <$Browse>
          <span>Add File : </span>
          <div>
            <input
              accept=".csv"
              type="file"
              onChange={(e) => handleChange('file', e.target.files[0])}
              title={form?.file?.name || 'No File Chosen'}
            />
            <small>BROWSE</small>
          </div>
        </$Browse>
      </FlexBox>
    </PopupContainer>
  )
}
