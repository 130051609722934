import React from 'react'

import { Text, StyledCloseButton } from 'components/Common'
import { ClearIcon } from 'assets/icons'
import { $Clear, $TagBlock } from './ProductTags.styled'

/**
 * @description product tags components
 */
const ProductTag = ({
  children,
  clearable,
  onClear,
  margin,
  roundedCorners,
}) => {
  return (
    <$TagBlock
      justify={clearable ? 'space-between' : 'center'}
      align="center"
      margin={margin}
      roundedCorners={roundedCorners}
    >
      <Text
        width="60px"
        variant="small"
        align={clearable ? 'left' : 'center'}
        title={children}
        ellipsis
      >
        {children}
      </Text>
      {clearable && (
        <>
          {!roundedCorners ? (
            <StyledCloseButton onClick={onClear} />
          ) : (
            <$Clear onClick={onClear}>
              <ClearIcon />
            </$Clear>
          )}
        </>
      )}
    </$TagBlock>
  )
}

export default ProductTag
