import React, { useEffect, useState } from 'react'
import { Element, useEditor } from '@craftjs/core'

import { $Toolbox, $FlexBox, $ToolTab, $ComponentBox } from './Editor.styled'
import {
  Button,
  Container,
  Text,
  Image,
  HTMLBlock,
  Social,
  Line,
  Columns,
  Spacer,
  Survey,
  Media,
} from './selectors'
import { SettingsPanel } from './SettingsPanel'
import {
  ButtonIcon,
  ContainerIcon,
  TextIcon,
  ImageIcon,
  HTMLBracketsIcon,
  EditorSocialIcon,
  EditorSpacerIcon,
  EditorLineIcon,
  ColumnIcon,
  MediaLinkIcon,
  SurveyIcon,
  VoucherIcon,
} from 'assets/icons'
import { Coupon } from './selectors/Coupon/Coupon'

export const Toolbox = ({ isSurvey }) => {
  const { connectors, selected } = useEditor((state, query) => {
    const currentNodeId = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      }
    }

    return {
      selected,
    }
  })

  useEffect(() => {
    selected && setCurrentTab(false)
  }, [selected])

  const [currentTab, setCurrentTab] = useState(true)

  return (
    <$Toolbox>
      <$FlexBox>
        <$ToolTab onClick={() => setCurrentTab(true)} active={currentTab}>
          Components
        </$ToolTab>
        <$ToolTab onClick={() => setCurrentTab(false)} active={!currentTab}>
          Properties
        </$ToolTab>
      </$FlexBox>
      {currentTab ? (
        <$FlexBox padding="16px" gap="5%" justify="flex-start">
          {!isSurvey && (
            <>
              <$ComponentBox
                ref={(ref) =>
                  connectors.create(
                    ref,
                    <Element canvas is={Container} padding="20px" />
                  )
                }
              >
                <ContainerIcon />
                <p>Container</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Columns />)}>
                <ColumnIcon />
                <p>Columns</p>
              </$ComponentBox>
            </>
          )}
          <$ComponentBox ref={(ref) => connectors.create(ref, <Button />)}>
            <ButtonIcon />
            <p>Button</p>
          </$ComponentBox>
          <$ComponentBox ref={(ref) => connectors.create(ref, <Text />)}>
            <TextIcon />
            <p>Text</p>
          </$ComponentBox>
          <$ComponentBox ref={(ref) => connectors.create(ref, <Image />)}>
            <ImageIcon />
            <p>Image</p>
          </$ComponentBox>
          {!isSurvey && (
            <>
              <$ComponentBox
                ref={(ref) => connectors.create(ref, <HTMLBlock />)}
              >
                <HTMLBracketsIcon />
                <p>HTML Block</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Social />)}>
                <EditorSocialIcon />
                <p>Social</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Spacer />)}>
                <EditorSpacerIcon />
                <p>Spacer</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Line />)}>
                <EditorLineIcon />
                <p>Line</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Survey />)}>
                <SurveyIcon />
                <p>Survey</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Coupon />)}>
                <VoucherIcon />
                <p>Coupon</p>
              </$ComponentBox>
              <$ComponentBox ref={(ref) => connectors.create(ref, <Media />)}>
                <MediaLinkIcon />
                <p>Media</p>
              </$ComponentBox>
            </>
          )}
        </$FlexBox>
      ) : (
        <SettingsPanel />
      )}
    </$Toolbox>
  )
}
