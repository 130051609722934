import styled, { css } from 'styled-components'

export const StyledInput = styled.input`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.alto};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const UploadButton = styled.button`
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.alto};
  background-color: ${({ theme }) => theme.colors.neutral['100']};
  border-radius: 6px;
  padding: 6px 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral['150']};
  }
`

export const ToggleButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: #222;
  border-radius: 8px;
  padding: 6px 8px;
  width: 105px;
  text-align: center;
  z-index: 2;
  position: relative;
  transition: color 0.2s ease-in-out;
  font-size: 14px;

  &:first-child {
    margin-right: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: #fff;
    `}
`
export const FeedbackText = styled.div`
  color: hsl(0, 0%, 5%);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
`
export const ImageButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: #222;
  border-radius: 8px;
  padding: 6px 8px;
  width: 105px;
  text-align: center;
  transition: color 0.2s ease-in-out;
  font-size: 12px;
  display: 'flex',
  alignItems: 'center',
  border: 'solid 1px #e6e6e6',
  marginTop: '6px',

  &:first-child {
    margin-right: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: #fff;
    `}
`

export const ToggleButtonContainer = styled.div`
  padding: 8px;
  background-color: #fff;
  display: inline-block;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 105px;
    background-color: ${({ theme }) => theme.colors.primary.dark};
    height: calc(100% - 15px);
    position: absolute;
    top: 8px;
    border-radius: 4px;
    z-index: 1;
    transition: left 0.2s;

    ${({ active }) =>
      active === 'upload'
        ? css`
            left: 8px;
          `
        : css`
            left: calc(105px + 8px + 8px);
          `}
  }
`
