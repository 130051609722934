import React, { useEffect } from 'react'
import { useEditor } from '@craftjs/core'
import lz from 'lzutf8'

import {
  DesktopIcon,
  FullScreenIcon,
  FullScreenExitIcon,
  ImportIcon,
  MobileIcon,
  PreviewIcon,
  PreviewExitIcon,
  RedoIcon,
  UndoIcon,
  SaveTemplateIcon,
} from 'assets/icons/emailEditorAssets'
import { $EditorHeader, $FlexBox } from './Editor.styled'
import { spacing } from 'design-system'
import { Slider } from 'components/Common/Slider'

export const Topbar = ({
  handleHtml,
  isMobileView,
  onSaveTemplate,
  setIsMobileView,
  onImportTemplate,
  onPreview,
}) => {
  const { actions, query, canUndo, canRedo, editorState } = useEditor(
    (state, query) => ({
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
      editorState: state,
    })
  )

  useEffect(() => {
    onSaveState()
  }, [editorState])

  const openFullscreen = () => {
    const elem = document?.getElementById('myEditor')
    if (elem?.requestFullscreen) {
      elem.requestFullscreen()
    }
  }

  const closeFullscreen = () => {
    if (document?.exitFullscreen) {
      document.exitFullscreen()
    }
  }

  const onSaveState = () => {
    if (handleHtml) {
      const elem = document.getElementById('mainEditor')
      const json = query.serialize()
      const compressed = lz.encodeBase64(lz.compress(json))
      handleHtml(elem?.innerHTML?.toString(), compressed)
    }
  }

  return (
    <$EditorHeader>
      <$FlexBox padding="0 0 0 128px" justify="flex-end" gap={spacing.ml}>
        <$FlexBox style={{ flex: 1 }}>
          <DesktopIcon
            title="Desktop View"
            onClick={() => setIsMobileView(false)}
          />
          <Slider
            ml={spacing.l}
            onChange={() => setIsMobileView(!isMobileView)}
            isChecked={isMobileView}
          />
          <MobileIcon
            title="Mobile View"
            onClick={() => setIsMobileView(true)}
          />
        </$FlexBox>
        <ImportIcon title="Import Template" onClick={onImportTemplate} />
        <SaveTemplateIcon title="Save Template" onClick={onSaveTemplate} />
        <UndoIcon
          disabled={!canUndo}
          onClick={() => actions.history.undo()}
          title="Undo"
        />
        <RedoIcon
          disabled={!canRedo}
          onClick={() => actions.history.redo()}
          title="Redo"
        />
        {document.fullscreenElement?.id === 'myEditor' ? (
          <FullScreenExitIcon
            title="Exit Fullscreen"
            onClick={closeFullscreen}
          />
        ) : (
          <FullScreenIcon title="View Fullscreen" onClick={openFullscreen} />
        )}
        <PreviewIcon title="Preview" onClick={onPreview} />
      </$FlexBox>
    </$EditorHeader>
  )
}
