import React from 'react'

import { Button } from 'components/Common'
import { ExportReportIcon } from 'assets/icons'

export const ExportReportButton = ({ onClick }) => {
  return (
    <Button
      color="#33475B"
      variant="primaryOutline"
      startIcon={ExportReportIcon}
      borderColor="#33475B"
      onClick={onClick}
    >
      Export Report
    </Button>
  )
}
