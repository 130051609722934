import React, { useState } from 'react'
import { Div, FlexBox } from 'styledComponent'
import { Text } from 'components/Common'
import { $PublishStatus } from '../AutoEngagement.styled'
import { ExportReportButton } from './ExportReportButton'
import { SendEmailReport } from './SendEmailReport'

export const PublishItem = ({
  width,
  header,
  children,
  headerType = 'h3',
  showExport,
  isError = false,
  ...rest
}) => {
  const [isPopOpen, setIsPopOpen] = useState(false)
  return (
    <Div width={width}>
      <FlexBox gap="10px" align="center" justify="space-between">
        <FlexBox gap="10px" align="center" {...rest}>
          <$PublishStatus
            isError={isError}
            completed={header?.includes('tatistics')}
          />
          <Text variant={headerType} type="main" weight="bold">
            {header}
          </Text>
        </FlexBox>
        {showExport && (
          <ExportReportButton onClick={() => setIsPopOpen(true)} />
        )}
      </FlexBox>
      <Div ml="24px">{children}</Div>
      <SendEmailReport
        isJourney={showExport === 'journeyExport'}
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
      />
    </Div>
  )
}
