import React from 'react'
import { ROOT_NODE, useNode } from '@craftjs/core'

import { ContainerSettings } from './ContainerSettings'

export const Container = ({ children, ...props }) => {
  const {
    id,
    connectors: { connect },
  } = useNode()

  const {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  const borderRadius = props.allSidesBorderRadius
    ? props.borderRadius
    : `${borderTopLeftRadius || 0}px ${borderTopRightRadius || 0}px ${
        borderBottomRightRadius || 0
      }px ${borderBottomLeftRadius || 0}px`

  return (
    <div
      style={{
        ...props,
        padding,
        borderRadius,
        maxWidth: id === ROOT_NODE ? '640px' : '100%',
      }}
      ref={(ref) => connect(ref)}
    >
      {children}
    </div>
  )
}

export const ContainerDefaultProps = {
  margin: '0 auto',
  overflow: 'auto',
  display: 'block',
  width: '100%',
  height: '100px',
  textAlign: 'center',
  borderWidth: '0px',
  borderStyle: 'dashed',
  borderColor: '#888888',
  boxSizing: 'border-box',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  fontFamily: 'Arial, Helvetica, sans-serif',
}

Container.craft = {
  props: ContainerDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
}
