import React from 'react'

import { useVendorSelect, useSelectStore } from 'hooks'
import Navbar from 'components/Layout/Navbar'
import Tabs from 'components/Common/Tabs'
import CRM from './CRM'
import ProductAnalytics from './ProductAnalytics'
import { $BodyStyles } from './Settings.styled'

const Settings = () => {
  return (
    <>
      <Navbar heading="Settings" />

      <Tabs>
        {/* <div label="Consumer Analytics">
          <ConsumerAnalytics />
        </div> */}
        <div label="Product Analytics">
          <ProductAnalytics />
        </div>
        <div label="Consumer Profiling">
          <CRM />
        </div>
      </Tabs>
      <$BodyStyles />
    </>
  )
}

export default Settings
