import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled, { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import { Text } from 'components/Common/Text'
import { spacing } from 'design-system'
import { PublishItem } from '../components/PublishItem'
import { $BlueBox, $BillContainer } from '../AutoEngagement.styled'
import { YoutubeComponent } from '../components/BuilderComponents'
import { UploadBox } from '../components/MediaUpload'
import { DetailRow } from '../components/CampaignPreview'

import CloseButtonRed from 'assets/icons/close-button-red.svg'
import {
  AutoTurnOnOffPreview,
  CampaignStats,
} from '../components/CampaignPreview'
import { ColorBlockWithValue } from '../components/BuilderComponents'

const $PreviewButton = styled.button`
  border: none;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  color: ${({ txtColor }) => txtColor || '#3B86FF'};
  border-radius: 4px;
  padding: 4px 16px;
`

const AddButtonPreview = ({ form }) => {
  if (form?.addButton)
    return (
      <div style={{ textAlign: 'center' }}>
        <$PreviewButton
          type="button"
          bgColor={form?.content?.setting?.btnDetails?.bgColor}
          txtColor={form?.content?.setting?.btnDetails?.txtColor}
        >
          {form?.content?.setting?.btnDetails?.txt || 'Button'}
        </$PreviewButton>
      </div>
    )

  return null
}

export const PreviewPane = ({ form }) => {
  const assetsDetails = form?.content?.body?.assetsDetails || []
  const couponData = useSelector((state) => state.autoEngage.couponData)
  return (
    <$BlueBox>
      <Text variant="p" type="main" weight="bold" mb={spacing.l}>
        Preview Pane
      </Text>
      <$BillContainer>
        <img
          src={CloseButtonRed}
          alt="Close button in red color"
          width="25px"
          style={{
            float: 'right',
            paddingBottom: '16px',
            alignSelf: 'flex-end',
          }}
        />
        {form?.content?.body?.componentType === 'youtube' ? (
          <YoutubeComponent
            value={
              assetsDetails[0]?.key || assetsDetails[0]?.fileLocation || ''
            }
          />
        ) : (
          assetsDetails?.map((item, i) => (
            <Div my={spacing.m} key={item?.key}>
              <UploadBox
                hideBorder
                uploadWidth="100%"
                uploadHeight="auto"
                src={item?.fileLocation}
                fileType={form?.content?.body?.componentType}
                couponPosition={form?.content?.body?.couponPosition}
                couponId={
                  item?.couponVisibility && form?.content?.body?.couponId
                }
                whiteBackground
                couponPreview={
                  form?.content?.body?.couponId
                    ? couponData.docs.filter(
                        (doc) => doc._id === form?.content?.body?.couponId
                      )
                    : []
                }
              />
            </Div>
          ))
        )}
        <AddButtonPreview form={form} />
      </$BillContainer>
    </$BlueBox>
  )
}

export const PopupOverPreviewAndPublish = ({ form, hideSteps }) => {
  const couponData = useSelector((state) => state.autoEngage.couponData)
  const couponSelected = form?.content?.body?.couponId
    ? couponData.docs.filter((doc) => doc._id === form?.content?.body?.couponId)
    : []

  const { spacing } = useTheme()

  return (
    <>
      <FlexBox>
        <Div flexBasis="70%" mr={spacing.xl}>
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            {form?.status === 'draft' ? 'Preview & Publish' : 'View Campaign'}
          </Text>
          <CampaignStats form={form} campaignType="Popup Over Bill" />
          <PublishItem
            width="48%"
            header="Campaign Details"
            headerType="h3"
            mt={spacing.l}
            mb={spacing.m}
          >
            <FlexBox>
              <DetailRow label="Campaign Name:" value={form?.name} mr="64px" />
              <DetailRow
                label="Created On:"
                value={moment(form?.createdDate).format('DD/MM/YYYY')}
              />
            </FlexBox>
            <DetailRow
              label="Campaign Description:"
              value={form?.description}
            />
          </PublishItem>
          <hr />
          <PublishItem
            width="100%"
            header="Popup Details"
            headerType="h3"
            mt={spacing.l}
            mb={spacing.m}
          >
            <DetailRow
              label="Popup Type:"
              value={form?.content?.body?.componentType}
            />
            <DetailRow
              label="Redirect Link:"
              value={form?.content?.body?.assetsDetails[0]?.redirectUrl || '-'}
              isLink
            />
            <DetailRow
              label="Coupon Name:"
              value={
                couponSelected?.[0]?.name ? couponSelected?.[0]?.name : null
              }
            />
            <DetailRow
              label="Coupon Position:"
              value={form?.content?.body?.couponPosition}
            />

            <FlexBox gap="44px" padding="8px 0px" expand>
              <DetailRow
                label="Coupon Font:"
                value={form?.content?.body?.couponFontTypeface || '-'}
              />
              <DetailRow
                label="Coupon Font Color:"
                value={
                  form?.content?.body?.couponFontColor ? (
                    <ColorBlockWithValue
                      color={form?.content?.body?.couponFontColor}
                    />
                  ) : (
                    '-'
                  )
                }
              />
              <DetailRow
                label="Coupon Size:"
                value={
                  form?.content?.body?.couponFontSize
                    ? `${form?.content?.body?.couponFontSize}px`
                    : '-'
                }
              />
            </FlexBox>
          </PublishItem>
          <hr />
          <PublishItem
            width="48%"
            header="Popup Settings"
            headerType="h3"
            mt={spacing.l}
            mb={spacing.m}
          >
            <DetailRow
              label="Show Popup:"
              value={
                form?.content?.setting?.visibility == 0
                  ? 'Immediately'
                  : `After ${form?.content?.setting?.visibility}s`
              }
            />
            <FlexBox>
              <DetailRow
                label="Add Button:"
                mr={spacing.l}
                value={form?.content?.setting?.btnDetails?.link ? 'Yes' : 'No'}
              />
              <DetailRow
                label="Button URL:"
                value={form?.content?.setting?.btnDetails?.link}
                isLink
              />
            </FlexBox>
          </PublishItem>
          <hr />
          <AutoTurnOnOffPreview form={form} hideSteps={hideSteps} />
        </Div>
        <Div flexBasis="30%">
          <PreviewPane form={form} />
        </Div>
      </FlexBox>
    </>
  )
}
