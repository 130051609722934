import React, { useState } from 'react'
import { Input, PopupContainer, useToasts } from 'components/Common'
import { isURLV2 } from 'utils/urlValidator'

export const CustomAttributeModal = ({
  isPopOpen,
  setIsPopOpen,
  onCustomChange,
  type = '',
}) => {
  const { toast } = useToasts()

  const [custom, setCustom] = useState('')
  const [validationError, setValidationError] = useState(false)

  const onSave = () => {
    if (!custom) {
      toast('Please enter your custom input.', 'error')
      throw ''
    }
    const isUrl = isURLV2(custom)
    if (type === 'sms') {
      // check if custom is only a url and doesnt have any other text
      if (!isUrl) {
        if (custom.includes('https') || custom.includes('http')) {
          setValidationError('Invalid URL')
          return
        }
        if (custom.length > 30) {
          setValidationError('Max 30 chars allowed')
          return
        }
      }
    }
    if (type === 'sms' && isUrl) {
      onCustomChange(custom, 'custom', 'disclaimerPopup')
    } else {
      setIsPopOpen(false)
      onCustomChange(custom, 'custom')
    }
    setCustom('')
    setValidationError(false)
  }

  const onCancel = () => {
    setValidationError(false)
    setIsPopOpen(false)
    setCustom('')
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Custom Input"
      onCancel={onCancel}
      onSubmitClick={onSave}
      disableAutoCloseOnSubmit={type === 'sms'}
    >
      <Input
        containerMargin="0"
        variant="input"
        label="Custom Input"
        placeholder="Customer"
        inputWidth="100%"
        value={custom}
        maxLength={type === 'sms' ? undefined : 30}
        onChange={(e) => {
          setValidationError(false)
          setCustom(e.target.value)
        }}
        validationError={validationError}
        errorBelow={type === 'sms'}
      />
    </PopupContainer>
  )
}
