import styled from 'styled-components'

import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'

const $TagBlock = styled(FlexBox)`
  max-width: 80px;
  height: 30px;
  padding: ${spacing.m};
  background-color: #f2f7ff;
  margin: ${({ margin }) => margin || 'unset'};
  border: 1px solid #98c1f9;
  border-top-left-radius: ${({ roundedCorners }) =>
    roundedCorners ? '5px' : '25px'};
  border-top-right-radius: ${({ roundedCorners }) =>
    roundedCorners ? '5px' : '25px'};
  border-bottom-left-radius: ${({ roundedCorners }) =>
    roundedCorners ? '5px' : '25px'};
  border-bottom-right-radius: ${({ roundedCorners }) =>
    roundedCorners ? '5px' : '25px'};
`

const $Clear = styled.span`
  cursor: pointer;
  svg {
    width: 16px;
  }
`

export { $TagBlock, $Clear }
