import React, { Component } from 'react'
import BillListCard from './BillsListCard'
import { connect } from 'react-redux'
import BillsPopup from '../billView/BillsPopup'
import { saveAs } from 'file-saver'
import swal from 'sweetalert'

//import actions here
import { getBillUrl } from '../../../../redux/actions/bills'
import { CheckBox } from '../../../../styledComponent/components/input'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '../../../../styledComponent'
import { Skeleton } from '../../../Common'
import { PaginationWrapper } from '../style'
import {
  cinepolisVendorId,
  nykaaVendorId,
  mirajVendorId,
  connplexVendorId,
  nyCinemasVendorId,
  bhutaniCineplex,
  decathVendorIds,
  rajhansId,
  trentVendorId,
  movieMaxVendorId,
} from 'components/conditionalComponent'
import { userTypes } from 'components/Layout/constants'

class BillsList extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      isAllSelected: false,
      billsPopup: {
        status: false,
        data: {},
      },
      // selectedBills: [],
      rowsPerPage: 10,
      offsetTop: 0,
      offsetLeft: 0,
      columns: [],
    }
    this.handleBillPopup = this.handleBillPopup.bind(this)
    this.handleBillDownload = this.handleBillDownload.bind(this)
  }

  handleBillPopup(data) {
    this.setState({
      billsPopup: {
        status: !this.state.billsPopup.status,
        data: data,
      },
    })
  }

  async handleBillDownload(key) {
    let response = await getBillUrl({ fileKey: key })
    if (response.code) {
      saveAs(response.data)
    } else swal('Missing', response.message)
  }
  tableColumns = () => {
    const columns = [
      'Store Details',
      'Store Code',
      'Email',
      'Contact No.',
      'Invoice No.',
      'Amount',
      'Date/Time',
      'Invoice Date/Time',
      'Transaction',
      'Actions',
    ]
    //this.props.vendorId === nykaaVendorId && columns.unshift('Customer ID')
    if (
      [
        cinepolisVendorId,
        mirajVendorId,
        connplexVendorId,
        nyCinemasVendorId,
        bhutaniCineplex,
        rajhansId,
        movieMaxVendorId,
      ].includes(this.props.vendorId)
    ) {
      columns.splice(2, 0, 'Bill Type')
      columns.splice(6, 0, 'Booking ID')
    }
    if (['1kpmjfjkkgt020t', '32la51wrkqta2oue'].includes(this.props.vendorId))
      columns.splice(columns.indexOf('Transaction') + 1, 0, 'Checkout', 'Read')
    if (
      this.props.userType !== userTypes.SECURITY &&
      this.props.vendorId !== trentVendorId
    )
      columns.splice(columns.indexOf('Actions'), 0, 'Delivered', 'Read')
    if (
      ['4gb0i29jkl7lk5hb', 'hr5pq1kfk5qccf7x'].includes(this.props.vendorId) &&
      this.props.userType === userTypes.SECURITY
    ) {
      columns.splice(columns.indexOf('Store Details'), 1, 'Customer Name')
      columns.splice(columns.indexOf('Store Code'), 1, 'Total Quantity')
      columns.splice(columns.indexOf('Date/Time'), 1)
      columns.splice(columns.indexOf('Transaction'), 1)
    }

    if (this.props.vendorId === '2gtfxjl74ip6od') {
      columns.splice(columns.indexOf('Invoice No.') + 1, 0, 'Transaction Id')
    }

    //nykaa
    if (['5c7hzqknstqhza', '357nfjkonyq6s0'].includes(this.props.vendorId)) {
      columns.splice(columns.indexOf('Actions'), 0, 'Read')
    }

    // decath
    if (decathVendorIds.includes(this.props.vendorId)) {
      columns.splice(columns.indexOf('Transaction'), 0, 'Cashier Name')
    }

    this.setState({ columns })
  }
  componentDidMount() {
    this.tableColumns()
  }
  render() {
    const selectedBillsCount = this.props.selectedBillsCount
    return (
      <>
        <Table>
          <TableHead>
            <TableRow fontSize="14px">
              {this.props.isDeleteActive && (
                <TableCell width="20px" textAlign="center">
                  <CheckBox
                    checked={
                      selectedBillsCount ===
                      (this.props.billsData && this.props.billsData.length)
                    }
                    onClick={() => {
                      this.props.handleBillSelection('all')
                    }}
                    id={`select-all`}
                  />
                </TableCell>
              )}
              {this.state.columns.map((x, i) => (
                <TableCell key={i}>{x}</TableCell>
              ))}
              {/* {this.props.vendorId === nykaaVendorId && (
                <TableCell>Customer ID</TableCell>
              )}
              <TableCell>Store Details</TableCell>
              {/* <TableCell>Source</TableCell> */}
              {/* <TableCell>Store Code</TableCell>
              {this.props.vendorId === cinepolisVendorId && (
                <TableCell>Bill Type</TableCell>
              )}
              <TableCell>Email</TableCell>
              <TableCell>Contact No.</TableCell>
              <TableCell>Invoice No.</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Invoice Date/Time</TableCell>
              <TableCell>Transaction</TableCell>
              {['1kpmjfjkkgt020t', '32la51wrkqta2oue'].includes(
                this.props.vendorId
              ) ? (
                <>
                  <TableCell>Checkout</TableCell>
                  <TableCell>Read</TableCell>
                </>
              ) : null}
              {[
                '3qp6rkl1kj2b86',
                '4corql28y0sda',
                '7mfv4ll1lpec9a',
                'nygrw1khk6j78bq2',
                '4gb0i29jkl7lk5hb',
                'g18aoekl2udy21m',
              ].includes(this.props.vendorId) ? (
                <>
                  <TableCell>Delivered</TableCell>
                  <TableCell>Read</TableCell>
                </>
              ) : null}
              <TableCell textAlign="center">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <tbody>
            {!this.props.loading &&
              this.props.billsData &&
              this.props.billsData.length > 0 &&
              this.props.billsData.map((data, i) => {
                return (
                  <BillListCard
                    deleteBills={this.props.deleteBills}
                    allSelected={this.state.isAllSelected}
                    billData={data}
                    onBillView={this.handleBillPopup}
                    onDownloadBill={this.handleBillDownload}
                    selectedBills={this.props.selectedBills}
                    onBillSelect={this.props.handleBillSelection}
                    selectedBillsCount={selectedBillsCount}
                    key={i}
                    isDeleteActive={this.props.isDeleteActive}
                    hideActionButtons={this.props.hideActionButtons}
                    selectedVendor={this.props.vendorId}
                    isCentroCashier={this.props.isCentroCashier}
                  />
                )
              })}
          </tbody>
        </Table>
        {this.props.loading && <Skeleton height={70} count={10} />}
        {this.props.billsData &&
        ((this.props.page === 0 && this.props.billsData.length > 0) ||
          this.props.page > 0) ? (
          <PaginationWrapper>
            <ul className="feedback-pagination notSelectableText">
              {this.props.page > 0 && (
                <li
                  className={`${!this.props.page ? 'disabled' : ''} previous`}
                  onClick={() =>
                    this.props.onPageChange(!this.props.page ? null : -1)
                  }
                >
                  <a tabIndex="0" role="button" aria-disabled="false">
                    &lt; Previous
                  </a>
                </li>
              )}
              {this.props.billsData.length === 10 && (
                <li
                  className={`${
                    !this.props.billsData.length ? 'disabled' : ''
                  } next`}
                  onClick={() =>
                    this.props.onPageChange(
                      !this.props.billsData.length ? null : 1
                    )
                  }
                >
                  <a tabIndex="1" role="button" aria-disabled="false">
                    Next &gt;
                  </a>
                </li>
              )}
            </ul>
          </PaginationWrapper>
        ) : (
          <>
            {!this.props.loading &&
              this.props.billsData &&
              this.props.billsData.length === 0 && (
                <div className="no-bills">No Bills Found.</div>
              )}
          </>
        )}
        {!!this.state.billsPopup.status && (
          <BillsPopup
            billData={this.state.billsPopup.data}
            isPopOpen={this.state.billsPopup.status}
            setIsPopOpen={() =>
              this.setState({
                billsPopup: {
                  status: !this.state.billsPopup.status,
                },
              })
            }
          />
        )}

        {/* selectedBillsCount ? (
					<div className="billsList_selectedBills_info animated fadeInUp">
						<div>{selectedBillsCount} Bills Selected</div>
						<div
							className="billsList_selectedBills_info_trash"
						>
							<i className="fas fa-trash"></i> Delete
						</div>
					</div>
				) : null */}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  cAnalytics: state.cAnalytics,
  errors: state.errors,
  vendorId: state.vendorIds.selectedVendor.value,
  userType: state.auth.user.userType,
})

export default connect(mapStateToProps)(BillsList)
