import styled, { css } from 'styled-components'
import { FlexBox } from './elements'
import { color } from 'design-system'

const Box = styled(FlexBox)`
  width: 100%;
  padding: ${(props) =>
    ({
      tbPadding: '10px 0px',
      default: '20px',
      custom: props.customPadding,
    }[props.padding || 'default'])};
  background-color: ${color.white};
  box-shadow: ${(props) =>
    props.boxShadow ? '0 4px 18px 0px #ebebeb' : 'none'};
  font-size: ${(props) => props.fontSize || 'medium'};
  position: relative;
  margin-top: ${(props) => props.mt || ''};
  cursor: ${(props) => props.cursor || `default`};
`

const RoundBox = styled(Box)`
  border-radius: 8px;
`

const BoxContent = styled.div`
  height: ${(props) => props['height'] || `100%`};
  max-height: ${(props) => props['maxHeight']};
  box-shadow: ${(props) => props['boxShadow']};
  width: ${(props) => props['width'] || `100%`};
`

export { Box, RoundBox, BoxContent }
