import { post } from '../../utils/axiosHandler'
import { getProcessedFilters } from '../../utils/feedback'
import { getTransformedValues } from '../../utils/filter'

export const getConsumerDataTabs = (data, options) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const processedFilters = getProcessedFilters(state.cAnalytics.filters || {})
  const filters = getTransformedValues(processedFilters)

  const res = await post(
    '/cAnalytics/allConsumerData',
    { ...data, ...filters },
    options
  )

  if (res.code) {
    dispatch({
      type: 'SET_CONSUMER_NUMBERS',
      payload: res.data,
    })
    return res
  }
}

export const getConsumerData = (data, options) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const processedFilters = getProcessedFilters(
    state.cAnalyticsOld.filters || {}
  )
  const filters = getTransformedValues(processedFilters)
  const query = {
    ...data,
    ...filters,
    groupBy: processedFilters?.groupBy?.value || 'user',
  }
  delete query.emailList
  const res = await post('/cAnalytics/customerData', query, options)

  // If first call, then store fetched data, or else append to existing
  const fetchedData =
    data.fetchCount === data.domainLength
      ? res.data
      : [...state.cAnalyticsOld.data, ...res.data]

  if (res.code) {
    dispatch({
      type: 'SET_CONSUMER_DATA',
      payload: fetchedData,
    })
    return res
  }
}

//Getting all consumer analytics data
export const getTotalNumbersCollected = (data) => async (dispatch) => {
  try {
    const result = await post('/cAnalytics/totalNumbers', data)
    if (result.code) dispatch(getTotalNumbersCollectedHelper(result))
  } catch (e) {
    console.log('Error')
  }
}

//Getting all consumer analytics data helper
export const getTotalNumbersCollectedHelper = (data) => {
  return {
    type: 'GET_CONSUMER_ANALYTICS_NUMBERS_COLLECTED',
    payload: data,
  }
}

export const setConsumerAnalyticsFilters = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_CONSUMER_ANALYTICS_FILTERS',
    payload: data,
  })
}

export const setConsumerAnalyticsRememberFilters = (data) => async (
  dispatch
) => {
  dispatch({
    type: 'SET_CONSUMER_ANALYTICS_REMEMBER_FILTERS',
    payload: data,
  })
}

export const exportConsumerAnalytics = (data) => {
  const { includeFilter, filters: exportFilters } = data
  let filters = {}
  if (includeFilter) {
    const processedFilters = getProcessedFilters(exportFilters || {})

    filters = {
      ...getTransformedValues(processedFilters),
      groupBy: processedFilters?.groupBy?.value || 'user',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    if (processedFilters.emailList)
      filters.emailList = [...processedFilters.emailList.value]
  }

  return post('/cAnalytics/allNumbersPopupFile', filters)
}
