import styled, { css } from 'styled-components'

export const Text = styled.div`
  color: ${(props) => props.color || props.theme.colors.neutral['900']};
  font-weight: ${(props) => (props.bold ? 'bold' : '400')};

  ${(props) =>
    props.variant === 'tiny' &&
    css`
      font-size: 0.65rem;
    `}

  ${(props) =>
    props.variant === 'caption' &&
    css`
      font-size: 0.75rem;
    `}

        ${(props) =>
    props.variant === 'body1' &&
    css`
      font-size: 1rem;
      line-height: 1.5;
    `}

        ${(props) =>
    props.variant === 'body2' &&
    css`
      font-size: 0.875rem;
      line-height: 1.43;
    `}

        ${(props) =>
    props.variant === 'subtitle1' &&
    css`
      font-size: 1rem;
      line-height: 1.75;
    `}

        ${(props) =>
    props.variant === 'subtitle1' &&
    css`
      font-size: 0.875rem;
      line-height: 1.57;
    `}
        
    &&& {
    ${(props) =>
      props.variant === 'h6' &&
      css`
        font-size: 1.25rem;
      `}

    ${(props) =>
      props.variant === 'h5' &&
      css`
        font-size: 1.5rem;
      `}

        ${(props) =>
      props.variant === 'h4' &&
      css`
        display: block;
        font-size: 1em;
        margin: 1.33em 0;
      `}

        ${(props) =>
      props.variant === 'h3' &&
      css`
        font-size: 3rem;
        line-height: 1.167;
        margin-bottom: -1rem;
      `}

        ${(props) =>
      props.variant === 'h2' &&
      css`
        font-size: 2em;
        margin: 0.67em 0;
        margin-bottom: -1em;
      `}
        
        ${(props) =>
      props.variant === 'h1' &&
      css`
        font-size: 2em;
        margin: 0.67em 0;
        margin-bottom: -1em;
      `}
  }

  text-align: ${(props) => props['text-align'] || 'left'};
  width: ${({ width }) => width || 'unset'};
  margin-right: ${({ mr }) => mr || 'unset'};
  margin-left: ${({ ml }) => ml || 'unset'};
  margin-top: ${({ mt }) => mt || 'inherit'};
  margin-bottom: ${({ mb }) => mb || 'inherit'};
`

export const GreyText = styled(Text)`
  &&& {
    color: ${(props) => props.theme.colors.neutral['700']};
    font-size: ${(props) =>
      ({
        nano: '8px',
        tiny: '10px',
        small: '12px',
        medium: '16px',
        big: '20px',
        large: '24px',
        huge: '30px',
        custom: props.customFontSize,
      }[props.fontSize || 'default'])};
    margin: ${(props) => props.margin || ''};
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  }
`

export const ErrorMessage = styled.p`
  display: ${(props) => (props.error ? 'inline-block' : 'none')};
  color: ${(props) => props.errorColor || 'red'};
  font-size: 14px;
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`

export const CenterText = styled.p`
  text-align: center;
`

export const WhiteText = styled(Text)`
  color: ${(props) => props.theme.colors.neutral['100']};
  font-size: ${(props) =>
    ({
      nano: '8px',
      tiny: '10px',
      small: '12px',
      medium: '16px',
      big: '20px',
      large: '24px',
      huge: '3em',
      enormous: '4em',
      custom: props.customFontSize,
    }[props.fontSize])};
  transition: all 0.3s;
  border: none;
  background-color: transparent;
  text-decoration: none;
`
