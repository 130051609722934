import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import styled, { useTheme } from 'styled-components'

import { useStoreFetch, useSelectStore } from 'hooks'
import {
  getTopCustomers,
  setFetchNewData,
  setCAPreviousSelected,
} from 'redux/actions/consumerAnalyticsActions'
import { SectionHeading, Skeleton } from 'components/Common'
import { borderRadius, spacing } from 'design-system'
import { ALL_CUSTOMER_LIST_TEXT, TOP_COSTUMER_COLS } from '../constants'
import { Table, TableHead, TableRow, TableCell, FlexBox } from 'styledComponent'

const AllCustomersList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { data: selectedDate } = useSelectStore('cAnalytics.selectedDate')
  const { data: selectedStores } = useSelectStore('stores.selectedStores')
  const { data: shouldFetchNewData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewData'
  )
  const fetchAction = () => getTopCustomers(null, { loader: true })
  const {
    data: topCustomers,
    loading,
    fetchData,
  } = useStoreFetch(fetchAction, 'cAnalytics.topCustomers', true)

  // TODO: Refactor this later
  // useFetchDataOnSelectedChange(fetchData, { selectedStores })

  React.useEffect(() => {
    const fetchDataAndCheckPreviousSelected = async (_) => {
      if (shouldFetchNewData) {
        fetchData()
        await dispatch(setFetchNewData(false))
      }

      await dispatch(setCAPreviousSelected({ selectedStores }))
    }

    fetchDataAndCheckPreviousSelected()
  }, [selectedStores, shouldFetchNewData])

  const rowClick = () => {
    history.push({
      pathname: '/consumer-analytics/customers',
    })
  }

  return (
    <>
      <FlexBox justify="space-between" align="center">
        <SectionHeading
          {...ALL_CUSTOMER_LIST_TEXT}
          variant="medium"
          subVariant="small"
          color="main"
          weight="bold"
        />
        <$ManageLink to="/consumer-analytics/customers" title="Manage">
          Manage
        </$ManageLink>
      </FlexBox>
      {loading ? (
        <Skeleton count={10} height="35px" />
      ) : (
        <CustomerTable
          rows={topCustomers?.data}
          cols={TOP_COSTUMER_COLS}
          onRowClick={rowClick}
        />
      )}
    </>
  )
}

export default AllCustomersList

const CustomerTable = ({ rows, cols, onRowClick }) => {
  if (!rows || !cols) return null
  const THead = () => (
    <StyledTableHead>
      <TableRow>
        {cols.map(({ value, label }) => (
          <StyledTableCell key={value} head>
            {label}
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  )

  const Row = ({ data }) => {
    const {
      data: { currencyCode },
    } = useSelectStore('auth.user')
    const theme = useTheme()
    return (
      <StyledTableRow
        onClick={onRowClick}
        highlightOnHover={theme.colors.solitude}
        highlightTextOnHover={theme.colors.primary.default}
      >
        <>
          {cols.map((column, index) => (
            <StyledTableCell key={index}>
              {`${column.value === '_id' ? '+91-' : ''}`}
              {column.value === 'amount' ? (
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={data[column.value]}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              ) : (
                data[column.value]
              )}
            </StyledTableCell>
          ))}
        </>
      </StyledTableRow>
    )
  }

  const TBody = () => {
    return (
      <tbody>
        {(rows.length > 10 ? rows.slice(0, 10) : rows).map((row, index) => (
          <Row key={row.id || index} data={row} />
        ))}
      </tbody>
    )
  }

  return (
    <TableContainer>
      <Table>
        <THead />
        <TBody />
      </Table>
      {rows && (
        <ViewMore>
          <Link to="/consumer-analytics/customers" title="View more">
            View more
          </Link>
        </ViewMore>
      )}
    </TableContainer>
  )
}

const $ManageLink = styled(Link)`
  padding: ${spacing.s} ${spacing.l};
  border-radius: ${borderRadius.small};
  color: ${({ theme }) => theme.colors.pickledBluewood};
  border: 1px solid ${({ theme }) => theme.colors.light};
  height: 28px;
  font-size: 12px;
  transition: border-color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.pickledBluewood};
    text-decoration: none;
    font-weight: bold;
    border-color: ${({ theme }) => theme.colors.pickledBluewood};
  }
`

const TableContainer = styled.div`
  margin-top: ${spacing.l};
`

const ViewMore = styled.div`
  padding-top: ${spacing.l};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-size: 12px;
`

const StyledTableHead = styled(TableHead)`
  background: none;
`

const StyledTableCell = styled(TableCell)`
  font-weight: ${(props) => (props.head ? '900 !important' : '400')};
  padding: 14px 15px;
  font-size: ${(props) => (props.head ? '12px' : '14px')};
  text-align: center;
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`
