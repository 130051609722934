import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Document, pdfjs, Page } from 'react-pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Container = createGlobalStyle`
    .react-pdf__Document{
        overflow: ${(props) => props.overflow || 'hidden'};
    }
`

export const Pdf = ({ url, overflow }) => {
  const [totalPages, setTotalPages] = React.useState()

  const pdfLoaded = ({ numPages }) => {
    setTotalPages(numPages)
  }

  return (
    <>
      <Container overflow={overflow} />
      <Document file={url} onLoadSuccess={pdfLoaded}>
        {[...new Array(totalPages).fill(0)].map((item, i) => (
          <Page key={i + 'pdf'} pageIndex={i} width={380} />
        ))}
      </Document>
    </>
  )
}
