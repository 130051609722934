import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { Text, Checkbox, Tooltip, Input } from 'components/Common'
import UploadModal from '../../components/UploadModal'
import { Div, FlexBox } from 'styledComponent'
import { AddBlueIcon, CloseIcon } from 'assets/icons'
import { $BlueBox, $SurveyPageImage } from '../../AutoEngagement.styled'
import {
  FONT_FAMILY_OPTIONS,
  FONT_WEIGHT_OPTIONS,
} from 'builder/editor/constants'
import { GET_SEL_VALUE, SURVEY_BUTTON_TYPES } from '../../constants'
import { $Submit } from 'builder/survey-builder/Survey.styled'

export const SurveyAdditionalSettings = ({ form, setForm }) => {
  const { spacing } = useTheme()

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [buttonType, setButtonType] = useState(
    !!(
      form?.content?.buttonConfig?.textColor ||
      form?.content?.buttonConfig?.bgColor
    )
  )

  const setValues = (key, value) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        [key]: value,
      },
    })
  }

  const configButton = (key, value) => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        buttonConfig: {
          ...form?.content?.buttonConfig,
          [key]: value,
        },
      },
    })
  }

  const onChooseButton = (bool) => {
    setButtonType(bool)
    if (!bool) {
      setForm({
        ...form,
        content: {
          ...form?.content,
          buttonConfig: {
            textColor: null,
            bgColor: null,
          },
        },
      })
    }
  }

  const setMedia = ({ fileLocation }) => {
    setValues(isPopOpen, fileLocation)
    setIsPopOpen(false)
  }

  return (
    <>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        Survey Additional Settings
      </Text>
      <Div>
        <Checkbox
          isChecked={!!form?.content?.welcomeImage}
          mb={spacing.l}
          id="welcomeImage"
          onCheckboxChange={() => setIsPopOpen('welcomeImage')}
        >
          <FlexBox gap={spacing.m}>
            <Text type="main" variant="medium">
              Add Welcome Page
            </Text>
            <Tooltip content="This image will be displayed to the the customer before the survey questions begin." />
          </FlexBox>
        </Checkbox>
        <$SurveyPageImage>
          {form?.content?.welcomeImage ? (
            <span>
              <img src={form?.content?.welcomeImage} />
              <CloseIcon
                type="button"
                onClick={() => setValues('welcomeImage', null)}
              />
            </span>
          ) : (
            <Div type="button" onClick={() => setIsPopOpen('welcomeImage')}>
              <AddBlueIcon /> &nbsp; Add Welcome Image here
              <Text align="center" variant="small" type="grey">
                (Image Dimensions - Free Size)
              </Text>
            </Div>
          )}
        </$SurveyPageImage>
      </Div>
      <Div>
        <Checkbox
          isChecked={!!form?.content?.exitImage}
          my={spacing.l}
          id="exitImage"
          onCheckboxChange={() => setIsPopOpen('exitImage')}
        >
          <FlexBox gap={spacing.m}>
            <Text type="main" variant="medium">
              Add Exit Page
            </Text>
            <Tooltip content="This image will be displayed to the customer after they have completed the survey." />
          </FlexBox>
        </Checkbox>
        <$SurveyPageImage>
          {form?.content?.exitImage ? (
            <span>
              <img src={form?.content?.exitImage} />
              <CloseIcon
                type="button"
                onClick={() => setValues('exitImage', null)}
              />
            </span>
          ) : (
            <Div type="button" onClick={() => setIsPopOpen('exitImage')}>
              <AddBlueIcon /> &nbsp; Add Exit Image here
              <Text align="center" variant="small" type="grey">
                (Image Dimensions - Free Size)
              </Text>
            </Div>
          )}
        </$SurveyPageImage>
      </Div>
      <UploadModal
        isPopOpen={!!isPopOpen}
        setIsPopOpen={setIsPopOpen}
        setMedia={setMedia}
        showMedias={['image', 'gif']}
        uploadedFrom="survey"
      />
      <Text type="main" mt={spacing.l} weight="bold">
        Survey Font Settings
      </Text>
      <FlexBox gap={spacing.xl}>
        <Input
          label="Survey Font Type"
          variant="single-select"
          inputWidth="300px"
          placeholder="Select Font Type"
          options={FONT_FAMILY_OPTIONS}
          value={GET_SEL_VALUE(form?.content?.fontFamily, FONT_FAMILY_OPTIONS)}
          onChange={(val) => setValues('fontFamily', val.value)}
        />
        <Input
          label="Survey Font Colour"
          placeholder="Select Font Colour"
          variant="color"
          inputWidth="300px"
          value={form?.content?.fontColor}
          onChange={(color) => setValues('fontColor', color)}
        />
      </FlexBox>
      <FlexBox justify="space-between">
        <Div>
          <Text type="main" mt={spacing.l} weight="bold">
            Survey Button Settings
          </Text>
          <FlexBox gap={spacing.xl}>
            <Div width="300px">
              <Input
                label="Choose Button"
                variant="radio"
                gap={spacing.xl}
                options={SURVEY_BUTTON_TYPES}
                value={buttonType}
                onChange={onChooseButton}
              />
            </Div>
            <Input
              label="Button Font Weight"
              variant="single-select"
              disabled={!buttonType}
              inputWidth="300px"
              placeholder="Select Font Weight"
              options={FONT_WEIGHT_OPTIONS}
              value={GET_SEL_VALUE(
                form?.content?.buttonConfig?.fontWeight,
                FONT_WEIGHT_OPTIONS
              )}
              onChange={(val) => configButton('fontWeight', val.value)}
            />
          </FlexBox>
          <FlexBox gap={spacing.xl}>
            <Input
              disabled={!buttonType}
              label="Button Text Colour"
              placeholder="#000000"
              variant="color"
              inputWidth="300px"
              value={form?.content?.buttonConfig?.textColor || ''}
              onChange={(color) => configButton('textColor', color)}
            />
            <Input
              disabled={!buttonType}
              label="Button Colour"
              placeholder="#000000"
              variant="color"
              inputWidth="300px"
              value={form?.content?.buttonConfig?.bgColor || ''}
              onChange={(color) => configButton('bgColor', color)}
            />
          </FlexBox>
        </Div>
        <$BlueBox>
          <Text type="main" mb={spacing.l} weight="bold">
            Preview Pane
          </Text>
          <FlexBox justify="center" align="center" direction="column">
            <FlexBox justify="center" align="center" gap="20px">
              <$Submit
                bgColor={form?.content?.buttonConfig?.bgColor}
                textColor={form?.content?.buttonConfig?.textColor}
                fontWeight={form?.content?.buttonConfig?.fontWeight}
                fontFamily={form?.content?.fontFamily}
              >
                Previous
              </$Submit>
              <$Submit
                bgColor={form?.content?.buttonConfig?.bgColor}
                textColor={form?.content?.buttonConfig?.textColor}
                fontWeight={form?.content?.buttonConfig?.fontWeight}
                fontFamily={form?.content?.fontFamily}
              >
                Next
              </$Submit>
            </FlexBox>
            <$Submit
              bgColor={form?.content?.buttonConfig?.bgColor}
              textColor={form?.content?.buttonConfig?.textColor}
              fontWeight={form?.content?.buttonConfig?.fontWeight}
              fontFamily={form?.content?.fontFamily}
            >
              Submit
            </$Submit>
          </FlexBox>
        </$BlueBox>
      </FlexBox>
    </>
  )
}
