import styled from 'styled-components'

export const $WhereToAddProduct = styled.ul`
  padding-right: 40px;
  li {
    list-style: none;
    width: fit-content;
    margin: auto;

    button:hover {
      svg {
        path {
          fill: ${(props) => props.theme.colors.dark} !important;
        }
      }
    }
  }
`
