import React from 'react'
import { useNode } from '@craftjs/core'

import { SurveySettings } from './SurveySettings'

export const Survey = (props) => {
  const {
    connectors: { connect },
    isSelected,
  } = useNode((state) => ({
    isSelected: state.events.selected,
  }))

  return (
    <div
      ref={(ref) => connect(ref)}
      style={{ padding: '10px', textAlign: 'center' }}
    >
      <a
        target="_blank"
        rel="noopener"
        href={!isSelected && props.link}
        className={props.surveyName}
      >
        {props.content}
      </a>
    </div>
  )
}

export const SurveyDefaultProps = {
  content: 'Take a survey and win exciting prizes',
  link: '',
  surveyName: '',
}

Survey.craft = {
  props: SurveyDefaultProps,
  related: {
    settings: SurveySettings,
  },
}
