import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import { useRouter } from 'hooks'
import { useTheme } from 'styled-components'

import { Text, useToasts, LoaderPopup } from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import {
  createJourney,
  getJourneyDetails,
  setJBDetails,
  setLoadJourney,
  updateJourney,
  getActions,
  getFilters,
  getEvents,
} from 'redux/actions/journeyActions'
import { $WhiteContainer } from '../../autoEngagement/AutoEngagement.styled'
import { JOURNEY_DETAILS_INIT } from '../constants'
import {
  CHECK_IF,
  DIFF_OBJ,
  ERROR_DECODE,
} from 'components/Content/autoEngagement/constants'
import JourneyBuilder from './journeyBuilder/JourneyBuilder'
import { JourneyDetails } from './JourneyDetails'
import { JourneyAudience } from './JourneyAudience'
import { JourneyPreview } from './JourneyPreview'
import { FlexBox } from 'styledComponent'

const CreateJourney = (props) => {
  const [journeySteps, setJourneySteps] = useState(0)
  const [journeyDetails, setJourneyDetails] = useState(
    cloneDeep(JOURNEY_DETAILS_INIT)
  )

  const { history, pathname } = useRouter()
  const { toast } = useToasts()
  const { spacing } = useTheme()

  const routeId = pathname.split('/')[2]
  const isCreate = routeId === 'create' ? true : false
  const isView = pathname.includes('view')

  useEffect(() => {
    setJourneySteps(isView ? 4 : 1)
    props.setLoadJourney(false)

    if (isCreate) {
      props.setJBDetails({})
    } else {
      props.getJourneyDetails(routeId)
    }
    return () => {
      props.setJBDetails({})
      setJourneyDetails({})
    }
  }, [])

  useEffect(() => {
    if (journeySteps > 2) {
      props.journey.jbActions?.length === 0 && props.getActions()
      props.journey.jbFilters?.length === 0 && props.getFilters()
      props.journey.jbEvents?.length === 0 && props.getEvents()
    }
  }, [journeySteps])

  useEffect(() => {
    if (['live', 'unpublished'].includes(props.journey?.jbDetails?.status)) {
      history.push(`/journey/${props.journey.jbDetails._id}/view`)
      setJourneySteps(4)
    }
    if (props.journey?.jbDetails?._id) {
      setJourneyDetails(cloneDeep(props.journey?.jbDetails))
    }
  }, [props.journey?.jbDetails, journeySteps])

  const onJourneyDetailsSave = async (callBack) => {
    try {
      const body = {
        vendorId: props.vendorId,
        createdBy: props.createdBy,
        companyId: journeyDetails?.companyId,
        name: journeyDetails?.name,
        startDate: journeyDetails?.startDate,
        endDate: journeyDetails?.endDate,
      }

      if (journeyDetails?.description) {
        body.description = journeyDetails.description
      }

      const check = CHECK_IF(
        ['name', 'companyId', 'startDate', 'endDate'],
        body
      )
      if (check && callBack) throw `Please enter ${check}`
      if (check) return

      let res = {}
      if (body) {
        if (isCreate) {
          res = await createJourney(body)
        } else {
          delete body.createdBy
          delete body.companyId
          const filteredBody = DIFF_OBJ(body, props.journey?.jbDetails)
          if (Object.keys(filteredBody).length > 0) {
            res = await updateJourney(routeId, {
              ...filteredBody,
              status: 'draft',
              vendorId: props.vendorId,
              storeUserId: props.createdBy,
              storeUserEmail: props.userEmail,
            })
          }
        }
      }
      if (res?.data) {
        isCreate && callBack && history.push(`/journey/${res.data._id}`)
        props.setJBDetails(res.data)
      }
      if (callBack) callBack()
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      console.error(err)
    }
  }

  const updJourney = async (updatedData, callBack, isPublish = false) => {
    try {
      const filteredBody = DIFF_OBJ(updatedData, props.journey?.jbDetails)
      if (isPublish) {
        filteredBody.eventId = updatedData?.eventId
      }
      if (Object.keys(filteredBody).length > 0) {
        const res = await updateJourney(routeId, {
          vendorId: props.vendorId,
          storeUserId: props.createdBy,
          storeUserEmail: props.userEmail,
          status: isPublish ? 'live' : 'draft',
          ...filteredBody,
        })
        if (res.data) {
          props.setJBDetails(res.data)
          if (isPublish) {
            toast('Journey Campaign is live', 'success')
            history.push('/journey')
          }
        }
      }
      if (callBack) callBack()
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      console.error(err)
    }
  }

  const title = (
    <FlexBox>
      <Text variant="p" weight="semi-bold">
        {props.journey?.jbDetails?.name || 'New Campaign'}
      </Text>
      {!isView && (
        <Text ml={spacing.l} variant="small" type="grey">
          Step {journeySteps} out of 4
        </Text>
      )}
    </FlexBox>
  )

  const getJourneyBody = () => {
    switch (journeySteps) {
      case 1:
        return (
          <JourneyDetails
            title={title}
            isCreate={isCreate}
            onJourneyDetailsSave={onJourneyDetailsSave}
            journeyDetails={cloneDeep(journeyDetails)}
            setJourneyDetails={setJourneyDetails}
            setJourneySteps={setJourneySteps}
          />
        )

      case 2:
        return (
          <JourneyAudience
            title={title}
            updJourney={updJourney}
            journeyDetails={journeyDetails}
            setJourneyDetails={setJourneyDetails}
            setJourneySteps={setJourneySteps}
          />
        )

      case 3: {
        const allEvents = props.journey.jbEvents
        const allFilters = [
          ...props.journey.jbFilters,
          ...props.journey.jbActions,
        ]

        if (allEvents.length < 1 || allFilters.length < 1) return null

        return (
          <JourneyBuilder
            title={title}
            history={history}
            updJourney={updJourney}
            setJourneySteps={setJourneySteps}
            jbDetails={props.journey.jbDetails}
            emailTemplateDetails={props.emailTemplateDetails}
            allEvents={allEvents}
            allFilters={allFilters}
          />
        )
      }

      case 4:
        return (
          <JourneyPreview
            title={title}
            isView={isView}
            updJourney={updJourney}
            setJourneySteps={setJourneySteps}
            jbDetails={props.journey.jbDetails}
            jbStats={props.journey.jbStats}
            allEvents={props.journey.jbEvents}
            allFilters={[
              ...props.journey.jbFilters,
              ...props.journey.jbActions,
            ]}
          />
        )

      default:
        return ''
    }
  }

  return (
    <$WhiteContainer pb="1px">
      <Navbar heading="Journey Builder" />
      <LoaderPopup
        isPopOpen={props.journey?.jbLoading || props.aeLoading}
        title="Your journey is loading. Please Wait..."
      />
      {getJourneyBody()}
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  createdBy: state.auth.user.userId,
  userEmail: state.auth.user.email,
  journey: state.journey,
  aeLoading: state.autoEngage.aeLoading,
  emailTemplateDetails: state.autoEngage.emailTemplateDetails,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, {
  getJourneyDetails,
  setJBDetails,
  setLoadJourney,
  getActions,
  getFilters,
  getEvents,
})(CreateJourney)
