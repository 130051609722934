import styled, { css } from 'styled-components'

const sidebarWidth = '85px'

export const SidebarRoute = styled.li`
  display: block;
  position: relative;

  &:hover {
    svg.arright {
      transform: rotate(90deg);
    }
  }

  > a {
    position: relative;
    display: block;
    padding: 4px 0px 4px 32px;
    white-space: nowrap;
    text-decoration: none;
    color: #888 !important;
    transition: all 0.3s;

    &:hover {
      color: #3b86ff !important;
      background-color: hsl(0, 0%, 97%);

      svg path,
      ellipse,
      line,
      rect {
        stroke: #3b86ff;
      }
      .fillA {
        fill: #3b86ff;
      }
    }

    ${(props) =>
      props.active &&
      css`
        color: #3b86ff !important;

        svg path,
        ellipse,
        line,
        rect {
          stroke: #3b86ff;
        }

        .fillA {
          fill: #3b86ff;
        }
      `}

    span {
      vertical-align: middle;
      margin-left: 16px;
      opacity: 0;
      font-size: 12px;
      display: inline-block;
      min-width: 125px;
    }
  }

  &:not(:first-child) {
    svg {
      width: 15px;

      path {
        transition: stroke 0.3s;
      }
    }
  }
  svg.arright {
    transition: transform 0.3s;
  }

  ${(props) =>
    props.logo &&
    css`
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        svg {
          path {
            stroke: transparent !important;
          }
        }
      }
    `};
`

export const SidebarWrapper = styled.div`
  // Firefox
  scrollbar-width: thin;
  scrollbar-color: grey;

  // Chrome
  ::-webkit-scrollbar {
    box-shadow: inset 0 0 1px grey;
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  position: fixed;
  top: 0px;
  left: 0px;
  width: ${sidebarWidth};
  height: 100vh;

  padding: 8px 0px 16px;
  border-right: 1px solid #e0e4e4;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;

  transition: width 0.4s;

  p {
    font-size: 12px;
    padding-left: 24px;
    margin: 0;
    color: #888;
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .sidebarLinkText {
    opacity: 0;
  }
  &:hover {
    .sidebarLinkText {
      opacity: 1;
    }
    width: 220px;

    ${SidebarRoute} a span {
      opacity: 1;
    }
  }
`

export const SidebarHolder = styled.div`
  width: ${sidebarWidth};
  height: 100vh;
`

export const SidebarChildren = styled.ul`
  position: relative;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  background-color: #f2f5f8;
  border-top: ${(props) => (props.showFullHeight ? '5px solid #f2f5f8' : '')};
  border-bottom: ${(props) =>
    props.showFullHeight ? '5px solid #f2f5f8' : ''};
  line-height: ${(props) =>
    props.showFullHeight ? '100% !important' : '0px !important'};
  transition: all 0.7s;
  overflow-y: hidden;
  li {
    a {
      line-height: ${(props) =>
        props.showFullHeight ? '100% !important' : '0px !important'};
      padding: ${(props) =>
        props.showFullHeight
          ? '8px 0 8px 48px !important'
          : '0px 0px 0px 48px !important'};
      transition: all 0.7s;
      &:hover {
        background-color: #fff !important;
      }
    }
  }
`
