import { Text } from 'components/Common/Text'
import { useSelectStore } from 'hooks'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSurveyById, setSurveyData } from 'redux/actions/autoEngageActions'
import { useTheme } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'
import { SelectSurvey } from '../components/SelectSurvey'

const SurveySelect = ({ form, setForm }) => {
  const selectedSurveyId = form?.content?.body?.surveyId
  const onSurveySelect = ({ surveyId }) => {
    setForm({
      ...form,
      content: {
        body: {
          ...form?.content?.body,
          surveyId,
        },
      },
    })
  }

  const dispatch = useDispatch()
  const { spacing } = useTheme()
  const { data } = useSelectStore('autoEngage.aeSurveyData')
  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )

  useEffect(() => {
    selectedSurveyId && dispatch(getSurveyById(selectedSurveyId))

    return () => dispatch(setSurveyData({}))
  }, [selectedSurveyId])

  return featuresAlloted.surveys ? (
    <>
      <Text variant="h3" type="main" weight="bold">
        Select Survey
      </Text>
      {selectedSurveyId && (
        <Div my={spacing.l}>
          <FlexBox gap={spacing.l} align="center">
            <Text width="152px" variant="small" type="grey">
              Selected Survey Name
            </Text>
            :<Text type="main">{data.name}</Text>
          </FlexBox>
          {data.description && (
            <FlexBox gap={spacing.l} align="center">
              <Text width="152px" variant="small" type="grey">
                Selected Survey Description
              </Text>
              :<Text type="main">{data.description}</Text>
            </FlexBox>
          )}
          <FlexBox gap={spacing.l} align="center">
            <Text width="152px" variant="small" type="grey">
              Selected Survey Display
            </Text>
            :
            <Text type="main">
              {data?.contentType === 'single' ? 'Single Page' : 'Multi Page'}
            </Text>
          </FlexBox>
        </Div>
      )}
      <SelectSurvey
        selectedSurveyId={selectedSurveyId}
        onSurveySelect={onSurveySelect}
      />
    </>
  ) : null
}

export default SurveySelect
