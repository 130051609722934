import React, { useState } from 'react'
import { Div, FlexBox } from 'styledComponent'
import SIBImage from 'assets/images/sib.png'
import { $BlueBox } from '../AutoEngagement.styled'
import { SIB_INSTRUCTIONS } from '../constants'
import { Text, useToasts } from 'components/Common'
import { UploadBox } from '../components/MediaUpload'
import { setCampaignLoader, uploadFiles } from 'redux/actions/autoEngageActions'
import { useSelectStore } from 'hooks'
import { CloseIcon } from 'assets/icons'

export const SIBButtonUpload = ({ media, setMedia }) => {
  const { toast } = useToasts()
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')
  const { data: storeUser } = useSelectStore('auth.user.userId')

  const onImageUpload = async (e) => {
    setCampaignLoader(true)
    try {
      const file = e.target.files[0]
      if (
        ['.png', '.jpg', '.jpeg', '.gif'].includes(file?.type?.split('/')[1])
      ) {
        toast(
          `Unsupported file format. Upload file can be one of these formats - .png, .jpg, .jpeg, .gif`,
          'error'
        )
        setCampaignLoader(false)
        return
      }

      const formData = new FormData()
      formData.append('vendorId', vendorId)
      formData.append('storeUser', storeUser)
      formData.append('name', file?.name)
      formData.append(
        'description',
        `File uploaded at ${new Date().toString()}`
      )
      formData.append('file', file)
      formData.append('uploadedFrom', 'surveyInBill')

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const res = await uploadFiles(formData, config)
      if (res) {
        setMedia(res)
        toast('File uploaded successfully', 'success')
      }
    } catch (error) {
      toast('Unknown error occured', 'error')
    } finally {
      setCampaignLoader(false)
    }
  }

  return (
    <FlexBox gap="20px" height="600px" width="100%">
      <Div
        width="calc(100% - 370px)"
        border="1px solid lightgrey"
        textAlign="center"
        borderRadius="6px"
        position="relative"
      >
        <img height="598px" src={SIBImage} />
        <Div
          position="absolute"
          width="360px"
          height="132px"
          backgroundColor="#FFF"
          top="235px"
          left="50%"
          style={{ transform: 'translateX(-50%)' }}
        >
          <Div position="relative" top="10px">
            <UploadBox
              src={media?.fileLocation}
              onChange={onImageUpload}
              fileType="image"
              acceptedFormats=".png, .jpg, .jpeg, .gif"
              uploadWidth="340px"
              uploadHeight="109px"
              whiteBackground
            />
            {media?.fileLocation && (
              <Div
                type="button"
                position="absolute"
                top={0}
                right="10px"
                onClick={() => setMedia({})}
              >
                <CloseIcon />
              </Div>
            )}
          </Div>
        </Div>
      </Div>
      <$BlueBox height="600px" width="350px">
        <Text mb="20px" type="grey">
          Instructions
        </Text>
        {SIB_INSTRUCTIONS.map((inst) => (
          <Text mb="20px" key={inst} variant="medium" type="grey">
            {inst}
          </Text>
        ))}
      </$BlueBox>
    </FlexBox>
  )
}
