import React, { Component } from "react";
import InputHelper from "./InputHelper";
import { connect } from "react-redux";

class CompanyPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true
    };
    this.onChange2 = this.onChange2.bind(this);
    this.openEdit = this.openEdit.bind(this);
  }
  onChange2(field, value) {
    this.setState({ [field]: value });
  }
  openEdit() {
    this.setState({ disabled: false });
  }
  render() {
    return (
      <div className="storePopupModal">
        <div className="popupModalHeading">
          <span className="popupModalHeadingInner">Company Details</span>
          {/* <span className="popupModalEditButton" onClick={this.openEdit}>
            {this.state.disabled ? (
              <img src={require("./images/Icon.png")} alt="" />
            ) : null}
          </span> */}
        </div>
        <div className="popupModalGrids">
          <div className="popupModalGridInner">
            <InputHelper
              label="Company Name"
              type="text"
              name="companyName"
              value={this.props.companies[this.props.pID].companyName}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={true}
            />
            <InputHelper
              label="Person in Charge"
              type="text"
              name="companyPersonInCharge"
              value={this.props.companies[this.props.pID].inchargeName}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Company Email Address"
              type="text"
              name="companyEmail"
              value={this.props.companies[this.props.pID].emailId}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Mobile Number"
              type="text"
              name="companyMobileNumber"
              value={this.props.companies[this.props.pID].mobileNumber}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Company Number"
              type="text"
              name="companyNumber"
              value={this.props.companies[this.props.pID].companyContactNo}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
          </div>
          <div className="popupModalGridInner">
            <InputHelper
              label="Company type"
              type="select"
              name="storeStartTime"
              value={this.props.companies[this.props.pID].companyType}
              optionValues={[
                { name: "Proprieter", value: "proprieter" },
                { name: "Pvt ltd", value: "Pvt ltd" },
                { name: "Ltd", value: "Ltd" },
                { name: "Government", value: "Government" }
              ]}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="GST"
              type="text"
              name="companyGSTNumber"
              value={this.props.companies[this.props.pID].gst}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="PAN"
              type="text"
              name="companyPANNumber"
              value={this.props.companies[this.props.pID].pan}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={true}
            />
            <InputHelper
              label="Address"
              type="textarea"
              name="companyAddress"
              value={this.props.companies[this.props.pID].officeAddres}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
          </div>
        </div>
        {!this.state.disabled ? (
          <button className="popupModalSubmitButton">Update</button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  companies: state.storesBrandsCompanies.companies
});

export default connect(mapStateToProps)(CompanyPopupModal);
