import React, { Component } from "react";
import InputHelper from "./InputHelper";

class CompanyAddPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true
    };
    this.onChange2 = this.onChange2.bind(this);
  }
  onChange2(field, value) {
    this.setState({ [field]: value });
  }
  render() {
    return (
      <div className="storePopupModal">
        <div className="popupModalHeading">
          <span className="popupModalHeadingInner">Company Details</span>
        </div>
        <div className="popupModalGrids">
          <div className="popupModalGridInner">
            <InputHelper
              label="Company Name"
              type="text"
              name="companyName"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Person in Charge"
              type="text"
              name="companyPersonInCharge"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Company Email Address"
              type="text"
              name="companyEmail"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Mobile Number"
              type="text"
              name="companyMobileNumber"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Company Number"
              type="text"
              name="companyNumber"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
          </div>
          <div className="popupModalGridInner">
            <InputHelper
              label="Company type"
              type="select"
              name="storeStartTime"
              value="Ltd"
              optionValues={[
                { name: "Proprieter", value: "proprieter" },
                { name: "Pvt ltd", value: "Pvt ltd" },
                { name: "Ltd", value: "Ltd" },
                { name: "Government", value: "Government" }
              ]}
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="GST"
              type="text"
              name="companyGSTNumber"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="PAN"
              type="text"
              name="companyPANNumber"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Address"
              type="textarea"
              name="companyAddress"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
          </div>
        </div>
        <button className="popupModalSubmitButton">Request</button>
      </div>
    );
  }
}

export default CompanyAddPopupModal;
