import { Table, useToasts } from 'components/Common'
import { useDebouncedEffect } from 'hooks'
import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getStoreData } from 'redux/actions/billingAnalyticsActions'
import { Div } from 'styledComponent'
import { ERROR_DECODE } from 'utils/reusables'
import { decathVendorIds } from 'components/conditionalComponent'
import {
  calcPercent,
  getKeyName,
  STORE_DATA_COLUMNS,
  STORE_DATA_ROW,
  STORE_SORT_COLUMNS,
  STORE_DATA_ROW_DECATH,
} from '../constants'

const StoreDataTable = ({ filterQuery, vendorId, storesArray }) => {
  const { toast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [sortColumns, setSortColumns] = useState(STORE_SORT_COLUMNS)

  const sorting = (field, sort) => {
    const key = getKeyName(field)
    switch (sort) {
      case 'increase': {
        const sortedData = [...data]?.sort((a, b) => a?.[key] - b?.[key])
        setData(sortedData)
        break
      }
      case 'decrease': {
        const sortedData = [...data]?.sort((a, b) => b?.[key] - a?.[key])
        setData(sortedData)
        break
      }
      default: {
        const sortedData = [...data]?.sort(
          (a, b) => b?.totalTransactions - a?.totalTransactions
        )
        setData(sortedData)
        break
      }
    }
  }

  const getTable = async (data = {}) => {
    try {
      setIsLoading(true)
      const res = await getStoreData({
        ...data,
        startDate: moment(filterQuery?.startDate).format('YYYY-MM-DD'),
        endDate: moment(filterQuery?.endDate).format('YYYY-MM-DD'),
        vendorId,
        selectedStoreIds: filterQuery?.selectedStoreIds || [],
      })
      if (res) {
        let filteredStrArray = [...storesArray]
        if (filterQuery?.selectedStoreIds?.length > 0) {
          filteredStrArray = [...storesArray]?.filter((str) =>
            filterQuery?.selectedStoreIds?.includes(str?.storeId)
          )
        }
        let resData = filteredStrArray?.map((str) => {
          const strInd = res?.data?.findIndex(
            (ress) => str?.storeId === ress?.storeId
          )
          str.totalSales = 0
          str.avgBillingAmount = 0
          str.totalTransactions = 0
          str.totalBM = 0
          str.totalBMPrint = 0
          str.totalPrint = 0
          str.totalCW = 0
          str.totalBMPercent = 0.0
          str.totalBMPrintPercent = 0.0
          str.totalPrintPercent = 0.0
          str.totalCWPercent = 0.0
          if (strInd > -1) {
            const item = res?.data?.[strInd]
            str = {
              ...str,
              ...item,
              totalBMPercent: calcPercent(
                item?.totalBM,
                item?.totalTransactions
              ),
              totalBMPrintPercent: calcPercent(
                item?.totalBMPrint,
                item?.totalTransactions
              ),
              totalPrintPercent: calcPercent(
                item?.totalPrint,
                item?.totalTransactions
              ),
              totalCWPercent: calcPercent(
                item?.totalCW,
                item?.totalTransactions
              ),
            }
          }
          return str
        })

        if (filterQuery?.avgSales) {
          resData = resData?.filter(
            (sss) =>
              sss?.avgBillingAmount >= filterQuery?.avgSales?.min &&
              sss?.avgBillingAmount <= filterQuery?.avgSales?.max
          )
        }

        if (filterQuery?.totalSales) {
          resData = resData?.filter(
            (sss) =>
              sss?.totalSales >= filterQuery?.totalSales?.min &&
              sss?.totalSales <= filterQuery?.totalSales?.max
          )
        }

        if (filterQuery?.transactions) {
          const fil =
            'total' +
            filterQuery?.transactions?.transType?.replace('.', '') +
            (filterQuery?.transactions?.type === 'Count' ? '' : 'Percent')
          resData = resData?.filter(
            (sss) =>
              sss?.[fil] >= filterQuery?.transactions?.min &&
              sss?.[fil] <= filterQuery?.transactions?.max
          )
        }

        setData(resData)
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const setSort = (ind) => {
    const scol = [...STORE_SORT_COLUMNS]
    switch (sortColumns[ind]) {
      case 'sort': {
        scol[ind] = 'increase'
        break
      }
      case 'increase': {
        scol[ind] = 'decrease'
        break
      }
      default: {
        scol[ind] = 'sort'
        break
      }
    }
    sorting(STORE_DATA_COLUMNS[ind], scol[ind])
    setSortColumns(scol)
  }

  useDebouncedEffect(getTable, [filterQuery], 300)

  return (
    <Div mx="-16px">
      <Table
        setSort={setSort}
        sortColumns={sortColumns}
        columns={STORE_DATA_COLUMNS}
        tableLoading={isLoading}
        list={data}
        row={
          !decathVendorIds.includes(vendorId)
            ? STORE_DATA_ROW
            : STORE_DATA_ROW_DECATH
        }
        fontSize="14px"
        showPagination={false}
        vendorId={vendorId}
      />
    </Div>
  )
}

const mapStateToProps = (state) => {
  return {
    filterQuery: state.bills.filterQuery,
    bAnalytics: state.bAnalytics,
    isCashier: state.bills.isCashier,
    vendorId: state.vendorIds.selectedVendor.value,
    featuresAlloted: state.vendorIds.selectedVendor.featuresAlloted,
    storesArray: state.stores.storesArray,
  }
}

export default connect(mapStateToProps)(StoreDataTable)
