import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useTheme } from 'styled-components'
import * as moment from 'moment'
import { useDispatch } from 'react-redux'

import { Table, TableHead } from 'styledComponent'
import { useRouter } from 'hooks'
import {
  Skeleton,
  Input,
  useToasts,
  Text,
  PopupContainer,
} from 'components/Common'

import { WHATSAPP_TEMPLATE_HEADER } from '../../constants'
import { $AETableContainer, $Ellipsis } from '../../AutoEngagement.styled'
import { TabRow } from '../../campaigns/AETable'
import { getStatus } from '../../campaigns/AETable'
import { moreActions } from '../../campaigns/AETable'
import { setTemplateData } from 'redux/actions/autoEngageActions'
import AddTemplateContent from './TemplateCampaign/AddTemplateContent'

const TEMPLATE_ROW = (template, onActionClick) => [
  template?.templateId,
  <$Ellipsis>{template?.name || '-'}</$Ellipsis>,
  template?.category,
  template?.content?.body,
  template?.type,
  template?.buttons.length ? template?.buttons.length : 'None',
  template?.status ? getStatus(template?.status) : '-',
  template?.createdDate
    ? moment(template.createdDate).format('DD MMM YYYY')
    : '-',
  moreActions(template, onActionClick, 'Template', { showDuplicate: true }),
]

const TemplateTable = ({
  tableLoading,
  onPaginationChange,
  templateList,
  pageCount,
  setTriggers,
  pageNumber,
}) => {
  const theme = useTheme()
  const { toast } = useToasts()
  const { history } = useRouter()
  const dispatch = useDispatch()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [templateAction, setTemplateAction] = useState({})
  const [showTestTemplate, setShowTestTemplate] = useState({
    show: false,
    template: null,
  })

  const onActionClick = (action, template) => {
    const { _id, createdBy } = template
    switch (action) {
      case 'edit':
        return history.push(`/auto-engage/whatsApp/${_id}?tab=Templates`)

      case 'view':
        return history.push(
          `/auto-engage/whatsApp/${_id}?tab=Templates&ops=view`
        )

      case 'testTemplate': {
        return setShowTestTemplate({ show: true, template })
      }

      case 'duplicate': {
        setTemplateAction({
          _id,
          action,
          createdBy,
          heading: 'Duplicate Template',
        })
        return setIsPopOpen(true)
      }

      case 'copyTemplate': {
        navigator.clipboard.writeText(template?.content?.body)
        return toast('Link has been copied to your clipboard.', 'success')
      }

      case 'delete': {
        setTemplateAction({ _id, action, heading: 'Delete Campaign' })
        return setIsPopOpen(true)
      }
    }
  }

  const controlTestTemplatePopup = (state = false) => {
    setShowTestTemplate({
      ...setShowTestTemplate,
      show: state,
    })
  }

  return (
    <$AETableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow
            align="center"
            fontSize="14px"
            cells={WHATSAPP_TEMPLATE_HEADER}
          />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                cells={[...new Array(WHATSAPP_TEMPLATE_HEADER.length)].map(
                  (it) => (
                    <Skeleton key={it} />
                  )
                )}
              />
            ))
          ) : templateList?.length > 0 ? (
            templateList?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={TEMPLATE_ROW(item, onActionClick)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={WHATSAPP_TEMPLATE_HEADER.length}
              cells={['No Templates Found']}
            />
          )}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={pageCount || 1}
          onPageChange={onPaginationChange}
          containerClassName="aePagination"
          activeClassName="pagination_activePage"
          forcePage={pageNumber || 0}
        />
      )}
      <ActionPop
        templateAction={templateAction}
        setTriggers={setTriggers}
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        toast={toast}
        theme={theme}
      />
      <AddTemplateContent
        form={showTestTemplate?.template}
        isActive={showTestTemplate?.show}
        setIsActive={controlTestTemplatePopup}
        title="Test Template"
        testTrigger
      />
    </$AETableContainer>
  )
}

const ActionPop = ({
  templateAction,
  setTriggers,
  isPopOpen,
  setIsPopOpen,
  toast,
  theme,
}) => {
  const [name, setName] = useState('')

  useEffect(() => () => setName(''), [isPopOpen])

  const getBody = (action) => {
    switch (action) {
      case 'delete':
        return (
          <Text weight="bold" type="main" m={theme.spacing.m}>
            Deleting a template can result in unpublishing the associated
            campaigns. <br />
            Do you wish to continue?
          </Text>
        )

      case 'duplicate':
        return (
          <Input
            variant="input"
            inputWidth="400px"
            placeholder="Enter New Template Name"
            label="Template Name"
            maxLength="50"
            value={name}
            onChange={(e) => setName(e?.target?.value?.replace(/[^\w]/gim, ''))}
          />
        )

      default:
        return null
    }
  }

  const onSubmit = () => {
    if (templateAction?.action === 'duplicate') {
      if (!name) {
        toast(
          'Enter a new Template name to create a new copy of the campaign.',
          'error'
        )
        throw 'err'
      }
      templateAction.name = name
    }
    setTriggers(templateAction)
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading={templateAction?.heading}
      onSubmitClick={onSubmit}
    >
      {getBody(templateAction?.action)}
    </PopupContainer>
  )
}

export default TemplateTable
