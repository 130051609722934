import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { useRouter } from 'hooks'
import { FlexBox } from 'styledComponent'
import { Input, useToasts, Button } from 'components/Common'
import { postCoupon } from 'redux/actions/autoEngageActions'

import { $CampaignContainer, $WhiteContainer } from '../AutoEngagement.styled'
import { ERROR_DECODE, CHECK_IF } from '../constants'
import { connect } from 'react-redux'
import TopBar from '../components/TopBar'
import CouponHint from 'assets/images/coupon-hint.svg'

const CouponCode = (props) => {
  const theme = useTheme()
  const { toast } = useToasts()
  const { history } = useRouter()
  const { vendorId, createdBy } = props

  const [form, setForm] = useState({
    vendorId,
    createdBy,
  })

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]:
        e.target.id === 'prefix'
          ? e.target.value.toUpperCase()
          : e.target.value,
    })
  }

  const onCouponCreate = async () => {
    const check = CHECK_IF(['name', 'vendorId', 'prefix', 'totalCoupons'], form)
    if (check) {
      toast(`Please enter ${check}`, 'error')
      return
    }
    if (form?.prefix?.length < 2 || form?.prefix?.length > 4) {
      toast(`Prefix should be between 2 to 4 characters`, 'error')
      return
    }
    try {
      const res = await postCoupon(form)
      if (res?.message) {
        toast(res.message, 'success')
        history.push('/auto-engage/coupons')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const SaveCoupon = (
    <Button variant="primary" onClick={onCouponCreate}>
      Save & Exit
    </Button>
  )

  return (
    <$WhiteContainer>
      <TopBar
        heading="Auto Engagement - Coupons"
        title="Generate New Coupon"
        extras={SaveCoupon}
        onExit={() => history.push('/auto-engage/coupons')}
      />
      <$CampaignContainer p={theme.spacing.xl} m={theme.spacing.l}>
        <FlexBox gap={theme.spacing.xl}>
          <div>
            <Input
              containerMargin="0"
              variant="input"
              label="Coupon Name"
              placeholder="Enter coupon name"
              id="name"
              value={form.name || ''}
              onChange={handleChange}
              inputWidth="500px"
              maxLength="50"
            />
            <Input
              containerMargin="0"
              variant="textarea"
              rows={3}
              label="Coupon Description"
              placeholder="Describe your coupon"
              id="description"
              value={form.description || ''}
              onChange={handleChange}
              inputWidth="500px"
              maxLength="250"
            />
          </div>
        </FlexBox>
        <FlexBox gap="50px">
          <Input
            variant="input"
            type="number"
            label="No. of Coupons"
            placeholder="eg:- 50"
            id="totalCoupons"
            value={form.totalCoupons || ''}
            onChange={handleChange}
            inputWidth="200px"
          />
          <Input
            variant="input"
            label="Prefix"
            placeholder="eg:- MDX"
            id="prefix"
            value={form.prefix || ''}
            onChange={handleChange}
            inputWidth="200px"
            maxLength="4"
          />
          <img src={CouponHint} alt="Coupon Hint" title="Coupon Hint" />
        </FlexBox>
      </$CampaignContainer>
    </$WhiteContainer>
  )
}

const mapStateToProps = (state) => ({
  createdBy: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps)(CouponCode)
