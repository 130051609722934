import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import * as moment from 'moment'

import { useRouter } from 'hooks'
import { FlexBox, Table, TableHead } from 'styledComponent'
import {
  Input,
  PopupContainer,
  Skeleton,
  Text,
  Tooltip,
} from 'components/Common'

import {
  $AETableContainer,
  $Ellipsis,
} from '../../autoEngagement/AutoEngagement.styled'
import {
  TabRow,
  getStatus,
  moreActions,
} from 'components/Content/autoEngagement/campaigns/AETable'
import { JB_HEADER } from '../constants'
import CancelPopup from 'components/Content/autoEngagement/components/CancelPopup'
import { PaginationWrapper } from 'components/Content/bills/style'

const JOURNEY_ROW = (journ, onActionClick, createdBy) => [
  <$Ellipsis>{journ?.name || '-'}</$Ellipsis>,
  journ?.status ? getStatus(journ?.status) : '-',
  <$Ellipsis>{journ?.description || '-'}</$Ellipsis>,
  <$Ellipsis>{journ?.createdByEmail || '-'}</$Ellipsis>,
  journ?.createdDate ? moment(journ.createdDate).format('DD MMM YYYY') : '-',
  // journ?.updatedDate ? moment(journ.updatedDate).format('DD MMM YYYY') : '-',
  journ?.endDate ? moment(journ?.endDate).format('DD MMM YYYY hh:mm A') : '-',
  moreActions(journ, onActionClick, ' Journey', {
    showEdit: createdBy === journ.createdBy,
    showDelete: createdBy === journ.createdBy,
    showDuplicate: true,
  }),
]

const JBTable = ({
  tableLoading,
  onPaginationChange,
  page,
  data,
  deleteJourney,
  unpublishJourney,
  unpublishForAllJourney,
  duplicateJourney,
  createdBy,
}) => {
  const theme = useTheme()
  const { history } = useRouter()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [journeyPopDetails, setJourneyPopDetails] = useState({})
  const [duplicateJourneyName, setDuplicateJourneyName] = useState('')
  const [isDupliPopupOpen, setIsDupliPopupOpen] = useState(false)
  const [isJourneyNameError, setIsJourneyNameError] = useState('')
  const [selectedJourneyForDupli, setSelectedJourneyForDupli] = useState({})

  const onActionClick = (action, { _id, name }) => {
    switch (action) {
      case 'edit':
        return history.push(`/journey/${_id}`)

      case 'view':
        return history.push(`/journey/${_id}/view`)

      case 'unpublish':
        setIsPopOpen(true)
        setJourneyPopDetails({
          title: 'Are you sure you want to unpublish the journey?',
          onOkAction: () => unpublishJourney(_id),
        })
        return

      case 'unpublishForAll':
        setIsPopOpen(true)
        setJourneyPopDetails({
          title: `Campaigns will be stopped for new customers. \n
          Old Customers will also not be able to see the Campaigns on their old bills.`,
          onOkAction: () => unpublishForAllJourney(_id),
        })
        return

      case 'delete':
        setIsPopOpen(true)
        setJourneyPopDetails({
          title: 'Are you sure you want to delete the journey?',
          onOkAction: () => deleteJourney(_id),
        })
        return
      case 'duplicate':
        setIsDupliPopupOpen(true)
        setSelectedJourneyForDupli({ _id, name })
        return
    }
  }

  const handleDuplicateJourneyNameChange = (e) => {
    setDuplicateJourneyName(e.target.value)
  }

  const handleJourneyDuplication = async () => {
    if (!duplicateJourneyName) {
      setIsJourneyNameError('Journey name cannot be empty')
      return
    }

    await duplicateJourney(selectedJourneyForDupli._id, {
      name: duplicateJourneyName,
    })

    setIsDupliPopupOpen(false)
  }

  React.useEffect(() => {
    if (!isDupliPopupOpen) {
      setDuplicateJourneyName('')
      setSelectedJourneyForDupli({})
      setIsJourneyNameError('')
    }
  }, [isDupliPopupOpen])

  return (
    <$AETableContainer>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={JB_HEADER} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                cells={[...new Array(JB_HEADER.length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : data?.length > 0 ? (
            data?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={JOURNEY_ROW(item, onActionClick, createdBy)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={JB_HEADER.length}
              cells={['No Data Found']}
            />
          )}
        </tbody>
      </Table>
      {((page === 0 && data.length > 0) || page > 0) && (
        <PaginationWrapper>
          <ul className="feedback-pagination notSelectableText">
            <li
              className={`${!page ? 'disabled' : ''} previous`}
              onClick={() => page > 0 && onPaginationChange(page - 1)}
            >
              <a tabIndex="0" role="button" aria-disabled="false">
                &lt; Previous
              </a>
            </li>
            <li
              className={`${data.length < 10 ? 'disabled' : ''} next`}
              onClick={() => data.length === 10 && onPaginationChange(page + 1)}
            >
              <a tabIndex="1" role="button" aria-disabled="false">
                Next &gt;
              </a>
            </li>
          </ul>
        </PaginationWrapper>
      )}

      <PopupContainer
        isPopOpen={isDupliPopupOpen}
        setIsPopOpen={setIsDupliPopupOpen}
        heading="Duplicate Journey"
        onSubmitClick={handleJourneyDuplication}
        width="550px"
        disableAutoCloseOnSubmit
      >
        <FlexBox m={0} mb={theme.spacing.l} gap={theme.spacing.l}>
          <Tooltip content="Some Info tip" margin={0} />
          <Text weight="800">
            '{selectedJourneyForDupli.name}' is being duplicated
          </Text>
        </FlexBox>

        <Input
          variant="input"
          label="Enter New Journey Name"
          id="journey-name"
          value={duplicateJourneyName}
          onChange={handleDuplicateJourneyNameChange}
          placeholder="Enter Journey Name"
        />
        {isJourneyNameError && (
          <p style={{ color: 'red', fontSize: '12px', margin: '4px' }}>
            {isJourneyNameError}
          </p>
        )}
      </PopupContainer>

      <CancelPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        title={journeyPopDetails.title}
        onOkClick={journeyPopDetails.onOkAction}
      />
    </$AETableContainer>
  )
}

export default JBTable
