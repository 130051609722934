import styled, { css } from 'styled-components'

import { spacing } from 'design-system'

const $FilterMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  flex-basis: 30%;
  border-right: 1px solid ${(props) => props.theme.colors.neutral[300]};
`
const $FilterMenuItem = styled.li`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.m} ${spacing.l};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[300]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.primary[100]};
    `};
`

const $FilterMenuValue = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary.default};
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
  }
`

export { $FilterMenu, $FilterMenuItem, $FilterMenuValue }
