import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getWhatsAppTemplates } from 'redux/actions/autoEngageActions'
import { useDebouncedEffect, useSelectStore } from 'hooks'
import { $SmsMessage, $BlueBox } from '../../AutoEngagement.styled'
import { Div, FlexBox } from 'styledComponent'
import { Text, Input, PopupContainer } from 'components/Common'
import { spacing } from 'design-system'
import {
  WHATSAPP_ATTRIBUTES,
  GET_SEL_VALUE,
  DEFAULT_TEMPLATE_BODY,
} from '../../constants'

import UploadModal from '../../components/UploadModal'
import { UserAttributeModal } from '../../components/UserAttributeModal'
import { SelectSurvey } from '../../components/SelectSurvey'
import { SelectCoupon } from '../../components/SelectCoupon'
import { CustomAttributeModal } from '../../components/CustomAttributeModal'
import { CustomLinkTypeAttributeModal } from '../../components/CustomLinkTypeAttributeModal'
import TemplatePreview from '../Templates/TemplateCampaign/PreviewPanel'

const Message = ({ form, setForm, fromJourney }) => {
  const dispatch = useDispatch()
  const { data: whatsAppTemplates } = useSelectStore(
    'autoEngage.whatsAppTemplates.docs'
  )

  const [mediaUrlError, setMediaUrlError] = useState('')
  const [mediaUrl, setMediaUrl] = useState(form?.content?.body?.mediaUrl || '')
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [isButtonPopOpen, setIsButtonPopOpen] = useState(false)
  const [paramId, setParamId] = useState(-1)
  const [buttonParamId, setButtonParamId] = useState(-1)
  const [template, setTemplate] = useState(
    whatsAppTemplates?.[0]?.content?.body?.split('{{var}}') || []
  )
  const [templateButtons, setTemplateButtons] = useState(() => {
    return whatsAppTemplates?.[0]?.buttonType === 'cta' &&
      whatsAppTemplates?.[0]?.buttons?.[0].urlType === 'dynamic'
      ? whatsAppTemplates?.[0]?.buttons?.[0]?.url?.split('{{var}}')
      : []
  })
  const [templateContent, setTemplateContent] = useState(DEFAULT_TEMPLATE_BODY)

  useEffect(() => {
    whatsAppTemplates && whatsAppTemplates.length
      ? setTemplateContent(whatsAppTemplates[0]?.content)
      : setTemplateContent(DEFAULT_TEMPLATE_BODY)
  }, [whatsAppTemplates?.[0]?.content])
  const [templateParams, setTemplateParams] = useState(
    form?.content?.body?.templateParams?.length > 0
      ? form?.content?.body?.templateParams?.map((tp, i) => ({
          attribute:
            tp === '$custom[]'
              ? 'blank'
              : /\$(.*?)\[/.exec(tp)?.length > 0
              ? /\$(.*?)\[/.exec(tp)[1]
              : '',
          value: tp,
          display: form?.content?.body?.displayParams[i] || null,
        }))
      : new Array(template.length - 1)
  )

  const [templateButtonParams, setTemplateButtonParams] = useState(
    form?.content?.body?.templateButtonParams?.length > 0
      ? form?.content?.body?.templateButtonParams?.map((tp, i) => ({
          attribute:
            tp === '$custom[]'
              ? 'blank'
              : /\$(.*?)\[/.exec(tp)?.length > 0
              ? /\$(.*?)\[/.exec(tp)[1]
              : '',
          value: tp,
          display: form?.content?.body?.displayButtonParams[i] || null,
        }))
      : new Array(template.length - 1)
  )

  useDebouncedEffect(
    () => {
      const temple =
        whatsAppTemplates?.[0]?.content?.body?.split('{{var}}') || []
      if (template?.[0] !== temple?.[0]) {
        setTemplate(temple)
        setTemplateParams(new Array(temple.length - 1))
      }
    },
    [whatsAppTemplates?.[0]?.content?.body],
    100
  )

  useDebouncedEffect(() => {
    const button =
      whatsAppTemplates?.[0]?.buttonType === 'cta' &&
      whatsAppTemplates?.[0]?.buttons?.[0]?.urlType === 'dynamic'
        ? whatsAppTemplates?.[0]?.buttons?.[0]?.url?.split('{{var}}')
        : []
    if (button?.[0] !== templateButtons?.[0]) {
      setTemplateButtons(button)
      setTemplateButtonParams(new Array(button.length - 1))
    }
  }, [whatsAppTemplates?.[0]?.buttons])

  useEffect(() => {
    const tempId = form.content?.body?.templateId
    if (tempId && tempId !== whatsAppTemplates[0]?._id) {
      dispatch(getWhatsAppTemplates({ _id: form.content?.body?.templateId }))
    }
    return () => setTemplateParams([])
  }, [])

  useEffect(() => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        body: {
          ...form?.content?.body,
          templateParams: templateParams
            .map((temp) => temp?.value)
            .filter((x) => x !== null),
          displayParams: templateParams.map((temp) => temp?.display),
          templateButtonParams: templateButtonParams
            .map((t) => t?.value)
            .filter((x) => x !== null),
          displayButtonParams: templateButtonParams.map((t) => t?.display),
          templateType: whatsAppTemplates?.[0]?.type,
          mediaUrl,
        },
      },
    })
  }, [templateParams, templateButtonParams, mediaUrl])

  const setTempEmbed = (value, field, display) => {
    setIsPopOpen(false)
    const tempParams = [...templateParams]
    tempParams[paramId] = {
      ...tempParams[paramId],
      value: `$${field}[${value}]`,
      display,
    }
    setTemplateParams(tempParams)
  }

  const setButtonEmbed = (value, field, display) => {
    setIsButtonPopOpen(false)
    const tempButtParams = [...templateButtonParams]
    tempButtParams[buttonParamId] = {
      ...tempButtParams[buttonParamId],
      value: `$${field}[${value}]`,
      display,
    }
    setTemplateButtonParams(tempButtParams)
  }

  useEffect(() => {
    if (isPopOpen === 'blank') {
      setTempEmbed('', 'custom', '{ }')
    }
  }, [isPopOpen])

  useEffect(() => {
    if (isPopOpen === 'blank') {
      setButtonEmbed('', 'custom', '{ }')
    }
  }, [isButtonPopOpen])

  const handleSelectParam = (i, attribute) => {
    const tempParams = [...templateParams]
    tempParams[i] = { attribute }
    setTemplateParams(tempParams)
    setParamId(i)
    setIsPopOpen(attribute)
  }

  const handleButtonSelectParam = (i, attribute) => {
    const tempButtParams = [...templateButtonParams]
    tempButtParams[i] = { attribute }
    setTemplateButtonParams(tempButtParams)
    setButtonParamId(i)
    setIsButtonPopOpen(attribute)
  }
  const handleMediaUrlChange = (e) => {
    setMediaUrl(e.target.value)
    if (/^(ftp|http|https):\/\/[^ "]+$/i.test(e.target.value)) {
      setMediaUrlError('')
    } else {
      setMediaUrlError('Invalid Url')
    }
  }

  return (
    <Div>
      <FlexBox height="auto" gap={spacing.l} justify="space-between">
        <Div width="100%" pt={spacing.l}>
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            Message
          </Text>
          {whatsAppTemplates?.[0]?.type === 'media' && (
            <Input
              label="Media Url"
              type="text"
              validationError={mediaUrlError}
              value={mediaUrl}
              onChange={handleMediaUrlChange}
              variant="input"
              required
            />
          )}
          <Text weight="bold">Template Header</Text>
          <Input
            variant="input"
            value={templateContent?.header ? templateContent?.header : ''}
            disabled
            type="text"
          />
          <Text weight="bold">Enter Body Variable Value</Text>
          <$SmsMessage style={{ margin: '0px 0px 16px' }}>
            {template?.map((t, i) => (
              <span key={t + i}>
                {t}
                {i !== template.length - 1 && (
                  <Div display="inline-block" width="200px" key={t + i}>
                    <Input
                      key={t + i}
                      containerMargin="0"
                      variant="single-select"
                      placeholder="Select Attribute"
                      options={WHATSAPP_ATTRIBUTES(fromJourney)}
                      value={
                        GET_SEL_VALUE(
                          templateParams[i]?.attribute,
                          WHATSAPP_ATTRIBUTES(fromJourney)
                        ) || ''
                      }
                      onChange={(val) => handleSelectParam(i, val.value)}
                    />
                  </Div>
                )}
              </span>
            ))}
          </$SmsMessage>
          <Text weight="bold">Template Footer</Text>
          <Input
            variant="input"
            value={templateContent?.footer ? templateContent?.footer : ''}
            disabled
          />

          {templateButtons && templateButtons.length > 0 && (
            <>
              <Text weight="bold">Enter Button Url Variable Value</Text>
              <$SmsMessage style={{ margin: '0px 0px 16px' }}>
                {templateButtons?.map((t, i) => (
                  <span key={t + i}>
                    {t}
                    {i !== templateButtons.length - 1 && (
                      <Div display="inline-block" width="200px" key={t + i}>
                        <Input
                          key={t + i}
                          containerMargin="0"
                          variant="single-select"
                          placeholder="Select Attribute"
                          options={WHATSAPP_ATTRIBUTES(fromJourney)}
                          value={
                            GET_SEL_VALUE(
                              templateButtonParams[i]?.attribute,
                              WHATSAPP_ATTRIBUTES(fromJourney)
                            ) || ''
                          }
                          onChange={(val) =>
                            handleButtonSelectParam(i, val.value)
                          }
                        />
                      </Div>
                    )}
                  </span>
                ))}
              </$SmsMessage>
            </>
          )}
        </Div>
        <Div width="400px">
          <TemplatePreview data={whatsAppTemplates?.[0]} />
        </Div>
      </FlexBox>
      <UploadModal
        showMedias={['image', 'gif', 'video', 'pdf']}
        isPopOpen={[isPopOpen, isButtonPopOpen].includes('asset')}
        setIsPopOpen={isPopOpen === 'asset' ? setIsPopOpen : setIsButtonPopOpen}
        setMedia={({ key, name }) =>
          isPopOpen === 'asset'
            ? setTempEmbed(key, 'asset', `{ Media-${name} }`)
            : setButtonEmbed(key, 'asset', `{ Media-${name} }`)
        }
        uploadedFrom="whatsapp"
      />
      <UserAttributeModal
        isPopOpen={[isPopOpen, isButtonPopOpen].includes('attribute')}
        setIsPopOpen={
          isPopOpen === 'attribute' ? setIsPopOpen : setIsButtonPopOpen
        }
        onAttributeChange={(value, field) =>
          isPopOpen === 'attribute'
            ? setTempEmbed(value, field, `{ ${value} }`)
            : setButtonEmbed(value, field, `{ ${value} }`)
        }
      />
      <CustomAttributeModal
        isPopOpen={[isPopOpen, isButtonPopOpen].includes('custom')}
        setIsPopOpen={
          isPopOpen === 'custom' ? setIsPopOpen : setIsButtonPopOpen
        }
        onCustomChange={(value, field) =>
          isPopOpen === 'custom'
            ? setTempEmbed(value, field, `{ ${value} }`)
            : setButtonEmbed(value, field, `{ ${value} }`)
        }
      />
      <CustomLinkTypeAttributeModal
        isPopOpen={[isPopOpen, isButtonPopOpen].includes('customLinkType')}
        setIsPopOpen={
          isPopOpen === 'customLinkType' ? setIsPopOpen : setIsButtonPopOpen
        }
        onCustomLinkTypeChange={(value, field) =>
          isPopOpen === 'customLinkType'
            ? setTempEmbed(value, field, `{ ${value} }`)
            : setButtonEmbed(value, field, `{ ${value} }`)
        }
      />
      <PopupContainer
        isPopOpen={
          ['survey', 'coupon'].includes(isPopOpen) ||
          ['survey', 'coupon'].includes(isButtonPopOpen)
        }
        setIsPopOpen={(e) =>
          ['survey', 'coupon'].includes(isPopOpen)
            ? setIsPopOpen
            : setIsButtonPopOpen
        }
        heading={`Select ${isPopOpen || isButtonPopOpen}`}
      >
        {[isPopOpen, isButtonPopOpen].includes('coupon') ? (
          <SelectCoupon
            onCouponSelect={(coup) =>
              isPopOpen === 'coupon'
                ? setTempEmbed(coup._id, 'coupon', `{ ${coup.prefix}-0000 }`)
                : setButtonEmbed(coup._id, 'coupon', `{ ${coup.prefix}-0000 }`)
            }
          />
        ) : (
          <SelectSurvey
            onSurveySelect={(sur) =>
              isPopOpen
                ? setTempEmbed(sur.surveyId, 'survey', `{ Survey-${sur.name} }`)
                : setButtonEmbed(
                    sur.surveyId,
                    'survey',
                    `{ Survey-${sur.name} }`
                  )
            }
          />
        )}
      </PopupContainer>
    </Div>
  )
}

export default Message
