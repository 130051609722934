import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToasts } from 'components/Common'
import {
  getSurveys,
  setLoadTable,
  deleteSurvey,
  duplicateSurvey,
  updateSurvey,
} from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../../constants'

export const useSurveyApi = (vendorId, search, skip, triggers, status) => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: 10,
  })
  const dispatch = useDispatch()
  const { toast } = useToasts()
  const debounceLoadData = useCallback(
    debounce((q) => setQuery(q), 750),
    []
  )

  useEffect(() => {
    query.vendorId && dispatch(getSurveys(query))
  }, [query])

  useEffect(() => {
    triggers._id && triggerActions()
  }, [triggers._id])

  useEffect(() => {
    dispatch(setLoadTable(true))
    const tempQuery = { ...query, vendorId, skip, name: search }
    if (search.length === 0) {
      delete tempQuery.name
    }
    if (status) {
      tempQuery.status = [status]
    }
    debounceLoadData(tempQuery)
  }, [vendorId, search, skip])

  const triggerActions = () => {
    switch (triggers?.action) {
      case 'delete':
        return delSurvey()

      case 'duplicate':
        return dupSurvey()

      case 'unpublishForAll':
        return unpubSurvey()

      default:
        break
    }
  }

  const dupSurvey = async () => {
    try {
      const res = await duplicateSurvey(triggers._id, {
        name: triggers.name,
        createdBy: triggers.createdBy,
        vendorId,
      })
      if (res) {
        toast('Survey duplicated successfully', 'success')
        dispatch(getSurveys(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const unpubSurvey = async () => {
    try {
      const res = await updateSurvey(triggers._id, {
        vendorId,
        status: 'unpublishedForAll',
      })
      if (res) {
        toast('Survey unpublished successfully', 'success')
        dispatch(getSurveys(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const delSurvey = async () => {
    try {
      const res = await deleteSurvey(triggers._id)
      if (res) {
        toast('Survey deleted successfully', 'success')
        dispatch(getSurveys(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return null
}
