import React from 'react'
import styled, { css } from 'styled-components'

export const NamedCheckbox = ({
  name,
  label,
  value,
  margin,
  handleCheckboxChange,
  lastDay,
  isChecked,
}) => (
  <StyledContainer lastDay={lastDay} margin={margin}>
    <StyledCheckbox
      id={value}
      name={value}
      onChange={() => handleCheckboxChange(value)}
      checked={isChecked}
    />
    <label htmlFor={value}>{label}</label>
  </StyledContainer>
)

const StyledContainer = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  margin: ${({ margin }) => margin || '0px'};
  box-sizing: border-box;

  label {
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary.default};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  ${(props) =>
    props.lastDay &&
    css`
      width: 145px;
      font-size: 12px;
    `};
`

const StyledCheckbox = styled.input.attrs((props) => ({
  type: 'checkbox',
  id: props.name,
  name: props.name,
  value: props.name,
}))`
  opacity: 0;
  position: absolute;

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.primary.default};
    color: white;
  }
`
