import styled from 'styled-components'

import { spacing } from 'design-system'

const $ContainerBlock = styled.div`
  width: max-content;
  position: relative;
  margin: ${({ margin }) => margin || 'unset'};
`
const $Rank = styled.span`
  position: absolute;
  top: ${spacing.m};
  left: 49%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 900;
`

export { $ContainerBlock, $Rank }
