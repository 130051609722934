const blacklistedAttributes = [
  'onload',
  'onclick',
  'onmouseover',
  'onerror' /* add more if needed */,
]

const validateIframe = (iframeHTML) => {
  if (!iframeHTML) return false

  const allowedSrc = 'https://www.youtube.com'

  const srcMatch = iframeHTML.match(
    /src=["'](https:\/\/www\.youtube\.com\/[^"']+)["']/
  )
  const iframeSrc = srcMatch?.[1] || null

  return !!(iframeSrc && iframeSrc.includes(allowedSrc))
}

const validateIframeAttribute = (iframeHTML) => {
  if (!iframeHTML) return false

  for (const attribute of blacklistedAttributes) {
    if (iframeHTML.includes(attribute)) {
      return false
    }
  }

  return true
}

export { validateIframe, validateIframeAttribute }
