import React from 'react'
import { components } from 'react-select'
import 'css/content/global.scss'
import { Logo, Box } from 'styledComponent'
import styled from 'styled-components'

const SelectStoreBox = styled(Box)`
  text-transform: capitalize;
  cursor: pointer;

  &:focus {
    background: #deebff;
  }
  &:hover {
    background: #d6ebff;
  }
  &:disabled {
    color: hsl(0, 0%, 80%);
  }
`

export const DropdownIndicator = ({ ...props }) => (
  <components.DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.16"
      height="11.58"
      viewBox="0 0 21.16 11.58"
    >
      <path
        id="Icon"
        d="M9791.666,75.141l4.583,4.583,4.584,4.583,2.673-2.673,2.826-2.826L9810,75.141"
        transform="translate(-9790.252 -73.727)"
        fill="none"
        stroke={props.isFocused ? '#707070' : 'hsl(0,0%,80%)'}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </components.DropdownIndicator>
)

export const SingleValue = ({ children, data, ...props }) => (
  <components.SingleValue {...props}>
    <div {...props} className="store-select-value">
      {data.brandLogo && (
        <img src={data.brandLogo} className="store-select-img" />
      )}
      <span className="store-select-label">{data.label}</span>
    </div>
  </components.SingleValue>
)

export const StoreSelectOption = ({ innerProps, isDisabled, data }) =>
  !isDisabled ? (
    <SelectStoreBox {...innerProps} flex align="center" padding="5px 12px">
      {data.brandLogo && (
        <Logo
          src={data.brandLogo}
          alt={`${data.label} logo`}
          size="tiny"
          lightBorder
        />
      )}
      <span className="store-select-label">{data.label}</span>
    </SelectStoreBox>
  ) : null
