import React from 'react'
import { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { BillExplicitSettings } from './BillExplicit'

import { $SideItem } from './NewSettings.styled'
import DLT from './dltsettings'
import { TABS, TABS_LIST } from './constants'
import ProductAnalytics from '../Settings/ProductAnalytics'
import CRM from '../Settings/CRM'
import { Breadcrumb } from 'components/Common'
import WhatsApp from './WhatsApp'

const NewSettings = () => {
  const [activeTab, setActiveTab] = React.useState(TABS.billExplicit)
  const { spacing, colors, borderRadius } = useTheme()

  return (
    <$WhiteContainer>
      <Navbar heading="Settings" />
      <FlexBox m={spacing.l} justify="space-between">
        <Breadcrumb label="Settings" />
      </FlexBox>
      <FlexBox height="calc(100vh - 128px)" gap={spacing.l} p={spacing.l}>
        <Div
          border={`1px solid ${colors.darkMercury}`}
          width="300px"
          minHeight="100%"
          borderRadius={borderRadius.small}
          position="relative"
        >
          {TABS_LIST.map((tab) => (
            <$SideItem
              key={tab.value}
              isActive={activeTab === tab.value}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
              {activeTab === tab.value && (
                <Div
                  backgroundColor={colors.primary.default}
                  position="absolute"
                  right={0}
                  top={0}
                  borderTopRightRadius={borderRadius.small}
                  borderBottomRightRadius={borderRadius.small}
                  width="8px"
                  height="100%"
                />
              )}
            </$SideItem>
          ))}
        </Div>
        <Div
          border={`1px solid ${colors.darkMercury}`}
          width="calc(100vw - 348px)"
          minHeight="100%"
          borderRadius={borderRadius.small}
          position="relative"
          overflow="auto"
        >
          {getActiveTab(activeTab)}
        </Div>
      </FlexBox>
    </$WhiteContainer>
  )
}

const getActiveTab = (activeTab) => {
  switch (activeTab) {
    case TABS.dltSettings:
      return <DLT />

    case TABS.billExplicit:
      return <BillExplicitSettings />

    // case TABS.productAnalytics:
    //   return <ProductAnalytics />

    case TABS.consumerProfiling:
      return <CRM />

    case TABS.whatsApp:
      return <WhatsApp />

    default:
      return <BillExplicitSettings />
  }
}

export default NewSettings
