import { Button } from 'components/Common/Button'
import { borderRadius, spacing } from 'design-system'
import styled from 'styled-components'

const $AddIconBlock = styled.div`
  padding: ${spacing.s};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.default};
  border-radius: ${borderRadius.small};
  cursor: pointer;
  font-size: 14px;
`

const $Button = styled(Button)`
  font-size: 14px;
  background-color: ${({ variant }) =>
    variant === 'primary' ? '#62B349' : 'transparent'};
  &:hover {
    border-color: transparent;
  }
`

export { $AddIconBlock, $Button }
