import React from 'react'
import { useSelectStore, useVendorSelect } from 'hooks'
import { VendorIdSelect } from 'components/Common'
import styled from 'styled-components'
import { spacing } from 'design-system'

const $VendorSelectContainer = styled.div`
  width: ${(props) => (props.width ? props.width : '250px')};
  margin: ${spacing.ml} 0;
`

export const SelectVendor = ({ width, isDisabled }) => {
  const {
    data: { vendorIds, selectedVendor },
  } = useSelectStore('vendorIds')
  const { handleVendorChange } = useVendorSelect(true)

  return (
    <$VendorSelectContainer width={width}>
      <VendorIdSelect
        selectedVendor={selectedVendor}
        vendorIds={vendorIds}
        onChange={handleVendorChange}
        isDisabled={isDisabled}
      />
    </$VendorSelectContainer>
  )
}
