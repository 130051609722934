import React from 'react'
import { useTheme } from 'styled-components'
import styled from 'styled-components'
import _ from 'lodash'
import { FlexBox, Table, TableCell, TableHead, TableRow } from 'styledComponent'
import { Button, Skeleton } from 'components/Common'
import { space } from 'styled-system'
import { DownArrowIcon } from 'assets/icons'
import { decathVendorIds } from 'components/conditionalComponent'

export const PPTable = ({
  columns = [],
  sortColumns = [],
  setSort = () => {},
  tableLoading = true,
  onActionClick = () => {},
  list = [],
  row = [],
  page = 1,
  onPaginationChange,
  limit = 10,
  showPagination = true,
  isStoreData,
  vendorId,
  ...styleProps
}) => {
  const theme = useTheme()

  const tableRowList = (list) => {
    if (!decathVendorIds.includes(vendorId)) {
      const result = list.map(({ treesSaved, co2, ...rest }) => ({ ...rest }))
      return result
    }
    return list
  }

  return (
    <$TableContainer {...styleProps}>
      <Table margin="0" borderColor={theme.colors.alto}>
        <TableHead>
          <TabRow
            align="center"
            fontSize="14px"
            cells={columns}
            sortCells={sortColumns}
            vendorId={vendorId}
            setSort={setSort}
          />
        </TableHead>
        <tbody>
          {tableLoading ? (
            [...new Array(10)].map((_, i) => (
              <TabRow
                key={i}
                setSort={setSort}
                isStoreData={isStoreData}
                vendorId={vendorId}
                cells={[...new Array(columns.length)].map((it) => (
                  <Skeleton key={it} />
                ))}
              />
            ))
          ) : list?.length > 0 ? (
            tableRowList(list)?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={theme.colors.solitude}
                align="center"
                cells={row(item, onActionClick)}
                setSort={setSort}
                vendorId={vendorId}
                {...styleProps}
              />
            ))
          ) : (
            <TabRow
              justify="center"
              colSpan={columns.length}
              cells={['No Data Found']}
              setSort={setSort}
              vendorId={vendorId}
            />
          )}
        </tbody>
      </Table>
      {showPagination && (
        <FlexBox gap="12px" pb="24px" justify="flex-end">
          <Button
            variant="textualPrimary"
            fontSize="13px"
            isDisabled={page < 2}
            onClick={() => page > 1 && onPaginationChange(page - 1)}
          >
            {`< PREV`}
          </Button>
          <Button
            variant="textualPrimary"
            fontSize="13px"
            isDisabled={list?.length !== limit}
            onClick={() =>
              list?.length === limit && onPaginationChange(page + 1)
            }
          >
            {`NEXT >`}
          </Button>
        </FlexBox>
      )}
    </$TableContainer>
  )
}

const $TableContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.l};
  table {
    margin: 0 0 ${({ theme }) => theme.spacing.xl};
  }
  ${space};
`

const $SortIcon = styled(DownArrowIcon)`
  cursor: pointer;
  transform: ${(props) => props?.isRotate && 'rotate(180deg)'};
  path {
    fill: ${(props) => props?.isActive && '#3B86FF'};
  }
`

const TabRow = ({
  cells,
  colSpan,
  align,
  padding,
  isStoreData,
  vendorId,
  setSort = () => {},
  ...props
}) => {
  const tableColumnList = (cell) => {
    if (!decathVendorIds.includes(vendorId)) {
      if (cell.length > 0) {
        let colHeader = []
        _.each(cell, (ele) => {
          if (ele !== 'Trees Saved' && ele !== 'Tons Co2 Saved') {
            colHeader.push(ele)
          }
        })
        return colHeader
      }
    }
    return cell
  }

  return (
    <TableRow {...props}>
      {tableColumnList(cells).map((cell, i) => (
        <TableCell padding={padding} colSpan={colSpan} key={i}>
          <FlexBox
            align="center"
            justify={props?.justify || (i === 0 ? 'flex-start' : 'center')}
            textAlign={`${i === 0 ? 'left' : align} !important`}
            gap="16px"
            onClick={() => setSort(i)}
            style={props?.sortCells?.[i] ? { cursor: 'pointer' } : {}}
          >
            {cell}
            {props?.sortCells?.[i] && (
              <FlexBox direction="column" gap="4px">
                <$SortIcon
                  isActive={props?.sortCells?.[i] === 'increase'}
                  isRotate
                />
                <$SortIcon isActive={props?.sortCells?.[i] === 'decrease'} />
              </FlexBox>
            )}
          </FlexBox>
        </TableCell>
      ))}
    </TableRow>
  )
}
