const initialState = {
    brands: [],
    selectedBrand:[],
    filterBrand:[]
};
  
export default function(state = initialState, action) {
    switch (action.type) {
    case "SET_BRANDS":
        return {
            ...state,
            brands: action.payload
        };
    case "SET_SELECTED_BRANDS":
        return {
            ...state,
            selectedBrand: action.payload
        }
    default:
        return state;
    }
}
  