import React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { spacing, borderRadius } from 'design-system/styles'

const VARIANT = {
  PRIMARY: 'primary',
  PRIMARY_OUTLINE: 'primaryOutline',

  TEXTUAL_PRIMARY: 'textualPrimary',
  TEXTUAL_ERROR: 'textualError',
  TEXTUAL_UNDERLINE: 'textualUnderline',

  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',

  CUSTOM: 'custom',
  PUBLISH: 'publish',
}

const StyledActiveCircle = styled.span`
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.default};
  font-size: 10px;
  border: 2px solid white;
  color: #fff;
`

const StyledButton = styled.button`
  padding: ${spacing.m} ${spacing.l};
  border-radius: ${borderRadius.small};
  color: ${({ theme }) => theme.colors.dark};
  border: 0;
  transition: all 0.2s;
  width: ${(props) => props.width || 'unset'};
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `};

  ${(props) =>
    props.variant === VARIANT.PRIMARY &&
    css`
      background-color: ${props.theme.colors.primary.default};
      color: ${props.theme.colors.white} !important;

      &:hover {
        background-color: ${props.theme.colors.primary.dark};
      }

      &:disabled {
        background-color: ${props.theme.colors.mercury};
        border-color: ${props.theme.colors.mercury};
        color: ${props.theme.colors.white};
      }

      .a,
      path {
        stroke: none;
        fill: white !important;
      }

      svg {
        fill: ${props.theme.colors.white};
      }
    `};

  ${(props) =>
    props.variant === VARIANT.PUBLISH &&
    css`
      background-color: ${props.theme.colors.success.default};
      color: ${props.theme.colors.white};

      &:hover {
        background-color: ${props.theme.colors.success.dark};
      }

      &:disabled {
        background-color: ${props.theme.colors.success.light};
      }

      svg {
        fill: ${props.theme.colors.white};
      }
    `};

  ${(props) =>
    props.variant === VARIANT.PRIMARY_OUTLINE &&
    css`
      padding: ${spacing.sm} ${spacing.l};
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.primary.default};
      border: 1px solid ${props.theme.colors.primary.default};

      &:hover {
        border-color: ${props.theme.colors.dark};
        color: ${props.theme.colors.dark};
        svg,
        .abc {
          fill: ${props.theme.colors.dark};
        }
      }

      &:disabled {
        background-color: ${props.theme.colors.mercury};
        border-color: ${props.theme.colors.mercury};
        color: ${props.theme.colors.white};
      }
    `};

  ${(props) =>
    props.variant === VARIANT.WARNING &&
    css`
      padding: ${spacing.sm} ${spacing.l};
      background-color: ${props.theme.colors.white};
      color: #e1b800;
      border: 1px solid #e1b800;
    `};

  ${(props) =>
    props.variant === VARIANT.TEXTUAL_PRIMARY &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${props.theme.colors.primary.default};

      ${(props) =>
        props.noBorder
          ? css`
              &:hover {
                color: ${props.theme.colors.primary.dark};
                svg {
                  fill: ${props.theme.colors.primary.dark};
                }
              }
              &:focus {
                outline: none;
              }
              &:disabled {
                color: ${props.theme.colors.primary.light};
                svg {
                  fill: ${props.theme.colors.primary.light};
                }
              }
            `
          : css`
              &:hover {
                border: 1px solid ${props.theme.colors.primary.default};
              }
            `}
    `};

  ${(props) =>
    props.variant === VARIANT.TEXTUAL_ERROR &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${props.theme.colors.error};

      ${(props) =>
        !props.noBorder &&
        css`
          &:hover {
            border: 1px solid ${props.theme.colors.primary.error};
          }
        `}
    `};

  ${(props) =>
    props.variant === VARIANT.TEXTUAL_UNDERLINE &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${props.theme.colors.error};

      ${(props) =>
        !props.noBorder &&
        css`
          &:hover {
            text-decoration: underline;
          }
        `}
    `};

  ${(props) =>
    props.variant === VARIANT.ERROR &&
    css`
      background-color: ${props.theme.colors.error};
      color: ${props.theme.colors.white};

      /* FIXME: proper colours */
      &:hover {
        /* background-color: ${props.theme.colors.primary.dark}; */
      }

      &:disabled {
        background-color: ${props.theme.colors.primary.light};
      }
    `};

  ${(props) =>
    props.variant === VARIANT.SUCCESS &&
    css`
      background-color: ${props.theme.colors.success.default};
      color: ${props.theme.colors.white};

      &:hover {
        background-color: ${props.theme.colors.success.dark};
      }

      &:disabled {
        background-color: ${props.theme.colors.success.light};
      }
    `};

  ${(props) =>
    props.variant === VARIANT.SECONDARY &&
    css`
      background-color: transparent;
      color: ${props.theme.colors.dark};
    `}

  ${(props) =>
    props.variant === VARIANT.TERTIARY &&
    css`
      font-size: 12px;
      padding: ${spacing.s};
      background-color: ${props.theme.colors.transparent};
    `}

  ${(props) =>
    props.variant === VARIANT.CUSTOM &&
    css`
      font-size: 12px;
      background: #ffffff;
      box-shadow: ${props.shadow && `0px 3px 6px ${props.theme.colors.alto}`};
      border: 1px solid #dbdbdb;
      border-radius: ${borderRadius.small};
      color: ${props.color};
      height: 44px;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${({ color }) => color};
    `};

  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `};

  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  ${space};
`

export const Button = ({
  children,
  startIcon: StartIconProp,
  endIcon: EndIconProp,
  isDisabled,
  isLoading,
  loadingText,
  iconWidth,
  iconSize,
  activeCircle,
  ...rest
}) => {
  const isButtonDisabled = isDisabled || isLoading

  const startIcon = () =>
    StartIconProp && (
      <StartIconProp
        width={iconSize || '15px'}
        height={iconSize || '15px'}
        style={{ marginRight: 10 }}
      />
    )
  const endIcon = () =>
    EndIconProp && (
      <EndIconProp
        width={iconWidth || '15px'}
        height="15px"
        style={{ marginLeft: 10 }}
      />
    )

  return (
    <StyledButton disabled={isButtonDisabled} {...rest}>
      {startIcon()}

      {children}

      {!!activeCircle && (
        <StyledActiveCircle>{activeCircle}</StyledActiveCircle>
      )}

      {endIcon()}
    </StyledButton>
  )
}
