import React, { Component } from 'react'
import BillDetails from './BillDetails'
import { PopupContainer } from 'components/Common'

const BillsPopup = (props) => {
  return (
    <PopupContainer {...props} width="900px" heading="View Bill">
      <BillDetails billData={props.billData} />
    </PopupContainer>
  )
}

export default BillsPopup
