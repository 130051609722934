import React from 'react'
import { Text, GreyText } from 'styledComponent/components/typography'
import {
  Progress,
  ProgressBar,
  Label,
  Labels,
  ProgressOutline,
} from 'styledComponent/components/progress'
import { LoaderIcon } from 'styledComponent/components/icons'
import { FlexBox, ColorIndicator } from 'styledComponent/components/elements'

const starColors = {
  five: '#76b271',
  four: '#185ae8',
  three: '#ffd92e',
  two: '#e87618',
  one: '#dc5858',
}

const FeedbackRating = ({ percentage, counts }) => (
  <div className="col-md-7 mt-3 mb-3">
    <Progress className="ml-4 mr-2">
      <Labels>
        <LabelCount
          width={`${percentage['5_star']}%`}
          count={counts['5_star']}
        />
        <LabelCount
          width={`${percentage['4_star']}%`}
          count={counts['4_star']}
        />
        <LabelCount
          width={`${percentage['3_star']}%`}
          count={counts['3_star']}
        />
        <LabelCount
          width={`${percentage['2_star']}%`}
          count={counts['2_star']}
        />
        <LabelCount
          width={`${percentage['1_star']}%`}
          count={counts['1_star']}
        />
      </Labels>
      <ProgressOutline>
        <ProgressBar
          width={`${percentage['5_star']}%`}
          color={starColors.five}
        />
        <ProgressBar
          width={`${percentage['4_star']}%`}
          color={starColors.four}
        />
        <ProgressBar
          width={`${percentage['3_star']}%`}
          color={starColors.three}
        />
        <ProgressBar
          width={`${percentage['2_star']}%`}
          color={starColors.two}
        />
        <ProgressBar
          width={`${percentage['1_star']}%`}
          color={starColors.one}
        />
      </ProgressOutline>
      <Labels>
        <ColorLabel
          width={`${percentage['5_star']}%`}
          color={starColors.five}
          rating={5}
        />
        <ColorLabel
          width={`${percentage['4_star']}%`}
          color={starColors.four}
          rating={4}
        />
        <ColorLabel
          width={`${percentage['3_star']}%`}
          color={starColors.three}
          rating={3}
        />
        <ColorLabel
          width={`${percentage['2_star']}%`}
          color={starColors.two}
          rating={2}
        />
        <ColorLabel
          width={`${percentage['1_star']}%`}
          color={starColors.one}
          rating={1}
        />
      </Labels>
    </Progress>
  </div>
)

const ColorLabel = ({ width, color, rating }) => (
  <Label width={width}>
    <ColorIndicator bgColor={color} as="span">
      &nbsp;
    </ColorIndicator>{' '}
    {rating} Star
  </Label>
)

const LabelCount = ({ width, count }) => (
  <Label width={width} style={{ position: 'static' }}>
    <GreyText as="span">({count})</GreyText>
  </Label>
)

const CampaignReport = (props) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <Text variant="h6" className="ml-4">
          Reports
        </Text>
      </div>
      {props.isStatsLoading && (
        <div className="col-md-12">
          <FlexBox justify="center">
            <LoaderIcon className="mx-2" /> <Text>Loading...</Text>
          </FlexBox>
        </div>
      )}
      {!props.isStatsLoading && props.stats && (
        <>
          <FeedbackRating
            percentage={props.stats.percentage}
            counts={props.stats}
          />
          <div className="col-md-5 mt-3 mb-3">
            <Progress className="ml-2 mr-5">
              <Labels>
                <Label>&nbsp;</Label>
              </Labels>
              <ProgressOutline>
                <ProgressBar
                  width={`${props.stats.percentage['forms_filled']}%`}
                  color="#3bb9ff"
                />
              </ProgressOutline>
              <Labels>
                <Label>
                  {props.stats.totalFilledForms} / {props.stats.totalForms}{' '}
                  filled
                </Label>
              </Labels>
            </Progress>
          </div>
        </>
      )}
    </div>
  )
}

export default CampaignReport
