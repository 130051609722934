import { ReactComponent as PageNotFound } from './not-found.svg'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useRouter } from 'hooks'

const PageNF = (props) => {
  const { history } = useRouter()

  useEffect(() => {
    if (
      (props?.storeLogin || props?.registeredFrom === 'shopify') &&
      !props?.isSubscribed &&
      !props?.onceOpened
    )
      history.push('/bills')
  })

  return <PageNotFound />
}

const mapStateToProps = (state) => {
  return {
    onceOpened: state.bRed.onceOpened,
    storeLogin: state.auth.user.storeLogin,
    isSubscribed:
      state.stores?.stores?.[Object.keys(state.stores?.stores)?.[0]]
        ?.isSubscribed,
    registeredFrom: state.vendorIds.selectedVendor.registeredFrom,
  }
}

export default connect(mapStateToProps)(PageNF)
