import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import { borderRadius } from 'design-system'
import { analyticsEnabledVendors } from 'components/conditionalComponent'

const moment = extendMoment(originalMoment)

const reportHeader = () => [
  'Report Name',
  'Description',
  'Brands',
  'Stores',
  'Time Frame',
  'Generation Date',
  'Actions',
]

const reportTypes = (email) => {
  const options = []
  if (analyticsEnabledVendors.PA.includes(email)) {
    options.push({
      label: 'Product Analytics',
      value: 'productAnalytics',
    })
  }
  if (analyticsEnabledVendors.CA.includes(email)) {
    options.push({
      label: 'Consumer Analytics',
      value: 'consumerAnalytics',
    })
  }
  if (
    analyticsEnabledVendors.CA.includes(email) ||
    analyticsEnabledVendors.PA.includes(email)
  ) {
    options.push({
      label: 'Business Analytics',
      value: 'businessAnalytics',
    })
  }
  if (analyticsEnabledVendors.CRM.includes(email)) {
    options.push({
      label: 'Consumer Profiling',
      value: 'consumerProfiling',
    })
  }
  return options
}

const initialFilter = {
  timeFrame: {
    id: 1,
    type: 'timeFrame',
    label: 'Time Frame',
    isActive: false,
    value: moment.range(moment().clone().startOf('day'), moment().clone()),
  },
  brands: {
    id: 2,
    type: 'brands',
    label: 'Brands',
    isActive: false,
    value: [],
  },
  stores: {
    id: 3,
    type: 'stores',
    label: 'Number of Stores',
    isActive: false,
    value: {
      min: 0,
      max: 100,
    },
  },
}

const initForm = {
  name: '',
  description: '',
  reportType: 'productAnalytics',
  emailList: [],
  vendorId: '',
  date: moment.range(moment().clone().startOf('day'), moment().clone()),
  brands: [],
  stores: [],
}

const popupStyle = {
  width: 'auto',
  minWidth: '50%',
  overflow: 'hidden',
  borderRadius: borderRadius.small,
}

const maxEmails = 10

const minDate = new Date(moment().subtract(90, 'd').format())

export {
  reportHeader,
  reportTypes,
  initialFilter,
  initForm,
  popupStyle,
  maxEmails,
  minDate,
}
