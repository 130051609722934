import React, { useState, useEffect } from 'react'
import { FlexBox } from 'styledComponent'

import { useSelectStore } from 'hooks'
import { Text, Input, useToasts, PopupContainer } from 'components/Common'
import { ERROR_DECODE } from '../constants'
import { useTheme } from 'styled-components'
import { exportCampaignReport } from 'redux/actions/autoEngageActions'
import { exportJourneyReport } from 'redux/actions/journeyActions'

const maxEmails = 5

export const SendEmailReport = ({ isPopOpen, setIsPopOpen, isJourney }) => {
  const [emailList, setEmailList] = useState([])
  const { toast } = useToasts()
  const { spacing } = useTheme()
  const {
    data: { _id: campaignId },
  } = useSelectStore('autoEngage.aeCampaignData')
  const {
    data: { _id: journeyId },
  } = useSelectStore('journey.jbDetails')
  const {
    data: { email: storeUserEmail },
  } = useSelectStore('auth.user')

  const onSendReport = async () => {
    try {
      const emList =
        emailList?.filter(
          (email) =>
            email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
        ) || []
      if (emList?.length === 0) throw 'Add at least one E-Mail'
      const exportFunction = (body) =>
        isJourney
          ? exportJourneyReport(journeyId, body)
          : exportCampaignReport(campaignId, body)
      const res = await exportFunction({
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        exportedBy: storeUserEmail,
        emailList: emList,
      })
      if (res?.message) {
        toast(res?.message, 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const addEmail = (emails) => {
    if (emails?.length > maxEmails) return
    return setEmailList(emails)
  }

  useEffect(() => () => setEmailList([]), [isPopOpen])

  return (
    <PopupContainer
      isPopOpen={!!isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Export Report"
      onSubmitClick={isPopOpen !== 'reportSent' && onSendReport}
    >
      {isPopOpen === 'reportSent' ? (
        <Text type="main" m={spacing.xl}>
          Your request is submitted successfully. We will notify you over E-Mail
          shortly. Thank you.
        </Text>
      ) : (
        <>
          <FlexBox gap={spacing.m}>
            <Text type="main" mb={spacing.l}>
              Enter your Email ID to receive the report.
            </Text>
            <Text type="grey" mb={spacing.l}>
              (Maximum upto {maxEmails} emails)
            </Text>
          </FlexBox>
          <Input
            variant="create-tags"
            label="E-Mail List"
            id="emailList"
            value={emailList}
            onChange={addEmail}
            placeholder={`Enter emails and press enter... (Maximum ${maxEmails} emails)`}
          />
        </>
      )}
    </PopupContainer>
  )
}
