import React from 'react'
import { useNode } from '@craftjs/core'
import ContentEditable from 'react-contenteditable'

import { TextSettings } from './TextSettings'

export const Text = ({ content, ...props }) => {
  const {
    connectors: { connect },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }))

  const {
    fontSize,
    color,
    backgroundColor,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    margin,
    textAlign,
    fontFamily,
  } = props

  const padding = props.allSidesPadding
    ? props.padding
    : `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${
        paddingLeft || 0
      }px`

  return (
    <ContentEditable
      style={{
        fontSize,
        color,
        backgroundColor,
        padding,
        margin,
        textAlign,
        fontFamily,
      }}
      innerRef={connect}
      html={content}
      disabled={!selected}
      tagName="p"
      onChange={(e) =>
        setProp((props) => (props.content = e.target.value), 500)
      }
    />
  )
}

export const TextDefaultProps = {
  content: 'Type your text here',
  fontSize: '16px',
  margin: 0,
  color: '#000',
  backgroundColor: 'rgba(255, 255, 255, 0)',
  textAlign: 'center',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  fontFamily: 'Arial, Helvetica, sans-serif',
}

Text.craft = {
  props: TextDefaultProps,
  related: {
    settings: TextSettings,
  },
}
