import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import LoadingOverlay from 'react-loading-overlay'
import 'css/content/companySelect.scss'
import 'css/content/brandSelect.scss'
import 'css/content/manageStores.scss'

class CustomPopup extends Component {
  constructor() {
    super()
    this.state = {
      isPopOpen: false,
    }
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ isPopOpen: true })
  }

  closeModal() {
    this.setState({ isPopOpen: false })
  }

  render() {
    const { isPopOpen } = this.state
    return (
      <Popup
        open={isPopOpen}
        onClose={this.closeModal}
        className="modal"
        contentStyle={this.props.contentStyle}
        overlayStyle={this.props.overlayStyle}
      >
        <div className="popupContent">
          <LoadingOverlay
            active={this.props.loadingStatus}
            spinner
            text="Processing your request..."
          >
            <div
              className={
                this.props.className ? this.props.className : 'modal_view'
              }
            >
              {this.props.headerComponent ? (
                this.props.heading
              ) : (
                <div className="modal_head advert">
                  <h4 className="text">{this.props.heading}</h4>
                </div>
              )}

              {this.props.children ? (
                this.props.children
              ) : (
                <div>Body View is missing...</div>
              )}

              {this.props.footer ? (
                this.props.footer
              ) : (
                <div className="modal_footer">
                  <div className="footer_btn">
                    <button className="cancelBtn" onClick={this.closeModal}>
                      Cancel
                    </button>

                    <button
                      className="payBtn"
                      onClick={() => this.props.onProceed()}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              )}
            </div>
          </LoadingOverlay>
        </div>
      </Popup>
    )
  }
}

export default CustomPopup
