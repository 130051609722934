import Terrible from './Terrible - filled.png'
import Bad from './Bad - filled.png'
import Average from './Average - filled.png'
import Good from './Good - filled.png'
import Excellent from './Excellent - filled.png'

import Absolutely from './Absolutely - Filled.svg'
import Maybe from './Maybe - filled.svg'
import NotLikely from './Not Likely - Filled.svg'

export const SmileyFilled3Arr = [NotLikely, Maybe, Absolutely]
export const SmileyFilled2Arr = [NotLikely, Absolutely]

export default [Terrible, Bad, Average, Good, Excellent]
