import styled, { keyframes } from 'styled-components'

const loading = keyframes`
    to{
        background-position: 200% 0%;
    }
`

export const Loader = styled.div`
    width: 100%;
    height: 20px;
    background-color: hsl(0, 0%, 95%);
    margin: 5px 0px;

    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: ${props => props.loaderColor 
            ? `linear-gradient(to right, transparent 0%, ${props.loaderColor} 50%, transparent 100%);`
            : "linear-gradient(to right, transparent 0%, hsl(0, 0%, 90%) 50%, transparent 100%);"
        };
        background-position: -100% 0;
        background-size: 50% 100%;
        background-repeat: no-repeat;
        animation: ${loading} 1s infinite;
        animation-timing-function: ease-in;
    }
`
