import React, { useState, useEffect } from 'react'

import EmailCampaign from './EmailCampaign'
import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'
import { CAMP_STEPS, EMAIL_INIT, CHECK_IF } from '../constants'
import { useCampaignApi } from '../components'
import { $WhiteContainer } from '../AutoEngagement.styled'

const EmailEngage = () => {
  const [emailForm, setEmailForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(emailForm, setEmailForm, EMAIL_INIT, 'email')

  useEffect(() => {
    const step = hideSteps
      ? { id: 5, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      Object.keys(body)?.length > 0 && campaignId && (await updCampaign())
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...emailForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        if (!(emailForm.senderDetails?.name || emailForm.senderDetails?.id))
          throw 'Please enter Sender Details'
        return (
          Object.keys(body)?.length > 0 &&
          (campaignId && campaignId !== 'create'
            ? await updCampaign()
            : await createCampaign())
        )
      }

      case 2: {
        const emailContent = { ...emailForm?.content }
        const check = CHECK_IF(
          ['subject', 'body.html', 'body.compressedValue'],
          emailContent
        )
        if (check) throw `Please enter Email ${check}`
        return await updateCall()
      }

      case 3: {
        const whomTo = { ...emailForm?.targetGroup }
        const check = CHECK_IF(['id', 'type'], whomTo)
        if (check) throw `Please choose ${check}`
        return await updateCall()
      }

      case 4: {
        const sendContent = { ...emailForm }
        if (!sendContent?.validity) throw `Please choose a send type`

        switch (sendContent.validity) {
          case 'immediate': {
            return await updateCall()
          }

          case 'oneTime': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          case 'recurring': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (!(sendContent?.repeatEvery?.days?.length > 0))
              throw `Please select at least one day`
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          default:
            return null
        }
      }

      default:
        return
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Email"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={CAMP_STEPS}
        onPreviousClick={fetchCampaignById}
        onSave={onSave}
        onPublish={onPublish}
        hideSteps={hideSteps}
        form={emailForm}
        campaignType="Email"
      >
        <EmailCampaign
          form={emailForm}
          setForm={setEmailForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}

export default EmailEngage
