import React from 'react'

import { EmailMessage } from './EmailMessage'
import { EmailPreviewnPublish } from './EmailPreviewnPublish'
import { CampaignDetails, WhomToSend, WhenToSend } from '../components'

const EmailCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails campaignType="E-mail" form={form} setForm={setForm} />
      )

    case 'Message':
      return <EmailMessage form={form} setForm={setForm} />

    case 'Whom to Send':
      return <WhomToSend form={form} setForm={setForm} uploadedFrom="email" />

    case 'When to Send':
      return <WhenToSend form={form} setForm={setForm} />

    case 'Preview & Publish':
      return (
        <EmailPreviewnPublish
          form={form}
          setForm={setForm}
          hideSteps={hideSteps}
        />
      )

    default:
      return null
  }
}

export default EmailCampaign
