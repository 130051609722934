import React from 'react'
import { ReactSortable } from 'react-sortablejs'

import { useTheme } from 'styled-components'
import { Div } from 'styledComponent'
import { DeleteIcon } from 'assets/icons'

import { $Position, $DeleteCarousel } from '../AutoEngagement.styled'
import { UploadBox } from './MediaUpload'

export const CarouselSort = ({
  carousel,
  setCarousel,
  acceptedFormats,
  couponPosition,
  couponId,
  couponPreview,
}) => {
  const theme = useTheme()
  const listCarousel = carousel.map((car, i) => ({
    ...car,
    id: i + 1,
  }))

  const setListCarousel = (arr) =>
    setCarousel(
      arr.map((car) => {
        delete car.id
        delete car.selected
        delete car.chosen
        return car
      })
    )

  const deleteCarouselImage = (img) => {
    const newCarousel = carousel.filter((car) => car.fileLocation !== img)
    setCarousel(newCarousel)
  }

  return (
    <>
      {carousel.length > 0 && (
        <ReactSortable
          style={{ display: 'flex', flexWrap: 'wrap' }}
          animation={200}
          delayOnTouchStart
          delay={2}
          list={listCarousel}
          setList={setListCarousel}
        >
          {listCarousel.map((baner, i) => (
            <Div
              width="200px"
              position="relative"
              m={theme.spacing.l}
              key={baner.id}
              style={{ cursor: 'move' }}
            >
              <$Position>{i + 1}</$Position>
              <$DeleteCarousel
                onClick={() => deleteCarouselImage(baner.fileLocation)}
              >
                <DeleteIcon />
              </$DeleteCarousel>
              <UploadBox
                hideBorder
                src={baner.fileLocation}
                uploadWidth="100%"
                uploadHeight="auto"
                acceptedFormats={acceptedFormats}
                couponId={baner.couponVisibility && couponId}
                couponPosition={couponPosition}
                couponPreview={couponPreview}
              />
            </Div>
          ))}
        </ReactSortable>
      )}
    </>
  )
}
