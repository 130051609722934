import React from 'react'

import { useSelectStore } from 'hooks'
import { getAllProducts } from 'redux/actions/productAnalyticsActions'
import { useAsyncRender } from '../hooks'
import TitleBlock from './TitleBlock'
import ProductsTable from './ProductsTable'
import TableControls from './TableControls'

/**
 * All Products page
 */
const AllProducts = () => {
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const { data: allProducts } = useSelectStore('productAnalytics.allProducts')
  const { data: date } = useSelectStore('productAnalytics.selectedDate')
  const { data: appliedProductsFilter } = useSelectStore(
    'productAnalytics.appliedProductsFilter'
  )
  const { data: selectedStores } = useSelectStore(
    'productAnalytics.selectedStores'
  )

  const [loading, fetchAction] = useAsyncRender({
    renderAction: getAllProducts,
    dependencies: [selectedStores, appliedProductsFilter, selectedBrand, date],
  })

  return (
    <>
      <TitleBlock />
      <TableControls
        loading={loading}
        action={fetchAction}
        limit={allProducts?.totalDocs}
        vendor={selectedVendor}
        brand={selectedBrand}
      />
      <ProductsTable
        loading={loading}
        action={fetchAction}
        allProducts={allProducts}
      />
    </>
  )
}

export default AllProducts
