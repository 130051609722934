import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import AvgBillingChart from 'components/Content/billingAnalytics/charts/AvgBillingChart'
import HighestLowestChart from 'components/Content/billingAnalytics/charts/HighestLowestChart'
import TotalSalesChart from 'components/Content/billingAnalytics/charts/TotalSalesChart'
import TotalTransactionsChart from 'components/Content/billingAnalytics/charts/TotalTransactionsChart'
import TransactionsStoreWiseChart from 'components/Content/billingAnalytics/charts/TransactionsStoreWiseChart'
import TransactionsChart from 'components/Content/billingAnalytics/charts/TransactionsChart'
import PaymentPopup from 'components/Content/Recharge/PaymentPopup'

class PopupMain extends Component {
  constructor(props) {
    super(props)
    this.state = { open: this.props.openPopup }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onPaymentStart = this.onPaymentStart.bind(this)
  }

  openModal() {
    this.setState({ open: true })
    this.props.onClick()
  }

  closeModal() {
    this.setState({ open: false })
    this.props.onClick()
  }

  onPaymentStart() {
    this.props.onPaymentProceed()
  }

  componentDidMount() {
    window.onkeydown = (event) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ open: false })
      }
    }
  }

  render() {
    return (
      <div>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          className="billAnalyticsPopup"
        >
          <div className="modal1">
            <img
              className="close"
              onClick={this.closeModal}
              src={require('../Content/profile/images/close.png')}
            />

            {this.props.popupType === 'AvgBillingChart' ? (
              <AvgBillingChart
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'HighestLowestChart' ? (
              <HighestLowestChart
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TotalSalesChart' ? (
              <TotalSalesChart
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TotalTransactionsChart' ? (
              <TotalTransactionsChart
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TransactionsChart' ? (
              <TransactionsChart
                onChartClick={this.props.onChartClick}
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'PaymentPopup' ? (
              <PaymentPopup
                onPaymentClicked={this.onPaymentStart}
                amount={this.props.paymentAmount}
                selectedDate={this.props.selectedDate}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TransactionsBm' ? (
              <TransactionsStoreWiseChart
                type="BM"
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TransactionsBmP' ? (
              <TransactionsStoreWiseChart
                type="BMPrint"
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TransactionsP' ? (
              <TransactionsStoreWiseChart
                type="Print"
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
            {this.props.popupType === 'TransactionsCW' ? (
              <TransactionsStoreWiseChart
                type="C.W"
                selectedDate={this.props.selectedDate}
                handleDateChange={this.props.handleDateChange}
                selectedStoreIds={this.props.selectedStoreIds}
              />
            ) : null}
          </div>
        </Popup>
      </div>
    )
  }
}

export default PopupMain
