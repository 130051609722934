import React from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $FlexBox, $Block, $ToolGroup, $ToolItem } from '../../Editor.styled'
import { LeftAlignIcon, RightAlignIcon, CenterAlignIcon } from 'assets/icons'
import PropertyHeader from '../../PropertyHeader'
import {
  PropertyBar,
  BorderStyle,
  BorderRadius,
  Padding,
} from 'builder/editor/common/PropertyBar'

export const ContainerSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
    align: 'center',
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header={id === ROOT_NODE ? 'Editor' : 'Container'}
        hideDelete={id !== ROOT_NODE && !props?.isColumn}
        onDelete={() => actions.delete(id)}
      />
      <$FlexBox justify="space-between">
        <p style={{ fontSize: '12px', marginBottom: '4px' }}>Alignment</p>
        <$ToolGroup>
          <$ToolItem
            isActive={props.textAlign === 'left'}
            onClick={() => setProp((props) => (props.textAlign = 'left'), 300)}
          >
            <LeftAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'center'}
            onClick={() =>
              setProp((props) => (props.textAlign = 'center'), 300)
            }
          >
            <CenterAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'right'}
            onClick={() => setProp((props) => (props.textAlign = 'right'), 300)}
          >
            <RightAlignIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <$FlexBox justify="space-between">
        <PropertyBar
          label="Width"
          propertyName="width"
          isFlex
          isPercent
          {...commonProps}
        />

        <PropertyBar
          label="Height"
          propertyName="height"
          isFlex
          isPercent
          {...commonProps}
        />
      </$FlexBox>

      <PropertyBar
        label="Container Background Colour"
        type="color"
        propertyName="background"
        isFlex
        justify="space-between"
        {...commonProps}
      />

      {Padding(props, setProp)}

      <PropertyBar
        label="Container Border Width"
        propertyName="borderWidth"
        isFlex
        isSplit
        type="number"
        justify="space-between"
        {...commonProps}
      />

      {BorderStyle(props, setProp, 'Container ')}

      <PropertyBar
        label="Container Border Colour"
        propertyName="borderColor"
        isFlex
        type="color"
        justify="space-between"
        {...commonProps}
      />

      {BorderRadius(props, setProp)}
    </$Block>
  )
}
