import React from 'react'
import { useTheme } from 'styled-components'
import { FlexBox } from 'styledComponent'
import {
  CarouselIcon,
  GifIcon,
  ImageIcon,
  LinkIcon,
  SurveyIcon,
  UserIcon,
  VoucherIcon,
  VideoIcon,
  YoutubeIcon,
} from 'assets/icons'
import { BuilderComponent } from 'components/Common'
import {
  $BlueBox,
  $ComponentTab,
  $MarginlessLine,
} from '../AutoEngagement.styled'

const builderComponents = [
  {
    id: 'link',
    icon: <LinkIcon />,
    text: 'Media Link',
  },
  {
    id: 'user',
    icon: <UserIcon />,
    text: 'User Attribute',
  },
  {
    id: 'coupon',
    icon: <VoucherIcon />,
    text: 'Coupon',
  },
  {
    id: 'survey',
    icon: <SurveyIcon />,
    text: 'Survey',
  },
  {
    id: 'image',
    icon: <ImageIcon />,
    text: 'Image',
  },
  {
    id: 'carousel',
    icon: <CarouselIcon />,
    text: 'Carousel',
  },
  {
    id: 'gif',
    icon: <GifIcon />,
    text: 'GIF',
  },
  {
    id: 'youtube',
    icon: <YoutubeIcon />,
    text: 'Youtube',
  },
  {
    id: 'video',
    icon: <VideoIcon />,
    text: 'Video',
  },
]

export const ComponentBox = ({ components, handleClick, activeComponent }) => {
  const theme = useTheme()

  return (
    <$BlueBox height="100%" minWidth="300px" padding={theme.spacing.m}>
      <$ComponentTab>Components</$ComponentTab>
      <$MarginlessLine mx={theme.spacing.s} />
      <FlexBox p={theme.spacing.m} gap={theme.spacing.ml} wrap="wrap">
        {builderComponents.map(
          (comp) =>
            components.includes(comp.id) && (
              <BuilderComponent
                key={comp.id}
                isActive={activeComponent === comp.id}
                handleClick={() => handleClick(comp.id)}
                icon={comp.icon}
                text={comp.text}
              />
            )
        )}
      </FlexBox>
    </$BlueBox>
  )
}
