import React from 'react'

import { CampaignDetails, WhenToSend, WhomToSend } from '../../components'
import WhatsAppTemplates from './Template'
import Message from './Message'
import Preview from './Preview'

const CampaignContainer = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          campaignType="WhatsApp"
          form={form}
          setForm={setForm}
        />
      )

    case 'Select Template':
      return <WhatsAppTemplates form={form} setForm={setForm} />

    case 'Message':
      return <Message form={form} setForm={setForm} />

    case 'Whom to Send':
      return (
        <WhomToSend form={form} setForm={setForm} uploadedFrom="whatsApp" />
      )

    case 'When to Send':
      return <WhenToSend form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <Preview form={form} setForm={setForm} hideSteps={hideSteps} />

    default:
      return null
  }
}

export default CampaignContainer
