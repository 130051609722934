import React from 'react'
import styled, { css } from 'styled-components'
import {
  space,
  layout,
  color,
  typography,
  position,
  borders,
  zIndex,
  flexbox,
  background,
  overflow,
} from 'styled-system'

export const Div = styled.div`
  ${layout};
  ${color};
  ${space};
  ${typography};
  ${position};
  ${borders};
  ${zIndex};
  ${flexbox};
  ${background};
  ${overflow};
`

export const HorizontalLine = styled.hr`
  border-top: 1px solid #cacaca;
`

export const FlexBox = styled.div`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || `left`};
  align-items: ${(props) => props.align || 'stretch'};
  align-self: ${(props) => props.alignSelf || 'auto'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  width: ${(props) => (props.expand ? '100%' : 'auto')};
  padding: ${(props) => props.padding || 'unset'};
  gap: ${(props) => props.gap || '0px'};
  flex: ${(props) => props.flex};
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${space};
  ${layout};
`

export const Box = styled(FlexBox)`
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  width: ${(props) =>
    ({
      tiny: '5px',
      small: '10px',
      medium: '20px',
      large: '30px',
      custom: props.customSize,
    }[props.size])};
  height: ${(props) =>
    ({
      tiny: '5px',
      small: '10px',
      medium: '20px',
      large: '30px',
      custom: props.customSize,
    }[props.size])};
  border-radius: ${(props) => props.borderRadius || '0px'};
  border: ${(props) => props.border || 'none'};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || ''};
  right: ${(props) => props.right || ''};
  bottom: ${(props) => props.bottom || ''};
  left: ${(props) => props.left || ''};
  padding: ${(props) => props.padding || ''};
  margin: ${(props) => props.margin || ''};
  text-align: ${(props) => props.textAlign || 'left'};
  background-color: ${(props) => props.bgColor || 'inherit'};
`

export const ScrollBox = styled.div`
  display: block;
  overflow: auto;
  height: ${(props) => props['height'] || `auto`};
  max-height: ${(props) => props['maxHeight'] || `auto`};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns || `unset`};
  grid-template-rows: ${(props) => props.rows || `unset`};
  column-gap: ${(props) => props.columnGap || '0px'};
  row-gap: ${(props) => props.rowGap || '0px'};
  align-items: ${(props) => props.align || 'start'};
  justify-items: ${(props) => props.justifyItems || 'start'};
  justify-content: ${(props) => props.justify || 'start'};
  background-color: ${(props) => props.bgColor || '#fff'};
  grid-auto-flow: ${(props) => props.direction};
  ${space};
  ${layout};
`

export const Block = styled.div`
  display: block;
  overflow: auto;
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${space};
`

export const RelativeBlock = styled(Block)`
  position: relative;

  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `};
`

export const DynamicBorderBox = styled(Box)`
  border-left: ${(props) =>
    props.isActive
      ? `6px solid ${props.borderColor}`
      : '6px solid transparent'};
  margin: 7px 10px;
  background: ${(props) => (props.isActive ? 'fff' : '#F5F4F4')};
  padding: 15px 20px;
  box-shadow: 0px 0px 3px 1px #c2c2c2;
  border-radius: 4px;
`

export const ColorIndicator = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${(props) => props.bgColor || '#fff'};
`

export const WhiteButton = styled.button`
  color: ${(props) => props.theme.colors.neutral['1000']};
  padding: 10px 15px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.neutral['100']};
  font-size: 1em;
  box-shadow: ${(props) =>
    props.boxShadow ? '0px 1px 20px hsla(0, 0%, 0%, 20%)' : 'none'};
`

export const ElementWithGap = styled.li`
  margin-right: ${(props) =>
    ({
      default: '10px',
      small: '20px',
      medium: '2em',
      big: '5em',
    }[props.gap || 'default'])};
  border-right: ${(props) => (props.dividingLine ? '1px solid black' : 'none')};
  padding-right: ${(props) => (props.dividingLine ? '10px' : '0px')};

  a {
    color: ${(props) => props.theme.colors.neutral['900']};
    text-decoration: none;
  }

  &:last-child {
    margin-right: 0;
    border-right: none;
    padding-right: 0;
  }
`

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: ${(props) => (props.expand ? '100%' : 'auto')};

  li {
    margin-top: 10px;
  }
`

export const UnorderedListPadded = styled.ul`
  list-style: none;
  width: ${(props) => (props.expand ? '100%' : 'auto')};

  li {
    margin-top: 10px;
  }
`

const StyledSteps = styled(FlexBox)`
  position: relative;

  &:before {
    content: '';
    width: 27px;
    height: 27px;
    display: block;
    position: absolute;
    z-index: -1;
    left: ${(props) => props.xPosition};
    background-color: #c4f6ff;
    border-radius: 50%;
    z-index: -1;
    transition: left 0.5s ease-in-out;
  }

  & > div:nth-child(2n) {
    &:after,
    &:before {
      content: '';
      width: 70px;
      height: 1px;
      background-color: #ccc;
      display: inline-block;
      vertical-align: middle;
    }

    &:after {
      margin: 0px 15px 0px 10px;
    }

    &:before {
      margin: 0px 10px 0px 15px;
    }
  }

  & > div:first-child,
  & > div:last-child {
    width: 10px;
  }
`

export const Steps = ({ total, page }) => {
  const parts = 100 / (total - 1)
  const [indicatorX, setIndicatorX] = React.useState('-12px')

  React.useEffect(() => {
    setIndicatorX(`calc(${(page - 1) * parts}% - ${10 + (page - 1) * 4}px)`)
  }, [page])

  return (
    <StyledSteps align="center" xPosition={indicatorX}>
      {[...new Array(total)].map((_item, i) => (
        <React.Fragment key={i}>
          <div>{i + 1}</div>
        </React.Fragment>
      ))}
    </StyledSteps>
  )
}
