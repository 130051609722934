import queryString from 'query-string'

export const getRootDomain = () =>
  window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')

class UrlUtils {
  constructor(url) {
    this.urlConfig = { arrayFormat: 'comma' }

    if (url) this.parsedUrl = queryString.parseUrl(url, this.urlConfig)
  }

  stringify = (data) => queryString.stringify(data, this.urlConfig)

  stringifyUrl = (data) => queryString.stringifyUrl(data, this.urlConfig)
}

export default UrlUtils
