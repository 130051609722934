import React from 'react'

import { spacing } from 'design-system'
import { SectionHeading } from 'components/Common/SectionHeading'
import { Box, BoxContent } from 'styledComponent/components/box'
import ProductIcon from '../../components/ProductIcon/ProductIcon'
import ProductTag from '../../components/ProductTag'
import { $FlexBox } from '../../ProductAnalytics.styled'
import { TAGS_HEADINGS } from './constants'
import { $Block, $InputBlock, $TagsBlock } from './ThumbnailAndTags.styled'

const ThumbnailAndTags = ({ productName, tags, onAddTag, onClearTag }) => {
  return (
    <Box padding="0" boxShadow>
      <BoxContent>
        <ProductIcon
          productTitle={productName}
          variant="large"
          height="300px"
          width="100%"
          bgColor={'#1EBCD1'}
          color={'#fff'}
        />
        <$Block padding={`${spacing.l} ${spacing.l} ${spacing.m}`}>
          <SectionHeading
            {...TAGS_HEADINGS}
            variant="p"
            subVariant="small"
            color="main"
            weight="bold"
          />
        </$Block>
        <$Block padding={spacing.m}>
          <$TagsBlock>
            <$FlexBox wrap="wrap">
              {tags?.map((tag, index) => (
                <ProductTag
                  key={index}
                  margin={`0 ${spacing.m} ${spacing.m} 0`}
                  onClear={() => onClearTag(index)}
                  roundedCorners
                  clearable
                >
                  {tag}
                </ProductTag>
              ))}
            </$FlexBox>
            <$InputBlock>
              <input onKeyUp={onAddTag} placeholder="Add Tags" />
            </$InputBlock>
          </$TagsBlock>
        </$Block>
      </BoxContent>
    </Box>
  )
}

export default ThumbnailAndTags
