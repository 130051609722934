export const getBillType = (ticketType) => {
  switch (ticketType) {
    case 'CINEPOLIS_FOOD':
      return 'F & B'
    case 'CINEPOLIS_TICKET':
      return 'Movie Ticket'
    default:
      return '--'
  }
}
export const getBookingId = (qr) => {
  if (qr) {
    const qrArr = qr.split(' ')
    return qrArr.length > 1 ? qrArr[1].replace(/[0]{5,}/, '') : '-'
  }
}
