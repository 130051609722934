import React from 'react'

import { SurveyPreview } from './SurveyPreview'
import { CampaignDetails } from '../../components'
import { SurveyBuilder } from 'builder/survey-builder'
import { SurveyAdditionalSettings } from './SurveyAdditionalSettings'

export const SurveyCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          showCampaignType
          hideCompany
          campaignType="Survey"
          form={form}
          setForm={setForm}
        />
      )

    case 'Survey Additional Settings':
      return <SurveyAdditionalSettings form={form} setForm={setForm} />

    case 'Survey Builder':
      return <SurveyBuilder form={form} setForm={setForm} />

    case 'Survey Preview':
      return (
        <SurveyPreview form={form} setForm={setForm} hideSteps={hideSteps} />
      )

    default:
      return null
  }
}
