import React from 'react'
import { useSelectStore } from 'hooks'
import { Avatar } from 'components/Common'
import { Block } from 'styledComponent'
import styled, { css } from 'styled-components'

const $BrandCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  input {
    width: 20px;
    height: 20px;
  }
  div {
    min-width: 35px;
    min-height: 35px;
    border-color: ${({ theme }) => theme.colors.darkMercury};
  }
`

const $SelectContainer = styled(Block)`
  width: 100%;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
      overflow-y: auto;
    `};
`

export const SelectBrand = ({ brands, setBrands }) => {
  const { data: allBrands } = useSelectStore('brand.brands')
  const { data: vendorBrandIds } = useSelectStore('vendorIds.vendorBrands')
  const vendorBrands = allBrands?.filter((ab) =>
    vendorBrandIds?.map((vb) => vb.value)?.includes(ab.brandId)
  )

  const handleCheckbox = (brandId) => {
    if (brands[0]?.brandId === brandId) return
    const br = vendorBrands.filter((brnd) => brnd.brandId === brandId)
    setBrands(br)
  }

  return (
    <$SelectContainer padding="20px" maxHeight="60vh">
      {vendorBrands.map((br) => (
        <$SelectContainer height="60px" padding="10px" key={br.brandId}>
          <$BrandCheckbox onClick={() => handleCheckbox(br.brandId)}>
            <input type="radio" checked={brands.includes(br)} />
            <Avatar src={br.brandLogo} />
            {br.name}
          </$BrandCheckbox>
        </$SelectContainer>
      ))}
    </$SelectContainer>
  )
}
