import React from 'react'
import Select from 'react-select'
import { spacing } from 'design-system'
import { Text } from 'components/Common'

export const VendorIdSelect = ({
  selectedVendor,
  vendorIds,
  onChange,
  isDisabled,
}) => {
  return vendorIds?.length > 1 ? (
    <>
      <Select
        value={selectedVendor}
        options={vendorIds}
        placeholder="Select Vendor"
        isSearchable
        isDisabled={isDisabled}
        onChange={onChange}
      />
    </>
  ) : null
}
