import { post } from 'utils/axiosHandler'
import * as types from '../types/usageAndInvoice'
import { USAGE_MAP } from 'components/Content/usageAndInvoices/constants'

export const setUsageTabCompany = (company) => (dispatch, state) => {
  dispatch({
    type: types.SET_USAGE_TAB_COMPANY_ID,
    payload: {
      ...company,
    },
  })
  const stores = state()
    .stores.storesArray.filter(
      (store) => store.companyId === company.value && store.isActive
    )
    .map((store) => store.storeId)
  dispatch(setUsageStoreIds(stores))
}

export const setUsageFilters = (data) => (dispatch) => {
  dispatch({
    type: types.SET_USAGE_FILTERS,
    payload: {
      ...data,
    },
  })
  dispatch(setUsagePagination(0))
}

export const setSortFilters = (data) => (dispatch) => {
  dispatch({
    type: types.SET_SORT_FILTERS,
    payload: {
      ...data,
    },
  })
}

export const setUsageDataByType = (data, type) => (dispatch) => {
  dispatch({
    type: types.SET_USAGE_DATA_BY_TYPE,
    payload: {
      type,
      ...data,
    },
  })
}

export const setViewType = (viewType) => (dispatch, state) => {
  dispatch({
    type: types.SET_VIEW_TYPE,
    payload: {
      viewType,
    },
  })
  dispatch(setUsageFilters(USAGE_MAP[viewType].filters))
  dispatch(setSortFilters({}))
  dispatch(setUsagePagination(0))
}
export const setUsageStoreIds = (storeIds) => (dispatch) => {
  dispatch({
    type: types.SET_USAGE_STORE_IDS,
    payload: {
      storeIds,
    },
  })
}

export const setUsagePagination = (page) => (dispatch) => {
  dispatch({
    type: types.SET_PAGINATION,
    payload: page,
  })
}

export const getUsageDataByType = (apiPayload) => async (dispatch, state) => {
  const type = state().usageAndInvoice.usageTab.viewType
  const companyId = state().usageAndInvoice.usageTab.company?.value

  const result = await post(`/company-usage/${companyId}/${type}`, {
    ...apiPayload,
  })

  dispatch(setUsageDataByType(result?.data, type))
}
