import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import {
  getTotalTransactions,
  getTotalSalesAndAvg,
  getBillingAnalyticsMaxMin,
} from '../../redux/actions/billingAnalyticsActions'
import { sendObject2 } from './billingAnalytics/utils'
import { getTotalNumbersCollected } from '../../redux/actions/oldCAnalyticsActions'
import { setSideBarLink } from '../../redux/actions/sidebarLinkActions'
import { getCompanies } from '../../redux/actions/payments'
import { filterCompanies } from '../../redux/actions/authActions'
import { getFeedbacksForDashboard } from '../../redux/actions/feedbackActions'
import { getActiveCampaignCount } from '../../redux/actions/autoEngageActions'
import { getActiveJourneyCount } from '../../redux/actions/journeyActions'
import '../../css/content/dashboard.scss'
import '../../css/content/liveAdvert.scss'
import { NewStarIcon } from '../../styledComponent/components/icons'
import Navbar from '../Layout/Navbar'
import { Skeleton } from '../Common'
import moment from 'moment'
import {
  Div,
  FlexBox,
  Box,
  Text,
  Block,
  Card,
  CardContent,
  TextualButton,
} from '../../styledComponent'
import { spacing } from 'design-system'
import {
  NYB_TYPES,
  TABLE_COLS,
} from './UserComplaints/ComplaintsList/constants'
import { TableHead, TableRow, TableCell, Table } from 'styledComponent'
import { useSelectStore } from 'hooks'
import { post } from 'utils/axiosHandler'

const Feedback = (props) => {
  const { feedback, starRating, user, email, visitedAt, displayAddress } = props
  return (
    <Card>
      <CardContent>
        <FlexBox justify={'space-between'}>
          <Text>{email ? email : user}</Text>
          <FlexBox className="ml-3">
            {[...Array(starRating).keys()].map((item, index) => (
              <NewStarIcon key={index} className="ml-0 mr-1" />
            ))}
          </FlexBox>
        </FlexBox>
        <FlexBox justify={'space-between'}>
          <Text bold color={'#12b9f3'}>
            {feedback || `-`}
          </Text>
          <Text color="#797979" variant="caption">
            {moment(visitedAt).format(`h:m a | D/M/YYYY`)}
          </Text>
        </FlexBox>
        <FlexBox justify={'space-between'}>
          <Text color="#797979" variant="caption">
            {displayAddress}
          </Text>
        </FlexBox>
      </CardContent>
    </Card>
  )
}

const Complaints = (props) => {
  const { complaints, loading } = props
  const columns = [
    {
      label: 'Store Name',
      value: 'storeId',
    },
    {
      label: 'Date',
      value: 'createdDate',
    },
    {
      label: 'Phone',
      value: 'phone',
    },
    {
      label: 'Complaint Type',
      value: 'type',
    },
  ]
  const {
    data: { stores },
  } = useSelectStore('stores')

  const _getStoreAddress = (storeId) => {
    return stores[storeId]?.displayAddress || ''
  }
  return (
    <Table margin="0px" style={{ boxShadow: 'unset' }}>
      <TableHead>
        <TableRow fontSize="16px">
          {columns.map(({ label }, index) => (
            <TableCell key={index}>{label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      {loading ? (
        <tbody>
          {Array(5)
            .fill()
            .map((_, index) => (
              <TableRow key={index} fontSize="16px">
                {columns.map((_, index) => (
                  <TableCell key={index}>
                    <Skeleton count={1} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </tbody>
      ) : null}
      {!loading && (
        <tbody>
          {complaints?.map((complaint) => (
            <TableRow key={complaint._id} fontSize="16px">
              {columns.map(({ value }, index) => (
                <TableCell key={index}>
                  {value === 'createdDate'
                    ? moment(complaint[value]).format('DD/MM/YYYY')
                    : value === 'storeId'
                    ? _getStoreAddress(complaint[value])
                    : value === 'type'
                    ? `Not Your Bill - ${NYB_TYPES[complaint[value]] || ''}`
                    : complaint[value] || ''}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      )}
    </Table>
  )
}

const Dashboard = (props) => {
  const today = new Date()
  const yesterDay = new Date(new Date().setDate(today.getDate() - 1))
  const [activeCampaigns, setActiveCampaigns] = useState(0)
  const [activeJournies, setActiveJournies] = useState(0)
  const [isFeedbacksLoading, setIsFeedbacksLoading] = useState(false)
  const [feedbacks, setFeedbacks] = useState([])
  const [isComplaintsLoading, setIsComplaintsLoading] = useState(false)
  const [complaints, setComplaints] = React.useState([])

  const activeStores = useSelector(
    (state) => state.stores.selectedStores.length
  )
  const balance = useSelector((state) =>
    state.company.companies
      .filter((v) => v.vendorId === props.selectedVendor.value)
      .reduce((acc, item) => acc + item.balance, 0)
  )
  const changeSidebarCurrent = (value) => {
    props.setSideBarLink(value)
  }
  const fetchComplaints = async () => {
    setIsComplaintsLoading(true)
    try {
      const complaintsRes = await post('/bill-complaints', {
        limit: 3,
        vendorId: props.selectedVendor.value,
      })
      setComplaints(complaintsRes?.data?.docs)
    } catch (error) {
      setComplaints([])
    } finally {
      setIsComplaintsLoading(false)
    }
  }
  const fetchFeedbacks = async () => {
    setIsFeedbacksLoading(true)
    try {
      let res = await getFeedbacksForDashboard(
        {
          limit: 3,
          requireFeedbackCount: false,
        },
        {
          loader: true,
        }
      )
      if (res.code) {
        setFeedbacks(res.data)
      }
    } catch (e) {
      console.error('feedback error', e.message, e.stack)
      setFeedbacks([])
    } finally {
      setIsFeedbacksLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (!props.storeLogin) {
          props.getTotalTransactions({
            ...sendObject2(today, null),
            storeIds: props.selectedStores,
          })

          props.getTotalSalesAndAvg({
            ...sendObject2(today, null),
            storeIds: props.selectedStores,
          })

          props.getTotalNumbersCollected({
            ...sendObject2(today, null),
            storeIds: props.selectedStores,
          })

          props.getBillingAnalyticsMaxMin({
            ...sendObject2(today, null),
            storeIds: props.selectedStores,
          })

          let journeyResponse = await getActiveJourneyCount(
            props.selectedVendor.value
          )
          setActiveJournies(journeyResponse ? journeyResponse.count : 0)
          let campaign_response = await getActiveCampaignCount(
            props.selectedVendor.value
          )
          setActiveCampaigns(
            campaign_response ? campaign_response.campaignCount : 0
          )
        }
        fetchFeedbacks()
        fetchComplaints()
      } catch (e) {
        console.error(e)
      }
    })()
  }, [props.selectedStores])

  const currency = props.currencyCode
  return (
    <>
      <Navbar />
      <Box padding="15px" bgColor="#FFF">
        <Div>
          <FlexBox p={spacing.l}>
            <Text variant="h5" type="main" bold>
              Dashboard
            </Text>
          </FlexBox>
        </Div>
        {!props.storeLogin && (
          <div className="sales_related_details">
            <FlexBox p={spacing.l} justify="space-between">
              <Text variant="h2" type="main" bold style={{ margin: '0em' }}>
                Today's Summary
              </Text>
            </FlexBox>
            <FlexBox p={spacing.l} justify="space-between">
              <Text color="#797979" variant="h2">
                Billing
              </Text>
            </FlexBox>
            <FlexBox p={spacing.l} justify="left">
              <Div
                style={{
                  padding: '0px 30px 5px 0px',
                  margin: '0px 10px 5px 0px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  <FormattedNumber
                    value={Math.round(props.bAnalytics.total.totalSales)}
                    style="currency"
                    notation="standard"
                    currency={currency}
                  />
                </Text>
                <Text variant="small"> Total Sales </Text>
              </Div>
              <Div
                style={{
                  padding: '0px 30px 5px 10px',
                  margin: '0px 10px 5px 15px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  <FormattedNumber
                    value={Math.round(props.bAnalytics.total.totalTransactions)}
                    style="unit"
                    notation="standard"
                  />
                </Text>
                <Text variant="small"> Total Transactions </Text>
              </Div>
              <Div
                style={{
                  padding: '0px 10px 5px 10px',
                  margin: '0px 10px 5px 10px',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  <FormattedNumber
                    value={Math.round(props.bAnalytics.total.avgBilling)}
                    style="currency"
                    notation="standard"
                    currency={currency}
                  />
                </Text>
                <Text variant="small"> Average Billing </Text>
              </Div>
            </FlexBox>
            <FlexBox p={spacing.l} justify="space-between">
              <Text color="#797979" variant="h2">
                Transactions
              </Text>
            </FlexBox>
            <FlexBox p={spacing.l} justify="left">
              <Div
                style={{
                  padding: '0px 30px 5px 0px',
                  margin: '0px 10px 5px 0px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {props.bAnalytics.noOfTransactions.BM}
                  <span style={{ marginLeft: '1em', color: '#00abf0' }}>
                    {props.bAnalytics.noOfTransactions.BM
                      ? Number(
                          (props.bAnalytics.noOfTransactions.BM /
                            props.bAnalytics.noOfTransactions.total) *
                            100
                        ).toFixed(2)
                      : 0}
                    %
                  </span>
                </Text>
                <Text variant="small" color="#00abf0">
                  BillMe
                </Text>
              </Div>
              <Div
                style={{
                  padding: '0px 30px 5px 10px',
                  margin: '0px 10px 5px 15px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {props.bAnalytics.noOfTransactions.print}
                  <span style={{ marginLeft: '1em', color: '#d7443e' }}>
                    {props.bAnalytics.noOfTransactions.print
                      ? Number(
                          (props.bAnalytics.noOfTransactions.print /
                            props.bAnalytics.noOfTransactions.total) *
                            100
                        ).toFixed(2)
                      : 0}
                    %
                  </span>
                </Text>
                <Text variant="small" color="#d7443e">
                  Print
                </Text>
              </Div>
              <Div
                style={{
                  padding: '0px 30px 5px 10px',
                  margin: '0px 10px 5px 15px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {props.bAnalytics.noOfTransactions.BMprint}
                  <span
                    style={{
                      marginLeft: '1em',
                      color: '#ffb241',
                      minWidth: '15%',
                    }}
                  >
                    {props.bAnalytics.noOfTransactions.BMprint
                      ? Number(
                          (props.bAnalytics.noOfTransactions.BMprint /
                            props.bAnalytics.noOfTransactions.total) *
                            100
                        ).toFixed(2)
                      : 0}
                    %
                  </span>
                </Text>
                <Text variant="small" color="#ffb241">
                  BillMe + Print
                </Text>
              </Div>
              <Div
                style={{
                  padding: '0px 10px 5px 10px',
                  margin: '0px 10px 5px 10px',
                  minWidth: '15%',
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                }}
              >
                <Text
                  variant="h5"
                  bold
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ textAlign: 'left' }}>
                    {props.bAnalytics.noOfTransactions.treesSaved}
                  </span>
                </Text>
                <Text
                  variant="h5"
                  bold
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span
                    style={{
                      color: '#12af1d',
                      fontWeight: '300',
                      fontSize: '13px',
                      marginLeft: '10px',
                    }}
                  >
                    Trees Saved ( Life Time )
                  </span>
                </Text>
                <Text
                  variant="h5"
                  bold
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ textAlign: 'left' }}>
                    {(
                      props.bAnalytics.noOfTransactions.co2 /
                      10000 /
                      1000
                    ).toFixed(2)}
                  </span>
                </Text>
                <Text
                  variant="h5"
                  bold
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span
                    style={{
                      color: '#12af1d',
                      fontWeight: '300',
                      fontSize: '13px',
                      marginLeft: '10px',
                    }}
                  >
                    Tons CO<sub>2</sub> Savings ( Life Time )
                  </span>
                </Text>
              </Div>
            </FlexBox>
            <FlexBox p={spacing.l} justify="space-between">
              <Text color="#797979" variant="h2">
                Store Summary
              </Text>
            </FlexBox>
            <FlexBox p={spacing.l} justify="left">
              <Div
                style={{
                  padding: '0px 30px 5px 0px',
                  margin: '0px 10px 5px 0px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {activeStores}
                </Text>
                <Text variant="small">Active Stores</Text>
              </Div>
              <Div
                style={{
                  padding: '0px 30px 5px 10px',
                  margin: '0px 10px 5px 15px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {activeCampaigns}
                </Text>
                <Text variant="small">Active Bill Campaigns</Text>
              </Div>
              <Div
                style={{
                  padding: '0px 10px 5px 10px',
                  margin: '0px 10px 5px 10px',
                  borderRight: '2px #DDD solid',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  {activeJournies}
                </Text>
                <Text variant="small">Active Journeys</Text>
              </Div>
              <Div
                style={{
                  padding: '0px 10px 5px 10px',
                  margin: '0px 10px 5px 10px',
                  minWidth: '15%',
                }}
              >
                <Text variant="h5" bold>
                  &#8377; {Number(balance.toFixed(4))}
                </Text>
                <Text variant="small">Company Balance</Text>
              </Div>
            </FlexBox>

            <FlexBox p={spacing.l} justify="space-between">
              <Text color="#797979" variant="h2">
                Feedback & Complaints
              </Text>
            </FlexBox>
            <FlexBox p={spacing.l} justify="space-between">
              <Div
                style={{
                  boxShadow: '0px 5px 10px 0px #ddd',
                  width: '75%',
                  padding: '20px',
                  marginRight: '50px',
                }}
              >
                <Text variant="h6" bold>
                  Recent Feedbacks
                </Text>
                <div
                  className="dashboardFeedbackContainer"
                  style={{ height: '16em' }}
                >
                  {isFeedbacksLoading ? (
                    <Skeleton height={75} count={3} />
                  ) : (
                    <>
                      {feedbacks && feedbacks.length > 0 ? (
                        feedbacks.map((data, i) => {
                          return (
                            <Feedback
                              key={i}
                              feedback={data.feedback}
                              starRating={data.starRating}
                              user={data.user}
                              visitedAt={data.feedbackDate}
                              displayAddress={data.displayAddress}
                            />
                          )
                        })
                      ) : (
                        <Text as="p" fontSize="small">
                          No feedback found
                        </Text>
                      )}
                    </>
                  )}
                </div>
                <FlexBox justify="center">
                  <TextualButton
                    onClick={() => {
                      props.history.push('/feedback')
                      changeSidebarCurrent('feedback')
                    }}
                  >
                    View More
                  </TextualButton>
                </FlexBox>
              </Div>
              <Div
                style={{
                  boxShadow: '0px 5px 10px 0px #ddd',
                  width: '100%',
                  padding: '20px',
                }}
              >
                <Text variant="h6" bold>
                  Recent Customer Complaints
                </Text>
                <div
                  className="dashboardFeedbackContainer"
                  style={{ height: '16em' }}
                >
                  {isComplaintsLoading ? (
                    <Skeleton height={75} count={3} />
                  ) : (
                    <>
                      {complaints && complaints.length > 0 ? (
                        <Complaints
                          loading={isComplaintsLoading}
                          complaints={complaints}
                        />
                      ) : (
                        <Text as="p" fontSize="small">
                          No complaints found
                        </Text>
                      )}
                    </>
                  )}
                </div>
                <FlexBox justify="center">
                  <TextualButton
                    onClick={() => {
                      props.history.push('/bill-complaints')
                      changeSidebarCurrent('userComplaints')
                    }}
                  >
                    View More
                  </TextualButton>
                </FlexBox>
              </Div>
            </FlexBox>
          </div>
        )}
      </Box>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    bAnalytics: state.bAnalytics,
    cAnalytics: state.cAnalyticsOld,
    errors: state.errors,
    userEmail: state.auth.user.email,
    currencyCode: state.auth.user.currencyCode,
    auth: state.auth,
    onceOpened: state.bRed.onceOpened,
    companies: filterCompanies(state, 'dashboard'),
    selectedBrand: state.advert.selectedBrandForAdvert,
    vendorIds: state.vendorIds.vendorIds.map((x) => x.value),
    selectedVendor: state.vendorIds.selectedVendor,
    selectedStores: state.stores?.selectedStores,
    isSubscribed:
      state.stores?.stores[Object.keys(state.stores?.stores)?.[0]]
        ?.isSubscribed,
    storeLogin: state.auth.user.storeLogin,
  }
}

export default connect(mapStateToProps, {
  getTotalTransactions,
  getTotalSalesAndAvg,
  getTotalNumbersCollected,
  getCompanies,
  setSideBarLink,
  getBillingAnalyticsMaxMin,
  getActiveJourneyCount,
})(withRouter(Dashboard))
