import React from 'react'
import { cloneDeep, isEqual } from 'lodash'

import { useStoreFetch, useSelectStore } from 'hooks'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'
import {
  SectionHeading,
  Input,
  Text,
  Dragger,
  Skeleton,
  Button,
  useToasts,
} from 'components/Common'
import { getSettingsData } from 'redux/actions/settingsActions'
import { patch, post } from 'utils/axiosHandler'
import { $DragHead } from './CRM.styled'

import {
  DATA_POINTS_HEADINGS,
  INITIAL_DATA,
  DATA_PRIORITY_HEADINGS,
  SETTING_TYPE,
} from './constants'

import {
  $SettingItem,
  $Container,
  $SettingBlock,
  $FlexBox,
  $Span,
} from '../Settings.styled'

const CRM = () => {
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const [crmData, setCrmData] = React.useState(null)
  const [savingState, setSavingState] = React.useState(false)

  const { toast } = useToasts()
  const { data, loading, fetchData } = useStoreFetch(
    () => getSettingsData(SETTING_TYPE),
    'settings.crm'
  )

  const onDataPointChange = (e) => {
    const tempState = cloneDeep(crmData)
    const index = tempState.settings.findIndex(
      ({ label }) => label === 'Data Point'
    )
    if (index > -1) {
      tempState.settings[index].key = Number(e.target.value)
      setCrmData(tempState)
    }
  }

  const onFieldPriorityReorder = (updatedValues) => {
    const tempState = cloneDeep(crmData)
    const index = tempState.settings.findIndex(
      ({ label }) => label === 'Field priority'
    )
    if (index > -1) {
      tempState.settings[index].key = updatedValues
      setCrmData(tempState)
    }
  }

  const _formatDraggableListData = (data) => {
    if (!data || !data.length) return []

    const formattedList = data.map((value) => {
      return {
        value,
        label: value.label
          ?.replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase()),
      }
    })

    return formattedList
  }

  const onSave = async () => {
    const body = cloneDeep(crmData)
    setSavingState(true)
    try {
      const url = `/settings${body.vendorId ? `/vendor/${body.vendorId}` : ''}`
      const method = body.vendorId ? patch : post
      if (body.vendorId) {
        delete body.vendorId
      } else {
        body.vendorId = selectedVendor.value
      }
      const saveResponse = await method(url, body)
      if (saveResponse.code) {
        toast('Changes successfully saved', 'success')
        fetchData()
      } else {
        toast(
          saveResponse?.message || 'Something went wrong, please try again',
          'error'
        )
      }
    } catch (error) {
      toast('Something went wrong, please try again', 'error')
    } finally {
      setSavingState(false)
    }
  }

  const onCancel = React.useCallback(() => {
    const stateToPopulate = {
      ...(data || {}),
      settings: data?.settings?.length ? data?.settings : INITIAL_DATA.settings,
    }
    setCrmData(stateToPopulate)
  }, [data])

  React.useEffect(() => {
    onCancel()
  }, [onCancel])

  return (
    <$SettingBlock>
      <$SettingItem borderBottom>
        <$Container>
          <FlexBox align="center" justify="space-between">
            <SectionHeading
              {...DATA_POINTS_HEADINGS}
              variant="medium"
              subVariant="small"
            />
            <FlexBox align="center">
              <Input
                variant="input"
                type="number"
                inputWidth="100px"
                value={
                  crmData?.settings?.find(({ label }) => label === 'Data Point')
                    ?.key || ''
                }
                onChange={onDataPointChange}
              />
              <$Span>Data Point</$Span>
            </FlexBox>
          </FlexBox>
        </$Container>
      </$SettingItem>
      <$SettingItem padding={`${spacing.xl} ${spacing.m}`}>
        <$Container width="350px">
          <SectionHeading
            {...DATA_PRIORITY_HEADINGS}
            variant="medium"
            subVariant="small"
          />
          {loading ? (
            <Skeleton count={5} />
          ) : (
            <>
              <$DragHead>
                <Text weight="semi-bold" type="main" variant="small">
                  Selected fields{' '}
                </Text>
                <Text type="grey" variant="small">
                  (DRAG TO REORDER){' '}
                </Text>
              </$DragHead>
              <Dragger
                list={_formatDraggableListData(
                  crmData?.settings?.find(
                    ({ label }) => label === 'Field priority'
                  )?.key
                )}
                getNewOrder={onFieldPriorityReorder}
              />
            </>
          )}
        </$Container>
      </$SettingItem>
      {!isEqual(data?.settings, crmData?.settings) && (
        <$FlexBox>
          <Button variant="primary" onClick={onSave} disabled={savingState}>
            SAVE
          </Button>
          <Button onClick={onCancel} variant="secondary">
            CANCEL
          </Button>
        </$FlexBox>
      )}
    </$SettingBlock>
  )
}

export default CRM
