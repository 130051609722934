import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { FormattedNumber } from 'react-intl'
import ReactPaginate from 'react-paginate'
import swal from 'sweetalert'
import { useToasts } from 'components/Common/Toast'

import { spacing } from 'design-system'
import {
  FlexBox,
  ExportXIcon,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from 'styledComponent'
import { $Container, $ContentDiv, $TableRow } from './Customers.styled'
import {
  getCustomersList,
  setCAnalyticsFilters,
  setFetchNewData,
  setCAPreviousSelected,
} from 'redux/actions/consumerAnalyticsActions'
import { useStoreFetch, useSelectStore } from 'hooks'
import { Filters } from './CustomerListFilter'
import {
  Skeleton,
  ActiveFilterPills,
  Text,
  Button,
  SectionHeading,
  StarRating,
} from 'components/Common'
import { initialFilter } from './constants'
import { VendorAndDateBar } from '../VendorAndDateBar'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { $SquareButton } from 'components/Content/consumerProfiling/CustomerProfiling'
import { processFilterData } from 'utils/consumerAnalytics'
import { post } from 'utils/axiosHandler'

const colsHeading = [
  'Phone Number',
  'Total Visit',
  'Last Visit',
  'Avg. Expense',
  'Total Expense',
  'Avg. Feedback',
]

const formatPillsData = (data) => {
  const pillsData = []

  if (!data || !Array.isArray(data)) return pillsData

  Object.values(data).map((items) => {
    if (items.isActive) {
      if (items.label === 'Avg. Feedback') {
        pillsData.push({
          id: items.id,
          type: 'star',
          label: items.label,
          value: items.value,
        })
      } else if (items.label === 'Gender') {
        const gender = []
        if (items.value.male) {
          gender.push('male')
        }
        if (items.value.female) {
          gender.push('female')
        }
        pillsData.push({
          id: items.id,
          type: 'conjunction',
          label: items.label,
          value: gender,
        })
      } else if (items.label === 'OS') {
        const os = []
        if (items.value.google) {
          os.push('android')
        }
        if (items.value.iphone) {
          os.push('iphone')
        }
        if (items.value.other) {
          os.push('other')
        }
        pillsData.push({
          id: items.id,
          type: 'conjunction',
          label: items.label,
          value: os,
        })
      } else if ('min' && 'max' in items.value) {
        pillsData.push({
          id: items.id,
          type: 'range',
          label: items.label,
          value: items.value,
        })
      } else if ('total' && 'avg' in items.value) {
        if (items.value.total.isActive) {
          const value = {
            min: items.value.total.min,
            max: items.value.total.max,
          }
          pillsData.push({
            id: items.id,
            type: 'expense',
            label: 'Total Expense',
            value,
          })
        } else {
          const value = {
            min: items.value.avg.min,
            max: items.value.avg.max,
          }
          pillsData.push({
            id: items.id,
            type: 'expense',
            label: 'Avg. Expense',
            value,
          })
        }
      } else if ('start' && 'end' in items.value) {
        pillsData.push({
          id: items.id,
          type: 'date',
          label: items.label,
          value: items.value,
        })
      } else {
        pillsData.push({
          id: items.id,
          type: 'count',
          label: items.label,
          value: items.value.length,
        })
      }
    }
  })

  return pillsData
}

const CustomersList = ({ isExplorePage, segmentTypes }) => {
  const [isExport, setIsExport] = useState(false)
  const popupRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const skip = 0
  const { data: selectedDate } = useSelectStore('cAnalytics.selectedDate')
  const { data: selectedStores } = useSelectStore('stores.selectedStores')
  const { data: userEmail } = useSelectStore('auth.user.email')

  const segmentIds = segmentTypes?.map((st) => st?.value) || []
  const fetchAction = () => getCustomersList(skip, { loader: true }, segmentIds)
  const {
    loading,
    data: customersList,
    fetchData,
  } = useStoreFetch(fetchAction, 'cAnalytics.customersList', true)
  const { data: shouldFetchNewData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewData'
  )

  const { data: filters } = useSelectStore('cAnalytics.filters')

  const { toast } = useToasts()

  React.useEffect(() => {
    const checkAndFetch = () => {
      if (shouldFetchNewData) {
        fetchData(fetchAction, true)

        dispatch(setFetchNewData(false))
      }

      dispatch(setCAPreviousSelected({ selectedDate, selectedStores }))
    }

    checkAndFetch()
  }, [selectedDate, selectedStores, shouldFetchNewData])

  React.useEffect(() => {
    fetchData(fetchAction, true)
  }, [segmentTypes])

  const rowClick = (id) => {
    if (isExplorePage)
      history.push({
        pathname: '/consumer-analytics/customers',
        search: `id=${id}`,
      })
    else
      history.push({
        search: `id=${id}`,
      })
  }

  const handlePageChange = async (data) => {
    const rowsPerPage = 10
    let skipValue = Math.ceil(data.selected * rowsPerPage)
    const reFetchAction = () => getCustomersList(skipValue, {})
    const isReFetch = true
    await fetchData(reFetchAction, isReFetch)
  }

  const handleSaveFilter = (data) => {
    popupRef.current.closeModal()
    dispatch(setCAnalyticsFilters(cloneDeep(data)))
    const reFetchAction = () => getCustomersList(skip)
    const isReFetch = true
    fetchData(reFetchAction, isReFetch)
  }

  const handleClearAllFilter = () => {
    const copyFilter = Object.values(cloneDeep(initialFilter))

    dispatch(setCAnalyticsFilters(cloneDeep(copyFilter)))
  }

  const handleExport = async (data, isWithoutFilter) => {
    try {
      popupRef.current.closeModal()

      const filters = processFilterData(data, isWithoutFilter)

      const body = {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        limit: 0,
        skip: 0,
        email: userEmail,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      if (filters?.segmentsValue) {
        body.segmentTypes = filters?.segmentsValue
        delete filters.segmentsValue
      }

      body.filters = filters

      const res = await post('/cAnalytics/customer/segment/export-list', body)

      // reset filters to initial state
      if (res.code) {
        swal(res.message || '')
      } else {
        toast(res.message || '')
      }
      const copyFilter = Object.values(cloneDeep(initialFilter))

      dispatch(setCAnalyticsFilters(cloneDeep(copyFilter)))
    } catch (error) {
      console.error(error)
      toast('Something went wrong, please try again', 'error')
    }
  }

  const handleClosePill = (pillToDelete) => {
    const copyFilter = Object.values(cloneDeep(filters))
    const defaultFilter = Object.values(cloneDeep(initialFilter))[
      pillToDelete - 1
    ]

    copyFilter[pillToDelete - 1] = defaultFilter
    dispatch(setCAnalyticsFilters(copyFilter))

    const reFetchAction = () => getCustomersList(skip)
    const isReFetch = true
    fetchData(reFetchAction, isReFetch)
  }

  const handleRemoveAll = () => {
    handleClearAllFilter()
    const reFetchAction = () => getCustomersList(skip)
    const isReFetch = true
    fetchData(reFetchAction, isReFetch)
  }

  return (
    <>
      {!isExplorePage && <VendorAndDateBar />}
      <$Container>
        <Header
          ref={popupRef}
          setIsExport={setIsExport}
          isExplorePage={isExplorePage}
        />
        <ActiveFilterPills
          pillData={formatPillsData(filters)}
          handleClosePill={handleClosePill}
          handleRemoveAll={handleRemoveAll}
          ref={popupRef}
        />
        <CustomersListTable
          colsHeading={colsHeading}
          customersData={customersList && customersList.data}
          isLoading={loading}
          rowClick={rowClick}
        />
        <CustomersListPagination
          customersData={customersList && customersList.data}
          customerCounts={customersList && customersList.customerCounts}
          handlePageChange={handlePageChange}
        />
        <Filters
          ref={popupRef}
          filters={filters}
          isExport={isExport}
          setIsExport={setIsExport}
          handleSaveFilter={handleSaveFilter}
          clearAllFilter={handleClearAllFilter}
          handleExport={handleExport}
        />
      </$Container>
    </>
  )
}

const Header = React.forwardRef(({ setIsExport, isExplorePage }, ref) => {
  return (
    <FlexBox justify="space-between" margin={`${spacing.l} 0`}>
      <SectionHeading
        variant="p"
        subVariant="small"
        color="main"
        weight="bold"
        heading="All Customers"
        subHeading="List of all customers in your system"
      />
      <$ContentDiv width="240px">
        <FlexBox justify="flex-end">
          {!isExplorePage && (
            <$SquareButton
              isFilter
              onClick={() => ref.current.openModal()}
              size={40}
            >
              <FilterIcon />
            </$SquareButton>
          )}
          <Button
            variant="primary"
            startIcon={ExportXIcon}
            onClick={() => {
              setIsExport(true)
              ref.current.openModal()
            }}
          >
            Export
          </Button>
        </FlexBox>
      </$ContentDiv>
    </FlexBox>
  )
})

const CustomersListTable = ({
  colsHeading,
  customersData,
  isLoading,
  rowClick,
}) => {
  return (
    <>
      {
        <Table>
          <TableHead>
            <TableRow>
              {colsHeading.map((heading) => (
                <TableCell key={heading} textAlign="center">
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <CustomersListTableRows
            customersData={customersData}
            isLoading={isLoading}
            rowClick={rowClick}
          />
        </Table>
      }
      {isLoading && <Skeleton count={10} height={50} />}
    </>
  )
}

const CustomersListTableRows = ({ customersData, isLoading, rowClick }) => {
  const {
    data: { currencyCode },
  } = useSelectStore('auth.user')
  return (
    <>
      <tbody>
        {!isLoading &&
          customersData?.length > 0 &&
          customersData.map((data) => (
            <$TableRow
              key={data._id}
              highlightOnHover="hsl(0, 0%, 97%)"
              onClick={() => rowClick(data._id)}
            >
              <TableCell textAlign="center">
                {(data._id && data._id === 'N.A') || data._id === null
                  ? '-'
                  : data._id}
              </TableCell>
              <TableCell textAlign="center">{data.visits}</TableCell>
              <TableCell textAlign="center">
                {moment(data.lastVisited).fromNow(true)}
              </TableCell>
              <TableCell textAlign="center">
                {data.avgAmountSpent ? (
                  <FormattedNumber
                    style="currency"
                    currency={currencyCode}
                    value={data.avgAmountSpent}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell textAlign="center">
                {data.totalAmountSpent ? (
                  <FormattedNumber
                    style="currency"
                    currency={currencyCode}
                    value={data.totalAmountSpent}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell textAlign="center">
                <FlexBox justify="center">
                  {data.avgFeedback ? (
                    <FlexBox margin={`0 ${spacing.s}`}>
                      <StarRating filled={parseInt(data.avgFeedback)} />
                    </FlexBox>
                  ) : (
                    '-'
                  )}
                </FlexBox>
              </TableCell>
            </$TableRow>
          ))}
      </tbody>
      {!isLoading && customersData?.length === 0 && (
        <tr>
          <TableCell textAlign="center" colSpan="6">
            No Customers Found
          </TableCell>
        </tr>
      )}
    </>
  )
}

const CustomersListPagination = ({
  customersData,
  customerCounts,
  handlePageChange,
}) => {
  const rowsPerPage = 10
  const pageCount = customerCounts / rowsPerPage

  if (!customersData) return null

  return (
    <FlexBox expand justify="center" align="center" margin={`${spacing.l} 0`}>
      {customersData && customersData.length > 0 && customerCounts > 1 ? (
        <FlexBox expand justify="space-between" align="center">
          <Text variant="small" type="grey">
            showing {customersData.length} - {customerCounts} customers
          </Text>
          <ReactPaginate
            previousLabel={'< Previous'}
            nextLabel={'Next >'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={2}
            onPageChange={(data) => handlePageChange(data)}
            containerClassName={'feedback-pagination notSelectableText'}
            activeClassName={'pagination_activePage'}
          />
        </FlexBox>
      ) : (
        !customersData &&
        customersData.length === 0 && <Text>No Customer Found</Text>
      )}
    </FlexBox>
  )
}

export default CustomersList
