import { cloneDeep } from 'lodash'
import { post } from 'utils/axiosHandler'

//Getting Max Min Amount
export const getBillingAnalyticsMaxMin = (data) => async (dispatch) => {
  try {
    const result = await post('/bAnalytics/maxMinAmount', data)
    if (result.code) dispatch(billingAnalyticsMaxMin(result))
  } catch (e) {
    console.log('Error:--', e)
  }
}

//Getting noOfTransactions
export const getTotalTransactions = (data) => async (dispatch) => {
  try {
    dispatch(transactionsLoading(true))
    const result = await post('/bAnalytics/noOfTransactionAll', data)
    if (result.code) dispatch(totalNoOfTransactions(cloneDeep(result)))
  } catch (e) {
    console.log('Error--', e)
  } finally {
    dispatch(transactionsLoading(false))
  }
}

//Getting total sales and avg billing amount
export const getTotalSalesAndAvg = (data) => async (dispatch) => {
  try {
    dispatch(totalLoading(true))
    const result = await post('/bAnalytics/totalSalesAndAvg', data)
    if (result.code) dispatch(getTotalSalesAndAvgHelper(result))
  } catch (e) {
    console.log('Error:--', e)
  } finally {
    dispatch(totalLoading(false))
  }
}

export const exportBAnalyticsReport = async (data) =>
  await post('/bAnalytics/export-data', data)

//Getting Max Min Amount
export const billingAnalyticsMaxMin = (maxMin) => {
  return {
    type: 'GET_BILLING_MAX_MIN',
    payload: maxMin,
  }
}

//Getting noOfTransactions
export const totalNoOfTransactions = (trans) => {
  return {
    type: 'GET_TOTAL_TRANSACTIONS',
    payload: cloneDeep(trans),
  }
}

//Getting Transactions Loading
export const transactionsLoading = (transactionLoading) => {
  return {
    type: 'GET_TRANSACTIONS_LOADING',
    payload: { transactionLoading },
  }
}

//Getting Total Loading
export const totalLoading = (totalLoading) => {
  return {
    type: 'GET_TOTAL_LOADING',
    payload: { totalLoading },
  }
}

//Getting total sales and avg billing amount helper
export const getTotalSalesAndAvgHelper = (total) => {
  return {
    type: 'GET_TOTAL_SALES_AND_AVG_BILLING',
    payload: total,
  }
}

export const exportStoreData = async (data, options) => {
  return post('/bAnalytics/stores/export-data', data, options)
}

export const getStoreData = async (data, options) => {
  return post('/bAnalytics/stores/stores-stats', data, options)
}

export const getGraphData = async (data, options) => {
  return post('/bAnalytics/stores/graph-data', data, options)
}
