import React from 'react'
import { isEqual } from 'lodash'
import styled from 'styled-components'

import { color, spacing, borderRadius } from 'design-system/styles'
import { Div } from 'styledComponent'
import { Text } from 'components/Common'
import SortSvg from 'assets/icons/sort-dot.svg'

const $DragContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing.l};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  > div {
    background-color: ${color.white};
    margin-bottom: ${spacing.s};
    display: flex;
    align-items: center;
    padding: ${spacing.l};
    position: relative;
    border-radius: ${borderRadius.small};
    border: 1px solid ${color.mercury};

    p {
      min-width: 25px;
    }
    span.dots {
      display: none;
      width: 6px;
      height: 10px;
      background-image: url(${SortSvg});
      position: absolute;
      right: 16px;
      cursor: pointer;
    }
    :hover {
      span.dots {
        display: block;
      }
    }
  }
`

export const Dragger = ({ list, getNewOrder }) => {
  return (
    <DragBox getNewOrder={getNewOrder}>
      {list.map(({ value, label, subLabel }, index) => (
        <div id={value} draggable key={index}>
          <Text type="main" variant="medium">
            {index + 1}.{' '}
          </Text>
          <Div>
            <Text type="main" variant="medium">
              {label}
            </Text>
            {subLabel && (
              <Text type="main" variant="medium">
                {subLabel}
              </Text>
            )}
          </Div>
          <span className="dots" />
        </div>
      ))}
    </DragBox>
  )
}

export class DragBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: 'React',
      draggedL: null,
      dragged: null,
      draggedOver: null,
      draggedOverL: null,
      content: this.props.children,
    }
  }

  static defaultProps = {
    getNewOrder: () => {},
  }

  dragStart = (ev) => {
    const dragged = ev.target
    if (document.getElementById(ev.target?.id)) {
      document.getElementById(ev.target.id).style.backgroundColor =
        color.success.default
    }
    const location = [].indexOf.call(ev.target.parentNode.children, ev.target)
    this.setState({ dragged: dragged, draggedL: location })
    ev.dataTransfer.effectAllowed = 'move'
  }

  onDrop = (ev) => {
    const { draggedL, draggedOverL, content } = this.state
    if (document.getElementById(ev.target?.id)) {
      document.getElementById(ev.target.id).style.backgroundColor = color.white
    }
    const newContent = [].concat(content)
    const movingValue = newContent.splice(draggedL, 1)[0]
    newContent.splice(draggedOverL, 0, content[draggedL])
    this.setState({ content: newContent })
    const updatedOrder = newContent.map((item) => item?.props?.id)
    this.props.getNewOrder(updatedOrder)
  }

  prevent = (ev) => {
    ev.preventDefault()
  }

  over = (ev) => {
    if (ev.target === this.state.draggedOver) return
    if (document.getElementById(ev.target?.id)) {
      document.getElementById(ev.target.id).style.backgroundColor = color.grey
    }
    const location = [].indexOf.call(ev.target.parentNode.children, ev.target)
    this.setState({ draggedOver: ev.target, draggedOverL: location })
  }

  dragLeave = (ev) => {
    if (document.getElementById(ev.target?.id)) {
      document.getElementById(ev.target.id).style.backgroundColor = color.white
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.children, this.props.children)) {
      this.setState({ content: this.props.children })
    }
  }

  render() {
    const draggableChildren = React.Children.map(this.state.content, (child) =>
      React.cloneElement(child, {
        onDragStart: this.dragStart,
        onDragLeave: this.dragLeave,
        onDragEnter: this.over,
        onDrop: this.onDrop,
        onDragOver: this.prevent,
      })
    )

    return <$DragContainer id="container">{draggableChildren}</$DragContainer>
  }
}
