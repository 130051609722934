import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { cloneDeep } from 'lodash'

import { Div } from 'styledComponent'
import { Text, Skeleton, Input } from 'components/Common'
import {
  AddBlueFilledIcon,
  CheckIcon,
  RemoveIcon,
  CloseIcon,
} from 'assets/icons'

import { $FlexBox, $FlexItem } from '../../ProductAnalytics.styled'
import {
  $Icon,
  $ProductList,
  $ProductListItem,
  $RemoveIconBlock,
} from './Products.styled'
import {
  getProducts,
  getProductsByCategory,
} from 'redux/actions/productAnalyticsActions'
import { useDebouncedEffect } from 'hooks'

const Products = ({ vendorId, brandId, setEditCategory, editCategory }) => {
  const [allProds, setAllProds] = useState([])
  const [allProdHasNextPage, setAllProdHasNextPage] = useState(true)
  const [allProdLimit, setAllProdLimit] = useState(20)
  const [allProdSearch, setAllProdSearch] = useState('')
  const [catProdSearch, setCatProdSearch] = useState('')
  const [scrollElemTo, setScrollElemTo] = useState(0)
  const [allLoading, setAllLoading] = useState(true)
  const [catLoading, setCatLoading] = useState(true)
  const [addProd, setAddProd] = useState({})
  const [delProdId, setDelProdId] = useState('')

  const { spacing } = useTheme()
  const isEdit = editCategory?.action === 'edit'
  const isNewCategory = editCategory?.categoryId === 'newCategory'
  const currentProdIds =
    cloneDeep(editCategory?.currentProducts)?.map((cur) => cur._id) || []

  const getAllProds = async () => {
    if (
      !editCategory?.categoryId ||
      !isEdit ||
      catLoading ||
      !allProdHasNextPage
    )
      return

    setAllLoading(true)
    const params = {
      vendorId,
      brandId,
      limit: allProdLimit,
      skip: 0,
      excludeProducts: [
        ...cloneDeep(currentProdIds),
        ...editCategory?.excludeProductIds,
      ],
    }

    if (allProdSearch) {
      params.searchTxt = allProdSearch
    }

    try {
      const res = await getProducts(params)
      if (res) {
        setAllProds(res?.docs || [])
        setAllProdHasNextPage(res?.hasNextPage)
        if (allProdLimit > 20) {
          setTimeout(() => {
            document.getElementById('scrollDiv').scrollTop = scrollElemTo
          }, 300)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setAllLoading(false)
    }
  }

  const getCatProds = async () => {
    const params = { vendorId, brandId, categoryId: editCategory?.categoryId }
    if (!editCategory?.categoryId || isNewCategory) return setCatLoading(false)
    setCatLoading(true)

    try {
      const res = await getProductsByCategory(params)
      const prods =
        (await res?.data?.map((pro) => {
          pro.categoryDetails = pro.category
          delete pro.category
          return pro
        })) || []
      setEditCategory((state) => ({
        ...cloneDeep(state),
        prevProducts: cloneDeep(prods),
        currentProducts: cloneDeep(prods),
      }))
    } catch (err) {
      console.error(err)
    } finally {
      setCatLoading(false)
    }
  }

  const onCategoryChange = () => {
    setAllProdHasNextPage(true)
    setCatLoading(true)
    setAllLoading(true)
    setAllProdLimit(20)
  }

  useDebouncedEffect(onCategoryChange, [editCategory?.categoryId], 0)
  useDebouncedEffect(getAllProds, [
    allProdSearch,
    editCategory?.categoryId,
    isEdit,
    catLoading,
    allProdLimit,
    allProdHasNextPage,
  ])
  useDebouncedEffect(getCatProds, [editCategory?.categoryId], 1)

  const onScrollElement = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setScrollElemTo(e.target.scrollTop)
      setAllProdLimit((lim) => lim + 20)
    }
  }

  const onProdAddClick = (product) => {
    if (product?.categoryDetails?._id) {
      setAddProd(product)
    } else {
      addProductToCategory(product)
    }
  }

  const addProductToCategory = (prod) => {
    setAddProd({})
    const currentProducts = [prod, ...editCategory.currentProducts]
    const newProducts = [prod, ...editCategory.newProducts]
    setEditCategory((state) => ({
      ...cloneDeep(state),
      currentProducts,
      newProducts,
    }))
  }

  const onProductRemove = () => {
    const currentProducts = [...editCategory?.currentProducts]?.filter(
      (prod) => prod._id !== delProdId
    )
    const newProducts = [...editCategory?.newProducts]?.filter(
      (prod) => prod._id !== delProdId
    )
    const excludeProductIds = [...editCategory?.excludeProductIds, delProdId]
    setEditCategory((state) => ({
      ...cloneDeep(state),
      newProducts,
      currentProducts,
      excludeProductIds,
    }))
    setDelProdId('')
  }

  const onProdSearchChange = (e) => {
    setAllProdLimit(20)
    setAllProdHasNextPage(true)
    setAllProdSearch(e.target.value)
  }

  if (!editCategory?.categoryId) return null

  return (
    <$FlexBox width="100%">
      {isEdit && (
        <Div width="50%" py={spacing.m} px={spacing.l}>
          <Input
            showSearch
            variant="input"
            inputWidth="100%"
            label="All Products"
            containerMargin="0px"
            value={allProdSearch}
            onChange={onProdSearchChange}
            placeholder="Search for products to add"
          />
          {allLoading ? (
            <Skeleton count={7} height="50px" />
          ) : (
            <$ProductList
              id="scrollDiv"
              onScroll={onScrollElement}
              isEdit={isEdit}
            >
              {allProds?.map(
                (product) =>
                  !currentProdIds?.includes(product._id) && (
                    <$ProductListItem
                      key={product._id}
                      active={addProd?._id === product._id}
                    >
                      <>
                        {addProd?._id === product._id ? (
                          <>
                            <$FlexItem width="100%">
                              <Text
                                variant="small"
                                type="primary"
                                weight="semi-bold"
                                ellipsis
                              >
                                Add in this category instead?
                              </Text>
                            </$FlexItem>
                            <$FlexBox
                              width="70px"
                              justify="space-between"
                              align="center"
                            >
                              <$Icon
                                onClick={() => addProductToCategory(product)}
                              >
                                <CheckIcon />
                              </$Icon>
                              <$Icon onClick={() => setAddProd({})}>
                                <CloseIcon />
                              </$Icon>
                            </$FlexBox>
                          </>
                        ) : (
                          <>
                            <$FlexItem flexBasis="unset">
                              <Text
                                title={product.productName}
                                variant="p"
                                type="main"
                                ellipsis
                              >
                                {product.productName}
                              </Text>
                            </$FlexItem>
                            <$FlexItem flexBasis="100px">
                              <$FlexBox justify="flex-end" align="center">
                                {product?.categoryDetails?._id && (
                                  <$FlexItem flexBasis="80px">
                                    <Text variant="tiny" type="main">
                                      Already in use
                                    </Text>
                                  </$FlexItem>
                                )}
                                <$RemoveIconBlock>
                                  <AddBlueFilledIcon
                                    onClick={() => onProdAddClick(product)}
                                  />
                                </$RemoveIconBlock>
                              </$FlexBox>
                            </$FlexItem>
                          </>
                        )}
                      </>
                    </$ProductListItem>
                  )
              )}
            </$ProductList>
          )}
        </Div>
      )}
      <Div width={isEdit ? '50%' : '100%'} py={spacing.m} px={spacing.l}>
        {isEdit && (
          <Input
            showSearch
            variant="input"
            inputWidth="100%"
            value={catProdSearch}
            containerMargin="0px"
            placeholder="Search for products to remove"
            label={`Products (${editCategory?.category})`}
            onChange={(e) => setCatProdSearch(e.target.value)}
          />
        )}
        {catLoading ? (
          <Skeleton count={7} height="50px" />
        ) : (
          <$ProductList isEdit={isEdit}>
            {editCategory?.currentProducts?.map(
              (product) =>
                product.productName
                  ?.toLowerCase()
                  ?.includes(catProdSearch?.toLowerCase()) && (
                  <$ProductListItem
                    key={product._id}
                    active={delProdId === product._id}
                  >
                    <>
                      {delProdId === product._id ? (
                        <>
                          <$FlexItem width="100%">
                            <Text
                              variant="p"
                              type="primary"
                              weight="semi-bold"
                              ellipsis
                            >
                              Remove from category?
                            </Text>
                          </$FlexItem>
                          <$FlexBox
                            width="70px"
                            justify="space-between"
                            align="center"
                          >
                            <$Icon onClick={onProductRemove}>
                              <CheckIcon />
                            </$Icon>
                            <$Icon onClick={() => setDelProdId('')}>
                              <CloseIcon />
                            </$Icon>
                          </$FlexBox>
                        </>
                      ) : (
                        <>
                          <$FlexItem width="100%">
                            <Text
                              title={product.productName}
                              variant="p"
                              type="main"
                              ellipsis
                            >
                              {product.productName}
                            </Text>
                          </$FlexItem>
                          {isEdit && (
                            <$FlexItem flexBasis="25px">
                              <$RemoveIconBlock>
                                <RemoveIcon
                                  onClick={() => setDelProdId(product._id)}
                                />
                              </$RemoveIconBlock>
                            </$FlexItem>
                          )}
                        </>
                      )}
                    </>
                  </$ProductListItem>
                )
            )}
          </$ProductList>
        )}
      </Div>
    </$FlexBox>
  )
}

export default Products
