import React from 'react'
import Select, { components } from 'react-select'

import { Input, Text } from 'components/Common'
import { spacing } from 'design-system'
import { CATEGORY_TYPES } from './constants'
import { GET_SEL_VALUE } from 'utils/reusables'
import { ReactComponent as DropdownIcon } from 'images/down-arrow.svg'
import { Div } from 'styledComponent'

const TemplateForm = ({ form, setForm, waAccounts }) => {
  React.useEffect(() => {
    setForm({
      name: form.name || '',
      description: form.description || '',
      category: form.category || '',
      settingAccountId: form.settingAccountId || waAccounts?.[0]?.value || '',
      ...form,
    })
  }, [waAccounts])

  const onInputChange = (e, key) => {
    let value = e?.target?.value
    if (key === 'name' && value) {
      value = value.replace(/[^\w]/gim, '')
    }

    if (key === 'settingAccountId') {
      value = e.value
    }

    setForm({
      ...form,
      [key]: key === 'category' ? e : value,
    })
  }

  return (
    <>
      <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
        Template Details
      </Text>
      <Div width="300px">
        <Input label="Select WhatsApp Account" inputWidth="300px">
          <Select
            options={waAccounts}
            placeholder="WhatsApp Account"
            value={GET_SEL_VALUE(form?.settingAccountId, waAccounts) || ''}
            onChange={(e) => onInputChange(e, 'settingAccountId')}
            components={{ DropdownIndicator }}
            isSearchable
          />
        </Input>
      </Div>
      <Input
        label={'WhatsApp Template Name'}
        variant={'input'}
        value={form?.name}
        onChange={(e) => onInputChange(e, 'name')}
        inputWidth="300px"
        maxLength="60"
      />
      <Input
        label={'Template Description'}
        variant={'textarea'}
        value={form?.description}
        onChange={(e) => onInputChange(e, 'description')}
        inputWidth="500px"
        rows="3"
        maxLength="100"
      />
      <Input
        label={'Template Category'}
        variant={'radio'}
        value={form?.category}
        onChange={(e) => onInputChange(e, 'category')}
        options={CATEGORY_TYPES}
      />
    </>
  )
}

export default TemplateForm

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon width="15px" height="15px" />
    </components.DropdownIndicator>
  )
}
