import React from 'react'
import { useTheme } from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'

import { Div, FlexBox } from 'styledComponent'
import { Text, Input, Tooltip, Button } from 'components/Common'
import { Slider } from 'components/Common/Slider'
import {
  getSettingsData,
  updateSettingsData,
} from 'redux/actions/settingsActions'
import { useSelectStore } from 'hooks'
import { _selectOptionsGenerator } from 'utils/formatter'
import { useToasts } from 'components/Common/Toast'

export const BillExplicitSettings = () => {
  const [userDefinedSettings, setUserDefinedSettings] = React.useState([])

  const theme = useTheme()
  const dispatch = useDispatch()
  const { data: explicitSettings } = useSelectStore(
    'settings.serviceExplicit.settings'
  )
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')
  const { toast } = useToasts()

  React.useEffect(() => {
    dispatch(getSettingsData('serviceExplicit'))
  }, [])

  React.useEffect(() => {
    setUserDefinedSettings(explicitSettings)
  }, [explicitSettings])

  const handleMessageChange = (e) => {
    const copySettings = cloneDeep(userDefinedSettings)

    copySettings[2] = {
      ...copySettings[2],
      key: {
        ...copySettings[2].key,
        customMessage: e.target.value,
      },
    }
    setUserDefinedSettings(copySettings)
  }

  const handleRadioChange = (value, index) => {
    const copySettings = cloneDeep(userDefinedSettings)
    copySettings[index] = {
      ...copySettings[index],
      key: {
        ...copySettings[index].key,
        value,
      },
    }
    setUserDefinedSettings(copySettings)
  }

  const handleSubmitClick = async (e) => {
    e.preventDefault()

    const body = {
      type: 'serviceExplicit',
      vendorId,
      settings: cloneDeep(userDefinedSettings),
    }

    try {
      const res = await dispatch(updateSettingsData(body))

      if (res.data.code) {
        toast('Settings have been saved successfully', 'success')
      }
    } catch (e) {
      toast('Some error occurred. Please try again', 'error')
      console.log(e)
    }
  }

  const handleCancelClick = () => {
    setUserDefinedSettings(explicitSettings)
  }

  return (
    <Div
      p={theme.spacing.l}
      bg={theme.colors.doveGray}
      width="100%"
      height="100%"
      position="relative"
    >
      {userDefinedSettings &&
        userDefinedSettings.length &&
        userDefinedSettings.map((item, i) => {
          const options = _selectOptionsGenerator(item?.key?.label?.split('|'))
          const currentValue = item?.key?.value
            ? options?.[0]?.value
            : options?.[1]?.value
          const currentMessage = item?.key?.value
            ? item?.key?.defaultMessage
            : item?.key?.customMessage

          return (
            <>
              <Input
                label={item?.label}
                variant={item?.key?.type}
                options={options}
                gap="48px"
                value={currentValue}
                onChange={(value) =>
                  handleRadioChange(value === options?.[0]?.value, i)
                }
              />
              {i === 2 && (
                <Input
                  variant="textarea"
                  placeholder={item?.key?.defaultMessage}
                  rows={5}
                  inputWidth="500px"
                  value={currentMessage}
                  onChange={handleMessageChange}
                  disabled={item?.key?.value}
                />
              )}
            </>
          )
        })}

      <div
        style={{
          position: 'absolute',
          bottom: theme.spacing.xl,
          right: theme.spacing.xl,
        }}
      >
        <Button
          color={theme.colors.shipGray}
          mr={theme.spacing.l}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmitClick}>
          Save
        </Button>
      </div>
    </Div>
  )
}
