import React, { Component } from 'react'
import DateRangePicker from 'react-daterange-picker'
import Select from 'react-select'
import moment from 'moment'
import { DynamicBorderBox, FlexBox } from 'styledComponent/components/elements'
import { CheckBox } from 'styledComponent/components/input'
import { GreyText } from 'styledComponent/components/typography'
import CalendarIcon from 'images/calendar.svg'
import { hours, timeState } from 'utils/feedback'
import { CreateTags } from './CreateTags'
export class DateFilter extends Component {
  render() {
    return (
      <DynamicBorderBox
        isActive={this.props.isActive}
        borderColor={this.props.borderColor}
        flex
      >
        <FlexBox style={{ width: '50%', paddingRight: '10px' }} align="center">
          <CheckBox
            id="date"
            checked={this.props.isActive}
            onClick={(e) => this.props.handleCheckboxClick(e, 'date')}
            margin={this.props.checkboxMargin}
          >
            <GreyText as="span">{this.props.title}</GreyText>
          </CheckBox>
          <div>
            <div>
              <FlexBox
                as="button"
                type="button"
                justify="space-around"
                onClick={this.props.onDatePickerClick}
                disabled={!this.props.isActive}
                align="center"
                style={{
                  background: 'none',
                  padding: '5px 10px',
                }}
              >
                <img style={{ width: '5%' }} src={CalendarIcon} />
                <div className="startDate" style={{ fontSize: '0.7em' }}>
                  {this.props.startDate}
                </div>
                <div className="endDate" style={{ fontSize: '0.7em' }}>
                  - {this.props.endDate}
                </div>
              </FlexBox>
              {this.props.isDatePickerOpen && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '100',
                    backgroundColor: '#fff',
                  }}
                >
                  <DateRangePicker
                    value={this.props.dateValue}
                    onSelect={this.props.onDateSelect}
                    singleDateRange={true}
                    maximumDate={moment().toDate()}
                  />
                </div>
              )}
            </div>
          </div>
        </FlexBox>

        {!this.props.isTimeAbsent && (
          <FlexBox align="center">
            <div className="sub-filter-name mr-2">Time</div>
            <GreyText as="span" fontSize="small" margin="0px 10px 0px 0px">
              (optional):
            </GreyText>
            <select
              className="form-control filter-select mr-2"
              value={this.props.startTimeHours}
              disabled={!this.props.isActive}
              onChange={(event) =>
                this.props.onTimeChange('date', 'startTime', event.target.value)
              }
            >
              {hours.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <select
              className="form-control filter-select mr-2"
              value={this.props.startTimeState}
              disabled={!this.props.isActive}
              onChange={(event) =>
                this.props.onTimeChange(
                  'date',
                  'startTimeState',
                  event.target.value
                )
              }
            >
              {timeState.map((option, i) => (
                <option value={option}>{option}</option>
              ))}
            </select>
            <div className="sub-filter-name mr-2">to</div>
            <select
              className="form-control filter-select mr-2"
              value={this.props.endTimeHours}
              disabled={!this.props.isActive}
              onChange={(event) =>
                this.props.onTimeChange('date', 'endTime', event.target.value)
              }
            >
              {hours.map((option, i) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
            <select
              className="form-control filter-select"
              value={this.props.endTimeState}
              disabled={!this.props.isActive}
              onChange={(event) =>
                this.props.onTimeChange(
                  'date',
                  'endTimeState',
                  event.target.value
                )
              }
            >
              {timeState.map((option, i) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </FlexBox>
        )}
      </DynamicBorderBox>
    )
  }
}

export const Filter = ({
  isFilterActive,
  borderColor,
  filterId,
  filterLabel,
  onCheckboxClick,
  isMulti = false,
  selectOption = [],
  selectValue = '',
  onSelectChange,
  filterStyles = {},
  type = 'select',
  // type = 'numeric',
  // type = 'numericrange',
  onValueChange,
  keyNames = '',
  inputValue,
  // fieldCount = 'one',
  placeholder,
  ...rest
}) => {
  const getFilterType = (inputType, id, isActive) => {
    let filterCheck =
      typeof isFilterActive === 'boolean' ? isFilterActive : isActive

    if ((inputType && inputType === 'select') || type === 'select')
      return (
        <Select
          isDisabled={!isFilterActive}
          isMulti={isMulti}
          options={selectOption}
          value={selectValue}
          onChange={onSelectChange}
          styles={{ ...filterStyles }}
        />
      )
    else if (
      (inputType && inputType === 'numericrange') ||
      type === 'numericrange'
    )
      return (
        <div>
          <input
            type="number"
            onChange={(e) => onValueChange(e, filterId, 'start', id)}
            style={{ width: '80px', marginRight: '10px' }}
            disabled={!filterCheck}
            value={filterCheck && inputValue.start}
          />
          {'-'}
          <input
            type="number"
            onChange={(e) => onValueChange(e, filterId, 'end', id)}
            style={{ width: '80px', marginLeft: '10px' }}
            disabled={!filterCheck}
            value={filterCheck && inputValue.end}
          />
        </div>
      )
    else if ((inputType && inputType === 'numeric') || type === 'numeric')
      return <input type="number" disabled={!isFilterActive} />
    else if (inputType === 'tags' || type === 'tags')
      return (
        <CreateTags
          isEmail
          disabled={!filterCheck}
          value={inputValue}
          onChange={onValueChange}
          placeholder={placeholder}
        />
      )
  }

  return (
    <DynamicBorderBox
      isActive={isFilterActive}
      borderColor={borderColor}
      {...rest}
    >
      {!Array.isArray(filterLabel) && (
        <>
          <CheckBox
            id={filterId}
            checked={isFilterActive}
            onClick={onCheckboxClick}
            margin={rest.flex ? '0px 5em 15px 0px' : '0px 0px 15px'}
          >
            <GreyText as="span">{filterLabel}</GreyText>
          </CheckBox>
          {getFilterType()}
        </>
      )}

      {Array.isArray(filterLabel) && filterLabel.length === 2 && (
        <>
          <FlexBox align="center">
            <CheckBox
              id={filterId[0]}
              checked={isFilterActive === 'first'}
              onClick={(e) =>
                onCheckboxClick(e, filterId, keyNames[0], 'first')
              }
              margin="0px 5em 15px 0px"
            >
              <GreyText as="span">{filterLabel[0]}</GreyText>
            </CheckBox>
            {getFilterType(type[0], keyNames[0], isFilterActive === 'first')}
          </FlexBox>
          <GreyText as="p" margin="0px 0px 1rem 2em">
            or
          </GreyText>
          <FlexBox align="center">
            <CheckBox
              id={filterId[1]}
              checked={isFilterActive === 'second'}
              onClick={(e) =>
                onCheckboxClick(e, filterId, keyNames[1], 'second')
              }
              margin="0px 5em 15px 0px"
            >
              <GreyText as="span">{filterLabel[1]}</GreyText>
            </CheckBox>
            {getFilterType(type[1], keyNames[1], isFilterActive === 'second')}
          </FlexBox>
        </>
      )}
    </DynamicBorderBox>
  )
}
