import React from 'react'
import _ from 'lodash'
import { spacing } from 'design-system'
import styled, { useTheme } from 'styled-components'
import {
  ActivateIcon,
  AddIcon,
  CopyIcon,
  DeactivateIcon,
  DeleteIcon,
  DisabledExternalIcon,
  DownloadIcon,
  EditIcon,
  ExcelIcon,
  MarkedExternalIcon,
  UnmarkedExternalIcon,
  ViewIcon,
  DotsIcon,
} from 'assets/actions'

export const $MoreOptions = styled.div`
  position: relative;
  width: 80px;
  margin: auto;
  z-index: 10;
  > svg {
    width: 30px;
    height: 40px;
    padding: 0 ${spacing.sm};
    border-radius: 2px;
    cursor: pointer;
    transform: rotate(90deg);
  }
  div {
    position: absolute;
    width: 220px;
    left: -196px;
    top: -8px;
    padding: ${spacing.ml};
    background-color: ${(props) => props.theme.colors.white};
    display: none;
    flex-direction: column;
    text-align: left;
    border-radius: ${(props) => props.theme.borderRadius.default};
    box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 8px;
    span {
      cursor: pointer;
      padding: ${spacing.sm};
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey};
      svg {
        width: 18px;
        height: 18px;
        margin-right: ${spacing.m};
      }
      :hover {
        background-color: ${(props) => props.theme.colors.solitude};
      }
    }
  }
  :hover {
    > svg {
      color: ${(props) => props.theme.colors.white};
      box-shadow: rgba(0, 0, 0, 0.35) 2px -2px 4px;
      background-color: ${(props) => props.theme.colors.white};
    }
    div {
      display: flex;
    }
  }
`

export const ActionCard = ({
  onIconClick,
  components = [],
  item = 'CAMPAIGN',
}) => {
  const { colors } = useTheme()

  const actionComponents = [
    {
      id: 'unmark-external',
      hoverColor: colors.error,
      icon: <MarkedExternalIcon />,
    },
    {
      id: 'mark-external',
      hoverColor: colors.error,
      icon: <UnmarkedExternalIcon />,
    },
    {
      id: 'disable-external',
      hoverColor: colors.error,
      icon: <DisabledExternalIcon />,
    },
    {
      id: 'view',
      hoverColor: colors.primary.default,
      icon: <ViewIcon />,
    },
    {
      id: 'edit',
      hoverColor: colors.grey,
      icon: <EditIcon />,
    },
    {
      id: 'add',
      hoverColor: colors.primary.default,
      icon: <AddIcon />,
    },
    {
      id: 'download',
      hoverColor: colors.grey,
      icon: <DownloadIcon />,
    },
    {
      id: 'export',
      hoverColor: colors.success.default,
      icon: <ExcelIcon />,
    },
    {
      id: 'unpublish',
      hoverColor: colors.orange,
      icon: <DeactivateIcon />,
    },
    {
      id: 'copy',
      hoverColor: colors.primary.default,
      icon: <CopyIcon />,
    },
    {
      id: 'delete',
      hoverColor: colors.error,
      icon: <DeleteIcon />,
    },
    {
      id: 'deactivate',
      hoverColor: colors.error,
      icon: <DeactivateIcon />,
    },
    {
      id: 'reactivate',
      hoverColor: colors.success.default,
      icon: <ActivateIcon />,
    },
  ]

  return (
    <$MoreOptions>
      <div>
        {actionComponents.map(
          (action) =>
            components.includes(action.id) && (
              <span
                key={action.id}
                onClick={(e) => {
                  e.stopPropagation()
                  onIconClick(action.id)
                }}
              >
                {action.icon}{' '}
                {_.startCase(
                  _.toLower(
                    `${action.id?.toUpperCase()} ${item}`.replace(
                      /([a-z])([A-Z])/g,
                      '$1 $2'
                    )
                  )
                )}
              </span>
            )
        )}
      </div>
      <DotsIcon />
    </$MoreOptions>
  )
}
