import React, { useEffect, useState } from 'react'

import { useRouter, useSelectStore, useDebouncedEffect } from 'hooks'
import {
  Button,
  Input,
  PopupContainer,
  Skeleton,
  useToasts,
} from 'components/Common'
import { $WhereToAddProduct } from '../UnregisteredProducts.styled'
import { useTheme } from 'styled-components'
import { AddBlueIcon } from 'assets/icons'
import {
  getProductsByCategory,
  registerProduct,
} from 'redux/actions/productAnalyticsActions'
import {
  $ProductList,
  $ProductListItem,
} from '../../AllCategories/Products/Products.styled'
import { Div } from 'styledComponent'
import { ERROR_DECODE, GET_SEL_VALUE } from 'utils/reusables'

const AddProductController = ({
  closePopup,
  selectedUnregisteredProduct,
  showPopup,
  fetchAction,
}) => {
  const [addToExisting, setAddToExisting] = React.useState(false)

  const { history } = useRouter()

  const addAsNewHandler = () => {
    history.push({
      pathname: '/product-management/products',
      state: selectedUnregisteredProduct,
    })
  }

  useEffect(() => () => setAddToExisting(false), [showPopup])

  return (
    <PopupContainer
      heading="Add Product"
      isPopOpen={showPopup}
      setIsPopOpen={closePopup}
      onClose={closePopup}
    >
      {addToExisting ? (
        <AddToExistingProduct
          closePopup={closePopup}
          sup={selectedUnregisteredProduct}
          fetchAction={fetchAction}
        />
      ) : (
        <WhereToAddProduct
          addToExisting={() => setAddToExisting(true)}
          addAsNew={addAsNewHandler}
        />
      )}
    </PopupContainer>
  )
}

export default AddProductController

const WhereToAddProduct = ({ addToExisting, addAsNew }) => {
  const { spacing } = useTheme()
  return (
    <$WhereToAddProduct>
      <li onClick={addAsNew}>
        <Button
          variant="primaryOutline"
          width="300px"
          m={spacing.l}
          startIcon={AddBlueIcon}
        >
          Add New Product
        </Button>
      </li>
      <li onClick={addToExisting}>
        <Button
          variant="primaryOutline"
          width="300px"
          m={spacing.l}
          startIcon={AddBlueIcon}
        >
          Add to Existing Product
        </Button>
      </li>
    </$WhereToAddProduct>
  )
}

const AddToExistingProduct = ({ closePopup, sup, fetchAction }) => {
  const [productsList, setProductsList] = useState([])
  const [selCat, setSelCat] = useState('')
  const [loading, setLoading] = useState(true)

  const { toast } = useToasts()

  const { data: allCategories } = useSelectStore(
    'productAnalytics.allCategories'
  )
  const { data: brandId } = useSelectStore('vendorIds.selectedBrand.value')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const catOptions =
    allCategories?.map((cat) => ({
      value: cat?._id || '',
      label: cat?.name,
    })) || []

  const getCatProds = async () => {
    const params = { vendorId, brandId, categoryId: selCat }
    if (!selCat) params.categoryId = 'unCategorised'
    setLoading(true)

    try {
      const res = await getProductsByCategory(params)
      setProductsList(res?.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const registerProd = async (productId) => {
    const body = { vendorId, productId }
    setLoading(true)

    try {
      await registerProduct(sup?._id, body)
      toast('Product registered successfully!', 'success')
      fetchAction()
      closePopup()
    } catch (err) {
      console.error(err)
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setLoading(false)
    }
  }

  useDebouncedEffect(getCatProds, [selCat], 0)

  return (
    <Div minHeight="400px">
      <Input
        variant="single-select"
        inputWidth="300px"
        label="Select Category"
        value={GET_SEL_VALUE(selCat, catOptions)}
        options={catOptions}
        onChange={(opt) => setSelCat(opt?.value || '')}
        maxMenuHeight="200px"
      />
      <$ProductList>
        {!loading &&
          productsList?.map((prod) => (
            <$ProductListItem
              type="button"
              onClick={() => registerProd(prod._id)}
              key={prod?._id}
            >
              {prod?.productName}
            </$ProductListItem>
          ))}
        {productsList?.length === 0 && !loading && 'No Data Found'}
        {loading && <Skeleton count={7} height="50px" />}
      </$ProductList>
    </Div>
  )
}
