import {
  nyCinemasVendorId,
  trentVendorId,
} from 'components/conditionalComponent'

const checkfeaturesAlloted = (permission, featuresAlloted) => {
  // console.log('permission', permission, permission instanceof Array ? permission.some((p) => featuresAlloted && featuresAlloted[p] !== undefined ? featuresAlloted[p] : false) : featuresAlloted[permission]);
  if (permission instanceof Array) {
    return permission.some((p) =>
      featuresAlloted && featuresAlloted[p] !== undefined
        ? featuresAlloted[p]
        : false
    )
  }
  return featuresAlloted && featuresAlloted[permission] !== undefined
    ? featuresAlloted[permission]
    : false
}

export const showDeleteBtn = (vendorId, isAdmin, isSecurity) => {
  if (isSecurity) return false
  if (vendorId === nyCinemasVendorId) return false
  if (vendorId === trentVendorId && !isAdmin) return false
  return true
}

export default checkfeaturesAlloted
