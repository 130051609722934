import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToasts } from 'components/Common'
import {
  getWhatsAppTemplates,
  setLoadTable,
  deleteWATemplate,
  duplicateWATemplate,
} from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../../constants'
import { useSelectStore } from 'hooks'

const useTemplateApi = (vendorId, skip, triggers) => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: 10,
  })
  const {
    data: { filterQuery },
  } = useSelectStore('autoEngage')
  const dispatch = useDispatch()
  const { toast } = useToasts()
  const debounceLoadData = useCallback(
    debounce((q) => setQuery(q), 750),
    []
  )

  useEffect(() => {
    dispatch(getWhatsAppTemplates({ ...filterQuery, ...query }))
  }, [query, filterQuery])

  useEffect(() => {
    triggers._id && triggerActions()
  }, [triggers._id])

  useEffect(() => {
    dispatch(setLoadTable(true))
    const tempQuery = { ...query, vendorId, skip }
    debounceLoadData(tempQuery)
  }, [vendorId, skip])

  const triggerActions = () => {
    switch (triggers?.action) {
      case 'delete':
        return delTemplate()

      case 'duplicate':
        return dupTemplate()

      default:
        break
    }
  }

  const dupTemplate = async () => {
    try {
      const res = await duplicateWATemplate(triggers._id, {
        name: triggers.name,
        createdBy: triggers.createdBy,
        vendorId,
      })
      if (res) {
        toast('Template duplicated successfully', 'success')
        dispatch(getWhatsAppTemplates(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const delTemplate = async () => {
    try {
      const res = await deleteWATemplate(triggers._id, vendorId)
      if (res) {
        toast('Template deleted successfully', 'success')
        dispatch(getWhatsAppTemplates(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return null
}

export default useTemplateApi
