import { ERROR_DECODE } from '../constants'

export const selectMedia = (
  args,
  componentType,
  assetsDetails,
  couponId,
  setBody,
  toast
) => {
  const { fileLocation, assetId, key, name } = args
  switch (componentType) {
    case 'image':
    case 'gif': {
      let keys
      if (key === undefined) {
        let index = fileLocation.indexOf('assets')
        keys = fileLocation.substring(index, fileLocation.length)
      }
      let keyValue = key === undefined ? keys : key
      const assets = [
        {
          ...assetsDetails[0],
          assetId,
          key: keyValue,
          fileLocation,
          name,
          couponVisibility: Boolean(couponId),
        },
      ]
      setBody({ assetsDetails: assets })
      return
    }

    case 'video': {
      const assets = [
        {
          assetId,
          key,
          fileLocation,
          name,
          couponVisibility: Boolean(couponId),
        },
      ]
      setBody({ assetsDetails: assets })
      return
    }

    case 'youtube': {
      const assets = [
        {
          key: fileLocation,
          fileLocation,
          couponVisibility: false,
        },
      ]
      setBody({ assetsDetails: assets, couponId: '' })
      return
    }

    case 'carousel': {
      const carousel = assetsDetails?.map((asset) => asset?.fileLocation)
      if (carousel.length > 4) {
        toast('Maximum 5 images can be added.', 'error')
        return
      }
      if (carousel.includes(fileLocation)) {
        toast('This image is already selected.', 'error')
        return
      }
      const asset = [...assetsDetails]
      const obj = {
        ...assetsDetails[0],
        assetId,
        key,
        name,
        fileLocation,
        couponVisibility: Boolean(couponId),
      }
      asset.push(obj)
      setBody({ assetsDetails: asset })
      return
    }
  }
}

export const uploadMedia = async (
  e,
  setCampaignLoader,
  supportedFormatArray,
  vendorId,
  storeUser,
  setMedia,
  fetchFiles,
  limit,
  uploadFiles,
  toast,
  uploadedFrom
) => {
  setCampaignLoader(true)
  try {
    const file = e.target.files[0]
    if (!supportedFormatArray.includes(file?.type?.split('/')[1])) {
      toast(
        `Unsupported file format. Upload file can be one of these formats - ${supportedFormatArray.join(
          ', '
        )}`,
        'error'
      )
      setCampaignLoader(false)
      return
    }

    const formData = new FormData()
    formData.append('vendorId', vendorId)
    formData.append('storeUser', storeUser)
    formData.append('name', file?.name)
    formData.append('description', `File uploaded at ${new Date().toString()}`)
    formData.append('file', file)
    formData.append('uploadedFrom', uploadedFrom)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    const res = await uploadFiles(formData, config)
    if (res) {
      setMedia(res)
      toast('File uploaded successfully', 'success')
      fetchFiles(limit)
    }
  } catch (err) {
    toast(ERROR_DECODE(err), 'error')
  } finally {
    setCampaignLoader(false)
  }
}
