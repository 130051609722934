import { post, get } from 'utils/axiosHandler'
import {
  CHARTS_AVG_BILLING,
  CHARTS_P_STORE_WISE,
  CHARTS_CW_STORE_WISE,
  CHARTS_BMP_STORE_WISE,
  CHARTS_BM_STORE_WISE,
  CHARTS_TOTAL_TRANSACTIONS,
  CHARTS_TOTAL_SALES,
  CHARTS_MAX_MIN_SALES,
  CHARTS_GET_TRANSACTIONS_BY_TYPE,
} from 'utils/dispatchConstants'
//Getting total sales chart data
export const getTotalSalesChart = (data) => async (dispatch) => {
  const res = await post('/chart/sales_report', data)
  if (res.code) dispatch(getTotalSalesChartHelper(res))
}

//Getting Max Min Amount
export const getTotalSalesChartHelper = (data) => {
  return {
    type: CHARTS_TOTAL_SALES,
    payload: data.data,
  }
}

//Getting average billing amount chart data
export const getAvgAmount = (data) => async (dispatch) => {
  const res = await post('/chart/sales_report', data)
  if (res) dispatch(getAvgAmountHelper(res))
}

//Getting Max Min Amount
export const getAvgAmountHelper = (data) => {
  return {
    type: CHARTS_AVG_BILLING,
    payload: data.data,
  }
}

//Getting total transactions chart data
export const getTotalTransactionsChart = (data) => async (dispatch) => {
  const res = await post('/chart/transactionType_perDay', data)
  if (res.code) dispatch(getTotalTransactionsChartHelper(res))
}

//Getting total transactions chart data helper
export const getTotalTransactionsChartHelper = (data) => {
  return {
    type: CHARTS_TOTAL_TRANSACTIONS,
    payload: data.data,
  }
}

//Getting total sales chart data
export const getMaxMinChart = (data) => async (dispatch) => {
  const res = await post('/chart/sales_report', data)
  if (res) dispatch(getMaxMinChartHelper(res))
}

//Getting Max Min Amount
export const getMaxMinChartHelper = (data) => {
  return {
    type: CHARTS_MAX_MIN_SALES,
    payload: data.data,
  }
}

//Getting total sales chart data
export const getTransactionsChartsByType = (data) => async (dispatch) => {
  const res = await post('/chart/transactionType_count', data)
  if (res.code) dispatch(getTransactionsChartsByTypeHelper(res))
}

//Getting Max Min Amount
export const getTransactionsChartsByTypeHelper = (data) => {
  return {
    type: CHARTS_GET_TRANSACTIONS_BY_TYPE,
    payload: data.data,
  }
}

export const getTransactionsStoreWise = (data) => async (dispatch) => {
  const res = await get('/chart/transaction-store-wise', data)
  if (res.code) {
    dispatch({
      type: getDispatchType(data.type),
      payload: res.data,
    })
  }
}

const getDispatchType = (type) => {
  switch (type) {
    case 'BM':
      return CHARTS_BM_STORE_WISE
    case 'BMPrint':
      return CHARTS_BMP_STORE_WISE
    case 'Print':
      return CHARTS_P_STORE_WISE
    case 'C.W':
      return CHARTS_CW_STORE_WISE
  }
}
