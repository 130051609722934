import { Tooltip } from 'components/Common'
import React from 'react'

export const StatsInfo = () => {
  return (
    <div>
      <Tooltip content="Only date filter and store selector take effect on the stats in the bar." />
    </div>
  )
}
