import styled from 'styled-components'

import { StyledCloseButton } from 'components/Common'
import { spacing } from 'design-system'
import { Button } from 'components/Common/Button'

const $CloseButton = styled(StyledCloseButton)`
  opacity: 0.6;

  &:before,
  &:after {
    background-color: #d76565;
  }

  &:hover {
    opacity: 0.9;
  }
`

const $Block = styled.div`
  background-color: #f2f5f8;
  padding: ${spacing.l};
`

const $FooterButton = styled(Button)`
  width: 100px;
  margin-left: ${spacing.l};
  padding: ${spacing.m} ${spacing.l};
`

const $PopupBody = styled.div`
  padding: ${spacing.l} ${spacing.l} ${spacing.xxl};
`

export { $CloseButton, $Block, $PopupBody, $FooterButton }
