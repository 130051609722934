import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import { isArray, isObject } from 'lodash-es'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { spacing } from 'design-system'
import { Input, Checkbox, Text, Button, useToasts } from 'components/Common'
import { Slider } from 'components/Common/Slider'
import { Div, FlexBox } from 'styledComponent'
import { post, patch, get } from 'utils/axiosHandler'
import { useDebouncedEffect, useSelectStore, useVendorSelect } from 'hooks'
import { StoreHeading } from 'components/Layout/StoresHeader'
import { SimpleOutlineButton } from 'styledComponent/components/button'
import ReactDatePicker from 'react-datepicker'

import WindowsIcon from 'assets/icons/windows.svg'
import AndroidIcon from 'assets/icons/android.svg'
import AppleIcon from 'assets/icons/apple.svg'
import {
  getPosTypes,
  getStatesCities,
} from 'redux/actions/consumerAnalyticsActions'

import { $FieldContainer } from '../segments.styled'
import {
  AVERAGE_FEEDBACK,
  SEGMENT_FIELD_TYPES,
  PARENT_SEGMENTS,
  GET_VALUE_OF,
  SEGMENT_PAYLOAD,
  SEGMENT_STATUSES,
} from '../constants'
import { _selectOptionsGenerator } from 'utils/formatter'
import {
  ERROR_DECODE,
  GET_SEL_VALUE,
} from 'components/Content/autoEngagement/constants'
import { useSegmentsContext } from '../contexts'
import {
  getAllCategories,
  getProducts,
} from 'redux/actions/productAnalyticsActions'
import { useBrandSelect } from 'components/Content/Products/hooks'
import { FilterIcon } from 'assets/icons'
import { SET_COUPONS_DATA } from 'redux/types/autoEngage'

const SegmentForm = ({
  initialData = {},
  id,
  onCancel,
  getSegments,
  trigger = '',
  setTrigger = () => {},
}) => {
  const [loading, setLoading] = React.useState(false)
  const [segmentData, setSegmentData] = React.useState(initialData)
  const [products, setProducts] = React.useState({
    data: [],
    isLoading: false,
    categories: [],
    searchInput: '',
  })
  const [isPopOpen, setIsPopOpen] = useState(false)

  const { toast } = useToasts()
  const dispatch = useDispatch()

  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const {
    data: { posData, location },
  } = useSelectStore('cAnalytics')
  const vendorPosTypes = posData?.posTypes || []
  const { fetchSegments } = useSegmentsContext()
  // useBrandSelect()

  const disableLastVisit =
    !segmentData?.duration?.startDate && !segmentData?.duration?.endDate
      ? true
      : false

  const handleSegmentInfo = (e) => {
    setSegmentData({
      ...segmentData,
      [e.target.id]: e.target.value,
    })
  }

  const handleTagChange = (key, value) => {
    setSegmentData({
      ...segmentData,
      [key]: Array.isArray(value) ? value?.map((item) => item?.trim()) : value,
    })
  }

  const handleSelect = (key, value) => {
    setSegmentData({
      ...segmentData,
      [key]: value,
    })
  }

  const handleRangeChange = (parent, key, value) => {
    setSegmentData({
      ...segmentData,
      [parent]: {
        ...segmentData?.[parent],
        [key]: value,
      },
    })
  }

  const handleCheckboxChange = (key, value) => {
    let copyArr = [...(segmentData?.[key] || [])]
    if (copyArr?.includes(value)) {
      copyArr = copyArr.filter((item) => item !== value)
    } else {
      copyArr.push(value)
    }
    setSegmentData({
      ...segmentData,
      [key]: copyArr,
    })
  }

  const getActiveStatus = (type) => {
    const tempSegmentData = { ...segmentData }
    if (disableLastVisit) {
      delete tempSegmentData?.lastVisit
    }
    return PARENT_SEGMENTS?.[type]?.some((item) => !!tempSegmentData?.[item])
  }

  const onSubmit = async () => {
    setTrigger('')
    const tempState = cloneDeep(segmentData)

    if (!tempState.segmentName) {
      return toast('Name cannot be empty', 'error')
    }

    delete tempState.segmentName
    delete tempState.segmentDescription
    delete tempState.keywordsCondition
    if (!segmentData?.type) {
      return toast('Please select Segment Type', 'error')
    }
    if (
      segmentData?.type === 'static' &&
      !segmentData?.duration?.startDate &&
      !segmentData?.duration?.endDate
    ) {
      return toast('Please select the start and end date', 'error')
    }
    if (segmentData?.keywords?.length > 0 && !segmentData?.keywordsCondition) {
      return toast('Please select "Any" or "All" in Keywords', 'error')
    }
    if (segmentData?.keywordsCondition && !segmentData?.keywords?.length) {
      return toast('Please add atleast one keyword', 'error')
    }
    for (const key in tempState) {
      const value = tempState[key]
      if (isArray(value) && value.length === 0) {
        delete tempState?.[key]
      } else if (key === 'keywords') {
        if (value.length > 10) {
          return toast('Keywords should not be more than 10', 'error')
        }
        if (value.some((keyword) => keyword.length > 32)) {
          return toast('Keyword should not be more than 32 characters', 'error')
        }
        if (value.some((keyword) => !/^[a-zA-Z0-9_ \(\)\/-]*$/.test(keyword))) {
          return toast(
            GET_VALUE_OF(key, 'label') +
              ' should contain only alphabets, numbers, spaces, _, (, ), /, -',
            'error'
          )
        }
      } else if (key === 'duration') {
        if (value) {
          if (!value?.startDate || !value?.endDate) {
            return toast('Please select both start and end date', 'error')
          }
          if (
            Math.abs(
              moment(value.startDate).diff(
                moment(value.endDate),
                'months',
                true
              )
            ) > 6
          ) {
            return toast(
              'Difference between start date and end date should be less than 6 months.',
              'error'
            )
          }
          if (moment(value.startDate).isAfter(value.endDate)) {
            return toast('End date should be greater than start date', 'error')
          }
          if (moment(value.endDate).isAfter(moment())) {
            return toast(
              'End date cannot be greater than Current Date',
              'error'
            )
          }
          if (moment(value.startDate).isAfter(moment())) {
            return toast('Start date cannot be greater than today', 'error')
          }
        }
      } else if (isObject(value) && !isArray(value)) {
        if (!value?.min && !value?.max) {
          delete tempState?.[key]
        } else if (!value?.min || !value?.max) {
          return toast(
            GET_VALUE_OF(key, 'label') +
              ' should have both minimum and maximum value',
            'error'
          )
        } else if (Number(value?.min) >= Number(value?.max)) {
          return toast(
            GET_VALUE_OF(key, 'label') +
              ' maximum value must be greater than minimum value',
            'error'
          )
        } else if (Number(value?.min) < 0) {
          return toast(
            GET_VALUE_OF(key, 'label') + ' minimum value cannot be negative',
            'error'
          )
        } else if (Number(value?.max) < 1) {
          return toast(
            GET_VALUE_OF(key, 'label') + ' maximum value cannot be less than 0',
            'error'
          )
        }
      } else if (!value) {
        delete tempState?.[key]
      } else if ((Number(value) || Number(value) === 0) && Number(value) < 1) {
        return toast(
          GET_VALUE_OF(key, 'label') + ' should be greater than 0',
          'error'
        )
      }
    }

    if (Object.keys(tempState).length === 0) {
      return toast('Add at least one condition.', 'error')
    }

    setLoading(true)
    const startDate = moment(segmentData?.duration?.startDate).format(
      'YYYY-MM-DD'
    )
    const endDate = moment(segmentData?.duration?.endDate).format('YYYY-MM-DD')
    try {
      const tempSegmentData = { ...segmentData }
      if (disableLastVisit) {
        delete tempSegmentData.lastVisit
      }
      if (segmentData?.keywords) {
        tempSegmentData.keywords = tempSegmentData.keywords.map((keyword) =>
          keyword.toLowerCase()
        )
      }
      delete tempSegmentData.duration
      let body = SEGMENT_PAYLOAD({ ...tempSegmentData })
      const urlPath = `/cust-segmentation/segments${id ? `/${id}` : ''}`
      const method = id ? patch : post
      if (segmentData?.duration?.startDate) {
        body = {
          ...body,
          startDate,
          endDate,
        }
      }

      const submitResponse = await method(urlPath, {
        ...body,
        vendorId: selectedVendor.value,
      })

      if (submitResponse.code) {
        toast(`Segment sucessfully ${id ? 'updated' : 'added'}`, 'success')
      }
      onCancel()
      fetchSegments && fetchSegments()
      getSegments && getSegments()
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleToggleChange = (type) => {
    const segCopy = { ...segmentData }
    for (const item of PARENT_SEGMENTS?.[type]) {
      delete segCopy[item]
    }
    setSegmentData(segCopy)
  }

  React.useEffect(() => {
    dispatch(getStatesCities())
    dispatch(getPosTypes())
    // dispatch(getAllCategories())
  }, [])

  React.useEffect(() => {
    trigger === 'save' && onSubmit()
    trigger === 'exit' && onCancel()
  }, [trigger])

  const fetchProducts = async () => {
    if (!selectedBrand?.value) return
    setProducts({ ...products, isLoading: true })
    const params = {
      limit: 10,
      skip: 0,
      brandId: selectedBrand?.value,
      vendorId: selectedVendor?.value,
    }

    if (products.searchInput) params.searchTxt = products.searchInput
    if (products.categories.length) params.categories = products.categories
    if (products.data.length)
      params.excludeProducts = products.data.map((item) => item.value)

    try {
      const res = await getProducts(params)
      const data =
        res?.docs?.map((pro) => ({
          value: pro?._id,
          label: pro?.productName,
        })) || []
      setProducts((prod) => ({ ...prod, data, isLoading: false }))
    } catch (err) {
      setProducts({ ...products, isLoading: false })
      console.error(err)
    }
  }

  // useDebouncedEffect(fetchProducts, [products?.categories, products?.searchInput], 2000)

  return (
    <div>
      <FlexBox>
        <FieldContainer label="Create Segment" disableSlider>
          <Input
            variant="input"
            label="Segment Name"
            required
            onChange={handleSegmentInfo}
            placeholder="Segment Name"
            value={segmentData?.segmentName || ''}
            id="segmentName"
            inputWidth="400px"
          />
          <Input
            variant="textarea"
            rows={4}
            label="Description"
            onChange={handleSegmentInfo}
            inputWidth="500px"
            value={segmentData?.segmentDescription || ''}
            id="segmentDescription"
          />
        </FieldContainer>
      </FlexBox>
      {/* 
      <FlexBox>
        <FieldContainer
          label="Products"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.product)}
          handleToggleChange={() => handleToggleChange(SEGMENT_FIELD_TYPES.product)}
        >
          <Div position="relative">
            <Input
              variant="multi-select"
              label="Products"
              placeholder="Search & Select Products"
              options={products?.data}
              cacheOptions
              hideSelectedOptions
              onChange={(arr) => handleSelect('product', arr)}
              value={segmentData?.product || []}
              onInputChange={searchInput => setProducts({ ...products, searchInput })}
              inputWidth="650px"
              containerMargin="0px"
            />
            <Div
              position="absolute"
              top="-4px"
              right="0"
            >
              <Button
                variant="primaryOutlined"
                >
                  <FilterIcon /> &nbsp; Filter
                </Button>
            </Div>
          </Div>
        </FieldContainer>
      </FlexBox> */}
      <FlexBox>
        <FieldContainer
          label={
            <Fragment>
              Segment Type <span style={{ color: 'tomato' }}>*</span>
            </Fragment>
          }
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.duration)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.duration)
          }
        >
          <Input
            padding="0px"
            containerMargin="0px"
            variant="radio"
            direction="row"
            options={[
              {
                value: 'static',
                label: SEGMENT_STATUSES.static.label,
              },
              {
                value: 'dynamic',
                label: SEGMENT_STATUSES.dynamic.label,
              },
            ]}
            value={segmentData?.type}
            onChange={(value) => handleSelect('type', value)}
          />
          {segmentData?.type === 'static' ? (
            <FlexBox gap="20px">
              <Input
                containerMargin="0px"
                label="Start Date"
                variant="date-input"
                onChange={(duration) => {
                  setSegmentData({
                    ...segmentData,
                    duration: {
                      ...segmentData?.duration,
                      startDate: duration,
                    },
                  })
                }}
                value={segmentData?.duration?.startDate}
                placeholder="Select Start Date"
                minDate={moment('2022-01-01').toDate()}
              />

              <Input
                label="End Date"
                variant="date-input"
                onChange={(duration) => {
                  setSegmentData({
                    ...segmentData,
                    duration: {
                      ...segmentData?.duration,
                      endDate: duration,
                    },
                  })
                }}
                value={segmentData?.duration?.endDate}
                placeholder="Select End Date"
                minDate={moment('2022-01-01').toDate()}
              />
            </FlexBox>
          ) : null}
        </FieldContainer>
      </FlexBox>
      <FlexBox mt="16px">
        <FieldContainer
          label="Other Keywords In Invoice"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.otherKeywordsInInvoice)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.otherKeywordsInInvoice)
          }
        >
          <Input
            padding="0px"
            variant="radio"
            direction="row"
            containerMargin="0px"
            options={[
              {
                value: 'in',
                label: 'Any',
              },
              {
                value: 'all',
                label: 'All',
              },
            ]}
            value={segmentData?.keywordsCondition}
            onChange={(value) => handleSelect('keywordsCondition', value)}
          />

          <Input
            variant="create-tags"
            label="Input Keywords"
            id="keywords"
            placeholder="Enter Keywords"
            options={segmentData?.keywords}
            cacheOptions
            hideSelectedOptions
            onChange={(value) => handleTagChange('keywords', value)}
            value={segmentData?.keywords || []}
            inputWidth="650px"
            containerMargin="0px"
            customKeyDownKeys={['Enter', 'Tab']}
          />
        </FieldContainer>
      </FlexBox>
      <FlexBox>
        <FieldContainer
          label="Visits &amp; Feedback"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.visitsAndFeedback)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.visitsAndFeedback)
          }
        >
          <FlexBox>
            <Input
              type="number"
              label="Total Visits"
              variant="input"
              id="visitCount"
              value={segmentData?.visitCount || ''}
              onChange={handleSegmentInfo}
              inputWidth="160px"
              containerMargin={`0px ${spacing.xxl} 0px 0px`}
            />

            <Input
              type="number"
              label="Last Visit (no. of days)"
              variant="input"
              id="lastVisit"
              value={disableLastVisit ? '' : segmentData?.lastVisit || ''}
              onChange={handleSegmentInfo}
              inputWidth="160px"
              containerMargin={`0px ${spacing.xxl} 0px 0px`}
              disabled={disableLastVisit}
            />

            <Input
              variant="single-select"
              label="Average Feedback"
              options={AVERAGE_FEEDBACK}
              value={
                GET_SEL_VALUE(
                  segmentData?.avgFeedback || '',
                  AVERAGE_FEEDBACK
                ) || ''
              }
              onChange={({ value }) => handleSelect('avgFeedback', value)}
              inputWidth="160px"
              containerMargin={`0px ${spacing.xxl} 0px 0px`}
              menuPlacement="auto"
            />
          </FlexBox>
        </FieldContainer>
      </FlexBox>

      <FlexBox>
        <FieldContainer
          label="Gender & Age"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.genderAndAge)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.genderAndAge)
          }
        >
          <FlexBox align="">
            <Input
              variant="range"
              label="Age"
              type="number"
              id={['min', 'max']}
              inputWidth="100px"
              value={[segmentData?.age?.min || '', segmentData?.age?.max || '']}
              onChange={[
                (e) => handleRangeChange('age', 'min', e.target.value),
                (e) => handleRangeChange('age', 'max', e.target.value),
              ]}
            />
            <div style={{ padding: '8px 0px', marginLeft: spacing.x3l }}>
              <Text as="label" variant="small" mb={spacing.m}>
                Gender
              </Text>
              <FlexBox mt={spacing.l}>
                <Checkbox
                  id="male"
                  mr={spacing.l}
                  onCheckboxChange={() =>
                    handleCheckboxChange('gender', 'male')
                  }
                  isChecked={(segmentData?.gender || [])?.includes('male')}
                >
                  Male
                </Checkbox>
                <Checkbox
                  id="female"
                  onCheckboxChange={() =>
                    handleCheckboxChange('gender', 'female')
                  }
                  isChecked={(segmentData?.gender || [])?.includes('female')}
                >
                  Female
                </Checkbox>
                <Checkbox
                  id="gender-other"
                  ml={spacing.l}
                  onCheckboxChange={() =>
                    handleCheckboxChange('gender', 'other')
                  }
                  isChecked={(segmentData?.gender || [])?.includes('other')}
                >
                  Other
                </Checkbox>
              </FlexBox>
            </div>
          </FlexBox>
        </FieldContainer>
      </FlexBox>

      <FlexBox>
        <FieldContainer
          label="Billing & Expense"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.billingAndExpense)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.billingAndExpense)
          }
        >
          <FlexBox>
            <Input
              type="number"
              variant="input"
              label="Total SKU's bought"
              id="totalSKU"
              value={segmentData?.totalSKU || ''}
              onChange={handleSegmentInfo}
              inputWidth="120px"
              containerMargin={`0px ${spacing.xxl} 0px 0px`}
            />
            <Input
              type="number"
              variant="input"
              label="Avg. quantity per bill"
              id="avgSKU"
              value={segmentData?.avgSKU || ''}
              onChange={handleSegmentInfo}
              inputWidth="120px"
              containerMargin={`0px ${spacing.xxl} 0px 0px`}
            />
            <Input
              variant="range"
              type="number"
              label="Total Expenses"
              id={['min', 'max']}
              inputWidth="100px"
              value={[
                segmentData?.totalSpent?.min || '',
                segmentData?.totalSpent?.max || '',
              ]}
              onChange={[
                (e) => handleRangeChange('totalSpent', 'min', e.target.value),
                (e) => handleRangeChange('totalSpent', 'max', e.target.value),
              ]}
            />
          </FlexBox>
        </FieldContainer>
      </FlexBox>

      <FlexBox>
        <FieldContainer
          label="Stores Visited"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.storesVisited)}
          handleToggleChange={() =>
            handleToggleChange(SEGMENT_FIELD_TYPES.storesVisited)
          }
        >
          <SimpleOutlineButton
            type="button"
            className="mr-2 py-2 mb-1"
            onClick={() => setIsPopOpen(true)}
          >
            <StoreHeading
              selectedStoreIds={segmentData?.storeIds || []}
              setSelectedStoreIds={(storeIds) => {
                setSegmentData({
                  ...segmentData,
                  storeIds,
                })
              }}
              isPopOpen={isPopOpen}
              setIsPopOpen={(isPopOpen) => setIsPopOpen(isPopOpen)}
              showStatus
              disableCompanyInput={true}
              disableStoreGroups={true}
            />
          </SimpleOutlineButton>
        </FieldContainer>
      </FlexBox>

      {/* <FlexBox> */}
      {/*   <FieldContainer */}
      {/*     label="Location" */}
      {/*     isActive={getActiveStatus(SEGMENT_FIELD_TYPES.location)} */}
      {/*     handleToggleChange={() => */}
      {/*       handleToggleChange(SEGMENT_FIELD_TYPES.location) */}
      {/*     } */}
      {/*   > */}
      {/*     <Input */}
      {/*       variant="multi-select" */}
      {/*       label="States" */}
      {/*       placeholder="Select States" */}
      {/*       options={_selectOptionsGenerator(location?.states)} */}
      {/*       value={ */}
      {/*         (segmentData?.state || []).map((item) => */}
      {/*           GET_SEL_VALUE(item, _selectOptionsGenerator(location?.states)) */}
      {/*         ) || '' */}
      {/*       } */}
      {/*       onChange={(arr) => */}
      {/*         handleSelect( */}
      {/*           'state', */}
      {/*           (arr || []).map((it) => it.value) */}
      {/*         ) */}
      {/*       } */}
      {/*       inputWidth="600px" */}
      {/*       containerMargin={`0px ${spacing.xxl} 0px 0px`} */}
      {/*     /> */}
      {/*     <Input */}
      {/*       variant="multi-select" */}
      {/*       label="Cities" */}
      {/*       placeholder="Select Cities" */}
      {/*       options={_selectOptionsGenerator(location?.cities)} */}
      {/*       value={ */}
      {/*         (segmentData?.city || []).map((item) => */}
      {/*           GET_SEL_VALUE( */}
      {/*             item, */}
      {/*             _selectOptionsGenerator(location?.cities || []) */}
      {/*           ) */}
      {/*         ) || '' */}
      {/*       } */}
      {/*       onChange={(arr) => */}
      {/*         handleSelect( */}
      {/*           'city', */}
      {/*           (arr || []).map((it) => it.value) */}
      {/*         ) */}
      {/*       } */}
      {/*       inputWidth="600px" */}
      {/*     /> */}
      {/*   </FieldContainer> */}
      {/* </FlexBox> */}

      {/* <FlexBox> */}
      {/*   <FieldContainer */}
      {/*     label="SMS Service Explicit Permission" */}
      {/*     isActive={getActiveStatus(SEGMENT_FIELD_TYPES.serviceExplicit)} */}
      {/*     handleToggleChange={() => */}
      {/*       handleToggleChange(SEGMENT_FIELD_TYPES.serviceExplicit) */}
      {/*     } */}
      {/*   > */}
      {/*     <div style={{ padding: '8px 0px' }}> */}
      {/*       <Text as="label" variant="small" mb={spacing.m}> */}
      {/*         Customer Opt-in */}
      {/*       </Text> */}
      {/*       <FlexBox mt={spacing.s}> */}
      {/*         <Input */}
      {/*           variant="radio" */}
      {/*           options={[ */}
      {/*             { */}
      {/*               value: 'true', */}
      {/*               label: 'Yes', */}
      {/*             }, */}
      {/*             { */}
      {/*               value: 'false', */}
      {/*               label: 'No', */}
      {/*             }, */}
      {/*           ]} */}
      {/*           gap={spacing.xl} */}
      {/*           onChange={(value) => handleSelect('consent', value)} */}
      {/*           value={segmentData?.consent} */}
      {/*         /> */}
      {/*       </FlexBox> */}
      {/*     </div> */}
      {/*   </FieldContainer> */}
      {/* </FlexBox> */}

      <FlexBox>
        <FieldContainer
          label="OS"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.os)}
          handleToggleChange={() => handleToggleChange(SEGMENT_FIELD_TYPES.os)}
        >
          <div style={{ padding: '8px 0px' }}>
            <Text as="label" variant="small" mb={spacing.m}>
              Select OS:
            </Text>
            <FlexBox mt={spacing.l}>
              <OSContainer
                id="android"
                icon={AndroidIcon}
                label="Android"
                mr={spacing.xl}
                onCheckboxChange={() => handleCheckboxChange('os', 'android')}
                isChecked={(segmentData?.os || [])?.includes('android')}
              />
              <OSContainer
                id="iphone"
                icon={AppleIcon}
                label="Apple"
                mr={spacing.xl}
                onCheckboxChange={() => handleCheckboxChange('os', 'iphone')}
                isChecked={(segmentData?.os || [])?.includes('iphone')}
              />
              <OSContainer
                id="os-other"
                icon={WindowsIcon}
                label="Other"
                onCheckboxChange={() => handleCheckboxChange('os', 'other')}
                isChecked={(segmentData?.os || [])?.includes('other')}
              />
            </FlexBox>
          </div>
        </FieldContainer>
      </FlexBox>

      <FlexBox>
        <FieldContainer
          label="POS"
          isActive={getActiveStatus(SEGMENT_FIELD_TYPES.pos)}
          handleToggleChange={() => handleToggleChange(SEGMENT_FIELD_TYPES.pos)}
        >
          <Input
            variant="multi-select"
            label="POS Types"
            placeholder="Select POS Types"
            options={_selectOptionsGenerator(vendorPosTypes)}
            value={
              (segmentData?.posType || []).map((item) =>
                GET_SEL_VALUE(item, _selectOptionsGenerator(vendorPosTypes))
              ) || ''
            }
            onChange={(arr) =>
              handleSelect(
                'posType',
                (arr || []).map((it) => it.value)
              )
            }
            inputWidth="650px"
            containerMargin="0px"
          />
        </FieldContainer>
      </FlexBox>

      <FlexBox my={spacing.xl}>
        <Button onClick={onSubmit} variant="primary" disabled={loading}>
          {id ? 'Update' : 'Save'}
        </Button>
        <Button variant="textualPrimary" onClick={onCancel} noBorder>
          Cancel
        </Button>
      </FlexBox>
    </div>
  )
}

export default SegmentForm

const $CheckboxWithIcon = styled(FlexBox)`
  img {
    margin-right: ${spacing.m};
  }
`

const FieldContainer = ({
  label,
  children,
  isActive,
  handleToggleChange,
  disableSlider,
}) => {
  return (
    <$FieldContainer>
      <FlexBox mb={spacing.m} align="center">
        <Text variant="p" m={`0px ${spacing.l} 0px 0px`} weight="bold">
          {label}
        </Text>
        {!disableSlider && (
          <Slider isChecked={isActive} onChange={handleToggleChange} />
        )}
      </FlexBox>
      {children}
    </$FieldContainer>
  )
}

const OSContainer = ({ id, icon, label, ...rest }) => (
  <Checkbox id={id} {...rest}>
    <$CheckboxWithIcon align="center">
      <img src={icon} />
      {label}
    </$CheckboxWithIcon>
  </Checkbox>
)
