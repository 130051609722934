import React from 'react'

import Navbar from 'components/Layout/Navbar'
import CustomerProfile from './ConsumerProfile'
import CustomersList from './CustomersList'
import { $BodyStyles } from '../ConsumerAnalytics.styled'
import { useRouter, useSelectStore } from 'hooks'
import { FALLBACK_ROUTE } from '../constants'

const Customers = () => {
  const { history } = useRouter()
  const { data: segmentsList } = useSelectStore('cAnalytics.segmentsList')

  React.useEffect(() => {
    if (!segmentsList) history.push(FALLBACK_ROUTE)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar heading="Customer Analytics" />
      <CustomerProfile />
      <CustomersList />
      <$BodyStyles />
    </>
  )
}

export default Customers
