import { PopupContainer, Table } from 'components/Common'
import { useRouter } from 'hooks'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SMTopBar } from './components'
import { COLUMNS } from './constants'
import { $WhiteContainer } from './StoreManagement.styled'
import { useStoreApi } from './useStoreApi'

const StoreManagement = () => {
  const { query, history } = useRouter()

  const {
    row,
    page,
    list,
    limit,
    search,
    setSearch,
    tableLoading,
    confirmPopup,
    onActionClick,
    onPaginationChange,
    filterQuery,
    setFilterQuery,
  } = useStoreApi()

  const {
    isPopOpen = false,
    setIsPopOpen = () => {},
    heading,
    onSubmit,
    content,
  } = confirmPopup

  useEffect(() => {
    !query?.tab && history.push('/storeManagement?tab=Company')
  }, [query?.tab])

  return (
    <$WhiteContainer>
      <SMTopBar
        search={search}
        setSearch={setSearch}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
      />
      <Table
        columns={COLUMNS[query.tab]}
        list={list}
        tableLoading={tableLoading}
        row={row}
        page={page}
        limit={limit}
        onActionClick={onActionClick}
        onPaginationChange={onPaginationChange}
      />

      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading={heading}
        onSubmitClick={onSubmit}
        children={content}
      />
    </$WhiteContainer>
  )
}

export default connect(null, {})(StoreManagement)
