import { PublishItem } from './PublishItem'
import { FlexBox } from 'styledComponent'
import styled from 'styled-components'

const StyledList = styled.ul`
  margin-top: 5px;
  padding: 2px;
  font-size: 1rem;
  color: #333;
`

const StyledListItem = styled.li`
  margin-bottom: 2px;
  font-weight: 400;
  &::first-letter {
    text-transform: capitalize;
  }
`

const CampaignErrors = ({ errors = [] }) => {
  return (
    <PublishItem
      isError={!!errors?.length}
      width="100%"
      header="Campaign Errors"
    >
      <FlexBox>
        <StyledList>
          {errors.map((error, index) => (
            <StyledListItem key={index}>{error}</StyledListItem>
          ))}
        </StyledList>
      </FlexBox>
    </PublishItem>
  )
}

export default CampaignErrors
