import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'

import { Div, FlexBox } from 'styledComponent'
import { Text, DateOrMonthSelector, Breadcrumb } from 'components/Common'
import { spacing } from 'design-system'
import { useSelectStore, useVendorSelect } from 'hooks'
import { setProductAnalyticsDate } from 'redux/actions/productAnalyticsActions'
import { useBrandSelect } from '../../hooks'
import { DATE_OPTIONS } from './constants'
import { $MarginlessLine } from 'components/Content/autoEngagement/AutoEngagement.styled'

const IndicatorSeparator = () => null

const TopNavbar = ({ dateSelectBox, hideDateSelector }) => {
  const dispatch = useDispatch()
  const { data: date } = useSelectStore('productAnalytics.selectedDate')

  const {
    data: { vendorIds, selectedVendor },
  } = useSelectStore('vendorIds')

  const { availableBrands, selectedBrand, handleBrandChange } = useBrandSelect()

  const { handleVendorChange } = useVendorSelect(true)

  const onDateSelectChange = (value) => {
    dispatch(setProductAnalyticsDate({ advancedDate: value }))
  }

  const handleDateChange = (action) => {
    dispatch(setProductAnalyticsDate(action))
  }

  const handleMonthChange = (action) => {
    dispatch(setProductAnalyticsDate(action))
  }

  React.useEffect(() => {
    if (dateSelectBox && !date?.advancedDate?.value) {
      onDateSelectChange(DATE_OPTIONS[0])
    }
    return () => {
      if (dateSelectBox) {
        onDateSelectChange(null)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FlexBox my={spacing.l} px={spacing.l} justify="space-between">
        <Breadcrumb label="Product Management" />
      </FlexBox>
      {availableBrands?.length > 1 && (
        <>
          <$MarginlessLine my={spacing.s} />
          <FlexBox gap={spacing.m} px={spacing.l} align="center">
            <Text variant="small">Select Brand :</Text>
            <Div width="250px">
              <Select
                value={selectedBrand}
                options={availableBrands}
                onChange={handleBrandChange}
                components={{ IndicatorSeparator }}
              />
            </Div>
          </FlexBox>
          <$MarginlessLine mt={spacing.s} mb={spacing.l} />
        </>
      )}
    </>
  )
}

export default TopNavbar
