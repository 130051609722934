import React from 'react'
import styled from 'styled-components'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { FlexBox, TableCell } from 'styledComponent'
import { spacing } from 'design-system/styles'
import { getOSIcon } from 'utils/consumerAnalytics'
import { Skeleton, Text } from 'components/Common'
import * as moment from 'moment'
import { useSelectStore } from 'hooks'

export const TransactionInfo = ({ transactionInfo, loading }) => {
  const {
    data: { currencyCode },
  } = useSelectStore('auth.user')
  return (
    <FlexBox direction="column" margin={`${spacing.l} ${spacing.xl}`}>
      <Text variant="p" weight="bold">
        Transactional information:
      </Text>
      <FlexBox height="75px" justify="space-between" mt={spacing.s}>
        {loading ? (
          <Skeleton width={350} height={75} />
        ) : (
          <StyledContainerCols width="50%">
            <StyledContainerRows>
              <Text variant="small">Total Visits:</Text>
              <Text variant="small" weight="semi-bold">
                {transactionInfo?.totalVisits}
              </Text>
            </StyledContainerRows>
            <StyledContainerRows>
              <Text variant="small">Total Spend:</Text>
              <Text variant="small" weight="semi-bold">
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={transactionInfo?.totalSpent}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Text>
            </StyledContainerRows>
            <StyledContainerRows>
              <Text variant="small">Avg. Spend:</Text>
              <Text variant="small" weight="semi-bold">
                <FormattedNumber
                  style="currency"
                  currency={currencyCode}
                  value={transactionInfo?.avgSpent}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Text>
            </StyledContainerRows>
          </StyledContainerCols>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export const PersonalInfo = ({
  personalInfo,
  isEditProfile,
  editProfile,
  handleInputChange,
  loading,
}) => {
  return (
    <FlexBox direction="column" margin={`${spacing.m} ${spacing.xl}`}>
      <Text variant="p" weight="bold">
        Personal information:
      </Text>
      <FlexBox>
        {loading ? (
          <Skeleton width={350} height={230} count={1} />
        ) : (
          <StyledContainerCols width="50%">
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Spouse Name:</Text>
              {isEditProfile ? (
                <>
                  <StyledInput
                    width="75px"
                    font="12px"
                    name="spouseFirst"
                    value={editProfile?.spouseFirst}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 'spouseFirst')
                    }
                    placeholder="Firstname"
                  />
                  <StyledInput
                    width="75px"
                    font="12px"
                    name="spouseLast"
                    value={editProfile?.spouseLast}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 'spouseLast')
                    }
                    placeholder="Lastname"
                  />
                </>
              ) : (
                <Text variant="small" weight="semi-bold">
                  {!personalInfo?.spouseFirst
                    ? '-'
                    : `${personalInfo?.spouseFirst} ${personalInfo?.spouseLast}`}
                </Text>
              )}
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Contact:</Text>
              <Text variant="small" weight="semi-bold">
                {personalInfo?.phone || '-'}
              </Text>
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Email:</Text>
              {isEditProfile ? (
                <StyledInput
                  width="150px"
                  font="12px"
                  name="email"
                  value={editProfile?.email}
                  onChange={(e) => handleInputChange(e.target.value, 'email')}
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.email || '-'}
                </Text>
              )}
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">DOB:</Text>
              {isEditProfile ? (
                <StyledInput
                  type="date"
                  width="150px"
                  font="12px"
                  value={
                    editProfile?.birthDate &&
                    moment(editProfile?.birthDate).format('YYYY-MM-DD')
                  }
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'birthDate')
                  }
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {!personalInfo?.birthDate ? (
                    '-'
                  ) : (
                    <FormattedDate value={personalInfo?.birthDate} />
                  )}
                </Text>
              )}
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Age:</Text>
              {isEditProfile ? (
                <StyledInput
                  width="150px"
                  font="12px"
                  name="age"
                  value={
                    editProfile?.age || personalInfo?.age
                      ? moment(editProfile?.age || personalInfo?.age).diff(
                          new Date()
                        )
                      : ''
                  }
                  onChange={(e) => handleInputChange(e.target.value, 'age')}
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.age || '-'}
                </Text>
              )}
            </StyledContainerRows>
            {/* <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Married:</Text>
              {isEditProfile ? (
                <StyledInput
                  width="150px"
                  font="12px"
                  name="married"
                  value={editProfile?.married && editProfile?.married}
                  onChange={(e) => handleInputChange(e.target.value, 'married')}
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.married === 'NA' ? '-' : personalInfo?.married}
                </Text>
              )}
            </StyledContainerRows> */}
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">Anniversary Date:</Text>
              {isEditProfile ? (
                <StyledInput
                  type="date"
                  width="150px"
                  font="12px"
                  value={
                    editProfile?.anniversaryDate &&
                    moment(editProfile?.anniversaryDate).format('YYYY-MM-DD')
                  }
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'anniversaryDate')
                  }
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.anniversaryDate ? (
                    <FormattedDate value={personalInfo?.anniversaryDate} />
                  ) : (
                    '-'
                  )}
                </Text>
              )}
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">State:</Text>
              {isEditProfile ? (
                <StyledInput
                  width="150px"
                  font="12px"
                  name="state"
                  value={editProfile?.state}
                  onChange={(e) => handleInputChange(e.target.value, 'state')}
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.state || '-'}
                </Text>
              )}
            </StyledContainerRows>
            <StyledContainerRows margin={`${spacing.s} 0`}>
              <Text variant="small">City:</Text>
              {isEditProfile ? (
                <StyledInput
                  width="150px"
                  font="12px"
                  name="city"
                  value={editProfile?.city}
                  onChange={(e) => handleInputChange(e.target.value, 'city')}
                />
              ) : (
                <Text variant="small" weight="semi-bold">
                  {personalInfo?.city || '-'}
                </Text>
              )}
            </StyledContainerRows>
          </StyledContainerCols>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export const DeviceInfo = ({ deviceInfo, loading }) => {
  return (
    <FlexBox direction="column" margin={`${spacing.m} ${spacing.xl}`}>
      <Text variant="p" weight="bold">
        Device information:
      </Text>
      {loading ? (
        <Skeleton width={350} height={25} />
      ) : (
        <StyledContainerRows width="75%">
          <StyledContainerRows width="50%" justify="unset">
            <Text variant="small">
              Operating System:{' '}
              {!deviceInfo?.os ? '-' : getOSIcon(deviceInfo?.os)}
            </Text>
          </StyledContainerRows>
          <StyledContainerRows width="50%" justify="unset">
            <Text variant="small">Phone Model:</Text>
            <Text
              variant="small"
              weight="bold"
              margin={`0px 0px 0px ${spacing.m}`}
            >
              {deviceInfo?.device || '-'}
            </Text>
          </StyledContainerRows>
        </StyledContainerRows>
      )}
    </FlexBox>
  )
}

export const WorkInfo = ({
  workInfo,
  isEditProfile,
  editProfile,
  handleInputChange,
  loading,
}) => {
  return (
    <FlexBox direction="column" margin={`${spacing.m} ${spacing.xl}`}>
      <Text variant="p" weight="bold">
        Work information:
      </Text>
      {loading ? (
        <Skeleton width={350} height={25} />
      ) : (
        <StyledContainerRows width="75%">
          <StyledContainerRows width="50%" justify="unset">
            <Text variant="small">Company:</Text>
            {isEditProfile ? (
              <StyledInput
                font="12px"
                value={editProfile?.companyName}
                onChange={(e) =>
                  handleInputChange(e.target.value, 'companyName')
                }
              />
            ) : (
              <Text
                variant="small"
                weight="bold"
                margin={`0px 0px 0px ${spacing.m}`}
              >
                {workInfo?.companyName || '-'}
              </Text>
            )}
          </StyledContainerRows>
          <StyledContainerRows width="50%" justify="unset">
            <Text variant="small">Designation:</Text>
            {isEditProfile ? (
              <StyledInput
                font="12px"
                value={editProfile?.profession}
                onChange={(e) =>
                  handleInputChange(e.target.value, 'profession')
                }
              />
            ) : (
              <Text
                variant="small"
                weight="bold"
                margin={`0px 0px 0px ${spacing.m}`}
              >
                {workInfo?.profession || '-'}
              </Text>
            )}
          </StyledContainerRows>
        </StyledContainerRows>
      )}
    </FlexBox>
  )
}

export const StyledInput = styled.input`
  width: ${(props) => (props.width ? props.width : '125px')};
  font-weight: bold;
  font-size: ${(props) => (props.font ? props.font : '16px')};
  border: 1px solid ${(props) => props.theme.colors.neutral[300]};
  margin: ${spacing.s};
  padding: ${spacing.xs};

  &::placeholder {
    font-size: 14px;
    color: #aaa;
  }
`

export const StyledContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

export const StyledContainerRows = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
  margin: ${(props) => props.margin};

  svg {
    width: 15px;
    height: 15px;
  }
`
export const StyledContainerCols = styled(StyledContainerRows)`
  flex-direction: column;
`
export const $TableCell = styled(TableCell)`
  height: 100%;
`
