import React from 'react'
import { cloneDeep, isEqual } from 'lodash'

import { useStoreFetch, useSelectStore } from 'hooks'
import { getSettingsData } from 'redux/actions/settingsActions'
import { patch, post } from 'utils/axiosHandler'
import { FlexBox } from 'styledComponent'
import { SectionHeading, Input, Button, useToasts } from 'components/Common'
import { spacing } from 'design-system'
import { SETTING_TYPE, INITIAL_DATA, SETTINGS_HEADING } from './constants'

import {
  $SettingBlock,
  $Container,
  $Span,
  $FlexBox,
  $SettingItem,
} from '../Settings.styled'

const ProductAnalytics = () => {
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')
  const [productAnalyticsData, setProductAnalyticsData] = React.useState(null)
  const [savingState, setSavingState] = React.useState(false)

  const { toast } = useToasts()
  const { data, fetchData } = useStoreFetch(
    () => getSettingsData(SETTING_TYPE),
    'settings.productAnalytics'
  )

  const onInputChange = (e, key) => {
    const tempState = cloneDeep(productAnalyticsData)
    const index = tempState.settings.findIndex(({ label }) => label === key)
    if (index > -1) {
      const value = Number(e.target.value)
      tempState.settings[index].key = value > 90 ? 90 : value
      setProductAnalyticsData(tempState)
    }
  }

  const onSave = async () => {
    const body = cloneDeep(productAnalyticsData)
    setSavingState(true)
    try {
      const url = `/settings${body.vendorId ? `/vendor/${body.vendorId}` : ''}`
      const method = body.vendorId ? patch : post
      if (body.vendorId) {
        delete body.vendorId
      } else {
        body.vendorId = selectedVendor.value
      }

      const saveResponse = await method(url, body)
      if (saveResponse.code) {
        toast('Changes successfully saved', 'success')
        fetchData()
      } else {
        toast(
          saveResponse?.message || 'Something went wrong, please try again',
          'error'
        )
      }
    } catch (error) {
      toast('Something went wrong, please try again', 'error')
    } finally {
      setSavingState(false)
    }
  }

  const onCancel = React.useCallback(() => {
    const stateToPopulate = {
      ...(data || {}),
      settings: data?.settings?.length ? data?.settings : INITIAL_DATA.settings,
    }
    setProductAnalyticsData(stateToPopulate)
  }, [data])

  React.useEffect(() => {
    onCancel()
  }, [onCancel])

  return (
    <$SettingBlock>
      {productAnalyticsData?.settings?.map((setting, index) =>
        SETTINGS_HEADING[setting.label] ? (
          <$SettingItem
            key={index}
            padding={`${spacing.l} ${spacing.m}`}
            borderBottom
          >
            <$Container>
              <FlexBox align="center" justify="space-between">
                <SectionHeading
                  {...SETTINGS_HEADING[setting.label]}
                  variant="medium"
                  subVariant="small"
                />
                <FlexBox align="center">
                  <Input
                    variant="input"
                    type="number"
                    inputWidth="100px"
                    value={setting?.key || ''}
                    onChange={(e) => onInputChange(e, setting.label)}
                  />
                  <$Span>Days</$Span>
                </FlexBox>
              </FlexBox>
            </$Container>
          </$SettingItem>
        ) : null
      )}
      {!isEqual(data?.settings, productAnalyticsData?.settings) && (
        <$FlexBox bottom>
          <Button variant="primary" onClick={onSave} disabled={savingState}>
            SAVE
          </Button>
          <Button onClick={onCancel} variant="secondary">
            CANCEL
          </Button>
        </$FlexBox>
      )}
    </$SettingBlock>
  )
}

export default ProductAnalytics
