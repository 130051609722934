import React from 'react'
import styled from 'styled-components'
import { TooltipIcon } from 'design-system'
import { FlexBox } from 'styledComponent'
import { SectionHeading, Text, Pill, Skeleton } from 'components/Common'
import { spacing, borderRadius } from 'design-system/styles'

const getBackgroundColor = (color) => {
  switch (color) {
    case 'blue':
      return '#1EBCD1'
    case 'dark-blue':
      return '#4D90FC'
    case 'purple':
      return '#4F549E'
    case 'red':
      return '#FD7975'
    default:
      return '#4D90FC'
  }
}

const StyledStatsBox = styled.div`
  width: ${(props) => props.width || '288px'};
  height: 126px;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  background-color: ${(props) => getBackgroundColor(props.variant)};
  border-radius: ${borderRadius.small};
  padding: ${spacing.l} ${spacing.xl};

  img {
    height: 10px;
    width: 10px;
    border-radius: 0px;
  }
`

const StyledContentBox = styled.div`
  display: flex;
  margin-bottom: ${spacing.l};
`

export const StatsBox = ({
  title,
  isLoading,
  total,
  totalPercentage,
  icon,
  color,
  percentage,
  toolTip,
  ...rest
}) => (
  <StyledStatsBox {...rest}>
    <FlexBox justify="space-between" align="center">
      <SectionHeading
        toolTip={toolTip}
        heading={title}
        icon={<TooltipIcon fill="#fff" />}
        variant="medium"
        color="white"
      />
    </FlexBox>
    {isLoading ? (
      <Skeleton background="transparent" count={1} width={150} />
    ) : (
      <StyledContentBox>
        <Text variant="h2" weight="bold" type="white">
          {total}
        </Text>
      </StyledContentBox>
    )}
    {isLoading ? (
      <Skeleton background="transparent" count={1} width={75} />
    ) : (
      percentage && (
        <Pill
          avatar={icon}
          variant="stats"
          color={color}
          padding={`0px ${spacing.l}`}
        >
          {percentage}
        </Pill>
      )
    )}
  </StyledStatsBox>
)
