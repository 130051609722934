import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { Div } from 'styledComponent'
import { Text } from 'components/Common'
import { AddBlueIcon, CloseIcon } from 'assets/icons'
import UploadModal from 'components/Content/autoEngagement/components/UploadModal'
import { $Banner, $BannerContainer } from '../Survey.styled'

export const SurveyImage = ({ marginal, setMarginals, isEditing }) => {
  const [isPopOpen, setIsPopOpen] = useState(false)
  const { spacing } = useTheme()

  const setUrl = (url) => {
    setMarginals({ ...marginal, url })
    setIsPopOpen(false)
  }

  return (
    <Div>
      {marginal?.url ? (
        <$BannerContainer>
          <img src={marginal?.url} />
          {isEditing && <CloseIcon type="button" onClick={() => setUrl('')} />}
        </$BannerContainer>
      ) : (
        isEditing && (
          <$Banner onClick={() => setIsPopOpen(true)}>
            <Div>
              <AddBlueIcon /> &nbsp; Add Header Image here
              <Text align="center" variant="small" type="grey">
                (Image Dimensions Max. =
                <Text variant="small" type="main" weight="bold" mx={spacing.s}>
                  375px
                </Text>
                *
                <Text variant="small" type="main" weight="bold" ml={spacing.s}>
                  136px
                </Text>
                )
              </Text>
            </Div>
          </$Banner>
        )
      )}
      {isPopOpen && (
        <UploadModal
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          setMedia={({ fileLocation }) => setUrl(fileLocation)}
          showMedias={['image', 'gif']}
          uploadedFrom="survey"
        />
      )}
    </Div>
  )
}
