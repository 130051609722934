import React from 'react'
import { useNode } from '@craftjs/core'
import ContentEditable from 'react-contenteditable'

import { HTMLBlockSettings } from './HTMLBlockSettings'

export const HTMLBlock = (props) => {
  const {
    connectors: { connect },
  } = useNode()

  return (
    <ContentEditable
      innerRef={connect}
      html={props.htmlContent}
      disabled
      tagName="div"
    />
  )
}

export const HTMLBlockDefaultProps = {
  htmlContent: '<p>HTML Block</p>',
}

HTMLBlock.craft = {
  props: HTMLBlockDefaultProps,
  related: {
    settings: HTMLBlockSettings,
  },
}
