import React from 'react'
import { useDispatch } from 'react-redux'

import { useRouter, useSelectStore } from 'hooks'
import { PopupContainer, useToasts } from 'components/Common'
import {
  postCustomer,
  getCustomerById,
  getCustomerDetails,
} from 'redux/actions/crmAction'
import { CustomerForm } from './CustomerForm'
import { Div } from 'styledComponent'

let initialState = {
  firstName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  email: '',
  phone: '',
  profession: '',
  companyName: '',
  city: '',
  state: '',
  married: '',
  spouseFirst: '',
  spouseLast: '',
  anniversaryDate: '',
}

export const CRMForm = () => {
  const { toast } = useToasts()
  const dispatch = useDispatch()
  const { pathname, history } = useRouter()
  const { data: selectedStores } = useSelectStore('stores.selectedStores')

  const [custData, setCustData] = React.useState(initialState)
  const [isLoading, setIsLoading] = React.useState(false)
  const [editableForm, setEditableForm] = React.useState(false)
  const [isPopOpen, setIsPopOpen] = React.useState(false)

  React.useEffect(() => {
    const pathCrumbs = pathname.split('/')

    const id = pathCrumbs[2] || 'add'
    if (id !== 'add') {
      getCustomer(id)
    }

    if (pathCrumbs.includes('edit') || pathCrumbs.includes('add')) {
      setEditableForm(true)
    } else {
      setEditableForm(false)
    }
  }, [pathname])

  const getCustomer = async (id) => {
    toast('Loading form data. Please wait...')
    setIsLoading(true)
    try {
      const res = await dispatch(getCustomerById(id))
      setCustData({ ...custData, ...res?.personal[0] })
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  const postForm = async (formData) => {
    try {
      // const res = await getCustomerDetails({
      //   ...formData,
      //   storeIds: selectedStores,
      // })
      // if (res?.personal?.length) {
      //   return setIsPopOpen(res?.personal?.[0]?._id)
      // }
      await postCustomer(formData)
      toast('Profile Created successfully!', 'success')
      history.push('/consumer-profiling')
    } catch (err) {
      console.error(err)
      toast(err?.response?.data?.errors?.[0]?.msg || 'Unknown Error', 'error')
    }
  }

  return (
    <>
      <CustomerForm
        isLoading={isLoading}
        initialState={custData}
        sendForm={postForm}
        editableForm={editableForm}
      />
      <PopupContainer
        isPopOpen={!!isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Duplicate Entry"
        onSubmitClick={() =>
          history.push(`/consumer-profiling/${isPopOpen}/edit`)
        }
        submitButtonName="Edit User"
      >
        <Div my="32px">
          Phone Number or Email already exists in the database. Click{' '}
          <b>Edit User</b> to edit user details or click <b>Cancel</b> to add a
          different customer.
        </Div>
      </PopupContainer>
    </>
  )
}
