import styled, { css } from 'styled-components'
import PlusIcon from 'images/plus.svg'
import PlusWhiteIcon from 'images/plus-white.svg'

export const Button = styled.button`
  display: inline-flex;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: ${(props) => (props.noPadding ? '0px' : '.375rem .75rem')};
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props['size'] &&
    props['size'] === 'small' &&
    css`
      font-size: 0.8rem;
      padding: 0.275rem 0.5rem;
    `};
`

export const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primary['500']};
  color: ${(props) => props.theme.colors.neutral['100']};
  width: ${(props) => `${props.maxWidth}px` || 'auto'};
  justify-content: center;
  margin: ${(props) => props.margin || '0px 10px'};

  &:hover {
    background: ${(props) => props.theme.colors.primary['600']};
  }
`

export const PrimaryOutlineButton = styled(Button)`
  height: ${(props) => props.height};
  color: ${(props) => props.theme.colors.primary['500']};
  border-color: ${(props) => props.theme.colors.primary['400']};

  &:hover {
    color: ${(props) => props.theme.colors.neutral['100']};
    background: ${(props) => props.theme.colors.primary['400']};
  }
`

export const FilterOutlineButton = styled(Button)`
  height: ${(props) => props.height};
  color: #33475b;
  border-color: #33475b;

  &:hover {
    color: ${(props) => props.theme.colors.neutral['100']};
    border-color: ${(props) => props.theme.colors.primary['400']};
    background: ${(props) => props.theme.colors.primary['400']};
    svg {
      path.a,
      path.abc {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
`

export const SimpleOutlineButton = styled(Button)`
  height: 40px;
  width: 270px;
  color: ${(props) => props.theme.colors.primary['500']};
  border-color: #9e9e9e;
  display: flex;
  justify-content: center;
  padding: 0;
`

export const TextualButton = styled(Button)`
  color: ${(props) =>
    props.theme.colors[props['colorVariant'] || 'primary']['400']};
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.colors[props['colorVariant'] || 'primary']['400']
        : 'transparent'};
  border-radius: 4px;

  &:not(:disabled):hover,
  &:active {
    border-color: ${(props) =>
      props.theme.colors[props['colorVariant'] || 'primary']['400']};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.neutral['400']};
  }
`

export const CloseButton = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.3;
  position: relative;
  cursor: pointer;
  border: none;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.6;
  }
`

export const AddButton = styled.button.attrs({
  type: 'button',
})`
  border-radius: ${(props) =>
    ({
      default: '0',
      mild: '10px',
      smooth: '10px',
      ultra: '20px',
      round: '50%',
    }[props.corner || 'default'])};
  width: ${(props) =>
    ({
      tiny: '10px',
      small: '20px',
      medium: '30px',
      large: '40px',
    }[props.size])};
  height: ${(props) =>
    ({
      tiny: '10px',
      small: '20px',
      medium: '30px',
      large: '40px',
    }[props.size])};
  background: url(${(props) =>
      props.color === 'white' ? PlusWhiteIcon : PlusIcon})
    no-repeat ${(props) => (props.children ? 'left' : 'center')};
  background-size: ${(props) => props.bgSize || '20px'};
  background-color: ${(props) => props.bgColor || ''};
  border: none;
  padding-left: ${(props) => (props.children ? '1em' : '0px')};
  cursor: pointer;
  transition: all 0.3s;
  ${(props) =>
    props.animate &&
    `
            border: 1px solid transparent;
        `}

  &:hover {
    ${(props) =>
      props.animate &&
      `
                transform: scale(0.9);
            `};
  }

  &:focus {
    ${(props) =>
      props.animate &&
      `
                outline: none;
                border: 1px solid #ccc;
            `};
  }
`

export const TabButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary['400']};
  width: 100%;
  border-radius: 0;
  padding: 1rem 0;

  &:hover {
    background: ${(props) => props.theme.colors.primary['600']};
  }

  &:disabled {
    background: rgb(176, 206, 255);
  }
`
