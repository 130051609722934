import React, { Component } from 'react'
import '../../../css/content/consumerAnalytics.scss'
import { connect } from 'react-redux'
import { getConsumerDataTabs } from '../../../redux/actions/oldCAnalyticsActions'
// import { ReactComponent as ContactIcon } from '../../../images/ic_contact@2x.png'
import ConsumerAnalyticsNumbers from './ConsumerAnalyticsNumbers'
import Navbar from '../../Layout/Navbar'
import { Skeleton } from '../../Common'
import { ConsumerFilter } from './ConsumerFilter'
import {
  FlexBox,
  PrimaryOutlineButton,
  PrimaryButton,
} from '../../../styledComponent'
import { color } from '../../../design-system'
import {
  StatsOneIcon,
  StatsThreeIcon,
  StatsFourIcon,
} from '../../../assets/icons'

const ShowTabs = (props) => {
  return (
    <div
      className="headTabChild"
      style={{
        borderRadius: '5px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: props.color1,
        margin: '5% 10% 0% 10%',
        zIndex: 2,
        padding: '4%',
        position: 'relative',
      }}
    >
      <i>
        <props.imageIcon />
      </i>
      <div className="tabText">
        <div className="tabTextLabel">{props.label}</div>
        <div className="tabTextNumber">
          {props.isLoading ? (
            <Skeleton
              height={30}
              background="transparent"
              loader={props.color2}
            />
          ) : (
            props.value
          )}
        </div>
      </div>
    </div>
  )
}

class ConsumerAnalytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalTransactions: 0,
      retentionRate: 0,
      totalNumbersCollected: 0,
      isLoading: false,
      isExport: false,
    }

    this.consumerFilterRef = React.createRef()

    this.openFilterModal = this.openFilterModal.bind(this)
  }
  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.props.getConsumerDataTabs({}, { loader: true })
    this.setState({ isLoading: false })
  }

  openFilterModal = (_e, isExport = false) => {
    this.setState({ isExport })
    this.consumerFilterRef.current && this.consumerFilterRef.current.openModal()
  }

  render() {
    return (
      <div className="consumerAnalytics">
        <Navbar heading="Customer Analytics" />

        <div style={{ margin: '2% 3%' }}>
          <FlexBox justify="flex-end">
            <PrimaryOutlineButton onClick={this.openFilterModal}>
              Filter
            </PrimaryOutlineButton>
            <PrimaryButton onClick={(e) => this.openFilterModal(e, true)}>
              Export
            </PrimaryButton>
          </FlexBox>
        </div>

        <div className="headTabGroup">
          <ShowTabs
            label="All Time Transactions"
            value={this.props.cAnalytics.totaltransactions}
            color1={color.eggBlue}
            imageIcon={StatsThreeIcon}
            isLoading={this.state.isLoading}
          />
          <ShowTabs
            label="Total Numbers Collected"
            value={this.props.cAnalytics.totalNumbersCount}
            color1={color.butterflyBush}
            imageIcon={StatsFourIcon}
            isLoading={this.state.isLoading}
          />
          {/* <ShowTabs
            label="Retention Rate"
            value={this.props.cAnalytics.retentionRate + "%"}
            color1={color.brinkPink}
            imageIcon={StatsOneIcon}
            isLoading={this.state.isLoading}
          /> */}
          <ShowTabs
            label="Numbers Collected Today"
            value={this.props.cAnalytics.totalNumbersToday}
            color1={color.brinkPink}
            imageIcon={StatsOneIcon}
            isLoading={this.state.isLoading}
          />
        </div>
        <ConsumerAnalyticsNumbers />
        <ConsumerFilter
          ref={this.consumerFilterRef}
          isExportFilter={this.state.isExport}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cAnalytics: state.cAnalyticsOld,
  consumerData: state.cAnalyticsOld.data,
  errors: state.errors,
})

export default connect(mapStateToProps, { getConsumerDataTabs })(
  ConsumerAnalytics
)
