import React from 'react'

import { Text } from 'components/Common/Text'
import { ButtonDropdown } from 'components/Common/ButtonDropdown'
import { useRouter } from 'hooks'
import { useManagement } from '../../contexts'

const AddOrUpload = () => {
  const { history, pathname } = useRouter()
  const { forManagement } = useManagement()
  const onAddClick = () => {
    history.push({
      pathname: `${pathname}/products`,
      state: { forManagement },
    })
  }
  const onUploadClick = () => {
    history.push({
      pathname: `${pathname}/products/add/upload`,
      state: { forManagement },
    })
  }
  return (
    <ButtonDropdown buttonText="Add/Upload">
      <Text variant="medium" type="grey" onClick={onAddClick}>
        Add
      </Text>
      <Text variant="medium" type="grey" onClick={onUploadClick}>
        Upload
      </Text>
    </ButtonDropdown>
  )
}

export default AddOrUpload
