import React, { /* useState, */ useEffect } from 'react'
import { connect } from 'react-redux'
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts'
import { extendMoment } from 'moment-range'
import moment from 'moment'
import DataSet from '@antv/data-set'
import _ from 'lodash'

import { getTransactionsStoreWise } from '../../../../redux/actions/billingCharts'
import ChartHeader from './ChartHeader'
// import DateRangeSelector from './DateRangeSelector'

const mapStateToProps = ({ billingCharts }) => ({
  billMeStoreWise: billingCharts.billMeStoreWise,
  billMePrintStoreWise: billingCharts.billMePrintStoreWise,
  printStoreWise: billingCharts.printStoreWise,
  cwStoreWise: billingCharts.cwStoreWise,
})

const extendedMoment = extendMoment(moment)
const now = extendedMoment()
const defaultDateRange = moment.range(
  now.clone().startOf('month'),
  now.clone().endOf('day')
)

export default connect(mapStateToProps, { getTransactionsStoreWise })(
  (props) => {
    // const [date, setDate] = useState(defaultDateRange)
    // const [showCalendar, setShowCalender] = useState(false)

    const { DataView } = DataSet

    useEffect(() => {
      const selectedDate = props.selectedDate || defaultDateRange

      // setDate(selectedDate)

      props.getTransactionsStoreWise({
        startDate: selectedDate.start?.toDate(),
        endDate: selectedDate.end?.toDate(),
        type: props.type,
        selectedStoreIds: props.selectedStoreIds,
      })
    }, [])

    const getTitle = (type) => {
      switch (type) {
        case 'BM':
          return 'BillMe Transactions'
        case 'BMPrint':
          return 'BillMe + Print Transactions'
        case 'Print':
          return 'Print Transactions'
        case 'C.W':
          return 'CW Transactions'
      }
    }
    const getDataByType = (type) => {
      switch (type) {
        case 'BM':
          return props.billMeStoreWise
        case 'BMPrint':
          return props.billMePrintStoreWise
        case 'Print':
          return props.printStoreWise
        case 'C.W':
          return props.cwStoreWise
      }
    }

    // const handleDateRangeChange = (values) => {
    //   setDate(values)
    //   setShowCalender(false)

    //   const startDate = values.start.startOf('day').toDate()
    //   const endDate = values.end.endOf('day').toDate()

    //   props.getTransactionsStoreWise({
    //     startDate,
    //     endDate,
    //     type: props.type,
    //   })
    // }

    // const toggleCalender = () => {
    //   setShowCalender((state) => !state)
    // }
    const dv = new DataView()
    const data =
      getDataByType(props.type) === undefined ? [] : getDataByType(props.type)

    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'name',
      as: 'percent',
    })
    const cols = {
      percent: {
        formatter: (val) => val * 100 + '%',
      },
    }
    return (
      <div className="transactionsChart chartsMain">
        <ChartHeader title={getTitle(props.type)} />
        <hr />
        {/* <DateRangeSelector
          value={date}
          onDateChange={handleDateRangeChange}
          showCalendar={showCalendar}
          toggleCalender={toggleCalender}
        /> */}
        <Chart
          height={400}
          data={dv}
          scale={cols}
          padding={[80, 100, 80, 80]}
          forceFit
        >
          <Coord type="theta" radius={0.75} />
          <Axis name="percent" />
          <Legend
            position="right"
            offsetY={-window.innerHeight / 2 + 120}
            offsetX={-70}
          />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Geom
            type="intervalStack"
            position="percent"
            color="name"
            tooltip={[
              'name*percent',
              (item, percent) => {
                percent = parseFloat(percent * 100).toFixed(1) + '%'
                return {
                  name: item,
                  value: percent,
                }
              },
            ]}
            style={{
              lineWidth: 1,
              stroke: '#fff',
            }}
          >
            <Label
              content="count"
              formatter={(val, item) => item.point.name + ': ' + val}
            />
          </Geom>
        </Chart>
        {_.isEmpty(getDataByType(props.type)) ? (
          <div className="noData">
            No data to display between selected dates
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
)
