import React, { Component } from 'react'
import moment from 'moment'
import {
  CheckBox,
  Text,
  GreyText,
  FlexBox,
  Block,
  Logo,
  Card,
  CardContent,
} from 'styledComponent'
import 'css/content/feedback.scss'
import { Badge } from 'components/Common/Badge'
import FeedbackIcons from './FeedbackIcons'

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleFeedbackClick = (event) => {
    this.props.onClick && this.props.onClick()
  }

  handleCheckboxClick = (event) => {
    this.props.onCheckBoxClick(
      event.target.checked ? `add` : `remove`,
      this.props.feedback.billUid
    )
  }

  render() {
    const {
      user,
      billUid,
      feedback,
      feedbackDate,
      brandLogo,
      brandName,
      displayAddress,
      starRating,
      reply,
      quickFeedback,
      email,
      scaleType = 'star',
      scaleLength = 5,
      filledRating = null,
    } = this.props.feedback
    console.log('starRating', starRating)
    const isChecked = this.props.checkedBillUids.includes(billUid)

    return (
      <Card onClick={this.handleFeedbackClick} cursor={`pointer`}>
        <CardContent width="70%">
          <FlexBox className="mt-1">
            <CheckBox
              variant="large"
              checked={isChecked}
              stopPropagation
              onClick={this.handleCheckboxClick}
              id={billUid}
            />
            <Text>{email ? email : user}</Text>
            <FlexBox className="ml-3">
              <FeedbackIcons
                starRating={starRating}
                scaleLength={scaleLength}
                scaleType={scaleType}
                filledRating={filledRating}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox className="my-1">
            <Text>{feedback || `-`}</Text>
          </FlexBox>
          <Block className="my-2">
            <div>
              <GreyText variant="tiny">
                {moment(feedbackDate).format(`h:m a | D/M/YYYY`)}
              </GreyText>
            </div>
          </Block>
        </CardContent>
        <CardContent width="30%">
          <FlexBox className="px-1 pt-1">
            <Logo className="mr-2" src={brandLogo} size="small" />
            <div className="feedback-brand-info">
              <Text variant="body2">{brandName}</Text>
              <GreyText variant="caption">{displayAddress}</GreyText>
              {(reply || quickFeedback) && (
                <div className="feedback-badge">
                  {quickFeedback && (
                    <Badge variant="butterflyBush" size="small">
                      Quick feedback
                    </Badge>
                  )}
                  {reply && (
                    <Badge variant="reply" size="small">
                      REPLIED
                    </Badge>
                  )}
                </div>
              )}
            </div>
          </FlexBox>
        </CardContent>
      </Card>
    )
  }
}

export default Feedback
