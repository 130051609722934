import React from 'react'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'

import {
  FilterFooter,
  FilterHeader,
  MultiSelectFilter,
} from 'components/Common/FIlterComponents'
import CustomPopup from 'components/Content/CustomPopup'
import { initialFilter } from './constants'
import { getMinMax } from 'utils'
import { Input, Checkbox, CreateTags } from 'components/Common'
import { spacing } from 'design-system'
import { FlexBox } from 'styledComponent'

const Filter = React.forwardRef(
  ({ data, handleFilter, isExport, setIsExport, handleExport }, ref) => {
    const [filter, setFilter] = React.useState(initialFilter)

    React.useEffect(() => {
      setFilter({ ...initialFilter, ...data })
    }, [data, ref.current])

    React.useEffect(() => {
      if (!isExport) {
        const copyFilter = cloneDeep(filter)
        copyFilter.emailList = {
          isActive: false,
        }
        setFilter(copyFilter)
      }
    }, [isExport])

    const handleRangeChange = (value, field, key) => {
      const copyFilter = cloneDeep(filter)
      copyFilter[field].isActive = true
      copyFilter[field].value[key] = value
      setFilter(copyFilter)
    }

    const handleCheckboxChange = (e, field) => {
      const key = e.target.id
      const copyFilter = cloneDeep(filter)

      copyFilter[field].isActive = true
      copyFilter[field].value[key] = e.target.checked

      if (
        copyFilter.gender.value.male === false &&
        copyFilter.gender.value.female === false &&
        copyFilter.gender.value.other === false
      ) {
        copyFilter.gender.isActive = false
      }
      if (
        copyFilter.relationship.value.married === false &&
        copyFilter.relationship.value.unmarried === false
      ) {
        copyFilter.relationship.isActive = false
      }
      setFilter(copyFilter)
    }

    const handleMultiSelectChange = (value, field, actionMeta) => {
      const copyFilter = cloneDeep(filter)
      const defaultFilter = initialFilter
      const deleteOption = (type) => {
        const index = copyFilter[field].value
          .map((item) => item.label)
          .indexOf(actionMeta[type].label)
        if (index > -1) {
          copyFilter[field].value.splice(index, 1)
        }
        if (copyFilter[field].value.length === 0) {
          copyFilter[field].isActive = false
        }
      }
      if (actionMeta.action === 'select-option') {
        copyFilter[field].isActive = true
        copyFilter[field].value = [
          ...copyFilter[field].value,
          value[copyFilter[field].value.length],
        ]
      } else if (actionMeta.action === 'deselect-option') {
        deleteOption('option')
      } else if (actionMeta.action === 'remove-value') {
        deleteOption('removedValue')
      } else if (actionMeta.action === 'clear') {
        copyFilter[field] = defaultFilter[field]
      } else copyFilter[field].isActive = !copyFilter[field].isActive

      setFilter(copyFilter)
    }

    const handleClearFilter = () => {
      setFilter(cloneDeep(initialFilter))
    }

    const handleFilterActiveChange = (e, filterType) => {
      const copyFilter = cloneDeep(filter)
      copyFilter[filterType].isActive = e.target.checked
      setFilter(copyFilter)
    }

    const _populateFilterInput = (filterData) => {
      switch (filterData.inputType) {
        case 'range':
          return (
            <Input
              variant="range"
              type="number"
              // label={filterData.label}
              id={['min', 'max']}
              inputWidth="125px"
              value={[
                filter?.[filterData.type]?.value.min,
                filter?.[filterData.type]?.value.max,
              ]}
              onChange={[
                (e) =>
                  handleRangeChange(
                    getMinMax(0, Infinity, e.target.value),
                    filterData.type,
                    'min'
                  ),
                (e) =>
                  handleRangeChange(
                    getMinMax(
                      filter?.[filterData.type]?.value.min,
                      Infinity,
                      e.target.value
                    ),
                    filterData.type,
                    'max'
                  ),
              ]}
            />
          )
        case 'checkBox':
          return (
            <FlexBox mt={spacing.l}>
              {Object.keys(filterData.value).map((label, index) => (
                <React.Fragment key={index}>
                  <Checkbox
                    id={label}
                    mr={spacing.l}
                    onCheckboxChange={(e) =>
                      handleCheckboxChange(e, filterData.type)
                    }
                    isChecked={filter?.[filterData.type]?.value[label]}
                  >
                    {label}
                  </Checkbox>
                </React.Fragment>
              ))}
            </FlexBox>
          )
        case 'multiSelect':
          return (
            <MultiSelectFilter
              // label={filterData.label}
              id={filterData.type}
              value={filter?.[filterData.type]?.value}
              handleMultiSelectChange={handleMultiSelectChange}
              options={filterData.options || []}
            />
          )
        case 'emailList':
          return (
            <div style={{ textTransform: 'none' }}>
              <CreateTags
                isEmail
                value={filter?.[filterData.type]?.value || []}
                onChange={(val) => {
                  const copyFilter = cloneDeep(filter)
                  copyFilter.emailList = {
                    isActive: true,
                    value: val,
                  }
                  setFilter(copyFilter)
                }}
                placeholder="Enter Emails"
              />
            </div>
          )
        default:
          break
      }
    }

    return (
      <CustomPopup
        className={'filter-modal'}
        ref={ref}
        headerComponent={true}
        heading={
          <FilterHeader
            ref={ref}
            filter={filter}
            isExport={isExport}
            setIsExport={setIsExport}
            filterHeader={isExport ? 'Export Filters' : 'Filters'}
          />
        }
        footer={
          <FilterFooter
            ref={ref}
            filter={filter}
            handleClearAll={handleClearFilter}
            handleFilter={handleFilter}
            isExport={isExport}
            setIsExport={setIsExport}
            handleExport={handleExport}
          />
        }
      >
        <$FilterWrapper>
          {Object.values(initialFilter).map(
            (filterData, index) =>
              !(!isExport && filterData.type === 'emailList') && (
                <$FilterBlock
                  key={index}
                  active={filter?.[filterData.type]?.isActive}
                >
                  <$FilterItemLabel>
                    <Checkbox
                      id={filterData?.type}
                      onCheckboxChange={(e) =>
                        handleFilterActiveChange(e, filterData?.type)
                      }
                      isChecked={filter?.[filterData.type]?.isActive}
                    >
                      {filterData?.label}
                    </Checkbox>
                  </$FilterItemLabel>
                  <$FilterItem>{_populateFilterInput(filterData)}</$FilterItem>
                </$FilterBlock>
              )
          )}
        </$FilterWrapper>
      </CustomPopup>
    )
  }
)

export default Filter

const $FilterWrapper = styled.div`
  padding: 10px;
  max-height: 60vh;
  overflow-y: auto;
`

const $FilterBlock = styled(FlexBox)`
  padding: 10px 16px 10px 11px;
  margin: 10px 0;
  box-shadow: 0px 2px 30px #00000016;
  border-radius: 5px;
  border-left: 5px solid
    ${(props) => (props.active ? '#0D52C2' : 'transparent')};
  transition: border-left 0.2s ease-in-out;
  min-height: 70px;
  align-items: center;
`

const sharedStyle = `
 > div {
   padding: 0;
   margin: 0;
 }
`

const $FilterItemLabel = styled.div`
  ${sharedStyle}
  padding: 16px 0;
  width: 25%;
  border-right: 1px solid #f0f0f0;
`

const $FilterItem = styled.div`
  ${sharedStyle}
  padding: 0 25px;
  width: 75%;
  text-transform: capitalize;
`
