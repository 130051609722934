import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkReadPermission } from 'redux/actions/authActions'
import checkFeatureAlloted from '../../utils/checkFeatureAlloted'
// import Popups from "../Content/profile/Popups";
import 'css/simple-sidebar.scss'
import 'css/content/profile.scss'
import {
  SidebarWrapper,
  SidebarRoute,
  SidebarChildren,
} from 'styledComponent/components/layout/sidebar'
import {
  DashboardIcon,
  ConsumerAnalyticsIcon,
  BillingAnalyticsIcon,
  BillsIcon,
  FeedbackIcon,
  ProfileIcon,
  UserAccessIcon,
  BillMeLogo,
  BillMeLogoShort,
  RightIcon,
  EmailIcon,
  SmsIcon,
  SurveysIcon,
  CouponIcon,
  CampaignsIcon,
  DigitalIcon,
  MediaIcon,
  BusinessProfileIcon,
  UserComplaintsIcon,
  ProductsIcon,
  JourneyIcon,
  SettingsIcon,
  CustSegmentIcon,
  StoreIcon,
  UsageAndInvoiceIcon,
  WhatsAppIcon,
} from 'assets/icons'
import { userTypes } from './constants'
import { useRouter } from 'hooks'

const generalList = [
  // {
  //   itemId: 'analytics',
  //   itemName: 'Analytics',
  //   ItemIcon: <ConsumerAnalyticsIcon />,
  //   permission: ['bAnalytics', 'cAnalyticsPro', 'cAnalytics', 'pAnalyticsPro'],
  //   childItems: [
  //     {
  //       itemId: 'billingAnalytics',
  //       itemName: 'Billing Analytics',
  //       authId: 'billingAnalysis',
  //       to: '/billingAnalytics',
  //       permission: 'bAnalytics',
  //     },
  //     /* {
  //       itemId: 'consumerAnalytics',
  //       itemName: 'Consumer Analytics',
  //       authId: 'customerAnalysis',
  //       to: '/consumer-analytics',
  //       permission: 'cAnalyticsPro',
  //     }, */
  //     {
  //       itemId: 'betaConsumerAnalytics',
  //       itemName: 'Customer Analytics',
  //       authId: 'customerAnalysis',
  //       to: '/beta-consumer-analytics',
  //       permission: 'cAnalytics',
  //     },
  //     {
  //       itemId: 'productAnalytics',
  //       itemName: 'Product Analytics',
  //       authId: 'productAnalytics',
  //       to: '/product-analytics',
  //       permission: 'pAnalyticsPro',
  //     },
  //   ],
  // },
  {
    itemId: 'bills',
    itemName: 'Bills',
    authId: 'bills',
    to: '/bills',
    ItemIcon: <BillsIcon />,
    permission: 'bills',
  },
  // {
  //   itemId: 'reports',
  //   itemName: 'Reports',
  //   authId: 'reports',
  //   to: '/reports',
  //   ItemIcon: <BillingAnalyticsIcon />,
  //   permission: 'reports',
  // },
  // {
  //   itemId: 'advertisement',
  //   itemName: 'Advertisement',
  //   authId: 'advertisement',
  //   to: '/advertisement',
  //   ItemIcon: <CampaignsIcon />,
  //   permission: 'advertisement',
  // },
  {
    itemId: 'feedback',
    itemName: 'Feedback',
    authId: 'feedback',
    to: '/feedback',
    ItemIcon: <FeedbackIcon />,
    permission: ['feedback', 'feedbackReplyPro'],
    childItems: [
      {
        authId: 'feedback',
        itemId: 'responses',
        itemName: 'Responses',
        to: '/feedback/responses',
        permission: 'feedbackReplyPro',
      },
      {
        authId: 'feedback',
        itemId: 'campaign',
        itemName: 'Campaigns',
        to: '/feedback/campaigns',
        permission: 'feedbackReplyPro',
      },
    ],
  },
  {
    itemId: 'crm',
    itemName: 'Consumer Profiling',
    authId: 'crm',
    to: '/consumer-profiling',
    ItemIcon: <ProfileIcon />,
    permission: 'crm',
  },
]

const communicationList = [
  {
    itemId: 'allCampaigns',
    itemName: 'All Campaigns',
    authId: 'autoEngage',
    to: '/auto-engage',
    ItemIcon: <CampaignsIcon />,
    permission: [
      'smsCampaign',
      'emailCampaign',
      'adCampaign',
      'sellCampaign',
      'bannerCampaign',
      'surveyCampaign',
      'popupCampaign',
      'whatsAppCampaign',
    ],
  },
  {
    itemId: 'sms',
    itemName: 'SMS',
    authId: 'autoEngage',
    to: '/auto-engage/sms',
    ItemIcon: <SmsIcon />,
    permission: 'smsCampaign',
  },
  {
    itemId: 'email',
    itemName: 'E-Mail',
    authId: 'autoEngage',
    to: '/auto-engage/email',
    ItemIcon: <EmailIcon />,
    permission: 'emailCampaign',
  },
  {
    itemId: 'whatsApp',
    itemName: 'WhatsApp',
    authId: 'autoEngage',
    to: '/auto-engage/whatsApp',
    ItemIcon: <WhatsAppIcon />,
    permission: 'whatsAppCampaign',
  },
  {
    itemId: 'digitalBills',
    itemName: 'Digital Bills',
    authId: 'digitalBills',
    ItemIcon: <DigitalIcon />,
    permission: [
      'adCampaign',
      'sellCampaign',
      'bannerCampaign',
      'surveyCampaign',
      'popupCampaign',
    ],
    childItems: [
      {
        itemId: 'bannerInBill',
        itemName: 'Banner in Bill',
        authId: 'autoEngage',
        to: '/auto-engage/bannerInBill',
        permission: 'bannerCampaign',
      },
      {
        itemId: 'adBelowBill',
        itemName: 'Ad Below Bill',
        authId: 'autoEngage',
        to: '/auto-engage/adBelowBill',
        permission: 'adCampaign',
      },
      {
        itemId: 'surveyInBill',
        itemName: 'Add Survey Button',
        authId: 'autoEngage',
        to: '/auto-engage/surveyInBill',
        permission: 'surveyCampaign',
      },
      {
        itemId: 'sellBelowBill',
        itemName: 'Sell Below Bill',
        authId: 'autoEngage',
        to: '/auto-engage/sellBelowBill',
        permission: 'sellCampaign',
      },
      {
        itemId: 'popupOverBill',
        itemName: 'Popup Over Bill',
        authId: 'autoEngage',
        to: '/auto-engage/popupOverBill',
        permission: 'popupCampaign',
      },
    ],
  },
]

const createList = [
  {
    itemId: 'journey',
    itemName: 'Auto Engagement',
    authId: 'autoEngage',
    to: '/journey',
    ItemIcon: <JourneyIcon />,
    permission: 'journey',
  },
  {
    itemId: 'survey',
    itemName: 'Surveys',
    authId: 'autoEngage',
    to: '/auto-engage/surveys',
    ItemIcon: <SurveysIcon />,
    permission: 'surveys',
  },
  {
    itemId: 'coupon',
    itemName: 'Coupons',
    authId: 'autoEngage',
    to: '/auto-engage/coupons',
    ItemIcon: <CouponIcon />,
    permission: 'coupons',
  },
]

const manageList = [
  {
    itemId: 'userAccess',
    itemName: 'User Access',
    authId: 'userAccess',
    to: '/userAccess',
    ItemIcon: <UserAccessIcon />,
    permission: 'uAccess',
  },
  {
    itemId: 'custSegmentation',
    itemName: 'Customer Segmentations',
    authId: 'custSegmentation',
    to: '/segment',
    ItemIcon: <CustSegmentIcon />,
    permission: 'custSegmentation',
  },
  {
    itemId: 'storeManagement',
    itemName: 'Store Management',
    authId: 'storeManagement',
    to: '/storeManagement',
    ItemIcon: <StoreIcon />,
    permission: 'storeManagement',
  },
  {
    itemId: 'companyUsage',
    itemName: 'Usage & Invoices',
    authId: 'companyUsage',
    to: '/usage-and-invoices',
    ItemIcon: <UsageAndInvoiceIcon />,
    permission: 'companyUsage',
  },
  // {
  //   itemId: 'profile',
  //   itemName: 'Business Profile',
  //   authId: 'profile',
  //   to: '/profile',
  //   ItemIcon: <BusinessProfileIcon />,
  //   permission: 'bProfile',
  // },
  {
    itemId: 'uploadedData',
    itemName: 'Media Bank',
    authId: 'autoEngage',
    to: '/auto-engage/uploadedData',
    ItemIcon: <MediaIcon />,
    permission: 'mBank',
  },
  // {
  //   itemId: 'productManagement',
  //   itemName: 'Product Management',
  //   authId: 'productManagement',
  //   to: '/product-management',
  //   ItemIcon: <ProductsIcon />,
  //   permission: 'products',
  // },
  {
    itemId: 'userComplaints',
    itemName: 'Customer Complaints',
    authId: 'billComplaints',
    to: '/bill-complaints',
    ItemIcon: <UserComplaintsIcon />,
    permission: 'custComplaints',
  },
  {
    itemId: 'settings',
    itemName: 'Settings',
    authId: 'setting',
    to: '/settings',
    ItemIcon: <SettingsIcon />,
    permission: 'settings',
  },
  // {
  //   itemId: 'billing',
  //   itemName: 'Billing & Usage',
  //   authId: 'billing',
  //   to: '/billing',
  //   ItemIcon: <BillsIcon />,
  //   permission: 'serviceSubscribtion',
  // },
  // {
  //   itemId: 'setting',
  //   itemName: 'Old Settings',
  //   authId: 'setting',
  //   to: '/setting',
  //   ItemIcon: <SettingsIcon />,
  //   permission: 'settings',
  // },
]

const Sidebar = (props) => {
  const { location } = useRouter()
  const [showFullLogo, setShowFullLogo] = useState(false)
  const [sideLink, setSideLink] = useState('')
  const { featuresAlloted } = props.selectedVendor
  const homepage =
    props.auth?.user?.storeLogin || props?.registeredFrom === 'shopify'
      ? !props?.isSubscribed && props?.serviceSubscribtion
        ? '/bills'
        : '/bills'
      : '/dashboard'

  const isSideBarActive = (item) => {
    if (item.itemId === 'allCampaigns') {
      return (
        location.pathname.split('/')[
          location.pathname.split('/')?.length - 1
        ] === 'auto-engage'
      )
    }
    if (item.childItems) {
      const isAvail = item.childItems?.some((child) =>
        location?.pathname?.includes(child.to)
      )
      return isAvail
    }
    return location?.pathname?.includes(item.to)
  }
  const authFilter = (items) => {
    const filItems = items
      ?.map(({ childItems, ...item }) => {
        if (childItems) {
          const filteredChild = childItems?.filter(
            (child) =>
              child?.authId &&
              props.checkReadPermission(child?.authId) &&
              checkFeatureAlloted(child?.permission, featuresAlloted)
          )
          childItems = [...filteredChild]
        }
        // item.childItems.length === 0  && delete item.childItems
        return childItems?.length ? { ...item, childItems } : { ...item }
      })
      ?.filter((sideItem) => {
        if (sideItem?.childItems?.length) {
          return true
        } else
          return (
            sideItem &&
            props.checkReadPermission(sideItem.authId) &&
            checkFeatureAlloted(sideItem.permission, featuresAlloted)
          )
      })
    return filItems
  }

  const getChildrenItem = (item) => (
    <>
      {item.ItemIcon}
      <span>{item.itemName}</span>
      {item.childItems && <RightIcon />}
    </>
  )

  const getSideItem = (list) => {
    return authFilter(list)?.map((item) => (
      <SidebarRoute
        active={isSideBarActive(item)}
        key={item.itemId}
        onMouseEnter={() => setSideLink(item.itemId)}
        onMouseLeave={() => setSideLink(item.itemId)}
      >
        {item.to ? (
          <Link to={item.to}>{getChildrenItem(item)}</Link>
        ) : (
          <a>{getChildrenItem(item)}</a>
        )}

        {showFullLogo && item.childItems && (
          <SidebarChildren showFullHeight={sideLink === item.itemId}>
            {item.childItems.map((childItem, j) => {
              return (
                <SidebarRoute
                  key={j}
                  active={location.pathname?.includes(childItem.to)}
                >
                  <Link to={childItem.to}>
                    <div>{childItem.itemName}</div>
                  </Link>
                </SidebarRoute>
              )
            })}
          </SidebarChildren>
        )}
      </SidebarRoute>
    ))
  }
  const dashboardAllowed = checkFeatureAlloted('dashboard', featuresAlloted)
  return (
    <SidebarWrapper
      onMouseEnter={() => setShowFullLogo(true)}
      onMouseLeave={() => setShowFullLogo(false)}
    >
      <SidebarRoute
        logo
        onMouseEnter={() =>
          !props.hideDashboard && dashboardAllowed
            ? setSideLink('dashboard')
            : '#'
        }
        onMouseLeave={() =>
          !props.hideDashboard && dashboardAllowed
            ? setSideLink('dashboard')
            : '#'
        }
      >
        <Link to={homepage}>
          {showFullLogo ? (
            <BillMeLogo height="60px" />
          ) : (
            <BillMeLogoShort height="60px" />
          )}
        </Link>
      </SidebarRoute>
      <hr style={showFullLogo ? {} : { borderColor: '#FFF' }} />
      {!props.hideDashboard && dashboardAllowed && (
        <SidebarRoute
          active={props.selectedLink === 'dashboard'}
          onMouseEnter={() => setSideLink('dashboard')}
          onMouseLeave={() => setSideLink('dashboard')}
        >
          <Link to="/dashboard">
            <DashboardIcon />
            <span>Dashboard</span>
          </Link>
        </SidebarRoute>
      )}
      {getSideItem(generalList)}
      <hr style={showFullLogo ? {} : { borderColor: '#FFF' }} />
      {authFilter(communicationList).length ? (
        <>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '10px',
              color: showFullLogo ? '' : '#FFF',
            }}
          >
            Communication Channel
          </p>
          {getSideItem(communicationList)}
          <hr style={showFullLogo ? {} : { borderColor: '#FFF' }} />
        </>
      ) : null}
      {authFilter(createList).length ? (
        <>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '10px',
              color: showFullLogo ? '' : '#FFF',
            }}
          >
            Create
          </p>
          {getSideItem(createList)}
          <hr style={showFullLogo ? {} : { borderColor: '#FFF' }} />
        </>
      ) : null}
      <p
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginRight: '10px',
          color: showFullLogo ? '' : '#FFF',
        }}
      >
        Manage
      </p>
      {getSideItem(manageList)}
    </SidebarWrapper>
  )
}

const mapStateToProps = (state) => {
  const vendorIds = state.vendorIds.vendorIds.map((x) => x.value)
  return {
    auth: state.auth,
    errors: state.errors,
    selectedLink: state.sidebarLink,
    hideDashboard: [
      userTypes.CASHIER,
      userTypes.MANAGER,
      userTypes.OPERATIONS,
      userTypes.SECURITY,
    ].includes(state.auth.user.userType),
    vendorIds,
    selectedVendor: state.vendorIds.selectedVendor,
    registeredFrom: state.vendorIds.selectedVendor.registeredFrom,
    isSubscribed:
      state.stores?.stores[Object.keys(state.stores?.stores)?.[0]]
        ?.isSubscribed,
    onceOpened: state.bRed.onceOpened,
    serviceSubscribtion:
      state.vendorIds.selectedVendor.featuresAlloted?.serviceSubscribtion,
    // featuresAlloted: state.vendorIds.vendorIds[0].featuresAlloted,
  }
}

export default connect(mapStateToProps, {
  checkReadPermission,
})(withRouter(Sidebar))
