import createReducer from 'utils/createReducer'
import * as types from '../types/crm'

const initialState = {
  filterData: {
    age: {
      id: 1,
      type: 'age',
      label: 'Age',
      isActive: false,
      value: {
        min: 0,
        max: 100,
      },
    },
    gender: {
      id: 2,
      type: 'gender',
      label: 'Gender',
      isActive: false,
      value: {
        male: false,
        female: false,
      },
    },
    relationship: {
      id: 3,
      type: 'relationship',
      label: 'Relationship',
      isActive: false,
      value: {
        married: false,
        unmarried: false,
        single: false,
      },
    },
    states: {
      id: 4,
      type: 'states',
      label: 'States',
      isActive: false,
      value: [],
    },
  },
  filterQuery: {},
}

export default createReducer(initialState)(types)
