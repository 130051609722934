import React from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'

import generateRandomColor from 'utils/generateRandomColor'

/**
 * @description common Pie Chart component.
 * @param {Array<Object>} data chart data
 * @param {Function} onDataPointClick data point click handler
 * @param {Boolean} legend show/hide legend
 */
const PieChart = ({ data, onDataPointClick, legend, ...props }) => {
  const dataPointClickHandler = (
    event,
    chartContext,
    { seriesIndex, dataPointIndex, config }
  ) => {
    if (dataPointIndex > -1 && typeof onDataPointClick === 'function') {
      const { key, value } = data[dataPointIndex]
      const chartDataPoint = {
        key,
        value,
      }
      return onDataPointClick(chartDataPoint)
    }
    return null
  }
  const chartConfig = {
    series: data.map(({ value }) => value),
    options: {
      chart: {
        type: 'pie',
        events: {
          dataPointSelection: dataPointClickHandler,
        },
      },
      labels: data.map(({ key }) => key),
      colors: data.map(({ color }) => color || generateRandomColor()),
      legend: {
        show: legend,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: () => '',
          title: {
            formatter: (val) => val,
          },
        },
      },
    },
  }
  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="pie"
      {...props}
    />
  )
}

export default PieChart

PieChart.defaultProps = {
  height: 350,
  onDataPointClick: null,
  legend: true,
}

PieChart.prototype = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
}
