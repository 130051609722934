import React, { Component } from 'react'
import { connect } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import CustomPopup from 'components/Content/CustomPopup'
import FilterFooter from 'components/Content/manageStores/StoreFilter/Footer'
import {
  defaultFeedbackCampaignFilters,
  dynamicBorderColor,
} from 'utils/feedback'
import {
  setFeedbackCampaignFilters,
  resetCampaignFilters,
  getFeedbackCampaigns,
  setRememberCampaignFilters,
} from 'redux/actions'
import { formatDateInFilters } from 'utils/getDateFormat'
import { DateFilter, FilterHeader } from 'components/Common'

class CampaignFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {},
      rememberFilters: false,
    }

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleResetFilters = this.handleResetFilters.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleRememberFilters = this.handleRememberFilters.bind(this)
    this.handleDatePicker = this.handleDatePicker.bind(this)
    this.onDateSelect = this.onDateSelect.bind(this)
    this.onSetFilters = this.onSetFilters.bind(this)
  }

  componentDidMount() {
    this.setState({
      filters: this.props.filters,
      rememberFilters: this.props.rememberFilters || false,
    })
  }

  handleDatePicker() {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen })
  }

  handleCheckboxClick = async (_e, filterName) => {
    let filters = cloneDeep(this.state.filters)
    filters[filterName].isActive =
      filters[filterName] && !filters[filterName].isActive
    filters[filterName].value =
      defaultFeedbackCampaignFilters[filterName] &&
      defaultFeedbackCampaignFilters[filterName].value
    this.setState({
      filters,
    })
  }

  handleResetFilters() {
    this.props.resetCampaignFilters()
    this.setState({
      filters: cloneDeep(defaultFeedbackCampaignFilters),
      rememberFilters: false,
    })
  }

  onDateSelect = (value, states) => {
    let filters = cloneDeep(this.state.filters)
    filters.date.value.dates.startValue = value.start._i
    filters.date.value.dates.startDate = formatDateInFilters(value.start._i)
    filters.date.value.dates.endValue = value.end._i
    filters.date.value.dates.endDate = formatDateInFilters(value.end._i)
    this.setState({
      filters,
      value,
      states,
      isDatePickerOpen: false,
    })
  }

  handleTimeChange = (filterName, filterProperty, value) => {
    let filters = cloneDeep(this.state.filters)
    switch (filterProperty) {
      case 'startTime':
      case 'endTime':
        filters[filterName].value.time[filterProperty].hrs = value
        break
      case 'startTimeState':
        filters[filterName].value.time.startTime.state = value
        break
      case 'endTimeState':
        filters[filterName].value.time.endTime.state = value
        break
      default:
        filters[filterName].value[filterProperty] = value
    }

    this.setState({ filters })
  }

  handleRememberFilters() {
    this.setState({ rememberFilters: !this.state.rememberFilters })
  }

  onSetFilters() {
    let filters = cloneDeep(this.state.filters)
    // TODO: Start Validation

    this.props.setFeedbackCampaignFilters(filters)
    this.props.setRememberCampaignFilters(this.state.rememberFilters)
    this.props.getFeedbackCampaigns()
    this.props.innerRef.current.closeModal()
  }

  render() {
    return (
      <CustomPopup
        className={'filter-modal'}
        headerComponent={true}
        heading={<FilterHeader heading={'Create campaign filters'} />}
        footer={
          <FilterFooter
            onCancel={() => this.props.innerRef.current.closeModal()}
            onSetFilters={this.onSetFilters}
            onResetFilters={this.handleResetFilters}
            onSetRememberFilters={this.handleRememberFilters}
            rememberFilters={this.state.rememberFilters}
          />
        }
        ref={this.props.innerRef}
      >
        <div className="filter-modal-body">
          <DateFilter
            title="Date"
            isActive={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.isActive
            }
            isDatePickerOpen={this.state.isDatePickerOpen}
            handleCheckboxClick={this.handleCheckboxClick}
            onDatePickerClick={this.handleDatePicker}
            startDate={this.state.startDate}
            dateValue={this.state.value}
            onDateSelect={this.onDateSelect}
            onTimeChange={this.handleTimeChange}
            startDate={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.dates.startDate
            }
            endDate={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.dates.endDate
            }
            startTimeHours={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.startTime.hrs
            }
            startTimeState={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.startTime.state
            }
            endTimeHours={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.endTime.hrs
            }
            endTimeState={
              this.state.filters &&
              this.state.filters.date &&
              this.state.filters.date.value &&
              this.state.filters.date.value.time.endTime.state
            }
            checkboxMargin="0px 10px 0px 0px"
            borderColor={dynamicBorderColor}
          />
        </div>
      </CustomPopup>
    )
  }
}

const mapStateToProps = ({
  feedbackCampaign: { filters, rememberFilters },
}) => ({
  filters,
  rememberFilters,
})

const ConnectedComponent = connect(mapStateToProps, {
  resetCampaignFilters,
  setFeedbackCampaignFilters,
  getFeedbackCampaigns,
  setRememberCampaignFilters,
})(CampaignFilter)

const RefCampaignFilter = React.forwardRef((props, ref) => (
  <ConnectedComponent innerRef={ref} {...props} />
))

export default RefCampaignFilter
