import React from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { useDebouncedEffect, useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'
import { Text, Input, Button, Tooltip, PopupContainer } from 'components/Common'
import { NamedCheckbox } from '../components/CustomCheckbox'
import { CarouselSort } from './CarouselSort'
import { UploadBox, getCouponPosition } from './MediaUpload'
import AsyncCouponSelect from './AsyncCouponSelect'

import {
  $YoutubeContainer,
  $AddNew,
  $Browse,
  $BlueBox,
  $CouponDetails,
} from '../AutoEngagement.styled'
import {
  COUPON_DISPLAY,
  POSITION_TYPES,
  GET_SEL_VALUE,
  COUPON_FONT_SIZES,
  COUPON_TYPEFACES,
} from '../constants'
import { getLabelFromValueInOptions } from 'utils/getLabelFromValue'
import { SelectSurvey } from './SelectSurvey'
import cloneDeep from 'lodash/cloneDeep'
import { spacing, borderRadius } from 'design-system'
import { CUSTOM_LINK_TYPES } from 'components/Content/storeManagement/constants'
import { validateIframe, validateIframeAttribute } from 'utils/iframeUtils'

const content = [
  'Steps on how to add a youtube embed url.',
  '1. Choose your youtube video on youtube.',
  '2. Click "Share" button shown at the bottom.',
  '3. Choose the embed url option from the list.',
  '4. Click on the "Copy" button at the bottom.',
  '5. Paste the content in the input box',
]

const DEFAULT_REDIRECT_COMPONENT_OPTIONS = [
  {
    value: 'redirect-link',
    label: 'Redirect URL',
  },
  {
    value: 'embed-survey',
    label: 'Embed BillMe Survey',
  },
  {
    value: 'embed-custom-link',
    label: 'Embed Custom Link',
  },
]

export const ColorBlockWithValue = ({ color }) => (
  <>
    <div
      style={{
        width: '50px',
        height: '15px',
        backgroundColor: color,
        display: 'inline-block',
        marginRight: spacing.m,
        borderRadius: borderRadius.small,
      }}
    />
    <span style={{ verticalAlign: 'top' }}>{color}</span>
  </>
)

/**
 *
 * @param {Number} assetLength - To check if asset is empty
 * @param {Boolean} isCarousel
 * @param {Number} index - Only used to define the labels
 */

const getRedirectComponentOptions = (
  assetLength,
  isCarousel,
  index,
  isSurveyFeatAllotted
) => {
  if (!assetLength) return []

  const arr = []
  const numIndex = Number(index)

  if (isCarousel) {
    arr.push(
      {
        value: `redirect-link-${numIndex + 1}`,
        label: `Redirect URL ${numIndex + 1}`,
      },
      {
        value: `embed-survey-${numIndex + 1}`,
        label: `Embed BillMe Survey ${numIndex + 1}`,
      },
      {
        value: `embed-custom-link-${numIndex + 1}`,
        label: `Embed Custom Link-${numIndex + 1}`,
      }
    )

    return arr
  }

  return isSurveyFeatAllotted
    ? DEFAULT_REDIRECT_COMPONENT_OPTIONS
    : [DEFAULT_REDIRECT_COMPONENT_OPTIONS[0]]
}

const YoutubeLabel = (
  <FlexBox align="flex-start">
    <Text variant="p" type="main">
      Youtube Video Code
    </Text>
    <Tooltip content={content.join(' ')} />
  </FlexBox>
)

export const YoutubeComponent = ({ value, setMedia }) => {
  return (
    <Div width="100%" maxWidth="500px" maxHeight="500px">
      <FlexBox expand height="300px" justify="center" align="center">
        {value ? (
          <$YoutubeContainer
            dangerouslySetInnerHTML={{
              __html:
                validateIframe(value) && validateIframeAttribute(value)
                  ? value
                  : '<span>Invalid link provided</span>',
            }}
          />
        ) : (
          <Div>
            {content?.map((con) => (
              <Text key={con} variant="p" type="main">
                {con}
              </Text>
            ))}
          </Div>
        )}
      </FlexBox>
      {setMedia && (
        <Input
          containerMargin="0"
          variant="input"
          label={YoutubeLabel}
          value={value}
          onChange={(e) => setMedia({ fileLocation: e.target.value })}
          placeholder="Add Embed youtube code here"
          inputWidth="500px"
        />
      )}
    </Div>
  )
}

export const ChooseSurvey = ({
  isPopOpen,
  setIsPopOpen,
  handleSubmitClick,
  handleSurveySelectClick,
  surveyId,
}) => {
  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Select Survey"
      onSubmitClick={handleSubmitClick}
      width="550px"
    >
      <SelectSurvey
        selectedSurveyId={surveyId}
        onSurveySelect={handleSurveySelectClick}
      />
    </PopupContainer>
  )
}

export const RedirectComponent = ({
  assetsDetails,
  handleRedirect,
  componentType,
  toast,
  body,
  setBody,
}) => {
  const redirectRadioOptions = (index, forseSet = false) =>
    getRedirectComponentOptions(
      forseSet || assetsDetails?.length,
      componentType === 'carousel',
      index,
      featuresAlloted.surveys
    )
  const [radioValue, setRadioValue] = React.useState({})
  const [isSurveyPopupOpen, setIsSurveyPopupOpen] = React.useState(false)
  const [surveyIndex, setSurveyIndex] = React.useState()

  const { data: surveyData } = useSelectStore('autoEngage.surveyData.docs')

  const setSelectedSurveyBodyOnId = (index, id) => {
    const surveyUrl = surveyData.filter((survey) => id === survey.surveyId)?.[0]
      ?.surveyUrl

    setBody({
      selectedSurveyIds: {
        ...body.selectedSurveyIds,
        [index]: {
          link: surveyUrl,
          id: id,
        },
      },
    })
  }
  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )

  const resetSurveyIdInBody = (id) => {
    if (id >= 0 && body?.selectedSurveyIds?.[id]) {
      const copySelectedSurvey = { ...body?.selectedSurveyIds }
      delete copySelectedSurvey?.[id]
      setBody({
        selectedSurveyIds: copySelectedSurvey,
      })
      return
    }

    if (body?.selectedSurveyIds && Object.keys(body?.selectedSurveyIds).length)
      setBody({
        selectedSurveyIds: {},
      })
  }

  React.useEffect(() => {
    if (componentType === 'carousel') {
      if (!assetsDetails?.length) {
        // Default set carousel radio values
        setRadioValue({
          0: redirectRadioOptions(0, true)?.[0]?.value,
          1: redirectRadioOptions(1, true)?.[0]?.value,
          2: redirectRadioOptions(2, true)?.[0]?.value,
          3: redirectRadioOptions(3, true)?.[0]?.value,
          4: redirectRadioOptions(4, true)?.[0]?.value,
        })
        resetSurveyIdInBody()
      }
    } else if (componentType !== 'carousel') {
      if (!assetsDetails?.length) {
        setRadioValue({
          0: redirectRadioOptions(0)?.[0]?.value,
        })
        resetSurveyIdInBody()
      }
    }
  }, [componentType, assetsDetails])

  React.useEffect(() => {
    if (componentType === 'carousel' && assetsDetails?.length) {
      // Check if redirect link or survey id present
      // If yes then set the radio button accordingly, or else default radio buttons
      let copyRadioValues = cloneDeep(radioValue)
      for (let index in assetsDetails) {
        if (assetsDetails[index].redirectUrl)
          copyRadioValues = {
            ...copyRadioValues,
            [index]: redirectRadioOptions(index)?.[0]?.value,
          }
        if (assetsDetails[index].surveyId) {
          copyRadioValues = {
            ...copyRadioValues,
            [index]: redirectRadioOptions(index)?.[1]?.value,
          }
          setSelectedSurveyBodyOnId(index, assetsDetails[index].surveyId)
        }

        if (assetsDetails[index].customLinkType) {
          copyRadioValues = {
            ...copyRadioValues,
            [index]: redirectRadioOptions(index)?.[2]?.value,
          }
        }
      }
      setRadioValue(copyRadioValues)
    } else if (componentType !== 'carousel' && assetsDetails?.length) {
      if (assetsDetails?.[0]?.redirectUrl)
        setRadioValue({
          0: redirectRadioOptions(0)?.[0]?.value,
        })
      if (assetsDetails?.[0]?.surveyId) {
        setRadioValue({
          0: redirectRadioOptions(0)?.[1]?.value,
        })
        setSelectedSurveyBodyOnId(0, assetsDetails?.[0]?.surveyId)
      }

      if (assetsDetails?.[0]?.customLinkType) {
        setRadioValue({
          0: redirectRadioOptions(0)?.[2]?.value,
        })
      }
    }
  }, [componentType])

  // componentWillUpdate
  // const prevRadioValuesRef = React.useRef()
  // React.useEffect(() => {
  //   prevRadioValuesRef.current = radioValue
  // })
  // const prevRadioValues = prevRadioValuesRef.current

  // useDebouncedEffect(() => {
  //   const removeLinkFromBody = (id) => {
  //     const copyAssetsDetails = cloneDeep(assetsDetails)
  //     if (copyAssetsDetails[id]?.redirectUrl)
  //       delete copyAssetsDetails[id]?.redirectUrl
  //     if (copyAssetsDetails[id]?.surveyId)
  //       delete copyAssetsDetails[id]?.surveyId
  //     // if (copyAssetsDetails[id]?.customLinkType)
  //     //   delete copyAssetsDetails[id]?.customLinkType

  //     setBody({ assetsDetails: copyAssetsDetails })
  //   }

  //   // Only change value after atleast one asset is added
  //   if (componentType === 'carousel' && assetsDetails?.length) {
  //     for (let i = 0; i < assetsDetails?.length; i++) {
  //       if (prevRadioValues?.[i] !== radioValue?.[i]) {
  //         removeLinkFromBody(i)

  //         /* FIXME:
  //             FIND OUT
  //             why when i move the resetSurveyIdInBody(i) function above the removeLinkFromBody(i) function,
  //             it remove the selectedsurveyid from body even though locally it changes it
  //         */
  //         resetSurveyIdInBody(i)
  //         break
  //       }
  //     }
  //   }

  //   // FIXME: Also need to delete values from asset details

  //   if (componentType !== 'carousel' && assetsDetails?.length) {
  //     if (prevRadioValues?.[0] && prevRadioValues?.[0] !== radioValue?.[0]) {

  //     }
  //   }
  // }, [radioValue])

  const testLink = (redirectUrl) => {
    if (!redirectUrl) {
      toast('Please add a redirect link and then test', 'error')
      return
    }
    if (!redirectUrl.includes('http')) {
      toast('Please add http / https', 'error')
      return
    }
    window.open(redirectUrl, '_blank')
  }

  const assetsDetailings = assetsDetails?.length > 0 ? assetsDetails : [{}]

  const handleRadioChange = (value, index) =>
    setRadioValue({
      ...radioValue,
      [index]: value,
    })

  const handleSurveyChooseButtonClick = (index) => {
    if (index >= 0) setSurveyIndex(index)

    setIsSurveyPopupOpen(!isSurveyPopupOpen)
  }

  const handleSurveySelectClick = (value) => {
    // surveyUrl
    handleRedirect(value?.surveyId, surveyIndex, true)

    // To maintain the local state for selected surveys
    setBody({
      selectedSurveyIds: {
        ...body.selectedSurveyIds,
        [surveyIndex]: {
          link: value.surveyUrl,
          id: value.surveyId,
        },
      },
    })
  }

  const subComponentArray = (asset, i) => {
    const arr = [
      <>
        <Input
          containerMargin="0"
          containerWidth="100%"
          variant="input"
          value={
            radioValue?.[i] === redirectRadioOptions(i)?.[0]?.value
              ? asset?.redirectUrl
              : ''
          }
          onChange={(e) => handleRedirect(e?.target?.value, i)}
          placeholder="https://"
          id={`redirectUrl${i}`}
          inputWidth="100%"
          disabled={
            assetsDetails?.length <= 0 ||
            radioValue?.[i] !== redirectRadioOptions(i)?.[0]?.value
          }
        />
        <$AddNew top="0px" left="0px">
          <Button
            noBorder
            variant="textualPrimary"
            onClick={() => testLink(asset?.redirectUrl)}
            disabled={
              assetsDetails?.length <= 0 ||
              radioValue?.[i] !== redirectRadioOptions(i)?.[0]?.value
            }
            style={{ whiteSpace: 'nowrap' }}
          >
            Test Link
          </Button>
        </$AddNew>
      </>,
    ]

    if (featuresAlloted.surveys) {
      arr.push(
        <>
          <Input
            containerWidth="100%"
            variant="input"
            value={
              radioValue[i] === redirectRadioOptions(i)?.[1]?.value
                ? body?.selectedSurveyIds?.[i]?.link
                : ''
            }
            readOnly
            placeholder="Selected Survey URL will come here"
            id={`surveyUrl${i}`}
            inputWidth="100%"
            disabled={
              assetsDetails?.length <= 0 ||
              radioValue[i] !== redirectRadioOptions(i)?.[1]?.value
            }
            containerMargin={`0px ${spacing.l} 0px 0px`}
          />
          <$Browse margin={0}>
            <button
              onClick={() => handleSurveyChooseButtonClick(i)}
              disabled={
                assetsDetails?.length <= 0 ||
                radioValue?.[i] !== redirectRadioOptions(i)?.[1]?.value
              }
            >
              Choose
            </button>
          </$Browse>
        </>,
        <>
          <Input
            containerWidth="100%"
            variant="single-select"
            value={
              radioValue[i] === redirectRadioOptions(i)?.[2]?.value
                ? GET_SEL_VALUE(asset?.customLinkType, CUSTOM_LINK_TYPES) || ''
                : ''
            }
            options={CUSTOM_LINK_TYPES}
            readOnly
            placeholder="Selected Custom Link"
            id={`customUrl${i}`}
            inputWidth="100%"
            disabled={
              assetsDetails?.length <= 0 ||
              radioValue[i] !== redirectRadioOptions(i)?.[2]?.value
            }
            containerMargin={`0px ${spacing.l} 0px 0px`}
            onChange={(e) => handleRedirect(e?.value, i, false, true)}
          />
        </>
      )
    }
    return arr
  }

  return (
    <>
      <ChooseSurvey
        isPopOpen={isSurveyPopupOpen}
        setIsPopOpen={setIsSurveyPopupOpen}
        handleSubmitClick={handleSurveyChooseButtonClick}
        handleSurveySelectClick={handleSurveySelectClick}
        surveyId={body?.selectedSurveyIds?.[surveyIndex]?.id}
      />
      {['image', 'gif', 'carousel'].includes(componentType) &&
        assetsDetails &&
        assetsDetails.length > 0 &&
        assetsDetails?.map((asset, i) => (
          <Div
            overflowY="auto"
            overflowX="hidden"
            width={assetsDetailings?.length > 1 ? '48%' : '100%'}
            key={asset?.fileLocation + i}
          >
            <Input
              variant="radio-text-column"
              options={redirectRadioOptions(i)}
              direction="column"
              onChange={(value) => handleRadioChange(value, i)}
              value={radioValue?.[i]}
              disabled={assetsDetails?.length <= 0}
              subComponent={subComponentArray(asset, i)}
            />
          </Div>
        ))}
    </>
  ) // : null
}

export const CarouselComponent = ({
  assetsDetails,
  setCarousel,
  uploadFile,
  acceptedFormats,
  couponPosition,
  couponId,
  couponFontColor,
  couponFontSize,
  couponFontTypeface,
}) => {
  const { data: coupons } = useSelectStore('autoEngage.couponData.docs')

  const theme = useTheme()

  const selectedCoupon =
    coupons && coupons.length && coupons.filter((item) => item._id === couponId)

  return (
    <Div my={theme.spacing.s}>
      <Text variant="small" type="grey" mb={theme.spacing.s}>
        Note: Max 5 images are allowed
      </Text>
      {assetsDetails?.length < 5 && (
        <Div my={theme.spacing.s}>
          <UploadBox
            isCarousel
            uploadWidth="100%"
            uploadHeight="150px"
            acceptedFormats={acceptedFormats}
            onChange={uploadFile}
            couponFontColor={couponFontColor}
            couponFontSize={couponFontSize}
            couponFontTypeface={couponFontTypeface}
          />
        </Div>
      )}
      <CarouselSort
        carousel={assetsDetails}
        setCarousel={setCarousel}
        acceptedFormats={acceptedFormats}
        couponId={couponId}
        couponPosition={couponPosition}
        couponPreview={selectedCoupon}
      />
    </Div>
  )
}

export const ChooseCoupon = ({
  spacing,
  couponId,
  setCouponProperty,
  assetsDetails,
  componentType,
  couponDisplay,
  couponPosition,
  couponFontSize,
  couponFontColor,
  couponFontTypeface,
  onCouponSelect,
  setCouponVisibility,
  onCouponDisplayChange,
}) => {
  const [isCouponPopupOpen, setIsCouponPopupOpen] = React.useState(false)

  const { colors } = useTheme()

  const { data: coupons } = useSelectStore('autoEngage.couponData.docs')
  const { featuresAlloted } = useSelector(
    (state) => state.vendorIds.selectedVendor
  )
  const selectedCouponName =
    coupons &&
    coupons.length &&
    coupons.filter((item) => item._id === couponId)?.[0]?.name

  const handleCouponPopupChange = () => setIsCouponPopupOpen(!isCouponPopupOpen)

  const couponStyle = getCouponPosition(couponPosition)

  const getCouponPrefix = () => {
    const filterWithCouponId =
      coupons && coupons.filter((item) => item._id === couponId)?.[0]?.prefix
    return filterWithCouponId
  }

  const getCouponDetails = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>Selected Coupon Name:</td>
            <td>{selectedCouponName || '-'}</td>
          </tr>
          <tr>
            <td>Selected Coupon Position:</td>
            <td>
              {getLabelFromValueInOptions(couponPosition, POSITION_TYPES)}
            </td>
          </tr>
          {componentType === 'carousel' && (
            <>
              <tr>
                <td>Coupon Code Display:</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Image Number:</td>
                <td>
                  {
                    <FlexBox mb={spacing.m} ml={spacing.l}>
                      {couponDisplay === 'chosen' &&
                        assetsDetails?.map((asset, i) => (
                          <NamedCheckbox
                            key={asset.key}
                            name={i + 1}
                            value={asset.key}
                            label={i + 1}
                            isChecked={asset.couponVisibility}
                            handleCheckboxChange={() => setCouponVisibility(i)}
                            margin="4px"
                          />
                        ))}
                    </FlexBox>
                  }
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>Coupon Font:</td>
            <td>
              {getLabelFromValueInOptions(couponFontTypeface, COUPON_TYPEFACES)}
            </td>
          </tr>
          <tr>
            <td>Coupon Font Colour:</td>
            <td>
              <ColorBlockWithValue color={couponFontColor} />
            </td>
          </tr>
          <tr>
            <td>Coupon Font Size:</td>
            <td>
              {getLabelFromValueInOptions(couponFontSize, COUPON_FONT_SIZES)}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return featuresAlloted.coupons ? (
    <>
      <PopupContainer
        isPopOpen={isCouponPopupOpen}
        setIsPopOpen={setIsCouponPopupOpen}
        heading="Add Coupon"
        onSubmitClick={handleCouponPopupChange}
        // width="550px"
        customContentStyle={{ overflow: 'visible' }}
      >
        <Div width="calc(100% - 120px)">
          <Input containerMargin="0" label="Select Coupon Code">
            <AsyncCouponSelect
              value={couponId}
              onCouponSelect={onCouponSelect}
            />
          </Input>
        </Div>
        {!!couponId && (
          <FlexBox>
            <Div flexBasis="50%">
              <Input
                containerMargin="0"
                variant="single-select"
                label="Select Coupon Overlay Position"
                value={GET_SEL_VALUE(couponPosition, POSITION_TYPES)}
                onChange={(value) => setCouponProperty(value, 'couponPosition')}
                placeholder="Select Coupon Position"
                options={POSITION_TYPES}
                inputWidth="calc(100% - 120px)"
              />
              {componentType === 'carousel' && (
                <FlexBox wrap="wrap" align="flex-end">
                  <Input
                    containerMargin="0"
                    variant="single-select"
                    label="Coupon Code Display"
                    value={GET_SEL_VALUE(couponDisplay, COUPON_DISPLAY)}
                    onChange={onCouponDisplayChange}
                    placeholder="All Images"
                    options={COUPON_DISPLAY}
                    inputWidth="300px"
                    menuZIndex={100}
                  />
                  <FlexBox mb={spacing.m} ml={spacing.l}>
                    {couponDisplay === 'chosen' &&
                      assetsDetails?.map((asset, i) => (
                        <NamedCheckbox
                          key={asset.key}
                          name={i + 1}
                          value={asset.key}
                          label={i + 1}
                          isChecked={asset.couponVisibility}
                          handleCheckboxChange={() => setCouponVisibility(i)}
                          margin="4px"
                        />
                      ))}
                  </FlexBox>
                </FlexBox>
              )}
              <Input
                containerMargin="0"
                variant="single-select"
                label="Coupon Font"
                value={GET_SEL_VALUE(couponFontTypeface, COUPON_TYPEFACES)}
                onChange={(value) =>
                  setCouponProperty(value, 'couponFontTypeface')
                }
                placeholder="Select Coupon Font"
                options={COUPON_TYPEFACES}
                inputWidth="calc(100% - 120px)"
              />
              <Input
                containerMargin="0"
                variant="single-select"
                label="Coupon Font Size"
                value={GET_SEL_VALUE(couponFontSize, COUPON_FONT_SIZES)}
                onChange={(value) => setCouponProperty(value, 'couponFontSize')}
                placeholder="Select Coupon Font Size"
                options={COUPON_FONT_SIZES}
                inputWidth="calc(100% - 120px)"
              />
              <Div width="calc(100% - 120px)">
                <Input
                  label="Coupon Font Colour"
                  variant="color"
                  inputWidth="100%"
                  placeholder="Select Coupon Font Colour"
                  value={couponFontColor || '#000'}
                  onChange={(color) =>
                    setCouponProperty(color, 'couponFontColor')
                  }
                />
              </Div>
            </Div>
            <Div flexBasis="50%" my={spacing.m}>
              <$BlueBox width="100%">
                <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
                  Preview Pane
                </Text>
                <FlexBox
                  justify="center"
                  align="center"
                  padding="32px"
                  height="300px"
                  style={{
                    border: '1px dashed #ddd',
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      color: couponFontColor,
                      fontSize: `${couponFontSize}px`,
                      fontFamily: couponFontTypeface,
                      ...couponStyle,
                    }}
                  >
                    {getCouponPrefix()} - Coupon Code
                  </div>
                </FlexBox>
              </$BlueBox>
            </Div>
          </FlexBox>
        )}
      </PopupContainer>
      <FlexBox justify={!!couponId ? 'space-between' : 'center'}>
        {!!couponId && (
          <$CouponDetails flexBasis="60%">{getCouponDetails()}</$CouponDetails>
        )}
        <Button
          variant="custom"
          onClick={handleCouponPopupChange}
          shadow
          color={colors.primary.default}
          width="200px"
          fontSize="16px"
        >
          {!!couponId ? 'Edit Coupon Details' : 'Add Coupon'}
        </Button>
      </FlexBox>
    </>
  ) : null
}

export const GeneralComponent = ({
  fileType,
  acceptedFormats,
  uploadFile,
  src,
  couponPosition,
  couponId,
  couponFontColor,
  couponFontSize,
  couponFontTypeface,
}) => {
  const { data: coupons } = useSelectStore('autoEngage.couponData.docs')

  const selectedCoupon =
    coupons && coupons.length && coupons.filter((item) => item._id === couponId)

  return (
    <Div>
      <FlexBox justify="center" width="100%" height="300px">
        <UploadBox
          hideBorder={src}
          uploadWidth="100%"
          fileType={fileType}
          acceptedFormats={acceptedFormats}
          onChange={uploadFile}
          src={src}
          couponId={couponId}
          couponPosition={couponPosition}
          couponPreview={selectedCoupon}
          couponFontColor={couponFontColor}
          couponFontSize={couponFontSize}
          couponFontTypeface={couponFontTypeface}
        />
      </FlexBox>
      <$Browse>
        <span>Add Image : </span>
        <div>
          <input onChange={uploadFile} accept={acceptedFormats} type="file" />
          <small>BROWSE</small>
        </div>
      </$Browse>
    </Div>
  )
}

export const AdditionalVideoOptions = ({
  componentType,
  onChange,
  form,
  setBody,
}) => {
  const isVideo = componentType === 'youtube' || componentType === 'video'

  React.useEffect(() => {
    if (isVideo) {
      setBody({
        autoplay: 'on',
        mute: 'on',
      })
    } else {
      if (form?.content?.body?.autoplay) delete form?.content?.body?.autoplay
      if (form?.content?.body?.mute) delete form?.content?.body?.mute
    }
  }, [componentType])

  if (isVideo)
    return (
      <FlexBox gap="128px">
        <Input
          label="Auto-Play"
          variant="radio"
          options={[
            {
              value: 'on',
              label: 'On',
            },
            {
              value: 'off',
              label: 'Off',
            },
          ]}
          gap="32px"
          value={form?.content?.body?.autoplay}
          onChange={(value) => onChange(value, 'autoplay')}
        />
        <Input
          label="Mute"
          variant="radio"
          options={[
            {
              value: 'on',
              label: 'On',
            },
            {
              value: 'off',
              label: 'Off',
            },
          ]}
          gap="32px"
          value={form?.content?.body?.mute}
          onChange={(value) => onChange(value, 'mute')}
        />
      </FlexBox>
    )

  return null
}
