import React from 'react'
import { debounce } from 'lodash'

import { useToasts } from 'components/Common/Toast'
import { post } from 'utils/axiosHandler'
import TableControls from './TableControls'
import { PAGINATION_LIMIT, SECTION_HEADINGS } from './constants'
import { useSelectStore } from 'hooks'
import { SectionHeading } from 'components/Common/SectionHeading'
import ComplaintsList from './ComplaintsList'
import { spacing } from 'design-system'
import { $Container } from '../UserComplaints.styled'

const ComplaintsListing = ({ storeIds, setStoreIds }) => {
  const [page, setPage] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [complaints, setComplaints] = React.useState(null)
  const [filter, setFilter] = React.useState({})
  const [search, setSearch] = React.useState('')

  const {
    data: { value: vendorId },
  } = useSelectStore('vendorIds.selectedVendor')

  const { toast } = useToasts()

  const onPageChange = ({ selected }) => {
    setPage(selected)
  }

  const getSearchQuery = () => {
    const searchQuery = {}

    if (!search) return searchQuery

    if (Number(search)) {
      searchQuery.phone = search
    } else {
      searchQuery.email = search
    }

    return searchQuery
  }

  const debouncedFetch = React.useCallback(
    debounce((query) => fetchData(query), 750),
    []
  )

  const fetchData = async (query) => {
    setLoading(true)
    try {
      const complaintsRes = await post('/bill-complaints', query)
      setComplaints(complaintsRes?.data)
    } catch (error) {
      toast(
        error?.response?.data?.message ||
          'Something went wrong, please try again.',
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchComplaints = React.useCallback(async () => {
    const query = {
      vendorId,
      limit: PAGINATION_LIMIT,
      skip: page * PAGINATION_LIMIT || 0,
      storeIds,
      ...filter,
      ...getSearchQuery(),
    }
    debouncedFetch(query)
  }, [page, filter, vendorId, search, storeIds])

  React.useEffect(() => {
    fetchComplaints()
  }, [fetchComplaints])

  return (
    <>
      <TableControls
        setFilter={setFilter}
        filter={filter}
        search={search}
        setSearch={setSearch}
        storeIds={storeIds}
        setStoreIds={setStoreIds}
      />
      <ComplaintsList
        loading={loading}
        complaints={complaints}
        onPageChange={onPageChange}
        pageLimit={PAGINATION_LIMIT}
        page={page}
      />
    </>
  )
}

export default ComplaintsListing
