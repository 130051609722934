import React, { useState } from 'react'
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'

import { $Block, $FlexBox } from 'builder/editor/Editor.styled'
import PropertyHeader from 'builder/editor/PropertyHeader'
import {
  PropertyBar,
  Padding,
  BorderRadius,
} from 'builder/editor/common/PropertyBar'
import { PopupContainer } from 'components/Common'
import { SelectCoupon } from 'components/Content/autoEngagement/components/SelectCoupon'
import { StyledInput, UploadButton } from 'builder/editor/common/Common.styled'

export const CouponSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)

  const onSelectCoupon = (coup) => {
    setProp((props) => {
      props.coupon = `$coupon[${coup._id}]`
      props.couponName = coup.name
    }, 300)
    setIsPopOpen(false)
  }

  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Coupon"
        onDelete={() => actions.delete(id)}
        hideDelete={id !== ROOT_NODE}
      />

      <PropertyBar
        label="Coupon Text"
        craftProps={props}
        setProp={setProp}
        propertyName="content"
        justify="space-between"
        margin="8px 0px"
      />

      <$FlexBox padding="0 0 4px" justify="space-between">
        <span>Select Coupon</span>
        <div>
          <UploadButton onClick={() => setIsPopOpen(true)}>Coupon</UploadButton>
        </div>
      </$FlexBox>
      <StyledInput
        readOnly
        style={{ width: '100%', background: '#D5D5D5' }}
        value={props.couponName}
      />
      <PropertyBar
        label="Width"
        propertyName="width"
        type="number"
        isFlex
        isSplit
        {...commonProps}
      />

      <PropertyBar
        label="Coupon Background Colour"
        type="color"
        propertyName="backgroundColor"
        isFlex
        justify="space-between"
        {...commonProps}
      />

      <PropertyBar
        label="Coupon Colour"
        type="color"
        propertyName="color"
        isFlex
        justify="space-between"
        {...commonProps}
      />

      {BorderRadius(props, setProp)}

      {Padding(props, setProp)}

      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Select Coupon"
        onSubmitClick={() => setIsPopOpen(false)}
      >
        <SelectCoupon onCouponSelect={onSelectCoupon} />
      </PopupContainer>
    </$Block>
  )
}
