import React, { Component } from 'react'
import CheckMinimalIcon from 'images/check-minimal-green.svg'
import { Card } from 'styledComponent/components/card'
import BillViewBlur from 'images/bill-app-blurred.png'
import { Text, GreyText } from 'styledComponent/components/typography'
import { FlexBox, Box } from 'styledComponent/components/elements'
import { Textarea, CheckboxInput } from 'styledComponent/components/input'
import { isArrayElementsEmpty } from 'utils'
import Icons from './FeedbackIcons'
import { FeedbackText } from 'builder/editor/common/Common.styled'

const FeedbackView = ({
  card,
  filledRating,
  fbHeading,
  rating,
  selectedValues,
  handleRatingClick,
  handleFeedbackClick,
}) => {
  const getFeedbackText = (rating, caption) => {
    if (!rating) return null

    if (!caption)
      return (
        <em>
          <GreyText
            variant="body2"
            as="li"
            fontSize="small"
            text-align="center"
            style={{ listStyle: 'none' }}
          >
            No caption given
          </GreyText>
        </em>
      )

    return (
      <Text
        variant="body1"
        as="h5"
        text-align="center"
        style={{ marginTop: '0.8em' }}
      >
        {caption}
      </Text>
    )
  }

  const getFeedbackList = (values) => {
    console.log('values', values, 'card', card)
    if (isArrayElementsEmpty(values))
      return (
        <em>
          <GreyText
            variant="body2"
            as="li"
            fontSize="small"
            text-align="center"
          >
            No options given for this rating
          </GreyText>
        </em>
      )

    return values.map((value, i) => {
      if (!value) return null

      const isValueSelected = selectedValues.includes(`${value}${i}`)

      return (
        <Card
          as="li"
          justify="space-between"
          align="center"
          fontSize="12px"
          mt="5px"
          selected={isValueSelected}
        >
          {value}
          <CheckboxInput
            type="checkbox"
            onChange={(e) => handleFeedbackClick(e, i)}
            checked={isValueSelected}
            value={value}
            transparent
            position="absolute"
            style={{ zIndex: '10' }}
          />
          <Box
            size="small"
            border={`1px solid ${
              isValueSelected ? 'hsla(0, 0%, 85%, 0)' : 'hsl(0, 0%, 90%)'
            }`}
            borderRadius="2px"
            position="relative"
            style={{ zIndex: '1' }}
          />
          {isValueSelected && (
            <Box
              as="img"
              size="small"
              src={CheckMinimalIcon}
              alt=""
              position="absolute"
              right="10px"
              style={{ zIndex: '1' }}
            />
          )}
        </Card>
      )
    })
  }
  return (
    <Box padding="10px 15px" textAlign="center">
      <Icons
        starRating={rating}
        scaleType={card ? card[0].scaleType : 'star'}
        filledRating={filledRating}
        fbHeading={fbHeading}
        scaleLength={card ? card.length : 5}
        handleRatingClick={handleRatingClick}
        showAll
      />
      {/* <Rating
        initialRating={rating}
        emptySymbol={<img src={StarEmpty} alt="Star rating icon" />}
        fullSymbol={<img src={StarFilled} alt="Star rating icon" />}
        onClick={handleRatingClick}
      /> */}

      {rating !== 0 ? getFeedbackText(rating, card[rating - 1].caption) : null}
      {rating !== 0 ? (
        <ul
          style={{
            margin: '20px 0px',
            padding: '0px',
            minHeight: '140px',
            listStyleType: 'none',
          }}
        >
          {getFeedbackList(card[rating - 1].values)}
        </ul>
      ) : null}

      <Textarea type="light" placeholder="Got any suggestions?" row="3" />
    </Box>
  )
}

class PreviewModal extends Component {
  constructor() {
    super()

    this.state = {
      // TODO: Check why does it give error when set to 1
      rating: 0,
      selectedFeedbackValues: [],
    }

    this.handleRatingClick = this.handleRatingClick.bind(this)
    this.handleFeedbackClick = this.handleFeedbackClick.bind(this)
  }

  componentDidMount() {
    this.setState({ rating: this.props.card?.length })
  }

  handleRatingClick(rating) {
    this.setState({
      rating,
      selectedFeedbackValues: [],
    })
  }

  handleFeedbackClick({ target: { value } }, i) {
    const uniqueValue = `${value}${i}`
    const selectedValuesCopy = [...this.state.selectedFeedbackValues]

    if (!selectedValuesCopy.includes(uniqueValue)) {
      selectedValuesCopy.push(uniqueValue)
    } else {
      const indexOf = selectedValuesCopy.indexOf(uniqueValue)
      selectedValuesCopy.splice(indexOf, 1)
    }
    this.setState({ selectedFeedbackValues: selectedValuesCopy })
  }

  render() {
    return (
      <FlexBox direction="column" align="center" padding="4em 6em">
        <Text variant="h4" as="h4" text-align="center">
          Custom feedback view
        </Text>
        <FlexBox
          justify="center"
          align="center"
          style={{
            width: '300px',
            height: '500px',
            border: '4px solid black',
            borderRadius: '20px',
            background: `url(${BillViewBlur})`,
            backgroundSize: '102% 102%',
            backgroundPositionX: '10%',
            backgroundPositionY: '10%',
            backgroundPosition: 'cover',
            backgroundOrigin: 'border-box',
          }}
        >
          <section
            style={{
              width: '80%',
              borderRadius: '6px',
              backgroundColor: '#fff',
            }}
          >
            {this.props?.fbHeading && (
              <FeedbackText>{this.props?.fbHeading}</FeedbackText>
            )}
            <FeedbackView
              card={this.props.card}
              filledRating={this.props.filledRating}
              fbHeading={this.props.fbHeading}
              rating={this.state.rating}
              handleRatingClick={this.handleRatingClick}
              handleFeedbackClick={this.handleFeedbackClick}
              selectedValues={this.state.selectedFeedbackValues}
            />
            <button
              style={{
                width: '100%',
                backgroundColor: '#50bd3d',
                borderRadius: '0px 0px 6px 6px',
                color: '#fff',
                padding: '5px 0px',
              }}
            >
              Send
            </button>
          </section>
        </FlexBox>
      </FlexBox>
    )
  }
}

export default PreviewModal
