import styled from 'styled-components'

import { borderRadius, spacing } from 'design-system'

const $EditIcon = styled.div`
  padding: ${({ padding }) => padding || spacing.s};
  border: ${({ noBorder, theme }) =>
    !noBorder ? `1px solid ${theme.colors.primary.default}` : 'unset'};
  border-radius: ${borderRadius.small};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const $CategoryList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  /* scrollbars*/
  /* width */
  &::-webkit-scrollbar {
    box-shadow: inset 0 0 1px grey;
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
  }
`

const $CategoryListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.l} ${spacing.m};
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  background-color: ${({ active, theme }) =>
    active ? '#EBF3FE' : theme.colors.white};
  div {
    margin: 0;
  }
  &:hover {
    ${({ hover }) => (hover ? `background-color: #EBF3FE` : '')};
  }
`

export { $EditIcon, $CategoryList, $CategoryListItem }
