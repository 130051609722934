import React from 'react'

export const BORDER_STYLE_OPTIONS = [
  {
    label: '....DOTTED....',
    value: 'dotted',
  },
  {
    label: '----DASHED----',
    value: 'dashed',
  },
  {
    label: '____SOLID____',
    value: 'solid',
  },
]

export const FONT_WEIGHT_OPTIONS = [
  {
    label: <span style={{ fontWeight: 'normal' }}>Normal</span>,
    value: 'normal',
  },
  {
    label: <span style={{ fontWeight: 'bold' }}>Bold</span>,
    value: 'bold',
  },
  {
    label: <span style={{ fontWeight: 'bolder' }}>Bolder</span>,
    value: 'bolder',
  },
  {
    label: <span style={{ fontWeight: 'lighter' }}>Lighter</span>,
    value: 'lighter',
  },
]

export const FONT_FAMILY_OPTIONS = [
  {
    label: (
      <span style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        Arial / Helvetica
      </span>
    ),
    value: 'Arial, Helvetica, sans-serif',
  },
  {
    label: (
      <span style={{ fontFamily: 'Times New Roman, Times, serif' }}>
        Times New Roman
      </span>
    ),
    value: 'Times New Roman, Times, serif',
  },
  {
    label: (
      <span style={{ fontFamily: 'Courier New, Courier, monospace' }}>
        Courier New
      </span>
    ),
    value: 'Courier New, Courier, monospace',
  },
]

export { GET_SEL_VALUE } from 'utils/reusables'
