import React, { Component } from 'react'
import 'css/content/legal.scss'
import PrivacyContent from './PrivacyContent'
import RefundContent from './RefundContent'
import VendorAgreement from './VendorAgreement'

class Legal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 'privacy',
    }
    this.onClick = this.onClick.bind(this)
  }
  onClick(data) {
    this.setState({ selected: data })
  }

  render() {
    return (
      <div className="legal">
        <div className="buttonGroup">
          <button
            className={
              this.state.selected === 'privacy'
                ? 'buttonLegal buttonLegalGreen'
                : 'buttonLegal'
            }
            onClick={() => {
              this.onClick('privacy')
            }}
          >
            Privacy Policy
          </button>
          <button
            className={
              this.state.selected === 'refund'
                ? 'buttonLegal buttonLegalGreen'
                : 'buttonLegal'
            }
            onClick={() => {
              this.onClick('refund')
            }}
          >
            Refund & Cancellation
          </button>
          <button
            className={
              this.state.selected === 'vendor'
                ? 'buttonLegal buttonLegalGreen'
                : 'buttonLegal'
            }
            onClick={() => {
              this.onClick('vendor')
            }}
          >
            Vendor Agreement
          </button>
        </div>
        <br />
        <div className="contentLegal shadow p-3 mb-5 bg-white rounded">
          {this.state.selected === 'privacy' ? <PrivacyContent /> : null}
          {this.state.selected === 'refund' ? <RefundContent /> : null}
          {this.state.selected === 'vendor' ? <VendorAgreement /> : null}
        </div>
      </div>
    )
  }
}

export default Legal
