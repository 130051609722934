import React from 'react'

import { useRouter, useSelectStore } from 'hooks'
import { TopBarAndStepsWrapper } from '../../../components'
import { TEMPLATE_STEPS, URL_REGEXP } from './constants'
import TemplateCampaign from './TemplateCampaign'
import { get, patch, post } from 'utils/axiosHandler'
import { useToasts } from 'components/Common'
import { ERROR_DECODE } from 'utils/reusables'

const Templates = () => {
  const [currentStep, setCurrentStep] = React.useState({
    id: 1,
    label: 'Template Details',
    status: 'active',
  })

  const [templateForm, setTemplateForm] = React.useState({})

  const [waAccounts, setWAAccounts] = React.useState([])

  const {
    data: { userId: createdBy },
  } = useSelectStore('auth.user')

  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')
  const { toast } = useToasts()
  const { params, history, query } = useRouter()

  const [hideSteps, setHideSteps] = React.useState(query?.ops === 'view')
  const [isPublishing, setIsPublishing] = React.useState(false)

  const onNextClick = async () => {
    try {
      _checkForValidations()

      const id = params.id !== 'create' ? params.id : templateForm._id
      if (id) {
        const res = await patch(
          `/auto-engage/whatsapp-services/${vendorId}/templates/${id}`,
          _populateReqBody(templateForm, currentStep)
        )
        setTemplateForm(res.data)
      } else {
        const res = await post(
          `/auto-engage/whatsapp-services/${vendorId}/templates`,
          templateForm
        )
        setTemplateForm(res.data)
      }
    } catch (error) {
      throw ERROR_DECODE(error)
    }
  }

  const fetchCampaignById = async () => {
    try {
      if ((!params.id || params.id === 'create') && !templateForm._id)
        return null
      const id = templateForm._id || params.id
      const res = await post(
        `/auto-engage/whatsapp-services/${vendorId}/templates/${id}`
      )
      setTemplateForm(res.data)

      if (res.data.status !== 'draft') {
        setHideSteps(true)
      }
    } catch (error) {
      toast(ERROR_DECODE(error), 'error')
    }
  }

  const _checkForValidations = () => {
    switch (currentStep?.id) {
      case 1:
        if (
          !templateForm?.name ||
          !templateForm?.category ||
          !templateForm.settingAccountId
        ) {
          throw 'name, category and WhatsApp account are required for template creation'
        }
        break
      case 2:
        const errorInContent = _validateTemplateContent(templateForm)

        if (errorInContent) {
          throw errorInContent
        }

        const errorInButtons = _validateButtonContent(templateForm)

        if (errorInButtons) {
          throw errorInButtons
        }

        break
      default:
        break
    }
  }

  const onSave = async () => {
    try {
      const id = params?.id !== 'create' ? params?.id : templateForm._id

      if (id && !hideSteps) {
        await patch(
          `/auto-engage/whatsapp-services/${vendorId}/templates/${id}`,
          _populateReqBody({ ...templateForm, status: 'draft' }, currentStep)
        )
      }

      history.goBack()
    } catch (error) {
      toast(ERROR_DECODE(error), 'error')
    }
  }

  const onPublish = async () => {
    setIsPublishing(true)
    try {
      const id = templateForm._id || params.id
      toast('Please wait while we publish your template.', 'info')

      await patch(
        `/auto-engage/whatsapp-services/${vendorId}/templates/${id}`,
        _populateReqBody({ ...templateForm, status: 'pending' }, currentStep)
      )

      setIsPublishing(false)
      history.goBack()
    } catch (error) {
      setIsPublishing(false)
      toast(ERROR_DECODE(error), 'error')
    }
  }

  const fetchSettings = React.useCallback(async () => {
    const { data } = await get(`/settings/vendor/${vendorId}?type=whatsApp`)
    let modifiedData = data?.settings

    if (data?.settings?.length) {
      modifiedData = data?.settings.map((account) => {
        const returnObj = account.reduce((acc, curr) => {
          if (curr?.key?.id === 'entity') {
            acc.label = curr?.key?.value
          }

          if (curr?.key?.id === 'accountId') {
            acc.value = curr?.key?.value
          }

          return acc
        }, {})

        return returnObj
      })
    }

    setWAAccounts(modifiedData || [])
  }, [])

  React.useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  React.useEffect(() => {
    if (params?.id !== 'create') {
      fetchCampaignById()
    } else {
      setTemplateForm({
        vendorId,
        createdBy,
        status: 'draft',
      })
    }
  }, [params?.id])

  const _populateReqBody = (body, step) => {
    let modifiedBody = body
    switch (step?.id) {
      case 1:
        modifiedBody = _getValidKeys(
          [
            'name',
            'description',
            'category',
            'type',
            'vendorId',
            'createdBy',
            'status',
            'settingAccountId',
          ],
          body
        )
        return modifiedBody
      case 2:
        modifiedBody = _getValidKeys(
          [
            'type',
            'vendorId',
            'status',
            'mediaType',
            'language',
            'content',
            'buttonType',
            'buttons',
            'settingAccountId',
          ],
          body
        )
        return modifiedBody
      case 3:
        modifiedBody = _getValidKeys(
          ['type', 'vendorId', 'status', 'settingAccountId'],
          body
        )
        return modifiedBody
      default:
        return modifiedBody
    }
  }

  return (
    <TopBarAndStepsWrapper
      heading="WhatsApp - Template"
      onNextClick={onNextClick}
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
      labels={TEMPLATE_STEPS}
      onPreviousClick={fetchCampaignById}
      onSave={onSave}
      onPublish={onPublish}
      hideSteps={hideSteps}
      form={templateForm}
      campaignType="WhatsApp"
      whatsAppTemplate={true}
      customValidations={_checkForValidations}
      disableButtonOnPublish={isPublishing}
    >
      <TemplateCampaign
        form={templateForm}
        setForm={setTemplateForm}
        active={currentStep.label}
        hideSteps={hideSteps}
        waAccounts={waAccounts}
      />
    </TopBarAndStepsWrapper>
  )
}

export default Templates

/**
 * helper functions
 */

const _getValidKeys = (keys, data) => {
  const filteredObject = {}

  for (const key of keys) {
    if (
      (data[key] instanceof Array && data[key].length) ||
      (!(data[key] instanceof Array) && data[key]) ||
      typeof data[key] === 'boolean'
    ) {
      if (key === 'buttons') {
        filteredObject[key] = data[key].map((ele) => {
          const obj = { ...ele }
          if (ele.type === 'phone-number') {
            ;['url', 'urlType'].forEach((field) => delete obj[field])
          } else {
            ;['phoneNumber'].forEach((field) => delete obj[field])
          }
          return obj
        })
      } else {
        filteredObject[key] = data[key]
      }
    }
  }

  return filteredObject
}

const _validateTemplateContent = (data) => {
  const { content, type, mediaType } = data

  if (!content) return 'invalid content'

  const { body } = content

  if (!body) {
    return 'template body is required'
  }

  if (type === 'media' && !mediaType) {
    return 'media type is required'
  }

  return null
}

const _validateButtonContent = (data) => {
  const { buttons, buttonType } = data

  switch (buttonType) {
    case 'cta':
      const fieldsAbsent = buttons.some((ele) => !ele.type || !ele.text)
      if (fieldsAbsent) {
        return 'button type and text is mandatory for CTA type'
      }

      const invalidUrlPayloadCheck = buttons.some(
        (ele) =>
          ele.type === 'website' && (!ele.urlType || !URL_REGEXP.test(ele.url))
      )

      if (invalidUrlPayloadCheck) {
        return 'url type and proper url is mandatory for CTA type website'
      }

      const invalidPhoneNumberCheck = buttons.some(
        (ele) => ele.type === 'phone-number' && !ele.phoneNumber
      )
      if (invalidPhoneNumberCheck) {
        return 'phone number is mandatory for CTA type phone-number'
      }

    case 'quick-reply':
      const textAbsent = buttons.some((ele) => !ele.text)
      if (textAbsent) {
        return 'button text is mandatory for quick-reply type'
      }
  }

  return null
}
