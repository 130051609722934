import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import 'css/content/profile.scss'
import Popups from './Popups'
import CompanyCard from './CompanyCard'
import BrandCard from './BrandCard'
import StoreCard from './StoreCard'
import { getCompanies } from 'redux/actions/payments'
import {
  filterStores,
  filterBrand,
  filterCompanies,
} from 'redux/actions/authActions'
import Navbar from 'components/Layout/Navbar'
import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { Breadcrumb } from 'components/Common'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storePopupOpen: false,
      companyPopupOpen: false,
      companyAddPopupOpen: false,
      showAddButton: false,
      storeAddPopupOpen: false,
      stores: [],
      hasMoreStores: true,
    }
    this.openStorePopup = this.openStorePopup.bind(this)
    this.openCompanyPopup = this.openCompanyPopup.bind(this)
    this.openCompanyAddPopup = this.openCompanyAddPopup.bind(this)
    this.openStoreAddPopup = this.openStoreAddPopup.bind(this)
    this.addButtonsShow = this.addButtonsShow.bind(this)
  }

  async componentDidMount() {
    this.props.getCompanies()
    this.handleStoreListScroll()
  }

  openStorePopup(id) {
    this.setState({
      storePopupId: id,
      storePopupOpen: !this.state.storePopupOpen,
    })
  }
  openStoreAddPopup() {
    this.setState({ storeAddPopupOpen: !this.state.storeAddPopupOpen })
  }
  openCompanyPopup(id) {
    this.setState({
      companyPopupId: id,
      companyPopupOpen: !this.state.companyPopupOpen,
    })
  }
  openCompanyAddPopup() {
    this.setState({ companyAddPopupOpen: !this.state.companyAddPopupOpen })
  }
  addButtonsShow() {
    this.setState({ showAddButton: !this.state.showAddButton })
  }

  handleStoreListScroll = () => {
    let storesArr = Object.values(this.props.stores)
    let stores = this.state.stores
    let hasMoreStores = true
    const limit = stores.length + 5
    for (let i = stores.length; i < limit; i++)
      if (storesArr[i]) stores.push(storesArr[i])
    if (storesArr.length === stores.length) hasMoreStores = false
    this.setState({
      stores: stores,
      hasMoreStores,
    })
  }

  render() {
    return (
      <>
        <Navbar heading="Profile" />
        <Div backgroundColor="#FFF">
          <FlexBox p={spacing.l} justify="space-between">
            <Breadcrumb label="Business Profile" />
          </FlexBox>
        </Div>
        <div className="profileWrapper">
          <div className="gridBoxProfile">
            <div className="profileWrapperInner">
              <div className="profileTypeHeading">Company</div>

              {this.props.companies.map((company, i) => {
                return (
                  <CompanyCard
                    companyName={company.companyName}
                    companyEmail={company.emailId}
                    companyBalance={company.balance.toFixed(2)}
                    companyContact={company.mobileNumber}
                    companyId={company.companyId}
                    number={i}
                    key={company.companyName}
                  />
                )
              })}
            </div>
            <div className="profileWrapperInner">
              <div className="profileTypeHeading">Brand</div>
              {this.props.brands.map((brand) => {
                return (
                  <BrandCard
                    brandLogoImage={brand.brandLogo}
                    brandName={brand.name}
                    brandCategory={brand.categoryName}
                    key={brand.name}
                  />
                )
              })}
            </div>
            <div id="storeList" className="profileWrapperInner">
              <div className="profileTypeHeading">Store</div>
              <InfiniteScroll
                dataLength={this.state.stores.length} //This is important field to render the next data
                next={this.handleStoreListScroll}
                hasMore={this.state.hasMoreStores}
                loader={
                  <div>
                    <span>Loading...</span>
                  </div>
                }
                scrollableTarget="storeList"
              >
                {this.state.stores.map((store, i) => {
                  let brand = this.props.brands.find(
                    (brand) => store.brandId === brand.brandId
                  )
                  let company = this.props.companies.find(
                    (company) => company.companyId === company.companyId
                  )
                  return (
                    <StoreCard
                      storeName={brand.name}
                      storeCompany={company.companyName}
                      storeAddress={store.displayAddress}
                      key={store.storeId}
                      number={i}
                    />
                  )
                })}
              </InfiniteScroll>
            </div>
          </div>
          {this.state.showAddButton ? (
            <div>
              <button
                onClick={this.openStoreAddPopup}
                className="rounded addOptionsStore"
              >
                Add Store
              </button>
              <button
                onClick={this.openCompanyAddPopup}
                className="rounded addOptionsCompany"
              >
                Add Company
              </button>
              <img
                className="addOptionsBackground animated lightSpeedIn"
                src={require('./images/Ellipse 60.png')}
                alt=""
              />
            </div>
          ) : null}

          {/* <div className="addButton" onClick={this.addButtonsShow}>
            {!this.state.showAddButton ? (
              <img
                className="animated rotateIn"
                src={require("./images/btn_add@2x.png")}
              />
            ) : (
              <img
                className="animated rotateInUpLeft"
                src={require("./images/btn_close_white@2x.png")}
              />
            )}
          </div> */}

          {this.state.storePopupOpen ? (
            <Popups
              openPopup={true}
              popupType="store"
              onClick={this.openStorePopup}
              storePopupId={this.state.storePopupId}
            />
          ) : null}
          {this.state.storeAddPopupOpen ? (
            <Popups
              openPopup={true}
              popupType="storeAdd"
              onClick={this.openStoreAddPopup}
            />
          ) : null}

          {this.state.companyPopupOpen ? (
            <Popups
              openPopup={true}
              onClick={this.openCompanyPopup}
              popupType="company"
              companyPopupId={this.state.companyPopupId}
            />
          ) : null}

          {this.state.companyAddPopupOpen ? (
            <Popups
              openPopup={true}
              onClick={this.openCompanyAddPopup}
              popupType="companyAdd"
            />
          ) : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  stores: filterStores(state, 'profile'),
  brands: filterBrand(state, 'profile'),
  companies: filterCompanies(state, 'profile'),
})
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCompanies,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
