import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { cloneDeep } from 'lodash'

import { useSelectStore } from 'hooks'
import { Block, FlexBox } from 'styledComponent'
import { Button, Checkbox, Text } from 'components/Common'
import { spacing } from 'design-system'
// import { getActiveFilter } from 'utils/filter'

const $BrandCheckbox = styled(Checkbox)`
  label {
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      min-width: 35px;
      min-height: 35px;
      border-color: ${({ theme }) => theme.colors.darkMercury};
    }
  }
`

export const $SelectContainer = styled(Block)`
  padding: ${(props) => props.padding};
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
      overflow-y: auto;
    `};
`

const $SearchInput = styled.input`
  padding: ${spacing.s} ${spacing.ml};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  min-width: 70%;
`

export const SelectStores = ({
  brands,
  vendorId,
  stores,
  setStores,
  isAudience,
}) => {
  const [search, setSearch] = useState('')

  let { data: totalStores } = useSelectStore('stores.stores')
  const { data: allCompanies } = useSelectStore('company.companies')
  const { data: audienceStoreFilter } = useSelectStore(
    'autoEngage.newAudienceStoreFilter'
  )

  const getStoreFromActiveFilter = () => {
    let filteredStores = {}

    if (
      audienceStoreFilter?.geography &&
      audienceStoreFilter?.geography.length
    ) {
      for (let storeId in totalStores) {
        const state = totalStores[storeId].state

        let isValid = true
        for (let filterName in audienceStoreFilter) {
          if (
            filterName === 'geography' &&
            !audienceStoreFilter[filterName]
              ?.map((location) => location.value)
              ?.includes(state)
          ) {
            isValid = false
            break
          }
        }
        if (isValid) filteredStores[storeId] = cloneDeep(totalStores[storeId])
      }
      return filteredStores
    }
    return totalStores
  }

  const allStores = isAudience ? getStoreFromActiveFilter() : totalStores

  const storeIds = stores?.map((str) => str.storeId) || []

  const availableStores = Object.values(allStores)?.filter(
    (store) =>
      store.displayAddress?.toLowerCase().includes(search?.toLowerCase()) &&
      store.vendorId === vendorId &&
      brands?.map((b) => b.brandId)?.includes(store.brandId)
  )
  const groupedStores = allCompanies
    .map((comp) => {
      comp.stores =
        availableStores.filter((str) => str.companyId === comp.companyId) || []
      return comp
    })
    .filter((cmp) => cmp.stores.length > 0)
  const otherStores = availableStores.filter(
    (str) => !allCompanies.map((cmp) => cmp.companyId).includes(str.companyId)
  )

  const handleCheckbox = (type, val) => {
    if (type === 'single') {
      if (storeIds.includes(val.storeId)) {
        const str = stores.filter((st) => st.storeId !== val.storeId)
        setStores(str)
      } else {
        setStores([...stores, val])
      }
    } else {
      const str = stores.filter(
        (st) => !val.map((str) => str.storeId).includes(st.storeId)
      )
      if (val.every((os) => storeIds.includes(os.storeId))) {
        setStores(str)
      } else {
        setStores([...str, ...val])
      }
    }
  }

  return (
    <$SelectContainer padding="20px">
      <FlexBox gap="20px" align="flex-end">
        <$SearchInput
          placeholder="Search by Store Address"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <$SelectContainer height="25px">
          <$BrandCheckbox
            id="all"
            value="all"
            onCheckboxChange={() => handleCheckbox('multiple', availableStores)}
            isChecked={
              availableStores.length &&
              stores.length &&
              availableStores.every((avs) => storeIds.includes(avs.storeId))
            }
          >
            Select All
          </$BrandCheckbox>
        </$SelectContainer>
        <Button variant="primaryOutline" onClick={() => setStores([])}>
          Reset Selection
        </Button>
      </FlexBox>
      <hr />
      <$SelectContainer padding="20px" maxHeight="50vh">
        <FlexBox wrap="wrap" gap="60px">
          {groupedStores.map((comp) => (
            <div key={comp.companyId}>
              <$BrandCheckbox
                id={comp.companyId}
                value={comp.companyId}
                onCheckboxChange={() => handleCheckbox('multiple', comp.stores)}
                isChecked={comp.stores.every((os) =>
                  storeIds?.includes(os.storeId)
                )}
              >
                <Text variant="p" type="main" weight="bold">
                  {comp.companyName}
                </Text>
              </$BrandCheckbox>
              <hr />
              {comp.stores.map((str) => (
                <$SelectContainer
                  padding="0 0 0 10px"
                  height="25px"
                  key={str.storeId}
                >
                  <$BrandCheckbox
                    id={str.storeId}
                    value={str.storeId}
                    onCheckboxChange={() => handleCheckbox('single', str)}
                    isChecked={storeIds?.includes(str.storeId)}
                  >
                    {str.displayAddress}
                  </$BrandCheckbox>
                </$SelectContainer>
              ))}
            </div>
          ))}
          <div>
            {otherStores.length > 0 && (
              <>
                <$BrandCheckbox
                  id="otherCompanies"
                  value="otherCompanies"
                  onCheckboxChange={() =>
                    handleCheckbox('multiple', otherStores)
                  }
                  isChecked={otherStores.every((os) =>
                    storeIds.includes(os.storeId)
                  )}
                >
                  Other Companies
                </$BrandCheckbox>
                <hr />
              </>
            )}
            {otherStores.map((str) => (
              <$SelectContainer
                padding="0 0 0 10px"
                height="25px"
                key={str.storeId}
              >
                <$BrandCheckbox
                  id={str.storeId}
                  value={str.storeId}
                  onCheckboxChange={() => handleCheckbox('single', str)}
                  isChecked={storeIds?.includes(str.storeId)}
                >
                  {str.displayAddress}
                </$BrandCheckbox>
              </$SelectContainer>
            ))}
          </div>
        </FlexBox>
      </$SelectContainer>
    </$SelectContainer>
  )
}
