import styled, { css } from 'styled-components'
import { borderRadius, spacing } from 'design-system'
import SearchIcon from 'images/search.svg'

const $FlexList = styled.ul`
  margin: 0;
  padding: 10px 0;
  list-style: none;
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'unset')};
`

const $FlexListItem = styled.li`
  margin-left: ${({ ml }) => ml || '5px'};
  margin-bottom: ${({ mb }) => mb || 'unset'};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
  padding: ${({ padding }) => padding || 'unset'};
  flex-basis: ${({ flexBasis }) => flexBasis || 'unset'};
  border-right: ${({ borderRight }) => borderRight || 'unset'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'unset'};
`

const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${borderRadius.small};
  margin-right: ${spacing.ml};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) =>
      props.isFilter
        ? props.theme.colors.primary.default
        : props.disabled
        ? props.theme.colors.alto
        : props.theme.colors.success.bright};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
`

const $FilterMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  flex-basis: 30%;
  border-right: 1px solid ${(props) => props.theme.colors.neutral[300]};
`
const $FilterMenuItem = styled.li`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.m} ${spacing.l};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[300]};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.primary[100]};
    `};
`

const $Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`

const $ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: hidden;
`

const $ModalContent = styled.div`
  width: 50%;
  max-width: 500px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: #fff;
  overflow: auto;
`

const $ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.3);
  svg {
    width: 16px;
    height: auto;
    cursor: pointer;
  }
`

export const $SearchInput = styled.div`
  position: relative;
  cursor: pointer;
  input {
    border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
    width: ${(props) => props.size || '40'}px;
    height: ${(props) => props.size || '40'}px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${spacing.l};
    margin-right: ${spacing.l};
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    transition: width 0.5s;
    &:focus,
    :hover,
    :not(:placeholder-shown) {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
  }
  span {
    position: absolute;
    top: 10px;
    right: 25px;
    background: ${({ theme }) => theme.colors.white} url(${SearchIcon}) center;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
`

export {
  $FlexList,
  $FlexListItem,
  $SquareButton,
  $FilterMenu,
  $FilterMenuItem,
  $Modal,
  $ModalContent,
  $ModalOverlay,
  $ModalHeader,
}
