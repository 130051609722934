import React, { useEffect, useState } from 'react'
import { Div } from 'styledComponent'
import { Input } from 'components/Common'
import { GET_SEL_VALUE } from 'components/Content/autoEngagement/constants'
import { GET_SELECTED_CITY_OPTIONS, STATE_OPTIONS } from 'utils/reusables'

export const Location = ({ answer }) => {
  const [state, setState] = useState('')
  const [city, setCity] = useState('')

  const onStateChange = (val) => {
    setState(val.value)
    setCity('')
  }

  useEffect(() => {
    const [st = '', cit = ''] = (answer || '').split(', ')
    setState(st)
    setCity(cit)
  }, [answer])

  return (
    <Div pb="70px">
      <Input
        variant="single-select"
        inputWidth="300px"
        showClear
        disabled={!!answer}
        options={STATE_OPTIONS}
        onChange={onStateChange}
        value={GET_SEL_VALUE(state, STATE_OPTIONS)}
        maxMenuHeight="100px"
        placeholder="Select State"
      />
      <Input
        variant="single-select"
        inputWidth="300px"
        disabled={!state}
        showClear
        disabled={!!answer}
        options={GET_SELECTED_CITY_OPTIONS(state)}
        onChange={(val) => setCity(val.value)}
        value={
          city
            ? GET_SEL_VALUE(city, GET_SELECTED_CITY_OPTIONS(state) || [])
            : ''
        }
        maxMenuHeight="100px"
        placeholder="Select City"
      />
    </Div>
  )
}
