import React from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'

/**
 * @description common Bar Chart component.
 * @param {Object<data, series>} data - chart data object,
 * for chart data and multiple series data
 * @param {String} toolTipTitle - tooltip title
 * @param {String} title - title
 * @param {String} barWidth - bar width (0% - 100%).
 * @param {Boolean} showToolBar - show/hide toolBar controls
 * @param {Boolean} showYLabels - show/hide y-axis labels
 * @param {Boolean} showGrid - show/hide x-axis & y-axis grid lines
 * @param {Boolean} showDataLabels - show/hide data labels
 * @param {Function} dataTitleFormatter - function to format in chart data labels
 */
const BarChart = ({
  data: { data, series },
  showToolBar,
  toolTipTitle,
  title,
  barWidth,
  showYLabels,
  showGrid,
  showDataLabels,
  dataTitleFormatter,
  onDataPointClick,
  ...props
}) => {
  const dataPointClickHandler = (
    event,
    chartContext,
    { seriesIndex, dataPointIndex, config }
  ) => {
    if (dataPointIndex > -1 && typeof onDataPointClick === 'function') {
      const { key, value } = data[dataPointIndex]
      const chartDataPoint = {
        key,
        value,
      }
      return onDataPointClick(chartDataPoint)
    }
    return null
  }

  const chartConfig = {
    options: {
      chart: {
        toolbar: {
          show: showToolBar,
        },
        events: {
          dataPointSelection: dataPointClickHandler,
        },
      },
      title: {
        text: title,
        align: 'left',
      },
      xaxis: {
        categories: data?.map(({ key }) => key),
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        axisTicks: {
          show: showGrid,
        },
      },
      yaxis: {
        labels: {
          show: showYLabels,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: showGrid,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
          },
          columnWidth: barWidth,
        },
      },
      dataLabels: {
        enabled: showDataLabels,
        offsetY: -20,
        formatter: dataTitleFormatter,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      grid: {
        show: showGrid,
      },
    },
    series: series || [
      {
        name: toolTipTitle,
        data: data?.map(({ value }) => value),
      },
    ],
  }

  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="bar"
      {...props}
    />
  )
}

export default BarChart

BarChart.defaultProps = {
  showToolBar: false,
  toolTipTitle: 'Total',
  title: '',
  barWidth: '70%',
  showYLabels: true,
  showGrid: true,
  showDataLabels: true,
  dataTitleFormatter: function (val) {
    return val
  },
  onDataPointClick: null,
}

BarChart.prototype = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.number,
        })
      ).isRequired,
      series: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          data: PropTypes.arrayOf(PropTypes.number),
        })
      ),
    })
  ),
  toolTipTitle: PropTypes.string,
  showToolBar: PropTypes.bool,
  showYLabels: PropTypes.bool,
  showGrid: PropTypes.bool,
  showDataLabels: PropTypes.bool,
  dataTitleFormatter: PropTypes.func,
  onDataPointClick: PropTypes.func,
}
