import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FormattedNumber } from 'react-intl'

import { Text } from 'components/Common'
import { OS } from '../CustomerProfiling'
import moment from 'moment'
import { StarFilledIcon } from 'assets/icons'
import { useSelectStore } from 'hooks'

const CustomerStats = ({ stats }) => {
  const {
    data: { currencyCode },
  } = useSelectStore('auth.user')
  return (
    <$StatsBlock>
      <Text variant="h4" type="main" weight="bold">
        Customer Overview
      </Text>
      <$StatsList>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Total Visit:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.vCnt || 'N/A'}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Last Visit:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.lvDate
              ? moment(stats?.lvDate)?.format('DD/MM/YYYY')
              : 'N/A'}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Total Spend:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.tSp ? (
              <FormattedNumber
                style="currency"
                currency={currencyCode}
                value={stats?.tSp}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            ) : (
              'N/A'
            )}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Average Feedback:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.aFb ? (
              <>
                {stats?.aFb} <StarFilledIcon />
              </>
            ) : (
              'N/A'
            )}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Average Spend:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.aSp ? (
              <FormattedNumber
                style="currency"
                currency={currencyCode}
                value={stats?.aSp}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            ) : (
              'N/A'
            )}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            Feedbacks Given:
          </Text>
          <Text variant="medium" type="grey" weight="bold">
            {stats?.tFbCount || 'N/A'}
          </Text>
        </$StatsListItem>
        <$StatsListItem>
          <Text variant="medium" type="grey">
            OS:
          </Text>
          {stats?.os ? (
            <OS osType={stats.os} defaultText="N/A" />
          ) : (
            <Text variant="medium" type="grey" weight="bold">
              N/A
            </Text>
          )}
        </$StatsListItem>
      </$StatsList>
      {/* <Text variant="h4" type="main" weight="bold" my={spacing.l}>
          Interaction History
        </Text>
        <$InteractionBox>
          <$InterHeader>
            <span className='left'>Activity Name</span>
            <span>Date & Time</span>
          </$InterHeader>
          <$InterBody>
            <span className='left'>Activity Name</span>
            <span>Date & Time</span>
          </$InterBody>
        </$InteractionBox> */}
    </$StatsBlock>
  )
}

export default CustomerStats

const $InteractionBox = styled.div`
  border: 2px solid #cccccc;
  border-radius: 16px;
  overflow: hidden;
`

const $InterHeader = styled.div`
  color: #fff;
  font-size: 14px;
  background-color: #00abfa;
  display: flex;
  border-bottom: 2px solid #cccccc;
  span {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.s};
    &.left {
      border-right: 2px solid #cccccc;
    }
  }
`
const $InterBody = styled($InterHeader)`
  background-color: #fff;
  color: #808080;
`

const $StatsBlock = styled.div`
  padding: 16px;
  background: #f2f5f8;
  margin-top: 8px;
  border-radius: 8px;
`

const $StatsList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  list-style: none;
`

const $StatsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  min-width: 48%;
`
