import React, { Component } from 'react'
import 'css/auth/auth.scss?v=3'

class Confirmation extends Component {
  render() {
    return (
      <div className="loginScreen row h-100">
        <div className="col col-12 col-sm-7 loginRight h-100">
          <div>
            <img
              src={require('../../images/Logo.png')}
              className="loginLogo"
              alt="logo"
            />
            <div className="loginForm">
              <span className="authHeading">
                Password Reset Link Has Been Sent On Your E-Mail.
              </span>
              <div
                className="goBackFromConfirmation"
                onClick={() => {
                  window.location.href = '/login'
                }}
              >
                <i className="fas fa-arrow-left"></i> Login
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Confirmation
