import React from 'react'

import { ViewIcon } from 'assets/icons'
import { $FlexList, $FlexListItem } from './ComplaintsList.styled'

import { billUrl } from 'config/config'

const TableActions = ({ billUid, viewAction, deleted }) => {
  const onViewClick = () => {
    const url = `${billUrl}/${billUid}${deleted ? '?deleted=true' : ''}`
    viewAction(url)
  }

  return (
    <>
      <$FlexList>
        <$FlexListItem onClick={onViewClick} pointer>
          <ViewIcon />
        </$FlexListItem>
      </$FlexList>
    </>
  )
}

export default TableActions
