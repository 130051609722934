import createReducer from 'utils/createReducer'
import * as types from 'redux/types/vendorIds'

const initialState = {
  vendorIds: [],
  selectedVendor: {},
  availableBrands: [],
  vendorBrands: [],
  selectedBrand: null,
}

export default createReducer(initialState)(types)
