import React from 'react'
import { useRouter, useSelectStore } from 'hooks'

import CSVFields from 'assets/images/upload-products-csv-fields.svg'
import Navbar from 'components/Layout/Navbar'
import { SectionHeading, UploadExcel, TopActionBar } from 'components/Common'
import { spacing } from 'design-system'
import { useToasts } from 'components/Common/Toast'
import { post } from 'utils/axiosHandler'

import {
  $Block,
  $BodyStyles,
  $FlexBox,
  $FlexItem,
} from '../ProductAnalytics.styled'
import { SAMPLE_FILE_URL } from './constants'
import Instructions from './Instructions'

const UploadProducts = () => {
  const {
    history,
    state: { forManagement },
  } = useRouter()
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')

  const onExitClick = () => {
    history.goBack()
  }

  const [file, setFile] = React.useState(null)
  const [isUploading, setIsUploading] = React.useState(false)

  const { toast } = useToasts()

  const fileRef = React.useRef(null)

  const onFileChange = (e) => {
    e.preventDefault()

    setFile(e.target.files[0])
  }

  const resetFile = () => {
    setFile(null)
    fileRef.current.value = ''
  }

  const onFileSave = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('file', file)
    formData.append('vendorId', selectedVendor?.value)
    formData.append('brandId', selectedBrand?.value)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      setIsUploading(true)
      const res = await post(
        `/product-management/products/bulk-upload`,
        formData,
        config
      )

      if (res.message) {
        return toast(res.message, 'error')
      }

      toast('Your file is uploaded successfully!', 'success')
      resetFile()
    } catch (e) {
      toast('Error in uploading file. Please try again', 'error')
      console.log('Error in uploading file', e)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <>
      <Navbar
        heading={`${
          forManagement ? 'Products' : 'Product Analytics'
        } - Upload Products`}
      />
      <TopActionBar
        title="Upload Products"
        buttonVariant="textualPrimary"
        buttonTitle="Import Data"
        onExit={onExitClick}
        onSave={onFileSave}
        loading={!file || isUploading}
        btnBorder
      />
      <$Block padding={spacing.l}>
        <$FlexBox justify="space-between">
          <$FlexItem flexBasis="60%">
            <SectionHeading
              heading="Add product data"
              subHeading="Follow the below 4 steps to add product data manually"
              variant="medium"
              subVariant="small"
            />

            <UploadExcel
              ref={fileRef}
              onFileChange={onFileChange}
              fileName="Products_Sample"
              sampleFile={SAMPLE_FILE_URL}
              excelFields={CSVFields}
            />
          </$FlexItem>
          <$FlexItem flexBasis="30%" maxWidth="420px">
            <Instructions />
          </$FlexItem>
        </$FlexBox>
      </$Block>
      <$BodyStyles />
    </>
  )
}

export default UploadProducts
