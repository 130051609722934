import React from 'react'
import Select, { components } from 'react-select'

import { spacing } from 'design-system'
import { Text, Input, RenderIf, useToasts } from 'components/Common'
import { Div, FlexBox } from 'styledComponent'
import { ReactComponent as DropdownIcon } from 'images/down-arrow.svg'
import Preview from './PreviewPanel'
import { GET_SEL_VALUE } from 'utils/reusables'
import {
  BUTTON_TYPES,
  CONTENT_INPUT_PARAMS,
  CTA_TYPES,
  LANGUAGES,
  MEDIA_TYPES,
  TEMPLATE_TYPES,
  WEBSITE_TYPES,
} from './constants'
import TemplateTextContent from './TemplateTextContent'
import { cloneDeep } from 'lodash'
import { $UnderlineText } from './TemplateCampaign.styled'

const TemplateContent = ({ form, setForm }) => {
  const { toast } = useToasts()

  const [inputCursorPoints, setInputCursorPoints] = React.useState({})

  const onInputChange = (e, key) => {
    if (['type', 'mediaType', 'buttonType'].includes(key)) {
      const formCopy = cloneDeep(form)

      if (key === 'type') {
        delete formCopy.mediaType
      }
      if (key === 'buttonType') {
        if (e === 'none') {
          delete formCopy.buttons
        } else {
          formCopy.buttons = [_getButtonStructure(e)]
        }
      }

      setForm({
        ...formCopy,
        [key]: e,
      })
    } else if (key === 'language') {
      setForm({
        ...form,
        [key]: e?.value,
      })
    } else {
      setForm({
        ...form,
        [key]: e?.target?.value,
      })
    }
  }

  const handleContentInputChange = (e, key) => {
    if (e?.target?.value?.length > CONTENT_INPUT_PARAMS[key].maxChar) {
      return
    }

    setForm({
      ...form,
      content: {
        ...form.content,
        [key]: e?.target?.value,
      },
    })
  }

  const addVariable = (key) => {
    const formCopy = cloneDeep(form)

    let content = null
    let contentIndex = null

    if (key === 'buttons') {
      contentIndex = form?.buttons?.findIndex(
        ({ urlType }) => urlType === 'dynamic'
      )

      if (contentIndex < 0) {
        return null
      }

      content = form?.buttons?.[contentIndex]?.url
    } else {
      content = form?.content?.[key]
    }

    if (content.length > CONTENT_INPUT_PARAMS?.[key].maxChar) {
      return
    }

    const regex = /{{[\w]+}}/gim

    const allMatches = content.match(regex)

    if (allMatches && allMatches.length === CONTENT_INPUT_PARAMS[key].maxVar) {
      toast(
        `Only ${CONTENT_INPUT_PARAMS[key].maxVar} variable(s) are allowed in ${key}`,
        'error'
      )
      return
    }

    if (key === 'buttons') {
      const inputContent = formCopy?.buttons[contentIndex].url
      const { start = inputContent.length, end = inputContent.length } =
        inputCursorPoints?.url || {}

      formCopy.buttons[contentIndex].url = `${inputContent.substring(
        0,
        start
      )}{{var}}${inputContent.substring(end)}`
    } else {
      const inputContent = formCopy.content[key]
      const { start = inputContent.length, end = inputContent.length } =
        inputCursorPoints?.[key] || {}

      formCopy.content = {
        ...formCopy.content,
        [key]: `${inputContent.substring(
          0,
          start
        )}{{var}}${inputContent.substring(end)}`,
      }
    }
    setForm(formCopy)
  }

  const onButtonInputChange = (e, index, key, inputType) => {
    const data = cloneDeep(form)

    if (!data?.buttons?.length) return null

    if (inputType === 'select') {
      if (key === 'type') {
        data.buttons[index] = {
          ..._getButtonStructure(form?.buttonType),
          [key]: e.value,
        }
      } else {
        data.buttons[index][key] = e.value
      }
    } else {
      if (
        key === 'text' &&
        e?.target?.value?.length > CONTENT_INPUT_PARAMS.buttons.maxChar
      ) {
        return null
      } else if (
        key === 'phoneNumber' &&
        !_validatePhoneNumber(e.target.value)
      ) {
        return null
      }

      data.buttons[index][key] = e.target.value
    }

    setForm(data)
  }

  const addAnotherButton = () => {
    const buttonStructure = _getButtonStructure(form.buttonType)

    if (!buttonStructure) return null

    const buttonData = cloneDeep(form?.buttons || [])
    buttonData.push(buttonStructure)

    setForm({
      ...form,
      buttons: buttonData,
    })
  }

  const deleteButton = (index) => {
    if (!index) return null

    const formCopy = cloneDeep(form)
    formCopy.buttons.splice(index, 1)

    setForm(formCopy)
  }

  const handleContentInputBlur = (e, key) => {
    const start = e.target.selectionStart
    const end = e.target.selectionEnd

    setInputCursorPoints({
      ...inputCursorPoints,
      [key]: {
        start,
        end,
      },
    })
  }

  const onEmojiClick = (value) => {
    const content = form.content.body

    if (content.length > CONTENT_INPUT_PARAMS.body.maxChar) {
      return
    }

    const { start = content.length, end = content.length } =
      inputCursorPoints?.body || {}

    const emoji = value?.emoji

    const textWithEmoji = `${content.substring(
      0,
      start
    )}${emoji}${form.content.body.substring(end)}`

    setForm({
      ...form,
      content: {
        ...form.content,
        body: textWithEmoji,
      },
    })

    setInputCursorPoints({
      ...inputCursorPoints,
      body: {
        start: start + (emoji?.length || 0),
        end: end + (emoji?.length || 0),
      },
    })
  }

  return (
    <FlexBox>
      <Div flex="1" padding="0 32px 0 0">
        <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
          Content
        </Text>
        <FlexBox justify="space-between">
          <Div flex="0.6">
            <Input label="Select Template Language">
              <Select
                options={LANGUAGES}
                placeholder="Select Language"
                value={GET_SEL_VALUE(form?.language, LANGUAGES) || ''}
                onChange={(e) => onInputChange(e, 'language')}
                components={{ DropdownIndicator }}
                isSearchable
              />
            </Input>
          </Div>
          <Div flex="1" padding="0 0 0 32px">
            <FlexBox>
              <Input
                label="Template Type"
                variant="radio"
                containerMargin="0 8px 16px 0"
                value={form?.type}
                onChange={(e) => onInputChange(e, 'type')}
                options={TEMPLATE_TYPES}
              />
              <RenderIf active={form?.type === 'media'}>
                <Input
                  label="Media Type"
                  variant="radio"
                  value={form?.mediaType}
                  onChange={(e) => onInputChange(e, 'mediaType')}
                  options={MEDIA_TYPES}
                />
              </RenderIf>
            </FlexBox>
          </Div>
        </FlexBox>
        <RenderIf active={form?.type === 'text'}>
          <TemplateTextContent
            text={form?.content?.header}
            label="Template Header"
            onChange={(e) => handleContentInputChange(e, 'header')}
            maxCharLength={CONTENT_INPUT_PARAMS.header.maxChar}
            margin="0 0 16px 0"
            optional
            disabledVar
          />
        </RenderIf>
        <TemplateTextContent
          text={form?.content?.body}
          label="Template Body"
          onChange={(e) => handleContentInputChange(e, 'body')}
          onBlur={(e) => handleContentInputBlur(e, 'body')}
          maxCharLength={CONTENT_INPUT_PARAMS.body.maxChar}
          addVariable={() => addVariable('body')}
          variant="textarea"
          margin="0 0 16px 0"
          onEmojiClick={onEmojiClick}
          emojiPicker
        />
        <TemplateTextContent
          text={form?.content?.footer}
          label="Template Footer"
          onChange={(e) => handleContentInputChange(e, 'footer')}
          maxCharLength={CONTENT_INPUT_PARAMS.footer.maxChar}
          margin="0 0 16px 0"
          optional
          disabledVar
        />

        <Input
          label="Add Button(s)"
          variant="radio"
          value={form?.buttonType}
          onChange={(e) => onInputChange(e, 'buttonType')}
          options={BUTTON_TYPES}
          containerMargin="0"
        />

        <RenderIf active={form?.buttonType === 'quick-reply'}>
          {form?.buttons?.map((button, index) => (
            <FlexBox>
              <Input
                label="Button Text"
                key={index}
                variant="input"
                containerMargin="0"
                value={button?.text}
                onChange={(e) => onButtonInputChange(e, index, 'text')}
              />
              <RenderIf active={!!index}>
                <Div alignSelf="center" paddingLeft="16px">
                  <$UnderlineText
                    variant="small"
                    type="primary"
                    onClick={() => deleteButton(index)}
                  >
                    <span>Remove</span>
                  </$UnderlineText>
                </Div>
              </RenderIf>
            </FlexBox>
          ))}
          <RenderIf active={form?.buttons?.length < 3}>
            <$UnderlineText
              variant="small"
              type="primary"
              onClick={addAnotherButton}
            >
              + <span>Add Another Button</span>
            </$UnderlineText>
          </RenderIf>
        </RenderIf>

        <RenderIf active={form?.buttonType === 'cta'}>
          {form?.buttons?.map((button, index) => (
            <FlexBox gap="10px">
              <Div flexBasis="25%">
                <Input label="Type of Action">
                  <Select
                    filterOption={false}
                    options={CTA_TYPES.filter(({ value }) =>
                      form.buttons?.findIndex((ele) => ele.type === value) > -1
                        ? false
                        : true
                    )}
                    placeholder="Action"
                    value={GET_SEL_VALUE(button?.type, CTA_TYPES) || ''}
                    onChange={(e) =>
                      onButtonInputChange(e, index, 'type', 'select')
                    }
                    components={{ DropdownIndicator }}
                  />
                </Input>
              </Div>
              <Input
                label="Button Text"
                variant="input"
                value={button?.text}
                onChange={(e) => onButtonInputChange(e, index, 'text')}
                width="30%"
              />
              <RenderIf active={button?.type === 'website'}>
                <Div flexBasis="25%">
                  <Input label="URL Type">
                    <Select
                      filterOption={false}
                      options={WEBSITE_TYPES.filter(({ value }) =>
                        form.buttons?.findIndex(
                          (ele) => ele.urlType === value
                        ) > -1
                          ? false
                          : true
                      )}
                      placeholder="URL Type"
                      value={
                        GET_SEL_VALUE(button?.urlType, WEBSITE_TYPES) || ''
                      }
                      onChange={(e) =>
                        onButtonInputChange(e, index, 'urlType', 'select')
                      }
                      components={{ DropdownIndicator }}
                    />
                  </Input>
                </Div>
              </RenderIf>
              <RenderIf
                active={
                  button?.type === 'website' && button.urlType === 'dynamic'
                }
              >
                <Input label="Website">
                  <TemplateTextContent
                    text={button?.url}
                    onChange={(e) => onButtonInputChange(e, index, 'url')}
                    onBlur={(e) => handleContentInputBlur(e, 'url')}
                    maxCharLength={CONTENT_INPUT_PARAMS.footer.maxChar}
                    justify="center"
                    addVariable={() => addVariable('buttons')}
                    hideCharCount
                  />
                </Input>
              </RenderIf>
              <RenderIf
                active={
                  (button?.type === 'website' && button.urlType === 'static') ||
                  button?.type === 'phone-number'
                }
              >
                <Input
                  label={
                    button?.type === 'website' ? 'Website' : 'Phone Number'
                  }
                  variant="input"
                  value={
                    button?.[
                      button?.type === 'website' ? 'url' : 'phoneNumber'
                    ] || ''
                  }
                  onChange={(e) =>
                    onButtonInputChange(
                      e,
                      index,
                      button?.type === 'website' ? 'url' : 'phoneNumber'
                    )
                  }
                  placeholder={
                    button?.type === 'website' ? 'URL' : 'with country code'
                  }
                  width="30%"
                />
              </RenderIf>
              <RenderIf active={!!index}>
                <Div alignSelf="center" paddingLeft="16px">
                  <$UnderlineText
                    variant="small"
                    type="primary"
                    onClick={() => deleteButton(index)}
                  >
                    <span>Remove</span>
                  </$UnderlineText>
                </Div>
              </RenderIf>
            </FlexBox>
          ))}
          <RenderIf active={form?.buttons?.length < 2}>
            <$UnderlineText
              variant="small"
              type="primary"
              onClick={addAnotherButton}
            >
              + <span>Add Another CTA</span>
            </$UnderlineText>
          </RenderIf>
        </RenderIf>
      </Div>
      <Preview data={form} />
    </FlexBox>
  )
}

export default TemplateContent

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon width="15px" height="15px" />
    </components.DropdownIndicator>
  )
}

const _getButtonStructure = (buttonType) => {
  switch (buttonType) {
    case 'cta':
      return {
        type: '',
        text: '',
      }
    case 'quick-reply':
      return {
        text: '',
      }

    default:
      return null
  }
}

const _validatePhoneNumber = (value) => {
  if (!value) return true
  if (value.length > 14) return false

  const regexp = /^[\d]+$/gim

  return regexp.test(value)
}
