import React, { useState, useEffect } from 'react'
import { $OptButton, $QuestContainer, $SelectContainer } from '../Survey.styled'
import {
  OPTION_GEN,
  OPTION_TYPES,
  QUEST_GEN,
  GET_SEL_VALUE,
  SURVEY_STYLE,
  OPINION_STYLE,
  SHOW_ADD,
  INPUT_TYPES,
} from '../constants'
import { SurveyOption } from '../options'
import {
  AddBlueIcon,
  DeleteRedIcon,
  CheckedIcon,
  EmptyIcon,
} from 'assets/icons'
import { Block, FlexBox, Div } from 'styledComponent'
import { Input, Text } from 'components/Common'
import UploadModal from 'components/Content/autoEngagement/components/UploadModal'

export const SurveyQuestion = (props) => {
  const [hoverNumber, setHoverNumber] = useState()
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [showDescription, setShowDescription] = useState(
    !!props?.quest?.description
  )

  const answer =
    props.answers?.filter((ans) => ans.questionId === props.quest._id)[0] || {}
  const optNumber = props.quest?.options?.findIndex((opt) =>
    answer?.answerIds?.includes(opt?._id)
  )

  const setOption = (opt, i) => {
    const altOpt = props.quest?.options?.map((op, ind) => {
      if (i === ind) {
        op.label = opt
      }
      return op
    })
    props.setQuest(
      {
        ...props.quest,
        options: altOpt,
      },
      props.qi
    )
  }

  const setImageTitle = (opt, i) => {
    const altOpt = props.quest?.options?.map((op, ind) => {
      if (i === ind) {
        op.title = opt
      }
      return op
    })
    props.setQuest(
      {
        ...props.quest,
        options: altOpt,
      },
      props.qi
    )
  }

  const addOption = () => {
    const altOpt = [
      ...props.quest?.options,
      OPTION_GEN(props.quest?.inputType, props.quest?.options?.length + 1),
    ]
    props.setQuest(
      {
        ...props.quest,
        options: altOpt,
      },
      props.qi
    )
  }

  const addImage = () => {
    setIsPopOpen(true)
  }

  const deleteLastOption = () => {
    const altOpt = [...props.quest?.options]
    altOpt.pop()
    props.setQuest(
      {
        ...props.quest,
        options: altOpt,
      },
      props.qi
    )
  }

  const deleteOption = (i) => {
    const refinedOptions = props.quest?.options?.filter((_, ind) => i !== ind)
    props.setQuest(
      {
        ...props.quest,
        options: refinedOptions,
      },
      props.qi
    )
  }

  const selectInputType = (obj) => {
    props.setQuest(
      QUEST_GEN(obj.value, props.quest?.text, props.quest?.description),
      props.qi
    )
  }

  const setMedia = ({ fileLocation }) => {
    const altOpt = [
      ...props.quest?.options,
      OPTION_GEN(props.quest?.inputType, fileLocation),
    ]
    setIsPopOpen(false)
    props.setQuest(
      {
        ...props.quest,
        options: altOpt,
      },
      props.qi
    )
  }

  const isImageType = ['image', 'image-multi'].includes(props.quest?.inputType)
  const isFlex = ['range', 'rating', 'image', 'image-multi'].includes(
    props.quest?.inputType
  )
  const isInputType = INPUT_TYPES('input').includes(props.quest?.inputType)
  const isDropdownType = ['dropdown'].includes(props.quest?.inputType)
  const isLocation = ['location'].includes(props?.quest?.inputType)

  const dropDownOptions =
    props.quest?.options?.map((opt) => ({
      label: opt?.label,
      value: opt?._id || opt?.label,
    })) || []

  const options = props?.quest?.reverse
    ? props.quest?.options?.slice()?.reverse()
    : props.quest?.options
  return (
    <$QuestContainer showBorder={props.isEditing}>
      {isImageType && isPopOpen && (
        <UploadModal
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
          setMedia={setMedia}
          showMedias={['image', 'gif']}
          uploadedFrom="survey"
        />
      )}
      <FlexBox margin="8px 0" justify="space-between">
        <Block width={props.isEditing ? 'calc(100% - 220px)' : '100%'}>
          {props.isEditing && <p>Survey Question</p>}
          <FlexBox align="center" justify="flex-start">
            {props.isEditing ? (
              <input
                style={{ ...SURVEY_STYLE, width: '100%' }}
                disabled={!props.isEditing}
                value={props.quest?.text || ''}
                title={props.quest?.text || ''}
                placeholder="Enter your question"
                onChange={(e) =>
                  props.setQuest(
                    { ...props.quest, text: e.target.value },
                    props.qi
                  )
                }
              />
            ) : (
              <div style={{ fontWeight: 'bold', color: props?.fontColor }}>
                {props.questNumber}. {props.quest?.text}
              </div>
            )}
          </FlexBox>
        </Block>
        {props.isEditing && (
          <$SelectContainer>
            <Input
              containerMargin="0"
              padding="0"
              variant="single-select"
              label="Question Type"
              value={GET_SEL_VALUE(props.quest?.inputType, OPTION_TYPES)}
              onChange={selectInputType}
              options={OPTION_TYPES}
              inputWidth="200px"
              maxMenuHeight="200px"
            />
          </$SelectContainer>
        )}
      </FlexBox>
      <FlexBox
        margin="4px 0 8px"
        gap="12px"
        justify="space-between"
        align="center"
      >
        {props.isEditing &&
          (showDescription || props.quest?.description ? (
            <>
              <input
                style={{ ...SURVEY_STYLE, width: '100%' }}
                disabled={!props.isEditing}
                value={props.quest?.description || ''}
                placeholder="Enter description"
                title={props.quest?.description || ''}
                onChange={(e) =>
                  props.setQuest(
                    { ...props.quest, description: e.target.value },
                    props.qi
                  )
                }
              />
              <DeleteRedIcon
                onClick={() => {
                  props.setQuest(
                    { ...props.quest, description: null },
                    props.qi
                  )
                  setShowDescription(false)
                }}
                type="button"
              />
            </>
          ) : (
            <$OptButton onClick={() => setShowDescription(true)}>
              <AddBlueIcon />
              Add Description
            </$OptButton>
          ))}
        {props.quest?.description && !props.isEditing && (
          <Text
            style={{ color: props?.fontColor }}
            variant="medium"
            mt="-8px !important"
          >
            {props.quest?.description}
          </Text>
        )}
      </FlexBox>
      <Div ml="8px" maxWidth="800px">
        <FlexBox gap="32px">
          {props.isEditing && (
            <Text width="100px">{isInputType ? 'Response' : 'Options'}</Text>
          )}
          {props.isEditing && isImageType && (
            <Text
              onClick={() =>
                props.setQuest(
                  { ...props.quest, showTitle: !props.quest.showTitle },
                  props.qi
                )
              }
              type="grey"
              weight="normal"
              variant="medium"
            >
              {props.quest?.showTitle ? (
                <CheckedIcon type="button" />
              ) : (
                <EmptyIcon type="button" />
              )}
              &nbsp; Show Title to User
            </Text>
          )}
        </FlexBox>
        {!isInputType &&
          !isLocation &&
          options?.map((opt, i) => (
            <>
              {isFlex &&
                props.isEditing &&
                i === 0 &&
                props.quest?.options?.length > 1 &&
                !isImageType && (
                  <div
                    onClick={deleteLastOption}
                    style={{ ...OPINION_STYLE, color: 'tomato' }}
                  >
                    Reduce Scale
                  </div>
                )}
              <article
                style={
                  isFlex ? { display: 'inline-block' } : { display: 'block' }
                }
                onMouseEnter={() => setHoverNumber(opt?.label)}
                onMouseLeave={() => setHoverNumber()}
              >
                <SurveyOption
                  answer={answer?.answerIds || []}
                  key={'opt' + i + props.quest?._id}
                  optId={i}
                  optionsLength={props.quest?.options?.length}
                  questId={props.questId}
                  isEditing={props.isEditing}
                  option={opt?.label}
                  imageTitle={opt?.title}
                  optionId={opt?._id}
                  optNumber={optNumber}
                  hoverNumber={hoverNumber}
                  deleteOption={deleteOption}
                  setOption={(val) => setOption(val, i)}
                  setImageTitle={(val) => setImageTitle(val, i)}
                  type={props.quest?.inputType}
                  fontColor={props?.fontColor}
                  fontFamily={props?.fontFamily}
                  showTitle={props.quest?.showTitle}
                  locationAnswer={answer?.customAnswer}
                  answers={answer}
                  reverse={props.quest?.reverse}
                />
              </article>
              {['range', 'rating'].includes(props.quest?.inputType) &&
                props.isEditing &&
                i === props.quest?.options?.length - 1 && (
                  <div onClick={addOption} style={OPINION_STYLE}>
                    Increase Scale
                  </div>
                )}
            </>
          ))}
        {isImageType && props.isEditing && (
          <$OptButton inline onClick={addImage}>
            <AddBlueIcon />
            Add {props.quest?.options?.length === 0 ? ' ' : ' Another '} Image
          </$OptButton>
        )}
      </Div>
      {(isInputType || isLocation) && (
        <Div ml="8px">
          <SurveyOption
            answer={answer?.customAnswer}
            key={'opt' + props.questId}
            optId={0}
            questId={props.questId}
            isEditing={props.isEditing}
            type={props.quest?.inputType}
          />
        </Div>
      )}
      {isDropdownType && !props.isEditing && (
        <Div ml="8px">
          <Input
            variant="single-select"
            options={dropDownOptions}
            value={
              answer?.answerIds?.length > 0
                ? GET_SEL_VALUE(answer?.answerIds?.[0] || '', dropDownOptions)
                : ''
            }
            inputWidth="300px"
            placeholder="Select an option"
            maxMenuHeight="200px"
          />
        </Div>
      )}
      {props.isEditing && SHOW_ADD.includes(props.quest?.inputType) && (
        <FlexBox margin="8px 0" gap="12px" justify="flex-start">
          <$OptButton onClick={addOption}>
            <AddBlueIcon />
            Add Another Option
          </$OptButton>
        </FlexBox>
      )}
    </$QuestContainer>
  )
}
