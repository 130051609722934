import { post, get, put, deleteReq } from 'utils/axiosHandler'
import { isEmpty } from 'utils'
import FileDownload from 'js-file-download'

export const addUser = (params) => (dispatch) => {
  return post(`/storeUser`, params)
    .then((data) => {
      data.code && dispatch(addUserHelper(data.user))
      return Promise.resolve(data)
    })
    .catch((err) => dispatch(axiosError(err)))
}

export const deleteUser = (params) => (dispatch) => {
  return deleteReq(`/storeUser`, {
    storeIds: params.storeIds,
    email: params.email,
  })
    .then((data) => {
      data.code && dispatch(deleteUserHelper(params.email))
      return Promise.resolve(data)
    })
    .catch((err) => dispatch(axiosError(err)))
}

export const editUser = (params) => (dispatch) => {
  return put(`/storeUser`, params)
    .then((data) => {
      data.code && dispatch(editUserHelper(data.user))
      return Promise.resolve(data)
    })
    .catch((err) => dispatch(axiosError(err)))
}

export const getUsersInit = (params) => (dispatch) => {
  return get(`/storeUser`, params)
    .then((data) => {
      data.code &&
        dispatch(
          getUsersInitHelper({
            hasMoreUsers: data.users.length < parseInt(data.userCount),
            users: data.users,
          })
        )
    })
    .catch((err) => dispatch(axiosError(err)))
}

export const getMoreUsers = (params) => (dispatch, getState) => {
  const offset = getState().userAccess.userList.length
  return get(`/storeUser`, { ...params, offset: offset })
    .then((data) => {
      data.code &&
        dispatch(
          getMoreUsersHelper({
            hasMoreUsers: offset + data.users.length < parseInt(data.userCount),
            users: data.users,
          })
        )
    })
    .catch((err) => dispatch(axiosError(err)))
}

export const downloadExcel = (params) => (dispatch) => {
  get(`/storeUser/exportUsers`, params, {
    responseType: 'arraybuffer',
  }).then((data) => {
    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileDownload(blob, 'data.xlsx')
  })
}

export const getUsersInitHelper = (data) => (dispatch) => {
  dispatch({
    type: 'INIT_USER_LIST',
    payload: data,
  })
}
const getMoreUsersHelper = (data) => {
  return {
    type: 'SET_USER_LIST',
    payload: data,
  }
}

const deleteUserHelper = (data) => {
  return {
    type: 'DELETE_FROM_USER_LIST',
    payload: data,
  }
}

const editUserHelper = (data) => {
  return {
    type: 'EDIT_USER_LIST',
    payload: data,
  }
}

const addUserHelper = (data) => {
  return {
    type: 'ADD_TO_USER_LIST',
    payload: data,
  }
}

//axios error
export const axiosError = (err) => {
  return {
    type: 'GET_ERRORS',
    payload: isEmpty(err.response) ? 'Something Went Wrong' : err.response.data,
  }
}
