import React, { useState, useEffect } from 'react'
import {
  ActionCard,
  Input,
  LoaderPopup,
  PopupContainer,
  Table,
  TopActionBar,
  useToasts,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { $WhiteContainer } from '../StoreManagement.styled'
import { useDebouncedEffect, useRouter, useSelectStore } from 'hooks'
import { Grid } from 'styledComponent'
import { useTheme } from 'styled-components'
import { ERROR_DECODE } from 'utils/reusables'
import { _selectOptionsGenerator } from 'utils/formatter'
import {
  createStoreKey,
  deleteStoreKey,
  getStoreKeyLists,
} from 'redux/actions/storeMgtActions'
import { useDispatch } from 'react-redux'
import { COLUMNS } from '../constants'
import moment from 'moment'
import { $Ellipsis } from 'components/Content/autoEngagement/AutoEngagement.styled'

const LIMIT = 10

export const ManageKeys = () => {
  const { history, params } = useRouter()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToasts()
  const [page, setPage] = useState(1)
  const {
    data: { storeKeylist, screenLoading, tableLoading, storeKeyDetails },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const init = { name: '', _id: params?.id, vendorId }
  const strCode = storeKeyDetails?.storeCode
    ? `${storeKeyDetails?.storeCode} - `
    : ''

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [form, setForm] = useState(init)

  let _params = {
    limit: LIMIT,
    skip: (page - 1) * LIMIT,
  }

  useDebouncedEffect(
    () => {
      params?.id && dispatch(getStoreKeyLists(params.id, _params))
    },
    [page],
    100
  )

  const onPaginationChange = (selPage) => setPage(selPage)

  useEffect(() => setForm(init), [isPopOpen])

  const getCommonProps = (id, label, placeholder) => ({
    id,
    label,
    key: id,
    placeholder,
    containerMargin: '0',
    inputWidth: '400px',
    variant: 'input',
    value: form?.[id] || '',
    onChange: (e) => handleChange(id, e?.target?.value),
  })

  const handleChange = (field, value) => {
    setForm((frm) => ({ ...frm, [field]: value }))
  }

  const createKey = async () => {
    if (storeKeylist?.length > 29) {
      setTimeout(() => setIsPopOpen('excess'), 0)
      return
    }
    try {
      const res = await createStoreKey(form)
      if (res) {
        toast('New Access Key Created', 'success')
        dispatch(getStoreKeyLists(params?.id, _params))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const deleteKey = async () => {
    try {
      const res = await deleteStoreKey({ ...isPopOpen, vendorId })
      if (res) {
        toast('Access Key Deleted', 'success')
        dispatch(getStoreKeyLists(params?.id, _params))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const row = (item, onActionClick) => [
    <$Ellipsis>{item?.accessKey || '-'}</$Ellipsis>,
    <$Ellipsis>{item?.name || '-'}</$Ellipsis>,
    <$Ellipsis>
      {moment(item?.createdDate)?.format('DD MMM YYYY hh:mm A')}
    </$Ellipsis>,
    <$Ellipsis>{item?.macId || '-'}</$Ellipsis>,
    <$Ellipsis>{item?.ipAddress || '-'}</$Ellipsis>,
    <ActionCard
      onIconClick={(action) => onActionClick(action, item)}
      components={['copy', 'delete']}
      item="Access Key"
    />,
  ]

  const onActionClick = (action, item) => {
    switch (action) {
      case 'copy': {
        navigator.clipboard.writeText(item?.accessKey)
        return toast('Access Key has been copied to your clipboard.', 'success')
      }

      case 'delete':
        return setIsPopOpen(item)
    }
  }

  const onAddNewClick = () => {
    setIsPopOpen('form')
  }

  return (
    <$WhiteContainer>
      <Navbar heading="Store Management - Manage Keys" />
      <LoaderPopup isPopOpen={screenLoading} />
      <PopupContainer
        isPopOpen={isPopOpen === 'form'}
        setIsPopOpen={setIsPopOpen}
        heading="Create New Access Key"
        onSubmitClick={createKey}
      >
        <Input {...getCommonProps('name', 'Name', 'Name')} />
        <Input {...getCommonProps('macId', 'Mac ID', 'Mac ID')} />
        <Input {...getCommonProps('ipAddress', 'IP Address', 'IP Address')} />
      </PopupContainer>
      <PopupContainer
        isPopOpen={isPopOpen === 'excess'}
        setIsPopOpen={setIsPopOpen}
        heading="Create New Access Key"
      >
        <div>
          You have reached the maximum number of access keys allowed for this
          store. Please raise a ticket with tech support to request additional
          terminals.
        </div>
        <div>
          Note: This will result in additional costs in your pricing plan
        </div>
      </PopupContainer>
      <PopupContainer
        isPopOpen={!['excess', 'form', false].includes(isPopOpen)}
        setIsPopOpen={setIsPopOpen}
        heading="Are you sure?"
        children="Are you sure you want to create a new access key?"
        onSubmitClick={deleteKey}
      />
      <TopActionBar
        title="Manage Keys"
        onExit={() =>
          history.push(`/storeManagement/viewRetailStore/${params?.id}`)
        }
        onSave={onAddNewClick}
        buttonTitle="Add New"
      />
      <Grid
        columns="auto auto"
        columnGap={spacing.xl}
        p={spacing.xl}
        pt={spacing.l}
      >
        <Input
          disabled
          readOnly
          label="Brand Name"
          variant="input"
          inputWidth="500px"
          value={storeKeyDetails?.store?.brand?.name}
        />
        <Input
          disabled
          readOnly
          label="Store Code + Display Address"
          variant="input"
          inputWidth="500px"
          value={strCode + storeKeyDetails?.store?.displayAddress}
        />
      </Grid>

      <Table
        columns={COLUMNS?.['Manage Keys']}
        list={storeKeylist}
        tableLoading={tableLoading}
        row={row}
        page={page}
        limit={LIMIT}
        onActionClick={onActionClick}
        onPaginationChange={onPaginationChange}
      />
    </$WhiteContainer>
  )
}
