import React from 'react'

import { ReactComponent as AppleIcon } from 'images/apple.svg'
import { ReactComponent as WindowsIcon } from 'images/windows.svg'
import { ReactComponent as AndroidIcon } from 'images/android.svg'
import { isArray, isObject } from 'lodash-es'

/**
 * helper data to display selected segment
 */
const SEGMENT_VALUES = [
  // {
  //   group: 'Products',
  //   filters: [
  //     {
  //       title: 'Products',
  //       filter: 'Products',
  //     },
  //   ],
  // },
  {
    numeric: 1,
    group: 'Other Keywords In Invoice',
    filters: [
      {
        title: 'Input Keywords',
        filter: 'keywords',
      },
    ],
  },
  {
    numeric: 2,
    group: 'Vists & Feedback',
    filters: [
      {
        title: 'Total Visits',
        filter: 'visitCount',
      },
      {
        title: 'Last Visit (no. of days)',
        filter: 'lastVisit',
      },
      {
        title: 'Average feedback',
        filter: 'avgFeedback',
      },
    ],
  },
  {
    numeric: 3,
    group: 'Billing & Expense',
    filters: [
      {
        title: 'Total SKU’s bought',
        filter: 'totalSKU',
      },
      {
        title: 'Avg. quantity per bill',
        filter: 'avgSKU',
      },
      {
        title: 'Total Expenses',
        filter: 'totalSpent',
      },
    ],
  },
  {
    numeric: 4,
    group: 'Service Explicit',
    filters: [
      {
        title: 'Customer Opt-in',
        filter: 'consent',
      },
    ],
  },
  {
    numeric: 5,
    group: 'Operating System',
    filters: [
      {
        title: 'Operating System',
        filter: 'os',
      },
    ],
  },
  {
    numeric: 6,
    group: 'Gender & Age',
    filters: [
      {
        title: 'Gender',
        filter: 'gender',
      },
      {
        title: 'Age',
        filter: 'age',
      },
    ],
  },
  {
    numeric: 7,
    group: 'POS',
    filters: [
      {
        title: 'POS Types',
        filter: 'posType',
      },
    ],
  },
  // {
  //   numeric: 8,
  //   group: 'Location',
  //   filters: [
  //     {
  //       title: 'States',
  //       filter: 'state',
  //     },
  //     {
  //       title: 'Cities',
  //       filter: 'city',
  //     },
  //   ],
  // },
]

const DEVICE_ICON = {
  android: <AndroidIcon />,
  iphone: <AppleIcon />,
  other: <WindowsIcon />,
}

const _generateOptions = (size = 5) =>
  new Array(size).fill().map((_, index) => ({
    label: index + 1,
    value: index + 1,
  }))

const TOTAL_SKU_BOUGHT = _generateOptions()
const TOTAL_VISITS = _generateOptions()
const AVG_QUANTITY_PER_BILL = _generateOptions()
const LAST_VISIT = _generateOptions()

const AVERAGE_FEEDBACK = new Array(5).fill().map((_, index) => ({
  label: `${index + 1} ⭐`,
  value: index + 1,
}))

const PARENT_SEGMENTS = {
  otherKeywordsInInvoice: ['keywordsCondition', 'keywords'],
  duration: ['duration', 'type'],
  storesVisited: ['storeIds'],
  visitsAndFeedback: ['visitCount', 'lastVisit', 'avgFeedback'],
  genderAndAge: ['gender', 'age'],
  billingAndExpense: ['totalSKU', 'totalSpent', 'avgSKU'],
  location: ['state', 'city'],
  serviceExplicit: ['consent'],
  os: ['os'],
  pos: ['posType'],
  product: [],
}

const SEGMENT_STATUSES = {
  dynamic: {
    colour: '#3498db',
    label: 'Dynamic (Real Time)',
  },
  static: {
    colour: '#27ae60',
    label: 'Static (Date Range)',
  },
  enqueue: {
    colour: '#95a5a6',
    label: 'In Queue',
  },
  processing: {
    colour: '#3498db',
  },
  completed: {
    colour: '#27ae60',
  },
  failed: {
    colour: '#e74c3c',
  },
  pending: {
    colour: '#f39c12',
  },
}

/**
 * Helper data for add/edit views
 */
const SEGMENT_CONDITION = [
  // {
  //   id: 'product',
  //   label: 'Products',
  //   by: 'in',
  //   parent: 'product',
  // },
  {
    id: 'keywords',
    label: 'Input Keywords',
    by: 'in',
    parent: 'otherKeywordsInInvoice',
  },
  {
    id: 'visitCount',
    label: 'Total Visits',
    by: 'eq',
    parent: 'visitsAndFeedback',
  },
  {
    id: 'lastVisit',
    label: 'Last Visit (no. of days)',
    by: 'eq',
    parent: 'visitsAndFeedback',
  },
  {
    id: 'avgFeedback',
    label: 'Average Feedback',
    by: 'eq',
    parent: 'visitsAndFeedback',
  },
  {
    id: 'age',
    label: 'Age',
    by: 'range',
    parent: 'genderAndAge',
  },
  {
    id: 'gender',
    label: 'Gender',
    by: 'in',
    parent: 'genderAndAge',
  },
  {
    id: 'totalSKU',
    label: "Total SKU's bought",
    by: 'eq',
    parent: 'billingAndExpense',
  },
  {
    id: 'avgSKU',
    label: 'Avg. quantity per bill',
    by: 'eq',
    parent: 'billingAndExpense',
  },
  {
    id: 'totalSpent',
    label: 'Total Expenses',
    by: 'range',
    parent: 'billingAndExpense',
  },
  {
    id: 'state',
    label: 'States',
    by: 'in',
    parent: 'location',
  },
  {
    id: 'city',
    label: 'Cities',
    by: 'in',
    parent: 'location',
  },
  {
    id: 'consent',
    label: 'Customer Opt-in',
    by: 'eq',
    parent: 'serviceExplicit',
  },
  {
    id: 'os',
    label: 'Operating System',
    by: 'in',
    parent: 'os',
  },
  {
    id: 'posType',
    label: 'POS Types',
    by: 'in',
    parent: 'pos',
  },
  {
    id: 'storeIds',
    label: 'Stores Visited',
    by: 'in',
    parent: 'storesVisited',
  },
]

const GET_VALUE_OF = (id, field) =>
  SEGMENT_CONDITION.filter((item) => item.id === id)?.[0]?.[field] || ''

const SEGMENT_FIELD_TYPES = Object.keys(PARENT_SEGMENTS)
  .map((item) => ({
    [item]: item,
  }))
  .reduce((start, next) => ({ ...start, ...next }))

const SEGMENT_PAYLOAD = (segData) => {
  const payload = {
    name: segData.segmentName,
    description: segData.segmentDescription,
    conditions: [],
  }
  delete segData.segmentName
  delete segData.segmentDescription
  delete segData.type
  for (const filter in segData) {
    const value =
      filter === 'consent' ? segData?.[filter] === 'true' : segData?.[filter]
    if (filter === 'keywordsCondition') {
      continue
    } else if (filter === 'keywords' && value.length > 0) {
      payload.conditions.push({
        filter,
        value: value?.map((item) => item?.trim()),
        by: segData?.keywordsCondition,
      })
    } else if (isObject(value) && !isArray(value)) {
      if (value?.min && value?.max)
        payload.conditions.push({
          filter,
          value: value?.min + '-' + value?.max,
          by: GET_VALUE_OF(filter, 'by'),
        })
    } else {
      if (value !== '')
        payload.conditions.push({
          filter,
          value,
          by: GET_VALUE_OF(filter, 'by'),
        })
    }
  }

  return payload
}

export {
  DEVICE_ICON,
  TOTAL_SKU_BOUGHT,
  AVG_QUANTITY_PER_BILL,
  TOTAL_VISITS,
  AVERAGE_FEEDBACK,
  LAST_VISIT,
  SEGMENT_CONDITION,
  SEGMENT_VALUES,
  SEGMENT_FIELD_TYPES,
  PARENT_SEGMENTS,
  GET_VALUE_OF,
  SEGMENT_PAYLOAD,
  SEGMENT_STATUSES,
}
