import one from './1.png'
import two from './2.png'
import three from './3.png'
import four from './4.png'
import five from './5.png'
import six from './6.png'
import seven from './7.png'
import eight from './8.png'
import nine from './9.png'
import ten from './10.png'

export default [one, two, three, four, five, six, seven, eight, nine, ten]
