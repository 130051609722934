import React, { PureComponent } from 'react'
import Verified from 'images/verified.svg'
import Select from 'react-select'
import { StoreSelectOption, SingleValue } from 'components/Common'

class SelectBrand extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      brandSelected: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.brandSelectRef = React.createRef()
  }

  handleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ brandSelected: true })
      this.props.onChangeBrand([selectedOption])
    } else {
      this.props.onChangeBrand(null)
      this.setState({ brandSelected: false })
    }
  }

  render() {
    return (
      <div className="brand_modal">
        <div className="info">
          <h5>Hi, you are currently managing multiple brands.</h5>
          <h5>
            Kindly, select one brand from the list below to view your
            advertisement and other information.
          </h5>
        </div>
        <div className="brands">
          <div className="brand_view">
            <div className="brand_heading">
              <div className="text">Select Brands:</div>
              {this.state.brandSelected ? (
                <div className="brand_done">
                  DONE
                  <img className="icon_img" src={Verified} />
                </div>
              ) : null}
            </div>
            <div className="select_brand">
              <Select
                options={this.props.brands.map((brand) => ({
                  label: brand.name,
                  value: brand.brandId,
                  brandLogo: brand.brandLogo,
                }))}
                placeholder="Select the brand to view their advertisement"
                required={true}
                ref={this.brandSelectRef}
                isClearable
                components={{ Option: StoreSelectOption, SingleValue }}
                isSearchable
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectBrand
