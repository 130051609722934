import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cloneDeep, isArray } from 'lodash'
import * as moment from 'moment'

import { useToasts } from 'components/Common'
import { useRouter, useSelectStore } from 'hooks'
import {
  postCampaign,
  setCampaignData,
  updateCampaign,
  getCampaignById,
  setCampaignLoader,
} from 'redux/actions/autoEngageActions'

import { validateScheduleCampaignDateTime } from '../advert/index'
import { ERROR_DECODE, DIFF_OBJ, FILTER_EMPTY } from '../constants'

export const useCampaignApi = (form, setForm, initState, type) => {
  const { toast } = useToasts()
  const { history, location } = useRouter()
  const dispatch = useDispatch()

  const [campaignId, setCampaignId] = useState('')
  const isView = location.pathname?.split('/')[4]
  const hideSteps = ['live', 'unpublished'].includes(form?.status) || isView

  // Redux Store
  const {
    data: { aeCampaignData },
  } = useSelectStore('autoEngage')
  const {
    data: { userId },
  } = useSelectStore('auth.user')
  const { data } = useSelectStore('vendorIds.selectedVendor.value')
  const {
    data: { companies },
  } = useSelectStore('company')
  const vendorId = data

  // Fitered Body
  const filteredBody = FILTER_EMPTY(DIFF_OBJ(form, aeCampaignData))

  const fetchCampaignById = (campId = campaignId) =>
    dispatch(getCampaignById(campId))

  useEffect(() => {
    dispatch(setCampaignLoader(false))
    return () => {
      dispatch(setCampaignData({}))
      setForm({})
    }
  }, [])

  useEffect(() => {
    if (
      campaignId &&
      campaignId !== 'create' &&
      Object.keys(aeCampaignData || {})?.length === 0
    ) {
      fetchCampaignById()
    }
    let tempForm = {
      ...form,
      vendorId,
      companyId: companies?.length === 1 ? companies[0]?.companyId : '',
    }
    if (aeCampaignData) {
      tempForm = { ...tempForm, ...aeCampaignData }
    }
    setForm(tempForm)
  }, [campaignId, vendorId, aeCampaignData])

  useEffect(() => {
    const routeId = location.pathname.split('/')[3]
    if (!routeId || routeId === 'create') {
      dispatch(setCampaignData({}))
      setForm(initState(userId, vendorId, type))
    }
    setCampaignId(routeId)
    if (
      routeId &&
      routeId !== 'create' &&
      Object.keys(aeCampaignData || {})?.length > 0
    ) {
      fetchCampaignById(routeId)
    }
  }, [location])

  const getDataBody = () => {
    let assetsDetails = filteredBody?.content?.body?.assetsDetails || null
    assetsDetails = isArray(assetsDetails)
      ? assetsDetails?.map((asset) => {
          delete asset.description
          delete asset.fileLocation
          return asset
        })
      : null

    const refinedBody = {
      ...filteredBody,
      content: {
        ...filteredBody?.content,
        body: {
          ...filteredBody?.content?.body,
          assetsDetails,
        },
      },
    }

    delete refinedBody?.content?.body?.variables
    delete refinedBody?.error

    !isArray(assetsDetails) && delete filteredBody?.content?.body?.assetsDetails
    let dataBody = assetsDetails ? refinedBody : filteredBody

    delete dataBody?.content?.body?.selectedSurveyIds

    if (dataBody?.startDate || dataBody?.scheduledTime) {
      const [hours, minutes] = form.scheduledTime?.split(':')
      const [startDate, scheduledTime] = moment(form.startDate)
        ?.set({ hours, minutes })
        ?.utc()
        ?.format('YYYY-MM-DD/HH:mm')
        ?.split('/')
      dataBody = { ...dataBody, startDate, scheduledTime }
    }

    if (dataBody.activateAt === null) dataBody.activateAt = ''
    if (dataBody.deactivateAt === null) dataBody.deactivateAt = ''

    if (dataBody?.content?.body?.templateParams?.length) {
      const array = dataBody?.content?.body?.templateParams || []
      const check =
        array.length === Object.values(array).length &&
        !array.includes(undefined) &&
        !array.includes(null)
      if (!check) throw 'Please fill all attributes before exiting.'
    }

    return dataBody
  }

  const getResult = (resData) => {
    let result = cloneDeep(resData)
    if (result.startDate && result.scheduledTime) {
      const [hours, minutes] = result.scheduledTime?.split(':')
      const [startDate, scheduledTime] = moment
        .utc(result.startDate)
        ?.set({ hours, minutes })
        ?.local()
        ?.format('YYYY-MM-DD/HH:mm')
        ?.split('/')
      result = { ...cloneDeep(resData), startDate, scheduledTime }
    }
    return result
  }

  const updCampaign = async (status = 'draft', exit = false) => {
    dispatch(setCampaignLoader(true))
    try {
      const dataBody = getDataBody()
      const res = await updateCampaign(campaignId, {
        ...dataBody,
        status,
        vendorId,
      })
      if (res.data) {
        ;(exit || status === 'live') &&
          history.push(`/auto-engage/${form.type}`)
        dispatch(setCampaignData(cloneDeep(getResult(res.data))))
        dispatch(setCampaignLoader(false))
      } else {
        dispatch(setCampaignLoader(false))
        throw res
      }
    } catch (err) {
      dispatch(setCampaignLoader(false))
      toast(ERROR_DECODE(err), 'error')
      throw ERROR_DECODE(err)
    }
  }

  const createCampaign = async (exit) => {
    if (!(filteredBody.companyId && filteredBody.name)) {
      history.push(`/auto-engage/${form.type}`)
      return
    }
    dispatch(setCampaignLoader(true))
    try {
      const res = await postCampaign(filteredBody)
      if (res.data) {
        const { _id } = res.data
        exit
          ? history.push(`/auto-engage/${form.type}`)
          : history.push(`/auto-engage/${form.type}/${_id}`)
        dispatch(setCampaignLoader(false))
        return 'success'
      } else {
        dispatch(setCampaignLoader(false))
        throw res
      }
    } catch (err) {
      dispatch(setCampaignLoader(false))
      toast(ERROR_DECODE(err), 'error')
      throw ERROR_DECODE(err)
    }
  }

  const onSave = async () => {
    const copyFilteredBody = cloneDeep(filteredBody)

    if (copyFilteredBody?.activateAt) delete copyFilteredBody?.activateAt
    if (copyFilteredBody?.deactivateAt) delete copyFilteredBody?.deactivateAt

    Object.keys(copyFilteredBody)?.length > 0
      ? campaignId && campaignId !== 'create'
        ? await updCampaign('draft', true)
        : await createCampaign(true)
      : history.push(`/auto-engage/${form.type}`)
  }

  const onPublish = async () => {
    const isScheduleErrorPresent = validateScheduleCampaignDateTime(form)

    if (isScheduleErrorPresent !== false) {
      toast(isScheduleErrorPresent, 'error')
      return
    }

    try {
      campaignId && (await updCampaign('live', true))
      toast(
        'Campaign will be deactivated automatically if embedded coupons are totally used',
        'warning'
      )
    } catch (e) {
      console.error(e)
    }
  }

  const body = { ...filteredBody }
  return {
    createCampaign,
    updCampaign,
    onSave,
    onPublish,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  }
}
