import React from 'react'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

import { useSelectStore, usePrevious, useDebouncedEffect } from 'hooks'
import { setSelectedBrand } from 'redux/actions/vendorActions'
import { setProductAnalyticsStores } from 'redux/actions/productAnalyticsActions'

/**
 * hook to handle async calls on mount/update
 * @param {Function} renderAction store action function
 * @param {Array} dependencies hook dependency array
 * @returns Array with loading state and async action
 */
const useAsyncRender = ({ renderAction, dependencies = [] }) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()

  const fetchAction = async (...rest) => {
    setLoading(true)
    try {
      await dispatch(renderAction(...rest))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const callbackAction = React.useCallback(fetchAction, [...dependencies])

  useDebouncedEffect(callbackAction, [...dependencies], 100)

  return [loading, fetchAction]
}

/**
 * @description brand select hook
 * @returns Object with available brands,
 * selected brand and select handler function
 */
const useBrandSelect = () => {
  const dispatch = useDispatch()
  const { data: availableBrands } = useSelectStore('vendorIds.vendorBrands')
  const { data: selectedBrand } = useSelectStore('vendorIds.selectedBrand')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')

  const {
    data: { stores },
  } = useSelectStore('stores')

  const setBrand = (brand) => {
    dispatch(setSelectedBrand(brand))
  }

  React.useEffect(() => {
    dispatch(setProductAnalyticsStores(null))
    let brandValue = selectedBrand

    if (!selectedBrand && availableBrands?.length) {
      const firstBrand = availableBrands[0]
      brandValue = firstBrand
      setBrand(firstBrand)
    }

    setSelectedStores(brandValue?.value)
  }, [availableBrands]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(
    () => () => {
      dispatch(setProductAnalyticsStores(null))
    },
    []
  )

  const setSelectedStores = (brandId) => {
    const storesDetails = Object.values(stores)
    const storeIds = []
    for (const store of storesDetails) {
      if (
        store.brandId === brandId &&
        store.vendorId === selectedVendor?.value
      ) {
        storeIds.push(store.storeId)
      }
    }

    if (!storeIds.length) {
      for (const store of storesDetails) {
        if (
          store.brandId === brandId &&
          store.vendorId === selectedVendor?.value
        ) {
          storeIds.push(store.storeId)
        }
      }
    }

    dispatch(setProductAnalyticsStores(storeIds))
  }

  const handleBrandChange = (value) => {
    setBrand(value)
    setSelectedStores(value?.value, false)
  }

  return {
    availableBrands,
    selectedBrand,
    handleBrandChange,
  }
}

export { useAsyncRender, useBrandSelect }
