export const TOTAL_NUMBER_COLLECTED = 'SET_CONSUMER_ANALYTICS_NUMBERS_COLLECTED'
export const CONSUMER_DEVICES_STATS = 'SET_CONSUMER_DEVICES_STATS'
export const CONSUMER_FEEDBACK_STARS = 'SET_CONSUMER_FEEDBACK_STARS'
export const TOP_CUSTOMERS_LIST = 'SET_TOP_CUSTOMERS_LIST'
export const CONSUMER_STATS = 'SET_CONSUMER_STATS'
export const CUSTOMERS_LIST = 'SET_CUSTOMER_LIST'
export const CONSUMER_SEGMENT_AVG_SPEND = 'SET_CONSUMER_SEGMENT_AVG_SPEND'
export const CONSUMER_SEGMENT_AVG_SKU = 'SET_CONSUMER_SEGMENT_AVG_SKU'
export const CONSUMER_SEGMENT_RETENTION = 'SET_CONSUMER_SEGMENT_RETENTION'
export const CONSUMER_SEGMENT_AGE = 'SET_CONSUMER_SEGMENT_AGE'
export const CONSUMER_SEGMENT_GENDER = 'SET_CONSUMER_SEGMENT_GENDER'
export const SET_CONSUMER_ANALYTICS_FILTERS = 'SET_CONSUMER_ANALYTICS_FILTERS'
export const ANALYTICS_SEGMENTS_LIST = 'SET_ANALYTICS_SEGMENTS_LIST'
export const SET_SELECTED_DATE_RANGE = 'SET_SELECTED_DATE_RANGE'
export const CONSUMER_SEGMENTS_COUNT = 'SET_CONSUMER_SEGMENTS_COUNT'
export const SET_PREVIOUS_SELECTED = 'SET_PREVIOUS_SELECTED'
export const SET_FETCH_NEW_DATA = 'SET_FETCH_NEW_DATA'
export const SELECTED_SEGMENTS = 'SET_SELECTED_SEGMENTS'
export const SET_CA_DATE = 'SET_CA_DATE'
export const SET_LOCATION_LIST = 'SET_LOCATION_LIST'
export const SET_SEGMENT_ANALYTICS_REGION = 'SET_SEGMENT_ANALYTICS_REGION'
export const SET_POS_DATA = 'SET_POS_DATA'
export const SET_STATES_CITIES = 'SET_STATES_CITIES'
