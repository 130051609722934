export const SET_LOAD_TABLE = 'SET_LOAD_TABLE'
export const SET_SCREEN_LOADING = 'SET_SCREEN_LOADING'

export const SET_COMPANY_LIST = 'SET_COMPANY_LIST'
export const SET_COMPANY_OPTIONS = 'SET_COMPANY_OPTIONS'
export const SET_COMPANY_TYPES = 'SET_COMPANY_TYPES'
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS'

export const SET_BRAND_LIST = 'SET_BRAND_LIST'
export const SET_BRAND_OPTIONS = 'SET_BRAND_OPTIONS'
export const SET_BRAND_CATEGORIES = 'SET_BRAND_CATEGORIES'
export const SET_BRAND_DETAILS = 'SET_BRAND_DETAILS'

export const SET_STORE_GROUP_LIST = 'SET_STORE_GROUP_LIST'
export const SET_STORE_GROUP_OPTIONS = 'SET_STORE_GROUP_OPTIONS'
export const SET_STORE_GROUP_DETAILS = 'SET_STORE_GROUP_DETAILS'

export const SET_STORES_LIST = 'SET_STORES_LIST'
export const SET_STORES_DETAILS = 'SET_STORES_DETAILS'
export const SET_STORE_KEYS_LIST = 'SET_STORE_KEYS_LIST'
export const SET_ALL_STORE_KEYS_LIST = 'SET_ALL_STORE_KEYS_LIST'
