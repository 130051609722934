import React, { useState } from 'react'
import { useEditor, useNode } from '@craftjs/core'

import { $FlexBox, $Block, $ToolGroup, $ToolItem } from '../../Editor.styled'
import PropertyHeader from '../../PropertyHeader'
import {
  PropertyBar,
  BorderRadius,
  Padding,
} from 'builder/editor/common/PropertyBar'
import { UploadOrUrl } from 'builder/editor/common/UploadOrUrlToggle'
import { CenterAlignIcon, LeftAlignIcon, RightAlignIcon } from 'assets/icons'
import { EmbedSurvey } from 'builder/editor/common/EmbedSurvey'
import { StyledInput, UploadButton } from 'builder/editor/common/Common.styled'

export const ImageSettings = () => {
  const { actions } = useEditor()
  const {
    id,
    props,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
  }))

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [isSurvey, setIsSurvey] = useState(
    (props.url || '').includes('$survey[')
  )

  const onSurveySelect = (survey) => {
    setProp((props) => {
      props.url = `$survey[${survey.surveyId}]`
      props.surveyName = survey.name
    }, 300)
    setIsPopOpen(false)
  }

  const setSurveyRadio = (bool) => {
    setIsSurvey(bool)
    setProp((props) => {
      props.url = ''
      props.surveyName = ''
    }, 0)
  }

  const commonProps = {
    craftProps: props,
    setProp,
    margin: '8px 0px',
  }

  return (
    <$Block padding="16px">
      <PropertyHeader
        header="Image"
        onDelete={() => actions.delete(id)}
        hideDelete={!props.isSocialImage}
      />
      <UploadOrUrl craftProps={props} setProp={setProp} />

      <$FlexBox justify="space-between">
        <p style={{ marginBottom: 0, fontSize: '12px' }}>Alignment</p>
        <$ToolGroup>
          <$ToolItem
            isActive={props.textAlign === 'left'}
            onClick={() => setProp((props) => (props.textAlign = 'left'), 300)}
          >
            <LeftAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'center'}
            onClick={() =>
              setProp((props) => (props.textAlign = 'center'), 300)
            }
          >
            <CenterAlignIcon />
          </$ToolItem>
          <$ToolItem
            isActive={props.textAlign === 'right'}
            onClick={() => setProp((props) => (props.textAlign = 'right'), 300)}
          >
            <RightAlignIcon />
          </$ToolItem>
        </$ToolGroup>
      </$FlexBox>

      <$FlexBox justify="space-between">
        <PropertyBar
          label="Width"
          propertyName="width"
          isFlex
          isPercent
          {...commonProps}
        />

        <PropertyBar
          label="Height"
          propertyName="height"
          isFlex
          isPercent
          {...commonProps}
        />
      </$FlexBox>

      <>
        <hr />
        <$FlexBox
          gap="20px"
          wrap="nowrap"
          onClick={() => setSurveyRadio(false)}
        >
          <input
            style={{ width: '20px', height: '20px' }}
            type="radio"
            checked={!isSurvey}
          />
          <PropertyBar
            margin="8px 0px"
            label="Redirect URL"
            propertyName="redirectUrl"
            craftProps={props}
            setProp={setProp}
            justify="space-between"
            placeholder="https://"
            isValueHidden={isSurvey}
          />
        </$FlexBox>

        <$FlexBox gap="20px" wrap="nowrap" onClick={() => setSurveyRadio(true)}>
          <input
            style={{ width: '20px', height: '20px' }}
            type="radio"
            readOnly
            checked={isSurvey}
          />
          <$Block width="100%">
            <$FlexBox padding="0 0 4px" justify="space-between">
              <small>Select Survey</small>
              <div>
                <UploadButton onClick={() => setIsPopOpen(true)}>
                  Survey
                </UploadButton>
              </div>
            </$FlexBox>
            <StyledInput
              readOnly
              style={{ width: '100%', background: '#D5D5D5' }}
              value={isSurvey ? props.surveyName : ''}
            />
          </$Block>
          <EmbedSurvey
            isPopOpen={isPopOpen}
            setIsPopOpen={setIsPopOpen}
            embeddedSurvey={props.url}
            onSurveySelect={onSurveySelect}
          />
        </$FlexBox>
        <hr />
      </>

      <PropertyBar
        label="Image Name"
        craftProps={props}
        setProp={setProp}
        propertyName="alt"
        justify="space-between"
        margin="8px 0px"
      />

      {BorderRadius(props, setProp, 'Image ')}
      {Padding(props, setProp)}
    </$Block>
  )
}
