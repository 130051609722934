import React from 'react'
import _ from 'lodash'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import { _selectOptionsGenerator } from 'utils/formatter'
import { utcToTimeStamp } from 'utils/getDateFormat'
import { borderRadius } from 'design-system'
import {
  GmailImage,
  MailchimpImage,
  MailgunImage,
  OutlookImage,
  SendgridImage,
  SesImage,
} from 'assets/emailProviders'
import {
  DigitalTag,
  EmailTag,
  ExternalTag,
  SmsTag,
} from 'assets/icons/survey-tags'
import {
  GET_SEL_VALUE,
  FILTER_EMPTY,
  DIFF_OBJ,
  CHECK_IF,
  ERROR_DECODE,
} from 'utils/reusables'

const moment = extendMoment(originalMoment)
// Steps
export const CAMP_STEPS = [
  'Campaign Details',
  'Message',
  'Whom to Send',
  'When to Send',
  'Preview & Publish',
]

export const SMS_CAMP_STEPS = [
  'Campaign Details',
  'Sender ID & Template',
  'Message',
  'Whom to Send',
  'When to Send',
  'Preview & Publish',
]

export const WHATSAPP_CAMP_STEPS = [
  'Campaign Details',
  'Select Template',
  'Message',
  'Whom to Send',
  'When to Send',
  'Preview & Publish',
]

export const SURVEY_STEPS = [
  'Campaign Details',
  'Survey Builder',
  'Survey Additional Settings',
  'Survey Preview',
]

export const AD_CAMP_STEPS = [
  'Campaign Details',
  'Advert Builder',
  'Advert Audience',
  'Preview & Publish',
]

export const SBB_CAMP_STEPS = [
  'Campaign Details',
  'PDF Upload',
  'Audience',
  'Preview & Publish',
]

export const SIB_CAMP_STEPS = [
  'Campaign Details',
  'Select Survey',
  'Survey Button Builder',
  'After Survey',
  'Audience',
  'Preview & Publish',
]

export const BANNER_CAMP_STEPS = [
  'Campaign Details',
  'Banner Builder',
  'Audience',
  'Preview & Publish',
]

export const POPUP_STEPS = [
  'Campaign Details',
  'Popup',
  'Popup Settings',
  'Campaign Settings',
  'Preview & Publish',
]

export const CONTENT_TYPES = [
  {
    label: 'Promotional',
    value: 'promotional',
  },
  {
    label: 'Service Explicit',
    value: 'serviceExplicit',
  },
]

export const END_DATE_SELECTION = [
  {
    label: 'Never',
    value: 'never',
  },
  {
    label: 'Till',
    value: 'till',
  },
]

export const WHEN_TYPES = [
  {
    label: 'Send Now',
    value: 'immediate',
  },
  {
    label: 'Send Later',
    value: 'oneTime',
  },
  {
    label: 'Recurring',
    value: 'recurring',
  },
]

export const WHOM_TYPES = (isSegmentationAllotted) => {
  const arr = [
    {
      label: 'Uploaded Data',
      value: 'userAssets',
    },
  ]
  if (isSegmentationAllotted) {
    arr.push({
      label: 'Segmentation',
      value: 'segments',
    })
  }
  return arr
}

export const SECURITY_PROTOCOL = [
  {
    label: 'TLS',
    value: false,
  },
  {
    label: 'SSL',
    value: true,
  },
]

export const WHEN_TYPES_FOR_CAMPAIGN = [
  {
    label: 'Publish Now',
    value: 'immediate',
  },
  {
    label: 'Schedule',
    value: 'schedule',
  },
]

export const GET_HOST_NAME = (provider) => {
  switch (provider) {
    case 'sendgrid':
      return 'smtp.sendgrid.net'
    case 'ses':
      return 'email-smtp.us-east-1.amazonaws.com'
    case 'gmail':
      return 'smtp.gmail.com'
    case 'outlook':
      return 'smtp.office365.com'
    case 'mailchimp':
      return 'smtp.mandrillapp.com'
    case 'mailgun':
      return 'smtp.mailgun.org'
    default:
      return ''
  }
}

export const EMAIL_PROVIDERS = [
  {
    label: 'SendGrid',
    value: 'sendgrid',
  },
  {
    label: 'SES',
    value: 'ses',
  },
  {
    label: 'Gmail',
    value: 'gmail',
  },
  {
    label: 'Outlook',
    value: 'outlook',
  },
  {
    label: 'Mailchimp',
    value: 'mailchimp',
  },
  {
    label: 'Mailgun',
    value: 'mailgun',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const HOURS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
]

export const MINUTES = new Array(60)
  .fill()
  .map((_, i) => (i < 10 ? '0' + i : i.toString()))

export const PERIOD = ['AM', 'PM']

export const REPEAT_EVERY = [
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
]

// Styles
export const POPUP_STYLE = {
  width: '80vw',
  maxHeight: '95vh',
  overflow: 'auto',
  borderRadius: borderRadius.default,
}

export const SEL_POPUP_STYLE = {
  borderRadius: borderRadius.default,
  overflow: 'hidden',
}

export const CANCEL_POP_STYLE = {
  width: '500px',
  borderRadius: borderRadius.default,
  overflow: 'hidden',
}

export const TODAY = moment(new Date()).format('YYYY-MM-DD')

// Functions
export const generateOptions = (size = 5, append = 0) => {
  return new Array(size).fill().map((_, index) => ({
    label: index + 1 + append,
    value: index + 1,
  }))
}

export const INIT_FILTER = {
  status: {
    id: 2,
    type: 'status',
    label: 'Campaign State',
    isActive: false,
    value: [],
  },
  timeFrame: {
    id: 3,
    type: 'timeFrame',
    label: 'Time Frame',
    isActive: false,
    value: moment.range(moment().clone().startOf('day'), moment().clone()),
  },
}
export const WA_FILTERS = {
  status: {
    id: 2,
    type: 'status',
    label: 'Template Status',
    isActive: false,
    value: [],
  },
}

export const AE_HEADER = [
  'Campaign Name',
  'Status',
  'Description',
  'Campaign Type',
  'Date Created',
  'Date Updated',
  'Actions',
]

export const COUPON_HEADER = [
  'Coupon Name',
  'Description',
  'Status (Campaign Type)',
  'Prefix',
  'Used Coupons',
  'Date Created',
  'Actions',
]

export const SURVEY_HEADER = [
  'Survey Name',
  '',
  'Description',
  'Date Created',
  'Date Updated',
  'Actions',
]
export const WHATSAPP_TEMPLATE_HEADER = [
  'Template ID',
  'Name',
  'Category',
  'Body',
  'Type',
  'Button',
  'Status',
  'Date Created',
  'Action',
]

export const UPLOADED_DATA_HEADER = [
  'File Name',
  'File Type',
  'Uploaded By (Via.)',
  'Uploaded Date',
  'Actions',
]

export const UPLOAD_TYPES = [
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'PDF', value: 'pdf' },
  { label: 'GIF', value: 'gif' },
  { label: 'CSV', value: 'csv' },
]

export const DEFAULT_CURRENT_TAB = {
  label: 'All Campaigns',
  value: 'all',
}

export const CAMP_TYPES = [
  { label: 'SMS', value: 'sms' },
  { label: 'E-Mail', value: 'email' },
  { label: 'WhatsApp', value: 'whatsApp' },
  { label: 'Banner In Bill', value: 'bannerInBill' },
  { label: 'Ad Below Bill', value: 'adBelowBill' },
  { label: 'Add Survey Button', value: 'surveyInBill' },
  { label: 'Sell Below Bill', value: 'sellBelowBill' },
  { label: 'Popup over Bill', value: 'popupOverBill' },
  { label: 'Multi Coupons', value: 'multiCoupons' },
]

export const SR_TYPES = [
  { label: 'All', value: '' },
  { label: 'SMS', value: 'sms' },
  { label: 'E-Mail', value: 'email' },
  { label: 'WhatsApp', value: 'whatsApp' },
  { label: 'Banner In Bill', value: 'bannerInBill' },
  { label: 'Ad Below Bill', value: 'adBelowBill' },
  { label: 'Add Survey Button', value: 'surveyInBill' },
  { label: 'Popup over Bill', value: 'popupOverBill' },
  { label: 'External', value: 'external' },
]

export const SR_TAGS = (type) => {
  switch (type) {
    case 'sms':
      return <SmsTag />
    case 'email':
      return <EmailTag />
    case 'surveyInBill':
    case 'bannerInBill':
    case 'adBelowBill':
    case 'popupOverBill':
      return <DigitalTag label={GET_SEL_VALUE(type, SR_TYPES)?.label} />
    case 'external':
      return <ExternalTag />
    default:
      return null
  }
}

export const CAMP_STATES = [
  { label: 'Draft', value: 'draft' },
  { label: 'Live', value: 'live' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Inactive - Unpublished', value: 'unpublished' },
  { label: 'Inactive - Unpublished For All', value: 'unpublishedForAll' },
]
export const WA_TEMP_STATES = [
  { label: 'Draft', value: 'draft' },
  { label: 'Live', value: 'live' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Pending', value: 'pending' },
]

export const USER_ATTRIBUTE_TYPES = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Phone Number',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Birth Date',
    value: 'birthDate',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'State',
    value: 'state',
  },
  {
    label: 'Company Name',
    value: 'companyName',
  },
  {
    label: 'Anniversary Date',
    value: 'anniversaryDate',
  },
  {
    label: 'Spouse First Name',
    value: 'spouseFirst',
  },
  {
    label: 'Spouse Last Name',
    value: 'spouseLast',
  },
]

const MONTH = [...[...new Array(31)].keys()].map((item) => ({
  label: ++item,
  value: item,
}))

export const MONTH_ARR = [
  ...MONTH,
  {
    label: 'Last day of the month',
    value: 'last day of month',
  },
]

export const WEEK_ARR = [
  {
    label: 'M',
    fullLabel: 'Monday',
    value: 0,
  },
  {
    label: 'T',
    fullLabel: 'Tuesday',
    value: 1,
  },
  {
    label: 'W',
    fullLabel: 'Wednesday',
    value: 2,
  },
  {
    label: 'T',
    fullLabel: 'Thursday',
    value: 3,
  },
  {
    label: 'F',
    fullLabel: 'Friday',
    value: 4,
  },
  {
    label: 'S',
    fullLabel: 'Saturday',
    value: 5,
  },
  {
    label: 'S',
    fullLabel: 'Sunday',
    value: 6,
  },
]

export const EMAIL_INIT = (userId, vendorId) => ({
  name: '',
  description: '',
  companyId: '',
  type: 'email',
  createdBy: userId,
  vendorId,
  senderDetails: {
    type: 'email',
    name: undefined,
  },
})

export const SMS_INIT = (userId, vendorId) => ({
  name: '',
  description: '',
  companyId: '',
  type: 'sms',
  createdBy: userId,
  vendorId,
  senderDetails: {
    type: 'sms',
    name: undefined,
  },
})

export const ASSETS_DETAIL_INIT = {
  key: '',
  fileLocation: '',
  redirectUrl: '',
  couponVisibility: false,
}

export const BUILDER_INIT = {
  componentType: 'image',
  couponId: '',
  couponPosition: 'bottomRight',
  assetsDetails: [ASSETS_DETAIL_INIT],
  couponFontSize: '12',
  couponFontColor: '#000',
  couponFontTypeface: 'Roboto',
}

export const POSITION_TYPES = [
  {
    value: 'topLeft',
    label: 'Top Left',
  },
  {
    value: 'topRight',
    label: 'Top Right',
  },
  {
    value: 'bottomLeft',
    label: 'Bottom Left',
  },
  {
    value: 'bottomRight',
    label: 'Bottom Right',
  },
  {
    value: 'center',
    label: 'Center',
  },
]

export const COUPON_FONT_SIZES = [
  {
    value: '8',
    label: '8px',
  },
  {
    value: '10',
    label: '10px',
  },
  {
    value: '12',
    label: '12px',
  },
  {
    value: '14',
    label: '14px',
  },
  {
    value: '16',
    label: '16px',
  },
]

export const COUPON_TYPEFACES = [
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'sans-serif',
    label: 'Sans Serif',
  },
  {
    value: 'Times New Roman',
    label: 'Times New Roman',
  },
]

export const COUPON_DISPLAY = [
  {
    value: 'all',
    label: 'All Images',
  },
  {
    value: 'chosen',
    label: 'Chosen Images',
  },
]

export const CAMPAIGN_STATS = [
  {
    value: 'campaignSent',
    label: 'Campaign Sent',
  },
  {
    value: 'linkVisits',
    label: 'No. of Link Clicks',
  },
  {
    value: 'totalSmsSent',
    label: 'No. of SMS sent',
  },
  {
    value: 'totalSmsDelivered',
    label: 'No. of SMS Delivered',
  },
  {
    value: 'totalSurveyVisits',
    label: 'No. of Survey Visits',
  },
  {
    value: 'buttonClicks',
    label: 'No. of Button Clicks',
  },
  {
    value: 'totalSurveySubmitted',
    label: 'No. of Surveys Submitted',
  },
  {
    value: 'totalCost',
    label: 'Campaign Cost',
  },
  {
    value: 'totalEmailSent',
    label: 'No. of E-Mail sent',
  },
  // {
  //   value: 'totalReportEntries',
  //   label: 'No. of Report Entries',
  // },
]

export const CAMPAIGN_FEATS = [
  {
    value: 'billCampaignCount',
    label: 'Campaign Count',
  },
  {
    value: 'billCampaignLimit',
    label: 'Campaign Limit',
  },
]

export const CAMP_INIT = (userId, vendorId, type) => ({
  name: '',
  description: '',
  companyId: '',
  type,
  createdBy: userId,
  vendorId,
})

export const EMAIL_PROVIDER_INIT = (userId, vendorId) => ({
  createdBy: userId,
  vendorId,
  secure: true,
  port: 465,
  provider: 'sendgrid',
  host: 'smtp.sendgrid.net',
})

export const GET_SEND_TIME = (obj) => {
  const atTime = utcToTimeStamp(obj?.scheduledTime)
  const time =
    atTime?.hours?.value +
    ':' +
    atTime?.minutes?.value +
    ' ' +
    atTime?.period?.value

  switch (obj?.validity) {
    case 'immediate':
      return 'Immediately'
    case 'oneTime':
      return `${moment(obj?.startDate)?.format('DD MMM YYYY')} at ${time}`
    case 'recurring':
      return `Every <b style="font-weight: bold;">${
        obj?.repeatEvery?.type
      }</b> on ${
        obj?.repeatEvery?.type === 'week'
          ? obj?.repeatEvery?.days
              ?.map((day) => GET_SEL_VALUE(day, WEEK_ARR)?.fullLabel)
              .join(', ')
          : obj?.repeatEvery?.days?.join(', ')
      }
        from <b style="font-weight: bold;">${moment(obj?.startDate)?.format(
          'DD MMM YYYY'
        )}</b> at <b style="font-weight: bold;">${time}</b> ${
        obj?.endDate
          ? `till <b style="font-weight: bold;">${moment(obj?.endDate)?.format(
              'DD MMM YYYY'
            )}</b>`
          : ''
      }`
    default:
      return null
  }
}

export const GET_FILE_TYPE = (componentType) => {
  switch (componentType) {
    case 'gif':
    case 'video':
    case 'carousel':
      return componentType

    default:
      return 'image'
  }
}

export const SUPPORTED_FORMATS = (componentType) => {
  switch (componentType) {
    case 'gif':
      return ['gif']

    case 'pdf':
    case 'document':
      return ['pdf']

    case 'csv':
      return ['csv']

    case 'video':
      return ['mp4', 'webm', 'mpeg', '3gp', 'flac', 'mov', 'avi']

    case 'carousel':
    case 'image':
      return ['png', 'bmp', 'jpg', 'jpeg', 'gif']

    case 'any':
      return [
        'mp4',
        'webm',
        'mpeg',
        '3gp',
        'flac',
        'mov',
        'avi',
        'png',
        'bmp',
        'jpg',
        'jpeg',
        'pdf',
        'gif',
        'csv',
      ]

    default:
      return ['png', 'bmp', 'jpg', 'jpeg']
  }
}

export const REAL_IMG_DIMENSION = async (img) => {
  if (img) {
    img.onload = function () {
      const naturalWidth = img.naturalWidth
      const naturalHeight = img.naturalHeight

      return { naturalWidth, naturalHeight }
    }
    return await img.onload()
  }
  return { naturalWidth: 0, naturalHeight: 0 }
}

export const GET_ASSET_LIST_COUNT = (mediaType, assetSet = {}) => {
  const {
    assetsImageList,
    assetsGifList,
    assetsVideoList,
    assetsPdfList,
    assetsCarouselList,
  } = assetSet
  switch (mediaType) {
    case 'image':
      return {
        assetsList: assetsImageList?.docs,
        assetsCount: assetsImageList?.count,
        assetsLimit: assetsImageList?.limit,
      }

    case 'carousel':
      return {
        assetsList: assetsCarouselList?.docs,
        assetsCount: assetsCarouselList?.count,
        assetsLimit: assetsCarouselList?.limit,
      }

    case 'gif':
      return {
        assetsList: assetsGifList?.docs,
        assetsCount: assetsGifList?.count,
        assetsLimit: assetsGifList?.limit,
      }

    case 'video':
      return {
        assetsList: assetsVideoList?.docs,
        assetsCount: assetsVideoList?.count,
        assetsLimit: assetsVideoList?.limit,
      }

    case 'pdf':
      return {
        assetsList: assetsPdfList?.docs,
        assetsCount: assetsPdfList?.count,
        assetsLimit: assetsPdfList?.limit,
      }

    default:
      return {
        assetsList: assetsImageList?.docs,
        assetsCount: assetsImageList?.count,
        assetsLimit: assetsImageList?.limit,
      }
  }
}

export const SMS_PREVIEW = (template = '', displayParams = []) => {
  const tempArray = template?.split('{#var#}') || []
  const tempString = tempArray?.map((sms, i) => (
    <>
      {sms}
      <b>{i !== tempArray.length - 1 ? displayParams[i] || '{ var }' : ''}</b>
    </>
  ))
  return tempString
}

export const WHATSAPP_PREVIEW = (template, displayParams = []) => {
  const bodyArray = template?.content?.body?.split('{{var}}') || []

  const tempString = bodyArray?.map((body, i) => (
    <>
      {body}
      <b>{i !== bodyArray.length - 1 ? displayParams[i] || '{{var}}' : ''}</b>
    </>
  ))

  template.content.body = tempString

  return template
}

export const EMAIL_PREVIEW = (emailString = '', variables = {}) => {
  const varsList = Object.keys(variables)

  emailString = emailString
    ?.toString()
    ?.replace(/\$attribute(.*?)\]/g, (attr) =>
      attr.replace('$attribute[', '{ ').replace(']', ' }')
    )

  varsList.forEach((variab) => {
    if (variab.includes('coupon[')) {
      const coupon = variables[variab]
      emailString = emailString
        ?.toString()
        ?.replace(/\$coupon(.*?)\]/g, `${coupon?.prefix}-00000`)
    }
  })
  return emailString
}

export const SORT_OPTIONS = [
  {
    id: 'newest',
    value: 'Newest First',
    sortOrder: -1,
  },
  {
    id: 'oldest',
    value: 'Oldest First',
    sortOrder: 1,
  },
]

export const SIB_INSTRUCTIONS = [
  '1. You can upload a PNG, JPG or a GIF format image as a button for survey.',
  '2. GIF images help animate the button to increase its click rate.',
  '3. The Image needs to have a fixed width of 375px.',
  '4. The Image height can be between 100px to 120px.',
]

export const SMS_ATTRIBUTES = (fromJourney = false) => [
  {
    label: 'User Attribute',
    value: 'attribute',
  },
  {
    label: 'Media Link',
    value: 'asset',
  },
  {
    label: 'Survey',
    value: 'survey',
  },
  {
    label: 'Coupon',
    value: 'coupon',
  },
  {
    label: 'Custom Attribute',
    value: 'custom',
  },
  ...(fromJourney
    ? [
        {
          label: 'Custom Link',
          value: 'customLinkType',
        },
      ]
    : []),
  {
    label: 'Blank',
    value: 'blank',
  },
]

export const WHATSAPP_ATTRIBUTES = (fromJourney) =>
  SMS_ATTRIBUTES(fromJourney).filter((x) => x.value !== 'blank')

export const EMAIL_PROVIDER_IMAGES = [
  {
    href: 'https://docs.sendgrid.com/for-developers/sending-email/drupal',
    src: SendgridImage,
    title: 'Send Grid',
  },
  {
    href: 'https://support.google.com/mail/answer/7126229',
    src: GmailImage,
    title: 'GMail',
  },
  {
    href: 'https://documentation.mailgun.com/en/latest/user_manual.html',
    src: MailgunImage,
    title: 'Mail Gun',
  },
  {
    href: 'https://docs.aws.amazon.com/ses/latest/DeveloperGuide/smtp-connect.html',
    src: SesImage,
    title: 'SES',
  },
  {
    href: 'https://mailchimp.com/developer/transactional/docs/smtp-integration/',
    src: MailchimpImage,
    title: 'Mail Chimp',
  },
  {
    href: 'https://support.microsoft.com/en-us/office/pop-imap-and-smtp-settings-for-outlook-com-d088b986-291d-42b8-9564-9c414e2aa040',
    src: OutlookImage,
    title: 'Outlook',
  },
]

export const SURVEY_BUTTON_TYPES = [
  {
    label: 'Default',
    value: false,
  },
  {
    label: 'Custom',
    value: true,
  },
]

export const UPLOAD_CSV_INSTRUCTIONS = [
  '1) The sample excel contains all headers relating to customer data upload.',
  '2) If you fill in only the email column that is enough data to send the mails.',
  '3) If for some reason you have filled other data with the email the mail will still be sent successfully.',
  '4) Please make sure you do not delete any headers or change their position or their text in the Sample CSV file.',
  '5) You can rename the file if you want to but do not change the file extension, only .csv files are accepted.',
]

export const UPLOADED_FILES_FROM = [
  ...CAMP_TYPES,
  {
    value: 'bank',
    label: 'Media Bank',
  },
  {
    value: 'journey',
    label: 'Journey',
  },
  {
    value: 'survey',
    label: 'Survey',
  },
]

export const COUPON_CANCEL_DETAILS = (action, isAssigned) => {
  switch (action) {
    case 'delete': {
      return {
        header: 'Delete Coupon - Are you sure?',
        title: isAssigned
          ? 'Campaigns using this coupons set will be unpublished for all and the coupons will be deleted from the system. Do you wish to continue?'
          : 'This coupon will be deleted from the system. Do you wish to continue?',
      }
    }

    case 'export': {
      return {
        header: 'Export Coupon - Disclaimer',
        title:
          'Please do not use coupons externally without marking it for external use.',
      }
    }

    case 'mark-external': {
      return {
        header: 'Mark Coupon for external use - Are you sure?',
        title:
          'Coupons marked for external use cannot be used to embed in Media. Do you wish to continue?',
      }
    }

    default:
      return {}
  }
}

export { GET_SEL_VALUE, FILTER_EMPTY, DIFF_OBJ, CHECK_IF, ERROR_DECODE }

export const DEFAULT_TEMPLATE_BODY = { body: '' }
