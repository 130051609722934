import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToasts } from 'components/Common'
import {
  getCoupons,
  setLoadTable,
  deleteCoupon,
  generateCoupon,
  updateCoupon,
} from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../constants'

export const useCouponApi = (
  vendorId,
  search,
  skip,
  couponDetails = {},
  isFilterExternalUse,
  setCouponDetails
) => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: 10,
  })

  const dispatch = useDispatch()
  const { toast } = useToasts()
  const debounceLoadData = useCallback(
    debounce((q) => setQuery(q), 750),
    []
  )

  useEffect(() => {
    query.vendorId && dispatch(getCoupons(query))
  }, [query])

  useEffect(() => {
    dispatch(setLoadTable(true))
    const tempQuery = { ...query, vendorId, skip, name: search }
    if (search.length === 0) {
      delete tempQuery.name
    }
    if (isFilterExternalUse) {
      tempQuery.externalUse = false
    }
    debounceLoadData(tempQuery)
  }, [vendorId, search, skip])

  const delCoupon = async () => {
    try {
      const res = await deleteCoupon(couponDetails?.id)
      if (res) {
        toast('Coupon deleted successfully', 'success')
        dispatch(getCoupons(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const updCoupon = async () => {
    try {
      const res = await updateCoupon(couponDetails?.id, { externalUse: true })
      if (res) {
        toast('Coupon marked for external use', 'success')
        dispatch(getCoupons(query))
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const exportCoupon = async () => {
    try {
      const res = await generateCoupon(couponDetails?.id)
      if (res) {
        downloadFile(couponDetails?.id, res)
        toast('Coupon exported successfully', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onActionChange = () => {
    switch (couponDetails?.action) {
      case 'export':
        return exportCoupon()

      case 'delete':
        return delCoupon()

      case 'mark-external':
        return updCoupon()
    }
  }

  useEffect(() => {
    onActionChange()
    if (setCouponDetails) {
      setCouponDetails({})
    }
  }, [couponDetails?.action])

  const downloadFile = (fileName, urlData) => {
    const aLink = document.createElement('a')
    aLink.download = 'Coupon ' + fileName + '.csv'
    aLink.href =
      'data:text/csv;charset=UTF-8,' + '\uFEFF' + encodeURIComponent(urlData)
    const event = new MouseEvent('click')
    aLink.dispatchEvent(event)
  }

  return null
}
