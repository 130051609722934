import { post } from 'utils/axiosHandler'

export const getCampaigns = async (data, options) => {
  return post('/advert/getAllAdverts', data, options)
}

export const getActiveCampaign = async (data, options) => {
  return post('/advert/getActiveCampaign', data, options)
}

export const getCampaign = async (data, options) => {
  return post('/advert/getCampaign', data, options)
}

export const updateCampaign = async (data, options) => {
  return post('/advert/updateCampaign', data, options)
}

export const activateCampaign = async (data, options) => {
  return post('/advert/activateCampaign', data, options)
}

export const deactivateCampaign = async (data, options) => {
  return post('/advert/deactivateCampaign', data, options)
}

export const deleteCampaign = async (data, options) => {
  return post('/advert/deleteCampaign', data, options)
}

export const addNew = async (data, options) => {
  return post('/advert/addNew', data, options)
}

export const modifyCampaign = async (data, options) => {
  return post('/advert/modifyCampaign', data, options)
}

export const selectBrandForAdvert = (data) => {
  return {
    type: 'SELECT_BRAND_FOR_ADVERT',
    payload: data,
  }
}
