import { cloneDeep } from 'lodash'

const initialState = {
  max: 0,
  min: 0,
  noOfTransactions: {
    total: 0,
    print: 0,
    BMprint: 0,
    BM: 0,
    CW: 0,
  },
  total: {
    avgBilling: 0,
    totalSales: 0,
  },
  transactionLoading: false,
  totalLoading: false,
  storeData: {
    loading: false,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_BILLING_MAX_MIN':
      return {
        ...state,
        max: action.payload.max,
        min: action.payload.min,
      }
    case 'GET_TOTAL_TRANSACTIONS':
      return {
        ...state,
        noOfTransactions: cloneDeep(action.payload.counts),
      }
    case 'GET_TRANSACTIONS_LOADING':
      return {
        ...state,
        transactionLoading: action.payload.transactionLoading,
      }
    case 'GET_TOTAL_LOADING':
      return {
        ...state,
        totalLoading: action.payload.totalLoading,
      }
    case 'GET_TOTAL_SALES_AND_AVG_BILLING':
      return {
        ...state,
        total: action.payload.counts,
      }
    default:
      return state
  }
}
