import * as types from 'redux/types/vendorIds'

export const setVendorIdsAvailable = (data) => {
  const vendorIds = data
    .map((vendor) => ({
      label: vendor.name,
      value: vendor.vendorId,
      featuresAlloted: vendor.featuresAlloted,
      registeredFrom: vendor.registeredFrom,
    }))
    .filter((vendor) => vendor.value)

  return {
    type: types.SET_VENDOR_IDS_AVAILABLE,
    payload: { vendorIds },
  }
}

export const setSelectedVendor = (data) => ({
  type: types.SET_SELECTED_VENDOR,
  payload: { selectedVendor: data },
})

export const setAvailableBrands = (data) => {
  return {
    type: types.SET_BRANDS_AVAILABLE,
    payload: { availableBrands: data },
  }
}

export const setVendorBrands = (data, vendorId, stores) => {
  const brandIds = Object.values(stores)
    .filter((item) => item.vendorId === vendorId)
    .map(({ brandId }) => brandId)
  const brands = data
    .filter(({ brandId }) => brandIds.includes(brandId))
    .map((brand) => ({
      label: brand.name,
      value: brand.brandId,
    }))
  return {
    type: types.SET_VENDOR_BRANDS,
    payload: { vendorBrands: brands },
  }
}

export const setSelectedBrand = (brandData) => ({
  type: types.SET_SELECTED_BRAND,
  payload: { selectedBrand: brandData },
})
