import React from 'react'
import EmojiPicker from 'emoji-picker-react'

import { RenderIf, Text } from 'components/Common'
import { Div, FlexBox } from 'styledComponent'
import {
  $ContentTextBlock,
  $UnderlineText,
  $ContentFooter,
  $ContentWrapper,
  $FloatingContainer,
} from './TemplateCampaign.styled'

const TemplateTextContent = ({
  text,
  label,
  onChange,
  onBlur,
  maxCharLength,
  optional,
  disabledVar,
  variant,
  margin,
  addVariable,
  hideCharCount,
  justify,
  emojiPicker,
  onEmojiClick,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false)

  const _getInputElement = (props = {}) => {
    switch (variant) {
      case 'input':
        return <input value={text} type="text" {...props} />

      case 'textarea':
        return <textarea value={text} rows="6" {...props} />
      default:
        return <input value={text} type="text" {...props} />
    }
  }

  return (
    <Div margin={margin || '0'}>
      <RenderIf active={!!label}>
        <Text variant="medium" type="main">
          {label}
          {optional ? (
            <Text variant="medium" type="grey" margin="0 0 0 8px">
              (Optional)
            </Text>
          ) : null}
        </Text>
      </RenderIf>
      <$ContentWrapper>
        <$ContentTextBlock>
          {_getInputElement({
            onChange,
            onBlur,
          })}
        </$ContentTextBlock>
        <$ContentFooter justify={justify}>
          <RenderIf active={!hideCharCount}>
            <Text variant="small" type="main">
              Character Count: {(text || '').length}/{maxCharLength}
            </Text>
          </RenderIf>
          <FlexBox>
            <RenderIf active={!!emojiPicker}>
              <$UnderlineText
                variant="h2"
                type="primary"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                margin="0 8px 2px"
                lineHeight="0"
              >
                {String.fromCodePoint(parseInt('263A', 16))}
              </$UnderlineText>
            </RenderIf>
            <RenderIf active={showEmojiPicker}>
              <$FloatingContainer>
                <EmojiPicker
                  skinTonesDisabled={true}
                  onEmojiClick={onEmojiClick}
                />
              </$FloatingContainer>
            </RenderIf>
            <RenderIf active={!disabledVar}>
              <$UnderlineText
                variant="small"
                type="primary"
                onClick={addVariable}
              >
                + <span>Add Variable</span>
              </$UnderlineText>
            </RenderIf>
          </FlexBox>
        </$ContentFooter>
      </$ContentWrapper>
    </Div>
  )
}

export default TemplateTextContent
