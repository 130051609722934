import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import styled from 'styled-components'

import { spacing } from 'design-system/styles'

const selectStyles = {
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ':hover': {
      backgroundColor: styles[':hover'].backgroundColor,
      cursor: 'pointer',
    },
  }),
}

const Option = ({ innerProps, isDisabled, data, isSelected }) => {
  return !isDisabled ? (
    <SelectorBlock {...innerProps}>
      <div className="custom-checkbox ml-5">
        <div>
          <input
            className="custom-control-input"
            type="checkbox"
            id={data.value}
            checked={isSelected}
          />
          <label className="custom-control-label" htmlFor={data.value} />
        </div>
      </div>
      {data.color && (
        <ColoredDot className="statusColor" bgColor={data.color} />
      )}
      <OptionLabel>{data.label}</OptionLabel>
    </SelectorBlock>
  ) : null
}

const IndicatorSeparator = () => null

/**
 * @description custom common multi-select component.
 * @param {Array<Object>} options - selectable options.
 * @param {Function} onChange - select change handler
 * @param {*} props - any additional props
 */
const MultiSelect = ({ options, onChange, ...props }) => {
  return (
    <Select
      className="select-box"
      styles={selectStyles}
      isMulti={true}
      onChange={onChange}
      options={options}
      components={{ Option, IndicatorSeparator }}
      isSearchable
      {...props}
    />
  )
}

MultiSelect.defaultProps = {
  hideSelectedOptions: false,
  placeholder: 'Select',
  closeMenuOnSelect: false,
}

MultiSelect.prototype = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
}

export default MultiSelect

const SelectorBlock = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.16);
  cursor: pointer;
  padding: ${spacing.m} 0;
  align-items: center;
`

const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 20px;
  background-color: ${(props) => props.bgColor};
`

const OptionLabel = styled.span`
  width: 80%;
  font-family: myHelvitikaFont;
`
