import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import {
  $AETableContainer,
  $Ellipsis,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import {
  TableLoader,
  TabRow,
} from 'components/Content/autoEngagement/campaigns/AETable'
import { Table, TableHead } from 'styledComponent'
import CancelPopup from 'components/Content/autoEngagement/components/CancelPopup'
import {
  deleteSmsHeaders,
  deleteSmsTemplates,
  getSmsHeaders,
  getSmsTemplates,
} from 'redux/actions/settingsActions'
import { useToasts, ActionCard } from 'components/Common'

const DLT_ROW = (item, onActionClick, heading) => {
  const dltRow = [
    <$Ellipsis>{item?.templateId || item?.headerId || '-'}</$Ellipsis>,
    <$Ellipsis>{item?.name || item?.senderId || '-'}</$Ellipsis>,
    <$Ellipsis title={item?.description || item?.template || '-'}>
      {item?.description || item?.template || '-'}
    </$Ellipsis>,
    item?.createdDate ? moment(item.createdDate).format('DD MMM YYYY') : '-',
    <ActionCard
      onIconClick={(action) => onActionClick(action, item)}
      components={['delete']}
      item={heading}
    />,
  ]
  if (heading === ' Template') {
    const headers = item?.headers?.join(', ') || '-'
    const status = item?.status === 'approved' ? 'Approved' : 'Header Pending'
    dltRow.splice(2, 0, headers, status)
  }
  return dltRow
}

export const DLTTable = ({
  header,
  list = [],
  tableLoading,
  vendorId,
  heading,
}) => {
  const { colors } = useTheme()
  const { toast } = useToasts()
  const dispatch = useDispatch()

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [headerDetails, setHeaderDetails] = useState({})

  const deleteHeader = async (smsHead) => {
    try {
      const res = await deleteSmsHeaders(vendorId, smsHead.senderId)
      if (res?.data) {
        toast('Header deleted successfully.', 'success')
        dispatch(getSmsHeaders())
      }
    } catch (err) {
      console.error(err)
    }
  }

  const deleteTemplate = async (smsTemplate) => {
    try {
      const res = await deleteSmsTemplates(vendorId, smsTemplate._id)
      if (res?.data) {
        toast('Template deleted successfully.', 'success')
        dispatch(getSmsTemplates())
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onActionClick = (action, item) => {
    switch (action) {
      case 'delete': {
        setIsPopOpen(true)
        setHeaderDetails({
          title: `Are you sure you want to delete the ${heading}?`,
          onOkAction: () =>
            heading === ' Sender ID'
              ? deleteHeader(item)
              : deleteTemplate(item),
        })
        return
      }
    }
  }

  return (
    <$AETableContainer p={0}>
      <Table margin="0" borderColor={colors.alto}>
        <TableHead>
          <TabRow align="center" fontSize="14px" cells={header} />
        </TableHead>
        <tbody>
          {tableLoading ? (
            <TableLoader tableRows={5} headerLength={header.length} />
          ) : list?.length > 0 ? (
            list?.map((item, i) => (
              <TabRow
                key={i}
                padding="0 12px"
                fontSize="12px"
                height="52px"
                highlightOnHover={colors.solitude}
                align="center"
                cells={DLT_ROW(item, onActionClick, heading)}
              />
            ))
          ) : (
            <TabRow
              align="center"
              colSpan={header.length}
              cells={[`No ${heading}s Found`]}
            />
          )}
        </tbody>
      </Table>

      <CancelPopup
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        title={headerDetails.title}
        onOkClick={headerDetails.onOkAction}
      />
    </$AETableContainer>
  )
}
