import React from 'react'
import { useTheme } from 'styled-components'
import cytoscape from 'cytoscape'
import cydagre from 'cytoscape-dagre'
import edgehandles from 'cytoscape-edgehandles'
import nodeHtmlLabel from 'cytoscape-node-html-label'

import { Div } from 'styledComponent'
import JourneyBG from 'assets/icons/journey-bg.svg'

cytoscape.use(cydagre)
cytoscape.use(edgehandles)
cytoscape.use(nodeHtmlLabel)

export const JourneyCanvas = ({ setZoom, isPreview, onDropElement }) => {
  const { spacing, colors, borderRadius } = useTheme()

  return (
    <Div
      position="relative"
      height={isPreview ? '532px' : '100%'}
      width={isPreview ? '100%' : 'calc(100% - 316px)'}
      p={spacing.l}
      backgroundColor={colors.lightBlue}
      borderRadius={borderRadius.default}
    >
      <Div
        height="100%"
        width="100%"
        backgroundImage={`url(${JourneyBG})`}
        backgroundColor={colors.white}
        id="cy"
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        onDropCapture={onDropElement}
      />
      <Div position="absolute" right="32px" bottom="32px">
        <svg
          width="29"
          height="52"
          viewBox="0 0 29 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 0H8C3.58172 0 0 3.58172 0 8V44C0 48.4183 3.58172 52 8 52H21C25.4183 52 29 48.4183 29 44V8C29 3.58172 25.4183 0 21 0Z"
            fill="white"
          />
          <path
            d="M21 1H8C4.13401 1 1 4.13401 1 8V44C1 47.866 4.13401 51 8 51H21C24.866 51 28 47.866 28 44V8C28 4.13401 24.866 1 21 1Z"
            stroke="#3B86FF"
            strokeWidth="2"
          />
          <path d="M2 25H27" stroke="#DADADA" />
          <path
            d="M20.671 13.4149H15.158V18.9269H13.321V13.4149H7.80901V11.5779H13.321V6.06592H15.158V11.5779H20.67L20.671 13.4149Z"
            fill="#3B86FF"
            onClick={() => setZoom((z) => z + 0.2)}
          />
          <path d="M8 38.349H20.862L20.861 36.512H8V38.349Z" fill="#3B86FF" />
          <rect
            y="26"
            width="29"
            height="26"
            fill="#C4C4C4"
            fillOpacity="0"
            type="button"
            onClick={() => setZoom((z) => z - 0.2)}
          />
          <rect
            width="29"
            height="25"
            fill="#C4C4C4"
            fillOpacity="0"
            type="button"
            onClick={() => setZoom((z) => z + 0.2)}
          />
        </svg>
      </Div>
    </Div>
  )
}
