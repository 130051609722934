import React, { useEffect, useRef, useCallback } from 'react'
import shortid from 'shortid'
import ReactDOM from 'react-dom'
import { ROOT_NODE } from '@craftjs/utils'
import { useNode, useEditor } from '@craftjs/core'

import { $Indicator, $Selector } from './Editor.styled'
import { MoveIcon, DeleteIcon, CopyIcon } from 'assets/icons'

export const RenderNode = ({ render }) => {
  const { actions, query } = useEditor()
  const {
    id,
    isActive,
    isHover,
    dom,
    moveable,
    deletable,
    connectors: { drag },
  } = useNode((node) => ({
    isActive: node.events.selected,
    isHover: node.events.hovered,
    dom: node.dom,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }))

  const currentRef = useRef()

  useEffect(() => {
    if (dom && id !== ROOT_NODE) {
      if (isActive || isHover) dom.classList.add('component-selected')
      else dom.classList.remove('component-selected')
    }
  }, [dom, isActive, isHover])

  const getPos = useCallback((dom) => {
    const { top, right, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 }
    return {
      top: `${top > 0 ? top : bottom}px`,
      right: `${right}px`,
    }
  }, [])

  const getCloneTree = useCallback((idToClone) => {
    const tree = query.node(idToClone).toNodeTree()
    const newNodes = {}

    const changeNodeId = (node, newParentId) => {
      const newNodeId = shortid()
      const childNodes = node.data.nodes.map((childId) =>
        changeNodeId(tree.nodes[childId], newNodeId)
      )
      const linkedNodes = Object.keys(node.data.linkedNodes).reduce(
        (accum, id) => {
          const latestNodeId = changeNodeId(
            tree.nodes[node.data.linkedNodes[id]],
            newNodeId
          )
          return {
            ...accum,
            [id]: latestNodeId,
          }
        },
        {}
      )

      let tmpNode = {
        ...node,
        id: newNodeId,
        data: {
          ...node.data,
          parent: newParentId || node.data.parent,
          nodes: childNodes,
          linkedNodes,
        },
      }
      let freshnode = query.parseFreshNode(tmpNode).toNode()
      newNodes[newNodeId] = freshnode
      return newNodeId
    }

    const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId])
    return {
      rootNodeId,
      nodes: newNodes,
    }
  }, [])

  const handleClone = (id) => {
    const theNode = query.node(id).get()
    const parentNode = query.node(theNode.data.parent).get()
    const indexToAdd = parentNode.data.nodes.indexOf(id)
    const tree = getCloneTree(id)
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1)
  }

  return (
    <>
      {isActive && id !== ROOT_NODE && (moveable || deletable)
        ? ReactDOM.createPortal(
            <$Indicator
              ref={currentRef}
              style={{
                left: getPos(dom).right,
                top: getPos(dom).top,
              }}
            >
              {moveable ? (
                <$Selector title="Move" isMove ref={drag}>
                  <MoveIcon />
                </$Selector>
              ) : null}
              <$Selector title="Replicate" onClick={() => handleClone(id)}>
                <CopyIcon />
              </$Selector>
              {deletable ? (
                <$Selector
                  title="Delete"
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    actions.delete(id)
                  }}
                >
                  <DeleteIcon />
                </$Selector>
              ) : null}
            </$Indicator>,
            document.body
          )
        : null}
      {render}
    </>
  )
}
