import React, { useEffect, useState } from 'react'

import SmsCampaign from './SmsCampaign'
import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'
import { SMS_CAMP_STEPS, SMS_INIT, CHECK_IF } from '../constants'
import { useCampaignApi } from '../components'
import { $WhiteContainer } from '../AutoEngagement.styled'

const SmsEngage = () => {
  const [smsForm, setSmsForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })
  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(smsForm, setSmsForm, SMS_INIT, 'sms')

  useEffect(() => {
    const step = hideSteps
      ? { id: 5, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [smsForm])

  const onNextClick = async () => {
    const updateCall = async () =>
      (await Object.keys(body)?.length) > 0 && campaignId && updCampaign()
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...smsForm }
        const check = CHECK_IF(
          ['name', 'vendorId', 'contentType', 'companyId'],
          campDetails
        )
        if (check) throw `Please enter ${check}`
        return (
          (await Object.keys(body)?.length) > 0 &&
          (campaignId && campaignId !== 'create'
            ? updCampaign()
            : createCampaign())
        )
      }

      case 2: {
        if (!smsForm?.senderDetails?.name) throw `Please enter Header`
        if (!smsForm?.content?.body?.templateId) throw `Please enter Template`
        return await updateCall()
      }

      case 3: {
        const array = smsForm?.content?.body?.templateParams || []
        if (array?.length) {
          const check =
            array.length === Object.values(array).length &&
            !array.includes(undefined) &&
            !array.includes(null)
          if (!check) throw `Please fill all attributes`
        }
        return await updateCall()
      }

      case 4: {
        const whomTo = { ...smsForm?.targetGroup }
        const check = CHECK_IF(['id', 'type'], whomTo)
        if (check) throw `Please choose ${check}`
        return await updateCall()
      }

      case 5: {
        const sendContent = { ...smsForm }
        if (!sendContent?.validity) throw `Please choose a Send type`

        switch (sendContent.validity) {
          case 'immediate': {
            return await updateCall()
          }

          case 'oneTime': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          case 'recurring': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (!(sendContent?.repeatEvery?.days?.length > 0))
              throw `Please select at least one day`
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          default:
            return null
        }
      }

      default:
        return
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - SMS"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={SMS_CAMP_STEPS}
        onPreviousClick={fetchCampaignById}
        onSave={onSave}
        onPublish={onPublish}
        hideSteps={hideSteps}
        form={smsForm}
        campaignType="SMS"
      >
        <SmsCampaign
          form={smsForm}
          setForm={setSmsForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}

export default SmsEngage
