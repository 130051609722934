import styled from 'styled-components'
import { spacing } from 'design-system'
import { borderRadius } from 'design-system/styles'
import { TableRow, Button, FlexBox } from 'styledComponent'

export const $Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  padding: 0 ${spacing.l};
`

export const $ContentDiv = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  align-items: ${({ align }) => align};
  margin: ${({ margin }) => margin};
`

export const $Button = styled.button`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.neutral[600]};
  border: 0;
  border-radius: ${borderRadius.small};
  display: flex;
  place-items: center;
  &:focus {
    outline: 0;
  }
`

export const $RemoveButton = styled(Button)`
  font-size: 12px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.error};
  &:focus {
    outline: 0;
  }
`

export const $TableRow = styled(TableRow)`
  cursor: pointer;
`

export const $RecentActivityPopupHeader = styled(FlexBox)`
  padding: 0px ${spacing.l};
  box-shadow: 0px 1px 4px 0px hsla(0 0% 20% / 0.2);

  p {
    margin: 0px;
  }
`

export const $RecentActivityPopupContainer = styled.div`
  padding: ${spacing.l};
  height: 400px;
  text-align: center;

  iframe {
    height: 100%;
  }
`
