import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FlexBox } from 'styledComponent'
import { Button } from 'components/Common'
import { spacing } from 'design-system'
import { LeftIcon } from 'assets/icons'
import checkbox from 'assets/icons/check-box.svg'
import { useToasts } from './Toast'

const $Icon = styled(LeftIcon)`
  width: 12px;
  height: 12px;
  ${(props) =>
    props.rotate &&
    css`
      transform: rotatey(180deg);
    `};
`

const $Steps = styled(FlexBox)`
  position: relative;
  text-align: center;
  hr {
    min-width: 20px;
    width: ${(props) => props.distance};
    margin: 10px;
  }
`

const $Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  ${(props) =>
    props.status === 'complete'
      ? css`
          background: url(${checkbox}) no-repeat center;
          background-size: contain;
        `
      : props.status === 'active'
      ? css`
          color: ${props.theme.colors.primary.default};
          border: 1px solid ${props.theme.colors.primary.default};
        `
      : css`
          color: ${props.theme.colors.grey};
          border: 1px solid ${props.theme.colors.grey};
        `};

  ::after {
    content: '${(props) => props.label}';
    color: ${(props) =>
      props.status === 'inactive'
        ? props.theme.colors.grey
        : props.theme.colors.dark};
    position: absolute;
    transform: translate(-50%, 20px);
    width: 140px;
  }
`

export const Steps = ({ distance, steps, stepClick }) => {
  return (
    <FlexBox expand gap={spacing.xxl} pb={spacing.xl} justify="center">
      <$Steps distance={distance}>
        {steps.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <hr dark={item.status} />}
            <$Circle
              status={item.status}
              label={item.label}
              onClick={() => stepClick(item)}
            >
              {item.status !== 'complete' && index + 1}
            </$Circle>
          </React.Fragment>
        ))}
      </$Steps>
    </FlexBox>
  )
}
