import { rootReducer } from './reducers/index.js'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

let composeArgs = [applyMiddleware(thunk)]
if (
  process.env.REACT_APP_ENV !== 'PROD' &&
  // process.env.REACT_APP_ENV !== 'TEST' &&
  window?.__REDUX_DEVTOOLS_EXTENSION__
) {
  composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

const storeEnhancers = compose(...composeArgs)

export const store = createStore(persistedReducer, {}, storeEnhancers)
export const persistor = persistStore(store)
