import styled, { css } from 'styled-components'

export const $SideItem = styled.div`
  padding: ${({ theme }) => `${theme.spacing.ml} ${theme.spacing.xl}`};
  color: ${({ theme }) => theme.colors.primary.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${props.theme.colors.solitude};
      color: ${props.theme.colors.pickledBluewood};
    `};
`
