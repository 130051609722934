import React from 'react'
import { useDispatch } from 'react-redux'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'

import { spacing } from 'design-system'
import {
  $DateSelector,
  $DateWrapperContainer,
} from './ConsumerAnalytics.styled'
import {
  /* setSelectedDate, */ setConsumerAnalyticsDate,
} from 'redux/actions/consumerAnalyticsActions'
import { useSelectStore, useVendorSelect } from 'hooks'
// import { setSelectedStore } from 'redux/actions/authActions'
import { FlexBox } from 'styledComponent'
// import { setSelectedVendor } from 'redux/actions/vendorActions'
import { DateOrMonthSelector } from 'components/Common'

export const VendorAndDateBar = ({}) => {
  const moment = extendMoment(originalMoment)
  const dispatch = useDispatch()
  const { data: selectedDate } = useSelectStore('cAnalytics.selectedDate')
  // const { data: stores } = useSelectStore('stores.stores')
  const { handleVendorChange } = useVendorSelect()
  const [showCalendar, setShowCalendar] = React.useState(false)

  /* React.useEffect(() => {

  },[selectedVendor]) */

  const handleOnClick = () => {
    setShowCalendar(!showCalendar)
  }

  /* const handleDateRangeChange = (values) => {
    setShowCalendar(!showCalendar)

    dispatch(
      setSelectedDate({
        start: values.start,
        end: values.end,
      })
    )
  } */

  const handleDateChange = (action) => {
    dispatch(setConsumerAnalyticsDate(action))
  }

  const handleMonthChange = (action) => {
    dispatch(setConsumerAnalyticsDate(action))
  }

  return (
    <FlexBox
      justify="space-between"
      align="center"
      p={`${spacing.m} ${spacing.l}`}
    >
      <$DateSelector>
        <$DateWrapperContainer>
          <DateOrMonthSelector
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
            handleMonthChange={handleMonthChange}
          />
          {/* <DatePickerWrapper onClick={handleOnClick}>
            {getFormattedDate(selectedDate?.start) +
              ' - ' +
              getFormattedDate(selectedDate?.end)}
          </DatePickerWrapper> */}
        </$DateWrapperContainer>
        {/* <$Calendar>
          <DateRangeSelector
            showCalendar={showCalendar}
            range={moment.range(
              originalMoment(selectedDate?.start).startOf('day'),
              originalMoment(selectedDate?.end).endOf('day')
            )}
            onSelect={handleDateRangeChange}
          />
        </$Calendar> */}
      </$DateSelector>
    </FlexBox>
  )
}
