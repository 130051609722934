import React from 'react'

import { Text } from 'components/Common/Text'
import {
  $ChartBlock,
  $ChartData,
  $ChartEnd,
  $ChartStart,
  $Label,
  $Labels,
} from './CombinationChart.styled'

/**
 * @description combination chart component
 */

const CombinationChart = ({
  width,
  height,
  startLabel,
  endLabel,
  startValue,
  endValue,
  ...props
}) => {
  return (
    <$ChartBlock width={width} {...props}>
      <$Labels>
        <$Label>
          <Text variant="p" align="right">
            {startLabel}
          </Text>
        </$Label>
        <$Label>
          <Text variant="p">{endLabel}</Text>
        </$Label>
      </$Labels>
      <$ChartData height={height}>
        <$ChartStart flexBasis={startValue} />
        <$ChartEnd flexBasis={endValue} />
      </$ChartData>
    </$ChartBlock>
  )
}

export default CombinationChart
