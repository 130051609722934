import React from 'react'
import { StarEmptyIcon, StarFilledIcon } from 'assets/icons'
import { $Rating } from '../Survey.styled'

export const Rating = (props) => {
  const num = props.optNumber >= 0 ? props.optNumber + 1 : props.hoverNumber
  return (
    <$Rating>
      {Number(num) < Number(props.option) ? (
        <StarEmptyIcon title={props.option} />
      ) : (
        <StarFilledIcon title={props.option} />
      )}
    </$Rating>
  )
}
