import React from 'react'
import { Div, FlexBox } from 'styledComponent'
import { $SelectCoupon } from '../AutoEngagement.styled'
import { Text } from 'components/Common'
import { useTheme } from 'styled-components'
import { useSelectStore } from 'hooks'
import { AsyncCouponSelect } from './index'

export const SelectCoupon = ({ onCouponSelect, selectedCouponId }) => {
  const { spacing } = useTheme()
  const { data } = useSelectStore('autoEngage.couponData.docs')

  return (
    <Div>
      <AsyncCouponSelect showMenu={false} />
      <hr />
      <Div height="250px" overflow="auto" my={spacing.l}>
        {data?.length > 0
          ? data?.map((coup) => (
              <$SelectCoupon
                p={spacing.m}
                key={coup._id}
                onClick={() => onCouponSelect(coup)}
              >
                <FlexBox gap={spacing.l}>
                  <input
                    readOnly
                    type="radio"
                    checked={selectedCouponId === coup._id}
                  />
                  <Div>
                    <Text type="main">{coup.name}</Text>
                    <FlexBox gap={spacing.l}>
                      <Text width="200px" variant="small" type="grey">
                        PREFIX : &nbsp;
                        <Text type="main" weight="bold">
                          {coup.prefix}
                        </Text>
                      </Text>
                      <Text variant="small" type="grey">
                        TOTAL COUPONS : &nbsp;
                        <Text type="main" weight="bold">
                          {coup.totalCoupons}
                        </Text>
                      </Text>
                    </FlexBox>
                  </Div>
                </FlexBox>
              </$SelectCoupon>
            ))
          : 'No Coupons Found'}
      </Div>
    </Div>
  )
}
