import React, { useState } from 'react'
import moment from 'moment'

import { post } from 'utils/axiosHandler'
import { spacing } from 'design-system'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import { $FlexBox } from '../../ProductAnalytics.styled'
import { $FlexList, $FlexListItem, $SquareButton } from '../AllProducts.styled'
import ProductsFilter from '../ProductsFilter'
import FilterPills from '../FilterPills'

/**
 * @description table top bar with different option controls
 */
const TableControls = ({ action, loading, vendor, brand, limit }) => {
  const filterRef = React.useRef()
  const showModal = () => filterRef.current.openModal()
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadFile = (fileName, urlData) => {
    const aLink = document.createElement('a')
    aLink.download = fileName
    aLink.href = urlData
    const event = new MouseEvent('click')
    aLink.dispatchEvent(event)
    setIsDownloading(false)
  }

  const downloadExcel = async () => {
    setIsDownloading(true)
    try {
      const res = await post('/product-management/products/list', {
        limit,
        vendorId: vendor?.value,
        brandId: brand?.value,
        csv: true,
      })

      downloadFile(
        `Product_Analytics_${moment().format('DD/MM/YYYY_HH-mm')}_${
          brand?.label
        }_BillMe.csv`,
        'data:text/csv;charset=UTF-8,' + '\uFEFF' + encodeURIComponent(res)
      )
    } catch (error) {
      setIsDownloading(true)
      console.error(error)
    }
  }

  return (
    <>
      <$FlexBox justify="space-between" m={`${spacing.l} 0 0`}>
        <FilterPills ref={filterRef} action={action} />
        <$FlexList>
          <$FlexListItem onClick={showModal}>
            <$SquareButton isFilter size={40}>
              <FilterIcon />
            </$SquareButton>
          </$FlexListItem>
          <$FlexListItem>
            <$SquareButton
              disabled={!Boolean(limit) || isDownloading || loading}
              onClick={downloadExcel}
              size={40}
            >
              <ExcelIcon />
            </$SquareButton>
          </$FlexListItem>
        </$FlexList>
      </$FlexBox>
      <ProductsFilter ref={filterRef} action={action} />
    </>
  )
}

export default TableControls
