import React from 'react'
import { GET_SEL_VALUE, OPTION_TYPES } from '../constants'
import { $SurveyInput } from '../Survey.styled'

export const SurveyInput = (props) => {
  return (
    <$SurveyInput
      width="250px"
      padding="10px"
      type={props.inputType}
      value={props.answer || ''}
      placeholder={'Enter ' + GET_SEL_VALUE(props.type, OPTION_TYPES)?.label}
    />
  )
}
