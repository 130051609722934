import React, { useCallback, useEffect, useState } from 'react'

import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import {
  Button,
  Input,
  useToasts,
  Text,
  PopupContainer,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { TopActionBar } from '../../components/TopActionBar'
import { useRouter, useSelectStore } from 'hooks'
import { $SearchInput } from '../../AutoEngagement.styled'
import { FlexBox } from 'styledComponent'
import { debounce, isEqual, cloneDeep } from 'lodash'
import { exportSurveyResponses } from 'redux/actions/autoEngageActions'
import { ERROR_DECODE } from '../../constants'

export const SRTopBar = ({ query, setQuery, totalDocs, isSIB }) => {
  const { history, location } = useRouter()
  const { toast } = useToasts()

  const { data: userEmail } = useSelectStore('auth.user.email')

  const [search, setSearch] = useState('')
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [exportDetails, setExportDetails] = useState({
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userEmail,
    emailList: [],
  })

  const debounceLoadData = useCallback(
    debounce((q) => setQuery(q), 750),
    []
  )

  const onEmailChange = (val) => {
    const copyDetails = cloneDeep(exportDetails)
    copyDetails['emailList'] = val
    setExportDetails(copyDetails)
  }

  useEffect(() => {
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const regPhone = /^[6-9]\d{9}$/

    let tempQuery = { ...query }

    if (regEmail.test(String(search).toLowerCase())) {
      tempQuery = {
        ...query,
        page: 1,
        email: search,
        phone: '',
      }
    } else if (Number(search) && regPhone.test(Number(search))) {
      tempQuery = {
        ...query,
        email: '',
        phone: search,
        page: 1,
      }
    } else {
      tempQuery = {
        ...query,
        email: '',
        phone: '',
        page: 1,
      }
    }

    !isEqual(tempQuery, query) && debounceLoadData(tempQuery)
  }, [search])

  const exportResponses = async () => {
    const surveyId = location?.pathname?.split('/')[4]
    const body = { ...exportDetails }

    body.emailList =
      body?.emailList?.filter(
        (email) =>
          email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
      ) || []

    if (query?.campaignType) {
      body.campaignType = query?.campaignType
    }

    if (body?.emailList?.length === 0) {
      delete body.emailList
    }

    try {
      const res = await exportSurveyResponses(surveyId, body)
      if (res) {
        toast(res.message, 'success')
      } else {
        throw { message: res.message }
      }
    } catch (err) {
      toast(ERROR_DECODE(err))
    }
    setIsPopOpen(false)
  }

  const extras = (
    <FlexBox align="center">
      <$SearchInput>
        <span />
        <input
          id="searchTxt"
          value={search}
          placeholder="Search by Phone Number or Email"
          onChange={(e) => setSearch(e.target.value)}
        />
      </$SearchInput>
      <Button
        variant="primary"
        startIcon={ExcelIcon}
        onClick={() => setIsPopOpen(true)}
        disabled={totalDocs === 0}
      >
        Export &nbsp;
      </Button>
    </FlexBox>
  )

  const onExit = () => {
    isSIB
      ? history.push(`/auto-engage/surveyInBill`)
      : history.push(`/auto-engage/surveys`)
  }

  return (
    <>
      <Navbar heading="Survey Responses" />
      <PopupContainer
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        heading="Export Survey Responses"
        onSubmitClick={exportResponses}
      >
        <Input
          variant="create-tags"
          label="E-Mail List"
          id="emailList"
          value={exportDetails.emailList}
          onChange={onEmailChange}
          placeholder={`Enter emails and press enter... (Maximum 10 emails)`}
        />
        <Text variant="medium" type="grey">
          If no email is provided, Survey responses will be sent to the
          registered email.
        </Text>
      </PopupContainer>
      <TopActionBar extras={extras} title="Survey Responses" onExit={onExit} />
    </>
  )
}
