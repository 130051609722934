import styled from 'styled-components'

import { spacing } from 'design-system'

const $ColumnBox = styled.div`
  border-right: 1px solid #f0f0f0;
  width: ${(props) => props.width || '30%'};
`

const $ColumnHead = styled.div`
  padding: 10px ${spacing.m};
  background-color: #f2f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 45px;
`

export { $ColumnBox, $ColumnHead }
