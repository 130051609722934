import React from 'react'
import { Table, TableHead } from 'styledComponent'
import { $AETableContainer } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { TabRow } from 'components/Content/autoEngagement/campaigns/AETable'
import { DLT_ERROR_ROW, TEMPLATE_ERROR_HEADER } from '../constants'

export const DLTErrorTable = ({ errorList = [] }) => {
  return (
    <$AETableContainer p={0}>
      <Table margin="0">
        <TableHead>
          <TabRow
            align="center"
            fontSize="14px"
            cells={TEMPLATE_ERROR_HEADER}
          />
        </TableHead>
        <tbody>
          {errorList.map((item) => (
            <TabRow
              key={item.rowNumber}
              padding="0 12px"
              fontSize="12px"
              height="52px"
              align="center"
              cells={DLT_ERROR_ROW(item)}
            />
          ))}
        </tbody>
      </Table>
    </$AETableContainer>
  )
}
