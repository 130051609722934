import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { SIBCampaign } from './SIBCampaign'
import { TopBarAndStepsWrapper } from '../components/TopBarAndStepsWrapper'

import { SIB_CAMP_STEPS, CAMP_INIT, CHECK_IF } from '../constants'
import { $WhiteContainer } from '../AutoEngagement.styled'
import { useCampaignApi } from '../components'

export const SurveyInBill = () => {
  const [sibForm, setSibForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(sibForm, setSibForm, CAMP_INIT, 'surveyInBill')

  useEffect(() => {
    const step = hideSteps
      ? { id: 5, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      Object.keys(body)?.length > 0 && campaignId && (await updCampaign())
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...sibForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          Object.keys(body)?.length > 0 &&
          (campaignId && campaignId !== 'create'
            ? await updCampaign()
            : await createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...sibForm?.content?.body }
        const check = CHECK_IF(['surveyId'], builderDetails)
        if (check) throw `Please enter ${check}`
        return await updateCall()
      }

      case 3: {
        const builderDetails = { ...sibForm?.content?.body }
        const check = CHECK_IF(['surveyButtonId'], builderDetails)
        if (check) throw `Please enter ${check}`
        return await updateCall()
      }

      case 4: {
        return await updateCall()
      }

      case 5: {
        const storeDetails = { ...sibForm?.targetGroup }
        if (storeDetails?.storeIds?.length < 1 || !storeDetails?.brandId)
          throw 'Please select a brand and at least one store.'

        if (!!sibForm?.turnOnCampaign) {
          if (!sibForm?.turnOffCampaign)
            throw 'Please also select Auto Turn Off Campaign along with Auto Turn On campaign'
          else {
            const turnOnTime = moment(sibForm?.activateAt)
            const turnOffTime = moment(sibForm?.deactivateAt)

            if (!turnOnTime.isBefore(turnOffTime)) {
              throw 'Scheduled End Date time should be after Scheduled Start Date time'
            }
          }
        }

        return await updateCall()
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - Add Survey Button"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={SIB_CAMP_STEPS}
        hideSteps={hideSteps}
        onSave={onSave}
        onPublish={onPublish}
        onPreviousClick={fetchCampaignById}
        form={sibForm}
        campaignType="Add Survey Button"
      >
        <SIBCampaign
          form={sibForm}
          setForm={setSibForm}
          active={currentStep.label}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}
