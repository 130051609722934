import { spacing } from 'design-system'
import styled from 'styled-components'

const $ClearBlock = styled.span`
  position: ${({ position }) => position || 'absolute'};
  top: ${spacing.xl};
  right: ${spacing.m};
  cursor: pointer;
`

const $AddAnotherBlock = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  cursor: pointer;
  svg {
    vertical-align: middle;
    width: 16px;
    height: 20px;
    polygon {
      fill: ${({ theme }) => theme.colors.primary.default};
    }
  }
`

const $AddNewCategory = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  cursor: pointer;
  margin-left: ${spacing.s};
`

const $PopupBody = styled.div`
  padding: ${spacing.l} ${spacing.l} ${spacing.xxl};
`

export { $ClearBlock, $AddAnotherBlock, $AddNewCategory, $PopupBody }
