import React, { Component } from 'react'

class BillingInfoCard extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }
  onClick() {
    this.props.onClick()
  }
  render() {
    return (
      <div
        className={
          'infoCard shadow p-3 mb-5 bg-white rounded ' + this.props.className
        }
        onClick={this.onClick}
      >
        <div className="cardContainer">
          <img
            src={require('' + this.props.imageName + '')}
            className="infoCardImage"
            alt=""
          />
          <div className="cardNameInfo">
            <span className="cardName1">{this.props.cardName}</span>
            <span className="cardName2">{this.props.cardName2}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default BillingInfoCard
