import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import cytoscape from 'cytoscape'
import { cloneDeep, isArray, isString } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { useRouter, useSelectStore } from 'hooks'
import { Div, FlexBox } from 'styledComponent'

import { Tabs, Text, useToasts } from 'components/Common'
import {
  $BlueBox,
  $CampaignContainer,
  $SmsPreview,
  $StatsTable,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import { PublishItem } from 'components/Content/autoEngagement/components/PublishItem'
import { JourneyTopBar } from './JourneyTopBar'
import { JourneyCanvas } from './journeyBuilder/JourneyCanvas'
import {
  CYTO_INIT,
  DAYS_OF_THE_WEEK,
  GET_DELAY_OPTIONS,
  GET_SUB_EVENT_LABEL,
  GET_SUB_EVENT_OPTIONS,
  NODE_HTML,
} from '../constants'
import {
  CAMP_TYPES,
  CHECK_IF,
  ERROR_DECODE,
  GET_SEL_VALUE,
  SMS_PREVIEW,
  UPLOAD_TYPES,
  WHATSAPP_PREVIEW,
} from 'components/Content/autoEngagement/constants'
import {
  getJourneyCampaign,
  getJourneyStats,
  setJourneyStats,
} from 'redux/actions/journeyActions'
import { LinkHoverRedirect } from 'components/Common/LinkHoverRedirect'
import { CampaignStats } from 'components/Content/autoEngagement/components/CampaignPreview'
import {
  getAssetsList,
  getSegments,
  getSurveyButtons,
  getWhatsAppTemplates,
} from 'redux/actions/autoEngageActions'
import { getSegmentDetails } from 'components/Content/autoEngagement/components/WhomToSend'
import { UploadBox } from 'components/Content/autoEngagement/components/MediaUpload'
import { getSmsTemplates } from 'redux/actions/settingsActions'
import SMSTemplateImg from 'assets/icons/mobile-template.svg'
import TemplatePreview from '../../autoEngagement/WhatsApp/Templates/TemplateCampaign/PreviewPanel'
import { get } from 'utils/axiosHandler'
import {
  $StoreTags,
  $Ellipsis,
} from '../../autoEngagement/AutoEngagement.styled'

let cy

export const JourneyPreview = ({
  isView,
  title,
  jbStats,
  jbDetails,
  allEvents,
  allFilters,
  updJourney,
  setJourneySteps,
}) => {
  const [zoom, setZoom] = useState(0.8)
  const [preview, setPreview] = useState({
    name: '',
    arr: [],
    assets: [],
  })

  const { data: allBrands } = useSelectStore('brand.brands')
  const { data: segmentData } = useSelectStore('autoEngage.segmentData')
  const { data: assetsList } = useSelectStore('autoEngage.assetsCsvList.docs')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')
  const { data: surveyButtonsData } = useSelectStore(
    'autoEngage.surveyButtonsData'
  )
  const { data: smsTemplatesList } = useSelectStore('settings.smsTemplatesList')
  const { data: whatsAppTemplates } = useSelectStore(
    'autoEngage.whatsAppTemplates.docs'
  )
  const { data: allStores } = useSelectStore('stores.stores')

  const { spacing } = useTheme()
  const { toast } = useToasts()
  const dispatch = useDispatch()
  const { query } = useRouter()

  const renderCytoscapeElement = () => {
    cy = cytoscape({
      container: document.getElementById('cy'),
      ...CYTO_INIT,
      elements: cloneDeep(
        jbDetails?.editorData || {
          nodes: [],
          edges: [],
        }
      ),
    })

    cy.on('tap', 'node', async (evt) => {
      const node = evt.target
      const nodeData = node?.data() || null

      const {
        arr = [],
        assets = [],
        smsPreview,
        emailPreview,
        whatsAppPreview,
      } = await previewValues(nodeData)
      setPreview({
        name: nodeData?.name,
        arr,
        assets,
        smsPreview,
        emailPreview,
        whatsAppPreview,
      })
    })

    NODE_HTML(cy, true, allEvents, allFilters)
    cy.zoom(zoom)
  }

  useEffect(renderCytoscapeElement, [jbDetails, zoom])

  useEffect(() => {
    jbDetails?.status !== 'draft' &&
      jbDetails?._id &&
      dispatch(getJourneyStats(jbDetails?._id))
    return () => dispatch(setJourneyStats({}))
  }, [jbDetails?._id])

  useEffect(() => {
    segmentData?.length === 0 && dispatch(getSegments({ vendorId }))
    assetsList?.length === 0 &&
      dispatch(
        getAssetsList({
          vendorId,
          listAll: true,
          fileType: 'csv',
        })
      )
  }, [])

  const onPublish = () => {
    try {
      const nonFreeNodes = cy
        .nodes()
        .filter((ele) =>
          ['time', 'start', 'customer'].includes(ele.data('nodeType'))
        )

      const bmNodes = cy.nodes("[nodeId = 'filter_billMonitoring']")

      const incompleteCustNodes = cy
        .nodes()
        .filter(
          (ele) =>
            ['filter_customerSegment', 'filter_customerSpecific'].includes(
              ele.data('nodeId')
            ) && !ele.data('customerId')
        )

      const incompleteActionNodes = cy
        .nodes()
        .filter(
          (ele) =>
            ['digital', 'party'].includes(ele.data('nodeType')) &&
            !ele.data('campaignId') &&
            ele.data('nodeId') !== 'filter_billMonitoring'
        )

      const timeNodes = cy
        .nodes()
        .filter((ele) => ele.data('nodeId') === 'action_waitDays')
        .map((ele) => ele.data('condition'))

      const specificTimeNode = cy.nodes("[subEvent = 'event_specificTime']")
      if (specificTimeNode?.length) {
        const stNode = specificTimeNode[0]
        const min = stNode?.data()?.condition?.min || {}
        const max = stNode?.data()?.condition?.max || {}
        const checkMin = CHECK_IF(['date', 'hours', 'minutes', 'period'], min)
        const checkMax = CHECK_IF(['date', 'hours', 'minutes', 'period'], max)
        if (checkMin || checkMax) {
          throw 'Some fields are missing in Event Occurence Component.'
        } else {
          const minDate = moment(
            `${min.date} ${min.hours}:${min.minutes} ${min.period}`
          ).toISOString()
          const maxDate = moment(
            `${max.date} ${max.hours}:${max.minutes} ${max.period}`
          ).toISOString()

          stNode.data({
            condition: {
              min: minDate,
              max: maxDate,
            },
          })
        }
      }

      if (cy.nodes().length < 3) {
        throw 'Your Journey is incomplete. Your journey must have at least 1 Event Occurence Component, 1 Customer Component & 1 Action Component.'
      }
      if (cy.nodes().length !== cy.edges().connectedNodes().length) {
        throw 'All Components must be connected in the Journey.'
      }
      if (incompleteActionNodes.length > 0) {
        throw `${incompleteActionNodes[0].data().name} component is incomplete.`
      }
      if (!timeNodes.every((nod) => !!nod)) {
        throw 'Time Regulator Component is incomplete.'
      }
      if (incompleteCustNodes.length > 0) {
        throw 'Please enter all the details in Customer component to proceed.'
      }
      if (bmNodes.leaves().length > 0) {
        throw 'Your Campaign is incomplete. Campaign cannot end with Bill Monitoring.'
      }
      if (nonFreeNodes.leaves().length > 0) {
        throw 'Your Campaign is incomplete. All your paths must end with Digital Bill/Engagement (3rd Party) Components.'
      }

      const eventNode = cy.nodes("[nodeId = 'event_occurence']")[0]
      const eventCheck = CHECK_IF(['event', 'subEvent'], eventNode?.data())
      if (eventCheck) {
        throw 'Please enter all the details in Event Occurence component to proceed.'
      }
      const structure = editorToStructure(eventNode)

      if (structure?.event_products) {
        const inputData = [
          {
            subEvent: 'event_productsQuantity',
            option: 'any',
            condition: {
              delay: 0,
            },
          },
          {
            subEvent: 'event_productsBought',
            option: 'any',
            condition: {
              delay: 0,
            },
          },
        ]

        if (structure.event_products?.inputData?.condition?.max) {
          inputData[0].option = 'specific'
          inputData[0].condition = {
            min: structure.event_products.inputData.condition?.min || 0,
            max: structure.event_products.inputData.condition?.max || 0,
          }
        }

        if (
          structure.event_products?.inputData?.condition?.productIds?.length > 0
        ) {
          inputData[1].option = 'specific'
          inputData[1].condition = [
            ...structure.event_products.inputData.condition.productIds,
          ]
        }

        structure.event_products.inputData = [...inputData]
      }
      // return console.log(eventNode, structure)

      updJourney(
        {
          structure,
          eventCategory: eventNode.data().event,
          eventId: eventNode.data().subEvent,
        },
        () => {},
        true
      )
    } catch (error) {
      toast(ERROR_DECODE(error), 'error')
    }
  }

  const editorToStructure = (node, structure = {}) => {
    const nodeData = node.data()
    const sourceEdges = node
      .connectedEdges()
      .filter((edge) => edge.data().source === node.data().id)
    let outcomeNodes = []
    if (sourceEdges.length > 0) {
      outcomeNodes = sourceEdges.map((ed) => ({
        outcome: ed.data()?.outcome?.value || 'OUTCOME_TRUE',
        target: ed.data()?.target,
        delay: ed.data()?.delay,
      }))
    }

    const outcomeStructure = outcomeNodes.map((nod) => {
      const outStr = editorToStructure(cy.getElementById(nod.target), structure)

      if (nodeData.nodeId === 'event_occurence') {
        return outStr
      }
      return { outcome: nod.outcome, outStr }
    })

    let structuredOutcomes = {}

    for (let i = 0; i < outcomeStructure.length; i++) {
      if (outcomeStructure[i]?.outcome) {
        structuredOutcomes[outcomeStructure[i]?.outcome] = {
          ...(structuredOutcomes[outcomeStructure[i]?.outcome] || {}),
          ...(outcomeStructure[i]?.outStr || {}),
        }
      } else {
        structuredOutcomes = { ...outcomeStructure[i] }
      }
    }

    let inputData = {}
    if (nodeData.option) inputData.option = nodeData.option
    inputData.condition = {
      ...(nodeData?.condition || {}),
      delay: nodeData?.delay || 0,
    }

    if (isArray(nodeData.condition)) {
      inputData.condition = [...(nodeData?.condition || [])]
    }

    if (isString(nodeData.condition)) {
      inputData.condition = nodeData?.condition
    }

    if (nodeData.nodeId === 'filter_customerSegment') {
      inputData.condition = {
        ...(inputData?.condition || {}),
        segmentId: nodeData.customerId,
      }
    }
    if (nodeData.nodeId === 'filter_customerSpecific') {
      inputData.condition = {
        ...(inputData?.condition || {}),
        assetId: nodeData.customerId,
      }
    }

    if (nodeData.nodeId === 'action_waitDays') {
      inputData.condition = {
        ...(inputData?.condition || {}),
        ...nodeData.condition,
      }
    }
    if (nodeData.campaignId) {
      inputData.condition = {
        ...(inputData?.condition || {}),
        campaignId: nodeData.campaignId,
      }
    }

    const structKey =
      nodeData.nodeId === 'event_occurence'
        ? nodeData.subEvent
        : nodeData.nodeId

    structure = {
      ...structure,
      [structKey]: {
        type: nodeData.nodeId.split('_').shift(),
        inputData,
        ...structuredOutcomes,
      },
    }
    return structure
  }

  const previewValues = async (nodeData) => {
    const getSubEventLabel =
      GET_SUB_EVENT_LABEL(allEvents, nodeData?.subEvent) || ''
    const getSubEventOptionLabel = GET_SUB_EVENT_OPTIONS(
      allEvents,
      nodeData?.subEvent
    )?.find((opt) => opt?.name === nodeData?.option)?.label

    switch (nodeData?.name) {
      case 'Event Occurence': {
        const arr = [
          {
            label: 'Event',
            value: nodeData?.event,
          },
          {
            label: 'Sub Event',
            value: getSubEventLabel,
          },
        ]
        if (nodeData?.option) {
          arr.push({
            label: 'Option',
            value: getSubEventOptionLabel,
          })
        }
        if (nodeData?.condition?.length) {
          let label = 'Condition'
          let value = nodeData?.condition

          if (nodeData?.option === 'daysOfWeek') {
            label = 'Days of Week'
            value = DAYS_OF_THE_WEEK?.filter((day) =>
              nodeData?.condition?.includes(day.value)
            )
              ?.map((day) => day.label)
              .join('\n')
          }

          arr.push({
            label,
            value,
          })
        }

        if (
          nodeData?.subEvent === 'event_products' &&
          nodeData?.condition?.productIds?.length
        ) {
          let label = 'Products Selected'
          let value = nodeData?.condition?.productLabels?.join('\n')
          arr.push({
            label,
            value,
          })
        }

        if (nodeData?.condition?.min) {
          let value = `${nodeData?.condition?.min} - ${nodeData?.condition?.max}`
          if (nodeData?.option === 'time') {
            value = `From : ${nodeData?.condition?.min?.date} ${nodeData?.condition?.min?.hours}:${nodeData?.condition?.min?.minutes} ${nodeData?.condition?.min?.period}\nTo : ${nodeData?.condition?.max?.date} ${nodeData?.condition?.max?.hours}:${nodeData?.condition?.max?.minutes} ${nodeData?.condition?.max?.period}`
          }
          arr.push({
            label: 'Condition',
            value,
          })
        }
        return { arr }
      }

      case 'Customer Segment': {
        const listItem = segmentData?.find(
          (list) => list._id === nodeData?.customerId
        )
        return { arr: getSegmentDetails(listItem, true) }
      }

      case 'Specific Customer': {
        const listItem = assetsList?.find(
          (list) => list._id === nodeData?.customerId
        )
        return { arr: getSegmentDetails(listItem, false) }
      }

      case 'Ad Below':
      case 'Sell Below':
      case 'Pop Over':
      case 'Survey':
      case 'Send SMS':
      case 'Send Email':
      case 'Send WhatsApp':
      case 'Banner': {
        const res = await getJourneyCampaign(nodeData?.campaignId)
        if (
          nodeData?.name === 'Survey' &&
          res?.data?.content?.body?.surveyButtonId
        ) {
          surveyButtonsData?.length === 0 &&
            (await dispatch(
              getSurveyButtons({
                vendorId,
                listAll: true,
              })
            ))
        }
        if (res?.data) {
          let arr = [
            {
              label: 'Campaign Name',
              value: res?.data?.name,
            },
          ]
          let assets = []
          let smsPreview = null
          let emailPreview = null
          let whatsAppPreview = null

          if (res?.data?.description) {
            arr = [
              ...arr,
              {
                label: 'Campaign Description',
                value: res?.data?.description,
              },
            ]
          }

          if (res?.data?.senderDetails) {
            arr = [
              ...arr,
              {
                label: 'Sender Details',
                value:
                  res?.data?.senderDetails?.name ||
                  res?.data?.senderDetails?.id,
              },
            ]
          }

          if (res?.data?.content?.subject) {
            arr = [
              ...arr,
              {
                label: 'Email Subject',
                value: res?.data?.content?.subject,
              },
            ]
          }

          if (res?.data?.targetGroup?.brandId) {
            arr = [
              ...arr,
              {
                label: 'Brand',
                value: allBrands.find(
                  (br) => res?.data?.targetGroup?.brandId === br.brandId
                )?.name,
              },
              {
                label: 'No. of Stores',
                value: res?.data?.targetGroup?.storeIds?.length || 0,
              },
            ]
          }

          if (res?.data?.content?.body?.componentType) {
            arr = [
              ...arr,
              {
                label: 'Media Type',
                value: GET_SEL_VALUE(res?.data?.content?.body?.componentType, [
                  ...UPLOAD_TYPES,
                  { value: 'carousel', label: 'Carousel' },
                ])?.label,
              },
              {
                label: 'Campaign Assets',
                value: res?.data?.content?.body?.assetsDetails
                  ?.map((asset) => asset?.name)
                  ?.join('\n'),
              },
            ]
            assets = res?.data?.content?.body?.assetsDetails?.map((asset) => {
              asset.fileType = res?.data?.content?.body?.componentType
              return asset
            })
          }

          if (nodeData?.name === 'Survey') {
            assets = surveyButtonsData
              ?.filter(
                (sbd) => res?.data?.content?.body?.surveyButtonId === sbd?._id
              )
              ?.map((item) => {
                item.fileLocation = item.button
                return item
              })
          }

          if (nodeData?.name === 'Send SMS') {
            const templateRes = await get(
              `/settings/${vendorId}/sms-templates`,
              {
                _id: res?.data?.content?.body?.templateId,
              }
            )

            smsPreview = {
              preview: SMS_PREVIEW(
                templateRes?.data?.[0]?.template,
                res?.data?.content?.body?.displayParams
              ),
              senderName: res?.data?.senderDetails?.name,
            }
          }

          if (
            nodeData?.name === 'Send Email' &&
            res?.data?.content?.body?.html
          ) {
            emailPreview = res?.data?.content?.body?.html
          }

          if (nodeData?.name === 'Send WhatsApp') {
            const tempId = res?.data?.content?.body?.templateId
            const templateRes = await get(
              `/auto-engage/whatsapp-services/${vendorId}/templates/list`,
              { _id: tempId }
            )

            if (res.data) {
              whatsAppPreview = {
                data: WHATSAPP_PREVIEW(
                  templateRes.data?.docs?.[0],
                  res?.data?.content?.body?.displayParams
                ),
              }
            }
          }

          return { arr, assets, smsPreview, emailPreview, whatsAppPreview }
        }
        return {}
      }

      case 'Wait For Some Time': {
        return {
          arr: [
            {
              label: 'Event',
              value: nodeData?.event,
            },
            {
              label: 'Option',
              value:
                GET_DELAY_OPTIONS(allFilters)?.find(
                  (opt) => opt?.name === nodeData?.option
                )?.label || '-',
            },
            {
              label: 'Wait For' + nodeData?.option,
              value: `${nodeData?.condition?.value} ${nodeData?.option}`,
            },
          ],
        }
      }

      case 'Bill Monitor': {
        return {
          arr: [
            {
              label: 'Bill Monitoring',
              value: '',
            },
          ],
        }
      }

      default:
        return {}
    }
  }

  return (
    <Div>
      <JourneyTopBar
        title={title}
        progress="100%"
        onPreviousClick={() => setJourneySteps(3)}
        onPublishClick={onPublish}
        isView={isView}
        isPublished={jbDetails?.status === 'live'}
      />
      <$CampaignContainer m={spacing.l}>
        <Tabs>
          <div label="Journey Overview" />
          {jbDetails?.status !== 'draft' && <div label="Journey Statistics" />}
        </Tabs>
        <Div
          pt={spacing.l}
          display={query?.tab === 'Journey Statistics' ? 'none' : 'block'}
        >
          <FlexBox width="100%" justify="space-between" gap="1%">
            <Div width="49%">
              <PublishItem width="100%" header="Campaign Details">
                <FlexBox gap="10px" padding="10px 0 5px" align="center">
                  <Text width="125px" variant="small" type="grey">
                    Campaign Name :
                  </Text>
                  <Text width="200px" variant="small" type="main">
                    {jbDetails?.name}
                  </Text>
                </FlexBox>
                <FlexBox gap="10px" align="center">
                  <Text width="125px" variant="small" type="grey">
                    Campaign Description :
                  </Text>
                  <Text variant="small" type="main">
                    {jbDetails?.description || '-'}
                  </Text>
                </FlexBox>
                <FlexBox gap="10px" padding="5px 0" align="center">
                  <Text width="125px" variant="small" type="grey">
                    Start Date :
                  </Text>
                  <Text variant="small" type="main">
                    {moment(jbDetails?.startDate || new Date()).format(
                      'DD-MM-YYYY hh:mm A'
                    )}
                  </Text>
                </FlexBox>
                <FlexBox gap="10px" align="center">
                  <Text width="125px" variant="small" type="grey">
                    End Date :
                  </Text>
                  <Text variant="small" type="main">
                    {moment(jbDetails?.endDate || new Date()).format(
                      'DD-MM-YYYY hh:mm A'
                    )}
                  </Text>
                </FlexBox>
              </PublishItem>
            </Div>
            <Div width="49%">
              <PublishItem width="100%" header="Audience">
                <FlexBox gap="10px" padding="10px 0 5px" align="center">
                  <Text width="75px" variant="small" type="grey">
                    Brand Name :
                  </Text>
                  <Text variant="small" type="main">
                    {allBrands.find((br) => jbDetails?.brandId === br.brandId)
                      ?.name || '-'}
                  </Text>
                </FlexBox>
                <FlexBox gap="10px" align="center">
                  <Text width="50px" variant="small" type="grey">
                    Stores :
                  </Text>
                  {/* <Text variant="small" type="main">
                      {jbDetails?.storeIds?.length || 0} stores selected
                    </Text> */}
                  <FlexBox
                    wrap="wrap"
                    maxHeight="300px"
                    overflowY="auto"
                    expand
                  >
                    {Object.values(allStores)
                      ?.filter((sto) =>
                        jbDetails?.storeIds?.includes(sto.storeId)
                      )
                      ?.map((str) => (
                        <$StoreTags key={str.displayAddress}>
                          {str.displayAddress}
                        </$StoreTags>
                      ))}
                  </FlexBox>
                </FlexBox>
              </PublishItem>
            </Div>
          </FlexBox>
          <hr />
          <$BlueBox>
            <FlexBox>
              <Div width="70%">
                <Text
                  variant="h3"
                  weight="bold"
                  type="main"
                  ml={spacing.l}
                  mb={spacing.m}
                >
                  Journey Overview
                </Text>
                <JourneyCanvas setZoom={setZoom} isPreview />
              </Div>
              <Div width="30%">
                <Text variant="h3" weight="bold" type="main">
                  Preview Pane
                </Text>
                <Text variant="small" type="main" mb={spacing.m}>
                  (Click the tile to get the preview)
                </Text>
                <Div
                  backgroundColor="#FFF"
                  p={spacing.l}
                  minHeight="500px"
                  minWidth="320px"
                  maxHeight="500px"
                  overflow="auto"
                >
                  <Text
                    variant="medium"
                    type="main"
                    mb={spacing.m}
                    weight="bold"
                  >
                    {preview?.name}
                  </Text>
                  {preview?.arr?.map((item, index) => (
                    <FlexBox
                      gap={spacing.l}
                      key={item?.value + item?.label}
                      align="flex-start"
                      {...(index + 1 === preview?.arr?.length
                        ? { margin: '0 0 32px 0' }
                        : {})}
                    >
                      <Div minWidth="100px">
                        <Text variant="small" type="grey">
                          {item?.label}
                        </Text>
                      </Div>
                      <Div>
                        <Text
                          style={{
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                          }}
                          variant="small"
                          type="main"
                          weight="bold"
                        >
                          {item?.value}
                        </Text>
                      </Div>
                    </FlexBox>
                  ))}
                  {preview?.assets?.map((item, i) => (
                    <Div key={item?.key + i} p={spacing.m}>
                      <UploadBox
                        hideBorder
                        src={item?.fileLocation}
                        uploadWidth="100%"
                        uploadHeight="100%"
                        fileType={item?.fileType}
                      />
                    </Div>
                  ))}
                  {preview?.smsPreview?.senderName && (
                    <$BlueBox height="450px">
                      <$SmsPreview>
                        <img src={SMSTemplateImg} />
                        <big>{preview?.smsPreview?.senderName}</big>
                        <span>{preview?.smsPreview?.preview}</span>
                      </$SmsPreview>
                    </$BlueBox>
                  )}
                  {preview?.whatsAppPreview?.data && (
                    <TemplatePreview
                      data={preview?.whatsAppPreview?.data}
                      insidePopup
                      hidePreviewTitle
                    />
                  )}
                  {preview?.emailPreview && (
                    <Div maxHeight="450px" overflow="auto" py={spacing.l}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: preview?.emailPreview,
                        }}
                      />
                    </Div>
                  )}
                </Div>
              </Div>
            </FlexBox>
          </$BlueBox>
        </Div>
        <Div
          pt={spacing.l}
          display={query?.tab === 'Journey Statistics' ? 'block' : 'none'}
        >
          <PublishItem
            width="100%"
            header="Journey Statistics"
            showExport="journeyExport"
          >
            <FlexBox gap="3%" wrap="wrap">
              {jbStats?.campaigns?.map((campaign) => (
                <Div key={campaign?._id} width="30%">
                  {['sms', 'email', 'whatsApp'].includes(campaign?.type) ? (
                    <CampaignStats
                      isJourney
                      form={campaign}
                      campaignType={
                        GET_SEL_VALUE(campaign?.type, CAMP_TYPES)?.label
                      }
                    />
                  ) : (
                    <DigitalCamps campaign={campaign} />
                  )}
                </Div>
              ))}
            </FlexBox>
          </PublishItem>
        </Div>
      </$CampaignContainer>
    </Div>
  )
}

export const DigitalCamps = ({ campaign }) => {
  const { spacing } = useTheme()

  const [statsArr, setStatsArr] = useState([])

  useEffect(() => {
    const arr = campaign?.content?.body?.assetsDetails?.map((asset) => {
      const lnkCount =
        campaign?.stats?.linkVisits?.find(
          (ele) => ele.url === asset?.redirectUrl
        )?.count || false
      return {
        fileName: asset?.fileName,
        link: asset?.redirectUrl || '',
        cols: ['Title', 'Volume', 'Percentage %'],
        rows: [
          ['Total Sent', campaign?.stats?.campaignSent, '-'],
          [
            'Total Seen',
            campaign?.stats?.assetSeen?.[asset?.assetId],
            campaign?.stats?.assetSeen?.[asset?.assetId]
              ? Math.round(
                  (campaign?.stats?.assetSeen?.[asset?.assetId] * 100) /
                    campaign?.stats?.campaignSent || 0
                )
              : '-',
          ],
          [
            'Total Clicked',
            lnkCount ? lnkCount : 0,
            lnkCount
              ? Math.round(
                  (lnkCount * 100) /
                    campaign?.stats?.assetSeen?.[asset?.assetId] || 1
                )
              : '-',
          ],
        ],
      }
    })
    setStatsArr(arr)
  }, [])

  return (
    <$StatsTable bgrd="#00abfa">
      <thead>
        <tr>
          <th colSpan="3" style={{ padding: spacing.m }}>
            {GET_SEL_VALUE(campaign?.type, CAMP_TYPES)?.label}{' '}
            <$Ellipsis>({campaign?.name})</$Ellipsis>
          </th>
        </tr>
      </thead>

      {statsArr?.map((stat) => (
        <>
          <thead>
            {stat?.fileName && (
              <tr>
                <td colSpan="3" style={{ padding: spacing.m }}>
                  File Name : {stat?.fileName}
                </td>
              </tr>
            )}
            {stat?.link && (
              <tr>
                <td colSpan="3" style={{ padding: spacing.m }}>
                  <FlexBox justify="center" gap={spacing.m}>
                    Link : <LinkHoverRedirect link={stat?.link} />
                  </FlexBox>
                </td>
              </tr>
            )}

            <tr>
              {stat?.cols?.map((col) => (
                <td key={col}>{col}</td>
              ))}
            </tr>
          </thead>

          <tbody>
            {stat?.rows?.map((row, ind) => (
              <tr key={ind}>
                {row?.map((col) => (
                  <td key={col}>{col}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </>
      ))}
    </$StatsTable>
  )
}

const jbStats = {
  journeyStats: {
    _id: '615dafcff9f6430014f8a095',
    stats: {
      email: {},
      sms: {},
    },
  },
  campaigns: [
    {
      name: 'adBelowBillfilter_adBelow2.27',
      type: 'adBelowBill',
      content: {
        body: {
          componentType: 'image',
          assetsDetails: [
            {
              assetId: '615da85e100eac0013a80fc1',
              key: 'assets/1kpmjfjkkfcef3i/662e96c7d2f3512709b672e67d0710cf_1633527902439.jpg',
              fileLocation:
                'https://d2e5rq195g1qgs.cloudfront.net/assets/1kpmjfjkkfcef3i/662e96c7d2f3512709b672e67d0710cf_1633527902439.jpg',
              couponVisibility: false,
            },
          ],
        },
      },
      stats: {
        $init: true,
        totalReportEntries: 4434,
        campaignSent: 144893,
        assetSeen: {
          '615da85e100eac0013a80fc1': 1541,
        },
      },
    },
    {
      name: 'bannerInBillfilter_banner3.58',
      type: 'bannerInBill',
      content: {
        body: {
          componentType: 'carousel',
          assetsDetails: [
            {
              assetId: '615dac7e100eac0013a80fd0',
              key: 'assets/1kpmjfjkkfcef3i/8424bddc8747e0fb6cdd21810f57f22b_1633528958523.png',
              fileLocation:
                'https://d2e5rq195g1qgs.cloudfront.net/assets/1kpmjfjkkfcef3i/8424bddc8747e0fb6cdd21810f57f22b_1633528958523.png',
              couponVisibility: false,
              redirectUrl: 'https://smart.link/ijvd4r5padzgl',
            },
            {
              assetId: '615daa5b9648a400132c4d28',
              key: 'assets/1kpmjfjkkfcef3i/8fcae5c0bf168eb4321700ac4b80347b_1633528411266.png',
              fileLocation:
                'https://d2e5rq195g1qgs.cloudfront.net/assets/1kpmjfjkkfcef3i/8fcae5c0bf168eb4321700ac4b80347b_1633528411266.png',
              couponVisibility: false,
              redirectUrl: 'https://smart.link/ijvd4r5padzgl',
            },
          ],
        },
      },
      stats: {
        $init: true,
        linkVisits: {
          'https://smart.link/ijvd4r5padzgl': 60,
        },
        totalReportEntries: 9726,
        campaignSent: 144903,
        assetSeen: {
          '615dac7e100eac0013a80fd0': 7195,
          '615daa5b9648a400132c4d28': 5500,
        },
      },
    },
  ],
}
