import React from 'react'
import styled from 'styled-components'

import { FlexBox } from 'styledComponent'
import { ExternalLinkIcon } from 'assets/icons'

const $FullLink = styled(FlexBox)`
  position: absolute;
  top: -20px;
  left: 40px;
  opacity: 0;
  display: none;
  transition: opacity 0.4s;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 16px;
  max-width: 700px;
  box-shadow: 0px 3px 6px 0px hsla(0, 0%, 0%, 0.18);

  svg {
    min-width: 15px;
    min-height: 15px;
  }
`

const $Container = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin: ${({ theme }) => `0px ${theme.spacing.s}`};

  .shortlink {
    max-width: 150px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }

  &:hover {
    ${$FullLink} {
      opacity: 1;
      display: flex;
    }
  }
`

export const LinkHoverRedirect = ({ link }) => {
  return (
    <$Container>
      <a href={link} target="_blank" className="shortlink">
        {link}
      </a>
      <$FullLink as="a" target="_blank" href={link} gap="8px" align="center">
        <div>{link}</div>
        <ExternalLinkIcon />
      </$FullLink>
    </$Container>
  )
}
