import { Text } from 'components/Common'
import { spacing } from 'design-system'
import styled, { css } from 'styled-components'
import { Div, FlexBox } from 'styledComponent'

import WA_Light_BG from 'assets/images/WA_Light_BG.jpg'
import WA_Dark_BG from 'assets/images/WA_Dark_BG.jpg'

export const $PreviewBlock = styled(Div)`
  position: relative;
  z-index: 1;
  padding: 0 32px;
  :after {
    position: absolute;
    z-index: -1;
    content: '';
    top: ${({ popup }) => (popup ? '-16px' : '-32px')};
    bottom: ${({ popup }) => (popup ? '-16px' : '-32px')};
    right: ${({ popup }) => (popup ? '-16px' : '-32px')};
    left: 0;
    background-color: #f2f5f8;
  }
`

export const $ContentTextBlock = styled(Div)`
  input,
  textarea {
    font-size: 14px;
    padding: ${spacing.m};
    width: 100%;
    border: none;
    resize: none;
    background-color: ${({ theme }) => theme.white};
    border-radius: 5px 5px 0 0;
    &:focus,
    :focus-visible {
      outline: none;
    }
  }
`
export const $UnderlineText = styled(Text)`
  cursor: pointer;
  span {
    text-decoration: underline;
  }
  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
`

export const $ContentFooter = styled(FlexBox)`
  padding: ${spacing.m};
  background-color: #ededed;
  border-radius: 0 0 5px 5px;
  justify-content: ${({ justify }) => justify || 'space-between'};
  position: relative;
`

export const $ContentWrapper = styled(Div)`
  border: 1px solid #ccc;
  border-radius: 5px;
`

export const $EmojiIcon = styled(Div)`
  > svg {
    color: ${({ theme }) => theme.primary};
  }
`

export const $FloatingContainer = styled(Div)`
  position: absolute;
  width: 350px;
  height: 450px;
  top: 30px;
  right: 32px;
  z-index: 2;
`

const themePolicy = {
  dark: {
    backgroundImage: WA_Dark_BG,
    chatBubbleBg: '#373738',
    textColor: '#FBFBFC',
    footerTextColor: '#aeaeae',
    buttonBorderColor: '#f5f5f5',
    mediaBg: '#505254',
  },
  light: {
    backgroundImage: WA_Light_BG,
    chatBubbleBg: '#f5f5f5',
    textColor: '#000',
    footerTextColor: '#00000073',
    buttonBorderColor: '#0000001a',
    mediaBg: '#ccd0d5',
  },
}

export const $WhatsAppPreviewBlock = styled(Div)`
  margin-top: 16px;
  width: 100%;
  max-width: 480px;
  min-height: ${({ minHeight }) => minHeight || '750px'};
  transition: 0.3s all ease-in-out;
  background-size: cover;
  padding: 32px 16px;
  background-image: url(${({ theme }) =>
    themePolicy[theme.mode]?.backgroundImage});
`

export const $ChatBubble = styled(Div)`
  width: 280px;
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  height: ${({ height }) => height || 'auto'};
  background: ${({ theme }) => themePolicy[theme.mode]?.chatBubbleBg};
  border-radius: 10px;
  position: relative;
  margin: ${({ margin }) => margin || 0};
  padding: 8px 8px 0;
  max-width: 100%;
`

export const $BubbleArrow = styled(Div)`
  position: absolute;
  width: 0;
  bottom: 32px;
  left: -16px;
  height: 0;

  &:after {
    content: '';
    position: absolute;
    border: 0 solid transparent;
    border-top: 9px solid
      ${({ theme }) => themePolicy[theme.mode]?.chatBubbleBg};
    border-radius: 0 20px 0;
    width: 15px;
    height: 30px;
    transform: rotate(145deg);
  }
`

export const $ChatHeader = styled.p`
  font-weight: 900;
  color: ${({ theme }) => themePolicy[theme.mode]?.textColor};
  padding-bottom: 4px;
`

export const $ChatBody = styled.p`
  color: ${({ theme }) => themePolicy[theme.mode]?.textColor};
  padding-bottom: 4px;
  white-space: pre-line;
`

export const $ChatFooter = styled.p`
  color: ${({ theme }) => themePolicy[theme.mode]?.footerTextColor};
  font-size: 14px;
  padding-bottom: 4px;
`

export const $MediaThumbnail = styled(FlexBox)`
  height: 120px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => themePolicy[theme.mode]?.mediaBg};
  margin-bottom: 8px;
  svg {
    width: 60px;
    height: 60px;
    display: inline-block;
    fill: #fff;
  }
`

export const $ButtonText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => themePolicy[theme.mode]?.textColor};
`

export const $ButtonsBlock = styled.ul`
  list-style: none;
  padding: 0;
  li {
    border-top: 1px solid
      ${({ theme }) => themePolicy[theme.mode]?.buttonBorderColor};
    margin-top: 4px;
    padding: 8px 0;
    p {
      color: #3b86ff;
      svg {
        width: 20px;
        height: 20px;
        display: inline-block;
        fill: #3b86ff;
      }
    }
  }
`

export const $DisclaimerBlock = styled(FlexBox)`
  border: 1px dashed #aeaeae;
  border-radius: 5px;
  background-color: #faf8d1;
  padding: 8px;
  align-items: ${({ align }) => align || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
`

export const $FileInputBlock = styled(Div)`
  input[type='file']::file-selector-button {
    background-color: #f5f5f5;
    border: 1px solid #000;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    cursor: pointer;
    font-size: 14px;
    width: 100px;
  }
`
