import React, { useState } from 'react'
import { $SurveyContainer } from '../Survey.styled'
import { TextIcon, QuestIcon, BackgroundIcon } from 'assets/icons'
import { QUEST_GEN } from '../constants'
import { BuilderComponent } from 'components/Common/BuilderComponent'

export const SurveyToolbox = ({ ...props }) => {
  const [hoveredElement, setHoveredElement] = useState('')

  const questGenerator = (type = 'radio') => {
    const newQuest = QUEST_GEN(type)
    props.setSurvey({
      ...props.survey,
      body: [...props.survey.body, newQuest],
    })
    setTimeout(
      () =>
        document.getElementById('surveyCon')?.scrollTo({
          top: 100000,
          behavior: 'smooth',
        }),
      100
    )
  }

  const components = [
    {
      width: '100%',
      handleClick: () => questGenerator('radio'),
      icon: <QuestIcon />,
      text: 'New Question',
    },
    {
      width: '100%',
      handleClick: () => questGenerator('html'),
      icon: <TextIcon />,
      text: 'Add Text Field',
    },
    {
      width: '100%',
      handleClick: () => props.setShowModal(true),
      icon: <BackgroundIcon />,
      text: 'Background Colour',
    },
  ]

  return (
    <$SurveyContainer toolbox width="120px" height="calc(100vh - 100px)">
      {components?.map((comp) => (
        <span
          key={comp.text}
          onMouseEnter={() => setHoveredElement(comp.text)}
          onMouseLeave={() => setHoveredElement('')}
        >
          <BuilderComponent isActive={comp.text === hoveredElement} {...comp} />
        </span>
      ))}
    </$SurveyContainer>
  )
}
