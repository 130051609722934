const initialState = {
    invoices:[],
    hasMoreInvoices: true,
    company:{
      companyId: "",
      companyName: "",
    }, 
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case "SET_INVOICELIST":
        return {
            ...state,
            invoices: action.payload
        };
      case "SET_INVOICE_COMPANY":
        return {
          ...state,
          company: action.payload
        };
      case "CONCAT_INVOICELIST":
        return {
          ...state,
          invoices: state.invoices.concat(action.payload),
        }
      case "SET_HAS_MORE_INVOICES":
        return {
          ...state,
          hasMoreInvoices: action.payload,
        }
      default:
        return state;
    }
  }