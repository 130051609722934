import React from 'react'

import { CampaignDetails } from '../components'
import { PopupSettings } from './PopupSettings'
import { PopupOverPreviewAndPublish } from './PopupPreviewAndPublish'
import PopupMessage from './PopupMessage'
import { CampaignSettingsWithATOC } from '../components/CampaignSettingsWithATOC'

export const PopupOverBillCampaign = ({ active, form, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          campaignType="Popup Over Bill"
          form={form}
          setForm={setForm}
        />
      )

    case 'Popup':
      return <PopupMessage form={form} setForm={setForm} />

    case 'Popup Settings':
      return <PopupSettings form={form} setForm={setForm} />

    case 'Campaign Settings':
      return <CampaignSettingsWithATOC form={form} setForm={setForm} />

    case 'Preview & Publish':
      return <PopupOverPreviewAndPublish form={form} hideSteps={hideSteps} />

    default:
      return null
  }
}
