import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { TopBarAndStepsWrapper } from '../../components/TopBarAndStepsWrapper'

import { WHATSAPP_CAMP_STEPS, CAMP_INIT, CHECK_IF } from '../../constants'
import { $WhiteContainer } from '../../AutoEngagement.styled'
import { useCampaignApi } from '../../components'
import CampaignContainer from './CampaignContainer'

const WhatsAppCampaign = () => {
  const [whatsAppForm, setWhatsAppForm] = useState({})
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    label: 'Campaign Details',
    status: 'active',
  })

  const {
    onSave,
    onPublish,
    createCampaign,
    updCampaign,
    campaignId,
    body,
    fetchCampaignById,
    hideSteps,
  } = useCampaignApi(whatsAppForm, setWhatsAppForm, CAMP_INIT, 'whatsApp')

  useEffect(() => {
    const step = hideSteps
      ? { id: 5, label: 'Preview & Publish', status: 'active' }
      : null
    step && setCurrentStep(step)
  }, [])

  const onNextClick = async () => {
    const updateCall = async () =>
      Object.keys(body)?.length > 0 && campaignId && (await updCampaign())
    switch (currentStep?.id) {
      case 1: {
        const campDetails = { ...whatsAppForm }
        const check = CHECK_IF(['name', 'vendorId', 'companyId'], campDetails)
        if (check) throw `Please enter ${check}`
        return (
          Object.keys(body)?.length > 0 &&
          (campaignId && campaignId !== 'create'
            ? await updCampaign()
            : await createCampaign())
        )
      }

      case 2: {
        const builderDetails = { ...whatsAppForm?.content?.body }
        const check = CHECK_IF(['templateId'], builderDetails)
        if (check) throw `Please enter ${check}`
        return await updateCall()
      }

      case 3: {
        const array = whatsAppForm?.content?.body?.templateParams || []
        if (array?.length) {
          const check =
            array.length === Object.values(array).length &&
            !array.includes(undefined) &&
            !array.includes(null)
          if (!check) throw `Please fill all attributes`
        }
        const check = CHECK_IF(['mediaUrl'], { ...whatsAppForm?.content?.body })

        if (whatsAppForm?.content?.body?.templateType === 'media' && check)
          throw `Please enter ${check}`

        return await updateCall()
      }

      case 4: {
        const whomTo = { ...whatsAppForm?.targetGroup }
        const check = CHECK_IF(['id', 'type'], whomTo)
        if (check) throw `Please choose ${check}`
        return await updateCall()
      }

      case 5: {
        const sendContent = { ...whatsAppForm }
        if (!sendContent?.validity) throw `Please choose a Send type`

        switch (sendContent.validity) {
          case 'immediate': {
            return await updateCall()
          }

          case 'oneTime': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          case 'recurring': {
            const check = CHECK_IF(['startDate', 'scheduledTime'], sendContent)
            if (!(sendContent?.repeatEvery?.days?.length > 0))
              throw `Please select at least one day`
            if (check) throw `Please enter ${check}`
            return await updateCall()
          }

          default:
            return null
        }
      }
    }
  }

  return (
    <$WhiteContainer>
      <TopBarAndStepsWrapper
        heading="Auto Engagement - WhatsApp"
        onNextClick={onNextClick}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        labels={WHATSAPP_CAMP_STEPS}
        hideSteps={hideSteps}
        onSave={onSave}
        onPublish={onPublish}
        onPreviousClick={fetchCampaignById}
        form={whatsAppForm}
        campaignType="WhatsApp"
      >
        <CampaignContainer
          form={whatsAppForm}
          setForm={setWhatsAppForm}
          active={currentStep.label}
          hideSteps={hideSteps}
        />
      </TopBarAndStepsWrapper>
    </$WhiteContainer>
  )
}

export default WhatsAppCampaign
