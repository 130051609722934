import createReducer from 'utils/createReducer'
import * as types from '../types/billing'

const initialState = {
  planData: [],
  usageData: {},
  onceOpened: false,
}

export default createReducer(initialState)(types)
