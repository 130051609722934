export const getTransformedValues = (processedFilters) => {
  const result = {}

  Object.keys(processedFilters).forEach((item) => {
    if (processedFilters[item].type)
      result[item] = {
        type: processedFilters[item].type,
        value: { ...processedFilters[item].value },
      }
    else result[item] = { ...processedFilters[item].value }
  })

  return result
}

export const getActiveFilter = (filters) => {
  let activeFilters = {}

  for (let filterName in filters) {
    if (filters[filterName].isActive)
      activeFilters = {
        ...activeFilters,
        [filterName]: { ...filters[filterName] },
      }
  }

  return activeFilters
}
