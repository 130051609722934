import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts'
import { extendMoment } from 'moment-range'
import moment from 'moment'
import DataSet from '@antv/data-set'
import _ from 'lodash'

import DateRangeSelector from './DateRangeSelector'
import ChartHeader from './ChartHeader'
import { getTransactionsChartsByType } from '../../../../redux/actions/billingCharts'
import { GreyText } from '../../../../styledComponent'
import '../../../../css/content/billingCharts.scss'

const extendedMoment = extendMoment(moment)
const now = extendedMoment()
const defaultDateRange = moment.range(
  now.clone().startOf('month'),
  now.clone().endOf('day')
)

class TransactionsChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'false',
    }
  }

  handleDateRangeChange = (values) => {
    this.setState({ showCalendar: false })
    this.props.handleDateChange(values)

    const startDate = values.start.startOf('day').toDate()
    const endDate = values.end.endOf('day').toDate()

    this.props.getTransactionsChartsByType({
      startDate,
      endDate,
      selectedStoreIds: this.props.selectedStoreIds,
    })
  }

  showCalendar = () => {
    this.setState((state) => ({
      ...state,
      showCalendar: !state.showCalendar,
    }))
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        dispaly: 'true',
      }
    }
  }

  componentDidMount() {
    this.handleDateRangeChange(this.props.selectedDate)
  }

  render() {
    const { DataView } = DataSet

    if (_.has(this.props.billingCharts, 'transactionsType')) {
      var data = [
        {
          item: 'BillMe Transactions',
          count: this.props.billingCharts.transactionsType.bmCount,
          type: 'BM',
        },
        {
          item: 'BillMe+Print Transactions',
          count: this.props.billingCharts.transactionsType.bmPrintCount,
          type: 'BMPrint',
        },
        {
          item: 'Print Transactions',
          count: this.props.billingCharts.transactionsType.printCount,
          type: 'Print',
        },
        {
          item: 'CW Transactions',
          count: this.props.billingCharts.transactionsType.CWCount,
          type: 'C.W',
        },
      ]
    } else {
      data = []
    }
    const dv = new DataView()

    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent',
    })
    const cols = {
      percent: {
        formatter: (val) => {
          val = val * 100 + '%'
          return val
        },
      },
    }

    const hasData = data.some((value) => value?.count)

    return (
      <div className="transactionsChart chartsMain">
        <ChartHeader title="Transactions Types" />
        <hr />
        <DateRangeSelector
          value={this.props.selectedDate}
          onDateChange={this.handleDateRangeChange}
          showCalendar={this.state.showCalendar}
          toggleCalender={this.showCalendar}
        />
        <Chart
          height={400}
          data={dv}
          scale={cols}
          padding={[80, 100, 80, 80]}
          forceFit
          onPlotClick={(e) => this.props.onChartClick(e?.data?._origin?.type)}
        >
          {hasData ? (
            <>
              <Coord type="theta" radius={0.75} />
              <Axis name="percent" />
              <Legend
                position="right"
                offsetY={-window.innerHeight / 2 + 120}
                offsetX={-100}
              />
              <Tooltip
                showTitle={false}
                itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
              />
              <Geom
                type="intervalStack"
                position="percent"
                color="item"
                tooltip={[
                  'item*percent',
                  (item, percent) => {
                    percent = parseFloat(percent * 100).toFixed(1) + '%'
                    return {
                      name: item,
                      value: percent,
                    }
                  },
                ]}
                style={{
                  lineWidth: 1,
                  stroke: '#fff',
                }}
              >
                <Label
                  content="count"
                  formatter={(val, item) => item.point.item + ': ' + val}
                />
              </Geom>
            </>
          ) : null}
        </Chart>
        {_.isEmpty(data) || !hasData ? (
          <div className="noData">
            No data to display between selected dates
          </div>
        ) : (
          <GreyText text-align={'center'}>
            Note: Click on the Pie chart to get a store-wise breakdown
          </GreyText>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  billingCharts: state.billingCharts,
  errors: state.errors,
})

export default connect(mapStateToProps, { getTransactionsChartsByType })(
  TransactionsChart
)
