import React from 'react'
import { connect } from 'react-redux'
import { useDebouncedEffect, useSelectStore } from 'hooks'
import { CustomerProfiling } from './CustomerProfiling'
import { getCrmList } from 'redux/actions/crmAction'
import { filterToQuery } from 'redux/actions/crmFilterActions'
import { initialFilter } from './constants'
import swal from 'sweetalert'
import { useToasts } from 'components/Common/Toast'

const CRM = (props) => {
  const [crmData, setCrmData] = React.useState({
    data: [],
    tableLoading: false,
    skip: 0,
  })
  const {
    data: { selectedVendor },
  } = useSelectStore('vendorIds')

  const [search, setSearch] = React.useState(
    props.crm.filterQuery?.search || ''
  )

  const { data: userEmail } = useSelectStore('auth.user.email')

  const { toast } = useToasts()

  useDebouncedEffect(
    () => getCrmData(0, props.crm.filterQuery),
    [selectedVendor]
  )

  useDebouncedEffect(
    () =>
      ((!search && !!props.crm.filterQuery?.search) ||
        (search && search !== props.crm.filterQuery?.search)) &&
      handleFilter({ ...props.crm.filterData, search }),
    [search]
  )

  const handleFilter = async (filter, isExported, discardFilters) => {
    const filterQuery = await props.filterToQuery(
      !!search ? { ...filter, search } : filter,
      isExported
    )
    if (!isExported) {
      getCrmData(0, filterQuery)
    }

    if (isExported) {
      exportCRMData(discardFilters ? {} : filterQuery)
    }
  }

  const getCrmData = async (skip = 0, query) => {
    if (!selectedVendor?.value) return

    setCrmData({ ...crmData, tableLoading: true })
    try {
      let res = await getCrmList({
        limit: 10,
        skip: skip * 10,
        vendorId: selectedVendor?.value,
        ...query,
      })
      setCrmData({
        data: res?.result || [],
        tableLoading: false,
        skip,
      })
    } catch (e) {
      console.error(e)
      setCrmData({
        data: [],
        tableLoading: false,
        skip: 0,
      })
    }
  }

  const exportCRMData = async (query) => {
    if (!selectedVendor?.value) return

    try {
      let res = await getCrmList({
        limit: 10,
        skip: 0,
        vendorId: selectedVendor?.value,
        ...query,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        email: userEmail,
        isExported: true,
      })

      if (res.code) {
        swal(res.message || '')
      } else {
        toast(res.message || '', 'error')
      }
    } catch (e) {
      console.error(e)
      toast('Something went wrong, please try again', 'error')
    }
  }

  return (
    <>
      <CustomerProfiling
        data={crmData.data}
        skip={crmData.skip}
        tableLoading={crmData.tableLoading}
        onPageChange={(page) => getCrmData(page, props.crm.filterQuery)}
        handleFilter={handleFilter}
        initialFilter={initialFilter}
        filterData={props.crm.filterData}
        getCrmData={getCrmData}
        search={search}
        setSearch={setSearch}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  crm: state.crm,
})

export default connect(mapStateToProps, { filterToQuery })(CRM)
