import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MinimalIdentityBox } from 'components/Common/ModalComponents'
import { Grid, ErrorMessage } from 'styledComponent'
import { filterBrand } from 'redux/actions'

class CampaignScreenOne extends Component {
  render() {
    return (
      <div style={{ padding: '1em 30px', background: '#fff' }}>
        <h5>Step 1/3: Select a Brand</h5>
        <ErrorMessage as="span" error={this.props.hasError}>
          Please select a brand
        </ErrorMessage>
        <Grid
          as="form"
          columns="repeat(auto-fill, minmax(130px, 1fr))"
          columnGap="1em"
          style={{
            maxHeight: '75vh',
            overflow: 'auto',
          }}
        >
          {this.props.brands.map((brand) => (
            <MinimalIdentityBox
              name={brand.name}
              logo={brand.brandLogo}
              id={brand.brandId}
              inputType="radio"
              inputName="brand"
              handleChange={this.props.handleBrandSelect}
              checked={
                this.props.brandId && this.props.brandId.includes(brand.brandId)
              }
            />
          ))}
        </Grid>
      </div>
    )
  }
}

const mapStatesToProps = (state) => ({
  brands: filterBrand(state, 'campaign'),
})

export default connect(mapStatesToProps)(CampaignScreenOne)
