import { isEmpty } from './is-empty'
import moment from 'moment'

const monthNames = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

export default function formatDate(date) {
  let day = date.getDate()
  let monthIndex = date.getMonth()
  let year = date.getFullYear()

  return day + ' ' + monthNames[monthIndex] + ' ' + year
}

export function formatDateInFilters(date) {
  if (!date) return

  let day = date.getDate()
  let monthIndex = date.getMonth()
  let year = date.getFullYear()
  switch (day) {
    case 1:
    case '1':
      return `${day}st ${monthNames[monthIndex]} ${year}`
      break
    case 2:
    case '2':
      return `${day}nd ${monthNames[monthIndex]} ${year}`
      break
    case 3:
    case '3':
      return `${day}rd ${monthNames[monthIndex]} ${year}`
      break
    default:
      return `${day}th ${monthNames[monthIndex]} ${year}`
      break
  }
}

//following function converts date in dd-mm-yyyy format
export function formatDate2(input) {
  if (isEmpty(input)) {
    return null
  }
  if (input.length < 2) {
    return null
  }
  var datePart = input.match(/\d+/g),
    year = datePart[0].substring(2),
    month = datePart[1],
    day = datePart[2]

  return day + '/' + month + '/' + year
}

export function formatDate3(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[2].substring(2),
    month = datePart[0],
    day = datePart[1]

  return day + '/' + month + '/' + year
}

export const formatDateWithTime = (date) => {
  const formattedDate = moment(date).format('D-MMMM-YY')
  const formattedTime = moment(date).format('hh:mm A')
  return {
    date: formattedDate,
    time: formattedTime,
  }
}

export function formatDate_4(dates) {
  let date = new Date(dates)
  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear()
  if (month <= 12) {
    return `0${month + 1}-${day}-${year}`
  } else {
    return `${month}-${day}-${year}`
  }
}

export const utcToTimeStamp = (time = '00:00') => {
  const [hr, min] = time.split(':')
  if (Number(hr) > 12) {
    const hour =
      Number(hr) - 12 > 9
        ? (Number(hr) - 12).toString()
        : '0' + (Number(hr) - 12).toString()
    return {
      hours: { value: hour, label: hour },
      minutes: { value: min, label: min },
      period: { value: 'PM', label: 'PM' },
    }
  } else if (Number(hr) == 12) {
    return {
      hours: { value: hr.toString(), label: hr.toString() },
      minutes: { value: min, label: min },
      period: { value: 'PM', label: 'PM' },
    }
  } else if (Number(hr) == 0) {
    return {
      hours: { value: '12', label: '12' },
      minutes: { value: min, label: min },
      period: { value: 'AM', label: 'AM' },
    }
  } else {
    return {
      hours: { value: hr.toString(), label: hr.toString() },
      minutes: { value: min, label: min },
      period: { value: 'AM', label: 'AM' },
    }
  }
}

export const timeStampToUtc = (timeStamp) => {
  const min =
    Number(timeStamp?.minutes?.value) > 9
      ? timeStamp?.minutes?.value?.toString()
      : '0' + Number(timeStamp?.minutes?.value)

  return timeStamp?.period?.value === 'PM' && timeStamp?.hours?.value != '12'
    ? (Number(timeStamp?.hours?.value) + 12).toString() + ':' + min
    : timeStamp?.period?.value === 'AM' && timeStamp?.hours?.value == '12'
    ? '00:' + min
    : timeStamp?.period?.value === 'PM' && timeStamp?.hours?.value == '12'
    ? '12:' + min
    : timeStamp?.hours?.value?.toString() + ':' + min
}

export const compareDatePermission = (permission, startDate) => {
  if (permission === 'allTime') {
    return true
  }
  const start = new Date(startDate)
  const end = new Date()
  const diffTime = Math.abs(end - start)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays < 62
}
