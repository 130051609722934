import React from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, color, spacing } from 'design-system/styles'

const VARIANT = {
  EGG_BLUE: 'eggBlue', //: '#00BCD1',
  SALMON: 'salmon', //: "#FD7975",
  BUTTERFLY_BUSH: 'butterflyBush', //: "#4F549E",
  BRINK_PINK: 'brinkPink', //: "#F9698D",
}

const Content = styled.div`
  margin-right: ${spacing.xl};

  p {
    font-size: 0.8em;
    color: ${color.medium};
    margin: 0px 0px 10px;
  }

  h3 {
    margin: 0px;
    color: ${(props) => props.amountColor || 'inherit'};
  }
`

const StyledStat = styled.div`
  border-radius: ${borderRadius.default};
  border: 1px solid hsl(208, 11%, 94%);
  background-color: ${color.white};
  padding: ${spacing.l} ${spacing.l};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px 0px hsl(0 0% 0% / 0.1);
  overflow: hidden;
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 255px;

  svg {
    z-index: 1;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: hsl(0 0% 100% / 0);
  }

  &:before {
    width: 100px;
    height: 100px;
    right: -20px;
    top: -50%;
  }

  &:after {
    width: 140px;
    height: 140px;
    right: -40px;
    bottom: -70%;
  }

  ${(props) =>
    props.variant === VARIANT.EGG_BLUE &&
    css`
      background-color: ${color.eggBlue};

      p,
      h3 {
        color: ${color.white};
      }

      &:after,
      &:before {
        background-color: hsl(182 100% 82% / 0.15);
      }
    `}

  ${(props) =>
    props.variant === VARIANT.SALMON &&
    css`
      background-color: ${color.salmon};

      p,
      h3 {
        color: ${color.white};
      }

      &:after,
      &:before {
        background-color: hsl(2 54% 99% / 0.15);
      }
    `}

    ${(props) =>
    props.variant === VARIANT.BUTTERFLY_BUSH &&
    css`
      background-color: ${color.butterflyBush};

      p,
      h3 {
        color: ${color.white};
      }

      &:after,
      &:before {
        background-color: hsl(182 100% 82% / 0.15);
      }
    `}

    ${(props) =>
    props.variant === VARIANT.BRINK_PINK &&
    css`
      background-color: ${color.brinkPink};

      p,
      h3 {
        color: ${color.white};
      }

      &:after,
      &:before {
        background-color: hsl(182 100% 82% / 0.15);
      }
    `}
`

export const Stat = ({
  label,
  amount,
  amountColor,
  icon: Icon,
  variant = '',
}) => {
  return (
    <StyledStat variant={variant}>
      <Content amountColor={amountColor}>
        <p>{label}</p>
        <h3>{amount}</h3>
      </Content>
      <Icon />
    </StyledStat>
  )
}
