import React from 'react'
import styled from 'styled-components'

import CustomPopup from 'components/Content/CustomPopup'
import { useSelectStore, useVendorSelect } from 'hooks'
import {
  PopupFooter,
  PopupHeader,
} from 'components/Content/Products/components/ModalComponents/ModalComponents'

import { Input } from './Input'

const AppHOC = ({ children }) => {
  const popupRef = React.useRef()
  const { data: vendorIds } = useSelectStore('vendorIds.vendorIds')
  const { data: selectedVendor } = useSelectStore('vendorIds.selectedVendor')
  const { data: auth } = useSelectStore('auth.isAuthenticated')

  const [inputVendor, setInputVendor] = React.useState(selectedVendor)

  const { handleVendorChange } = useVendorSelect(true, true, true)

  const onChange = (value) => {
    const vendorValue = vendorIds.find((vendor) => vendor.value === value)
    setInputVendor(vendorValue)
  }

  const closePopup = () => {
    if (!inputVendor) return null
    popupRef.current.closeModal()
  }

  const onConfirm = () => {
    handleVendorChange(inputVendor)
    popupRef.current.closeModal()
  }

  React.useEffect(() => {
    if (popupRef.current) {
      popupRef.current.openModal()
    }
  }, [auth, vendorIds])

  React.useEffect(() => {
    if (auth && vendorIds?.length === 1) {
      handleVendorChange(vendorIds[0])
    }
  }, [])

  if (auth && !Object.keys(selectedVendor)?.length && vendorIds?.length > 1) {
    return (
      <CustomPopup
        className={'filter-modal'}
        ref={popupRef}
        headerComponent={true}
        heading={<PopupHeader title="Select Group" onClose={closePopup} />}
        footer={<PopupFooter onCancel={closePopup} onConfirm={onConfirm} />}
      >
        <$Block>
          <Input
            variant="radio"
            name="vendor"
            value={inputVendor?.value}
            options={vendorIds}
            onChange={onChange}
            direction="column"
          />
        </$Block>
      </CustomPopup>
    )
  } else {
    return children
  }
}

export default AppHOC

const $Block = styled.div`
  padding: 20px;
`
