const _selectOptionsGenerator = (data) =>
  data?.map((option) => ({
    label: option,
    value: option,
  }))

const _selectOptionGeneratorForLocation = (data, field) =>
  data?.map((option) => ({
    value: option._id,
    label: option[field],
  }))

export { _selectOptionsGenerator, _selectOptionGeneratorForLocation }
