import React from 'react'

import { useRouter } from 'hooks'
import SegmentForm from '../SegmentForm'

const NewSegment = ({ getSegments, trigger, setTrigger }) => {
  const { query, history } = useRouter()
  const { addNew } = query

  if (!addNew) return null

  const onCancel = () => {
    setTrigger('')
    history.goBack()
  }

  return (
    <SegmentForm
      onCancel={onCancel}
      getSegments={getSegments}
      trigger={trigger}
      setTrigger={setTrigger}
    />
  )
}

export default NewSegment
