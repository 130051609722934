import styled from 'styled-components'

import { spacing } from 'design-system'

const $ChartBlock = styled.div`
  width: ${({ width }) => width || '100%'};
  padding: ${({ padding }) => padding || 'unset'};
`

const sharedUlStyles = `
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`

const $Labels = styled.ul`
  ${sharedUlStyles}
  justify-content: space-between;
  padding: ${spacing.l} 0;
`

const $Label = styled.li`
  flex-basis: 50%:
`

const $ChartData = styled.ul`
  ${sharedUlStyles}
  padding: ${spacing.m} 0;
  li {
    height: ${({ height }) => height || spacing.m};
  }
`

const $ChartStart = styled.li`
  flex-basis: ${({ flexBasis }) => flexBasis || 'unset'};
  background-color: #3b86ff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`

const $ChartEnd = styled($ChartStart)`
  background-color: #d91eca;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`

export { $ChartBlock, $Labels, $Label, $ChartData, $ChartStart, $ChartEnd }
