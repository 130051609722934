import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash'

import { Input, useToasts, PopupContainer } from 'components/Common'
import {
  postSettingsData,
  setSettingsData,
  updateSettingsData,
} from 'redux/actions/settingsActions'
import { ERROR_DECODE } from 'components/Content/autoEngagement/constants'

export const DLTSettingsPopup = ({ isPopOpen, setIsPopOpen, dlt }) => {
  const [entity, setEntity] = useState([])
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const onEntityChange = (e, i) => {
    const inps = [...entity]
    inps[i].value = e.target.value
    setEntity(inps)
  }

  useEffect(() => {
    isPopOpen &&
      dlt?.settings[0]?.key &&
      setEntity(cloneDeep(dlt?.settings[0]?.key || []))
  }, [isPopOpen])

  const onSubmit = async () => {
    try {
      if (!entity[0]?.value || !entity[1]?.value) {
        throw 'Please enter all details before proceeding'
      }
      const body = {
        ...dlt,
        settings: [
          {
            ...dlt.settings[0],
            key: entity,
          },
        ],
        isConfigured: true,
      }
      const res = await (dlt?.isConfigured === false
        ? postSettingsData(body)
        : updateSettingsData(body))
      if (res?.data) {
        toast('Settings updated successfully', 'success')
        dispatch(setSettingsData(res.data))
      }
      setEntity([])
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
      throw ''
    }
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading={dlt?.settings[0]?.label}
      onSubmitClick={onSubmit}
    >
      {entity?.map((inp, i) => (
        <Input
          variant="input"
          inputWidth="300px"
          key={inp.label}
          label={inp.label}
          value={inp.value}
          onChange={(e) => onEntityChange(e, i)}
        />
      ))}
    </PopupContainer>
  )
}
