import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { AddIcon } from 'assets/icons'
import Navbar from 'components/Layout/Navbar'
import { SectionHeading, Button, Breadcrumb } from 'components/Common'
import {
  $AEHeader,
  $SearchInput,
  $MarginlessLine,
} from '../AutoEngagement.styled'
import UploadData from '../components/UploadData'
import { FlexBox } from 'styledComponent'

export const UploadHeader = (props) => {
  const [isPopOpen, setIsPopOpen] = useState(false)
  const { spacing } = useTheme()
  return (
    <>
      <Navbar heading="Media Bank" />
      <FlexBox m={spacing.l} justify="space-between">
        <Breadcrumb label="Media Bank" />
      </FlexBox>
      <$MarginlessLine />
      <FlexBox px={spacing.l} justify="flex-end">
        <Button
          mr={spacing.l}
          variant="primaryOutline"
          onClick={() =>
            window.open('https://assets.billme.co.in/crm/sample.csv')
          }
        >
          Download Sample .csv
        </Button>
        <Button
          variant="primary"
          startIcon={AddIcon}
          onClick={() => setIsPopOpen(true)}
        >
          Upload Media
        </Button>
      </FlexBox>
      <$MarginlessLine />
      <$AEHeader justify="space-between">
        <span />
        <span>
          <$SearchInput>
            <span />
            <input
              id="searchTxt"
              value={props.search}
              placeholder="Search by File Name"
              onChange={(e) => props.setSearch(e.target.value)}
            />
          </$SearchInput>
        </span>
      </$AEHeader>
      <UploadData
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        fetchAssets={() => props.setUpdate(Math.random())}
        uploadedFrom="bank"
      />
    </>
  )
}
