import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { useSelectStore } from 'hooks'
import { Input, Text, useToasts } from 'components/Common'
import { testCampaign } from 'redux/actions/autoEngageActions'
import { getSmsTemplates } from 'redux/actions/settingsActions'
import { getAssetsList, getSegments } from 'redux/actions/autoEngageActions'
import { PublishItem } from '../components/PublishItem'
import { $BlueBox, $PublishButton, $SmsPreview } from '../AutoEngagement.styled'
import {
  CHECK_IF,
  CONTENT_TYPES,
  ERROR_DECODE,
  GET_SEL_VALUE,
  GET_SEND_TIME,
  SMS_PREVIEW,
  WHEN_TYPES,
  WHOM_TYPES,
} from '../constants'
import { getEmbeddedDetails } from '../email/EmailPreviewnPublish'
import { CampaignStats } from '../components/CampaignPreview'
import SMSTemplateImg from 'assets/icons/mobile-template.svg'
import CampaignErrors from '../components/CampaignErrors'

export const SmsPreviewnPublish = ({ form, hideSteps }) => {
  const [phone, setPhone] = useState('')
  const [segmentName, setSegmentName] = useState('')
  const { toast } = useToasts()
  const dispatch = useDispatch()

  const { data: smsTemplatesList } = useSelectStore('settings.smsTemplatesList')
  const {
    data: { segmentData, assetsCsvList },
  } = useSelectStore('autoEngage')

  useEffect(() => {
    form.content?.body?.templateId &&
      dispatch(getSmsTemplates({ _id: form.content?.body?.templateId }))
  }, [form.content?.body?.templateId])

  useEffect(() => {
    const vendorId = form.vendorId
    if (form.targetGroup?.type) {
      if (form.targetGroup?.type === 'segments') {
        dispatch(getSegments({ vendorId }))
      } else {
        dispatch(
          getAssetsList({
            vendorId,
            listAll: true,
            fileType: 'csv',
          })
        )
      }
    }
  }, [form.targetGroup?.type])

  useEffect(() => {
    if (form.targetGroup?.type) {
      if (form.targetGroup?.type === 'segments') {
        const segName =
          segmentData?.filter((seg) => seg._id === form.targetGroup?.id)[0]
            ?.name || '-'
        setSegmentName(segName)
      } else {
        const segName =
          assetsCsvList?.docs?.filter(
            (asset) => asset._id === form.targetGroup?.id
          )[0]?.name || '-'
        setSegmentName(segName)
      }
    }
  }, [form.targetGroup?.type, segmentData, assetsCsvList])

  const sendTest = () => {
    try {
      if (phone?.toString()?.length !== 10)
        throw { message: 'Please enter a valid phone number' }
      const res = testCampaign(form._id, { targetUser: phone })
      if (res) {
        setPhone('')
        toast('Message sent successfully', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const getValue = (value) => {
    switch (value) {
      case 'linkVisits': {
        const linkVisits = Object.values(form?.stats?.[value]).reduce(
          (acc, curr) => {
            acc += curr
            return acc
          },
          0
        )
        return linkVisits
      }

      case 'totalCost': {
        return form?.stats?.[value]?.toFixed(2)
      }

      default: {
        return form?.stats?.[value]
      }
    }
  }

  const embeddedDeets = () => {
    const arr = form?.content?.body?.variables || []
    const variables = {}

    for (let i = 0; i < arr.length; i++) {
      let curObj = arr[i]
      const key = Object.keys(curObj)[0]
      variables[key] = curObj[key]
    }
    return getEmbeddedDetails(form?.content?.body?.variables)
  }

  return (
    <FlexBox wrap="flex-wrap" gap="2%">
      <Div width="68%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          {hideSteps ? 'Preview' : 'Preview & Publish'}
        </Text>
        {form?.status === 'draft' ? (
          <PublishItem header="Test Campaign">
            <FlexBox gap="10px" align="flex-end">
              <Input
                variant="input"
                type="number"
                containerMargin="0"
                label="Enter Test Phone Number"
                placeholder="9876543210"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                inputWidth="300px"
              />
              <$PublishButton
                noBorder
                mb="12px"
                fontSize="12px"
                variant="textualPrimary"
                onClick={sendTest}
              >
                Send Test Message
              </$PublishButton>
            </FlexBox>
            <Text variant="tiny" type="grey">
              Note: You can send a test message to only one phone number.
            </Text>
          </PublishItem>
        ) : (
          <CampaignStats form={form} campaignType="SMS" />
        )}
        <hr />
        <PublishItem
          width="100%"
          header="Campaign Details"
          completed={
            !Boolean(CHECK_IF(['name', 'vendorId', 'companyId'], form))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Campaign Name :
            </Text>
            <Text variant="small" type="main">
              {form?.name || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Campaign Description :
            </Text>
            <Text variant="small" type="main">
              {form?.description || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="5px 0 0">
            <Text variant="small" type="grey">
              Created Date :
            </Text>
            <Text variant="small" type="main">
              {moment(form?.createdDate)?.format('DD MMM YYYY')}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="5px 0 0">
            <Text variant="small" type="grey">
              Content Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.contentType, CONTENT_TYPES)?.label || '-'}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Whom To Send"
          completed={
            !Boolean(CHECK_IF(['type', 'id'], { ...form?.targetGroup }))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.targetGroup?.type, WHOM_TYPES(true), 'label')
                ?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Segment Name :
            </Text>
            <Text variant="small" type="main">
              {segmentName}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="When To Send"
          completed={
            Boolean(form?.validity) &&
            !(
              form?.validity !== 'immediate' &&
              Boolean(CHECK_IF(['startDate', 'scheduledTime'], form))
            )
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.validity, WHEN_TYPES, 'label')?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              When :
            </Text>
            <Text variant="small" type="main">
              <div
                dangerouslySetInnerHTML={{ __html: GET_SEND_TIME(form) || '-' }}
              />
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Embedded Components"
          completed={Boolean(form?.content?.body?.variables)}
        >
          {getEmbeddedDetails(form?.content?.body?.variables)}
        </PublishItem>
        {form?.other?.errors && form.status !== 'draft' ? (
          <>
            <hr />
            <CampaignErrors errors={form?.other?.errors} />
          </>
        ) : null}
      </Div>
      <Div width="30%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          Preview Pane
        </Text>
        <$BlueBox height="90%">
          <$SmsPreview>
            <img src={SMSTemplateImg} />
            <big>{form?.senderDetails?.name}</big>
            <span>
              {SMS_PREVIEW(
                smsTemplatesList[0]?.template,
                form?.content?.body?.displayParams
              )}
            </span>
          </$SmsPreview>
        </$BlueBox>
      </Div>
    </FlexBox>
  )
}
