import React, { useEffect, useState } from 'react'
import { cloneDeep, isInteger } from 'lodash'
import cytoscape from 'cytoscape'
import { useTheme } from 'styled-components'

import { FlexBox } from 'styledComponent'
import { useToasts } from 'components/Common'
import { ComponentsBar } from './ComponentsBar'
import {
  CYTO_INIT,
  GET_NODE_HEIGHT,
  NODE_HTML,
  NODE_LABEL,
} from '../../constants'
import { JourneyTopBar } from '../JourneyTopBar'
import JourneyPopup from './JourneyPopup'
import { JourneyAction } from './JourneyAction'
import { JourneyCanvas } from './JourneyCanvas'
import { deleteJourneyCampaign } from 'redux/actions/journeyActions'
import { ERROR_DECODE } from 'components/Content/autoEngagement/constants'

let cy

export const JourneyBuilder = ({
  title,
  jbDetails,
  updJourney,
  allEvents,
  allFilters,
  setJourneySteps,
  emailTemplateDetails,
}) => {
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [nodeData, setNodeData] = useState({})
  const [currentEdge, setCurrentEdge] = useState(null)
  const [nodeDatum, setNodeDatum] = useState({})
  const [outcome, setOutcome] = useState('')
  const [isActionVisible, setIsActionVisible] = useState(false)
  const [zoom, setZoom] = useState(1)

  const { toast } = useToasts()
  const { spacing } = useTheme()

  const renderCytoscapeElement = () => {
    const cyElem = document.getElementById('cy')
    cy = cytoscape({
      container: cyElem,
      ...CYTO_INIT,
      elements: cloneDeep(
        jbDetails?.editorData || {
          nodes: [],
          edges: [],
        }
      ),
    })

    const eh = cy.edgehandles({
      handlePosition: 'middle bottom',
      edgeParams: (nodeA, nodeB) => {
        return {
          data: {
            sourceNodeId: nodeA.data().nodeId,
            targetNodeId: nodeB.data().nodeId,
          },
        }
      },
      complete: (nodeA, nodeB, edge) => {
        const nodeIdA = nodeA.data().nodeId
        const nodeIdB = nodeB.data().nodeId

        const nodeTypeA = nodeA.data().nodeType
        const nodeTypeB = nodeB.data().nodeType

        const filEdges = nodeB
          ?.incomers()
          ?.filter((ele) => ele.data('target') === nodeB.data().id)
          ?.map((ele) => ele.data())

        const siblingTargetNodes = nodeA
          ?.outgoers()
          ?.map((ele) => ele.data().targetNodeId)
          ?.filter((nid) => nid === nodeB.data().nodeId)

        if (filEdges.length > 1 || siblingTargetNodes.length > 1) {
          return cy.remove(edge)
        } else if (nodeTypeA === 'start') {
          if (nodeTypeB !== 'customer') {
            toast(
              `${NODE_LABEL(
                nodeTypeA
              )} Component can only be connected to ${NODE_LABEL(
                'customer'
              )} Component.`,
              'error'
            )
            return cy.remove(edge)
          }
        } else if (nodeTypeA === 'customer') {
          if (!['digital', 'party', 'time'].includes(nodeTypeB)) {
            toast(
              `Customer Component cannot be connected to ${nodeTypeB} component.`,
              'error'
            )
            return cy.remove(edge)
          }
        } else if (nodeTypeA === 'digital' || nodeTypeA === 'party') {
          if (!['party', 'time'].includes(nodeTypeB)) {
            toast(
              `${NODE_LABEL(
                nodeTypeA
              )} Component cannot be connected to ${NODE_LABEL(
                nodeTypeB
              )} Component.`,
              'error'
            )
            return cy.remove(edge)
          }
        } else if (nodeTypeA === 'time') {
          if (nodeTypeB !== 'party') {
            toast(
              `${NODE_LABEL(
                nodeTypeA
              )} Component can only be connected to ${NODE_LABEL(
                'party'
              )} Component.`,
              'error'
            )
            return cy.remove(edge)
          }
        } else if (nodeTypeB === 'start') {
          toast(
            `${NODE_LABEL(
              nodeTypeB
            )} Component cannot be connected from ${NODE_LABEL(
              nodeTypeA
            )} component.`,
            'error'
          )
          return cy.remove(edge)
        }

        setCurrentEdge(edge)
        setIsPopOpen('edge')
      },
    })
    eh.enable()

    cy.on('tap', 'node', (evt) => {
      const node = evt.target
      const nodeID = node?.id() || null

      const deleteIconID = evt?.originalEvent?.target?.id || 'none'
      if (deleteIconID === `delete${nodeID}`) {
        cy.remove(node)
        const campaignId = node.data()?.campaignId
        if (campaignId) {
          deleteJourneyCampaign(campaignId)
          onSaveElements()
        }
      } else {
        if (
          ['filter_customerAll', 'filter_billMonitoring'].includes(
            node?.data()?.nodeId
          )
        )
          return
        setNodeData({
          data: cloneDeep(node?.data()),
          classes: node?.classes(),
        })
        setNodeDatum(cloneDeep(node?.data()))
        const nodeType = node?.data()?.nodeType

        if (['digital', 'party'].includes(nodeType)) setIsActionVisible(true)
        else setIsPopOpen(nodeType)
      }
    })

    cy.on('tap', 'edge', (evt) => {
      setCurrentEdge(evt.target)
      setOutcome(evt.target?.data()?.outcome)
      setIsPopOpen('edge')
    })

    NODE_HTML(cy, false, allEvents, allFilters)

    cy.zoom(1)
  }

  useEffect(renderCytoscapeElement, [jbDetails])

  useEffect(() => {
    cy.zoom && cy.zoom(zoom)
  }, [zoom])

  const onDropElement = async (e) => {
    try {
      const nodeType = e.dataTransfer.getData('nodeType')
      const name = e.dataTransfer.getData('nodeName')
      const nodeId = e.dataTransfer.getData('nodeId')

      const nodeIdArray = cy.nodes().map((nod) => nod.data().nodeId)
      const singleNodeArray = [
        'event_occurence',
        'filter_billMonitoring',
        'filter_adBelow',
        'filter_sellBelow',
        'filter_banner',
        'filter_survey',
        'filter_popOver',
      ]

      if (nodeIdArray.includes(nodeId) && singleNodeArray.includes(nodeId))
        throw `Component already exists in the journey.`

      if (
        cy.nodes("[nodeType = 'customer']").length > 0 &&
        nodeType === 'customer'
      )
        throw `${NODE_LABEL(nodeType)} Component already exists in the journey.`

      if (cy.nodes("[nodeType = 'start']").length === 0 && nodeType !== 'start')
        throw `Add an ${NODE_LABEL('start')} before adding other components.`

      if (
        cy.nodes("[nodeType = 'customer']").length === 0 &&
        !['start', 'customer'].includes(nodeType)
      )
        throw `Add a ${NODE_LABEL(
          'customer'
        )} component before adding other components.`

      const idNum = cy.nodes().size()
      const id = nodeId + Number(idNum + Number(Math.random())).toFixed(2)
      const nodeKaData = {
        group: 'nodes',
        data: {
          id,
          name,
          nodeType,
          nodeId,
          delay: 0,
          nodeHeight: GET_NODE_HEIGHT(nodeType),
        },
        classes: `${nodeType} node`,
        renderedPosition: {
          x: e.clientX - 110,
          y: e.clientY - 120,
        },
      }
      cy.add([nodeKaData])
      if (['filter_customerAll', 'filter_billMonitoring'].includes(nodeId))
        return
      setNodeData(nodeKaData)

      await setTimeout(() => {
        if (['digital', 'party'].includes(nodeType)) {
          return setIsActionVisible(true)
        }
        setIsPopOpen(nodeType)
      }, 500)
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onPopupSubmit = () => {
    if (isPopOpen) {
      if (isPopOpen === 'start' && !nodeDatum.subEvent) {
        toast('Please select a Sub Event', 'error')
        throw 'error'
      }
      if (
        nodeDatum?.subEvent === 'event_products' &&
        !(nodeDatum?.condition?.max || nodeDatum?.condition?.productIds?.length)
      ) {
        toast(
          'Please select either Product Quantity Range or Products Bought or both',
          'error'
        )
        throw 'error'
      }
      if (!!nodeDatum?.condition?.max) {
        if (
          Number(nodeDatum?.condition?.max) < Number(nodeDatum?.condition?.min)
        ) {
          toast(
            'Maximum value should always be greater than minimum value',
            'error'
          )
          throw 'error'
        }
        if (nodeDatum?.condition?.max < 0 || nodeDatum?.condition?.min < 0) {
          toast('Range cannot have negative values', 'error')
          throw 'error'
        }
        if (nodeDatum?.condition?.max < 1) {
          toast('Maximum value cannot be less than one', 'error')
          throw 'error'
        }
        if (nodeDatum?.subEvent === 'event_products') {
          if (nodeDatum?.condition?.min < 1 || nodeDatum?.condition?.max < 1) {
            toast('Product Quantity cannot be less than 1', 'error')
            throw 'error'
          }
          if (Number(nodeDatum?.condition?.max) > 99) {
            toast('Product Quantity cannot be more than 99', 'error')
            throw 'error'
          }
          if (
            !isInteger(Number(nodeDatum?.condition?.min)) ||
            !isInteger(Number(nodeDatum?.condition?.max))
          ) {
            toast('Product Quantity cannot have decimal values', 'error')
            throw 'error'
          }
        }
      }
      if (isPopOpen === 'edge') {
        const edge = cy.getElementById(currentEdge?.data()?.id)
        edge.data({
          name: outcome?.label,
          outcome,
        })
      } else {
        const node = cy.getElementById(nodeData?.data?.id)
        node.data({
          ...nodeData.data,
          ...nodeDatum,
        })
      }
    }
    stateCleanUp()
  }

  const onPopupCancel = () => {
    if (isPopOpen === 'edge') {
      if (!outcome?.value) {
        cy.remove(currentEdge)
      }
    }
    stateCleanUp()
  }
  const stateCleanUp = () => {
    setNodeData({})
    setCurrentEdge(null)
    setNodeDatum({})
    setOutcome('')
    setIsPopOpen(false)
    setIsActionVisible(false)
  }

  const onExitJourney = (campaignId) => {
    const node = cy.getElementById(nodeData?.data?.id)
    node.data({
      ...nodeData.data,
      campaignId,
    })
    onSaveElements(stateCleanUp)
  }

  const onSaveElements = (callBack) => {
    if (cy?.json()?.elements) {
      let nodes =
        cy.json().elements.nodes?.map((no) => ({
          classes: no.classes,
          data: no.data,
        })) || []
      const edges =
        cy.json().elements.edges?.map((ed) => ({
          classes: ed.classes,
          data: ed.data,
        })) || []

      nodes = nodes?.filter(
        (cl) => !(cl.classes.includes('eh-handle') || cl.classes === '')
      )

      updJourney({ editorData: { nodes, edges } }, callBack)
    }
  }

  const journeyPopProps = {
    nodeDatum,
    setNodeDatum,
    isPopOpen,
    setIsPopOpen,
    onPopupCancel,
    onPopupSubmit,
    currentEdge,
    outcome,
    setOutcome,
    nodeData,
    allEvents,
    allFilters,
    cy,
    jbDetails,
  }
  const journeyActionProps = {
    nodeDatum,
    setNodeDatum,
    nodeData,
    jbDetails,
    onExitJourney,
    emailTemplateDetails,
  }

  return (
    <>
      <JourneyTopBar
        progress="75%"
        title={title}
        onNextClick={() => onSaveElements(() => setJourneySteps(4))}
        onExitClick={onSaveElements}
        onPreviousClick={() => setJourneySteps(2)}
      />
      <JourneyPopup {...journeyPopProps} />
      {isActionVisible && <JourneyAction {...journeyActionProps} />}
      <FlexBox
        expand
        p={spacing.l}
        gap={spacing.l}
        height="calc(100vh - 112px)"
      >
        <JourneyCanvas setZoom={setZoom} onDropElement={onDropElement} />
        <ComponentsBar />
      </FlexBox>
    </>
  )
}

export default JourneyBuilder
