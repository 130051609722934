import React from 'react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'

import { spacing } from 'design-system'
import { useOnClickOutside } from 'hooks'

export const $ColoredBox = styled.button`
  outline: none;
  border: 2px solid #b0b0b0;
  width: 80px;
  height: 20px;
  background-color: ${({ bg }) => bg};
  border-radius: 4px;
  position: relative;
  /* left: 110px; */
  /* transform: translateX(-50%); */
  margin-bottom: ${spacing.m};
`

export const ColorPicker = ({
  color = '#417505',
  setColor = () => {},
  disabledButton,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const pickerRef = React.useRef()

  const handlePickerOutsideClick = () => {
    if (isOpen) handleBoxClick()
  }

  useOnClickOutside(pickerRef, handlePickerOutsideClick)

  const handleBoxClick = () => setIsOpen(!isOpen)

  const handleColorChange = (val) => {
    setColor(val.hex)
  }

  return (
    <div style={{ position: 'relative', width: '220px' }}>
      <$ColoredBox
        onClick={handleBoxClick}
        bg={color}
        disabled={disabledButton}
      />
      {isOpen && (
        <div
          ref={pickerRef}
          style={{ position: 'absolute', top: '30px', zIndex: 99 }}
        >
          <SketchPicker
            color={color}
            onChangeComplete={handleColorChange}
            disableAlpha
            {...rest}
          />
        </div>
      )}
    </div>
  )
}
