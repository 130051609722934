import styled from 'styled-components'
import { $Block } from '../ProductAnalytics.styled'

const $InstructionBlock = styled($Block)`
  background: #f2f5f8;
  padding: 20px;
  /* height: calc(100vh - 145px); */
`

const $InstructionsListBlock = styled.ul`
  list-style: none;
  padding: 10px 0 0;
  li {
    padding: 2px 0;
    font-size: 15px;
    color: #474242;
  }
`

export { $InstructionBlock, $InstructionsListBlock }
