import { chunk } from 'lodash'
import React from 'react'
import ReactPaginate from 'react-paginate'

import { spacing } from 'design-system'
import { Div, FlexBox } from 'styledComponent'
import { NoDataFound, Text } from 'components/Common'
import { $FlexBox, $Block } from '../../ProductAnalytics.styled'
import UnregisteredProductsCol from './UnregisteredProductItem'
import AddProductController from '../AddProductController'

const UnregisteredProductsList = ({
  loading,
  action,
  unregisteredProducts,
}) => {
  const [addProductController, setAddProductController] = React.useState({
    unregisteredProduct: null,
    popup: false,
  })

  const onPageChange = ({ selected }) => {
    const selectedPage = selected + 1
    action({ page: selectedPage || 1 })
  }

  const showPopup = (productDetails) => {
    setAddProductController({
      unregisteredProduct: productDetails,
      popup: true,
    })
  }

  const closePopup = () => {
    setAddProductController({
      unregisteredProduct: null,
      popup: false,
    })
  }

  return (
    <>
      <$Block margin={`${spacing.l} 0 0`} position="relative">
        {!loading && !unregisteredProducts?.docs?.length ? (
          <NoDataFound />
        ) : (
          <>
            <FlexBox>
              {chunk(unregisteredProducts?.docs, 5)?.map(
                (productsChunk, index) => (
                  <Div
                    width="33.33%"
                    borderRight={index < 2 && '1px solid lightgrey'}
                    key={index}
                  >
                    <UnregisteredProductsCol
                      unregisteredProducts={productsChunk}
                      addClick={showPopup}
                    />
                  </Div>
                )
              )}
            </FlexBox>

            {unregisteredProducts?.totalDocs > 15 ? (
              <$FlexBox
                justify="space-between"
                align="center"
                m={`${spacing.l} 0`}
              >
                <Text variant="small" type="grey">
                  Showing {unregisteredProducts?.page} -{' '}
                  {unregisteredProducts?.totalPages} pages
                </Text>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  pageCount={unregisteredProducts?.totalPages}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={2}
                  onPageChange={onPageChange}
                  containerClassName="aePagination"
                  activeClassName="pagination_activePage"
                />
              </$FlexBox>
            ) : null}
          </>
        )}
      </$Block>
      <AddProductController
        selectedUnregisteredProduct={addProductController?.unregisteredProduct}
        showPopup={addProductController?.popup}
        closePopup={closePopup}
        fetchAction={action}
      />
    </>
  )
}

export default UnregisteredProductsList
