import React from 'react'
import Popup from 'reactjs-popup'
import styled, { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import { Text, Button } from 'components/Common'
import { CloseIcon } from 'assets/icons'

export const PopupContainer = ({
  isPopOpen,
  setIsPopOpen,
  heading,
  onSubmitClick,
  children,
  submitButtonName = 'Submit',
  onCancel = () => setIsPopOpen(false),
  overlayStyle = {},
  cancelButtonName = 'Cancel',
  width,
  customContentStyle,
  extraButton = null,
  disableAutoCloseOnSubmit,
  contentBlockStyles = {},
}) => {
  const onSubmit = async () => {
    await onSubmitClick()
    if (!disableAutoCloseOnSubmit) {
      setIsPopOpen(false)
    }
  }

  const { borderRadius } = useTheme()

  return (
    <Popup
      open={isPopOpen}
      onClose={onCancel}
      contentStyle={{
        borderRadius: borderRadius.default,
        overflow: 'hidden',
        width,
        ...customContentStyle,
      }}
      overlayStyle={{ overflow: 'auto', ...overlayStyle }}
    >
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            {heading}
          </Text>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={onCancel} />
        </FlexBox>
        <Div style={contentBlockStyles}>{children}</Div>
        <FlexBox justify="flex-end">
          {extraButton}
          <Button variant="primaryOutline" onClick={onCancel}>
            {cancelButtonName}
          </Button>
          {onSubmitClick && (
            <Button ml="8px" variant="primary" onClick={onSubmit}>
              {submitButtonName}
            </Button>
          )}
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}

const $PopContainer = styled.div`
  > div {
    padding: ${({ theme }) => theme.spacing.l};
  }
  > div:first-child,
  > div:last-child {
    padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.l}`};
    background-color: ${({ theme }) => theme.colors.aquaHaze};
  }
`
