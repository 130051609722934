const initialState = {
    userList : [],
    hasMoreUsers : true,
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case "ADD_TO_USER_LIST":
        return {
            ...state,
            userList : [ ...state.userList, action.payload],
        }
        case "DELETE_FROM_USER_LIST":
        return {
            ...state,
            userList: state.userList.filter((user)=> user.email !== action.payload),
        }
        case "EDIT_USER_LIST":
        return{
            ...state,
            userList: state.userList.map((user)=> (user.email === action.payload.email) ? action.payload : user) 
        }
        case "INIT_USER_LIST":
        return {
            ...state,
            userList: action.payload.users,
            hasMoreUsers: action.payload.hasMoreUsers,
        };
        case "SET_USER_LIST": 
        return {
            ...state,
            userList: state.userList.concat(action.payload.users),
            hasMoreUsers: action.payload.hasMoreUsers,
        };
        default:
        return state;
    }
}