import React, { useState, useEffect } from 'react'
import { FlexBox } from 'styledComponent'

import { Text, Input, PopupContainer } from 'components/Common'
import { useTheme } from 'styled-components'

export const EmailReportPopup = ({
  isPopOpen,
  setIsPopOpen,
  onSendReport,
  maxEmails = 5,
}) => {
  const [emailList, setEmailList] = useState([])
  const { spacing } = useTheme()

  const addEmail = (emails) => {
    if (emails?.length > maxEmails) return
    return setEmailList(emails)
  }

  useEffect(() => () => setEmailList([]), [isPopOpen])

  return (
    <PopupContainer
      isPopOpen={!!isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Export Report"
      onSubmitClick={() =>
        onSendReport(
          emailList?.filter(
            (email) =>
              email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length || false
          ) || []
        )
      }
    >
      <FlexBox gap={spacing.m}>
        <Text type="main" mb={spacing.l}>
          Enter your Email ID to receive the report.
        </Text>
        <Text type="grey" mb={spacing.l}>
          (Maximum upto {maxEmails} emails)
        </Text>
      </FlexBox>
      <Input
        isEmail
        variant="create-tags"
        label="E-Mail List"
        id="emailList"
        value={emailList}
        onChange={addEmail}
        placeholder={`Enter emails and press enter... (Maximum ${maxEmails} emails)`}
      />
    </PopupContainer>
  )
}
