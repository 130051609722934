import moment from 'moment'
import { formatDateInFilters } from './getDateFormat'
import { convertObjectsIntoArray } from './convertObject'
import { isEmpty } from './is-empty'

export const defaultFeedbackCampaignFilters = {
  date: {
    isActive: false,
    value: {
      dates: {
        startDate: formatDateInFilters(
          new Date(new Date().setDate(new Date().getDate() - 1))
        ),
        startValue: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: formatDateInFilters(new Date()),
        endValue: new Date(),
      },
      time: {
        startTime: {
          hrs: '00',
          state: 'AM',
        },
        endTime: {
          hrs: '00',
          state: 'AM',
        },
      },
    },
  },
}

export const defaultFilters = {
  date: {
    isActive: false,
    value: {
      dates: {
        startDate: formatDateInFilters(moment().startOf('month').toDate()),
        startValue: moment().startOf('month').toDate(),
        endDate: formatDateInFilters(moment().endOf('day').toDate()),
        endValue: moment().endOf('day').toDate(),
      },
      time: {
        startTime: {
          hrs: '00',
          state: 'AM',
        },
        endTime: {
          hrs: '00',
          state: 'AM',
        },
      },
    },
  },
  ratings: {
    isActive: false,
    value: [],
  },
  selectedCampaign: {
    isActive: false,
    value: '',
  },
  selectedStores: {
    isActive: false,
    value: [],
  },
  emailList: {
    isActive: false,
    value: [],
  },
}

// TODO: Make this common during refactoring
export const hours = [
  {
    value: '01',
    label: '01',
  },
  {
    value: '02',
    label: '02',
  },
  {
    value: '03',
    label: '03',
  },
  {
    value: '04',
    label: '04',
  },
  {
    value: '05',
    label: '05',
  },
  {
    value: '06',
    label: '06',
  },
  {
    value: '07',
    label: '07',
  },
  {
    value: '08',
    label: '08',
  },
  {
    value: '09',
    label: '09',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
]

export const timeState = ['AM', 'PM']

export const getProcessedFilters = (filters) => {
  const optionToProcess = ['ratings']
  let result = {}

  Object.keys(filters).forEach((option) => {
    if (option === 'search' && filters[option]) {
      result[option] = filters[option]
    } else if (filters[option].isActive && !isEmpty(filters[option].value)) {
      if (optionToProcess.includes(option))
        result[option] = convertObjectsIntoArray(filters[option].value, 'value')
      else if (option === 'selectedCampaign')
        result.campaignIds = [filters[option].value.value]
      else if (option === 'selectedStores')
        result.storeIds = convertObjectsIntoArray(
          filters[option].value,
          'value'
        )
      else if (option === 'date') {
        // For consumer analytics
        if (filters[option].value.start && filters[option].value.end) {
          const startDate = moment(filters[option].value.start)
            .startOf('day')
            .toDate()
          const endDate = moment(filters[option].value.end)
            .endOf('day')
            .toDate()

          result[option] = {
            value: {
              startDate,
              endDate,
            },
          }
          return
        }
        let startTime = filters[option].value.time.startTime.hrs
        let endTime = filters[option].value.time.endTime.hrs

        let { startValue: startDate, endValue: endDate } =
          filters[option].value.dates

        if (filters[option].value.time.startTime.state === 'PM') {
          startDate = moment(startDate).hour(Number(startTime) + 12)
        } else if (startTime === '00') {
          startDate = moment(startDate).startOf('day').toDate()
        } else {
          startDate = moment(startDate).hour(Number(startTime))
        }

        if (filters[option].value.time.endTime.state === 'PM') {
          endDate = moment(endDate).hour(Number(endTime) + 12)
        } else if (endTime === '00') {
          endDate = moment(endDate).endOf('day').toDate()
        } else {
          endDate = moment(endDate).hour(Number(endTime))
        }

        result[option] = {
          startDate,
          endDate,
        }
      } else if (option === 'emailList') {
        result[option] = filters[option].value
      } else result[option] = filters[option]
    }
  })
  return result
}

export const listRowsPerPage = 10

export const SortOptions = [
  {
    id: 'newest',
    value: 'Newest First',
    sortOrder: -1,
    sortValue: 'feedbackDate',
  },
  {
    id: 'oldest',
    value: 'Oldest First',
    sortOrder: 1,
    sortValue: 'feedbackDate',
  },
]

export const dynamicBorderColor = '#0081ED'

export const captionTextLimit = 30
export const optionTextLimit = 30
