export default function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas')
  // const scaleX = image.naturalWidth / image.width;
  // const scaleY = image.naturalHeight / image.height;
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  //var image: HTMLImageElement;

  ctx.drawImage(
    image,
    // crop.x * 1,
    // crop.y * 1,
    // crop.width * 1,
    // crop.height * 1,
    0,
    0,
    image.width,
    image.height
  )

  // As Base64 string
  const base64Image = canvas.toDataURL('image/jpeg')

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      blob.name = fileName
      blob.frontImgUrl = window.URL.createObjectURL(blob)
      resolve(blob)
    }, 'image/jpeg')
  })
}
