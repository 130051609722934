import React from 'react'
import styled, { css } from 'styled-components'
import { spacing, color, borderRadius } from 'design-system/styles'

const StyledDatePickerButton = styled.button`
  border: 1px solid ${color.primary.default};
  border-radius: ${borderRadius.small};
  color: ${color.primary.default};
  background-color: ${color.white};
  padding: ${spacing.s} ${spacing.m};
  margin: ${spacing.s} ${spacing.m};
  font-size: 70%;
  text-align: center;
  outline: none;

  i {
    margin-right: ${spacing.s};
  }
  ${(props) =>
    props.variant === 'input' &&
    css`
      font-size: 14px;
      padding: ${spacing.m} ${spacing.l};
      width: 100%;
      margin: 0;
      border-radius: ${borderRadius.default};
      border: solid 1px #e6e6e6;
      background-color: ${({ theme }) => theme.white};
      width: ${({ width }) => width || '300px'};
      text-align: left;
    `};
`

const StyledStartEndDatePicker = styled.div`
  display: flex;
  align-items: center;
`

export const DatePickerWrapper = ({ children, onClick }) => {
  return (
    <StyledDatePickerButton onClick={onClick}>
      {children}
    </StyledDatePickerButton>
  )
}

export const DatePickerButton = React.forwardRef(
  ({ value, onClick, variant, ...rest }, ref) => {
    return (
      <StyledDatePickerButton
        variant={variant}
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        {...rest}
      >
        <i className="fas fa-calendar-alt" />
        {value}
      </StyledDatePickerButton>
    )
  }
)

export const StartEndDatePicker = ({ children }) => {
  return <StyledStartEndDatePicker>{children}</StyledStartEndDatePicker>
}
