import createReducer from 'utils/createReducer'
import * as types from '../types/productAnalytics'

const INITIAL_STATE = {
  selectedDate: null,
  overviewStats: null,
  topProducts: null,
  topProductCombinations: null,
  categoryAnalytics: null,
  allProducts: null,
  productsFilterOptions: null,
  appliedProductsFilter: null,
  productDetails: null,
  allCategories: null,
  selectedStores: null,
  paLoading: false,
  productsCount: 0,
  selectedStores: null,
}

export default createReducer(INITIAL_STATE)(types)
