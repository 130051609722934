export const SET_AE_FILTERS = 'SET_AE_FILTERS'
export const SET_AE_DATA = 'SET_AE_DATA'
export const SET_AE_CAMPAIGN_DATA = 'SET_AE_CAMPAIGN_DATA'
export const SET_AE_TABLE_LOADING = 'SET_AE_TABLE_LOADING'
export const SET_AE_LOADING = 'SET_AE_LOADING'
export const SET_AE_EMAIL_PROVIDERS = 'SET_AE_EMAIL_PROVIDERS'
export const SET_AE_CSV_LIST = 'SET_AE_CSV_LIST'
export const SET_AE_IMAGE_LIST = 'SET_AE_IMAGE_LIST'
export const SET_AE_CAROUSEL_LIST = 'SET_AE_CAROUSEL_LIST'
export const SET_AE_VIDEO_LIST = 'SET_AE_VIDEO_LIST'
export const SET_AE_PDF_LIST = 'SET_AE_PDF_LIST'
export const SET_AE_GIF_LIST = 'SET_AE_GIF_LIST'
export const SET_AE_ASSETS_LIST = 'SET_AE_ASSETS_LIST'
export const SET_COUPONS_DATA = 'SET_COUPONS_DATA'
export const SET_SURVEY_DATA = 'SET_SURVEY_DATA'
export const SET_AE_SURVEY_DATA = 'SET_AE_SURVEY_DATA'
export const SET_SEGMENT_DATA = 'SET_SEGMENT_DATA'
export const SET_SURVEY_RESPONSES_DATA = 'SET_SURVEY_RESPONSES_DATA'
export const SET_SURVEY_BUTTON_DATA = 'SET_SURVEY_BUTTON_DATA'
export const SET_FROM_SIB = 'SET_FROM_SIB'
export const SET_AUDIENCE_FILTER = 'SET_AUDIENCE_FILTER'
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES'
export const SET_EMAIL_TEMPLATE_DETAILS = 'SET_EMAIL_TEMPLATE_DETAILS'
export const SET_WHATSAPP_TEMPLATE_LIST = 'SET_WHATSAPP_TEMPLATE_LIST'
export const SET_AE_WHATSAPP_TEMPLATE = 'SET_AE_WHATSAPP_TEMPLATE'
