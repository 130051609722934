import React, { useState } from 'react'
import { Button } from 'components/Common'
import { StoreHeading } from 'components/Layout/StoresHeader'

export const SelectStoreGroupModal = ({ ...props }) => {
  const [isPopOpen, setIsPopOpen] = useState(false)

  return (
    <Button
      borderColor="#151515"
      variant="primaryOutline"
      onClick={() => setIsPopOpen(true)}
    >
      <StoreHeading
        isPopOpen={isPopOpen}
        setIsPopOpen={setIsPopOpen}
        {...props}
      />
    </Button>
  )
}
