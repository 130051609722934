import React, { Component } from 'react'
import moment from 'moment'
import swal from 'sweetalert'
import { connect } from 'react-redux'
import {
  NewStarIcon,
  StoreIcon,
  CheckBox,
  InputGroup,
  FlexBox,
  HorizontalLine,
  Grid,
  ScrollBox,
  Block,
  RelativeBlock,
  Text,
  GreyText,
  Logo,
  Card,
  ChatMessageCard,
  ChatReplyCard,
} from 'styledComponent'
import { replyToFeedback } from 'redux/actions'
import 'css/content/feedback.scss'
import FeedbackIcons from './FeedbackIcons'

const FeedbackRenderer = (props) => {
  const {
    starRating,
    type,
    brandLogo,
    feedback,
    feedbackDate,
    isFilled,
    quickFeedback,
    quickFeedbackMeta,
  } = props.feedback

  return isFilled ? (
    <Grid columns="1fr 8fr" rows="auto 2fr" className="mb-4">
      <Logo
        height="50px"
        width="50px"
        className="mt-auto mr-4"
        src={brandLogo}
      />
      <ChatMessageCard>
        {type === 'generic' && feedback}
        {type === 'quick' && (
          <>
            <Text className="mb-3">
              {quickFeedbackMeta?.ratingModels
                ? quickFeedbackMeta?.ratingModels[starRating - 1]?.caption
                : null}
            </Text>
            {quickFeedback &&
              quickFeedback.map((item, index) => (
                <CheckBox
                  className="mb-2"
                  readOnly
                  id={`quick-feedback-${index}`}
                  checked={item.isSelected}
                >
                  {item.value}
                </CheckBox>
              ))}
            <Text className="mb-2 mt-4">Any suggestions?</Text>
            <Text className="">{feedback}</Text>
          </>
        )}
      </ChatMessageCard>
      <div />
      <GreyText className="my-2" variant="tiny">
        {moment(feedbackDate).format(`h:m a | D/M/YYYY`)}
      </GreyText>
    </Grid>
  ) : null
}

const ChatRenderer = (props) => {
  const { reply, replyDate } = props.feedback
  return reply ? (
    <Grid columns="1fr 8fr" rows="auto 2fr">
      <div style={{ width: '40px' }} className="mr-4" />
      <ChatReplyCard>{reply}</ChatReplyCard>
      <div />
      <GreyText className="my-2 ml-auto" variant="tiny" text-align="right">
        {replyDate ? moment(replyDate).format(`h:m a | D/M/YYYY`) : null}
      </GreyText>
    </Grid>
  ) : null
}

const MAX_FEEDBACK_REPLY_CHARS_ALLOWED = 60

class FeedbackDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      replyText: '',
      isSendingReply: false,
    }
  }

  handleReplyInputChange = (event) =>
    this.setState({ replyText: event.target.value })

  replyToFeedback = async () => {
    const { user, billUid, companyId, storeId, brandName } =
      this.props.feedbacks[this.props.activeFeedbackIndex]
    const { replyText } = this.state

    if (!replyText)
      return swal('Reply is Required.', 'Reply cannot be blank.', 'warning')

    this.setState({ isSendingReply: true })
    const data = {
      replyText,
      user,
      billUid,
      companyId,
      brandName,
    }
    const result = await this.props.replyToFeedback(data)
    this.setState({ isSendingReply: false })
    if (result.code) {
      this.setState({ replyText: '' })
      swal('Success', 'Reply sent successfully', 'success')
    } else if (result.code === 3) {
      swal(
        'Insufficient balance',
        'Company balance is insufficient please recharge to proceed',
        'warning'
      )
    } else {
      swal('ERROR', 'Reply Sending error', 'error')
    }
  }

  render() {
    const feedback = this.props.feedbacks[this.props.activeFeedbackIndex]
      ? this.props.feedbacks[this.props.activeFeedbackIndex]
      : null

    return feedback ? (
      <Card
        className={this.props.className}
        lightBoxShadow
        noDash
        padding="0"
        style={{
          height: `calc(100vh - 230px)`,
        }}
        direction="column"
      >
        <Block className="p-4" height={`calc(100% - 58px)`}>
          <FlexBox className="mb-2">
            <Logo size="medium" src={feedback.brandLogo} />
            <Block className="mx-2">
              <Text variant="h6">
                {feedback.email ? feedback.email : feedback.user}
              </Text>
              <FlexBox>
                <StoreIcon className="mx-0" />
                <GreyText className="ml-2" variant="caption">
                  {feedback.displayAddress}
                </GreyText>
              </FlexBox>
            </Block>
            <FlexBox className="ml-2 mt-2 mb-auto">
              <FeedbackIcons
                starRating={feedback.starRating}
                scaleLength={feedback.scaleLength}
                scaleType={feedback.scaleType}
                filledRating={feedback?.filledRating}
              />
            </FlexBox>
          </FlexBox>
          <HorizontalLine className="mb-4" />
          <ScrollBox className="mr-3" height={`calc(100% - 105px)`}>
            <FeedbackRenderer feedback={feedback} />
            <ChatRenderer feedback={feedback} />
          </ScrollBox>
        </Block>
        {this.props.featuresAlloted.feedbackReplyPro && !feedback.reply ? (
          <GreyText ml="15px" mb="10px" fontSize="medium">
            {`Character Count: ${this.state.replyText?.length}/${MAX_FEEDBACK_REPLY_CHARS_ALLOWED}`}
          </GreyText>
        ) : null}
        {this.props.featuresAlloted.feedbackReplyPro ? (
          <RelativeBlock bottom={0}>
            {feedback.reply ? (
              <FlexBox>
                <GreyText variant="caption" className="my-3 mx-auto">
                  You can reply to a feedback only once.
                </GreyText>
              </FlexBox>
            ) : (
              <InputGroup
                isLoading={this.state.isSendingReply}
                handleInputChange={this.handleReplyInputChange}
                handleButtonClick={this.replyToFeedback}
                disabled={
                  (feedback.email &&
                    ['N.A', 'NA', null].includes(feedback.user)) ||
                  this.state.replyText?.length >
                    MAX_FEEDBACK_REPLY_CHARS_ALLOWED
                    ? true
                    : false
                }
              >
                Reply
              </InputGroup>
            )}
          </RelativeBlock>
        ) : null}
      </Card>
    ) : null
  }
}

const mapStateToProps = (state) => {
  return {
    feedbacks: state.feedback.feedbacks,
    featuresAlloted: state.vendorIds.selectedVendor.featuresAlloted,
  }
}

export default connect(mapStateToProps, {
  replyToFeedback,
})(FeedbackDetail)
