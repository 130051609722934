import * as types from 'redux/types/reports'
import * as moment from 'moment'
import { post, get } from 'utils/axiosHandler'

export const filterToQuery = (filter) => async (dispatch) => {
  let filterQuery = {}
  if (filter.timeFrame?.isActive) {
    filterQuery = {
      ...filterQuery,
      startDate: moment(filter.timeFrame.value.start).format('YYYY-MM-DD'),
      endDate: moment(filter.timeFrame.value.end).format('YYYY-MM-DD'),
    }
  }
  if (filter.brands?.isActive) {
    let brandIds = filter.brands.value.map((brand) => brand.brandId)
    filterQuery = { ...filterQuery, brandIds }
  }
  if (filter.stores?.isActive) {
    filterQuery = {
      ...filterQuery,
      storeRange: [filter.stores.value.min, filter.stores.value.max],
    }
  }
  await dispatch({
    type: types.SET_REPORTS_FILTERS,
    payload: { filterData: filter, filterQuery },
  })
  return filterQuery
}

export const generateReport = async (body) => {
  try {
    const res = await post(`/reports/analytics-reports`, body)
    return res.message
  } catch (error) {
    console.error(error)
    return false
  }
}

export const downloadReport = async (id) => {
  try {
    const res = await get(`/reports/analytics-reports/${id}?needFile=true`)
    if (res.code) {
      const link = document.createElement('a')
      link.setAttribute('href', res.data?.signedUrl)
      link.click()
      link.remove()
    }
    return res
  } catch (error) {
    console.error(error)
    return true
  }
}

// dispatch

export const getReportsList = (body) => async (dispatch) => {
  try {
    const res = await post(`/reports/analytics-reports/list`, body)

    dispatch({
      type: types.SET_REPORTS_DATA,
      payload: { data: res.data },
    })
    return true
  } catch (error) {
    console.error(error)
    return true
  }
}
