const TABLE_COLS = [
  {
    label: 'Product Name',
    value: 'productName',
  },
  {
    label: 'Display Name',
    value: 'displayNames',
  },
  {
    label: 'Product Code',
    value: 'productCode',
  },
  {
    label: 'Category',
    value: 'category',
  },
  {
    label: 'UAM',
    value: 'uam',
  },
  {
    label: 'Action',
    value: 'action',
  },
]

export { TABLE_COLS }
