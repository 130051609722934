import React from 'react'

import { ViewIcon, TrashBoxIcon, EditBoxIcon } from 'assets/icons'
import { useRouter } from 'hooks'
import DeleteProductModal from '../DeleteProductModal'
import { $FlexList, $FlexListItem } from '../AllProducts.styled'

const TableActions = ({ productId, action }) => {
  const deleteRef = React.useRef()
  const { setQuery, history, pathname } = useRouter()

  const onViewClick = () => {
    const pageQuery = {
      id: productId,
    }
    setQuery(pageQuery, { preservePrevState: true })
  }

  const onEditClick = () => {
    history.push({
      pathname: `${pathname}/products/${productId}`,
    })
  }

  const onDeleteClick = () => {
    deleteRef.current.openModal()
  }

  return (
    <>
      <$FlexList justify="center">
        <$FlexListItem onClick={onViewClick} pointer>
          <ViewIcon />
        </$FlexListItem>
        <$FlexListItem onClick={onEditClick} pointer>
          <EditBoxIcon />
        </$FlexListItem>
        <$FlexListItem onClick={onDeleteClick} pointer>
          <TrashBoxIcon />
        </$FlexListItem>
      </$FlexList>
      <DeleteProductModal
        ref={deleteRef}
        productId={productId}
        action={action}
      />
    </>
  )
}

export default TableActions
