import styled from 'styled-components'

import { spacing } from 'design-system'

const $Block = styled.ul`
  padding: ${spacing.m} 0 0;
  list-style: none;
`

const $ProductsBlock = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  width: 80%;
  flex-wrap: wrap;
`

const $ProductsItem = styled.li`
  flex-basis: 30%;
  max-width: 300px;
  position: relative;
`

const $CombinationBlock = styled.li`
  padding: ${spacing.m} 0;
  background-image: linear-gradient(
    to right,
    #dadada 70%,
    rgba(255, 255, 255, 0) 20%
  );
  background-position: bottom;
  background-size: 13px 1px;
  background-repeat: repeat-x;
`

export { $ProductsBlock, $ProductsItem, $CombinationBlock, $Block }
