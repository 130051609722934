import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { spacing, color } from 'design-system/styles'
import { ReactComponent as BackIcon } from 'images/back.svg'

const Drawer = ({ open, onClose, children, ...props }) => {
  return (
    <StyledDrawer open={open} {...props}>
      <DrawerHeader>
        <BackControls onClick={onClose}>
          <StyledBackIcon />
          <span>Go Back</span>
        </BackControls>
      </DrawerHeader>
      <Content>{children}</Content>
    </StyledDrawer>
  )
}

export default Drawer

const Animation = keyframes`
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(90%);
  }
  20% {
    transform: translateX(80%);
  }
  30% {
    transform: translateX(70%);
  }
  40% {
    transform: translateX(60%);
  }
  50% {
    transform: translateX(50%);
  }
  60% {
    transform: translateX(40%);
  }
  70% {
    transform: translateX(30%);
  }
  80% {
    transform: translateX(20%);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }

`

const DrawerHeader = styled.div`
  padding: ${spacing.l};
  padding-left: ${spacing.xl};
  background-color: rgb(238, 238, 238);
  font-weight: 900;
  color: ${color.primary.default};
`

const BackControls = styled.span`
  cursor: pointer;
  &:hover span {
    text-decoration: underline;
  }
`

const StyledBackIcon = styled(BackIcon)`
  width: 16px;
  margin-right: 16px;
  fill: ${color.primary.default};
`

const Content = styled.div`
  position: relative;
`

const StyledDrawer = styled.div`
  width: 100%;
  height: 100%;
  top: ${({ top }) => top || '0'};
  left: 0;
  padding-left: ${({ pl }) => pl || spacing.xxl};
  position: ${({ position }) => position || 'fixed'};
  display: none;
  overflow: auto;
  z-index: 4;
  background-color: ${({ bgColor }) => bgColor || 'unset'};
  ${(props) =>
    props.open &&
    css`
      display: block;
      transform: translateX(0);
      transform-origin: right;
      animation-name: ${Animation};
      animation-duration: 230ms;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-play-state: running;
    `}
`
