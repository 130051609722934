import { post, get } from 'utils/axiosHandler'
import * as types from '../types/billing'

export const getPlans = () => async (dispatch) => {
  try {
    const res = await get('/service-subscription/plans')
    dispatch({
      type: types.SET_SUBSCRIPTION_PLANS,
      payload: { planData: res },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SUBSCRIPTION_PLANS,
      payload: { planData: [] },
    })
  }
}

export const getUsage = () => async (dispatch, getState) => {
  const companyId = getState()?.company?.selectedCompanies?.[0]
  try {
    const res = await get(`/service-subscription/usage/${companyId}`)
    dispatch({
      type: types.SET_SUBSCRIPTION_USAGE,
      payload: { usageData: res },
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: types.SET_SUBSCRIPTION_USAGE,
      payload: { usageData: [] },
    })
  }
}

export const createPlans = async (data) =>
  await post(`/service-subscription/plans`, data)

export const setShopifyOpen = () => async (dispatch) => {
  dispatch({
    type: types.SET_SHOPIFY_OPEN,
    payload: { onceOpened: true },
  })
}
