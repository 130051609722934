import React from 'react'
import Popup from 'reactjs-popup'
import { Div, FlexBox } from 'styledComponent'
import { Text, Button } from 'components/Common'
import { $PopContainer } from '../AutoEngagement.styled'
import { CloseIcon } from 'assets/icons'
import { spacing } from 'design-system'
import { CANCEL_POP_STYLE } from '../constants'
import { useRouter } from 'hooks'

const CancelPopup = ({
  isPopOpen,
  setIsPopOpen,
  exitUrl = '/auto-engage',
  title = 'All unsaved data will be lost.',
  onOkClick,
  header = 'Are you sure?',
}) => {
  const { history } = useRouter()
  const onOk = () => {
    if (onOkClick) {
      onOkClick()
      setIsPopOpen(false)
    } else {
      history.push(exitUrl)
      setIsPopOpen(false)
    }
  }
  return (
    <Popup
      open={isPopOpen}
      onClose={() => setIsPopOpen(false)}
      contentStyle={CANCEL_POP_STYLE}
      className="modal"
    >
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            {header}
          </Text>
          <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
        </FlexBox>
        <Div margin={`${spacing.l} 0px`}>
          <Text variant="p" weight="bold" style={{ whiteSpace: 'pre-line' }}>
            {title}
          </Text>
        </Div>
        <FlexBox justify="flex-end">
          <Button variant="primaryOutline" onClick={() => setIsPopOpen(false)}>
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="primary" onClick={onOk}>
            OK
          </Button>
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}

export default CancelPopup
