import React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { spacing } from 'design-system/styles'

const VARIANT = {
  NANO: 'nano',
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  P: 'p',
  H3: 'h3',
  H2: 'h2',
  H1: 'h1',
}

const TYPE = {
  GREY: 'grey',
  WHITE: 'white',
  PRIMARY: 'primary',
  MAIN: 'main',
  TABLE: 'table',
  ACTIVITYCARD: 'activity-card',
  ERROR: 'error',
  SUCCESS: 'success',
}

const WEIGHT = {
  BOLDER: 'bolder',
  BOLD: 'bold',
  SEMIBOLD: 'semi-bold',
  NORMAL: 'normal',
  REGULAR: 'regular',
  LIGHT: 'light',
  LIGHTER: 'lighter',
}

const ALIGNMENT = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
}

const StyledText = styled.p`
  color: ${(props) => props.color || getType(props.type, props.theme)};
  font-size: ${(props) => getVariant(props.variant)};
  font-weight: ${(props) => getFontWeight(props.weight)};
  text-align: ${({ align }) => align || ALIGNMENT.LEFT};
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align === ALIGNMENT.CENTER && 'center'};
  word-break: ${({ wordBreak }) => (wordBreak ? 'break-word' : 'unset')};
  ${(props) =>
    props.align === ALIGNMENT.CENTER &&
    css`
      text-align: ${ALIGNMENT.CENTER};
    `};
  ${(props) =>
    props.align === ALIGNMENT.RIGHT &&
    css`
      text-align: ${ALIGNMENT.RIGHT};
    `};
  ${({ ellipsis }) =>
    ellipsis
      ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
  `
      : ''}
  max-width: ${(props) => props.maxWidth};
  ${space};
`

const $Icon = styled.img`
  width: ${({ variant }) => getVariant(variant)};
  height: ${({ variant }) => getVariant(variant)};
  margin-right: ${({ startIcon }) => startIcon && `${spacing.m}`};
  margin-left: ${({ endIcon }) => endIcon && `${spacing.m}`};
  padding: ${({ variant }) => getIconPadding(variant)};
`

const getVariant = (variant) => {
  switch (variant) {
    case VARIANT.H1:
      return '30px'
    case VARIANT.H2:
      return '24px'
    case VARIANT.H3:
      return '20px'
    case VARIANT.P:
      return '16px'
    case VARIANT.MEDIUM:
      return '14px'
    case VARIANT.SMALL:
      return '12px'
    case VARIANT.TINY:
      return '10px'
    case VARIANT.NANO:
      return '8px'
    default:
      return 'inherit'
  }
}

const getFontWeight = (weight) => {
  switch (weight) {
    case WEIGHT.BOLDER:
      return 900
    case WEIGHT.BOLD:
      return 700
    case WEIGHT.SEMIBOLD:
      return 600
    case WEIGHT.NORMAL:
      return 400
    case WEIGHT.REGULAR:
      return 300
    case WEIGHT.LIGHT:
      return 200
    case WEIGHT.LIGHTER:
      return 100
    default:
      return 400
  }
}

const getType = (type, theme) => {
  switch (type) {
    case TYPE.GREY:
      return theme.colors.grey
    case TYPE.WHITE:
      return theme.colors.white
    case TYPE.PRIMARY:
      return theme.colors.primary.default
    case TYPE.MAIN:
      return theme.colors.pickledBluewood
    case TYPE.ERROR:
      return theme.colors.error
    case TYPE.SUCCESS:
      return theme.colors.success.default
    case TYPE.TABLE:
      return theme.colors.shipGray
    default:
      return theme.colors.black
  }
}

const getIconPadding = (variant) => {
  switch (variant) {
    case VARIANT.H1:
      return `${spacing.s}`
    case VARIANT.H2:
    case VARIANT.H3:
    case VARIANT.P:
      return `${spacing.xs}`
    case VARIANT.MEDIUM:
    case VARIANT.SMALL:
    case VARIANT.TINY:
    case VARIANT.NANO:
      return '1px'
    default:
      return 'inherit'
  }
}

export const Text = ({ children, startIcon, endIcon, ...rest }) => {
  return (
    <StyledText {...rest}>
      {startIcon && <$Icon src={startIcon} startIcon {...rest} />}
      {children}
      {endIcon && <$Icon src={endIcon} endIcon {...rest} />}
    </StyledText>
  )
}
