import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import { Div, EditIcon, FlexBox } from 'styledComponent'
import { Text, Button, Input } from 'components/Common'
import { AddIcon } from 'assets/icons'
import {
  DLT_OPTIONS,
  SMS_HEADERS_HEADER,
  SMS_TEMPLATE_HEADER,
} from '../constants'
import { GET_SEL_VALUE } from 'components/Content/autoEngagement/constants'
import { DLTTable } from './DLTTable'
import { DLTSmsTemplatePopup } from './DLTSmsTemplatePopup'
import { DLTSettingsPopup } from './DLTSettingsPopup'
import {
  getSettingsData,
  getSmsHeaders,
  getSmsTemplates,
  setSettingsTableLoader,
} from 'redux/actions/settingsActions'
import { DLTSmsSenderIDPopup } from './DLTSmsSenderIDPopup'
import { useDebouncedEffect } from 'hooks'
import { $SearchInput } from 'components/Content/autoEngagement/AutoEngagement.styled'

const DLT = ({
  dlt,
  vendorId,
  getSettingsData,
  getSmsHeaders,
  getSmsTemplates,
  smsHeadersList,
  smsTemplatesList,
  settingsTableLoading,
  setSettingsTableLoader,
}) => {
  const { spacing } = useTheme()

  const [listing, setListing] = useState('senderIds')
  const [isPopOpen, setIsPopOpen] = useState(false)
  const [name, setName] = useState('')

  const tableHeader =
    listing === 'templates' ? SMS_TEMPLATE_HEADER : SMS_HEADERS_HEADER
  const tableList = listing === 'templates' ? smsTemplatesList : smsHeadersList
  const heading = listing === 'templates' ? ' Template' : ' Sender ID'
  const searchPlaceHolder =
    listing === 'templates'
      ? 'Search for Template Name, ID & Body'
      : 'Search for Header ID, Sender ID & Name'

  const getList = () => {
    const options = {}
    if (name) {
      options.name = name
    }
    if (dlt?.isConfigured !== false) {
      listing === 'senderIds'
        ? getSmsHeaders(options)
        : getSmsTemplates(options)
    }
  }

  useEffect(() => {
    if (dlt?.isConfigured === false) {
      setIsPopOpen('dlt-settings')
    }
  }, [dlt])

  useDebouncedEffect(getList, [listing, dlt, name])

  useEffect(() => {
    if (dlt?.isConfigured !== false) {
      setSettingsTableLoader(true)
    }
    if (dlt?.isConfigured === false) {
      setSettingsTableLoader(false)
    }
  }, [listing, dlt, name])

  useEffect(() => {
    getSettingsData('dlt')
  }, [vendorId])

  const entity = cloneDeep(dlt?.settings[0]?.key) || []

  return (
    <Div p={spacing.l}>
      <FlexBox justify="space-between" align="center">
        <FlexBox gap={spacing.xl}>
          <Div>
            {entity?.map((ent) => (
              <Text key={ent?.label}>
                {ent.label} : {ent.value}
              </Text>
            ))}
          </Div>
          <EditIcon onClick={() => setIsPopOpen('dlt-settings')} />
        </FlexBox>
        <Button
          variant="primary"
          startIcon={AddIcon}
          onClick={() => setIsPopOpen('dlt-add')}
        >
          Upload {heading}s
        </Button>
      </FlexBox>
      <FlexBox justify="space-between" align="flex-end">
        <Input
          variant="single-select"
          label="Select Listings"
          options={DLT_OPTIONS}
          id="senderIds"
          value={GET_SEL_VALUE(listing, DLT_OPTIONS) || ''}
          onChange={(val) => setListing(val.value)}
          inputWidth="280px"
        />
        <Input>
          <Div mr="-16px">
            <$SearchInput inputWidth="350px">
              <span />
              <input
                id="searchTxt"
                value={name}
                placeholder={searchPlaceHolder}
                onChange={(e) => setName(e.target.value)}
              />
            </$SearchInput>
          </Div>
        </Input>
      </FlexBox>
      <DLTTable
        list={tableList}
        heading={heading}
        header={tableHeader}
        vendorId={vendorId}
        setIsPopOpen={setIsPopOpen}
        tableLoading={settingsTableLoading}
      />
      {listing === 'senderIds' ? (
        <DLTSmsSenderIDPopup
          isPopOpen={isPopOpen === 'dlt-add'}
          setIsPopOpen={setIsPopOpen}
        />
      ) : (
        <DLTSmsTemplatePopup
          isPopOpen={isPopOpen === 'dlt-add'}
          setIsPopOpen={setIsPopOpen}
        />
      )}
      <DLTSettingsPopup
        dlt={cloneDeep(dlt)}
        isPopOpen={isPopOpen === 'dlt-settings'}
        setIsPopOpen={setIsPopOpen}
      />
    </Div>
  )
}

const mapStateToProps = (state) => ({
  dlt: state.settings.dlt,
  vendorId: state.vendorIds.selectedVendor.value,
  smsHeadersList: state.settings.smsHeadersList,
  smsTemplatesList: state.settings.smsTemplatesList,
  settingsTableLoading: state.settings.settingsTableLoading,
})

export default connect(mapStateToProps, {
  getSettingsData,
  getSmsHeaders,
  getSmsTemplates,
  setSettingsTableLoader,
})(DLT)
